export default {
  Canvas: {
    CanvasWidth: 1400,
    CanvasHeight: 600,
    CanvasBorderWeight: .5,
    CanvasBorderColor: '#E0E0DF',
    CanvasFillColor: '#fff',
    CanvasOpacity: 1,

    CanvasChartX: 0,
    CanvasChartY: 0,
    CanvasChartWidth: 400,
    CanvasChartHeight: 200,
    CanvasChartBorderWeight: 0,
    CanvasChartBorderColor: '#757575',
    CanvasChartFillColor: '#757575',
    CanvasChartOpacity: 0,
    CanvasChartSpace: 0,

    CanvasFontFamily: 'roboto',
  },
  
  Text: {
    Text: [],
    Filter: [],
    Sql: []
  },

  
  DataItems: [
    {
      // Timeline
      CUTOFF      : '2025-01-24',
      START_DATE  : '2024-11-15',
      END_DATE    : '2025-04-25',
    },
  ],


  Queries: {
    
    SQL1: [
      { CDATE: '2024-11-15', AREA: 'HULL',    ZONE: 'OVERALL', TOT: 6440,  FAB_WK: 0,     FAB_CUM: 0,     INS_WK: 0,     INS_CUM: 0, },
      { CDATE: '2024-11-22', AREA: 'HULL',    ZONE: 'OVERALL', TOT: 6440,  FAB_WK: 1,     FAB_CUM: 1,     INS_WK: 1,     INS_CUM: 1, },
      { CDATE: '2024-11-29', AREA: 'HULL',    ZONE: 'OVERALL', TOT: 6440,  FAB_WK: 0,     FAB_CUM: 1,     INS_WK: 0,     INS_CUM: 1, },
      { CDATE: '2024-12-06', AREA: 'HULL',    ZONE: 'OVERALL', TOT: 6440,  FAB_WK: 1,     FAB_CUM: 2,     INS_WK: 2,     INS_CUM: 3, },
      { CDATE: '2024-12-13', AREA: 'HULL',    ZONE: 'OVERALL', TOT: 6440,  FAB_WK: 12,    FAB_CUM: 14,    INS_WK: 2,     INS_CUM: 5, },
      { CDATE: '2024-12-20', AREA: 'HULL',    ZONE: 'OVERALL', TOT: 6440,  FAB_WK: 0,     FAB_CUM: 14,    INS_WK: 5,     INS_CUM: 10, },
      { CDATE: '2024-12-27', AREA: 'HULL',    ZONE: 'OVERALL', TOT: 6440,  FAB_WK: 14,    FAB_CUM: 28,    INS_WK: 5,     INS_CUM: 15, },
      { CDATE: '2025-01-03', AREA: 'HULL',    ZONE: 'OVERALL', TOT: 6440,  FAB_WK: 17,    FAB_CUM: 45,    INS_WK: 10,    INS_CUM: 25, },
      { CDATE: '2025-01-10', AREA: 'HULL',    ZONE: 'OVERALL', TOT: 6440,  FAB_WK: 26,    FAB_CUM: 71,    INS_WK: 5,     INS_CUM: 30, },
      { CDATE: '2025-01-17', AREA: 'HULL',    ZONE: 'OVERALL', TOT: 6440,  FAB_WK: 15,    FAB_CUM: 86,    INS_WK: 10,    INS_CUM: 40, },
      { CDATE: '2025-01-24', AREA: 'HULL',    ZONE: 'OVERALL', TOT: 6440,  FAB_WK: 41,    FAB_CUM: 127,   INS_WK: 35,    INS_CUM: 60, },
      { CDATE: '2025-01-31', AREA: 'HULL',    ZONE: 'OVERALL', TOT: 6440,  FAB_WK: 0,     FAB_CUM: 127,   INS_WK: 0,     INS_CUM: 0, },
      { CDATE: '2025-02-07', AREA: 'HULL',    ZONE: 'OVERALL', TOT: 6440,  FAB_WK: 0,     FAB_CUM: 127,   INS_WK: 0,     INS_CUM: 0, },
      { CDATE: '2025-02-14', AREA: 'HULL',    ZONE: 'OVERALL', TOT: 6440,  FAB_WK: 0,     FAB_CUM: 127,   INS_WK: 0,     INS_CUM: 0, },
      { CDATE: '2025-02-21', AREA: 'HULL',    ZONE: 'OVERALL', TOT: 6440,  FAB_WK: 0,     FAB_CUM: 127,   INS_WK: 0,     INS_CUM: 0, },
      { CDATE: '2025-02-28', AREA: 'HULL',    ZONE: 'OVERALL', TOT: 6440,  FAB_WK: 0,     FAB_CUM: 127,   INS_WK: 0,     INS_CUM: 0, },
      { CDATE: '2025-03-07', AREA: 'HULL',    ZONE: 'OVERALL', TOT: 6440,  FAB_WK: 0,     FAB_CUM: 127,   INS_WK: 0,     INS_CUM: 0, },
      { CDATE: '2025-03-14', AREA: 'HULL',    ZONE: 'OVERALL', TOT: 6440,  FAB_WK: 0,     FAB_CUM: 127,   INS_WK: 0,     INS_CUM: 0, },
      { CDATE: '2025-03-21', AREA: 'HULL',    ZONE: 'OVERALL', TOT: 6440,  FAB_WK: 0,     FAB_CUM: 127,   INS_WK: 0,     INS_CUM: 0, },
      { CDATE: '2025-03-28', AREA: 'HULL',    ZONE: 'OVERALL', TOT: 6440,  FAB_WK: 0,     FAB_CUM: 127,   INS_WK: 0,     INS_CUM: 0, },
      { CDATE: '2025-04-04', AREA: 'HULL',    ZONE: 'OVERALL', TOT: 6440,  FAB_WK: 0,     FAB_CUM: 127,   INS_WK: 0,     INS_CUM: 0, },
      { CDATE: '2025-04-11', AREA: 'HULL',    ZONE: 'OVERALL', TOT: 6440,  FAB_WK: 0,     FAB_CUM: 127,   INS_WK: 0,     INS_CUM: 0, },
      { CDATE: '2025-04-18', AREA: 'HULL',    ZONE: 'OVERALL', TOT: 6440,  FAB_WK: 0,     FAB_CUM: 127,   INS_WK: 0,     INS_CUM: 0, },
      { CDATE: '2025-04-25', AREA: 'HULL',    ZONE: 'OVERALL', TOT: 6440,  FAB_WK: 0,     FAB_CUM: 127,   INS_WK: 0,     INS_CUM: 0, },
      { CDATE: '2024-11-15', AREA: 'LQ',      ZONE: 'OVERALL', TOT: 373,   FAB_WK: 0,     FAB_CUM: 0,     INS_WK: 0,     INS_CUM: 0, },
      { CDATE: '2024-11-22', AREA: 'LQ',      ZONE: 'OVERALL', TOT: 373,   FAB_WK: 0,     FAB_CUM: 0,     INS_WK: 1,     INS_CUM: 1, },
      { CDATE: '2024-11-29', AREA: 'LQ',      ZONE: 'OVERALL', TOT: 373,   FAB_WK: 0,     FAB_CUM: 0,     INS_WK: 0,     INS_CUM: 1, },
      { CDATE: '2024-12-06', AREA: 'LQ',      ZONE: 'OVERALL', TOT: 373,   FAB_WK: 0,     FAB_CUM: 0,     INS_WK: 2,     INS_CUM: 3, },
      { CDATE: '2024-12-13', AREA: 'LQ',      ZONE: 'OVERALL', TOT: 373,   FAB_WK: 1,     FAB_CUM: 0,     INS_WK: 2,     INS_CUM: 5, },
      { CDATE: '2024-12-20', AREA: 'LQ',      ZONE: 'OVERALL', TOT: 373,   FAB_WK: 0,     FAB_CUM: 0,     INS_WK: 5,     INS_CUM: 10, },
      { CDATE: '2024-12-27', AREA: 'LQ',      ZONE: 'OVERALL', TOT: 373,   FAB_WK: 1,     FAB_CUM: 0,     INS_WK: 5,     INS_CUM: 15, },
      { CDATE: '2025-01-03', AREA: 'LQ',      ZONE: 'OVERALL', TOT: 373,   FAB_WK: 12,    FAB_CUM: 0,     INS_WK: 10,    INS_CUM: 25, },
      { CDATE: '2025-01-10', AREA: 'LQ',      ZONE: 'OVERALL', TOT: 373,   FAB_WK: 0,     FAB_CUM: 0,     INS_WK: 5,     INS_CUM: 30, },
      { CDATE: '2025-01-17', AREA: 'LQ',      ZONE: 'OVERALL', TOT: 373,   FAB_WK: 14,    FAB_CUM: 0,     INS_WK: 10,    INS_CUM: 40, },
      { CDATE: '2025-01-24', AREA: 'LQ',      ZONE: 'OVERALL', TOT: 373,   FAB_WK: 17,    FAB_CUM: 0,     INS_WK: 35,    INS_CUM: 60, },
      { CDATE: '2025-01-31', AREA: 'LQ',      ZONE: 'OVERALL', TOT: 373,   FAB_WK: 26,    FAB_CUM: 0,     INS_WK: 0,     INS_CUM: 0, },
      { CDATE: '2025-02-07', AREA: 'LQ',      ZONE: 'OVERALL', TOT: 373,   FAB_WK: 15,    FAB_CUM: 0,     INS_WK: 0,     INS_CUM: 0, },
      { CDATE: '2025-02-14', AREA: 'LQ',      ZONE: 'OVERALL', TOT: 373,   FAB_WK: 41,    FAB_CUM: 0,     INS_WK: 0,     INS_CUM: 0, },
      { CDATE: '2025-02-21', AREA: 'LQ',      ZONE: 'OVERALL', TOT: 373,   FAB_WK: 0,     FAB_CUM: 0,     INS_WK: 0,     INS_CUM: 0, },
      { CDATE: '2025-02-28', AREA: 'LQ',      ZONE: 'OVERALL', TOT: 373,   FAB_WK: 0,     FAB_CUM: 0,     INS_WK: 0,     INS_CUM: 0, },
      { CDATE: '2025-03-07', AREA: 'LQ',      ZONE: 'OVERALL', TOT: 373,   FAB_WK: 0,     FAB_CUM: 0,     INS_WK: 0,     INS_CUM: 0, },
      { CDATE: '2025-03-14', AREA: 'LQ',      ZONE: 'OVERALL', TOT: 373,   FAB_WK: 0,     FAB_CUM: 0,     INS_WK: 0,     INS_CUM: 0, },
      { CDATE: '2025-03-21', AREA: 'LQ',      ZONE: 'OVERALL', TOT: 373,   FAB_WK: 0,     FAB_CUM: 0,     INS_WK: 0,     INS_CUM: 0, },
      { CDATE: '2025-03-28', AREA: 'LQ',      ZONE: 'OVERALL', TOT: 373,   FAB_WK: 0,     FAB_CUM: 0,     INS_WK: 0,     INS_CUM: 0, },
      { CDATE: '2025-04-04', AREA: 'LQ',      ZONE: 'OVERALL', TOT: 373,   FAB_WK: 0,     FAB_CUM: 0,     INS_WK: 0,     INS_CUM: 0, },
      { CDATE: '2025-04-11', AREA: 'LQ',      ZONE: 'OVERALL', TOT: 373,   FAB_WK: 0,     FAB_CUM: 0,     INS_WK: 0,     INS_CUM: 0, },
      { CDATE: '2025-04-18', AREA: 'LQ',      ZONE: 'OVERALL', TOT: 373,   FAB_WK: 0,     FAB_CUM: 0,     INS_WK: 0,     INS_CUM: 0, },
      { CDATE: '2025-04-25', AREA: 'LQ',      ZONE: 'OVERALL', TOT: 373,   FAB_WK: 0,     FAB_CUM: 0,     INS_WK: 0,     INS_CUM: 0, },
      { CDATE: '2024-11-15', AREA: 'TOPSIDE', ZONE: 'CENTRAL', TOT: 5659,  FAB_WK: 0,     FAB_CUM: 0,     INS_WK: 0,     INS_CUM: 0, },
      { CDATE: '2024-11-22', AREA: 'TOPSIDE', ZONE: 'CENTRAL', TOT: 5659,  FAB_WK: 0,     FAB_CUM: 0,     INS_WK: 1,     INS_CUM: 1, },
      { CDATE: '2024-11-29', AREA: 'TOPSIDE', ZONE: 'CENTRAL', TOT: 5659,  FAB_WK: 0,     FAB_CUM: 0,     INS_WK: 0,     INS_CUM: 1, },
      { CDATE: '2024-12-06', AREA: 'TOPSIDE', ZONE: 'CENTRAL', TOT: 5659,  FAB_WK: 1,     FAB_CUM: 0,     INS_WK: 2,     INS_CUM: 3, },
      { CDATE: '2024-12-13', AREA: 'TOPSIDE', ZONE: 'CENTRAL', TOT: 5659,  FAB_WK: 0,     FAB_CUM: 0,     INS_WK: 2,     INS_CUM: 5, },
      { CDATE: '2024-12-20', AREA: 'TOPSIDE', ZONE: 'CENTRAL', TOT: 5659,  FAB_WK: 1,     FAB_CUM: 0,     INS_WK: 5,     INS_CUM: 10, },
      { CDATE: '2024-12-27', AREA: 'TOPSIDE', ZONE: 'CENTRAL', TOT: 5659,  FAB_WK: 12,    FAB_CUM: 0,     INS_WK: 5,     INS_CUM: 15, },
      { CDATE: '2025-01-03', AREA: 'TOPSIDE', ZONE: 'CENTRAL', TOT: 5659,  FAB_WK: 0,     FAB_CUM: 0,     INS_WK: 10,    INS_CUM: 25, },
      { CDATE: '2025-01-10', AREA: 'TOPSIDE', ZONE: 'CENTRAL', TOT: 5659,  FAB_WK: 14,    FAB_CUM: 0,     INS_WK: 5,     INS_CUM: 30, },
      { CDATE: '2025-01-17', AREA: 'TOPSIDE', ZONE: 'CENTRAL', TOT: 5659,  FAB_WK: 17,    FAB_CUM: 0,     INS_WK: 10,    INS_CUM: 40, },
      { CDATE: '2025-01-24', AREA: 'TOPSIDE', ZONE: 'CENTRAL', TOT: 5659,  FAB_WK: 26,    FAB_CUM: 0,     INS_WK: 35,    INS_CUM: 60, },
      { CDATE: '2025-01-31', AREA: 'TOPSIDE', ZONE: 'CENTRAL', TOT: 5659,  FAB_WK: 15,    FAB_CUM: 0,     INS_WK: 0,     INS_CUM: 0, },
      { CDATE: '2025-02-07', AREA: 'TOPSIDE', ZONE: 'CENTRAL', TOT: 5659,  FAB_WK: 41,    FAB_CUM: 0,     INS_WK: 0,     INS_CUM: 0, },
      { CDATE: '2025-02-14', AREA: 'TOPSIDE', ZONE: 'CENTRAL', TOT: 5659,  FAB_WK: 0,     FAB_CUM: 0,     INS_WK: 0,     INS_CUM: 0, },
      { CDATE: '2025-02-21', AREA: 'TOPSIDE', ZONE: 'CENTRAL', TOT: 5659,  FAB_WK: 0,     FAB_CUM: 0,     INS_WK: 0,     INS_CUM: 0, },
      { CDATE: '2025-02-28', AREA: 'TOPSIDE', ZONE: 'CENTRAL', TOT: 5659,  FAB_WK: 0,     FAB_CUM: 0,     INS_WK: 0,     INS_CUM: 0, },
      { CDATE: '2025-03-07', AREA: 'TOPSIDE', ZONE: 'CENTRAL', TOT: 5659,  FAB_WK: 0,     FAB_CUM: 0,     INS_WK: 0,     INS_CUM: 0, },
      { CDATE: '2025-03-14', AREA: 'TOPSIDE', ZONE: 'CENTRAL', TOT: 5659,  FAB_WK: 0,     FAB_CUM: 0,     INS_WK: 0,     INS_CUM: 0, },
      { CDATE: '2025-03-21', AREA: 'TOPSIDE', ZONE: 'CENTRAL', TOT: 5659,  FAB_WK: 0,     FAB_CUM: 0,     INS_WK: 0,     INS_CUM: 0, },
      { CDATE: '2025-03-28', AREA: 'TOPSIDE', ZONE: 'CENTRAL', TOT: 5659,  FAB_WK: 0,     FAB_CUM: 0,     INS_WK: 0,     INS_CUM: 0, },
      { CDATE: '2025-04-04', AREA: 'TOPSIDE', ZONE: 'CENTRAL', TOT: 5659,  FAB_WK: 0,     FAB_CUM: 0,     INS_WK: 0,     INS_CUM: 0, },
      { CDATE: '2025-04-11', AREA: 'TOPSIDE', ZONE: 'CENTRAL', TOT: 5659,  FAB_WK: 0,     FAB_CUM: 0,     INS_WK: 0,     INS_CUM: 0, },
      { CDATE: '2025-04-18', AREA: 'TOPSIDE', ZONE: 'CENTRAL', TOT: 5659,  FAB_WK: 0,     FAB_CUM: 0,     INS_WK: 0,     INS_CUM: 0, },
      { CDATE: '2025-04-25', AREA: 'TOPSIDE', ZONE: 'CENTRAL', TOT: 5659,  FAB_WK: 0,     FAB_CUM: 0,     INS_WK: 0,     INS_CUM: 0, },
      { CDATE: '2024-11-15', AREA: 'TOPSIDE', ZONE: 'NORTH',   TOT: 5768,  FAB_WK: 0,     FAB_CUM: 0,     INS_WK: 0,     INS_CUM: 0, },
      { CDATE: '2024-11-22', AREA: 'TOPSIDE', ZONE: 'NORTH',   TOT: 5768,  FAB_WK: 0,     FAB_CUM: 0,     INS_WK: 0,     INS_CUM: 0, },
      { CDATE: '2024-11-29', AREA: 'TOPSIDE', ZONE: 'NORTH',   TOT: 5768,  FAB_WK: 0,     FAB_CUM: 0,     INS_WK: 0,     INS_CUM: 0, },
      { CDATE: '2024-12-06', AREA: 'TOPSIDE', ZONE: 'NORTH',   TOT: 5768,  FAB_WK: 0,     FAB_CUM: 0,     INS_WK: 0,     INS_CUM: 0, },
      { CDATE: '2024-12-13', AREA: 'TOPSIDE', ZONE: 'NORTH',   TOT: 5768,  FAB_WK: 0,     FAB_CUM: 0,     INS_WK: 0,     INS_CUM: 0, },
      { CDATE: '2024-12-20', AREA: 'TOPSIDE', ZONE: 'NORTH',   TOT: 5768,  FAB_WK: 0,     FAB_CUM: 0,     INS_WK: 0,     INS_CUM: 0, },
      { CDATE: '2024-12-27', AREA: 'TOPSIDE', ZONE: 'NORTH',   TOT: 5768,  FAB_WK: 0,     FAB_CUM: 0,     INS_WK: 0,     INS_CUM: 0, },
      { CDATE: '2025-01-03', AREA: 'TOPSIDE', ZONE: 'NORTH',   TOT: 5768,  FAB_WK: 0,     FAB_CUM: 0,     INS_WK: 0,     INS_CUM: 0, },
      { CDATE: '2025-01-10', AREA: 'TOPSIDE', ZONE: 'NORTH',   TOT: 5768,  FAB_WK: 0,     FAB_CUM: 0,     INS_WK: 0,     INS_CUM: 0, },
      { CDATE: '2025-01-17', AREA: 'TOPSIDE', ZONE: 'NORTH',   TOT: 5768,  FAB_WK: 0,     FAB_CUM: 0,     INS_WK: 0,     INS_CUM: 0, },
      { CDATE: '2025-01-24', AREA: 'TOPSIDE', ZONE: 'NORTH',   TOT: 5768,  FAB_WK: 0,     FAB_CUM: 0,     INS_WK: 0,     INS_CUM: 0, },
      { CDATE: '2025-01-31', AREA: 'TOPSIDE', ZONE: 'NORTH',   TOT: 5768,  FAB_WK: 0,     FAB_CUM: 0,     INS_WK: 0,     INS_CUM: 0, },
      { CDATE: '2025-02-07', AREA: 'TOPSIDE', ZONE: 'NORTH',   TOT: 5768,  FAB_WK: 0,     FAB_CUM: 0,     INS_WK: 0,     INS_CUM: 0, },
      { CDATE: '2025-02-14', AREA: 'TOPSIDE', ZONE: 'NORTH',   TOT: 5768,  FAB_WK: 0,     FAB_CUM: 0,     INS_WK: 0,     INS_CUM: 0, },
      { CDATE: '2025-02-21', AREA: 'TOPSIDE', ZONE: 'NORTH',   TOT: 5768,  FAB_WK: 0,     FAB_CUM: 0,     INS_WK: 0,     INS_CUM: 0, },
      { CDATE: '2025-02-28', AREA: 'TOPSIDE', ZONE: 'NORTH',   TOT: 5768,  FAB_WK: 0,     FAB_CUM: 0,     INS_WK: 0,     INS_CUM: 0, },
      { CDATE: '2025-03-07', AREA: 'TOPSIDE', ZONE: 'NORTH',   TOT: 5768,  FAB_WK: 0,     FAB_CUM: 0,     INS_WK: 0,     INS_CUM: 0, },
      { CDATE: '2025-03-14', AREA: 'TOPSIDE', ZONE: 'NORTH',   TOT: 5768,  FAB_WK: 0,     FAB_CUM: 0,     INS_WK: 0,     INS_CUM: 0, },
      { CDATE: '2025-03-21', AREA: 'TOPSIDE', ZONE: 'NORTH',   TOT: 5768,  FAB_WK: 0,     FAB_CUM: 0,     INS_WK: 0,     INS_CUM: 0, },
      { CDATE: '2025-03-28', AREA: 'TOPSIDE', ZONE: 'NORTH',   TOT: 5768,  FAB_WK: 0,     FAB_CUM: 0,     INS_WK: 0,     INS_CUM: 0, },
      { CDATE: '2025-04-04', AREA: 'TOPSIDE', ZONE: 'NORTH',   TOT: 5768,  FAB_WK: 0,     FAB_CUM: 0,     INS_WK: 0,     INS_CUM: 0, },
      { CDATE: '2025-04-11', AREA: 'TOPSIDE', ZONE: 'NORTH',   TOT: 5768,  FAB_WK: 0,     FAB_CUM: 0,     INS_WK: 0,     INS_CUM: 0, },
      { CDATE: '2025-04-18', AREA: 'TOPSIDE', ZONE: 'NORTH',   TOT: 5768,  FAB_WK: 0,     FAB_CUM: 0,     INS_WK: 0,     INS_CUM: 0, },
      { CDATE: '2025-04-25', AREA: 'TOPSIDE', ZONE: 'NORTH',   TOT: 5768,  FAB_WK: 0,     FAB_CUM: 0,     INS_WK: 0,     INS_CUM: 0, },
      { CDATE: '2024-11-15', AREA: 'TOPSIDE', ZONE: 'SOUTH',   TOT: 3297,  FAB_WK: 0,     FAB_CUM: 0,     INS_WK: 0,     INS_CUM: 0, },
      { CDATE: '2024-11-22', AREA: 'TOPSIDE', ZONE: 'SOUTH',   TOT: 3297,  FAB_WK: 0,     FAB_CUM: 0,     INS_WK: 0,     INS_CUM: 0, },
      { CDATE: '2024-11-29', AREA: 'TOPSIDE', ZONE: 'SOUTH',   TOT: 3297,  FAB_WK: 0,     FAB_CUM: 0,     INS_WK: 0,     INS_CUM: 0, },
      { CDATE: '2024-12-06', AREA: 'TOPSIDE', ZONE: 'SOUTH',   TOT: 3297,  FAB_WK: 0,     FAB_CUM: 0,     INS_WK: 0,     INS_CUM: 0, },
      { CDATE: '2024-12-13', AREA: 'TOPSIDE', ZONE: 'SOUTH',   TOT: 3297,  FAB_WK: 0,     FAB_CUM: 0,     INS_WK: 0,     INS_CUM: 0, },
      { CDATE: '2024-12-20', AREA: 'TOPSIDE', ZONE: 'SOUTH',   TOT: 3297,  FAB_WK: 0,     FAB_CUM: 0,     INS_WK: 0,     INS_CUM: 0, },
      { CDATE: '2024-12-27', AREA: 'TOPSIDE', ZONE: 'SOUTH',   TOT: 3297,  FAB_WK: 0,     FAB_CUM: 0,     INS_WK: 0,     INS_CUM: 0, },
      { CDATE: '2025-01-03', AREA: 'TOPSIDE', ZONE: 'SOUTH',   TOT: 3297,  FAB_WK: 0,     FAB_CUM: 0,     INS_WK: 0,     INS_CUM: 0, },
      { CDATE: '2025-01-10', AREA: 'TOPSIDE', ZONE: 'SOUTH',   TOT: 3297,  FAB_WK: 0,     FAB_CUM: 0,     INS_WK: 0,     INS_CUM: 0, },
      { CDATE: '2025-01-17', AREA: 'TOPSIDE', ZONE: 'SOUTH',   TOT: 3297,  FAB_WK: 0,     FAB_CUM: 0,     INS_WK: 0,     INS_CUM: 0, },
      { CDATE: '2025-01-24', AREA: 'TOPSIDE', ZONE: 'SOUTH',   TOT: 3297,  FAB_WK: 0,     FAB_CUM: 0,     INS_WK: 0,     INS_CUM: 0, },
      { CDATE: '2025-01-31', AREA: 'TOPSIDE', ZONE: 'SOUTH',   TOT: 3297,  FAB_WK: 0,     FAB_CUM: 0,     INS_WK: 0,     INS_CUM: 0, },
      { CDATE: '2025-02-07', AREA: 'TOPSIDE', ZONE: 'SOUTH',   TOT: 3297,  FAB_WK: 0,     FAB_CUM: 0,     INS_WK: 0,     INS_CUM: 0, },
      { CDATE: '2025-02-14', AREA: 'TOPSIDE', ZONE: 'SOUTH',   TOT: 3297,  FAB_WK: 0,     FAB_CUM: 0,     INS_WK: 0,     INS_CUM: 0, },
      { CDATE: '2025-02-21', AREA: 'TOPSIDE', ZONE: 'SOUTH',   TOT: 3297,  FAB_WK: 0,     FAB_CUM: 0,     INS_WK: 0,     INS_CUM: 0, },
      { CDATE: '2025-02-28', AREA: 'TOPSIDE', ZONE: 'SOUTH',   TOT: 3297,  FAB_WK: 0,     FAB_CUM: 0,     INS_WK: 0,     INS_CUM: 0, },
      { CDATE: '2025-03-07', AREA: 'TOPSIDE', ZONE: 'SOUTH',   TOT: 3297,  FAB_WK: 0,     FAB_CUM: 0,     INS_WK: 0,     INS_CUM: 0, },
      { CDATE: '2025-03-14', AREA: 'TOPSIDE', ZONE: 'SOUTH',   TOT: 3297,  FAB_WK: 0,     FAB_CUM: 0,     INS_WK: 0,     INS_CUM: 0, },
      { CDATE: '2025-03-21', AREA: 'TOPSIDE', ZONE: 'SOUTH',   TOT: 3297,  FAB_WK: 0,     FAB_CUM: 0,     INS_WK: 0,     INS_CUM: 0, },
      { CDATE: '2025-03-28', AREA: 'TOPSIDE', ZONE: 'SOUTH',   TOT: 3297,  FAB_WK: 0,     FAB_CUM: 0,     INS_WK: 0,     INS_CUM: 0, },
      { CDATE: '2025-04-04', AREA: 'TOPSIDE', ZONE: 'SOUTH',   TOT: 3297,  FAB_WK: 0,     FAB_CUM: 0,     INS_WK: 0,     INS_CUM: 0, },
      { CDATE: '2025-04-11', AREA: 'TOPSIDE', ZONE: 'SOUTH',   TOT: 3297,  FAB_WK: 0,     FAB_CUM: 0,     INS_WK: 0,     INS_CUM: 0, },
      { CDATE: '2025-04-18', AREA: 'TOPSIDE', ZONE: 'SOUTH',   TOT: 3297,  FAB_WK: 0,     FAB_CUM: 0,     INS_WK: 0,     INS_CUM: 0, },
      { CDATE: '2025-04-25', AREA: 'TOPSIDE', ZONE: 'SOUTH',   TOT: 3297,  FAB_WK: 0,     FAB_CUM: 0,     INS_WK: 0,     INS_CUM: 0, },
      { CDATE: '2024-11-15', AREA: 'TOPSIDE', ZONE: 'OVERALL', TOT: 15083, FAB_WK: 0,     FAB_CUM: 0,     INS_WK: 0,     INS_CUM: 0, },
      { CDATE: '2024-11-22', AREA: 'TOPSIDE', ZONE: 'OVERALL', TOT: 15083, FAB_WK: 0,     FAB_CUM: 0,     INS_WK: 1,     INS_CUM: 1, },
      { CDATE: '2024-11-29', AREA: 'TOPSIDE', ZONE: 'OVERALL', TOT: 15083, FAB_WK: 0,     FAB_CUM: 0,     INS_WK: 0,     INS_CUM: 1, },
      { CDATE: '2024-12-06', AREA: 'TOPSIDE', ZONE: 'OVERALL', TOT: 15083, FAB_WK: 0,     FAB_CUM: 0,     INS_WK: 2,     INS_CUM: 3, },
      { CDATE: '2024-12-13', AREA: 'TOPSIDE', ZONE: 'OVERALL', TOT: 15083, FAB_WK: 0,     FAB_CUM: 0,     INS_WK: 2,     INS_CUM: 5, },
      { CDATE: '2024-12-20', AREA: 'TOPSIDE', ZONE: 'OVERALL', TOT: 15083, FAB_WK: 0,     FAB_CUM: 0,     INS_WK: 5,     INS_CUM: 10, },
      { CDATE: '2024-12-27', AREA: 'TOPSIDE', ZONE: 'OVERALL', TOT: 15083, FAB_WK: 0,     FAB_CUM: 0,     INS_WK: 5,     INS_CUM: 15, },
      { CDATE: '2025-01-03', AREA: 'TOPSIDE', ZONE: 'OVERALL', TOT: 15083, FAB_WK: 0,     FAB_CUM: 0,     INS_WK: 10,    INS_CUM: 25, },
      { CDATE: '2025-01-10', AREA: 'TOPSIDE', ZONE: 'OVERALL', TOT: 15083, FAB_WK: 0,     FAB_CUM: 0,     INS_WK: 5,     INS_CUM: 30, },
      { CDATE: '2025-01-17', AREA: 'TOPSIDE', ZONE: 'OVERALL', TOT: 15083, FAB_WK: 0,     FAB_CUM: 0,     INS_WK: 10,    INS_CUM: 40, },
      { CDATE: '2025-01-24', AREA: 'TOPSIDE', ZONE: 'OVERALL', TOT: 15083, FAB_WK: 0,     FAB_CUM: 0,     INS_WK: 35,    INS_CUM: 60, },
      { CDATE: '2025-01-31', AREA: 'TOPSIDE', ZONE: 'OVERALL', TOT: 15083, FAB_WK: 0,     FAB_CUM: 0,     INS_WK: 0,     INS_CUM: 0, },
      { CDATE: '2025-02-07', AREA: 'TOPSIDE', ZONE: 'OVERALL', TOT: 15083, FAB_WK: 0,     FAB_CUM: 0,     INS_WK: 0,     INS_CUM: 0, },
      { CDATE: '2025-02-14', AREA: 'TOPSIDE', ZONE: 'OVERALL', TOT: 15083, FAB_WK: 0,     FAB_CUM: 0,     INS_WK: 0,     INS_CUM: 0, },
      { CDATE: '2025-02-21', AREA: 'TOPSIDE', ZONE: 'OVERALL', TOT: 15083, FAB_WK: 0,     FAB_CUM: 0,     INS_WK: 0,     INS_CUM: 0, },
      { CDATE: '2025-02-28', AREA: 'TOPSIDE', ZONE: 'OVERALL', TOT: 15083, FAB_WK: 0,     FAB_CUM: 0,     INS_WK: 0,     INS_CUM: 0, },
      { CDATE: '2025-03-07', AREA: 'TOPSIDE', ZONE: 'OVERALL', TOT: 15083, FAB_WK: 0,     FAB_CUM: 0,     INS_WK: 0,     INS_CUM: 0, },
      { CDATE: '2025-03-14', AREA: 'TOPSIDE', ZONE: 'OVERALL', TOT: 15083, FAB_WK: 0,     FAB_CUM: 0,     INS_WK: 0,     INS_CUM: 0, },
      { CDATE: '2025-03-21', AREA: 'TOPSIDE', ZONE: 'OVERALL', TOT: 15083, FAB_WK: 0,     FAB_CUM: 0,     INS_WK: 0,     INS_CUM: 0, },
      { CDATE: '2025-03-28', AREA: 'TOPSIDE', ZONE: 'OVERALL', TOT: 15083, FAB_WK: 0,     FAB_CUM: 0,     INS_WK: 0,     INS_CUM: 0, },
      { CDATE: '2025-04-04', AREA: 'TOPSIDE', ZONE: 'OVERALL', TOT: 15083, FAB_WK: 0,     FAB_CUM: 0,     INS_WK: 0,     INS_CUM: 0, },
      { CDATE: '2025-04-11', AREA: 'TOPSIDE', ZONE: 'OVERALL', TOT: 15083, FAB_WK: 0,     FAB_CUM: 0,     INS_WK: 0,     INS_CUM: 0, },
      { CDATE: '2025-04-18', AREA: 'TOPSIDE', ZONE: 'OVERALL', TOT: 15083, FAB_WK: 0,     FAB_CUM: 0,     INS_WK: 0,     INS_CUM: 0, },
      { CDATE: '2025-04-25', AREA: 'TOPSIDE', ZONE: 'OVERALL', TOT: 15083, FAB_WK: 0,     FAB_CUM: 0,     INS_WK: 0,     INS_CUM: 0, },
      ],

  },
}