import __C from '../../../../../../includes/primitives/_constant_'

export default {
    Canvas: {
      CanvasWidth: 1400,
      CanvasHeight: 420,
      CanvasBorderWeight: .5,
      CanvasBorderColor: '#E0E0DF',
      CanvasFillColor: '#fff',
      CanvasOpacity: 1,

      CanvasChartX: 0,
      CanvasChartY: 0,
      CanvasChartWidth: 400,
      CanvasChartHeight: 200,
      CanvasChartBorderWeight: 0,
      CanvasChartBorderColor: '#757575',
      CanvasChartFillColor: '#757575',
      CanvasChartOpacity: 0,
      CanvasChartSpace: 0,

      CanvasFontFamily: 'roboto',
    },
    
    Text: {
      Text: [],
      Filter: [],
      Sql: []
    },

    DataItems: [
      { CDATE: '2023-07-28', DISC: 'Structure',           TOTAL: 24898.9, P_WK: 0,      A_WK: 0,     D_WK: 0,     P_CUM: 0,       A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2023-08-25', DISC: 'Structure',           TOTAL: 24898.9, P_WK: 0,      A_WK: 0,     D_WK: 0,     P_CUM: 0,       A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2023-09-29', DISC: 'Structure',           TOTAL: 24898.9, P_WK: 0,      A_WK: 0,     D_WK: 0,     P_CUM: 0,       A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2023-10-27', DISC: 'Structure',           TOTAL: 24898.9, P_WK: 0,      A_WK: 0,     D_WK: 0,     P_CUM: 0,       A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2023-11-24', DISC: 'Structure',           TOTAL: 24898.9, P_WK: 0,      A_WK: 0,     D_WK: 0,     P_CUM: 0,       A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2023-12-29', DISC: 'Structure',           TOTAL: 24898.9, P_WK: 0,      A_WK: 0,     D_WK: 0,     P_CUM: 0,       A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2024-01-26', DISC: 'Structure',           TOTAL: 24898.9, P_WK: 0,      A_WK: 0,     D_WK: 0,     P_CUM: 0,       A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2024-02-23', DISC: 'Structure',           TOTAL: 24898.9, P_WK: 0,      A_WK: 0,     D_WK: 0,     P_CUM: 0,       A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2024-03-29', DISC: 'Structure',           TOTAL: 24898.9, P_WK: 0,      A_WK: 0,     D_WK: 0,     P_CUM: 0,       A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2024-04-26', DISC: 'Structure',           TOTAL: 24898.9, P_WK: 0,      A_WK: 0,     D_WK: 0,     P_CUM: 0,       A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2024-05-31', DISC: 'Structure',           TOTAL: 24898.9, P_WK: 0,      A_WK: 0,     D_WK: 0,     P_CUM: 0,       A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2024-06-28', DISC: 'Structure',           TOTAL: 24898.9, P_WK: 0,      A_WK: 0,     D_WK: 0,     P_CUM: 0,       A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2024-07-26', DISC: 'Structure',           TOTAL: 24898.9, P_WK: 0,      A_WK: 0,     D_WK: 0,     P_CUM: 0,       A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2024-08-30', DISC: 'Structure',           TOTAL: 24898.9, P_WK: 0,      A_WK: 0,     D_WK: 0,     P_CUM: 0,       A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2024-09-27', DISC: 'Structure',           TOTAL: 24898.9, P_WK: 0,      A_WK: 0,     D_WK: 0,     P_CUM: 0,       A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2024-10-25', DISC: 'Structure',           TOTAL: 24898.9, P_WK: 0,      A_WK: 0,     D_WK: 0,     P_CUM: 0,       A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2024-11-29', DISC: 'Structure',           TOTAL: 24898.9, P_WK: 199.1,  A_WK: 133.5, D_WK: -65.6, P_CUM: 199.1,   A_CUM: 133.5, D_CUM: -65.6, },
      { CDATE: '2024-12-27', DISC: 'Structure',           TOTAL: 24898.9, P_WK: 344.2,  A_WK: 0,     D_WK: 0,     P_CUM: 543.3,   A_CUM: 133.5, D_CUM: 0, },
      { CDATE: '2025-01-31', DISC: 'Structure',           TOTAL: 24898.9, P_WK: 494.7,  A_WK: 0,     D_WK: 0,     P_CUM: 1038,    A_CUM: 133.5, D_CUM: 0, },
      { CDATE: '2025-02-28', DISC: 'Structure',           TOTAL: 24898.9, P_WK: 850.5,  A_WK: 0,     D_WK: 0,     P_CUM: 1888.5,  A_CUM: 133.5, D_CUM: 0, },
      { CDATE: '2025-03-28', DISC: 'Structure',           TOTAL: 24898.9, P_WK: 1309.2, A_WK: 0,     D_WK: 0,     P_CUM: 3197.7,  A_CUM: 133.5, D_CUM: 0, },
      { CDATE: '2025-04-25', DISC: 'Structure',           TOTAL: 24898.9, P_WK: 1268.2, A_WK: 0,     D_WK: 0,     P_CUM: 4465.9,  A_CUM: 133.5, D_CUM: 0, },
      { CDATE: '2025-05-30', DISC: 'Structure',           TOTAL: 24898.9, P_WK: 1752.4, A_WK: 0,     D_WK: 0,     P_CUM: 6218.3,  A_CUM: 133.5, D_CUM: 0, },
      { CDATE: '2025-06-27', DISC: 'Structure',           TOTAL: 24898.9, P_WK: 1958.6, A_WK: 0,     D_WK: 0,     P_CUM: 8176.9,  A_CUM: 133.5, D_CUM: 0, },
      { CDATE: '2025-07-25', DISC: 'Structure',           TOTAL: 24898.9, P_WK: 2380.7, A_WK: 0,     D_WK: 0,     P_CUM: 10557.6, A_CUM: 133.5, D_CUM: 0, },
      { CDATE: '2025-08-29', DISC: 'Structure',           TOTAL: 24898.9, P_WK: 1457.9, A_WK: 0,     D_WK: 0,     P_CUM: 12015.5, A_CUM: 133.5, D_CUM: 0, },
      { CDATE: '2025-09-26', DISC: 'Structure',           TOTAL: 24898.9, P_WK: 2000.6, A_WK: 0,     D_WK: 0,     P_CUM: 14016.1, A_CUM: 133.5, D_CUM: 0, },
      { CDATE: '2025-10-31', DISC: 'Structure',           TOTAL: 24898.9, P_WK: 1549.7, A_WK: 0,     D_WK: 0,     P_CUM: 15565.8, A_CUM: 133.5, D_CUM: 0, },
      { CDATE: '2025-11-28', DISC: 'Structure',           TOTAL: 24898.9, P_WK: 1966.8, A_WK: 0,     D_WK: 0,     P_CUM: 17532.6, A_CUM: 133.5, D_CUM: 0, },
      { CDATE: '2025-12-26', DISC: 'Structure',           TOTAL: 24898.9, P_WK: 1541.2, A_WK: 0,     D_WK: 0,     P_CUM: 19073.8, A_CUM: 133.5, D_CUM: 0, },
      { CDATE: '2026-01-30', DISC: 'Structure',           TOTAL: 24898.9, P_WK: 2066.4, A_WK: 0,     D_WK: 0,     P_CUM: 21140.2, A_CUM: 133.5, D_CUM: 0, },
      { CDATE: '2026-02-27', DISC: 'Structure',           TOTAL: 24898.9, P_WK: 1166,   A_WK: 0,     D_WK: 0,     P_CUM: 22306.2, A_CUM: 133.5, D_CUM: 0, },
      { CDATE: '2026-03-27', DISC: 'Structure',           TOTAL: 24898.9, P_WK: 1079.2, A_WK: 0,     D_WK: 0,     P_CUM: 23385.4, A_CUM: 133.5, D_CUM: 0, },
      { CDATE: '2026-04-24', DISC: 'Structure',           TOTAL: 24898.9, P_WK: 515.8,  A_WK: 0,     D_WK: 0,     P_CUM: 23901.2, A_CUM: 133.5, D_CUM: 0, },
      { CDATE: '2026-05-29', DISC: 'Structure',           TOTAL: 24898.9, P_WK: 279.7,  A_WK: 0,     D_WK: 0,     P_CUM: 24180.9, A_CUM: 133.5, D_CUM: 0, },
      { CDATE: '2026-06-26', DISC: 'Structure',           TOTAL: 24898.9, P_WK: 202,    A_WK: 0,     D_WK: 0,     P_CUM: 24382.9, A_CUM: 133.5, D_CUM: 0, },
      { CDATE: '2026-07-31', DISC: 'Structure',           TOTAL: 24898.9, P_WK: 91.9,   A_WK: 0,     D_WK: 0,     P_CUM: 24474.8, A_CUM: 133.5, D_CUM: 0, },
      { CDATE: '2026-08-28', DISC: 'Structure',           TOTAL: 24898.9, P_WK: 63.4,   A_WK: 0,     D_WK: 0,     P_CUM: 24538.2, A_CUM: 133.5, D_CUM: 0, },
      { CDATE: '2026-09-25', DISC: 'Structure',           TOTAL: 24898.9, P_WK: 67.1,   A_WK: 0,     D_WK: 0,     P_CUM: 24605.3, A_CUM: 133.5, D_CUM: 0, },
      { CDATE: '2026-10-30', DISC: 'Structure',           TOTAL: 24898.9, P_WK: 38.3,   A_WK: 0,     D_WK: 0,     P_CUM: 24643.6, A_CUM: 133.5, D_CUM: 0, },
      { CDATE: '2026-11-27', DISC: 'Structure',           TOTAL: 24898.9, P_WK: 20.9,   A_WK: 0,     D_WK: 0,     P_CUM: 24664.5, A_CUM: 133.5, D_CUM: 0, },
      { CDATE: '2026-12-25', DISC: 'Structure',           TOTAL: 24898.9, P_WK: 40.3,   A_WK: 0,     D_WK: 0,     P_CUM: 24704.8, A_CUM: 133.5, D_CUM: 0, },
      { CDATE: '2027-01-29', DISC: 'Structure',           TOTAL: 24898.9, P_WK: 14.5,   A_WK: 0,     D_WK: 0,     P_CUM: 24719.3, A_CUM: 133.5, D_CUM: 0, },
      { CDATE: '2027-02-26', DISC: 'Structure',           TOTAL: 24898.9, P_WK: 64.4,   A_WK: 0,     D_WK: 0,     P_CUM: 24783.7, A_CUM: 133.5, D_CUM: 0, },
      { CDATE: '2027-03-26', DISC: 'Structure',           TOTAL: 24898.9, P_WK: 115,    A_WK: 0,     D_WK: 0,     P_CUM: 24898.7, A_CUM: 133.5, D_CUM: 0, },
      { CDATE: '2027-04-30', DISC: 'Structure',           TOTAL: 24898.9, P_WK: 0,      A_WK: 0,     D_WK: 0,     P_CUM: 24898.7, A_CUM: 133.5, D_CUM: 0, },
      { CDATE: '2027-05-28', DISC: 'Structure',           TOTAL: 24898.9, P_WK: 0,      A_WK: 0,     D_WK: 0,     P_CUM: 24898.7, A_CUM: 133.5, D_CUM: 0, },
      { CDATE: '2027-06-11', DISC: 'Structure',           TOTAL: 24898.9, P_WK: 0,      A_WK: 0,     D_WK: 0,     P_CUM: 24898.7, A_CUM: 133.5, D_CUM: 0, },
      { CDATE: '2023-07-28', DISC: 'Mechanical',          TOTAL: 3511.1,  P_WK: 0,      A_WK: 0,     D_WK: 0,     P_CUM: 0,       A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2023-08-25', DISC: 'Mechanical',          TOTAL: 3511.1,  P_WK: 0,      A_WK: 0,     D_WK: 0,     P_CUM: 0,       A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2023-09-29', DISC: 'Mechanical',          TOTAL: 3511.1,  P_WK: 0,      A_WK: 0,     D_WK: 0,     P_CUM: 0,       A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2023-10-27', DISC: 'Mechanical',          TOTAL: 3511.1,  P_WK: 0,      A_WK: 0,     D_WK: 0,     P_CUM: 0,       A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2023-11-24', DISC: 'Mechanical',          TOTAL: 3511.1,  P_WK: 0,      A_WK: 0,     D_WK: 0,     P_CUM: 0,       A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2023-12-29', DISC: 'Mechanical',          TOTAL: 3511.1,  P_WK: 0,      A_WK: 0,     D_WK: 0,     P_CUM: 0,       A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2024-01-26', DISC: 'Mechanical',          TOTAL: 3511.1,  P_WK: 0,      A_WK: 0,     D_WK: 0,     P_CUM: 0,       A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2024-02-23', DISC: 'Mechanical',          TOTAL: 3511.1,  P_WK: 0,      A_WK: 0,     D_WK: 0,     P_CUM: 0,       A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2024-03-29', DISC: 'Mechanical',          TOTAL: 3511.1,  P_WK: 0,      A_WK: 0,     D_WK: 0,     P_CUM: 0,       A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2024-04-26', DISC: 'Mechanical',          TOTAL: 3511.1,  P_WK: 0,      A_WK: 0,     D_WK: 0,     P_CUM: 0,       A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2024-05-31', DISC: 'Mechanical',          TOTAL: 3511.1,  P_WK: 0,      A_WK: 0,     D_WK: 0,     P_CUM: 0,       A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2024-06-28', DISC: 'Mechanical',          TOTAL: 3511.1,  P_WK: 0,      A_WK: 0,     D_WK: 0,     P_CUM: 0,       A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2024-07-26', DISC: 'Mechanical',          TOTAL: 3511.1,  P_WK: 0,      A_WK: 0,     D_WK: 0,     P_CUM: 0,       A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2024-08-30', DISC: 'Mechanical',          TOTAL: 3511.1,  P_WK: 0,      A_WK: 0,     D_WK: 0,     P_CUM: 0,       A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2024-09-27', DISC: 'Mechanical',          TOTAL: 3511.1,  P_WK: 0,      A_WK: 0,     D_WK: 0,     P_CUM: 0,       A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2024-10-25', DISC: 'Mechanical',          TOTAL: 3511.1,  P_WK: 0,      A_WK: 0,     D_WK: 0,     P_CUM: 0,       A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2024-11-29', DISC: 'Mechanical',          TOTAL: 3511.1,  P_WK: 0,      A_WK: 0,     D_WK: 0,     P_CUM: 0,       A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2024-12-27', DISC: 'Mechanical',          TOTAL: 3511.1,  P_WK: 0,      A_WK: 0,     D_WK: 0,     P_CUM: 0,       A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2025-01-31', DISC: 'Mechanical',          TOTAL: 3511.1,  P_WK: 0,      A_WK: 0,     D_WK: 0,     P_CUM: 0,       A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2025-02-28', DISC: 'Mechanical',          TOTAL: 3511.1,  P_WK: 9.4,    A_WK: 0,     D_WK: 0,     P_CUM: 9.4,     A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2025-03-28', DISC: 'Mechanical',          TOTAL: 3511.1,  P_WK: 17.6,   A_WK: 0,     D_WK: 0,     P_CUM: 27,      A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2025-04-25', DISC: 'Mechanical',          TOTAL: 3511.1,  P_WK: 65.6,   A_WK: 0,     D_WK: 0,     P_CUM: 92.6,    A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2025-05-30', DISC: 'Mechanical',          TOTAL: 3511.1,  P_WK: 124.9,  A_WK: 0,     D_WK: 0,     P_CUM: 217.5,   A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2025-06-27', DISC: 'Mechanical',          TOTAL: 3511.1,  P_WK: 133,    A_WK: 0,     D_WK: 0,     P_CUM: 350.5,   A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2025-07-25', DISC: 'Mechanical',          TOTAL: 3511.1,  P_WK: 180.2,  A_WK: 0,     D_WK: 0,     P_CUM: 530.7,   A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2025-08-29', DISC: 'Mechanical',          TOTAL: 3511.1,  P_WK: 264.8,  A_WK: 0,     D_WK: 0,     P_CUM: 795.5,   A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2025-09-26', DISC: 'Mechanical',          TOTAL: 3511.1,  P_WK: 372.5,  A_WK: 0,     D_WK: 0,     P_CUM: 1168,    A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2025-10-31', DISC: 'Mechanical',          TOTAL: 3511.1,  P_WK: 272.9,  A_WK: 0,     D_WK: 0,     P_CUM: 1440.9,  A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2025-11-28', DISC: 'Mechanical',          TOTAL: 3511.1,  P_WK: 174.5,  A_WK: 0,     D_WK: 0,     P_CUM: 1615.4,  A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2025-12-26', DISC: 'Mechanical',          TOTAL: 3511.1,  P_WK: 134.5,  A_WK: 0,     D_WK: 0,     P_CUM: 1749.9,  A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2026-01-30', DISC: 'Mechanical',          TOTAL: 3511.1,  P_WK: 167.5,  A_WK: 0,     D_WK: 0,     P_CUM: 1917.4,  A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2026-02-27', DISC: 'Mechanical',          TOTAL: 3511.1,  P_WK: 74.9,   A_WK: 0,     D_WK: 0,     P_CUM: 1992.3,  A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2026-03-27', DISC: 'Mechanical',          TOTAL: 3511.1,  P_WK: 37.5,   A_WK: 0,     D_WK: 0,     P_CUM: 2029.8,  A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2026-04-24', DISC: 'Mechanical',          TOTAL: 3511.1,  P_WK: 156.5,  A_WK: 0,     D_WK: 0,     P_CUM: 2186.3,  A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2026-05-29', DISC: 'Mechanical',          TOTAL: 3511.1,  P_WK: 206.6,  A_WK: 0,     D_WK: 0,     P_CUM: 2392.9,  A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2026-06-26', DISC: 'Mechanical',          TOTAL: 3511.1,  P_WK: 220.5,  A_WK: 0,     D_WK: 0,     P_CUM: 2613.4,  A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2026-07-31', DISC: 'Mechanical',          TOTAL: 3511.1,  P_WK: 248.3,  A_WK: 0,     D_WK: 0,     P_CUM: 2861.7,  A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2026-08-28', DISC: 'Mechanical',          TOTAL: 3511.1,  P_WK: 216.6,  A_WK: 0,     D_WK: 0,     P_CUM: 3078.3,  A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2026-09-25', DISC: 'Mechanical',          TOTAL: 3511.1,  P_WK: 167.5,  A_WK: 0,     D_WK: 0,     P_CUM: 3245.8,  A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2026-10-30', DISC: 'Mechanical',          TOTAL: 3511.1,  P_WK: 54.4,   A_WK: 0,     D_WK: 0,     P_CUM: 3300.2,  A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2026-11-27', DISC: 'Mechanical',          TOTAL: 3511.1,  P_WK: 75.7,   A_WK: 0,     D_WK: 0,     P_CUM: 3375.9,  A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2026-12-25', DISC: 'Mechanical',          TOTAL: 3511.1,  P_WK: 105.9,  A_WK: 0,     D_WK: 0,     P_CUM: 3481.8,  A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2027-01-29', DISC: 'Mechanical',          TOTAL: 3511.1,  P_WK: 5.2,    A_WK: 0,     D_WK: 0,     P_CUM: 3487,    A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2027-02-26', DISC: 'Mechanical',          TOTAL: 3511.1,  P_WK: 24,     A_WK: 0,     D_WK: 0,     P_CUM: 3511,    A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2027-03-26', DISC: 'Mechanical',          TOTAL: 3511.1,  P_WK: 0,      A_WK: 0,     D_WK: 0,     P_CUM: 3511,    A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2027-04-30', DISC: 'Mechanical',          TOTAL: 3511.1,  P_WK: 0,      A_WK: 0,     D_WK: 0,     P_CUM: 3511,    A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2027-05-28', DISC: 'Mechanical',          TOTAL: 3511.1,  P_WK: 0,      A_WK: 0,     D_WK: 0,     P_CUM: 3511,    A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2027-06-11', DISC: 'Mechanical',          TOTAL: 3511.1,  P_WK: 0,      A_WK: 0,     D_WK: 0,     P_CUM: 3511,    A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2023-07-28', DISC: 'Piping',              TOTAL: 19725.6, P_WK: 0,      A_WK: 0,     D_WK: 0,     P_CUM: 0,       A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2023-08-25', DISC: 'Piping',              TOTAL: 19725.6, P_WK: 0,      A_WK: 0,     D_WK: 0,     P_CUM: 0,       A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2023-09-29', DISC: 'Piping',              TOTAL: 19725.6, P_WK: 0,      A_WK: 0,     D_WK: 0,     P_CUM: 0,       A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2023-10-27', DISC: 'Piping',              TOTAL: 19725.6, P_WK: 0,      A_WK: 0,     D_WK: 0,     P_CUM: 0,       A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2023-11-24', DISC: 'Piping',              TOTAL: 19725.6, P_WK: 0,      A_WK: 0,     D_WK: 0,     P_CUM: 0,       A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2023-12-29', DISC: 'Piping',              TOTAL: 19725.6, P_WK: 0,      A_WK: 0,     D_WK: 0,     P_CUM: 0,       A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2024-01-26', DISC: 'Piping',              TOTAL: 19725.6, P_WK: 0,      A_WK: 0,     D_WK: 0,     P_CUM: 0,       A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2024-02-23', DISC: 'Piping',              TOTAL: 19725.6, P_WK: 0,      A_WK: 0,     D_WK: 0,     P_CUM: 0,       A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2024-03-29', DISC: 'Piping',              TOTAL: 19725.6, P_WK: 0,      A_WK: 0,     D_WK: 0,     P_CUM: 0,       A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2024-04-26', DISC: 'Piping',              TOTAL: 19725.6, P_WK: 0,      A_WK: 0,     D_WK: 0,     P_CUM: 0,       A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2024-05-31', DISC: 'Piping',              TOTAL: 19725.6, P_WK: 0,      A_WK: 0,     D_WK: 0,     P_CUM: 0,       A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2024-06-28', DISC: 'Piping',              TOTAL: 19725.6, P_WK: 0,      A_WK: 0,     D_WK: 0,     P_CUM: 0,       A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2024-07-26', DISC: 'Piping',              TOTAL: 19725.6, P_WK: 0,      A_WK: 0,     D_WK: 0,     P_CUM: 0,       A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2024-08-30', DISC: 'Piping',              TOTAL: 19725.6, P_WK: 0,      A_WK: 0,     D_WK: 0,     P_CUM: 0,       A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2024-09-27', DISC: 'Piping',              TOTAL: 19725.6, P_WK: 0,      A_WK: 0,     D_WK: 0,     P_CUM: 0,       A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2024-10-25', DISC: 'Piping',              TOTAL: 19725.6, P_WK: 0,      A_WK: 0,     D_WK: 0,     P_CUM: 0,       A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2024-11-29', DISC: 'Piping',              TOTAL: 19725.6, P_WK: 0,      A_WK: 0,     D_WK: 0,     P_CUM: 0,       A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2024-12-27', DISC: 'Piping',              TOTAL: 19725.6, P_WK: 0,      A_WK: 0,     D_WK: 0,     P_CUM: 0,       A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2025-01-31', DISC: 'Piping',              TOTAL: 19725.6, P_WK: 0.3,    A_WK: 0,     D_WK: 0,     P_CUM: 0.3,     A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2025-02-28', DISC: 'Piping',              TOTAL: 19725.6, P_WK: 53.7,   A_WK: 0,     D_WK: 0,     P_CUM: 54,      A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2025-03-28', DISC: 'Piping',              TOTAL: 19725.6, P_WK: 186.3,  A_WK: 0,     D_WK: 0,     P_CUM: 240.3,   A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2025-04-25', DISC: 'Piping',              TOTAL: 19725.6, P_WK: 359.1,  A_WK: 0,     D_WK: 0,     P_CUM: 599.4,   A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2025-05-30', DISC: 'Piping',              TOTAL: 19725.6, P_WK: 926.6,  A_WK: 0,     D_WK: 0,     P_CUM: 1526,    A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2025-06-27', DISC: 'Piping',              TOTAL: 19725.6, P_WK: 1140.1, A_WK: 0,     D_WK: 0,     P_CUM: 2666.1,  A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2025-07-25', DISC: 'Piping',              TOTAL: 19725.6, P_WK: 1685.4, A_WK: 0,     D_WK: 0,     P_CUM: 4351.5,  A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2025-08-29', DISC: 'Piping',              TOTAL: 19725.6, P_WK: 985.7,  A_WK: 0,     D_WK: 0,     P_CUM: 5337.2,  A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2025-09-26', DISC: 'Piping',              TOTAL: 19725.6, P_WK: 1635.4, A_WK: 0,     D_WK: 0,     P_CUM: 6972.6,  A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2025-10-31', DISC: 'Piping',              TOTAL: 19725.6, P_WK: 1340.6, A_WK: 0,     D_WK: 0,     P_CUM: 8313.2,  A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2025-11-28', DISC: 'Piping',              TOTAL: 19725.6, P_WK: 1566.6, A_WK: 0,     D_WK: 0,     P_CUM: 9879.8,  A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2025-12-26', DISC: 'Piping',              TOTAL: 19725.6, P_WK: 1227,   A_WK: 0,     D_WK: 0,     P_CUM: 11106.8, A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2026-01-30', DISC: 'Piping',              TOTAL: 19725.6, P_WK: 1175.6, A_WK: 0,     D_WK: 0,     P_CUM: 12282.4, A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2026-02-27', DISC: 'Piping',              TOTAL: 19725.6, P_WK: 159.4,  A_WK: 0,     D_WK: 0,     P_CUM: 12441.8, A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2026-03-27', DISC: 'Piping',              TOTAL: 19725.6, P_WK: 20,     A_WK: 0,     D_WK: 0,     P_CUM: 12461.8, A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2026-04-24', DISC: 'Piping',              TOTAL: 19725.6, P_WK: 134.6,  A_WK: 0,     D_WK: 0,     P_CUM: 12596.4, A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2026-05-29', DISC: 'Piping',              TOTAL: 19725.6, P_WK: 454.1,  A_WK: 0,     D_WK: 0,     P_CUM: 13050.5, A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2026-06-26', DISC: 'Piping',              TOTAL: 19725.6, P_WK: 845.9,  A_WK: 0,     D_WK: 0,     P_CUM: 13896.4, A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2026-07-31', DISC: 'Piping',              TOTAL: 19725.6, P_WK: 1354.3, A_WK: 0,     D_WK: 0,     P_CUM: 15250.7, A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2026-08-28', DISC: 'Piping',              TOTAL: 19725.6, P_WK: 1200.8, A_WK: 0,     D_WK: 0,     P_CUM: 16451.5, A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2026-09-25', DISC: 'Piping',              TOTAL: 19725.6, P_WK: 1289,   A_WK: 0,     D_WK: 0,     P_CUM: 17740.5, A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2026-10-30', DISC: 'Piping',              TOTAL: 19725.6, P_WK: 1189.7, A_WK: 0,     D_WK: 0,     P_CUM: 18930.2, A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2026-11-27', DISC: 'Piping',              TOTAL: 19725.6, P_WK: 471.7,  A_WK: 0,     D_WK: 0,     P_CUM: 19401.9, A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2026-12-25', DISC: 'Piping',              TOTAL: 19725.6, P_WK: 104.6,  A_WK: 0,     D_WK: 0,     P_CUM: 19506.5, A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2027-01-29', DISC: 'Piping',              TOTAL: 19725.6, P_WK: 66.5,   A_WK: 0,     D_WK: 0,     P_CUM: 19573,   A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2027-02-26', DISC: 'Piping',              TOTAL: 19725.6, P_WK: 152.7,  A_WK: 0,     D_WK: 0,     P_CUM: 19725.7, A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2027-03-26', DISC: 'Piping',              TOTAL: 19725.6, P_WK: 0,      A_WK: 0,     D_WK: 0,     P_CUM: 19725.7, A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2027-04-30', DISC: 'Piping',              TOTAL: 19725.6, P_WK: 0,      A_WK: 0,     D_WK: 0,     P_CUM: 19725.7, A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2027-05-28', DISC: 'Piping',              TOTAL: 19725.6, P_WK: 0,      A_WK: 0,     D_WK: 0,     P_CUM: 19725.7, A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2027-06-11', DISC: 'Piping',              TOTAL: 19725.6, P_WK: 0,      A_WK: 0,     D_WK: 0,     P_CUM: 19725.7, A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2023-07-28', DISC: 'Elect/Inst/Tel',      TOTAL: 12480.1, P_WK: 0,      A_WK: 0,     D_WK: 0,     P_CUM: 0,       A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2023-08-25', DISC: 'Elect/Inst/Tel',      TOTAL: 12480.1, P_WK: 0,      A_WK: 0,     D_WK: 0,     P_CUM: 0,       A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2023-09-29', DISC: 'Elect/Inst/Tel',      TOTAL: 12480.1, P_WK: 0,      A_WK: 0,     D_WK: 0,     P_CUM: 0,       A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2023-10-27', DISC: 'Elect/Inst/Tel',      TOTAL: 12480.1, P_WK: 0,      A_WK: 0,     D_WK: 0,     P_CUM: 0,       A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2023-11-24', DISC: 'Elect/Inst/Tel',      TOTAL: 12480.1, P_WK: 0,      A_WK: 0,     D_WK: 0,     P_CUM: 0,       A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2023-12-29', DISC: 'Elect/Inst/Tel',      TOTAL: 12480.1, P_WK: 0,      A_WK: 0,     D_WK: 0,     P_CUM: 0,       A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2024-01-26', DISC: 'Elect/Inst/Tel',      TOTAL: 12480.1, P_WK: 0,      A_WK: 0,     D_WK: 0,     P_CUM: 0,       A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2024-02-23', DISC: 'Elect/Inst/Tel',      TOTAL: 12480.1, P_WK: 0,      A_WK: 0,     D_WK: 0,     P_CUM: 0,       A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2024-03-29', DISC: 'Elect/Inst/Tel',      TOTAL: 12480.1, P_WK: 0,      A_WK: 0,     D_WK: 0,     P_CUM: 0,       A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2024-04-26', DISC: 'Elect/Inst/Tel',      TOTAL: 12480.1, P_WK: 0,      A_WK: 0,     D_WK: 0,     P_CUM: 0,       A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2024-05-31', DISC: 'Elect/Inst/Tel',      TOTAL: 12480.1, P_WK: 0,      A_WK: 0,     D_WK: 0,     P_CUM: 0,       A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2024-06-28', DISC: 'Elect/Inst/Tel',      TOTAL: 12480.1, P_WK: 0,      A_WK: 0,     D_WK: 0,     P_CUM: 0,       A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2024-07-26', DISC: 'Elect/Inst/Tel',      TOTAL: 12480.1, P_WK: 0,      A_WK: 0,     D_WK: 0,     P_CUM: 0,       A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2024-08-30', DISC: 'Elect/Inst/Tel',      TOTAL: 12480.1, P_WK: 0,      A_WK: 0,     D_WK: 0,     P_CUM: 0,       A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2024-09-27', DISC: 'Elect/Inst/Tel',      TOTAL: 12480.1, P_WK: 0,      A_WK: 0,     D_WK: 0,     P_CUM: 0,       A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2024-10-25', DISC: 'Elect/Inst/Tel',      TOTAL: 12480.1, P_WK: 0,      A_WK: 0,     D_WK: 0,     P_CUM: 0,       A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2024-11-29', DISC: 'Elect/Inst/Tel',      TOTAL: 12480.1, P_WK: 0,      A_WK: 0,     D_WK: 0,     P_CUM: 0,       A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2024-12-27', DISC: 'Elect/Inst/Tel',      TOTAL: 12480.1, P_WK: 0,      A_WK: 0,     D_WK: 0,     P_CUM: 0,       A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2025-01-31', DISC: 'Elect/Inst/Tel',      TOTAL: 12480.1, P_WK: 0,      A_WK: 0,     D_WK: 0,     P_CUM: 0,       A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2025-02-28', DISC: 'Elect/Inst/Tel',      TOTAL: 12480.1, P_WK: 28.7,   A_WK: 0,     D_WK: 0,     P_CUM: 28.7,    A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2025-03-28', DISC: 'Elect/Inst/Tel',      TOTAL: 12480.1, P_WK: 51.6,   A_WK: 0,     D_WK: 0,     P_CUM: 80.3,    A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2025-04-25', DISC: 'Elect/Inst/Tel',      TOTAL: 12480.1, P_WK: 148.5,  A_WK: 0,     D_WK: 0,     P_CUM: 228.8,   A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2025-05-30', DISC: 'Elect/Inst/Tel',      TOTAL: 12480.1, P_WK: 424.9,  A_WK: 0,     D_WK: 0,     P_CUM: 653.7,   A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2025-06-27', DISC: 'Elect/Inst/Tel',      TOTAL: 12480.1, P_WK: 661,    A_WK: 0,     D_WK: 0,     P_CUM: 1314.7,  A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2025-07-25', DISC: 'Elect/Inst/Tel',      TOTAL: 12480.1, P_WK: 991.2,  A_WK: 0,     D_WK: 0,     P_CUM: 2305.9,  A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2025-08-29', DISC: 'Elect/Inst/Tel',      TOTAL: 12480.1, P_WK: 521.2,  A_WK: 0,     D_WK: 0,     P_CUM: 2827.1,  A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2025-09-26', DISC: 'Elect/Inst/Tel',      TOTAL: 12480.1, P_WK: 867.2,  A_WK: 0,     D_WK: 0,     P_CUM: 3694.3,  A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2025-10-31', DISC: 'Elect/Inst/Tel',      TOTAL: 12480.1, P_WK: 571.1,  A_WK: 0,     D_WK: 0,     P_CUM: 4265.4,  A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2025-11-28', DISC: 'Elect/Inst/Tel',      TOTAL: 12480.1, P_WK: 799.8,  A_WK: 0,     D_WK: 0,     P_CUM: 5065.2,  A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2025-12-26', DISC: 'Elect/Inst/Tel',      TOTAL: 12480.1, P_WK: 685.3,  A_WK: 0,     D_WK: 0,     P_CUM: 5750.5,  A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2026-01-30', DISC: 'Elect/Inst/Tel',      TOTAL: 12480.1, P_WK: 904.4,  A_WK: 0,     D_WK: 0,     P_CUM: 6654.9,  A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2026-02-27', DISC: 'Elect/Inst/Tel',      TOTAL: 12480.1, P_WK: 559.3,  A_WK: 0,     D_WK: 0,     P_CUM: 7214.2,  A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2026-03-27', DISC: 'Elect/Inst/Tel',      TOTAL: 12480.1, P_WK: 264.8,  A_WK: 0,     D_WK: 0,     P_CUM: 7479,    A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2026-04-24', DISC: 'Elect/Inst/Tel',      TOTAL: 12480.1, P_WK: 61.1,   A_WK: 0,     D_WK: 0,     P_CUM: 7540.1,  A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2026-05-29', DISC: 'Elect/Inst/Tel',      TOTAL: 12480.1, P_WK: 198.7,  A_WK: 0,     D_WK: 0,     P_CUM: 7738.8,  A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2026-06-26', DISC: 'Elect/Inst/Tel',      TOTAL: 12480.1, P_WK: 331.5,  A_WK: 0,     D_WK: 0,     P_CUM: 8070.3,  A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2026-07-31', DISC: 'Elect/Inst/Tel',      TOTAL: 12480.1, P_WK: 606.1,  A_WK: 0,     D_WK: 0,     P_CUM: 8676.4,  A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2026-08-28', DISC: 'Elect/Inst/Tel',      TOTAL: 12480.1, P_WK: 728.5,  A_WK: 0,     D_WK: 0,     P_CUM: 9404.9,  A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2026-09-25', DISC: 'Elect/Inst/Tel',      TOTAL: 12480.1, P_WK: 745.9,  A_WK: 0,     D_WK: 0,     P_CUM: 10150.8, A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2026-10-30', DISC: 'Elect/Inst/Tel',      TOTAL: 12480.1, P_WK: 905.9,  A_WK: 0,     D_WK: 0,     P_CUM: 11056.7, A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2026-11-27', DISC: 'Elect/Inst/Tel',      TOTAL: 12480.1, P_WK: 736.3,  A_WK: 0,     D_WK: 0,     P_CUM: 11793,   A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2026-12-25', DISC: 'Elect/Inst/Tel',      TOTAL: 12480.1, P_WK: 540.4,  A_WK: 0,     D_WK: 0,     P_CUM: 12333.4, A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2027-01-29', DISC: 'Elect/Inst/Tel',      TOTAL: 12480.1, P_WK: 52.7,   A_WK: 0,     D_WK: 0,     P_CUM: 12386.1, A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2027-02-26', DISC: 'Elect/Inst/Tel',      TOTAL: 12480.1, P_WK: 94,     A_WK: 0,     D_WK: 0,     P_CUM: 12480.1, A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2027-03-26', DISC: 'Elect/Inst/Tel',      TOTAL: 12480.1, P_WK: 0,      A_WK: 0,     D_WK: 0,     P_CUM: 12480.1, A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2027-04-30', DISC: 'Elect/Inst/Tel',      TOTAL: 12480.1, P_WK: 0,      A_WK: 0,     D_WK: 0,     P_CUM: 12480.1, A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2027-05-28', DISC: 'Elect/Inst/Tel',      TOTAL: 12480.1, P_WK: 0,      A_WK: 0,     D_WK: 0,     P_CUM: 12480.1, A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2027-06-11', DISC: 'Elect/Inst/Tel',      TOTAL: 12480.1, P_WK: 0,      A_WK: 0,     D_WK: 0,     P_CUM: 12480.1, A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2023-07-28', DISC: 'HVAC & Arch.', TOTAL: 1947.9,  P_WK: 0,      A_WK: 0,     D_WK: 0,     P_CUM: 0,       A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2023-08-25', DISC: 'HVAC & Arch.', TOTAL: 1947.9,  P_WK: 0,      A_WK: 0,     D_WK: 0,     P_CUM: 0,       A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2023-09-29', DISC: 'HVAC & Arch.', TOTAL: 1947.9,  P_WK: 0,      A_WK: 0,     D_WK: 0,     P_CUM: 0,       A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2023-10-27', DISC: 'HVAC & Arch.', TOTAL: 1947.9,  P_WK: 0,      A_WK: 0,     D_WK: 0,     P_CUM: 0,       A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2023-11-24', DISC: 'HVAC & Arch.', TOTAL: 1947.9,  P_WK: 0,      A_WK: 0,     D_WK: 0,     P_CUM: 0,       A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2023-12-29', DISC: 'HVAC & Arch.', TOTAL: 1947.9,  P_WK: 0,      A_WK: 0,     D_WK: 0,     P_CUM: 0,       A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2024-01-26', DISC: 'HVAC & Arch.', TOTAL: 1947.9,  P_WK: 0,      A_WK: 0,     D_WK: 0,     P_CUM: 0,       A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2024-02-23', DISC: 'HVAC & Arch.', TOTAL: 1947.9,  P_WK: 0,      A_WK: 0,     D_WK: 0,     P_CUM: 0,       A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2024-03-29', DISC: 'HVAC & Arch.', TOTAL: 1947.9,  P_WK: 0,      A_WK: 0,     D_WK: 0,     P_CUM: 0,       A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2024-04-26', DISC: 'HVAC & Arch.', TOTAL: 1947.9,  P_WK: 0,      A_WK: 0,     D_WK: 0,     P_CUM: 0,       A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2024-05-31', DISC: 'HVAC & Arch.', TOTAL: 1947.9,  P_WK: 0,      A_WK: 0,     D_WK: 0,     P_CUM: 0,       A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2024-06-28', DISC: 'HVAC & Arch.', TOTAL: 1947.9,  P_WK: 0,      A_WK: 0,     D_WK: 0,     P_CUM: 0,       A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2024-07-26', DISC: 'HVAC & Arch.', TOTAL: 1947.9,  P_WK: 0,      A_WK: 0,     D_WK: 0,     P_CUM: 0,       A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2024-08-30', DISC: 'HVAC & Arch.', TOTAL: 1947.9,  P_WK: 0,      A_WK: 0,     D_WK: 0,     P_CUM: 0,       A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2024-09-27', DISC: 'HVAC & Arch.', TOTAL: 1947.9,  P_WK: 0,      A_WK: 0,     D_WK: 0,     P_CUM: 0,       A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2024-10-25', DISC: 'HVAC & Arch.', TOTAL: 1947.9,  P_WK: 0,      A_WK: 0,     D_WK: 0,     P_CUM: 0,       A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2024-11-29', DISC: 'HVAC & Arch.', TOTAL: 1947.9,  P_WK: 0,      A_WK: 0,     D_WK: 0,     P_CUM: 0,       A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2024-12-27', DISC: 'HVAC & Arch.', TOTAL: 1947.9,  P_WK: 0,      A_WK: 0,     D_WK: 0,     P_CUM: 0,       A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2025-01-31', DISC: 'HVAC & Arch.', TOTAL: 1947.9,  P_WK: 0,      A_WK: 0,     D_WK: 0,     P_CUM: 0,       A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2025-02-28', DISC: 'HVAC & Arch.', TOTAL: 1947.9,  P_WK: 59.6,   A_WK: 0,     D_WK: 0,     P_CUM: 59.6,    A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2025-03-28', DISC: 'HVAC & Arch.', TOTAL: 1947.9,  P_WK: 152,    A_WK: 0,     D_WK: 0,     P_CUM: 211.6,   A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2025-04-25', DISC: 'HVAC & Arch.', TOTAL: 1947.9,  P_WK: 219,    A_WK: 0,     D_WK: 0,     P_CUM: 430.6,   A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2025-05-30', DISC: 'HVAC & Arch.', TOTAL: 1947.9,  P_WK: 111.4,  A_WK: 0,     D_WK: 0,     P_CUM: 542,     A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2025-06-27', DISC: 'HVAC & Arch.', TOTAL: 1947.9,  P_WK: 160.4,  A_WK: 0,     D_WK: 0,     P_CUM: 702.4,   A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2025-07-25', DISC: 'HVAC & Arch.', TOTAL: 1947.9,  P_WK: 56.2,   A_WK: 0,     D_WK: 0,     P_CUM: 758.6,   A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2025-08-29', DISC: 'HVAC & Arch.', TOTAL: 1947.9,  P_WK: 33.6,   A_WK: 0,     D_WK: 0,     P_CUM: 792.2,   A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2025-09-26', DISC: 'HVAC & Arch.', TOTAL: 1947.9,  P_WK: 74.2,   A_WK: 0,     D_WK: 0,     P_CUM: 866.4,   A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2025-10-31', DISC: 'HVAC & Arch.', TOTAL: 1947.9,  P_WK: 95.4,   A_WK: 0,     D_WK: 0,     P_CUM: 961.8,   A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2025-11-28', DISC: 'HVAC & Arch.', TOTAL: 1947.9,  P_WK: 122.6,  A_WK: 0,     D_WK: 0,     P_CUM: 1084.4,  A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2025-12-26', DISC: 'HVAC & Arch.', TOTAL: 1947.9,  P_WK: 64.7,   A_WK: 0,     D_WK: 0,     P_CUM: 1149.1,  A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2026-01-30', DISC: 'HVAC & Arch.', TOTAL: 1947.9,  P_WK: 82,     A_WK: 0,     D_WK: 0,     P_CUM: 1231.1,  A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2026-02-27', DISC: 'HVAC & Arch.', TOTAL: 1947.9,  P_WK: 92.7,   A_WK: 0,     D_WK: 0,     P_CUM: 1323.8,  A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2026-03-27', DISC: 'HVAC & Arch.', TOTAL: 1947.9,  P_WK: 24.2,   A_WK: 0,     D_WK: 0,     P_CUM: 1348,    A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2026-04-24', DISC: 'HVAC & Arch.', TOTAL: 1947.9,  P_WK: 24.4,   A_WK: 0,     D_WK: 0,     P_CUM: 1372.4,  A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2026-05-29', DISC: 'HVAC & Arch.', TOTAL: 1947.9,  P_WK: 54.6,   A_WK: 0,     D_WK: 0,     P_CUM: 1427,    A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2026-06-26', DISC: 'HVAC & Arch.', TOTAL: 1947.9,  P_WK: 91.6,   A_WK: 0,     D_WK: 0,     P_CUM: 1518.6,  A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2026-07-31', DISC: 'HVAC & Arch.', TOTAL: 1947.9,  P_WK: 157,    A_WK: 0,     D_WK: 0,     P_CUM: 1675.6,  A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2026-08-28', DISC: 'HVAC & Arch.', TOTAL: 1947.9,  P_WK: 65.5,   A_WK: 0,     D_WK: 0,     P_CUM: 1741.1,  A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2026-09-25', DISC: 'HVAC & Arch.', TOTAL: 1947.9,  P_WK: 49.1,   A_WK: 0,     D_WK: 0,     P_CUM: 1790.2,  A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2026-10-30', DISC: 'HVAC & Arch.', TOTAL: 1947.9,  P_WK: 2.8,    A_WK: 0,     D_WK: 0,     P_CUM: 1793,    A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2026-11-27', DISC: 'HVAC & Arch.', TOTAL: 1947.9,  P_WK: 57.5,   A_WK: 0,     D_WK: 0,     P_CUM: 1850.5,  A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2026-12-25', DISC: 'HVAC & Arch.', TOTAL: 1947.9,  P_WK: 97.2,   A_WK: 0,     D_WK: 0,     P_CUM: 1947.7,  A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2027-01-29', DISC: 'HVAC & Arch.', TOTAL: 1947.9,  P_WK: 0,      A_WK: 0,     D_WK: 0,     P_CUM: 1947.7,  A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2027-02-26', DISC: 'HVAC & Arch.', TOTAL: 1947.9,  P_WK: 0,      A_WK: 0,     D_WK: 0,     P_CUM: 1947.7,  A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2027-03-26', DISC: 'HVAC & Arch.', TOTAL: 1947.9,  P_WK: 0,      A_WK: 0,     D_WK: 0,     P_CUM: 1947.7,  A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2027-04-30', DISC: 'HVAC & Arch.', TOTAL: 1947.9,  P_WK: 0,      A_WK: 0,     D_WK: 0,     P_CUM: 1947.7,  A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2027-05-28', DISC: 'HVAC & Arch.', TOTAL: 1947.9,  P_WK: 0,      A_WK: 0,     D_WK: 0,     P_CUM: 1947.7,  A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2027-06-11', DISC: 'HVAC & Arch.', TOTAL: 1947.9,  P_WK: 0,      A_WK: 0,     D_WK: 0,     P_CUM: 1947.7,  A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2023-07-28', DISC: 'Painting',            TOTAL: 5507.2,  P_WK: 0,      A_WK: 0,     D_WK: 0,     P_CUM: 0,       A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2023-08-25', DISC: 'Painting',            TOTAL: 5507.2,  P_WK: 0,      A_WK: 0,     D_WK: 0,     P_CUM: 0,       A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2023-09-29', DISC: 'Painting',            TOTAL: 5507.2,  P_WK: 0,      A_WK: 0,     D_WK: 0,     P_CUM: 0,       A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2023-10-27', DISC: 'Painting',            TOTAL: 5507.2,  P_WK: 0,      A_WK: 0,     D_WK: 0,     P_CUM: 0,       A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2023-11-24', DISC: 'Painting',            TOTAL: 5507.2,  P_WK: 0,      A_WK: 0,     D_WK: 0,     P_CUM: 0,       A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2023-12-29', DISC: 'Painting',            TOTAL: 5507.2,  P_WK: 0,      A_WK: 0,     D_WK: 0,     P_CUM: 0,       A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2024-01-26', DISC: 'Painting',            TOTAL: 5507.2,  P_WK: 0,      A_WK: 0,     D_WK: 0,     P_CUM: 0,       A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2024-02-23', DISC: 'Painting',            TOTAL: 5507.2,  P_WK: 0,      A_WK: 0,     D_WK: 0,     P_CUM: 0,       A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2024-03-29', DISC: 'Painting',            TOTAL: 5507.2,  P_WK: 0,      A_WK: 0,     D_WK: 0,     P_CUM: 0,       A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2024-04-26', DISC: 'Painting',            TOTAL: 5507.2,  P_WK: 0,      A_WK: 0,     D_WK: 0,     P_CUM: 0,       A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2024-05-31', DISC: 'Painting',            TOTAL: 5507.2,  P_WK: 0,      A_WK: 0,     D_WK: 0,     P_CUM: 0,       A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2024-06-28', DISC: 'Painting',            TOTAL: 5507.2,  P_WK: 0,      A_WK: 0,     D_WK: 0,     P_CUM: 0,       A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2024-07-26', DISC: 'Painting',            TOTAL: 5507.2,  P_WK: 0,      A_WK: 0,     D_WK: 0,     P_CUM: 0,       A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2024-08-30', DISC: 'Painting',            TOTAL: 5507.2,  P_WK: 0,      A_WK: 0,     D_WK: 0,     P_CUM: 0,       A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2024-09-27', DISC: 'Painting',            TOTAL: 5507.2,  P_WK: 0,      A_WK: 0,     D_WK: 0,     P_CUM: 0,       A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2024-10-25', DISC: 'Painting',            TOTAL: 5507.2,  P_WK: 0,      A_WK: 0,     D_WK: 0,     P_CUM: 0,       A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2024-11-29', DISC: 'Painting',            TOTAL: 5507.2,  P_WK: 0,      A_WK: 0,     D_WK: 0,     P_CUM: 0,       A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2024-12-27', DISC: 'Painting',            TOTAL: 5507.2,  P_WK: 0,      A_WK: 0,     D_WK: 0,     P_CUM: 0,       A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2025-01-31', DISC: 'Painting',            TOTAL: 5507.2,  P_WK: 0,      A_WK: 0,     D_WK: 0,     P_CUM: 0,       A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2025-02-28', DISC: 'Painting',            TOTAL: 5507.2,  P_WK: 0,      A_WK: 0,     D_WK: 0,     P_CUM: 0,       A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2025-03-28', DISC: 'Painting',            TOTAL: 5507.2,  P_WK: 99.5,   A_WK: 0,     D_WK: 0,     P_CUM: 99.5,    A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2025-04-25', DISC: 'Painting',            TOTAL: 5507.2,  P_WK: 249.9,  A_WK: 0,     D_WK: 0,     P_CUM: 349.4,   A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2025-05-30', DISC: 'Painting',            TOTAL: 5507.2,  P_WK: 373.3,  A_WK: 0,     D_WK: 0,     P_CUM: 722.7,   A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2025-06-27', DISC: 'Painting',            TOTAL: 5507.2,  P_WK: 385.5,  A_WK: 0,     D_WK: 0,     P_CUM: 1108.2,  A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2025-07-25', DISC: 'Painting',            TOTAL: 5507.2,  P_WK: 444.3,  A_WK: 0,     D_WK: 0,     P_CUM: 1552.5,  A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2025-08-29', DISC: 'Painting',            TOTAL: 5507.2,  P_WK: 685.9,  A_WK: 0,     D_WK: 0,     P_CUM: 2238.4,  A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2025-09-26', DISC: 'Painting',            TOTAL: 5507.2,  P_WK: 539.6,  A_WK: 0,     D_WK: 0,     P_CUM: 2778,    A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2025-10-31', DISC: 'Painting',            TOTAL: 5507.2,  P_WK: 689.6,  A_WK: 0,     D_WK: 0,     P_CUM: 3467.6,  A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2025-11-28', DISC: 'Painting',            TOTAL: 5507.2,  P_WK: 527.8,  A_WK: 0,     D_WK: 0,     P_CUM: 3995.4,  A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2025-12-26', DISC: 'Painting',            TOTAL: 5507.2,  P_WK: 269.9,  A_WK: 0,     D_WK: 0,     P_CUM: 4265.3,  A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2026-01-30', DISC: 'Painting',            TOTAL: 5507.2,  P_WK: 242.9,  A_WK: 0,     D_WK: 0,     P_CUM: 4508.2,  A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2026-02-27', DISC: 'Painting',            TOTAL: 5507.2,  P_WK: 172,    A_WK: 0,     D_WK: 0,     P_CUM: 4680.2,  A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2026-03-27', DISC: 'Painting',            TOTAL: 5507.2,  P_WK: 36.7,   A_WK: 0,     D_WK: 0,     P_CUM: 4716.9,  A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2026-04-24', DISC: 'Painting',            TOTAL: 5507.2,  P_WK: 39.8,   A_WK: 0,     D_WK: 0,     P_CUM: 4756.7,  A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2026-05-29', DISC: 'Painting',            TOTAL: 5507.2,  P_WK: 47.6,   A_WK: 0,     D_WK: 0,     P_CUM: 4804.3,  A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2026-06-26', DISC: 'Painting',            TOTAL: 5507.2,  P_WK: 61.2,   A_WK: 0,     D_WK: 0,     P_CUM: 4865.5,  A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2026-07-31', DISC: 'Painting',            TOTAL: 5507.2,  P_WK: 61.2,   A_WK: 0,     D_WK: 0,     P_CUM: 4926.7,  A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2026-08-28', DISC: 'Painting',            TOTAL: 5507.2,  P_WK: 49,     A_WK: 0,     D_WK: 0,     P_CUM: 4975.7,  A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2026-09-25', DISC: 'Painting',            TOTAL: 5507.2,  P_WK: 55.1,   A_WK: 0,     D_WK: 0,     P_CUM: 5030.8,  A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2026-10-30', DISC: 'Painting',            TOTAL: 5507.2,  P_WK: 73.4,   A_WK: 0,     D_WK: 0,     P_CUM: 5104.2,  A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2026-11-27', DISC: 'Painting',            TOTAL: 5507.2,  P_WK: 61.2,   A_WK: 0,     D_WK: 0,     P_CUM: 5165.4,  A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2026-12-25', DISC: 'Painting',            TOTAL: 5507.2,  P_WK: 62.5,   A_WK: 0,     D_WK: 0,     P_CUM: 5227.9,  A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2027-01-29', DISC: 'Painting',            TOTAL: 5507.2,  P_WK: 176.5,  A_WK: 0,     D_WK: 0,     P_CUM: 5404.4,  A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2027-02-26', DISC: 'Painting',            TOTAL: 5507.2,  P_WK: 73.1,   A_WK: 0,     D_WK: 0,     P_CUM: 5477.5,  A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2027-03-26', DISC: 'Painting',            TOTAL: 5507.2,  P_WK: 29.8,   A_WK: 0,     D_WK: 0,     P_CUM: 5507.3,  A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2027-04-30', DISC: 'Painting',            TOTAL: 5507.2,  P_WK: 0,      A_WK: 0,     D_WK: 0,     P_CUM: 5507.3,  A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2027-05-28', DISC: 'Painting',            TOTAL: 5507.2,  P_WK: 0,      A_WK: 0,     D_WK: 0,     P_CUM: 5507.3,  A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2027-06-11', DISC: 'Painting',            TOTAL: 5507.2,  P_WK: 0,      A_WK: 0,     D_WK: 0,     P_CUM: 5507.3,  A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2023-07-28', DISC: 'Load-out',            TOTAL: 236,     P_WK: 0,      A_WK: 0,     D_WK: 0,     P_CUM: 0,       A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2023-08-25', DISC: 'Load-out',            TOTAL: 236,     P_WK: 0,      A_WK: 0,     D_WK: 0,     P_CUM: 0,       A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2023-09-29', DISC: 'Load-out',            TOTAL: 236,     P_WK: 0,      A_WK: 0,     D_WK: 0,     P_CUM: 0,       A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2023-10-27', DISC: 'Load-out',            TOTAL: 236,     P_WK: 0,      A_WK: 0,     D_WK: 0,     P_CUM: 0,       A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2023-11-24', DISC: 'Load-out',            TOTAL: 236,     P_WK: 0,      A_WK: 0,     D_WK: 0,     P_CUM: 0,       A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2023-12-29', DISC: 'Load-out',            TOTAL: 236,     P_WK: 0,      A_WK: 0,     D_WK: 0,     P_CUM: 0,       A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2024-01-26', DISC: 'Load-out',            TOTAL: 236,     P_WK: 0,      A_WK: 0,     D_WK: 0,     P_CUM: 0,       A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2024-02-23', DISC: 'Load-out',            TOTAL: 236,     P_WK: 0,      A_WK: 0,     D_WK: 0,     P_CUM: 0,       A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2024-03-29', DISC: 'Load-out',            TOTAL: 236,     P_WK: 0,      A_WK: 0,     D_WK: 0,     P_CUM: 0,       A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2024-04-26', DISC: 'Load-out',            TOTAL: 236,     P_WK: 0,      A_WK: 0,     D_WK: 0,     P_CUM: 0,       A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2024-05-31', DISC: 'Load-out',            TOTAL: 236,     P_WK: 0,      A_WK: 0,     D_WK: 0,     P_CUM: 0,       A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2024-06-28', DISC: 'Load-out',            TOTAL: 236,     P_WK: 0,      A_WK: 0,     D_WK: 0,     P_CUM: 0,       A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2024-07-26', DISC: 'Load-out',            TOTAL: 236,     P_WK: 0,      A_WK: 0,     D_WK: 0,     P_CUM: 0,       A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2024-08-30', DISC: 'Load-out',            TOTAL: 236,     P_WK: 0,      A_WK: 0,     D_WK: 0,     P_CUM: 0,       A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2024-09-27', DISC: 'Load-out',            TOTAL: 236,     P_WK: 0,      A_WK: 0,     D_WK: 0,     P_CUM: 0,       A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2024-10-25', DISC: 'Load-out',            TOTAL: 236,     P_WK: 0,      A_WK: 0,     D_WK: 0,     P_CUM: 0,       A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2024-11-29', DISC: 'Load-out',            TOTAL: 236,     P_WK: 0,      A_WK: 0,     D_WK: 0,     P_CUM: 0,       A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2024-12-27', DISC: 'Load-out',            TOTAL: 236,     P_WK: 0,      A_WK: 0,     D_WK: 0,     P_CUM: 0,       A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2025-01-31', DISC: 'Load-out',            TOTAL: 236,     P_WK: 0,      A_WK: 0,     D_WK: 0,     P_CUM: 0,       A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2025-02-28', DISC: 'Load-out',            TOTAL: 236,     P_WK: 0,      A_WK: 0,     D_WK: 0,     P_CUM: 0,       A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2025-03-28', DISC: 'Load-out',            TOTAL: 236,     P_WK: 0,      A_WK: 0,     D_WK: 0,     P_CUM: 0,       A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2025-04-25', DISC: 'Load-out',            TOTAL: 236,     P_WK: 0,      A_WK: 0,     D_WK: 0,     P_CUM: 0,       A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2025-05-30', DISC: 'Load-out',            TOTAL: 236,     P_WK: 0,      A_WK: 0,     D_WK: 0,     P_CUM: 0,       A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2025-06-27', DISC: 'Load-out',            TOTAL: 236,     P_WK: 0,      A_WK: 0,     D_WK: 0,     P_CUM: 0,       A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2025-07-25', DISC: 'Load-out',            TOTAL: 236,     P_WK: 0,      A_WK: 0,     D_WK: 0,     P_CUM: 0,       A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2025-08-29', DISC: 'Load-out',            TOTAL: 236,     P_WK: 0,      A_WK: 0,     D_WK: 0,     P_CUM: 0,       A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2025-09-26', DISC: 'Load-out',            TOTAL: 236,     P_WK: 0,      A_WK: 0,     D_WK: 0,     P_CUM: 0,       A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2025-10-31', DISC: 'Load-out',            TOTAL: 236,     P_WK: 0,      A_WK: 0,     D_WK: 0,     P_CUM: 0,       A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2025-11-28', DISC: 'Load-out',            TOTAL: 236,     P_WK: 0,      A_WK: 0,     D_WK: 0,     P_CUM: 0,       A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2025-12-26', DISC: 'Load-out',            TOTAL: 236,     P_WK: 0,      A_WK: 0,     D_WK: 0,     P_CUM: 0,       A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2026-01-30', DISC: 'Load-out',            TOTAL: 236,     P_WK: 0,      A_WK: 0,     D_WK: 0,     P_CUM: 0,       A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2026-02-27', DISC: 'Load-out',            TOTAL: 236,     P_WK: 0,      A_WK: 0,     D_WK: 0,     P_CUM: 0,       A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2026-03-27', DISC: 'Load-out',            TOTAL: 236,     P_WK: 12.5,   A_WK: 0,     D_WK: 0,     P_CUM: 12.5,    A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2026-04-24', DISC: 'Load-out',            TOTAL: 236,     P_WK: 11.5,   A_WK: 0,     D_WK: 0,     P_CUM: 24,      A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2026-05-29', DISC: 'Load-out',            TOTAL: 236,     P_WK: 8,      A_WK: 0,     D_WK: 0,     P_CUM: 32,      A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2026-06-26', DISC: 'Load-out',            TOTAL: 236,     P_WK: 0,      A_WK: 0,     D_WK: 0,     P_CUM: 32,      A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2026-07-31', DISC: 'Load-out',            TOTAL: 236,     P_WK: 0,      A_WK: 0,     D_WK: 0,     P_CUM: 32,      A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2026-08-28', DISC: 'Load-out',            TOTAL: 236,     P_WK: 0,      A_WK: 0,     D_WK: 0,     P_CUM: 32,      A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2026-09-25', DISC: 'Load-out',            TOTAL: 236,     P_WK: 0,      A_WK: 0,     D_WK: 0,     P_CUM: 32,      A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2026-10-30', DISC: 'Load-out',            TOTAL: 236,     P_WK: 0,      A_WK: 0,     D_WK: 0,     P_CUM: 32,      A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2026-11-27', DISC: 'Load-out',            TOTAL: 236,     P_WK: 0,      A_WK: 0,     D_WK: 0,     P_CUM: 32,      A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2026-12-25', DISC: 'Load-out',            TOTAL: 236,     P_WK: 0,      A_WK: 0,     D_WK: 0,     P_CUM: 32,      A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2027-01-29', DISC: 'Load-out',            TOTAL: 236,     P_WK: 0,      A_WK: 0,     D_WK: 0,     P_CUM: 32,      A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2027-02-26', DISC: 'Load-out',            TOTAL: 236,     P_WK: 0,      A_WK: 0,     D_WK: 0,     P_CUM: 32,      A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2027-03-26', DISC: 'Load-out',            TOTAL: 236,     P_WK: 102.6,  A_WK: 0,     D_WK: 0,     P_CUM: 134.6,   A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2027-04-30', DISC: 'Load-out',            TOTAL: 236,     P_WK: 101.4,  A_WK: 0,     D_WK: 0,     P_CUM: 236,     A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2027-05-28', DISC: 'Load-out',            TOTAL: 236,     P_WK: 0,      A_WK: 0,     D_WK: 0,     P_CUM: 236,     A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2027-06-11', DISC: 'Load-out',            TOTAL: 236,     P_WK: 0,      A_WK: 0,     D_WK: 0,     P_CUM: 236,     A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2023-07-28', DISC: 'Others',              TOTAL: 183.2,   P_WK: 0,      A_WK: 0,     D_WK: 0,     P_CUM: 0,       A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2023-08-25', DISC: 'Others',              TOTAL: 183.2,   P_WK: 0,      A_WK: 0,     D_WK: 0,     P_CUM: 0,       A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2023-09-29', DISC: 'Others',              TOTAL: 183.2,   P_WK: 0,      A_WK: 0,     D_WK: 0,     P_CUM: 0,       A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2023-10-27', DISC: 'Others',              TOTAL: 183.2,   P_WK: 0,      A_WK: 0,     D_WK: 0,     P_CUM: 0,       A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2023-11-24', DISC: 'Others',              TOTAL: 183.2,   P_WK: 0,      A_WK: 0,     D_WK: 0,     P_CUM: 0,       A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2023-12-29', DISC: 'Others',              TOTAL: 183.2,   P_WK: 0,      A_WK: 0,     D_WK: 0,     P_CUM: 0,       A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2024-01-26', DISC: 'Others',              TOTAL: 183.2,   P_WK: 0,      A_WK: 0,     D_WK: 0,     P_CUM: 0,       A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2024-02-23', DISC: 'Others',              TOTAL: 183.2,   P_WK: 0,      A_WK: 0,     D_WK: 0,     P_CUM: 0,       A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2024-03-29', DISC: 'Others',              TOTAL: 183.2,   P_WK: 0,      A_WK: 0,     D_WK: 0,     P_CUM: 0,       A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2024-04-26', DISC: 'Others',              TOTAL: 183.2,   P_WK: 0,      A_WK: 0,     D_WK: 0,     P_CUM: 0,       A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2024-05-31', DISC: 'Others',              TOTAL: 183.2,   P_WK: 0,      A_WK: 0,     D_WK: 0,     P_CUM: 0,       A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2024-06-28', DISC: 'Others',              TOTAL: 183.2,   P_WK: 0,      A_WK: 0,     D_WK: 0,     P_CUM: 0,       A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2024-07-26', DISC: 'Others',              TOTAL: 183.2,   P_WK: 0,      A_WK: 0,     D_WK: 0,     P_CUM: 0,       A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2024-08-30', DISC: 'Others',              TOTAL: 183.2,   P_WK: 0,      A_WK: 0,     D_WK: 0,     P_CUM: 0,       A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2024-09-27', DISC: 'Others',              TOTAL: 183.2,   P_WK: 0,      A_WK: 0,     D_WK: 0,     P_CUM: 0,       A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2024-10-25', DISC: 'Others',              TOTAL: 183.2,   P_WK: 0,      A_WK: 0,     D_WK: 0,     P_CUM: 0,       A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2024-11-29', DISC: 'Others',              TOTAL: 183.2,   P_WK: 0,      A_WK: 0,     D_WK: 0,     P_CUM: 0,       A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2024-12-27', DISC: 'Others',              TOTAL: 183.2,   P_WK: 0,      A_WK: 0,     D_WK: 0,     P_CUM: 0,       A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2025-01-31', DISC: 'Others',              TOTAL: 183.2,   P_WK: 0,      A_WK: 0,     D_WK: 0,     P_CUM: 0,       A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2025-02-28', DISC: 'Others',              TOTAL: 183.2,   P_WK: 0,      A_WK: 0,     D_WK: 0,     P_CUM: 0,       A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2025-03-28', DISC: 'Others',              TOTAL: 183.2,   P_WK: 0,      A_WK: 0,     D_WK: 0,     P_CUM: 0,       A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2025-04-25', DISC: 'Others',              TOTAL: 183.2,   P_WK: 0,      A_WK: 0,     D_WK: 0,     P_CUM: 0,       A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2025-05-30', DISC: 'Others',              TOTAL: 183.2,   P_WK: 0,      A_WK: 0,     D_WK: 0,     P_CUM: 0,       A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2025-06-27', DISC: 'Others',              TOTAL: 183.2,   P_WK: 0,      A_WK: 0,     D_WK: 0,     P_CUM: 0,       A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2025-07-25', DISC: 'Others',              TOTAL: 183.2,   P_WK: 0,      A_WK: 0,     D_WK: 0,     P_CUM: 0,       A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2025-08-29', DISC: 'Others',              TOTAL: 183.2,   P_WK: 0,      A_WK: 0,     D_WK: 0,     P_CUM: 0,       A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2025-09-26', DISC: 'Others',              TOTAL: 183.2,   P_WK: 0,      A_WK: 0,     D_WK: 0,     P_CUM: 0,       A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2025-10-31', DISC: 'Others',              TOTAL: 183.2,   P_WK: 0,      A_WK: 0,     D_WK: 0,     P_CUM: 0,       A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2025-11-28', DISC: 'Others',              TOTAL: 183.2,   P_WK: 0,      A_WK: 0,     D_WK: 0,     P_CUM: 0,       A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2025-12-26', DISC: 'Others',              TOTAL: 183.2,   P_WK: 0,      A_WK: 0,     D_WK: 0,     P_CUM: 0,       A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2026-01-30', DISC: 'Others',              TOTAL: 183.2,   P_WK: 0,      A_WK: 0,     D_WK: 0,     P_CUM: 0,       A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2026-02-27', DISC: 'Others',              TOTAL: 183.2,   P_WK: 0,      A_WK: 0,     D_WK: 0,     P_CUM: 0,       A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2026-03-27', DISC: 'Others',              TOTAL: 183.2,   P_WK: 0,      A_WK: 0,     D_WK: 0,     P_CUM: 0,       A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2026-04-24', DISC: 'Others',              TOTAL: 183.2,   P_WK: 9.6,    A_WK: 0,     D_WK: 0,     P_CUM: 9.6,     A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2026-05-29', DISC: 'Others',              TOTAL: 183.2,   P_WK: 29.5,   A_WK: 0,     D_WK: 0,     P_CUM: 39.1,    A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2026-06-26', DISC: 'Others',              TOTAL: 183.2,   P_WK: 113.6,  A_WK: 0,     D_WK: 0,     P_CUM: 152.7,   A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2026-07-31', DISC: 'Others',              TOTAL: 183.2,   P_WK: 14.7,   A_WK: 0,     D_WK: 0,     P_CUM: 167.4,   A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2026-08-28', DISC: 'Others',              TOTAL: 183.2,   P_WK: 15.9,   A_WK: 0,     D_WK: 0,     P_CUM: 183.3,   A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2026-09-25', DISC: 'Others',              TOTAL: 183.2,   P_WK: 0,      A_WK: 0,     D_WK: 0,     P_CUM: 183.3,   A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2026-10-30', DISC: 'Others',              TOTAL: 183.2,   P_WK: 0,      A_WK: 0,     D_WK: 0,     P_CUM: 183.3,   A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2026-11-27', DISC: 'Others',              TOTAL: 183.2,   P_WK: 0,      A_WK: 0,     D_WK: 0,     P_CUM: 183.3,   A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2026-12-25', DISC: 'Others',              TOTAL: 183.2,   P_WK: 0,      A_WK: 0,     D_WK: 0,     P_CUM: 183.3,   A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2027-01-29', DISC: 'Others',              TOTAL: 183.2,   P_WK: 0,      A_WK: 0,     D_WK: 0,     P_CUM: 183.3,   A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2027-02-26', DISC: 'Others',              TOTAL: 183.2,   P_WK: 0,      A_WK: 0,     D_WK: 0,     P_CUM: 183.3,   A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2027-03-26', DISC: 'Others',              TOTAL: 183.2,   P_WK: 0,      A_WK: 0,     D_WK: 0,     P_CUM: 183.3,   A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2027-04-30', DISC: 'Others',              TOTAL: 183.2,   P_WK: 0,      A_WK: 0,     D_WK: 0,     P_CUM: 183.3,   A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2027-05-28', DISC: 'Others',              TOTAL: 183.2,   P_WK: 0,      A_WK: 0,     D_WK: 0,     P_CUM: 183.3,   A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2027-06-11', DISC: 'Others',              TOTAL: 183.2,   P_WK: 0,      A_WK: 0,     D_WK: 0,     P_CUM: 183.3,   A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2023-07-28', DISC: 'OVERALL',             TOTAL: 68489.9, P_WK: 0,      A_WK: 0,     D_WK: 0,     P_CUM: 0,       A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2023-08-25', DISC: 'OVERALL',             TOTAL: 68489.9, P_WK: 0,      A_WK: 0,     D_WK: 0,     P_CUM: 0,       A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2023-09-29', DISC: 'OVERALL',             TOTAL: 68489.9, P_WK: 0,      A_WK: 0,     D_WK: 0,     P_CUM: 0,       A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2023-10-27', DISC: 'OVERALL',             TOTAL: 68489.9, P_WK: 0,      A_WK: 0,     D_WK: 0,     P_CUM: 0,       A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2023-11-24', DISC: 'OVERALL',             TOTAL: 68489.9, P_WK: 0,      A_WK: 0,     D_WK: 0,     P_CUM: 0,       A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2023-12-29', DISC: 'OVERALL',             TOTAL: 68489.9, P_WK: 0,      A_WK: 0,     D_WK: 0,     P_CUM: 0,       A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2024-01-26', DISC: 'OVERALL',             TOTAL: 68489.9, P_WK: 0,      A_WK: 0,     D_WK: 0,     P_CUM: 0,       A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2024-02-23', DISC: 'OVERALL',             TOTAL: 68489.9, P_WK: 0,      A_WK: 0,     D_WK: 0,     P_CUM: 0,       A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2024-03-29', DISC: 'OVERALL',             TOTAL: 68489.9, P_WK: 0,      A_WK: 0,     D_WK: 0,     P_CUM: 0,       A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2024-04-26', DISC: 'OVERALL',             TOTAL: 68489.9, P_WK: 0,      A_WK: 0,     D_WK: 0,     P_CUM: 0,       A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2024-05-31', DISC: 'OVERALL',             TOTAL: 68489.9, P_WK: 0,      A_WK: 0,     D_WK: 0,     P_CUM: 0,       A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2024-06-28', DISC: 'OVERALL',             TOTAL: 68489.9, P_WK: 0,      A_WK: 0,     D_WK: 0,     P_CUM: 0,       A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2024-07-26', DISC: 'OVERALL',             TOTAL: 68489.9, P_WK: 0,      A_WK: 0,     D_WK: 0,     P_CUM: 0,       A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2024-08-30', DISC: 'OVERALL',             TOTAL: 68489.9, P_WK: 0,      A_WK: 0,     D_WK: 0,     P_CUM: 0,       A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2024-09-27', DISC: 'OVERALL',             TOTAL: 68489.9, P_WK: 0,      A_WK: 0,     D_WK: 0,     P_CUM: 0,       A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2024-10-25', DISC: 'OVERALL',             TOTAL: 68489.9, P_WK: 0,      A_WK: 0,     D_WK: 0,     P_CUM: 0,       A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2024-11-29', DISC: 'OVERALL',             TOTAL: 68489.9, P_WK: 199.1,  A_WK: 133.5, D_WK: -65.6, P_CUM: 199.1,   A_CUM: 133.5, D_CUM: -65.6, },
      { CDATE: '2024-12-27', DISC: 'OVERALL',             TOTAL: 68489.9, P_WK: 344.2,  A_WK: 0,     D_WK: 0,     P_CUM: 543.3,   A_CUM: 133.5, D_CUM: 0, },
      { CDATE: '2025-01-31', DISC: 'OVERALL',             TOTAL: 68489.9, P_WK: 495,    A_WK: 0,     D_WK: 0,     P_CUM: 1038.3,  A_CUM: 133.5, D_CUM: 0, },
      { CDATE: '2025-02-28', DISC: 'OVERALL',             TOTAL: 68489.9, P_WK: 1001.9, A_WK: 0,     D_WK: 0,     P_CUM: 2040.2,  A_CUM: 133.5, D_CUM: 0, },
      { CDATE: '2025-03-28', DISC: 'OVERALL',             TOTAL: 68489.9, P_WK: 1816.2, A_WK: 0,     D_WK: 0,     P_CUM: 3856.4,  A_CUM: 133.5, D_CUM: 0, },
      { CDATE: '2025-04-25', DISC: 'OVERALL',             TOTAL: 68489.9, P_WK: 2310.3, A_WK: 0,     D_WK: 0,     P_CUM: 6166.7,  A_CUM: 133.5, D_CUM: 0, },
      { CDATE: '2025-05-30', DISC: 'OVERALL',             TOTAL: 68489.9, P_WK: 3713.5, A_WK: 0,     D_WK: 0,     P_CUM: 9880.2,  A_CUM: 133.5, D_CUM: 0, },
      { CDATE: '2025-06-27', DISC: 'OVERALL',             TOTAL: 68489.9, P_WK: 4438.6, A_WK: 0,     D_WK: 0,     P_CUM: 14318.8, A_CUM: 133.5, D_CUM: 0, },
      { CDATE: '2025-07-25', DISC: 'OVERALL',             TOTAL: 68489.9, P_WK: 5738,   A_WK: 0,     D_WK: 0,     P_CUM: 20056.8, A_CUM: 133.5, D_CUM: 0, },
      { CDATE: '2025-08-29', DISC: 'OVERALL',             TOTAL: 68489.9, P_WK: 3949.1, A_WK: 0,     D_WK: 0,     P_CUM: 24005.9, A_CUM: 133.5, D_CUM: 0, },
      { CDATE: '2025-09-26', DISC: 'OVERALL',             TOTAL: 68489.9, P_WK: 5489.5, A_WK: 0,     D_WK: 0,     P_CUM: 29495.4, A_CUM: 133.5, D_CUM: 0, },
      { CDATE: '2025-10-31', DISC: 'OVERALL',             TOTAL: 68489.9, P_WK: 4519.3, A_WK: 0,     D_WK: 0,     P_CUM: 34014.7, A_CUM: 133.5, D_CUM: 0, },
      { CDATE: '2025-11-28', DISC: 'OVERALL',             TOTAL: 68489.9, P_WK: 5158.1, A_WK: 0,     D_WK: 0,     P_CUM: 39172.8, A_CUM: 133.5, D_CUM: 0, },
      { CDATE: '2025-12-26', DISC: 'OVERALL',             TOTAL: 68489.9, P_WK: 3922.6, A_WK: 0,     D_WK: 0,     P_CUM: 43095.4, A_CUM: 133.5, D_CUM: 0, },
      { CDATE: '2026-01-30', DISC: 'OVERALL',             TOTAL: 68489.9, P_WK: 4638.8, A_WK: 0,     D_WK: 0,     P_CUM: 47734.2, A_CUM: 133.5, D_CUM: 0, },
      { CDATE: '2026-02-27', DISC: 'OVERALL',             TOTAL: 68489.9, P_WK: 2224.3, A_WK: 0,     D_WK: 0,     P_CUM: 49958.5, A_CUM: 133.5, D_CUM: 0, },
      { CDATE: '2026-03-27', DISC: 'OVERALL',             TOTAL: 68489.9, P_WK: 1474.9, A_WK: 0,     D_WK: 0,     P_CUM: 51433.4, A_CUM: 133.5, D_CUM: 0, },
      { CDATE: '2026-04-24', DISC: 'OVERALL',             TOTAL: 68489.9, P_WK: 953.3,  A_WK: 0,     D_WK: 0,     P_CUM: 52386.7, A_CUM: 133.5, D_CUM: 0, },
      { CDATE: '2026-05-29', DISC: 'OVERALL',             TOTAL: 68489.9, P_WK: 1278.8, A_WK: 0,     D_WK: 0,     P_CUM: 53665.5, A_CUM: 133.5, D_CUM: 0, },
      { CDATE: '2026-06-26', DISC: 'OVERALL',             TOTAL: 68489.9, P_WK: 1866.3, A_WK: 0,     D_WK: 0,     P_CUM: 55531.8, A_CUM: 133.5, D_CUM: 0, },
      { CDATE: '2026-07-31', DISC: 'OVERALL',             TOTAL: 68489.9, P_WK: 2533.5, A_WK: 0,     D_WK: 0,     P_CUM: 58065.3, A_CUM: 133.5, D_CUM: 0, },
      { CDATE: '2026-08-28', DISC: 'OVERALL',             TOTAL: 68489.9, P_WK: 2339.7, A_WK: 0,     D_WK: 0,     P_CUM: 60405,   A_CUM: 133.5, D_CUM: 0, },
      { CDATE: '2026-09-25', DISC: 'OVERALL',             TOTAL: 68489.9, P_WK: 2373.7, A_WK: 0,     D_WK: 0,     P_CUM: 62778.7, A_CUM: 133.5, D_CUM: 0, },
      { CDATE: '2026-10-30', DISC: 'OVERALL',             TOTAL: 68489.9, P_WK: 2264.5, A_WK: 0,     D_WK: 0,     P_CUM: 65043.2, A_CUM: 133.5, D_CUM: 0, },
      { CDATE: '2026-11-27', DISC: 'OVERALL',             TOTAL: 68489.9, P_WK: 1423.3, A_WK: 0,     D_WK: 0,     P_CUM: 66466.5, A_CUM: 133.5, D_CUM: 0, },
      { CDATE: '2026-12-25', DISC: 'OVERALL',             TOTAL: 68489.9, P_WK: 950.9,  A_WK: 0,     D_WK: 0,     P_CUM: 67417.4, A_CUM: 133.5, D_CUM: 0, },
      { CDATE: '2027-01-29', DISC: 'OVERALL',             TOTAL: 68489.9, P_WK: 315.4,  A_WK: 0,     D_WK: 0,     P_CUM: 67732.8, A_CUM: 133.5, D_CUM: 0, },
      { CDATE: '2027-02-26', DISC: 'OVERALL',             TOTAL: 68489.9, P_WK: 408.2,  A_WK: 0,     D_WK: 0,     P_CUM: 68141,   A_CUM: 133.5, D_CUM: 0, },
      { CDATE: '2027-03-26', DISC: 'OVERALL',             TOTAL: 68489.9, P_WK: 247.4,  A_WK: 0,     D_WK: 0,     P_CUM: 68388.4, A_CUM: 133.5, D_CUM: 0, },
      { CDATE: '2027-04-30', DISC: 'OVERALL',             TOTAL: 68489.9, P_WK: 101.4,  A_WK: 0,     D_WK: 0,     P_CUM: 68489.8, A_CUM: 133.5, D_CUM: 0, },
      { CDATE: '2027-05-28', DISC: 'OVERALL',             TOTAL: 68489.9, P_WK: 0,      A_WK: 0,     D_WK: 0,     P_CUM: 68489.8, A_CUM: 133.5, D_CUM: 0, },
      { CDATE: '2027-06-11', DISC: 'OVERALL',             TOTAL: 68489.9, P_WK: 0,      A_WK: 0,     D_WK: 0,     P_CUM: 68489.8, A_CUM: 133.5, D_CUM: 0, },
      
    ],


    Queries: {
      SQL1: [
        {
          // Timeline
          CUTOFF      : '2024-11-30',
          START_DATE  : '2023-07-28',
          END_DATE    : '2027-06-11',
        },
      ],

    },


}