import __C from '../../../../../../includes/primitives/_constant_'

export default {
    Canvas: {
      CanvasWidth: 1400,
      CanvasHeight: 460,
      CanvasBorderWeight: .5,
      CanvasBorderColor: '#E0E0DF',
      CanvasFillColor: '#fff',
      CanvasOpacity: 1,

      CanvasChartX: 0,
      CanvasChartY: 0,
      CanvasChartWidth: 400,
      CanvasChartHeight: 200,
      CanvasChartBorderWeight: 0,
      CanvasChartBorderColor: '#757575',
      CanvasChartFillColor: '#757575',
      CanvasChartOpacity: 0,
      CanvasChartSpace: 0,

      CanvasFontFamily: 'roboto',
    },
    
    Text: {
      Text: [],
      Filter: [],
      Sql: []
    },

    DataItems: [
      { CDATE: '2024-06-07', TOTAL: 147979.2, P_WK: 437.4,  A_WK: 491.3,  D_WK: 54,   P_CUM: 18155.1,  A_CUM: 17092.4, D_CUM: -1063, },
      { CDATE: '2024-06-14', TOTAL: 147979.2, P_WK: 447.4,  A_WK: 511.6,  D_WK: 64,   P_CUM: 18602.5,  A_CUM: 17604,   D_CUM: -999, },
      { CDATE: '2024-06-21', TOTAL: 147979.2, P_WK: 447.4,  A_WK: 538.5,  D_WK: 91,   P_CUM: 19049.9,  A_CUM: 18142.5, D_CUM: -907, },
      { CDATE: '2024-06-28', TOTAL: 147979.2, P_WK: 440.9,  A_WK: 540.7,  D_WK: 100,  P_CUM: 19490.8,  A_CUM: 18683.2, D_CUM: -808, },
      { CDATE: '2024-07-05', TOTAL: 147979.2, P_WK: 430.4,  A_WK: 471.8,  D_WK: 41,   P_CUM: 19921.2,  A_CUM: 19155,   D_CUM: -766, },
      { CDATE: '2024-07-12', TOTAL: 147979.2, P_WK: 484,    A_WK: 511.1,  D_WK: 27,   P_CUM: 20405.2,  A_CUM: 19666.1, D_CUM: -739, },
      { CDATE: '2024-07-19', TOTAL: 147979.2, P_WK: 483.2,  A_WK: 548.7,  D_WK: 66,   P_CUM: 20888.4,  A_CUM: 20214.8, D_CUM: -674, },
      { CDATE: '2024-07-26', TOTAL: 147979.2, P_WK: 474.8,  A_WK: 551.5,  D_WK: 77,   P_CUM: 21363.2,  A_CUM: 20766.3, D_CUM: -597, },
      { CDATE: '2024-08-02', TOTAL: 147979.2, P_WK: 463.2,  A_WK: 379.3,  D_WK: -84,  P_CUM: 21826.4,  A_CUM: 21145.6, D_CUM: -681, },
      { CDATE: '2024-08-09', TOTAL: 147979.2, P_WK: 314.1,  A_WK: 480.1,  D_WK: 166,  P_CUM: 22140.5,  A_CUM: 21625.7, D_CUM: -515, },
      { CDATE: '2024-08-16', TOTAL: 147979.2, P_WK: 464.2,  A_WK: 525.2,  D_WK: 61,   P_CUM: 22604.7,  A_CUM: 22150.9, D_CUM: -454, },
      { CDATE: '2024-08-23', TOTAL: 147979.2, P_WK: 464.1,  A_WK: 554.6,  D_WK: 91,   P_CUM: 23068.8,  A_CUM: 22705.5, D_CUM: -363, },
      { CDATE: '2024-08-30', TOTAL: 147979.2, P_WK: 451.8,  A_WK: 563.4,  D_WK: 112,  P_CUM: 23520.6,  A_CUM: 23268.9, D_CUM: -252, },
      { CDATE: '2024-09-06', TOTAL: 147979.2, P_WK: 451.6,  A_WK: 526.8,  D_WK: 75,   P_CUM: 23972.2,  A_CUM: 23795.7, D_CUM: -177, },
      { CDATE: '2024-09-13', TOTAL: 147979.2, P_WK: 450.4,  A_WK: 554.4,  D_WK: 104,  P_CUM: 24422.6,  A_CUM: 24350.1, D_CUM: -73, },
      { CDATE: '2024-09-20', TOTAL: 147979.2, P_WK: 438.3,  A_WK: 533,    D_WK: 95,   P_CUM: 24860.9,  A_CUM: 24883.1, D_CUM: 22, },
      { CDATE: '2024-09-27', TOTAL: 147979.2, P_WK: 438.7,  A_WK: 553.4,  D_WK: 115,  P_CUM: 25299.6,  A_CUM: 25436.5, D_CUM: 137, },
      { CDATE: '2024-10-04', TOTAL: 147979.2, P_WK: 415.6,  A_WK: 545.1,  D_WK: 129,  P_CUM: 25715.2,  A_CUM: 25981.6, D_CUM: 266, },
      { CDATE: '2024-10-11', TOTAL: 147979.2, P_WK: 414.6,  A_WK: 536.3,  D_WK: 122,  P_CUM: 26129.8,  A_CUM: 26517.9, D_CUM: 388, },
      { CDATE: '2024-10-18', TOTAL: 147979.2, P_WK: 414.4,  A_WK: 518.4,  D_WK: 104,  P_CUM: 26544.2,  A_CUM: 27036.3, D_CUM: 492, },
      { CDATE: '2024-10-25', TOTAL: 147979.2, P_WK: 397.2,  A_WK: 546.1,  D_WK: 149,  P_CUM: 26941.4,  A_CUM: 27582.4, D_CUM: 641, },
      { CDATE: '2024-11-01', TOTAL: 147979.2, P_WK: 400.1,  A_WK: 587.8,  D_WK: 188,  P_CUM: 27341.5,  A_CUM: 28170.2, D_CUM: 829, },
      { CDATE: '2024-11-08', TOTAL: 147979.2, P_WK: 413.4,  A_WK: 565.7,  D_WK: 152,  P_CUM: 27754.9,  A_CUM: 28735.9, D_CUM: 981, },
      { CDATE: '2024-11-15', TOTAL: 147979.2, P_WK: 586.7,  A_WK: 625.9,  D_WK: 39,   P_CUM: 28341.6,  A_CUM: 29361.8, D_CUM: 1020, },
      { CDATE: '2024-11-22', TOTAL: 147979.2, P_WK: 641.5,  A_WK: 722.8,  D_WK: 81,   P_CUM: 28983.1,  A_CUM: 30084.6, D_CUM: 1101, },
      { CDATE: '2024-11-29', TOTAL: 147979.2, P_WK: 626.4,  A_WK: 733,    D_WK: 107,  P_CUM: 29609.5,  A_CUM: 30817.6, D_CUM: 1208, },
      { CDATE: '2024-12-06', TOTAL: 147979.2, P_WK: 709.8,  A_WK: 969.5,  D_WK: 260,  P_CUM: 30319.3,  A_CUM: 31787.1, D_CUM: 1468, },
      { CDATE: '2024-12-13', TOTAL: 147979.2, P_WK: 802.7,  A_WK: 1091.7, D_WK: 289,  P_CUM: 31122,    A_CUM: 32878.8, D_CUM: 1757, },
      { CDATE: '2024-12-20', TOTAL: 147979.2, P_WK: 902.1,  A_WK: 1136.3, D_WK: 234,  P_CUM: 32024.1,  A_CUM: 34015.1, D_CUM: 1991, },
      { CDATE: '2024-12-27', TOTAL: 147979.2, P_WK: 859.5,  A_WK: 1066,   D_WK: 207,  P_CUM: 32883.6,  A_CUM: 35081.1, D_CUM: 2197, },
      { CDATE: '2025-01-03', TOTAL: 147979.2, P_WK: 925.7,  A_WK: 0,      D_WK: 0,    P_CUM: 33809.3,  A_CUM: 35081.1, D_CUM: 0, },
      { CDATE: '2025-01-10', TOTAL: 147979.2, P_WK: 1198.5, A_WK: 0,      D_WK: 0,    P_CUM: 35007.8,  A_CUM: 35081.1, D_CUM: 0, },
      { CDATE: '2025-01-17', TOTAL: 147979.2, P_WK: 1330.8, A_WK: 0,      D_WK: 0,    P_CUM: 36338.6,  A_CUM: 35081.1, D_CUM: 0, },
      { CDATE: '2025-01-24', TOTAL: 147979.2, P_WK: 1426.7, A_WK: 0,      D_WK: 0,    P_CUM: 37765.3,  A_CUM: 35081.1, D_CUM: 0, },
      { CDATE: '2025-01-31', TOTAL: 147979.2, P_WK: 761,    A_WK: 0,      D_WK: 0,    P_CUM: 38526.3,  A_CUM: 35081.1, D_CUM: 0, },
      { CDATE: '2025-02-07', TOTAL: 147979.2, P_WK: 1078.9, A_WK: 0,      D_WK: 0,    P_CUM: 39605.2,  A_CUM: 35081.1, D_CUM: 0, },
      { CDATE: '2025-02-14', TOTAL: 147979.2, P_WK: 1560.8, A_WK: 0,      D_WK: 0,    P_CUM: 41166,    A_CUM: 35081.1, D_CUM: 0, },
      { CDATE: '2025-02-21', TOTAL: 147979.2, P_WK: 1581.7, A_WK: 0,      D_WK: 0,    P_CUM: 42747.7,  A_CUM: 35081.1, D_CUM: 0, },
      { CDATE: '2025-02-28', TOTAL: 147979.2, P_WK: 1575.3, A_WK: 0,      D_WK: 0,    P_CUM: 44323,    A_CUM: 35081.1, D_CUM: 0, },
      { CDATE: '2025-03-07', TOTAL: 147979.2, P_WK: 1571.2, A_WK: 0,      D_WK: 0,    P_CUM: 45894.2,  A_CUM: 35081.1, D_CUM: 0, },
      { CDATE: '2025-03-14', TOTAL: 147979.2, P_WK: 1506.8, A_WK: 0,      D_WK: 0,    P_CUM: 47401,    A_CUM: 35081.1, D_CUM: 0, },
      { CDATE: '2025-03-21', TOTAL: 147979.2, P_WK: 1590.2, A_WK: 0,      D_WK: 0,    P_CUM: 48991.2,  A_CUM: 35081.1, D_CUM: 0, },
      { CDATE: '2025-03-28', TOTAL: 147979.2, P_WK: 1713.7, A_WK: 0,      D_WK: 0,    P_CUM: 50704.9,  A_CUM: 35081.1, D_CUM: 0, },
      { CDATE: '2025-04-04', TOTAL: 147979.2, P_WK: 1575.9, A_WK: 0,      D_WK: 0,    P_CUM: 52280.8,  A_CUM: 35081.1, D_CUM: 0, },
      { CDATE: '2025-04-11', TOTAL: 147979.2, P_WK: 1982.1, A_WK: 0,      D_WK: 0,    P_CUM: 54262.9,  A_CUM: 35081.1, D_CUM: 0, },
      { CDATE: '2025-04-18', TOTAL: 147979.2, P_WK: 2096.8, A_WK: 0,      D_WK: 0,    P_CUM: 56359.7,  A_CUM: 35081.1, D_CUM: 0, },
      { CDATE: '2025-04-25', TOTAL: 147979.2, P_WK: 1862.6, A_WK: 0,      D_WK: 0,    P_CUM: 58222.3,  A_CUM: 35081.1, D_CUM: 0, },
      { CDATE: '2025-05-02', TOTAL: 147979.2, P_WK: 2182.3, A_WK: 0,      D_WK: 0,    P_CUM: 60404.6,  A_CUM: 35081.1, D_CUM: 0, },
      { CDATE: '2025-05-09', TOTAL: 147979.2, P_WK: 1954.4, A_WK: 0,      D_WK: 0,    P_CUM: 62359,    A_CUM: 35081.1, D_CUM: 0, },
      { CDATE: '2025-05-16', TOTAL: 147979.2, P_WK: 2176.3, A_WK: 0,      D_WK: 0,    P_CUM: 64535.3,  A_CUM: 35081.1, D_CUM: 0, },
      { CDATE: '2025-05-23', TOTAL: 147979.2, P_WK: 2085.1, A_WK: 0,      D_WK: 0,    P_CUM: 66620.4,  A_CUM: 35081.1, D_CUM: 0, },
      { CDATE: '2025-05-30', TOTAL: 147979.2, P_WK: 2050.8, A_WK: 0,      D_WK: 0,    P_CUM: 68671.2,  A_CUM: 35081.1, D_CUM: 0, },
    ],


    Queries: {
      SQL1: [
        { CDATE: '2024-12-27', PHASE: 'Engineering',   GROUP: 'Detail Eng',     SUBCONT: 'Exmar',        TOTAL: 5228,   P_WK: 29,  A_WK: 29,   D_WK: 0,   P_CUM: 5031,  A_CUM: 5026,  D_CUM: -6, },
        { CDATE: '2024-12-27', PHASE: 'Engineering',   GROUP: 'Detail Eng',     SUBCONT: 'HHI',          TOTAL: 9461,   P_WK: 113, A_WK: 114,  D_WK: 2,   P_CUM: 8016,  A_CUM: 8249,  D_CUM: 233, },
        { CDATE: '2024-12-27', PHASE: 'Engineering',   GROUP: 'Detail Eng',     SUBCONT: 'WOOD',         TOTAL: 17673,  P_WK: 146, A_WK: 218,  D_WK: 72,  P_CUM: 14921, A_CUM: 15583, D_CUM: 663, },
        { CDATE: '2024-12-27', PHASE: 'Engineering',   GROUP: 'Detail Eng',     SUBCONT: 'OVERALL',      TOTAL: 32362,  P_WK: 288, A_WK: 361,  D_WK: 73,  P_CUM: 27968, A_CUM: 28858, D_CUM: 890, },
        { CDATE: '2024-12-27', PHASE: 'Engineering',   GROUP: 'Shop Drawing',   SUBCONT: 'HHI',          TOTAL: 12253,  P_WK: 88,  A_WK: 41,   D_WK: -47, P_CUM: 723,   A_CUM: 503,   D_CUM: -221, },
        { CDATE: '2024-12-27', PHASE: 'Engineering',   GROUP: 'Commissing Eng', SUBCONT: 'HHI',          TOTAL: 185,    P_WK: 2,   A_WK: 1,    D_WK: -1,  P_CUM: 42,    A_CUM: 29,    D_CUM: -13, },
        { CDATE: '2024-12-27', PHASE: 'Engineering',   GROUP: 'Commissing Eng', SUBCONT: 'Gate',         TOTAL: 551,    P_WK: 3,   A_WK: 0,    D_WK: -3,  P_CUM: 92,    A_CUM: 80,    D_CUM: -12, },
        { CDATE: '2024-12-27', PHASE: 'Engineering',   GROUP: 'Commissing Eng', SUBCONT: 'OVERALL',      TOTAL: 736,    P_WK: 5,   A_WK: 2,    D_WK: -3,  P_CUM: 134,   A_CUM: 109,   D_CUM: -25, },
        { CDATE: '2024-12-27', PHASE: 'Engineering',   GROUP: 'OVERALL',        SUBCONT: 'OVERALL',      TOTAL: 45351,  P_WK: 381, A_WK: 404,  D_WK: 23,  P_CUM: 28825, A_CUM: 29470, D_CUM: 644, },
        { CDATE: '2024-12-27', PHASE: 'Construction',  GROUP: 'HHI',            SUBCONT: 'HHI',          TOTAL: 68490,  P_WK: 76,  A_WK: 177,  D_WK: 101, P_CUM: 544,   A_CUM: 718,   D_CUM: 175, },
        { CDATE: '2024-12-27', PHASE: 'Construction',  GROUP: 'Subcontractor',  SUBCONT: 'ShinHwaTech.', TOTAL: 0,      P_WK: 0,   A_WK: 21,   D_WK: 21,  P_CUM: 0,     A_CUM: 249,   D_CUM: 249, },
        { CDATE: '2024-12-27', PHASE: 'Construction',  GROUP: 'Subcontractor',  SUBCONT: 'Sung-II',      TOTAL: 0,      P_WK: 0,   A_WK: 15,   D_WK: 15,  P_CUM: 0,     A_CUM: 142,   D_CUM: 142, },
        { CDATE: '2024-12-27', PHASE: 'Construction',  GROUP: 'Subcontractor',  SUBCONT: 'COSCO',        TOTAL: 23768,  P_WK: 353, A_WK: 400,  D_WK: 47,  P_CUM: 1058,  A_CUM: 1666,  D_CUM: 608, },
        { CDATE: '2024-12-27', PHASE: 'Construction',  GROUP: 'Subcontractor',  SUBCONT: 'OVERALL',      TOTAL: 23768,  P_WK: 353, A_WK: 436,  D_WK: 83,  P_CUM: 1058,  A_CUM: 2056,  D_CUM: 998, },
        { CDATE: '2024-12-27', PHASE: 'Construction',  GROUP: 'OVERALL',        SUBCONT: 'OVERALL',      TOTAL: 92258,  P_WK: 429, A_WK: 612,  D_WK: 184, P_CUM: 1602,  A_CUM: 2775,  D_CUM: 1173, },
        { CDATE: '2024-12-27', PHASE: 'Commissioning', GROUP: 'Commissioning',  SUBCONT: 'HHI',          TOTAL: 3200,   P_WK: 0,   A_WK: 0,    D_WK: 0,   P_CUM: 0,     A_CUM: 0,     D_CUM: 0, },
        { CDATE: '2024-12-27', PHASE: 'Management',    GROUP: 'Management',     SUBCONT: 'HHI',          TOTAL: 7171,   P_WK: 50,  A_WK: 50,   D_WK: 0,   P_CUM: 2457,  A_CUM: 2837,  D_CUM: 380, },
        { CDATE: '2024-12-27', PHASE: 'OVERALL',       GROUP: 'OVERALL',        SUBCONT: 'OVERALL',      TOTAL: 147979, P_WK: 860, A_WK: 1066, D_WK: 207, P_CUM: 32884, A_CUM: 35081, D_CUM: 2197, },
      ],

      SQL2: [
        {
          // Timeline
          CUTOFF      : '2024-12-27',
          START_DATE  : '2024-06-07',
          END_DATE    : '2025-05-30',
        },
      ],

    },


}