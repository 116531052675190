import __C from '../../../../../../includes/primitives/_constant_'

export default {
  Canvas: {
    CanvasWidth: 1400,
    CanvasHeight: 900,
    CanvasBorderWeight: .5,
    CanvasBorderColor: '#E0E0DF',
    CanvasFillColor: '#fff',
    CanvasOpacity: 1,

    CanvasChartX: 0,
    CanvasChartY: 0,
    CanvasChartWidth: 400,
    CanvasChartHeight: 200,
    CanvasChartBorderWeight: 0,
    CanvasChartBorderColor: '#757575',
    CanvasChartFillColor: '#757575',
    CanvasChartOpacity: 0,
    CanvasChartSpace: 0,

    CanvasFontFamily: 'roboto',
  },
  
  Text: {
    Text: [],
    Filter: [],
    Sql: []
  },

  DataItems: [
    { 
      CUTOFF:     '2025-01-24',
      START_DATE: '2024-11-15',
      END_DATE:   '2027-02-26', 
      SPOOL_QTY:  1000
    },
  ],


  Queries: {
    
    // SUMMARY TABLE DATA
    SQL1: [
      { AREA: 'HULL',        ZONE: 'NODE',      YARD: 'HHI',       TOTAL: 1414,  F_ACT: 127, F_REM: 1287,  F_PRO: 9,   I_ACT: 0, I_REM: 1414,  I_PRO: 0, },
      { AREA: 'HULL',        ZONE: 'NODE',      YARD: 'Sub-Total', TOTAL: 1414,  F_ACT: 127, F_REM: 1287,  F_PRO: 9,   I_ACT: 0, I_REM: 1414,  I_PRO: 0, },
      { AREA: 'HULL',        ZONE: 'PONTOON',   YARD: 'COSCO',     TOTAL: 12,    F_ACT: 0,   F_REM: 12,    F_PRO: 0,   I_ACT: 0, I_REM: 12,    I_PRO: 0, },
      { AREA: 'HULL',        ZONE: 'PONTOON',   YARD: 'HHI',       TOTAL: 4,     F_ACT: 0,   F_REM: 4,     F_PRO: 0,   I_ACT: 0, I_REM: 4,     I_PRO: 0, },
      { AREA: 'HULL',        ZONE: 'PONTOON',   YARD: 'Sub-Total', TOTAL: 16,    F_ACT: 0,   F_REM: 16,    F_PRO: 0,   I_ACT: 0, I_REM: 16,    I_PRO: 0, },
      { AREA: 'HULL',        ZONE: 'COLUMN',    YARD: 'COSCO',     TOTAL: 2396,  F_ACT: 0,   F_REM: 2396,  F_PRO: 0,   I_ACT: 0, I_REM: 2396,  I_PRO: 0, },
      { AREA: 'HULL',        ZONE: 'COLUMN',    YARD: 'HHI',       TOTAL: 1867,  F_ACT: 0,   F_REM: 1867,  F_PRO: 0,   I_ACT: 0, I_REM: 1867,  I_PRO: 0, },
      { AREA: 'HULL',        ZONE: 'COLUMN',    YARD: 'Sub-Total', TOTAL: 4263,  F_ACT: 0,   F_REM: 4263,  F_PRO: 0,   I_ACT: 0, I_REM: 4263,  I_PRO: 0, },
      { AREA: 'HULL',        ZONE: 'UCF',       YARD: 'HHI',       TOTAL: 102,   F_ACT: 0,   F_REM: 102,   F_PRO: 0,   I_ACT: 0, I_REM: 102,   I_PRO: 0, },
      { AREA: 'HULL',        ZONE: 'UCF',       YARD: 'Sub-Total', TOTAL: 102,   F_ACT: 0,   F_REM: 102,   F_PRO: 0,   I_ACT: 0, I_REM: 102,   I_PRO: 0, },
      { AREA: 'HULL',        ZONE: 'COMMON',    YARD: 'HHI',       TOTAL: 645,   F_ACT: 0,   F_REM: 645,   F_PRO: 0,   I_ACT: 0, I_REM: 645,   I_PRO: 0, },
      { AREA: 'HULL',        ZONE: 'COMMON',    YARD: 'Sub-Total', TOTAL: 645,   F_ACT: 0,   F_REM: 645,   F_PRO: 0,   I_ACT: 0, I_REM: 645,   I_PRO: 0, },
      { AREA: 'HULL',        ZONE: 'Sub-Total', YARD: '',          TOTAL: 6440,  F_ACT: 127, F_REM: 6313,  F_PRO: 2,   I_ACT: 0, I_REM: 6440,  I_PRO: 0, },
      { AREA: 'TOPSIDE',     ZONE: 'CENTRAL',   YARD: 'HHI',       TOTAL: 5659,  F_ACT: 0,   F_REM: 5659,  F_PRO: 0,   I_ACT: 0, I_REM: 5659,  I_PRO: 0, },
      { AREA: 'TOPSIDE',     ZONE: 'NORTH',     YARD: 'HHI',       TOTAL: 5768,  F_ACT: 0,   F_REM: 5768,  F_PRO: 0,   I_ACT: 0, I_REM: 5768,  I_PRO: 0, },
      { AREA: 'TOPSIDE',     ZONE: 'COMMON',    YARD: 'HHI',       TOTAL: 210,   F_ACT: 0,   F_REM: 210,   F_PRO: 0,   I_ACT: 0, I_REM: 210,   I_PRO: 0, },
      { AREA: 'TOPSIDE',     ZONE: 'FLARE',     YARD: 'HHI',       TOTAL: 149,   F_ACT: 0,   F_REM: 149,   F_PRO: 0,   I_ACT: 0, I_REM: 149,   I_PRO: 0, },
      { AREA: 'TOPSIDE',     ZONE: 'SOUTH',     YARD: 'HHI',       TOTAL: 3297,  F_ACT: 0,   F_REM: 3297,  F_PRO: 0,   I_ACT: 0, I_REM: 3297,  I_PRO: 0, },
      { AREA: 'TOPSIDE',     ZONE: 'Sub-Total', YARD: '',          TOTAL: 15083, F_ACT: 0,   F_REM: 15083, F_PRO: 0,   I_ACT: 0, I_REM: 15083, I_PRO: 0, },
      { AREA: 'LQ',          ZONE: 'LQ',        YARD: 'HHI',       TOTAL: 373,   F_ACT: 0,   F_REM: 373,   F_PRO: 0,   I_ACT: 0, I_REM: 373,   I_PRO: 0, },
      { AREA: 'LQ',          ZONE: 'Sub-Total', YARD: '',          TOTAL: 373,   F_ACT: 0,   F_REM: 373,   F_PRO: 0,   I_ACT: 0, I_REM: 373,   I_PRO: 0, },
      { AREA: 'Grand Total', ZONE: '',          YARD: '',          TOTAL: 21896, F_ACT: 127, F_REM: 21769, F_PRO: 0.6, I_ACT: 0, I_REM: 21896, I_PRO: 0, },
      ],
    
    // TREND CHART
    SQL2: [
      { CDATE: '2024-11-22', AREA: 'HULL',    FAB_WK: 1,     FAB_CUM: 1,     INS_WK: 10,    INS_CUM: 0, },
      { CDATE: '2024-11-29', AREA: 'HULL',    FAB_WK: 0,     FAB_CUM: 1,     INS_WK: 11,    INS_CUM: 0, },
      { CDATE: '2024-12-06', AREA: 'HULL',    FAB_WK: 1,     FAB_CUM: 2,     INS_WK: 25,    INS_CUM: 0, },
      { CDATE: '2024-12-13', AREA: 'HULL',    FAB_WK: 12,    FAB_CUM: 14,    INS_WK: 3,     INS_CUM: 0, },
      { CDATE: '2024-12-20', AREA: 'HULL',    FAB_WK: 0,     FAB_CUM: 14,    INS_WK: 5,     INS_CUM: 0, },
      { CDATE: '2024-12-27', AREA: 'HULL',    FAB_WK: 14,    FAB_CUM: 28,    INS_WK: 22,    INS_CUM: 0, },
      { CDATE: '2025-01-03', AREA: 'HULL',    FAB_WK: 17,    FAB_CUM: 45,    INS_WK: 33,    INS_CUM: 0, },
      { CDATE: '2025-01-10', AREA: 'HULL',    FAB_WK: 26,    FAB_CUM: 71,    INS_WK: 15,    INS_CUM: 0, },
      { CDATE: '2025-01-17', AREA: 'HULL',    FAB_WK: 15,    FAB_CUM: 86,    INS_WK: 11,    INS_CUM: 0, },
      { CDATE: '2025-01-24', AREA: 'HULL',    FAB_WK: 41,    FAB_CUM: 127,   INS_WK: 9,     INS_CUM: 0, },
      { CDATE: '2024-11-22', AREA: 'LQ',      FAB_WK: 0,     FAB_CUM: 0,     INS_WK: 20,    INS_CUM: 0, },
      { CDATE: '2024-11-29', AREA: 'LQ',      FAB_WK: 10,    FAB_CUM: 0,     INS_WK: 25,    INS_CUM: 0, },
      { CDATE: '2024-12-06', AREA: 'LQ',      FAB_WK: 12,    FAB_CUM: 0,     INS_WK: 36,    INS_CUM: 0, },
      { CDATE: '2024-12-13', AREA: 'LQ',      FAB_WK: 15,    FAB_CUM: 0,     INS_WK: 45,    INS_CUM: 0, },
      { CDATE: '2024-12-20', AREA: 'LQ',      FAB_WK: 20,    FAB_CUM: 0,     INS_WK: 14,    INS_CUM: 0, },
      { CDATE: '2024-12-27', AREA: 'LQ',      FAB_WK: 10,    FAB_CUM: 0,     INS_WK: 15,    INS_CUM: 0, },
      { CDATE: '2025-01-03', AREA: 'LQ',      FAB_WK: 33,    FAB_CUM: 0,     INS_WK: 18,    INS_CUM: 0, },
      { CDATE: '2025-01-10', AREA: 'LQ',      FAB_WK: 0,     FAB_CUM: 0,     INS_WK: 5,     INS_CUM: 0, },
      { CDATE: '2025-01-17', AREA: 'LQ',      FAB_WK: 0,     FAB_CUM: 0,     INS_WK: 11,    INS_CUM: 0, },
      { CDATE: '2025-01-24', AREA: 'LQ',      FAB_WK: 10,    FAB_CUM: 0,     INS_WK: 13,    INS_CUM: 0, },
      { CDATE: '2024-11-22', AREA: 'TOPSIDE', FAB_WK: 0,     FAB_CUM: 0,     INS_WK: 15,    INS_CUM: 0, },
      { CDATE: '2024-11-29', AREA: 'TOPSIDE', FAB_WK: 10,    FAB_CUM: 0,     INS_WK: 20,    INS_CUM: 0, },
      { CDATE: '2024-12-06', AREA: 'TOPSIDE', FAB_WK: 20,    FAB_CUM: 0,     INS_WK: 30,    INS_CUM: 0, },
      { CDATE: '2024-12-13', AREA: 'TOPSIDE', FAB_WK: 30,    FAB_CUM: 0,     INS_WK: 33,    INS_CUM: 0, },
      { CDATE: '2024-12-20', AREA: 'TOPSIDE', FAB_WK: 22,    FAB_CUM: 0,     INS_WK: 41,    INS_CUM: 0, },
      { CDATE: '2024-12-27', AREA: 'TOPSIDE', FAB_WK: 15,    FAB_CUM: 0,     INS_WK: 25,    INS_CUM: 0, },
      { CDATE: '2025-01-03', AREA: 'TOPSIDE', FAB_WK: 14,    FAB_CUM: 0,     INS_WK: 36,    INS_CUM: 0, },
      { CDATE: '2025-01-10', AREA: 'TOPSIDE', FAB_WK: 9,     FAB_CUM: 0,     INS_WK: 25,    INS_CUM: 0, },
      { CDATE: '2025-01-17', AREA: 'TOPSIDE', FAB_WK: 11,    FAB_CUM: 0,     INS_WK: 15,    INS_CUM: 0, },
      { CDATE: '2025-01-24', AREA: 'TOPSIDE', FAB_WK: 19,    FAB_CUM: 0,     INS_WK: 10,    INS_CUM: 0, },
      ],

    // SPOOL CHART DATA BY AREA
    SQL3: [
      { CDATE: '2024-11-15', FAB_WK: 0,     FAB_CUM: 0,     INS_WK: 0,     INS_CUM: 0, },
      { CDATE: '2024-11-22', FAB_WK: 1,     FAB_CUM: 1,     INS_WK: 0,     INS_CUM: 0, },
      { CDATE: '2024-11-29', FAB_WK: 0,     FAB_CUM: 1,     INS_WK: 0,     INS_CUM: 0, },
      { CDATE: '2024-12-06', FAB_WK: 1,     FAB_CUM: 2,     INS_WK: 0,     INS_CUM: 0, },
      { CDATE: '2024-12-13', FAB_WK: 12,    FAB_CUM: 14,    INS_WK: 0,     INS_CUM: 0, },
      { CDATE: '2024-12-20', FAB_WK: 0,     FAB_CUM: 14,    INS_WK: 0,     INS_CUM: 0, },
      { CDATE: '2024-12-27', FAB_WK: 14,    FAB_CUM: 28,    INS_WK: 0,     INS_CUM: 0, },
      { CDATE: '2025-01-03', FAB_WK: 17,    FAB_CUM: 45,    INS_WK: 0,     INS_CUM: 0, },
      { CDATE: '2025-01-10', FAB_WK: 26,    FAB_CUM: 71,    INS_WK: 0,     INS_CUM: 0, },
      { CDATE: '2025-01-17', FAB_WK: 15,    FAB_CUM: 86,    INS_WK: 0,     INS_CUM: 0, },
      { CDATE: '2025-01-24', FAB_WK: 41,    FAB_CUM: 127,   INS_WK: 0,     INS_CUM: 0, },
      { CDATE: '2025-01-31', FAB_WK: 0,     FAB_CUM: 127,   INS_WK: 0,     INS_CUM: 0, },
      { CDATE: '2025-02-07', FAB_WK: 0,     FAB_CUM: 127,   INS_WK: 0,     INS_CUM: 0, },
      { CDATE: '2025-02-14', FAB_WK: 0,     FAB_CUM: 127,   INS_WK: 0,     INS_CUM: 0, },
      { CDATE: '2025-02-21', FAB_WK: 0,     FAB_CUM: 127,   INS_WK: 0,     INS_CUM: 0, },
      { CDATE: '2025-02-28', FAB_WK: 0,     FAB_CUM: 127,   INS_WK: 0,     INS_CUM: 0, },
      { CDATE: '2025-03-07', FAB_WK: 0,     FAB_CUM: 127,   INS_WK: 0,     INS_CUM: 0, },
      { CDATE: '2025-03-14', FAB_WK: 0,     FAB_CUM: 127,   INS_WK: 0,     INS_CUM: 0, },
      { CDATE: '2025-03-21', FAB_WK: 0,     FAB_CUM: 127,   INS_WK: 0,     INS_CUM: 0, },
      { CDATE: '2025-03-28', FAB_WK: 0,     FAB_CUM: 127,   INS_WK: 0,     INS_CUM: 0, },
      { CDATE: '2025-04-04', FAB_WK: 0,     FAB_CUM: 127,   INS_WK: 0,     INS_CUM: 0, },
      { CDATE: '2025-04-11', FAB_WK: 0,     FAB_CUM: 127,   INS_WK: 0,     INS_CUM: 0, },
      { CDATE: '2025-04-18', FAB_WK: 0,     FAB_CUM: 127,   INS_WK: 0,     INS_CUM: 0, },
      { CDATE: '2025-04-25', FAB_WK: 0,     FAB_CUM: 127,   INS_WK: 0,     INS_CUM: 0, },
      { CDATE: '2025-05-02', FAB_WK: 0,     FAB_CUM: 127,   INS_WK: 0,     INS_CUM: 0, },
      { CDATE: '2025-05-09', FAB_WK: 0,     FAB_CUM: 127,   INS_WK: 0,     INS_CUM: 0, },
      { CDATE: '2025-05-16', FAB_WK: 0,     FAB_CUM: 127,   INS_WK: 0,     INS_CUM: 0, },
      { CDATE: '2025-05-23', FAB_WK: 0,     FAB_CUM: 127,   INS_WK: 0,     INS_CUM: 0, },
      { CDATE: '2025-05-30', FAB_WK: 0,     FAB_CUM: 127,   INS_WK: 0,     INS_CUM: 0, },
      { CDATE: '2025-06-06', FAB_WK: 0,     FAB_CUM: 127,   INS_WK: 0,     INS_CUM: 0, },
      { CDATE: '2025-06-13', FAB_WK: 0,     FAB_CUM: 127,   INS_WK: 0,     INS_CUM: 0, },
      { CDATE: '2025-06-20', FAB_WK: 0,     FAB_CUM: 127,   INS_WK: 0,     INS_CUM: 0, },
      { CDATE: '2025-06-27', FAB_WK: 0,     FAB_CUM: 127,   INS_WK: 0,     INS_CUM: 0, },
      { CDATE: '2025-07-04', FAB_WK: 0,     FAB_CUM: 127,   INS_WK: 0,     INS_CUM: 0, },
      { CDATE: '2025-07-11', FAB_WK: 0,     FAB_CUM: 127,   INS_WK: 0,     INS_CUM: 0, },
      { CDATE: '2025-07-18', FAB_WK: 0,     FAB_CUM: 127,   INS_WK: 0,     INS_CUM: 0, },
      { CDATE: '2025-07-25', FAB_WK: 0,     FAB_CUM: 127,   INS_WK: 0,     INS_CUM: 0, },
      { CDATE: '2025-08-01', FAB_WK: 0,     FAB_CUM: 127,   INS_WK: 0,     INS_CUM: 0, },
      { CDATE: '2025-08-08', FAB_WK: 0,     FAB_CUM: 127,   INS_WK: 0,     INS_CUM: 0, },
      { CDATE: '2025-08-15', FAB_WK: 0,     FAB_CUM: 127,   INS_WK: 0,     INS_CUM: 0, },
      { CDATE: '2025-08-22', FAB_WK: 0,     FAB_CUM: 127,   INS_WK: 0,     INS_CUM: 0, },
      { CDATE: '2025-08-29', FAB_WK: 0,     FAB_CUM: 127,   INS_WK: 0,     INS_CUM: 0, },
      { CDATE: '2025-09-05', FAB_WK: 0,     FAB_CUM: 127,   INS_WK: 0,     INS_CUM: 0, },
      { CDATE: '2025-09-12', FAB_WK: 0,     FAB_CUM: 127,   INS_WK: 0,     INS_CUM: 0, },
      { CDATE: '2025-09-19', FAB_WK: 0,     FAB_CUM: 127,   INS_WK: 0,     INS_CUM: 0, },
      { CDATE: '2025-09-26', FAB_WK: 0,     FAB_CUM: 127,   INS_WK: 0,     INS_CUM: 0, },
      { CDATE: '2025-10-03', FAB_WK: 0,     FAB_CUM: 127,   INS_WK: 0,     INS_CUM: 0, },
      { CDATE: '2025-10-10', FAB_WK: 0,     FAB_CUM: 127,   INS_WK: 0,     INS_CUM: 0, },
      { CDATE: '2025-10-17', FAB_WK: 0,     FAB_CUM: 127,   INS_WK: 0,     INS_CUM: 0, },
      { CDATE: '2025-10-24', FAB_WK: 0,     FAB_CUM: 127,   INS_WK: 0,     INS_CUM: 0, },
      { CDATE: '2025-10-31', FAB_WK: 0,     FAB_CUM: 127,   INS_WK: 0,     INS_CUM: 0, },
      { CDATE: '2025-11-07', FAB_WK: 0,     FAB_CUM: 127,   INS_WK: 0,     INS_CUM: 0, },
      { CDATE: '2025-11-14', FAB_WK: 0,     FAB_CUM: 127,   INS_WK: 0,     INS_CUM: 0, },
      { CDATE: '2025-11-21', FAB_WK: 0,     FAB_CUM: 127,   INS_WK: 0,     INS_CUM: 0, },
      { CDATE: '2025-11-28', FAB_WK: 0,     FAB_CUM: 127,   INS_WK: 0,     INS_CUM: 0, },
      { CDATE: '2025-12-05', FAB_WK: 0,     FAB_CUM: 127,   INS_WK: 0,     INS_CUM: 0, },
      { CDATE: '2025-12-12', FAB_WK: 0,     FAB_CUM: 127,   INS_WK: 0,     INS_CUM: 0, },
      { CDATE: '2025-12-19', FAB_WK: 0,     FAB_CUM: 127,   INS_WK: 0,     INS_CUM: 0, },
      { CDATE: '2025-12-26', FAB_WK: 0,     FAB_CUM: 127,   INS_WK: 0,     INS_CUM: 0, },
      { CDATE: '2026-01-02', FAB_WK: 0,     FAB_CUM: 127,   INS_WK: 0,     INS_CUM: 0, },
      { CDATE: '2026-01-09', FAB_WK: 0,     FAB_CUM: 127,   INS_WK: 0,     INS_CUM: 0, },
      { CDATE: '2026-01-16', FAB_WK: 0,     FAB_CUM: 127,   INS_WK: 0,     INS_CUM: 0, },
      { CDATE: '2026-01-23', FAB_WK: 0,     FAB_CUM: 127,   INS_WK: 0,     INS_CUM: 0, },
      { CDATE: '2026-01-30', FAB_WK: 0,     FAB_CUM: 127,   INS_WK: 0,     INS_CUM: 0, },
      { CDATE: '2026-02-06', FAB_WK: 0,     FAB_CUM: 127,   INS_WK: 0,     INS_CUM: 0, },
      { CDATE: '2026-02-13', FAB_WK: 0,     FAB_CUM: 127,   INS_WK: 0,     INS_CUM: 0, },
      { CDATE: '2026-02-20', FAB_WK: 0,     FAB_CUM: 127,   INS_WK: 0,     INS_CUM: 0, },
      { CDATE: '2026-02-27', FAB_WK: 0,     FAB_CUM: 127,   INS_WK: 0,     INS_CUM: 0, },
      { CDATE: '2026-03-06', FAB_WK: 0,     FAB_CUM: 127,   INS_WK: 0,     INS_CUM: 0, },
      { CDATE: '2026-03-13', FAB_WK: 0,     FAB_CUM: 127,   INS_WK: 0,     INS_CUM: 0, },
      { CDATE: '2026-03-20', FAB_WK: 0,     FAB_CUM: 127,   INS_WK: 0,     INS_CUM: 0, },
      { CDATE: '2026-03-27', FAB_WK: 0,     FAB_CUM: 127,   INS_WK: 0,     INS_CUM: 0, },
      { CDATE: '2026-04-03', FAB_WK: 0,     FAB_CUM: 127,   INS_WK: 0,     INS_CUM: 0, },
      { CDATE: '2026-04-10', FAB_WK: 0,     FAB_CUM: 127,   INS_WK: 0,     INS_CUM: 0, },
      { CDATE: '2026-04-17', FAB_WK: 0,     FAB_CUM: 127,   INS_WK: 0,     INS_CUM: 0, },
      { CDATE: '2026-04-24', FAB_WK: 0,     FAB_CUM: 127,   INS_WK: 0,     INS_CUM: 0, },
      { CDATE: '2026-05-01', FAB_WK: 0,     FAB_CUM: 127,   INS_WK: 0,     INS_CUM: 0, },
      { CDATE: '2026-05-08', FAB_WK: 0,     FAB_CUM: 127,   INS_WK: 0,     INS_CUM: 0, },
      { CDATE: '2026-05-15', FAB_WK: 0,     FAB_CUM: 127,   INS_WK: 0,     INS_CUM: 0, },
      { CDATE: '2026-05-22', FAB_WK: 0,     FAB_CUM: 127,   INS_WK: 0,     INS_CUM: 0, },
      { CDATE: '2026-05-29', FAB_WK: 0,     FAB_CUM: 127,   INS_WK: 0,     INS_CUM: 0, },
      { CDATE: '2026-06-05', FAB_WK: 0,     FAB_CUM: 127,   INS_WK: 0,     INS_CUM: 0, },
      { CDATE: '2026-06-12', FAB_WK: 0,     FAB_CUM: 127,   INS_WK: 0,     INS_CUM: 0, },
      { CDATE: '2026-06-19', FAB_WK: 0,     FAB_CUM: 127,   INS_WK: 0,     INS_CUM: 0, },
      { CDATE: '2026-06-26', FAB_WK: 0,     FAB_CUM: 127,   INS_WK: 0,     INS_CUM: 0, },
      { CDATE: '2026-07-03', FAB_WK: 0,     FAB_CUM: 127,   INS_WK: 0,     INS_CUM: 0, },
      { CDATE: '2026-07-10', FAB_WK: 0,     FAB_CUM: 127,   INS_WK: 0,     INS_CUM: 0, },
      { CDATE: '2026-07-17', FAB_WK: 0,     FAB_CUM: 127,   INS_WK: 0,     INS_CUM: 0, },
      { CDATE: '2026-07-24', FAB_WK: 0,     FAB_CUM: 127,   INS_WK: 0,     INS_CUM: 0, },
      { CDATE: '2026-07-31', FAB_WK: 0,     FAB_CUM: 127,   INS_WK: 0,     INS_CUM: 0, },
      { CDATE: '2026-08-07', FAB_WK: 0,     FAB_CUM: 127,   INS_WK: 0,     INS_CUM: 0, },
      { CDATE: '2026-08-14', FAB_WK: 0,     FAB_CUM: 127,   INS_WK: 0,     INS_CUM: 0, },
      { CDATE: '2026-08-21', FAB_WK: 0,     FAB_CUM: 127,   INS_WK: 0,     INS_CUM: 0, },
      { CDATE: '2026-08-28', FAB_WK: 0,     FAB_CUM: 127,   INS_WK: 0,     INS_CUM: 0, },
      { CDATE: '2026-09-04', FAB_WK: 0,     FAB_CUM: 127,   INS_WK: 0,     INS_CUM: 0, },
      { CDATE: '2026-09-11', FAB_WK: 0,     FAB_CUM: 127,   INS_WK: 0,     INS_CUM: 0, },
      { CDATE: '2026-09-18', FAB_WK: 0,     FAB_CUM: 127,   INS_WK: 0,     INS_CUM: 0, },
      { CDATE: '2026-09-25', FAB_WK: 0,     FAB_CUM: 127,   INS_WK: 0,     INS_CUM: 0, },
      { CDATE: '2026-10-02', FAB_WK: 0,     FAB_CUM: 127,   INS_WK: 0,     INS_CUM: 0, },
      { CDATE: '2026-10-09', FAB_WK: 0,     FAB_CUM: 127,   INS_WK: 0,     INS_CUM: 0, },
      { CDATE: '2026-10-16', FAB_WK: 0,     FAB_CUM: 127,   INS_WK: 0,     INS_CUM: 0, },
      { CDATE: '2026-10-23', FAB_WK: 0,     FAB_CUM: 127,   INS_WK: 0,     INS_CUM: 0, },
      { CDATE: '2026-10-30', FAB_WK: 0,     FAB_CUM: 127,   INS_WK: 0,     INS_CUM: 0, },
      { CDATE: '2026-11-06', FAB_WK: 0,     FAB_CUM: 127,   INS_WK: 0,     INS_CUM: 0, },
      { CDATE: '2026-11-13', FAB_WK: 0,     FAB_CUM: 127,   INS_WK: 0,     INS_CUM: 0, },
      { CDATE: '2026-11-20', FAB_WK: 0,     FAB_CUM: 127,   INS_WK: 0,     INS_CUM: 0, },
      { CDATE: '2026-11-27', FAB_WK: 0,     FAB_CUM: 127,   INS_WK: 0,     INS_CUM: 0, },
      { CDATE: '2026-12-04', FAB_WK: 0,     FAB_CUM: 127,   INS_WK: 0,     INS_CUM: 0, },
      { CDATE: '2026-12-11', FAB_WK: 0,     FAB_CUM: 127,   INS_WK: 0,     INS_CUM: 0, },
      { CDATE: '2026-12-18', FAB_WK: 0,     FAB_CUM: 127,   INS_WK: 0,     INS_CUM: 0, },
      { CDATE: '2026-12-25', FAB_WK: 0,     FAB_CUM: 127,   INS_WK: 0,     INS_CUM: 0, },
      { CDATE: '2027-01-01', FAB_WK: 0,     FAB_CUM: 127,   INS_WK: 0,     INS_CUM: 0, },
      { CDATE: '2027-01-08', FAB_WK: 0,     FAB_CUM: 127,   INS_WK: 0,     INS_CUM: 0, },
      { CDATE: '2027-01-15', FAB_WK: 0,     FAB_CUM: 127,   INS_WK: 0,     INS_CUM: 0, },
      { CDATE: '2027-01-22', FAB_WK: 0,     FAB_CUM: 127,   INS_WK: 0,     INS_CUM: 0, },
      { CDATE: '2027-01-29', FAB_WK: 0,     FAB_CUM: 127,   INS_WK: 0,     INS_CUM: 0, },
      { CDATE: '2027-02-05', FAB_WK: 0,     FAB_CUM: 127,   INS_WK: 0,     INS_CUM: 0, },
      { CDATE: '2027-02-12', FAB_WK: 0,     FAB_CUM: 127,   INS_WK: 0,     INS_CUM: 0, },
      { CDATE: '2027-02-19', FAB_WK: 0,     FAB_CUM: 127,   INS_WK: 0,     INS_CUM: 0, },
      { CDATE: '2027-02-26', FAB_WK: 0,     FAB_CUM: 127,   INS_WK: 0,     INS_CUM: 0, },
      ],

  },
}