export default {
    Canvas: {
      CanvasWidth: 810,
      CanvasHeight: 320,
      CanvasBorderWeight: .5,
      CanvasBorderColor: '#E0E0DF',
      CanvasFillColor: '#fff',
      CanvasOpacity: 1,

      CanvasChartX: 0,
      CanvasChartY: 0,
      CanvasChartWidth: 400,
      CanvasChartHeight: 200,
      CanvasChartBorderWeight: 0,
      CanvasChartBorderColor: '#757575',
      CanvasChartFillColor: '#757575',
      CanvasChartOpacity: 0,
      CanvasChartSpace: 0,

      CanvasFontFamily: 'roboto',
    },
    
    Text: {
    },

    
    DataItems: [
      {
        CUTOFF: '2025-01-24'
      },
    ],


    Queries: {
      
      SQL1: [
        { CDATE: '2024-11-15', TOT: 6440,  FAB_WK: 0,     FAB_REM: 6440,  IST_WK: 0,     IST_REM: 6440, },
        { CDATE: '2024-11-22', TOT: 6440,  FAB_WK: 1,     FAB_REM: 6439,  IST_WK: 1,     IST_REM: 6439, },
        { CDATE: '2024-11-29', TOT: 6440,  FAB_WK: 0,     FAB_REM: 6440,  IST_WK: 0,     IST_REM: 6440, },
        { CDATE: '2024-12-06', TOT: 6440,  FAB_WK: 1,     FAB_REM: 6439,  IST_WK: 2,     IST_REM: 6438, },
        { CDATE: '2024-12-13', TOT: 6440,  FAB_WK: 12,    FAB_REM: 6428,  IST_WK: 2,     IST_REM: 6438, },
        { CDATE: '2024-12-20', TOT: 6440,  FAB_WK: 0,     FAB_REM: 6440,  IST_WK: 5,     IST_REM: 6435, },
        { CDATE: '2024-12-27', TOT: 6440,  FAB_WK: 14,    FAB_REM: 6426,  IST_WK: 5,     IST_REM: 6435, },
        { CDATE: '2025-01-03', TOT: 6440,  FAB_WK: 17,    FAB_REM: 6423,  IST_WK: 10,    IST_REM: 6430, },
        { CDATE: '2025-01-10', TOT: 6440,  FAB_WK: 26,    FAB_REM: 6414,  IST_WK: 5,     IST_REM: 6435, },
        { CDATE: '2025-01-17', TOT: 6440,  FAB_WK: 15,    FAB_REM: 6425,  IST_WK: 10,    IST_REM: 6430, },
        { CDATE: '2025-01-24', TOT: 6440,  FAB_WK: 41,    FAB_REM: 6399,  IST_WK: 35,    IST_REM: 6405, },
        { CDATE: '2025-01-31', TOT: 6440,  FAB_WK: 0,     FAB_REM: 6440,  IST_WK: 0,     IST_REM: 6440, },
        { CDATE: '2025-02-07', TOT: 6440,  FAB_WK: 0,     FAB_REM: 6440,  IST_WK: 0,     IST_REM: 6440, },
        { CDATE: '2025-02-14', TOT: 6440,  FAB_WK: 0,     FAB_REM: 6440,  IST_WK: 0,     IST_REM: 6440, },
        { CDATE: '2025-02-21', TOT: 6440,  FAB_WK: 0,     FAB_REM: 6440,  IST_WK: 0,     IST_REM: 6440, },
        { CDATE: '2025-02-28', TOT: 6440,  FAB_WK: 0,     FAB_REM: 6440,  IST_WK: 0,     IST_REM: 6440, },
        { CDATE: '2025-03-07', TOT: 6440,  FAB_WK: 0,     FAB_REM: 6440,  IST_WK: 0,     IST_REM: 6440, },
        { CDATE: '2025-03-14', TOT: 6440,  FAB_WK: 0,     FAB_REM: 6440,  IST_WK: 0,     IST_REM: 6440, },
        { CDATE: '2025-03-21', TOT: 6440,  FAB_WK: 0,     FAB_REM: 6440,  IST_WK: 0,     IST_REM: 6440, },
        { CDATE: '2025-03-28', TOT: 6440,  FAB_WK: 0,     FAB_REM: 6440,  IST_WK: 0,     IST_REM: 6440, },
        { CDATE: '2025-04-04', TOT: 6440,  FAB_WK: 0,     FAB_REM: 6440,  IST_WK: 0,     IST_REM: 6440, },
        { CDATE: '2025-04-11', TOT: 6440,  FAB_WK: 0,     FAB_REM: 6440,  IST_WK: 0,     IST_REM: 6440, },
        { CDATE: '2025-04-18', TOT: 6440,  FAB_WK: 0,     FAB_REM: 6440,  IST_WK: 0,     IST_REM: 6440, },
        { CDATE: '2025-04-25', TOT: 6440,  FAB_WK: 0,     FAB_REM: 6440,  IST_WK: 0,     IST_REM: 6440, },
        ],
        
        
      SQL2: [
        { AREA: 'HULL', ZONE: 'PONTOON', TOT: '16',   FAB_WO: '4',    FAB: '0',   IST_WO: '4',    IST: '0', },
        { AREA: 'HULL', ZONE: 'COLUMN',  TOT: '4263', FAB_WO: '168',  FAB: '0',   IST_WO: '168',  IST: '0', },
        { AREA: 'HULL', ZONE: 'NODE',    TOT: '1414', FAB_WO: '991',  FAB: '127', IST_WO: '991',  IST: '0', },
        { AREA: 'HULL', ZONE: 'UCF',     TOT: '102',  FAB_WO: '0',    FAB: '0',   IST_WO: '0',    IST: '0', },
        { AREA: 'HULL', ZONE: 'COMMON',  TOT: '645',  FAB_WO: '0',    FAB: '0',   IST_WO: '0',    IST: '0', },
        { AREA: 'HULL', ZONE: 'TOTAL',   TOT: '6440', FAB_WO: '1163', FAB: '127', IST_WO: '1163', IST: '0', },
        ]
    },
}