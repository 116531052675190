
export default {
  Canvas: {
    CanvasWidth: 810,
    CanvasHeight: 1200,
    CanvasBorderWeight: 0,
    CanvasBorderColor: '#bcbcbc',
    CanvasFillColor: '#fff',
    CanvasOpacity: 1,

    CanvasChartX: 0,
    CanvasChartY: 0,
    CanvasChartWidth: 1300,
    CanvasChartHeight: 760,
    CanvasChartBorderWeight: 0,
    CanvasChartBorderColor: '#757575',
    CanvasChartFillColor: '#000',
    CanvasChartOpacity: 0,
    CanvasChartSpace: 0,

    CanvasFontFamily: 'roboto',
  },
  


  DataItems: [
    {
      DATA: ''
    }
  ],

  Queries: {
    
    SQL1: [
      {SN: 1, DISC: 'PIPING',     ITEM: 'SPOOL',     NAME: 'Fab.',      CODE: 'P_FAB',  ACT: 135, TOT: 350, PRO:45.5},
      {SN: 2, DISC: 'PIPING',     ITEM: 'SPOOL',     NAME: 'Installed', CODE: 'P_IST',  ACT: 135, TOT: 350, PRO:15.5},
      {SN: 3, DISC: 'MECHANICAL', ITEM: 'EQUIPMENT', NAME: 'PO',        CODE: 'EQ_PO',  ACT: 55,  TOT: 75,  PRO:75.5},
      {SN: 4, DISC: 'MECHANICAL', ITEM: 'EQUIPMENT', NAME: 'FAT',       CODE: 'EQ_FAT', ACT: 34,  TOT: 75,  PRO:43.5},
      {SN: 5, DISC: 'MECHANICAL', ITEM: 'EQUIPMENT', NAME: 'ATA',       CODE: 'EQ_OS',  ACT: 12,  TOT: 75,  PRO:19.5},
      {SN: 6, DISC: 'MECHANICAL', ITEM: 'EQUIPMENT', NAME: 'Installed', CODE: 'EQ_IST', ACT: 0,   TOT: 75,  PRO:0},
    ],

  },
}