import { RGB_S3TC_DXT1_Format } from "three/src/constants.js";

export default {
  Canvas: {
    CanvasWidth: 1600,
    CanvasHeight: 1120,
    CanvasBorderWeight: 0,
    CanvasBorderColor: '#bcbcbc',
    CanvasFillColor: '#fff',
    CanvasOpacity: 1,

    CanvasChartX: 0,
    CanvasChartY: 0,
    CanvasChartWidth: 1300,
    CanvasChartHeight: 760,
    CanvasChartBorderWeight: 0,
    CanvasChartBorderColor: '#757575',
    CanvasChartFillColor: '#000',
    CanvasChartOpacity: 0,
    CanvasChartSpace: 0,

    CanvasFontFamily: 'roboto',
  },
  

  // Progress
  DataItems: [
    { 
      CDATE: '2025-02-14', 
      SOURCE: 'Block Division Rev.2 (29 Aug 2024) / Structure Schedule (15 Nov 2024)',

      OVERALL_EP: 1.2, OVERALL_LP: 0.8, OVERALL_A: 0, 
      HULL_EP: 4.5,    HULL_LP: 3.4,    HULL_A: 4.0, 
      TOPSIDE_EP: 0,   TOPSIDE_LP: 0,   TOPSIDE_A: 0, 
      LQ_EP: 2.1,      LQ_LP: 0.1,      LQ_A: 0, 
    },
  ],

  Queries: {
    
    // Structure Schedule
    SQL1: [
      { EXCEPT: '',  AREA: 'HULL',    ZONE: 'NODE',           MOD: 'Node, NW',            LEVEL: 'NW, Node',            NAME: 'Node  Shell',            G_EREC: 'WN112', G_2PE: 'WN112', G_1PE: 'WN111', EREC: 'WN112', PE2: '',      PE1: 'WN111', PBLK: 'WN111', BLK: 'WN110', L: '19.6', B: '19.6', H: '2.8',  YARD: 'HHI',   SC_WF: 835.67, FB_WF: 1671.34, AS_WF: 2507.01, PE1_WF: 0,       PT_WF: 2711.73, PE2_WF: 4178.34, ER_WF: 2073.7, SC_PS: '2024-11-20', SC_PF: '2024-12-09', SC_PP: 100,   SC_AS: '2024-11-26', SC_AF: '2024-12-06', SC_AP: 100, FB_PS: '2024-12-10', FB_PF: '2025-01-13', FB_PP: 100,   FB_AS: '2024-12-19', FB_AF: '2025-02-14', FB_AP: 100,  AS_PS: '2025-01-14', AS_PF: '2025-03-10', AS_PP: 43.8, AS_AS: '2025-02-12', AS_AF: undefined,    AS_AP: 5,    PE1_PS: undefined,    PE1_PF: undefined,    PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: '2025-04-10', PT_PF: '2025-05-28', PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: '2025-05-29', PE2_PF: '2025-07-23', PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: '2025-07-24', ER_PF: '2025-09-02', ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'HULL',    ZONE: 'NODE',           MOD: 'Node, NW',            LEVEL: 'NW, Node',            NAME: 'Node Support',           G_EREC: 'WN112', G_2PE: 'WN112', G_1PE: 'WN111', EREC: '',      PE2: '',      PE1: '',      PBLK: '',      BLK: 'WNSA0', L: '1(n)', B: '1(n)', H: '1(n)', YARD: 'HHI',   SC_WF: 8,      FB_WF: 8,       AS_WF: 0,       PE1_WF: 24,      PT_WF: 0,       PE2_WF: 0,       ER_WF: 0,      SC_PS: '2025-01-08', SC_PF: '2025-01-27', SC_PP: 100,   SC_AS: '2025-01-11', SC_AF: '2025-01-17', SC_AP: 100, FB_PS: '2025-01-31', FB_PF: '2025-03-17', FB_PP: 24.1,  FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: undefined,    AS_PF: undefined,    AS_PP: 0,    AS_AS: undefined,    AS_AF: undefined,    AS_AP: 0,    PE1_PS: '2025-03-18', PE1_PF: '2025-04-07', PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: undefined,    PT_PF: undefined,    PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: undefined,    PE2_PF: undefined,    PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: undefined,    ER_PF: undefined,    ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'HULL',    ZONE: 'NODE',           MOD: 'Node, NW',            LEVEL: 'NW, Node',            NAME: 'Node  Shell',            G_EREC: 'WN112', G_2PE: 'WN112', G_1PE: 'WN121', EREC: '',      PE2: '',      PE1: 'WN121', PBLK: 'WN121', BLK: 'WN120', L: '13.3', B: '7.8',  H: '6.7',  YARD: 'HHI',   SC_WF: 400.85, FB_WF: 801.7,   AS_WF: 1202.55, PE1_WF: 2004.25, PT_WF: 602.61,  PE2_WF: 2004.25, ER_WF: 0,      SC_PS: '2024-12-20', SC_PF: '2025-01-13', SC_PP: 100,   SC_AS: '2024-12-26', SC_AF: '2025-01-09', SC_AP: 100, FB_PS: '2025-01-14', FB_PF: '2025-02-13', FB_PP: 100,   FB_AS: '2025-01-22', FB_AF: undefined,    FB_AP: 10,   AS_PS: '2025-02-14', AS_PF: '2025-04-02', AS_PP: 2.1,  AS_AS: undefined,    AS_AF: undefined,    AS_AP: 0,    PE1_PS: '2025-04-03', PE1_PF: '2025-05-07', PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: '2025-05-08', PT_PF: '2025-05-30', PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: '2025-06-02', PE2_PF: '2025-07-23', PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: undefined,    ER_PF: undefined,    ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'HULL',    ZONE: 'NODE',           MOD: 'Node, NW',            LEVEL: 'NW, Node',            NAME: 'Node  Shell',            G_EREC: 'WN112', G_2PE: 'WN112', G_1PE: 'WN121', EREC: '',      PE2: '',      PE1: '',      PBLK: '',      BLK: 'WN130', L: '7.6',  B: '13',   H: '6.8',  YARD: 'HHI',   SC_WF: 369.99, FB_WF: 739.99,  AS_WF: 1109.98, PE1_WF: 1849.96, PT_WF: 0,       PE2_WF: 0,       ER_WF: 0,      SC_PS: '2024-12-20', SC_PF: '2025-01-13', SC_PP: 100,   SC_AS: '2024-12-27', SC_AF: '2025-01-11', SC_AP: 100, FB_PS: '2025-01-14', FB_PF: '2025-02-13', FB_PP: 100,   FB_AS: '2025-01-22', FB_AF: undefined,    FB_AP: 10,   AS_PS: '2025-02-14', AS_PF: '2025-04-02', AS_PP: 2.1,  AS_AS: undefined,    AS_AF: undefined,    AS_AP: 0,    PE1_PS: '2025-04-03', PE1_PF: '2025-04-30', PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: undefined,    PT_PF: undefined,    PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: undefined,    PE2_PF: undefined,    PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: undefined,    ER_PF: undefined,    ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'HULL',    ZONE: 'NODE',           MOD: 'Node, NW',            LEVEL: 'NW, Node',            NAME: 'Node  Shell',            G_EREC: 'WN112', G_2PE: 'WN112', G_1PE: 'WN121', EREC: '',      PE2: '',      PE1: '',      PBLK: '',      BLK: 'WN140', L: '7.5',  B: '13.3', H: '6.7',  YARD: 'HHI',   SC_WF: 414.15, FB_WF: 828.31,  AS_WF: 1242.46, PE1_WF: 2070.77, PT_WF: 0,       PE2_WF: 0,       ER_WF: 0,      SC_PS: '2024-12-30', SC_PF: '2025-01-20', SC_PP: 100,   SC_AS: '2025-01-06', SC_AF: '2025-01-13', SC_AP: 100, FB_PS: '2025-01-21', FB_PF: '2025-02-20', FB_PP: 74,    FB_AS: '2025-02-08', FB_AF: undefined,    FB_AP: 10,   AS_PS: '2025-02-21', AS_PF: '2025-04-09', AS_PP: 0,    AS_AS: undefined,    AS_AF: undefined,    AS_AP: 0,    PE1_PS: '2025-04-10', PE1_PF: '2025-04-30', PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: undefined,    PT_PF: undefined,    PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: undefined,    PE2_PF: undefined,    PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: undefined,    ER_PF: undefined,    ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'HULL',    ZONE: 'NODE',           MOD: 'Node, NW',            LEVEL: 'NW, Node',            NAME: 'Node  Shell',            G_EREC: 'WN112', G_2PE: 'WN112', G_1PE: 'WN121', EREC: '',      PE2: '',      PE1: '',      PBLK: '',      BLK: 'WN150', L: '13',   B: '7.5',  H: '6.7',  YARD: 'HHI',   SC_WF: 338.85, FB_WF: 677.71,  AS_WF: 1016.56, PE1_WF: 1694.27, PT_WF: 0,       PE2_WF: 0,       ER_WF: 0,      SC_PS: '2024-12-20', SC_PF: '2025-01-13', SC_PP: 100,   SC_AS: '2024-12-27', SC_AF: '2025-01-15', SC_AP: 100, FB_PS: '2025-01-14', FB_PF: '2025-02-13', FB_PP: 100,   FB_AS: '2025-01-30', FB_AF: undefined,    FB_AP: 22.1, AS_PS: '2025-02-14', AS_PF: '2025-04-08', AS_PP: 1.8,  AS_AS: undefined,    AS_AF: undefined,    AS_AP: 0,    PE1_PS: '2025-04-09', PE1_PF: '2025-04-30', PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: undefined,    PT_PF: undefined,    PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: undefined,    PE2_PF: undefined,    PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: undefined,    ER_PF: undefined,    ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'HULL',    ZONE: 'NODE',           MOD: 'Node, NW',            LEVEL: 'NW, Node',            NAME: 'Node Support',           G_EREC: 'WN112', G_2PE: 'WN112', G_1PE: 'WN121', EREC: '',      PE2: '',      PE1: '',      PBLK: '',      BLK: 'WNS10', L: '1(n)', B: '1(n)', H: '1(n)', YARD: 'HHI',   SC_WF: 8,      FB_WF: 8,       AS_WF: 0,       PE1_WF: 24,      PT_WF: 0,       PE2_WF: 0,       ER_WF: 0,      SC_PS: '2025-02-05', SC_PF: '2025-02-24', SC_PP: 44.3,  SC_AS: '2025-02-11', SC_AF: undefined,    SC_AP: 50,  FB_PS: '2025-02-25', FB_PF: '2025-04-09', FB_PP: 0,     FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: undefined,    AS_PF: undefined,    AS_PP: 0,    AS_AS: undefined,    AS_AF: undefined,    AS_AP: 0,    PE1_PS: '2025-04-10', PE1_PF: '2025-04-30', PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: undefined,    PT_PF: undefined,    PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: undefined,    PE2_PF: undefined,    PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: undefined,    ER_PF: undefined,    ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'HULL',    ZONE: 'NODE',           MOD: 'Node, NW',            LEVEL: 'NW, Node',            NAME: 'Grating Beam P/F',       G_EREC: 'WN112', G_2PE: 'WN112', G_1PE: 'WNG10', EREC: '',      PE2: '',      PE1: '',      PBLK: 'WNG10', BLK: 'WNG10', L: '6.6',  B: '6.6',  H: '1.4',  YARD: 'HHI',   SC_WF: 24,     FB_WF: 40,      AS_WF: 0,       PE1_WF: 70.77,   PT_WF: 310.65,  PE2_WF: 80,      ER_WF: 0,      SC_PS: '2025-02-10', SC_PF: '2025-02-27', SC_PP: 25,    SC_AS: '2025-02-11', SC_AF: undefined,    SC_AP: 25,  FB_PS: '2025-02-28', FB_PF: '2025-04-21', FB_PP: 0,     FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: undefined,    AS_PF: undefined,    AS_PP: 0,    AS_AS: undefined,    AS_AF: undefined,    AS_AP: 0,    PE1_PS: '2025-04-22', PE1_PF: '2025-05-12', PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: '2025-05-13', PT_PF: '2025-06-04', PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: '2025-06-05', PE2_PF: '2025-07-03', PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: undefined,    ER_PF: undefined,    ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'HULL',    ZONE: 'NODE',           MOD: 'Node, NW',            LEVEL: 'NW, Node',            NAME: 'Grating Beam P/F',       G_EREC: 'WN112', G_2PE: 'WN112', G_1PE: 'WNG20', EREC: '',      PE2: '',      PE1: '',      PBLK: 'WNG20', BLK: 'WNG20', L: '12',   B: '7.1',  H: '1.5',  YARD: 'HHI',   SC_WF: 33.97,  FB_WF: 67.94,   AS_WF: 0,       PE1_WF: 169.85,  PT_WF: 599.04,  PE2_WF: 169.85,  ER_WF: 0,      SC_PS: '2025-02-10', SC_PF: '2025-02-27', SC_PP: 25,    SC_AS: '2025-02-11', SC_AF: undefined,    SC_AP: 50,  FB_PS: '2025-02-28', FB_PF: '2025-04-21', FB_PP: 0,     FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: undefined,    AS_PF: undefined,    AS_PP: 0,    AS_AS: undefined,    AS_AF: undefined,    AS_AP: 0,    PE1_PS: '2025-04-22', PE1_PF: '2025-05-12', PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: '2025-05-13', PT_PF: '2025-06-04', PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: '2025-06-05', PE2_PF: '2025-07-03', PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: undefined,    ER_PF: undefined,    ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'HULL',    ZONE: 'NODE',           MOD: 'Node, NW',            LEVEL: 'NW, Node',            NAME: 'Grating Beam P/F',       G_EREC: 'WN112', G_2PE: 'WN112', G_1PE: 'WNG30', EREC: '',      PE2: '',      PE1: '',      PBLK: 'WNG30', BLK: 'WNG30', L: '3.6',  B: '5.3',  H: '1.3',  YARD: 'HHI',   SC_WF: 8,      FB_WF: 16,      AS_WF: 0,       PE1_WF: 32,      PT_WF: 129.79,  PE2_WF: 32,      ER_WF: 0,      SC_PS: '2025-02-10', SC_PF: '2025-02-27', SC_PP: 25,    SC_AS: '2025-02-11', SC_AF: undefined,    SC_AP: 25,  FB_PS: '2025-02-28', FB_PF: '2025-04-21', FB_PP: 0,     FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: undefined,    AS_PF: undefined,    AS_PP: 0,    AS_AS: undefined,    AS_AF: undefined,    AS_AP: 0,    PE1_PS: '2025-04-22', PE1_PF: '2025-05-12', PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: '2025-05-13', PT_PF: '2025-06-04', PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: '2025-06-05', PE2_PF: '2025-07-03', PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: undefined,    ER_PF: undefined,    ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'HULL',    ZONE: 'NODE',           MOD: 'Node, NW',            LEVEL: 'NW, Node',            NAME: 'Diagonal Node',          G_EREC: 'WN161', G_2PE: 'WN161', G_1PE: 'WN161', EREC: 'WN161', PE2: '',      PE1: 'WN161', PBLK: 'WN161', BLK: 'WN160', L: '8',    B: '14.5', H: '9.5',  YARD: 'HHI',   SC_WF: 247.42, FB_WF: 494.83,  AS_WF: 742.25,  PE1_WF: 1237.08, PT_WF: 779.11,  PE2_WF: 0,       ER_WF: 0,      SC_PS: '2025-04-04', SC_PF: '2025-04-23', SC_PP: 0,     SC_AS: undefined,    SC_AF: undefined,    SC_AP: 0,   FB_PS: '2025-04-24', FB_PF: '2025-05-26', FB_PP: 0,     FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: '2025-05-27', AS_PF: '2025-07-14', AS_PP: 0,    AS_AS: undefined,    AS_AF: undefined,    AS_AP: 0,    PE1_PS: '2025-07-15', PE1_PF: '2025-08-25', PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: '2025-08-27', PT_PF: '2025-09-16', PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: undefined,    PE2_PF: undefined,    PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: undefined,    ER_PF: undefined,    ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'HULL',    ZONE: 'NODE',           MOD: 'Node, NW',            LEVEL: 'NW, Node',            NAME: 'Diagonal Node',          G_EREC: 'WN161', G_2PE: 'WN161', G_1PE: 'WN161', EREC: '',      PE2: '',      PE1: '',      PBLK: '',      BLK: 'WN170', L: '12.7', B: '8',    H: '9.5',  YARD: 'HHI',   SC_WF: 291.3,  FB_WF: 582.59,  AS_WF: 873.89,  PE1_WF: 1456.48, PT_WF: 0,       PE2_WF: 0,       ER_WF: 0,      SC_PS: '2025-04-08', SC_PF: '2025-04-25', SC_PP: 0,     SC_AS: undefined,    SC_AF: undefined,    SC_AP: 0,   FB_PS: '2025-04-28', FB_PF: '2025-05-28', FB_PP: 0,     FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: '2025-05-29', AS_PF: '2025-07-16', AS_PP: 0,    AS_AS: undefined,    AS_AF: undefined,    AS_AP: 0,    PE1_PS: '2025-07-17', PE1_PF: '2025-08-26', PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: undefined,    PT_PF: undefined,    PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: undefined,    PE2_PF: undefined,    PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: undefined,    ER_PF: undefined,    ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'HULL',    ZONE: 'NODE',           MOD: 'Node, SW',            LEVEL: 'SW, Node',            NAME: 'Node  Shell',            G_EREC: 'WN212', G_2PE: 'WN212', G_1PE: 'WN211', EREC: 'WN212', PE2: '',      PE1: 'WN211', PBLK: 'WN211', BLK: 'WN210', L: '19.6', B: '19.6', H: '2.8',  YARD: 'HHI',   SC_WF: 835.67, FB_WF: 1671.34, AS_WF: 2507.01, PE1_WF: 0,       PT_WF: 2711.73, PE2_WF: 4178.34, ER_WF: 0,      SC_PS: '2025-01-16', SC_PF: '2025-02-07', SC_PP: 100,   SC_AS: '2025-01-31', SC_AF: '2025-02-11', SC_AP: 100, FB_PS: '2025-02-10', FB_PF: '2025-03-11', FB_PP: 15.9,  FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: '2025-03-12', AS_PF: '2025-05-06', AS_PP: 0,    AS_AS: undefined,    AS_AF: undefined,    AS_AP: 0,    PE1_PS: undefined,    PE1_PF: undefined,    PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: '2025-06-11', PT_PF: '2025-07-17', PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: '2025-07-18', PE2_PF: '2025-09-04', PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: undefined,    ER_PF: undefined,    ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'HULL',    ZONE: 'NODE',           MOD: 'Node, SW',            LEVEL: 'SW, Node',            NAME: 'Node Support',           G_EREC: 'WN212', G_2PE: 'WN212', G_1PE: 'WN211', EREC: '',      PE2: '',      PE1: '',      PBLK: '',      BLK: 'WNSB0', L: '1(n)', B: '1(n)', H: '1(n)', YARD: 'HHI',   SC_WF: 8,      FB_WF: 8,       AS_WF: 0,       PE1_WF: 24,      PT_WF: 0,       PE2_WF: 0,       ER_WF: 0,      SC_PS: '2025-03-06', SC_PF: '2025-03-25', SC_PP: 0,     SC_AS: undefined,    SC_AF: undefined,    SC_AP: 0,   FB_PS: '2025-03-26', FB_PF: '2025-05-13', FB_PP: 0,     FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: undefined,    AS_PF: undefined,    AS_PP: 0,    AS_AS: undefined,    AS_AF: undefined,    AS_AP: 0,    PE1_PS: '2025-05-14', PE1_PF: '2025-06-03', PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: undefined,    PT_PF: undefined,    PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: undefined,    PE2_PF: undefined,    PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: undefined,    ER_PF: undefined,    ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'HULL',    ZONE: 'NODE',           MOD: 'Node, SW',            LEVEL: 'SW, Node',            NAME: 'Node  Shell',            G_EREC: 'WN212', G_2PE: 'WN212', G_1PE: 'WN221', EREC: '',      PE2: '',      PE1: 'WN221', PBLK: 'WN221', BLK: 'WN220', L: '13.3', B: '7.8',  H: '6.7',  YARD: 'HHI',   SC_WF: 400.85, FB_WF: 801.7,   AS_WF: 1202.55, PE1_WF: 2004.25, PT_WF: 602.61,  PE2_WF: 2004.25, ER_WF: 0,      SC_PS: '2025-02-19', SC_PF: '2025-03-10', SC_PP: 0,     SC_AS: undefined,    SC_AF: undefined,    SC_AP: 0,   FB_PS: '2025-03-11', FB_PF: '2025-04-07', FB_PP: 0,     FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: '2025-04-08', AS_PF: '2025-05-28', AS_PP: 0,    AS_AS: undefined,    AS_AF: undefined,    AS_AP: 0,    PE1_PS: '2025-05-29', PE1_PF: '2025-06-27', PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: '2025-06-30', PT_PF: '2025-07-21', PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: '2025-07-22', PE2_PF: '2025-09-04', PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: undefined,    ER_PF: undefined,    ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'HULL',    ZONE: 'NODE',           MOD: 'Node, SW',            LEVEL: 'SW, Node',            NAME: 'Node  Shell',            G_EREC: 'WN212', G_2PE: 'WN212', G_1PE: 'WN221', EREC: '',      PE2: '',      PE1: '',      PBLK: '',      BLK: 'WN230', L: '7.6',  B: '13',   H: '6.8',  YARD: 'HHI',   SC_WF: 369.99, FB_WF: 739.99,  AS_WF: 1109.98, PE1_WF: 1849.96, PT_WF: 0,       PE2_WF: 0,       ER_WF: 0,      SC_PS: '2025-02-19', SC_PF: '2025-03-10', SC_PP: 0,     SC_AS: undefined,    SC_AF: undefined,    SC_AP: 0,   FB_PS: '2025-03-11', FB_PF: '2025-04-07', FB_PP: 0,     FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: '2025-04-08', AS_PF: '2025-05-28', AS_PP: 0,    AS_AS: undefined,    AS_AF: undefined,    AS_AP: 0,    PE1_PS: '2025-05-29', PE1_PF: '2025-06-26', PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: undefined,    PT_PF: undefined,    PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: undefined,    PE2_PF: undefined,    PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: undefined,    ER_PF: undefined,    ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'HULL',    ZONE: 'NODE',           MOD: 'Node, SW',            LEVEL: 'SW, Node',            NAME: 'Node  Shell',            G_EREC: 'WN212', G_2PE: 'WN212', G_1PE: 'WN221', EREC: '',      PE2: '',      PE1: '',      PBLK: '',      BLK: 'WN240', L: '7.5',  B: '13.3', H: '6.7',  YARD: 'HHI',   SC_WF: 393.77, FB_WF: 787.54,  AS_WF: 1181.32, PE1_WF: 1968.86, PT_WF: 0,       PE2_WF: 0,       ER_WF: 0,      SC_PS: '2025-02-25', SC_PF: '2025-03-14', SC_PP: 0,     SC_AS: undefined,    SC_AF: undefined,    SC_AP: 0,   FB_PS: '2025-03-17', FB_PF: '2025-04-11', FB_PP: 0,     FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: '2025-04-14', AS_PF: '2025-06-02', AS_PP: 0,    AS_AS: undefined,    AS_AF: undefined,    AS_AP: 0,    PE1_PS: '2025-06-03', PE1_PF: '2025-06-26', PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: undefined,    PT_PF: undefined,    PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: undefined,    PE2_PF: undefined,    PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: undefined,    ER_PF: undefined,    ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'HULL',    ZONE: 'NODE',           MOD: 'Node, SW',            LEVEL: 'SW, Node',            NAME: 'Node  Shell',            G_EREC: 'WN212', G_2PE: 'WN212', G_1PE: 'WN221', EREC: '',      PE2: '',      PE1: '',      PBLK: '',      BLK: 'WN250', L: '13',   B: '7.5',  H: '6.7',  YARD: 'HHI',   SC_WF: 335.74, FB_WF: 671.48,  AS_WF: 1007.22, PE1_WF: 1678.7,  PT_WF: 0,       PE2_WF: 0,       ER_WF: 0,      SC_PS: '2025-02-19', SC_PF: '2025-03-10', SC_PP: 0,     SC_AS: undefined,    SC_AF: undefined,    SC_AP: 0,   FB_PS: '2025-03-11', FB_PF: '2025-04-07', FB_PP: 0,     FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: '2025-04-08', AS_PF: '2025-06-03', AS_PP: 0,    AS_AS: undefined,    AS_AF: undefined,    AS_AP: 0,    PE1_PS: '2025-06-04', PE1_PF: '2025-06-26', PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: undefined,    PT_PF: undefined,    PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: undefined,    PE2_PF: undefined,    PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: undefined,    ER_PF: undefined,    ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'HULL',    ZONE: 'NODE',           MOD: 'Node, SW',            LEVEL: 'SW, Node',            NAME: 'Node Support',           G_EREC: 'WN212', G_2PE: 'WN212', G_1PE: 'WN221', EREC: '',      PE2: '',      PE1: '',      PBLK: '',      BLK: 'WNS20', L: '1(n)', B: '1(n)', H: '1(n)', YARD: 'HHI',   SC_WF: 8,      FB_WF: 8,       AS_WF: 0,       PE1_WF: 24,      PT_WF: 0,       PE2_WF: 0,       ER_WF: 0,      SC_PS: '2025-03-21', SC_PF: '2025-04-16', SC_PP: 0,     SC_AS: undefined,    SC_AF: undefined,    SC_AP: 0,   FB_PS: '2025-04-17', FB_PF: '2025-06-04', FB_PP: 0,     FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: undefined,    AS_PF: undefined,    AS_PP: 0,    AS_AS: undefined,    AS_AF: undefined,    AS_AP: 0,    PE1_PS: '2025-06-05', PE1_PF: '2025-06-26', PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: undefined,    PT_PF: undefined,    PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: undefined,    PE2_PF: undefined,    PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: undefined,    ER_PF: undefined,    ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'HULL',    ZONE: 'NODE',           MOD: 'Node, SW',            LEVEL: 'SW, Node',            NAME: 'Grating Beam P/F',       G_EREC: 'WN212', G_2PE: 'WN212', G_1PE: 'WNGA0', EREC: '',      PE2: '',      PE1: '',      PBLK: 'WNGA0', BLK: 'WNGA0', L: '6.6',  B: '6.6',  H: '1.4',  YARD: 'HHI',   SC_WF: 24,     FB_WF: 40,      AS_WF: 0,       PE1_WF: 70.77,   PT_WF: 310.65,  PE2_WF: 80,      ER_WF: 0,      SC_PS: '2025-03-31', SC_PF: '2025-04-17', SC_PP: 0,     SC_AS: undefined,    SC_AF: undefined,    SC_AP: 0,   FB_PS: '2025-04-18', FB_PF: '2025-06-13', FB_PP: 0,     FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: undefined,    AS_PF: undefined,    AS_PP: 0,    AS_AS: undefined,    AS_AF: undefined,    AS_AP: 0,    PE1_PS: '2025-06-16', PE1_PF: '2025-07-01', PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: '2025-07-02', PT_PF: '2025-07-24', PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: '2025-07-25', PE2_PF: '2025-09-03', PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: undefined,    ER_PF: undefined,    ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'HULL',    ZONE: 'NODE',           MOD: 'Node, SW',            LEVEL: 'SW, Node',            NAME: 'Grating Beam P/F',       G_EREC: 'WN212', G_2PE: 'WN212', G_1PE: 'WNGB0', EREC: '',      PE2: '',      PE1: '',      PBLK: 'WNGB0', BLK: 'WNGB0', L: '12',   B: '7.1',  H: '1.5',  YARD: 'HHI',   SC_WF: 33.97,  FB_WF: 67.94,   AS_WF: 0,       PE1_WF: 169.85,  PT_WF: 599.04,  PE2_WF: 169.85,  ER_WF: 0,      SC_PS: '2025-03-31', SC_PF: '2025-04-17', SC_PP: 0,     SC_AS: undefined,    SC_AF: undefined,    SC_AP: 0,   FB_PS: '2025-04-18', FB_PF: '2025-06-13', FB_PP: 0,     FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: undefined,    AS_PF: undefined,    AS_PP: 0,    AS_AS: undefined,    AS_AF: undefined,    AS_AP: 0,    PE1_PS: '2025-06-16', PE1_PF: '2025-07-01', PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: '2025-07-02', PT_PF: '2025-07-24', PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: '2025-07-25', PE2_PF: '2025-09-03', PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: undefined,    ER_PF: undefined,    ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'HULL',    ZONE: 'NODE',           MOD: 'Node, SW',            LEVEL: 'SW, Node',            NAME: 'Grating Beam P/F',       G_EREC: 'WN212', G_2PE: 'WN212', G_1PE: 'WNGC0', EREC: '',      PE2: '',      PE1: '',      PBLK: 'WNGC0', BLK: 'WNGC0', L: '3.6',  B: '5.3',  H: '1.3',  YARD: 'HHI',   SC_WF: 8,      FB_WF: 16,      AS_WF: 0,       PE1_WF: 32,      PT_WF: 129.79,  PE2_WF: 32,      ER_WF: 0,      SC_PS: '2025-03-31', SC_PF: '2025-04-17', SC_PP: 0,     SC_AS: undefined,    SC_AF: undefined,    SC_AP: 0,   FB_PS: '2025-04-18', FB_PF: '2025-06-13', FB_PP: 0,     FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: undefined,    AS_PF: undefined,    AS_PP: 0,    AS_AS: undefined,    AS_AF: undefined,    AS_AP: 0,    PE1_PS: '2025-06-16', PE1_PF: '2025-07-01', PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: '2025-07-02', PT_PF: '2025-07-24', PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: '2025-07-25', PE2_PF: '2025-09-03', PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: undefined,    ER_PF: undefined,    ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'HULL',    ZONE: 'NODE',           MOD: 'Node, SW',            LEVEL: 'SW, Node',            NAME: 'Diagonal Node',          G_EREC: 'WN261', G_2PE: 'WN261', G_1PE: 'WN261', EREC: 'WN261', PE2: '',      PE1: 'WN261', PBLK: 'WN261', BLK: 'WN260', L: '8',    B: '12.7', H: '9.5',  YARD: 'HHI',   SC_WF: 247.42, FB_WF: 494.83,  AS_WF: 742.25,  PE1_WF: 1237.08, PT_WF: 779.11,  PE2_WF: 0,       ER_WF: 0,      SC_PS: '2025-04-17', SC_PF: '2025-05-09', SC_PP: 0,     SC_AS: undefined,    SC_AF: undefined,    SC_AP: 0,   FB_PS: '2025-05-12', FB_PF: '2025-06-09', FB_PP: 0,     FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: '2025-06-10', AS_PF: '2025-07-24', AS_PP: 0,    AS_AS: undefined,    AS_AF: undefined,    AS_AP: 0,    PE1_PS: '2025-07-25', PE1_PF: '2025-09-08', PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: '2025-09-09', PT_PF: '2025-09-29', PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: undefined,    PE2_PF: undefined,    PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: undefined,    ER_PF: undefined,    ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'HULL',    ZONE: 'NODE',           MOD: 'Node, SW',            LEVEL: 'SW, Node',            NAME: 'Diagonal Node',          G_EREC: 'WN261', G_2PE: 'WN261', G_1PE: 'WN261', EREC: '',      PE2: '',      PE1: '',      PBLK: '',      BLK: 'WN270', L: '14.5', B: '8',    H: '9.5',  YARD: 'HHI',   SC_WF: 291.3,  FB_WF: 582.59,  AS_WF: 873.89,  PE1_WF: 1456.48, PT_WF: 0,       PE2_WF: 0,       ER_WF: 0,      SC_PS: '2025-04-21', SC_PF: '2025-05-13', SC_PP: 0,     SC_AS: undefined,    SC_AF: undefined,    SC_AP: 0,   FB_PS: '2025-05-14', FB_PF: '2025-06-11', FB_PP: 0,     FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: '2025-06-12', AS_PF: '2025-07-25', AS_PP: 0,    AS_AS: undefined,    AS_AF: undefined,    AS_AP: 0,    PE1_PS: '2025-08-08', PE1_PF: '2025-09-04', PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: undefined,    PT_PF: undefined,    PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: undefined,    PE2_PF: undefined,    PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: undefined,    ER_PF: undefined,    ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'HULL',    ZONE: 'NODE',           MOD: 'Node, NE',            LEVEL: 'NE, Node',            NAME: 'Node  Shell',            G_EREC: 'EN112', G_2PE: 'EN112', G_1PE: 'EN111', EREC: 'EN112', PE2: 'EN112', PE1: 'EN111', PBLK: 'EN111', BLK: 'EN110', L: '19.6', B: '19.6', H: '2.8',  YARD: 'HHI',   SC_WF: 835.67, FB_WF: 1671.34, AS_WF: 2507.01, PE1_WF: 0,       PT_WF: 2711.73, PE2_WF: 4178.34, ER_WF: 2073.7, SC_PS: '2024-11-01', SC_PF: '2024-11-13', SC_PP: 100,   SC_AS: '2024-11-01', SC_AF: '2024-11-12', SC_AP: 100, FB_PS: '2024-11-14', FB_PF: '2024-12-12', FB_PP: 100,   FB_AS: '2024-11-15', FB_AF: '2024-12-09', FB_AP: 100,  AS_PS: '2024-12-13', AS_PF: '2025-02-11', AS_PP: 100,  AS_AS: '2024-12-09', AS_AF: undefined,    AS_AP: 90,   PE1_PS: undefined,    PE1_PF: undefined,    PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: '2025-03-14', PT_PF: '2025-04-07', PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: '2025-04-08', PE2_PF: '2025-06-27', PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: '2025-06-30', ER_PF: '2025-07-25', ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'HULL',    ZONE: 'NODE',           MOD: 'Node, NE',            LEVEL: 'NE, Node',            NAME: 'Node  Shell',            G_EREC: 'EN112', G_2PE: 'EN112', G_1PE: 'EN111', EREC: '',      PE2: '',      PE1: '',      PBLK: '',      BLK: 'ENSA0', L: '1(n)', B: '1(n)', H: '1(n)', YARD: 'HHI',   SC_WF: 8,      FB_WF: 8,       AS_WF: 0,       PE1_WF: 24,      PT_WF: 0,       PE2_WF: 0,       ER_WF: 0,      SC_PS: '2024-12-09', SC_PF: '2024-12-27', SC_PP: 100,   SC_AS: '2024-12-20', SC_AF: '2024-12-27', SC_AP: 100, FB_PS: '2024-12-30', FB_PF: '2025-02-18', FB_PP: 92.1,  FB_AS: '2025-01-24', FB_AF: '2025-01-31', FB_AP: 100,  AS_PS: undefined,    AS_PF: undefined,    AS_PP: 0,    AS_AS: undefined,    AS_AF: undefined,    AS_AP: 0,    PE1_PS: '2025-02-19', PE1_PF: '2025-03-11', PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: undefined,    PT_PF: undefined,    PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: undefined,    PE2_PF: undefined,    PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: undefined,    ER_PF: undefined,    ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'HULL',    ZONE: 'NODE',           MOD: 'Node, NE',            LEVEL: 'NE, Node',            NAME: 'Node  Shell',            G_EREC: 'EN112', G_2PE: 'EN112', G_1PE: 'EN121', EREC: '',      PE2: '',      PE1: 'EN121', PBLK: '',      BLK: 'EN120', L: '13.3', B: '7.8',  H: '6.7',  YARD: 'HHI',   SC_WF: 438.5,  FB_WF: 877,     AS_WF: 1315.5,  PE1_WF: 2192.5,  PT_WF: 602.61,  PE2_WF: 2192.5,  ER_WF: 0,      SC_PS: '2024-11-04', SC_PF: '2024-11-19', SC_PP: 100,   SC_AS: '2024-11-09', SC_AF: '2024-11-19', SC_AP: 100, FB_PS: '2024-11-20', FB_PF: '2024-12-17', FB_PP: 100,   FB_AS: '2024-11-15', FB_AF: '2024-12-13', FB_AP: 100,  AS_PS: '2024-12-18', AS_PF: '2025-02-11', AS_PP: 100,  AS_AS: '2024-12-18', AS_AF: undefined,    AS_AP: 80,   PE1_PS: '2025-02-12', PE1_PF: '2025-03-13', PE1_PP: 9.5,  PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: '2025-03-14', PT_PF: '2025-04-07', PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: '2025-04-08', PE2_PF: '2025-06-27', PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: undefined,    ER_PF: undefined,    ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'HULL',    ZONE: 'NODE',           MOD: 'Node, NE',            LEVEL: 'NE, Node',            NAME: 'Node  Shell',            G_EREC: 'EN112', G_2PE: 'EN112', G_1PE: 'EN121', EREC: '',      PE2: '',      PE1: '',      PBLK: '',      BLK: 'EN130', L: '7.6',  B: '13',   H: '6.8',  YARD: 'HHI',   SC_WF: 369.99, FB_WF: 739.99,  AS_WF: 1109.98, PE1_WF: 1849.96, PT_WF: 0,       PE2_WF: 0,       ER_WF: 0,      SC_PS: '2024-11-04', SC_PF: '2024-11-19', SC_PP: 100,   SC_AS: '2024-11-11', SC_AF: '2024-11-18', SC_AP: 100, FB_PS: '2024-11-20', FB_PF: '2024-12-17', FB_PP: 100,   FB_AS: '2024-11-15', FB_AF: '2024-12-13', FB_AP: 100,  AS_PS: '2024-12-18', AS_PF: '2025-02-11', AS_PP: 100,  AS_AS: '2024-12-19', AS_AF: '2025-02-12', AS_AP: 100,  PE1_PS: '2025-02-12', PE1_PF: '2025-03-11', PE1_PP: 10.5, PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: undefined,    PT_PF: undefined,    PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: undefined,    PE2_PF: undefined,    PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: undefined,    ER_PF: undefined,    ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'HULL',    ZONE: 'NODE',           MOD: 'Node, NE',            LEVEL: 'NE, Node',            NAME: 'Node  Shell',            G_EREC: 'EN112', G_2PE: 'EN112', G_1PE: 'EN121', EREC: '',      PE2: '',      PE1: '',      PBLK: '',      BLK: 'EN140', L: '7.5',  B: '13.3', H: '6.7',  YARD: 'HHI',   SC_WF: 422.65, FB_WF: 845.29,  AS_WF: 1267.94, PE1_WF: 2113.23, PT_WF: 0,       PE2_WF: 0,       ER_WF: 0,      SC_PS: '2024-11-04', SC_PF: '2024-11-19', SC_PP: 100,   SC_AS: '2024-11-12', SC_AF: '2024-11-19', SC_AP: 100, FB_PS: '2024-11-20', FB_PF: '2024-12-17', FB_PP: 100,   FB_AS: '2024-11-22', FB_AF: '2024-12-21', FB_AP: 100,  AS_PS: '2024-12-18', AS_PF: '2025-02-11', AS_PP: 100,  AS_AS: '2024-12-24', AS_AF: undefined,    AS_AP: 95,   PE1_PS: '2025-02-12', PE1_PF: '2025-03-11', PE1_PP: 10.5, PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: undefined,    PT_PF: undefined,    PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: undefined,    PE2_PF: undefined,    PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: undefined,    ER_PF: undefined,    ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'HULL',    ZONE: 'NODE',           MOD: 'Node, NE',            LEVEL: 'NE, Node',            NAME: 'Node  Shell',            G_EREC: 'EN112', G_2PE: 'EN112', G_1PE: 'EN121', EREC: '',      PE2: '',      PE1: '',      PBLK: '',      BLK: 'EN150', L: '13',   B: '7.5',  H: '6.7',  YARD: 'HHI',   SC_WF: 338.85, FB_WF: 677.71,  AS_WF: 1016.56, PE1_WF: 1694.27, PT_WF: 0,       PE2_WF: 0,       ER_WF: 0,      SC_PS: '2024-11-08', SC_PF: '2024-11-25', SC_PP: 100,   SC_AS: '2024-11-16', SC_AF: '2024-11-28', SC_AP: 100, FB_PS: '2024-11-26', FB_PF: '2024-12-23', FB_PP: 100,   FB_AS: '2024-11-29', FB_AF: '2024-12-27', FB_AP: 100,  AS_PS: '2024-12-24', AS_PF: '2025-02-17', AS_PP: 96.3, AS_AS: '2025-01-03', AS_AF: undefined,    AS_AP: 70,   PE1_PS: '2025-02-18', PE1_PF: '2025-03-11', PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: undefined,    PT_PF: undefined,    PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: undefined,    PE2_PF: undefined,    PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: undefined,    ER_PF: undefined,    ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'HULL',    ZONE: 'NODE',           MOD: 'Node, NE',            LEVEL: 'NE, Node',            NAME: 'Node Support',           G_EREC: 'EN112', G_2PE: 'EN112', G_1PE: 'EN121', EREC: '',      PE2: '',      PE1: '',      PBLK: '',      BLK: 'ENS10', L: '1(n)', B: '1(n)', H: '1(n)', YARD: 'HHI',   SC_WF: 8,      FB_WF: 8,       AS_WF: 0,       PE1_WF: 24,      PT_WF: 0,       PE2_WF: 0,       ER_WF: 0,      SC_PS: '2024-12-09', SC_PF: '2024-12-27', SC_PP: 100,   SC_AS: '2024-12-20', SC_AF: '2024-12-27', SC_AP: 100, FB_PS: '2024-12-30', FB_PF: '2025-02-18', FB_PP: 92.1,  FB_AS: '2024-12-30', FB_AF: '2025-02-04', FB_AP: 100,  AS_PS: undefined,    AS_PF: undefined,    AS_PP: 0,    AS_AS: undefined,    AS_AF: undefined,    AS_AP: 0,    PE1_PS: '2025-02-19', PE1_PF: '2025-03-11', PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: undefined,    PT_PF: undefined,    PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: undefined,    PE2_PF: undefined,    PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: undefined,    ER_PF: undefined,    ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'HULL',    ZONE: 'NODE',           MOD: 'Node, NE',            LEVEL: 'NE, Node',            NAME: 'Grating Beam P/F',       G_EREC: 'EN112', G_2PE: 'EN112', G_1PE: 'ENG10', EREC: '',      PE2: '',      PE1: '',      PBLK: 'ENG10', BLK: 'ENG10', L: '6.6',  B: '6.6',  H: '1.4',  YARD: 'HHI',   SC_WF: 24,     FB_WF: 40,      AS_WF: 0,       PE1_WF: 70.77,   PT_WF: 310.65,  PE2_WF: 80,      ER_WF: 0,      SC_PS: '2024-12-16', SC_PF: '2025-01-07', SC_PP: 100,   SC_AS: '2025-12-31', SC_AF: '2025-01-06', SC_AP: 100, FB_PS: '2025-01-08', FB_PF: '2025-03-04', FB_PP: 57.9,  FB_AS: '2025-01-11', FB_AF: '2025-01-17', FB_AP: 100,  AS_PS: undefined,    AS_PF: undefined,    AS_PP: 0,    AS_AS: undefined,    AS_AF: undefined,    AS_AP: 0,    PE1_PS: '2025-03-05', PE1_PF: '2025-03-20', PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: '2025-03-21', PT_PF: '2025-04-14', PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: '2025-04-15', PE2_PF: '2025-05-15', PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: undefined,    ER_PF: undefined,    ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'HULL',    ZONE: 'NODE',           MOD: 'Node, NE',            LEVEL: 'NE, Node',            NAME: 'Grating Beam P/F',       G_EREC: 'EN112', G_2PE: 'EN112', G_1PE: 'ENG20', EREC: '',      PE2: '',      PE1: '',      PBLK: 'ENG20', BLK: 'ENG20', L: '12',   B: '7.1',  H: '1.5',  YARD: 'HHI',   SC_WF: 33.97,  FB_WF: 67.94,   AS_WF: 0,       PE1_WF: 169.85,  PT_WF: 599.04,  PE2_WF: 169.85,  ER_WF: 0,      SC_PS: '2024-12-16', SC_PF: '2025-01-07', SC_PP: 100,   SC_AS: '2025-01-01', SC_AF: '2025-01-14', SC_AP: 100, FB_PS: '2025-01-08', FB_PF: '2025-03-04', FB_PP: 57.8,  FB_AS: '2025-01-11', FB_AF: '2025-01-17', FB_AP: 100,  AS_PS: undefined,    AS_PF: undefined,    AS_PP: 0,    AS_AS: undefined,    AS_AF: undefined,    AS_AP: 0,    PE1_PS: '2025-03-05', PE1_PF: '2025-03-20', PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: '2025-03-21', PT_PF: '2025-04-14', PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: '2025-04-15', PE2_PF: '2025-05-15', PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: undefined,    ER_PF: undefined,    ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'HULL',    ZONE: 'NODE',           MOD: 'Node, NE',            LEVEL: 'NE, Node',            NAME: 'Grating Beam P/F',       G_EREC: 'EN112', G_2PE: 'EN112', G_1PE: 'ENG30', EREC: '',      PE2: '',      PE1: '',      PBLK: 'ENG30', BLK: 'ENG30', L: '3.6',  B: '5.3',  H: '1.3',  YARD: 'HHI',   SC_WF: 8,      FB_WF: 16,      AS_WF: 0,       PE1_WF: 32,      PT_WF: 129.79,  PE2_WF: 32,      ER_WF: 0,      SC_PS: '2024-12-16', SC_PF: '2025-01-07', SC_PP: 100,   SC_AS: '2025-12-31', SC_AF: '2025-01-06', SC_AP: 100, FB_PS: '2025-01-08', FB_PF: '2025-03-04', FB_PP: 57.8,  FB_AS: '2025-01-11', FB_AF: '2025-01-17', FB_AP: 100,  AS_PS: undefined,    AS_PF: undefined,    AS_PP: 0,    AS_AS: undefined,    AS_AF: undefined,    AS_AP: 0,    PE1_PS: '2025-03-05', PE1_PF: '2025-03-20', PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: '2025-03-21', PT_PF: '2025-04-14', PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: '2025-04-15', PE2_PF: '2025-05-15', PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: undefined,    ER_PF: undefined,    ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'HULL',    ZONE: 'NODE',           MOD: 'Node, NE',            LEVEL: 'NE, Node',            NAME: 'Diagonal Node',          G_EREC: 'EN161', G_2PE: 'EN161', G_1PE: 'EN161', EREC: 'EN161', PE2: '',      PE1: 'EN161', PBLK: 'EN161', BLK: 'EN160', L: '8',    B: '12.7', H: '9.5',  YARD: 'HHI',   SC_WF: 247.42, FB_WF: 494.83,  AS_WF: 742.25,  PE1_WF: 1237.08, PT_WF: 779.11,  PE2_WF: 1237.08, ER_WF: 0,      SC_PS: '2025-01-13', SC_PF: '2025-02-04', SC_PP: 100,   SC_AS: '2025-01-16', SC_AF: '2025-01-31', SC_AP: 100, FB_PS: '2025-02-05', FB_PF: '2025-03-04', FB_PP: 28,    FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: '2025-03-05', AS_PF: '2025-04-21', AS_PP: 0,    AS_AS: undefined,    AS_AF: undefined,    AS_AP: 0,    PE1_PS: '2025-04-22', PE1_PF: '2025-05-26', PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: '2025-05-27', PT_PF: '2025-06-19', PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: '2025-06-20', PE2_PF: '2025-07-14', PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: undefined,    ER_PF: undefined,    ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'HULL',    ZONE: 'NODE',           MOD: 'Node, NE',            LEVEL: 'NE, Node',            NAME: 'Diagonal Node',          G_EREC: 'EN161', G_2PE: 'EN161', G_1PE: 'EN161', EREC: '',      PE2: '',      PE1: '',      PBLK: '',      BLK: 'EN170', L: '14.5', B: '8',    H: '9.5',  YARD: 'HHI',   SC_WF: 291.3,  FB_WF: 582.59,  AS_WF: 873.89,  PE1_WF: 1456.48, PT_WF: 0,       PE2_WF: 0,       ER_WF: 0,      SC_PS: '2025-01-13', SC_PF: '2025-02-04', SC_PP: 100,   SC_AS: '2025-01-15', SC_AF: '2025-01-31', SC_AP: 100, FB_PS: '2025-02-05', FB_PF: '2025-03-04', FB_PP: 28,    FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: '2025-03-05', AS_PF: '2025-04-21', AS_PP: 0,    AS_AS: undefined,    AS_AF: undefined,    AS_AP: 0,    PE1_PS: '2025-04-22', PE1_PF: '2025-05-26', PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: undefined,    PT_PF: undefined,    PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: undefined,    PE2_PF: undefined,    PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: undefined,    ER_PF: undefined,    ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'HULL',    ZONE: 'NODE',           MOD: 'Node, SE',            LEVEL: 'SE, Node',            NAME: 'Node  Shell',            G_EREC: 'EN212', G_2PE: 'EN212', G_1PE: 'EN211', EREC: 'EN212', PE2: 'EN212', PE1: 'EN211', PBLK: 'EN211', BLK: 'EN210', L: '19.6', B: '19.6', H: '2.8',  YARD: 'HHI',   SC_WF: 835.67, FB_WF: 1671.34, AS_WF: 2507.01, PE1_WF: 0,       PT_WF: 2711.73, PE2_WF: 4178.34, ER_WF: 2073.7, SC_PS: '2024-12-04', SC_PF: '2024-12-23', SC_PP: 100,   SC_AS: '2024-12-06', SC_AF: '2024-12-17', SC_AP: 100, FB_PS: '2024-12-24', FB_PF: '2025-01-27', FB_PP: 100,   FB_AS: '2024-12-26', FB_AF: undefined,    FB_AP: 80,   AS_PS: '2025-01-31', AS_PF: '2025-03-24', AS_PP: 20.8, AS_AS: undefined,    AS_AF: undefined,    AS_AP: 0,    PE1_PS: undefined,    PE1_PF: undefined,    PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: '2025-04-24', PT_PF: '2025-05-21', PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: '2025-05-22', PE2_PF: '2025-07-16', PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: '2025-07-17', ER_PF: '2025-08-26', ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'HULL',    ZONE: 'NODE',           MOD: 'Node, SE',            LEVEL: 'SE, Node',            NAME: 'Node Support',           G_EREC: 'EN212', G_2PE: 'EN212', G_1PE: 'EN211', EREC: '',      PE2: '',      PE1: '',      PBLK: '',      BLK: 'ENSB0', L: '1(n)', B: '1(n)', H: '1(n)', YARD: 'HHI',   SC_WF: 8,      FB_WF: 8,       AS_WF: 0,       PE1_WF: 24,      PT_WF: 0,       PE2_WF: 0,       ER_WF: 0,      SC_PS: '2025-01-14', SC_PF: '2025-02-13', SC_PP: 100,   SC_AS: '2025-01-25', SC_AF: '2025-02-11', SC_AP: 100, FB_PS: '2025-02-14', FB_PF: '2025-03-31', FB_PP: 2.3,   FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: undefined,    AS_PF: undefined,    AS_PP: 0,    AS_AS: undefined,    AS_AF: undefined,    AS_AP: 0,    PE1_PS: '2025-04-01', PE1_PF: '2025-04-23', PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: undefined,    PT_PF: undefined,    PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: undefined,    PE2_PF: undefined,    PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: undefined,    ER_PF: undefined,    ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'HULL',    ZONE: 'NODE',           MOD: 'Node, SE',            LEVEL: 'SE, Node',            NAME: 'Node  Shell',            G_EREC: 'EN212', G_2PE: 'EN212', G_1PE: 'EN221', EREC: '',      PE2: '',      PE1: 'EN221', PBLK: 'EN221', BLK: 'EN220', L: '13.3', B: '7.8',  H: '6.7',  YARD: 'HHI',   SC_WF: 438.5,  FB_WF: 877,     AS_WF: 1315.5,  PE1_WF: 2192.5,  PT_WF: 602.61,  PE2_WF: 2192.5,  ER_WF: 0,      SC_PS: '2024-12-16', SC_PF: '2025-01-07', SC_PP: 100,   SC_AS: '2024-12-21', SC_AF: '2025-01-05', SC_AP: 100, FB_PS: '2025-01-08', FB_PF: '2025-02-07', FB_PP: 100,   FB_AS: '2025-01-13', FB_AF: undefined,    FB_AP: 36.7, AS_PS: '2025-02-10', AS_PF: '2025-03-26', AS_PP: 10.6, AS_AS: undefined,    AS_AF: undefined,    AS_AP: 0,    PE1_PS: '2025-03-27', PE1_PF: '2025-04-25', PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: '2025-04-28', PT_PF: '2025-05-23', PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: '2025-05-26', PE2_PF: '2025-07-16', PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: undefined,    ER_PF: undefined,    ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'HULL',    ZONE: 'NODE',           MOD: 'Node, SE',            LEVEL: 'SE, Node',            NAME: 'Node  Shell',            G_EREC: 'EN212', G_2PE: 'EN212', G_1PE: 'EN221', EREC: '',      PE2: '',      PE1: '',      PBLK: '',      BLK: 'EN230', L: '7.6',  B: '13',   H: '6.8',  YARD: 'HHI',   SC_WF: 369.99, FB_WF: 739.99,  AS_WF: 1109.98, PE1_WF: 1849.96, PT_WF: 0,       PE2_WF: 0,       ER_WF: 0,      SC_PS: '2024-12-04', SC_PF: '2024-12-23', SC_PP: 100,   SC_AS: '2024-12-06', SC_AF: '2024-12-26', SC_AP: 100, FB_PS: '2024-12-24', FB_PF: '2025-01-23', FB_PP: 100,   FB_AS: '2024-12-26', FB_AF: '2025-01-21', FB_AP: 100,  AS_PS: '2025-01-24', AS_PF: '2025-03-26', AS_PP: 22.2, AS_AS: '2025-02-07', AS_AF: undefined,    AS_AP: 5,    PE1_PS: '2025-03-27', PE1_PF: '2025-04-23', PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: undefined,    PT_PF: undefined,    PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: undefined,    PE2_PF: undefined,    PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: undefined,    ER_PF: undefined,    ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'HULL',    ZONE: 'NODE',           MOD: 'Node, SE',            LEVEL: 'SE, Node',            NAME: 'Node  Shell',            G_EREC: 'EN212', G_2PE: 'EN212', G_1PE: 'EN221', EREC: '',      PE2: '',      PE1: '',      PBLK: '',      BLK: 'EN240', L: '7.5',  B: '13.3', H: '6.7',  YARD: 'HHI',   SC_WF: 393.77, FB_WF: 787.54,  AS_WF: 1181.32, PE1_WF: 1968.86, PT_WF: 0,       PE2_WF: 0,       ER_WF: 0,      SC_PS: '2024-12-10', SC_PF: '2024-12-30', SC_PP: 100,   SC_AS: '2024-12-13', SC_AF: '2024-12-25', SC_AP: 100, FB_PS: '2024-12-31', FB_PF: '2025-02-03', FB_PP: 100,   FB_AS: '2025-01-08', FB_AF: undefined,    FB_AP: 80,   AS_PS: '2025-02-04', AS_PF: '2025-03-28', AS_PP: 16.2, AS_AS: undefined,    AS_AF: undefined,    AS_AP: 0,    PE1_PS: '2025-03-31', PE1_PF: '2025-04-23', PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: undefined,    PT_PF: undefined,    PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: undefined,    PE2_PF: undefined,    PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: undefined,    ER_PF: undefined,    ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'HULL',    ZONE: 'NODE',           MOD: 'Node, SE',            LEVEL: 'SE, Node',            NAME: 'Node  Shell',            G_EREC: 'EN212', G_2PE: 'EN212', G_1PE: 'EN221', EREC: '',      PE2: '',      PE1: '',      PBLK: '',      BLK: 'EN250', L: '13',   B: '7.5',  H: '6.7',  YARD: 'HHI',   SC_WF: 343.1,  FB_WF: 686.2,   AS_WF: 1029.3,  PE1_WF: 1715.5,  PT_WF: 0,       PE2_WF: 0,       ER_WF: 0,      SC_PS: '2024-12-16', SC_PF: '2025-01-07', SC_PP: 100,   SC_AS: '2024-12-22', SC_AF: '2025-01-06', SC_AP: 100, FB_PS: '2025-01-08', FB_PF: '2025-02-07', FB_PP: 100,   FB_AS: '2025-01-15', FB_AF: undefined,    FB_AP: 80,   AS_PS: '2025-02-10', AS_PF: '2025-04-01', AS_PP: 9.5,  AS_AS: undefined,    AS_AF: undefined,    AS_AP: 0,    PE1_PS: '2025-04-02', PE1_PF: '2025-04-23', PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: undefined,    PT_PF: undefined,    PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: undefined,    PE2_PF: undefined,    PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: undefined,    ER_PF: undefined,    ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'HULL',    ZONE: 'NODE',           MOD: 'Node, SE',            LEVEL: 'SE, Node',            NAME: 'Node Support',           G_EREC: 'EN212', G_2PE: 'EN212', G_1PE: 'EN221', EREC: '',      PE2: '',      PE1: '',      PBLK: '',      BLK: 'ENS20', L: '1(n)', B: '1(n)', H: '1(n)', YARD: 'HHI',   SC_WF: 8,      FB_WF: 8,       AS_WF: 0,       PE1_WF: 24,      PT_WF: 0,       PE2_WF: 0,       ER_WF: 0,      SC_PS: '2025-01-24', SC_PF: '2025-02-17', SC_PP: 90.8,  SC_AS: '2025-01-16', SC_AF: '2025-01-31', SC_AP: 100, FB_PS: '2025-02-18', FB_PF: '2025-04-02', FB_PP: 0,     FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: undefined,    AS_PF: undefined,    AS_PP: 0,    AS_AS: undefined,    AS_AF: undefined,    AS_AP: 0,    PE1_PS: '2025-04-03', PE1_PF: '2025-04-23', PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: undefined,    PT_PF: undefined,    PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: undefined,    PE2_PF: undefined,    PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: undefined,    ER_PF: undefined,    ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'HULL',    ZONE: 'NODE',           MOD: 'Node, SE',            LEVEL: 'SE, Node',            NAME: 'Grating Beam P/F',       G_EREC: 'EN212', G_2PE: 'EN212', G_1PE: 'ENGA0', EREC: '',      PE2: '',      PE1: '',      PBLK: 'ENGA0', BLK: 'ENGA0', L: '6.6',  B: '6.6',  H: '1.4',  YARD: 'HHI',   SC_WF: 24,     FB_WF: 40,      AS_WF: 0,       PE1_WF: 70.77,   PT_WF: 310.65,  PE2_WF: 80,      ER_WF: 0,      SC_PS: '2025-02-03', SC_PF: '2025-02-20', SC_PP: 62.9,  SC_AS: '2025-01-22', SC_AF: '2025-01-25', SC_AP: 100, FB_PS: '2025-02-21', FB_PF: '2025-04-14', FB_PP: 0,     FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: undefined,    AS_PF: undefined,    AS_PP: 0,    AS_AS: undefined,    AS_AF: undefined,    AS_AP: 0,    PE1_PS: '2025-04-15', PE1_PF: '2025-04-30', PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: '2025-05-02', PT_PF: '2025-05-28', PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: '2025-05-29', PE2_PF: '2025-06-26', PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: undefined,    ER_PF: undefined,    ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'HULL',    ZONE: 'NODE',           MOD: 'Node, SE',            LEVEL: 'SE, Node',            NAME: 'Grating Beam P/F',       G_EREC: 'EN212', G_2PE: 'EN212', G_1PE: 'ENGB0', EREC: '',      PE2: '',      PE1: '',      PBLK: 'ENGB0', BLK: 'ENGB0', L: '12',   B: '7.1',  H: '1.5',  YARD: 'HHI',   SC_WF: 33.97,  FB_WF: 67.94,   AS_WF: 0,       PE1_WF: 169.85,  PT_WF: 599.04,  PE2_WF: 169.85,  ER_WF: 0,      SC_PS: '2025-02-03', SC_PF: '2025-02-20', SC_PP: 62.8,  SC_AS: '2025-01-24', SC_AF: '2025-01-31', SC_AP: 100, FB_PS: '2025-02-21', FB_PF: '2025-04-14', FB_PP: 0,     FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: undefined,    AS_PF: undefined,    AS_PP: 0,    AS_AS: undefined,    AS_AF: undefined,    AS_AP: 0,    PE1_PS: '2025-04-15', PE1_PF: '2025-04-30', PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: '2025-05-02', PT_PF: '2025-05-28', PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: '2025-05-29', PE2_PF: '2025-06-26', PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: undefined,    ER_PF: undefined,    ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'HULL',    ZONE: 'NODE',           MOD: 'Node, SE',            LEVEL: 'SE, Node',            NAME: 'Grating Beam P/F',       G_EREC: 'EN212', G_2PE: 'EN212', G_1PE: 'ENGC0', EREC: '',      PE2: '',      PE1: '',      PBLK: 'ENGC0', BLK: 'ENGC0', L: '3.6',  B: '5.3',  H: '1.3',  YARD: 'HHI',   SC_WF: 8,      FB_WF: 16,      AS_WF: 0,       PE1_WF: 32,      PT_WF: 129.79,  PE2_WF: 32,      ER_WF: 0,      SC_PS: '2025-02-03', SC_PF: '2025-02-20', SC_PP: 62.9,  SC_AS: '2025-01-22', SC_AF: '2025-01-25', SC_AP: 100, FB_PS: '2025-02-21', FB_PF: '2025-04-14', FB_PP: 0,     FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: undefined,    AS_PF: undefined,    AS_PP: 0,    AS_AS: undefined,    AS_AF: undefined,    AS_AP: 0,    PE1_PS: '2025-04-15', PE1_PF: '2025-04-30', PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: '2025-05-02', PT_PF: '2025-05-28', PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: '2025-05-29', PE2_PF: '2025-06-26', PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: undefined,    ER_PF: undefined,    ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'HULL',    ZONE: 'NODE',           MOD: 'Node, SE',            LEVEL: 'SE, Node',            NAME: 'Diagonal Node',          G_EREC: 'EN261', G_2PE: 'EN261', G_1PE: 'EN261', EREC: 'EN261', PE2: '',      PE1: 'EN261', PBLK: 'EN261', BLK: 'EN260', L: '8',    B: '14.5', H: '9.5',  YARD: 'HHI',   SC_WF: 247.42, FB_WF: 494.83,  AS_WF: 742.25,  PE1_WF: 1237.08, PT_WF: 779.11,  PE2_WF: 1237.08, ER_WF: 0,      SC_PS: '2025-02-04', SC_PF: '2025-02-21', SC_PP: 53.6,  SC_AS: '2025-02-10', SC_AF: undefined,    SC_AP: 7,   FB_PS: '2025-02-24', FB_PF: '2025-03-21', FB_PP: 0,     FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: '2025-03-24', AS_PF: '2025-05-13', AS_PP: 0,    AS_AS: undefined,    AS_AF: undefined,    AS_AP: 0,    PE1_PS: '2025-05-14', PE1_PF: '2025-06-13', PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: '2025-06-16', PT_PF: '2025-07-08', PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: '2025-07-09', PE2_PF: '2025-08-08', PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: undefined,    ER_PF: undefined,    ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'HULL',    ZONE: 'NODE',           MOD: 'Node, SE',            LEVEL: 'SE, Node',            NAME: 'Diagonal Node',          G_EREC: 'EN261', G_2PE: 'EN261', G_1PE: 'EN261', EREC: '',      PE2: '',      PE1: '',      PBLK: '',      BLK: 'EN270', L: '12.7', B: '8',    H: '9.5',  YARD: 'HHI',   SC_WF: 291.3,  FB_WF: 582.59,  AS_WF: 873.89,  PE1_WF: 1456.48, PT_WF: 0,       PE2_WF: 0,       ER_WF: 0,      SC_PS: '2025-02-06', SC_PF: '2025-02-25', SC_PP: 35,    SC_AS: '2025-02-11', SC_AF: undefined,    SC_AP: 16,  FB_PS: '2025-02-26', FB_PF: '2025-03-25', FB_PP: 0,     FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: '2025-03-26', AS_PF: '2025-05-15', AS_PP: 0,    AS_AS: undefined,    AS_AF: undefined,    AS_AP: 0,    PE1_PS: '2025-05-16', PE1_PF: '2025-06-11', PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: undefined,    PT_PF: undefined,    PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: undefined,    PE2_PF: undefined,    PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: undefined,    ER_PF: undefined,    ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'HULL',    ZONE: 'NODE',           MOD: 'Node, NW',            LEVEL: 'North-West',          NAME: 'Push Knee',              G_EREC: 'WN410', G_2PE: 'WN410', G_1PE: 'WN410', EREC: 'WN410', PE2: '',      PE1: '',      PBLK: 'WN410', BLK: 'WN410', L: '3.9',  B: '6.6',  H: '8.6',  YARD: 'HHI',   SC_WF: 104.74, FB_WF: 209.48,  AS_WF: 314.22,  PE1_WF: 0,       PT_WF: 252.17,  PE2_WF: 0,       ER_WF: 2073.7, SC_PS: '2025-06-05', SC_PF: '2025-06-25', SC_PP: 0,     SC_AS: undefined,    SC_AF: undefined,    SC_AP: 0,   FB_PS: '2025-06-26', FB_PF: '2025-07-18', FB_PP: 0,     FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: '2025-07-21', AS_PF: '2025-09-08', AS_PP: 0,    AS_AS: undefined,    AS_AF: undefined,    AS_AP: 0,    PE1_PS: undefined,    PE1_PF: undefined,    PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: '2025-09-18', PT_PF: '2025-10-17', PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: undefined,    PE2_PF: undefined,    PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: '2025-10-29', ER_PF: '2025-11-25', ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'HULL',    ZONE: 'NODE',           MOD: 'Node, NW',            LEVEL: 'North-West',          NAME: 'Push Knee',              G_EREC: 'WN420', G_2PE: 'WN420', G_1PE: 'WN420', EREC: 'WN420', PE2: '',      PE1: '',      PBLK: 'WN420', BLK: 'WN420', L: '6.6',  B: '3.9',  H: '8.6',  YARD: 'HHI',   SC_WF: 104.74, FB_WF: 209.48,  AS_WF: 314.22,  PE1_WF: 0,       PT_WF: 252.17,  PE2_WF: 0,       ER_WF: 2073.7, SC_PS: '2025-06-09', SC_PF: '2025-06-26', SC_PP: 0,     SC_AS: undefined,    SC_AF: undefined,    SC_AP: 0,   FB_PS: '2025-06-27', FB_PF: '2025-07-21', FB_PP: 0,     FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: '2025-07-22', AS_PF: '2025-09-09', AS_PP: 0,    AS_AS: undefined,    AS_AF: undefined,    AS_AP: 0,    PE1_PS: undefined,    PE1_PF: undefined,    PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: '2025-09-19', PT_PF: '2025-10-20', PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: undefined,    PE2_PF: undefined,    PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: '2025-10-30', ER_PF: '2025-11-26', ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'HULL',    ZONE: 'NODE',           MOD: 'Node, NW',            LEVEL: 'South-West',          NAME: 'Push Knee',              G_EREC: 'WN510', G_2PE: 'WN510', G_1PE: 'WN510', EREC: 'WN510', PE2: '',      PE1: '',      PBLK: 'WN510', BLK: 'WN510', L: '3.9',  B: '6.9',  H: '8.6',  YARD: 'HHI',   SC_WF: 104.74, FB_WF: 209.48,  AS_WF: 314.22,  PE1_WF: 0,       PT_WF: 252.17,  PE2_WF: 0,       ER_WF: 2073.7, SC_PS: '2025-06-11', SC_PF: '2025-06-30', SC_PP: 0,     SC_AS: undefined,    SC_AF: undefined,    SC_AP: 0,   FB_PS: '2025-07-01', FB_PF: '2025-07-23', FB_PP: 0,     FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: '2025-07-24', AS_PF: '2025-09-11', AS_PP: 0,    AS_AS: undefined,    AS_AF: undefined,    AS_AP: 0,    PE1_PS: undefined,    PE1_PF: undefined,    PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: '2025-08-18', PT_PF: '2025-09-10', PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: undefined,    PE2_PF: undefined,    PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: '2025-11-03', ER_PF: '2025-11-28', ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'HULL',    ZONE: 'NODE',           MOD: 'Node, NW',            LEVEL: 'South-West',          NAME: 'Push Knee',              G_EREC: 'WN520', G_2PE: 'WN520', G_1PE: 'WN520', EREC: 'WN520', PE2: '',      PE1: '',      PBLK: 'WN520', BLK: 'WN520', L: '6.6',  B: '3.9',  H: '8.6',  YARD: 'HHI',   SC_WF: 104.74, FB_WF: 209.48,  AS_WF: 314.22,  PE1_WF: 0,       PT_WF: 252.17,  PE2_WF: 0,       ER_WF: 2073.7, SC_PS: '2025-06-12', SC_PF: '2025-07-01', SC_PP: 0,     SC_AS: undefined,    SC_AF: undefined,    SC_AP: 0,   FB_PS: '2025-07-02', FB_PF: '2025-07-24', FB_PP: 0,     FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: '2025-07-25', AS_PF: '2025-09-12', AS_PP: 0,    AS_AS: undefined,    AS_AF: undefined,    AS_AP: 0,    PE1_PS: undefined,    PE1_PF: undefined,    PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: '2025-09-24', PT_PF: '2025-10-23', PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: undefined,    PE2_PF: undefined,    PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: '2025-11-04', ER_PF: '2025-12-01', ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'HULL',    ZONE: 'NODE',           MOD: 'Node, NE',            LEVEL: 'North-East',          NAME: 'Push Knee',              G_EREC: 'EN410', G_2PE: 'EN410', G_1PE: 'EN410', EREC: 'EN410', PE2: '',      PE1: '',      PBLK: 'EN410', BLK: 'EN410', L: '3.9',  B: '6.6',  H: '8.6',  YARD: 'HHI',   SC_WF: 104.74, FB_WF: 209.48,  AS_WF: 314.22,  PE1_WF: 0,       PT_WF: 252.17,  PE2_WF: 0,       ER_WF: 2073.7, SC_PS: '2025-05-19', SC_PF: '2025-06-05', SC_PP: 0,     SC_AS: undefined,    SC_AF: undefined,    SC_AP: 0,   FB_PS: '2025-06-09', FB_PF: '2025-07-01', FB_PP: 0,     FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: '2025-07-02', AS_PF: '2025-08-20', AS_PP: 0,    AS_AS: undefined,    AS_AF: undefined,    AS_AP: 0,    PE1_PS: undefined,    PE1_PF: undefined,    PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: '2025-09-01', PT_PF: '2025-09-23', PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: undefined,    PE2_PF: undefined,    PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: '2025-10-10', ER_PF: '2025-11-06', ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'HULL',    ZONE: 'NODE',           MOD: 'Node, NE',            LEVEL: 'North-East',          NAME: 'Push Knee',              G_EREC: 'EN420', G_2PE: 'EN420', G_1PE: 'EN420', EREC: 'EN420', PE2: '',      PE1: '',      PBLK: 'EN420', BLK: 'EN420', L: '6.6',  B: '3.9',  H: '8.9',  YARD: 'HHI',   SC_WF: 104.74, FB_WF: 209.48,  AS_WF: 314.22,  PE1_WF: 0,       PT_WF: 252.17,  PE2_WF: 0,       ER_WF: 2073.7, SC_PS: '2025-05-20', SC_PF: '2025-06-09', SC_PP: 0,     SC_AS: undefined,    SC_AF: undefined,    SC_AP: 0,   FB_PS: '2025-06-10', FB_PF: '2025-07-02', FB_PP: 0,     FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: '2025-07-03', AS_PF: '2025-08-21', AS_PP: 0,    AS_AS: undefined,    AS_AF: undefined,    AS_AP: 0,    PE1_PS: undefined,    PE1_PF: undefined,    PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: '2025-09-02', PT_PF: '2025-09-24', PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: undefined,    PE2_PF: undefined,    PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: '2025-10-13', ER_PF: '2025-11-07', ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'HULL',    ZONE: 'NODE',           MOD: 'Node, SE',            LEVEL: 'South-East',          NAME: 'Push Knee',              G_EREC: 'EN510', G_2PE: 'EN510', G_1PE: 'EN510', EREC: 'EN510', PE2: '',      PE1: '',      PBLK: 'EN510', BLK: 'EN510', L: '3.9',  B: '7',    H: '8.9',  YARD: 'HHI',   SC_WF: 104.74, FB_WF: 209.48,  AS_WF: 314.22,  PE1_WF: 0,       PT_WF: 252.17,  PE2_WF: 0,       ER_WF: 2073.7, SC_PS: '2025-05-27', SC_PF: '2025-06-16', SC_PP: 0,     SC_AS: undefined,    SC_AF: undefined,    SC_AP: 0,   FB_PS: '2025-06-17', FB_PF: '2025-07-09', FB_PP: 0,     FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: '2025-07-10', AS_PF: '2025-08-28', AS_PP: 0,    AS_AS: undefined,    AS_AF: undefined,    AS_AP: 0,    PE1_PS: undefined,    PE1_PF: undefined,    PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: '2025-09-09', PT_PF: '2025-10-01', PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: undefined,    PE2_PF: undefined,    PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: '2025-10-20', ER_PF: '2025-11-14', ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'HULL',    ZONE: 'NODE',           MOD: 'Node, SE',            LEVEL: 'South-East',          NAME: 'Push Knee',              G_EREC: 'EN520', G_2PE: 'EN520', G_1PE: 'EN520', EREC: 'EN520', PE2: '',      PE1: '',      PBLK: 'EN520', BLK: 'EN520', L: '6.6',  B: '3.9',  H: '8.9',  YARD: 'HHI',   SC_WF: 104.74, FB_WF: 209.48,  AS_WF: 314.22,  PE1_WF: 0,       PT_WF: 252.17,  PE2_WF: 0,       ER_WF: 2073.7, SC_PS: '2025-05-28', SC_PF: '2025-06-17', SC_PP: 0,     SC_AS: undefined,    SC_AF: undefined,    SC_AP: 0,   FB_PS: '2025-06-18', FB_PF: '2025-07-10', FB_PP: 0,     FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: '2025-07-11', AS_PF: '2025-08-29', AS_PP: 0,    AS_AS: undefined,    AS_AF: undefined,    AS_AP: 0,    PE1_PS: undefined,    PE1_PF: undefined,    PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: '2025-09-10', PT_PF: '2025-10-08', PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: undefined,    PE2_PF: undefined,    PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: '2025-10-21', ER_PF: '2025-11-17', ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'HULL',    ZONE: 'PONTOON',        MOD: 'Pontoon, East',       LEVEL: 'East Pontoon',        NAME: 'Pontoon Shell',          G_EREC: 'EP112', G_2PE: 'EP112', G_1PE: 'EP111', EREC: 'EP112', PE2: 'EP112', PE1: 'EP111', PBLK: 'EP111', BLK: 'EP110', L: '7.3',  B: '19.2', H: '9.5',  YARD: 'HHI',   SC_WF: 745.08, FB_WF: 1490.16, AS_WF: 2235.24, PE1_WF: 3725.41, PT_WF: 1321.1,  PE2_WF: 3725.41, ER_WF: 2073.7, SC_PS: '2024-11-07', SC_PF: '2024-11-20', SC_PP: 100,   SC_AS: '2024-11-15', SC_AF: '2024-11-15', SC_AP: 100, FB_PS: '2024-11-21', FB_PF: '2024-12-19', FB_PP: 100,   FB_AS: '2024-11-25', FB_AF: '2024-12-20', FB_AP: 100,  AS_PS: '2024-12-20', AS_PF: '2025-02-24', AS_PP: 81.4, AS_AS: '2024-12-27', AS_AF: undefined,    AS_AP: 60,   PE1_PS: '2025-02-25', PE1_PF: '2025-03-26', PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: '2025-03-27', PT_PF: '2025-04-21', PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: '2025-04-22', PE2_PF: '2025-07-02', PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: '2025-07-03', ER_PF: '2025-07-25', ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'HULL',    ZONE: 'PONTOON',        MOD: 'Pontoon, East',       LEVEL: 'East Pontoon',        NAME: 'Pontoon Shell',          G_EREC: 'EP112', G_2PE: 'EP112', G_1PE: 'EP111', EREC: '',      PE2: '',      PE1: '',      PBLK: '',      BLK: 'EP120', L: '6.5',  B: '19.2', H: '9.5',  YARD: 'HHI',   SC_WF: 467.94, FB_WF: 935.88,  AS_WF: 1403.82, PE1_WF: 2339.7,  PT_WF: 0,       PE2_WF: 0,       ER_WF: 0,      SC_PS: '2024-11-13', SC_PF: '2024-12-02', SC_PP: 100,   SC_AS: '2024-11-19', SC_AF: '2024-11-30', SC_AP: 100, FB_PS: '2024-12-03', FB_PF: '2025-01-02', FB_PP: 100,   FB_AS: '2024-12-05', FB_AF: '2024-12-26', FB_AP: 100,  AS_PS: '2025-01-03', AS_PF: '2025-02-24', AS_PP: 77.1, AS_AS: '2025-01-22', AS_AF: undefined,    AS_AP: 15,   PE1_PS: '2025-02-25', PE1_PF: '2025-03-26', PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: undefined,    PT_PF: undefined,    PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: undefined,    PE2_PF: undefined,    PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: undefined,    ER_PF: undefined,    ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'HULL',    ZONE: 'PONTOON',        MOD: 'Pontoon, East',       LEVEL: 'East Pontoon',        NAME: 'Pontoon Shell',          G_EREC: 'EP112', G_2PE: 'EP112', G_1PE: 'EP131', EREC: '',      PE2: '',      PE1: 'EP131', PBLK: 'EP111', BLK: 'EP130', L: '7.2',  B: '16.8', H: '9.5',  YARD: 'HHI',   SC_WF: 629.58, FB_WF: 1259.16, AS_WF: 1888.75, PE1_WF: 3147.91, PT_WF: 1151.73, PE2_WF: 3147.91, ER_WF: 0,      SC_PS: '2024-11-04', SC_PF: '2024-11-21', SC_PP: 100,   SC_AS: '2024-11-14', SC_AF: '2024-11-22', SC_AP: 100, FB_PS: '2024-11-22', FB_PF: '2024-12-23', FB_PP: 100,   FB_AS: '2024-11-23', FB_AF: '2024-12-19', FB_AP: 100,  AS_PS: '2024-12-24', AS_PF: '2025-02-25', AS_PP: 77.8, AS_AS: '2025-01-08', AS_AF: undefined,    AS_AP: 30,   PE1_PS: '2025-02-26', PE1_PF: '2025-03-27', PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: '2025-03-28', PT_PF: '2025-04-22', PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: '2025-04-23', PE2_PF: '2025-05-29', PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: undefined,    ER_PF: undefined,    ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'HULL',    ZONE: 'PONTOON',        MOD: 'Pontoon, East',       LEVEL: 'East Pontoon',        NAME: 'Pontoon Shell',          G_EREC: 'EP112', G_2PE: 'EP112', G_1PE: 'EP131', EREC: '',      PE2: '',      PE1: '',      PBLK: '',      BLK: 'EP140', L: '6.4',  B: '16.8', H: '9.4',  YARD: 'HHI',   SC_WF: 412.74, FB_WF: 825.48,  AS_WF: 1238.22, PE1_WF: 2063.69, PT_WF: 0,       PE2_WF: 0,       ER_WF: 0,      SC_PS: '2024-11-14', SC_PF: '2024-12-03', SC_PP: 100.1, SC_AS: '2024-11-20', SC_AF: '2024-12-02', SC_AP: 100, FB_PS: '2024-12-04', FB_PF: '2025-01-03', FB_PP: 100,   FB_AS: '2024-12-25', FB_AF: '2025-01-09', FB_AP: 100,  AS_PS: '2025-01-06', AS_PF: '2025-02-25', AS_PP: 73.2, AS_AS: '2025-02-01', AS_AF: undefined,    AS_AP: 20,   PE1_PS: '2025-02-26', PE1_PF: '2025-03-27', PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: undefined,    PT_PF: undefined,    PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: undefined,    PE2_PF: undefined,    PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: undefined,    ER_PF: undefined,    ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'HULL',    ZONE: 'PONTOON',        MOD: 'Pontoon, East',       LEVEL: 'East Pontoon',        NAME: 'Pontoon Shell',          G_EREC: 'EP112', G_2PE: 'EP112', G_1PE: 'EP151', EREC: '',      PE2: '',      PE1: 'EP151', PBLK: 'EP151', BLK: 'EP150', L: '7.3',  B: '19.2', H: '9.5',  YARD: 'HHI',   SC_WF: 745.08, FB_WF: 1490.16, AS_WF: 2235.24, PE1_WF: 3725.41, PT_WF: 1321.1,  PE2_WF: 3725.41, ER_WF: 0,      SC_PS: '2024-11-07', SC_PF: '2024-11-26', SC_PP: 100,   SC_AS: '2024-11-18', SC_AF: '2024-11-25', SC_AP: 100, FB_PS: '2024-12-02', FB_PF: '2024-12-27', FB_PP: 100,   FB_AS: '2024-12-05', FB_AF: '2024-12-19', FB_AP: 100,  AS_PS: '2024-12-30', AS_PF: '2025-02-27', AS_PP: 70.7, AS_AS: '2025-01-10', AS_AF: undefined,    AS_AP: 18,   PE1_PS: '2025-02-28', PE1_PF: '2025-03-31', PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: '2025-04-01', PT_PF: '2025-04-23', PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: '2025-04-24', PE2_PF: '2025-05-29', PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: undefined,    ER_PF: undefined,    ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'HULL',    ZONE: 'PONTOON',        MOD: 'Pontoon, East',       LEVEL: 'East Pontoon',        NAME: 'Pontoon Shell',          G_EREC: 'EP112', G_2PE: 'EP112', G_1PE: 'EP151', EREC: '',      PE2: '',      PE1: '',      PBLK: '',      BLK: 'EP160', L: '6.5',  B: '19.2', H: '9.5',  YARD: 'HHI',   SC_WF: 467.94, FB_WF: 935.88,  AS_WF: 1403.82, PE1_WF: 2339.7,  PT_WF: 0,       PE2_WF: 0,       ER_WF: 0,      SC_PS: '2024-11-15', SC_PF: '2024-12-04', SC_PP: 100,   SC_AS: '2024-11-22', SC_AF: '2024-12-03', SC_AP: 100, FB_PS: '2024-12-05', FB_PF: '2025-01-06', FB_PP: 100,   FB_AS: '2024-12-20', FB_AF: '2025-01-03', FB_AP: 100,  AS_PS: '2025-01-07', AS_PF: '2025-02-27', AS_PP: 66.6, AS_AS: '2025-02-07', AS_AF: undefined,    AS_AP: 20,   PE1_PS: '2025-02-28', PE1_PF: '2025-03-31', PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: undefined,    PT_PF: undefined,    PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: undefined,    PE2_PF: undefined,    PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: undefined,    ER_PF: undefined,    ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'HULL',    ZONE: 'PONTOON',        MOD: 'Pontoon, West',       LEVEL: 'West Pontoon',        NAME: 'Pontoon Shell',          G_EREC: 'WP111', G_2PE: 'WP111', G_1PE: 'WP110', EREC: 'WP111', PE2: '',      PE1: 'WP111', PBLK: 'WP110', BLK: 'WP110', L: '7.3',  B: '19.2', H: '9.5',  YARD: 'COSCO', SC_WF: 0,      FB_WF: 0,       AS_WF: 6501,    PE1_WF: 6262.5,  PT_WF: 1400,    PE2_WF: 3132.34, ER_WF: 2073.7, SC_PS: undefined,    SC_PF: undefined,    SC_PP: 0,     SC_AS: undefined,    SC_AF: undefined,    SC_AP: 0,   FB_PS: undefined,    FB_PF: undefined,    FB_PP: 0,     FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: '2024-12-07', AS_PF: '2025-03-29', AS_PP: 53,   AS_AS: '2024-11-26', AS_AF: undefined,    AS_AP: 72,   PE1_PS: '2025-04-19', PE1_PF: '2025-05-22', PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: '2025-04-05', PT_PF: '2025-04-15', PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: '2025-06-17', PE2_PF: '2025-08-14', PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: '2025-07-24', ER_PF: '2025-09-04', ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'HULL',    ZONE: 'PONTOON',        MOD: 'Pontoon, West',       LEVEL: 'West Pontoon',        NAME: 'Pontoon Shell',          G_EREC: 'WP111', G_2PE: 'WP111', G_1PE: 'WP120', EREC: '',      PE2: '',      PE1: '',      PBLK: 'WP120', BLK: 'WP120', L: '7.3',  B: '19.2', H: '9.5',  YARD: 'COSCO', SC_WF: 0,      FB_WF: 0,       AS_WF: 5825,    PE1_WF: 0,       PT_WF: 1254.5,  PE2_WF: 0,       ER_WF: 0,      SC_PS: undefined,    SC_PF: undefined,    SC_PP: 0,     SC_AS: undefined,    SC_AF: undefined,    SC_AP: 0,   FB_PS: undefined,    FB_PF: undefined,    FB_PP: 0,     FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: '2024-12-09', AS_PF: '2025-03-31', AS_PP: 43.9, AS_AS: '2024-11-27', AS_AF: undefined,    AS_AP: 58.8, PE1_PS: undefined,    PE1_PF: undefined,    PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: '2025-04-07', PT_PF: '2025-04-16', PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: undefined,    PE2_PF: undefined,    PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: undefined,    ER_PF: undefined,    ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'HULL',    ZONE: 'PONTOON',        MOD: 'Pontoon, West',       LEVEL: 'West Pontoon',        NAME: 'Support',                G_EREC: 'WP111', G_2PE: 'WP111', G_1PE: 'WPS30', EREC: '',      PE2: '',      PE1: '',      PBLK: '',      BLK: 'WPS30', L: '1(n)', B: '1(n)', H: '1(n)', YARD: 'COSCO', SC_WF: 0,      FB_WF: 0,       AS_WF: 0,       PE1_WF: 0,       PT_WF: 0,       PE2_WF: 0,       ER_WF: 0,      SC_PS: undefined,    SC_PF: undefined,    SC_PP: 0,     SC_AS: undefined,    SC_AF: undefined,    SC_AP: 0,   FB_PS: undefined,    FB_PF: undefined,    FB_PP: 0,     FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: undefined,    AS_PF: undefined,    AS_PP: 0,    AS_AS: undefined,    AS_AF: undefined,    AS_AP: 0,    PE1_PS: undefined,    PE1_PF: undefined,    PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: undefined,    PT_PF: undefined,    PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: undefined,    PE2_PF: undefined,    PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: undefined,    ER_PF: undefined,    ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'HULL',    ZONE: 'PONTOON',        MOD: 'Pontoon, West',       LEVEL: 'West Pontoon',        NAME: 'Porch',                  G_EREC: 'WP111', G_2PE: 'WP111', G_1PE: 'WPP10', EREC: '',      PE2: '',      PE1: '',      PBLK: '',      BLK: 'WPP10', L: '1(n)', B: '1(n)', H: '1(n)', YARD: 'COSCO', SC_WF: 0,      FB_WF: 0,       AS_WF: 0,       PE1_WF: 0,       PT_WF: 0,       PE2_WF: 0,       ER_WF: 0,      SC_PS: undefined,    SC_PF: undefined,    SC_PP: 0,     SC_AS: undefined,    SC_AF: undefined,    SC_AP: 0,   FB_PS: undefined,    FB_PF: undefined,    FB_PP: 0,     FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: undefined,    AS_PF: undefined,    AS_PP: 0,    AS_AS: undefined,    AS_AF: undefined,    AS_AP: 0,    PE1_PS: undefined,    PE1_PF: undefined,    PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: undefined,    PT_PF: undefined,    PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: undefined,    PE2_PF: undefined,    PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: undefined,    ER_PF: undefined,    ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'HULL',    ZONE: 'PONTOON',        MOD: 'Pontoon, West',       LEVEL: 'West Pontoon',        NAME: 'Pontoon Shell',          G_EREC: 'WP112', G_2PE: 'WP112', G_1PE: 'WP131', EREC: 'WP112', PE2: 'WP112', PE1: 'WP131', PBLK: 'WP130', BLK: 'WP130', L: '7.2',  B: '16.8', H: '9.5',  YARD: 'COSCO', SC_WF: 0,      FB_WF: 0,       AS_WF: 5851,    PE1_WF: 5615,    PT_WF: 1260,    PE2_WF: 3743.81, ER_WF: 0,      SC_PS: undefined,    SC_PF: undefined,    SC_PP: 0,     SC_AS: undefined,    SC_AF: undefined,    SC_AP: 0,   FB_PS: undefined,    FB_PF: undefined,    FB_PP: 0,     FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: '2024-12-10', AS_PF: '2025-04-01', AS_PP: 42.4, AS_AS: '2024-11-28', AS_AF: undefined,    AS_AP: 66.5, PE1_PS: '2025-04-21', PE1_PF: '2025-05-22', PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: '2025-04-08', PT_PF: '2025-04-17', PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: '2025-06-17', PE2_PF: '2025-07-21', PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: '2025-07-31', ER_PF: '2025-09-10', ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'HULL',    ZONE: 'PONTOON',        MOD: 'Pontoon, West',       LEVEL: 'West Pontoon',        NAME: 'Pontoon Shell',          G_EREC: 'WP112', G_2PE: 'WP112', G_1PE: 'WP131', EREC: '',      PE2: '',      PE1: '',      PBLK: 'WP140', BLK: 'WP140', L: '6.4',  B: '16.8', H: '9.4',  YARD: 'COSCO', SC_WF: 0,      FB_WF: 0,       AS_WF: 5201,    PE1_WF: 0,       PT_WF: 1120,    PE2_WF: 0,       ER_WF: 0,      SC_PS: undefined,    SC_PF: undefined,    SC_PP: 0,     SC_AS: undefined,    SC_AF: undefined,    SC_AP: 0,   FB_PS: undefined,    FB_PF: undefined,    FB_PP: 0,     FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: '2024-12-11', AS_PF: '2025-04-02', AS_PP: 40.9, AS_AS: '2024-11-30', AS_AF: undefined,    AS_AP: 63.9, PE1_PS: undefined,    PE1_PF: undefined,    PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: '2025-04-09', PT_PF: '2025-04-18', PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: undefined,    PE2_PF: undefined,    PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: undefined,    ER_PF: undefined,    ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'HULL',    ZONE: 'PONTOON',        MOD: 'Pontoon, West',       LEVEL: 'West Pontoon',        NAME: 'Porch',                  G_EREC: 'WP112', G_2PE: 'WP112', G_1PE: 'WP131', EREC: '',      PE2: '',      PE1: '',      PBLK: '',      BLK: 'WPP20', L: '1(n)', B: '1(n)', H: '1(n)', YARD: 'COSCO', SC_WF: 0,      FB_WF: 0,       AS_WF: 0,       PE1_WF: 0,       PT_WF: 0,       PE2_WF: 0,       ER_WF: 0,      SC_PS: undefined,    SC_PF: undefined,    SC_PP: 0,     SC_AS: undefined,    SC_AF: undefined,    SC_AP: 0,   FB_PS: undefined,    FB_PF: undefined,    FB_PP: 0,     FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: undefined,    AS_PF: undefined,    AS_PP: 0,    AS_AS: undefined,    AS_AF: undefined,    AS_AP: 0,    PE1_PS: undefined,    PE1_PF: undefined,    PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: undefined,    PT_PF: undefined,    PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: undefined,    PE2_PF: undefined,    PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: undefined,    ER_PF: undefined,    ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'HULL',    ZONE: 'PONTOON',        MOD: 'Pontoon, West',       LEVEL: 'West Pontoon',        NAME: 'Support',                G_EREC: 'WP112', G_2PE: 'WP112', G_1PE: 'WP131', EREC: '',      PE2: '',      PE1: '',      PBLK: '',      BLK: 'WPS10', L: '1(n)', B: '1(n)', H: '1(n)', YARD: 'COSCO', SC_WF: 0,      FB_WF: 0,       AS_WF: 0,       PE1_WF: 0,       PT_WF: 0,       PE2_WF: 0,       ER_WF: 0,      SC_PS: undefined,    SC_PF: undefined,    SC_PP: 0,     SC_AS: undefined,    SC_AF: undefined,    SC_AP: 0,   FB_PS: undefined,    FB_PF: undefined,    FB_PP: 0,     FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: undefined,    AS_PF: undefined,    AS_PP: 0,    AS_AS: undefined,    AS_AF: undefined,    AS_AP: 0,    PE1_PS: undefined,    PE1_PF: undefined,    PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: undefined,    PT_PF: undefined,    PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: undefined,    PE2_PF: undefined,    PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: undefined,    ER_PF: undefined,    ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'HULL',    ZONE: 'PONTOON',        MOD: 'Pontoon, West',       LEVEL: 'West Pontoon',        NAME: 'Pontoon Shell',          G_EREC: 'WP112', G_2PE: 'WP112', G_1PE: 'WP151', EREC: '',      PE2: '',      PE1: 'WP151', PBLK: 'WP150', BLK: 'WP150', L: '7.3',  B: '19.2', H: '9.5',  YARD: 'COSCO', SC_WF: 0,      FB_WF: 0,       AS_WF: 5331,    PE1_WF: 5351,    PT_WF: 1148,    PE2_WF: 3731.07, ER_WF: 0,      SC_PS: undefined,    SC_PF: undefined,    SC_PP: 0,     SC_AS: undefined,    SC_AF: undefined,    SC_AP: 0,   FB_PS: undefined,    FB_PF: undefined,    FB_PP: 0,     FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: '2024-12-12', AS_PF: '2025-04-03', AS_PP: 39.4, AS_AS: '2024-11-30', AS_AF: undefined,    AS_AP: 63.9, PE1_PS: '2025-04-23', PE1_PF: '2025-05-22', PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: '2025-04-10', PT_PF: '2025-04-19', PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: '2025-06-17', PE2_PF: '2025-07-21', PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: undefined,    ER_PF: undefined,    ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'HULL',    ZONE: 'PONTOON',        MOD: 'Pontoon, West',       LEVEL: 'West Pontoon',        NAME: 'Pontoon Shell',          G_EREC: 'WP112', G_2PE: 'WP112', G_1PE: 'WP151', EREC: '',      PE2: '',      PE1: '',      PBLK: 'WP160', BLK: 'WP160', L: '6.5',  B: '19.2', H: '9.5',  YARD: 'COSCO', SC_WF: 0,      FB_WF: 0,       AS_WF: 5201,    PE1_WF: 0,       PT_WF: 1120,    PE2_WF: 0,       ER_WF: 0,      SC_PS: undefined,    SC_PF: undefined,    SC_PP: 0,     SC_AS: undefined,    SC_AF: undefined,    SC_AP: 0,   FB_PS: undefined,    FB_PF: undefined,    FB_PP: 0,     FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: '2024-12-13', AS_PF: '2025-04-03', AS_PP: 38.7, AS_AS: '2024-12-02', AS_AF: undefined,    AS_AP: 63.9, PE1_PS: undefined,    PE1_PF: undefined,    PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: '2025-04-10', PT_PF: '2025-04-19', PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: undefined,    PE2_PF: undefined,    PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: undefined,    ER_PF: undefined,    ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'HULL',    ZONE: 'PONTOON',        MOD: 'Pontoon, West',       LEVEL: 'West Pontoon',        NAME: 'Support',                G_EREC: 'WP112', G_2PE: 'WP112', G_1PE: 'WP151', EREC: '',      PE2: '',      PE1: '',      PBLK: '',      BLK: 'WPS20', L: '1(n)', B: '1(n)', H: '1(n)', YARD: 'COSCO', SC_WF: 0,      FB_WF: 0,       AS_WF: 0,       PE1_WF: 0,       PT_WF: 0,       PE2_WF: 0,       ER_WF: 0,      SC_PS: undefined,    SC_PF: undefined,    SC_PP: 0,     SC_AS: undefined,    SC_AF: undefined,    SC_AP: 0,   FB_PS: undefined,    FB_PF: undefined,    FB_PP: 0,     FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: undefined,    AS_PF: undefined,    AS_PP: 0,    AS_AS: undefined,    AS_AF: undefined,    AS_AP: 0,    PE1_PS: undefined,    PE1_PF: undefined,    PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: undefined,    PT_PF: undefined,    PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: undefined,    PE2_PF: undefined,    PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: undefined,    ER_PF: undefined,    ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'HULL',    ZONE: 'PONTOON',        MOD: 'Pontoon, West',       LEVEL: 'West Pontoon',        NAME: 'Piping Guard',           G_EREC: 'WPG10', G_2PE: 'WPG10', G_1PE: 'WPG10', EREC: 'WPG10', PE2: '',      PE1: '',      PBLK: '',      BLK: 'WPG10', L: '10.3', B: '11.7', H: '3.5',  YARD: 'HHI',   SC_WF: 0,      FB_WF: 0,       AS_WF: 0,       PE1_WF: 0,       PT_WF: 0,       PE2_WF: 0,       ER_WF: 0,      SC_PS: undefined,    SC_PF: undefined,    SC_PP: 0,     SC_AS: undefined,    SC_AF: undefined,    SC_AP: 0,   FB_PS: undefined,    FB_PF: undefined,    FB_PP: 0,     FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: undefined,    AS_PF: undefined,    AS_PP: 0,    AS_AS: undefined,    AS_AF: undefined,    AS_AP: 0,    PE1_PS: undefined,    PE1_PF: undefined,    PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: undefined,    PT_PF: undefined,    PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: undefined,    PE2_PF: undefined,    PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: undefined,    ER_PF: undefined,    ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'HULL',    ZONE: 'PONTOON',        MOD: 'Pontoon, West',       LEVEL: 'West Pontoon',        NAME: 'Support',                G_EREC: 'WPB10', G_2PE: 'WPB10', G_1PE: 'WPB10', EREC: 'WPB10', PE2: '',      PE1: '',      PBLK: '',      BLK: 'WPB10', L: '2.5',  B: '15.8', H: '2.3',  YARD: 'HHI',   SC_WF: 0,      FB_WF: 0,       AS_WF: 0,       PE1_WF: 0,       PT_WF: 0,       PE2_WF: 0,       ER_WF: 0,      SC_PS: undefined,    SC_PF: undefined,    SC_PP: 0,     SC_AS: undefined,    SC_AF: undefined,    SC_AP: 0,   FB_PS: undefined,    FB_PF: undefined,    FB_PP: 0,     FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: undefined,    AS_PF: undefined,    AS_PP: 0,    AS_AS: undefined,    AS_AF: undefined,    AS_AP: 0,    PE1_PS: undefined,    PE1_PF: undefined,    PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: undefined,    PT_PF: undefined,    PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: undefined,    PE2_PF: undefined,    PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: undefined,    ER_PF: undefined,    ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'HULL',    ZONE: 'PONTOON',        MOD: 'Pontoon, South',      LEVEL: 'South Pontoon',       NAME: 'Pontoon Shell',          G_EREC: 'SP112', G_2PE: 'SP112', G_1PE: 'SP111', EREC: 'SP112', PE2: 'SP112', PE1: 'SP111', PBLK: 'SP110', BLK: 'SP110', L: '19.2', B: '7.3',  H: '9.5',  YARD: 'COSCO', SC_WF: 0,      FB_WF: 0,       AS_WF: 5721,    PE1_WF: 5285,    PT_WF: 1232,    PE2_WF: 3726.82, ER_WF: 2073.7, SC_PS: undefined,    SC_PF: undefined,    SC_PP: 0,     SC_AS: undefined,    SC_AF: undefined,    SC_AP: 0,   FB_PS: undefined,    FB_PF: undefined,    FB_PP: 0,     FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: '2024-12-02', AS_PF: '2025-03-24', AS_PP: 63,   AS_AS: '2024-11-18', AS_AF: undefined,    AS_AP: 78.7, PE1_PS: '2025-04-14', PE1_PF: '2025-05-15', PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: '2025-03-29', PT_PF: '2025-04-09', PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: '2025-06-10', PE2_PF: '2025-07-25', PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: '2025-07-21', ER_PF: '2025-09-01', ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'HULL',    ZONE: 'PONTOON',        MOD: 'Pontoon, South',      LEVEL: 'South Pontoon',       NAME: 'Pontoon Shell',          G_EREC: 'SP112', G_2PE: 'SP112', G_1PE: 'SP111', EREC: '',      PE2: '',      PE1: '',      PBLK: 'SP120', BLK: 'SP120', L: '19.2', B: '7.3',  H: '9.5',  YARD: 'COSCO', SC_WF: 0,      FB_WF: 0,       AS_WF: 4680.5,  PE1_WF: 0,       PT_WF: 1008,    PE2_WF: 0,       ER_WF: 0,      SC_PS: undefined,    SC_PF: undefined,    SC_PP: 0,     SC_AS: undefined,    SC_AF: undefined,    SC_AP: 0,   FB_PS: undefined,    FB_PF: undefined,    FB_PP: 0,     FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: '2024-12-03', AS_PF: '2025-03-25', AS_PP: 61.3, AS_AS: '2024-11-19', AS_AF: undefined,    AS_AP: 63.9, PE1_PS: undefined,    PE1_PF: undefined,    PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: '2025-03-31', PT_PF: '2025-04-10', PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: undefined,    PE2_PF: undefined,    PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: undefined,    ER_PF: undefined,    ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'HULL',    ZONE: 'PONTOON',        MOD: 'Pontoon, South',      LEVEL: 'South Pontoon',       NAME: 'Pontoon Shell',          G_EREC: 'SP112', G_2PE: 'SP112', G_1PE: 'SP131', EREC: '',      PE2: '',      PE1: 'SP131', PBLK: 'SP130', BLK: 'SP130', L: '16.8', B: '7.2',  H: '9.5',  YARD: 'COSCO', SC_WF: 0,      FB_WF: 0,       AS_WF: 5721,    PE1_WF: 5285,    PT_WF: 1232,    PE2_WF: 3147.91, ER_WF: 0,      SC_PS: undefined,    SC_PF: undefined,    SC_PP: 0,     SC_AS: undefined,    SC_AF: undefined,    SC_AP: 0,   FB_PS: undefined,    FB_PF: undefined,    FB_PP: 0,     FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: '2024-12-04', AS_PF: '2025-03-26', AS_PP: 61,   AS_AS: '2024-11-19', AS_AF: undefined,    AS_AP: 70.6, PE1_PS: '2025-04-15', PE1_PF: '2025-05-15', PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: '2025-04-01', PT_PF: '2025-04-11', PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: '2025-06-10', PE2_PF: '2025-07-14', PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: undefined,    ER_PF: undefined,    ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'HULL',    ZONE: 'PONTOON',        MOD: 'Pontoon, South',      LEVEL: 'South Pontoon',       NAME: 'Pontoon Shell',          G_EREC: 'SP112', G_2PE: 'SP112', G_1PE: 'SP131', EREC: '',      PE2: '',      PE1: '',      PBLK: 'SP140', BLK: 'SP140', L: '16.8', B: '6.4',  H: '9.4',  YARD: 'COSCO', SC_WF: 0,      FB_WF: 0,       AS_WF: 4680.5,  PE1_WF: 0,       PT_WF: 1008,    PE2_WF: 0,       ER_WF: 0,      SC_PS: undefined,    SC_PF: undefined,    SC_PP: 0,     SC_AS: undefined,    SC_AF: undefined,    SC_AP: 0,   FB_PS: undefined,    FB_PF: undefined,    FB_PP: 0,     FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: '2024-12-05', AS_PF: '2025-03-27', AS_PP: 59.4, AS_AS: '2024-11-22', AS_AF: undefined,    AS_AP: 63.9, PE1_PS: undefined,    PE1_PF: undefined,    PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: '2025-04-02', PT_PF: '2025-04-12', PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: undefined,    PE2_PF: undefined,    PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: undefined,    ER_PF: undefined,    ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'HULL',    ZONE: 'PONTOON',        MOD: 'Pontoon, South',      LEVEL: 'South Pontoon',       NAME: 'Pontoon Shell',          G_EREC: 'SP151', G_2PE: 'SP151', G_1PE: 'SP150', EREC: 'SP151', PE2: '',      PE1: 'SP151', PBLK: 'SP150', BLK: 'SP150', L: '19.5', B: '7.3',  H: '9.5',  YARD: 'COSCO', SC_WF: 0,      FB_WF: 0,       AS_WF: 5721,    PE1_WF: 5285,    PT_WF: 1232,    PE2_WF: 3736.73, ER_WF: 0,      SC_PS: undefined,    SC_PF: undefined,    SC_PP: 0,     SC_AS: undefined,    SC_AF: undefined,    SC_AP: 0,   FB_PS: undefined,    FB_PF: undefined,    FB_PP: 0,     FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: '2024-12-06', AS_PF: '2025-03-28', AS_PP: 58.6, AS_AS: '2024-11-19', AS_AF: undefined,    AS_AP: 63.9, PE1_PS: '2025-04-16', PE1_PF: '2025-05-15', PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: '2025-04-03', PT_PF: '2025-04-14', PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: '2025-06-10', PE2_PF: '2025-07-14', PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: '2025-07-11', ER_PF: '2025-08-22', ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'HULL',    ZONE: 'PONTOON',        MOD: 'Pontoon, South',      LEVEL: 'South Pontoon',       NAME: 'Pontoon Shell',          G_EREC: 'SP151', G_2PE: 'SP151', G_1PE: 'SP160', EREC: '',      PE2: '',      PE1: '',      PBLK: 'SP160', BLK: 'SP160', L: '19.5', B: '6.5',  H: '9.5',  YARD: 'COSCO', SC_WF: 0,      FB_WF: 0,       AS_WF: 4680.5,  PE1_WF: 0,       PT_WF: 1008,    PE2_WF: 0,       ER_WF: 0,      SC_PS: undefined,    SC_PF: undefined,    SC_PP: 0,     SC_AS: undefined,    SC_AF: undefined,    SC_AP: 0,   FB_PS: undefined,    FB_PF: undefined,    FB_PP: 0,     FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: '2024-12-07', AS_PF: '2025-03-29', AS_PP: 57.7, AS_AS: '2024-11-20', AS_AF: undefined,    AS_AP: 83.5, PE1_PS: undefined,    PE1_PF: undefined,    PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: '2025-04-03', PT_PF: '2025-04-14', PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: undefined,    PE2_PF: undefined,    PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: undefined,    ER_PF: undefined,    ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'HULL',    ZONE: 'PONTOON',        MOD: 'Pontoon, North',      LEVEL: 'North Pontoon',       NAME: 'Pontoon Shell',          G_EREC: 'NP112', G_2PE: 'NP112', G_1PE: 'NP111', EREC: 'NP112', PE2: 'NP112', PE1: 'NP111', PBLK: 'NP111', BLK: 'NP110', L: '19.5', B: '7.3',  H: '9.5',  YARD: 'COSCO', SC_WF: 0,      FB_WF: 0,       AS_WF: 5721,    PE1_WF: 5285,    PT_WF: 1232,    PE2_WF: 3726.82, ER_WF: 2073.7, SC_PS: undefined,    SC_PF: undefined,    SC_PP: 0,     SC_AS: undefined,    SC_AF: undefined,    SC_AP: 0,   FB_PS: undefined,    FB_PF: undefined,    FB_PP: 0,     FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: '2024-11-25', AS_PF: '2025-03-14', AS_PP: 71.7, AS_AS: '2024-11-11', AS_AF: undefined,    AS_AP: 80.1, PE1_PS: '2025-04-03', PE1_PF: '2025-04-30', PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: '2025-03-20', PT_PF: '2025-03-29', PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: '2025-05-21', PE2_PF: '2025-07-09', PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: '2025-07-10', ER_PF: '2025-08-21', ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'HULL',    ZONE: 'PONTOON',        MOD: 'Pontoon, North',      LEVEL: 'North Pontoon',       NAME: 'Pontoon Shell',          G_EREC: 'NP112', G_2PE: 'NP112', G_1PE: 'NP111', EREC: '',      PE2: '',      PE1: '',      PBLK: '',      BLK: 'NP120', L: '19.5', B: '6.5',  H: '9.5',  YARD: 'COSCO', SC_WF: 0,      FB_WF: 0,       AS_WF: 4680.5,  PE1_WF: 1008,    PT_WF: 0,       PE2_WF: 0,       ER_WF: 0,      SC_PS: undefined,    SC_PF: undefined,    SC_PP: 0,     SC_AS: undefined,    SC_AF: undefined,    SC_AP: 0,   FB_PS: undefined,    FB_PF: undefined,    FB_PP: 0,     FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: '2024-11-26', AS_PF: '2025-03-10', AS_PP: 74.6, AS_AS: '2024-11-12', AS_AF: undefined,    AS_AP: 78.7, PE1_PS: '2025-03-15', PE1_PF: '2025-03-25', PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: undefined,    PT_PF: undefined,    PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: undefined,    PE2_PF: undefined,    PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: undefined,    ER_PF: undefined,    ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'HULL',    ZONE: 'PONTOON',        MOD: 'Pontoon, North',      LEVEL: 'North Pontoon',       NAME: 'Porch',                  G_EREC: 'NP112', G_2PE: 'NP112', G_1PE: 'NP111', EREC: '',      PE2: '',      PE1: '',      PBLK: '',      BLK: 'NPP10', L: '1(n)', B: '1(n)', H: '1(n)', YARD: 'COSCO', SC_WF: 0,      FB_WF: 0,       AS_WF: 0,       PE1_WF: 0,       PT_WF: 0,       PE2_WF: 0,       ER_WF: 0,      SC_PS: undefined,    SC_PF: undefined,    SC_PP: 0,     SC_AS: undefined,    SC_AF: undefined,    SC_AP: 0,   FB_PS: undefined,    FB_PF: undefined,    FB_PP: 0,     FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: undefined,    AS_PF: undefined,    AS_PP: 0,    AS_AS: undefined,    AS_AF: undefined,    AS_AP: 0,    PE1_PS: undefined,    PE1_PF: undefined,    PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: undefined,    PT_PF: undefined,    PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: undefined,    PE2_PF: undefined,    PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: undefined,    ER_PF: undefined,    ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'HULL',    ZONE: 'PONTOON',        MOD: 'Pontoon, North',      LEVEL: 'North Pontoon',       NAME: 'Support',                G_EREC: 'NP112', G_2PE: 'NP112', G_1PE: 'NP111', EREC: '',      PE2: '',      PE1: '',      PBLK: '',      BLK: 'NPS10', L: '1(n)', B: '1(n)', H: '1(n)', YARD: 'COSCO', SC_WF: 0,      FB_WF: 0,       AS_WF: 0,       PE1_WF: 0,       PT_WF: 0,       PE2_WF: 0,       ER_WF: 0,      SC_PS: undefined,    SC_PF: undefined,    SC_PP: 0,     SC_AS: undefined,    SC_AF: undefined,    SC_AP: 0,   FB_PS: undefined,    FB_PF: undefined,    FB_PP: 0,     FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: undefined,    AS_PF: undefined,    AS_PP: 0,    AS_AS: undefined,    AS_AF: undefined,    AS_AP: 0,    PE1_PS: undefined,    PE1_PF: undefined,    PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: undefined,    PT_PF: undefined,    PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: undefined,    PE2_PF: undefined,    PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: undefined,    ER_PF: undefined,    ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'HULL',    ZONE: 'PONTOON',        MOD: 'Pontoon, North',      LEVEL: 'North Pontoon',       NAME: 'Pontoon Shell',          G_EREC: 'NP112', G_2PE: 'NP112', G_1PE: 'NP131', EREC: '',      PE2: '',      PE1: 'NP131', PBLK: 'NP130', BLK: 'NP130', L: '16.8', B: '7.2',  H: '9.5',  YARD: 'COSCO', SC_WF: 0,      FB_WF: 0,       AS_WF: 5851,    PE1_WF: 5615,    PT_WF: 1260,    PE2_WF: 3149.33, ER_WF: 0,      SC_PS: undefined,    SC_PF: undefined,    SC_PP: 0,     SC_AS: undefined,    SC_AF: undefined,    SC_AP: 0,   FB_PS: undefined,    FB_PF: undefined,    FB_PP: 0,     FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: '2024-11-27', AS_PF: '2025-03-11', AS_PP: 73.5, AS_AS: '2024-11-13', AS_AF: undefined,    AS_AP: 72,   PE1_PS: '2025-03-31', PE1_PF: '2025-04-26', PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: '2025-03-17', PT_PF: '2025-03-26', PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: '2025-05-21', PE2_PF: '2025-06-25', PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: undefined,    ER_PF: undefined,    ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'HULL',    ZONE: 'PONTOON',        MOD: 'Pontoon, North',      LEVEL: 'North Pontoon',       NAME: 'Pontoon Shell',          G_EREC: 'NP112', G_2PE: 'NP112', G_1PE: 'NP131', EREC: '',      PE2: '',      PE1: '',      PBLK: 'NP140', BLK: 'NP140', L: '16.8', B: '6.4',  H: '9.4',  YARD: 'COSCO', SC_WF: 0,      FB_WF: 0,       AS_WF: 5201,    PE1_WF: 0,       PT_WF: 1120,    PE2_WF: 0,       ER_WF: 0,      SC_PS: undefined,    SC_PF: undefined,    SC_PP: 0,     SC_AS: undefined,    SC_AF: undefined,    SC_AP: 0,   FB_PS: undefined,    FB_PF: undefined,    FB_PP: 0,     FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: '2024-11-28', AS_PF: '2025-03-12', AS_PP: 72.4, AS_AS: '2024-11-12', AS_AF: undefined,    AS_AP: 72,   PE1_PS: undefined,    PE1_PF: undefined,    PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: '2025-03-18', PT_PF: '2025-03-27', PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: undefined,    PE2_PF: undefined,    PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: undefined,    ER_PF: undefined,    ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'HULL',    ZONE: 'PONTOON',        MOD: 'Pontoon, North',      LEVEL: 'North Pontoon',       NAME: 'Porch',                  G_EREC: 'NP112', G_2PE: 'NP112', G_1PE: 'NP131', EREC: '',      PE2: '',      PE1: '',      PBLK: '',      BLK: 'NPP20', L: '1(n)', B: '1(n)', H: '1(n)', YARD: 'COSCO', SC_WF: 0,      FB_WF: 0,       AS_WF: 0,       PE1_WF: 0,       PT_WF: 0,       PE2_WF: 0,       ER_WF: 0,      SC_PS: undefined,    SC_PF: undefined,    SC_PP: 0,     SC_AS: undefined,    SC_AF: undefined,    SC_AP: 0,   FB_PS: undefined,    FB_PF: undefined,    FB_PP: 0,     FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: undefined,    AS_PF: undefined,    AS_PP: 0,    AS_AS: undefined,    AS_AF: undefined,    AS_AP: 0,    PE1_PS: undefined,    PE1_PF: undefined,    PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: undefined,    PT_PF: undefined,    PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: undefined,    PE2_PF: undefined,    PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: undefined,    ER_PF: undefined,    ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'HULL',    ZONE: 'PONTOON',        MOD: 'Pontoon, North',      LEVEL: 'North Pontoon',       NAME: 'Support',                G_EREC: 'NP112', G_2PE: 'NP112', G_1PE: 'NP131', EREC: '',      PE2: '',      PE1: '',      PBLK: '',      BLK: 'NPS20', L: '1(n)', B: '1(n)', H: '1(n)', YARD: 'COSCO', SC_WF: 0,      FB_WF: 0,       AS_WF: 0,       PE1_WF: 0,       PT_WF: 0,       PE2_WF: 0,       ER_WF: 0,      SC_PS: undefined,    SC_PF: undefined,    SC_PP: 0,     SC_AS: undefined,    SC_AF: undefined,    SC_AP: 0,   FB_PS: undefined,    FB_PF: undefined,    FB_PP: 0,     FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: undefined,    AS_PF: undefined,    AS_PP: 0,    AS_AS: undefined,    AS_AF: undefined,    AS_AP: 0,    PE1_PS: undefined,    PE1_PF: undefined,    PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: undefined,    PT_PF: undefined,    PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: undefined,    PE2_PF: undefined,    PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: undefined,    ER_PF: undefined,    ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'HULL',    ZONE: 'PONTOON',        MOD: 'Pontoon, North',      LEVEL: 'North Pontoon',       NAME: 'Pontoon Shell',          G_EREC: 'NP151', G_2PE: 'NP151', G_1PE: 'NP150', EREC: 'NP151', PE2: '',      PE1: 'NP151', PBLK: 'NP150', BLK: 'NP150', L: '19.2', B: '7.3',  H: '9.5',  YARD: 'COSCO', SC_WF: 0,      FB_WF: 0,       AS_WF: 5851,    PE1_WF: 5615,    PT_WF: 1260,    PE2_WF: 3726.82, ER_WF: 0,      SC_PS: undefined,    SC_PF: undefined,    SC_PP: 0,     SC_AS: undefined,    SC_AF: undefined,    SC_AP: 0,   FB_PS: undefined,    FB_PF: undefined,    FB_PP: 0,     FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: '2024-11-29', AS_PF: '2025-03-13', AS_PP: 70.7, AS_AS: '2024-11-16', AS_AF: undefined,    AS_AP: 68,   PE1_PS: '2025-03-31', PE1_PF: '2025-04-26', PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: '2025-03-19', PT_PF: '2025-03-28', PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: '2025-05-21', PE2_PF: '2025-06-25', PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: '2025-07-01', ER_PF: '2025-07-29', ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'HULL',    ZONE: 'PONTOON',        MOD: 'Pontoon, North',      LEVEL: 'North Pontoon',       NAME: 'Pontoon Shell',          G_EREC: 'NP151', G_2PE: 'NP151', G_1PE: 'NP160', EREC: '',      PE2: '',      PE1: '',      PBLK: '',      BLK: 'NP160', L: '19.2', B: '7.3',  H: '9.5',  YARD: 'COSCO', SC_WF: 0,      FB_WF: 0,       AS_WF: 5201,    PE1_WF: 0,       PT_WF: 0,       PE2_WF: 0,       ER_WF: 0,      SC_PS: undefined,    SC_PF: undefined,    SC_PP: 0,     SC_AS: undefined,    SC_AF: undefined,    SC_AP: 0,   FB_PS: undefined,    FB_PF: undefined,    FB_PP: 0,     FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: '2024-11-30', AS_PF: '2025-03-14', AS_PP: 70.1, AS_AS: '2024-11-13', AS_AF: undefined,    AS_AP: 72,   PE1_PS: undefined,    PE1_PF: undefined,    PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: undefined,    PT_PF: undefined,    PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: undefined,    PE2_PF: undefined,    PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: undefined,    ER_PF: undefined,    ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'HULL',    ZONE: 'PONTOON',        MOD: 'Pontoon, North',      LEVEL: 'North Pontoon',       NAME: 'Support',                G_EREC: 'NPB10', G_2PE: 'NPB10', G_1PE: 'NPB10', EREC: 'NPB10', PE2: '',      PE1: '',      PBLK: '',      BLK: 'NPB10', L: '16',   B: '2.6',  H: '2.6',  YARD: 'HHI',   SC_WF: 0,      FB_WF: 0,       AS_WF: 0,       PE1_WF: 0,       PT_WF: 0,       PE2_WF: 0,       ER_WF: 0,      SC_PS: undefined,    SC_PF: undefined,    SC_PP: 0,     SC_AS: undefined,    SC_AF: undefined,    SC_AP: 0,   FB_PS: undefined,    FB_PF: undefined,    FB_PP: 0,     FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: undefined,    AS_PF: undefined,    AS_PP: 0,    AS_AS: undefined,    AS_AF: undefined,    AS_AP: 0,    PE1_PS: undefined,    PE1_PF: undefined,    PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: undefined,    PT_PF: undefined,    PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: undefined,    PE2_PF: undefined,    PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: undefined,    ER_PF: undefined,    ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'HULL',    ZONE: 'COLUMN',         MOD: 'Column, NW',          LEVEL: 'NW, Lower Column',    NAME: 'Column Shell',           G_EREC: 'WC112', G_2PE: 'WC112', G_1PE: 'WC111', EREC: 'WC112', PE2: 'WC112', PE1: 'WC111', PBLK: 'WC110', BLK: 'WC110', L: '13',   B: '7.3',  H: '10',   YARD: 'COSCO', SC_WF: 0,      FB_WF: 0,       AS_WF: 3562.5,  PE1_WF: 7240,    PT_WF: 767,     PE2_WF: 1913.66, ER_WF: 0,      SC_PS: undefined,    SC_PF: undefined,    SC_PP: 0,     SC_AS: undefined,    SC_AF: undefined,    SC_AP: 0,   FB_PS: undefined,    FB_PF: undefined,    FB_PP: 0,     FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: '2024-12-12', AS_PF: '2025-04-18', AS_PP: 32.6, AS_AS: '2024-12-22', AS_AF: undefined,    AS_AP: 45.4, PE1_PS: '2025-06-17', PE1_PF: '2025-07-23', PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: '2025-06-03', PT_PF: '2025-06-16', PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: '2025-08-13', PE2_PF: '2025-09-17', PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: undefined,    ER_PF: undefined,    ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'HULL',    ZONE: 'COLUMN',         MOD: 'Column, NW',          LEVEL: 'NW, Lower Column',    NAME: 'Column Shell',           G_EREC: 'WC112', G_2PE: 'WC112', G_1PE: 'WC111', EREC: '',      PE2: '',      PE1: '',      PBLK: 'WC120', BLK: 'WC120', L: '7.3',  B: '14.1', H: '10',   YARD: 'COSCO', SC_WF: 0,      FB_WF: 0,       AS_WF: 3562.5,  PE1_WF: 0,       PT_WF: 767,     PE2_WF: 0,       ER_WF: 0,      SC_PS: undefined,    SC_PF: undefined,    SC_PP: 0,     SC_AS: undefined,    SC_AF: undefined,    SC_AP: 0,   FB_PS: undefined,    FB_PF: undefined,    FB_PP: 0,     FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: '2024-12-12', AS_PF: '2025-04-18', AS_PP: 32.6, AS_AS: '2024-12-23', AS_AF: undefined,    AS_AP: 44.3, PE1_PS: undefined,    PE1_PF: undefined,    PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: '2025-06-03', PT_PF: '2025-06-16', PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: undefined,    PE2_PF: undefined,    PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: undefined,    ER_PF: undefined,    ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'HULL',    ZONE: 'COLUMN',         MOD: 'Column, NW',          LEVEL: 'NW, Lower Column',    NAME: 'Column Shell',           G_EREC: 'WC112', G_2PE: 'WC112', G_1PE: 'WC111', EREC: '',      PE2: '',      PE1: '',      PBLK: 'WC130', BLK: 'WC130', L: '8.4',  B: '13.4', H: '10',   YARD: 'COSCO', SC_WF: 0,      FB_WF: 0,       AS_WF: 3562.5,  PE1_WF: 0,       PT_WF: 767,     PE2_WF: 0,       ER_WF: 0,      SC_PS: undefined,    SC_PF: undefined,    SC_PP: 0,     SC_AS: undefined,    SC_AF: undefined,    SC_AP: 0,   FB_PS: undefined,    FB_PF: undefined,    FB_PP: 0,     FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: '2024-12-13', AS_PF: '2025-04-19', AS_PP: 31.9, AS_AS: '2024-12-23', AS_AF: undefined,    AS_AP: 44.3, PE1_PS: undefined,    PE1_PF: undefined,    PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: '2025-06-04', PT_PF: '2025-06-17', PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: undefined,    PE2_PF: undefined,    PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: undefined,    ER_PF: undefined,    ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'HULL',    ZONE: 'COLUMN',         MOD: 'Column, NW',          LEVEL: 'NW, Lower Column',    NAME: 'Column Shell',           G_EREC: 'WC112', G_2PE: 'WC112', G_1PE: 'WC111', EREC: '',      PE2: '',      PE1: '',      PBLK: 'WC140', BLK: 'WC140', L: '13.5', B: '7.8',  H: '10',   YARD: 'COSCO', SC_WF: 0,      FB_WF: 0,       AS_WF: 3562.5,  PE1_WF: 0,       PT_WF: 767,     PE2_WF: 0,       ER_WF: 0,      SC_PS: undefined,    SC_PF: undefined,    SC_PP: 0,     SC_AS: undefined,    SC_AF: undefined,    SC_AP: 0,   FB_PS: undefined,    FB_PF: undefined,    FB_PP: 0,     FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: '2024-12-13', AS_PF: '2025-04-19', AS_PP: 31.9, AS_AS: '2024-12-24', AS_AF: undefined,    AS_AP: 45.1, PE1_PS: undefined,    PE1_PF: undefined,    PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: '2025-06-04', PT_PF: '2025-06-17', PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: undefined,    PE2_PF: undefined,    PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: undefined,    ER_PF: undefined,    ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'HULL',    ZONE: 'COLUMN',         MOD: 'Column, NW',          LEVEL: 'NW, Lower Column',    NAME: '',                       G_EREC: 'WC112', G_2PE: 'WC112', G_1PE: 'WC111', EREC: '',      PE2: '',      PE1: '',      PBLK: '',      BLK: 'WC310', L: '1(n)', B: '1(n)', H: '1(n)', YARD: 'COSCO', SC_WF: 0,      FB_WF: 0,       AS_WF: 0,       PE1_WF: 0,       PT_WF: 0,       PE2_WF: 0,       ER_WF: 0,      SC_PS: undefined,    SC_PF: undefined,    SC_PP: 0,     SC_AS: undefined,    SC_AF: undefined,    SC_AP: 0,   FB_PS: undefined,    FB_PF: undefined,    FB_PP: 0,     FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: undefined,    AS_PF: undefined,    AS_PP: 0,    AS_AS: undefined,    AS_AF: undefined,    AS_AP: 0,    PE1_PS: undefined,    PE1_PF: undefined,    PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: undefined,    PT_PF: undefined,    PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: undefined,    PE2_PF: undefined,    PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: undefined,    ER_PF: undefined,    ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'HULL',    ZONE: 'COLUMN',         MOD: 'Column, NW',          LEVEL: 'NW, Lower Column',    NAME: 'Column Shell',           G_EREC: 'WC112', G_2PE: 'WC112', G_1PE: 'WC151', EREC: '',      PE2: '',      PE1: 'WC151', PBLK: 'WC150', BLK: 'WC150', L: '13',   B: '7.3',  H: '9.9',  YARD: 'COSCO', SC_WF: 0,      FB_WF: 0,       AS_WF: 3562.5,  PE1_WF: 7240,    PT_WF: 767,     PE2_WF: 1663.13, ER_WF: 0,      SC_PS: undefined,    SC_PF: undefined,    SC_PP: 0,     SC_AS: undefined,    SC_AF: undefined,    SC_AP: 0,   FB_PS: undefined,    FB_PF: undefined,    FB_PP: 0,     FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: '2024-12-14', AS_PF: '2025-04-21', AS_PP: 31.2, AS_AS: '2024-12-23', AS_AF: undefined,    AS_AP: 44.3, PE1_PS: '2025-06-19', PE1_PF: '2025-07-23', PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: '2025-06-05', PT_PF: '2025-06-18', PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: '2025-08-13', PE2_PF: '2025-09-17', PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: undefined,    ER_PF: undefined,    ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'HULL',    ZONE: 'COLUMN',         MOD: 'Column, NW',          LEVEL: 'NW, Lower Column',    NAME: 'Column Shell',           G_EREC: 'WC112', G_2PE: 'WC112', G_1PE: 'WC151', EREC: '',      PE2: '',      PE1: '',      PBLK: 'WC160', BLK: 'WC160', L: '7.3',  B: '13',   H: '9.9',  YARD: 'COSCO', SC_WF: 0,      FB_WF: 0,       AS_WF: 3562.5,  PE1_WF: 0,       PT_WF: 767,     PE2_WF: 0,       ER_WF: 0,      SC_PS: undefined,    SC_PF: undefined,    SC_PP: 0,     SC_AS: undefined,    SC_AF: undefined,    SC_AP: 0,   FB_PS: undefined,    FB_PF: undefined,    FB_PP: 0,     FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: '2024-12-14', AS_PF: '2025-04-21', AS_PP: 31.2, AS_AS: '2024-12-25', AS_AF: undefined,    AS_AP: 41.9, PE1_PS: undefined,    PE1_PF: undefined,    PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: '2025-06-05', PT_PF: '2025-06-18', PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: undefined,    PE2_PF: undefined,    PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: undefined,    ER_PF: undefined,    ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'HULL',    ZONE: 'COLUMN',         MOD: 'Column, NW',          LEVEL: 'NW, Lower Column',    NAME: 'Column Shell',           G_EREC: 'WC112', G_2PE: 'WC112', G_1PE: 'WC151', EREC: '',      PE2: '',      PE1: '',      PBLK: 'WC170', BLK: 'WC170', L: '7.3',  B: '13.3', H: '9.9',  YARD: 'COSCO', SC_WF: 0,      FB_WF: 0,       AS_WF: 3562.5,  PE1_WF: 0,       PT_WF: 767,     PE2_WF: 0,       ER_WF: 0,      SC_PS: undefined,    SC_PF: undefined,    SC_PP: 0,     SC_AS: undefined,    SC_AF: undefined,    SC_AP: 0,   FB_PS: undefined,    FB_PF: undefined,    FB_PP: 0,     FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: '2024-12-16', AS_PF: '2025-04-22', AS_PP: 30.5, AS_AS: '2024-12-27', AS_AF: undefined,    AS_AP: 38.1, PE1_PS: undefined,    PE1_PF: undefined,    PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: '2025-06-06', PT_PF: '2025-06-19', PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: undefined,    PE2_PF: undefined,    PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: undefined,    ER_PF: undefined,    ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'HULL',    ZONE: 'COLUMN',         MOD: 'Column, NW',          LEVEL: 'NW, Lower Column',    NAME: 'Column Shell',           G_EREC: 'WC112', G_2PE: 'WC112', G_1PE: 'WC151', EREC: '',      PE2: '',      PE1: '',      PBLK: 'WC180', BLK: 'WC180', L: '13',   B: '7.3',  H: '9.9',  YARD: 'COSCO', SC_WF: 0,      FB_WF: 0,       AS_WF: 3562.5,  PE1_WF: 0,       PT_WF: 767,     PE2_WF: 0,       ER_WF: 0,      SC_PS: undefined,    SC_PF: undefined,    SC_PP: 0,     SC_AS: undefined,    SC_AF: undefined,    SC_AP: 0,   FB_PS: undefined,    FB_PF: undefined,    FB_PP: 0,     FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: '2024-12-16', AS_PF: '2025-04-22', AS_PP: 30.5, AS_AS: '2024-12-27', AS_AF: undefined,    AS_AP: 44.3, PE1_PS: undefined,    PE1_PF: undefined,    PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: '2025-06-06', PT_PF: '2025-06-19', PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: undefined,    PE2_PF: undefined,    PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: undefined,    ER_PF: undefined,    ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'HULL',    ZONE: 'COLUMN',         MOD: 'Column, NW',          LEVEL: 'NW, Lower Column',    NAME: '',                       G_EREC: 'WC112', G_2PE: 'WC112', G_1PE: 'WC151', EREC: '',      PE2: '',      PE1: '',      PBLK: '',      BLK: 'WC320', L: '1(n)', B: '1(n)', H: '1(n)', YARD: 'COSCO', SC_WF: 0,      FB_WF: 0,       AS_WF: 0,       PE1_WF: 0,       PT_WF: 0,       PE2_WF: 0,       ER_WF: 0,      SC_PS: undefined,    SC_PF: undefined,    SC_PP: 0,     SC_AS: undefined,    SC_AF: undefined,    SC_AP: 0,   FB_PS: undefined,    FB_PF: undefined,    FB_PP: 0,     FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: undefined,    AS_PF: undefined,    AS_PP: 0,    AS_AS: undefined,    AS_AF: undefined,    AS_AP: 0,    PE1_PS: undefined,    PE1_PF: undefined,    PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: undefined,    PT_PF: undefined,    PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: undefined,    PE2_PF: undefined,    PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: undefined,    ER_PF: undefined,    ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'HULL',    ZONE: 'COLUMN',         MOD: 'Column, NW',          LEVEL: 'NW, Lower Column',    NAME: 'Riser Guard Cone',       G_EREC: 'WC112', G_2PE: 'WC112', G_1PE: 'WC151', EREC: '',      PE2: '',      PE1: '',      PBLK: '',      BLK: 'WCC10', L: '1(n)', B: '1(n)', H: '1(n)', YARD: 'COSCO', SC_WF: 0,      FB_WF: 0,       AS_WF: 0,       PE1_WF: 0,       PT_WF: 0,       PE2_WF: 0,       ER_WF: 0,      SC_PS: undefined,    SC_PF: undefined,    SC_PP: 0,     SC_AS: undefined,    SC_AF: undefined,    SC_AP: 0,   FB_PS: undefined,    FB_PF: undefined,    FB_PP: 0,     FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: undefined,    AS_PF: undefined,    AS_PP: 0,    AS_AS: undefined,    AS_AF: undefined,    AS_AP: 0,    PE1_PS: undefined,    PE1_PF: undefined,    PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: undefined,    PT_PF: undefined,    PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: undefined,    PE2_PF: undefined,    PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: undefined,    ER_PF: undefined,    ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'HULL',    ZONE: 'COLUMN',         MOD: 'Column, NW',          LEVEL: 'NW, Lower Column',    NAME: 'Riser Guard Cone',       G_EREC: 'WC112', G_2PE: 'WC112', G_1PE: 'WC151', EREC: '',      PE2: '',      PE1: '',      PBLK: '',      BLK: 'WCC20', L: '1(n)', B: '1(n)', H: '1(n)', YARD: 'COSCO', SC_WF: 0,      FB_WF: 0,       AS_WF: 0,       PE1_WF: 0,       PT_WF: 0,       PE2_WF: 0,       ER_WF: 0,      SC_PS: undefined,    SC_PF: undefined,    SC_PP: 0,     SC_AS: undefined,    SC_AF: undefined,    SC_AP: 0,   FB_PS: undefined,    FB_PF: undefined,    FB_PP: 0,     FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: undefined,    AS_PF: undefined,    AS_PP: 0,    AS_AS: undefined,    AS_AF: undefined,    AS_AP: 0,    PE1_PS: undefined,    PE1_PF: undefined,    PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: undefined,    PT_PF: undefined,    PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: undefined,    PE2_PF: undefined,    PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: undefined,    ER_PF: undefined,    ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'HULL',    ZONE: 'COLUMN',         MOD: 'Column, NW',          LEVEL: 'NW, Middle Column',   NAME: 'Column Shell',           G_EREC: 'WC1K2', G_2PE: 'WC1K0', G_1PE: 'WC1K0', EREC: 'WC1K2', PE2: 'WC1K2', PE1: '',      PBLK: 'WC1K0', BLK: 'WC1K0', L: '13',   B: '7.3',  H: '10',   YARD: 'COSCO', SC_WF: 0,      FB_WF: 0,       AS_WF: 3172.5,  PE1_WF: 12961,   PT_WF: 683,     PE2_WF: 0,       ER_WF: 0,      SC_PS: undefined,    SC_PF: undefined,    SC_PP: 0,     SC_AS: undefined,    SC_AF: undefined,    SC_AP: 0,   FB_PS: undefined,    FB_PF: undefined,    FB_PP: 0,     FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: '2025-02-15', AS_PF: '2025-06-04', AS_PP: 0,    AS_AS: '2025-02-10', AS_AF: undefined,    AS_AP: 15,   PE1_PS: '2025-07-18', PE1_PF: '2025-09-22', PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: '2025-07-04', PT_PF: '2025-07-17', PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: undefined,    PE2_PF: undefined,    PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: undefined,    ER_PF: undefined,    ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'HULL',    ZONE: 'COLUMN',         MOD: 'Column, NW',          LEVEL: 'NW, Middle Column',   NAME: 'Column Shell',           G_EREC: 'WC1K2', G_2PE: 'WC1L0', G_1PE: 'WC1L0', EREC: '',      PE2: '',      PE1: '',      PBLK: 'WC1L0', BLK: 'WC1L0', L: '7.3',  B: '13',   H: '10',   YARD: 'COSCO', SC_WF: 0,      FB_WF: 0,       AS_WF: 3172.5,  PE1_WF: 0,       PT_WF: 683,     PE2_WF: 0,       ER_WF: 0,      SC_PS: undefined,    SC_PF: undefined,    SC_PP: 0,     SC_AS: undefined,    SC_AF: undefined,    SC_AP: 0,   FB_PS: undefined,    FB_PF: undefined,    FB_PP: 0,     FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: '2025-02-17', AS_PF: '2025-06-05', AS_PP: 0,    AS_AS: undefined,    AS_AF: undefined,    AS_AP: 0,    PE1_PS: undefined,    PE1_PF: undefined,    PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: '2025-07-05', PT_PF: '2025-07-18', PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: undefined,    PE2_PF: undefined,    PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: undefined,    ER_PF: undefined,    ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'HULL',    ZONE: 'COLUMN',         MOD: 'Column, NW',          LEVEL: 'NW, Middle Column',   NAME: 'Column Shell',           G_EREC: 'WC1K2', G_2PE: 'WC1M0', G_1PE: 'WC1M0', EREC: '',      PE2: '',      PE1: '',      PBLK: 'WC1M0', BLK: 'WC1M0', L: '7.3',  B: '13',   H: '10',   YARD: 'COSCO', SC_WF: 0,      FB_WF: 0,       AS_WF: 3172.5,  PE1_WF: 0,       PT_WF: 683,     PE2_WF: 0,       ER_WF: 2073.7, SC_PS: undefined,    SC_PF: undefined,    SC_PP: 0,     SC_AS: undefined,    SC_AF: undefined,    SC_AP: 0,   FB_PS: undefined,    FB_PF: undefined,    FB_PP: 0,     FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: '2025-02-18', AS_PF: '2025-06-06', AS_PP: 0,    AS_AS: undefined,    AS_AF: undefined,    AS_AP: 0,    PE1_PS: undefined,    PE1_PF: undefined,    PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: '2025-07-07', PT_PF: '2025-07-19', PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: undefined,    PE2_PF: undefined,    PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: '2025-11-05', ER_PF: '2025-12-02', ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'HULL',    ZONE: 'COLUMN',         MOD: 'Column, NW',          LEVEL: 'NW, Middle Column',   NAME: 'Column Shell',           G_EREC: 'WC1K2', G_2PE: 'WC1N0', G_1PE: 'WC1N0', EREC: '',      PE2: '',      PE1: '',      PBLK: 'WC1N0', BLK: 'WC1N0', L: '13',   B: '7.3',  H: '10.2', YARD: 'COSCO', SC_WF: 0,      FB_WF: 0,       AS_WF: 3198.5,  PE1_WF: 0,       PT_WF: 689,     PE2_WF: 0,       ER_WF: 0,      SC_PS: undefined,    SC_PF: undefined,    SC_PP: 0,     SC_AS: undefined,    SC_AF: undefined,    SC_AP: 0,   FB_PS: undefined,    FB_PF: undefined,    FB_PP: 0,     FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: '2025-02-18', AS_PF: '2025-06-06', AS_PP: 0,    AS_AS: undefined,    AS_AF: undefined,    AS_AP: 0,    PE1_PS: undefined,    PE1_PF: undefined,    PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: '2025-07-07', PT_PF: '2025-07-19', PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: undefined,    PE2_PF: undefined,    PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: undefined,    ER_PF: undefined,    ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'HULL',    ZONE: 'COLUMN',         MOD: 'Column, NW',          LEVEL: 'NW, Middle Column',   NAME: 'Column Shell',           G_EREC: 'WC1K2', G_2PE: 'WC1P0', G_1PE: 'WC1P0', EREC: '',      PE2: '',      PE1: '',      PBLK: 'WC1P0', BLK: 'WC1P0', L: '13',   B: '6.6',  H: '10',   YARD: 'COSCO', SC_WF: 0,      FB_WF: 0,       AS_WF: 3198.5,  PE1_WF: 0,       PT_WF: 689,     PE2_WF: 0,       ER_WF: 0,      SC_PS: undefined,    SC_PF: undefined,    SC_PP: 0,     SC_AS: undefined,    SC_AF: undefined,    SC_AP: 0,   FB_PS: undefined,    FB_PF: undefined,    FB_PP: 0,     FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: '2025-03-07', AS_PF: '2025-06-24', AS_PP: 0,    AS_AS: undefined,    AS_AF: undefined,    AS_AP: 0,    PE1_PS: undefined,    PE1_PF: undefined,    PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: '2025-07-23', PT_PF: '2025-08-05', PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: undefined,    PE2_PF: undefined,    PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: undefined,    ER_PF: undefined,    ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'HULL',    ZONE: 'COLUMN',         MOD: 'Column, NW',          LEVEL: 'NW, Middle Column',   NAME: 'Column Shell',           G_EREC: 'WC1K2', G_2PE: 'WC1Q0', G_1PE: 'WC1Q0', EREC: '',      PE2: '',      PE1: '',      PBLK: 'WC1Q0', BLK: 'WC1Q0', L: '7.3',  B: '13.1', H: '10',   YARD: 'COSCO', SC_WF: 0,      FB_WF: 0,       AS_WF: 3198.5,  PE1_WF: 0,       PT_WF: 689,     PE2_WF: 0,       ER_WF: 0,      SC_PS: undefined,    SC_PF: undefined,    SC_PP: 0,     SC_AS: undefined,    SC_AF: undefined,    SC_AP: 0,   FB_PS: undefined,    FB_PF: undefined,    FB_PP: 0,     FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: '2025-03-07', AS_PF: '2025-06-24', AS_PP: 0,    AS_AS: undefined,    AS_AF: undefined,    AS_AP: 0,    PE1_PS: undefined,    PE1_PF: undefined,    PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: '2025-07-23', PT_PF: '2025-08-05', PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: undefined,    PE2_PF: undefined,    PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: undefined,    ER_PF: undefined,    ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'HULL',    ZONE: 'COLUMN',         MOD: 'Column, NW',          LEVEL: 'NW, Middle Column',   NAME: 'Column Shell',           G_EREC: 'WC1K2', G_2PE: 'WC1R0', G_1PE: 'WC1R0', EREC: '',      PE2: '',      PE1: '',      PBLK: 'WC1R0', BLK: 'WC1R0', L: '7.3',  B: '13',   H: '10',   YARD: 'COSCO', SC_WF: 0,      FB_WF: 0,       AS_WF: 3198.5,  PE1_WF: 0,       PT_WF: 689,     PE2_WF: 0,       ER_WF: 0,      SC_PS: undefined,    SC_PF: undefined,    SC_PP: 0,     SC_AS: undefined,    SC_AF: undefined,    SC_AP: 0,   FB_PS: undefined,    FB_PF: undefined,    FB_PP: 0,     FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: '2025-03-08', AS_PF: '2025-06-25', AS_PP: 0,    AS_AS: undefined,    AS_AF: undefined,    AS_AP: 0,    PE1_PS: undefined,    PE1_PF: undefined,    PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: '2025-07-30', PT_PF: '2025-08-12', PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: undefined,    PE2_PF: undefined,    PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: undefined,    ER_PF: undefined,    ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'HULL',    ZONE: 'COLUMN',         MOD: 'Column, NW',          LEVEL: 'NW, Middle Column',   NAME: 'Column Shell',           G_EREC: 'WC1K2', G_2PE: 'WC1S0', G_1PE: 'WC1S0', EREC: '',      PE2: '',      PE1: '',      PBLK: 'WC1S0', BLK: 'WC1S0', L: '13.1', B: '7.3',  H: '10',   YARD: 'COSCO', SC_WF: 0,      FB_WF: 0,       AS_WF: 3198.5,  PE1_WF: 0,       PT_WF: 689,     PE2_WF: 0,       ER_WF: 0,      SC_PS: undefined,    SC_PF: undefined,    SC_PP: 0,     SC_AS: undefined,    SC_AF: undefined,    SC_AP: 0,   FB_PS: undefined,    FB_PF: undefined,    FB_PP: 0,     FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: '2025-03-08', AS_PF: '2025-06-25', AS_PP: 0,    AS_AS: undefined,    AS_AF: undefined,    AS_AP: 0,    PE1_PS: undefined,    PE1_PF: undefined,    PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: '2025-07-28', PT_PF: '2025-08-09', PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: undefined,    PE2_PF: undefined,    PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: undefined,    ER_PF: undefined,    ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'HULL',    ZONE: 'COLUMN',         MOD: 'Column, NW',          LEVEL: 'NW, Middle Column',   NAME: '',                       G_EREC: 'WC1K2', G_2PE: 'WC330', G_1PE: 'WC330', EREC: '',      PE2: '',      PE1: '',      PBLK: '',      BLK: 'WC330', L: '1(n)', B: '1(n)', H: '1(n)', YARD: 'COSCO', SC_WF: 0,      FB_WF: 0,       AS_WF: 0,       PE1_WF: 0,       PT_WF: 0,       PE2_WF: 0,       ER_WF: 0,      SC_PS: undefined,    SC_PF: undefined,    SC_PP: 0,     SC_AS: undefined,    SC_AF: undefined,    SC_AP: 0,   FB_PS: undefined,    FB_PF: undefined,    FB_PP: 0,     FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: undefined,    AS_PF: undefined,    AS_PP: 0,    AS_AS: undefined,    AS_AF: undefined,    AS_AP: 0,    PE1_PS: undefined,    PE1_PF: undefined,    PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: undefined,    PT_PF: undefined,    PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: undefined,    PE2_PF: undefined,    PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: undefined,    ER_PF: undefined,    ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'HULL',    ZONE: 'COLUMN',         MOD: 'Column, NW',          LEVEL: 'NW, Middle Column',   NAME: 'Riser Guard Cone',       G_EREC: 'WC1K2', G_2PE: 'WCC30', G_1PE: 'WCC30', EREC: '',      PE2: '',      PE1: '',      PBLK: '',      BLK: 'WCC30', L: '1(n)', B: '1(n)', H: '1(n)', YARD: 'COSCO', SC_WF: 0,      FB_WF: 0,       AS_WF: 0,       PE1_WF: 0,       PT_WF: 0,       PE2_WF: 0,       ER_WF: 0,      SC_PS: undefined,    SC_PF: undefined,    SC_PP: 0,     SC_AS: undefined,    SC_AF: undefined,    SC_AP: 0,   FB_PS: undefined,    FB_PF: undefined,    FB_PP: 0,     FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: undefined,    AS_PF: undefined,    AS_PP: 0,    AS_AS: undefined,    AS_AF: undefined,    AS_AP: 0,    PE1_PS: undefined,    PE1_PF: undefined,    PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: undefined,    PT_PF: undefined,    PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: undefined,    PE2_PF: undefined,    PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: undefined,    ER_PF: undefined,    ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'HULL',    ZONE: 'COLUMN',         MOD: 'Column, NW',          LEVEL: 'NW, Middle Column',   NAME: 'Riser Guard Cone',       G_EREC: 'WC1K2', G_2PE: 'WCC40', G_1PE: 'WCC40', EREC: '',      PE2: '',      PE1: '',      PBLK: '',      BLK: 'WCC40', L: '1(n)', B: '1(n)', H: '1(n)', YARD: 'COSCO', SC_WF: 0,      FB_WF: 0,       AS_WF: 0,       PE1_WF: 0,       PT_WF: 0,       PE2_WF: 0,       ER_WF: 0,      SC_PS: undefined,    SC_PF: undefined,    SC_PP: 0,     SC_AS: undefined,    SC_AF: undefined,    SC_AP: 0,   FB_PS: undefined,    FB_PF: undefined,    FB_PP: 0,     FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: undefined,    AS_PF: undefined,    AS_PP: 0,    AS_AS: undefined,    AS_AF: undefined,    AS_AP: 0,    PE1_PS: undefined,    PE1_PF: undefined,    PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: undefined,    PT_PF: undefined,    PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: undefined,    PE2_PF: undefined,    PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: undefined,    ER_PF: undefined,    ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'HULL',    ZONE: 'COLUMN',         MOD: 'Column, NW',          LEVEL: 'NW, Middle Column',   NAME: 'Riser Guard',            G_EREC: 'WCG10', G_2PE: 'WCG10', G_1PE: 'WCG10', EREC: 'WCG10', PE2: '',      PE1: '',      PBLK: '',      BLK: 'WCG10', L: '9',    B: '4.2',  H: '15.2', YARD: 'COSCO', SC_WF: 0,      FB_WF: 0,       AS_WF: 0,       PE1_WF: 0,       PT_WF: 0,       PE2_WF: 0,       ER_WF: 0,      SC_PS: undefined,    SC_PF: undefined,    SC_PP: 0,     SC_AS: undefined,    SC_AF: undefined,    SC_AP: 0,   FB_PS: undefined,    FB_PF: undefined,    FB_PP: 0,     FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: undefined,    AS_PF: undefined,    AS_PP: 0,    AS_AS: undefined,    AS_AF: undefined,    AS_AP: 0,    PE1_PS: undefined,    PE1_PF: undefined,    PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: undefined,    PT_PF: undefined,    PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: undefined,    PE2_PF: undefined,    PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: undefined,    ER_PF: undefined,    ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'HULL',    ZONE: 'COLUMN',         MOD: 'Column, NW',          LEVEL: 'NW, Middle Column',   NAME: 'Riser Guard',            G_EREC: 'WCG20', G_2PE: 'WCG20', G_1PE: 'WCG20', EREC: 'WCG20', PE2: '',      PE1: '',      PBLK: '',      BLK: 'WCG20', L: '4.2',  B: '5.7',  H: '15.2', YARD: 'COSCO', SC_WF: 0,      FB_WF: 0,       AS_WF: 0,       PE1_WF: 0,       PT_WF: 0,       PE2_WF: 0,       ER_WF: 0,      SC_PS: undefined,    SC_PF: undefined,    SC_PP: 0,     SC_AS: undefined,    SC_AF: undefined,    SC_AP: 0,   FB_PS: undefined,    FB_PF: undefined,    FB_PP: 0,     FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: undefined,    AS_PF: undefined,    AS_PP: 0,    AS_AS: undefined,    AS_AF: undefined,    AS_AP: 0,    PE1_PS: undefined,    PE1_PF: undefined,    PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: undefined,    PT_PF: undefined,    PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: undefined,    PE2_PF: undefined,    PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: undefined,    ER_PF: undefined,    ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'HULL',    ZONE: 'COLUMN',         MOD: 'Column, NW',          LEVEL: 'NW, Middle Column',   NAME: '',                       G_EREC: 'WC3A0', G_2PE: 'WC3A0', G_1PE: 'WC3A0', EREC: 'WC3A0', PE2: '',      PE1: '',      PBLK: 'WC3A0', BLK: 'WC3A0', L: '1(n)', B: '1(n)', H: '1(n)', YARD: 'HHI',   SC_WF: 8,      FB_WF: 8,       AS_WF: 0,       PE1_WF: 0,       PT_WF: 16,      PE2_WF: 0,       ER_WF: 2073.7, SC_PS: '2025-06-09', SC_PF: '2025-06-26', SC_PP: 0,     SC_AS: undefined,    SC_AF: undefined,    SC_AP: 0,   FB_PS: '2025-06-27', FB_PF: '2025-08-29', FB_PP: 0,     FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: undefined,    AS_PF: undefined,    AS_PP: 0,    AS_AS: undefined,    AS_AF: undefined,    AS_AP: 0,    PE1_PS: undefined,    PE1_PF: undefined,    PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: '2025-09-10', PT_PF: '2025-10-10', PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: undefined,    PE2_PF: undefined,    PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: '2025-10-22', ER_PF: '2025-11-18', ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'HULL',    ZONE: 'COLUMN',         MOD: 'Column, NW',          LEVEL: 'NW, Upper Column',    NAME: 'Column Shell',           G_EREC: 'WC1G2', G_2PE: 'WC1G2', G_1PE: 'WC1G1', EREC: 'WC1G2', PE2: 'WC1G2', PE1: 'WC1G1', PBLK: 'WC1G1', BLK: 'WC1G0', L: '19.5', B: '19.5', H: '5.3',  YARD: 'HHI',   SC_WF: 594.48, FB_WF: 1188.96, AS_WF: 1783.44, PE1_WF: 0,       PT_WF: 2711.73, PE2_WF: 5046,    ER_WF: 0,      SC_PS: '2025-03-04', SC_PF: '2025-03-21', SC_PP: 0,     SC_AS: undefined,    SC_AF: undefined,    SC_AP: 0,   FB_PS: '2025-03-24', FB_PF: '2025-04-22', FB_PP: 0,     FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: '2025-04-23', AS_PF: '2025-06-18', AS_PP: 0,    AS_AS: undefined,    AS_AF: undefined,    AS_AP: 0,    PE1_PS: undefined,    PE1_PF: undefined,    PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: '2025-07-25', PT_PF: '2025-09-01', PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: '2025-09-02', PE2_PF: '2025-11-25', PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: undefined,    ER_PF: undefined,    ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'HULL',    ZONE: 'COLUMN',         MOD: 'Column, NW',          LEVEL: 'NW, Upper Column',    NAME: 'Column Shell',           G_EREC: 'WC1G2', G_2PE: 'WC1G2', G_1PE: 'WC1G1', EREC: '',      PE2: '',      PE1: '',      PBLK: '',      BLK: 'WC340', L: '1(n)', B: '1(n)', H: '1(n)', YARD: 'HHI',   SC_WF: 8,      FB_WF: 16,      AS_WF: 0,       PE1_WF: 32,      PT_WF: 0,       PE2_WF: 0,       ER_WF: 0,      SC_PS: '2025-04-03', SC_PF: '2025-04-22', SC_PP: 0,     SC_AS: undefined,    SC_AF: undefined,    SC_AP: 0,   FB_PS: '2025-04-23', FB_PF: '2025-06-18', FB_PP: 0,     FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: undefined,    AS_PF: undefined,    AS_PP: 0,    AS_AS: undefined,    AS_AF: undefined,    AS_AP: 0,    PE1_PS: '2025-06-19', PE1_PF: '2025-07-23', PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: undefined,    PT_PF: undefined,    PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: undefined,    PE2_PF: undefined,    PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: undefined,    ER_PF: undefined,    ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'HULL',    ZONE: 'COLUMN',         MOD: 'Column, NW',          LEVEL: 'NW, Upper Column',    NAME: 'Column Shell',           G_EREC: 'WC1G2', G_2PE: 'WC1G2', G_1PE: 'WC1H1', EREC: '',      PE2: '',      PE1: 'WC1H1', PBLK: 'WC1H1', BLK: 'WC1H0', L: '20.7', B: '20.7', H: '5.9',  YARD: 'HHI',   SC_WF: 676.57, FB_WF: 1353.15, AS_WF: 2029.72, PE1_WF: 3382.87, PT_WF: 2711.73, PE2_WF: 3382.87, ER_WF: 0,      SC_PS: '2025-03-04', SC_PF: '2025-03-21', SC_PP: 0,     SC_AS: undefined,    SC_AF: undefined,    SC_AP: 0,   FB_PS: '2025-03-24', FB_PF: '2025-04-22', FB_PP: 0,     FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: '2025-04-23', AS_PF: '2025-06-18', AS_PP: 0,    AS_AS: undefined,    AS_AF: undefined,    AS_AP: 0,    PE1_PS: '2025-06-19', PE1_PF: '2025-07-24', PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: '2025-07-25', PT_PF: '2025-09-01', PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: '2025-09-02', PE2_PF: '2025-11-25', PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: undefined,    ER_PF: undefined,    ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'HULL',    ZONE: 'COLUMN',         MOD: 'Column, NW',          LEVEL: 'NW, Upper Column',    NAME: 'Column Shell',           G_EREC: 'WC1G2', G_2PE: 'WC1G2', G_1PE: 'WC1H1', EREC: '',      PE2: '',      PE1: '',      PBLK: '',      BLK: 'WC350', L: '1(n)', B: '1(n)', H: '1(n)', YARD: 'HHI',   SC_WF: 8,      FB_WF: 16,      AS_WF: 0,       PE1_WF: 32,      PT_WF: 0,       PE2_WF: 0,       ER_WF: 0,      SC_PS: '2025-04-03', SC_PF: '2025-04-22', SC_PP: 0,     SC_AS: undefined,    SC_AF: undefined,    SC_AP: 0,   FB_PS: '2025-04-23', FB_PF: '2025-06-18', FB_PP: 0,     FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: undefined,    AS_PF: undefined,    AS_PP: 0,    AS_AS: undefined,    AS_AF: undefined,    AS_AP: 0,    PE1_PS: '2025-06-19', PE1_PF: '2025-07-23', PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: undefined,    PT_PF: undefined,    PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: undefined,    PE2_PF: undefined,    PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: undefined,    ER_PF: undefined,    ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'HULL',    ZONE: 'COLUMN',         MOD: 'Column, NW',          LEVEL: 'NW, Upper Column',    NAME: 'Stair House',            G_EREC: 'WC1G2', G_2PE: 'WC1G2', G_1PE: 'WC1H1', EREC: '',      PE2: '',      PE1: '',      PBLK: '',      BLK: 'WC1J0', L: '5',    B: '2.6',  H: '2.5',  YARD: 'HHI',   SC_WF: 24,     FB_WF: 40,      AS_WF: 0,       PE1_WF: 70.77,   PT_WF: 0,       PE2_WF: 0,       ER_WF: 0,      SC_PS: '2025-04-03', SC_PF: '2025-04-22', SC_PP: 0,     SC_AS: undefined,    SC_AF: undefined,    SC_AP: 0,   FB_PS: '2025-04-23', FB_PF: '2025-06-18', FB_PP: 0,     FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: undefined,    AS_PF: undefined,    AS_PP: 0,    AS_AS: undefined,    AS_AF: undefined,    AS_AP: 0,    PE1_PS: '2025-06-19', PE1_PF: '2025-07-23', PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: undefined,    PT_PF: undefined,    PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: undefined,    PE2_PF: undefined,    PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: undefined,    ER_PF: undefined,    ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'HULL',    ZONE: 'COLUMN',         MOD: 'Column, NW',          LEVEL: 'NW, Upper Column',    NAME: 'UCF Brace',              G_EREC: 'WC1G2', G_2PE: 'WC1G2', G_1PE: 'WC1H1', EREC: '',      PE2: '',      PE1: '',      PBLK: '',      BLK: 'WF220', L: '2.3',  B: '4',    H: '3.5',  YARD: 'HHI',   SC_WF: 24,     FB_WF: 40,      AS_WF: 0,       PE1_WF: 70.77,   PT_WF: 0,       PE2_WF: 0,       ER_WF: 0,      SC_PS: '2025-03-27', SC_PF: '2025-04-15', SC_PP: 0,     SC_AS: undefined,    SC_AF: undefined,    SC_AP: 0,   FB_PS: '2025-04-16', FB_PF: '2025-06-18', FB_PP: 0,     FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: undefined,    AS_PF: undefined,    AS_PP: 0,    AS_AS: undefined,    AS_AF: undefined,    AS_AP: 0,    PE1_PS: '2025-06-19', PE1_PF: '2025-07-23', PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: undefined,    PT_PF: undefined,    PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: undefined,    PE2_PF: undefined,    PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: undefined,    ER_PF: undefined,    ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'HULL',    ZONE: 'COLUMN',         MOD: 'Column, NW',          LEVEL: 'NW, Upper Column',    NAME: 'UCF Brace',              G_EREC: 'WC1G2', G_2PE: 'WC1G2', G_1PE: 'WC1H1', EREC: '',      PE2: '',      PE1: '',      PBLK: '',      BLK: 'NF220', L: '4',    B: '2.3',  H: '3.5',  YARD: 'HHI',   SC_WF: 24,     FB_WF: 40,      AS_WF: 0,       PE1_WF: 70.77,   PT_WF: 0,       PE2_WF: 0,       ER_WF: 0,      SC_PS: '2025-03-27', SC_PF: '2025-04-15', SC_PP: 0,     SC_AS: undefined,    SC_AF: undefined,    SC_AP: 0,   FB_PS: '2025-04-16', FB_PF: '2025-06-18', FB_PP: 0,     FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: undefined,    AS_PF: undefined,    AS_PP: 0,    AS_AS: undefined,    AS_AF: undefined,    AS_AP: 0,    PE1_PS: '2025-06-19', PE1_PF: '2025-07-23', PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: undefined,    PT_PF: undefined,    PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: undefined,    PE2_PF: undefined,    PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: undefined,    ER_PF: undefined,    ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'HULL',    ZONE: 'COLUMN',         MOD: 'Column, NW',          LEVEL: 'NW, Upper Column',    NAME: 'Hull Access P/F',        G_EREC: 'WCP10', G_2PE: 'WCP10', G_1PE: 'WCP10', EREC: 'WCP10', PE2: '',      PE1: '',      PBLK: 'WCP10', BLK: 'WCP10', L: '2.9',  B: '6.5',  H: '3.3',  YARD: 'HHI',   SC_WF: 8,      FB_WF: 16,      AS_WF: 0,       PE1_WF: 32,      PT_WF: 106.97,  PE2_WF: 0,       ER_WF: 2073.7, SC_PS: '2025-07-15', SC_PF: '2025-08-13', SC_PP: 0,     SC_AS: undefined,    SC_AF: undefined,    SC_AP: 0,   FB_PS: '2025-08-14', FB_PF: '2025-09-29', FB_PP: 0,     FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: undefined,    AS_PF: undefined,    AS_PP: 0,    AS_AS: undefined,    AS_AF: undefined,    AS_AP: 0,    PE1_PS: '2025-09-30', PE1_PF: '2025-10-22', PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: '2025-10-23', PT_PF: '2025-11-14', PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: undefined,    PE2_PF: undefined,    PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: '2025-12-03', ER_PF: '2025-12-23', ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'HULL',    ZONE: 'COLUMN',         MOD: 'Column, NW',          LEVEL: 'NW, Upper Column',    NAME: 'Hull Access P/F',        G_EREC: 'WCP20', G_2PE: 'WCP20', G_1PE: 'WCP20', EREC: 'WCP20', PE2: '',      PE1: '',      PBLK: 'WCP20', BLK: 'WCP20', L: '4.9',  B: '11.5', H: '8.7',  YARD: 'HHI',   SC_WF: 8,      FB_WF: 16,      AS_WF: 0,       PE1_WF: 32,      PT_WF: 160.46,  PE2_WF: 0,       ER_WF: 2073.7, SC_PS: '2025-07-15', SC_PF: '2025-08-13', SC_PP: 0,     SC_AS: undefined,    SC_AF: undefined,    SC_AP: 0,   FB_PS: '2025-08-14', FB_PF: '2025-09-29', FB_PP: 0,     FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: undefined,    AS_PF: undefined,    AS_PP: 0,    AS_AS: undefined,    AS_AF: undefined,    AS_AP: 0,    PE1_PS: '2025-09-30', PE1_PF: '2025-10-22', PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: '2025-10-23', PT_PF: '2025-11-14', PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: undefined,    PE2_PF: undefined,    PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: '2025-12-03', ER_PF: '2025-12-23', ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'HULL',    ZONE: 'COLUMN',         MOD: 'Column, NW',          LEVEL: 'NW, Upper Column',    NAME: 'Hull Access P/F',        G_EREC: 'WCP30', G_2PE: 'WCP30', G_1PE: 'WCP30', EREC: 'WCP30', PE2: '',      PE1: '',      PBLK: 'WCP30', BLK: 'WCP30', L: '6.2',  B: '11.3', H: '8.7',  YARD: 'HHI',   SC_WF: 8,      FB_WF: 16,      AS_WF: 0,       PE1_WF: 32,      PT_WF: 267.43,  PE2_WF: 0,       ER_WF: 2073.7, SC_PS: '2025-07-15', SC_PF: '2025-08-13', SC_PP: 0,     SC_AS: undefined,    SC_AF: undefined,    SC_AP: 0,   FB_PS: '2025-08-14', FB_PF: '2025-09-29', FB_PP: 0,     FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: undefined,    AS_PF: undefined,    AS_PP: 0,    AS_AS: undefined,    AS_AF: undefined,    AS_AP: 0,    PE1_PS: '2025-09-30', PE1_PF: '2025-10-22', PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: '2025-10-23', PT_PF: '2025-11-14', PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: undefined,    PE2_PF: undefined,    PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: '2025-12-03', ER_PF: '2025-12-23', ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'HULL',    ZONE: 'COLUMN',         MOD: 'Column, NW',          LEVEL: 'NW, Upper Column',    NAME: 'Hull Access P/F',        G_EREC: 'WCP40', G_2PE: 'WCP40', G_1PE: 'WCP40', EREC: 'WCP40', PE2: '',      PE1: '',      PBLK: 'WCP40', BLK: 'WCP40', L: '11',   B: '5.2',  H: '8.7',  YARD: 'HHI',   SC_WF: 8.49,   FB_WF: 24,      AS_WF: 0,       PE1_WF: 42.46,   PT_WF: 431.45,  PE2_WF: 0,       ER_WF: 2073.7, SC_PS: '2025-07-15', SC_PF: '2025-08-13', SC_PP: 0,     SC_AS: undefined,    SC_AF: undefined,    SC_AP: 0,   FB_PS: '2025-08-14', FB_PF: '2025-09-29', FB_PP: 0,     FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: undefined,    AS_PF: undefined,    AS_PP: 0,    AS_AS: undefined,    AS_AF: undefined,    AS_AP: 0,    PE1_PS: '2025-09-30', PE1_PF: '2025-10-22', PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: '2025-10-23', PT_PF: '2025-11-14', PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: undefined,    PE2_PF: undefined,    PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: '2025-12-03', ER_PF: '2025-12-23', ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'HULL',    ZONE: 'COLUMN',         MOD: 'Column, NW',          LEVEL: 'NW, Upper Column',    NAME: 'Hull Access P/F',        G_EREC: 'WCP50', G_2PE: 'WCP50', G_1PE: 'WCP50', EREC: 'WCP50', PE2: '',      PE1: '',      PBLK: 'WCP50', BLK: 'WCP50', L: '11.3', B: '7.1',  H: '8.7',  YARD: 'HHI',   SC_WF: 8.49,   FB_WF: 24,      AS_WF: 0,       PE1_WF: 42.46,   PT_WF: 392.23,  PE2_WF: 0,       ER_WF: 2073.7, SC_PS: '2025-07-15', SC_PF: '2025-08-13', SC_PP: 0,     SC_AS: undefined,    SC_AF: undefined,    SC_AP: 0,   FB_PS: '2025-08-14', FB_PF: '2025-09-29', FB_PP: 0,     FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: undefined,    AS_PF: undefined,    AS_PP: 0,    AS_AS: undefined,    AS_AF: undefined,    AS_AP: 0,    PE1_PS: '2025-09-30', PE1_PF: '2025-10-22', PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: '2025-10-23', PT_PF: '2025-11-14', PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: undefined,    PE2_PF: undefined,    PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: '2025-12-03', ER_PF: '2025-12-23', ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'HULL',    ZONE: 'COLUMN',         MOD: 'Column, NW',          LEVEL: 'NW, Upper Column',    NAME: 'Hull Access P/F',        G_EREC: 'WCP60', G_2PE: 'WCP60', G_1PE: 'WCP60', EREC: 'WCP60', PE2: '',      PE1: '',      PBLK: 'WCP60', BLK: 'WCP60', L: '4.3',  B: '2.7',  H: '3',    YARD: 'HHI',   SC_WF: 8,      FB_WF: 16,      AS_WF: 0,       PE1_WF: 32,      PT_WF: 53.49,   PE2_WF: 0,       ER_WF: 2073.7, SC_PS: '2025-07-15', SC_PF: '2025-08-13', SC_PP: 0,     SC_AS: undefined,    SC_AF: undefined,    SC_AP: 0,   FB_PS: '2025-08-14', FB_PF: '2025-09-29', FB_PP: 0,     FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: undefined,    AS_PF: undefined,    AS_PP: 0,    AS_AS: undefined,    AS_AF: undefined,    AS_AP: 0,    PE1_PS: '2025-09-30', PE1_PF: '2025-10-22', PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: '2025-10-23', PT_PF: '2025-11-14', PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: undefined,    PE2_PF: undefined,    PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: '2025-12-03', ER_PF: '2025-12-23', ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'HULL',    ZONE: 'COLUMN',         MOD: 'Column, SW',          LEVEL: 'SW, Lower Column',    NAME: 'Column Shell',           G_EREC: 'WC212', G_2PE: 'WC212', G_1PE: 'WC211', EREC: 'WC212', PE2: 'WC212', PE1: 'WC211', PBLK: 'WC210', BLK: 'WC210', L: '13',   B: '7.3',  H: '10',   YARD: 'COSCO', SC_WF: 0,      FB_WF: 0,       AS_WF: 3562.5,  PE1_WF: 7240,    PT_WF: 767,     PE2_WF: 3965,    ER_WF: 0,      SC_PS: undefined,    SC_PF: undefined,    SC_PP: 0,     SC_AS: undefined,    SC_AF: undefined,    SC_AP: 0,   FB_PS: undefined,    FB_PF: undefined,    FB_PP: 0,     FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: '2024-12-17', AS_PF: '2025-04-28', AS_PP: 28.6, AS_AS: '2024-12-28', AS_AF: undefined,    AS_AP: 26.2, PE1_PS: '2025-06-12', PE1_PF: '2025-07-23', PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: '2025-05-28', PT_PF: '2025-06-11', PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: '2025-08-18', PE2_PF: '2025-10-10', PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: undefined,    ER_PF: undefined,    ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'HULL',    ZONE: 'COLUMN',         MOD: 'Column, SW',          LEVEL: 'SW, Lower Column',    NAME: 'Column Shell',           G_EREC: 'WC212', G_2PE: 'WC212', G_1PE: 'WC211', EREC: '',      PE2: '',      PE1: '',      PBLK: 'WC220', BLK: 'WC220', L: '7.3',  B: '14.1', H: '10',   YARD: 'COSCO', SC_WF: 0,      FB_WF: 0,       AS_WF: 3562.5,  PE1_WF: 0,       PT_WF: 767,     PE2_WF: 0,       ER_WF: 0,      SC_PS: undefined,    SC_PF: undefined,    SC_PP: 0,     SC_AS: undefined,    SC_AF: undefined,    SC_AP: 0,   FB_PS: undefined,    FB_PF: undefined,    FB_PP: 0,     FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: '2024-12-17', AS_PF: '2025-04-28', AS_PP: 28.6, AS_AS: '2024-12-30', AS_AF: undefined,    AS_AP: 41.7, PE1_PS: undefined,    PE1_PF: undefined,    PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: '2025-05-28', PT_PF: '2025-06-11', PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: undefined,    PE2_PF: undefined,    PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: undefined,    ER_PF: undefined,    ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'HULL',    ZONE: 'COLUMN',         MOD: 'Column, SW',          LEVEL: 'SW, Lower Column',    NAME: 'Column Shell',           G_EREC: 'WC212', G_2PE: 'WC212', G_1PE: 'WC211', EREC: '',      PE2: '',      PE1: '',      PBLK: 'WC230', BLK: 'WC230', L: '8.4',  B: '13.4', H: '10',   YARD: 'COSCO', SC_WF: 0,      FB_WF: 0,       AS_WF: 3562.5,  PE1_WF: 0,       PT_WF: 767,     PE2_WF: 0,       ER_WF: 0,      SC_PS: undefined,    SC_PF: undefined,    SC_PP: 0,     SC_AS: undefined,    SC_AF: undefined,    SC_AP: 0,   FB_PS: undefined,    FB_PF: undefined,    FB_PP: 0,     FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: '2024-12-17', AS_PF: '2025-04-28', AS_PP: 28.6, AS_AS: '2024-12-29', AS_AF: undefined,    AS_AP: 23.3, PE1_PS: undefined,    PE1_PF: undefined,    PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: '2025-05-29', PT_PF: '2025-06-12', PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: undefined,    PE2_PF: undefined,    PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: undefined,    ER_PF: undefined,    ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'HULL',    ZONE: 'COLUMN',         MOD: 'Column, SW',          LEVEL: 'SW, Lower Column',    NAME: 'Column Shell',           G_EREC: 'WC212', G_2PE: 'WC212', G_1PE: 'WC211', EREC: '',      PE2: '',      PE1: '',      PBLK: 'WC240', BLK: 'WC240', L: '13.5', B: '7.8',  H: '10',   YARD: 'COSCO', SC_WF: 0,      FB_WF: 0,       AS_WF: 3562.5,  PE1_WF: 0,       PT_WF: 767,     PE2_WF: 0,       ER_WF: 0,      SC_PS: undefined,    SC_PF: undefined,    SC_PP: 0,     SC_AS: undefined,    SC_AF: undefined,    SC_AP: 0,   FB_PS: undefined,    FB_PF: undefined,    FB_PP: 0,     FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: '2024-12-17', AS_PF: '2025-04-28', AS_PP: 28.6, AS_AS: '2024-12-30', AS_AF: undefined,    AS_AP: 25.1, PE1_PS: undefined,    PE1_PF: undefined,    PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: '2025-05-29', PT_PF: '2025-06-12', PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: undefined,    PE2_PF: undefined,    PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: undefined,    ER_PF: undefined,    ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'HULL',    ZONE: 'COLUMN',         MOD: 'Column, SW',          LEVEL: 'SW, Lower Column',    NAME: 'Column Support',         G_EREC: 'WC212', G_2PE: 'WC212', G_1PE: 'WC211', EREC: '',      PE2: '',      PE1: '',      PBLK: '',      BLK: 'WC410', L: '1(n)', B: '1(n)', H: '1(n)', YARD: 'COSCO', SC_WF: 0,      FB_WF: 0,       AS_WF: 0,       PE1_WF: 0,       PT_WF: 0,       PE2_WF: 0,       ER_WF: 0,      SC_PS: undefined,    SC_PF: undefined,    SC_PP: 0,     SC_AS: undefined,    SC_AF: undefined,    SC_AP: 0,   FB_PS: undefined,    FB_PF: undefined,    FB_PP: 0,     FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: undefined,    AS_PF: undefined,    AS_PP: 0,    AS_AS: undefined,    AS_AF: undefined,    AS_AP: 0,    PE1_PS: undefined,    PE1_PF: undefined,    PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: undefined,    PT_PF: undefined,    PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: undefined,    PE2_PF: undefined,    PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: undefined,    ER_PF: undefined,    ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'HULL',    ZONE: 'COLUMN',         MOD: 'Column, SW',          LEVEL: 'SW, Lower Column',    NAME: 'Column Shell',           G_EREC: 'WC212', G_2PE: 'WC212', G_1PE: 'WC251', EREC: '',      PE2: '',      PE1: 'WC251', PBLK: 'WC250', BLK: 'WC250', L: '13',   B: '7.3',  H: '9.9',  YARD: 'COSCO', SC_WF: 0,      FB_WF: 0,       AS_WF: 3562.5,  PE1_WF: 7240,    PT_WF: 767,     PE2_WF: 1647.56, ER_WF: 0,      SC_PS: undefined,    SC_PF: undefined,    SC_PP: 0,     SC_AS: undefined,    SC_AF: undefined,    SC_AP: 0,   FB_PS: undefined,    FB_PF: undefined,    FB_PP: 0,     FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: '2024-12-18', AS_PF: '2025-04-29', AS_PP: 27.9, AS_AS: '2025-01-02', AS_AF: undefined,    AS_AP: 25.1, PE1_PS: '2025-06-14', PE1_PF: '2025-07-23', PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: '2025-05-30', PT_PF: '2025-06-13', PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: '2025-08-18', PE2_PF: '2025-10-10', PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: undefined,    ER_PF: undefined,    ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'HULL',    ZONE: 'COLUMN',         MOD: 'Column, SW',          LEVEL: 'SW, Lower Column',    NAME: 'Column Shell',           G_EREC: 'WC212', G_2PE: 'WC212', G_1PE: 'WC251', EREC: '',      PE2: '',      PE1: '',      PBLK: 'WC260', BLK: 'WC260', L: '7.3',  B: '13',   H: '9.9',  YARD: 'COSCO', SC_WF: 0,      FB_WF: 0,       AS_WF: 3562.5,  PE1_WF: 0,       PT_WF: 767,     PE2_WF: 0,       ER_WF: 0,      SC_PS: undefined,    SC_PF: undefined,    SC_PP: 0,     SC_AS: undefined,    SC_AF: undefined,    SC_AP: 0,   FB_PS: undefined,    FB_PF: undefined,    FB_PP: 0,     FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: '2024-12-18', AS_PF: '2025-04-29', AS_PP: 27.9, AS_AS: '2025-01-03', AS_AF: undefined,    AS_AP: 25.4, PE1_PS: undefined,    PE1_PF: undefined,    PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: '2025-05-30', PT_PF: '2025-06-13', PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: undefined,    PE2_PF: undefined,    PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: undefined,    ER_PF: undefined,    ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'HULL',    ZONE: 'COLUMN',         MOD: 'Column, SW',          LEVEL: 'SW, Lower Column',    NAME: 'Column Shell',           G_EREC: 'WC212', G_2PE: 'WC212', G_1PE: 'WC251', EREC: '',      PE2: '',      PE1: '',      PBLK: 'WC270', BLK: 'WC270', L: '7.3',  B: '13.3', H: '9.9',  YARD: 'COSCO', SC_WF: 0,      FB_WF: 0,       AS_WF: 3562.5,  PE1_WF: 0,       PT_WF: 767,     PE2_WF: 0,       ER_WF: 0,      SC_PS: undefined,    SC_PF: undefined,    SC_PP: 0,     SC_AS: undefined,    SC_AF: undefined,    SC_AP: 0,   FB_PS: undefined,    FB_PF: undefined,    FB_PP: 0,     FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: '2024-12-19', AS_PF: '2025-04-30', AS_PP: 27.3, AS_AS: '2025-01-04', AS_AF: undefined,    AS_AP: 36.4, PE1_PS: undefined,    PE1_PF: undefined,    PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: '2025-06-02', PT_PF: '2025-06-14', PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: undefined,    PE2_PF: undefined,    PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: undefined,    ER_PF: undefined,    ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'HULL',    ZONE: 'COLUMN',         MOD: 'Column, SW',          LEVEL: 'SW, Lower Column',    NAME: 'Column Shell',           G_EREC: 'WC212', G_2PE: 'WC212', G_1PE: 'WC251', EREC: '',      PE2: '',      PE1: '',      PBLK: 'WC280', BLK: 'WC280', L: '13',   B: '7.3',  H: '9.9',  YARD: 'COSCO', SC_WF: 0,      FB_WF: 0,       AS_WF: 3562.5,  PE1_WF: 0,       PT_WF: 767,     PE2_WF: 0,       ER_WF: 0,      SC_PS: undefined,    SC_PF: undefined,    SC_PP: 0,     SC_AS: undefined,    SC_AF: undefined,    SC_AP: 0,   FB_PS: undefined,    FB_PF: undefined,    FB_PP: 0,     FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: '2024-12-19', AS_PF: '2025-04-30', AS_PP: 27.3, AS_AS: '2025-01-05', AS_AF: undefined,    AS_AP: 23.5, PE1_PS: undefined,    PE1_PF: undefined,    PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: '2025-06-02', PT_PF: '2025-06-14', PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: undefined,    PE2_PF: undefined,    PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: undefined,    ER_PF: undefined,    ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'HULL',    ZONE: 'COLUMN',         MOD: 'Column, SW',          LEVEL: 'SW, Lower Column',    NAME: 'Column Support',         G_EREC: 'WC212', G_2PE: 'WC212', G_1PE: 'WC251', EREC: '',      PE2: '',      PE1: '',      PBLK: '',      BLK: 'WC420', L: '1(n)', B: '1(n)', H: '1(n)', YARD: 'COSCO', SC_WF: 0,      FB_WF: 0,       AS_WF: 0,       PE1_WF: 0,       PT_WF: 0,       PE2_WF: 0,       ER_WF: 0,      SC_PS: undefined,    SC_PF: undefined,    SC_PP: 0,     SC_AS: undefined,    SC_AF: undefined,    SC_AP: 0,   FB_PS: undefined,    FB_PF: undefined,    FB_PP: 0,     FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: undefined,    AS_PF: undefined,    AS_PP: 0,    AS_AS: undefined,    AS_AF: undefined,    AS_AP: 0,    PE1_PS: undefined,    PE1_PF: undefined,    PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: undefined,    PT_PF: undefined,    PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: undefined,    PE2_PF: undefined,    PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: undefined,    ER_PF: undefined,    ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'HULL',    ZONE: 'COLUMN',         MOD: 'Column, SW',          LEVEL: 'SW, Middle Column',   NAME: 'Column Shell',           G_EREC: 'WC2K2', G_2PE: 'WC2K2', G_1PE: 'WC2K0', EREC: 'WC2K2', PE2: 'WC2K2', PE1: '',      PBLK: 'WC2K0', BLK: 'WC2K0', L: '13',   B: '7.3',  H: '10',   YARD: 'COSCO', SC_WF: 0,      FB_WF: 0,       AS_WF: 2535.5,  PE1_WF: 10305.5, PT_WF: 546,     PE2_WF: 0,       ER_WF: 0,      SC_PS: undefined,    SC_PF: undefined,    SC_PP: 0,     SC_AS: undefined,    SC_AF: undefined,    SC_AP: 0,   FB_PS: undefined,    FB_PF: undefined,    FB_PP: 0,     FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: '2025-02-19', AS_PF: '2025-06-11', AS_PP: 0,    AS_AS: undefined,    AS_AF: undefined,    AS_AP: 0,    PE1_PS: '2025-07-26', PE1_PF: '2025-09-22', PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: '2025-07-11', PT_PF: '2025-07-24', PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: undefined,    PE2_PF: undefined,    PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: undefined,    ER_PF: undefined,    ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'HULL',    ZONE: 'COLUMN',         MOD: 'Column, SW',          LEVEL: 'SW, Middle Column',   NAME: 'Column Shell',           G_EREC: 'WC2K2', G_2PE: 'WC2K2', G_1PE: 'WC2L0', EREC: '',      PE2: '',      PE1: '',      PBLK: 'WC2L0', BLK: 'WC2L0', L: '7.3',  B: '13',   H: '10',   YARD: 'COSCO', SC_WF: 0,      FB_WF: 0,       AS_WF: 2535.5,  PE1_WF: 0,       PT_WF: 546,     PE2_WF: 0,       ER_WF: 0,      SC_PS: undefined,    SC_PF: undefined,    SC_PP: 0,     SC_AS: undefined,    SC_AF: undefined,    SC_AP: 0,   FB_PS: undefined,    FB_PF: undefined,    FB_PP: 0,     FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: '2025-02-20', AS_PF: '2025-06-12', AS_PP: 0,    AS_AS: undefined,    AS_AF: undefined,    AS_AP: 0,    PE1_PS: undefined,    PE1_PF: undefined,    PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: '2025-07-12', PT_PF: '2025-07-25', PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: undefined,    PE2_PF: undefined,    PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: undefined,    ER_PF: undefined,    ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'HULL',    ZONE: 'COLUMN',         MOD: 'Column, SW',          LEVEL: 'SW, Middle Column',   NAME: 'Column Shell',           G_EREC: 'WC2K2', G_2PE: 'WC2K2', G_1PE: 'WC2M0', EREC: '',      PE2: '',      PE1: '',      PBLK: 'WC2M0', BLK: 'WC2M0', L: '7.3',  B: '13',   H: '10',   YARD: 'COSCO', SC_WF: 0,      FB_WF: 0,       AS_WF: 2535.5,  PE1_WF: 0,       PT_WF: 546,     PE2_WF: 0,       ER_WF: 2073.7, SC_PS: undefined,    SC_PF: undefined,    SC_PP: 0,     SC_AS: undefined,    SC_AF: undefined,    SC_AP: 0,   FB_PS: undefined,    FB_PF: undefined,    FB_PP: 0,     FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: '2025-02-21', AS_PF: '2025-06-13', AS_PP: 0,    AS_AS: undefined,    AS_AF: undefined,    AS_AP: 0,    PE1_PS: undefined,    PE1_PF: undefined,    PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: '2025-07-14', PT_PF: '2025-07-26', PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: undefined,    PE2_PF: undefined,    PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: '2025-11-10', ER_PF: '2025-12-05', ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'HULL',    ZONE: 'COLUMN',         MOD: 'Column, SW',          LEVEL: 'SW, Middle Column',   NAME: 'Column Shell',           G_EREC: 'WC2K2', G_2PE: 'WC2K2', G_1PE: 'WC2N0', EREC: '',      PE2: '',      PE1: '',      PBLK: 'WC2N0', BLK: 'WC2N0', L: '13',   B: '7.3',  H: '10.2', YARD: 'COSCO', SC_WF: 0,      FB_WF: 0,       AS_WF: 2535.5,  PE1_WF: 0,       PT_WF: 546,     PE2_WF: 0,       ER_WF: 0,      SC_PS: undefined,    SC_PF: undefined,    SC_PP: 0,     SC_AS: undefined,    SC_AF: undefined,    SC_AP: 0,   FB_PS: undefined,    FB_PF: undefined,    FB_PP: 0,     FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: '2025-02-22', AS_PF: '2025-06-14', AS_PP: 0,    AS_AS: undefined,    AS_AF: undefined,    AS_AP: 0,    PE1_PS: undefined,    PE1_PF: undefined,    PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: '2025-07-15', PT_PF: '2025-07-28', PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: undefined,    PE2_PF: undefined,    PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: undefined,    ER_PF: undefined,    ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'HULL',    ZONE: 'COLUMN',         MOD: 'Column, SW',          LEVEL: 'SW, Middle Column',   NAME: 'Column Shell',           G_EREC: 'WC2K2', G_2PE: 'WC2K2', G_1PE: 'WC2P0', EREC: '',      PE2: '',      PE1: '',      PBLK: 'WC2P0', BLK: 'WC2P0', L: '13',   B: '6.6',  H: '10',   YARD: 'COSCO', SC_WF: 0,      FB_WF: 0,       AS_WF: 2535.5,  PE1_WF: 0,       PT_WF: 546,     PE2_WF: 0,       ER_WF: 0,      SC_PS: undefined,    SC_PF: undefined,    SC_PP: 0,     SC_AS: undefined,    SC_AF: undefined,    SC_AP: 0,   FB_PS: undefined,    FB_PF: undefined,    FB_PP: 0,     FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: '2025-03-10', AS_PF: '2025-06-30', AS_PP: 0,    AS_AS: undefined,    AS_AF: undefined,    AS_AP: 0,    PE1_PS: undefined,    PE1_PF: undefined,    PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: '2025-07-30', PT_PF: '2025-08-12', PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: undefined,    PE2_PF: undefined,    PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: undefined,    ER_PF: undefined,    ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'HULL',    ZONE: 'COLUMN',         MOD: 'Column, SW',          LEVEL: 'SW, Middle Column',   NAME: 'Column Shell',           G_EREC: 'WC2K2', G_2PE: 'WC2K2', G_1PE: 'WC2Q0', EREC: '',      PE2: '',      PE1: '',      PBLK: 'WC2Q0', BLK: 'WC2Q0', L: '13.1', B: '7.3',  H: '10',   YARD: 'COSCO', SC_WF: 0,      FB_WF: 0,       AS_WF: 2535.5,  PE1_WF: 0,       PT_WF: 546,     PE2_WF: 0,       ER_WF: 0,      SC_PS: undefined,    SC_PF: undefined,    SC_PP: 0,     SC_AS: undefined,    SC_AF: undefined,    SC_AP: 0,   FB_PS: undefined,    FB_PF: undefined,    FB_PP: 0,     FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: '2025-03-10', AS_PF: '2025-06-30', AS_PP: 0,    AS_AS: undefined,    AS_AF: undefined,    AS_AP: 0,    PE1_PS: undefined,    PE1_PF: undefined,    PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: '2025-07-30', PT_PF: '2025-08-12', PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: undefined,    PE2_PF: undefined,    PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: undefined,    ER_PF: undefined,    ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'HULL',    ZONE: 'COLUMN',         MOD: 'Column, SW',          LEVEL: 'SW, Middle Column',   NAME: 'Column Shell',           G_EREC: 'WC2K2', G_2PE: 'WC2K2', G_1PE: 'WC2R0', EREC: '',      PE2: '',      PE1: '',      PBLK: 'WC2R0', BLK: 'WC2R0', L: '7.3',  B: '13.1', H: '10',   YARD: 'COSCO', SC_WF: 0,      FB_WF: 0,       AS_WF: 2535.5,  PE1_WF: 0,       PT_WF: 546,     PE2_WF: 0,       ER_WF: 0,      SC_PS: undefined,    SC_PF: undefined,    SC_PP: 0,     SC_AS: undefined,    SC_AF: undefined,    SC_AP: 0,   FB_PS: undefined,    FB_PF: undefined,    FB_PP: 0,     FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: '2025-03-11', AS_PF: '2025-07-01', AS_PP: 0,    AS_AS: undefined,    AS_AF: undefined,    AS_AP: 0,    PE1_PS: undefined,    PE1_PF: undefined,    PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: '2025-07-31', PT_PF: '2025-08-13', PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: undefined,    PE2_PF: undefined,    PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: undefined,    ER_PF: undefined,    ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'HULL',    ZONE: 'COLUMN',         MOD: 'Column, SW',          LEVEL: 'SW, Middle Column',   NAME: 'Column Shell',           G_EREC: 'WC2K2', G_2PE: 'WC2K2', G_1PE: 'WC2S0', EREC: '',      PE2: '',      PE1: '',      PBLK: 'WC2S0', BLK: 'WC2S0', L: '7.3',  B: '13',   H: '10',   YARD: 'COSCO', SC_WF: 0,      FB_WF: 0,       AS_WF: 2535.5,  PE1_WF: 0,       PT_WF: 546,     PE2_WF: 0,       ER_WF: 0,      SC_PS: undefined,    SC_PF: undefined,    SC_PP: 0,     SC_AS: undefined,    SC_AF: undefined,    SC_AP: 0,   FB_PS: undefined,    FB_PF: undefined,    FB_PP: 0,     FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: '2025-03-11', AS_PF: '2025-07-01', AS_PP: 0,    AS_AS: undefined,    AS_AF: undefined,    AS_AP: 0,    PE1_PS: undefined,    PE1_PF: undefined,    PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: '2025-07-31', PT_PF: '2025-08-13', PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: undefined,    PE2_PF: undefined,    PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: undefined,    ER_PF: undefined,    ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'HULL',    ZONE: 'COLUMN',         MOD: 'Column, SW',          LEVEL: 'SW, Middle Column',   NAME: 'Column Support',         G_EREC: 'WC2K2', G_2PE: 'WC2K2', G_1PE: 'WC430', EREC: '',      PE2: '',      PE1: '',      PBLK: '',      BLK: 'WC430', L: '1(n)', B: '1(n)', H: '1(n)', YARD: 'COSCO', SC_WF: 0,      FB_WF: 0,       AS_WF: 0,       PE1_WF: 0,       PT_WF: 0,       PE2_WF: 0,       ER_WF: 0,      SC_PS: undefined,    SC_PF: undefined,    SC_PP: 0,     SC_AS: undefined,    SC_AF: undefined,    SC_AP: 0,   FB_PS: undefined,    FB_PF: undefined,    FB_PP: 0,     FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: undefined,    AS_PF: undefined,    AS_PP: 0,    AS_AS: undefined,    AS_AF: undefined,    AS_AP: 0,    PE1_PS: undefined,    PE1_PF: undefined,    PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: undefined,    PT_PF: undefined,    PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: undefined,    PE2_PF: undefined,    PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: undefined,    ER_PF: undefined,    ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'HULL',    ZONE: 'COLUMN',         MOD: 'Column, SW',          LEVEL: 'SW, Middle Column',   NAME: 'Column Support',         G_EREC: 'WC4A0', G_2PE: 'WC4A0', G_1PE: 'WC4A0', EREC: 'WC4A0', PE2: '',      PE1: '',      PBLK: 'WC4A0', BLK: 'WC4A0', L: '2',    B: '2.5',  H: '1.1',  YARD: 'HHI',   SC_WF: 8,      FB_WF: 8,       AS_WF: 0,       PE1_WF: 0,       PT_WF: 16,      PE2_WF: 0,       ER_WF: 2073.7, SC_PS: '2025-06-12', SC_PF: '2025-07-01', SC_PP: 0,     SC_AS: undefined,    SC_AF: undefined,    SC_AP: 0,   FB_PS: '2025-07-02', FB_PF: '2025-09-03', FB_PP: 0,     FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: undefined,    AS_PF: undefined,    AS_PP: 0,    AS_AS: undefined,    AS_AF: undefined,    AS_AP: 0,    PE1_PS: undefined,    PE1_PF: undefined,    PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: '2025-09-15', PT_PF: '2025-10-15', PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: undefined,    PE2_PF: undefined,    PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: '2025-10-27', ER_PF: '2025-11-21', ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'HULL',    ZONE: 'COLUMN',         MOD: 'Column, SW',          LEVEL: 'SW, Upper Column',    NAME: '',                       G_EREC: 'WC2G2', G_2PE: 'WC2G2', G_1PE: 'WC2G1', EREC: 'WC2G2', PE2: 'WC2G2', PE1: 'WC2G1', PBLK: 'WC2G1', BLK: 'WC2G0', L: '19.5', B: '19.5', H: '5.3',  YARD: 'HHI',   SC_WF: 594.48, FB_WF: 1188.96, AS_WF: 1783.44, PE1_WF: 2972.4,  PT_WF: 2711.73, PE2_WF: 5046,    ER_WF: 0,      SC_PS: '2025-03-07', SC_PF: '2025-03-26', SC_PP: 0,     SC_AS: undefined,    SC_AF: undefined,    SC_AP: 0,   FB_PS: '2025-03-27', FB_PF: '2025-04-25', FB_PP: 0,     FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: '2025-04-28', AS_PF: '2025-06-23', AS_PP: 0,    AS_AS: undefined,    AS_AF: undefined,    AS_AP: 0,    PE1_PS: '2025-06-24', PE1_PF: '2025-08-11', PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: '2025-08-12', PT_PF: '2025-09-04', PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: '2025-09-05', PE2_PF: '2025-11-28', PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: undefined,    ER_PF: undefined,    ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'HULL',    ZONE: 'COLUMN',         MOD: 'Column, SW',          LEVEL: 'SW, Upper Column',    NAME: 'Column Support',         G_EREC: 'WC2G2', G_2PE: 'WC2G2', G_1PE: 'WC2G1', EREC: '',      PE2: '',      PE1: '',      PBLK: '',      BLK: 'WC440', L: '1(n)', B: '1(n)', H: '1(n)', YARD: 'HHI',   SC_WF: 8,      FB_WF: 16,      AS_WF: 0,       PE1_WF: 32,      PT_WF: 0,       PE2_WF: 0,       ER_WF: 0,      SC_PS: '2025-04-08', SC_PF: '2025-04-25', SC_PP: 0,     SC_AS: undefined,    SC_AF: undefined,    SC_AP: 0,   FB_PS: '2025-04-28', FB_PF: '2025-06-23', FB_PP: 0,     FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: undefined,    AS_PF: undefined,    AS_PP: 0,    AS_AS: undefined,    AS_AF: undefined,    AS_AP: 0,    PE1_PS: '2025-06-24', PE1_PF: '2025-07-25', PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: undefined,    PT_PF: undefined,    PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: undefined,    PE2_PF: undefined,    PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: undefined,    ER_PF: undefined,    ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'HULL',    ZONE: 'COLUMN',         MOD: 'Column, SW',          LEVEL: 'SW, Upper Column',    NAME: '',                       G_EREC: 'WC2G2', G_2PE: 'WC2G2', G_1PE: 'WC2H1', EREC: '',      PE2: '',      PE1: 'WC2H1', PBLK: 'WC2H1', BLK: 'WC2H0', L: '20.7', B: '20.7', H: '5.9',  YARD: 'HHI',   SC_WF: 676.57, FB_WF: 1353.15, AS_WF: 2029.72, PE1_WF: 3382.87, PT_WF: 2711.73, PE2_WF: 3382.87, ER_WF: 0,      SC_PS: '2025-03-07', SC_PF: '2025-03-26', SC_PP: 0,     SC_AS: undefined,    SC_AF: undefined,    SC_AP: 0,   FB_PS: '2025-03-27', FB_PF: '2025-04-25', FB_PP: 0,     FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: '2025-04-28', AS_PF: '2025-06-23', AS_PP: 0,    AS_AS: undefined,    AS_AF: undefined,    AS_AP: 0,    PE1_PS: '2025-06-24', PE1_PF: '2025-08-11', PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: '2025-08-12', PT_PF: '2025-09-04', PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: '2025-09-05', PE2_PF: '2025-11-28', PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: undefined,    ER_PF: undefined,    ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'HULL',    ZONE: 'COLUMN',         MOD: 'Column, SW',          LEVEL: 'SW, Upper Column',    NAME: 'Hull E&I Building Wall', G_EREC: 'WC2G2', G_2PE: 'WC2G2', G_1PE: 'WC2H1', EREC: '',      PE2: '',      PE1: '',      PBLK: '',      BLK: 'WCW10', L: '13',   B: '4.1',  H: '5.2',  YARD: 'HHI',   SC_WF: 24,     FB_WF: 40,      AS_WF: 0,       PE1_WF: 70.77,   PT_WF: 0,       PE2_WF: 0,       ER_WF: 0,      SC_PS: '2025-04-08', SC_PF: '2025-04-25', SC_PP: 0,     SC_AS: undefined,    SC_AF: undefined,    SC_AP: 0,   FB_PS: '2025-04-28', FB_PF: '2025-06-23', FB_PP: 0,     FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: undefined,    AS_PF: undefined,    AS_PP: 0,    AS_AS: undefined,    AS_AF: undefined,    AS_AP: 0,    PE1_PS: '2025-06-24', PE1_PF: '2025-07-25', PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: undefined,    PT_PF: undefined,    PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: undefined,    PE2_PF: undefined,    PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: undefined,    ER_PF: undefined,    ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'HULL',    ZONE: 'COLUMN',         MOD: 'Column, SW',          LEVEL: 'SW, Upper Column',    NAME: 'Column Support',         G_EREC: 'WC2G2', G_2PE: 'WC2G2', G_1PE: 'WC2H1', EREC: '',      PE2: '',      PE1: '',      PBLK: '',      BLK: 'WC450', L: '1(n)', B: '1(n)', H: '1(n)', YARD: 'HHI',   SC_WF: 8,      FB_WF: 16,      AS_WF: 0,       PE1_WF: 32,      PT_WF: 0,       PE2_WF: 0,       ER_WF: 0,      SC_PS: '2025-04-08', SC_PF: '2025-04-25', SC_PP: 0,     SC_AS: undefined,    SC_AF: undefined,    SC_AP: 0,   FB_PS: '2025-04-28', FB_PF: '2025-06-23', FB_PP: 0,     FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: undefined,    AS_PF: undefined,    AS_PP: 0,    AS_AS: undefined,    AS_AF: undefined,    AS_AP: 0,    PE1_PS: '2025-06-24', PE1_PF: '2025-07-25', PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: undefined,    PT_PF: undefined,    PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: undefined,    PE2_PF: undefined,    PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: undefined,    ER_PF: undefined,    ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'HULL',    ZONE: 'COLUMN',         MOD: 'Column, SW',          LEVEL: 'SW, Upper Column',    NAME: 'Stair House',            G_EREC: 'WC2G2', G_2PE: 'WC2G2', G_1PE: 'WC2H1', EREC: '',      PE2: '',      PE1: '',      PBLK: '',      BLK: 'WC2J0', L: '5',    B: '2.6',  H: '2.5',  YARD: 'HHI',   SC_WF: 24,     FB_WF: 40,      AS_WF: 0,       PE1_WF: 70.77,   PT_WF: 0,       PE2_WF: 0,       ER_WF: 0,      SC_PS: '2025-04-08', SC_PF: '2025-04-25', SC_PP: 0,     SC_AS: undefined,    SC_AF: undefined,    SC_AP: 0,   FB_PS: '2025-04-28', FB_PF: '2025-06-23', FB_PP: 0,     FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: undefined,    AS_PF: undefined,    AS_PP: 0,    AS_AS: undefined,    AS_AF: undefined,    AS_AP: 0,    PE1_PS: '2025-06-24', PE1_PF: '2025-07-25', PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: undefined,    PT_PF: undefined,    PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: undefined,    PE2_PF: undefined,    PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: undefined,    ER_PF: undefined,    ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'HULL',    ZONE: 'COLUMN',         MOD: 'Column, SW',          LEVEL: 'SW, Upper Column',    NAME: 'UCF Brace',              G_EREC: 'WC2G2', G_2PE: 'WC2G2', G_1PE: 'WC2H1', EREC: '',      PE2: '',      PE1: '',      PBLK: '',      BLK: 'WF230', L: '2.3',  B: '4',    H: '3.5',  YARD: 'HHI',   SC_WF: 24,     FB_WF: 40,      AS_WF: 0,       PE1_WF: 70.77,   PT_WF: 0,       PE2_WF: 0,       ER_WF: 0,      SC_PS: '2025-04-15', SC_PF: '2025-05-07', SC_PP: 0,     SC_AS: undefined,    SC_AF: undefined,    SC_AP: 0,   FB_PS: '2025-05-08', FB_PF: '2025-06-23', FB_PP: 0,     FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: undefined,    AS_PF: undefined,    AS_PP: 0,    AS_AS: undefined,    AS_AF: undefined,    AS_AP: 0,    PE1_PS: '2025-06-24', PE1_PF: '2025-07-25', PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: undefined,    PT_PF: undefined,    PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: undefined,    PE2_PF: undefined,    PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: undefined,    ER_PF: undefined,    ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'HULL',    ZONE: 'COLUMN',         MOD: 'Column, SW',          LEVEL: 'SW, Upper Column',    NAME: 'UCF Brace',              G_EREC: 'WC2G2', G_2PE: 'WC2G2', G_1PE: 'WC2H1', EREC: '',      PE2: '',      PE1: '',      PBLK: '',      BLK: 'SF220', L: '4',    B: '2.3',  H: '3.5',  YARD: 'HHI',   SC_WF: 24,     FB_WF: 40,      AS_WF: 0,       PE1_WF: 70.77,   PT_WF: 0,       PE2_WF: 0,       ER_WF: 0,      SC_PS: '2025-04-15', SC_PF: '2025-05-07', SC_PP: 0,     SC_AS: undefined,    SC_AF: undefined,    SC_AP: 0,   FB_PS: '2025-05-08', FB_PF: '2025-06-23', FB_PP: 0,     FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: undefined,    AS_PF: undefined,    AS_PP: 0,    AS_AS: undefined,    AS_AF: undefined,    AS_AP: 0,    PE1_PS: '2025-06-24', PE1_PF: '2025-07-25', PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: undefined,    PT_PF: undefined,    PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: undefined,    PE2_PF: undefined,    PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: undefined,    ER_PF: undefined,    ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'HULL',    ZONE: 'COLUMN',         MOD: 'Column, SW',          LEVEL: 'SW, Upper Column',    NAME: 'Hull Access P/F',        G_EREC: 'WC2G2', G_2PE: 'WC2G2', G_1PE: 'WC2H1', EREC: '',      PE2: '',      PE1: '',      PBLK: '',      BLK: 'WCPA0', L: '2.6',  B: '1.4',  H: '7.4',  YARD: 'HHI',   SC_WF: 8,      FB_WF: 8,       AS_WF: 0,       PE1_WF: 24,      PT_WF: 0,       PE2_WF: 0,       ER_WF: 0,      SC_PS: '2025-04-17', SC_PF: '2025-05-08', SC_PP: 0,     SC_AS: undefined,    SC_AF: undefined,    SC_AP: 0,   FB_PS: '2025-05-09', FB_PF: '2025-06-23', FB_PP: 0,     FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: undefined,    AS_PF: undefined,    AS_PP: 0,    AS_AS: undefined,    AS_AF: undefined,    AS_AP: 0,    PE1_PS: '2025-06-24', PE1_PF: '2025-07-21', PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: undefined,    PT_PF: undefined,    PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: undefined,    PE2_PF: undefined,    PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: undefined,    ER_PF: undefined,    ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'HULL',    ZONE: 'COLUMN',         MOD: 'Column, SW',          LEVEL: 'SW, Upper Column',    NAME: 'Hull Access P/F',        G_EREC: 'WCP70', G_2PE: 'WCP70', G_1PE: 'WCP70', EREC: 'WCP70', PE2: '',      PE1: '',      PBLK: '',      BLK: 'WCP70', L: '5.5',  B: '5.8',  H: '4.9',  YARD: 'HHI',   SC_WF: 8,      FB_WF: 16,      AS_WF: 0,       PE1_WF: 32,      PT_WF: 0,       PE2_WF: 0,       ER_WF: 0,      SC_PS: '2025-04-15', SC_PF: '2025-05-07', SC_PP: 0,     SC_AS: undefined,    SC_AF: undefined,    SC_AP: 0,   FB_PS: '2025-05-08', FB_PF: '2025-06-23', FB_PP: 0,     FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: undefined,    AS_PF: undefined,    AS_PP: 0,    AS_AS: undefined,    AS_AF: undefined,    AS_AP: 0,    PE1_PS: '2025-06-24', PE1_PF: '2025-07-25', PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: undefined,    PT_PF: undefined,    PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: undefined,    PE2_PF: undefined,    PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: undefined,    ER_PF: undefined,    ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'HULL',    ZONE: 'COLUMN',         MOD: 'Column, SW',          LEVEL: 'SW, Upper Column',    NAME: 'Hull Access P/F',        G_EREC: 'WCP80', G_2PE: 'WCP80', G_1PE: 'WCP80', EREC: 'WCP80', PE2: '',      PE1: '',      PBLK: 'WCP80', BLK: 'WCP80', L: '4.1',  B: '12.9', H: '4.5',  YARD: 'HHI',   SC_WF: 8,      FB_WF: 16,      AS_WF: 0,       PE1_WF: 32,      PT_WF: 213.94,  PE2_WF: 0,       ER_WF: 2073.7, SC_PS: '2025-07-11', SC_PF: '2025-08-11', SC_PP: 0,     SC_AS: undefined,    SC_AF: undefined,    SC_AP: 0,   FB_PS: '2025-08-12', FB_PF: '2025-09-25', FB_PP: 0,     FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: undefined,    AS_PF: undefined,    AS_PP: 0,    AS_AS: undefined,    AS_AF: undefined,    AS_AP: 0,    PE1_PS: '2025-09-26', PE1_PF: '2025-10-20', PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: '2025-10-21', PT_PF: '2025-11-12', PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: undefined,    PE2_PF: undefined,    PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: '2025-12-01', ER_PF: '2025-12-19', ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'HULL',    ZONE: 'COLUMN',         MOD: 'Column, SW',          LEVEL: 'SW, Upper Column',    NAME: 'Hull Access P/F',        G_EREC: 'WCP90', G_2PE: 'WCP90', G_1PE: 'WCP90', EREC: 'WCP90', PE2: '',      PE1: '',      PBLK: 'WCP90', BLK: 'WCP90', L: '7.7',  B: '2.8',  H: '3.3',  YARD: 'HHI',   SC_WF: 8,      FB_WF: 16,      AS_WF: 0,       PE1_WF: 32,      PT_WF: 171.15,  PE2_WF: 0,       ER_WF: 2073.7, SC_PS: '2025-07-11', SC_PF: '2025-08-11', SC_PP: 0,     SC_AS: undefined,    SC_AF: undefined,    SC_AP: 0,   FB_PS: '2025-08-12', FB_PF: '2025-09-25', FB_PP: 0,     FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: undefined,    AS_PF: undefined,    AS_PP: 0,    AS_AS: undefined,    AS_AF: undefined,    AS_AP: 0,    PE1_PS: '2025-09-26', PE1_PF: '2025-10-20', PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: '2025-10-21', PT_PF: '2025-11-12', PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: undefined,    PE2_PF: undefined,    PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: '2025-12-01', ER_PF: '2025-12-19', ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'HULL',    ZONE: 'COLUMN',         MOD: 'Column, NE',          LEVEL: 'NE, Lower Column',    NAME: 'Column Shell',           G_EREC: 'EC112', G_2PE: 'EC112', G_1PE: 'EC111', EREC: 'EC112', PE2: 'EC112', PE1: 'EC111', PBLK: 'EC110', BLK: 'EC110', L: '13',   B: '7.3',  H: '10',   YARD: 'COSCO', SC_WF: 0,      FB_WF: 0,       AS_WF: 3562.5,  PE1_WF: 7240,    PT_WF: 767,     PE2_WF: 3965,    ER_WF: 0,      SC_PS: undefined,    SC_PF: undefined,    SC_PP: 0,     SC_AS: undefined,    SC_AF: undefined,    SC_AP: 0,   FB_PS: undefined,    FB_PF: undefined,    FB_PP: 0,     FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: '2024-12-03', AS_PF: '2025-04-01', AS_PP: 45.7, AS_AS: '2024-12-09', AS_AF: undefined,    AS_AP: 52.5, PE1_PS: '2025-05-17', PE1_PF: '2025-06-25', PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: '2025-05-03', PT_PF: '2025-05-16', PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: '2025-07-21', PE2_PF: '2025-09-18', PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: undefined,    ER_PF: undefined,    ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'HULL',    ZONE: 'COLUMN',         MOD: 'Column, NE',          LEVEL: 'NE, Lower Column',    NAME: 'Column Shell',           G_EREC: 'EC112', G_2PE: 'EC112', G_1PE: 'EC111', EREC: '',      PE2: '',      PE1: '',      PBLK: 'EC120', BLK: 'EC120', L: '7.3',  B: '14.1', H: '10',   YARD: 'COSCO', SC_WF: 0,      FB_WF: 0,       AS_WF: 3562.5,  PE1_WF: 0,       PT_WF: 767,     PE2_WF: 0,       ER_WF: 0,      SC_PS: undefined,    SC_PF: undefined,    SC_PP: 0,     SC_AS: undefined,    SC_AF: undefined,    SC_AP: 0,   FB_PS: undefined,    FB_PF: undefined,    FB_PP: 0,     FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: '2024-12-03', AS_PF: '2025-04-01', AS_PP: 45.7, AS_AS: '2024-12-09', AS_AF: undefined,    AS_AP: 52.5, PE1_PS: undefined,    PE1_PF: undefined,    PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: '2025-05-03', PT_PF: '2025-05-16', PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: undefined,    PE2_PF: undefined,    PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: undefined,    ER_PF: undefined,    ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'HULL',    ZONE: 'COLUMN',         MOD: 'Column, NE',          LEVEL: 'NE, Lower Column',    NAME: 'Column Shell',           G_EREC: 'EC112', G_2PE: 'EC112', G_1PE: 'EC111', EREC: '',      PE2: '',      PE1: '',      PBLK: 'EC130', BLK: 'EC130', L: '8.4',  B: '13.4', H: '10',   YARD: 'COSCO', SC_WF: 0,      FB_WF: 0,       AS_WF: 3562.5,  PE1_WF: 0,       PT_WF: 767,     PE2_WF: 0,       ER_WF: 0,      SC_PS: undefined,    SC_PF: undefined,    SC_PP: 0,     SC_AS: undefined,    SC_AF: undefined,    SC_AP: 0,   FB_PS: undefined,    FB_PF: undefined,    FB_PP: 0,     FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: '2024-12-04', AS_PF: '2025-04-02', AS_PP: 44.3, AS_AS: '2024-12-11', AS_AF: undefined,    AS_AP: 52.5, PE1_PS: undefined,    PE1_PF: undefined,    PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: '2025-05-05', PT_PF: '2025-05-17', PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: undefined,    PE2_PF: undefined,    PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: undefined,    ER_PF: undefined,    ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'HULL',    ZONE: 'COLUMN',         MOD: 'Column, NE',          LEVEL: 'NE, Lower Column',    NAME: 'Column Shell',           G_EREC: 'EC112', G_2PE: 'EC112', G_1PE: 'EC111', EREC: '',      PE2: '',      PE1: '',      PBLK: 'EC140', BLK: 'EC140', L: '13.5', B: '7.8',  H: '10',   YARD: 'COSCO', SC_WF: 0,      FB_WF: 0,       AS_WF: 3562.5,  PE1_WF: 0,       PT_WF: 767,     PE2_WF: 0,       ER_WF: 0,      SC_PS: undefined,    SC_PF: undefined,    SC_PP: 0,     SC_AS: undefined,    SC_AF: undefined,    SC_AP: 0,   FB_PS: undefined,    FB_PF: undefined,    FB_PP: 0,     FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: '2024-12-04', AS_PF: '2025-04-02', AS_PP: 44.3, AS_AS: '2024-12-11', AS_AF: undefined,    AS_AP: 52.5, PE1_PS: undefined,    PE1_PF: undefined,    PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: '2025-05-05', PT_PF: '2025-05-17', PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: undefined,    PE2_PF: undefined,    PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: undefined,    ER_PF: undefined,    ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'HULL',    ZONE: 'COLUMN',         MOD: 'Column, NE',          LEVEL: 'NE, Lower Column',    NAME: '',                       G_EREC: 'EC112', G_2PE: 'EC112', G_1PE: 'EC111', EREC: '',      PE2: '',      PE1: '',      PBLK: '',      BLK: 'EC310', L: '1(n)', B: '1(n)', H: '1(n)', YARD: 'COSCO', SC_WF: 0,      FB_WF: 0,       AS_WF: 0,       PE1_WF: 0,       PT_WF: 0,       PE2_WF: 0,       ER_WF: 0,      SC_PS: undefined,    SC_PF: undefined,    SC_PP: 0,     SC_AS: undefined,    SC_AF: undefined,    SC_AP: 0,   FB_PS: undefined,    FB_PF: undefined,    FB_PP: 0,     FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: undefined,    AS_PF: undefined,    AS_PP: 0,    AS_AS: undefined,    AS_AF: undefined,    AS_AP: 0,    PE1_PS: undefined,    PE1_PF: undefined,    PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: undefined,    PT_PF: undefined,    PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: undefined,    PE2_PF: undefined,    PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: undefined,    ER_PF: undefined,    ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'HULL',    ZONE: 'COLUMN',         MOD: 'Column, NE',          LEVEL: 'NE, Lower Column',    NAME: 'Column Shell',           G_EREC: 'EC112', G_2PE: 'EC112', G_1PE: 'EC151', EREC: '',      PE2: '',      PE1: 'EC151', PBLK: 'EC150', BLK: 'EC150', L: '13',   B: '7.3',  H: '9.9',  YARD: 'COSCO', SC_WF: 0,      FB_WF: 0,       AS_WF: 3562.5,  PE1_WF: 7240,    PT_WF: 767,     PE2_WF: 1647.56, ER_WF: 0,      SC_PS: undefined,    SC_PF: undefined,    SC_PP: 0,     SC_AS: undefined,    SC_AF: undefined,    SC_AP: 0,   FB_PS: undefined,    FB_PF: undefined,    FB_PP: 0,     FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: '2024-12-05', AS_PF: '2025-04-03', AS_PP: 42.9, AS_AS: '2024-12-12', AS_AF: undefined,    AS_AP: 52.5, PE1_PS: '2025-05-20', PE1_PF: '2025-06-26', PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: '2025-05-06', PT_PF: '2025-05-19', PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: '2025-07-21', PE2_PF: '2025-09-18', PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: undefined,    ER_PF: undefined,    ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'HULL',    ZONE: 'COLUMN',         MOD: 'Column, NE',          LEVEL: 'NE, Lower Column',    NAME: 'Column Shell',           G_EREC: 'EC112', G_2PE: 'EC112', G_1PE: 'EC151', EREC: '',      PE2: '',      PE1: '',      PBLK: 'EC160', BLK: 'EC160', L: '7.3',  B: '13',   H: '9.9',  YARD: 'COSCO', SC_WF: 0,      FB_WF: 0,       AS_WF: 3562.5,  PE1_WF: 0,       PT_WF: 767,     PE2_WF: 0,       ER_WF: 0,      SC_PS: undefined,    SC_PF: undefined,    SC_PP: 0,     SC_AS: undefined,    SC_AF: undefined,    SC_AP: 0,   FB_PS: undefined,    FB_PF: undefined,    FB_PP: 0,     FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: '2024-12-05', AS_PF: '2025-04-03', AS_PP: 42.9, AS_AS: '2024-12-13', AS_AF: undefined,    AS_AP: 52.5, PE1_PS: undefined,    PE1_PF: undefined,    PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: '2025-05-06', PT_PF: '2025-05-19', PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: undefined,    PE2_PF: undefined,    PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: undefined,    ER_PF: undefined,    ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'HULL',    ZONE: 'COLUMN',         MOD: 'Column, NE',          LEVEL: 'NE, Lower Column',    NAME: 'Column Shell',           G_EREC: 'EC112', G_2PE: 'EC112', G_1PE: 'EC151', EREC: '',      PE2: '',      PE1: '',      PBLK: 'EC170', BLK: 'EC170', L: '7.3',  B: '13.3', H: '9.9',  YARD: 'COSCO', SC_WF: 0,      FB_WF: 0,       AS_WF: 3562.5,  PE1_WF: 0,       PT_WF: 767,     PE2_WF: 0,       ER_WF: 0,      SC_PS: undefined,    SC_PF: undefined,    SC_PP: 0,     SC_AS: undefined,    SC_AF: undefined,    SC_AP: 0,   FB_PS: undefined,    FB_PF: undefined,    FB_PP: 0,     FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: '2024-12-06', AS_PF: '2025-04-05', AS_PP: 41.5, AS_AS: '2024-12-14', AS_AF: undefined,    AS_AP: 52.5, PE1_PS: undefined,    PE1_PF: undefined,    PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: '2025-05-07', PT_PF: '2025-05-20', PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: undefined,    PE2_PF: undefined,    PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: undefined,    ER_PF: undefined,    ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'HULL',    ZONE: 'COLUMN',         MOD: 'Column, NE',          LEVEL: 'NE, Lower Column',    NAME: 'Column Shell',           G_EREC: 'EC112', G_2PE: 'EC112', G_1PE: 'EC151', EREC: '',      PE2: '',      PE1: '',      PBLK: 'EC180', BLK: 'EC180', L: '13',   B: '7.3',  H: '9.9',  YARD: 'COSCO', SC_WF: 0,      FB_WF: 0,       AS_WF: 3562.5,  PE1_WF: 0,       PT_WF: 767,     PE2_WF: 0,       ER_WF: 0,      SC_PS: undefined,    SC_PF: undefined,    SC_PP: 0,     SC_AS: undefined,    SC_AF: undefined,    SC_AP: 0,   FB_PS: undefined,    FB_PF: undefined,    FB_PP: 0,     FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: '2024-12-06', AS_PF: '2025-04-05', AS_PP: 41.5, AS_AS: '2024-12-15', AS_AF: undefined,    AS_AP: 52.5, PE1_PS: undefined,    PE1_PF: undefined,    PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: '2025-05-07', PT_PF: '2025-05-20', PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: undefined,    PE2_PF: undefined,    PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: undefined,    ER_PF: undefined,    ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'HULL',    ZONE: 'COLUMN',         MOD: 'Column, NE',          LEVEL: 'NE, Lower Column',    NAME: '',                       G_EREC: 'EC112', G_2PE: 'EC112', G_1PE: 'EC151', EREC: '',      PE2: '',      PE1: '',      PBLK: '',      BLK: 'EC320', L: '1(n)', B: '1(n)', H: '1(n)', YARD: 'COSCO', SC_WF: 0,      FB_WF: 0,       AS_WF: 0,       PE1_WF: 0,       PT_WF: 0,       PE2_WF: 0,       ER_WF: 0,      SC_PS: undefined,    SC_PF: undefined,    SC_PP: 0,     SC_AS: undefined,    SC_AF: undefined,    SC_AP: 0,   FB_PS: undefined,    FB_PF: undefined,    FB_PP: 0,     FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: undefined,    AS_PF: undefined,    AS_PP: 0,    AS_AS: undefined,    AS_AF: undefined,    AS_AP: 0,    PE1_PS: undefined,    PE1_PF: undefined,    PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: undefined,    PT_PF: undefined,    PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: undefined,    PE2_PF: undefined,    PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: undefined,    ER_PF: undefined,    ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'HULL',    ZONE: 'COLUMN',         MOD: 'Column, NE',          LEVEL: 'NE, Middle Column',   NAME: 'Column Shell',           G_EREC: 'EC1K2', G_2PE: 'EC1K2', G_1PE: 'EC1K0', EREC: 'EC1K2', PE2: 'EC1K2', PE1: '',      PBLK: 'EC1K0', BLK: 'EC1K0', L: '13',   B: '7.3',  H: '10',   YARD: 'COSCO', SC_WF: 0,      FB_WF: 0,       AS_WF: 2535.5,  PE1_WF: 10305.5, PT_WF: 546,     PE2_WF: 0,       ER_WF: 0,      SC_PS: undefined,    SC_PF: undefined,    SC_PP: 0,     SC_AS: undefined,    SC_AF: undefined,    SC_AP: 0,   FB_PS: undefined,    FB_PF: undefined,    FB_PP: 0,     FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: '2025-02-07', AS_PF: '2025-05-29', AS_PP: 4.6,  AS_AS: '2025-02-05', AS_AF: undefined,    AS_AP: 15,   PE1_PS: '2025-07-15', PE1_PF: '2025-09-15', PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: '2025-07-02', PT_PF: '2025-07-15', PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: undefined,    PE2_PF: undefined,    PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: undefined,    ER_PF: undefined,    ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'HULL',    ZONE: 'COLUMN',         MOD: 'Column, NE',          LEVEL: 'NE, Middle Column',   NAME: 'Column Shell',           G_EREC: 'EC1K2', G_2PE: 'EC1K2', G_1PE: 'EC1L0', EREC: '',      PE2: '',      PE1: '',      PBLK: 'EC1L0', BLK: 'EC1L0', L: '7.3',  B: '13',   H: '10',   YARD: 'COSCO', SC_WF: 0,      FB_WF: 0,       AS_WF: 2535.5,  PE1_WF: 0,       PT_WF: 546,     PE2_WF: 0,       ER_WF: 0,      SC_PS: undefined,    SC_PF: undefined,    SC_PP: 0,     SC_AS: undefined,    SC_AF: undefined,    SC_AP: 0,   FB_PS: undefined,    FB_PF: undefined,    FB_PP: 0,     FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: '2025-02-08', AS_PF: '2025-05-30', AS_PP: 3.9,  AS_AS: '2025-02-06', AS_AF: undefined,    AS_AP: 15,   PE1_PS: undefined,    PE1_PF: undefined,    PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: '2025-07-02', PT_PF: '2025-07-15', PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: undefined,    PE2_PF: undefined,    PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: undefined,    ER_PF: undefined,    ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'HULL',    ZONE: 'COLUMN',         MOD: 'Column, NE',          LEVEL: 'NE, Middle Column',   NAME: 'Column Shell',           G_EREC: 'EC1K2', G_2PE: 'EC1K2', G_1PE: 'EC1M0', EREC: '',      PE2: '',      PE1: '',      PBLK: 'EC1M0', BLK: 'EC1M0', L: '7.3',  B: '13',   H: '10',   YARD: 'COSCO', SC_WF: 0,      FB_WF: 0,       AS_WF: 2535.5,  PE1_WF: 0,       PT_WF: 546,     PE2_WF: 0,       ER_WF: 2073.7, SC_PS: undefined,    SC_PF: undefined,    SC_PP: 0,     SC_AS: undefined,    SC_AF: undefined,    SC_AP: 0,   FB_PS: undefined,    FB_PF: undefined,    FB_PP: 0,     FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: '2025-02-10', AS_PF: '2025-06-02', AS_PP: 3.1,  AS_AS: '2025-02-07', AS_AF: undefined,    AS_AP: 15,   PE1_PS: undefined,    PE1_PF: undefined,    PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: '2025-07-02', PT_PF: '2025-07-15', PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: undefined,    PE2_PF: undefined,    PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: '2025-10-24', ER_PF: '2025-11-20', ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'HULL',    ZONE: 'COLUMN',         MOD: 'Column, NE',          LEVEL: 'NE, Middle Column',   NAME: 'Column Shell',           G_EREC: 'EC1K2', G_2PE: 'EC1K2', G_1PE: 'EC1N0', EREC: '',      PE2: '',      PE1: '',      PBLK: 'EC1N0', BLK: 'EC1N0', L: '13',   B: '7.3',  H: '10.2', YARD: 'COSCO', SC_WF: 0,      FB_WF: 0,       AS_WF: 2535.5,  PE1_WF: 0,       PT_WF: 546,     PE2_WF: 0,       ER_WF: 0,      SC_PS: undefined,    SC_PF: undefined,    SC_PP: 0,     SC_AS: undefined,    SC_AF: undefined,    SC_AP: 0,   FB_PS: undefined,    FB_PF: undefined,    FB_PP: 0,     FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: '2025-02-11', AS_PF: '2025-06-03', AS_PP: 2.4,  AS_AS: '2025-02-08', AS_AF: undefined,    AS_AP: 15,   PE1_PS: undefined,    PE1_PF: undefined,    PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: '2025-07-03', PT_PF: '2025-07-16', PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: undefined,    PE2_PF: undefined,    PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: undefined,    ER_PF: undefined,    ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'HULL',    ZONE: 'COLUMN',         MOD: 'Column, NE',          LEVEL: 'NE, Middle Column',   NAME: 'Column Shell',           G_EREC: 'EC1K2', G_2PE: 'EC1K2', G_1PE: 'EC1P0', EREC: '',      PE2: '',      PE1: '',      PBLK: 'EC1P0', BLK: 'EC1P0', L: '13',   B: '6.6',  H: '10',   YARD: 'COSCO', SC_WF: 0,      FB_WF: 0,       AS_WF: 2535.5,  PE1_WF: 0,       PT_WF: 546,     PE2_WF: 0,       ER_WF: 0,      SC_PS: undefined,    SC_PF: undefined,    SC_PP: 0,     SC_AS: undefined,    SC_AF: undefined,    SC_AP: 0,   FB_PS: undefined,    FB_PF: undefined,    FB_PP: 0,     FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: '2025-02-24', AS_PF: '2025-06-16', AS_PP: 0,    AS_AS: undefined,    AS_AF: undefined,    AS_AP: 0,    PE1_PS: undefined,    PE1_PF: undefined,    PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: '2025-07-16', PT_PF: '2025-07-29', PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: undefined,    PE2_PF: undefined,    PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: undefined,    ER_PF: undefined,    ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'HULL',    ZONE: 'COLUMN',         MOD: 'Column, NE',          LEVEL: 'NE, Middle Column',   NAME: 'Column Shell',           G_EREC: 'EC1K2', G_2PE: 'EC1K2', G_1PE: 'EC1Q0', EREC: '',      PE2: '',      PE1: '',      PBLK: 'EC1Q0', BLK: 'EC1Q0', L: '13.1', B: '7.3',  H: '10',   YARD: 'COSCO', SC_WF: 0,      FB_WF: 0,       AS_WF: 2535.5,  PE1_WF: 0,       PT_WF: 546,     PE2_WF: 0,       ER_WF: 0,      SC_PS: undefined,    SC_PF: undefined,    SC_PP: 0,     SC_AS: undefined,    SC_AF: undefined,    SC_AP: 0,   FB_PS: undefined,    FB_PF: undefined,    FB_PP: 0,     FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: '2025-02-25', AS_PF: '2025-06-17', AS_PP: 0,    AS_AS: undefined,    AS_AF: undefined,    AS_AP: 0,    PE1_PS: undefined,    PE1_PF: undefined,    PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: '2025-07-17', PT_PF: '2025-07-30', PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: undefined,    PE2_PF: undefined,    PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: undefined,    ER_PF: undefined,    ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'HULL',    ZONE: 'COLUMN',         MOD: 'Column, NE',          LEVEL: 'NE, Middle Column',   NAME: 'Column Shell',           G_EREC: 'EC1K2', G_2PE: 'EC1K2', G_1PE: 'EC1R0', EREC: '',      PE2: '',      PE1: '',      PBLK: 'EC1R0', BLK: 'EC1R0', L: '7.3',  B: '13.1', H: '10',   YARD: 'COSCO', SC_WF: 0,      FB_WF: 0,       AS_WF: 2535.5,  PE1_WF: 0,       PT_WF: 546,     PE2_WF: 0,       ER_WF: 0,      SC_PS: undefined,    SC_PF: undefined,    SC_PP: 0,     SC_AS: undefined,    SC_AF: undefined,    SC_AP: 0,   FB_PS: undefined,    FB_PF: undefined,    FB_PP: 0,     FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: '2025-03-04', AS_PF: '2025-06-24', AS_PP: 0,    AS_AS: undefined,    AS_AF: undefined,    AS_AP: 0,    PE1_PS: undefined,    PE1_PF: undefined,    PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: '2025-07-24', PT_PF: '2025-08-06', PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: undefined,    PE2_PF: undefined,    PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: undefined,    ER_PF: undefined,    ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'HULL',    ZONE: 'COLUMN',         MOD: 'Column, NE',          LEVEL: 'NE, Middle Column',   NAME: 'Column Shell',           G_EREC: 'EC1K2', G_2PE: 'EC1K2', G_1PE: 'EC1S0', EREC: '',      PE2: '',      PE1: '',      PBLK: 'EC1S0', BLK: 'EC1S0', L: '7.3',  B: '13',   H: '10',   YARD: 'COSCO', SC_WF: 0,      FB_WF: 0,       AS_WF: 2535.5,  PE1_WF: 0,       PT_WF: 546,     PE2_WF: 0,       ER_WF: 0,      SC_PS: undefined,    SC_PF: undefined,    SC_PP: 0,     SC_AS: undefined,    SC_AF: undefined,    SC_AP: 0,   FB_PS: undefined,    FB_PF: undefined,    FB_PP: 0,     FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: '2025-03-04', AS_PF: '2025-06-24', AS_PP: 0,    AS_AS: undefined,    AS_AF: undefined,    AS_AP: 0,    PE1_PS: undefined,    PE1_PF: undefined,    PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: '2025-07-24', PT_PF: '2025-08-06', PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: undefined,    PE2_PF: undefined,    PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: undefined,    ER_PF: undefined,    ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'HULL',    ZONE: 'COLUMN',         MOD: 'Column, NE',          LEVEL: 'NE, Middle Column',   NAME: '',                       G_EREC: 'EC1K2', G_2PE: 'EC1K2', G_1PE: 'EC330', EREC: '',      PE2: '',      PE1: '',      PBLK: '',      BLK: 'EC330', L: '1(n)', B: '1(n)', H: '1(n)', YARD: 'COSCO', SC_WF: 0,      FB_WF: 0,       AS_WF: 0,       PE1_WF: 0,       PT_WF: 0,       PE2_WF: 0,       ER_WF: 0,      SC_PS: undefined,    SC_PF: undefined,    SC_PP: 0,     SC_AS: undefined,    SC_AF: undefined,    SC_AP: 0,   FB_PS: undefined,    FB_PF: undefined,    FB_PP: 0,     FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: undefined,    AS_PF: undefined,    AS_PP: 0,    AS_AS: undefined,    AS_AF: undefined,    AS_AP: 0,    PE1_PS: undefined,    PE1_PF: undefined,    PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: undefined,    PT_PF: undefined,    PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: undefined,    PE2_PF: undefined,    PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: undefined,    ER_PF: undefined,    ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'HULL',    ZONE: 'COLUMN',         MOD: 'Column, NE',          LEVEL: 'NE, Middle Column',   NAME: '',                       G_EREC: 'EC3A0', G_2PE: 'EC3A0', G_1PE: 'EC3A0', EREC: 'EC3A0', PE2: '',      PE1: '',      PBLK: 'EC3A0', BLK: 'EC3A0', L: '7.6',  B: '21.4', H: '1',    YARD: 'HHI',   SC_WF: 8,      FB_WF: 8,       AS_WF: 0,       PE1_WF: 0,       PT_WF: 16,      PE2_WF: 0,       ER_WF: 2073.7, SC_PS: '2025-05-27', SC_PF: '2025-06-16', SC_PP: 0,     SC_AS: undefined,    SC_AF: undefined,    SC_AP: 0,   FB_PS: '2025-06-17', FB_PF: '2025-08-19', FB_PP: 0,     FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: undefined,    AS_PF: undefined,    AS_PP: 0,    AS_AS: undefined,    AS_AF: undefined,    AS_AP: 0,    PE1_PS: undefined,    PE1_PF: undefined,    PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: '2025-08-29', PT_PF: '2025-09-23', PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: undefined,    PE2_PF: undefined,    PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: '2025-10-10', ER_PF: '2025-11-06', ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'HULL',    ZONE: 'COLUMN',         MOD: 'Column, NE',          LEVEL: 'NE, Upper Column',    NAME: '',                       G_EREC: 'EC1G2', G_2PE: 'EC1G2', G_1PE: 'EC1G1', EREC: 'EC1G2', PE2: 'EC1G2', PE1: 'EC1G1', PBLK: 'EC1G1', BLK: 'EC1G0', L: '19.5', B: '19.5', H: '5.3',  YARD: 'HHI',   SC_WF: 594.48, FB_WF: 1188.96, AS_WF: 1783.44, PE1_WF: 2972.4,  PT_WF: 2711.73, PE2_WF: 5046,    ER_WF: 0,      SC_PS: '2025-02-20', SC_PF: '2025-03-11', SC_PP: 0,     SC_AS: undefined,    SC_AF: undefined,    SC_AP: 0,   FB_PS: '2025-03-12', FB_PF: '2025-04-10', FB_PP: 0,     FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: '2025-04-11', AS_PF: '2025-06-05', AS_PP: 0,    AS_AS: undefined,    AS_AF: undefined,    AS_AP: 0,    PE1_PS: '2025-06-09', PE1_PF: '2025-07-15', PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: '2025-07-16', PT_PF: '2025-08-20', PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: '2025-08-21', PE2_PF: '2025-11-13', PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: undefined,    ER_PF: undefined,    ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'HULL',    ZONE: 'COLUMN',         MOD: 'Column, NE',          LEVEL: 'NE, Upper Column',    NAME: '',                       G_EREC: 'EC1G2', G_2PE: 'EC1G2', G_1PE: 'EC1G1', EREC: '',      PE2: '',      PE1: '',      PBLK: '',      BLK: 'EC340', L: '1(n)', B: '1(n)', H: '1(n)', YARD: 'HHI',   SC_WF: 8,      FB_WF: 16,      AS_WF: 0,       PE1_WF: 32,      PT_WF: 0,       PE2_WF: 0,       ER_WF: 0,      SC_PS: '2025-03-24', SC_PF: '2025-04-10', SC_PP: 0,     SC_AS: undefined,    SC_AF: undefined,    SC_AP: 0,   FB_PS: '2025-04-11', FB_PF: '2025-06-05', FB_PP: 0,     FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: undefined,    AS_PF: undefined,    AS_PP: 0,    AS_AS: undefined,    AS_AF: undefined,    AS_AP: 0,    PE1_PS: '2025-06-09', PE1_PF: '2025-07-11', PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: undefined,    PT_PF: undefined,    PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: undefined,    PE2_PF: undefined,    PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: undefined,    ER_PF: undefined,    ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'HULL',    ZONE: 'COLUMN',         MOD: 'Column, NE',          LEVEL: 'NE, Upper Column',    NAME: '',                       G_EREC: 'EC1G2', G_2PE: 'EC1G2', G_1PE: 'EC1H1', EREC: '',      PE2: '',      PE1: 'EC1H1', PBLK: 'EC1H1', BLK: 'EC1H0', L: '20.7', B: '20.7', H: '5.9',  YARD: 'HHI',   SC_WF: 676.57, FB_WF: 1353.15, AS_WF: 2029.72, PE1_WF: 3382.87, PT_WF: 2711.73, PE2_WF: 3382.87, ER_WF: 0,      SC_PS: '2025-02-20', SC_PF: '2025-03-11', SC_PP: 0,     SC_AS: undefined,    SC_AF: undefined,    SC_AP: 0,   FB_PS: '2025-03-12', FB_PF: '2025-04-10', FB_PP: 0,     FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: '2025-04-11', AS_PF: '2025-06-05', AS_PP: 0,    AS_AS: undefined,    AS_AF: undefined,    AS_AP: 0,    PE1_PS: '2025-06-09', PE1_PF: '2025-07-15', PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: '2025-07-16', PT_PF: '2025-08-20', PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: '2025-08-21', PE2_PF: '2025-11-13', PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: undefined,    ER_PF: undefined,    ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'HULL',    ZONE: 'COLUMN',         MOD: 'Column, NE',          LEVEL: 'NE, Upper Column',    NAME: 'Hull E&I Building Wall', G_EREC: 'EC1G2', G_2PE: 'EC1G2', G_1PE: 'EC1H1', EREC: '',      PE2: '',      PE1: '',      PBLK: '',      BLK: 'ECW10', L: '13',   B: '4.1',  H: '5.2',  YARD: 'HHI',   SC_WF: 24,     FB_WF: 40,      AS_WF: 0,       PE1_WF: 70.77,   PT_WF: 0,       PE2_WF: 0,       ER_WF: 0,      SC_PS: '2025-03-24', SC_PF: '2025-04-10', SC_PP: 0,     SC_AS: undefined,    SC_AF: undefined,    SC_AP: 0,   FB_PS: '2025-04-11', FB_PF: '2025-06-05', FB_PP: 0,     FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: undefined,    AS_PF: undefined,    AS_PP: 0,    AS_AS: undefined,    AS_AF: undefined,    AS_AP: 0,    PE1_PS: '2025-06-09', PE1_PF: '2025-07-11', PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: undefined,    PT_PF: undefined,    PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: undefined,    PE2_PF: undefined,    PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: undefined,    ER_PF: undefined,    ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'HULL',    ZONE: 'COLUMN',         MOD: 'Column, NE',          LEVEL: 'NE, Upper Column',    NAME: '',                       G_EREC: 'EC1G2', G_2PE: 'EC1G2', G_1PE: 'EC1H1', EREC: '',      PE2: '',      PE1: '',      PBLK: '',      BLK: 'EC350', L: '1(n)', B: '1(n)', H: '1(n)', YARD: 'HHI',   SC_WF: 8,      FB_WF: 16,      AS_WF: 0,       PE1_WF: 32,      PT_WF: 0,       PE2_WF: 0,       ER_WF: 0,      SC_PS: '2025-03-24', SC_PF: '2025-04-10', SC_PP: 0,     SC_AS: undefined,    SC_AF: undefined,    SC_AP: 0,   FB_PS: '2025-04-11', FB_PF: '2025-06-05', FB_PP: 0,     FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: undefined,    AS_PF: undefined,    AS_PP: 0,    AS_AS: undefined,    AS_AF: undefined,    AS_AP: 0,    PE1_PS: '2025-06-09', PE1_PF: '2025-07-11', PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: undefined,    PT_PF: undefined,    PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: undefined,    PE2_PF: undefined,    PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: undefined,    ER_PF: undefined,    ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'HULL',    ZONE: 'COLUMN',         MOD: 'Column, NE',          LEVEL: 'NE, Upper Column',    NAME: 'Stair House',            G_EREC: 'EC1G2', G_2PE: 'EC1G2', G_1PE: 'EC1H1', EREC: '',      PE2: '',      PE1: '',      PBLK: '',      BLK: 'EC1J0', L: '5',    B: '2.6',  H: '2.5',  YARD: 'HHI',   SC_WF: 24,     FB_WF: 40,      AS_WF: 0,       PE1_WF: 70.77,   PT_WF: 0,       PE2_WF: 0,       ER_WF: 0,      SC_PS: '2025-03-24', SC_PF: '2025-04-10', SC_PP: 0,     SC_AS: undefined,    SC_AF: undefined,    SC_AP: 0,   FB_PS: '2025-04-11', FB_PF: '2025-06-05', FB_PP: 0,     FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: undefined,    AS_PF: undefined,    AS_PP: 0,    AS_AS: undefined,    AS_AF: undefined,    AS_AP: 0,    PE1_PS: '2025-06-09', PE1_PF: '2025-07-11', PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: undefined,    PT_PF: undefined,    PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: undefined,    PE2_PF: undefined,    PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: undefined,    ER_PF: undefined,    ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'HULL',    ZONE: 'COLUMN',         MOD: 'Column, NE',          LEVEL: 'NE, Upper Column',    NAME: 'UCF Brace',              G_EREC: 'EC1G2', G_2PE: 'EC1G2', G_1PE: 'EC1H1', EREC: '',      PE2: '',      PE1: '',      PBLK: '',      BLK: 'NF230', L: '4',    B: '2.3',  H: '3.5',  YARD: 'HHI',   SC_WF: 24,     FB_WF: 40,      AS_WF: 0,       PE1_WF: 70.77,   PT_WF: 0,       PE2_WF: 0,       ER_WF: 0,      SC_PS: '2025-03-31', SC_PF: '2025-04-17', SC_PP: 0,     SC_AS: undefined,    SC_AF: undefined,    SC_AP: 0,   FB_PS: '2025-04-18', FB_PF: '2025-06-05', FB_PP: 0,     FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: undefined,    AS_PF: undefined,    AS_PP: 0,    AS_AS: undefined,    AS_AF: undefined,    AS_AP: 0,    PE1_PS: '2025-06-09', PE1_PF: '2025-07-11', PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: undefined,    PT_PF: undefined,    PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: undefined,    PE2_PF: undefined,    PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: undefined,    ER_PF: undefined,    ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'HULL',    ZONE: 'COLUMN',         MOD: 'Column, NE',          LEVEL: 'NE, Upper Column',    NAME: 'UCF Brace',              G_EREC: 'EC1G2', G_2PE: 'EC1G2', G_1PE: 'EC1H1', EREC: '',      PE2: '',      PE1: '',      PBLK: '',      BLK: 'EF220', L: '2.3',  B: '4',    H: '3.5',  YARD: 'HHI',   SC_WF: 24,     FB_WF: 40,      AS_WF: 0,       PE1_WF: 70.77,   PT_WF: 0,       PE2_WF: 0,       ER_WF: 0,      SC_PS: '2025-03-24', SC_PF: '2025-04-10', SC_PP: 0,     SC_AS: undefined,    SC_AF: undefined,    SC_AP: 0,   FB_PS: '2025-04-11', FB_PF: '2025-06-05', FB_PP: 0,     FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: undefined,    AS_PF: undefined,    AS_PP: 0,    AS_AS: undefined,    AS_AF: undefined,    AS_AP: 0,    PE1_PS: '2025-06-09', PE1_PF: '2025-07-11', PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: undefined,    PT_PF: undefined,    PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: undefined,    PE2_PF: undefined,    PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: undefined,    ER_PF: undefined,    ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'HULL',    ZONE: 'COLUMN',         MOD: 'Column, NE',          LEVEL: 'NE, Upper Column',    NAME: 'Hull Access P/F',        G_EREC: 'EC1G2', G_2PE: 'EC1G2', G_1PE: 'EC1H1', EREC: '',      PE2: '',      PE1: '',      PBLK: '',      BLK: 'ECP20', L: '1.7',  B: '9',    H: '1.7',  YARD: 'HHI',   SC_WF: 8,      FB_WF: 8,       AS_WF: 0,       PE1_WF: 24,      PT_WF: 0,       PE2_WF: 0,       ER_WF: 0,      SC_PS: '2025-03-31', SC_PF: '2025-04-17', SC_PP: 0,     SC_AS: undefined,    SC_AF: undefined,    SC_AP: 0,   FB_PS: '2025-04-18', FB_PF: '2025-06-10', FB_PP: 0,     FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: undefined,    AS_PF: undefined,    AS_PP: 0,    AS_AS: undefined,    AS_AF: undefined,    AS_AP: 0,    PE1_PS: '2025-06-11', PE1_PF: '2025-07-08', PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: undefined,    PT_PF: undefined,    PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: undefined,    PE2_PF: undefined,    PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: undefined,    ER_PF: undefined,    ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'HULL',    ZONE: 'COLUMN',         MOD: 'Column, NE',          LEVEL: 'NE, Upper Column',    NAME: 'Hull Access P/F',        G_EREC: 'ECP30', G_2PE: 'ECP30', G_1PE: 'ECP30', EREC: 'ECP30', PE2: '',      PE1: '',      PBLK: 'ECP30', BLK: 'ECP30', L: '2.6',  B: '1.4',  H: '7.4',  YARD: 'HHI',   SC_WF: 8,      FB_WF: 8,       AS_WF: 0,       PE1_WF: 24,      PT_WF: 16,      PE2_WF: 0,       ER_WF: 2073.7, SC_PS: '2025-06-26', SC_PF: '2025-07-15', SC_PP: 0,     SC_AS: undefined,    SC_AF: undefined,    SC_AP: 0,   FB_PS: '2025-07-16', FB_PF: '2025-09-10', FB_PP: 0,     FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: undefined,    AS_PF: undefined,    AS_PP: 0,    AS_AS: undefined,    AS_AF: undefined,    AS_AP: 0,    PE1_PS: '2025-09-11', PE1_PF: '2025-09-26', PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: '2025-09-29', PT_PF: '2025-10-28', PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: undefined,    PE2_PF: undefined,    PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: '2025-11-14', ER_PF: '2025-12-04', ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'HULL',    ZONE: 'COLUMN',         MOD: 'Column, NE',          LEVEL: 'NE, Upper Column',    NAME: 'Hull Access P/F',        G_EREC: 'ECP10', G_2PE: 'ECP10', G_1PE: 'ECP10', EREC: 'ECP10', PE2: '',      PE1: '',      PBLK: 'ECP10', BLK: 'ECP10', L: '6.3',  B: '5.7',  H: '5.7',  YARD: 'HHI',   SC_WF: 8,      FB_WF: 16,      AS_WF: 0,       PE1_WF: 32,      PT_WF: 235.34,  PE2_WF: 0,       ER_WF: 2073.7, SC_PS: '2025-06-26', SC_PF: '2025-07-15', SC_PP: 0,     SC_AS: undefined,    SC_AF: undefined,    SC_AP: 0,   FB_PS: '2025-07-16', FB_PF: '2025-09-10', FB_PP: 0,     FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: undefined,    AS_PF: undefined,    AS_PP: 0,    AS_AS: undefined,    AS_AF: undefined,    AS_AP: 0,    PE1_PS: '2025-09-11', PE1_PF: '2025-09-26', PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: '2025-09-29', PT_PF: '2025-10-28', PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: undefined,    PE2_PF: undefined,    PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: '2025-11-14', ER_PF: '2025-12-04', ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'HULL',    ZONE: 'COLUMN',         MOD: 'Column, SE',          LEVEL: 'SE, Lower Column',    NAME: 'Column Shell',           G_EREC: 'EC212', G_2PE: 'EC212', G_1PE: 'EC211', EREC: 'EC212', PE2: 'EC212', PE1: 'EC211', PBLK: 'EC210', BLK: 'EC210', L: '13',   B: '7.3',  H: '10',   YARD: 'COSCO', SC_WF: 0,      FB_WF: 0,       AS_WF: 3562.5,  PE1_WF: 7240,    PT_WF: 767,     PE2_WF: 3988,    ER_WF: 0,      SC_PS: undefined,    SC_PF: undefined,    SC_PP: 0,     SC_AS: undefined,    SC_AF: undefined,    SC_AP: 0,   FB_PS: undefined,    FB_PF: undefined,    FB_PP: 0,     FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: '2024-12-07', AS_PF: '2025-04-07', AS_PP: 40.1, AS_AS: '2024-12-15', AS_AF: undefined,    AS_AP: 52.5, PE1_PS: '2025-05-22', PE1_PF: '2025-06-27', PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: '2025-05-07', PT_PF: '2025-05-20', PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: '2025-07-24', PE2_PF: '2025-09-23', PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: undefined,    ER_PF: undefined,    ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'HULL',    ZONE: 'COLUMN',         MOD: 'Column, SE',          LEVEL: 'SE, Lower Column',    NAME: 'Column Shell',           G_EREC: 'EC212', G_2PE: 'EC212', G_1PE: 'EC211', EREC: '',      PE2: '',      PE1: '',      PBLK: 'EC220', BLK: 'EC220', L: '7.3',  B: '14.1', H: '10',   YARD: 'COSCO', SC_WF: 0,      FB_WF: 0,       AS_WF: 3562.5,  PE1_WF: 0,       PT_WF: 767,     PE2_WF: 0,       ER_WF: 0,      SC_PS: undefined,    SC_PF: undefined,    SC_PP: 0,     SC_AS: undefined,    SC_AF: undefined,    SC_AP: 0,   FB_PS: undefined,    FB_PF: undefined,    FB_PP: 0,     FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: '2024-12-07', AS_PF: '2025-04-07', AS_PP: 40.1, AS_AS: '2024-12-16', AS_AF: undefined,    AS_AP: 52.5, PE1_PS: undefined,    PE1_PF: undefined,    PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: '2025-05-08', PT_PF: '2025-05-21', PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: undefined,    PE2_PF: undefined,    PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: undefined,    ER_PF: undefined,    ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'HULL',    ZONE: 'COLUMN',         MOD: 'Column, SE',          LEVEL: 'SE, Lower Column',    NAME: 'Column Shell',           G_EREC: 'EC212', G_2PE: 'EC212', G_1PE: 'EC211', EREC: '',      PE2: '',      PE1: '',      PBLK: 'EC230', BLK: 'EC230', L: '8.4',  B: '13.4', H: '10',   YARD: 'COSCO', SC_WF: 0,      FB_WF: 0,       AS_WF: 3562.5,  PE1_WF: 0,       PT_WF: 767,     PE2_WF: 0,       ER_WF: 0,      SC_PS: undefined,    SC_PF: undefined,    SC_PP: 0,     SC_AS: undefined,    SC_AF: undefined,    SC_AP: 0,   FB_PS: undefined,    FB_PF: undefined,    FB_PP: 0,     FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: '2024-12-09', AS_PF: '2025-04-08', AS_PP: 38.7, AS_AS: '2024-12-17', AS_AF: undefined,    AS_AP: 52.5, PE1_PS: undefined,    PE1_PF: undefined,    PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: '2025-05-09', PT_PF: '2025-05-22', PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: undefined,    PE2_PF: undefined,    PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: undefined,    ER_PF: undefined,    ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'HULL',    ZONE: 'COLUMN',         MOD: 'Column, SE',          LEVEL: 'SE, Lower Column',    NAME: 'Column Shell',           G_EREC: 'EC212', G_2PE: 'EC212', G_1PE: 'EC211', EREC: '',      PE2: '',      PE1: '',      PBLK: 'EC240', BLK: 'EC240', L: '13.5', B: '7.8',  H: '10',   YARD: 'COSCO', SC_WF: 0,      FB_WF: 0,       AS_WF: 3562.5,  PE1_WF: 0,       PT_WF: 767,     PE2_WF: 0,       ER_WF: 0,      SC_PS: undefined,    SC_PF: undefined,    SC_PP: 0,     SC_AS: undefined,    SC_AF: undefined,    SC_AP: 0,   FB_PS: undefined,    FB_PF: undefined,    FB_PP: 0,     FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: '2024-12-09', AS_PF: '2025-04-08', AS_PP: 38.7, AS_AS: '2024-12-19', AS_AF: undefined,    AS_AP: 52.5, PE1_PS: undefined,    PE1_PF: undefined,    PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: '2025-05-09', PT_PF: '2025-05-22', PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: undefined,    PE2_PF: undefined,    PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: undefined,    ER_PF: undefined,    ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'HULL',    ZONE: 'COLUMN',         MOD: 'Column, SE',          LEVEL: 'SE, Lower Column',    NAME: '',                       G_EREC: 'EC212', G_2PE: 'EC212', G_1PE: 'EC211', EREC: '',      PE2: '',      PE1: '',      PBLK: '',      BLK: 'EC410', L: '1(n)', B: '1(n)', H: '1(n)', YARD: 'COSCO', SC_WF: 0,      FB_WF: 0,       AS_WF: 0,       PE1_WF: 0,       PT_WF: 0,       PE2_WF: 0,       ER_WF: 0,      SC_PS: undefined,    SC_PF: undefined,    SC_PP: 0,     SC_AS: undefined,    SC_AF: undefined,    SC_AP: 0,   FB_PS: undefined,    FB_PF: undefined,    FB_PP: 0,     FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: undefined,    AS_PF: undefined,    AS_PP: 0,    AS_AS: undefined,    AS_AF: undefined,    AS_AP: 0,    PE1_PS: undefined,    PE1_PF: undefined,    PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: undefined,    PT_PF: undefined,    PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: undefined,    PE2_PF: undefined,    PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: undefined,    ER_PF: undefined,    ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'HULL',    ZONE: 'COLUMN',         MOD: 'Column, SE',          LEVEL: 'SE, Lower Column',    NAME: 'Column Shell',           G_EREC: 'EC212', G_2PE: 'EC212', G_1PE: 'EC251', EREC: '',      PE2: '',      PE1: 'EC251', PBLK: 'EC250', BLK: 'EC250', L: '13',   B: '7.3',  H: '9.9',  YARD: 'COSCO', SC_WF: 0,      FB_WF: 0,       AS_WF: 3562.5,  PE1_WF: 7240,    PT_WF: 767,     PE2_WF: 1663.13, ER_WF: 0,      SC_PS: undefined,    SC_PF: undefined,    SC_PP: 0,     SC_AS: undefined,    SC_AF: undefined,    SC_AP: 0,   FB_PS: undefined,    FB_PF: undefined,    FB_PP: 0,     FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: '2024-12-10', AS_PF: '2025-04-09', AS_PP: 37.3, AS_AS: '2024-12-19', AS_AF: undefined,    AS_AP: 52.5, PE1_PS: '2025-05-24', PE1_PF: '2025-06-27', PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: '2025-05-10', PT_PF: '2025-05-23', PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: '2025-07-24', PE2_PF: '2025-09-23', PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: undefined,    ER_PF: undefined,    ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'HULL',    ZONE: 'COLUMN',         MOD: 'Column, SE',          LEVEL: 'SE, Lower Column',    NAME: 'Column Shell',           G_EREC: 'EC212', G_2PE: 'EC212', G_1PE: 'EC251', EREC: '',      PE2: '',      PE1: '',      PBLK: 'EC260', BLK: 'EC260', L: '7.3',  B: '13',   H: '9.9',  YARD: 'COSCO', SC_WF: 0,      FB_WF: 0,       AS_WF: 3562.5,  PE1_WF: 0,       PT_WF: 767,     PE2_WF: 0,       ER_WF: 0,      SC_PS: undefined,    SC_PF: undefined,    SC_PP: 0,     SC_AS: undefined,    SC_AF: undefined,    SC_AP: 0,   FB_PS: undefined,    FB_PF: undefined,    FB_PP: 0,     FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: '2024-12-10', AS_PF: '2025-04-09', AS_PP: 37.3, AS_AS: '2024-12-20', AS_AF: undefined,    AS_AP: 52.5, PE1_PS: undefined,    PE1_PF: undefined,    PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: '2025-05-10', PT_PF: '2025-05-23', PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: undefined,    PE2_PF: undefined,    PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: undefined,    ER_PF: undefined,    ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'HULL',    ZONE: 'COLUMN',         MOD: 'Column, SE',          LEVEL: 'SE, Lower Column',    NAME: 'Column Shell',           G_EREC: 'EC212', G_2PE: 'EC212', G_1PE: 'EC251', EREC: '',      PE2: '',      PE1: '',      PBLK: 'EC270', BLK: 'EC270', L: '7.3',  B: '13.3', H: '9.9',  YARD: 'COSCO', SC_WF: 0,      FB_WF: 0,       AS_WF: 3562.5,  PE1_WF: 0,       PT_WF: 767,     PE2_WF: 0,       ER_WF: 0,      SC_PS: undefined,    SC_PF: undefined,    SC_PP: 0,     SC_AS: undefined,    SC_AF: undefined,    SC_AP: 0,   FB_PS: undefined,    FB_PF: undefined,    FB_PP: 0,     FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: '2024-12-11', AS_PF: '2025-04-10', AS_PP: 35.9, AS_AS: '2024-12-21', AS_AF: undefined,    AS_AP: 52.5, PE1_PS: undefined,    PE1_PF: undefined,    PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: '2025-05-12', PT_PF: '2025-05-24', PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: undefined,    PE2_PF: undefined,    PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: undefined,    ER_PF: undefined,    ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'HULL',    ZONE: 'COLUMN',         MOD: 'Column, SE',          LEVEL: 'SE, Lower Column',    NAME: 'Column Shell',           G_EREC: 'EC212', G_2PE: 'EC212', G_1PE: 'EC251', EREC: '',      PE2: '',      PE1: '',      PBLK: 'EC280', BLK: 'EC280', L: '13',   B: '7.3',  H: '9.9',  YARD: 'COSCO', SC_WF: 0,      FB_WF: 0,       AS_WF: 3562.5,  PE1_WF: 0,       PT_WF: 767,     PE2_WF: 0,       ER_WF: 0,      SC_PS: undefined,    SC_PF: undefined,    SC_PP: 0,     SC_AS: undefined,    SC_AF: undefined,    SC_AP: 0,   FB_PS: undefined,    FB_PF: undefined,    FB_PP: 0,     FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: '2024-12-11', AS_PF: '2025-04-10', AS_PP: 35.9, AS_AS: '2024-12-21', AS_AF: undefined,    AS_AP: 52.5, PE1_PS: undefined,    PE1_PF: undefined,    PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: '2025-05-12', PT_PF: '2025-05-24', PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: undefined,    PE2_PF: undefined,    PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: undefined,    ER_PF: undefined,    ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'HULL',    ZONE: 'COLUMN',         MOD: 'Column, SE',          LEVEL: 'SE, Lower Column',    NAME: '',                       G_EREC: 'EC212', G_2PE: 'EC212', G_1PE: 'EC251', EREC: '',      PE2: '',      PE1: '',      PBLK: '',      BLK: 'EC420', L: '1(n)', B: '1(n)', H: '1(n)', YARD: 'COSCO', SC_WF: 0,      FB_WF: 0,       AS_WF: 0,       PE1_WF: 0,       PT_WF: 0,       PE2_WF: 0,       ER_WF: 0,      SC_PS: undefined,    SC_PF: undefined,    SC_PP: 0,     SC_AS: undefined,    SC_AF: undefined,    SC_AP: 0,   FB_PS: undefined,    FB_PF: undefined,    FB_PP: 0,     FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: undefined,    AS_PF: undefined,    AS_PP: 0,    AS_AS: undefined,    AS_AF: undefined,    AS_AP: 0,    PE1_PS: undefined,    PE1_PF: undefined,    PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: undefined,    PT_PF: undefined,    PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: undefined,    PE2_PF: undefined,    PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: undefined,    ER_PF: undefined,    ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'HULL',    ZONE: 'COLUMN',         MOD: 'Column, SE',          LEVEL: 'SE, Middle Column',   NAME: 'Column Shell',           G_EREC: 'EC2K2', G_2PE: 'EC2K2', G_1PE: 'EC2K0', EREC: 'EC2K2', PE2: 'EC2K2', PE1: '',      PBLK: 'EC2K0', BLK: 'EC2K0', L: '13',   B: '7.3',  H: '10',   YARD: 'COSCO', SC_WF: 0,      FB_WF: 0,       AS_WF: 2535.5,  PE1_WF: 10305.5, PT_WF: 546,     PE2_WF: 0,       ER_WF: 0,      SC_PS: undefined,    SC_PF: undefined,    SC_PP: 0,     SC_AS: undefined,    SC_AF: undefined,    SC_AP: 0,   FB_PS: undefined,    FB_PF: undefined,    FB_PP: 0,     FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: '2025-02-11', AS_PF: '2025-06-03', AS_PP: 2.4,  AS_AS: '2025-02-08', AS_AF: undefined,    AS_AP: 15,   PE1_PS: '2025-07-18', PE1_PF: '2025-09-15', PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: '2025-07-03', PT_PF: '2025-07-16', PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: undefined,    PE2_PF: undefined,    PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: undefined,    ER_PF: undefined,    ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'HULL',    ZONE: 'COLUMN',         MOD: 'Column, SE',          LEVEL: 'SE, Middle Column',   NAME: 'Column Shell',           G_EREC: 'EC2K2', G_2PE: 'EC2K2', G_1PE: 'EC2L0', EREC: '',      PE2: '',      PE1: '',      PBLK: 'EC2L0', BLK: 'EC2L0', L: '7.3',  B: '13',   H: '10',   YARD: 'COSCO', SC_WF: 0,      FB_WF: 0,       AS_WF: 2535.5,  PE1_WF: 0,       PT_WF: 546,     PE2_WF: 0,       ER_WF: 0,      SC_PS: undefined,    SC_PF: undefined,    SC_PP: 0,     SC_AS: undefined,    SC_AF: undefined,    SC_AP: 0,   FB_PS: undefined,    FB_PF: undefined,    FB_PP: 0,     FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: '2025-02-12', AS_PF: '2025-06-04', AS_PP: 1.6,  AS_AS: '2025-02-08', AS_AF: undefined,    AS_AP: 15,   PE1_PS: undefined,    PE1_PF: undefined,    PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: '2025-07-04', PT_PF: '2025-07-17', PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: undefined,    PE2_PF: undefined,    PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: undefined,    ER_PF: undefined,    ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'HULL',    ZONE: 'COLUMN',         MOD: 'Column, SE',          LEVEL: 'SE, Middle Column',   NAME: 'Column Shell',           G_EREC: 'EC2K2', G_2PE: 'EC2K2', G_1PE: 'EC2M0', EREC: '',      PE2: '',      PE1: '',      PBLK: 'EC2M0', BLK: 'EC2M0', L: '7.3',  B: '13',   H: '10',   YARD: 'COSCO', SC_WF: 0,      FB_WF: 0,       AS_WF: 2535.5,  PE1_WF: 0,       PT_WF: 546,     PE2_WF: 0,       ER_WF: 2073.7, SC_PS: undefined,    SC_PF: undefined,    SC_PP: 0,     SC_AS: undefined,    SC_AF: undefined,    SC_AP: 0,   FB_PS: undefined,    FB_PF: undefined,    FB_PP: 0,     FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: '2025-02-13', AS_PF: '2025-06-05', AS_PP: 0.8,  AS_AS: '2025-02-09', AS_AF: undefined,    AS_AP: 15,   PE1_PS: undefined,    PE1_PF: undefined,    PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: '2025-07-05', PT_PF: '2025-07-18', PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: undefined,    PE2_PF: undefined,    PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: '2025-10-29', ER_PF: '2025-11-25', ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'HULL',    ZONE: 'COLUMN',         MOD: 'Column, SE',          LEVEL: 'SE, Middle Column',   NAME: 'Column Shell',           G_EREC: 'EC2K2', G_2PE: 'EC2K2', G_1PE: 'EC2N0', EREC: '',      PE2: '',      PE1: '',      PBLK: 'EC2N0', BLK: 'EC2N0', L: '13',   B: '7.3',  H: '10.2', YARD: 'COSCO', SC_WF: 0,      FB_WF: 0,       AS_WF: 2535.5,  PE1_WF: 0,       PT_WF: 546,     PE2_WF: 0,       ER_WF: 0,      SC_PS: undefined,    SC_PF: undefined,    SC_PP: 0,     SC_AS: undefined,    SC_AF: undefined,    SC_AP: 0,   FB_PS: undefined,    FB_PF: undefined,    FB_PP: 0,     FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: '2025-02-14', AS_PF: '2025-06-06', AS_PP: 0.1,  AS_AS: '2025-02-09', AS_AF: undefined,    AS_AP: 15,   PE1_PS: undefined,    PE1_PF: undefined,    PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: '2025-07-08', PT_PF: '2025-07-21', PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: undefined,    PE2_PF: undefined,    PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: undefined,    ER_PF: undefined,    ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'HULL',    ZONE: 'COLUMN',         MOD: 'Column, SE',          LEVEL: 'SE, Middle Column',   NAME: 'Column Shell',           G_EREC: 'EC2K2', G_2PE: 'EC2K2', G_1PE: 'EC2P0', EREC: '',      PE2: '',      PE1: '',      PBLK: 'EC2P0', BLK: 'EC2P0', L: '13',   B: '6.6',  H: '10',   YARD: 'COSCO', SC_WF: 0,      FB_WF: 0,       AS_WF: 2535.5,  PE1_WF: 0,       PT_WF: 546,     PE2_WF: 0,       ER_WF: 0,      SC_PS: undefined,    SC_PF: undefined,    SC_PP: 0,     SC_AS: undefined,    SC_AF: undefined,    SC_AP: 0,   FB_PS: undefined,    FB_PF: undefined,    FB_PP: 0,     FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: '2025-03-05', AS_PF: '2025-06-25', AS_PP: 0,    AS_AS: undefined,    AS_AF: undefined,    AS_AP: 0,    PE1_PS: undefined,    PE1_PF: undefined,    PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: '2025-07-25', PT_PF: '2025-08-07', PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: undefined,    PE2_PF: undefined,    PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: undefined,    ER_PF: undefined,    ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'HULL',    ZONE: 'COLUMN',         MOD: 'Column, SE',          LEVEL: 'SE, Middle Column',   NAME: 'Column Shell',           G_EREC: 'EC2K2', G_2PE: 'EC2K2', G_1PE: 'EC2Q0', EREC: '',      PE2: '',      PE1: '',      PBLK: 'EC2Q0', BLK: 'EC2Q0', L: '13.1', B: '7.3',  H: '10',   YARD: 'COSCO', SC_WF: 0,      FB_WF: 0,       AS_WF: 2535.5,  PE1_WF: 0,       PT_WF: 546,     PE2_WF: 0,       ER_WF: 0,      SC_PS: undefined,    SC_PF: undefined,    SC_PP: 0,     SC_AS: undefined,    SC_AF: undefined,    SC_AP: 0,   FB_PS: undefined,    FB_PF: undefined,    FB_PP: 0,     FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: '2025-03-05', AS_PF: '2025-06-25', AS_PP: 0,    AS_AS: undefined,    AS_AF: undefined,    AS_AP: 0,    PE1_PS: undefined,    PE1_PF: undefined,    PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: '2025-07-25', PT_PF: '2025-08-07', PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: undefined,    PE2_PF: undefined,    PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: undefined,    ER_PF: undefined,    ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'HULL',    ZONE: 'COLUMN',         MOD: 'Column, SE',          LEVEL: 'SE, Middle Column',   NAME: 'Column Shell',           G_EREC: 'EC2K2', G_2PE: 'EC2K2', G_1PE: 'EC2R0', EREC: '',      PE2: '',      PE1: '',      PBLK: 'EC2R0', BLK: 'EC2R0', L: '7.3',  B: '13.1', H: '10',   YARD: 'COSCO', SC_WF: 0,      FB_WF: 0,       AS_WF: 2535.5,  PE1_WF: 0,       PT_WF: 546,     PE2_WF: 0,       ER_WF: 0,      SC_PS: undefined,    SC_PF: undefined,    SC_PP: 0,     SC_AS: undefined,    SC_AF: undefined,    SC_AP: 0,   FB_PS: undefined,    FB_PF: undefined,    FB_PP: 0,     FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: '2025-03-06', AS_PF: '2025-06-26', AS_PP: 0,    AS_AS: undefined,    AS_AF: undefined,    AS_AP: 0,    PE1_PS: undefined,    PE1_PF: undefined,    PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: '2025-07-26', PT_PF: '2025-08-08', PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: undefined,    PE2_PF: undefined,    PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: undefined,    ER_PF: undefined,    ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'HULL',    ZONE: 'COLUMN',         MOD: 'Column, SE',          LEVEL: 'SE, Middle Column',   NAME: 'Column Shell',           G_EREC: 'EC2K2', G_2PE: 'EC2K2', G_1PE: 'EC2S0', EREC: '',      PE2: '',      PE1: '',      PBLK: 'EC2S0', BLK: 'EC2S0', L: '7.3',  B: '13',   H: '10',   YARD: 'COSCO', SC_WF: 0,      FB_WF: 0,       AS_WF: 2535.5,  PE1_WF: 0,       PT_WF: 546,     PE2_WF: 0,       ER_WF: 0,      SC_PS: undefined,    SC_PF: undefined,    SC_PP: 0,     SC_AS: undefined,    SC_AF: undefined,    SC_AP: 0,   FB_PS: undefined,    FB_PF: undefined,    FB_PP: 0,     FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: '2025-03-06', AS_PF: '2025-06-26', AS_PP: 0,    AS_AS: undefined,    AS_AF: undefined,    AS_AP: 0,    PE1_PS: undefined,    PE1_PF: undefined,    PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: '2025-07-26', PT_PF: '2025-08-08', PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: undefined,    PE2_PF: undefined,    PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: undefined,    ER_PF: undefined,    ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'HULL',    ZONE: 'COLUMN',         MOD: 'Column, SE',          LEVEL: 'SE, Middle Column',   NAME: '',                       G_EREC: 'EC2K2', G_2PE: 'EC2K2', G_1PE: 'EC430', EREC: '',      PE2: '',      PE1: '',      PBLK: '',      BLK: 'EC430', L: '1(n)', B: '1(n)', H: '1(n)', YARD: 'COSCO', SC_WF: 0,      FB_WF: 0,       AS_WF: 0,       PE1_WF: 0,       PT_WF: 0,       PE2_WF: 0,       ER_WF: 0,      SC_PS: undefined,    SC_PF: undefined,    SC_PP: 0,     SC_AS: undefined,    SC_AF: undefined,    SC_AP: 0,   FB_PS: undefined,    FB_PF: undefined,    FB_PP: 0,     FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: undefined,    AS_PF: undefined,    AS_PP: 0,    AS_AS: undefined,    AS_AF: undefined,    AS_AP: 0,    PE1_PS: undefined,    PE1_PF: undefined,    PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: undefined,    PT_PF: undefined,    PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: undefined,    PE2_PF: undefined,    PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: undefined,    ER_PF: undefined,    ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'HULL',    ZONE: 'COLUMN',         MOD: 'Column, SE',          LEVEL: 'SE, Middle Column',   NAME: '',                       G_EREC: 'EC4A0', G_2PE: 'EC4A0', G_1PE: 'EC4A0', EREC: 'EC4A0', PE2: '',      PE1: '',      PBLK: 'EC4A0', BLK: 'EC4A0', L: '1(n)', B: '1(n)', H: '1(n)', YARD: 'HHI',   SC_WF: 8,      FB_WF: 8,       AS_WF: 0,       PE1_WF: 0,       PT_WF: 16,      PE2_WF: 0,       ER_WF: 2073.7, SC_PS: '2025-05-30', SC_PF: '2025-06-19', SC_PP: 0,     SC_AS: undefined,    SC_AF: undefined,    SC_AP: 0,   FB_PS: '2025-06-20', FB_PF: '2025-08-22', FB_PP: 0,     FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: undefined,    AS_PF: undefined,    AS_PP: 0,    AS_AS: undefined,    AS_AF: undefined,    AS_AP: 0,    PE1_PS: undefined,    PE1_PF: undefined,    PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: '2025-09-03', PT_PF: '2025-09-26', PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: undefined,    PE2_PF: undefined,    PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: '2025-10-15', ER_PF: '2025-11-11', ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'HULL',    ZONE: 'COLUMN',         MOD: 'Column, SE',          LEVEL: 'SE, Upper Column',    NAME: '',                       G_EREC: 'EC2G2', G_2PE: 'EC2G2', G_1PE: 'EC2G1', EREC: 'EC2G2', PE2: 'EC2G2', PE1: 'EC2G1', PBLK: 'EC2G1', BLK: 'EC2G0', L: '19.5', B: '19.5', H: '5.3',  YARD: 'HHI',   SC_WF: 594.48, FB_WF: 1188.96, AS_WF: 1783.44, PE1_WF: 2972.4,  PT_WF: 2711.73, PE2_WF: 5046,    ER_WF: 0,      SC_PS: '2025-02-25', SC_PF: '2025-03-14', SC_PP: 0,     SC_AS: undefined,    SC_AF: undefined,    SC_AP: 0,   FB_PS: '2025-03-17', FB_PF: '2025-04-15', FB_PP: 0,     FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: '2025-04-16', AS_PF: '2025-06-11', AS_PP: 0,    AS_AS: undefined,    AS_AF: undefined,    AS_AP: 0,    PE1_PS: '2025-06-12', PE1_PF: '2025-07-18', PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: '2025-07-21', PT_PF: '2025-08-25', PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: '2025-08-26', PE2_PF: '2025-11-18', PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: undefined,    ER_PF: undefined,    ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'HULL',    ZONE: 'COLUMN',         MOD: 'Column, SE',          LEVEL: 'SE, Upper Column',    NAME: '',                       G_EREC: 'EC2G2', G_2PE: 'EC2G2', G_1PE: 'EC2G1', EREC: '',      PE2: '',      PE1: '',      PBLK: '',      BLK: 'EC440', L: '1(n)', B: '1(n)', H: '1(n)', YARD: 'HHI',   SC_WF: 8,      FB_WF: 16,      AS_WF: 0,       PE1_WF: 32,      PT_WF: 0,       PE2_WF: 0,       ER_WF: 0,      SC_PS: '2025-03-27', SC_PF: '2025-04-15', SC_PP: 0,     SC_AS: undefined,    SC_AF: undefined,    SC_AP: 0,   FB_PS: '2025-04-16', FB_PF: '2025-06-11', FB_PP: 0,     FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: undefined,    AS_PF: undefined,    AS_PP: 0,    AS_AS: undefined,    AS_AF: undefined,    AS_AP: 0,    PE1_PS: '2025-06-12', PE1_PF: '2025-07-11', PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: undefined,    PT_PF: undefined,    PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: undefined,    PE2_PF: undefined,    PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: undefined,    ER_PF: undefined,    ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'HULL',    ZONE: 'COLUMN',         MOD: 'Column, SE',          LEVEL: 'SE, Upper Column',    NAME: '',                       G_EREC: 'EC2G2', G_2PE: 'EC2G2', G_1PE: 'EC2H1', EREC: '',      PE2: '',      PE1: 'EC2H1', PBLK: 'EC2H1', BLK: 'EC2H0', L: '20.7', B: '20.7', H: '5.9',  YARD: 'HHI',   SC_WF: 676.57, FB_WF: 1353.15, AS_WF: 2029.72, PE1_WF: 3382.87, PT_WF: 2711.73, PE2_WF: 3382.87, ER_WF: 0,      SC_PS: '2025-02-25', SC_PF: '2025-03-14', SC_PP: 0,     SC_AS: undefined,    SC_AF: undefined,    SC_AP: 0,   FB_PS: '2025-03-17', FB_PF: '2025-04-15', FB_PP: 0,     FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: '2025-04-16', AS_PF: '2025-06-11', AS_PP: 0,    AS_AS: undefined,    AS_AF: undefined,    AS_AP: 0,    PE1_PS: '2025-06-12', PE1_PF: '2025-07-18', PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: '2025-07-21', PT_PF: '2025-08-25', PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: '2025-08-26', PE2_PF: '2025-11-18', PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: undefined,    ER_PF: undefined,    ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'HULL',    ZONE: 'COLUMN',         MOD: 'Column, SE',          LEVEL: 'SE, Upper Column',    NAME: '',                       G_EREC: 'EC2G2', G_2PE: 'EC2G2', G_1PE: 'EC2H1', EREC: '',      PE2: '',      PE1: '',      PBLK: '',      BLK: 'EC450', L: '1(n)', B: '1(n)', H: '1(n)', YARD: 'HHI',   SC_WF: 8,      FB_WF: 16,      AS_WF: 0,       PE1_WF: 32,      PT_WF: 0,       PE2_WF: 0,       ER_WF: 0,      SC_PS: '2025-03-27', SC_PF: '2025-04-15', SC_PP: 0,     SC_AS: undefined,    SC_AF: undefined,    SC_AP: 0,   FB_PS: '2025-04-16', FB_PF: '2025-06-11', FB_PP: 0,     FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: undefined,    AS_PF: undefined,    AS_PP: 0,    AS_AS: undefined,    AS_AF: undefined,    AS_AP: 0,    PE1_PS: '2025-06-12', PE1_PF: '2025-07-16', PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: undefined,    PT_PF: undefined,    PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: undefined,    PE2_PF: undefined,    PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: undefined,    ER_PF: undefined,    ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'HULL',    ZONE: 'COLUMN',         MOD: 'Column, SE',          LEVEL: 'SE, Upper Column',    NAME: 'Stair House',            G_EREC: 'EC2G2', G_2PE: 'EC2G2', G_1PE: 'EC2H1', EREC: '',      PE2: '',      PE1: '',      PBLK: '',      BLK: 'EC2J0', L: '5',    B: '2.6',  H: '2.5',  YARD: 'HHI',   SC_WF: 24,     FB_WF: 40,      AS_WF: 0,       PE1_WF: 70.77,   PT_WF: 0,       PE2_WF: 0,       ER_WF: 0,      SC_PS: '2025-03-27', SC_PF: '2025-04-15', SC_PP: 0,     SC_AS: undefined,    SC_AF: undefined,    SC_AP: 0,   FB_PS: '2025-04-16', FB_PF: '2025-06-11', FB_PP: 0,     FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: undefined,    AS_PF: undefined,    AS_PP: 0,    AS_AS: undefined,    AS_AF: undefined,    AS_AP: 0,    PE1_PS: '2025-06-12', PE1_PF: '2025-07-16', PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: undefined,    PT_PF: undefined,    PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: undefined,    PE2_PF: undefined,    PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: undefined,    ER_PF: undefined,    ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'HULL',    ZONE: 'COLUMN',         MOD: 'Column, SE',          LEVEL: 'SE, Upper Column',    NAME: 'UCF Brace',              G_EREC: 'EC2G2', G_2PE: 'EC2G2', G_1PE: 'EC2H1', EREC: '',      PE2: '',      PE1: '',      PBLK: '',      BLK: 'EF230', L: '2.3',  B: '4',    H: '3.5',  YARD: 'HHI',   SC_WF: 24,     FB_WF: 40,      AS_WF: 0,       PE1_WF: 70.77,   PT_WF: 0,       PE2_WF: 0,       ER_WF: 0,      SC_PS: '2025-03-27', SC_PF: '2025-04-15', SC_PP: 0,     SC_AS: undefined,    SC_AF: undefined,    SC_AP: 0,   FB_PS: '2025-04-16', FB_PF: '2025-06-11', FB_PP: 0,     FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: undefined,    AS_PF: undefined,    AS_PP: 0,    AS_AS: undefined,    AS_AF: undefined,    AS_AP: 0,    PE1_PS: '2025-06-12', PE1_PF: '2025-07-16', PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: undefined,    PT_PF: undefined,    PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: undefined,    PE2_PF: undefined,    PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: undefined,    ER_PF: undefined,    ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'HULL',    ZONE: 'COLUMN',         MOD: 'Column, SE',          LEVEL: 'SE, Upper Column',    NAME: 'UCF Brace',              G_EREC: 'EC2G2', G_2PE: 'EC2G2', G_1PE: 'EC2H1', EREC: '',      PE2: '',      PE1: '',      PBLK: '',      BLK: 'SF230', L: '4',    B: '2.3',  H: '3.5',  YARD: 'HHI',   SC_WF: 24,     FB_WF: 40,      AS_WF: 0,       PE1_WF: 70.77,   PT_WF: 0,       PE2_WF: 0,       ER_WF: 0,      SC_PS: '2025-04-03', SC_PF: '2025-04-22', SC_PP: 0,     SC_AS: undefined,    SC_AF: undefined,    SC_AP: 0,   FB_PS: '2025-04-23', FB_PF: '2025-06-11', FB_PP: 0,     FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: undefined,    AS_PF: undefined,    AS_PP: 0,    AS_AS: undefined,    AS_AF: undefined,    AS_AP: 0,    PE1_PS: '2025-06-12', PE1_PF: '2025-07-16', PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: undefined,    PT_PF: undefined,    PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: undefined,    PE2_PF: undefined,    PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: undefined,    ER_PF: undefined,    ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'HULL',    ZONE: 'COLUMN',         MOD: 'Column, SE',          LEVEL: 'South-East',          NAME: 'Hull Access P/F',        G_EREC: 'ECP40', G_2PE: 'ECP40', G_1PE: 'ECP40', EREC: 'ECP40', PE2: '',      PE1: '',      PBLK: '',      BLK: 'ECP40', L: '6.3',  B: '5.9',  H: '4.8',  YARD: 'HHI',   SC_WF: 8,      FB_WF: 16,      AS_WF: 0,       PE1_WF: 32,      PT_WF: 0,       PE2_WF: 0,       ER_WF: 2073.7, SC_PS: '2025-07-01', SC_PF: '2025-07-18', SC_PP: 0,     SC_AS: undefined,    SC_AF: undefined,    SC_AP: 0,   FB_PS: '2025-07-21', FB_PF: '2025-09-15', FB_PP: 0,     FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: undefined,    AS_PF: undefined,    AS_PP: 0,    AS_AS: undefined,    AS_AF: undefined,    AS_AP: 0,    PE1_PS: '2025-09-16', PE1_PF: '2025-09-29', PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: undefined,    PT_PF: undefined,    PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: undefined,    PE2_PF: undefined,    PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: '2025-11-19', ER_PF: '2025-12-09', ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'HULL',    ZONE: 'COLUMN',         MOD: 'Column, SE',          LEVEL: 'South-East',          NAME: 'Hull Access P/F',        G_EREC: 'ECP50', G_2PE: 'ECP50', G_1PE: 'ECP50', EREC: 'ECP50', PE2: '',      PE1: '',      PBLK: '',      BLK: 'ECP50', L: '6',    B: '5.7',  H: '6.6',  YARD: 'HHI',   SC_WF: 8,      FB_WF: 16,      AS_WF: 0,       PE1_WF: 32,      PT_WF: 0,       PE2_WF: 0,       ER_WF: 2073.7, SC_PS: '2025-07-01', SC_PF: '2025-07-18', SC_PP: 0,     SC_AS: undefined,    SC_AF: undefined,    SC_AP: 0,   FB_PS: '2025-07-21', FB_PF: '2025-09-15', FB_PP: 0,     FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: undefined,    AS_PF: undefined,    AS_PP: 0,    AS_AS: undefined,    AS_AF: undefined,    AS_AP: 0,    PE1_PS: '2025-09-16', PE1_PF: '2025-09-29', PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: undefined,    PT_PF: undefined,    PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: undefined,    PE2_PF: undefined,    PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: '2025-11-19', ER_PF: '2025-12-09', ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'HULL',    ZONE: 'COLUMN',         MOD: 'Column, SE',          LEVEL: 'South-East',          NAME: 'Hull Access P/F',        G_EREC: 'ECP60', G_2PE: 'ECP60', G_1PE: 'ECP60', EREC: 'ECP60', PE2: '',      PE1: '',      PBLK: '',      BLK: 'ECP60', L: '4.6',  B: '12.9', H: '4.8',  YARD: 'HHI',   SC_WF: 8,      FB_WF: 16,      AS_WF: 0,       PE1_WF: 32,      PT_WF: 0,       PE2_WF: 0,       ER_WF: 2073.7, SC_PS: '2025-07-01', SC_PF: '2025-07-18', SC_PP: 0,     SC_AS: undefined,    SC_AF: undefined,    SC_AP: 0,   FB_PS: '2025-07-21', FB_PF: '2025-09-15', FB_PP: 0,     FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: undefined,    AS_PF: undefined,    AS_PP: 0,    AS_AS: undefined,    AS_AF: undefined,    AS_AP: 0,    PE1_PS: '2025-09-16', PE1_PF: '2025-09-29', PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: undefined,    PT_PF: undefined,    PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: undefined,    PE2_PF: undefined,    PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: '2025-11-19', ER_PF: '2025-12-09', ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'HULL',    ZONE: 'COLUMN',         MOD: 'Column, SE',          LEVEL: 'South-East',          NAME: 'Hull Access P/F',        G_EREC: 'ECP70', G_2PE: 'ECP70', G_1PE: 'ECP70', EREC: 'ECP70', PE2: '',      PE1: '',      PBLK: '',      BLK: 'ECP70', L: '2.3',  B: '1.5',  H: '4.7',  YARD: 'HHI',   SC_WF: 8,      FB_WF: 8,       AS_WF: 0,       PE1_WF: 24,      PT_WF: 0,       PE2_WF: 0,       ER_WF: 2073.7, SC_PS: '2025-07-01', SC_PF: '2025-07-18', SC_PP: 0,     SC_AS: undefined,    SC_AF: undefined,    SC_AP: 0,   FB_PS: '2025-07-21', FB_PF: '2025-09-15', FB_PP: 0,     FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: undefined,    AS_PF: undefined,    AS_PP: 0,    AS_AS: undefined,    AS_AF: undefined,    AS_AP: 0,    PE1_PS: '2025-09-16', PE1_PF: '2025-09-29', PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: undefined,    PT_PF: undefined,    PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: undefined,    PE2_PF: undefined,    PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: '2025-11-19', ER_PF: '2025-12-09', ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'HULL',    ZONE: 'UCF',            MOD: 'East',                LEVEL: 'East',                NAME: 'UCF',                    G_EREC: 'EF212', G_2PE: 'EF212', G_1PE: 'EF210', EREC: 'EF212', PE2: 'EF212', PE1: '',      PBLK: 'EF210', BLK: 'EF210', L: '2.8',  B: '27.6', H: '5.7',  YARD: 'HHI',   SC_WF: 283.09, FB_WF: 566.17,  AS_WF: 0,       PE1_WF: 0,       PT_WF: 720.28,  PE2_WF: 0,       ER_WF: 2073.7, SC_PS: '2025-05-28', SC_PF: '2025-06-17', SC_PP: 0,     SC_AS: undefined,    SC_AF: undefined,    SC_AP: 0,   FB_PS: '2025-06-18', FB_PF: '2025-09-24', FB_PP: 0,     FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: undefined,    AS_PF: undefined,    AS_PP: 0,    AS_AS: undefined,    AS_AF: undefined,    AS_AP: 0,    PE1_PS: undefined,    PE1_PF: undefined,    PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: '2025-09-25', PT_PF: '2025-10-24', PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: undefined,    PE2_PF: undefined,    PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: '2025-11-26', ER_PF: '2025-12-23', ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'HULL',    ZONE: 'UCF',            MOD: 'East',                LEVEL: 'East',                NAME: 'UCF',                    G_EREC: 'EF212', G_2PE: 'EF212', G_1PE: 'EF21A', EREC: '',      PE2: '',      PE1: '',      PBLK: '',      BLK: 'EF2A0', L: '2.8',  B: '26.7', H: '5.7',  YARD: 'HHI',   SC_WF: 0,      FB_WF: 0,       AS_WF: 0,       PE1_WF: 0,       PT_WF: 0,       PE2_WF: 0,       ER_WF: 0,      SC_PS: undefined,    SC_PF: undefined,    SC_PP: 0,     SC_AS: undefined,    SC_AF: undefined,    SC_AP: 0,   FB_PS: undefined,    FB_PF: undefined,    FB_PP: 0,     FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: undefined,    AS_PF: undefined,    AS_PP: 0,    AS_AS: undefined,    AS_AF: undefined,    AS_AP: 0,    PE1_PS: undefined,    PE1_PF: undefined,    PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: undefined,    PT_PF: undefined,    PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: undefined,    PE2_PF: undefined,    PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: undefined,    ER_PF: undefined,    ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'HULL',    ZONE: 'UCF',            MOD: 'East',                LEVEL: 'East',                NAME: 'Temporary Support',      G_EREC: 'EF2B0', G_2PE: 'EF2B0', G_1PE: 'EF2B0', EREC: 'EF2B0', PE2: '',      PE1: '',      PBLK: '',      BLK: 'EF2B0', L: '1.5',  B: '14.1', H: '16.7', YARD: 'HHI',   SC_WF: 0,      FB_WF: 0,       AS_WF: 0,       PE1_WF: 0,       PT_WF: 0,       PE2_WF: 0,       ER_WF: 0,      SC_PS: undefined,    SC_PF: undefined,    SC_PP: 0,     SC_AS: undefined,    SC_AF: undefined,    SC_AP: 0,   FB_PS: undefined,    FB_PF: undefined,    FB_PP: 0,     FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: undefined,    AS_PF: undefined,    AS_PP: 0,    AS_AS: undefined,    AS_AF: undefined,    AS_AP: 0,    PE1_PS: undefined,    PE1_PF: undefined,    PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: undefined,    PT_PF: undefined,    PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: undefined,    PE2_PF: undefined,    PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: undefined,    ER_PF: undefined,    ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'HULL',    ZONE: 'UCF',            MOD: 'East',                LEVEL: 'East',                NAME: 'Temporary Support',      G_EREC: 'EF2C0', G_2PE: 'EF2C0', G_1PE: 'EF2C0', EREC: 'EF2C0', PE2: '',      PE1: '',      PBLK: '',      BLK: 'EF2C0', L: '1.5',  B: '14.1', H: '16.7', YARD: 'HHI',   SC_WF: 0,      FB_WF: 0,       AS_WF: 0,       PE1_WF: 0,       PT_WF: 0,       PE2_WF: 0,       ER_WF: 0,      SC_PS: undefined,    SC_PF: undefined,    SC_PP: 0,     SC_AS: undefined,    SC_AF: undefined,    SC_AP: 0,   FB_PS: undefined,    FB_PF: undefined,    FB_PP: 0,     FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: undefined,    AS_PF: undefined,    AS_PP: 0,    AS_AS: undefined,    AS_AF: undefined,    AS_AP: 0,    PE1_PS: undefined,    PE1_PF: undefined,    PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: undefined,    PT_PF: undefined,    PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: undefined,    PE2_PF: undefined,    PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: undefined,    ER_PF: undefined,    ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'HULL',    ZONE: 'UCF',            MOD: 'West',                LEVEL: 'West',                NAME: 'UCF',                    G_EREC: 'WF212', G_2PE: 'WF212', G_1PE: 'WF210', EREC: 'WF212', PE2: 'WF212', PE1: '',      PBLK: 'WF210', BLK: 'WF210', L: '2.8',  B: '23.6', H: '5.7',  YARD: 'HHI',   SC_WF: 283.09, FB_WF: 566.17,  AS_WF: 0,       PE1_WF: 0,       PT_WF: 670.36,  PE2_WF: 0,       ER_WF: 2073.7, SC_PS: '2025-06-10', SC_PF: '2025-06-27', SC_PP: 0,     SC_AS: undefined,    SC_AF: undefined,    SC_AP: 0,   FB_PS: '2025-06-30', FB_PF: '2025-10-13', FB_PP: 0,     FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: undefined,    AS_PF: undefined,    AS_PP: 0,    AS_AS: undefined,    AS_AF: undefined,    AS_AP: 0,    PE1_PS: undefined,    PE1_PF: undefined,    PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: '2025-10-14', PT_PF: '2025-11-05', PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: undefined,    PE2_PF: undefined,    PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: '2025-12-08', ER_PF: '2026-01-07', ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'HULL',    ZONE: 'UCF',            MOD: 'West',                LEVEL: 'West',                NAME: 'UCF',                    G_EREC: 'WF212', G_2PE: 'WF212', G_1PE: 'WF2A0', EREC: '',      PE2: '',      PE1: '',      PBLK: '',      BLK: 'WF2A0', L: '2.8',  B: '26.7', H: '5.7',  YARD: 'HHI',   SC_WF: 0,      FB_WF: 0,       AS_WF: 0,       PE1_WF: 0,       PT_WF: 0,       PE2_WF: 0,       ER_WF: 0,      SC_PS: undefined,    SC_PF: undefined,    SC_PP: 0,     SC_AS: undefined,    SC_AF: undefined,    SC_AP: 0,   FB_PS: undefined,    FB_PF: undefined,    FB_PP: 0,     FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: undefined,    AS_PF: undefined,    AS_PP: 0,    AS_AS: undefined,    AS_AF: undefined,    AS_AP: 0,    PE1_PS: undefined,    PE1_PF: undefined,    PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: undefined,    PT_PF: undefined,    PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: undefined,    PE2_PF: undefined,    PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: undefined,    ER_PF: undefined,    ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'HULL',    ZONE: 'UCF',            MOD: 'West',                LEVEL: 'West',                NAME: 'Temporary Support',      G_EREC: 'WF2B0', G_2PE: 'WF2B0', G_1PE: 'WF2B0', EREC: 'WF2B0', PE2: '',      PE1: '',      PBLK: '',      BLK: 'WF2B0', L: '1.5',  B: '14.1', H: '16.7', YARD: 'HHI',   SC_WF: 0,      FB_WF: 0,       AS_WF: 0,       PE1_WF: 0,       PT_WF: 0,       PE2_WF: 0,       ER_WF: 0,      SC_PS: undefined,    SC_PF: undefined,    SC_PP: 0,     SC_AS: undefined,    SC_AF: undefined,    SC_AP: 0,   FB_PS: undefined,    FB_PF: undefined,    FB_PP: 0,     FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: undefined,    AS_PF: undefined,    AS_PP: 0,    AS_AS: undefined,    AS_AF: undefined,    AS_AP: 0,    PE1_PS: undefined,    PE1_PF: undefined,    PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: undefined,    PT_PF: undefined,    PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: undefined,    PE2_PF: undefined,    PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: undefined,    ER_PF: undefined,    ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'HULL',    ZONE: 'UCF',            MOD: 'West',                LEVEL: 'West',                NAME: 'Temporary Support',      G_EREC: 'WF2C0', G_2PE: 'WF2C0', G_1PE: 'WF2C0', EREC: 'WF2C0', PE2: '',      PE1: '',      PBLK: '',      BLK: 'WF2C0', L: '1.5',  B: '14.1', H: '16.7', YARD: 'HHI',   SC_WF: 0,      FB_WF: 0,       AS_WF: 0,       PE1_WF: 0,       PT_WF: 0,       PE2_WF: 0,       ER_WF: 0,      SC_PS: undefined,    SC_PF: undefined,    SC_PP: 0,     SC_AS: undefined,    SC_AF: undefined,    SC_AP: 0,   FB_PS: undefined,    FB_PF: undefined,    FB_PP: 0,     FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: undefined,    AS_PF: undefined,    AS_PP: 0,    AS_AS: undefined,    AS_AF: undefined,    AS_AP: 0,    PE1_PS: undefined,    PE1_PF: undefined,    PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: undefined,    PT_PF: undefined,    PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: undefined,    PE2_PF: undefined,    PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: undefined,    ER_PF: undefined,    ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'HULL',    ZONE: 'UCF',            MOD: 'South',               LEVEL: 'South',               NAME: 'UCF',                    G_EREC: 'SF212', G_2PE: 'SF212', G_1PE: 'SF210', EREC: 'SF212', PE2: 'SF212', PE1: '',      PBLK: 'SF210', BLK: 'SF210', L: '27.6', B: '2.8',  H: '3.4',  YARD: 'HHI',   SC_WF: 283.09, FB_WF: 566.17,  AS_WF: 0,       PE1_WF: 0,       PT_WF: 720.28,  PE2_WF: 0,       ER_WF: 2073.7, SC_PS: '2025-06-13', SC_PF: '2025-07-02', SC_PP: 0,     SC_AS: undefined,    SC_AF: undefined,    SC_AP: 0,   FB_PS: '2025-07-03', FB_PF: '2025-10-16', FB_PP: 0,     FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: undefined,    AS_PF: undefined,    AS_PP: 0,    AS_AS: undefined,    AS_AF: undefined,    AS_AP: 0,    PE1_PS: undefined,    PE1_PF: undefined,    PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: '2025-10-17', PT_PF: '2025-11-10', PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: undefined,    PE2_PF: undefined,    PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: '2025-12-11', ER_PF: '2026-01-12', ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'HULL',    ZONE: 'UCF',            MOD: 'South',               LEVEL: 'South',               NAME: 'UCF',                    G_EREC: 'SF212', G_2PE: 'SF212', G_1PE: 'SF2A0', EREC: '',      PE2: '',      PE1: '',      PBLK: '',      BLK: 'SF2A0', L: '27.6', B: '2.8',  H: '3.4',  YARD: 'HHI',   SC_WF: 0,      FB_WF: 0,       AS_WF: 0,       PE1_WF: 0,       PT_WF: 0,       PE2_WF: 0,       ER_WF: 0,      SC_PS: undefined,    SC_PF: undefined,    SC_PP: 0,     SC_AS: undefined,    SC_AF: undefined,    SC_AP: 0,   FB_PS: undefined,    FB_PF: undefined,    FB_PP: 0,     FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: undefined,    AS_PF: undefined,    AS_PP: 0,    AS_AS: undefined,    AS_AF: undefined,    AS_AP: 0,    PE1_PS: undefined,    PE1_PF: undefined,    PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: undefined,    PT_PF: undefined,    PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: undefined,    PE2_PF: undefined,    PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: undefined,    ER_PF: undefined,    ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'HULL',    ZONE: 'UCF',            MOD: 'North',               LEVEL: 'North',               NAME: 'UCF',                    G_EREC: 'NF212', G_2PE: 'NF212', G_1PE: 'NF210', EREC: 'NF212', PE2: 'NF212', PE1: '',      PBLK: 'NF210', BLK: 'NF210', L: '25.6', B: '2.8',  H: '3.4',  YARD: 'HHI',   SC_WF: 283.09, FB_WF: 566.17,  AS_WF: 0,       PE1_WF: 0,       PT_WF: 720.28,  PE2_WF: 1415.43, ER_WF: 2073.7, SC_PS: '2025-06-04', SC_PF: '2025-06-24', SC_PP: 0,     SC_AS: undefined,    SC_AF: undefined,    SC_AP: 0,   FB_PS: '2025-06-25', FB_PF: '2025-10-01', FB_PP: 0,     FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: undefined,    AS_PF: undefined,    AS_PP: 0,    AS_AS: undefined,    AS_AF: undefined,    AS_AP: 0,    PE1_PS: undefined,    PE1_PF: undefined,    PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: '2025-10-02', PT_PF: '2025-10-31', PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: '2025-11-03', PE2_PF: '2025-11-28', PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: '2025-12-03', ER_PF: '2025-12-31', ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'HULL',    ZONE: 'UCF',            MOD: 'North',               LEVEL: 'North',               NAME: 'UCF',                    G_EREC: 'NF212', G_2PE: 'NF212', G_1PE: 'NF2A0', EREC: '',      PE2: '',      PE1: '',      PBLK: 'NF2A0', BLK: 'NF2A0', L: '25.6', B: '2.8',  H: '3.4',  YARD: 'HHI',   SC_WF: 0,      FB_WF: 0,       AS_WF: 0,       PE1_WF: 0,       PT_WF: 0,       PE2_WF: 0,       ER_WF: 0,      SC_PS: undefined,    SC_PF: undefined,    SC_PP: 0,     SC_AS: undefined,    SC_AF: undefined,    SC_AP: 0,   FB_PS: undefined,    FB_PF: undefined,    FB_PP: 0,     FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: undefined,    AS_PF: undefined,    AS_PP: 0,    AS_AS: undefined,    AS_AF: undefined,    AS_AP: 0,    PE1_PS: undefined,    PE1_PF: undefined,    PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: undefined,    PT_PF: undefined,    PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: undefined,    PE2_PF: undefined,    PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: undefined,    ER_PF: undefined,    ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'HULL',    ZONE: 'UCF',            MOD: 'North',               LEVEL: 'North',               NAME: 'UCF P/F',                G_EREC: 'NF212', G_2PE: 'NF212', G_1PE: 'NFP10', EREC: '',      PE2: '',      PE1: '',      PBLK: 'NFP10', BLK: 'NFP10', L: '17',   B: '6',    H: '3.3',  YARD: 'HHI',   SC_WF: 28.31,  FB_WF: 56.62,   AS_WF: 0,       PE1_WF: 0,       PT_WF: 568.73,  PE2_WF: 141.54,  ER_WF: 0,      SC_PS: '2025-07-08', SC_PF: '2025-07-25', SC_PP: 0,     SC_AS: undefined,    SC_AF: undefined,    SC_AP: 0,   FB_PS: '2025-08-08', FB_PF: '2025-09-29', FB_PP: 0,     FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: undefined,    AS_PF: undefined,    AS_PP: 0,    AS_AS: undefined,    AS_AF: undefined,    AS_AP: 0,    PE1_PS: undefined,    PE1_PF: undefined,    PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: '2025-10-23', PT_PF: '2025-11-14', PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: '2025-11-17', PE2_PF: '2025-11-28', PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: undefined,    ER_PF: undefined,    ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'HULL',    ZONE: 'UCF',            MOD: 'Center',              LEVEL: 'Center',              NAME: 'UCF Center',             G_EREC: 'CF112', G_2PE: 'CF112', G_1PE: 'CF111', EREC: 'CF112', PE2: 'CF112', PE1: 'CF111', PBLK: 'CF110', BLK: 'CF110', L: '1.6',  B: '27.2', H: '1.6',  YARD: 'HHI',   SC_WF: 141.54, FB_WF: 283.09,  AS_WF: 0,       PE1_WF: 707.71,  PT_WF: 385.1,   PE2_WF: 707.71,  ER_WF: 2073.7, SC_PS: '2025-05-27', SC_PF: '2025-06-16', SC_PP: 0,     SC_AS: undefined,    SC_AF: undefined,    SC_AP: 0,   FB_PS: '2025-06-17', FB_PF: '2025-08-26', FB_PP: 0,     FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: undefined,    AS_PF: undefined,    AS_PP: 0,    AS_AS: undefined,    AS_AF: undefined,    AS_AP: 0,    PE1_PS: '2025-08-27', PE1_PF: '2025-09-11', PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: '2025-09-12', PT_PF: '2025-10-13', PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: '2025-10-14', PE2_PF: '2025-12-22', PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: '2025-12-26', ER_PF: '2026-02-25', ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'HULL',    ZONE: 'UCF',            MOD: 'Center',              LEVEL: 'Center',              NAME: 'UCF Center',             G_EREC: 'CF112', G_2PE: 'CF112', G_1PE: 'CF111', EREC: '',      PE2: '',      PE1: '',      PBLK: 'CF120', BLK: 'CF120', L: '27.2', B: '1.6',  H: '1.6',  YARD: 'HHI',   SC_WF: 141.54, FB_WF: 283.09,  AS_WF: 0,       PE1_WF: 707.71,  PT_WF: 385.1,   PE2_WF: 707.71,  ER_WF: 0,      SC_PS: '2025-05-27', SC_PF: '2025-06-16', SC_PP: 0,     SC_AS: undefined,    SC_AF: undefined,    SC_AP: 0,   FB_PS: '2025-06-17', FB_PF: '2025-08-26', FB_PP: 0,     FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: undefined,    AS_PF: undefined,    AS_PP: 0,    AS_AS: undefined,    AS_AF: undefined,    AS_AP: 0,    PE1_PS: '2025-08-27', PE1_PF: '2025-09-11', PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: '2025-09-12', PT_PF: '2025-10-13', PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: '2025-10-14', PE2_PF: '2025-12-22', PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: undefined,    ER_PF: undefined,    ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'HULL',    ZONE: 'UCF',            MOD: 'Center',              LEVEL: 'Center',              NAME: 'UCF Center',             G_EREC: 'CF112', G_2PE: 'CF112', G_1PE: 'CF111', EREC: '',      PE2: '',      PE1: '',      PBLK: 'CF130', BLK: 'CF130', L: '1.6',  B: '27.2', H: '1.6',  YARD: 'HHI',   SC_WF: 141.54, FB_WF: 283.09,  AS_WF: 0,       PE1_WF: 707.71,  PT_WF: 385.1,   PE2_WF: 707.71,  ER_WF: 0,      SC_PS: '2025-05-27', SC_PF: '2025-06-16', SC_PP: 0,     SC_AS: undefined,    SC_AF: undefined,    SC_AP: 0,   FB_PS: '2025-06-17', FB_PF: '2025-08-26', FB_PP: 0,     FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: undefined,    AS_PF: undefined,    AS_PP: 0,    AS_AS: undefined,    AS_AF: undefined,    AS_AP: 0,    PE1_PS: '2025-08-27', PE1_PF: '2025-09-11', PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: '2025-09-12', PT_PF: '2025-10-13', PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: '2025-10-14', PE2_PF: '2025-12-22', PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: undefined,    ER_PF: undefined,    ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'HULL',    ZONE: 'UCF',            MOD: 'Center',              LEVEL: 'Center',              NAME: 'UCF Center',             G_EREC: 'CF112', G_2PE: 'CF112', G_1PE: 'CF111', EREC: '',      PE2: '',      PE1: '',      PBLK: 'CF140', BLK: 'CF140', L: '27.2', B: '1.6',  H: '1.6',  YARD: 'HHI',   SC_WF: 141.54, FB_WF: 283.09,  AS_WF: 0,       PE1_WF: 707.71,  PT_WF: 385.1,   PE2_WF: 707.71,  ER_WF: 0,      SC_PS: '2025-05-27', SC_PF: '2025-06-16', SC_PP: 0,     SC_AS: undefined,    SC_AF: undefined,    SC_AP: 0,   FB_PS: '2025-06-17', FB_PF: '2025-08-26', FB_PP: 0,     FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: undefined,    AS_PF: undefined,    AS_PP: 0,    AS_AS: undefined,    AS_AF: undefined,    AS_AP: 0,    PE1_PS: '2025-08-27', PE1_PF: '2025-09-11', PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: '2025-09-12', PT_PF: '2025-10-13', PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: '2025-10-14', PE2_PF: '2025-12-22', PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: undefined,    ER_PF: undefined,    ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'HULL',    ZONE: 'UCF',            MOD: 'Center',              LEVEL: 'Center',              NAME: 'UCF',                    G_EREC: 'CF112', G_2PE: 'CF112', G_1PE: 'WF111', EREC: '',      PE2: '',      PE1: 'WF111', PBLK: 'WF110', BLK: 'WF110', L: '16.9', B: '16.9', H: '9',    YARD: 'HHI',   SC_WF: 566.17, FB_WF: 1132.34, AS_WF: 0,       PE1_WF: 2830.86, PT_WF: 2706.38, PE2_WF: 2830.86, ER_WF: 0,      SC_PS: '2025-04-17', SC_PF: '2025-05-08', SC_PP: 0,     SC_AS: undefined,    SC_AF: undefined,    SC_AP: 0,   FB_PS: '2025-05-09', FB_PF: '2025-08-19', FB_PP: 0,     FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: undefined,    AS_PF: undefined,    AS_PP: 0,    AS_AS: undefined,    AS_AF: undefined,    AS_AP: 0,    PE1_PS: '2025-08-20', PE1_PF: '2025-09-04', PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: '2025-09-05', PT_PF: '2025-10-13', PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: '2025-10-14', PE2_PF: '2025-12-22', PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: undefined,    ER_PF: undefined,    ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'HULL',    ZONE: 'UCF',            MOD: 'Center',              LEVEL: 'Center',              NAME: 'UCF',                    G_EREC: 'CF112', G_2PE: 'CF112', G_1PE: 'WF111', EREC: '',      PE2: '',      PE1: '',      PBLK: '',      BLK: 'WF1A0', L: '13.3', B: '13.3', H: '18.9', YARD: 'HHI',   SC_WF: 0,      FB_WF: 0,       AS_WF: 0,       PE1_WF: 0,       PT_WF: 0,       PE2_WF: 0,       ER_WF: 0,      SC_PS: undefined,    SC_PF: undefined,    SC_PP: 0,     SC_AS: undefined,    SC_AF: undefined,    SC_AP: 0,   FB_PS: undefined,    FB_PF: undefined,    FB_PP: 0,     FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: undefined,    AS_PF: undefined,    AS_PP: 0,    AS_AS: undefined,    AS_AF: undefined,    AS_AP: 0,    PE1_PS: undefined,    PE1_PF: undefined,    PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: undefined,    PT_PF: undefined,    PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: undefined,    PE2_PF: undefined,    PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: undefined,    ER_PF: undefined,    ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'HULL',    ZONE: 'UCF',            MOD: 'Center',              LEVEL: 'Center',              NAME: 'UCF',                    G_EREC: 'CF112', G_2PE: 'CF112', G_1PE: 'WF121', EREC: '',      PE2: '',      PE1: 'WF121', PBLK: 'WF120', BLK: 'WF120', L: '16.9', B: '16.9', H: '9',    YARD: 'HHI',   SC_WF: 566.17, FB_WF: 1132.34, AS_WF: 0,       PE1_WF: 2830.86, PT_WF: 2706.38, PE2_WF: 2830.86, ER_WF: 0,      SC_PS: '2025-04-17', SC_PF: '2025-05-08', SC_PP: 0,     SC_AS: undefined,    SC_AF: undefined,    SC_AP: 0,   FB_PS: '2025-05-09', FB_PF: '2025-08-19', FB_PP: 0,     FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: undefined,    AS_PF: undefined,    AS_PP: 0,    AS_AS: undefined,    AS_AF: undefined,    AS_AP: 0,    PE1_PS: '2025-08-20', PE1_PF: '2025-09-04', PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: '2025-09-05', PT_PF: '2025-10-13', PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: '2025-10-14', PE2_PF: '2025-12-22', PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: undefined,    ER_PF: undefined,    ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'HULL',    ZONE: 'UCF',            MOD: 'Center',              LEVEL: 'Center',              NAME: 'UCF',                    G_EREC: 'CF112', G_2PE: 'CF112', G_1PE: 'WF121', EREC: '',      PE2: '',      PE1: '',      PBLK: '',      BLK: 'WF1B0', L: '13.3', B: '13.3', H: '18.9', YARD: 'HHI',   SC_WF: 0,      FB_WF: 0,       AS_WF: 0,       PE1_WF: 0,       PT_WF: 0,       PE2_WF: 0,       ER_WF: 0,      SC_PS: undefined,    SC_PF: undefined,    SC_PP: 0,     SC_AS: undefined,    SC_AF: undefined,    SC_AP: 0,   FB_PS: undefined,    FB_PF: undefined,    FB_PP: 0,     FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: undefined,    AS_PF: undefined,    AS_PP: 0,    AS_AS: undefined,    AS_AF: undefined,    AS_AP: 0,    PE1_PS: undefined,    PE1_PF: undefined,    PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: undefined,    PT_PF: undefined,    PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: undefined,    PE2_PF: undefined,    PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: undefined,    ER_PF: undefined,    ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'HULL',    ZONE: 'UCF',            MOD: 'Center',              LEVEL: 'Center',              NAME: 'UCF',                    G_EREC: 'CF112', G_2PE: 'CF112', G_1PE: 'EF111', EREC: '',      PE2: '',      PE1: 'EF111', PBLK: 'EF110', BLK: 'EF110', L: '16.9', B: '16.9', H: '9',    YARD: 'HHI',   SC_WF: 566.17, FB_WF: 1132.34, AS_WF: 0,       PE1_WF: 2830.86, PT_WF: 2706.38, PE2_WF: 2830.86, ER_WF: 0,      SC_PS: '2025-04-17', SC_PF: '2025-05-08', SC_PP: 0,     SC_AS: undefined,    SC_AF: undefined,    SC_AP: 0,   FB_PS: '2025-05-09', FB_PF: '2025-08-19', FB_PP: 0,     FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: undefined,    AS_PF: undefined,    AS_PP: 0,    AS_AS: undefined,    AS_AF: undefined,    AS_AP: 0,    PE1_PS: '2025-08-20', PE1_PF: '2025-09-04', PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: '2025-09-05', PT_PF: '2025-10-13', PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: '2025-10-14', PE2_PF: '2025-12-22', PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: undefined,    ER_PF: undefined,    ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'HULL',    ZONE: 'UCF',            MOD: 'Center',              LEVEL: 'Center',              NAME: 'UCF',                    G_EREC: 'CF112', G_2PE: 'CF112', G_1PE: 'EF111', EREC: '',      PE2: '',      PE1: '',      PBLK: '',      BLK: 'EF1A0', L: '13.3', B: '13.3', H: '18.9', YARD: 'HHI',   SC_WF: 0,      FB_WF: 0,       AS_WF: 0,       PE1_WF: 0,       PT_WF: 0,       PE2_WF: 0,       ER_WF: 0,      SC_PS: undefined,    SC_PF: undefined,    SC_PP: 0,     SC_AS: undefined,    SC_AF: undefined,    SC_AP: 0,   FB_PS: undefined,    FB_PF: undefined,    FB_PP: 0,     FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: undefined,    AS_PF: undefined,    AS_PP: 0,    AS_AS: undefined,    AS_AF: undefined,    AS_AP: 0,    PE1_PS: undefined,    PE1_PF: undefined,    PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: undefined,    PT_PF: undefined,    PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: undefined,    PE2_PF: undefined,    PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: undefined,    ER_PF: undefined,    ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'HULL',    ZONE: 'UCF',            MOD: 'Center',              LEVEL: 'Center',              NAME: 'UCF',                    G_EREC: 'CF112', G_2PE: 'CF112', G_1PE: 'EF121', EREC: '',      PE2: '',      PE1: 'EF121', PBLK: 'EF120', BLK: 'EF120', L: '16.9', B: '16.9', H: '9',    YARD: 'HHI',   SC_WF: 566.17, FB_WF: 1132.34, AS_WF: 0,       PE1_WF: 2830.86, PT_WF: 2706.38, PE2_WF: 2830.86, ER_WF: 0,      SC_PS: '2025-04-17', SC_PF: '2025-05-08', SC_PP: 0,     SC_AS: undefined,    SC_AF: undefined,    SC_AP: 0,   FB_PS: '2025-05-09', FB_PF: '2025-08-19', FB_PP: 0,     FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: undefined,    AS_PF: undefined,    AS_PP: 0,    AS_AS: undefined,    AS_AF: undefined,    AS_AP: 0,    PE1_PS: '2025-08-20', PE1_PF: '2025-09-04', PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: '2025-09-05', PT_PF: '2025-10-13', PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: '2025-10-14', PE2_PF: '2025-12-22', PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: undefined,    ER_PF: undefined,    ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'HULL',    ZONE: 'UCF',            MOD: 'Center',              LEVEL: 'Center',              NAME: 'UCF',                    G_EREC: 'CF112', G_2PE: 'CF112', G_1PE: 'EF121', EREC: '',      PE2: '',      PE1: '',      PBLK: '',      BLK: 'EF1B0', L: '13.3', B: '13.3', H: '18.9', YARD: 'HHI',   SC_WF: 0,      FB_WF: 0,       AS_WF: 0,       PE1_WF: 0,       PT_WF: 0,       PE2_WF: 0,       ER_WF: 0,      SC_PS: undefined,    SC_PF: undefined,    SC_PP: 0,     SC_AS: undefined,    SC_AF: undefined,    SC_AP: 0,   FB_PS: undefined,    FB_PF: undefined,    FB_PP: 0,     FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: undefined,    AS_PF: undefined,    AS_PP: 0,    AS_AS: undefined,    AS_AF: undefined,    AS_AP: 0,    PE1_PS: undefined,    PE1_PF: undefined,    PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: undefined,    PT_PF: undefined,    PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: undefined,    PE2_PF: undefined,    PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: undefined,    ER_PF: undefined,    ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'HULL',    ZONE: 'CAISION/RISER',  MOD: 'Caisson',             LEVEL: 'NW',                  NAME: 'Caisson',                G_EREC: 'CC410', G_2PE: 'CC410', G_1PE: 'CC410', EREC: 'CC410', PE2: '',      PE1: '',      PBLK: 'CC410', BLK: 'CC410', L: '17.7', B: '17.6', H: '56',   YARD: 'HHI',   SC_WF: 24,     FB_WF: 48,      AS_WF: 0,       PE1_WF: 113.23,  PT_WF: 80,      PE2_WF: 113.23,  ER_WF: 2073.7, SC_PS: '2025-04-23', SC_PF: '2025-05-15', SC_PP: 0,     SC_AS: undefined,    SC_AF: undefined,    SC_AP: 0,   FB_PS: '2025-05-16', FB_PF: '2025-08-25', FB_PP: 0,     FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: undefined,    AS_PF: undefined,    AS_PP: 0,    AS_AS: undefined,    AS_AF: undefined,    AS_AP: 0,    PE1_PS: '2025-08-26', PE1_PF: '2025-09-24', PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: '2025-09-25', PT_PF: '2025-10-24', PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: '2025-10-27', PE2_PF: '2025-12-09', PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: '2025-12-10', ER_PF: '2026-01-09', ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'HULL',    ZONE: 'CAISION/RISER',  MOD: 'Caisson',             LEVEL: 'SW',                  NAME: 'Caisson',                G_EREC: 'CC420', G_2PE: 'CC420', G_1PE: 'CC420', EREC: 'CC420', PE2: '',      PE1: '',      PBLK: 'CC420', BLK: 'CC420', L: '24.1', B: '17.9', H: '57.7', YARD: 'HHI',   SC_WF: 854.92, FB_WF: 1709.84, AS_WF: 0,       PE1_WF: 4274.59, PT_WF: 2435.17, PE2_WF: 4274.59, ER_WF: 2073.7, SC_PS: '2025-04-28', SC_PF: '2025-05-20', SC_PP: 0,     SC_AS: undefined,    SC_AF: undefined,    SC_AP: 0,   FB_PS: '2025-05-21', FB_PF: '2025-08-28', FB_PP: 0,     FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: undefined,    AS_PF: undefined,    AS_PP: 0,    AS_AS: undefined,    AS_AF: undefined,    AS_AP: 0,    PE1_PS: '2025-08-29', PE1_PF: '2025-09-29', PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: '2025-09-30', PT_PF: '2025-10-29', PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: '2025-10-30', PE2_PF: '2025-12-12', PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: '2025-12-15', ER_PF: '2026-01-14', ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'HULL',    ZONE: 'CAISION/RISER',  MOD: 'Caisson',             LEVEL: 'NE',                  NAME: 'Caisson',                G_EREC: 'CC430', G_2PE: 'CC430', G_1PE: 'CC430', EREC: 'CC430', PE2: '',      PE1: '',      PBLK: 'CC430', BLK: 'CC430', L: '9',    B: '28',   H: '48.4', YARD: 'HHI',   SC_WF: 118.9,  FB_WF: 237.79,  AS_WF: 0,       PE1_WF: 594.48,  PT_WF: 338.67,  PE2_WF: 594.48,  ER_WF: 2073.7, SC_PS: '2025-04-11', SC_PF: '2025-04-30', SC_PP: 0,     SC_AS: undefined,    SC_AF: undefined,    SC_AP: 0,   FB_PS: '2025-05-02', FB_PF: '2025-08-12', FB_PP: 0,     FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: undefined,    AS_PF: undefined,    AS_PP: 0,    AS_AS: undefined,    AS_AF: undefined,    AS_AP: 0,    PE1_PS: '2025-08-13', PE1_PF: '2025-09-12', PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: '2025-09-15', PT_PF: '2025-10-14', PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: '2025-10-15', PE2_PF: '2025-11-27', PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: '2025-11-28', ER_PF: '2025-12-26', ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'HULL',    ZONE: 'CAISION/RISER',  MOD: 'Caisson',             LEVEL: 'SE',                  NAME: 'Caisson',                G_EREC: 'CC440', G_2PE: 'CC440', G_1PE: 'CC440', EREC: 'CC440', PE2: '',      PE1: '',      PBLK: 'CC440', BLK: 'CC440', L: '9.8',  B: '17.4', H: '57.5', YARD: 'HHI',   SC_WF: 854.92, FB_WF: 1709.84, AS_WF: 0,       PE1_WF: 4274.59, PT_WF: 2435.17, PE2_WF: 4274.59, ER_WF: 2073.7, SC_PS: '2025-04-16', SC_PF: '2025-05-08', SC_PP: 0,     SC_AS: undefined,    SC_AF: undefined,    SC_AP: 0,   FB_PS: '2025-05-09', FB_PF: '2025-08-18', FB_PP: 0,     FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: undefined,    AS_PF: undefined,    AS_PP: 0,    AS_AS: undefined,    AS_AF: undefined,    AS_AP: 0,    PE1_PS: '2025-08-19', PE1_PF: '2025-09-17', PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: '2025-09-18', PT_PF: '2025-10-17', PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: '2025-10-20', PE2_PF: '2025-12-02', PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: '2025-12-03', ER_PF: '2025-12-31', ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'HULL',    ZONE: 'CAISION/RISER',  MOD: 'Riser Pipe',          LEVEL: 'NW',                  NAME: 'Riser Pipe',             G_EREC: 'RC410', G_2PE: 'RC410', G_1PE: 'RC410', EREC: 'RC410', PE2: '',      PE1: '',      PBLK: '',      BLK: 'RC410', L: '48.7', B: '36.4', H: '52.8', YARD: 'HHI',   SC_WF: 0,      FB_WF: 0,       AS_WF: 0,       PE1_WF: 0,       PT_WF: 0,       PE2_WF: 608.63,  ER_WF: 2073.7, SC_PS: undefined,    SC_PF: undefined,    SC_PP: 0,     SC_AS: undefined,    SC_AF: undefined,    SC_AP: 0,   FB_PS: undefined,    FB_PF: undefined,    FB_PP: 0,     FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: undefined,    AS_PF: undefined,    AS_PP: 0,    AS_AS: undefined,    AS_AF: undefined,    AS_AP: 0,    PE1_PS: undefined,    PE1_PF: undefined,    PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: undefined,    PT_PF: undefined,    PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: '2025-10-27', PE2_PF: '2025-12-09', PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: '2025-12-10', ER_PF: '2026-01-09', ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'HULL',    ZONE: 'CAISION/RISER',  MOD: 'Riser Pipe',          LEVEL: 'SW',                  NAME: 'Riser  Pipe',            G_EREC: 'RC420', G_2PE: 'RC420', G_1PE: 'RC420', EREC: 'RC420', PE2: '',      PE1: '',      PBLK: '',      BLK: 'RC420', L: '15.6', B: '36.9', H: '50.9', YARD: 'HHI',   SC_WF: 0,      FB_WF: 0,       AS_WF: 0,       PE1_WF: 0,       PT_WF: 0,       PE2_WF: 113.23,  ER_WF: 2073.7, SC_PS: undefined,    SC_PF: undefined,    SC_PP: 0,     SC_AS: undefined,    SC_AF: undefined,    SC_AP: 0,   FB_PS: undefined,    FB_PF: undefined,    FB_PP: 0,     FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: undefined,    AS_PF: undefined,    AS_PP: 0,    AS_AS: undefined,    AS_AF: undefined,    AS_AP: 0,    PE1_PS: undefined,    PE1_PF: undefined,    PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: undefined,    PT_PF: undefined,    PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: '2025-10-30', PE2_PF: '2025-12-12', PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: '2025-12-15', ER_PF: '2026-01-14', ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'HULL',    ZONE: 'CAISION/RISER',  MOD: 'Sump Pile',           LEVEL: 'NE',                  NAME: 'Sump Pile',              G_EREC: 'SC410', G_2PE: 'SC410', G_1PE: 'SC410', EREC: 'SC410', PE2: '',      PE1: '',      PBLK: '',      BLK: 'SC410', L: '3.6',  B: '3.7',  H: '43.3', YARD: 'HHI',   SC_WF: 0,      FB_WF: 0,       AS_WF: 0,       PE1_WF: 0,       PT_WF: 0,       PE2_WF: 0,       ER_WF: 0,      SC_PS: undefined,    SC_PF: undefined,    SC_PP: 0,     SC_AS: undefined,    SC_AF: undefined,    SC_AP: 0,   FB_PS: undefined,    FB_PF: undefined,    FB_PP: 0,     FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: undefined,    AS_PF: undefined,    AS_PP: 0,    AS_AS: undefined,    AS_AF: undefined,    AS_AP: 0,    PE1_PS: undefined,    PE1_PF: undefined,    PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: undefined,    PT_PF: undefined,    PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: undefined,    PE2_PF: undefined,    PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: undefined,    ER_PF: undefined,    ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'HULL',    ZONE: 'CAISION/RISER',  MOD: 'Sump Pile',           LEVEL: 'SE',                  NAME: 'Sump Pile',              G_EREC: 'SC420', G_2PE: 'SC420', G_1PE: 'SC420', EREC: 'SC420', PE2: '',      PE1: '',      PBLK: '',      BLK: 'SC420', L: '3.1',  B: '3.1',  H: '43.3', YARD: 'HHI',   SC_WF: 0,      FB_WF: 0,       AS_WF: 0,       PE1_WF: 0,       PT_WF: 0,       PE2_WF: 0,       ER_WF: 0,      SC_PS: undefined,    SC_PF: undefined,    SC_PP: 0,     SC_AS: undefined,    SC_AF: undefined,    SC_AP: 0,   FB_PS: undefined,    FB_PF: undefined,    FB_PP: 0,     FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: undefined,    AS_PF: undefined,    AS_PP: 0,    AS_AS: undefined,    AS_AF: undefined,    AS_AP: 0,    PE1_PS: undefined,    PE1_PF: undefined,    PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: undefined,    PT_PF: undefined,    PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: undefined,    PE2_PF: undefined,    PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: undefined,    ER_PF: undefined,    ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'TOPSIDE', ZONE: 'NORTH',          MOD: 'Production Deck',     LEVEL: 'Production Deck',     NAME: 'Deck Block',             G_EREC: 'ND110', G_2PE: 'ND110', G_1PE: 'ND110', EREC: 'ND110', PE2: 'ND110', PE1: 'ND110', PBLK: 'ND110', BLK: 'ND111', L: '20.8', B: '22.6', H: '2.2',  YARD: 'HHI',   SC_WF: 306.15, FB_WF: 612.31,  AS_WF: 918.46,  PE1_WF: 3660.18, PT_WF: 3136.67, PE2_WF: 3660.18, ER_WF: 5822.9, SC_PS: '2025-02-27', SC_PF: '2025-03-18', SC_PP: 0,     SC_AS: undefined,    SC_AF: undefined,    SC_AP: 0,   FB_PS: '2025-03-19', FB_PF: '2025-04-15', FB_PP: 0,     FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: '2025-04-16', AS_PF: '2025-06-05', AS_PP: 0,    AS_AS: undefined,    AS_AF: undefined,    AS_AP: 0,    PE1_PS: '2025-06-09', PE1_PF: '2025-07-08', PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: '2025-07-09', PT_PF: '2025-08-12', PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: '2025-08-13', PE2_PF: '2025-09-19', PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: '2025-09-22', ER_PF: '2026-04-08', ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'TOPSIDE', ZONE: 'NORTH',          MOD: 'Production Deck',     LEVEL: 'Production Deck',     NAME: 'Deck Block',             G_EREC: 'ND110', G_2PE: 'ND110', G_1PE: 'ND110', EREC: '',      PE2: '',      PE1: '',      PBLK: '',      BLK: 'ND112', L: '14.9', B: '21.5', H: '1.5',  YARD: 'HHI',   SC_WF: 211.39, FB_WF: 422.78,  AS_WF: 634.18,  PE1_WF: 0,       PT_WF: 0,       PE2_WF: 0,       ER_WF: 0,      SC_PS: '2025-02-27', SC_PF: '2025-03-18', SC_PP: 0,     SC_AS: undefined,    SC_AF: undefined,    SC_AP: 0,   FB_PS: '2025-03-19', FB_PF: '2025-04-15', FB_PP: 0,     FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: '2025-04-16', AS_PF: '2025-06-05', AS_PP: 0,    AS_AS: undefined,    AS_AF: undefined,    AS_AP: 0,    PE1_PS: undefined,    PE1_PF: undefined,    PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: undefined,    PT_PF: undefined,    PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: undefined,    PE2_PF: undefined,    PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: undefined,    ER_PF: undefined,    ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'TOPSIDE', ZONE: 'NORTH',          MOD: 'Production Deck',     LEVEL: 'Production Deck',     NAME: 'Deck Block',             G_EREC: 'ND110', G_2PE: 'ND110', G_1PE: 'ND110', EREC: '',      PE2: '',      PE1: '',      PBLK: '',      BLK: 'ND113', L: '18',   B: '2.4',  H: '0.6',  YARD: 'HHI',   SC_WF: 36.45,  FB_WF: 72.89,   AS_WF: 109.34,  PE1_WF: 0,       PT_WF: 0,       PE2_WF: 0,       ER_WF: 0,      SC_PS: '2025-03-17', SC_PF: '2025-04-03', SC_PP: 0,     SC_AS: undefined,    SC_AF: undefined,    SC_AP: 0,   FB_PS: '2025-04-04', FB_PF: '2025-04-28', FB_PP: 0,     FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: '2025-04-29', AS_PF: '2025-06-05', AS_PP: 0,    AS_AS: undefined,    AS_AF: undefined,    AS_AP: 0,    PE1_PS: undefined,    PE1_PF: undefined,    PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: undefined,    PT_PF: undefined,    PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: undefined,    PE2_PF: undefined,    PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: undefined,    ER_PF: undefined,    ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'TOPSIDE', ZONE: 'NORTH',          MOD: 'Production Deck',     LEVEL: 'Production Deck',     NAME: 'Deck Block',             G_EREC: 'ND110', G_2PE: 'ND110', G_1PE: 'ND110', EREC: '',      PE2: '',      PE1: '',      PBLK: '',      BLK: 'ND114', L: '12.7', B: '2.3',  H: '0.5',  YARD: 'HHI',   SC_WF: 16,     FB_WF: 24.3,    AS_WF: 27.34,   PE1_WF: 0,       PT_WF: 0,       PE2_WF: 0,       ER_WF: 0,      SC_PS: '2025-03-19', SC_PF: '2025-04-07', SC_PP: 0,     SC_AS: undefined,    SC_AF: undefined,    SC_AP: 0,   FB_PS: '2025-04-08', FB_PF: '2025-04-30', FB_PP: 0,     FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: '2025-05-02', AS_PF: '2025-06-05', AS_PP: 0,    AS_AS: undefined,    AS_AF: undefined,    AS_AP: 0,    PE1_PS: undefined,    PE1_PF: undefined,    PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: undefined,    PT_PF: undefined,    PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: undefined,    PE2_PF: undefined,    PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: undefined,    ER_PF: undefined,    ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'TOPSIDE', ZONE: 'NORTH',          MOD: 'Production Deck',     LEVEL: 'Production Deck',     NAME: 'Column & Brace',         G_EREC: 'ND110', G_2PE: 'ND110', G_1PE: 'NC111', EREC: '',      PE2: '',      PE1: '',      PBLK: 'NC111', BLK: 'NC111', L: '31.2', B: '21.2', H: '15.9', YARD: 'HHI',   SC_WF: 168.93, FB_WF: 337.86,  AS_WF: 506.79,  PE1_WF: 0,       PT_WF: 626.77,  PE2_WF: 0,       ER_WF: 0,      SC_PS: '2025-03-05', SC_PF: '2025-03-21', SC_PP: 0,     SC_AS: undefined,    SC_AF: undefined,    SC_AP: 0,   FB_PS: '2025-03-24', FB_PF: '2025-04-08', FB_PP: 0,     FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: '2025-04-09', AS_PF: '2025-05-06', AS_PP: 0,    AS_AS: undefined,    AS_AF: undefined,    AS_AP: 0,    PE1_PS: undefined,    PE1_PF: undefined,    PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: '2025-06-09', PT_PF: '2025-07-08', PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: undefined,    PE2_PF: undefined,    PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: undefined,    ER_PF: undefined,    ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'TOPSIDE', ZONE: 'NORTH',          MOD: 'Production Deck',     LEVEL: 'Production Deck',     NAME: 'Stair',                  G_EREC: 'ND110', G_2PE: 'ND110', G_1PE: 'ND1A1', EREC: '',      PE2: '',      PE1: '',      PBLK: 'ND1A1', BLK: 'ND1A1', L: '8.6',  B: '3.2',  H: '5.1',  YARD: 'HHI',   SC_WF: 8,      FB_WF: 0,       AS_WF: 24,      PE1_WF: 0,       PT_WF: 26.49,   PE2_WF: 32,      ER_WF: 0,      SC_PS: '2025-04-08', SC_PF: '2025-04-25', SC_PP: 0,     SC_AS: undefined,    SC_AF: undefined,    SC_AP: 0,   FB_PS: undefined,    FB_PF: undefined,    FB_PP: 0,     FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: '2025-04-28', AS_PF: '2025-07-02', AS_PP: 0,    AS_AS: undefined,    AS_AF: undefined,    AS_AP: 0,    PE1_PS: undefined,    PE1_PF: undefined,    PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: '2025-07-23', PT_PF: '2025-08-27', PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: '2025-08-28', PE2_PF: '2025-09-17', PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: undefined,    ER_PF: undefined,    ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'TOPSIDE', ZONE: 'NORTH',          MOD: 'Production Deck',     LEVEL: 'Production Deck',     NAME: 'Access P/F',             G_EREC: 'ND110', G_2PE: 'ND110', G_1PE: 'NV111', EREC: '',      PE2: '',      PE1: '',      PBLK: 'NV111', BLK: 'NV111', L: '3.5',  B: '4.5',  H: '2.9',  YARD: 'HHI',   SC_WF: 8,      FB_WF: 0,       AS_WF: 24,      PE1_WF: 0,       PT_WF: 45.7,    PE2_WF: 16,      ER_WF: 0,      SC_PS: '2025-04-24', SC_PF: '2025-05-16', SC_PP: 0,     SC_AS: undefined,    SC_AF: undefined,    SC_AP: 0,   FB_PS: undefined,    FB_PF: undefined,    FB_PP: 0,     FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: '2025-05-19', AS_PF: '2025-07-02', AS_PP: 0,    AS_AS: undefined,    AS_AF: undefined,    AS_AP: 0,    PE1_PS: undefined,    PE1_PF: undefined,    PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: '2025-07-23', PT_PF: '2025-08-27', PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: '2025-08-28', PE2_PF: '2025-09-17', PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: undefined,    ER_PF: undefined,    ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'TOPSIDE', ZONE: 'NORTH',          MOD: 'Production Deck',     LEVEL: 'Production Deck',     NAME: 'Access P/F',             G_EREC: 'ND110', G_2PE: 'ND110', G_1PE: 'NV121', EREC: '',      PE2: '',      PE1: '',      PBLK: 'NV121', BLK: 'NV121', L: '2.6',  B: '6.3',  H: '3.4',  YARD: 'HHI',   SC_WF: 8,      FB_WF: 0,       AS_WF: 24,      PE1_WF: 0,       PT_WF: 71.54,   PE2_WF: 16,      ER_WF: 0,      SC_PS: '2025-04-24', SC_PF: '2025-05-16', SC_PP: 0,     SC_AS: undefined,    SC_AF: undefined,    SC_AP: 0,   FB_PS: undefined,    FB_PF: undefined,    FB_PP: 0,     FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: '2025-05-19', AS_PF: '2025-07-02', AS_PP: 0,    AS_AS: undefined,    AS_AF: undefined,    AS_AP: 0,    PE1_PS: undefined,    PE1_PF: undefined,    PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: '2025-07-23', PT_PF: '2025-08-27', PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: '2025-08-28', PE2_PF: '2025-09-17', PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: undefined,    ER_PF: undefined,    ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'TOPSIDE', ZONE: 'NORTH',          MOD: 'Production Deck',     LEVEL: 'Production Deck',     NAME: 'Access P/F',             G_EREC: 'ND110', G_2PE: 'ND110', G_1PE: 'NV131', EREC: '',      PE2: '',      PE1: '',      PBLK: 'NV131', BLK: 'NV131', L: '1.8',  B: '6.8',  H: '2.7',  YARD: 'HHI',   SC_WF: 8,      FB_WF: 0,       AS_WF: 24,      PE1_WF: 0,       PT_WF: 45.46,   PE2_WF: 16,      ER_WF: 0,      SC_PS: '2025-04-24', SC_PF: '2025-05-16', SC_PP: 0,     SC_AS: undefined,    SC_AF: undefined,    SC_AP: 0,   FB_PS: undefined,    FB_PF: undefined,    FB_PP: 0,     FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: '2025-05-19', AS_PF: '2025-07-02', AS_PP: 0,    AS_AS: undefined,    AS_AF: undefined,    AS_AP: 0,    PE1_PS: undefined,    PE1_PF: undefined,    PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: '2025-07-23', PT_PF: '2025-08-27', PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: '2025-08-28', PE2_PF: '2025-09-17', PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: undefined,    ER_PF: undefined,    ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'TOPSIDE', ZONE: 'NORTH',          MOD: 'Production Deck',     LEVEL: 'Production Deck',     NAME: 'Access P/F',             G_EREC: 'ND110', G_2PE: 'ND110', G_1PE: 'NV141', EREC: '',      PE2: '',      PE1: '',      PBLK: 'NV141', BLK: 'NV141', L: '1.8',  B: '6.8',  H: '2.7',  YARD: 'HHI',   SC_WF: 8,      FB_WF: 0,       AS_WF: 24,      PE1_WF: 0,       PT_WF: 45.46,   PE2_WF: 16,      ER_WF: 0,      SC_PS: '2025-04-24', SC_PF: '2025-05-16', SC_PP: 0,     SC_AS: undefined,    SC_AF: undefined,    SC_AP: 0,   FB_PS: undefined,    FB_PF: undefined,    FB_PP: 0,     FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: '2025-05-19', AS_PF: '2025-07-02', AS_PP: 0,    AS_AS: undefined,    AS_AF: undefined,    AS_AP: 0,    PE1_PS: undefined,    PE1_PF: undefined,    PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: '2025-07-23', PT_PF: '2025-08-27', PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: '2025-08-28', PE2_PF: '2025-09-17', PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: undefined,    ER_PF: undefined,    ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'TOPSIDE', ZONE: 'NORTH',          MOD: 'Production Deck',     LEVEL: 'Production Deck',     NAME: 'Access P/F',             G_EREC: 'ND110', G_2PE: 'ND110', G_1PE: 'NV151', EREC: '',      PE2: '',      PE1: '',      PBLK: 'NV151', BLK: 'NV151', L: '1.5',  B: '6.8',  H: '2.7',  YARD: 'HHI',   SC_WF: 8,      FB_WF: 0,       AS_WF: 24,      PE1_WF: 0,       PT_WF: 45.46,   PE2_WF: 16,      ER_WF: 0,      SC_PS: '2025-04-24', SC_PF: '2025-05-16', SC_PP: 0,     SC_AS: undefined,    SC_AF: undefined,    SC_AP: 0,   FB_PS: undefined,    FB_PF: undefined,    FB_PP: 0,     FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: '2025-05-19', AS_PF: '2025-06-30', AS_PP: 0,    AS_AS: undefined,    AS_AF: undefined,    AS_AP: 0,    PE1_PS: undefined,    PE1_PF: undefined,    PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: '2025-07-21', PT_PF: '2025-08-25', PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: '2025-08-26', PE2_PF: '2025-09-17', PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: undefined,    ER_PF: undefined,    ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'TOPSIDE', ZONE: 'NORTH',          MOD: 'Production Deck',     LEVEL: 'Production Deck',     NAME: 'Deck Block',             G_EREC: 'ND120', G_2PE: 'ND120', G_1PE: 'ND120', EREC: 'ND120', PE2: 'ND120', PE1: 'ND120', PBLK: 'ND120', BLK: 'ND121', L: '15.5', B: '21.6', H: '1.5',  YARD: 'HHI',   SC_WF: 240.55, FB_WF: 481.1,   AS_WF: 721.65,  PE1_WF: 3744.92, PT_WF: 3174.93, PE2_WF: 3744.92, ER_WF: 5822.9, SC_PS: '2025-03-06', SC_PF: '2025-03-25', SC_PP: 0,     SC_AS: undefined,    SC_AF: undefined,    SC_AP: 0,   FB_PS: '2025-03-26', FB_PF: '2025-04-22', FB_PP: 0,     FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: '2025-04-23', AS_PF: '2025-06-13', AS_PP: 0,    AS_AS: undefined,    AS_AF: undefined,    AS_AP: 0,    PE1_PS: '2025-06-16', PE1_PF: '2025-07-15', PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: '2025-07-16', PT_PF: '2025-08-20', PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: '2025-08-21', PE2_PF: '2025-09-26', PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: '2025-09-29', ER_PF: '2026-04-08', ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'TOPSIDE', ZONE: 'NORTH',          MOD: 'Production Deck',     LEVEL: 'Production Deck',     NAME: 'Deck Block',             G_EREC: 'ND120', G_2PE: 'ND120', G_1PE: 'ND120', EREC: '',      PE2: '',      PE1: '',      PBLK: '',      BLK: 'ND122', L: '17.4', B: '22.3', H: '1.5',  YARD: 'HHI',   SC_WF: 264.24, FB_WF: 528.48,  AS_WF: 792.72,  PE1_WF: 0,       PT_WF: 0,       PE2_WF: 0,       ER_WF: 0,      SC_PS: '2025-03-06', SC_PF: '2025-03-25', SC_PP: 0,     SC_AS: undefined,    SC_AF: undefined,    SC_AP: 0,   FB_PS: '2025-03-26', FB_PF: '2025-04-22', FB_PP: 0,     FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: '2025-04-23', AS_PF: '2025-06-13', AS_PP: 0,    AS_AS: undefined,    AS_AF: undefined,    AS_AP: 0,    PE1_PS: undefined,    PE1_PF: undefined,    PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: undefined,    PT_PF: undefined,    PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: undefined,    PE2_PF: undefined,    PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: undefined,    ER_PF: undefined,    ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'TOPSIDE', ZONE: 'NORTH',          MOD: 'Production Deck',     LEVEL: 'Production Deck',     NAME: 'Deck Block',             G_EREC: 'ND120', G_2PE: 'ND120', G_1PE: 'ND120', EREC: '',      PE2: '',      PE1: '',      PBLK: '',      BLK: 'ND123', L: '15.5', B: '2.3',  H: '0.4',  YARD: 'HHI',   SC_WF: 23.69,  FB_WF: 47.38,   AS_WF: 71.07,   PE1_WF: 0,       PT_WF: 0,       PE2_WF: 0,       ER_WF: 0,      SC_PS: '2025-03-25', SC_PF: '2025-04-11', SC_PP: 0,     SC_AS: undefined,    SC_AF: undefined,    SC_AP: 0,   FB_PS: '2025-04-14', FB_PF: '2025-05-09', FB_PP: 0,     FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: '2025-05-12', AS_PF: '2025-06-13', AS_PP: 0,    AS_AS: undefined,    AS_AF: undefined,    AS_AP: 0,    PE1_PS: undefined,    PE1_PF: undefined,    PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: undefined,    PT_PF: undefined,    PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: undefined,    PE2_PF: undefined,    PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: undefined,    ER_PF: undefined,    ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'TOPSIDE', ZONE: 'NORTH',          MOD: 'Production Deck',     LEVEL: 'Production Deck',     NAME: 'Deck Block',             G_EREC: 'ND120', G_2PE: 'ND120', G_1PE: 'ND140', EREC: '',      PE2: '',      PE1: 'ND140', PBLK: 'ND140', BLK: 'ND141', L: '17.9', B: '13.3', H: '2.6',  YARD: 'HHI',   SC_WF: 167.66, FB_WF: 335.31,  AS_WF: 502.97,  PE1_WF: 0,       PT_WF: 2646.52, PE2_WF: 3044.23, ER_WF: 0,      SC_PS: '2025-03-10', SC_PF: '2025-03-27', SC_PP: 0,     SC_AS: undefined,    SC_AF: undefined,    SC_AP: 0,   FB_PS: '2025-03-28', FB_PF: '2025-04-24', FB_PP: 0,     FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: '2025-04-25', AS_PF: '2025-06-13', AS_PP: 0,    AS_AS: undefined,    AS_AF: undefined,    AS_AP: 0,    PE1_PS: undefined,    PE1_PF: undefined,    PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: '2025-07-16', PT_PF: '2025-08-20', PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: '2025-08-21', PE2_PF: '2025-09-19', PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: undefined,    ER_PF: undefined,    ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'TOPSIDE', ZONE: 'NORTH',          MOD: 'Production Deck',     LEVEL: 'Production Deck',     NAME: 'Deck Block',             G_EREC: 'ND120', G_2PE: 'ND120', G_1PE: 'ND140', EREC: '',      PE2: '',      PE1: '',      PBLK: '',      BLK: 'ND142', L: '18.5', B: '12.7', H: '1.5',  YARD: 'HHI',   SC_WF: 156.72, FB_WF: 313.44,  AS_WF: 470.17,  PE1_WF: 0,       PT_WF: 0,       PE2_WF: 0,       ER_WF: 0,      SC_PS: '2025-03-19', SC_PF: '2025-04-03', SC_PP: 0,     SC_AS: undefined,    SC_AF: undefined,    SC_AP: 0,   FB_PS: '2025-04-04', FB_PF: '2025-05-06', FB_PP: 0,     FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: '2025-05-07', AS_PF: '2025-06-13', AS_PP: 0,    AS_AS: undefined,    AS_AF: undefined,    AS_AP: 0,    PE1_PS: undefined,    PE1_PF: undefined,    PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: undefined,    PT_PF: undefined,    PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: undefined,    PE2_PF: undefined,    PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: undefined,    ER_PF: undefined,    ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'TOPSIDE', ZONE: 'NORTH',          MOD: 'Production Deck',     LEVEL: 'Production Deck',     NAME: 'Deck Block',             G_EREC: 'ND120', G_2PE: 'ND120', G_1PE: 'ND140', EREC: '',      PE2: '',      PE1: '',      PBLK: '',      BLK: 'ND143', L: '7.1',  B: '21.2', H: '1.5',  YARD: 'HHI',   SC_WF: 109.34, FB_WF: 218.68,  AS_WF: 328.02,  PE1_WF: 0,       PT_WF: 0,       PE2_WF: 0,       ER_WF: 0,      SC_PS: '2025-03-10', SC_PF: '2025-04-03', SC_PP: 0,     SC_AS: undefined,    SC_AF: undefined,    SC_AP: 0,   FB_PS: '2025-04-04', FB_PF: '2025-05-06', FB_PP: 0,     FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: '2025-05-07', AS_PF: '2025-06-13', AS_PP: 0,    AS_AS: undefined,    AS_AF: undefined,    AS_AP: 0,    PE1_PS: undefined,    PE1_PF: undefined,    PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: undefined,    PT_PF: undefined,    PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: undefined,    PE2_PF: undefined,    PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: undefined,    ER_PF: undefined,    ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'TOPSIDE', ZONE: 'NORTH',          MOD: 'Production Deck',     LEVEL: 'Production Deck',     NAME: 'Deck Block',             G_EREC: 'ND120', G_2PE: 'ND120', G_1PE: 'ND140', EREC: '',      PE2: '',      PE1: '',      PBLK: '',      BLK: 'ND144', L: '9.5',  B: '5.4',  H: '5.1',  YARD: 'HHI',   SC_WF: 34.62,  FB_WF: 69.25,   AS_WF: 103.87,  PE1_WF: 0,       PT_WF: 0,       PE2_WF: 0,       ER_WF: 0,      SC_PS: '2025-04-02', SC_PF: '2025-04-17', SC_PP: 0,     SC_AS: undefined,    SC_AF: undefined,    SC_AP: 0,   FB_PS: '2025-04-18', FB_PF: '2025-05-15', FB_PP: 0,     FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: '2025-05-16', AS_PF: '2025-06-13', AS_PP: 0,    AS_AS: undefined,    AS_AF: undefined,    AS_AP: 0,    PE1_PS: undefined,    PE1_PF: undefined,    PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: undefined,    PT_PF: undefined,    PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: undefined,    PE2_PF: undefined,    PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: undefined,    ER_PF: undefined,    ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'TOPSIDE', ZONE: 'NORTH',          MOD: 'Production Deck',     LEVEL: 'Production Deck',     NAME: 'Column & Brace',         G_EREC: 'ND120', G_2PE: 'ND120', G_1PE: 'ND120', EREC: '',      PE2: '',      PE1: '',      PBLK: '',      BLK: 'NC121', L: '30.2', B: '25.5', H: '13',   YARD: 'HHI',   SC_WF: 158.54, FB_WF: 317.09,  AS_WF: 475.63,  PE1_WF: 0,       PT_WF: 635.05,  PE2_WF: 0,       ER_WF: 0,      SC_PS: '2025-03-12', SC_PF: '2025-03-28', SC_PP: 0,     SC_AS: undefined,    SC_AF: undefined,    SC_AP: 0,   FB_PS: '2025-03-31', FB_PF: '2025-04-15', FB_PP: 0,     FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: '2025-04-16', AS_PF: '2025-05-13', AS_PP: 0,    AS_AS: undefined,    AS_AF: undefined,    AS_AP: 0,    PE1_PS: undefined,    PE1_PF: undefined,    PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: '2025-06-16', PT_PF: '2025-07-15', PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: undefined,    PE2_PF: undefined,    PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: undefined,    ER_PF: undefined,    ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'TOPSIDE', ZONE: 'NORTH',          MOD: 'Production Deck',     LEVEL: 'Production Deck',     NAME: 'Wall',                   G_EREC: 'ND120', G_2PE: 'ND120', G_1PE: 'ND120', EREC: '',      PE2: '',      PE1: '',      PBLK: '',      BLK: 'NT111', L: '7.4',  B: '0.4',  H: '4.1',  YARD: 'HHI',   SC_WF: 27.34,  FB_WF: 54.67,   AS_WF: 0,       PE1_WF: 0,       PT_WF: 0,       PE2_WF: 0,       ER_WF: 0,      SC_PS: '2025-04-07', SC_PF: '2025-04-24', SC_PP: 0,     SC_AS: undefined,    SC_AF: undefined,    SC_AP: 0,   FB_PS: '2025-04-25', FB_PF: '2025-06-20', FB_PP: 0,     FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: undefined,    AS_PF: undefined,    AS_PP: 0,    AS_AS: undefined,    AS_AF: undefined,    AS_AP: 0,    PE1_PS: undefined,    PE1_PF: undefined,    PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: undefined,    PT_PF: undefined,    PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: undefined,    PE2_PF: undefined,    PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: undefined,    ER_PF: undefined,    ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'TOPSIDE', ZONE: 'NORTH',          MOD: 'Production Deck',     LEVEL: 'Production Deck',     NAME: 'Wall',                   G_EREC: 'ND120', G_2PE: 'ND120', G_1PE: 'ND120', EREC: '',      PE2: '',      PE1: '',      PBLK: '',      BLK: 'NT121', L: '13.8', B: '0.5',  H: '4.1',  YARD: 'HHI',   SC_WF: 16,     FB_WF: 24.3,    AS_WF: 0,       PE1_WF: 0,       PT_WF: 0,       PE2_WF: 0,       ER_WF: 0,      SC_PS: '2025-04-17', SC_PF: '2025-05-09', SC_PP: 0,     SC_AS: undefined,    SC_AF: undefined,    SC_AP: 0,   FB_PS: '2025-05-12', FB_PF: '2025-07-02', FB_PP: 0,     FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: undefined,    AS_PF: undefined,    AS_PP: 0,    AS_AS: undefined,    AS_AF: undefined,    AS_AP: 0,    PE1_PS: undefined,    PE1_PF: undefined,    PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: undefined,    PT_PF: undefined,    PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: undefined,    PE2_PF: undefined,    PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: undefined,    ER_PF: undefined,    ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'TOPSIDE', ZONE: 'NORTH',          MOD: 'Production Deck',     LEVEL: 'Production Deck',     NAME: 'Deck Block',             G_EREC: 'ND120', G_2PE: 'ND120', G_1PE: 'ND130', EREC: '',      PE2: '',      PE1: '',      PBLK: 'ND130', BLK: 'ND131', L: '17.6', B: '6.7',  H: '1.1',  YARD: 'HHI',   SC_WF: 91.12,  FB_WF: 182.23,  AS_WF: 273.35,  PE1_WF: 464.7,   PT_WF: 2209.06, PE2_WF: 464.7,   ER_WF: 0,      SC_PS: '2025-03-24', SC_PF: '2025-04-08', SC_PP: 0,     SC_AS: undefined,    SC_AF: undefined,    SC_AP: 0,   FB_PS: '2025-04-09', FB_PF: '2025-05-09', FB_PP: 0,     FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: '2025-05-12', AS_PF: '2025-06-27', AS_PP: 0,    AS_AS: undefined,    AS_AF: undefined,    AS_AP: 0,    PE1_PS: '2025-06-30', PE1_PF: '2025-07-22', PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: '2025-07-23', PT_PF: '2025-08-27', PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: '2025-08-28', PE2_PF: '2025-09-24', PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: undefined,    ER_PF: undefined,    ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'TOPSIDE', ZONE: 'NORTH',          MOD: 'Production Deck',     LEVEL: 'Production Deck',     NAME: 'Column & Brace',         G_EREC: 'ND120', G_2PE: 'ND120', G_1PE: 'ND140', EREC: '',      PE2: '',      PE1: '',      PBLK: '',      BLK: 'NC141', L: '22.8', B: '21',   H: '15.9', YARD: 'HHI',   SC_WF: 140.5,  FB_WF: 281.01,  AS_WF: 421.51,  PE1_WF: 702.51,  PT_WF: 529.9,   PE2_WF: 0,       ER_WF: 0,      SC_PS: '2025-03-14', SC_PF: '2025-04-01', SC_PP: 0,     SC_AS: undefined,    SC_AF: undefined,    SC_AP: 0,   FB_PS: '2025-04-02', FB_PF: '2025-04-17', FB_PP: 0,     FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: '2025-04-18', AS_PF: '2025-05-15', AS_PP: 0,    AS_AS: undefined,    AS_AF: undefined,    AS_AP: 0,    PE1_PS: '2025-06-18', PE1_PF: '2025-07-15', PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: undefined,    PT_PF: undefined,    PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: undefined,    PE2_PF: undefined,    PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: undefined,    ER_PF: undefined,    ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'TOPSIDE', ZONE: 'NORTH',          MOD: 'Production Deck',     LEVEL: 'Production Deck',     NAME: 'Access P/F',             G_EREC: 'ND120', G_2PE: 'ND120', G_1PE: 'NV161', EREC: '',      PE2: '',      PE1: '',      PBLK: 'NV161', BLK: 'NV161', L: '5.2',  B: '1.2',  H: '2.5',  YARD: 'HHI',   SC_WF: 8,      FB_WF: 0,       AS_WF: 24,      PE1_WF: 0,       PT_WF: 24,      PE2_WF: 16,      ER_WF: 0,      SC_PS: '2025-05-02', SC_PF: '2025-05-23', SC_PP: 0,     SC_AS: undefined,    SC_AF: undefined,    SC_AP: 0,   FB_PS: undefined,    FB_PF: undefined,    FB_PP: 0,     FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: '2025-05-26', AS_PF: '2025-07-07', AS_PP: 0,    AS_AS: undefined,    AS_AF: undefined,    AS_AP: 0,    PE1_PS: undefined,    PE1_PF: undefined,    PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: '2025-08-08', PT_PF: '2025-09-01', PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: '2025-09-02', PE2_PF: '2025-09-24', PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: undefined,    ER_PF: undefined,    ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'TOPSIDE', ZONE: 'NORTH',          MOD: 'Production Deck',     LEVEL: 'Production Deck',     NAME: 'Access P/F',             G_EREC: 'ND120', G_2PE: 'ND120', G_1PE: 'NV171', EREC: '',      PE2: '',      PE1: '',      PBLK: 'NV171', BLK: 'NV171', L: '4.2',  B: '1.2',  H: '2.5',  YARD: 'HHI',   SC_WF: 8,      FB_WF: 0,       AS_WF: 24,      PE1_WF: 0,       PT_WF: 24,      PE2_WF: 16,      ER_WF: 0,      SC_PS: '2025-05-02', SC_PF: '2025-05-23', SC_PP: 0,     SC_AS: undefined,    SC_AF: undefined,    SC_AP: 0,   FB_PS: undefined,    FB_PF: undefined,    FB_PP: 0,     FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: '2025-05-26', AS_PF: '2025-07-07', AS_PP: 0,    AS_AS: undefined,    AS_AF: undefined,    AS_AP: 0,    PE1_PS: undefined,    PE1_PF: undefined,    PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: '2025-08-08', PT_PF: '2025-09-01', PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: '2025-09-02', PE2_PF: '2025-09-24', PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: undefined,    ER_PF: undefined,    ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'TOPSIDE', ZONE: 'NORTH',          MOD: 'Production Deck',     LEVEL: 'Production Deck',     NAME: 'Access P/F',             G_EREC: 'ND120', G_2PE: 'ND120', G_1PE: 'NV181', EREC: '',      PE2: '',      PE1: '',      PBLK: 'NV181', BLK: 'NV181', L: '2.3',  B: '2.4',  H: '2.8',  YARD: 'HHI',   SC_WF: 8,      FB_WF: 0,       AS_WF: 24,      PE1_WF: 0,       PT_WF: 24,      PE2_WF: 16,      ER_WF: 0,      SC_PS: '2025-05-02', SC_PF: '2025-05-23', SC_PP: 0,     SC_AS: undefined,    SC_AF: undefined,    SC_AP: 0,   FB_PS: undefined,    FB_PF: undefined,    FB_PP: 0,     FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: '2025-05-26', AS_PF: '2025-07-07', AS_PP: 0,    AS_AS: undefined,    AS_AF: undefined,    AS_AP: 0,    PE1_PS: undefined,    PE1_PF: undefined,    PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: '2025-08-08', PT_PF: '2025-09-01', PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: '2025-09-02', PE2_PF: '2025-09-24', PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: undefined,    ER_PF: undefined,    ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'TOPSIDE', ZONE: 'NORTH',          MOD: 'Production Deck',     LEVEL: 'Production Deck',     NAME: 'Access P/F',             G_EREC: 'ND120', G_2PE: 'ND120', G_1PE: 'NV191', EREC: '',      PE2: '',      PE1: '',      PBLK: 'NV191', BLK: 'NV191', L: '2.3',  B: '2.7',  H: '1.3',  YARD: 'HHI',   SC_WF: 8,      FB_WF: 0,       AS_WF: 24,      PE1_WF: 0,       PT_WF: 24,      PE2_WF: 16,      ER_WF: 0,      SC_PS: '2025-05-02', SC_PF: '2025-05-23', SC_PP: 0,     SC_AS: undefined,    SC_AF: undefined,    SC_AP: 0,   FB_PS: undefined,    FB_PF: undefined,    FB_PP: 0,     FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: '2025-05-26', AS_PF: '2025-07-07', AS_PP: 0,    AS_AS: undefined,    AS_AF: undefined,    AS_AP: 0,    PE1_PS: undefined,    PE1_PF: undefined,    PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: '2025-08-08', PT_PF: '2025-09-01', PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: '2025-09-02', PE2_PF: '2025-09-24', PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: undefined,    ER_PF: undefined,    ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'TOPSIDE', ZONE: 'NORTH',          MOD: 'Production Deck',     LEVEL: 'Production Deck',     NAME: 'Access P/F',             G_EREC: 'ND120', G_2PE: 'ND120', G_1PE: 'NV1A1', EREC: '',      PE2: '',      PE1: '',      PBLK: '',      BLK: 'NV1A1', L: '2.5',  B: '2.4',  H: '1.2',  YARD: 'HHI',   SC_WF: 8,      FB_WF: 0,       AS_WF: 24,      PE1_WF: 0,       PT_WF: 24,      PE2_WF: 16,      ER_WF: 0,      SC_PS: '2025-05-02', SC_PF: '2025-05-23', SC_PP: 0,     SC_AS: undefined,    SC_AF: undefined,    SC_AP: 0,   FB_PS: undefined,    FB_PF: undefined,    FB_PP: 0,     FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: '2025-05-26', AS_PF: '2025-07-09', AS_PP: 0,    AS_AS: undefined,    AS_AF: undefined,    AS_AP: 0,    PE1_PS: undefined,    PE1_PF: undefined,    PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: '2025-08-08', PT_PF: '2025-09-03', PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: '2025-09-04', PE2_PF: '2025-09-24', PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: undefined,    ER_PF: undefined,    ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'TOPSIDE', ZONE: 'NORTH',          MOD: 'Production Deck',     LEVEL: 'Production Deck',     NAME: 'Access P/F',             G_EREC: 'ND120', G_2PE: 'ND120', G_1PE: 'NV1B1', EREC: '',      PE2: '',      PE1: '',      PBLK: '',      BLK: 'NV1B1', L: '1.4',  B: '3.6',  H: '1.1',  YARD: 'HHI',   SC_WF: 8,      FB_WF: 0,       AS_WF: 24,      PE1_WF: 0,       PT_WF: 23.53,   PE2_WF: 16,      ER_WF: 0,      SC_PS: '2025-05-02', SC_PF: '2025-05-23', SC_PP: 0,     SC_AS: undefined,    SC_AF: undefined,    SC_AP: 0,   FB_PS: undefined,    FB_PF: undefined,    FB_PP: 0,     FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: '2025-05-26', AS_PF: '2025-07-09', AS_PP: 0,    AS_AS: undefined,    AS_AF: undefined,    AS_AP: 0,    PE1_PS: undefined,    PE1_PF: undefined,    PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: '2025-08-08', PT_PF: '2025-09-03', PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: '2025-09-04', PE2_PF: '2025-09-24', PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: undefined,    ER_PF: undefined,    ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'TOPSIDE', ZONE: 'NORTH',          MOD: 'Mezz. Deck',          LEVEL: 'Mezz. Deck',          NAME: 'Deck Block',             G_EREC: 'ND210', G_2PE: 'ND210', G_1PE: 'ND211', EREC: 'ND210', PE2: '',      PE1: '',      PBLK: 'ND211', BLK: 'ND211', L: '9.4',  B: '9.8',  H: '0.7',  YARD: 'HHI',   SC_WF: 23.69,  FB_WF: 47.38,   AS_WF: 71.07,   PE1_WF: 0,       PT_WF: 377.31,  PE2_WF: 47.38,   ER_WF: 5822.9, SC_PS: '2025-04-16', SC_PF: '2025-04-24', SC_PP: 0,     SC_AS: undefined,    SC_AF: undefined,    SC_AP: 0,   FB_PS: '2025-04-25', FB_PF: '2025-05-22', FB_PP: 0,     FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: '2025-05-23', AS_PF: '2025-07-01', AS_PP: 0,    AS_AS: undefined,    AS_AF: undefined,    AS_AP: 0,    PE1_PS: undefined,    PE1_PF: undefined,    PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: '2025-08-13', PT_PF: '2025-09-05', PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: '2025-09-08', PE2_PF: '2025-10-10', PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: '2025-10-15', ER_PF: '2026-04-08', ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'TOPSIDE', ZONE: 'NORTH',          MOD: 'Mezz. Deck',          LEVEL: 'Mezz. Deck',          NAME: 'Deck Block',             G_EREC: 'ND220', G_2PE: 'ND220', G_1PE: 'ND221', EREC: 'ND220', PE2: 'ND220', PE1: '',      PBLK: 'ND221', BLK: 'ND221', L: '14.6', B: '10.7', H: '0.7',  YARD: 'HHI',   SC_WF: 27.34,  FB_WF: 54.67,   AS_WF: 82.01,   PE1_WF: 0,       PT_WF: 584.21,  PE2_WF: 54.67,   ER_WF: 5822.9, SC_PS: '2025-03-14', SC_PF: '2025-04-02', SC_PP: 0,     SC_AS: undefined,    SC_AF: undefined,    SC_AP: 0,   FB_PS: '2025-04-03', FB_PF: '2025-04-30', FB_PP: 0,     FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: '2025-05-02', AS_PF: '2025-06-23', AS_PP: 0,    AS_AS: undefined,    AS_AF: undefined,    AS_AP: 0,    PE1_PS: undefined,    PE1_PF: undefined,    PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: '2025-07-24', PT_PF: '2025-08-28', PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: '2025-08-29', PE2_PF: '2025-10-02', PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: '2025-10-14', ER_PF: '2026-04-08', ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'TOPSIDE', ZONE: 'NORTH',          MOD: 'Mezz. Deck',          LEVEL: 'Mezz. Deck',          NAME: 'Access P/F',             G_EREC: 'ND220', G_2PE: 'ND220', G_1PE: 'NV211', EREC: '',      PE2: '',      PE1: '',      PBLK: '',      BLK: 'NV211', L: '8.8',  B: '5.4',  H: '2.4',  YARD: 'HHI',   SC_WF: 0,      FB_WF: 0,       AS_WF: 0,       PE1_WF: 0,       PT_WF: 0,       PE2_WF: 0,       ER_WF: 0,      SC_PS: undefined,    SC_PF: undefined,    SC_PP: 0,     SC_AS: undefined,    SC_AF: undefined,    SC_AP: 0,   FB_PS: undefined,    FB_PF: undefined,    FB_PP: 0,     FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: undefined,    AS_PF: undefined,    AS_PP: 0,    AS_AS: undefined,    AS_AF: undefined,    AS_AP: 0,    PE1_PS: undefined,    PE1_PF: undefined,    PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: undefined,    PT_PF: undefined,    PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: undefined,    PE2_PF: undefined,    PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: undefined,    ER_PF: undefined,    ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'TOPSIDE', ZONE: 'NORTH',          MOD: 'Mezz. Deck',          LEVEL: 'Mezz. Deck',          NAME: 'Deck Block',             G_EREC: 'ND230', G_2PE: 'ND230', G_1PE: 'ND231', EREC: 'ND230', PE2: 'ND230', PE1: '',      PBLK: 'ND231', BLK: 'ND231', L: '18.3', B: '9.9',  H: '1.2',  YARD: 'HHI',   SC_WF: 29.16,  FB_WF: 58.32,   AS_WF: 87.47,   PE1_WF: 0,       PT_WF: 659.1,   PE2_WF: 145.79,  ER_WF: 5822.9, SC_PS: '2025-03-19', SC_PF: '2025-04-07', SC_PP: 0,     SC_AS: undefined,    SC_AF: undefined,    SC_AP: 0,   FB_PS: '2025-04-08', FB_PF: '2025-05-08', FB_PP: 0,     FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: '2025-05-09', AS_PF: '2025-06-26', AS_PP: 0,    AS_AS: undefined,    AS_AF: undefined,    AS_AP: 0,    PE1_PS: undefined,    PE1_PF: undefined,    PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: '2025-08-08', PT_PF: '2025-09-02', PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: '2025-09-03', PE2_PF: '2025-10-16', PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: '2025-10-17', ER_PF: '2026-04-08', ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'TOPSIDE', ZONE: 'NORTH',          MOD: 'Mezz. Deck',          LEVEL: 'Mezz. Deck',          NAME: 'Deck Block',             G_EREC: 'ND230', G_2PE: 'ND230', G_1PE: 'ND240', EREC: '',      PE2: '',      PE1: 'ND240', PBLK: 'ND240', BLK: 'ND241', L: '15.7', B: '7.7',  H: '0.7',  YARD: 'HHI',   SC_WF: 24.3,   FB_WF: 36.45,   AS_WF: 54.67,   PE1_WF: 173.12,  PT_WF: 1045.1,  PE2_WF: 173.12,  ER_WF: 0,      SC_PS: '2025-04-18', SC_PF: '2025-04-28', SC_PP: 0,     SC_AS: undefined,    SC_AF: undefined,    SC_AP: 0,   FB_PS: '2025-04-29', FB_PF: '2025-05-26', FB_PP: 0,     FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: '2025-05-27', AS_PF: '2025-07-03', AS_PP: 0,    AS_AS: undefined,    AS_AF: undefined,    AS_AP: 0,    PE1_PS: '2025-07-04', PE1_PF: '2025-07-25', PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: '2025-08-08', PT_PF: '2025-09-02', PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: '2025-09-03', PE2_PF: '2025-10-16', PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: undefined,    ER_PF: undefined,    ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'TOPSIDE', ZONE: 'NORTH',          MOD: 'Mezz. Deck',          LEVEL: 'Mezz. Deck',          NAME: 'Deck Block',             G_EREC: 'ND230', G_2PE: 'ND230', G_1PE: 'ND240', EREC: '',      PE2: '',      PE1: '',      PBLK: '',      BLK: 'ND242', L: '14.7', B: '8.5',  H: '0.7',  YARD: 'HHI',   SC_WF: 16.4,   FB_WF: 32.8,    AS_WF: 49.2,    PE1_WF: 0,       PT_WF: 0,       PE2_WF: 0,       ER_WF: 0,      SC_PS: '2025-04-18', SC_PF: '2025-04-28', SC_PP: 0,     SC_AS: undefined,    SC_AF: undefined,    SC_AP: 0,   FB_PS: '2025-04-29', FB_PF: '2025-05-26', FB_PP: 0,     FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: '2025-05-27', AS_PF: '2025-07-03', AS_PP: 0,    AS_AS: undefined,    AS_AF: undefined,    AS_AP: 0,    PE1_PS: undefined,    PE1_PF: undefined,    PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: undefined,    PT_PF: undefined,    PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: undefined,    PE2_PF: undefined,    PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: undefined,    ER_PF: undefined,    ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'TOPSIDE', ZONE: 'NORTH',          MOD: 'Mezz. Deck',          LEVEL: 'Mezz. Deck',          NAME: 'Access P/F',             G_EREC: 'ND230', G_2PE: 'ND230', G_1PE: 'NV221', EREC: '',      PE2: '',      PE1: '',      PBLK: 'NV221', BLK: 'NV221', L: '4.2',  B: '1.9',  H: '2.4',  YARD: 'HHI',   SC_WF: 0,      FB_WF: 0,       AS_WF: 0,       PE1_WF: 0,       PT_WF: 0,       PE2_WF: 0,       ER_WF: 0,      SC_PS: undefined,    SC_PF: undefined,    SC_PP: 0,     SC_AS: undefined,    SC_AF: undefined,    SC_AP: 0,   FB_PS: undefined,    FB_PF: undefined,    FB_PP: 0,     FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: undefined,    AS_PF: undefined,    AS_PP: 0,    AS_AS: undefined,    AS_AF: undefined,    AS_AP: 0,    PE1_PS: undefined,    PE1_PF: undefined,    PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: undefined,    PT_PF: undefined,    PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: undefined,    PE2_PF: undefined,    PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: undefined,    ER_PF: undefined,    ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'TOPSIDE', ZONE: 'NORTH',          MOD: 'Mezz. Deck',          LEVEL: 'Mezz. Deck',          NAME: 'Access P/F',             G_EREC: 'ND230', G_2PE: 'ND230', G_1PE: 'NV231', EREC: '',      PE2: '',      PE1: '',      PBLK: 'NV231', BLK: 'NV231', L: '10.5', B: '2',    H: '2.4',  YARD: 'HHI',   SC_WF: 0,      FB_WF: 0,       AS_WF: 0,       PE1_WF: 0,       PT_WF: 0,       PE2_WF: 0,       ER_WF: 0,      SC_PS: undefined,    SC_PF: undefined,    SC_PP: 0,     SC_AS: undefined,    SC_AF: undefined,    SC_AP: 0,   FB_PS: undefined,    FB_PF: undefined,    FB_PP: 0,     FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: undefined,    AS_PF: undefined,    AS_PP: 0,    AS_AS: undefined,    AS_AF: undefined,    AS_AP: 0,    PE1_PS: undefined,    PE1_PF: undefined,    PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: undefined,    PT_PF: undefined,    PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: undefined,    PE2_PF: undefined,    PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: undefined,    ER_PF: undefined,    ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'TOPSIDE', ZONE: 'NORTH',          MOD: 'Mezz. Deck',          LEVEL: 'Mezz. Deck',          NAME: 'Deck Block',             G_EREC: 'ND250', G_2PE: 'ND250', G_1PE: 'ND251', EREC: 'ND250', PE2: '',      PE1: '',      PBLK: 'ND251', BLK: 'ND251', L: '11.4', B: '3.4',  H: '0.7',  YARD: 'HHI',   SC_WF: 8,      FB_WF: 16,      AS_WF: 24,      PE1_WF: 0,       PT_WF: 155.74,  PE2_WF: 16,      ER_WF: 5822.9, SC_PS: '2025-06-02', SC_PF: '2025-06-11', SC_PP: 0,     SC_AS: undefined,    SC_AF: undefined,    SC_AP: 0,   FB_PS: '2025-06-12', FB_PF: '2025-07-04', FB_PP: 0,     FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: '2025-07-07', AS_PF: '2025-08-18', AS_PP: 0,    AS_AS: undefined,    AS_AF: undefined,    AS_AP: 0,    PE1_PS: undefined,    PE1_PF: undefined,    PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: '2025-09-04', PT_PF: '2025-09-26', PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: '2025-09-29', PE2_PF: '2025-10-24', PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: '2025-10-29', ER_PF: '2026-04-08', ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'TOPSIDE', ZONE: 'NORTH',          MOD: 'Main Deck',           LEVEL: 'Main Deck',           NAME: 'Deck Block',             G_EREC: 'ND310', G_2PE: 'ND310', G_1PE: 'ND311', EREC: 'ND310', PE2: 'ND310', PE1: '',      PBLK: 'ND311', BLK: 'ND311', L: '15',   B: '21.3', H: '1.5',  YARD: 'HHI',   SC_WF: 222.33, FB_WF: 444.65,  AS_WF: 666.98,  PE1_WF: 1111.63, PT_WF: 1225.68, PE2_WF: 1111.63, ER_WF: 5822.9, SC_PS: '2025-06-13', SC_PF: '2025-07-02', SC_PP: 0,     SC_AS: undefined,    SC_AF: undefined,    SC_AP: 0,   FB_PS: '2025-07-03', FB_PF: '2025-08-11', FB_PP: 0,     FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: '2025-08-12', AS_PF: '2025-09-29', AS_PP: 0,    AS_AS: undefined,    AS_AF: undefined,    AS_AP: 0,    PE1_PS: '2025-09-30', PE1_PF: '2025-11-05', PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: '2025-11-06', PT_PF: '2025-11-28', PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: '2025-12-01', PE2_PF: '2025-12-31', PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: '2026-01-07', ER_PF: '2026-04-08', ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'TOPSIDE', ZONE: 'NORTH',          MOD: 'Main Deck',           LEVEL: 'Main Deck',           NAME: 'Deck Block',             G_EREC: 'ND310', G_2PE: 'ND310', G_1PE: 'ND320', EREC: '',      PE2: '',      PE1: 'ND320', PBLK: 'ND320', BLK: 'ND321', L: '18.4', B: '12.2', H: '1.5',  YARD: 'HHI',   SC_WF: 154.9,  FB_WF: 309.8,   AS_WF: 464.7,   PE1_WF: 3043.32, PT_WF: 3143.05, PE2_WF: 3043.32, ER_WF: 0,      SC_PS: '2025-06-11', SC_PF: '2025-06-30', SC_PP: 0,     SC_AS: undefined,    SC_AF: undefined,    SC_AP: 0,   FB_PS: '2025-07-01', FB_PF: '2025-08-08', FB_PP: 0,     FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: '2025-08-11', AS_PF: '2025-09-25', AS_PP: 0,    AS_AS: undefined,    AS_AF: undefined,    AS_AP: 0,    PE1_PS: '2025-09-26', PE1_PF: '2025-11-03', PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: '2025-11-04', PT_PF: '2025-11-26', PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: '2025-11-27', PE2_PF: '2025-12-31', PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: undefined,    ER_PF: undefined,    ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'TOPSIDE', ZONE: 'NORTH',          MOD: 'Main Deck',           LEVEL: 'Main Deck',           NAME: 'Deck Block',             G_EREC: 'ND310', G_2PE: 'ND310', G_1PE: 'ND320', EREC: '',      PE2: '',      PE1: '',      PBLK: '',      BLK: 'ND322', L: '18.4', B: '13.1', H: '1.5',  YARD: 'HHI',   SC_WF: 164.01, FB_WF: 328.02,  AS_WF: 492.03,  PE1_WF: 0,       PT_WF: 0,       PE2_WF: 0,       ER_WF: 0,      SC_PS: '2025-06-11', SC_PF: '2025-06-30', SC_PP: 0,     SC_AS: undefined,    SC_AF: undefined,    SC_AP: 0,   FB_PS: '2025-07-01', FB_PF: '2025-08-08', FB_PP: 0,     FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: '2025-08-11', AS_PF: '2025-09-25', AS_PP: 0,    AS_AS: undefined,    AS_AF: undefined,    AS_AP: 0,    PE1_PS: undefined,    PE1_PF: undefined,    PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: undefined,    PT_PF: undefined,    PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: undefined,    PE2_PF: undefined,    PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: undefined,    ER_PF: undefined,    ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'TOPSIDE', ZONE: 'NORTH',          MOD: 'Main Deck',           LEVEL: 'Main Deck',           NAME: 'Deck Block',             G_EREC: 'ND310', G_2PE: 'ND310', G_1PE: 'ND320', EREC: '',      PE2: '',      PE1: '',      PBLK: '',      BLK: 'ND323', L: '16.1', B: '22',   H: '1.5',  YARD: 'HHI',   SC_WF: 253.31, FB_WF: 506.61,  AS_WF: 759.92,  PE1_WF: 0,       PT_WF: 0,       PE2_WF: 0,       ER_WF: 0,      SC_PS: '2025-06-12', SC_PF: '2025-07-01', SC_PP: 0,     SC_AS: undefined,    SC_AF: undefined,    SC_AP: 0,   FB_PS: '2025-07-02', FB_PF: '2025-08-11', FB_PP: 0,     FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: '2025-08-12', AS_PF: '2025-09-25', AS_PP: 0,    AS_AS: undefined,    AS_AF: undefined,    AS_AP: 0,    PE1_PS: undefined,    PE1_PF: undefined,    PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: undefined,    PT_PF: undefined,    PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: undefined,    PE2_PF: undefined,    PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: undefined,    ER_PF: undefined,    ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'TOPSIDE', ZONE: 'NORTH',          MOD: 'Main Deck',           LEVEL: 'Main Deck',           NAME: 'Turnnion',               G_EREC: 'ND310', G_2PE: 'ND310', G_1PE: 'ND320', EREC: '',      PE2: '',      PE1: '',      PBLK: '',      BLK: 'NT311', L: '1.9',  B: '1.9',  H: '1.8',  YARD: 'HHI',   SC_WF: 16,     FB_WF: 24.3,    AS_WF: 0,       PE1_WF: 0,       PT_WF: 0,       PE2_WF: 0,       ER_WF: 0,      SC_PS: '2025-07-11', SC_PF: '2025-08-11', SC_PP: 0,     SC_AS: undefined,    SC_AF: undefined,    SC_AP: 0,   FB_PS: '2025-08-12', FB_PF: '2025-10-08', FB_PP: 0,     FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: undefined,    AS_PF: undefined,    AS_PP: 0,    AS_AS: undefined,    AS_AF: undefined,    AS_AP: 0,    PE1_PS: undefined,    PE1_PF: undefined,    PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: undefined,    PT_PF: undefined,    PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: undefined,    PE2_PF: undefined,    PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: undefined,    ER_PF: undefined,    ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'TOPSIDE', ZONE: 'NORTH',          MOD: 'Main Deck',           LEVEL: 'Main Deck',           NAME: 'Turnnion',               G_EREC: 'ND310', G_2PE: 'ND310', G_1PE: 'ND320', EREC: '',      PE2: '',      PE1: '',      PBLK: '',      BLK: 'NT321', L: '1.9',  B: '1.9',  H: '1.8',  YARD: 'HHI',   SC_WF: 16,     FB_WF: 24.3,    AS_WF: 0,       PE1_WF: 0,       PT_WF: 0,       PE2_WF: 0,       ER_WF: 0,      SC_PS: '2025-07-11', SC_PF: '2025-08-11', SC_PP: 0,     SC_AS: undefined,    SC_AF: undefined,    SC_AP: 0,   FB_PS: '2025-08-12', FB_PF: '2025-10-08', FB_PP: 0,     FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: undefined,    AS_PF: undefined,    AS_PP: 0,    AS_AS: undefined,    AS_AF: undefined,    AS_AP: 0,    PE1_PS: undefined,    PE1_PF: undefined,    PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: undefined,    PT_PF: undefined,    PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: undefined,    PE2_PF: undefined,    PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: undefined,    ER_PF: undefined,    ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'TOPSIDE', ZONE: 'NORTH',          MOD: 'Main Deck',           LEVEL: 'Main Deck',           NAME: 'Turnnion',               G_EREC: 'ND310', G_2PE: 'ND310', G_1PE: 'ND320', EREC: '',      PE2: '',      PE1: '',      PBLK: '',      BLK: 'NT331', L: '1.4',  B: '1.7',  H: '1.8',  YARD: 'HHI',   SC_WF: 16,     FB_WF: 24.3,    AS_WF: 0,       PE1_WF: 0,       PT_WF: 0,       PE2_WF: 0,       ER_WF: 0,      SC_PS: '2025-07-11', SC_PF: '2025-08-11', SC_PP: 0,     SC_AS: undefined,    SC_AF: undefined,    SC_AP: 0,   FB_PS: '2025-08-12', FB_PF: '2025-10-08', FB_PP: 0,     FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: undefined,    AS_PF: undefined,    AS_PP: 0,    AS_AS: undefined,    AS_AF: undefined,    AS_AP: 0,    PE1_PS: undefined,    PE1_PF: undefined,    PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: undefined,    PT_PF: undefined,    PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: undefined,    PE2_PF: undefined,    PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: undefined,    ER_PF: undefined,    ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'TOPSIDE', ZONE: 'NORTH',          MOD: 'Main Deck',           LEVEL: 'Main Deck',           NAME: 'Turnnion',               G_EREC: 'ND310', G_2PE: 'ND310', G_1PE: 'ND320', EREC: '',      PE2: '',      PE1: '',      PBLK: '',      BLK: 'NT341', L: '1.4',  B: '1.5',  H: '1.8',  YARD: 'HHI',   SC_WF: 16,     FB_WF: 24.3,    AS_WF: 0,       PE1_WF: 0,       PT_WF: 0,       PE2_WF: 0,       ER_WF: 0,      SC_PS: '2025-07-11', SC_PF: '2025-08-11', SC_PP: 0,     SC_AS: undefined,    SC_AF: undefined,    SC_AP: 0,   FB_PS: '2025-08-12', FB_PF: '2025-10-08', FB_PP: 0,     FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: undefined,    AS_PF: undefined,    AS_PP: 0,    AS_AS: undefined,    AS_AF: undefined,    AS_AP: 0,    PE1_PS: undefined,    PE1_PF: undefined,    PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: undefined,    PT_PF: undefined,    PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: undefined,    PE2_PF: undefined,    PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: undefined,    ER_PF: undefined,    ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'TOPSIDE', ZONE: 'NORTH',          MOD: 'Main Deck',           LEVEL: 'Main Deck',           NAME: 'Access P/F',             G_EREC: 'ND310', G_2PE: 'ND310', G_1PE: 'NV311', EREC: '',      PE2: '',      PE1: '',      PBLK: 'NV311', BLK: 'NV311', L: '3.4',  B: '9.9',  H: '2.8',  YARD: 'HHI',   SC_WF: 8,      FB_WF: 0,       AS_WF: 24,      PE1_WF: 0,       PT_WF: 93.89,   PE2_WF: 16,      ER_WF: 0,      SC_PS: '2025-08-18', SC_PF: '2025-09-04', SC_PP: 0,     SC_AS: undefined,    SC_AF: undefined,    SC_AP: 0,   FB_PS: undefined,    FB_PF: undefined,    FB_PP: 0,     FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: '2025-09-05', AS_PF: '2025-10-27', AS_PP: 0,    AS_AS: undefined,    AS_AF: undefined,    AS_AP: 0,    PE1_PS: undefined,    PE1_PF: undefined,    PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: '2025-11-17', PT_PF: '2025-12-09', PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: '2025-12-10', PE2_PF: '2025-12-31', PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: undefined,    ER_PF: undefined,    ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'TOPSIDE', ZONE: 'NORTH',          MOD: 'Main Deck',           LEVEL: 'Main Deck',           NAME: 'Access P/F',             G_EREC: 'ND310', G_2PE: 'ND310', G_1PE: 'NV321', EREC: '',      PE2: '',      PE1: '',      PBLK: 'NV321', BLK: 'NV321', L: '3.4',  B: '7.7',  H: '2.8',  YARD: 'HHI',   SC_WF: 8,      FB_WF: 0,       AS_WF: 24,      PE1_WF: 0,       PT_WF: 81.6,    PE2_WF: 16,      ER_WF: 0,      SC_PS: '2025-08-18', SC_PF: '2025-09-04', SC_PP: 0,     SC_AS: undefined,    SC_AF: undefined,    SC_AP: 0,   FB_PS: undefined,    FB_PF: undefined,    FB_PP: 0,     FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: '2025-09-05', AS_PF: '2025-10-23', AS_PP: 0,    AS_AS: undefined,    AS_AF: undefined,    AS_AP: 0,    PE1_PS: undefined,    PE1_PF: undefined,    PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: '2025-11-13', PT_PF: '2025-12-05', PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: '2025-12-08', PE2_PF: '2025-12-29', PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: undefined,    ER_PF: undefined,    ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'TOPSIDE', ZONE: 'NORTH',          MOD: 'Main Deck',           LEVEL: 'Main Deck',           NAME: 'Access P/F',             G_EREC: 'ND310', G_2PE: 'ND310', G_1PE: 'NV331', EREC: '',      PE2: '',      PE1: '',      PBLK: 'NV331', BLK: 'NV331', L: '2.4',  B: '3.8',  H: '0.9',  YARD: 'HHI',   SC_WF: 8,      FB_WF: 0,       AS_WF: 24,      PE1_WF: 0,       PT_WF: 33.12,   PE2_WF: 16,      ER_WF: 0,      SC_PS: '2025-08-18', SC_PF: '2025-09-04', SC_PP: 0,     SC_AS: undefined,    SC_AF: undefined,    SC_AP: 0,   FB_PS: undefined,    FB_PF: undefined,    FB_PP: 0,     FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: '2025-09-05', AS_PF: '2025-10-23', AS_PP: 0,    AS_AS: undefined,    AS_AF: undefined,    AS_AP: 0,    PE1_PS: undefined,    PE1_PF: undefined,    PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: '2025-11-13', PT_PF: '2025-12-05', PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: '2025-12-08', PE2_PF: '2025-12-29', PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: undefined,    ER_PF: undefined,    ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'TOPSIDE', ZONE: 'NORTH',          MOD: 'Main Deck',           LEVEL: 'Main Deck',           NAME: 'Access P/F',             G_EREC: 'ND310', G_2PE: 'ND310', G_1PE: 'NV341', EREC: '',      PE2: '',      PE1: '',      PBLK: 'NV341', BLK: 'NV341', L: '2.5',  B: '3.8',  H: '0.9',  YARD: 'HHI',   SC_WF: 8,      FB_WF: 0,       AS_WF: 24,      PE1_WF: 0,       PT_WF: 33.12,   PE2_WF: 16,      ER_WF: 0,      SC_PS: '2025-08-18', SC_PF: '2025-09-04', SC_PP: 0,     SC_AS: undefined,    SC_AF: undefined,    SC_AP: 0,   FB_PS: undefined,    FB_PF: undefined,    FB_PP: 0,     FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: '2025-09-05', AS_PF: '2025-10-23', AS_PP: 0,    AS_AS: undefined,    AS_AF: undefined,    AS_AP: 0,    PE1_PS: undefined,    PE1_PF: undefined,    PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: '2025-11-13', PT_PF: '2025-12-05', PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: '2025-12-08', PE2_PF: '2025-12-29', PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: undefined,    ER_PF: undefined,    ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'TOPSIDE', ZONE: 'NORTH',          MOD: 'Main Deck',           LEVEL: 'Main Deck',           NAME: 'Access P/F',             G_EREC: 'ND310', G_2PE: 'ND310', G_1PE: 'NV3A1', EREC: '',      PE2: '',      PE1: '',      PBLK: 'NV3A1', BLK: 'NV3A1', L: '3.2',  B: '10',   H: '2.2',  YARD: 'HHI',   SC_WF: 8,      FB_WF: 0,       AS_WF: 24,      PE1_WF: 0,       PT_WF: 102.05,  PE2_WF: 32,      ER_WF: 0,      SC_PS: '2025-07-25', SC_PF: '2025-08-28', SC_PP: 0,     SC_AS: undefined,    SC_AF: undefined,    SC_AP: 0,   FB_PS: undefined,    FB_PF: undefined,    FB_PP: 0,     FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: '2025-08-29', AS_PF: '2025-10-23', AS_PP: 0,    AS_AS: undefined,    AS_AF: undefined,    AS_AP: 0,    PE1_PS: undefined,    PE1_PF: undefined,    PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: '2025-11-13', PT_PF: '2025-12-05', PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: '2025-12-08', PE2_PF: '2025-12-29', PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: undefined,    ER_PF: undefined,    ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'TOPSIDE', ZONE: 'NORTH',          MOD: 'Main Deck',           LEVEL: 'Main Deck',           NAME: 'Access P/F',             G_EREC: 'ND310', G_2PE: 'ND310', G_1PE: 'NV3B1', EREC: '',      PE2: '',      PE1: '',      PBLK: 'NV3B1', BLK: 'NV3B1', L: '1.6',  B: '7.9',  H: '2.2',  YARD: 'HHI',   SC_WF: 8,      FB_WF: 0,       AS_WF: 24,      PE1_WF: 0,       PT_WF: 49.26,   PE2_WF: 32,      ER_WF: 0,      SC_PS: '2025-07-25', SC_PF: '2025-08-28', SC_PP: 0,     SC_AS: undefined,    SC_AF: undefined,    SC_AP: 0,   FB_PS: undefined,    FB_PF: undefined,    FB_PP: 0,     FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: '2025-08-29', AS_PF: '2025-10-27', AS_PP: 0,    AS_AS: undefined,    AS_AF: undefined,    AS_AP: 0,    PE1_PS: undefined,    PE1_PF: undefined,    PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: '2025-11-17', PT_PF: '2025-12-09', PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: '2025-12-10', PE2_PF: '2025-12-31', PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: undefined,    ER_PF: undefined,    ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'TOPSIDE', ZONE: 'NORTH',          MOD: 'Main Deck',           LEVEL: 'Main Deck',           NAME: 'Access P/F',             G_EREC: 'ND310', G_2PE: 'ND310', G_1PE: 'NV3C1', EREC: '',      PE2: '',      PE1: '',      PBLK: 'NV3C1', BLK: 'NV3C1', L: '9.8',  B: '2.5',  H: '1.8',  YARD: 'HHI',   SC_WF: 8,      FB_WF: 0,       AS_WF: 24,      PE1_WF: 0,       PT_WF: 100.39,  PE2_WF: 32,      ER_WF: 0,      SC_PS: '2025-08-18', SC_PF: '2025-09-04', SC_PP: 0,     SC_AS: undefined,    SC_AF: undefined,    SC_AP: 0,   FB_PS: undefined,    FB_PF: undefined,    FB_PP: 0,     FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: '2025-09-05', AS_PF: '2025-10-27', AS_PP: 0,    AS_AS: undefined,    AS_AF: undefined,    AS_AP: 0,    PE1_PS: undefined,    PE1_PF: undefined,    PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: '2025-11-17', PT_PF: '2025-12-09', PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: '2025-12-10', PE2_PF: '2025-12-31', PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: undefined,    ER_PF: undefined,    ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'TOPSIDE', ZONE: 'NORTH',          MOD: 'Main Deck',           LEVEL: 'Main Deck',           NAME: 'Access P/F',             G_EREC: 'ND310', G_2PE: 'ND310', G_1PE: 'NV3D1', EREC: '',      PE2: '',      PE1: '',      PBLK: 'NV3D1', BLK: 'NV3D1', L: '9.6',  B: '7.3',  H: '3.2',  YARD: 'HHI',   SC_WF: 8,      FB_WF: 0,       AS_WF: 24,      PE1_WF: 0,       PT_WF: 98.32,   PE2_WF: 32,      ER_WF: 0,      SC_PS: '2025-08-18', SC_PF: '2025-09-04', SC_PP: 0,     SC_AS: undefined,    SC_AF: undefined,    SC_AP: 0,   FB_PS: undefined,    FB_PF: undefined,    FB_PP: 0,     FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: '2025-09-05', AS_PF: '2025-10-27', AS_PP: 0,    AS_AS: undefined,    AS_AF: undefined,    AS_AP: 0,    PE1_PS: undefined,    PE1_PF: undefined,    PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: '2025-11-17', PT_PF: '2025-12-09', PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: '2025-12-10', PE2_PF: '2025-12-31', PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: undefined,    ER_PF: undefined,    ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'TOPSIDE', ZONE: 'NORTH',          MOD: 'Main Deck',           LEVEL: 'Main Deck',           NAME: 'Access P/F',             G_EREC: 'ND310', G_2PE: 'ND310', G_1PE: 'NV3E1', EREC: '',      PE2: '',      PE1: '',      PBLK: 'NV3E1', BLK: 'NV3E1', L: '9.7',  B: '3.5',  H: '2.2',  YARD: 'HHI',   SC_WF: 8,      FB_WF: 0,       AS_WF: 24,      PE1_WF: 0,       PT_WF: 136.53,  PE2_WF: 32,      ER_WF: 0,      SC_PS: '2025-08-18', SC_PF: '2025-09-04', SC_PP: 0,     SC_AS: undefined,    SC_AF: undefined,    SC_AP: 0,   FB_PS: undefined,    FB_PF: undefined,    FB_PP: 0,     FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: '2025-09-05', AS_PF: '2025-10-27', AS_PP: 0,    AS_AS: undefined,    AS_AF: undefined,    AS_AP: 0,    PE1_PS: undefined,    PE1_PF: undefined,    PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: '2025-11-17', PT_PF: '2025-12-09', PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: '2025-12-10', PE2_PF: '2025-12-31', PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: undefined,    ER_PF: undefined,    ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'TOPSIDE', ZONE: 'NORTH',          MOD: 'Main Deck',           LEVEL: 'Main Deck',           NAME: 'Access P/F',             G_EREC: 'ND310', G_2PE: 'ND310', G_1PE: 'NV3F1', EREC: '',      PE2: '',      PE1: '',      PBLK: 'NV3F1', BLK: 'NV3F1', L: '9.6',  B: '8.3',  H: '2.2',  YARD: 'HHI',   SC_WF: 8,      FB_WF: 0,       AS_WF: 24,      PE1_WF: 0,       PT_WF: 133.72,  PE2_WF: 32,      ER_WF: 0,      SC_PS: '2025-08-18', SC_PF: '2025-09-04', SC_PP: 0,     SC_AS: undefined,    SC_AF: undefined,    SC_AP: 0,   FB_PS: undefined,    FB_PF: undefined,    FB_PP: 0,     FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: '2025-09-05', AS_PF: '2025-10-27', AS_PP: 0,    AS_AS: undefined,    AS_AF: undefined,    AS_AP: 0,    PE1_PS: undefined,    PE1_PF: undefined,    PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: '2025-11-17', PT_PF: '2025-12-09', PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: '2025-12-10', PE2_PF: '2025-12-31', PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: undefined,    ER_PF: undefined,    ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'TOPSIDE', ZONE: 'NORTH',          MOD: 'Main Deck',           LEVEL: 'Main Deck',           NAME: 'Access P/F',             G_EREC: 'ND310', G_2PE: 'ND310', G_1PE: 'NV3G1', EREC: '',      PE2: '',      PE1: '',      PBLK: 'NV3G1', BLK: 'NV3G1', L: '10',   B: '2.2',  H: '2.3',  YARD: 'HHI',   SC_WF: 8,      FB_WF: 0,       AS_WF: 24,      PE1_WF: 0,       PT_WF: 96.38,   PE2_WF: 32,      ER_WF: 0,      SC_PS: '2025-07-25', SC_PF: '2025-08-28', SC_PP: 0,     SC_AS: undefined,    SC_AF: undefined,    SC_AP: 0,   FB_PS: undefined,    FB_PF: undefined,    FB_PP: 0,     FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: '2025-08-29', AS_PF: '2025-10-27', AS_PP: 0,    AS_AS: undefined,    AS_AF: undefined,    AS_AP: 0,    PE1_PS: undefined,    PE1_PF: undefined,    PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: '2025-11-17', PT_PF: '2025-12-09', PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: '2025-12-10', PE2_PF: '2025-12-31', PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: undefined,    ER_PF: undefined,    ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'TOPSIDE', ZONE: 'NORTH',          MOD: 'Main Deck',           LEVEL: 'Main Deck',           NAME: 'Access P/F',             G_EREC: 'ND310', G_2PE: 'ND310', G_1PE: 'NV3H1', EREC: '',      PE2: '',      PE1: '',      PBLK: 'NV3H1', BLK: 'NV3H1', L: '7.1',  B: '3',    H: '2.2',  YARD: 'HHI',   SC_WF: 8,      FB_WF: 0,       AS_WF: 24,      PE1_WF: 0,       PT_WF: 94.39,   PE2_WF: 32,      ER_WF: 0,      SC_PS: '2025-07-25', SC_PF: '2025-08-28', SC_PP: 0,     SC_AS: undefined,    SC_AF: undefined,    SC_AP: 0,   FB_PS: undefined,    FB_PF: undefined,    FB_PP: 0,     FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: '2025-08-29', AS_PF: '2025-10-27', AS_PP: 0,    AS_AS: undefined,    AS_AF: undefined,    AS_AP: 0,    PE1_PS: undefined,    PE1_PF: undefined,    PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: '2025-11-17', PT_PF: '2025-12-09', PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: '2025-12-10', PE2_PF: '2025-12-31', PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: undefined,    ER_PF: undefined,    ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'TOPSIDE', ZONE: 'NORTH',          MOD: 'Main Deck',           LEVEL: 'Main Deck',           NAME: 'Access P/F',             G_EREC: 'ND310', G_2PE: 'ND310', G_1PE: 'NV3J1', EREC: '',      PE2: '',      PE1: '',      PBLK: 'NV3J1', BLK: 'NV3J1', L: '1.4',  B: '11.6', H: '1.7',  YARD: 'HHI',   SC_WF: 8,      FB_WF: 0,       AS_WF: 24,      PE1_WF: 0,       PT_WF: 49.06,   PE2_WF: 32,      ER_WF: 0,      SC_PS: '2025-07-25', SC_PF: '2025-08-28', SC_PP: 0,     SC_AS: undefined,    SC_AF: undefined,    SC_AP: 0,   FB_PS: undefined,    FB_PF: undefined,    FB_PP: 0,     FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: '2025-08-29', AS_PF: '2025-10-27', AS_PP: 0,    AS_AS: undefined,    AS_AF: undefined,    AS_AP: 0,    PE1_PS: undefined,    PE1_PF: undefined,    PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: '2025-11-17', PT_PF: '2025-12-09', PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: '2025-12-10', PE2_PF: '2025-12-31', PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: undefined,    ER_PF: undefined,    ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'TOPSIDE', ZONE: 'NORTH',          MOD: 'Main Deck',           LEVEL: 'Main Deck',           NAME: 'Access P/F',             G_EREC: 'ND310', G_2PE: 'ND310', G_1PE: 'NV3K1', EREC: '',      PE2: '',      PE1: '',      PBLK: 'NV3K1', BLK: 'NV3K1', L: '1.4',  B: '8.1',  H: '1.7',  YARD: 'HHI',   SC_WF: 8,      FB_WF: 0,       AS_WF: 24,      PE1_WF: 0,       PT_WF: 52.78,   PE2_WF: 32,      ER_WF: 0,      SC_PS: '2025-07-25', SC_PF: '2025-08-28', SC_PP: 0,     SC_AS: undefined,    SC_AF: undefined,    SC_AP: 0,   FB_PS: undefined,    FB_PF: undefined,    FB_PP: 0,     FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: '2025-08-29', AS_PF: '2025-10-27', AS_PP: 0,    AS_AS: undefined,    AS_AF: undefined,    AS_AP: 0,    PE1_PS: undefined,    PE1_PF: undefined,    PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: '2025-11-17', PT_PF: '2025-12-09', PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: '2025-12-10', PE2_PF: '2025-12-31', PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: undefined,    ER_PF: undefined,    ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'TOPSIDE', ZONE: 'NORTH',          MOD: 'Main Deck',           LEVEL: 'Main Deck',           NAME: 'Access P/F',             G_EREC: 'ND310', G_2PE: 'ND310', G_1PE: 'NV3Y1', EREC: '',      PE2: '',      PE1: '',      PBLK: 'NV3Y1', BLK: 'NV3Y1', L: '1.4',  B: '2.7',  H: '1.2',  YARD: 'HHI',   SC_WF: 0,      FB_WF: 0,       AS_WF: 0,       PE1_WF: 0,       PT_WF: 0,       PE2_WF: 0,       ER_WF: 0,      SC_PS: undefined,    SC_PF: undefined,    SC_PP: 0,     SC_AS: undefined,    SC_AF: undefined,    SC_AP: 0,   FB_PS: undefined,    FB_PF: undefined,    FB_PP: 0,     FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: undefined,    AS_PF: undefined,    AS_PP: 0,    AS_AS: undefined,    AS_AF: undefined,    AS_AP: 0,    PE1_PS: undefined,    PE1_PF: undefined,    PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: undefined,    PT_PF: undefined,    PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: undefined,    PE2_PF: undefined,    PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: undefined,    ER_PF: undefined,    ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'TOPSIDE', ZONE: 'NORTH',          MOD: 'Main Deck',           LEVEL: 'Main Deck',           NAME: 'Pipe Rack',              G_EREC: 'ND310', G_2PE: 'ND310', G_1PE: 'NP311', EREC: '',      PE2: '',      PE1: '',      PBLK: 'NP311', BLK: 'NP311', L: '5.6',  B: '9.3',  H: '3.4',  YARD: 'HHI',   SC_WF: 16,     FB_WF: 0,       AS_WF: 27.34,   PE1_WF: 0,       PT_WF: 141.58,  PE2_WF: 45.56,   ER_WF: 0,      SC_PS: '2025-07-15', SC_PF: '2025-08-13', SC_PP: 0,     SC_AS: undefined,    SC_AF: undefined,    SC_AP: 0,   FB_PS: undefined,    FB_PF: undefined,    FB_PP: 0,     FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: '2025-08-14', AS_PF: '2025-09-29', AS_PP: 0,    AS_AS: undefined,    AS_AF: undefined,    AS_AP: 0,    PE1_PS: undefined,    PE1_PF: undefined,    PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: '2025-10-27', PT_PF: '2025-11-18', PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: '2025-11-19', PE2_PF: '2025-12-31', PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: undefined,    ER_PF: undefined,    ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'TOPSIDE', ZONE: 'NORTH',          MOD: 'Main Deck',           LEVEL: 'Main Deck',           NAME: 'Pipe Rack',              G_EREC: 'ND310', G_2PE: 'ND310', G_1PE: 'NP321', EREC: '',      PE2: '',      PE1: '',      PBLK: 'NP321', BLK: 'NP321', L: '6.2',  B: '6.4',  H: '2.7',  YARD: 'HHI',   SC_WF: 16,     FB_WF: 0,       AS_WF: 27.34,   PE1_WF: 0,       PT_WF: 164.27,  PE2_WF: 45.56,   ER_WF: 0,      SC_PS: '2025-07-15', SC_PF: '2025-08-13', SC_PP: 0,     SC_AS: undefined,    SC_AF: undefined,    SC_AP: 0,   FB_PS: undefined,    FB_PF: undefined,    FB_PP: 0,     FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: '2025-08-14', AS_PF: '2025-09-29', AS_PP: 0,    AS_AS: undefined,    AS_AF: undefined,    AS_AP: 0,    PE1_PS: undefined,    PE1_PF: undefined,    PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: '2025-10-27', PT_PF: '2025-11-18', PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: '2025-11-19', PE2_PF: '2025-12-31', PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: undefined,    ER_PF: undefined,    ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'TOPSIDE', ZONE: 'NORTH',          MOD: 'Main Deck',           LEVEL: 'Main Deck',           NAME: 'Pipe Rack',              G_EREC: 'ND310', G_2PE: 'ND310', G_1PE: 'NP331', EREC: '',      PE2: '',      PE1: '',      PBLK: 'NP331', BLK: 'NP331', L: '5.7',  B: '1.6',  H: '2.7',  YARD: 'HHI',   SC_WF: 8,      FB_WF: 0,       AS_WF: 24,      PE1_WF: 0,       PT_WF: 37.76,   PE2_WF: 32,      ER_WF: 0,      SC_PS: '2025-07-15', SC_PF: '2025-08-13', SC_PP: 0,     SC_AS: undefined,    SC_AF: undefined,    SC_AP: 0,   FB_PS: undefined,    FB_PF: undefined,    FB_PP: 0,     FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: '2025-08-14', AS_PF: '2025-09-29', AS_PP: 0,    AS_AS: undefined,    AS_AF: undefined,    AS_AP: 0,    PE1_PS: undefined,    PE1_PF: undefined,    PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: '2025-10-27', PT_PF: '2025-11-18', PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: '2025-11-19', PE2_PF: '2025-12-31', PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: undefined,    ER_PF: undefined,    ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'TOPSIDE', ZONE: 'NORTH',          MOD: 'Main Deck',           LEVEL: 'Main Deck',           NAME: 'Pipe Rack',              G_EREC: 'ND310', G_2PE: 'ND310', G_1PE: 'NP341', EREC: '',      PE2: '',      PE1: '',      PBLK: 'NP341', BLK: 'NP341', L: '12.7', B: '9.6',  H: '3.4',  YARD: 'HHI',   SC_WF: 24.3,   FB_WF: 0,       AS_WF: 54.67,   PE1_WF: 0,       PT_WF: 420.61,  PE2_WF: 91.12,   ER_WF: 0,      SC_PS: '2025-07-15', SC_PF: '2025-08-13', SC_PP: 0,     SC_AS: undefined,    SC_AF: undefined,    SC_AP: 0,   FB_PS: undefined,    FB_PF: undefined,    FB_PP: 0,     FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: '2025-08-14', AS_PF: '2025-09-29', AS_PP: 0,    AS_AS: undefined,    AS_AF: undefined,    AS_AP: 0,    PE1_PS: undefined,    PE1_PF: undefined,    PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: '2025-10-27', PT_PF: '2025-11-18', PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: '2025-11-19', PE2_PF: '2025-12-31', PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: undefined,    ER_PF: undefined,    ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'TOPSIDE', ZONE: 'NORTH',          MOD: 'Main Deck',           LEVEL: 'Main Deck',           NAME: 'Pipe Rack',              G_EREC: 'ND310', G_2PE: 'ND310', G_1PE: 'NP351', EREC: '',      PE2: '',      PE1: '',      PBLK: 'NP351', BLK: 'NP351', L: '13.1', B: '6.2',  H: '3.4',  YARD: 'HHI',   SC_WF: 24.3,   FB_WF: 0,       AS_WF: 54.67,   PE1_WF: 0,       PT_WF: 320.71,  PE2_WF: 91.12,   ER_WF: 0,      SC_PS: '2025-07-15', SC_PF: '2025-08-13', SC_PP: 0,     SC_AS: undefined,    SC_AF: undefined,    SC_AP: 0,   FB_PS: undefined,    FB_PF: undefined,    FB_PP: 0,     FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: '2025-08-14', AS_PF: '2025-09-29', AS_PP: 0,    AS_AS: undefined,    AS_AF: undefined,    AS_AP: 0,    PE1_PS: undefined,    PE1_PF: undefined,    PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: '2025-10-27', PT_PF: '2025-11-18', PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: '2025-11-19', PE2_PF: '2025-12-31', PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: undefined,    ER_PF: undefined,    ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'TOPSIDE', ZONE: 'NORTH',          MOD: 'Main Deck',           LEVEL: 'Main Deck',           NAME: 'Pipe Rack',              G_EREC: 'ND310', G_2PE: 'ND310', G_1PE: 'NP361', EREC: '',      PE2: '',      PE1: '',      PBLK: 'NP361', BLK: 'NP361', L: '16.3', B: '20.2', H: '4.3',  YARD: 'HHI',   SC_WF: 45.56,  FB_WF: 0,       AS_WF: 136.68,  PE1_WF: 0,       PT_WF: 1137.3,  PE2_WF: 227.79,  ER_WF: 0,      SC_PS: '2025-07-08', SC_PF: '2025-07-24', SC_PP: 0,     SC_AS: undefined,    SC_AF: undefined,    SC_AP: 0,   FB_PS: undefined,    FB_PF: undefined,    FB_PP: 0,     FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: '2025-07-25', AS_PF: '2025-09-22', AS_PP: 0,    AS_AS: undefined,    AS_AF: undefined,    AS_AP: 0,    PE1_PS: undefined,    PE1_PF: undefined,    PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: '2025-10-20', PT_PF: '2025-11-11', PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: '2025-11-12', PE2_PF: '2025-12-23', PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: undefined,    ER_PF: undefined,    ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'TOPSIDE', ZONE: 'NORTH',          MOD: 'Main Deck',           LEVEL: 'Main Deck',           NAME: 'Deck Block',             G_EREC: 'ND330', G_2PE: 'ND330', G_1PE: 'ND330', EREC: 'ND330', PE2: 'ND330', PE1: 'ND330', PBLK: 'ND330', BLK: 'ND331', L: '14.3', B: '21.2', H: '1.5',  YARD: 'HHI',   SC_WF: 176.77, FB_WF: 353.54,  AS_WF: 530.3,   PE1_WF: 3125.33, PT_WF: 3407.92, PE2_WF: 3125.33, ER_WF: 5822.9, SC_PS: '2025-06-17', SC_PF: '2025-07-04', SC_PP: 0,     SC_AS: undefined,    SC_AF: undefined,    SC_AP: 0,   FB_PS: '2025-07-07', FB_PF: '2025-08-13', FB_PP: 0,     FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: '2025-08-14', AS_PF: '2025-10-01', AS_PP: 0,    AS_AS: undefined,    AS_AF: undefined,    AS_AP: 0,    PE1_PS: '2025-10-02', PE1_PF: '2025-11-07', PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: '2025-11-10', PT_PF: '2025-12-02', PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: '2025-12-03', PE2_PF: '2026-01-09', PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: '2026-01-12', ER_PF: '2026-04-08', ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'TOPSIDE', ZONE: 'NORTH',          MOD: 'Main Deck',           LEVEL: 'Main Deck',           NAME: 'Deck Block',             G_EREC: 'ND330', G_2PE: 'ND330', G_1PE: 'ND330', EREC: '',      PE2: '',      PE1: '',      PBLK: '',      BLK: 'ND332', L: '22.2', B: '13.1', H: '2.2',  YARD: 'HHI',   SC_WF: 207.75, FB_WF: 415.5,   AS_WF: 623.24,  PE1_WF: 0,       PT_WF: 0,       PE2_WF: 0,       ER_WF: 0,      SC_PS: '2025-06-17', SC_PF: '2025-07-04', SC_PP: 0,     SC_AS: undefined,    SC_AF: undefined,    SC_AP: 0,   FB_PS: '2025-07-07', FB_PF: '2025-08-13', FB_PP: 0,     FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: '2025-08-14', AS_PF: '2025-10-01', AS_PP: 0,    AS_AS: undefined,    AS_AF: undefined,    AS_AP: 0,    PE1_PS: undefined,    PE1_PF: undefined,    PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: undefined,    PT_PF: undefined,    PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: undefined,    PE2_PF: undefined,    PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: undefined,    ER_PF: undefined,    ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'TOPSIDE', ZONE: 'NORTH',          MOD: 'Main Deck',           LEVEL: 'Main Deck',           NAME: 'Deck Block',             G_EREC: 'ND330', G_2PE: 'ND330', G_1PE: 'ND330', EREC: '',      PE2: '',      PE1: '',      PBLK: '',      BLK: 'ND333', L: '22.7', B: '13.1', H: '1.5',  YARD: 'HHI',   SC_WF: 204.1,  FB_WF: 408.21,  AS_WF: 612.31,  PE1_WF: 0,       PT_WF: 0,       PE2_WF: 0,       ER_WF: 0,      SC_PS: '2025-06-19', SC_PF: '2025-07-08', SC_PP: 0,     SC_AS: undefined,    SC_AF: undefined,    SC_AP: 0,   FB_PS: '2025-07-09', FB_PF: '2025-08-18', FB_PP: 0,     FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: '2025-08-19', AS_PF: '2025-10-01', AS_PP: 0,    AS_AS: undefined,    AS_AF: undefined,    AS_AP: 0,    PE1_PS: undefined,    PE1_PF: undefined,    PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: undefined,    PT_PF: undefined,    PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: undefined,    PE2_PF: undefined,    PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: undefined,    ER_PF: undefined,    ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'TOPSIDE', ZONE: 'NORTH',          MOD: 'Main Deck',           LEVEL: 'Main Deck',           NAME: 'Turnnion',               G_EREC: 'ND330', G_2PE: 'ND330', G_1PE: 'ND330', EREC: '',      PE2: '',      PE1: '',      PBLK: '',      BLK: 'NT351', L: '1.8',  B: '1.7',  H: '1.8',  YARD: 'HHI',   SC_WF: 16,     FB_WF: 24.3,    AS_WF: 0,       PE1_WF: 0,       PT_WF: 0,       PE2_WF: 0,       ER_WF: 0,      SC_PS: '2025-07-17', SC_PF: '2025-08-18', SC_PP: 0,     SC_AS: undefined,    SC_AF: undefined,    SC_AP: 0,   FB_PS: '2025-08-19', FB_PF: '2025-10-15', FB_PP: 0,     FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: undefined,    AS_PF: undefined,    AS_PP: 0,    AS_AS: undefined,    AS_AF: undefined,    AS_AP: 0,    PE1_PS: undefined,    PE1_PF: undefined,    PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: undefined,    PT_PF: undefined,    PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: undefined,    PE2_PF: undefined,    PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: undefined,    ER_PF: undefined,    ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'TOPSIDE', ZONE: 'NORTH',          MOD: 'Main Deck',           LEVEL: 'Main Deck',           NAME: 'Turnnion',               G_EREC: 'ND330', G_2PE: 'ND330', G_1PE: 'ND330', EREC: '',      PE2: '',      PE1: '',      PBLK: '',      BLK: 'NT361', L: '1.8',  B: '1.7',  H: '1.8',  YARD: 'HHI',   SC_WF: 16,     FB_WF: 24.3,    AS_WF: 0,       PE1_WF: 0,       PT_WF: 0,       PE2_WF: 0,       ER_WF: 0,      SC_PS: '2025-07-17', SC_PF: '2025-08-18', SC_PP: 0,     SC_AS: undefined,    SC_AF: undefined,    SC_AP: 0,   FB_PS: '2025-08-19', FB_PF: '2025-10-15', FB_PP: 0,     FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: undefined,    AS_PF: undefined,    AS_PP: 0,    AS_AS: undefined,    AS_AF: undefined,    AS_AP: 0,    PE1_PS: undefined,    PE1_PF: undefined,    PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: undefined,    PT_PF: undefined,    PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: undefined,    PE2_PF: undefined,    PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: undefined,    ER_PF: undefined,    ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'TOPSIDE', ZONE: 'NORTH',          MOD: 'Main Deck',           LEVEL: 'Main Deck',           NAME: 'Turnnion',               G_EREC: 'ND330', G_2PE: 'ND330', G_1PE: 'ND330', EREC: '',      PE2: '',      PE1: '',      PBLK: '',      BLK: 'NT371', L: '1.7',  B: '1.8',  H: '1.8',  YARD: 'HHI',   SC_WF: 16,     FB_WF: 24.3,    AS_WF: 0,       PE1_WF: 0,       PT_WF: 0,       PE2_WF: 0,       ER_WF: 0,      SC_PS: '2025-07-17', SC_PF: '2025-08-18', SC_PP: 0,     SC_AS: undefined,    SC_AF: undefined,    SC_AP: 0,   FB_PS: '2025-08-19', FB_PF: '2025-10-15', FB_PP: 0,     FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: undefined,    AS_PF: undefined,    AS_PP: 0,    AS_AS: undefined,    AS_AF: undefined,    AS_AP: 0,    PE1_PS: undefined,    PE1_PF: undefined,    PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: undefined,    PT_PF: undefined,    PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: undefined,    PE2_PF: undefined,    PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: undefined,    ER_PF: undefined,    ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'TOPSIDE', ZONE: 'NORTH',          MOD: 'Main Deck',           LEVEL: 'Main Deck',           NAME: 'Turnnion',               G_EREC: 'ND330', G_2PE: 'ND330', G_1PE: 'ND330', EREC: '',      PE2: '',      PE1: '',      PBLK: '',      BLK: 'NT381', L: '1.7',  B: '1.8',  H: '1.8',  YARD: 'HHI',   SC_WF: 16,     FB_WF: 24.3,    AS_WF: 0,       PE1_WF: 0,       PT_WF: 0,       PE2_WF: 0,       ER_WF: 0,      SC_PS: '2025-07-17', SC_PF: '2025-08-18', SC_PP: 0,     SC_AS: undefined,    SC_AF: undefined,    SC_AP: 0,   FB_PS: '2025-08-19', FB_PF: '2025-10-15', FB_PP: 0,     FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: undefined,    AS_PF: undefined,    AS_PP: 0,    AS_AS: undefined,    AS_AF: undefined,    AS_AP: 0,    PE1_PS: undefined,    PE1_PF: undefined,    PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: undefined,    PT_PF: undefined,    PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: undefined,    PE2_PF: undefined,    PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: undefined,    ER_PF: undefined,    ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'TOPSIDE', ZONE: 'NORTH',          MOD: 'Main Deck',           LEVEL: 'Main Deck',           NAME: 'Deck Block',             G_EREC: 'ND330', G_2PE: 'ND330', G_1PE: 'ND341', EREC: '',      PE2: '',      PE1: '',      PBLK: 'ND341', BLK: 'ND341', L: '9.8',  B: '3.9',  H: '0.6',  YARD: 'HHI',   SC_WF: 25.51,  FB_WF: 51.03,   AS_WF: 76.54,   PE1_WF: 127.56,  PT_WF: 142,     PE2_WF: 127.56,  ER_WF: 0,      SC_PS: '2025-07-01', SC_PF: '2025-07-18', SC_PP: 0,     SC_AS: undefined,    SC_AF: undefined,    SC_AP: 0,   FB_PS: '2025-07-21', FB_PF: '2025-08-25', FB_PP: 0,     FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: '2025-08-26', AS_PF: '2025-10-01', AS_PP: 0,    AS_AS: undefined,    AS_AF: undefined,    AS_AP: 0,    PE1_PS: '2025-10-02', PE1_PF: '2025-11-07', PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: '2025-11-10', PT_PF: '2025-12-02', PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: '2025-12-03', PE2_PF: '2026-01-07', PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: undefined,    ER_PF: undefined,    ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'TOPSIDE', ZONE: 'NORTH',          MOD: 'Main Deck',           LEVEL: 'Main Deck',           NAME: 'Access P/F',             G_EREC: 'ND330', G_2PE: 'ND330', G_1PE: 'NV351', EREC: '',      PE2: '',      PE1: '',      PBLK: 'NV351', BLK: 'NV351', L: '5.9',  B: '3.2',  H: '4.1',  YARD: 'HHI',   SC_WF: 8,      FB_WF: 0,       AS_WF: 24,      PE1_WF: 0,       PT_WF: 47.69,   PE2_WF: 16,      ER_WF: 0,      SC_PS: '2025-08-21', SC_PF: '2025-09-09', SC_PP: 0,     SC_AS: undefined,    SC_AF: undefined,    SC_AP: 0,   FB_PS: undefined,    FB_PF: undefined,    FB_PP: 0,     FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: '2025-09-10', AS_PF: '2025-10-30', AS_PP: 0,    AS_AS: undefined,    AS_AF: undefined,    AS_AP: 0,    PE1_PS: undefined,    PE1_PF: undefined,    PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: '2025-11-20', PT_PF: '2025-12-12', PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: '2025-12-15', PE2_PF: '2026-01-07', PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: undefined,    ER_PF: undefined,    ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'TOPSIDE', ZONE: 'NORTH',          MOD: 'Main Deck',           LEVEL: 'Main Deck',           NAME: 'Access P/F',             G_EREC: 'ND330', G_2PE: 'ND330', G_1PE: 'NV3L1', EREC: '',      PE2: '',      PE1: '',      PBLK: 'NV3L1', BLK: 'NV3L1', L: '1.5',  B: '11.5', H: '2',    YARD: 'HHI',   SC_WF: 8,      FB_WF: 0,       AS_WF: 24,      PE1_WF: 0,       PT_WF: 51,      PE2_WF: 32,      ER_WF: 0,      SC_PS: '2025-08-21', SC_PF: '2025-09-09', SC_PP: 0,     SC_AS: undefined,    SC_AF: undefined,    SC_AP: 0,   FB_PS: undefined,    FB_PF: undefined,    FB_PP: 0,     FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: '2025-09-10', AS_PF: '2025-10-30', AS_PP: 0,    AS_AS: undefined,    AS_AF: undefined,    AS_AP: 0,    PE1_PS: undefined,    PE1_PF: undefined,    PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: '2025-11-20', PT_PF: '2025-12-12', PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: '2025-12-15', PE2_PF: '2026-01-07', PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: undefined,    ER_PF: undefined,    ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'TOPSIDE', ZONE: 'NORTH',          MOD: 'Main Deck',           LEVEL: 'Main Deck',           NAME: 'Access P/F',             G_EREC: 'ND330', G_2PE: 'ND330', G_1PE: 'NV3M1', EREC: '',      PE2: '',      PE1: '',      PBLK: 'NV3M1', BLK: 'NV3M1', L: '1.4',  B: '9',    H: '2',    YARD: 'HHI',   SC_WF: 8,      FB_WF: 0,       AS_WF: 24,      PE1_WF: 0,       PT_WF: 51.58,   PE2_WF: 32,      ER_WF: 0,      SC_PS: '2025-08-21', SC_PF: '2025-09-09', SC_PP: 0,     SC_AS: undefined,    SC_AF: undefined,    SC_AP: 0,   FB_PS: undefined,    FB_PF: undefined,    FB_PP: 0,     FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: '2025-09-10', AS_PF: '2025-10-30', AS_PP: 0,    AS_AS: undefined,    AS_AF: undefined,    AS_AP: 0,    PE1_PS: undefined,    PE1_PF: undefined,    PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: '2025-11-20', PT_PF: '2025-12-12', PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: '2025-12-15', PE2_PF: '2026-01-07', PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: undefined,    ER_PF: undefined,    ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'TOPSIDE', ZONE: 'NORTH',          MOD: 'Main Deck',           LEVEL: 'Main Deck',           NAME: 'Access P/F',             G_EREC: 'ND330', G_2PE: 'ND330', G_1PE: 'NV3N1', EREC: '',      PE2: '',      PE1: '',      PBLK: 'NV3N1', BLK: 'NV3N1', L: '8.4',  B: '4.9',  H: '7',    YARD: 'HHI',   SC_WF: 8,      FB_WF: 0,       AS_WF: 24,      PE1_WF: 0,       PT_WF: 107.3,   PE2_WF: 32,      ER_WF: 0,      SC_PS: '2025-08-21', SC_PF: '2025-09-09', SC_PP: 0,     SC_AS: undefined,    SC_AF: undefined,    SC_AP: 0,   FB_PS: undefined,    FB_PF: undefined,    FB_PP: 0,     FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: '2025-09-10', AS_PF: '2025-10-30', AS_PP: 0,    AS_AS: undefined,    AS_AF: undefined,    AS_AP: 0,    PE1_PS: undefined,    PE1_PF: undefined,    PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: '2025-11-20', PT_PF: '2025-12-12', PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: '2025-12-15', PE2_PF: '2026-01-07', PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: undefined,    ER_PF: undefined,    ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'TOPSIDE', ZONE: 'NORTH',          MOD: 'Main Deck',           LEVEL: 'Main Deck',           NAME: 'Access P/F',             G_EREC: 'ND330', G_2PE: 'ND330', G_1PE: 'NV3P1', EREC: '',      PE2: '',      PE1: '',      PBLK: 'NV3P1', BLK: 'NV3P1', L: '9',    B: '2.8',  H: '2',    YARD: 'HHI',   SC_WF: 8,      FB_WF: 0,       AS_WF: 24,      PE1_WF: 0,       PT_WF: 116.58,  PE2_WF: 32,      ER_WF: 0,      SC_PS: '2025-08-21', SC_PF: '2025-09-09', SC_PP: 0,     SC_AS: undefined,    SC_AF: undefined,    SC_AP: 0,   FB_PS: undefined,    FB_PF: undefined,    FB_PP: 0,     FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: '2025-09-10', AS_PF: '2025-10-30', AS_PP: 0,    AS_AS: undefined,    AS_AF: undefined,    AS_AP: 0,    PE1_PS: undefined,    PE1_PF: undefined,    PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: '2025-11-20', PT_PF: '2025-12-12', PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: '2025-12-15', PE2_PF: '2026-01-07', PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: undefined,    ER_PF: undefined,    ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'TOPSIDE', ZONE: 'NORTH',          MOD: 'Main Deck',           LEVEL: 'Main Deck',           NAME: 'Access P/F',             G_EREC: 'ND330', G_2PE: 'ND330', G_1PE: 'NV3Q1', EREC: '',      PE2: '',      PE1: '',      PBLK: 'NV3Q1', BLK: 'NV3Q1', L: '8.2',  B: '4.6',  H: '2',    YARD: 'HHI',   SC_WF: 8,      FB_WF: 0,       AS_WF: 24,      PE1_WF: 0,       PT_WF: 157.6,   PE2_WF: 32,      ER_WF: 0,      SC_PS: '2025-08-21', SC_PF: '2025-09-09', SC_PP: 0,     SC_AS: undefined,    SC_AF: undefined,    SC_AP: 0,   FB_PS: undefined,    FB_PF: undefined,    FB_PP: 0,     FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: '2025-09-10', AS_PF: '2025-10-30', AS_PP: 0,    AS_AS: undefined,    AS_AF: undefined,    AS_AP: 0,    PE1_PS: undefined,    PE1_PF: undefined,    PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: '2025-11-20', PT_PF: '2025-12-12', PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: '2025-12-15', PE2_PF: '2026-01-07', PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: undefined,    ER_PF: undefined,    ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'TOPSIDE', ZONE: 'NORTH',          MOD: 'Main Deck',           LEVEL: 'Main Deck',           NAME: 'Access P/F',             G_EREC: 'ND330', G_2PE: 'ND330', G_1PE: 'NV3R1', EREC: '',      PE2: '',      PE1: '',      PBLK: 'NV3R1', BLK: 'NV3R1', L: '10.2', B: '4.6',  H: '2',    YARD: 'HHI',   SC_WF: 8,      FB_WF: 0,       AS_WF: 24,      PE1_WF: 0,       PT_WF: 171.22,  PE2_WF: 32,      ER_WF: 0,      SC_PS: '2025-08-21', SC_PF: '2025-09-09', SC_PP: 0,     SC_AS: undefined,    SC_AF: undefined,    SC_AP: 0,   FB_PS: undefined,    FB_PF: undefined,    FB_PP: 0,     FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: '2025-09-10', AS_PF: '2025-10-30', AS_PP: 0,    AS_AS: undefined,    AS_AF: undefined,    AS_AP: 0,    PE1_PS: undefined,    PE1_PF: undefined,    PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: '2025-11-20', PT_PF: '2025-12-12', PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: '2025-12-15', PE2_PF: '2026-01-07', PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: undefined,    ER_PF: undefined,    ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'TOPSIDE', ZONE: 'NORTH',          MOD: 'Main Deck',           LEVEL: 'Main Deck',           NAME: 'Access P/F',             G_EREC: 'ND330', G_2PE: 'ND330', G_1PE: 'NV3S1', EREC: '',      PE2: '',      PE1: '',      PBLK: 'NV3S1', BLK: 'NV3S1', L: '8.2',  B: '1.6',  H: '2',    YARD: 'HHI',   SC_WF: 8,      FB_WF: 0,       AS_WF: 24,      PE1_WF: 0,       PT_WF: 53.65,   PE2_WF: 32,      ER_WF: 0,      SC_PS: '2025-08-21', SC_PF: '2025-09-09', SC_PP: 0,     SC_AS: undefined,    SC_AF: undefined,    SC_AP: 0,   FB_PS: undefined,    FB_PF: undefined,    FB_PP: 0,     FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: '2025-09-10', AS_PF: '2025-10-30', AS_PP: 0,    AS_AS: undefined,    AS_AF: undefined,    AS_AP: 0,    PE1_PS: undefined,    PE1_PF: undefined,    PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: '2025-11-20', PT_PF: '2025-12-12', PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: '2025-12-15', PE2_PF: '2026-01-07', PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: undefined,    ER_PF: undefined,    ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'TOPSIDE', ZONE: 'NORTH',          MOD: 'Main Deck',           LEVEL: 'Main Deck',           NAME: 'Access P/F',             G_EREC: 'ND330', G_2PE: 'ND330', G_1PE: 'NV3T1', EREC: '',      PE2: '',      PE1: '',      PBLK: 'NV3T1', BLK: 'NV3T1', L: '6.9',  B: '1.6',  H: '2',    YARD: 'HHI',   SC_WF: 8,      FB_WF: 0,       AS_WF: 24,      PE1_WF: 0,       PT_WF: 58.29,   PE2_WF: 32,      ER_WF: 0,      SC_PS: '2025-08-21', SC_PF: '2025-09-09', SC_PP: 0,     SC_AS: undefined,    SC_AF: undefined,    SC_AP: 0,   FB_PS: undefined,    FB_PF: undefined,    FB_PP: 0,     FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: '2025-09-10', AS_PF: '2025-10-30', AS_PP: 0,    AS_AS: undefined,    AS_AF: undefined,    AS_AP: 0,    PE1_PS: undefined,    PE1_PF: undefined,    PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: '2025-11-20', PT_PF: '2025-12-12', PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: '2025-12-15', PE2_PF: '2026-01-07', PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: undefined,    ER_PF: undefined,    ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'TOPSIDE', ZONE: 'NORTH',          MOD: 'Main Deck',           LEVEL: 'Main Deck',           NAME: 'Access P/F',             G_EREC: 'ND330', G_2PE: 'ND330', G_1PE: 'NV3U1', EREC: '',      PE2: '',      PE1: '',      PBLK: 'NV3U1', BLK: 'NV3U1', L: '1.8',  B: '9.6',  H: '2',    YARD: 'HHI',   SC_WF: 8,      FB_WF: 0,       AS_WF: 24,      PE1_WF: 0,       PT_WF: 64.25,   PE2_WF: 32,      ER_WF: 0,      SC_PS: '2025-08-21', SC_PF: '2025-09-09', SC_PP: 0,     SC_AS: undefined,    SC_AF: undefined,    SC_AP: 0,   FB_PS: undefined,    FB_PF: undefined,    FB_PP: 0,     FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: '2025-09-10', AS_PF: '2025-10-30', AS_PP: 0,    AS_AS: undefined,    AS_AF: undefined,    AS_AP: 0,    PE1_PS: undefined,    PE1_PF: undefined,    PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: '2025-11-20', PT_PF: '2025-12-12', PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: '2025-12-15', PE2_PF: '2026-01-07', PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: undefined,    ER_PF: undefined,    ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'TOPSIDE', ZONE: 'NORTH',          MOD: 'Main Deck',           LEVEL: 'Main Deck',           NAME: 'Access P/F',             G_EREC: 'ND330', G_2PE: 'ND330', G_1PE: 'NV3V1', EREC: '',      PE2: '',      PE1: '',      PBLK: 'NV3V1', BLK: 'NV3V1', L: '1.6',  B: '7',    H: '2',    YARD: 'HHI',   SC_WF: 8,      FB_WF: 0,       AS_WF: 24,      PE1_WF: 0,       PT_WF: 45.04,   PE2_WF: 32,      ER_WF: 0,      SC_PS: '2025-08-21', SC_PF: '2025-09-09', SC_PP: 0,     SC_AS: undefined,    SC_AF: undefined,    SC_AP: 0,   FB_PS: undefined,    FB_PF: undefined,    FB_PP: 0,     FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: '2025-09-10', AS_PF: '2025-10-30', AS_PP: 0,    AS_AS: undefined,    AS_AF: undefined,    AS_AP: 0,    PE1_PS: undefined,    PE1_PF: undefined,    PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: '2025-11-20', PT_PF: '2025-12-12', PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: '2025-12-15', PE2_PF: '2026-01-07', PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: undefined,    ER_PF: undefined,    ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'TOPSIDE', ZONE: 'NORTH',          MOD: 'Main Deck',           LEVEL: 'Main Deck',           NAME: 'Access P/F',             G_EREC: 'ND330', G_2PE: 'ND330', G_1PE: 'NV3W1', EREC: '',      PE2: '',      PE1: '',      PBLK: 'NV3W1', BLK: 'NV3W1', L: '14.9', B: '6.8',  H: '6',    YARD: 'HHI',   SC_WF: 8,      FB_WF: 0,       AS_WF: 24,      PE1_WF: 0,       PT_WF: 407.73,  PE2_WF: 32,      ER_WF: 0,      SC_PS: '2025-08-21', SC_PF: '2025-09-09', SC_PP: 0,     SC_AS: undefined,    SC_AF: undefined,    SC_AP: 0,   FB_PS: undefined,    FB_PF: undefined,    FB_PP: 0,     FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: '2025-09-10', AS_PF: '2025-10-30', AS_PP: 0,    AS_AS: undefined,    AS_AF: undefined,    AS_AP: 0,    PE1_PS: undefined,    PE1_PF: undefined,    PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: '2025-11-20', PT_PF: '2025-12-12', PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: '2025-12-15', PE2_PF: '2026-01-07', PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: undefined,    ER_PF: undefined,    ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'TOPSIDE', ZONE: 'NORTH',          MOD: 'Main Deck',           LEVEL: 'Main Deck',           NAME: 'Access P/F',             G_EREC: 'ND330', G_2PE: 'ND330', G_1PE: 'NV3X1', EREC: '',      PE2: '',      PE1: '',      PBLK: 'NV3X1', BLK: 'NV3X1', L: '3.3',  B: '6.4',  H: '3.2',  YARD: 'HHI',   SC_WF: 8,      FB_WF: 0,       AS_WF: 24,      PE1_WF: 0,       PT_WF: 50.42,   PE2_WF: 16,      ER_WF: 0,      SC_PS: '2025-08-21', SC_PF: '2025-09-09', SC_PP: 0,     SC_AS: undefined,    SC_AF: undefined,    SC_AP: 0,   FB_PS: undefined,    FB_PF: undefined,    FB_PP: 0,     FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: '2025-09-10', AS_PF: '2025-10-30', AS_PP: 0,    AS_AS: undefined,    AS_AF: undefined,    AS_AP: 0,    PE1_PS: undefined,    PE1_PF: undefined,    PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: '2025-11-20', PT_PF: '2025-12-12', PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: '2025-12-15', PE2_PF: '2026-01-07', PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: undefined,    ER_PF: undefined,    ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'TOPSIDE', ZONE: 'NORTH',          MOD: 'Main Deck',           LEVEL: 'Main Deck',           NAME: 'Pipe Rack',              G_EREC: 'ND330', G_2PE: 'ND330', G_1PE: 'NP371', EREC: '',      PE2: '',      PE1: '',      PBLK: 'NP371', BLK: 'NP371', L: '12.3', B: '20.1', H: '4.3',  YARD: 'HHI',   SC_WF: 45.56,  FB_WF: 0,       AS_WF: 136.68,  PE1_WF: 0,       PT_WF: 1036.95, PE2_WF: 227.79,  ER_WF: 0,      SC_PS: '2025-07-11', SC_PF: '2025-08-11', SC_PP: 0,     SC_AS: undefined,    SC_AF: undefined,    SC_AP: 0,   FB_PS: undefined,    FB_PF: undefined,    FB_PP: 0,     FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: '2025-08-12', AS_PF: '2025-09-25', AS_PP: 0,    AS_AS: undefined,    AS_AF: undefined,    AS_AP: 0,    PE1_PS: undefined,    PE1_PF: undefined,    PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: '2025-10-23', PT_PF: '2025-11-14', PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: '2025-11-17', PE2_PF: '2025-12-29', PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: undefined,    ER_PF: undefined,    ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'TOPSIDE', ZONE: 'NORTH',          MOD: 'Main Deck',           LEVEL: 'Main Deck',           NAME: 'Pipe Rack',              G_EREC: 'ND330', G_2PE: 'ND330', G_1PE: 'NP381', EREC: '',      PE2: '',      PE1: '',      PBLK: 'NP381', BLK: 'NP381', L: '14.8', B: '20.2', H: '4.3',  YARD: 'HHI',   SC_WF: 45.56,  FB_WF: 0,       AS_WF: 136.68,  PE1_WF: 0,       PT_WF: 1237.65, PE2_WF: 227.79,  ER_WF: 0,      SC_PS: '2025-07-11', SC_PF: '2025-08-11', SC_PP: 0,     SC_AS: undefined,    SC_AF: undefined,    SC_AP: 0,   FB_PS: undefined,    FB_PF: undefined,    FB_PP: 0,     FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: '2025-08-12', AS_PF: '2025-09-25', AS_PP: 0,    AS_AS: undefined,    AS_AF: undefined,    AS_AP: 0,    PE1_PS: undefined,    PE1_PF: undefined,    PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: '2025-10-23', PT_PF: '2025-11-14', PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: '2025-11-17', PE2_PF: '2025-12-29', PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: undefined,    ER_PF: undefined,    ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'TOPSIDE', ZONE: 'NORTH',          MOD: 'Main Deck',           LEVEL: 'Main Deck',           NAME: 'Pipe Rack',              G_EREC: 'ND330', G_2PE: 'ND330', G_1PE: 'NP391', EREC: '',      PE2: '',      PE1: '',      PBLK: 'NP391', BLK: 'NP391', L: '4.3',  B: '10.3', H: '2.7',  YARD: 'HHI',   SC_WF: 8,      FB_WF: 0,       AS_WF: 24,      PE1_WF: 0,       PT_WF: 173.13,  PE2_WF: 27.34,   ER_WF: 0,      SC_PS: '2025-07-18', SC_PF: '2025-08-19', SC_PP: 0,     SC_AS: undefined,    SC_AF: undefined,    SC_AP: 0,   FB_PS: undefined,    FB_PF: undefined,    FB_PP: 0,     FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: '2025-08-20', AS_PF: '2025-10-08', AS_PP: 0,    AS_AS: undefined,    AS_AF: undefined,    AS_AP: 0,    PE1_PS: undefined,    PE1_PF: undefined,    PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: '2025-10-30', PT_PF: '2025-11-21', PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: '2025-11-24', PE2_PF: '2026-01-07', PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: undefined,    ER_PF: undefined,    ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'TOPSIDE', ZONE: 'NORTH',          MOD: 'Main Deck',           LEVEL: 'Main Deck',           NAME: 'Stair Tower',            G_EREC: 'NX110', G_2PE: 'NX110', G_1PE: 'NX111', EREC: 'NX110', PE2: ' ',     PE1: '',      PBLK: 'NX111', BLK: 'NX111', L: '9.1',  B: '6.3',  H: '20.1', YARD: 'HHI',   SC_WF: 36.45,  FB_WF: 72.89,   AS_WF: 109.34,  PE1_WF: 0,       PT_WF: 740.58,  PE2_WF: 72.89,   ER_WF: 5822.9, SC_PS: '2025-08-11', SC_PF: '2025-08-27', SC_PP: 0,     SC_AS: undefined,    SC_AF: undefined,    SC_AP: 0,   FB_PS: '2025-08-28', FB_PF: '2025-09-22', FB_PP: 0,     FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: '2025-09-23', AS_PF: '2025-10-24', AS_PP: 0,    AS_AS: undefined,    AS_AF: undefined,    AS_AP: 0,    PE1_PS: undefined,    PE1_PF: undefined,    PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: '2025-11-19', PT_PF: '2025-12-11', PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: '2025-12-12', PE2_PF: '2026-01-09', PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: '2026-01-14', ER_PF: '2026-04-08', ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'TOPSIDE', ZONE: 'NORTH',          MOD: 'Compressor Platform', LEVEL: 'Compressor Platform', NAME: 'Deck Block',             G_EREC: 'KD110', G_2PE: 'KD110', G_1PE: 'KD110', EREC: 'KD110', PE2: '',      PE1: 'KD110', PBLK: 'KD110', BLK: 'KD111', L: '14.7', B: '16.8', H: '1.6',  YARD: 'HHI',   SC_WF: 83.83,  FB_WF: 167.66,  AS_WF: 251.48,  PE1_WF: 1266.53, PT_WF: 3093.7,  PE2_WF: 1266.53, ER_WF: 5822.9, SC_PS: '2025-07-03', SC_PF: '2025-07-22', SC_PP: 0,     SC_AS: undefined,    SC_AF: undefined,    SC_AP: 0,   FB_PS: '2025-07-23', FB_PF: '2025-09-01', FB_PP: 0,     FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: '2025-09-02', AS_PF: '2025-10-22', AS_PP: 0,    AS_AS: undefined,    AS_AF: undefined,    AS_AP: 0,    PE1_PS: '2025-10-23', PE1_PF: '2025-11-21', PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: '2025-11-24', PT_PF: '2025-12-16', PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: '2025-12-17', PE2_PF: '2026-01-16', PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: '2026-01-20', ER_PF: '2026-04-08', ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'TOPSIDE', ZONE: 'NORTH',          MOD: 'Compressor Platform', LEVEL: 'Compressor Platform', NAME: 'Deck Block',             G_EREC: 'KD110', G_2PE: 'KD110', G_1PE: 'KD110', EREC: '',      PE2: '',      PE1: '',      PBLK: '',      BLK: 'KD112', L: '11.1', B: '17.5', H: '2',    YARD: 'HHI',   SC_WF: 56.49,  FB_WF: 112.99,  AS_WF: 169.48,  PE1_WF: 0,       PT_WF: 0,       PE2_WF: 0,       ER_WF: 0,      SC_PS: '2025-07-03', SC_PF: '2025-07-22', SC_PP: 0,     SC_AS: undefined,    SC_AF: undefined,    SC_AP: 0,   FB_PS: '2025-07-23', FB_PF: '2025-09-01', FB_PP: 0,     FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: '2025-09-02', AS_PF: '2025-10-22', AS_PP: 0,    AS_AS: undefined,    AS_AF: undefined,    AS_AP: 0,    PE1_PS: undefined,    PE1_PF: undefined,    PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: undefined,    PT_PF: undefined,    PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: undefined,    PE2_PF: undefined,    PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: undefined,    ER_PF: undefined,    ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'TOPSIDE', ZONE: 'NORTH',          MOD: 'Compressor Platform', LEVEL: 'Compressor Platform', NAME: 'Deck Block',             G_EREC: 'KD110', G_2PE: 'KD110', G_1PE: 'KD110', EREC: '',      PE2: '',      PE1: '',      PBLK: '',      BLK: 'KD113', L: '14.6', B: '9.1',  H: '3.2',  YARD: 'HHI',   SC_WF: 47.38,  FB_WF: 94.76,   AS_WF: 142.14,  PE1_WF: 0,       PT_WF: 0,       PE2_WF: 0,       ER_WF: 0,      SC_PS: '2025-08-25', SC_PF: '2025-09-02', SC_PP: 0,     SC_AS: undefined,    SC_AF: undefined,    SC_AP: 0,   FB_PS: '2025-09-03', FB_PF: '2025-09-25', FB_PP: 0,     FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: '2025-09-26', AS_PF: '2025-10-22', AS_PP: 0,    AS_AS: undefined,    AS_AF: undefined,    AS_AP: 0,    PE1_PS: undefined,    PE1_PF: undefined,    PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: undefined,    PT_PF: undefined,    PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: undefined,    PE2_PF: undefined,    PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: undefined,    ER_PF: undefined,    ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'TOPSIDE', ZONE: 'NORTH',          MOD: 'Compressor Platform', LEVEL: 'Compressor Platform', NAME: 'Deck Block',             G_EREC: 'KD110', G_2PE: 'KD110', G_1PE: 'KD110', EREC: '',      PE2: '',      PE1: '',      PBLK: '',      BLK: 'KD114', L: '9.9',  B: '9.2',  H: '0.6',  YARD: 'HHI',   SC_WF: 36.45,  FB_WF: 72.89,   AS_WF: 109.34,  PE1_WF: 0,       PT_WF: 0,       PE2_WF: 0,       ER_WF: 0,      SC_PS: '2025-08-25', SC_PF: '2025-09-02', SC_PP: 0,     SC_AS: undefined,    SC_AF: undefined,    SC_AP: 0,   FB_PS: '2025-09-03', FB_PF: '2025-09-25', FB_PP: 0,     FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: '2025-09-26', AS_PF: '2025-10-22', AS_PP: 0,    AS_AS: undefined,    AS_AF: undefined,    AS_AP: 0,    PE1_PS: undefined,    PE1_PF: undefined,    PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: undefined,    PT_PF: undefined,    PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: undefined,    PE2_PF: undefined,    PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: undefined,    ER_PF: undefined,    ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'TOPSIDE', ZONE: 'NORTH',          MOD: 'Compressor Platform', LEVEL: 'Compressor Platform', NAME: 'Column & Brace',         G_EREC: 'KD110', G_2PE: 'KD110', G_1PE: 'KD110', EREC: '',      PE2: '',      PE1: '',      PBLK: '',      BLK: 'KC111', L: '21.6', B: '20.6', H: '7.9',  YARD: 'HHI',   SC_WF: 34.62,  FB_WF: 69.25,   AS_WF: 103.87,  PE1_WF: 0,       PT_WF: 0,       PE2_WF: 0,       ER_WF: 0,      SC_PS: '2025-07-09', SC_PF: '2025-07-25', SC_PP: 0,     SC_AS: undefined,    SC_AF: undefined,    SC_AP: 0,   FB_PS: '2025-08-08', FB_PF: '2025-08-21', FB_PP: 0,     FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: '2025-08-22', AS_PF: '2025-09-15', AS_PP: 0,    AS_AS: undefined,    AS_AF: undefined,    AS_AP: 0,    PE1_PS: undefined,    PE1_PF: undefined,    PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: undefined,    PT_PF: undefined,    PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: undefined,    PE2_PF: undefined,    PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: undefined,    ER_PF: undefined,    ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'TOPSIDE', ZONE: 'NORTH',          MOD: 'Compressor Platform', LEVEL: 'Compressor Platform', NAME: 'Pipe Rack',              G_EREC: 'KD110', G_2PE: 'KD110', G_1PE: 'KP111', EREC: '',      PE2: '',      PE1: '',      PBLK: 'KP111', BLK: 'KP111', L: '10.1', B: '17',   H: '3.5',  YARD: 'HHI',   SC_WF: 16,     FB_WF: 0,       AS_WF: 27.34,   PE1_WF: 0,       PT_WF: 558.88,  PE2_WF: 24.3,    ER_WF: 0,      SC_PS: '2025-08-08', SC_PF: '2025-08-28', SC_PP: 0,     SC_AS: undefined,    SC_AF: undefined,    SC_AP: 0,   FB_PS: undefined,    FB_PF: undefined,    FB_PP: 0,     FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: '2025-08-29', AS_PF: '2025-10-20', AS_PP: 0,    AS_AS: undefined,    AS_AF: undefined,    AS_AP: 0,    PE1_PS: undefined,    PE1_PF: undefined,    PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: '2025-11-10', PT_PF: '2025-12-02', PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: '2025-12-03', PE2_PF: '2026-01-16', PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: undefined,    ER_PF: undefined,    ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'TOPSIDE', ZONE: 'NORTH',          MOD: 'Compressor Platform', LEVEL: 'Compressor Platform', NAME: 'Access P/F',             G_EREC: 'KD110', G_2PE: 'KD110', G_1PE: 'KV111', EREC: '',      PE2: '',      PE1: '',      PBLK: 'KV111', BLK: 'KV111', L: '11.1', B: '21.8', H: '4.7',  YARD: 'HHI',   SC_WF: 24,     FB_WF: 0,       AS_WF: 65.6,    PE1_WF: 0,       PT_WF: 974.19,  PE2_WF: 118.45,  ER_WF: 0,      SC_PS: '2025-08-08', SC_PF: '2025-08-28', SC_PP: 0,     SC_AS: undefined,    SC_AF: undefined,    SC_AP: 0,   FB_PS: undefined,    FB_PF: undefined,    FB_PP: 0,     FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: '2025-08-29', AS_PF: '2025-10-27', AS_PP: 0,    AS_AS: undefined,    AS_AF: undefined,    AS_AP: 0,    PE1_PS: undefined,    PE1_PF: undefined,    PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: '2025-11-17', PT_PF: '2025-12-09', PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: '2025-12-10', PE2_PF: '2026-01-02', PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: undefined,    ER_PF: undefined,    ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'TOPSIDE', ZONE: 'NORTH',          MOD: 'Compressor Platform', LEVEL: 'Compressor Platform', NAME: 'Access P/F',             G_EREC: 'KD110', G_2PE: 'KD110', G_1PE: 'KV121', EREC: '',      PE2: '',      PE1: '',      PBLK: 'KV121', BLK: 'KV121', L: '6.4',  B: '4.2',  H: '3.1',  YARD: 'HHI',   SC_WF: 8,      FB_WF: 0,       AS_WF: 24,      PE1_WF: 0,       PT_WF: 24,      PE2_WF: 16,      ER_WF: 0,      SC_PS: '2025-08-18', SC_PF: '2025-09-04', SC_PP: 0,     SC_AS: undefined,    SC_AF: undefined,    SC_AP: 0,   FB_PS: undefined,    FB_PF: undefined,    FB_PP: 0,     FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: '2025-09-05', AS_PF: '2025-10-27', AS_PP: 0,    AS_AS: undefined,    AS_AF: undefined,    AS_AP: 0,    PE1_PS: undefined,    PE1_PF: undefined,    PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: '2025-11-17', PT_PF: '2025-12-09', PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: '2025-12-10', PE2_PF: '2026-01-02', PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: undefined,    ER_PF: undefined,    ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'TOPSIDE', ZONE: 'NORTH',          MOD: 'Compressor Platform', LEVEL: 'Compressor Platform', NAME: 'Access P/F',             G_EREC: 'KD110', G_2PE: 'KD110', G_1PE: 'KV131', EREC: '',      PE2: '',      PE1: '',      PBLK: 'KV131', BLK: 'KV131', L: '2.1',  B: '6.7',  H: '3.9',  YARD: 'HHI',   SC_WF: 8,      FB_WF: 0,       AS_WF: 24,      PE1_WF: 0,       PT_WF: 34.77,   PE2_WF: 16,      ER_WF: 0,      SC_PS: '2025-08-18', SC_PF: '2025-09-04', SC_PP: 0,     SC_AS: undefined,    SC_AF: undefined,    SC_AP: 0,   FB_PS: undefined,    FB_PF: undefined,    FB_PP: 0,     FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: '2025-09-05', AS_PF: '2025-10-27', AS_PP: 0,    AS_AS: undefined,    AS_AF: undefined,    AS_AP: 0,    PE1_PS: undefined,    PE1_PF: undefined,    PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: '2025-11-17', PT_PF: '2025-12-09', PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: '2025-12-10', PE2_PF: '2026-01-02', PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: undefined,    ER_PF: undefined,    ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'TOPSIDE', ZONE: 'NORTH',          MOD: 'Compressor Platform', LEVEL: 'Compressor Platform', NAME: 'Access P/F',             G_EREC: 'KD110', G_2PE: 'KD110', G_1PE: 'KV141', EREC: '',      PE2: '',      PE1: '',      PBLK: 'KV141', BLK: 'KV141', L: '3.9',  B: '4.7',  H: '5.3',  YARD: 'HHI',   SC_WF: 8,      FB_WF: 0,       AS_WF: 24,      PE1_WF: 0,       PT_WF: 33.12,   PE2_WF: 16,      ER_WF: 0,      SC_PS: '2025-08-18', SC_PF: '2025-09-04', SC_PP: 0,     SC_AS: undefined,    SC_AF: undefined,    SC_AP: 0,   FB_PS: undefined,    FB_PF: undefined,    FB_PP: 0,     FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: '2025-09-05', AS_PF: '2025-10-27', AS_PP: 0,    AS_AS: undefined,    AS_AF: undefined,    AS_AP: 0,    PE1_PS: undefined,    PE1_PF: undefined,    PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: '2025-11-17', PT_PF: '2025-12-09', PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: '2025-12-10', PE2_PF: '2026-01-02', PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: undefined,    ER_PF: undefined,    ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'TOPSIDE', ZONE: 'NORTH',          MOD: 'Compressor Platform', LEVEL: 'Compressor Platform', NAME: 'Access P/F',             G_EREC: 'KD110', G_2PE: 'KD110', G_1PE: 'KV151', EREC: '',      PE2: '',      PE1: '',      PBLK: '',      BLK: 'KV151', L: '3.5',  B: '8.3',  H: '6',    YARD: 'HHI',   SC_WF: 0,      FB_WF: 0,       AS_WF: 0,       PE1_WF: 0,       PT_WF: 0,       PE2_WF: 0,       ER_WF: 0,      SC_PS: undefined,    SC_PF: undefined,    SC_PP: 0,     SC_AS: undefined,    SC_AF: undefined,    SC_AP: 0,   FB_PS: undefined,    FB_PF: undefined,    FB_PP: 0,     FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: undefined,    AS_PF: undefined,    AS_PP: 0,    AS_AS: undefined,    AS_AF: undefined,    AS_AP: 0,    PE1_PS: undefined,    PE1_PF: undefined,    PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: undefined,    PT_PF: undefined,    PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: undefined,    PE2_PF: undefined,    PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: undefined,    ER_PF: undefined,    ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'TOPSIDE', ZONE: 'CENTRAL',        MOD: 'Production Deck',     LEVEL: 'Production Deck',     NAME: 'Deck Block',             G_EREC: 'CD110', G_2PE: 'CD110', G_1PE: 'CD110', EREC: 'CD110', PE2: 'CD110', PE1: 'CD110', PBLK: 'CD110', BLK: 'CD111', L: '20.8', B: '15.7', H: '1.5',  YARD: 'HHI',   SC_WF: 224.15, FB_WF: 448.3,   AS_WF: 672.45,  PE1_WF: 3454.26, PT_WF: 3396.99, PE2_WF: 3454.26, ER_WF: 5822.9, SC_PS: '2025-01-16', SC_PF: '2025-01-23', SC_PP: 100,   SC_AS: '2025-01-16', SC_AF: undefined,    SC_AP: 98,  FB_PS: '2025-01-24', FB_PF: '2025-02-20', FB_PP: 69.4,  FB_AS: '2025-01-23', FB_AF: undefined,    FB_AP: 5,    AS_PS: '2025-02-21', AS_PF: '2025-04-10', AS_PP: 0,    AS_AS: undefined,    AS_AF: undefined,    AS_AP: 0,    PE1_PS: '2025-04-11', PE1_PF: '2025-05-16', PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: '2025-05-19', PT_PF: '2025-06-12', PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: '2025-06-13', PE2_PF: '2025-07-21', PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: '2025-07-22', ER_PF: '2026-03-10', ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'TOPSIDE', ZONE: 'CENTRAL',        MOD: 'Production Deck',     LEVEL: 'Production Deck',     NAME: 'Deck Block',             G_EREC: 'CD110', G_2PE: 'CD110', G_1PE: 'CD110', EREC: '',      PE2: '',      PE1: '',      PBLK: '',      BLK: 'CD112', L: '20.8', B: '16.7', H: '1.5',  YARD: 'HHI',   SC_WF: 280.64, FB_WF: 561.28,  AS_WF: 841.92,  PE1_WF: 0,       PT_WF: 0,       PE2_WF: 0,       ER_WF: 0,      SC_PS: '2025-01-16', SC_PF: '2025-01-23', SC_PP: 100,   SC_AS: '2025-01-16', SC_AF: undefined,    SC_AP: 98,  FB_PS: '2025-01-24', FB_PF: '2025-02-24', FB_PP: 58.9,  FB_AS: '2025-01-23', FB_AF: undefined,    FB_AP: 5,    AS_PS: '2025-02-25', AS_PF: '2025-04-10', AS_PP: 0,    AS_AS: undefined,    AS_AF: undefined,    AS_AP: 0,    PE1_PS: undefined,    PE1_PF: undefined,    PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: undefined,    PT_PF: undefined,    PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: undefined,    PE2_PF: undefined,    PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: undefined,    ER_PF: undefined,    ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'TOPSIDE', ZONE: 'CENTRAL',        MOD: 'Production Deck',     LEVEL: 'Production Deck',     NAME: 'Deck Block',             G_EREC: 'CD110', G_2PE: 'CD110', G_1PE: 'CD110', EREC: '',      PE2: '',      PE1: '',      PBLK: '',      BLK: 'CD113', L: '5.8',  B: '19.3', H: '1.7',  YARD: 'HHI',   SC_WF: 54.67,  FB_WF: 109.34,  AS_WF: 164.01,  PE1_WF: 0,       PT_WF: 0,       PE2_WF: 0,       ER_WF: 0,      SC_PS: '2025-01-23', SC_PF: '2025-02-05', SC_PP: 100,   SC_AS: '2025-01-16', SC_AF: undefined,    SC_AP: 98,  FB_PS: '2025-02-06', FB_PF: '2025-02-28', FB_PP: 28.8,  FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: '2025-03-03', AS_PF: '2025-04-10', AS_PP: 0,    AS_AS: undefined,    AS_AF: undefined,    AS_AP: 0,    PE1_PS: undefined,    PE1_PF: undefined,    PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: undefined,    PT_PF: undefined,    PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: undefined,    PE2_PF: undefined,    PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: undefined,    ER_PF: undefined,    ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'TOPSIDE', ZONE: 'CENTRAL',        MOD: 'Production Deck',     LEVEL: 'Production Deck',     NAME: 'Column & Brace',         G_EREC: 'CD110', G_2PE: 'CD110', G_1PE: 'CC111', EREC: '',      PE2: '',      PE1: '',      PBLK: 'CC111', BLK: 'CC111', L: '25.6', B: '31',   H: '13',   YARD: 'HHI',   SC_WF: 131.39, FB_WF: 262.78,  AS_WF: 394.17,  PE1_WF: 0,       PT_WF: 678.93,  PE2_WF: 0,       ER_WF: 0,      SC_PS: '2025-01-17', SC_PF: '2025-02-03', SC_PP: 100,   SC_AS: '2025-02-04', SC_AF: '2025-02-11', SC_AP: 100, FB_PS: '2025-02-04', FB_PF: '2025-02-17', FB_PP: 87,    FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: '2025-02-18', AS_PF: '2025-03-12', AS_PP: 0,    AS_AS: undefined,    AS_AF: undefined,    AS_AP: 0,    PE1_PS: undefined,    PE1_PF: undefined,    PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: '2025-04-14', PT_PF: '2025-05-16', PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: undefined,    PE2_PF: undefined,    PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: undefined,    ER_PF: undefined,    ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'TOPSIDE', ZONE: 'CENTRAL',        MOD: 'Production Deck',     LEVEL: 'Production Deck',     NAME: 'Deck Block',             G_EREC: 'CD110', G_2PE: 'CD110', G_1PE: 'CD120', EREC: '',      PE2: '',      PE1: 'CD120', PBLK: 'CD120', BLK: 'CD121', L: '18.3', B: '15.9', H: '1.5',  YARD: 'HHI',   SC_WF: 196.81, FB_WF: 393.63,  AS_WF: 590.44,  PE1_WF: 2506.64, PT_WF: 2273.27, PE2_WF: 2506.64, ER_WF: 0,      SC_PS: '2025-01-20', SC_PF: '2025-01-27', SC_PP: 100,   SC_AS: '2025-01-21', SC_AF: undefined,    SC_AP: 98,  FB_PS: '2025-01-31', FB_PF: '2025-02-26', FB_PP: 45.3,  FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: '2025-02-27', AS_PF: '2025-04-15', AS_PP: 0,    AS_AS: undefined,    AS_AF: undefined,    AS_AP: 0,    PE1_PS: '2025-04-16', PE1_PF: '2025-05-20', PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: '2025-05-21', PT_PF: '2025-06-13', PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: '2025-06-16', PE2_PF: '2025-07-21', PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: undefined,    ER_PF: undefined,    ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'TOPSIDE', ZONE: 'CENTRAL',        MOD: 'Production Deck',     LEVEL: 'Production Deck',     NAME: 'Deck Block',             G_EREC: 'CD110', G_2PE: 'CD110', G_1PE: 'CD120', EREC: '',      PE2: '',      PE1: '',      PBLK: '',      BLK: 'CD122', L: '18.6', B: '17.2', H: '2.5',  YARD: 'HHI',   SC_WF: 216.86, FB_WF: 433.72,  AS_WF: 650.58,  PE1_WF: 0,       PT_WF: 0,       PE2_WF: 0,       ER_WF: 0,      SC_PS: '2025-01-20', SC_PF: '2025-01-27', SC_PP: 100,   SC_AS: '2025-01-21', SC_AF: undefined,    SC_AP: 98,  FB_PS: '2025-01-31', FB_PF: '2025-02-26', FB_PP: 45.3,  FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: '2025-02-27', AS_PF: '2025-04-15', AS_PP: 0,    AS_AS: undefined,    AS_AF: undefined,    AS_AP: 0,    PE1_PS: undefined,    PE1_PF: undefined,    PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: undefined,    PT_PF: undefined,    PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: undefined,    PE2_PF: undefined,    PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: undefined,    ER_PF: undefined,    ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'TOPSIDE', ZONE: 'CENTRAL',        MOD: 'Production Deck',     LEVEL: 'Production Deck',     NAME: 'Column & Brace',         G_EREC: 'CD110', G_2PE: 'CD110', G_1PE: 'CC121', EREC: '',      PE2: '',      PE1: '',      PBLK: 'CC121', BLK: 'CC121', L: '15.9', B: '31',   H: '13',   YARD: 'HHI',   SC_WF: 87.65,  FB_WF: 175.31,  AS_WF: 262.96,  PE1_WF: 0,       PT_WF: 455.38,  PE2_WF: 0,       ER_WF: 0,      SC_PS: '2025-01-17', SC_PF: '2025-02-05', SC_PP: 100,   SC_AS: '2025-02-04', SC_AF: '2025-02-11', SC_AP: 100, FB_PS: '2025-02-06', FB_PF: '2025-02-19', FB_PP: 61,    FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: '2025-02-20', AS_PF: '2025-03-14', AS_PP: 0,    AS_AS: undefined,    AS_AF: undefined,    AS_AP: 0,    PE1_PS: undefined,    PE1_PF: undefined,    PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: '2025-04-16', PT_PF: '2025-05-20', PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: undefined,    PE2_PF: undefined,    PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: undefined,    ER_PF: undefined,    ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'TOPSIDE', ZONE: 'CENTRAL',        MOD: 'Production Deck',     LEVEL: 'Production Deck',     NAME: 'Access P/F',             G_EREC: 'CD110', G_2PE: 'CD110', G_1PE: 'CV1K1', EREC: '',      PE2: '',      PE1: '',      PBLK: 'CV1K1', BLK: 'CV1K1', L: '2.3',  B: '3.2',  H: '3.4',  YARD: 'HHI',   SC_WF: 8,      FB_WF: 0,       AS_WF: 24,      PE1_WF: 0,       PT_WF: 24.76,   PE2_WF: 16,      ER_WF: 0,      SC_PS: '2025-03-07', SC_PF: '2025-03-26', SC_PP: 0,     SC_AS: undefined,    SC_AF: undefined,    SC_AP: 0,   FB_PS: undefined,    FB_PF: undefined,    FB_PP: 0,     FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: '2025-03-27', AS_PF: '2025-05-14', AS_PP: 0,    AS_AS: undefined,    AS_AF: undefined,    AS_AP: 0,    PE1_PS: undefined,    PE1_PF: undefined,    PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: '2025-06-04', PT_PF: '2025-06-27', PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: '2025-06-30', PE2_PF: '2025-07-18', PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: undefined,    ER_PF: undefined,    ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'TOPSIDE', ZONE: 'CENTRAL',        MOD: 'Production Deck',     LEVEL: 'Production Deck',     NAME: 'Access P/F',             G_EREC: 'CD110', G_2PE: 'CD110', G_1PE: 'CV111', EREC: '',      PE2: '',      PE1: '',      PBLK: 'CV111', BLK: 'CV111', L: '2.3',  B: '3.2',  H: '3.4',  YARD: 'HHI',   SC_WF: 8,      FB_WF: 0,       AS_WF: 24,      PE1_WF: 0,       PT_WF: 24.76,   PE2_WF: 16,      ER_WF: 0,      SC_PS: '2025-03-07', SC_PF: '2025-03-26', SC_PP: 0,     SC_AS: undefined,    SC_AF: undefined,    SC_AP: 0,   FB_PS: undefined,    FB_PF: undefined,    FB_PP: 0,     FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: '2025-03-27', AS_PF: '2025-05-14', AS_PP: 0,    AS_AS: undefined,    AS_AF: undefined,    AS_AP: 0,    PE1_PS: undefined,    PE1_PF: undefined,    PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: '2025-06-04', PT_PF: '2025-06-27', PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: '2025-06-30', PE2_PF: '2025-07-18', PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: undefined,    ER_PF: undefined,    ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'TOPSIDE', ZONE: 'CENTRAL',        MOD: 'Production Deck',     LEVEL: 'Production Deck',     NAME: 'Access P/F',             G_EREC: 'CD110', G_2PE: 'CD110', G_1PE: 'CV121', EREC: '',      PE2: '',      PE1: '',      PBLK: 'CV121', BLK: 'CV121', L: '2.1',  B: '1.6',  H: '0.8',  YARD: 'HHI',   SC_WF: 8,      FB_WF: 0,       AS_WF: 24,      PE1_WF: 0,       PT_WF: 16,      PE2_WF: 16,      ER_WF: 0,      SC_PS: '2025-03-07', SC_PF: '2025-03-26', SC_PP: 0,     SC_AS: undefined,    SC_AF: undefined,    SC_AP: 0,   FB_PS: undefined,    FB_PF: undefined,    FB_PP: 0,     FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: '2025-03-27', AS_PF: '2025-05-14', AS_PP: 0,    AS_AS: undefined,    AS_AF: undefined,    AS_AP: 0,    PE1_PS: undefined,    PE1_PF: undefined,    PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: '2025-06-04', PT_PF: '2025-06-27', PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: '2025-06-30', PE2_PF: '2025-07-18', PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: undefined,    ER_PF: undefined,    ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'TOPSIDE', ZONE: 'CENTRAL',        MOD: 'Production Deck',     LEVEL: 'Production Deck',     NAME: 'Access P/F',             G_EREC: 'CD110', G_2PE: 'CD110', G_1PE: 'CV131', EREC: '',      PE2: '',      PE1: '',      PBLK: 'CV131', BLK: 'CV131', L: '1.6',  B: '1.9',  H: '1.2',  YARD: 'HHI',   SC_WF: 8,      FB_WF: 0,       AS_WF: 24,      PE1_WF: 0,       PT_WF: 15.76,   PE2_WF: 16,      ER_WF: 0,      SC_PS: '2025-03-07', SC_PF: '2025-03-26', SC_PP: 0,     SC_AS: undefined,    SC_AF: undefined,    SC_AP: 0,   FB_PS: undefined,    FB_PF: undefined,    FB_PP: 0,     FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: '2025-03-27', AS_PF: '2025-05-14', AS_PP: 0,    AS_AS: undefined,    AS_AF: undefined,    AS_AP: 0,    PE1_PS: undefined,    PE1_PF: undefined,    PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: '2025-06-04', PT_PF: '2025-06-27', PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: '2025-06-30', PE2_PF: '2025-07-18', PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: undefined,    ER_PF: undefined,    ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'TOPSIDE', ZONE: 'CENTRAL',        MOD: 'Production Deck',     LEVEL: 'Production Deck',     NAME: 'Access P/F',             G_EREC: 'CD110', G_2PE: 'CD110', G_1PE: 'CV141', EREC: '',      PE2: '',      PE1: '',      PBLK: 'CV141', BLK: 'CV141', L: '5.2',  B: '1',    H: '1.2',  YARD: 'HHI',   SC_WF: 8,      FB_WF: 0,       AS_WF: 24,      PE1_WF: 0,       PT_WF: 16,      PE2_WF: 16,      ER_WF: 0,      SC_PS: '2025-03-07', SC_PF: '2025-03-26', SC_PP: 0,     SC_AS: undefined,    SC_AF: undefined,    SC_AP: 0,   FB_PS: undefined,    FB_PF: undefined,    FB_PP: 0,     FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: '2025-03-27', AS_PF: '2025-05-14', AS_PP: 0,    AS_AS: undefined,    AS_AF: undefined,    AS_AP: 0,    PE1_PS: undefined,    PE1_PF: undefined,    PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: '2025-06-04', PT_PF: '2025-06-27', PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: '2025-06-30', PE2_PF: '2025-07-18', PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: undefined,    ER_PF: undefined,    ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'TOPSIDE', ZONE: 'CENTRAL',        MOD: 'Production Deck',     LEVEL: 'Production Deck',     NAME: 'Access P/F',             G_EREC: 'CD110', G_2PE: 'CD110', G_1PE: 'CV151', EREC: '',      PE2: '',      PE1: '',      PBLK: 'CV151', BLK: 'CV151', L: '6.5',  B: '3',    H: '2.5',  YARD: 'HHI',   SC_WF: 8,      FB_WF: 0,       AS_WF: 24,      PE1_WF: 0,       PT_WF: 75.72,   PE2_WF: 16,      ER_WF: 0,      SC_PS: '2025-03-07', SC_PF: '2025-03-26', SC_PP: 0,     SC_AS: undefined,    SC_AF: undefined,    SC_AP: 0,   FB_PS: undefined,    FB_PF: undefined,    FB_PP: 0,     FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: '2025-03-27', AS_PF: '2025-05-14', AS_PP: 0,    AS_AS: undefined,    AS_AF: undefined,    AS_AP: 0,    PE1_PS: undefined,    PE1_PF: undefined,    PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: '2025-06-04', PT_PF: '2025-06-27', PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: '2025-06-30', PE2_PF: '2025-07-18', PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: undefined,    ER_PF: undefined,    ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'TOPSIDE', ZONE: 'CENTRAL',        MOD: 'Production Deck',     LEVEL: 'Production Deck',     NAME: 'Access P/F',             G_EREC: 'CD110', G_2PE: 'CD110', G_1PE: 'CV161', EREC: '',      PE2: '',      PE1: '',      PBLK: 'CV161', BLK: 'CV161', L: '9.5',  B: '3.3',  H: '2.5',  YARD: 'HHI',   SC_WF: 8,      FB_WF: 0,       AS_WF: 24,      PE1_WF: 0,       PT_WF: 67.07,   PE2_WF: 16,      ER_WF: 0,      SC_PS: '2025-03-07', SC_PF: '2025-03-26', SC_PP: 0,     SC_AS: undefined,    SC_AF: undefined,    SC_AP: 0,   FB_PS: undefined,    FB_PF: undefined,    FB_PP: 0,     FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: '2025-03-27', AS_PF: '2025-05-14', AS_PP: 0,    AS_AS: undefined,    AS_AF: undefined,    AS_AP: 0,    PE1_PS: undefined,    PE1_PF: undefined,    PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: '2025-06-04', PT_PF: '2025-06-27', PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: '2025-06-30', PE2_PF: '2025-07-18', PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: undefined,    ER_PF: undefined,    ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'TOPSIDE', ZONE: 'CENTRAL',        MOD: 'Production Deck',     LEVEL: 'Production Deck',     NAME: 'Access P/F',             G_EREC: 'CD110', G_2PE: 'CD110', G_1PE: 'CV171', EREC: '',      PE2: '',      PE1: '',      PBLK: 'CV171', BLK: 'CV171', L: '6.9',  B: '2.7',  H: '2.9',  YARD: 'HHI',   SC_WF: 8,      FB_WF: 0,       AS_WF: 24,      PE1_WF: 0,       PT_WF: 68.39,   PE2_WF: 16,      ER_WF: 0,      SC_PS: '2025-03-07', SC_PF: '2025-03-26', SC_PP: 0,     SC_AS: undefined,    SC_AF: undefined,    SC_AP: 0,   FB_PS: undefined,    FB_PF: undefined,    FB_PP: 0,     FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: '2025-03-27', AS_PF: '2025-05-14', AS_PP: 0,    AS_AS: undefined,    AS_AF: undefined,    AS_AP: 0,    PE1_PS: undefined,    PE1_PF: undefined,    PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: '2025-06-04', PT_PF: '2025-06-27', PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: '2025-06-30', PE2_PF: '2025-07-18', PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: undefined,    ER_PF: undefined,    ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'TOPSIDE', ZONE: 'CENTRAL',        MOD: 'Production Deck',     LEVEL: 'Production Deck',     NAME: 'Access P/F',             G_EREC: 'CD110', G_2PE: 'CD110', G_1PE: 'CV181', EREC: '',      PE2: '',      PE1: '',      PBLK: 'CV181', BLK: 'CV181', L: '8.2',  B: '1.8',  H: '0.8',  YARD: 'HHI',   SC_WF: 8,      FB_WF: 0,       AS_WF: 24,      PE1_WF: 0,       PT_WF: 24,      PE2_WF: 16,      ER_WF: 0,      SC_PS: '2025-03-07', SC_PF: '2025-03-26', SC_PP: 0,     SC_AS: undefined,    SC_AF: undefined,    SC_AP: 0,   FB_PS: undefined,    FB_PF: undefined,    FB_PP: 0,     FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: '2025-03-27', AS_PF: '2025-05-12', AS_PP: 0,    AS_AS: undefined,    AS_AF: undefined,    AS_AP: 0,    PE1_PS: undefined,    PE1_PF: undefined,    PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: '2025-06-02', PT_PF: '2025-06-25', PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: '2025-06-26', PE2_PF: '2025-07-16', PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: undefined,    ER_PF: undefined,    ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'TOPSIDE', ZONE: 'CENTRAL',        MOD: 'Production Deck',     LEVEL: 'Production Deck',     NAME: 'Deck Block',             G_EREC: 'CD130', G_2PE: 'CD130', G_1PE: 'CD130', EREC: 'CD130', PE2: 'CD130', PE1: 'CD130', PBLK: 'CD130', BLK: 'CD131', L: '17.6', B: '15.9', H: '2',    YARD: 'HHI',   SC_WF: 194.99, FB_WF: 389.98,  AS_WF: 584.97,  PE1_WF: 2151.28, PT_WF: 2060.4,  PE2_WF: 2151.28, ER_WF: 5822.9, SC_PS: '2025-01-23', SC_PF: '2025-02-06', SC_PP: 100,   SC_AS: '2025-01-21', SC_AF: undefined,    SC_AP: 83,  FB_PS: '2025-02-07', FB_PF: '2025-03-05', FB_PP: 22.1,  FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: '2025-03-06', AS_PF: '2025-04-22', AS_PP: 0,    AS_AS: undefined,    AS_AF: undefined,    AS_AP: 0,    PE1_PS: '2025-04-23', PE1_PF: '2025-05-27', PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: '2025-05-28', PT_PF: '2025-06-20', PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: '2025-06-23', PE2_PF: '2025-08-11', PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: '2025-08-12', ER_PF: '2026-03-10', ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'TOPSIDE', ZONE: 'CENTRAL',        MOD: 'Production Deck',     LEVEL: 'Production Deck',     NAME: 'Deck Block',             G_EREC: 'CD130', G_2PE: 'CD130', G_1PE: 'CD130', EREC: '',      PE2: '',      PE1: '',      PBLK: '',      BLK: 'CD132', L: '15.9', B: '15.7', H: '1.5',  YARD: 'HHI',   SC_WF: 164.01, FB_WF: 328.02,  AS_WF: 492.03,  PE1_WF: 0,       PT_WF: 0,       PE2_WF: 0,       ER_WF: 0,      SC_PS: '2025-01-23', SC_PF: '2025-02-06', SC_PP: 100,   SC_AS: '2025-01-21', SC_AF: undefined,    SC_AP: 83,  FB_PS: '2025-02-07', FB_PF: '2025-03-05', FB_PP: 22.1,  FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: '2025-03-06', AS_PF: '2025-04-22', AS_PP: 0,    AS_AS: undefined,    AS_AF: undefined,    AS_AP: 0,    PE1_PS: undefined,    PE1_PF: undefined,    PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: undefined,    PT_PF: undefined,    PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: undefined,    PE2_PF: undefined,    PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: undefined,    ER_PF: undefined,    ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'TOPSIDE', ZONE: 'CENTRAL',        MOD: 'Production Deck',     LEVEL: 'Production Deck',     NAME: 'Column & Brace',         G_EREC: 'CD130', G_2PE: 'CD130', G_1PE: 'CC131', EREC: '',      PE2: '',      PE1: '',      PBLK: 'CC131', BLK: 'CC131', L: '38.7', B: '14.7', H: '13',   YARD: 'HHI',   SC_WF: 71.25,  FB_WF: 142.51,  AS_WF: 213.76,  PE1_WF: 0,       PT_WF: 413.98,  PE2_WF: 0,       ER_WF: 0,      SC_PS: '2025-01-24', SC_PF: '2025-02-12', SC_PP: 100,   SC_AS: '2025-01-31', SC_AF: undefined,    SC_AP: 12,  FB_PS: '2025-02-13', FB_PF: '2025-02-26', FB_PP: 14,    FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: '2025-02-27', AS_PF: '2025-03-21', AS_PP: 0,    AS_AS: undefined,    AS_AF: undefined,    AS_AP: 0,    PE1_PS: undefined,    PE1_PF: undefined,    PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: '2025-04-23', PT_PF: '2025-05-27', PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: undefined,    PE2_PF: undefined,    PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: undefined,    ER_PF: undefined,    ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'TOPSIDE', ZONE: 'CENTRAL',        MOD: 'Production Deck',     LEVEL: 'Production Deck',     NAME: 'Deck Block',             G_EREC: 'CD130', G_2PE: 'CD130', G_1PE: 'CD140', EREC: '',      PE2: '',      PE1: 'CD140', PBLK: 'CD140', BLK: 'CD141', L: '17.6', B: '17.2', H: '1.5',  YARD: 'HHI',   SC_WF: 215.04, FB_WF: 430.07,  AS_WF: 645.11,  PE1_WF: 2400.03, PT_WF: 2269.05, PE2_WF: 2400.03, ER_WF: 0,      SC_PS: '2025-01-20', SC_PF: '2025-02-03', SC_PP: 100,   SC_AS: '2025-01-21', SC_AF: undefined,    SC_AP: 88,  FB_PS: '2025-02-04', FB_PF: '2025-02-28', FB_PP: 33.2,  FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: '2025-03-03', AS_PF: '2025-04-17', AS_PP: 0,    AS_AS: undefined,    AS_AF: undefined,    AS_AP: 0,    PE1_PS: '2025-04-18', PE1_PF: '2025-05-22', PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: '2025-05-23', PT_PF: '2025-06-17', PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: '2025-06-18', PE2_PF: '2025-07-25', PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: undefined,    ER_PF: undefined,    ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'TOPSIDE', ZONE: 'CENTRAL',        MOD: 'Production Deck',     LEVEL: 'Production Deck',     NAME: 'Deck Block',             G_EREC: 'CD130', G_2PE: 'CD130', G_1PE: 'CD140', EREC: '',      PE2: '',      PE1: '',      PBLK: '',      BLK: 'CD142', L: '15.9', B: '16.8', H: '1.5',  YARD: 'HHI',   SC_WF: 182.23, FB_WF: 364.47,  AS_WF: 546.7,   PE1_WF: 0,       PT_WF: 0,       PE2_WF: 0,       ER_WF: 0,      SC_PS: '2025-01-20', SC_PF: '2025-02-03', SC_PP: 100,   SC_AS: '2025-01-21', SC_AF: undefined,    SC_AP: 88,  FB_PS: '2025-02-04', FB_PF: '2025-02-28', FB_PP: 33.2,  FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: '2025-03-03', AS_PF: '2025-04-17', AS_PP: 0,    AS_AS: undefined,    AS_AF: undefined,    AS_AP: 0,    PE1_PS: undefined,    PE1_PF: undefined,    PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: undefined,    PT_PF: undefined,    PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: undefined,    PE2_PF: undefined,    PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: undefined,    ER_PF: undefined,    ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'TOPSIDE', ZONE: 'CENTRAL',        MOD: 'Production Deck',     LEVEL: 'Production Deck',     NAME: 'Column & Brace',         G_EREC: 'CD130', G_2PE: 'CD130', G_1PE: 'CC141', EREC: '',      PE2: '',      PE1: '',      PBLK: 'CC141', BLK: 'CC141', L: '39.1', B: '16',   H: '13',   YARD: 'HHI',   SC_WF: 82.73,  FB_WF: 165.47,  AS_WF: 248.2,   PE1_WF: 0,       PT_WF: 455.38,  PE2_WF: 0,       ER_WF: 0,      SC_PS: '2025-01-21', SC_PF: '2025-02-07', SC_PP: 100,   SC_AS: undefined,    SC_AF: undefined,    SC_AP: 0,   FB_PS: '2025-02-10', FB_PF: '2025-02-21', FB_PP: 35,    FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: '2025-02-24', AS_PF: '2025-03-18', AS_PP: 0,    AS_AS: undefined,    AS_AF: undefined,    AS_AP: 0,    PE1_PS: undefined,    PE1_PF: undefined,    PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: '2025-04-18', PT_PF: '2025-05-22', PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: undefined,    PE2_PF: undefined,    PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: undefined,    ER_PF: undefined,    ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'TOPSIDE', ZONE: 'CENTRAL',        MOD: 'Production Deck',     LEVEL: 'Production Deck',     NAME: 'Deck Block',             G_EREC: 'CD130', G_2PE: 'CD130', G_1PE: 'CD150', EREC: '',      PE2: '',      PE1: 'CD150', PBLK: 'CD150', BLK: 'CD151', L: '15.2', B: '15.7', H: '1.7',  YARD: 'HHI',   SC_WF: 151.25, FB_WF: 302.51,  AS_WF: 453.76,  PE1_WF: 1795.92, PT_WF: 1782.45, PE2_WF: 1795.92, ER_WF: 0,      SC_PS: '2025-01-27', SC_PF: '2025-02-10', SC_PP: 100,   SC_AS: '2025-01-31', SC_AF: undefined,    SC_AP: 70,  FB_PS: '2025-02-11', FB_PF: '2025-03-07', FB_PP: 14.7,  FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: '2025-03-10', AS_PF: '2025-04-24', AS_PP: 0,    AS_AS: undefined,    AS_AF: undefined,    AS_AP: 0,    PE1_PS: '2025-04-25', PE1_PF: '2025-05-29', PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: '2025-05-30', PT_PF: '2025-06-24', PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: '2025-06-25', PE2_PF: '2025-07-25', PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: undefined,    ER_PF: undefined,    ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'TOPSIDE', ZONE: 'CENTRAL',        MOD: 'Production Deck',     LEVEL: 'Production Deck',     NAME: 'Deck Block',             G_EREC: 'CD130', G_2PE: 'CD130', G_1PE: 'CD150', EREC: '',      PE2: '',      PE1: '',      PBLK: '',      BLK: 'CD152', L: '14.3', B: '17',   H: '1.5',  YARD: 'HHI',   SC_WF: 153.08, FB_WF: 306.15,  AS_WF: 459.23,  PE1_WF: 0,       PT_WF: 0,       PE2_WF: 0,       ER_WF: 0,      SC_PS: '2025-01-27', SC_PF: '2025-02-10', SC_PP: 100,   SC_AS: '2025-01-31', SC_AF: undefined,    SC_AP: 70,  FB_PS: '2025-02-11', FB_PF: '2025-03-07', FB_PP: 14.7,  FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: '2025-03-10', AS_PF: '2025-04-24', AS_PP: 0,    AS_AS: undefined,    AS_AF: undefined,    AS_AP: 0,    PE1_PS: undefined,    PE1_PF: undefined,    PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: undefined,    PT_PF: undefined,    PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: undefined,    PE2_PF: undefined,    PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: undefined,    ER_PF: undefined,    ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'TOPSIDE', ZONE: 'CENTRAL',        MOD: 'Production Deck',     LEVEL: 'Production Deck',     NAME: 'Column & Brace',         G_EREC: 'CD130', G_2PE: 'CD130', G_1PE: 'CC151', EREC: '',      PE2: '',      PE1: '',      PBLK: 'CC151', BLK: 'CC151', L: '2.4',  B: '16.1', H: '11.5', YARD: 'HHI',   SC_WF: 54.85,  FB_WF: 109.71,  AS_WF: 164.56,  PE1_WF: 0,       PT_WF: 356.03,  PE2_WF: 0,       ER_WF: 0,      SC_PS: '2025-01-31', SC_PF: '2025-02-14', SC_PP: 100,   SC_AS: undefined,    SC_AF: undefined,    SC_AP: 0,   FB_PS: '2025-02-17', FB_PF: '2025-02-28', FB_PP: 0,     FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: '2025-03-03', AS_PF: '2025-03-25', AS_PP: 0,    AS_AS: undefined,    AS_AF: undefined,    AS_AP: 0,    PE1_PS: undefined,    PE1_PF: undefined,    PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: '2025-04-25', PT_PF: '2025-05-29', PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: undefined,    PE2_PF: undefined,    PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: undefined,    ER_PF: undefined,    ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'TOPSIDE', ZONE: 'CENTRAL',        MOD: 'Production Deck',     LEVEL: 'Production Deck',     NAME: 'Access P/F',             G_EREC: 'CD130', G_2PE: 'CD130', G_1PE: 'CV191', EREC: '',      PE2: '',      PE1: '',      PBLK: 'CV191', BLK: 'CV191', L: '5',    B: '6.4',  H: '2.9',  YARD: 'HHI',   SC_WF: 8,      FB_WF: 0,       AS_WF: 24,      PE1_WF: 0,       PT_WF: 117.99,  PE2_WF: 16,      ER_WF: 0,      SC_PS: '2025-03-17', SC_PF: '2025-04-03', SC_PP: 0,     SC_AS: undefined,    SC_AF: undefined,    SC_AP: 0,   FB_PS: undefined,    FB_PF: undefined,    FB_PP: 0,     FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: '2025-04-04', AS_PF: '2025-05-22', AS_PP: 0,    AS_AS: undefined,    AS_AF: undefined,    AS_AP: 0,    PE1_PS: undefined,    PE1_PF: undefined,    PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: '2025-06-13', PT_PF: '2025-07-07', PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: '2025-07-08', PE2_PF: '2025-07-25', PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: undefined,    ER_PF: undefined,    ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'TOPSIDE', ZONE: 'CENTRAL',        MOD: 'Production Deck',     LEVEL: 'Production Deck',     NAME: 'Access P/F',             G_EREC: 'CD130', G_2PE: 'CD130', G_1PE: 'CV1A1', EREC: '',      PE2: '',      PE1: '',      PBLK: 'CV1A1', BLK: 'CV1A1', L: '3.2',  B: '1.9',  H: '1.7',  YARD: 'HHI',   SC_WF: 8,      FB_WF: 0,       AS_WF: 24,      PE1_WF: 0,       PT_WF: 24,      PE2_WF: 16,      ER_WF: 0,      SC_PS: '2025-03-17', SC_PF: '2025-04-03', SC_PP: 0,     SC_AS: undefined,    SC_AF: undefined,    SC_AP: 0,   FB_PS: undefined,    FB_PF: undefined,    FB_PP: 0,     FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: '2025-04-04', AS_PF: '2025-05-22', AS_PP: 0,    AS_AS: undefined,    AS_AF: undefined,    AS_AP: 0,    PE1_PS: undefined,    PE1_PF: undefined,    PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: '2025-06-13', PT_PF: '2025-07-07', PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: '2025-07-08', PE2_PF: '2025-07-25', PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: undefined,    ER_PF: undefined,    ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'TOPSIDE', ZONE: 'CENTRAL',        MOD: 'Production Deck',     LEVEL: 'Production Deck',     NAME: 'Access P/F',             G_EREC: 'CD130', G_2PE: 'CD130', G_1PE: 'CV1B1', EREC: '',      PE2: '',      PE1: '',      PBLK: 'CV1B1', BLK: 'CV1B1', L: '4.9',  B: '4.9',  H: '5.8',  YARD: 'HHI',   SC_WF: 8,      FB_WF: 0,       AS_WF: 24,      PE1_WF: 0,       PT_WF: 78.66,   PE2_WF: 16,      ER_WF: 0,      SC_PS: '2025-03-17', SC_PF: '2025-04-03', SC_PP: 0,     SC_AS: undefined,    SC_AF: undefined,    SC_AP: 0,   FB_PS: undefined,    FB_PF: undefined,    FB_PP: 0,     FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: '2025-04-04', AS_PF: '2025-05-22', AS_PP: 0,    AS_AS: undefined,    AS_AF: undefined,    AS_AP: 0,    PE1_PS: undefined,    PE1_PF: undefined,    PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: '2025-06-13', PT_PF: '2025-07-07', PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: '2025-07-08', PE2_PF: '2025-07-25', PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: undefined,    ER_PF: undefined,    ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'TOPSIDE', ZONE: 'CENTRAL',        MOD: 'Production Deck',     LEVEL: 'Production Deck',     NAME: 'Access P/F',             G_EREC: 'CD130', G_2PE: 'CD130', G_1PE: 'CV1C1', EREC: '',      PE2: '',      PE1: '',      PBLK: 'CV1C1', BLK: 'CV1C1', L: '9.9',  B: '1.4',  H: '6.3',  YARD: 'HHI',   SC_WF: 8,      FB_WF: 0,       AS_WF: 24,      PE1_WF: 0,       PT_WF: 16,      PE2_WF: 16,      ER_WF: 0,      SC_PS: '2025-03-17', SC_PF: '2025-04-03', SC_PP: 0,     SC_AS: undefined,    SC_AF: undefined,    SC_AP: 0,   FB_PS: undefined,    FB_PF: undefined,    FB_PP: 0,     FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: '2025-04-04', AS_PF: '2025-05-22', AS_PP: 0,    AS_AS: undefined,    AS_AF: undefined,    AS_AP: 0,    PE1_PS: undefined,    PE1_PF: undefined,    PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: '2025-06-13', PT_PF: '2025-07-07', PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: '2025-07-08', PE2_PF: '2025-07-25', PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: undefined,    ER_PF: undefined,    ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'TOPSIDE', ZONE: 'CENTRAL',        MOD: 'Production Deck',     LEVEL: 'Production Deck',     NAME: 'Access P/F',             G_EREC: 'CD130', G_2PE: 'CD130', G_1PE: 'CV1D1', EREC: '',      PE2: '',      PE1: '',      PBLK: 'CV1D1', BLK: 'CV1D1', L: '1.8',  B: '3.2',  H: '3.9',  YARD: 'HHI',   SC_WF: 8,      FB_WF: 0,       AS_WF: 24,      PE1_WF: 0,       PT_WF: 16.15,   PE2_WF: 16,      ER_WF: 0,      SC_PS: '2025-03-17', SC_PF: '2025-04-03', SC_PP: 0,     SC_AS: undefined,    SC_AF: undefined,    SC_AP: 0,   FB_PS: undefined,    FB_PF: undefined,    FB_PP: 0,     FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: '2025-04-04', AS_PF: '2025-05-22', AS_PP: 0,    AS_AS: undefined,    AS_AF: undefined,    AS_AP: 0,    PE1_PS: undefined,    PE1_PF: undefined,    PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: '2025-06-13', PT_PF: '2025-07-07', PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: '2025-07-08', PE2_PF: '2025-07-25', PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: undefined,    ER_PF: undefined,    ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'TOPSIDE', ZONE: 'CENTRAL',        MOD: 'Production Deck',     LEVEL: 'Production Deck',     NAME: 'Access P/F',             G_EREC: 'CD130', G_2PE: 'CD130', G_1PE: 'CV1E1', EREC: '',      PE2: '',      PE1: '',      PBLK: 'CV1E1', BLK: 'CV1E1', L: '5.7',  B: '4.1',  H: '1.5',  YARD: 'HHI',   SC_WF: 8,      FB_WF: 0,       AS_WF: 24,      PE1_WF: 0,       PT_WF: 81.64,   PE2_WF: 16,      ER_WF: 0,      SC_PS: '2025-03-17', SC_PF: '2025-04-03', SC_PP: 0,     SC_AS: undefined,    SC_AF: undefined,    SC_AP: 0,   FB_PS: undefined,    FB_PF: undefined,    FB_PP: 0,     FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: '2025-04-04', AS_PF: '2025-05-22', AS_PP: 0,    AS_AS: undefined,    AS_AF: undefined,    AS_AP: 0,    PE1_PS: undefined,    PE1_PF: undefined,    PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: '2025-06-13', PT_PF: '2025-07-07', PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: '2025-07-08', PE2_PF: '2025-07-25', PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: undefined,    ER_PF: undefined,    ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'TOPSIDE', ZONE: 'CENTRAL',        MOD: 'Production Deck',     LEVEL: 'Production Deck',     NAME: 'Access P/F',             G_EREC: 'CD130', G_2PE: 'CD130', G_1PE: 'CV1F1', EREC: '',      PE2: '',      PE1: '',      PBLK: 'CV1F1', BLK: 'CV1F1', L: '2.2',  B: '2.4',  H: '1.5',  YARD: 'HHI',   SC_WF: 8,      FB_WF: 0,       AS_WF: 24,      PE1_WF: 0,       PT_WF: 24,      PE2_WF: 16,      ER_WF: 0,      SC_PS: '2025-03-17', SC_PF: '2025-04-03', SC_PP: 0,     SC_AS: undefined,    SC_AF: undefined,    SC_AP: 0,   FB_PS: undefined,    FB_PF: undefined,    FB_PP: 0,     FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: '2025-04-04', AS_PF: '2025-05-22', AS_PP: 0,    AS_AS: undefined,    AS_AF: undefined,    AS_AP: 0,    PE1_PS: undefined,    PE1_PF: undefined,    PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: '2025-06-13', PT_PF: '2025-07-07', PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: '2025-07-08', PE2_PF: '2025-07-25', PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: undefined,    ER_PF: undefined,    ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'TOPSIDE', ZONE: 'CENTRAL',        MOD: 'Production Deck',     LEVEL: 'Production Deck',     NAME: 'Access P/F',             G_EREC: 'CD130', G_2PE: 'CD130', G_1PE: 'CV1G1', EREC: '',      PE2: '',      PE1: '',      PBLK: 'CV1G1', BLK: 'CV1G1', L: '8',    B: '4.7',  H: '1',    YARD: 'HHI',   SC_WF: 8,      FB_WF: 0,       AS_WF: 24,      PE1_WF: 0,       PT_WF: 102.46,  PE2_WF: 16,      ER_WF: 0,      SC_PS: '2025-03-17', SC_PF: '2025-04-03', SC_PP: 0,     SC_AS: undefined,    SC_AF: undefined,    SC_AP: 0,   FB_PS: undefined,    FB_PF: undefined,    FB_PP: 0,     FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: '2025-04-04', AS_PF: '2025-05-22', AS_PP: 0,    AS_AS: undefined,    AS_AF: undefined,    AS_AP: 0,    PE1_PS: undefined,    PE1_PF: undefined,    PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: '2025-06-13', PT_PF: '2025-07-07', PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: '2025-07-08', PE2_PF: '2025-07-25', PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: undefined,    ER_PF: undefined,    ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'TOPSIDE', ZONE: 'CENTRAL',        MOD: 'Production Deck',     LEVEL: 'Production Deck',     NAME: 'Access P/F',             G_EREC: 'CD130', G_2PE: 'CD130', G_1PE: 'CV1H1', EREC: '',      PE2: '',      PE1: '',      PBLK: 'CV1H1', BLK: 'CV1H1', L: '4.3',  B: '1.7',  H: '3.5',  YARD: 'HHI',   SC_WF: 8,      FB_WF: 0,       AS_WF: 24,      PE1_WF: 0,       PT_WF: 29.1,    PE2_WF: 16,      ER_WF: 0,      SC_PS: '2025-03-17', SC_PF: '2025-04-03', SC_PP: 0,     SC_AS: undefined,    SC_AF: undefined,    SC_AP: 0,   FB_PS: undefined,    FB_PF: undefined,    FB_PP: 0,     FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: '2025-04-04', AS_PF: '2025-05-22', AS_PP: 0,    AS_AS: undefined,    AS_AF: undefined,    AS_AP: 0,    PE1_PS: undefined,    PE1_PF: undefined,    PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: '2025-06-13', PT_PF: '2025-07-07', PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: '2025-07-08', PE2_PF: '2025-07-25', PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: undefined,    ER_PF: undefined,    ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'TOPSIDE', ZONE: 'CENTRAL',        MOD: 'Production Deck',     LEVEL: 'Production Deck',     NAME: 'Access P/F',             G_EREC: 'CD130', G_2PE: 'CD130', G_1PE: 'CV1J1', EREC: '',      PE2: '',      PE1: '',      PBLK: 'CV1J1', BLK: 'CV1J1', L: '1.8',  B: '2.4',  H: '1.6',  YARD: 'HHI',   SC_WF: 8,      FB_WF: 0,       AS_WF: 24,      PE1_WF: 0,       PT_WF: 16,      PE2_WF: 16,      ER_WF: 0,      SC_PS: '2025-03-17', SC_PF: '2025-04-03', SC_PP: 0,     SC_AS: undefined,    SC_AF: undefined,    SC_AP: 0,   FB_PS: undefined,    FB_PF: undefined,    FB_PP: 0,     FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: '2025-04-04', AS_PF: '2025-05-22', AS_PP: 0,    AS_AS: undefined,    AS_AF: undefined,    AS_AP: 0,    PE1_PS: undefined,    PE1_PF: undefined,    PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: '2025-06-13', PT_PF: '2025-07-07', PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: '2025-07-08', PE2_PF: '2025-07-25', PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: undefined,    ER_PF: undefined,    ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'TOPSIDE', ZONE: 'CENTRAL',        MOD: 'Production Deck',     LEVEL: 'Production Deck',     NAME: 'Access P/F',             G_EREC: 'CD130', G_2PE: 'CD130', G_1PE: 'CV1L1', EREC: '',      PE2: '',      PE1: '',      PBLK: 'CV1L1', BLK: 'CV1L1', L: '1.8',  B: '2.4',  H: '1.6',  YARD: 'HHI',   SC_WF: 8,      FB_WF: 0,       AS_WF: 24,      PE1_WF: 0,       PT_WF: 16,      PE2_WF: 16,      ER_WF: 0,      SC_PS: '2025-03-17', SC_PF: '2025-04-03', SC_PP: 0,     SC_AS: undefined,    SC_AF: undefined,    SC_AP: 0,   FB_PS: undefined,    FB_PF: undefined,    FB_PP: 0,     FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: '2025-04-04', AS_PF: '2025-05-22', AS_PP: 0,    AS_AS: undefined,    AS_AF: undefined,    AS_AP: 0,    PE1_PS: undefined,    PE1_PF: undefined,    PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: '2025-06-13', PT_PF: '2025-07-07', PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: '2025-07-08', PE2_PF: '2025-07-25', PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: undefined,    ER_PF: undefined,    ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'TOPSIDE', ZONE: 'CENTRAL',        MOD: 'Mezz. Deck',          LEVEL: 'Mezz. Deck',          NAME: 'Deck Block',             G_EREC: 'CD210', G_2PE: 'CD210', G_1PE: 'CD211', EREC: 'CD210', PE2: '',      PE1: '',      PBLK: 'CD211', BLK: 'CD211', L: '12.7', B: '14.6', H: '7.6',  YARD: 'HHI',   SC_WF: 69.25,  FB_WF: 138.5,   AS_WF: 207.75,  PE1_WF: 0,       PT_WF: 762.35,  PE2_WF: 65.6,    ER_WF: 5822.9, SC_PS: '2025-02-18', SC_PF: '2025-02-26', SC_PP: 0,     SC_AS: undefined,    SC_AF: undefined,    SC_AP: 0,   FB_PS: '2025-02-27', FB_PF: '2025-03-26', FB_PP: 0,     FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: '2025-03-27', AS_PF: '2025-05-16', AS_PP: 0,    AS_AS: undefined,    AS_AF: undefined,    AS_AP: 0,    PE1_PS: undefined,    PE1_PF: undefined,    PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: '2025-06-19', PT_PF: '2025-07-11', PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: '2025-07-14', PE2_PF: '2025-09-01', PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: '2025-09-02', ER_PF: '2026-03-10', ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'TOPSIDE', ZONE: 'CENTRAL',        MOD: 'Mezz. Deck',          LEVEL: 'Mezz. Deck',          NAME: 'Deck Block',             G_EREC: 'CD220', G_2PE: 'CD220', G_1PE: 'CD220', EREC: 'CD220', PE2: '',      PE1: 'CD220', PBLK: 'CD221', BLK: 'CD221', L: '17.1', B: '16',   H: '0.7',  YARD: 'HHI',   SC_WF: 83.83,  FB_WF: 167.66,  AS_WF: 251.48,  PE1_WF: 0,       PT_WF: 1111.96, PE2_WF: 94.76,   ER_WF: 5822.9, SC_PS: '2025-02-24', SC_PF: '2025-03-13', SC_PP: 0,     SC_AS: undefined,    SC_AF: undefined,    SC_AP: 0,   FB_PS: '2025-03-14', FB_PF: '2025-04-10', FB_PP: 0,     FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: '2025-04-11', AS_PF: '2025-06-02', AS_PP: 0,    AS_AS: undefined,    AS_AF: undefined,    AS_AP: 0,    PE1_PS: undefined,    PE1_PF: undefined,    PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: '2025-07-04', PT_PF: '2025-07-25', PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: '2025-08-08', PE2_PF: '2025-09-16', PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: '2025-09-17', ER_PF: '2026-03-10', ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'TOPSIDE', ZONE: 'CENTRAL',        MOD: 'Mezz. Deck',          LEVEL: 'Mezz. Deck',          NAME: 'Access P/F',             G_EREC: 'CD220', G_2PE: 'CD220', G_1PE: 'CD220', EREC: '',      PE2: '',      PE1: '',      PBLK: 'CV211', BLK: 'CV211', L: '3.7',  B: '3.7',  H: '2.8',  YARD: 'HHI',   SC_WF: 8,      FB_WF: 0,       AS_WF: 24,      PE1_WF: 0,       PT_WF: 47.19,   PE2_WF: 16,      ER_WF: 0,      SC_PS: '2025-04-21', SC_PF: '2025-05-13', SC_PP: 0,     SC_AS: undefined,    SC_AF: undefined,    SC_AP: 0,   FB_PS: undefined,    FB_PF: undefined,    FB_PP: 0,     FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: '2025-05-14', AS_PF: '2025-06-27', AS_PP: 0,    AS_AS: undefined,    AS_AF: undefined,    AS_AP: 0,    PE1_PS: undefined,    PE1_PF: undefined,    PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: '2025-07-18', PT_PF: '2025-08-22', PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: '2025-08-25', PE2_PF: '2025-09-12', PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: undefined,    ER_PF: undefined,    ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'TOPSIDE', ZONE: 'CENTRAL',        MOD: 'Mezz. Deck',          LEVEL: 'Mezz. Deck',          NAME: 'Deck Block',             G_EREC: 'CD230', G_2PE: 'CD230', G_1PE: 'CD231', EREC: 'CD230', PE2: '',      PE1: '',      PBLK: 'CD231', BLK: 'CD231', L: '12.7', B: '12.8', H: '7.6',  YARD: 'HHI',   SC_WF: 65.6,   FB_WF: 131.21,  AS_WF: 196.81,  PE1_WF: 0,       PT_WF: 672.97,  PE2_WF: 58.32,   ER_WF: 5822.9, SC_PS: '2025-02-26', SC_PF: '2025-03-13', SC_PP: 0,     SC_AS: undefined,    SC_AF: undefined,    SC_AP: 0,   FB_PS: '2025-03-14', FB_PF: '2025-04-07', FB_PP: 0,     FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: '2025-04-08', AS_PF: '2025-05-19', AS_PP: 0,    AS_AS: undefined,    AS_AF: undefined,    AS_AP: 0,    PE1_PS: undefined,    PE1_PF: undefined,    PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: '2025-06-20', PT_PF: '2025-07-11', PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: '2025-07-14', PE2_PF: '2025-08-29', PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: '2025-09-17', ER_PF: '2026-03-10', ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'TOPSIDE', ZONE: 'CENTRAL',        MOD: 'Mezz. Deck',          LEVEL: 'Mezz. Deck',          NAME: 'Deck Block',             G_EREC: 'CD240', G_2PE: 'CD240', G_1PE: 'CD240', EREC: 'CD240', PE2: '',      PE1: 'CD240', PBLK: 'CD241', BLK: 'CD241', L: '17.2', B: '12.8', H: '0.8',  YARD: 'HHI',   SC_WF: 74.72,  FB_WF: 149.43,  AS_WF: 224.15,  PE1_WF: 282.46,  PT_WF: 1595,    PE2_WF: 112.99,  ER_WF: 5822.9, SC_PS: '2025-02-25', SC_PF: '2025-03-14', SC_PP: 0,     SC_AS: undefined,    SC_AF: undefined,    SC_AP: 0,   FB_PS: '2025-03-17', FB_PF: '2025-04-11', FB_PP: 0,     FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: '2025-04-14', AS_PF: '2025-06-03', AS_PP: 0,    AS_AS: undefined,    AS_AF: undefined,    AS_AP: 0,    PE1_PS: '2025-06-04', PE1_PF: '2025-07-04', PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: '2025-07-07', PT_PF: '2025-07-25', PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: '2025-08-08', PE2_PF: '2025-09-15', PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: '2025-09-18', ER_PF: '2026-03-10', ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'TOPSIDE', ZONE: 'CENTRAL',        MOD: 'Mezz. Deck',          LEVEL: 'Mezz. Deck',          NAME: 'Deck Block',             G_EREC: 'CD240', G_2PE: 'CD240', G_1PE: 'CD240', EREC: '',      PE2: '',      PE1: '',      PBLK: '',      BLK: 'CD242', L: '13.2', B: '8.8',  H: '6',    YARD: 'HHI',   SC_WF: 24.3,   FB_WF: 36.45,   AS_WF: 54.67,   PE1_WF: 0,       PT_WF: 0,       PE2_WF: 0,       ER_WF: 0,      SC_PS: '2025-03-20', SC_PF: '2025-03-28', SC_PP: 0,     SC_AS: undefined,    SC_AF: undefined,    SC_AP: 0,   FB_PS: '2025-03-31', FB_PF: '2025-04-22', FB_PP: 0,     FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: '2025-04-23', AS_PF: '2025-06-03', AS_PP: 0,    AS_AS: undefined,    AS_AF: undefined,    AS_AP: 0,    PE1_PS: undefined,    PE1_PF: undefined,    PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: undefined,    PT_PF: undefined,    PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: undefined,    PE2_PF: undefined,    PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: undefined,    ER_PF: undefined,    ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'TOPSIDE', ZONE: 'CENTRAL',        MOD: 'Mezz. Deck',          LEVEL: 'Mezz. Deck',          NAME: 'Deck Block',             G_EREC: 'CD250', G_2PE: 'CD250', G_1PE: 'CD250', EREC: 'CD250', PE2: '',      PE1: 'CD250', PBLK: 'CD251', BLK: 'CD251', L: '17.4', B: '7.2',  H: '6',    YARD: 'HHI',   SC_WF: 24,     FB_WF: 43.74,   AS_WF: 65.6,    PE1_WF: 273.35,  PT_WF: 1484.34, PE2_WF: 109.34,  ER_WF: 5822.9, SC_PS: '2025-04-04', SC_PF: '2025-04-14', SC_PP: 0,     SC_AS: undefined,    SC_AF: undefined,    SC_AP: 0,   FB_PS: '2025-04-15', FB_PF: '2025-05-12', FB_PP: 0,     FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: '2025-05-13', AS_PF: '2025-06-19', AS_PP: 0,    AS_AS: undefined,    AS_AF: undefined,    AS_AP: 0,    PE1_PS: '2025-06-20', PE1_PF: '2025-07-21', PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: '2025-07-22', PT_PF: '2025-08-26', PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: '2025-08-27', PE2_PF: '2025-09-18', PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: '2025-09-19', ER_PF: '2026-03-10', ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'TOPSIDE', ZONE: 'CENTRAL',        MOD: 'Mezz. Deck',          LEVEL: 'Mezz. Deck',          NAME: 'Deck Block',             G_EREC: 'CD250', G_2PE: 'CD250', G_1PE: 'CD250', EREC: '',      PE2: '',      PE1: '',      PBLK: '',      BLK: 'CD252', L: '18.2', B: '10.1', H: '6',    YARD: 'HHI',   SC_WF: 78.36,  FB_WF: 156.72,  AS_WF: 235.08,  PE1_WF: 0,       PT_WF: 0,       PE2_WF: 0,       ER_WF: 0,      SC_PS: '2025-03-21', SC_PF: '2025-03-31', SC_PP: 0,     SC_AS: undefined,    SC_AF: undefined,    SC_AP: 0,   FB_PS: '2025-04-01', FB_PF: '2025-04-28', FB_PP: 0,     FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: '2025-04-29', AS_PF: '2025-06-16', AS_PP: 0,    AS_AS: undefined,    AS_AF: undefined,    AS_AP: 0,    PE1_PS: undefined,    PE1_PF: undefined,    PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: undefined,    PT_PF: undefined,    PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: undefined,    PE2_PF: undefined,    PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: undefined,    ER_PF: undefined,    ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'TOPSIDE', ZONE: 'CENTRAL',        MOD: 'Mezz. Deck',          LEVEL: 'Mezz. Deck',          NAME: 'Deck Block',             G_EREC: 'CD260', G_2PE: 'CD260', G_1PE: 'CD261', EREC: 'CD260', PE2: '',      PE1: '',      PBLK: 'CD261', BLK: 'CD261', L: '8.3',  B: '5.5',  H: '1.2',  YARD: 'HHI',   SC_WF: 16,     FB_WF: 24.3,    AS_WF: 27.34,   PE1_WF: 0,       PT_WF: 162.94,  PE2_WF: 24.3,    ER_WF: 5822.9, SC_PS: '2025-03-07', SC_PF: '2025-03-17', SC_PP: 0,     SC_AS: undefined,    SC_AF: undefined,    SC_AP: 0,   FB_PS: '2025-03-18', FB_PF: '2025-04-09', FB_PP: 0,     FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: '2025-04-10', AS_PF: '2025-05-14', AS_PP: 0,    AS_AS: undefined,    AS_AF: undefined,    AS_AP: 0,    PE1_PS: undefined,    PE1_PF: undefined,    PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: '2025-06-17', PT_PF: '2025-07-09', PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: '2025-07-10', PE2_PF: '2025-08-28', PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: '2025-08-29', ER_PF: '2026-03-10', ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'TOPSIDE', ZONE: 'CENTRAL',        MOD: 'Mezz. Deck',          LEVEL: 'Mezz. Deck',          NAME: 'Deck Block',             G_EREC: 'CD270', G_2PE: 'CD270', G_1PE: 'CD271', EREC: 'CD270', PE2: '',      PE1: '',      PBLK: 'CD271', BLK: 'CD271', L: '8.1',  B: '4.6',  H: '1.4',  YARD: 'HHI',   SC_WF: 16,     FB_WF: 24.3,    AS_WF: 27.34,   PE1_WF: 0,       PT_WF: 129,     PE2_WF: 24.3,    ER_WF: 5822.9, SC_PS: '2025-03-07', SC_PF: '2025-03-17', SC_PP: 0,     SC_AS: undefined,    SC_AF: undefined,    SC_AP: 0,   FB_PS: '2025-03-18', FB_PF: '2025-04-09', FB_PP: 0,     FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: '2025-04-10', AS_PF: '2025-05-14', AS_PP: 0,    AS_AS: undefined,    AS_AF: undefined,    AS_AP: 0,    PE1_PS: undefined,    PE1_PF: undefined,    PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: '2025-06-17', PT_PF: '2025-07-09', PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: '2025-07-10', PE2_PF: '2025-08-28', PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: '2025-08-29', ER_PF: '2026-03-10', ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'TOPSIDE', ZONE: 'CENTRAL',        MOD: 'Main Deck',           LEVEL: 'Main Deck',           NAME: 'Deck Block',             G_EREC: 'CD310', G_2PE: 'CD310', G_1PE: 'CD310', EREC: 'CD310', PE2: 'CD310', PE1: 'CD310', PBLK: 'CD310', BLK: 'CD311', L: '16.6', B: '19.5', H: '1.5',  YARD: 'HHI',   SC_WF: 225.97, FB_WF: 451.94,  AS_WF: 677.91,  PE1_WF: 2086.59, PT_WF: 2478.32, PE2_WF: 2086.59, ER_WF: 5822.9, SC_PS: '2025-03-17', SC_PF: '2025-04-03', SC_PP: 0,     SC_AS: undefined,    SC_AF: undefined,    SC_AP: 0,   FB_PS: '2025-04-04', FB_PF: '2025-05-06', FB_PP: 0,     FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: '2025-05-07', AS_PF: '2025-06-24', AS_PP: 0,    AS_AS: undefined,    AS_AF: undefined,    AS_AP: 0,    PE1_PS: '2025-06-25', PE1_PF: '2025-07-24', PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: '2025-08-08', PT_PF: '2025-08-29', PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: '2025-09-01', PE2_PF: '2025-10-14', PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: '2025-10-15', ER_PF: '2026-03-10', ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'TOPSIDE', ZONE: 'CENTRAL',        MOD: 'Main Deck',           LEVEL: 'Main Deck',           NAME: 'Deck Block',             G_EREC: 'CD310', G_2PE: 'CD310', G_1PE: 'CD310', EREC: '',      PE2: '',      PE1: '',      PBLK: '',      BLK: 'CD312', L: '16.3', B: '17.3', H: '1.5',  YARD: 'HHI',   SC_WF: 173.12, FB_WF: 346.25,  AS_WF: 519.37,  PE1_WF: 0,       PT_WF: 0,       PE2_WF: 0,       ER_WF: 0,      SC_PS: '2025-03-17', SC_PF: '2025-04-03', SC_PP: 0,     SC_AS: undefined,    SC_AF: undefined,    SC_AP: 0,   FB_PS: '2025-04-04', FB_PF: '2025-05-06', FB_PP: 0,     FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: '2025-05-07', AS_PF: '2025-06-24', AS_PP: 0,    AS_AS: undefined,    AS_AF: undefined,    AS_AP: 0,    PE1_PS: undefined,    PE1_PF: undefined,    PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: undefined,    PT_PF: undefined,    PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: undefined,    PE2_PF: undefined,    PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: undefined,    ER_PF: undefined,    ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'TOPSIDE', ZONE: 'CENTRAL',        MOD: 'Main Deck',           LEVEL: 'Main Deck',           NAME: 'Turnnion',               G_EREC: 'CD310', G_2PE: 'CD310', G_1PE: 'CD310', EREC: '',      PE2: '',      PE1: '',      PBLK: '',      BLK: 'CT311', L: '2.5',  B: '2.5',  H: '1.8',  YARD: 'HHI',   SC_WF: 16,     FB_WF: 24.3,    AS_WF: 0,       PE1_WF: 0,       PT_WF: 0,       PE2_WF: 0,       ER_WF: 0,      SC_PS: '2025-04-16', SC_PF: '2025-05-08', SC_PP: 0,     SC_AS: undefined,    SC_AF: undefined,    SC_AP: 0,   FB_PS: '2025-05-09', FB_PF: '2025-07-01', FB_PP: 0,     FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: undefined,    AS_PF: undefined,    AS_PP: 0,    AS_AS: undefined,    AS_AF: undefined,    AS_AP: 0,    PE1_PS: undefined,    PE1_PF: undefined,    PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: undefined,    PT_PF: undefined,    PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: undefined,    PE2_PF: undefined,    PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: undefined,    ER_PF: undefined,    ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'TOPSIDE', ZONE: 'CENTRAL',        MOD: 'Main Deck',           LEVEL: 'Main Deck',           NAME: 'Turnnion',               G_EREC: 'CD310', G_2PE: 'CD310', G_1PE: 'CD310', EREC: '',      PE2: '',      PE1: '',      PBLK: '',      BLK: 'CT331', L: '2.2',  B: '1.5',  H: '1.8',  YARD: 'HHI',   SC_WF: 16,     FB_WF: 24.3,    AS_WF: 0,       PE1_WF: 0,       PT_WF: 0,       PE2_WF: 0,       ER_WF: 0,      SC_PS: '2025-04-16', SC_PF: '2025-05-08', SC_PP: 0,     SC_AS: undefined,    SC_AF: undefined,    SC_AP: 0,   FB_PS: '2025-05-09', FB_PF: '2025-07-01', FB_PP: 0,     FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: undefined,    AS_PF: undefined,    AS_PP: 0,    AS_AS: undefined,    AS_AF: undefined,    AS_AP: 0,    PE1_PS: undefined,    PE1_PF: undefined,    PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: undefined,    PT_PF: undefined,    PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: undefined,    PE2_PF: undefined,    PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: undefined,    ER_PF: undefined,    ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'TOPSIDE', ZONE: 'CENTRAL',        MOD: 'Main Deck',           LEVEL: 'Main Deck',           NAME: 'Deck Block',             G_EREC: 'CD310', G_2PE: 'CD310', G_1PE: 'CD320', EREC: '',      PE2: '',      PE1: 'CD320', PBLK: 'CD320', BLK: 'CD321', L: '10.2', B: '14.2', H: '1.5',  YARD: 'HHI',   SC_WF: 91.12,  FB_WF: 182.23,  AS_WF: 273.35,  PE1_WF: 1339.43, PT_WF: 1298.83, PE2_WF: 1339.43, ER_WF: 0,      SC_PS: '2025-03-20', SC_PF: '2025-04-08', SC_PP: 0,     SC_AS: undefined,    SC_AF: undefined,    SC_AP: 0,   FB_PS: '2025-04-09', FB_PF: '2025-05-09', FB_PP: 0,     FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: '2025-05-12', AS_PF: '2025-06-27', AS_PP: 0,    AS_AS: undefined,    AS_AF: undefined,    AS_AP: 0,    PE1_PS: '2025-06-30', PE1_PF: '2025-08-08', PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: '2025-08-11', PT_PF: '2025-09-03', PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: '2025-09-04', PE2_PF: '2025-10-10', PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: undefined,    ER_PF: undefined,    ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'TOPSIDE', ZONE: 'CENTRAL',        MOD: 'Main Deck',           LEVEL: 'Main Deck',           NAME: 'Deck Block',             G_EREC: 'CD310', G_2PE: 'CD310', G_1PE: 'CD320', EREC: '',      PE2: '',      PE1: '',      PBLK: '',      BLK: 'CD322', L: '16.7', B: '16.3', H: '1.5',  YARD: 'HHI',   SC_WF: 158.54, FB_WF: 317.09,  AS_WF: 475.63,  PE1_WF: 0,       PT_WF: 0,       PE2_WF: 0,       ER_WF: 0,      SC_PS: '2025-03-20', SC_PF: '2025-04-08', SC_PP: 0,     SC_AS: undefined,    SC_AF: undefined,    SC_AP: 0,   FB_PS: '2025-04-09', FB_PF: '2025-05-09', FB_PP: 0,     FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: '2025-05-12', AS_PF: '2025-06-27', AS_PP: 0,    AS_AS: undefined,    AS_AF: undefined,    AS_AP: 0,    PE1_PS: undefined,    PE1_PF: undefined,    PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: undefined,    PT_PF: undefined,    PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: undefined,    PE2_PF: undefined,    PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: undefined,    ER_PF: undefined,    ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'TOPSIDE', ZONE: 'CENTRAL',        MOD: 'Main Deck',           LEVEL: 'Main Deck',           NAME: 'Turnnion',               G_EREC: 'CD310', G_2PE: 'CD310', G_1PE: 'CD320', EREC: '',      PE2: '',      PE1: '',      PBLK: '',      BLK: 'CT321', L: '2.3',  B: '2.3',  H: '1.8',  YARD: 'HHI',   SC_WF: 16,     FB_WF: 24.3,    AS_WF: 0,       PE1_WF: 0,       PT_WF: 0,       PE2_WF: 0,       ER_WF: 0,      SC_PS: '2025-04-21', SC_PF: '2025-05-13', SC_PP: 0,     SC_AS: undefined,    SC_AF: undefined,    SC_AP: 0,   FB_PS: '2025-05-14', FB_PF: '2025-07-04', FB_PP: 0,     FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: undefined,    AS_PF: undefined,    AS_PP: 0,    AS_AS: undefined,    AS_AF: undefined,    AS_AP: 0,    PE1_PS: undefined,    PE1_PF: undefined,    PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: undefined,    PT_PF: undefined,    PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: undefined,    PE2_PF: undefined,    PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: undefined,    ER_PF: undefined,    ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'TOPSIDE', ZONE: 'CENTRAL',        MOD: 'Main Deck',           LEVEL: 'Main Deck',           NAME: 'Turnnion',               G_EREC: 'CD310', G_2PE: 'CD310', G_1PE: 'CD320', EREC: '',      PE2: '',      PE1: '',      PBLK: '',      BLK: 'CT341', L: '2.2',  B: '1.5',  H: '1.8',  YARD: 'HHI',   SC_WF: 16,     FB_WF: 24.3,    AS_WF: 0,       PE1_WF: 0,       PT_WF: 0,       PE2_WF: 0,       ER_WF: 0,      SC_PS: '2025-04-21', SC_PF: '2025-05-13', SC_PP: 0,     SC_AS: undefined,    SC_AF: undefined,    SC_AP: 0,   FB_PS: '2025-05-14', FB_PF: '2025-07-04', FB_PP: 0,     FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: undefined,    AS_PF: undefined,    AS_PP: 0,    AS_AS: undefined,    AS_AF: undefined,    AS_AP: 0,    PE1_PS: undefined,    PE1_PF: undefined,    PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: undefined,    PT_PF: undefined,    PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: undefined,    PE2_PF: undefined,    PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: undefined,    ER_PF: undefined,    ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'TOPSIDE', ZONE: 'CENTRAL',        MOD: 'Main Deck',           LEVEL: 'Main Deck',           NAME: 'Deck Block',             G_EREC: 'CD310', G_2PE: 'CD310', G_1PE: 'CD330', EREC: '',      PE2: '',      PE1: 'CD330', PBLK: 'CD330', BLK: 'CD331', L: '16',   B: '19.3', H: '1.5',  YARD: 'HHI',   SC_WF: 191.35, FB_WF: 382.69,  AS_WF: 574.04,  PE1_WF: 1767.68, PT_WF: 1889.75, PE2_WF: 1767.68, ER_WF: 0,      SC_PS: '2025-03-27', SC_PF: '2025-04-15', SC_PP: 0,     SC_AS: undefined,    SC_AF: undefined,    SC_AP: 0,   FB_PS: '2025-04-16', FB_PF: '2025-05-16', FB_PP: 0,     FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: '2025-05-19', AS_PF: '2025-07-04', AS_PP: 0,    AS_AS: undefined,    AS_AF: undefined,    AS_AP: 0,    PE1_PS: '2025-07-07', PE1_PF: '2025-08-14', PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: '2025-08-18', PT_PF: '2025-09-08', PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: '2025-09-09', PE2_PF: '2025-10-10', PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: undefined,    ER_PF: undefined,    ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'TOPSIDE', ZONE: 'CENTRAL',        MOD: 'Main Deck',           LEVEL: 'Main Deck',           NAME: 'Deck Block',             G_EREC: 'CD310', G_2PE: 'CD310', G_1PE: 'CD330', EREC: '',      PE2: '',      PE1: '',      PBLK: '',      BLK: 'CD332', L: '15.8', B: '16.3', H: '1.5',  YARD: 'HHI',   SC_WF: 162.19, FB_WF: 324.38,  AS_WF: 486.57,  PE1_WF: 0,       PT_WF: 0,       PE2_WF: 0,       ER_WF: 0,      SC_PS: '2025-03-27', SC_PF: '2025-04-15', SC_PP: 0,     SC_AS: undefined,    SC_AF: undefined,    SC_AP: 0,   FB_PS: '2025-04-16', FB_PF: '2025-05-16', FB_PP: 0,     FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: '2025-05-19', AS_PF: '2025-07-04', AS_PP: 0,    AS_AS: undefined,    AS_AF: undefined,    AS_AP: 0,    PE1_PS: undefined,    PE1_PF: undefined,    PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: undefined,    PT_PF: undefined,    PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: undefined,    PE2_PF: undefined,    PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: undefined,    ER_PF: undefined,    ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'TOPSIDE', ZONE: 'CENTRAL',        MOD: 'Main Deck',           LEVEL: 'Main Deck',           NAME: 'Access P/F',             G_EREC: 'CD310', G_2PE: 'CD310', G_1PE: 'CV311', EREC: '',      PE2: '',      PE1: '',      PBLK: 'CV311', BLK: 'CV311', L: '2.5',  B: '4.6',  H: '2.8',  YARD: 'HHI',   SC_WF: 8,      FB_WF: 0,       AS_WF: 24,      PE1_WF: 0,       PT_WF: 41.98,   PE2_WF: 16,      ER_WF: 0,      SC_PS: '2025-05-16', SC_PF: '2025-06-04', SC_PP: 0,     SC_AS: undefined,    SC_AF: undefined,    SC_AP: 0,   FB_PS: undefined,    FB_PF: undefined,    FB_PP: 0,     FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: '2025-06-05', AS_PF: '2025-07-21', AS_PP: 0,    AS_AS: undefined,    AS_AF: undefined,    AS_AP: 0,    PE1_PS: undefined,    PE1_PF: undefined,    PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: '2025-08-22', PT_PF: '2025-09-15', PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: '2025-09-16', PE2_PF: '2025-10-10', PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: undefined,    ER_PF: undefined,    ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'TOPSIDE', ZONE: 'CENTRAL',        MOD: 'Main Deck',           LEVEL: 'Main Deck',           NAME: 'Access P/F',             G_EREC: 'CD310', G_2PE: 'CD310', G_1PE: 'CV321', EREC: '',      PE2: '',      PE1: '',      PBLK: 'CV321', BLK: 'CV321', L: '8.2',  B: '5',    H: '0.9',  YARD: 'HHI',   SC_WF: 8,      FB_WF: 0,       AS_WF: 24,      PE1_WF: 0,       PT_WF: 121.71,  PE2_WF: 16,      ER_WF: 0,      SC_PS: '2025-05-16', SC_PF: '2025-06-04', SC_PP: 0,     SC_AS: undefined,    SC_AF: undefined,    SC_AP: 0,   FB_PS: undefined,    FB_PF: undefined,    FB_PP: 0,     FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: '2025-06-05', AS_PF: '2025-07-21', AS_PP: 0,    AS_AS: undefined,    AS_AF: undefined,    AS_AP: 0,    PE1_PS: undefined,    PE1_PF: undefined,    PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: '2025-08-22', PT_PF: '2025-09-15', PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: '2025-09-16', PE2_PF: '2025-10-10', PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: undefined,    ER_PF: undefined,    ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'TOPSIDE', ZONE: 'CENTRAL',        MOD: 'Main Deck',           LEVEL: 'Main Deck',           NAME: 'Access P/F',             G_EREC: 'CD310', G_2PE: 'CD310', G_1PE: 'CV331', EREC: '',      PE2: '',      PE1: '',      PBLK: 'CV331', BLK: 'CV331', L: '3.2',  B: '1.8',  H: '2.7',  YARD: 'HHI',   SC_WF: 8,      FB_WF: 0,       AS_WF: 24,      PE1_WF: 0,       PT_WF: 24,      PE2_WF: 16,      ER_WF: 0,      SC_PS: '2025-05-16', SC_PF: '2025-06-04', SC_PP: 0,     SC_AS: undefined,    SC_AF: undefined,    SC_AP: 0,   FB_PS: undefined,    FB_PF: undefined,    FB_PP: 0,     FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: '2025-06-05', AS_PF: '2025-07-21', AS_PP: 0,    AS_AS: undefined,    AS_AF: undefined,    AS_AP: 0,    PE1_PS: undefined,    PE1_PF: undefined,    PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: '2025-08-22', PT_PF: '2025-09-15', PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: '2025-09-16', PE2_PF: '2025-10-10', PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: undefined,    ER_PF: undefined,    ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'TOPSIDE', ZONE: 'CENTRAL',        MOD: 'Main Deck',           LEVEL: 'Main Deck',           NAME: 'Access P/F',             G_EREC: 'CD310', G_2PE: 'CD310', G_1PE: 'CV341', EREC: '',      PE2: '',      PE1: '',      PBLK: '',      BLK: 'CV341', L: '9.3',  B: '5',    H: '0.7',  YARD: 'HHI',   SC_WF: 8,      FB_WF: 0,       AS_WF: 24,      PE1_WF: 0,       PT_WF: 163.44,  PE2_WF: 16,      ER_WF: 0,      SC_PS: '2025-05-16', SC_PF: '2025-06-04', SC_PP: 0,     SC_AS: undefined,    SC_AF: undefined,    SC_AP: 0,   FB_PS: undefined,    FB_PF: undefined,    FB_PP: 0,     FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: '2025-06-05', AS_PF: '2025-07-21', AS_PP: 0,    AS_AS: undefined,    AS_AF: undefined,    AS_AP: 0,    PE1_PS: undefined,    PE1_PF: undefined,    PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: '2025-08-22', PT_PF: '2025-09-15', PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: '2025-09-16', PE2_PF: '2025-10-10', PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: undefined,    ER_PF: undefined,    ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'TOPSIDE', ZONE: 'CENTRAL',        MOD: 'Main Deck',           LEVEL: 'Main Deck',           NAME: 'Crane Boom Rest',        G_EREC: 'CD310', G_2PE: 'CD310', G_1PE: 'CK161', EREC: '',      PE2: '',      PE1: '',      PBLK: 'CK161', BLK: 'CK161', L: '1',    B: '11.1', H: '17.9', YARD: 'HHI',   SC_WF: 8,      FB_WF: 16,      AS_WF: 0,       PE1_WF: 0,       PT_WF: 652.02,  PE2_WF: 27.34,   ER_WF: 0,      SC_PS: '2025-05-16', SC_PF: '2025-06-04', SC_PP: 0,     SC_AS: undefined,    SC_AF: undefined,    SC_AP: 0,   FB_PS: '2025-06-05', FB_PF: '2025-07-21', FB_PP: 0,     FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: undefined,    AS_PF: undefined,    AS_PP: 0,    AS_AS: undefined,    AS_AF: undefined,    AS_AP: 0,    PE1_PS: undefined,    PE1_PF: undefined,    PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: '2025-08-22', PT_PF: '2025-09-15', PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: '2025-09-16', PE2_PF: '2025-10-10', PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: undefined,    ER_PF: undefined,    ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'TOPSIDE', ZONE: 'CENTRAL',        MOD: 'Main Deck',           LEVEL: 'Main Deck',           NAME: 'Pipe Rack',              G_EREC: 'CD310', G_2PE: 'CD310', G_1PE: 'CP311', EREC: '',      PE2: '',      PE1: '',      PBLK: 'CP311', BLK: 'CP311', L: '5.1',  B: '13.2', H: '2.7',  YARD: 'HHI',   SC_WF: 24.3,   FB_WF: 0,       AS_WF: 54.67,   PE1_WF: 0,       PT_WF: 327.88,  PE2_WF: 91.12,   ER_WF: 0,      SC_PS: '2025-04-22', SC_PF: '2025-05-14', SC_PP: 0,     SC_AS: undefined,    SC_AF: undefined,    SC_AP: 0,   FB_PS: undefined,    FB_PF: undefined,    FB_PP: 0,     FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: '2025-05-15', AS_PF: '2025-06-30', AS_PP: 0,    AS_AS: undefined,    AS_AF: undefined,    AS_AP: 0,    PE1_PS: undefined,    PE1_PF: undefined,    PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: '2025-07-21', PT_PF: '2025-08-25', PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: '2025-08-26', PE2_PF: '2025-10-10', PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: undefined,    ER_PF: undefined,    ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'TOPSIDE', ZONE: 'CENTRAL',        MOD: 'Main Deck',           LEVEL: 'Main Deck',           NAME: 'Access P/F',             G_EREC: 'CD310', G_2PE: 'CD310', G_1PE: 'CV3J1', EREC: '',      PE2: '',      PE1: '',      PBLK: 'CV3J1', BLK: 'CV3J1', L: '1',    B: '7.8',  H: '0.4',  YARD: 'HHI',   SC_WF: 0,      FB_WF: 0,       AS_WF: 0,       PE1_WF: 0,       PT_WF: 0,       PE2_WF: 0,       ER_WF: 0,      SC_PS: undefined,    SC_PF: undefined,    SC_PP: 0,     SC_AS: undefined,    SC_AF: undefined,    SC_AP: 0,   FB_PS: undefined,    FB_PF: undefined,    FB_PP: 0,     FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: undefined,    AS_PF: undefined,    AS_PP: 0,    AS_AS: undefined,    AS_AF: undefined,    AS_AP: 0,    PE1_PS: undefined,    PE1_PF: undefined,    PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: undefined,    PT_PF: undefined,    PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: undefined,    PE2_PF: undefined,    PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: undefined,    ER_PF: undefined,    ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'TOPSIDE', ZONE: 'CENTRAL',        MOD: 'Main Deck',           LEVEL: 'Main Deck',           NAME: 'Pipe Rack',              G_EREC: 'CD310', G_2PE: 'CD310', G_1PE: 'CP321', EREC: '',      PE2: '',      PE1: '',      PBLK: 'CP321', BLK: 'CP321', L: '13.4', B: '13.8', H: '4.3',  YARD: 'HHI',   SC_WF: 27.34,  FB_WF: 0,       AS_WF: 82.01,   PE1_WF: 0,       PT_WF: 699.47,  PE2_WF: 136.68,  ER_WF: 0,      SC_PS: '2025-04-15', SC_PF: '2025-05-07', SC_PP: 0,     SC_AS: undefined,    SC_AF: undefined,    SC_AP: 0,   FB_PS: undefined,    FB_PF: undefined,    FB_PP: 0,     FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: '2025-05-08', AS_PF: '2025-06-30', AS_PP: 0,    AS_AS: undefined,    AS_AF: undefined,    AS_AP: 0,    PE1_PS: undefined,    PE1_PF: undefined,    PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: '2025-07-21', PT_PF: '2025-08-25', PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: '2025-08-26', PE2_PF: '2025-10-10', PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: undefined,    ER_PF: undefined,    ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'TOPSIDE', ZONE: 'CENTRAL',        MOD: 'Main Deck',           LEVEL: 'Main Deck',           NAME: 'Pipe Rack',              G_EREC: 'CD310', G_2PE: 'CD310', G_1PE: 'CP331', EREC: '',      PE2: '',      PE1: '',      PBLK: 'CP331', BLK: 'CP331', L: '6',    B: '13.8', H: '3',    YARD: 'HHI',   SC_WF: 24.3,   FB_WF: 0,       AS_WF: 54.67,   PE1_WF: 0,       PT_WF: 342.78,  PE2_WF: 91.12,   ER_WF: 0,      SC_PS: '2025-04-22', SC_PF: '2025-05-14', SC_PP: 0,     SC_AS: undefined,    SC_AF: undefined,    SC_AP: 0,   FB_PS: undefined,    FB_PF: undefined,    FB_PP: 0,     FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: '2025-05-15', AS_PF: '2025-06-30', AS_PP: 0,    AS_AS: undefined,    AS_AF: undefined,    AS_AP: 0,    PE1_PS: undefined,    PE1_PF: undefined,    PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: '2025-07-21', PT_PF: '2025-08-25', PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: '2025-08-26', PE2_PF: '2025-10-10', PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: undefined,    ER_PF: undefined,    ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'TOPSIDE', ZONE: 'CENTRAL',        MOD: 'Main Deck',           LEVEL: 'Main Deck',           NAME: 'Pipe Rack',              G_EREC: 'CD310', G_2PE: 'CD310', G_1PE: 'CP341', EREC: '',      PE2: '',      PE1: '',      PBLK: 'CP341', BLK: 'CP341', L: '12.8', B: '13.6', H: '3.4',  YARD: 'HHI',   SC_WF: 27.34,  FB_WF: 0,       AS_WF: 82.01,   PE1_WF: 0,       PT_WF: 731.26,  PE2_WF: 136.68,  ER_WF: 0,      SC_PS: '2025-04-15', SC_PF: '2025-05-07', SC_PP: 0,     SC_AS: undefined,    SC_AF: undefined,    SC_AP: 0,   FB_PS: undefined,    FB_PF: undefined,    FB_PP: 0,     FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: '2025-05-08', AS_PF: '2025-06-30', AS_PP: 0,    AS_AS: undefined,    AS_AF: undefined,    AS_AP: 0,    PE1_PS: undefined,    PE1_PF: undefined,    PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: '2025-07-21', PT_PF: '2025-08-25', PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: '2025-08-26', PE2_PF: '2025-10-10', PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: undefined,    ER_PF: undefined,    ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'TOPSIDE', ZONE: 'CENTRAL',        MOD: 'Main Deck',           LEVEL: 'Main Deck',           NAME: 'Pipe Rack',              G_EREC: 'CD310', G_2PE: 'CD310', G_1PE: 'CP351', EREC: '',      PE2: '',      PE1: '',      PBLK: 'CP351', BLK: 'CP351', L: '11.4', B: '13.8', H: '4.3',  YARD: 'HHI',   SC_WF: 27.34,  FB_WF: 0,       AS_WF: 82.01,   PE1_WF: 0,       PT_WF: 617.5,   PE2_WF: 136.68,  ER_WF: 0,      SC_PS: '2025-04-22', SC_PF: '2025-05-14', SC_PP: 0,     SC_AS: undefined,    SC_AF: undefined,    SC_AP: 0,   FB_PS: undefined,    FB_PF: undefined,    FB_PP: 0,     FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: '2025-05-15', AS_PF: '2025-06-30', AS_PP: 0,    AS_AS: undefined,    AS_AF: undefined,    AS_AP: 0,    PE1_PS: undefined,    PE1_PF: undefined,    PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: '2025-07-21', PT_PF: '2025-08-25', PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: '2025-08-26', PE2_PF: '2025-10-10', PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: undefined,    ER_PF: undefined,    ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'TOPSIDE', ZONE: 'CENTRAL',        MOD: 'Main Deck',           LEVEL: 'Main Deck',           NAME: 'Pipe Rack',              G_EREC: 'CD310', G_2PE: 'CD310', G_1PE: 'CP361', EREC: '',      PE2: '',      PE1: '',      PBLK: 'CP361', BLK: 'CP361', L: '11.4', B: '13.8', H: '4.3',  YARD: 'HHI',   SC_WF: 27.34,  FB_WF: 0,       AS_WF: 82.01,   PE1_WF: 0,       PT_WF: 645.57,  PE2_WF: 136.68,  ER_WF: 0,      SC_PS: '2025-04-22', SC_PF: '2025-05-14', SC_PP: 0,     SC_AS: undefined,    SC_AF: undefined,    SC_AP: 0,   FB_PS: undefined,    FB_PF: undefined,    FB_PP: 0,     FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: '2025-05-15', AS_PF: '2025-06-30', AS_PP: 0,    AS_AS: undefined,    AS_AF: undefined,    AS_AP: 0,    PE1_PS: undefined,    PE1_PF: undefined,    PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: '2025-07-21', PT_PF: '2025-08-25', PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: '2025-08-26', PE2_PF: '2025-10-10', PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: undefined,    ER_PF: undefined,    ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'TOPSIDE', ZONE: 'CENTRAL',        MOD: 'Main Deck',           LEVEL: 'Main Deck',           NAME: 'Deck Block',             G_EREC: 'CD340', G_2PE: 'CD340', G_1PE: 'CD340', EREC: 'CD340', PE2: 'CD340', PE1: 'CD340', PBLK: 'CD340', BLK: 'CD341', L: '22.3', B: '20.9', H: '1.5',  YARD: 'HHI',   SC_WF: 278.82, FB_WF: 557.64,  AS_WF: 836.46,  PE1_WF: 2851.97, PT_WF: 3107.99, PE2_WF: 2851.97, ER_WF: 5822.9, SC_PS: '2025-04-09', SC_PF: '2025-04-28', SC_PP: 0,     SC_AS: undefined,    SC_AF: undefined,    SC_AP: 0,   FB_PS: '2025-04-29', FB_PF: '2025-05-29', FB_PP: 0,     FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: '2025-05-30', AS_PF: '2025-08-11', AS_PP: 0,    AS_AS: undefined,    AS_AF: undefined,    AS_AP: 0,    PE1_PS: '2025-08-12', PE1_PF: '2025-09-11', PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: '2025-09-12', PT_PF: '2025-10-13', PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: '2025-10-14', PE2_PF: '2025-11-19', PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: '2025-11-20', ER_PF: '2026-03-10', ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'TOPSIDE', ZONE: 'CENTRAL',        MOD: 'Main Deck',           LEVEL: 'Main Deck',           NAME: 'Deck Block',             G_EREC: 'CD340', G_2PE: 'CD340', G_1PE: 'CD340', EREC: '',      PE2: '',      PE1: '',      PBLK: '',      BLK: 'CD342', L: '19.3', B: '18.8', H: '1.5',  YARD: 'HHI',   SC_WF: 273.35, FB_WF: 546.7,   AS_WF: 820.06,  PE1_WF: 0,       PT_WF: 0,       PE2_WF: 0,       ER_WF: 0,      SC_PS: '2025-04-09', SC_PF: '2025-04-28', SC_PP: 0,     SC_AS: undefined,    SC_AF: undefined,    SC_AP: 0,   FB_PS: '2025-04-29', FB_PF: '2025-05-29', FB_PP: 0,     FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: '2025-05-30', AS_PF: '2025-08-11', AS_PP: 0,    AS_AS: undefined,    AS_AF: undefined,    AS_AP: 0,    PE1_PS: undefined,    PE1_PF: undefined,    PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: undefined,    PT_PF: undefined,    PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: undefined,    PE2_PF: undefined,    PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: undefined,    ER_PF: undefined,    ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'TOPSIDE', ZONE: 'CENTRAL',        MOD: 'Main Deck',           LEVEL: 'Main Deck',           NAME: 'Turnnion',               G_EREC: 'CD340', G_2PE: 'CD340', G_1PE: 'CD340', EREC: '',      PE2: '',      PE1: '',      PBLK: '',      BLK: 'CT351', L: '2.2',  B: '1.5',  H: '1.8',  YARD: 'HHI',   SC_WF: 16,     FB_WF: 24.3,    AS_WF: 0,       PE1_WF: 0,       PT_WF: 0,       PE2_WF: 0,       ER_WF: 0,      SC_PS: '2025-05-27', SC_PF: '2025-06-16', SC_PP: 0,     SC_AS: undefined,    SC_AF: undefined,    SC_AP: 0,   FB_PS: '2025-06-17', FB_PF: '2025-08-19', FB_PP: 0,     FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: undefined,    AS_PF: undefined,    AS_PP: 0,    AS_AS: undefined,    AS_AF: undefined,    AS_AP: 0,    PE1_PS: undefined,    PE1_PF: undefined,    PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: undefined,    PT_PF: undefined,    PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: undefined,    PE2_PF: undefined,    PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: undefined,    ER_PF: undefined,    ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'TOPSIDE', ZONE: 'CENTRAL',        MOD: 'Main Deck',           LEVEL: 'Main Deck',           NAME: 'Turnnion',               G_EREC: 'CD340', G_2PE: 'CD340', G_1PE: 'CD340', EREC: '',      PE2: '',      PE1: '',      PBLK: '',      BLK: 'CT371', L: '2.5',  B: '2.5',  H: '1.8',  YARD: 'HHI',   SC_WF: 16,     FB_WF: 24.3,    AS_WF: 0,       PE1_WF: 0,       PT_WF: 0,       PE2_WF: 0,       ER_WF: 0,      SC_PS: '2025-05-27', SC_PF: '2025-06-16', SC_PP: 0,     SC_AS: undefined,    SC_AF: undefined,    SC_AP: 0,   FB_PS: '2025-06-17', FB_PF: '2025-08-19', FB_PP: 0,     FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: undefined,    AS_PF: undefined,    AS_PP: 0,    AS_AS: undefined,    AS_AF: undefined,    AS_AP: 0,    PE1_PS: undefined,    PE1_PF: undefined,    PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: undefined,    PT_PF: undefined,    PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: undefined,    PE2_PF: undefined,    PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: undefined,    ER_PF: undefined,    ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'TOPSIDE', ZONE: 'CENTRAL',        MOD: 'Main Deck',           LEVEL: 'Main Deck',           NAME: 'Deck Block',             G_EREC: 'CD340', G_2PE: 'CD340', G_1PE: 'CD350', EREC: '',      PE2: '',      PE1: 'CD350', PBLK: 'CD350', BLK: 'CD351', L: '16.7', B: '16.2', H: '1.5',  YARD: 'HHI',   SC_WF: 169.48, FB_WF: 338.96,  AS_WF: 508.43,  PE1_WF: 1849.68, PT_WF: 2063.96, PE2_WF: 1849.68, ER_WF: 0,      SC_PS: '2025-04-23', SC_PF: '2025-05-15', SC_PP: 0,     SC_AS: undefined,    SC_AF: undefined,    SC_AP: 0,   FB_PS: '2025-05-16', FB_PF: '2025-06-13', FB_PP: 0,     FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: '2025-06-16', AS_PF: '2025-08-12', AS_PP: 0,    AS_AS: undefined,    AS_AF: undefined,    AS_AP: 0,    PE1_PS: '2025-08-13', PE1_PF: '2025-09-12', PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: '2025-09-15', PT_PF: '2025-10-14', PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: '2025-10-15', PE2_PF: '2025-11-18', PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: undefined,    ER_PF: undefined,    ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'TOPSIDE', ZONE: 'CENTRAL',        MOD: 'Main Deck',           LEVEL: 'Main Deck',           NAME: 'Deck Block',             G_EREC: 'CD340', G_2PE: 'CD340', G_1PE: 'CD350', EREC: '',      PE2: '',      PE1: '',      PBLK: '',      BLK: 'CD352', L: '19.5', B: '14.1', H: '1.5',  YARD: 'HHI',   SC_WF: 182.23, FB_WF: 364.47,  AS_WF: 546.7,   PE1_WF: 0,       PT_WF: 0,       PE2_WF: 0,       ER_WF: 0,      SC_PS: '2025-04-23', SC_PF: '2025-05-15', SC_PP: 0,     SC_AS: undefined,    SC_AF: undefined,    SC_AP: 0,   FB_PS: '2025-05-16', FB_PF: '2025-06-13', FB_PP: 0,     FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: '2025-06-16', AS_PF: '2025-08-12', AS_PP: 0,    AS_AS: undefined,    AS_AF: undefined,    AS_AP: 0,    PE1_PS: undefined,    PE1_PF: undefined,    PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: undefined,    PT_PF: undefined,    PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: undefined,    PE2_PF: undefined,    PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: undefined,    ER_PF: undefined,    ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'TOPSIDE', ZONE: 'CENTRAL',        MOD: 'Main Deck',           LEVEL: 'Main Deck',           NAME: 'Turnnion',               G_EREC: 'CD340', G_2PE: 'CD340', G_1PE: 'CD350', EREC: '',      PE2: '',      PE1: '',      PBLK: '',      BLK: 'CT361', L: '2.2',  B: '1.5',  H: '1.8',  YARD: 'HHI',   SC_WF: 16,     FB_WF: 24.3,    AS_WF: 0,       PE1_WF: 0,       PT_WF: 0,       PE2_WF: 0,       ER_WF: 0,      SC_PS: '2025-05-28', SC_PF: '2025-06-17', SC_PP: 0,     SC_AS: undefined,    SC_AF: undefined,    SC_AP: 0,   FB_PS: '2025-06-18', FB_PF: '2025-08-20', FB_PP: 0,     FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: undefined,    AS_PF: undefined,    AS_PP: 0,    AS_AS: undefined,    AS_AF: undefined,    AS_AP: 0,    PE1_PS: undefined,    PE1_PF: undefined,    PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: undefined,    PT_PF: undefined,    PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: undefined,    PE2_PF: undefined,    PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: undefined,    ER_PF: undefined,    ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'TOPSIDE', ZONE: 'CENTRAL',        MOD: 'Main Deck',           LEVEL: 'Main Deck',           NAME: 'Turnnion',               G_EREC: 'CD340', G_2PE: 'CD340', G_1PE: 'CD350', EREC: '',      PE2: '',      PE1: '',      PBLK: '',      BLK: 'CT381', L: '2.3',  B: '2.3',  H: '1.8',  YARD: 'HHI',   SC_WF: 16,     FB_WF: 24.3,    AS_WF: 0,       PE1_WF: 0,       PT_WF: 0,       PE2_WF: 0,       ER_WF: 0,      SC_PS: '2025-05-28', SC_PF: '2025-06-17', SC_PP: 0,     SC_AS: undefined,    SC_AF: undefined,    SC_AP: 0,   FB_PS: '2025-06-18', FB_PF: '2025-08-20', FB_PP: 0,     FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: undefined,    AS_PF: undefined,    AS_PP: 0,    AS_AS: undefined,    AS_AF: undefined,    AS_AP: 0,    PE1_PS: undefined,    PE1_PF: undefined,    PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: undefined,    PT_PF: undefined,    PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: undefined,    PE2_PF: undefined,    PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: undefined,    ER_PF: undefined,    ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'TOPSIDE', ZONE: 'CENTRAL',        MOD: 'Main Deck',           LEVEL: 'Main Deck',           NAME: 'Access P/F',             G_EREC: 'CD340', G_2PE: 'CD340', G_1PE: 'CV3A1', EREC: '',      PE2: '',      PE1: '',      PBLK: 'CV3A1', BLK: 'CV3A1', L: '7.5',  B: '3.3',  H: '1.3',  YARD: 'HHI',   SC_WF: 8,      FB_WF: 0,       AS_WF: 24,      PE1_WF: 0,       PT_WF: 40.9,    PE2_WF: 32,      ER_WF: 0,      SC_PS: '2025-06-17', SC_PF: '2025-07-04', SC_PP: 0,     SC_AS: undefined,    SC_AF: undefined,    SC_AP: 0,   FB_PS: undefined,    FB_PF: undefined,    FB_PP: 0,     FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: '2025-07-07', AS_PF: '2025-09-08', AS_PP: 0,    AS_AS: undefined,    AS_AF: undefined,    AS_AP: 0,    PE1_PS: undefined,    PE1_PF: undefined,    PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: '2025-09-29', PT_PF: '2025-10-28', PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: '2025-10-29', PE2_PF: '2025-11-18', PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: undefined,    ER_PF: undefined,    ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'TOPSIDE', ZONE: 'CENTRAL',        MOD: 'Main Deck',           LEVEL: 'Main Deck',           NAME: 'Access P/F',             G_EREC: 'CD340', G_2PE: 'CD340', G_1PE: 'CV3B1', EREC: '',      PE2: '',      PE1: '',      PBLK: 'CV3B1', BLK: 'CV3B1', L: '1.9',  B: '12.1', H: '1.3',  YARD: 'HHI',   SC_WF: 16,     FB_WF: 0,       AS_WF: 27.34,   PE1_WF: 0,       PT_WF: 80.81,   PE2_WF: 45.56,   ER_WF: 0,      SC_PS: '2025-06-17', SC_PF: '2025-07-04', SC_PP: 0,     SC_AS: undefined,    SC_AF: undefined,    SC_AP: 0,   FB_PS: undefined,    FB_PF: undefined,    FB_PP: 0,     FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: '2025-07-07', AS_PF: '2025-09-08', AS_PP: 0,    AS_AS: undefined,    AS_AF: undefined,    AS_AP: 0,    PE1_PS: undefined,    PE1_PF: undefined,    PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: '2025-09-29', PT_PF: '2025-10-28', PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: '2025-10-29', PE2_PF: '2025-11-18', PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: undefined,    ER_PF: undefined,    ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'TOPSIDE', ZONE: 'CENTRAL',        MOD: 'Main Deck',           LEVEL: 'Main Deck',           NAME: 'Access P/F',             G_EREC: 'CD340', G_2PE: 'CD340', G_1PE: 'CV3C1', EREC: '',      PE2: '',      PE1: '',      PBLK: 'CV3C1', BLK: 'CV3C1', L: '3.7',  B: '3.9',  H: '6',    YARD: 'HHI',   SC_WF: 16,     FB_WF: 0,       AS_WF: 27.34,   PE1_WF: 0,       PT_WF: 80.81,   PE2_WF: 45.56,   ER_WF: 0,      SC_PS: '2025-06-17', SC_PF: '2025-07-04', SC_PP: 0,     SC_AS: undefined,    SC_AF: undefined,    SC_AP: 0,   FB_PS: undefined,    FB_PF: undefined,    FB_PP: 0,     FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: '2025-07-07', AS_PF: '2025-09-08', AS_PP: 0,    AS_AS: undefined,    AS_AF: undefined,    AS_AP: 0,    PE1_PS: undefined,    PE1_PF: undefined,    PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: '2025-09-29', PT_PF: '2025-10-28', PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: '2025-10-29', PE2_PF: '2025-11-18', PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: undefined,    ER_PF: undefined,    ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'TOPSIDE', ZONE: 'CENTRAL',        MOD: 'Main Deck',           LEVEL: 'Main Deck',           NAME: 'Access P/F',             G_EREC: 'CD340', G_2PE: 'CD340', G_1PE: 'CV3D1', EREC: '',      PE2: '',      PE1: '',      PBLK: 'CV3D1', BLK: 'CV3D1', L: '7.5',  B: '1.1',  H: '1.3',  YARD: 'HHI',   SC_WF: 8,      FB_WF: 0,       AS_WF: 24,      PE1_WF: 0,       PT_WF: 37.76,   PE2_WF: 32,      ER_WF: 0,      SC_PS: '2025-06-17', SC_PF: '2025-07-04', SC_PP: 0,     SC_AS: undefined,    SC_AF: undefined,    SC_AP: 0,   FB_PS: undefined,    FB_PF: undefined,    FB_PP: 0,     FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: '2025-07-07', AS_PF: '2025-09-08', AS_PP: 0,    AS_AS: undefined,    AS_AF: undefined,    AS_AP: 0,    PE1_PS: undefined,    PE1_PF: undefined,    PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: '2025-09-29', PT_PF: '2025-10-28', PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: '2025-10-29', PE2_PF: '2025-11-18', PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: undefined,    ER_PF: undefined,    ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'TOPSIDE', ZONE: 'CENTRAL',        MOD: 'Main Deck',           LEVEL: 'Main Deck',           NAME: 'Access P/F',             G_EREC: 'CD340', G_2PE: 'CD340', G_1PE: 'CV3E1', EREC: '',      PE2: '',      PE1: '',      PBLK: 'CV3E1', BLK: 'CV3E1', L: '1.7',  B: '12.1', H: '1.4',  YARD: 'HHI',   SC_WF: 8,      FB_WF: 0,       AS_WF: 24,      PE1_WF: 0,       PT_WF: 37.76,   PE2_WF: 16,      ER_WF: 0,      SC_PS: '2025-06-17', SC_PF: '2025-07-04', SC_PP: 0,     SC_AS: undefined,    SC_AF: undefined,    SC_AP: 0,   FB_PS: undefined,    FB_PF: undefined,    FB_PP: 0,     FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: '2025-07-07', AS_PF: '2025-09-08', AS_PP: 0,    AS_AS: undefined,    AS_AF: undefined,    AS_AP: 0,    PE1_PS: undefined,    PE1_PF: undefined,    PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: '2025-09-29', PT_PF: '2025-10-28', PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: '2025-10-29', PE2_PF: '2025-11-18', PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: undefined,    ER_PF: undefined,    ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'TOPSIDE', ZONE: 'CENTRAL',        MOD: 'Main Deck',           LEVEL: 'Main Deck',           NAME: 'Access P/F',             G_EREC: 'CD340', G_2PE: 'CD340', G_1PE: 'CV3F1', EREC: '',      PE2: '',      PE1: '',      PBLK: 'CV3F1', BLK: 'CV3F1', L: '1.5',  B: '11.9', H: '0.5',  YARD: 'HHI',   SC_WF: 0,      FB_WF: 0,       AS_WF: 0,       PE1_WF: 0,       PT_WF: 0,       PE2_WF: 0,       ER_WF: 0,      SC_PS: undefined,    SC_PF: undefined,    SC_PP: 0,     SC_AS: undefined,    SC_AF: undefined,    SC_AP: 0,   FB_PS: undefined,    FB_PF: undefined,    FB_PP: 0,     FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: undefined,    AS_PF: undefined,    AS_PP: 0,    AS_AS: undefined,    AS_AF: undefined,    AS_AP: 0,    PE1_PS: undefined,    PE1_PF: undefined,    PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: undefined,    PT_PF: undefined,    PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: undefined,    PE2_PF: undefined,    PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: undefined,    ER_PF: undefined,    ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'TOPSIDE', ZONE: 'CENTRAL',        MOD: 'Main Deck',           LEVEL: 'Main Deck',           NAME: 'Access P/F',             G_EREC: 'CD340', G_2PE: 'CD340', G_1PE: 'CV3G1', EREC: '',      PE2: '',      PE1: '',      PBLK: 'CV3G1', BLK: 'CV3G1', L: '5.3',  B: '4.1',  H: '2.8',  YARD: 'HHI',   SC_WF: 0,      FB_WF: 0,       AS_WF: 0,       PE1_WF: 0,       PT_WF: 0,       PE2_WF: 0,       ER_WF: 0,      SC_PS: undefined,    SC_PF: undefined,    SC_PP: 0,     SC_AS: undefined,    SC_AF: undefined,    SC_AP: 0,   FB_PS: undefined,    FB_PF: undefined,    FB_PP: 0,     FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: undefined,    AS_PF: undefined,    AS_PP: 0,    AS_AS: undefined,    AS_AF: undefined,    AS_AP: 0,    PE1_PS: undefined,    PE1_PF: undefined,    PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: undefined,    PT_PF: undefined,    PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: undefined,    PE2_PF: undefined,    PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: undefined,    ER_PF: undefined,    ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'TOPSIDE', ZONE: 'CENTRAL',        MOD: 'Main Deck',           LEVEL: 'Main Deck',           NAME: 'Access P/F',             G_EREC: 'CD340', G_2PE: 'CD340', G_1PE: 'CV3H1', EREC: '',      PE2: '',      PE1: '',      PBLK: 'CV3H1', BLK: 'CV3H1', L: '1.5',  B: '1.9',  H: '0.9',  YARD: 'HHI',   SC_WF: 0,      FB_WF: 0,       AS_WF: 0,       PE1_WF: 0,       PT_WF: 0,       PE2_WF: 0,       ER_WF: 0,      SC_PS: undefined,    SC_PF: undefined,    SC_PP: 0,     SC_AS: undefined,    SC_AF: undefined,    SC_AP: 0,   FB_PS: undefined,    FB_PF: undefined,    FB_PP: 0,     FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: undefined,    AS_PF: undefined,    AS_PP: 0,    AS_AS: undefined,    AS_AF: undefined,    AS_AP: 0,    PE1_PS: undefined,    PE1_PF: undefined,    PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: undefined,    PT_PF: undefined,    PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: undefined,    PE2_PF: undefined,    PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: undefined,    ER_PF: undefined,    ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'TOPSIDE', ZONE: 'CENTRAL',        MOD: 'Main Deck',           LEVEL: 'Main Deck',           NAME: 'Access P/F',             G_EREC: 'CD340', G_2PE: 'CD340', G_1PE: 'CV351', EREC: '',      PE2: '',      PE1: '',      PBLK: 'CV351', BLK: 'CV351', L: '14.5', B: '6',    H: '1.9',  YARD: 'HHI',   SC_WF: 16.4,   FB_WF: 0,       AS_WF: 49.2,    PE1_WF: 0,       PT_WF: 222.1,   PE2_WF: 82.01,   ER_WF: 0,      SC_PS: '2025-06-17', SC_PF: '2025-07-04', SC_PP: 0,     SC_AS: undefined,    SC_AF: undefined,    SC_AP: 0,   FB_PS: undefined,    FB_PF: undefined,    FB_PP: 0,     FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: '2025-07-07', AS_PF: '2025-09-08', AS_PP: 0,    AS_AS: undefined,    AS_AF: undefined,    AS_AP: 0,    PE1_PS: undefined,    PE1_PF: undefined,    PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: '2025-09-29', PT_PF: '2025-10-28', PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: '2025-10-29', PE2_PF: '2025-11-18', PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: undefined,    ER_PF: undefined,    ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'TOPSIDE', ZONE: 'CENTRAL',        MOD: 'Main Deck',           LEVEL: 'Main Deck',           NAME: 'Crane Boom Rest',        G_EREC: 'CD340', G_2PE: 'CD340', G_1PE: 'CK131', EREC: '',      PE2: '',      PE1: '',      PBLK: 'CK131', BLK: 'CK131', L: '1.5',  B: '9.7',  H: '17.9', YARD: 'HHI',   SC_WF: 8,      FB_WF: 16,      AS_WF: 0,       PE1_WF: 27.34,   PT_WF: 652.02,  PE2_WF: 27.34,   ER_WF: 5822.9, SC_PS: '2025-06-24', SC_PF: '2025-07-11', SC_PP: 0,     SC_AS: undefined,    SC_AF: undefined,    SC_AP: 0,   FB_PS: '2025-07-14', FB_PF: '2025-09-08', FB_PP: 0,     FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: undefined,    AS_PF: undefined,    AS_PP: 0,    AS_AS: undefined,    AS_AF: undefined,    AS_AP: 0,    PE1_PS: '2025-09-09', PE1_PF: '2025-10-14', PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: '2025-10-15', PT_PF: '2025-11-06', PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: '2025-11-07', PE2_PF: '2025-12-05', PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: '2026-01-13', ER_PF: '2026-03-10', ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'TOPSIDE', ZONE: 'CENTRAL',        MOD: 'Main Deck',           LEVEL: 'Main Deck',           NAME: 'Pipe Rack',              G_EREC: 'CD340', G_2PE: 'CD340', G_1PE: 'CP371', EREC: '',      PE2: '',      PE1: '',      PBLK: 'CP371', BLK: 'CP371', L: '10.7', B: '13.8', H: '4.3',  YARD: 'HHI',   SC_WF: 27.34,  FB_WF: 0,       AS_WF: 82.01,   PE1_WF: 0,       PT_WF: 715.86,  PE2_WF: 136.68,  ER_WF: 0,      SC_PS: '2025-06-02', SC_PF: '2025-06-20', SC_PP: 0,     SC_AS: undefined,    SC_AF: undefined,    SC_AP: 0,   FB_PS: undefined,    FB_PF: undefined,    FB_PP: 0,     FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: '2025-06-23', AS_PF: '2025-08-18', AS_PP: 0,    AS_AS: undefined,    AS_AF: undefined,    AS_AP: 0,    PE1_PS: undefined,    PE1_PF: undefined,    PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: '2025-09-08', PT_PF: '2025-09-30', PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: '2025-10-01', PE2_PF: '2025-11-18', PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: undefined,    ER_PF: undefined,    ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'TOPSIDE', ZONE: 'CENTRAL',        MOD: 'Main Deck',           LEVEL: 'Main Deck',           NAME: 'Pipe Rack',              G_EREC: 'CD340', G_2PE: 'CD340', G_1PE: 'CP381', EREC: '',      PE2: '',      PE1: '',      PBLK: 'CP381', BLK: 'CP381', L: '13.1', B: '13.8', H: '4.3',  YARD: 'HHI',   SC_WF: 27.34,  FB_WF: 0,       AS_WF: 82.01,   PE1_WF: 0,       PT_WF: 748.4,   PE2_WF: 136.68,  ER_WF: 0,      SC_PS: '2025-06-02', SC_PF: '2025-06-20', SC_PP: 0,     SC_AS: undefined,    SC_AF: undefined,    SC_AP: 0,   FB_PS: undefined,    FB_PF: undefined,    FB_PP: 0,     FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: '2025-06-23', AS_PF: '2025-08-18', AS_PP: 0,    AS_AS: undefined,    AS_AF: undefined,    AS_AP: 0,    PE1_PS: undefined,    PE1_PF: undefined,    PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: '2025-09-08', PT_PF: '2025-09-30', PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: '2025-10-01', PE2_PF: '2025-11-18', PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: undefined,    ER_PF: undefined,    ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'TOPSIDE', ZONE: 'CENTRAL',        MOD: 'Main Deck',           LEVEL: 'Main Deck',           NAME: 'Pipe Rack',              G_EREC: 'CD340', G_2PE: 'CD340', G_1PE: 'CP391', EREC: '',      PE2: '',      PE1: '',      PBLK: 'CP391', BLK: 'CP391', L: '12.5', B: '14',   H: '4.3',  YARD: 'HHI',   SC_WF: 36.45,  FB_WF: 0,       AS_WF: 109.34,  PE1_WF: 0,       PT_WF: 699.47,  PE2_WF: 182.23,  ER_WF: 0,      SC_PS: '2025-06-02', SC_PF: '2025-06-20', SC_PP: 0,     SC_AS: undefined,    SC_AF: undefined,    SC_AP: 0,   FB_PS: undefined,    FB_PF: undefined,    FB_PP: 0,     FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: '2025-06-23', AS_PF: '2025-08-18', AS_PP: 0,    AS_AS: undefined,    AS_AF: undefined,    AS_AP: 0,    PE1_PS: undefined,    PE1_PF: undefined,    PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: '2025-09-08', PT_PF: '2025-09-30', PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: '2025-10-01', PE2_PF: '2025-11-18', PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: undefined,    ER_PF: undefined,    ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'TOPSIDE', ZONE: 'CENTRAL',        MOD: 'Main Deck',           LEVEL: 'Main Deck',           NAME: 'Pipe Rack',              G_EREC: 'CD340', G_2PE: 'CD340', G_1PE: 'CP3A1', EREC: '',      PE2: '',      PE1: '',      PBLK: 'CP3A1', BLK: 'CP3A1', L: '12.8', B: '13',   H: '4.3',  YARD: 'HHI',   SC_WF: 36.45,  FB_WF: 0,       AS_WF: 109.34,  PE1_WF: 0,       PT_WF: 731.26,  PE2_WF: 182.23,  ER_WF: 0,      SC_PS: '2025-06-02', SC_PF: '2025-06-20', SC_PP: 0,     SC_AS: undefined,    SC_AF: undefined,    SC_AP: 0,   FB_PS: undefined,    FB_PF: undefined,    FB_PP: 0,     FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: '2025-06-23', AS_PF: '2025-08-18', AS_PP: 0,    AS_AS: undefined,    AS_AF: undefined,    AS_AP: 0,    PE1_PS: undefined,    PE1_PF: undefined,    PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: '2025-09-08', PT_PF: '2025-09-30', PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: '2025-10-01', PE2_PF: '2025-11-18', PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: undefined,    ER_PF: undefined,    ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'TOPSIDE', ZONE: 'CENTRAL',        MOD: 'Main Deck',           LEVEL: 'Main Deck',           NAME: 'Pipe Rack',              G_EREC: 'CD340', G_2PE: 'CD340', G_1PE: 'CP3B1', EREC: '',      PE2: '',      PE1: '',      PBLK: 'CP3B1', BLK: 'CP3B1', L: '3.4',  B: '12.5', H: '3.4',  YARD: 'HHI',   SC_WF: 16,     FB_WF: 0,       AS_WF: 27.34,   PE1_WF: 0,       PT_WF: 180.33,  PE2_WF: 45.56,   ER_WF: 0,      SC_PS: '2025-06-02', SC_PF: '2025-06-20', SC_PP: 0,     SC_AS: undefined,    SC_AF: undefined,    SC_AP: 0,   FB_PS: undefined,    FB_PF: undefined,    FB_PP: 0,     FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: '2025-06-23', AS_PF: '2025-08-18', AS_PP: 0,    AS_AS: undefined,    AS_AF: undefined,    AS_AP: 0,    PE1_PS: undefined,    PE1_PF: undefined,    PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: '2025-09-08', PT_PF: '2025-09-30', PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: '2025-10-01', PE2_PF: '2025-11-18', PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: undefined,    ER_PF: undefined,    ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'TOPSIDE', ZONE: 'CENTRAL',        MOD: 'Main Deck',           LEVEL: 'Main Deck',           NAME: 'Pipe Rack',              G_EREC: 'CD340', G_2PE: 'CD340', G_1PE: 'CP3C1', EREC: '',      PE2: '',      PE1: '',      PBLK: 'CP3C1', BLK: 'CP3C1', L: '3.3',  B: '14.8', H: '3.4',  YARD: 'HHI',   SC_WF: 16,     FB_WF: 0,       AS_WF: 27.34,   PE1_WF: 0,       PT_WF: 188.53,  PE2_WF: 45.56,   ER_WF: 0,      SC_PS: '2025-06-02', SC_PF: '2025-06-20', SC_PP: 0,     SC_AS: undefined,    SC_AF: undefined,    SC_AP: 0,   FB_PS: undefined,    FB_PF: undefined,    FB_PP: 0,     FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: '2025-06-23', AS_PF: '2025-08-18', AS_PP: 0,    AS_AS: undefined,    AS_AF: undefined,    AS_AP: 0,    PE1_PS: undefined,    PE1_PF: undefined,    PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: '2025-09-08', PT_PF: '2025-09-30', PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: '2025-10-01', PE2_PF: '2025-11-18', PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: undefined,    ER_PF: undefined,    ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'TOPSIDE', ZONE: 'CENTRAL',        MOD: 'Main Deck',           LEVEL: 'Main Deck',           NAME: 'Stair Tower',            G_EREC: 'CX110', G_2PE: 'CX110', G_1PE: 'CX111', EREC: 'CX110', PE2: '',      PE1: '',      PBLK: 'CX111', BLK: 'CX111', L: '4.7',  B: '9.1',  H: '20.1', YARD: 'HHI',   SC_WF: 36.45,  FB_WF: 72.89,   AS_WF: 109.34,  PE1_WF: 0,       PT_WF: 814.1,   PE2_WF: 72.89,   ER_WF: 5822.9, SC_PS: '2025-07-03', SC_PF: '2025-07-22', SC_PP: 0,     SC_AS: undefined,    SC_AF: undefined,    SC_AP: 0,   FB_PS: '2025-07-23', FB_PF: '2025-08-27', FB_PP: 0,     FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: '2025-08-28', AS_PF: '2025-10-10', AS_PP: 0,    AS_AS: undefined,    AS_AF: undefined,    AS_AP: 0,    PE1_PS: undefined,    PE1_PF: undefined,    PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: '2025-11-05', PT_PF: '2025-11-27', PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: '2025-11-28', PE2_PF: '2025-12-18', PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: '2025-12-23', ER_PF: '2026-03-10', ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'TOPSIDE', ZONE: 'CENTRAL',        MOD: 'Main Deck',           LEVEL: 'Main Deck',           NAME: 'Crane Pedstal',          G_EREC: 'CK110', G_2PE: 'CK110', G_1PE: 'CK110', EREC: 'CK110', PE2: '',      PE1: 'CK110', PBLK: 'CK111', BLK: 'CK111', L: '8.7',  B: '16.8', H: '15.7', YARD: 'HHI',   SC_WF: 54.67,  FB_WF: 109.34,  AS_WF: 164.01,  PE1_WF: 273.35,  PT_WF: 173.87,  PE2_WF: 109.34,  ER_WF: 5822.9, SC_PS: '2025-06-18', SC_PF: '2025-07-07', SC_PP: 0,     SC_AS: undefined,    SC_AF: undefined,    SC_AP: 0,   FB_PS: '2025-07-08', FB_PF: '2025-08-14', FB_PP: 0,     FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: '2025-08-18', AS_PF: '2025-09-30', AS_PP: 0,    AS_AS: undefined,    AS_AF: undefined,    AS_AP: 0,    PE1_PS: '2025-10-01', PE1_PF: '2025-11-06', PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: '2025-11-07', PT_PF: '2025-12-01', PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: '2025-12-02', PE2_PF: '2025-12-30', PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: '2026-01-06', ER_PF: '2026-03-10', ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'TOPSIDE', ZONE: 'CENTRAL',        MOD: 'Main Deck',           LEVEL: 'Main Deck',           NAME: 'Crane Pedstal',          G_EREC: 'CK110', G_2PE: 'CK110', G_1PE: 'CK110', EREC: '',      PE2: '',      PE1: '',      PBLK: 'CV411', BLK: 'CV411', L: '3.8',  B: '5.1',  H: '4.2',  YARD: 'HHI',   SC_WF: 8,      FB_WF: 0,       AS_WF: 24,      PE1_WF: 0,       PT_WF: 37.26,   PE2_WF: 16,      ER_WF: 0,      SC_PS: '2025-08-14', SC_PF: '2025-09-03', SC_PP: 0,     SC_AS: undefined,    SC_AF: undefined,    SC_AP: 0,   FB_PS: undefined,    FB_PF: undefined,    FB_PP: 0,     FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: '2025-09-04', AS_PF: '2025-10-24', AS_PP: 0,    AS_AS: undefined,    AS_AF: undefined,    AS_AP: 0,    PE1_PS: undefined,    PE1_PF: undefined,    PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: '2025-11-14', PT_PF: '2025-12-08', PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: '2025-12-09', PE2_PF: '2025-12-30', PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: undefined,    ER_PF: undefined,    ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'TOPSIDE', ZONE: 'CENTRAL',        MOD: 'Main Deck',           LEVEL: 'Main Deck',           NAME: 'Crane Pedstal',          G_EREC: 'CK110', G_2PE: 'CK110', G_1PE: 'CK110', EREC: '',      PE2: '',      PE1: '',      PBLK: 'CV421', BLK: 'CV421', L: '3',    B: '2.3',  H: '2.3',  YARD: 'HHI',   SC_WF: 8,      FB_WF: 0,       AS_WF: 24,      PE1_WF: 0,       PT_WF: 37.26,   PE2_WF: 16,      ER_WF: 0,      SC_PS: '2025-08-14', SC_PF: '2025-09-03', SC_PP: 0,     SC_AS: undefined,    SC_AF: undefined,    SC_AP: 0,   FB_PS: undefined,    FB_PF: undefined,    FB_PP: 0,     FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: '2025-09-04', AS_PF: '2025-10-24', AS_PP: 0,    AS_AS: undefined,    AS_AF: undefined,    AS_AP: 0,    PE1_PS: undefined,    PE1_PF: undefined,    PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: '2025-11-14', PT_PF: '2025-12-08', PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: '2025-12-09', PE2_PF: '2025-12-30', PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: undefined,    ER_PF: undefined,    ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'TOPSIDE', ZONE: 'CENTRAL',        MOD: 'Main Deck',           LEVEL: 'Main Deck',           NAME: 'Crane Pedstal',          G_EREC: 'CK140', G_2PE: 'CK140', G_1PE: 'CK140', EREC: 'CK140', PE2: '',      PE1: 'CK140', PBLK: 'CK141', BLK: 'CK141', L: '10.5', B: '16.8', H: '15.9', YARD: 'HHI',   SC_WF: 149.43, FB_WF: 298.86,  AS_WF: 0,       PE1_WF: 0,       PT_WF: 0,       PE2_WF: 0,       ER_WF: 0,      SC_PS: '2025-06-25', SC_PF: '2025-07-14', SC_PP: 0,     SC_AS: undefined,    SC_AF: undefined,    SC_AP: 0,   FB_PS: '2025-07-15', FB_PF: '2025-09-11', FB_PP: 0,     FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: undefined,    AS_PF: undefined,    AS_PP: 0,    AS_AS: undefined,    AS_AF: undefined,    AS_AP: 0,    PE1_PS: undefined,    PE1_PF: undefined,    PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: undefined,    PT_PF: undefined,    PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: undefined,    PE2_PF: undefined,    PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: undefined,    ER_PF: undefined,    ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'TOPSIDE', ZONE: 'CENTRAL',        MOD: 'Main Deck',           LEVEL: 'Main Deck',           NAME: 'Crane Pedstal',          G_EREC: 'CK140', G_2PE: 'CK140', G_1PE: 'CK140', EREC: '',      PE2: '',      PE1: '',      PBLK: 'CK151', BLK: 'CV431', L: '11.1', B: '9.1',  H: '7.3',  YARD: 'HHI',   SC_WF: 0,      FB_WF: 0,       AS_WF: 0,       PE1_WF: 0,       PT_WF: 0,       PE2_WF: 0,       ER_WF: 0,      SC_PS: undefined,    SC_PF: undefined,    SC_PP: 0,     SC_AS: undefined,    SC_AF: undefined,    SC_AP: 0,   FB_PS: undefined,    FB_PF: undefined,    FB_PP: 0,     FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: undefined,    AS_PF: undefined,    AS_PP: 0,    AS_AS: undefined,    AS_AF: undefined,    AS_AP: 0,    PE1_PS: undefined,    PE1_PF: undefined,    PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: undefined,    PT_PF: undefined,    PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: undefined,    PE2_PF: undefined,    PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: undefined,    ER_PF: undefined,    ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'TOPSIDE', ZONE: 'CENTRAL',        MOD: 'Main Deck',           LEVEL: 'Main Deck',           NAME: 'Crane Pedstal',          G_EREC: 'CK140', G_2PE: 'CK140', G_1PE: 'CK140', EREC: '',      PE2: '',      PE1: '',      PBLK: 'CV441', BLK: 'CV441', L: '4.8',  B: '5.4',  H: '1.7',  YARD: 'HHI',   SC_WF: 0,      FB_WF: 0,       AS_WF: 0,       PE1_WF: 0,       PT_WF: 0,       PE2_WF: 0,       ER_WF: 0,      SC_PS: undefined,    SC_PF: undefined,    SC_PP: 0,     SC_AS: undefined,    SC_AF: undefined,    SC_AP: 0,   FB_PS: undefined,    FB_PF: undefined,    FB_PP: 0,     FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: undefined,    AS_PF: undefined,    AS_PP: 0,    AS_AS: undefined,    AS_AF: undefined,    AS_AP: 0,    PE1_PS: undefined,    PE1_PF: undefined,    PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: undefined,    PT_PF: undefined,    PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: undefined,    PE2_PF: undefined,    PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: undefined,    ER_PF: undefined,    ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'TOPSIDE', ZONE: 'SOUTH',          MOD: 'Production Deck',     LEVEL: 'Production Deck',     NAME: 'Deck Block',             G_EREC: 'SD110', G_2PE: 'SD110', G_1PE: 'SD110', EREC: 'SD110', PE2: 'SD110', PE1: 'SD110', PBLK: 'SD110', BLK: 'SD111', L: '20.2', B: '21.6', H: '5',    YARD: 'HHI',   SC_WF: 271.53, FB_WF: 543.06,  AS_WF: 814.59,  PE1_WF: 3276.58, PT_WF: 3522.55, PE2_WF: 1310.63, ER_WF: 5822.9, SC_PS: '2025-01-20', SC_PF: '2025-02-11', SC_PP: 100,   SC_AS: '2025-01-20', SC_AF: undefined,    SC_AP: 89,  FB_PS: '2025-02-12', FB_PF: '2025-03-11', FB_PP: 10.5,  FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: '2025-03-12', AS_PF: '2025-04-30', AS_PP: 0,    AS_AS: undefined,    AS_AF: undefined,    AS_AP: 0,    PE1_PS: '2025-05-02', PE1_PF: '2025-06-04', PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: '2025-06-05', PT_PF: '2025-06-30', PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: '2025-07-01', PE2_PF: '2025-08-19', PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: '2025-08-20', ER_PF: '2026-03-25', ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'TOPSIDE', ZONE: 'SOUTH',          MOD: 'Production Deck',     LEVEL: 'Production Deck',     NAME: 'Deck Block',             G_EREC: 'SD110', G_2PE: 'SD110', G_1PE: 'SD110', EREC: '',      PE2: '',      PE1: '',      PBLK: '',      BLK: 'SD112', L: '15.6', B: '4.3',  H: '1',    YARD: 'HHI',   SC_WF: 24,     FB_WF: 43.74,   AS_WF: 65.6,    PE1_WF: 0,       PT_WF: 0,       PE2_WF: 0,       ER_WF: 0,      SC_PS: '2025-02-06', SC_PF: '2025-02-25', SC_PP: 35,    SC_AS: '2025-01-20', SC_AF: undefined,    SC_AP: 89,  FB_PS: '2025-02-26', FB_PF: '2025-03-20', FB_PP: 0,     FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: '2025-03-21', AS_PF: '2025-04-30', AS_PP: 0,    AS_AS: undefined,    AS_AF: undefined,    AS_AP: 0,    PE1_PS: undefined,    PE1_PF: undefined,    PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: undefined,    PT_PF: undefined,    PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: undefined,    PE2_PF: undefined,    PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: undefined,    ER_PF: undefined,    ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'TOPSIDE', ZONE: 'SOUTH',          MOD: 'Production Deck',     LEVEL: 'Production Deck',     NAME: 'Deck Block',             G_EREC: 'SD110', G_2PE: 'SD110', G_1PE: 'SD110', EREC: '',      PE2: '',      PE1: '',      PBLK: '',      BLK: 'SD113', L: '14.6', B: '12.5', H: '1.5',  YARD: 'HHI',   SC_WF: 112.99, FB_WF: 225.97,  AS_WF: 338.96,  PE1_WF: 0,       PT_WF: 0,       PE2_WF: 0,       ER_WF: 0,      SC_PS: '2025-01-22', SC_PF: '2025-02-13', SC_PP: 100,   SC_AS: '2025-01-20', SC_AF: undefined,    SC_AP: 89,  FB_PS: '2025-02-14', FB_PF: '2025-03-13', FB_PP: 3.5,   FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: '2025-03-14', AS_PF: '2025-04-30', AS_PP: 0,    AS_AS: undefined,    AS_AF: undefined,    AS_AP: 0,    PE1_PS: undefined,    PE1_PF: undefined,    PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: undefined,    PT_PF: undefined,    PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: undefined,    PE2_PF: undefined,    PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: undefined,    ER_PF: undefined,    ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'TOPSIDE', ZONE: 'SOUTH',          MOD: 'Production Deck',     LEVEL: 'Production Deck',     NAME: 'Deck Block',             G_EREC: 'SD110', G_2PE: 'SD110', G_1PE: 'SD110', EREC: '',      PE2: '',      PE1: '',      PBLK: '',      BLK: 'SD114', L: '14.7', B: '13.7', H: '5.8',  YARD: 'HHI',   SC_WF: 125.74, FB_WF: 251.48,  AS_WF: 377.23,  PE1_WF: 0,       PT_WF: 0,       PE2_WF: 0,       ER_WF: 0,      SC_PS: '2025-01-22', SC_PF: '2025-02-13', SC_PP: 100,   SC_AS: '2025-01-20', SC_AF: undefined,    SC_AP: 89,  FB_PS: '2025-02-14', FB_PF: '2025-03-13', FB_PP: 3.5,   FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: '2025-03-14', AS_PF: '2025-04-30', AS_PP: 0,    AS_AS: undefined,    AS_AF: undefined,    AS_AP: 0,    PE1_PS: undefined,    PE1_PF: undefined,    PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: undefined,    PT_PF: undefined,    PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: undefined,    PE2_PF: undefined,    PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: undefined,    ER_PF: undefined,    ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'TOPSIDE', ZONE: 'SOUTH',          MOD: 'Production Deck',     LEVEL: 'Production Deck',     NAME: 'Column & Brace',         G_EREC: 'SD110', G_2PE: 'SD110', G_1PE: 'SC111', EREC: '',      PE2: '',      PE1: '',      PBLK: 'SC111', BLK: 'SC111', L: '24',   B: '21.2', H: '15.9', YARD: 'HHI',   SC_WF: 123.19, FB_WF: 246.38,  AS_WF: 369.57,  PE1_WF: 0,       PT_WF: 703.77,  PE2_WF: 0,       ER_WF: 0,      SC_PS: '2025-01-31', SC_PF: '2025-02-18', SC_PP: 80,    SC_AS: undefined,    SC_AF: undefined,    SC_AP: 0,   FB_PS: '2025-02-19', FB_PF: '2025-03-06', FB_PP: 0,     FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: '2025-03-07', AS_PF: '2025-03-31', AS_PP: 0,    AS_AS: undefined,    AS_AF: undefined,    AS_AP: 0,    PE1_PS: undefined,    PE1_PF: undefined,    PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: '2025-05-02', PT_PF: '2025-06-04', PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: undefined,    PE2_PF: undefined,    PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: undefined,    ER_PF: undefined,    ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'TOPSIDE', ZONE: 'SOUTH',          MOD: 'Production Deck',     LEVEL: 'Production Deck',     NAME: 'Access P/F(Top)',        G_EREC: 'SD110', G_2PE: 'SD110', G_1PE: 'SV111', EREC: '',      PE2: '',      PE1: '',      PBLK: 'SV111', BLK: 'SV111', L: '3.3',  B: '2.5',  H: '1.1',  YARD: 'HHI',   SC_WF: 8,      FB_WF: 0,       AS_WF: 24,      PE1_WF: 0,       PT_WF: 16,      PE2_WF: 16,      ER_WF: 0,      SC_PS: '2025-03-24', SC_PF: '2025-04-10', SC_PP: 0,     SC_AS: undefined,    SC_AF: undefined,    SC_AP: 0,   FB_PS: undefined,    FB_PF: undefined,    FB_PP: 0,     FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: '2025-04-11', AS_PF: '2025-05-29', AS_PP: 0,    AS_AS: undefined,    AS_AF: undefined,    AS_AP: 0,    PE1_PS: undefined,    PE1_PF: undefined,    PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: '2025-06-20', PT_PF: '2025-07-14', PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: '2025-07-15', PE2_PF: '2025-08-19', PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: undefined,    ER_PF: undefined,    ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'TOPSIDE', ZONE: 'SOUTH',          MOD: 'Production Deck',     LEVEL: 'Production Deck',     NAME: 'Access P/F(Top)',        G_EREC: 'SD110', G_2PE: 'SD110', G_1PE: 'SV121', EREC: '',      PE2: '',      PE1: '',      PBLK: 'SV121', BLK: 'SV121', L: '3.3',  B: '2',    H: '1.1',  YARD: 'HHI',   SC_WF: 8,      FB_WF: 0,       AS_WF: 24,      PE1_WF: 0,       PT_WF: 16,      PE2_WF: 16,      ER_WF: 0,      SC_PS: '2025-03-24', SC_PF: '2025-04-10', SC_PP: 0,     SC_AS: undefined,    SC_AF: undefined,    SC_AP: 0,   FB_PS: undefined,    FB_PF: undefined,    FB_PP: 0,     FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: '2025-04-11', AS_PF: '2025-05-29', AS_PP: 0,    AS_AS: undefined,    AS_AF: undefined,    AS_AP: 0,    PE1_PS: undefined,    PE1_PF: undefined,    PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: '2025-06-20', PT_PF: '2025-07-14', PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: '2025-07-15', PE2_PF: '2025-08-19', PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: undefined,    ER_PF: undefined,    ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'TOPSIDE', ZONE: 'SOUTH',          MOD: 'Production Deck',     LEVEL: 'Production Deck',     NAME: 'Access P/F(Top)',        G_EREC: 'SD110', G_2PE: 'SD110', G_1PE: 'SV141', EREC: '',      PE2: '',      PE1: '',      PBLK: 'SV141', BLK: 'SV141', L: '3.3',  B: '2.9',  H: '1.1',  YARD: 'HHI',   SC_WF: 8,      FB_WF: 0,       AS_WF: 24,      PE1_WF: 0,       PT_WF: 31.3,    PE2_WF: 16,      ER_WF: 0,      SC_PS: '2025-03-24', SC_PF: '2025-04-10', SC_PP: 0,     SC_AS: undefined,    SC_AF: undefined,    SC_AP: 0,   FB_PS: undefined,    FB_PF: undefined,    FB_PP: 0,     FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: '2025-04-11', AS_PF: '2025-05-27', AS_PP: 0,    AS_AS: undefined,    AS_AF: undefined,    AS_AP: 0,    PE1_PS: undefined,    PE1_PF: undefined,    PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: '2025-06-18', PT_PF: '2025-07-10', PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: '2025-07-11', PE2_PF: '2025-08-14', PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: undefined,    ER_PF: undefined,    ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'TOPSIDE', ZONE: 'SOUTH',          MOD: 'Production Deck',     LEVEL: 'Production Deck',     NAME: 'Access P/F(Top)',        G_EREC: 'SD110', G_2PE: 'SD110', G_1PE: 'SV151', EREC: '',      PE2: '',      PE1: '',      PBLK: 'SV151', BLK: 'SV151', L: '1.9',  B: '1.6',  H: '1.2',  YARD: 'HHI',   SC_WF: 8,      FB_WF: 0,       AS_WF: 24,      PE1_WF: 0,       PT_WF: 16.03,   PE2_WF: 16,      ER_WF: 0,      SC_PS: '2025-03-24', SC_PF: '2025-04-10', SC_PP: 0,     SC_AS: undefined,    SC_AF: undefined,    SC_AP: 0,   FB_PS: undefined,    FB_PF: undefined,    FB_PP: 0,     FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: '2025-04-11', AS_PF: '2025-05-27', AS_PP: 0,    AS_AS: undefined,    AS_AF: undefined,    AS_AP: 0,    PE1_PS: undefined,    PE1_PF: undefined,    PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: '2025-06-18', PT_PF: '2025-07-10', PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: '2025-07-11', PE2_PF: '2025-08-14', PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: undefined,    ER_PF: undefined,    ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'TOPSIDE', ZONE: 'SOUTH',          MOD: 'Production Deck',     LEVEL: 'Production Deck',     NAME: 'Access P/F(Top)',        G_EREC: 'SD110', G_2PE: 'SD110', G_1PE: 'SV161', EREC: '',      PE2: '',      PE1: '',      PBLK: 'SV161', BLK: 'SV161', L: '1.7',  B: '3.2',  H: '1.1',  YARD: 'HHI',   SC_WF: 8,      FB_WF: 0,       AS_WF: 24,      PE1_WF: 0,       PT_WF: 16,      PE2_WF: 16,      ER_WF: 0,      SC_PS: '2025-03-24', SC_PF: '2025-04-10', SC_PP: 0,     SC_AS: undefined,    SC_AF: undefined,    SC_AP: 0,   FB_PS: undefined,    FB_PF: undefined,    FB_PP: 0,     FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: '2025-04-11', AS_PF: '2025-05-27', AS_PP: 0,    AS_AS: undefined,    AS_AF: undefined,    AS_AP: 0,    PE1_PS: undefined,    PE1_PF: undefined,    PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: '2025-06-18', PT_PF: '2025-07-10', PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: '2025-07-11', PE2_PF: '2025-08-14', PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: undefined,    ER_PF: undefined,    ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'TOPSIDE', ZONE: 'SOUTH',          MOD: 'Production Deck',     LEVEL: 'Production Deck',     NAME: 'Access P/F(Top)',        G_EREC: 'SD110', G_2PE: 'SD110', G_1PE: 'SV171', EREC: '',      PE2: '',      PE1: '',      PBLK: 'SV171', BLK: 'SV171', L: '5.6',  B: '3.6',  H: '4.4',  YARD: 'HHI',   SC_WF: 8,      FB_WF: 0,       AS_WF: 24,      PE1_WF: 0,       PT_WF: 30.63,   PE2_WF: 16,      ER_WF: 0,      SC_PS: '2025-03-24', SC_PF: '2025-04-10', SC_PP: 0,     SC_AS: undefined,    SC_AF: undefined,    SC_AP: 0,   FB_PS: undefined,    FB_PF: undefined,    FB_PP: 0,     FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: '2025-04-11', AS_PF: '2025-05-27', AS_PP: 0,    AS_AS: undefined,    AS_AF: undefined,    AS_AP: 0,    PE1_PS: undefined,    PE1_PF: undefined,    PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: '2025-06-18', PT_PF: '2025-07-10', PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: '2025-07-11', PE2_PF: '2025-08-14', PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: undefined,    ER_PF: undefined,    ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'TOPSIDE', ZONE: 'SOUTH',          MOD: 'Production Deck',     LEVEL: 'Production Deck',     NAME: 'Deck Block',             G_EREC: 'SD120', G_2PE: 'SD120', G_1PE: 'SD120', EREC: 'SD120', PE2: 'SD120', PE1: 'SD120', PBLK: 'SD120', BLK: 'SD121', L: '14.6', B: '12.7', H: '1.5',  YARD: 'HHI',   SC_WF: 111.16, FB_WF: 222.33,  AS_WF: 333.49,  PE1_WF: 3027.83, PT_WF: 3232.72, PE2_WF: 3027.83, ER_WF: 5822.9, SC_PS: '2025-01-23', SC_PF: '2025-02-14', SC_PP: 100,   SC_AS: '2025-01-20', SC_AF: '2025-02-13', SC_AP: 100, FB_PS: '2025-02-17', FB_PF: '2025-03-14', FB_PP: 0,     FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: '2025-03-17', AS_PF: '2025-05-12', AS_PP: 0,    AS_AS: undefined,    AS_AF: undefined,    AS_AP: 0,    PE1_PS: '2025-05-13', PE1_PF: '2025-06-12', PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: '2025-06-13', PT_PF: '2025-07-07', PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: '2025-07-08', PE2_PF: '2025-08-26', PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: '2025-08-29', ER_PF: '2026-03-25', ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'TOPSIDE', ZONE: 'SOUTH',          MOD: 'Production Deck',     LEVEL: 'Production Deck',     NAME: 'Deck Block',             G_EREC: 'SD120', G_2PE: 'SD120', G_1PE: 'SD120', EREC: '',      PE2: '',      PE1: '',      PBLK: '',      BLK: 'SD122', L: '14.6', B: '11.2', H: '1.5',  YARD: 'HHI',   SC_WF: 102.05, FB_WF: 204.1,   AS_WF: 306.15,  PE1_WF: 0,       PT_WF: 0,       PE2_WF: 0,       ER_WF: 0,      SC_PS: '2025-01-23', SC_PF: '2025-02-14', SC_PP: 100,   SC_AS: '2025-01-20', SC_AF: '2025-02-12', SC_AP: 100, FB_PS: '2025-02-17', FB_PF: '2025-03-14', FB_PP: 0,     FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: '2025-03-17', AS_PF: '2025-05-12', AS_PP: 0,    AS_AS: undefined,    AS_AF: undefined,    AS_AP: 0,    PE1_PS: undefined,    PE1_PF: undefined,    PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: undefined,    PT_PF: undefined,    PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: undefined,    PE2_PF: undefined,    PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: undefined,    ER_PF: undefined,    ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'TOPSIDE', ZONE: 'SOUTH',          MOD: 'Production Deck',     LEVEL: 'Production Deck',     NAME: 'Deck Block',             G_EREC: 'SD120', G_2PE: 'SD120', G_1PE: 'SD120', EREC: '',      PE2: '',      PE1: '',      PBLK: '',      BLK: 'SD123', L: '20.1', B: '21.3', H: '1.5',  YARD: 'HHI',   SC_WF: 262.42, FB_WF: 524.84,  AS_WF: 787.25,  PE1_WF: 0,       PT_WF: 0,       PE2_WF: 0,       ER_WF: 0,      SC_PS: '2025-02-04', SC_PF: '2025-02-21', SC_PP: 53.6,  SC_AS: '2025-02-04', SC_AF: '2025-02-13', SC_AP: 100, FB_PS: '2025-02-24', FB_PF: '2025-03-21', FB_PP: 0,     FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: '2025-03-24', AS_PF: '2025-05-12', AS_PP: 0,    AS_AS: undefined,    AS_AF: undefined,    AS_AP: 0,    PE1_PS: undefined,    PE1_PF: undefined,    PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: undefined,    PT_PF: undefined,    PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: undefined,    PE2_PF: undefined,    PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: undefined,    ER_PF: undefined,    ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'TOPSIDE', ZONE: 'SOUTH',          MOD: 'Production Deck',     LEVEL: 'Production Deck',     NAME: 'Deck Block',             G_EREC: 'SD120', G_2PE: 'SD120', G_1PE: 'SD120', EREC: '',      PE2: '',      PE1: '',      PBLK: '',      BLK: 'SD124', L: '20.1', B: '1.8',  H: '0.5',  YARD: 'HHI',   SC_WF: 24.3,   FB_WF: 36.45,   AS_WF: 54.67,   PE1_WF: 0,       PT_WF: 0,       PE2_WF: 0,       ER_WF: 0,      SC_PS: '2025-02-18', SC_PF: '2025-03-07', SC_PP: 0,     SC_AS: undefined,    SC_AF: undefined,    SC_AP: 0,   FB_PS: '2025-03-10', FB_PF: '2025-04-01', FB_PP: 0,     FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: '2025-04-02', AS_PF: '2025-05-12', AS_PP: 0,    AS_AS: undefined,    AS_AF: undefined,    AS_AP: 0,    PE1_PS: undefined,    PE1_PF: undefined,    PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: undefined,    PT_PF: undefined,    PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: undefined,    PE2_PF: undefined,    PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: undefined,    ER_PF: undefined,    ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'TOPSIDE', ZONE: 'SOUTH',          MOD: 'Production Deck',     LEVEL: 'Production Deck',     NAME: 'Column & Brace',         G_EREC: 'SD120', G_2PE: 'SD120', G_1PE: 'SC121', EREC: '',      PE2: '',      PE1: '',      PBLK: '',      BLK: 'SC121', L: '29.3', B: '21.2', H: '13',   YARD: 'HHI',   SC_WF: 111.71, FB_WF: 223.42,  AS_WF: 335.13,  PE1_WF: 0,       PT_WF: 645.82,  PE2_WF: 0,       ER_WF: 0,      SC_PS: '2025-02-07', SC_PF: '2025-02-25', SC_PP: 32.3,  SC_AS: undefined,    SC_AF: undefined,    SC_AP: 0,   FB_PS: '2025-02-26', FB_PF: '2025-03-13', FB_PP: 0,     FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: '2025-03-14', AS_PF: '2025-04-07', AS_PP: 0,    AS_AS: undefined,    AS_AF: undefined,    AS_AP: 0,    PE1_PS: undefined,    PE1_PF: undefined,    PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: '2025-05-13', PT_PF: '2025-06-12', PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: undefined,    PE2_PF: undefined,    PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: undefined,    ER_PF: undefined,    ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'TOPSIDE', ZONE: 'SOUTH',          MOD: 'Production Deck',     LEVEL: 'Production Deck',     NAME: 'Deck Block',             G_EREC: 'SD120', G_2PE: 'SD120', G_1PE: 'SD130', EREC: '',      PE2: '',      PE1: 'SD130', PBLK: 'SD130', BLK: 'SD131', L: '19',   B: '14.5', H: '2.9',  YARD: 'HHI',   SC_WF: 165.83, FB_WF: 331.67,  AS_WF: 497.5,   PE1_WF: 1878.84, PT_WF: 1898.28, PE2_WF: 1878.84, ER_WF: 0,      SC_PS: '2025-02-10', SC_PF: '2025-02-27', SC_PP: 25,    SC_AS: '2025-02-11', SC_AF: undefined,    SC_AP: 8,   FB_PS: '2025-02-28', FB_PF: '2025-03-27', FB_PP: 0,     FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: '2025-03-28', AS_PF: '2025-05-16', AS_PP: 0,    AS_AS: undefined,    AS_AF: undefined,    AS_AP: 0,    PE1_PS: '2025-05-19', PE1_PF: '2025-06-17', PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: '2025-06-18', PT_PF: '2025-07-09', PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: '2025-07-10', PE2_PF: '2025-08-27', PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: undefined,    ER_PF: undefined,    ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'TOPSIDE', ZONE: 'SOUTH',          MOD: 'Production Deck',     LEVEL: 'Production Deck',     NAME: 'Deck Block',             G_EREC: 'SD120', G_2PE: 'SD120', G_1PE: 'SD130', EREC: '',      PE2: '',      PE1: '',      PBLK: '',      BLK: 'SD132', L: '19.5', B: '12.7', H: '5',    YARD: 'HHI',   SC_WF: 151.25, FB_WF: 302.51,  AS_WF: 453.76,  PE1_WF: 0,       PT_WF: 0,       PE2_WF: 0,       ER_WF: 0,      SC_PS: '2025-02-10', SC_PF: '2025-02-27', SC_PP: 25,    SC_AS: '2025-02-11', SC_AF: undefined,    SC_AP: 8,   FB_PS: '2025-02-28', FB_PF: '2025-03-27', FB_PP: 0,     FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: '2025-03-28', AS_PF: '2025-05-16', AS_PP: 0,    AS_AS: undefined,    AS_AF: undefined,    AS_AP: 0,    PE1_PS: undefined,    PE1_PF: undefined,    PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: undefined,    PT_PF: undefined,    PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: undefined,    PE2_PF: undefined,    PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: undefined,    ER_PF: undefined,    ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'TOPSIDE', ZONE: 'SOUTH',          MOD: 'Production Deck',     LEVEL: 'Production Deck',     NAME: 'Column & Brace',         G_EREC: 'SD120', G_2PE: 'SD120', G_1PE: 'SD130', EREC: '',      PE2: '',      PE1: '',      PBLK: '',      BLK: 'SC131', L: '22.7', B: '21',   H: '15.9', YARD: 'HHI',   SC_WF: 58.68,  FB_WF: 117.36,  AS_WF: 176.04,  PE1_WF: 0,       PT_WF: 380.04,  PE2_WF: 0,       ER_WF: 0,      SC_PS: '2025-02-13', SC_PF: '2025-03-03', SC_PP: 10.8,  SC_AS: undefined,    SC_AF: undefined,    SC_AP: 0,   FB_PS: '2025-03-04', FB_PF: '2025-03-19', FB_PP: 0,     FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: '2025-03-20', AS_PF: '2025-04-11', AS_PP: 0,    AS_AS: undefined,    AS_AF: undefined,    AS_AP: 0,    PE1_PS: undefined,    PE1_PF: undefined,    PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: '2025-05-19', PT_PF: '2025-06-17', PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: undefined,    PE2_PF: undefined,    PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: undefined,    ER_PF: undefined,    ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'TOPSIDE', ZONE: 'SOUTH',          MOD: 'Production Deck',     LEVEL: 'Production Deck',     NAME: 'ER',                     G_EREC: 'SD120', G_2PE: 'SD120', G_1PE: 'ER110', EREC: '',      PE2: '',      PE1: 'ER110', PBLK: 'ER110', BLK: 'ES121', L: '6.9',  B: '6.9',  H: '4.4',  YARD: 'HHI',   SC_WF: 0,      FB_WF: 0,       AS_WF: 0,       PE1_WF: 0,       PT_WF: 0,       PE2_WF: 0,       ER_WF: 0,      SC_PS: undefined,    SC_PF: undefined,    SC_PP: 0,     SC_AS: undefined,    SC_AF: undefined,    SC_AP: 0,   FB_PS: undefined,    FB_PF: undefined,    FB_PP: 0,     FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: undefined,    AS_PF: undefined,    AS_PP: 0,    AS_AS: undefined,    AS_AF: undefined,    AS_AP: 0,    PE1_PS: undefined,    PE1_PF: undefined,    PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: undefined,    PT_PF: undefined,    PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: undefined,    PE2_PF: undefined,    PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: undefined,    ER_PF: undefined,    ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'TOPSIDE', ZONE: 'SOUTH',          MOD: 'Production Deck',     LEVEL: 'Production Deck',     NAME: 'ER',                     G_EREC: 'SD120', G_2PE: 'SD120', G_1PE: 'ER110', EREC: '',      PE2: '',      PE1: '',      PBLK: '',      BLK: 'ER121', L: '8.4',  B: '6.9',  H: '1',    YARD: 'HHI',   SC_WF: 36.45,  FB_WF: 0,       AS_WF: 109.34,  PE1_WF: 0,       PT_WF: 0,       PE2_WF: 0,       ER_WF: 0,      SC_PS: '2025-02-11', SC_PF: '2025-02-28', SC_PP: 20,    SC_AS: undefined,    SC_AF: undefined,    SC_AP: 0,   FB_PS: undefined,    FB_PF: undefined,    FB_PP: 0,     FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: '2025-03-03', AS_PF: '2025-05-14', AS_PP: 0,    AS_AS: undefined,    AS_AF: undefined,    AS_AP: 0,    PE1_PS: undefined,    PE1_PF: undefined,    PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: undefined,    PT_PF: undefined,    PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: undefined,    PE2_PF: undefined,    PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: undefined,    ER_PF: undefined,    ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'TOPSIDE', ZONE: 'SOUTH',          MOD: 'Production Deck',     LEVEL: 'Production Deck',     NAME: 'SR',                     G_EREC: 'SD120', G_2PE: 'SD120', G_1PE: 'SR110', EREC: '',      PE2: '',      PE1: 'SR110', PBLK: 'SR110', BLK: 'SS121', L: '4.9',  B: '3.1',  H: '3.6',  YARD: 'HHI',   SC_WF: 0,      FB_WF: 0,       AS_WF: 0,       PE1_WF: 0,       PT_WF: 0,       PE2_WF: 0,       ER_WF: 0,      SC_PS: undefined,    SC_PF: undefined,    SC_PP: 0,     SC_AS: undefined,    SC_AF: undefined,    SC_AP: 0,   FB_PS: undefined,    FB_PF: undefined,    FB_PP: 0,     FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: undefined,    AS_PF: undefined,    AS_PP: 0,    AS_AS: undefined,    AS_AF: undefined,    AS_AP: 0,    PE1_PS: undefined,    PE1_PF: undefined,    PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: undefined,    PT_PF: undefined,    PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: undefined,    PE2_PF: undefined,    PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: undefined,    ER_PF: undefined,    ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'TOPSIDE', ZONE: 'SOUTH',          MOD: 'Production Deck',     LEVEL: 'Production Deck',     NAME: 'SR',                     G_EREC: 'SD120', G_2PE: 'SD120', G_1PE: 'SR110', EREC: '',      PE2: '',      PE1: '',      PBLK: '',      BLK: 'SR121', L: '4.9',  B: '3.4',  H: '0.6',  YARD: 'HHI',   SC_WF: 16,     FB_WF: 24.3,    AS_WF: 0,       PE1_WF: 0,       PT_WF: 0,       PE2_WF: 0,       ER_WF: 0,      SC_PS: '2025-02-21', SC_PF: '2025-03-12', SC_PP: 0,     SC_AS: undefined,    SC_AF: undefined,    SC_AP: 0,   FB_PS: '2025-03-13', FB_PF: '2025-05-21', FB_PP: 0,     FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: undefined,    AS_PF: undefined,    AS_PP: 0,    AS_AS: undefined,    AS_AF: undefined,    AS_AP: 0,    PE1_PS: undefined,    PE1_PF: undefined,    PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: undefined,    PT_PF: undefined,    PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: undefined,    PE2_PF: undefined,    PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: undefined,    ER_PF: undefined,    ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'TOPSIDE', ZONE: 'SOUTH',          MOD: 'Production Deck',     LEVEL: 'Production Deck',     NAME: 'Access P/F(Top)',        G_EREC: 'SD120', G_2PE: 'SD120', G_1PE: 'SV181', EREC: '',      PE2: '',      PE1: '',      PBLK: 'SV181', BLK: 'SV181', L: '11',   B: '1.8',  H: '1.1',  YARD: 'HHI',   SC_WF: 8,      FB_WF: 0,       AS_WF: 24,      PE1_WF: 0,       PT_WF: 63.71,   PE2_WF: 16,      ER_WF: 0,      SC_PS: '2025-04-02', SC_PF: '2025-04-21', SC_PP: 0,     SC_AS: undefined,    SC_AF: undefined,    SC_AP: 0,   FB_PS: undefined,    FB_PF: undefined,    FB_PP: 0,     FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: '2025-04-22', AS_PF: '2025-06-05', AS_PP: 0,    AS_AS: undefined,    AS_AF: undefined,    AS_AP: 0,    PE1_PS: undefined,    PE1_PF: undefined,    PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: '2025-06-27', PT_PF: '2025-07-21', PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: '2025-07-22', PE2_PF: '2025-08-26', PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: undefined,    ER_PF: undefined,    ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'TOPSIDE', ZONE: 'SOUTH',          MOD: 'Production Deck',     LEVEL: 'Production Deck',     NAME: 'Access P/F(Top)',        G_EREC: 'SD120', G_2PE: 'SD120', G_1PE: 'SV191', EREC: '',      PE2: '',      PE1: '',      PBLK: 'SV191', BLK: 'SV191', L: '11',   B: '1.8',  H: '1.1',  YARD: 'HHI',   SC_WF: 8,      FB_WF: 0,       AS_WF: 24,      PE1_WF: 0,       PT_WF: 63.71,   PE2_WF: 16,      ER_WF: 0,      SC_PS: '2025-04-02', SC_PF: '2025-04-21', SC_PP: 0,     SC_AS: undefined,    SC_AF: undefined,    SC_AP: 0,   FB_PS: undefined,    FB_PF: undefined,    FB_PP: 0,     FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: '2025-04-22', AS_PF: '2025-06-10', AS_PP: 0,    AS_AS: undefined,    AS_AF: undefined,    AS_AP: 0,    PE1_PS: undefined,    PE1_PF: undefined,    PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: '2025-07-01', PT_PF: '2025-07-23', PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: '2025-07-24', PE2_PF: '2025-08-28', PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: undefined,    ER_PF: undefined,    ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'TOPSIDE', ZONE: 'SOUTH',          MOD: 'Production Deck',     LEVEL: 'Production Deck',     NAME: 'Access P/F(Top)',        G_EREC: 'SD120', G_2PE: 'SD120', G_1PE: 'SV1A1', EREC: '',      PE2: '',      PE1: '',      PBLK: 'SV1A1', BLK: 'SV1A1', L: '2',    B: '1.6',  H: '1.5',  YARD: 'HHI',   SC_WF: 8,      FB_WF: 0,       AS_WF: 24,      PE1_WF: 0,       PT_WF: 16,      PE2_WF: 16,      ER_WF: 0,      SC_PS: '2025-04-02', SC_PF: '2025-04-21', SC_PP: 0,     SC_AS: undefined,    SC_AF: undefined,    SC_AP: 0,   FB_PS: undefined,    FB_PF: undefined,    FB_PP: 0,     FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: '2025-04-22', AS_PF: '2025-06-10', AS_PP: 0,    AS_AS: undefined,    AS_AF: undefined,    AS_AP: 0,    PE1_PS: undefined,    PE1_PF: undefined,    PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: '2025-07-01', PT_PF: '2025-07-23', PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: '2025-07-24', PE2_PF: '2025-08-28', PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: undefined,    ER_PF: undefined,    ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'TOPSIDE', ZONE: 'SOUTH',          MOD: 'Production Deck',     LEVEL: 'Production Deck',     NAME: 'Access P/F(Top)',        G_EREC: 'SD120', G_2PE: 'SD120', G_1PE: 'SV1B1', EREC: '',      PE2: '',      PE1: '',      PBLK: 'SV1B1', BLK: 'SV1B1', L: '1.6',  B: '2.1',  H: '0.8',  YARD: 'HHI',   SC_WF: 8,      FB_WF: 0,       AS_WF: 24,      PE1_WF: 0,       PT_WF: 16,      PE2_WF: 16,      ER_WF: 0,      SC_PS: '2025-04-02', SC_PF: '2025-04-21', SC_PP: 0,     SC_AS: undefined,    SC_AF: undefined,    SC_AP: 0,   FB_PS: undefined,    FB_PF: undefined,    FB_PP: 0,     FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: '2025-04-22', AS_PF: '2025-06-10', AS_PP: 0,    AS_AS: undefined,    AS_AF: undefined,    AS_AP: 0,    PE1_PS: undefined,    PE1_PF: undefined,    PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: '2025-07-01', PT_PF: '2025-07-23', PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: '2025-07-24', PE2_PF: '2025-08-28', PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: undefined,    ER_PF: undefined,    ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'TOPSIDE', ZONE: 'SOUTH',          MOD: 'Production Deck',     LEVEL: 'Production Deck',     NAME: 'Access P/F(Top)',        G_EREC: 'SD120', G_2PE: 'SD120', G_1PE: 'SV1C1', EREC: '',      PE2: '',      PE1: '',      PBLK: 'SV1C1', BLK: 'SV1C1', L: '2.6',  B: '3.4',  H: '1',    YARD: 'HHI',   SC_WF: 8,      FB_WF: 0,       AS_WF: 24,      PE1_WF: 0,       PT_WF: 27.32,   PE2_WF: 16,      ER_WF: 0,      SC_PS: '2025-04-02', SC_PF: '2025-04-21', SC_PP: 0,     SC_AS: undefined,    SC_AF: undefined,    SC_AP: 0,   FB_PS: undefined,    FB_PF: undefined,    FB_PP: 0,     FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: '2025-04-22', AS_PF: '2025-06-10', AS_PP: 0,    AS_AS: undefined,    AS_AF: undefined,    AS_AP: 0,    PE1_PS: undefined,    PE1_PF: undefined,    PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: '2025-07-01', PT_PF: '2025-07-23', PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: '2025-07-24', PE2_PF: '2025-08-28', PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: undefined,    ER_PF: undefined,    ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'TOPSIDE', ZONE: 'SOUTH',          MOD: 'Production Deck',     LEVEL: 'Production Deck',     NAME: 'Access P/F(Top)',        G_EREC: 'SD120', G_2PE: 'SD120', G_1PE: 'SV1D1', EREC: '',      PE2: '',      PE1: '',      PBLK: 'SV1D1', BLK: 'SV1D1', L: '2.6',  B: '3.4',  H: '1',    YARD: 'HHI',   SC_WF: 8,      FB_WF: 0,       AS_WF: 24,      PE1_WF: 0,       PT_WF: 27.32,   PE2_WF: 16,      ER_WF: 0,      SC_PS: '2025-04-02', SC_PF: '2025-04-21', SC_PP: 0,     SC_AS: undefined,    SC_AF: undefined,    SC_AP: 0,   FB_PS: undefined,    FB_PF: undefined,    FB_PP: 0,     FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: '2025-04-22', AS_PF: '2025-06-10', AS_PP: 0,    AS_AS: undefined,    AS_AF: undefined,    AS_AP: 0,    PE1_PS: undefined,    PE1_PF: undefined,    PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: '2025-07-01', PT_PF: '2025-07-23', PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: '2025-07-24', PE2_PF: '2025-08-28', PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: undefined,    ER_PF: undefined,    ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'TOPSIDE', ZONE: 'SOUTH',          MOD: 'Production Deck',     LEVEL: 'Production Deck',     NAME: 'Access P/F(Top)',        G_EREC: 'SD120', G_2PE: 'SD120', G_1PE: 'SV1E1', EREC: '',      PE2: '',      PE1: '',      PBLK: 'SV1E1', BLK: 'SV1E1', L: '2.1',  B: '1.6',  H: '0.8',  YARD: 'HHI',   SC_WF: 8,      FB_WF: 0,       AS_WF: 24,      PE1_WF: 0,       PT_WF: 16,      PE2_WF: 16,      ER_WF: 0,      SC_PS: '2025-04-02', SC_PF: '2025-04-21', SC_PP: 0,     SC_AS: undefined,    SC_AF: undefined,    SC_AP: 0,   FB_PS: undefined,    FB_PF: undefined,    FB_PP: 0,     FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: '2025-04-22', AS_PF: '2025-06-10', AS_PP: 0,    AS_AS: undefined,    AS_AF: undefined,    AS_AP: 0,    PE1_PS: undefined,    PE1_PF: undefined,    PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: '2025-07-01', PT_PF: '2025-07-23', PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: '2025-07-24', PE2_PF: '2025-08-28', PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: undefined,    ER_PF: undefined,    ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'TOPSIDE', ZONE: 'SOUTH',          MOD: 'Production Deck',     LEVEL: 'Production Deck',     NAME: 'Access P/F',             G_EREC: 'SD120', G_2PE: 'SD120', G_1PE: 'SV1F1', EREC: '',      PE2: '',      PE1: '',      PBLK: 'SV1F1', BLK: 'SV1F1', L: '7.4',  B: '3.3',  H: '4.4',  YARD: 'HHI',   SC_WF: 0,      FB_WF: 0,       AS_WF: 0,       PE1_WF: 0,       PT_WF: 0,       PE2_WF: 0,       ER_WF: 0,      SC_PS: undefined,    SC_PF: undefined,    SC_PP: 0,     SC_AS: undefined,    SC_AF: undefined,    SC_AP: 0,   FB_PS: undefined,    FB_PF: undefined,    FB_PP: 0,     FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: undefined,    AS_PF: undefined,    AS_PP: 0,    AS_AS: undefined,    AS_AF: undefined,    AS_AP: 0,    PE1_PS: undefined,    PE1_PF: undefined,    PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: undefined,    PT_PF: undefined,    PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: undefined,    PE2_PF: undefined,    PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: undefined,    ER_PF: undefined,    ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'TOPSIDE', ZONE: 'SOUTH',          MOD: 'Production Deck',     LEVEL: 'Production Deck',     NAME: 'Access P/F',             G_EREC: 'SD120', G_2PE: 'SD120', G_1PE: 'SV1G1', EREC: '',      PE2: '',      PE1: '',      PBLK: 'SV1G1', BLK: 'SV1G1', L: '7.8',  B: '4.6',  H: '5.3',  YARD: 'HHI',   SC_WF: 0,      FB_WF: 0,       AS_WF: 0,       PE1_WF: 0,       PT_WF: 0,       PE2_WF: 0,       ER_WF: 0,      SC_PS: undefined,    SC_PF: undefined,    SC_PP: 0,     SC_AS: undefined,    SC_AF: undefined,    SC_AP: 0,   FB_PS: undefined,    FB_PF: undefined,    FB_PP: 0,     FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: undefined,    AS_PF: undefined,    AS_PP: 0,    AS_AS: undefined,    AS_AF: undefined,    AS_AP: 0,    PE1_PS: undefined,    PE1_PF: undefined,    PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: undefined,    PT_PF: undefined,    PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: undefined,    PE2_PF: undefined,    PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: undefined,    ER_PF: undefined,    ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'TOPSIDE', ZONE: 'SOUTH',          MOD: 'Production Deck',     LEVEL: 'Production Deck',     NAME: 'Access P/F',             G_EREC: 'SD120', G_2PE: 'SD120', G_1PE: 'SV1H1', EREC: '',      PE2: '',      PE1: '',      PBLK: 'SV1H1', BLK: 'SV1H1', L: '4.1',  B: '1.8',  H: '5.5',  YARD: 'HHI',   SC_WF: 0,      FB_WF: 0,       AS_WF: 0,       PE1_WF: 0,       PT_WF: 0,       PE2_WF: 0,       ER_WF: 0,      SC_PS: undefined,    SC_PF: undefined,    SC_PP: 0,     SC_AS: undefined,    SC_AF: undefined,    SC_AP: 0,   FB_PS: undefined,    FB_PF: undefined,    FB_PP: 0,     FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: undefined,    AS_PF: undefined,    AS_PP: 0,    AS_AS: undefined,    AS_AF: undefined,    AS_AP: 0,    PE1_PS: undefined,    PE1_PF: undefined,    PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: undefined,    PT_PF: undefined,    PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: undefined,    PE2_PF: undefined,    PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: undefined,    ER_PF: undefined,    ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'TOPSIDE', ZONE: 'SOUTH',          MOD: 'Main Deck',           LEVEL: 'Main Deck',           NAME: 'Deck Block',             G_EREC: 'SD120', G_2PE: 'SD120', G_1PE: 'SD210', EREC: '',      PE2: '',      PE1: 'SD210', PBLK: 'SD210', BLK: 'SD211', L: '20.8', B: '11.2', H: '0.5',  YARD: 'HHI',   SC_WF: 24.3,   FB_WF: 36.45,   AS_WF: 54.67,   PE1_WF: 109.34,  PT_WF: 873.71,  PE2_WF: 109.34,  ER_WF: 0,      SC_PS: '2025-03-14', SC_PF: '2025-03-24', SC_PP: 0,     SC_AS: undefined,    SC_AF: undefined,    SC_AP: 0,   FB_PS: '2025-03-25', FB_PF: '2025-04-16', FB_PP: 0,     FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: '2025-04-17', AS_PF: '2025-05-21', AS_PP: 0,    AS_AS: undefined,    AS_AF: undefined,    AS_AP: 0,    PE1_PS: '2025-05-22', PE1_PF: '2025-06-23', PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: '2025-06-24', PT_PF: '2025-07-16', PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: '2025-07-17', PE2_PF: '2025-08-28', PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: undefined,    ER_PF: undefined,    ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'TOPSIDE', ZONE: 'SOUTH',          MOD: 'Main Deck',           LEVEL: 'Main Deck',           NAME: 'Column & Brace',         G_EREC: 'SD120', G_2PE: 'SD120', G_1PE: 'SD210', EREC: '',      PE2: '',      PE1: '',      PBLK: '',      BLK: 'SC211', L: '18',   B: '10.4', H: '4',    YARD: 'HHI',   SC_WF: 8,      FB_WF: 8,       AS_WF: 24,      PE1_WF: 0,       PT_WF: 0,       PE2_WF: 0,       ER_WF: 0,      SC_PS: '2025-03-14', SC_PF: '2025-03-21', SC_PP: 0,     SC_AS: undefined,    SC_AF: undefined,    SC_AP: 0,   FB_PS: '2025-03-24', FB_PF: '2025-04-04', FB_PP: 0,     FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: '2025-04-07', AS_PF: '2025-04-23', AS_PP: 0,    AS_AS: undefined,    AS_AF: undefined,    AS_AP: 0,    PE1_PS: undefined,    PE1_PF: undefined,    PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: undefined,    PT_PF: undefined,    PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: undefined,    PE2_PF: undefined,    PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: undefined,    ER_PF: undefined,    ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'TOPSIDE', ZONE: 'SOUTH',          MOD: 'Main Deck',           LEVEL: 'Main Deck',           NAME: 'Access P/F(Top)',        G_EREC: 'SD120', G_2PE: 'SD120', G_1PE: 'SD210', EREC: '',      PE2: '',      PE1: '',      PBLK: '',      BLK: 'SV211', L: '1.7',  B: '2.1',  H: '1.4',  YARD: 'HHI',   SC_WF: 8,      FB_WF: 8,       AS_WF: 0,       PE1_WF: 0,       PT_WF: 0,       PE2_WF: 0,       ER_WF: 0,      SC_PS: '2025-03-21', SC_PF: '2025-04-09', SC_PP: 0,     SC_AS: undefined,    SC_AF: undefined,    SC_AP: 0,   FB_PS: '2025-04-10', FB_PF: '2025-05-28', FB_PP: 0,     FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: undefined,    AS_PF: undefined,    AS_PP: 0,    AS_AS: undefined,    AS_AF: undefined,    AS_AP: 0,    PE1_PS: undefined,    PE1_PF: undefined,    PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: undefined,    PT_PF: undefined,    PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: undefined,    PE2_PF: undefined,    PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: undefined,    ER_PF: undefined,    ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'TOPSIDE', ZONE: 'SOUTH',          MOD: 'Main Deck',           LEVEL: 'Main Deck',           NAME: 'Access P/F(Top)',        G_EREC: 'SD120', G_2PE: 'SD120', G_1PE: 'SD210', EREC: '',      PE2: '',      PE1: '',      PBLK: '',      BLK: 'SV221', L: '3.2',  B: '2.8',  H: '2.6',  YARD: 'HHI',   SC_WF: 0,      FB_WF: 0,       AS_WF: 0,       PE1_WF: 0,       PT_WF: 0,       PE2_WF: 0,       ER_WF: 0,      SC_PS: undefined,    SC_PF: undefined,    SC_PP: 0,     SC_AS: undefined,    SC_AF: undefined,    SC_AP: 0,   FB_PS: undefined,    FB_PF: undefined,    FB_PP: 0,     FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: undefined,    AS_PF: undefined,    AS_PP: 0,    AS_AS: undefined,    AS_AF: undefined,    AS_AP: 0,    PE1_PS: undefined,    PE1_PF: undefined,    PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: undefined,    PT_PF: undefined,    PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: undefined,    PE2_PF: undefined,    PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: undefined,    ER_PF: undefined,    ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'TOPSIDE', ZONE: 'SOUTH',          MOD: 'Main Deck',           LEVEL: 'Main Deck',           NAME: 'Deck Block',             G_EREC: 'SD310', G_2PE: 'SD310', G_1PE: 'SD310', EREC: 'SD310', PE2: 'SD310', PE1: 'SD310', PBLK: 'SD310', BLK: 'SD311', L: '19.4', B: '22',   H: '1.5',  YARD: 'HHI',   SC_WF: 331.67, FB_WF: 663.33,  AS_WF: 995,     PE1_WF: 4045.61, PT_WF: 1836.1,  PE2_WF: 4045.61, ER_WF: 5822.9, SC_PS: '2025-05-09', SC_PF: '2025-05-28', SC_PP: 0,     SC_AS: undefined,    SC_AF: undefined,    SC_AP: 0,   FB_PS: '2025-05-29', FB_PF: '2025-06-26', FB_PP: 0,     FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: '2025-06-27', AS_PF: '2025-08-25', AS_PP: 0,    AS_AS: undefined,    AS_AF: undefined,    AS_AP: 0,    PE1_PS: '2025-08-26', PE1_PF: '2025-09-24', PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: '2025-09-25', PT_PF: '2025-10-24', PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: '2025-10-27', PE2_PF: '2025-11-25', PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: '2025-11-26', ER_PF: '2026-03-25', ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'TOPSIDE', ZONE: 'SOUTH',          MOD: 'Main Deck',           LEVEL: 'Main Deck',           NAME: 'Deck Block',             G_EREC: 'SD310', G_2PE: 'SD310', G_1PE: 'SD310', EREC: '',      PE2: '',      PE1: '',      PBLK: '',      BLK: 'SD312', L: '18.4', B: '2.4',  H: '0.7',  YARD: 'HHI',   SC_WF: 32.8,   FB_WF: 65.6,    AS_WF: 98.41,   PE1_WF: 0,       PT_WF: 0,       PE2_WF: 0,       ER_WF: 0,      SC_PS: '2025-05-23', SC_PF: '2025-06-12', SC_PP: 0,     SC_AS: undefined,    SC_AF: undefined,    SC_AP: 0,   FB_PS: '2025-06-13', FB_PF: '2025-07-07', FB_PP: 0,     FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: '2025-07-08', AS_PF: '2025-08-25', AS_PP: 0,    AS_AS: undefined,    AS_AF: undefined,    AS_AP: 0,    PE1_PS: undefined,    PE1_PF: undefined,    PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: undefined,    PT_PF: undefined,    PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: undefined,    PE2_PF: undefined,    PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: undefined,    ER_PF: undefined,    ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'TOPSIDE', ZONE: 'SOUTH',          MOD: 'Main Deck',           LEVEL: 'Main Deck',           NAME: 'Deck Block',             G_EREC: 'SD310', G_2PE: 'SD310', G_1PE: 'SD310', EREC: '',      PE2: '',      PE1: '',      PBLK: '',      BLK: 'SD313', L: '21.9', B: '13.1', H: '1.5',  YARD: 'HHI',   SC_WF: 207.75, FB_WF: 415.5,   AS_WF: 623.24,  PE1_WF: 0,       PT_WF: 0,       PE2_WF: 0,       ER_WF: 0,      SC_PS: '2025-05-08', SC_PF: '2025-05-27', SC_PP: 0,     SC_AS: undefined,    SC_AF: undefined,    SC_AP: 0,   FB_PS: '2025-05-28', FB_PF: '2025-06-25', FB_PP: 0,     FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: '2025-06-26', AS_PF: '2025-08-25', AS_PP: 0,    AS_AS: undefined,    AS_AF: undefined,    AS_AP: 0,    PE1_PS: undefined,    PE1_PF: undefined,    PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: undefined,    PT_PF: undefined,    PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: undefined,    PE2_PF: undefined,    PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: undefined,    ER_PF: undefined,    ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'TOPSIDE', ZONE: 'SOUTH',          MOD: 'Main Deck',           LEVEL: 'Main Deck',           NAME: 'Deck Block',             G_EREC: 'SD310', G_2PE: 'SD310', G_1PE: 'SD310', EREC: '',      PE2: '',      PE1: '',      PBLK: '',      BLK: 'SD314', L: '22.3', B: '12.4', H: '1.5',  YARD: 'HHI',   SC_WF: 200.46, FB_WF: 400.92,  AS_WF: 601.37,  PE1_WF: 0,       PT_WF: 0,       PE2_WF: 0,       ER_WF: 0,      SC_PS: '2025-05-12', SC_PF: '2025-05-29', SC_PP: 0,     SC_AS: undefined,    SC_AF: undefined,    SC_AP: 0,   FB_PS: '2025-05-30', FB_PF: '2025-06-27', FB_PP: 0,     FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: '2025-06-30', AS_PF: '2025-08-25', AS_PP: 0,    AS_AS: undefined,    AS_AF: undefined,    AS_AP: 0,    PE1_PS: undefined,    PE1_PF: undefined,    PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: undefined,    PT_PF: undefined,    PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: undefined,    PE2_PF: undefined,    PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: undefined,    ER_PF: undefined,    ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'TOPSIDE', ZONE: 'SOUTH',          MOD: 'Main Deck',           LEVEL: 'Main Deck',           NAME: 'Trunnion',               G_EREC: 'SD310', G_2PE: 'SD310', G_1PE: 'SD310', EREC: '',      PE2: '',      PE1: '',      PBLK: '',      BLK: 'ST311', L: '1.7',  B: '1.8',  H: '1.8',  YARD: 'HHI',   SC_WF: 16,     FB_WF: 24.3,    AS_WF: 0,       PE1_WF: 0,       PT_WF: 0,       PE2_WF: 0,       ER_WF: 0,      SC_PS: '2025-06-10', SC_PF: '2025-06-27', SC_PP: 0,     SC_AS: undefined,    SC_AF: undefined,    SC_AP: 0,   FB_PS: '2025-06-30', FB_PF: '2025-09-01', FB_PP: 0,     FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: undefined,    AS_PF: undefined,    AS_PP: 0,    AS_AS: undefined,    AS_AF: undefined,    AS_AP: 0,    PE1_PS: undefined,    PE1_PF: undefined,    PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: undefined,    PT_PF: undefined,    PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: undefined,    PE2_PF: undefined,    PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: undefined,    ER_PF: undefined,    ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'TOPSIDE', ZONE: 'SOUTH',          MOD: 'Main Deck',           LEVEL: 'Main Deck',           NAME: 'Trunnion',               G_EREC: 'SD310', G_2PE: 'SD310', G_1PE: 'SD310', EREC: '',      PE2: '',      PE1: '',      PBLK: '',      BLK: 'ST321', L: '1.7',  B: '1.8',  H: '1.8',  YARD: 'HHI',   SC_WF: 16,     FB_WF: 24.3,    AS_WF: 0,       PE1_WF: 0,       PT_WF: 0,       PE2_WF: 0,       ER_WF: 0,      SC_PS: '2025-06-10', SC_PF: '2025-06-27', SC_PP: 0,     SC_AS: undefined,    SC_AF: undefined,    SC_AP: 0,   FB_PS: '2025-06-30', FB_PF: '2025-09-01', FB_PP: 0,     FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: undefined,    AS_PF: undefined,    AS_PP: 0,    AS_AS: undefined,    AS_AF: undefined,    AS_AP: 0,    PE1_PS: undefined,    PE1_PF: undefined,    PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: undefined,    PT_PF: undefined,    PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: undefined,    PE2_PF: undefined,    PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: undefined,    ER_PF: undefined,    ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'TOPSIDE', ZONE: 'SOUTH',          MOD: 'Main Deck',           LEVEL: 'Main Deck',           NAME: 'Trunnion',               G_EREC: 'SD310', G_2PE: 'SD310', G_1PE: 'SD310', EREC: '',      PE2: '',      PE1: '',      PBLK: '',      BLK: 'ST331', L: '1.4',  B: '1.7',  H: '1.8',  YARD: 'HHI',   SC_WF: 16,     FB_WF: 24.3,    AS_WF: 0,       PE1_WF: 0,       PT_WF: 0,       PE2_WF: 0,       ER_WF: 0,      SC_PS: '2025-06-10', SC_PF: '2025-06-27', SC_PP: 0,     SC_AS: undefined,    SC_AF: undefined,    SC_AP: 0,   FB_PS: '2025-06-30', FB_PF: '2025-09-01', FB_PP: 0,     FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: undefined,    AS_PF: undefined,    AS_PP: 0,    AS_AS: undefined,    AS_AF: undefined,    AS_AP: 0,    PE1_PS: undefined,    PE1_PF: undefined,    PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: undefined,    PT_PF: undefined,    PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: undefined,    PE2_PF: undefined,    PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: undefined,    ER_PF: undefined,    ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'TOPSIDE', ZONE: 'SOUTH',          MOD: 'Main Deck',           LEVEL: 'Main Deck',           NAME: 'Trunnion',               G_EREC: 'SD310', G_2PE: 'SD310', G_1PE: 'SD310', EREC: '',      PE2: '',      PE1: '',      PBLK: '',      BLK: 'ST341', L: '1.4',  B: '1.7',  H: '1.8',  YARD: 'HHI',   SC_WF: 16,     FB_WF: 24.3,    AS_WF: 0,       PE1_WF: 0,       PT_WF: 0,       PE2_WF: 0,       ER_WF: 0,      SC_PS: '2025-06-10', SC_PF: '2025-06-27', SC_PP: 0,     SC_AS: undefined,    SC_AF: undefined,    SC_AP: 0,   FB_PS: '2025-06-30', FB_PF: '2025-09-01', FB_PP: 0,     FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: undefined,    AS_PF: undefined,    AS_PP: 0,    AS_AS: undefined,    AS_AF: undefined,    AS_AP: 0,    PE1_PS: undefined,    PE1_PF: undefined,    PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: undefined,    PT_PF: undefined,    PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: undefined,    PE2_PF: undefined,    PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: undefined,    ER_PF: undefined,    ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'TOPSIDE', ZONE: 'SOUTH',          MOD: 'Main Deck',           LEVEL: 'Main Deck',           NAME: 'Deck Block',             G_EREC: 'SD310', G_2PE: 'SD310', G_1PE: 'SD321', EREC: '',      PE2: '',      PE1: '',      PBLK: 'SD321', BLK: 'SD321', L: '10',   B: '2.1',  H: '4.7',  YARD: 'HHI',   SC_WF: 8,      FB_WF: 16,      AS_WF: 24,      PE1_WF: 27.34,   PT_WF: 30.43,   PE2_WF: 27.34,   ER_WF: 0,      SC_PS: '2025-05-19', SC_PF: '2025-06-05', SC_PP: 0,     SC_AS: undefined,    SC_AF: undefined,    SC_AP: 0,   FB_PS: '2025-06-09', FB_PF: '2025-07-01', FB_PP: 0,     FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: '2025-07-02', AS_PF: '2025-08-21', AS_PP: 0,    AS_AS: undefined,    AS_AF: undefined,    AS_AP: 0,    PE1_PS: '2025-08-22', PE1_PF: '2025-09-22', PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: '2025-09-23', PT_PF: '2025-10-22', PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: '2025-10-23', PE2_PF: '2025-11-24', PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: undefined,    ER_PF: undefined,    ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'TOPSIDE', ZONE: 'SOUTH',          MOD: 'Main Deck',           LEVEL: 'Main Deck',           NAME: 'Access P/F(Top)',        G_EREC: 'SD310', G_2PE: 'SD310', G_1PE: 'SV311', EREC: '',      PE2: '',      PE1: '',      PBLK: 'SV311', BLK: 'SV311', L: '3.5',  B: '13',   H: '1.5',  YARD: 'HHI',   SC_WF: 8,      FB_WF: 0,       AS_WF: 24,      PE1_WF: 0,       PT_WF: 81.35,   PE2_WF: 27.34,   ER_WF: 0,      SC_PS: '2025-06-27', SC_PF: '2025-07-16', SC_PP: 0,     SC_AS: undefined,    SC_AF: undefined,    SC_AP: 0,   FB_PS: undefined,    FB_PF: undefined,    FB_PP: 0,     FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: '2025-07-17', AS_PF: '2025-09-11', AS_PP: 0,    AS_AS: undefined,    AS_AF: undefined,    AS_AP: 0,    PE1_PS: undefined,    PE1_PF: undefined,    PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: '2025-10-02', PT_PF: '2025-10-31', PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: '2025-11-03', PE2_PF: '2025-11-21', PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: undefined,    ER_PF: undefined,    ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'TOPSIDE', ZONE: 'SOUTH',          MOD: 'Main Deck',           LEVEL: 'Main Deck',           NAME: 'Access P/F(Top)',        G_EREC: 'SD310', G_2PE: 'SD310', G_1PE: 'SV321', EREC: '',      PE2: '',      PE1: '',      PBLK: 'SV321', BLK: 'SV321', L: '2.2',  B: '14.7', H: '1.5',  YARD: 'HHI',   SC_WF: 8,      FB_WF: 0,       AS_WF: 24,      PE1_WF: 0,       PT_WF: 86.94,   PE2_WF: 27.34,   ER_WF: 0,      SC_PS: '2025-06-27', SC_PF: '2025-07-16', SC_PP: 0,     SC_AS: undefined,    SC_AF: undefined,    SC_AP: 0,   FB_PS: undefined,    FB_PF: undefined,    FB_PP: 0,     FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: '2025-07-17', AS_PF: '2025-09-11', AS_PP: 0,    AS_AS: undefined,    AS_AF: undefined,    AS_AP: 0,    PE1_PS: undefined,    PE1_PF: undefined,    PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: '2025-10-02', PT_PF: '2025-10-31', PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: '2025-11-03', PE2_PF: '2025-11-21', PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: undefined,    ER_PF: undefined,    ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'TOPSIDE', ZONE: 'SOUTH',          MOD: 'Main Deck',           LEVEL: 'Main Deck',           NAME: 'Access P/F(Top)',        G_EREC: 'SD310', G_2PE: 'SD310', G_1PE: 'SV331', EREC: '',      PE2: '',      PE1: '',      PBLK: 'SV331', BLK: 'SV331', L: '2.2',  B: '14',   H: '1.5',  YARD: 'HHI',   SC_WF: 8,      FB_WF: 0,       AS_WF: 24,      PE1_WF: 0,       PT_WF: 86.94,   PE2_WF: 27.34,   ER_WF: 0,      SC_PS: '2025-06-27', SC_PF: '2025-07-16', SC_PP: 0,     SC_AS: undefined,    SC_AF: undefined,    SC_AP: 0,   FB_PS: undefined,    FB_PF: undefined,    FB_PP: 0,     FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: '2025-07-17', AS_PF: '2025-09-11', AS_PP: 0,    AS_AS: undefined,    AS_AF: undefined,    AS_AP: 0,    PE1_PS: undefined,    PE1_PF: undefined,    PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: '2025-10-02', PT_PF: '2025-10-31', PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: '2025-11-03', PE2_PF: '2025-11-21', PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: undefined,    ER_PF: undefined,    ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'TOPSIDE', ZONE: 'SOUTH',          MOD: 'Main Deck',           LEVEL: 'Main Deck',           NAME: 'Access P/F(Top)',        G_EREC: 'SD310', G_2PE: 'SD310', G_1PE: 'SV341', EREC: '',      PE2: '',      PE1: '',      PBLK: 'SV341', BLK: 'SV341', L: '2.2',  B: '16.1', H: '1.5',  YARD: 'HHI',   SC_WF: 8,      FB_WF: 0,       AS_WF: 24,      PE1_WF: 0,       PT_WF: 86.94,   PE2_WF: 27.34,   ER_WF: 0,      SC_PS: '2025-06-27', SC_PF: '2025-07-16', SC_PP: 0,     SC_AS: undefined,    SC_AF: undefined,    SC_AP: 0,   FB_PS: undefined,    FB_PF: undefined,    FB_PP: 0,     FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: '2025-07-17', AS_PF: '2025-09-11', AS_PP: 0,    AS_AS: undefined,    AS_AF: undefined,    AS_AP: 0,    PE1_PS: undefined,    PE1_PF: undefined,    PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: '2025-10-02', PT_PF: '2025-10-31', PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: '2025-11-03', PE2_PF: '2025-11-21', PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: undefined,    ER_PF: undefined,    ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'TOPSIDE', ZONE: 'SOUTH',          MOD: 'Main Deck',           LEVEL: 'Main Deck',           NAME: 'Access P/F(Top)',        G_EREC: 'SD310', G_2PE: 'SD310', G_1PE: 'SV351', EREC: '',      PE2: '',      PE1: '',      PBLK: 'SV351', BLK: 'SV351', L: '3.5',  B: '13',   H: '1.5',  YARD: 'HHI',   SC_WF: 8,      FB_WF: 0,       AS_WF: 24,      PE1_WF: 0,       PT_WF: 80.73,   PE2_WF: 27.34,   ER_WF: 0,      SC_PS: '2025-06-27', SC_PF: '2025-07-16', SC_PP: 0,     SC_AS: undefined,    SC_AF: undefined,    SC_AP: 0,   FB_PS: undefined,    FB_PF: undefined,    FB_PP: 0,     FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: '2025-07-17', AS_PF: '2025-09-11', AS_PP: 0,    AS_AS: undefined,    AS_AF: undefined,    AS_AP: 0,    PE1_PS: undefined,    PE1_PF: undefined,    PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: '2025-10-02', PT_PF: '2025-10-31', PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: '2025-11-03', PE2_PF: '2025-11-21', PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: undefined,    ER_PF: undefined,    ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'TOPSIDE', ZONE: 'SOUTH',          MOD: 'Main Deck',           LEVEL: 'Main Deck',           NAME: 'Access P/F(Top)',        G_EREC: 'SD310', G_2PE: 'SD310', G_1PE: 'SV361', EREC: '',      PE2: '',      PE1: '',      PBLK: 'SV361', BLK: 'SV361', L: '2.2',  B: '16.1', H: '1.5',  YARD: 'HHI',   SC_WF: 8,      FB_WF: 0,       AS_WF: 24,      PE1_WF: 0,       PT_WF: 86.94,   PE2_WF: 27.34,   ER_WF: 0,      SC_PS: '2025-06-27', SC_PF: '2025-07-16', SC_PP: 0,     SC_AS: undefined,    SC_AF: undefined,    SC_AP: 0,   FB_PS: undefined,    FB_PF: undefined,    FB_PP: 0,     FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: '2025-07-17', AS_PF: '2025-09-11', AS_PP: 0,    AS_AS: undefined,    AS_AF: undefined,    AS_AP: 0,    PE1_PS: undefined,    PE1_PF: undefined,    PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: '2025-10-02', PT_PF: '2025-10-31', PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: '2025-11-03', PE2_PF: '2025-11-21', PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: undefined,    ER_PF: undefined,    ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'TOPSIDE', ZONE: 'SOUTH',          MOD: 'Main Deck',           LEVEL: 'Main Deck',           NAME: 'Pipe Rack',              G_EREC: 'SD310', G_2PE: 'SD310', G_1PE: 'SP311', EREC: '',      PE2: '',      PE1: '',      PBLK: 'SP311', BLK: 'SP311', L: '7.1',  B: '20.1', H: '4.3',  YARD: 'HHI',   SC_WF: 24.3,   FB_WF: 0,       AS_WF: 54.67,   PE1_WF: 0,       PT_WF: 590.8,   PE2_WF: 91.12,   ER_WF: 0,      SC_PS: '2025-06-05', SC_PF: '2025-06-25', SC_PP: 0,     SC_AS: undefined,    SC_AF: undefined,    SC_AP: 0,   FB_PS: undefined,    FB_PF: undefined,    FB_PP: 0,     FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: '2025-06-26', AS_PF: '2025-08-21', AS_PP: 0,    AS_AS: undefined,    AS_AF: undefined,    AS_AP: 0,    PE1_PS: undefined,    PE1_PF: undefined,    PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: '2025-09-11', PT_PF: '2025-10-10', PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: '2025-10-13', PE2_PF: '2025-11-21', PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: undefined,    ER_PF: undefined,    ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'TOPSIDE', ZONE: 'SOUTH',          MOD: 'Main Deck',           LEVEL: 'Main Deck',           NAME: 'Pipe Rack',              G_EREC: 'SD310', G_2PE: 'SD310', G_1PE: 'SP321', EREC: '',      PE2: '',      PE1: '',      PBLK: 'SP321', BLK: 'SP321', L: '14.7', B: '11.5', H: '3.4',  YARD: 'HHI',   SC_WF: 36.45,  FB_WF: 0,       AS_WF: 109.34,  PE1_WF: 0,       PT_WF: 685.56,  PE2_WF: 182.23,  ER_WF: 0,      SC_PS: '2025-06-05', SC_PF: '2025-06-25', SC_PP: 0,     SC_AS: undefined,    SC_AF: undefined,    SC_AP: 0,   FB_PS: undefined,    FB_PF: undefined,    FB_PP: 0,     FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: '2025-06-26', AS_PF: '2025-08-21', AS_PP: 0,    AS_AS: undefined,    AS_AF: undefined,    AS_AP: 0,    PE1_PS: undefined,    PE1_PF: undefined,    PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: '2025-09-11', PT_PF: '2025-10-10', PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: '2025-10-13', PE2_PF: '2025-11-21', PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: undefined,    ER_PF: undefined,    ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'TOPSIDE', ZONE: 'SOUTH',          MOD: 'Main Deck',           LEVEL: 'Main Deck',           NAME: 'Pipe Rack',              G_EREC: 'SD310', G_2PE: 'SD310', G_1PE: 'SP331', EREC: '',      PE2: '',      PE1: '',      PBLK: 'SP331', BLK: 'SP331', L: '14.4', B: '6.1',  H: '3.4',  YARD: 'HHI',   SC_WF: 24.3,   FB_WF: 0,       AS_WF: 54.67,   PE1_WF: 0,       PT_WF: 357.68,  PE2_WF: 91.12,   ER_WF: 0,      SC_PS: '2025-06-05', SC_PF: '2025-06-25', SC_PP: 0,     SC_AS: undefined,    SC_AF: undefined,    SC_AP: 0,   FB_PS: undefined,    FB_PF: undefined,    FB_PP: 0,     FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: '2025-06-26', AS_PF: '2025-08-21', AS_PP: 0,    AS_AS: undefined,    AS_AF: undefined,    AS_AP: 0,    PE1_PS: undefined,    PE1_PF: undefined,    PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: '2025-09-11', PT_PF: '2025-10-10', PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: '2025-10-13', PE2_PF: '2025-11-21', PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: undefined,    ER_PF: undefined,    ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'TOPSIDE', ZONE: 'SOUTH',          MOD: 'Main Deck',           LEVEL: 'Main Deck',           NAME: 'Pipe Rack',              G_EREC: 'SD310', G_2PE: 'SD310', G_1PE: 'SP341', EREC: '',      PE2: '',      PE1: '',      PBLK: 'SP341', BLK: 'SP341', L: '14.5', B: '10.3', H: '4.3',  YARD: 'HHI',   SC_WF: 36.45,  FB_WF: 0,       AS_WF: 109.34,  PE1_WF: 0,       PT_WF: 685.56,  PE2_WF: 182.23,  ER_WF: 0,      SC_PS: '2025-06-05', SC_PF: '2025-06-25', SC_PP: 0,     SC_AS: undefined,    SC_AF: undefined,    SC_AP: 0,   FB_PS: undefined,    FB_PF: undefined,    FB_PP: 0,     FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: '2025-06-26', AS_PF: '2025-08-21', AS_PP: 0,    AS_AS: undefined,    AS_AF: undefined,    AS_AP: 0,    PE1_PS: undefined,    PE1_PF: undefined,    PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: '2025-09-11', PT_PF: '2025-10-10', PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: '2025-10-13', PE2_PF: '2025-11-21', PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: undefined,    ER_PF: undefined,    ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'TOPSIDE', ZONE: 'SOUTH',          MOD: 'Main Deck',           LEVEL: 'Main Deck',           NAME: 'Pipe Rack',              G_EREC: 'SD310', G_2PE: 'SD310', G_1PE: 'SP351', EREC: '',      PE2: '',      PE1: '',      PBLK: 'SP351', BLK: 'SP351', L: '14.4', B: '10',   H: '5.3',  YARD: 'HHI',   SC_WF: 24.3,   FB_WF: 0,       AS_WF: 54.67,   PE1_WF: 0,       PT_WF: 357.68,  PE2_WF: 91.12,   ER_WF: 0,      SC_PS: '2025-06-05', SC_PF: '2025-06-25', SC_PP: 0,     SC_AS: undefined,    SC_AF: undefined,    SC_AP: 0,   FB_PS: undefined,    FB_PF: undefined,    FB_PP: 0,     FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: '2025-06-26', AS_PF: '2025-08-21', AS_PP: 0,    AS_AS: undefined,    AS_AF: undefined,    AS_AP: 0,    PE1_PS: undefined,    PE1_PF: undefined,    PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: '2025-09-11', PT_PF: '2025-10-10', PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: '2025-10-13', PE2_PF: '2025-11-21', PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: undefined,    ER_PF: undefined,    ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'TOPSIDE', ZONE: 'SOUTH',          MOD: 'Main Deck',           LEVEL: 'Main Deck',           NAME: 'Deck Block',             G_EREC: 'SD330', G_2PE: 'SD330', G_1PE: 'SD330', EREC: 'SD330', PE2: 'SD330', PE1: 'SD330', PBLK: 'SD330', BLK: 'SD331', L: '17.4', B: '22.7', H: '1.5',  YARD: 'HHI',   SC_WF: 284.29, FB_WF: 568.57,  AS_WF: 852.86,  PE1_WF: 3334.9,  PT_WF: 3117.55, PE2_WF: 3334.9,  ER_WF: 5822.9, SC_PS: '2025-05-15', SC_PF: '2025-06-03', SC_PP: 0,     SC_AS: undefined,    SC_AF: undefined,    SC_AP: 0,   FB_PS: '2025-06-04', FB_PF: '2025-07-02', FB_PP: 0,     FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: '2025-07-03', AS_PF: '2025-09-01', AS_PP: 0,    AS_AS: undefined,    AS_AF: undefined,    AS_AP: 0,    PE1_PS: '2025-09-02', PE1_PF: '2025-10-01', PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: '2025-10-02', PT_PF: '2025-10-31', PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: '2025-11-03', PE2_PF: '2025-12-09', PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: '2025-12-10', ER_PF: '2026-03-25', ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'TOPSIDE', ZONE: 'SOUTH',          MOD: 'Main Deck',           LEVEL: 'Main Deck',           NAME: 'Deck Block',             G_EREC: 'SD330', G_2PE: 'SD330', G_1PE: 'SD330', EREC: '',      PE2: '',      PE1: '',      PBLK: '',      BLK: 'SD332', L: '19.5', B: '12.7', H: '1.5',  YARD: 'HHI',   SC_WF: 178.59, FB_WF: 357.18,  AS_WF: 535.77,  PE1_WF: 0,       PT_WF: 0,       PE2_WF: 0,       ER_WF: 0,      SC_PS: '2025-05-15', SC_PF: '2025-06-03', SC_PP: 0,     SC_AS: undefined,    SC_AF: undefined,    SC_AP: 0,   FB_PS: '2025-06-04', FB_PF: '2025-07-02', FB_PP: 0,     FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: '2025-07-03', AS_PF: '2025-09-01', AS_PP: 0,    AS_AS: undefined,    AS_AF: undefined,    AS_AP: 0,    PE1_PS: undefined,    PE1_PF: undefined,    PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: undefined,    PT_PF: undefined,    PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: undefined,    PE2_PF: undefined,    PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: undefined,    ER_PF: undefined,    ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'TOPSIDE', ZONE: 'SOUTH',          MOD: 'Main Deck',           LEVEL: 'Main Deck',           NAME: 'Deck Block',             G_EREC: 'SD330', G_2PE: 'SD330', G_1PE: 'SD330', EREC: '',      PE2: '',      PE1: '',      PBLK: '',      BLK: 'SD333', L: '19.6', B: '11.8', H: '1.5',  YARD: 'HHI',   SC_WF: 167.66, FB_WF: 335.31,  AS_WF: 502.97,  PE1_WF: 0,       PT_WF: 0,       PE2_WF: 0,       ER_WF: 0,      SC_PS: '2025-05-19', SC_PF: '2025-06-05', SC_PP: 0,     SC_AS: undefined,    SC_AF: undefined,    SC_AP: 0,   FB_PS: '2025-06-09', FB_PF: '2025-07-04', FB_PP: 0,     FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: '2025-07-07', AS_PF: '2025-09-01', AS_PP: 0,    AS_AS: undefined,    AS_AF: undefined,    AS_AP: 0,    PE1_PS: undefined,    PE1_PF: undefined,    PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: undefined,    PT_PF: undefined,    PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: undefined,    PE2_PF: undefined,    PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: undefined,    ER_PF: undefined,    ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'TOPSIDE', ZONE: 'SOUTH',          MOD: 'Main Deck',           LEVEL: 'Main Deck',           NAME: 'Trunnion',               G_EREC: 'SD330', G_2PE: 'SD330', G_1PE: 'SD330', EREC: '',      PE2: '',      PE1: '',      PBLK: '',      BLK: 'ST351', L: '1.6',  B: '1.8',  H: '1.8',  YARD: 'HHI',   SC_WF: 16,     FB_WF: 24.3,    AS_WF: 0,       PE1_WF: 0,       PT_WF: 0,       PE2_WF: 0,       ER_WF: 0,      SC_PS: '2025-06-17', SC_PF: '2025-07-04', SC_PP: 0,     SC_AS: undefined,    SC_AF: undefined,    SC_AP: 0,   FB_PS: '2025-07-07', FB_PF: '2025-09-08', FB_PP: 0,     FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: undefined,    AS_PF: undefined,    AS_PP: 0,    AS_AS: undefined,    AS_AF: undefined,    AS_AP: 0,    PE1_PS: undefined,    PE1_PF: undefined,    PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: undefined,    PT_PF: undefined,    PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: undefined,    PE2_PF: undefined,    PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: undefined,    ER_PF: undefined,    ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'TOPSIDE', ZONE: 'SOUTH',          MOD: 'Main Deck',           LEVEL: 'Main Deck',           NAME: 'Trunnion',               G_EREC: 'SD330', G_2PE: 'SD330', G_1PE: 'SD330', EREC: '',      PE2: '',      PE1: '',      PBLK: '',      BLK: 'ST361', L: '1.6',  B: '1.8',  H: '1.8',  YARD: 'HHI',   SC_WF: 16,     FB_WF: 24.3,    AS_WF: 0,       PE1_WF: 0,       PT_WF: 0,       PE2_WF: 0,       ER_WF: 0,      SC_PS: '2025-06-17', SC_PF: '2025-07-04', SC_PP: 0,     SC_AS: undefined,    SC_AF: undefined,    SC_AP: 0,   FB_PS: '2025-07-07', FB_PF: '2025-09-08', FB_PP: 0,     FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: undefined,    AS_PF: undefined,    AS_PP: 0,    AS_AS: undefined,    AS_AF: undefined,    AS_AP: 0,    PE1_PS: undefined,    PE1_PF: undefined,    PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: undefined,    PT_PF: undefined,    PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: undefined,    PE2_PF: undefined,    PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: undefined,    ER_PF: undefined,    ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'TOPSIDE', ZONE: 'SOUTH',          MOD: 'Main Deck',           LEVEL: 'Main Deck',           NAME: 'Trunnion',               G_EREC: 'SD330', G_2PE: 'SD330', G_1PE: 'SD330', EREC: '',      PE2: '',      PE1: '',      PBLK: '',      BLK: 'ST371', L: '1.6',  B: '1.7',  H: '1.8',  YARD: 'HHI',   SC_WF: 16,     FB_WF: 24.3,    AS_WF: 0,       PE1_WF: 0,       PT_WF: 0,       PE2_WF: 0,       ER_WF: 0,      SC_PS: '2025-06-17', SC_PF: '2025-07-04', SC_PP: 0,     SC_AS: undefined,    SC_AF: undefined,    SC_AP: 0,   FB_PS: '2025-07-07', FB_PF: '2025-09-08', FB_PP: 0,     FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: undefined,    AS_PF: undefined,    AS_PP: 0,    AS_AS: undefined,    AS_AF: undefined,    AS_AP: 0,    PE1_PS: undefined,    PE1_PF: undefined,    PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: undefined,    PT_PF: undefined,    PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: undefined,    PE2_PF: undefined,    PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: undefined,    ER_PF: undefined,    ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'TOPSIDE', ZONE: 'SOUTH',          MOD: 'Main Deck',           LEVEL: 'Main Deck',           NAME: 'Trunnion',               G_EREC: 'SD330', G_2PE: 'SD330', G_1PE: 'SD330', EREC: '',      PE2: '',      PE1: '',      PBLK: '',      BLK: 'ST381', L: '1.6',  B: '1.7',  H: '1.8',  YARD: 'HHI',   SC_WF: 16,     FB_WF: 24.3,    AS_WF: 0,       PE1_WF: 0,       PT_WF: 0,       PE2_WF: 0,       ER_WF: 0,      SC_PS: '2025-06-17', SC_PF: '2025-07-04', SC_PP: 0,     SC_AS: undefined,    SC_AF: undefined,    SC_AP: 0,   FB_PS: '2025-07-07', FB_PF: '2025-09-08', FB_PP: 0,     FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: undefined,    AS_PF: undefined,    AS_PP: 0,    AS_AS: undefined,    AS_AF: undefined,    AS_AP: 0,    PE1_PS: undefined,    PE1_PF: undefined,    PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: undefined,    PT_PF: undefined,    PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: undefined,    PE2_PF: undefined,    PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: undefined,    ER_PF: undefined,    ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'TOPSIDE', ZONE: 'SOUTH',          MOD: 'Main Deck',           LEVEL: 'Main Deck',           NAME: 'Pipe Rack',              G_EREC: 'SD330', G_2PE: 'SD330', G_1PE: 'SP361', EREC: '',      PE2: '',      PE1: '',      PBLK: 'SP361', BLK: 'SP361', L: '13.8', B: '11.5', H: '4.5',  YARD: 'HHI',   SC_WF: 36.45,  FB_WF: 0,       AS_WF: 109.34,  PE1_WF: 0,       PT_WF: 652.23,  PE2_WF: 182.23,  ER_WF: 0,      SC_PS: '2025-06-20', SC_PF: '2025-07-09', SC_PP: 0,     SC_AS: undefined,    SC_AF: undefined,    SC_AP: 0,   FB_PS: undefined,    FB_PF: undefined,    FB_PP: 0,     FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: '2025-07-10', AS_PF: '2025-09-04', AS_PP: 0,    AS_AS: undefined,    AS_AF: undefined,    AS_AP: 0,    PE1_PS: undefined,    PE1_PF: undefined,    PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: '2025-09-25', PT_PF: '2025-10-24', PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: '2025-10-27', PE2_PF: '2025-12-05', PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: undefined,    ER_PF: undefined,    ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'TOPSIDE', ZONE: 'SOUTH',          MOD: 'Main Deck',           LEVEL: 'Main Deck',           NAME: 'Pipe Rack',              G_EREC: 'SD330', G_2PE: 'SD330', G_1PE: 'SP371', EREC: '',      PE2: '',      PE1: '',      PBLK: 'SP371', BLK: 'SP371', L: '13.9', B: '9.9',  H: '5.3',  YARD: 'HHI',   SC_WF: 24.3,   FB_WF: 0,       AS_WF: 54.67,   PE1_WF: 0,       PT_WF: 340.29,  PE2_WF: 91.12,   ER_WF: 0,      SC_PS: '2025-06-20', SC_PF: '2025-07-09', SC_PP: 0,     SC_AS: undefined,    SC_AF: undefined,    SC_AP: 0,   FB_PS: undefined,    FB_PF: undefined,    FB_PP: 0,     FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: '2025-07-10', AS_PF: '2025-09-04', AS_PP: 0,    AS_AS: undefined,    AS_AF: undefined,    AS_AP: 0,    PE1_PS: undefined,    PE1_PF: undefined,    PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: '2025-09-25', PT_PF: '2025-10-24', PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: '2025-10-27', PE2_PF: '2025-12-05', PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: undefined,    ER_PF: undefined,    ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'TOPSIDE', ZONE: 'SOUTH',          MOD: 'Main Deck',           LEVEL: 'Main Deck',           NAME: 'Pipe Rack',              G_EREC: 'SD330', G_2PE: 'SD330', G_1PE: 'SP381', EREC: '',      PE2: '',      PE1: '',      PBLK: 'SP381', BLK: 'SP381', L: '14.4', B: '20.1', H: '5.3',  YARD: 'HHI',   SC_WF: 45.56,  FB_WF: 0,       AS_WF: 136.68,  PE1_WF: 0,       PT_WF: 1189.91, PE2_WF: 227.79,  ER_WF: 0,      SC_PS: '2025-06-20', SC_PF: '2025-07-09', SC_PP: 0,     SC_AS: undefined,    SC_AF: undefined,    SC_AP: 0,   FB_PS: undefined,    FB_PF: undefined,    FB_PP: 0,     FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: '2025-07-10', AS_PF: '2025-09-04', AS_PP: 0,    AS_AS: undefined,    AS_AF: undefined,    AS_AP: 0,    PE1_PS: undefined,    PE1_PF: undefined,    PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: '2025-09-25', PT_PF: '2025-10-24', PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: '2025-10-27', PE2_PF: '2025-12-05', PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: undefined,    ER_PF: undefined,    ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'TOPSIDE', ZONE: 'SOUTH',          MOD: 'Main Deck',           LEVEL: 'Main Deck',           NAME: 'Pipe Rack',              G_EREC: 'SD330', G_2PE: 'SD330', G_1PE: 'SP391', EREC: '',      PE2: '',      PE1: '',      PBLK: 'SP391', BLK: 'SP391', L: '3.5',  B: '11.8', H: '3.4',  YARD: 'HHI',   SC_WF: 16,     FB_WF: 0,       AS_WF: 27.34,   PE1_WF: 0,       PT_WF: 169.53,  PE2_WF: 45.56,   ER_WF: 0,      SC_PS: '2025-06-20', SC_PF: '2025-07-09', SC_PP: 0,     SC_AS: undefined,    SC_AF: undefined,    SC_AP: 0,   FB_PS: undefined,    FB_PF: undefined,    FB_PP: 0,     FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: '2025-07-10', AS_PF: '2025-09-04', AS_PP: 0,    AS_AS: undefined,    AS_AF: undefined,    AS_AP: 0,    PE1_PS: undefined,    PE1_PF: undefined,    PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: '2025-09-25', PT_PF: '2025-10-24', PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: '2025-10-27', PE2_PF: '2025-12-05', PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: undefined,    ER_PF: undefined,    ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'TOPSIDE', ZONE: 'SOUTH',          MOD: 'Main Deck',           LEVEL: 'Main Deck',           NAME: 'Stair Tower',            G_EREC: 'SX110', G_2PE: 'SX110', G_1PE: 'SX111', EREC: 'SX110', PE2: '',      PE1: '',      PBLK: 'SX111', BLK: 'SX111', L: '9.1',  B: '4.6',  H: '20.1', YARD: 'HHI',   SC_WF: 36.45,  FB_WF: 72.89,   AS_WF: 109.34,  PE1_WF: 0,       PT_WF: 740.58,  PE2_WF: 72.89,   ER_WF: 5822.9, SC_PS: '2025-07-07', SC_PF: '2025-07-24', SC_PP: 0,     SC_AS: undefined,    SC_AF: undefined,    SC_AP: 0,   FB_PS: '2025-07-25', FB_PF: '2025-08-29', FB_PP: 0,     FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: '2025-09-01', AS_PF: '2025-10-14', AS_PP: 0,    AS_AS: undefined,    AS_AF: undefined,    AS_AP: 0,    PE1_PS: undefined,    PE1_PF: undefined,    PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: '2025-11-07', PT_PF: '2025-12-01', PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: '2025-12-02', PE2_PF: '2025-12-22', PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: '2025-12-26', ER_PF: '2026-03-25', ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'TOPSIDE', ZONE: 'SOUTH',          MOD: 'Generator Building',  LEVEL: 'Generator Building',  NAME: 'Deck Block',             G_EREC: 'GD110', G_2PE: 'GD110', G_1PE: 'GD110', EREC: 'GD110', PE2: 'GD110', PE1: 'GD110', PBLK: 'GD110', BLK: 'GD111', L: '17',   B: '12.8', H: '0.8',  YARD: 'HHI',   SC_WF: 74.72,  FB_WF: 149.43,  AS_WF: 224.15,  PE1_WF: 1640.11, PT_WF: 3260.99, PE2_WF: 656.05,  ER_WF: 5822.9, SC_PS: '2025-06-19', SC_PF: '2025-07-08', SC_PP: 0,     SC_AS: undefined,    SC_AF: undefined,    SC_AP: 0,   FB_PS: '2025-07-09', FB_PF: '2025-08-18', FB_PP: 0,     FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: '2025-08-19', AS_PF: '2025-10-01', AS_PP: 0,    AS_AS: undefined,    AS_AF: undefined,    AS_AP: 0,    PE1_PS: '2025-10-02', PE1_PF: '2025-11-07', PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: '2025-11-10', PT_PF: '2025-12-02', PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: '2025-12-03', PE2_PF: '2026-01-13', PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: '2026-01-14', ER_PF: '2026-03-25', ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'TOPSIDE', ZONE: 'SOUTH',          MOD: 'Generator Building',  LEVEL: 'Generator Building',  NAME: 'Deck Block',             G_EREC: 'GD110', G_2PE: 'GD110', G_1PE: 'GD110', EREC: '',      PE2: '',      PE1: '',      PBLK: '',      BLK: 'GD112', L: '17.2', B: '14',   H: '0.8',  YARD: 'HHI',   SC_WF: 74.72,  FB_WF: 149.43,  AS_WF: 224.15,  PE1_WF: 0,       PT_WF: 0,       PE2_WF: 0,       ER_WF: 0,      SC_PS: '2025-06-19', SC_PF: '2025-07-08', SC_PP: 0,     SC_AS: undefined,    SC_AF: undefined,    SC_AP: 0,   FB_PS: '2025-07-09', FB_PF: '2025-08-18', FB_PP: 0,     FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: '2025-08-19', AS_PF: '2025-10-01', AS_PP: 0,    AS_AS: undefined,    AS_AF: undefined,    AS_AP: 0,    PE1_PS: undefined,    PE1_PF: undefined,    PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: undefined,    PT_PF: undefined,    PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: undefined,    PE2_PF: undefined,    PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: undefined,    ER_PF: undefined,    ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'TOPSIDE', ZONE: 'SOUTH',          MOD: 'Generator Building',  LEVEL: 'Generator Building',  NAME: 'Deck Block',             G_EREC: 'GD110', G_2PE: 'GD110', G_1PE: 'GD110', EREC: '',      PE2: '',      PE1: '',      PBLK: '',      BLK: 'GD113', L: '17',   B: '12.8', H: '3.2',  YARD: 'HHI',   SC_WF: 58.32,  FB_WF: 116.63,  AS_WF: 174.95,  PE1_WF: 0,       PT_WF: 0,       PE2_WF: 0,       ER_WF: 0,      SC_PS: '2025-06-23', SC_PF: '2025-07-10', SC_PP: 0,     SC_AS: undefined,    SC_AF: undefined,    SC_AP: 0,   FB_PS: '2025-07-11', FB_PF: '2025-08-20', FB_PP: 0,     FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: '2025-08-21', AS_PF: '2025-10-01', AS_PP: 0,    AS_AS: undefined,    AS_AF: undefined,    AS_AP: 0,    PE1_PS: undefined,    PE1_PF: undefined,    PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: undefined,    PT_PF: undefined,    PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: undefined,    PE2_PF: undefined,    PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: undefined,    ER_PF: undefined,    ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'TOPSIDE', ZONE: 'SOUTH',          MOD: 'Generator Building',  LEVEL: 'Generator Building',  NAME: 'Deck Block',             G_EREC: 'GD110', G_2PE: 'GD110', G_1PE: 'GD110', EREC: '',      PE2: '',      PE1: '',      PBLK: '',      BLK: 'GD114', L: '17.2', B: '10.6', H: '0.8',  YARD: 'HHI',   SC_WF: 58.32,  FB_WF: 116.63,  AS_WF: 174.95,  PE1_WF: 0,       PT_WF: 0,       PE2_WF: 0,       ER_WF: 0,      SC_PS: '2025-06-23', SC_PF: '2025-07-10', SC_PP: 0,     SC_AS: undefined,    SC_AF: undefined,    SC_AP: 0,   FB_PS: '2025-07-11', FB_PF: '2025-08-20', FB_PP: 0,     FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: '2025-08-21', AS_PF: '2025-10-01', AS_PP: 0,    AS_AS: undefined,    AS_AF: undefined,    AS_AP: 0,    PE1_PS: undefined,    PE1_PF: undefined,    PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: undefined,    PT_PF: undefined,    PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: undefined,    PE2_PF: undefined,    PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: undefined,    ER_PF: undefined,    ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'TOPSIDE', ZONE: 'SOUTH',          MOD: 'Generator Building',  LEVEL: 'Generator Building',  NAME: 'Column & Brace',         G_EREC: 'GD110', G_2PE: 'GD110', G_1PE: 'GD110', EREC: '',      PE2: '',      PE1: '',      PBLK: '',      BLK: 'GC111', L: '30.9', B: '20.5', H: '9',    YARD: 'HHI',   SC_WF: 66.52,  FB_WF: 133.03,  AS_WF: 199.55,  PE1_WF: 0,       PT_WF: 0,       PE2_WF: 0,       ER_WF: 0,      SC_PS: '2025-06-24', SC_PF: '2025-07-09', SC_PP: 0,     SC_AS: undefined,    SC_AF: undefined,    SC_AP: 0,   FB_PS: '2025-07-10', FB_PF: '2025-07-24', FB_PP: 0,     FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: '2025-07-25', AS_PF: '2025-09-01', AS_PP: 0,    AS_AS: undefined,    AS_AF: undefined,    AS_AP: 0,    PE1_PS: undefined,    PE1_PF: undefined,    PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: undefined,    PT_PF: undefined,    PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: undefined,    PE2_PF: undefined,    PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: undefined,    ER_PF: undefined,    ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'TOPSIDE', ZONE: 'SOUTH',          MOD: 'Generator Building',  LEVEL: 'Generator Building',  NAME: 'Access P/F(Top)',        G_EREC: 'GD110', G_2PE: 'GD110', G_1PE: 'GV111', EREC: '',      PE2: '',      PE1: '',      PBLK: 'GV111', BLK: 'GV111', L: '2.8',  B: '1.3',  H: '1.5',  YARD: 'HHI',   SC_WF: 8,      FB_WF: 0,       AS_WF: 24,      PE1_WF: 0,       PT_WF: 16,      PE2_WF: 16,      ER_WF: 0,      SC_PS: '2025-08-25', SC_PF: '2025-09-11', SC_PP: 0,     SC_AS: undefined,    SC_AF: undefined,    SC_AP: 0,   FB_PS: undefined,    FB_PF: undefined,    FB_PP: 0,     FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: '2025-09-12', AS_PF: '2025-11-03', AS_PP: 0,    AS_AS: undefined,    AS_AF: undefined,    AS_AP: 0,    PE1_PS: undefined,    PE1_PF: undefined,    PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: '2025-11-24', PT_PF: '2025-12-16', PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: '2025-12-17', PE2_PF: '2026-01-09', PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: undefined,    ER_PF: undefined,    ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'TOPSIDE', ZONE: 'SOUTH',          MOD: 'Generator Building',  LEVEL: 'Generator Building',  NAME: 'Access P/F(Top)',        G_EREC: 'GD110', G_2PE: 'GD110', G_1PE: 'GV121', EREC: '',      PE2: '',      PE1: '',      PBLK: 'GV121', BLK: 'GV121', L: '3',    B: '3.2',  H: '1.3',  YARD: 'HHI',   SC_WF: 8,      FB_WF: 0,       AS_WF: 24,      PE1_WF: 0,       PT_WF: 29.81,   PE2_WF: 16,      ER_WF: 0,      SC_PS: '2025-08-25', SC_PF: '2025-09-11', SC_PP: 0,     SC_AS: undefined,    SC_AF: undefined,    SC_AP: 0,   FB_PS: undefined,    FB_PF: undefined,    FB_PP: 0,     FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: '2025-09-12', AS_PF: '2025-11-03', AS_PP: 0,    AS_AS: undefined,    AS_AF: undefined,    AS_AP: 0,    PE1_PS: undefined,    PE1_PF: undefined,    PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: '2025-11-24', PT_PF: '2025-12-16', PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: '2025-12-17', PE2_PF: '2026-01-09', PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: undefined,    ER_PF: undefined,    ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'TOPSIDE', ZONE: 'SOUTH',          MOD: 'Generator Building',  LEVEL: 'Generator Building',  NAME: 'Access P/F(Top)',        G_EREC: 'GD110', G_2PE: 'GD110', G_1PE: 'GV131', EREC: '',      PE2: '',      PE1: '',      PBLK: 'GV131', BLK: 'GV131', L: '3.2',  B: '4.1',  H: '2.5',  YARD: 'HHI',   SC_WF: 8,      FB_WF: 0,       AS_WF: 24,      PE1_WF: 0,       PT_WF: 26.83,   PE2_WF: 16,      ER_WF: 0,      SC_PS: '2025-08-25', SC_PF: '2025-09-11', SC_PP: 0,     SC_AS: undefined,    SC_AF: undefined,    SC_AP: 0,   FB_PS: undefined,    FB_PF: undefined,    FB_PP: 0,     FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: '2025-09-12', AS_PF: '2025-11-03', AS_PP: 0,    AS_AS: undefined,    AS_AF: undefined,    AS_AP: 0,    PE1_PS: undefined,    PE1_PF: undefined,    PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: '2025-11-24', PT_PF: '2025-12-16', PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: '2025-12-17', PE2_PF: '2026-01-09', PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: undefined,    ER_PF: undefined,    ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'TOPSIDE', ZONE: 'SOUTH',          MOD: 'Generator Building',  LEVEL: 'Generator Building',  NAME: 'Access P/F(Top)',        G_EREC: 'GD110', G_2PE: 'GD110', G_1PE: 'GV141', EREC: '',      PE2: '',      PE1: '',      PBLK: 'GV141', BLK: 'GV141', L: '3.9',  B: '1.9',  H: '2.4',  YARD: 'HHI',   SC_WF: 8,      FB_WF: 0,       AS_WF: 24,      PE1_WF: 0,       PT_WF: 29.06,   PE2_WF: 16,      ER_WF: 0,      SC_PS: '2025-08-25', SC_PF: '2025-09-11', SC_PP: 0,     SC_AS: undefined,    SC_AF: undefined,    SC_AP: 0,   FB_PS: undefined,    FB_PF: undefined,    FB_PP: 0,     FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: '2025-09-12', AS_PF: '2025-11-03', AS_PP: 0,    AS_AS: undefined,    AS_AF: undefined,    AS_AP: 0,    PE1_PS: undefined,    PE1_PF: undefined,    PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: '2025-11-24', PT_PF: '2025-12-16', PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: '2025-12-17', PE2_PF: '2026-01-09', PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: undefined,    ER_PF: undefined,    ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'TOPSIDE', ZONE: 'SOUTH',          MOD: 'Generator Building',  LEVEL: 'Generator Building',  NAME: 'Access P/F(Top)',        G_EREC: 'GD110', G_2PE: 'GD110', G_1PE: 'GV151', EREC: '',      PE2: '',      PE1: '',      PBLK: 'GV151', BLK: 'GV151', L: '4.8',  B: '2.2',  H: '2.5',  YARD: 'HHI',   SC_WF: 8,      FB_WF: 0,       AS_WF: 24,      PE1_WF: 0,       PT_WF: 43.72,   PE2_WF: 16,      ER_WF: 0,      SC_PS: '2025-08-25', SC_PF: '2025-09-11', SC_PP: 0,     SC_AS: undefined,    SC_AF: undefined,    SC_AP: 0,   FB_PS: undefined,    FB_PF: undefined,    FB_PP: 0,     FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: '2025-09-12', AS_PF: '2025-11-03', AS_PP: 0,    AS_AS: undefined,    AS_AF: undefined,    AS_AP: 0,    PE1_PS: undefined,    PE1_PF: undefined,    PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: '2025-11-24', PT_PF: '2025-12-16', PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: '2025-12-17', PE2_PF: '2026-01-09', PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: undefined,    ER_PF: undefined,    ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'TOPSIDE', ZONE: 'SOUTH',          MOD: 'Generator Building',  LEVEL: 'Generator Building',  NAME: 'Access P/F(Top)',        G_EREC: 'GD110', G_2PE: 'GD110', G_1PE: 'GV161', EREC: '',      PE2: '',      PE1: '',      PBLK: 'GV161', BLK: 'GV161', L: '3.9',  B: '1.9',  H: '2.4',  YARD: 'HHI',   SC_WF: 8,      FB_WF: 0,       AS_WF: 24,      PE1_WF: 0,       PT_WF: 29.06,   PE2_WF: 16,      ER_WF: 0,      SC_PS: '2025-08-25', SC_PF: '2025-09-11', SC_PP: 0,     SC_AS: undefined,    SC_AF: undefined,    SC_AP: 0,   FB_PS: undefined,    FB_PF: undefined,    FB_PP: 0,     FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: '2025-09-12', AS_PF: '2025-11-03', AS_PP: 0,    AS_AS: undefined,    AS_AF: undefined,    AS_AP: 0,    PE1_PS: undefined,    PE1_PF: undefined,    PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: '2025-11-24', PT_PF: '2025-12-16', PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: '2025-12-17', PE2_PF: '2026-01-09', PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: undefined,    ER_PF: undefined,    ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'TOPSIDE', ZONE: 'SOUTH',          MOD: 'Generator Building',  LEVEL: 'Generator Building',  NAME: 'Access P/F(Top)',        G_EREC: 'GD110', G_2PE: 'GD110', G_1PE: 'GV171', EREC: '',      PE2: '',      PE1: '',      PBLK: 'GV171', BLK: 'GV171', L: '4.8',  B: '2.2',  H: '2.5',  YARD: 'HHI',   SC_WF: 8,      FB_WF: 0,       AS_WF: 24,      PE1_WF: 0,       PT_WF: 43.72,   PE2_WF: 16,      ER_WF: 0,      SC_PS: '2025-08-25', SC_PF: '2025-09-11', SC_PP: 0,     SC_AS: undefined,    SC_AF: undefined,    SC_AP: 0,   FB_PS: undefined,    FB_PF: undefined,    FB_PP: 0,     FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: '2025-09-12', AS_PF: '2025-11-03', AS_PP: 0,    AS_AS: undefined,    AS_AF: undefined,    AS_AP: 0,    PE1_PS: undefined,    PE1_PF: undefined,    PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: '2025-11-24', PT_PF: '2025-12-16', PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: '2025-12-17', PE2_PF: '2026-01-09', PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: undefined,    ER_PF: undefined,    ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'TOPSIDE', ZONE: 'SOUTH',          MOD: 'Generator Building',  LEVEL: 'Generator Building',  NAME: 'Access P/F(Top)',        G_EREC: 'GD110', G_2PE: 'GD110', G_1PE: 'GV181', EREC: '',      PE2: '',      PE1: '',      PBLK: 'GV181', BLK: 'GV181', L: '3.9',  B: '1.9',  H: '2.4',  YARD: 'HHI',   SC_WF: 8,      FB_WF: 0,       AS_WF: 24,      PE1_WF: 0,       PT_WF: 29.06,   PE2_WF: 16,      ER_WF: 0,      SC_PS: '2025-08-25', SC_PF: '2025-09-11', SC_PP: 0,     SC_AS: undefined,    SC_AF: undefined,    SC_AP: 0,   FB_PS: undefined,    FB_PF: undefined,    FB_PP: 0,     FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: '2025-09-12', AS_PF: '2025-11-03', AS_PP: 0,    AS_AS: undefined,    AS_AF: undefined,    AS_AP: 0,    PE1_PS: undefined,    PE1_PF: undefined,    PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: '2025-11-24', PT_PF: '2025-12-16', PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: '2025-12-17', PE2_PF: '2026-01-09', PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: undefined,    ER_PF: undefined,    ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'TOPSIDE', ZONE: 'SOUTH',          MOD: 'Generator Building',  LEVEL: 'Generator Building',  NAME: 'Access P/F(Top)',        G_EREC: 'GD110', G_2PE: 'GD110', G_1PE: 'GV191', EREC: '',      PE2: '',      PE1: '',      PBLK: 'GV191', BLK: 'GV191', L: '4.8',  B: '2.2',  H: '2.5',  YARD: 'HHI',   SC_WF: 8,      FB_WF: 0,       AS_WF: 24,      PE1_WF: 0,       PT_WF: 43.72,   PE2_WF: 16,      ER_WF: 0,      SC_PS: '2025-08-25', SC_PF: '2025-09-11', SC_PP: 0,     SC_AS: undefined,    SC_AF: undefined,    SC_AP: 0,   FB_PS: undefined,    FB_PF: undefined,    FB_PP: 0,     FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: '2025-09-12', AS_PF: '2025-11-03', AS_PP: 0,    AS_AS: undefined,    AS_AF: undefined,    AS_AP: 0,    PE1_PS: undefined,    PE1_PF: undefined,    PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: '2025-11-24', PT_PF: '2025-12-16', PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: '2025-12-17', PE2_PF: '2026-01-09', PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: undefined,    ER_PF: undefined,    ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'TOPSIDE', ZONE: 'SOUTH',          MOD: 'Generator Building',  LEVEL: 'Generator Building',  NAME: 'Access P/F(Top)',        G_EREC: 'GD110', G_2PE: 'GD110', G_1PE: 'GV1A1', EREC: '',      PE2: '',      PE1: '',      PBLK: 'GV1A1', BLK: 'GV1A1', L: '1.8',  B: '2',    H: '2.6',  YARD: 'HHI',   SC_WF: 0,      FB_WF: 0,       AS_WF: 0,       PE1_WF: 0,       PT_WF: 0,       PE2_WF: 0,       ER_WF: 0,      SC_PS: undefined,    SC_PF: undefined,    SC_PP: 0,     SC_AS: undefined,    SC_AF: undefined,    SC_AP: 0,   FB_PS: undefined,    FB_PF: undefined,    FB_PP: 0,     FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: undefined,    AS_PF: undefined,    AS_PP: 0,    AS_AS: undefined,    AS_AF: undefined,    AS_AP: 0,    PE1_PS: undefined,    PE1_PF: undefined,    PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: undefined,    PT_PF: undefined,    PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: undefined,    PE2_PF: undefined,    PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: undefined,    ER_PF: undefined,    ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'TOPSIDE', ZONE: 'SOUTH',          MOD: 'Generator Building',  LEVEL: 'Generator Building',  NAME: 'Access P/F(Top)',        G_EREC: 'GD110', G_2PE: 'GD110', G_1PE: 'GV1B1', EREC: '',      PE2: '',      PE1: '',      PBLK: 'GV1B1', BLK: 'GV1B1', L: '1.8',  B: '2',    H: '2.6',  YARD: 'HHI',   SC_WF: 0,      FB_WF: 0,       AS_WF: 0,       PE1_WF: 0,       PT_WF: 0,       PE2_WF: 0,       ER_WF: 0,      SC_PS: undefined,    SC_PF: undefined,    SC_PP: 0,     SC_AS: undefined,    SC_AF: undefined,    SC_AP: 0,   FB_PS: undefined,    FB_PF: undefined,    FB_PP: 0,     FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: undefined,    AS_PF: undefined,    AS_PP: 0,    AS_AS: undefined,    AS_AF: undefined,    AS_AP: 0,    PE1_PS: undefined,    PE1_PF: undefined,    PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: undefined,    PT_PF: undefined,    PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: undefined,    PE2_PF: undefined,    PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: undefined,    ER_PF: undefined,    ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'TOPSIDE', ZONE: 'SOUTH',          MOD: 'Generator Building',  LEVEL: 'Generator Building',  NAME: 'Access P/F(Top)',        G_EREC: 'GD110', G_2PE: 'GD110', G_1PE: 'GV1C1', EREC: '',      PE2: '',      PE1: '',      PBLK: 'GV1C1', BLK: 'GV1C1', L: '1.8',  B: '2',    H: '2.6',  YARD: 'HHI',   SC_WF: 0,      FB_WF: 0,       AS_WF: 0,       PE1_WF: 0,       PT_WF: 0,       PE2_WF: 0,       ER_WF: 0,      SC_PS: undefined,    SC_PF: undefined,    SC_PP: 0,     SC_AS: undefined,    SC_AF: undefined,    SC_AP: 0,   FB_PS: undefined,    FB_PF: undefined,    FB_PP: 0,     FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: undefined,    AS_PF: undefined,    AS_PP: 0,    AS_AS: undefined,    AS_AF: undefined,    AS_AP: 0,    PE1_PS: undefined,    PE1_PF: undefined,    PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: undefined,    PT_PF: undefined,    PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: undefined,    PE2_PF: undefined,    PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: undefined,    ER_PF: undefined,    ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'TOPSIDE', ZONE: 'SOUTH',          MOD: 'Generator Building',  LEVEL: 'Generator Building',  NAME: 'Pipe Rack',              G_EREC: 'GD110', G_2PE: 'GD110', G_1PE: 'GP111', EREC: '',      PE2: '',      PE1: '',      PBLK: 'GP111', BLK: 'GP111', L: '31.4', B: '9.2',  H: '3.5',  YARD: 'HHI',   SC_WF: 16,     FB_WF: 0,       AS_WF: 27.34,   PE1_WF: 0,       PT_WF: 279.44,  PE2_WF: 45.56,   ER_WF: 0,      SC_PS: '2025-07-22', SC_PF: '2025-08-28', SC_PP: 0,     SC_AS: undefined,    SC_AF: undefined,    SC_AP: 0,   FB_PS: undefined,    FB_PF: undefined,    FB_PP: 0,     FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: '2025-08-29', AS_PF: '2025-10-20', AS_PP: 0,    AS_AS: undefined,    AS_AF: undefined,    AS_AP: 0,    PE1_PS: undefined,    PE1_PF: undefined,    PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: '2025-11-10', PT_PF: '2025-12-02', PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: '2025-12-03', PE2_PF: '2026-01-09', PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: undefined,    ER_PF: undefined,    ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'TOPSIDE', ZONE: 'SOUTH',          MOD: 'Generator Building',  LEVEL: 'Generator Building',  NAME: 'Pipe Rack',              G_EREC: 'GD110', G_2PE: 'GD110', G_1PE: 'GP121', EREC: '',      PE2: '',      PE1: '',      PBLK: 'GP121', BLK: 'GP121', L: '25',   B: '1.9',  H: '3.1',  YARD: 'HHI',   SC_WF: 16,     FB_WF: 0,       AS_WF: 27.34,   PE1_WF: 0,       PT_WF: 279.44,  PE2_WF: 45.56,   ER_WF: 0,      SC_PS: '2025-07-22', SC_PF: '2025-08-28', SC_PP: 0,     SC_AS: undefined,    SC_AF: undefined,    SC_AP: 0,   FB_PS: undefined,    FB_PF: undefined,    FB_PP: 0,     FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: '2025-08-29', AS_PF: '2025-10-20', AS_PP: 0,    AS_AS: undefined,    AS_AF: undefined,    AS_AP: 0,    PE1_PS: undefined,    PE1_PF: undefined,    PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: '2025-11-10', PT_PF: '2025-12-02', PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: '2025-12-03', PE2_PF: '2026-01-09', PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: undefined,    ER_PF: undefined,    ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'TOPSIDE', ZONE: 'SOUTH',          MOD: 'Generator Building',  LEVEL: 'Generator Building',  NAME: 'Access P/F(Under)',      G_EREC: 'GD110', G_2PE: 'GD110', G_1PE: 'GB111', EREC: '',      PE2: '',      PE1: '',      PBLK: 'GB111', BLK: 'GB111', L: '4.8',  B: '8.2',  H: '2.2',  YARD: 'HHI',   SC_WF: 8,      FB_WF: 0,       AS_WF: 24,      PE1_WF: 0,       PT_WF: 124.07,  PE2_WF: 16,      ER_WF: 0,      SC_PS: '2025-07-25', SC_PF: '2025-08-28', SC_PP: 0,     SC_AS: undefined,    SC_AF: undefined,    SC_AP: 0,   FB_PS: undefined,    FB_PF: undefined,    FB_PP: 0,     FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: '2025-08-29', AS_PF: '2025-10-20', AS_PP: 0,    AS_AS: undefined,    AS_AF: undefined,    AS_AP: 0,    PE1_PS: undefined,    PE1_PF: undefined,    PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: '2025-11-10', PT_PF: '2025-12-02', PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: '2025-12-03', PE2_PF: '2026-01-09', PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: undefined,    ER_PF: undefined,    ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'TOPSIDE', ZONE: 'SOUTH',          MOD: 'Generator Building',  LEVEL: 'Generator Building',  NAME: 'Access P/F(Under)',      G_EREC: 'GD110', G_2PE: 'GD110', G_1PE: 'GB121', EREC: '',      PE2: '',      PE1: '',      PBLK: 'GB121', BLK: 'GB121', L: '4.8',  B: '8.2',  H: '2.2',  YARD: 'HHI',   SC_WF: 8,      FB_WF: 0,       AS_WF: 24,      PE1_WF: 0,       PT_WF: 124.07,  PE2_WF: 16,      ER_WF: 0,      SC_PS: '2025-07-25', SC_PF: '2025-08-28', SC_PP: 0,     SC_AS: undefined,    SC_AF: undefined,    SC_AP: 0,   FB_PS: undefined,    FB_PF: undefined,    FB_PP: 0,     FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: '2025-08-29', AS_PF: '2025-10-20', AS_PP: 0,    AS_AS: undefined,    AS_AF: undefined,    AS_AP: 0,    PE1_PS: undefined,    PE1_PF: undefined,    PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: '2025-11-10', PT_PF: '2025-12-02', PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: '2025-12-03', PE2_PF: '2026-01-09', PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: undefined,    ER_PF: undefined,    ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'TOPSIDE', ZONE: 'SOUTH',          MOD: 'Generator Building',  LEVEL: 'Generator Building',  NAME: 'Access P/F(Under)',      G_EREC: 'GD110', G_2PE: 'GD110', G_1PE: 'GB131', EREC: '',      PE2: '',      PE1: '',      PBLK: 'GB131', BLK: 'GB131', L: '4.8',  B: '8.2',  H: '2.2',  YARD: 'HHI',   SC_WF: 8,      FB_WF: 0,       AS_WF: 24,      PE1_WF: 0,       PT_WF: 124.07,  PE2_WF: 16,      ER_WF: 0,      SC_PS: '2025-07-25', SC_PF: '2025-08-28', SC_PP: 0,     SC_AS: undefined,    SC_AF: undefined,    SC_AP: 0,   FB_PS: undefined,    FB_PF: undefined,    FB_PP: 0,     FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: '2025-08-29', AS_PF: '2025-10-20', AS_PP: 0,    AS_AS: undefined,    AS_AF: undefined,    AS_AP: 0,    PE1_PS: undefined,    PE1_PF: undefined,    PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: '2025-11-10', PT_PF: '2025-12-02', PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: '2025-12-03', PE2_PF: '2026-01-09', PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: undefined,    ER_PF: undefined,    ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'TOPSIDE', ZONE: 'SOUTH',          MOD: 'Erectrical Building', LEVEL: 'Erectrical Building', NAME: 'Deck Block',             G_EREC: 'ER310', G_2PE: 'ER310', G_1PE: 'ER310', EREC: 'ER310', PE2: 'ER310', PE1: 'ER310', PBLK: 'ER310', BLK: 'ER311', L: '15.6', B: '18.6', H: '7',    YARD: 'HHI',   SC_WF: 247.84, FB_WF: 495.68,  AS_WF: 743.52,  PE1_WF: 0,       PT_WF: 2062.26, PE2_WF: 1969.59, ER_WF: 5822.9, SC_PS: '2025-04-10', SC_PF: '2025-04-29', SC_PP: 0,     SC_AS: undefined,    SC_AF: undefined,    SC_AP: 0,   FB_PS: '2025-04-30', FB_PF: '2025-05-30', FB_PP: 0,     FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: '2025-06-02', AS_PF: '2025-08-11', AS_PP: 0,    AS_AS: undefined,    AS_AF: undefined,    AS_AP: 0,    PE1_PS: undefined,    PE1_PF: undefined,    PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: '2025-09-19', PT_PF: '2025-10-20', PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: '2025-10-21', PE2_PF: '2025-12-22', PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: '2025-12-24', ER_PF: '2026-03-25', ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'TOPSIDE', ZONE: 'SOUTH',          MOD: 'Erectrical Building', LEVEL: 'Erectrical Building', NAME: 'Wall',                   G_EREC: 'ER310', G_2PE: 'ER310', G_1PE: 'ER310', EREC: '',      PE2: '',      PE1: '',      PBLK: '',      BLK: 'ES311', L: '15.5', B: '18.5', H: '6.4',  YARD: 'HHI',   SC_WF: 91.12,  FB_WF: 182.23,  AS_WF: 0,       PE1_WF: 0,       PT_WF: 0,       PE2_WF: 0,       ER_WF: 0,      SC_PS: '2025-05-14', SC_PF: '2025-06-02', SC_PP: 0,     SC_AS: undefined,    SC_AF: undefined,    SC_AP: 0,   FB_PS: '2025-06-03', FB_PF: '2025-07-14', FB_PP: 0,     FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: undefined,    AS_PF: undefined,    AS_PP: 0,    AS_AS: undefined,    AS_AF: undefined,    AS_AP: 0,    PE1_PS: undefined,    PE1_PF: undefined,    PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: undefined,    PT_PF: undefined,    PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: undefined,    PE2_PF: undefined,    PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: undefined,    ER_PF: undefined,    ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'TOPSIDE', ZONE: 'SOUTH',          MOD: 'Erectrical Building', LEVEL: 'Erectrical Building', NAME: 'Deck Block',             G_EREC: 'ER310', G_2PE: 'ER310', G_1PE: 'ER310', EREC: '',      PE2: '',      PE1: '',      PBLK: '',      BLK: 'ER321', L: '15.5', B: '18.6', H: '6.7',  YARD: 'HHI',   SC_WF: 205.93, FB_WF: 411.85,  AS_WF: 617.78,  PE1_WF: 0,       PT_WF: 0,       PE2_WF: 0,       ER_WF: 0,      SC_PS: '2025-04-16', SC_PF: '2025-05-08', SC_PP: 0,     SC_AS: undefined,    SC_AF: undefined,    SC_AP: 0,   FB_PS: '2025-05-09', FB_PF: '2025-06-05', FB_PP: 0,     FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: '2025-06-09', AS_PF: '2025-08-18', AS_PP: 0,    AS_AS: undefined,    AS_AF: undefined,    AS_AP: 0,    PE1_PS: undefined,    PE1_PF: undefined,    PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: undefined,    PT_PF: undefined,    PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: undefined,    PE2_PF: undefined,    PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: undefined,    ER_PF: undefined,    ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'TOPSIDE', ZONE: 'SOUTH',          MOD: 'Erectrical Building', LEVEL: 'Erectrical Building', NAME: 'Wall',                   G_EREC: 'ER310', G_2PE: 'ER310', G_1PE: 'ER310', EREC: '',      PE2: '',      PE1: '',      PBLK: '',      BLK: 'ES321', L: '15.5', B: '18.5', H: '6.2',  YARD: 'HHI',   SC_WF: 91.12,  FB_WF: 182.23,  AS_WF: 0,       PE1_WF: 0,       PT_WF: 0,       PE2_WF: 0,       ER_WF: 0,      SC_PS: '2025-05-20', SC_PF: '2025-06-09', SC_PP: 0,     SC_AS: undefined,    SC_AF: undefined,    SC_AP: 0,   FB_PS: '2025-06-10', FB_PF: '2025-07-18', FB_PP: 0,     FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: undefined,    AS_PF: undefined,    AS_PP: 0,    AS_AS: undefined,    AS_AF: undefined,    AS_AP: 0,    PE1_PS: undefined,    PE1_PF: undefined,    PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: undefined,    PT_PF: undefined,    PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: undefined,    PE2_PF: undefined,    PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: undefined,    ER_PF: undefined,    ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'TOPSIDE', ZONE: 'SOUTH',          MOD: 'Erectrical Building', LEVEL: 'Erectrical Building', NAME: 'Deck Block',             G_EREC: 'ER310', G_2PE: 'ER310', G_1PE: 'ER310', EREC: '',      PE2: '',      PE1: '',      PBLK: '',      BLK: 'ER331', L: '17.3', B: '20.3', H: '2',    YARD: 'HHI',   SC_WF: 229.62, FB_WF: 459.23,  AS_WF: 688.85,  PE1_WF: 0,       PT_WF: 0,       PE2_WF: 0,       ER_WF: 0,      SC_PS: '2025-04-17', SC_PF: '2025-05-09', SC_PP: 0,     SC_AS: undefined,    SC_AF: undefined,    SC_AP: 0,   FB_PS: '2025-05-12', FB_PF: '2025-06-09', FB_PP: 0,     FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: '2025-06-10', AS_PF: '2025-08-19', AS_PP: 0,    AS_AS: undefined,    AS_AF: undefined,    AS_AP: 0,    PE1_PS: undefined,    PE1_PF: undefined,    PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: undefined,    PT_PF: undefined,    PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: undefined,    PE2_PF: undefined,    PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: undefined,    ER_PF: undefined,    ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'TOPSIDE', ZONE: 'SOUTH',          MOD: 'Erectrical Building', LEVEL: 'Erectrical Building', NAME: 'Access P/F',             G_EREC: 'ER310', G_2PE: 'ER310', G_1PE: 'ER310', EREC: '',      PE2: '',      PE1: '',      PBLK: '',      BLK: 'EV111', L: '10.7', B: '6.6',  H: '2.2',  YARD: 'HHI',   SC_WF: 24,     FB_WF: 0,       AS_WF: 60.14,   PE1_WF: 0,       PT_WF: 0,       PE2_WF: 0,       ER_WF: 0,      SC_PS: '2025-06-05', SC_PF: '2025-06-25', SC_PP: 0,     SC_AS: undefined,    SC_AF: undefined,    SC_AP: 0,   FB_PS: undefined,    FB_PF: undefined,    FB_PP: 0,     FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: '2025-06-26', AS_PF: '2025-08-21', AS_PP: 0,    AS_AS: undefined,    AS_AF: undefined,    AS_AP: 0,    PE1_PS: undefined,    PE1_PF: undefined,    PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: undefined,    PT_PF: undefined,    PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: undefined,    PE2_PF: undefined,    PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: undefined,    ER_PF: undefined,    ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'TOPSIDE', ZONE: 'SOUTH',          MOD: 'Erectrical Building', LEVEL: 'Erectrical Building', NAME: 'Access P/F',             G_EREC: 'ER310', G_2PE: 'ER310', G_1PE: 'ER310', EREC: '',      PE2: '',      PE1: '',      PBLK: '',      BLK: 'EV121', L: '8.1',  B: '2.6',  H: '2.1',  YARD: 'HHI',   SC_WF: 24,     FB_WF: 0,       AS_WF: 43.74,   PE1_WF: 0,       PT_WF: 0,       PE2_WF: 0,       ER_WF: 0,      SC_PS: '2025-06-05', SC_PF: '2025-06-25', SC_PP: 0,     SC_AS: undefined,    SC_AF: undefined,    SC_AP: 0,   FB_PS: undefined,    FB_PF: undefined,    FB_PP: 0,     FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: '2025-06-26', AS_PF: '2025-08-21', AS_PP: 0,    AS_AS: undefined,    AS_AF: undefined,    AS_AP: 0,    PE1_PS: undefined,    PE1_PF: undefined,    PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: undefined,    PT_PF: undefined,    PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: undefined,    PE2_PF: undefined,    PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: undefined,    ER_PF: undefined,    ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'TOPSIDE', ZONE: 'SOUTH',          MOD: 'Erectrical Building', LEVEL: 'Erectrical Building', NAME: 'Access P/F',             G_EREC: 'ER310', G_2PE: 'ER310', G_1PE: 'ER310', EREC: '',      PE2: '',      PE1: '',      PBLK: '',      BLK: 'EV131', L: '3.4',  B: '12.2', H: '1.8',  YARD: 'HHI',   SC_WF: 25.51,  FB_WF: 0,       AS_WF: 76.54,   PE1_WF: 0,       PT_WF: 0,       PE2_WF: 0,       ER_WF: 0,      SC_PS: '2025-05-29', SC_PF: '2025-06-18', SC_PP: 0,     SC_AS: undefined,    SC_AF: undefined,    SC_AP: 0,   FB_PS: undefined,    FB_PF: undefined,    FB_PP: 0,     FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: '2025-06-19', AS_PF: '2025-08-21', AS_PP: 0,    AS_AS: undefined,    AS_AF: undefined,    AS_AP: 0,    PE1_PS: undefined,    PE1_PF: undefined,    PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: undefined,    PT_PF: undefined,    PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: undefined,    PE2_PF: undefined,    PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: undefined,    ER_PF: undefined,    ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'TOPSIDE', ZONE: 'SOUTH',          MOD: 'Erectrical Building', LEVEL: 'Erectrical Building', NAME: 'Access P/F',             G_EREC: 'ER310', G_2PE: 'ER310', G_1PE: 'ER310', EREC: '',      PE2: '',      PE1: '',      PBLK: '',      BLK: 'EV141', L: '3.3',  B: '6.9',  H: '6.4',  YARD: 'HHI',   SC_WF: 8,      FB_WF: 0,       AS_WF: 24,      PE1_WF: 0,       PT_WF: 0,       PE2_WF: 0,       ER_WF: 0,      SC_PS: '2025-05-29', SC_PF: '2025-06-18', SC_PP: 0,     SC_AS: undefined,    SC_AF: undefined,    SC_AP: 0,   FB_PS: undefined,    FB_PF: undefined,    FB_PP: 0,     FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: '2025-06-19', AS_PF: '2025-08-21', AS_PP: 0,    AS_AS: undefined,    AS_AF: undefined,    AS_AP: 0,    PE1_PS: undefined,    PE1_PF: undefined,    PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: undefined,    PT_PF: undefined,    PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: undefined,    PE2_PF: undefined,    PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: undefined,    ER_PF: undefined,    ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'TOPSIDE', ZONE: 'SOUTH',          MOD: 'Erectrical Building', LEVEL: 'Erectrical Building', NAME: 'Access P/F',             G_EREC: 'ER310', G_2PE: 'ER310', G_1PE: 'ER310', EREC: '',      PE2: '',      PE1: '',      PBLK: '',      BLK: 'EV1A1', L: '3.3',  B: '6.9',  H: '2.2',  YARD: 'HHI',   SC_WF: 0,      FB_WF: 0,       AS_WF: 0,       PE1_WF: 0,       PT_WF: 0,       PE2_WF: 0,       ER_WF: 0,      SC_PS: undefined,    SC_PF: undefined,    SC_PP: 0,     SC_AS: undefined,    SC_AF: undefined,    SC_AP: 0,   FB_PS: undefined,    FB_PF: undefined,    FB_PP: 0,     FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: undefined,    AS_PF: undefined,    AS_PP: 0,    AS_AS: undefined,    AS_AF: undefined,    AS_AP: 0,    PE1_PS: undefined,    PE1_PF: undefined,    PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: undefined,    PT_PF: undefined,    PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: undefined,    PE2_PF: undefined,    PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: undefined,    ER_PF: undefined,    ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'TOPSIDE', ZONE: 'SOUTH',          MOD: 'Erectrical Building', LEVEL: 'Erectrical Building', NAME: 'Access P/F',             G_EREC: 'ER310', G_2PE: 'ER310', G_1PE: 'ER310', EREC: '',      PE2: '',      PE1: '',      PBLK: '',      BLK: 'EV211', L: '1.9',  B: '7.3',  H: '3',    YARD: 'HHI',   SC_WF: 8,      FB_WF: 0,       AS_WF: 24,      PE1_WF: 0,       PT_WF: 0,       PE2_WF: 0,       ER_WF: 0,      SC_PS: '2025-05-09', SC_PF: '2025-05-28', SC_PP: 0,     SC_AS: undefined,    SC_AF: undefined,    SC_AP: 0,   FB_PS: undefined,    FB_PF: undefined,    FB_PP: 0,     FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: '2025-05-29', AS_PF: '2025-07-11', AS_PP: 0,    AS_AS: undefined,    AS_AF: undefined,    AS_AP: 0,    PE1_PS: undefined,    PE1_PF: undefined,    PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: undefined,    PT_PF: undefined,    PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: undefined,    PE2_PF: undefined,    PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: undefined,    ER_PF: undefined,    ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'TOPSIDE', ZONE: 'SOUTH',          MOD: 'Erectrical Building', LEVEL: 'Erectrical Building', NAME: 'Access P/F',             G_EREC: 'ER310', G_2PE: 'ER310', G_1PE: 'ER310', EREC: '',      PE2: '',      PE1: '',      PBLK: '',      BLK: 'EV221', L: '8.5',  B: '3.8',  H: '3.2',  YARD: 'HHI',   SC_WF: 24,     FB_WF: 0,       AS_WF: 38.27,   PE1_WF: 0,       PT_WF: 0,       PE2_WF: 0,       ER_WF: 0,      SC_PS: '2025-05-09', SC_PF: '2025-05-28', SC_PP: 0,     SC_AS: undefined,    SC_AF: undefined,    SC_AP: 0,   FB_PS: undefined,    FB_PF: undefined,    FB_PP: 0,     FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: '2025-05-29', AS_PF: '2025-07-11', AS_PP: 0,    AS_AS: undefined,    AS_AF: undefined,    AS_AP: 0,    PE1_PS: undefined,    PE1_PF: undefined,    PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: undefined,    PT_PF: undefined,    PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: undefined,    PE2_PF: undefined,    PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: undefined,    ER_PF: undefined,    ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'TOPSIDE', ZONE: 'SOUTH',          MOD: 'Erectrical Building', LEVEL: 'Erectrical Building', NAME: 'Access P/F',             G_EREC: 'ER310', G_2PE: 'ER310', G_1PE: 'ER310', EREC: '',      PE2: '',      PE1: '',      PBLK: '',      BLK: 'EV231', L: '3.4',  B: '16',   H: '6.7',  YARD: 'HHI',   SC_WF: 24.3,   FB_WF: 0,       AS_WF: 54.67,   PE1_WF: 0,       PT_WF: 0,       PE2_WF: 0,       ER_WF: 0,      SC_PS: '2025-05-09', SC_PF: '2025-05-28', SC_PP: 0,     SC_AS: undefined,    SC_AF: undefined,    SC_AP: 0,   FB_PS: undefined,    FB_PF: undefined,    FB_PP: 0,     FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: '2025-05-29', AS_PF: '2025-07-11', AS_PP: 0,    AS_AS: undefined,    AS_AF: undefined,    AS_AP: 0,    PE1_PS: undefined,    PE1_PF: undefined,    PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: undefined,    PT_PF: undefined,    PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: undefined,    PE2_PF: undefined,    PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: undefined,    ER_PF: undefined,    ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'TOPSIDE', ZONE: 'SOUTH',          MOD: 'Erectrical Building', LEVEL: 'Erectrical Building', NAME: 'Access P/F',             G_EREC: 'ER310', G_2PE: 'ER310', G_1PE: 'ER310', EREC: '',      PE2: '',      PE1: '',      PBLK: '',      BLK: 'EV241', L: '3.4',  B: '16',   H: '6.7',  YARD: 'HHI',   SC_WF: 24.3,   FB_WF: 0,       AS_WF: 54.67,   PE1_WF: 0,       PT_WF: 0,       PE2_WF: 0,       ER_WF: 0,      SC_PS: '2025-05-09', SC_PF: '2025-05-28', SC_PP: 0,     SC_AS: undefined,    SC_AF: undefined,    SC_AP: 0,   FB_PS: undefined,    FB_PF: undefined,    FB_PP: 0,     FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: '2025-05-29', AS_PF: '2025-07-11', AS_PP: 0,    AS_AS: undefined,    AS_AF: undefined,    AS_AP: 0,    PE1_PS: undefined,    PE1_PF: undefined,    PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: undefined,    PT_PF: undefined,    PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: undefined,    PE2_PF: undefined,    PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: undefined,    ER_PF: undefined,    ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'TOPSIDE', ZONE: 'SOUTH',          MOD: 'Erectrical Building', LEVEL: 'Erectrical Building', NAME: 'Access P/F',             G_EREC: 'ER310', G_2PE: 'ER310', G_1PE: 'ER310', EREC: '',      PE2: '',      PE1: '',      PBLK: '',      BLK: 'EV3A1', L: '4.6',  B: '1.5',  H: '4',    YARD: 'HHI',   SC_WF: 15.58,  FB_WF: 0,       AS_WF: 29.52,   PE1_WF: 0,       PT_WF: 0,       PE2_WF: 0,       ER_WF: 0,      SC_PS: '2025-05-09', SC_PF: '2025-05-28', SC_PP: 0,     SC_AS: undefined,    SC_AF: undefined,    SC_AP: 0,   FB_PS: undefined,    FB_PF: undefined,    FB_PP: 0,     FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: '2025-05-29', AS_PF: '2025-07-11', AS_PP: 0,    AS_AS: undefined,    AS_AF: undefined,    AS_AP: 0,    PE1_PS: undefined,    PE1_PF: undefined,    PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: undefined,    PT_PF: undefined,    PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: undefined,    PE2_PF: undefined,    PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: undefined,    ER_PF: undefined,    ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: 'Y', AREA: 'TOPSIDE', ZONE: 'FLARE',          MOD: 'Flare Tower',         LEVEL: 'Flare Tower',         NAME: 'Upper Part',             G_EREC: 'NF110', G_2PE: 'NF110', G_1PE: 'NF110', EREC: 'NF110', PE2: 'NF110', PE1: 'NF110', PBLK: 'NF111', BLK: 'NF111', L: '14.8', B: '12.4', H: '19.6', YARD: 'HHI',   SC_WF: 118.45, FB_WF: 236.91,  AS_WF: 355.36,  PE1_WF: 646.93,  PT_WF: 2144.44, PE2_WF: 646.93,  ER_WF: 2073.7, SC_PS: '2026-07-17', SC_PF: '2026-08-19', SC_PP: 0,     SC_AS: undefined,    SC_AF: undefined,    SC_AP: 0,   FB_PS: '2026-08-20', FB_PF: '2026-09-18', FB_PP: 0,     FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: '2026-09-21', AS_PF: '2026-11-20', AS_PP: 0,    AS_AS: undefined,    AS_AF: undefined,    AS_AP: 0,    PE1_PS: '2026-11-23', PE1_PF: '2026-12-22', PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: '2026-12-23', PT_PF: '2027-01-18', PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: '2027-01-19', PE2_PF: '2027-02-16', PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: '2025-12-03', ER_PF: '2025-12-31', ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: 'Y', AREA: 'TOPSIDE', ZONE: 'FLARE',          MOD: 'Flare Tower',         LEVEL: 'Flare Tower',         NAME: 'Upper Part',             G_EREC: 'NF110', G_2PE: 'NF110', G_1PE: 'NF110', EREC: '',      PE2: '',      PE1: '',      PBLK: '',      BLK: 'NF112', L: '12.7', B: '20.2', H: '20.6', YARD: 'HHI',   SC_WF: 109.34, FB_WF: 218.68,  AS_WF: 328.02,  PE1_WF: 0,       PT_WF: 0,       PE2_WF: 0,       ER_WF: 0,      SC_PS: '2026-07-17', SC_PF: '2026-08-19', SC_PP: 0,     SC_AS: undefined,    SC_AF: undefined,    SC_AP: 0,   FB_PS: '2026-08-20', FB_PF: '2026-09-18', FB_PP: 0,     FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: '2026-09-21', AS_PF: '2026-11-20', AS_PP: 0,    AS_AS: undefined,    AS_AF: undefined,    AS_AP: 0,    PE1_PS: undefined,    PE1_PF: undefined,    PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: undefined,    PT_PF: undefined,    PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: undefined,    PE2_PF: undefined,    PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: undefined,    ER_PF: undefined,    ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: 'Y', AREA: 'TOPSIDE', ZONE: 'FLARE',          MOD: 'Flare Tower',         LEVEL: 'Flare Tower',         NAME: 'Middle Part',            G_EREC: 'NF110', G_2PE: 'NF110', G_1PE: 'NF120', EREC: '',      PE2: '',      PE1: 'NF120', PBLK: 'NF121', BLK: 'NF121', L: '9.9',  B: '17.7', H: '16.9', YARD: 'HHI',   SC_WF: 82.01,  FB_WF: 164.01,  AS_WF: 246.02,  PE1_WF: 464.7,   PT_WF: 1548.3,  PE2_WF: 464.7,   ER_WF: 0,      SC_PS: '2026-07-21', SC_PF: '2026-08-21', SC_PP: 0,     SC_AS: undefined,    SC_AF: undefined,    SC_AP: 0,   FB_PS: '2026-08-24', FB_PF: '2026-09-22', FB_PP: 0,     FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: '2026-09-23', AS_PF: '2026-11-24', AS_PP: 0,    AS_AS: undefined,    AS_AF: undefined,    AS_AP: 0,    PE1_PS: '2026-11-25', PE1_PF: '2026-12-24', PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: '2026-12-28', PT_PF: '2027-01-20', PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: '2027-01-21', PE2_PF: '2027-02-16', PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: undefined,    ER_PF: undefined,    ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: 'Y', AREA: 'TOPSIDE', ZONE: 'FLARE',          MOD: 'Flare Tower',         LEVEL: 'Flare Tower',         NAME: 'Middle Part',            G_EREC: 'NF110', G_2PE: 'NF110', G_1PE: 'NF120', EREC: '',      PE2: '',      PE1: '',      PBLK: '',      BLK: 'NF122', L: '7.7',  B: '14.9', H: '13.6', YARD: 'HHI',   SC_WF: 72.89,  FB_WF: 145.79,  AS_WF: 218.68,  PE1_WF: 0,       PT_WF: 0,       PE2_WF: 0,       ER_WF: 0,      SC_PS: '2026-07-21', SC_PF: '2026-08-21', SC_PP: 0,     SC_AS: undefined,    SC_AF: undefined,    SC_AP: 0,   FB_PS: '2026-08-24', FB_PF: '2026-09-22', FB_PP: 0,     FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: '2026-09-23', AS_PF: '2026-11-24', AS_PP: 0,    AS_AS: undefined,    AS_AF: undefined,    AS_AP: 0,    PE1_PS: undefined,    PE1_PF: undefined,    PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: undefined,    PT_PF: undefined,    PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: undefined,    PE2_PF: undefined,    PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: undefined,    ER_PF: undefined,    ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: 'Y', AREA: 'TOPSIDE', ZONE: 'FLARE',          MOD: 'Flare Tower',         LEVEL: 'Flare Tower',         NAME: 'Lower Part',             G_EREC: 'NF110', G_2PE: 'NF110', G_1PE: 'NF130', EREC: '',      PE2: '',      PE1: 'NF130', PBLK: 'NF131', BLK: 'NF131', L: '7.7',  B: '17.4', H: '11.9', YARD: 'HHI',   SC_WF: 34.62,  FB_WF: 69.25,   AS_WF: 0,       PE1_WF: 227.79,  PT_WF: 608.56,  PE2_WF: 227.79,  ER_WF: 0,      SC_PS: '2026-09-08', SC_PF: '2026-09-29', SC_PP: 0,     SC_AS: undefined,    SC_AF: undefined,    SC_AP: 0,   FB_PS: '2026-09-30', FB_PF: '2026-11-27', FB_PP: 0,     FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: undefined,    AS_PF: undefined,    AS_PP: 0,    AS_AS: undefined,    AS_AF: undefined,    AS_AP: 0,    PE1_PS: '2026-11-30', PE1_PF: '2026-12-30', PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: '2026-12-31', PT_PF: '2027-01-25', PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: '2027-01-26', PE2_PF: '2027-02-16', PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: undefined,    ER_PF: undefined,    ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: 'Y', AREA: 'TOPSIDE', ZONE: 'FLARE',          MOD: 'Flare Tower',         LEVEL: 'Flare Tower',         NAME: 'Lower Part',             G_EREC: 'NF110', G_2PE: 'NF110', G_1PE: 'NF130', EREC: '',      PE2: '',      PE1: '',      PBLK: '',      BLK: 'NF132', L: '7.9',  B: '9.1',  H: '1.3',  YARD: 'HHI',   SC_WF: 24.3,   FB_WF: 36.45,   AS_WF: 0,       PE1_WF: 0,       PT_WF: 0,       PE2_WF: 0,       ER_WF: 0,      SC_PS: '2026-09-22', SC_PF: '2026-10-14', SC_PP: 0,     SC_AS: undefined,    SC_AF: undefined,    SC_AP: 0,   FB_PS: '2026-10-15', FB_PF: '2026-11-27', FB_PP: 0,     FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: undefined,    AS_PF: undefined,    AS_PP: 0,    AS_AS: undefined,    AS_AF: undefined,    AS_AP: 0,    PE1_PS: undefined,    PE1_PF: undefined,    PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: undefined,    PT_PF: undefined,    PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: undefined,    PE2_PF: undefined,    PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: undefined,    ER_PF: undefined,    ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'LQ',      ZONE: 'Living Quarter', MOD: 'LQ-Level 1',          LEVEL: 'LQ-Level 1',          NAME: 'Deck Block',             G_EREC: 'LD110', G_2PE: 'LD110', G_1PE: 'LD110', EREC: 'LD110', PE2: 'LD110', PE1: 'LD110', PBLK: 'LD110', BLK: 'LD111', L: '18.6', B: '18.6', H: '1.2',  YARD: 'HHI',   SC_WF: 162.77, FB_WF: 488.32,  AS_WF: 651.09,  PE1_WF: 911.17,  PT_WF: 2719.88, PE2_WF: 911.17,  ER_WF: 2073.7, SC_PS: '2024-11-01', SC_PF: '2024-11-11', SC_PP: 100,   SC_AS: '2024-11-01', SC_AF: '2024-11-08', SC_AP: 100, FB_PS: '2024-11-12', FB_PF: '2024-12-18', FB_PP: 99.9,  FB_AS: '2024-11-13', FB_AF: '2024-12-20', FB_AP: 100,  AS_PS: '2024-12-19', AS_PF: '2025-02-10', AS_PP: 100,  AS_AS: '2024-12-19', AS_AF: '2025-02-04', AS_AP: 100,  PE1_PS: '2025-02-11', PE1_PF: '2025-03-07', PE1_PP: 14.7, PE1_AS: '2025-02-11', PE1_AF: undefined,    PE1_AP: 15, PT_PS: '2025-03-10', PT_PF: '2025-04-01', PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: '2025-04-02', PE2_PF: '2025-05-27', PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: '2025-05-28', ER_PF: '2026-05-08', ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'LQ',      ZONE: 'Living Quarter', MOD: 'LQ-Level 1',          LEVEL: 'LQ-Level 1',          NAME: 'Deck Block',             G_EREC: 'LD110', G_2PE: 'LD110', G_1PE: 'LD110', EREC: '',      PE2: '',      PE1: '',      PBLK: '',      BLK: 'LD112', L: '19.9', B: '18.2', H: '1.2',  YARD: 'HHI',   SC_WF: 165.9,  FB_WF: 497.71,  AS_WF: 663.62,  PE1_WF: 0,       PT_WF: 0,       PE2_WF: 0,       ER_WF: 0,      SC_PS: '2024-11-01', SC_PF: '2024-11-11', SC_PP: 100,   SC_AS: '2024-11-01', SC_AF: '2024-11-08', SC_AP: 100, FB_PS: '2024-11-12', FB_PF: '2024-12-18', FB_PP: 100.1, FB_AS: '2024-11-13', FB_AF: '2024-12-20', FB_AP: 100,  AS_PS: '2024-12-19', AS_PF: '2025-02-10', AS_PP: 100,  AS_AS: '2024-12-19', AS_AF: '2025-02-01', AS_AP: 100,  PE1_PS: undefined,    PE1_PF: undefined,    PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: undefined,    PT_PF: undefined,    PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: undefined,    PE2_PF: undefined,    PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: undefined,    ER_PF: undefined,    ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'LQ',      ZONE: 'Living Quarter', MOD: 'LQ-Level 1',          LEVEL: 'LQ-Level 1',          NAME: 'Column & Brace',         G_EREC: 'LD110', G_2PE: 'LD110', G_1PE: 'LD110', EREC: '',      PE2: '',      PE1: '',      PBLK: '',      BLK: 'LC111', L: '22.9', B: '4.3',  H: '3.9',  YARD: 'HHI',   SC_WF: 24,     FB_WF: 46.95,   AS_WF: 62.61,   PE1_WF: 0,       PT_WF: 0,       PE2_WF: 0,       ER_WF: 0,      SC_PS: '2024-12-02', SC_PF: '2024-12-17', SC_PP: 100,   SC_AS: '2024-12-21', SC_AF: '2024-12-22', SC_AP: 100, FB_PS: '2024-12-18', FB_PF: '2025-01-06', FB_PP: 100,   FB_AS: '2024-12-23', FB_AF: '2025-01-06', FB_AP: 100,  AS_PS: '2025-01-07', AS_PF: '2025-02-04', AS_PP: 100,  AS_AS: '2025-01-07', AS_AF: '2025-01-31', AS_AP: 100,  PE1_PS: undefined,    PE1_PF: undefined,    PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: undefined,    PT_PF: undefined,    PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: undefined,    PE2_PF: undefined,    PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: undefined,    ER_PF: undefined,    ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'LQ',      ZONE: 'Living Quarter', MOD: 'Level 1',             LEVEL: 'Level 1',             NAME: 'Deck Block',             G_EREC: 'LD110', G_2PE: 'LD110', G_1PE: 'LD120', EREC: '',      PE2: '',      PE1: '',      PBLK: 'LD120', BLK: 'LD121', L: '21',   B: '15.9', H: '1.2',  YARD: 'HHI',   SC_WF: 162.77, FB_WF: 488.32,  AS_WF: 651.09,  PE1_WF: 0,       PT_WF: 0,       PE2_WF: 0,       ER_WF: 0,      SC_PS: '2024-11-01', SC_PF: '2024-11-11', SC_PP: 100,   SC_AS: '2024-11-01', SC_AF: '2024-11-08', SC_AP: 100, FB_PS: '2024-11-12', FB_PF: '2024-12-18', FB_PP: 99.9,  FB_AS: '2024-11-14', FB_AF: '2024-12-20', FB_AP: 100,  AS_PS: '2024-12-19', AS_PF: '2025-02-10', AS_PP: 100,  AS_AS: '2024-12-19', AS_AF: '2025-02-14', AS_AP: 100,  PE1_PS: undefined,    PE1_PF: undefined,    PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: undefined,    PT_PF: undefined,    PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: undefined,    PE2_PF: undefined,    PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: undefined,    ER_PF: undefined,    ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'LQ',      ZONE: 'Living Quarter', MOD: 'Level 1',             LEVEL: 'Level 1',             NAME: 'Column & Brace',         G_EREC: 'LD110', G_2PE: 'LD110', G_1PE: 'LD120', EREC: '',      PE2: '',      PE1: '',      PBLK: '',      BLK: 'LC121', L: '24',   B: '13.2', H: '3.7',  YARD: 'HHI',   SC_WF: 24,     FB_WF: 46.95,   AS_WF: 62.61,   PE1_WF: 0,       PT_WF: 0,       PE2_WF: 0,       ER_WF: 0,      SC_PS: '2024-12-02', SC_PF: '2024-12-17', SC_PP: 100,   SC_AS: '2024-12-21', SC_AF: '2024-12-22', SC_AP: 100, FB_PS: '2024-12-18', FB_PF: '2025-01-06', FB_PP: 100,   FB_AS: '2024-12-23', FB_AF: '2025-01-06', FB_AP: 100,  AS_PS: '2025-01-07', AS_PF: '2025-02-04', AS_PP: 100,  AS_AS: '2025-01-07', AS_AF: '2025-01-31', AS_AP: 100,  PE1_PS: undefined,    PE1_PF: undefined,    PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: undefined,    PT_PF: undefined,    PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: undefined,    PE2_PF: undefined,    PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: undefined,    ER_PF: undefined,    ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'LQ',      ZONE: 'Living Quarter', MOD: 'Level 2',             LEVEL: 'Level 2',             NAME: 'Deck Block',             G_EREC: 'LD210', G_2PE: 'LD210', G_1PE: 'LD210', EREC: 'LD210', PE2: 'LD210', PE1: 'LD210', PBLK: 'LD210', BLK: 'LD211', L: '16',   B: '12.3', H: '4.3',  YARD: 'HHI',   SC_WF: 125.21, FB_WF: 375.63,  AS_WF: 500.84,  PE1_WF: 956.73,  PT_WF: 1713.89, PE2_WF: 956.73,  ER_WF: 2073.7, SC_PS: '2024-11-12', SC_PF: '2024-11-20', SC_PP: 100,   SC_AS: '2024-11-12', SC_AF: '2024-11-22', SC_AP: 100, FB_PS: '2024-11-21', FB_PF: '2024-12-30', FB_PP: 100.1, FB_AS: '2024-11-22', FB_AF: '2025-01-10', FB_AP: 100,  AS_PS: '2024-12-31', AS_PF: '2025-02-19', AS_PP: 88.2, AS_AS: '2025-01-23', AS_AF: undefined,    AS_AP: 75,   PE1_PS: '2025-02-20', PE1_PF: '2025-04-01', PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: '2025-04-02', PT_PF: '2025-04-24', PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: '2025-04-25', PE2_PF: '2025-06-20', PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: '2025-06-23', ER_PF: '2026-05-08', ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'LQ',      ZONE: 'Living Quarter', MOD: 'Level 2',             LEVEL: 'Level 2',             NAME: 'Deck Block',             G_EREC: 'LD210', G_2PE: 'LD210', G_1PE: 'LD210', EREC: '',      PE2: '',      PE1: '',      PBLK: '',      BLK: 'LD212', L: '19.9', B: '12.1', H: '4.3',  YARD: 'HHI',   SC_WF: 125.21, FB_WF: 375.63,  AS_WF: 500.84,  PE1_WF: 0,       PT_WF: 0,       PE2_WF: 0,       ER_WF: 0,      SC_PS: '2024-11-12', SC_PF: '2024-11-20', SC_PP: 100,   SC_AS: '2024-11-12', SC_AF: '2024-11-22', SC_AP: 100, FB_PS: '2024-11-21', FB_PF: '2024-12-30', FB_PP: 100.1, FB_AS: '2024-11-22', FB_AF: '2025-01-10', FB_AP: 100,  AS_PS: '2024-12-31', AS_PF: '2025-02-19', AS_PP: 88.2, AS_AS: '2025-01-15', AS_AF: undefined,    AS_AP: 75,   PE1_PS: undefined,    PE1_PF: undefined,    PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: undefined,    PT_PF: undefined,    PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: undefined,    PE2_PF: undefined,    PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: undefined,    ER_PF: undefined,    ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'LQ',      ZONE: 'Living Quarter', MOD: 'Level 2',             LEVEL: 'Level 2',             NAME: 'Wall',                   G_EREC: 'LD210', G_2PE: 'LD210', G_1PE: 'LD210', EREC: '',      PE2: '',      PE1: '',      PBLK: '',      BLK: 'LW211', L: '34.3', B: '11.4', H: '4.1',  YARD: 'HHI',   SC_WF: 78.26,  FB_WF: 234.77,  AS_WF: 0,       PE1_WF: 1878.18, PT_WF: 0,       PE2_WF: 0,       ER_WF: 0,      SC_PS: '2024-12-03', SC_PF: '2025-01-17', SC_PP: 100,   SC_AS: '2024-11-01', SC_AF: '2024-11-08', SC_AP: 100, FB_PS: '2025-01-20', FB_PF: '2025-02-19', FB_PP: 96.6,  FB_AS: '2024-11-15', FB_AF: '2025-01-10', FB_AP: 100,  AS_PS: undefined,    AS_PF: undefined,    AS_PP: 0,    AS_AS: undefined,    AS_AF: undefined,    AS_AP: 0,    PE1_PS: '2025-02-20', PE1_PF: '2025-03-28', PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: undefined,    PT_PF: undefined,    PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: undefined,    PE2_PF: undefined,    PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: undefined,    ER_PF: undefined,    ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'LQ',      ZONE: 'Living Quarter', MOD: 'Level 2',             LEVEL: 'Level 2',             NAME: 'Deck Block',             G_EREC: 'LD210', G_2PE: 'LD210', G_1PE: 'LD220', EREC: '',      PE2: '',      PE1: 'LD220', PBLK: 'LD220', BLK: 'LD221', L: '18.2', B: '12.3', H: '4.3',  YARD: 'HHI',   SC_WF: 125.21, FB_WF: 375.63,  AS_WF: 500.84,  PE1_WF: 364.47,  PT_WF: 869.37,  PE2_WF: 364.47,  ER_WF: 0,      SC_PS: '2024-11-12', SC_PF: '2024-11-20', SC_PP: 100,   SC_AS: '2024-11-12', SC_AF: '2024-11-28', SC_AP: 100, FB_PS: '2024-11-21', FB_PF: '2024-12-30', FB_PP: 100.1, FB_AS: '2024-11-22', FB_AF: '2025-12-31', FB_AP: 100,  AS_PS: '2024-12-31', AS_PF: '2025-03-05', AS_PP: 60.7, AS_AS: '2025-01-13', AS_AF: undefined,    AS_AP: 75,   PE1_PS: '2025-03-06', PE1_PF: '2025-04-15', PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: '2025-04-16', PT_PF: '2025-05-13', PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: '2025-05-14', PE2_PF: '2025-06-20', PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: undefined,    ER_PF: undefined,    ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'LQ',      ZONE: 'Living Quarter', MOD: 'Level 2',             LEVEL: 'Level 2',             NAME: 'Wall',                   G_EREC: 'LD210', G_2PE: 'LD210', G_1PE: 'LD220', EREC: '',      PE2: '',      PE1: '',      PBLK: '',      BLK: 'LW221', L: '17',   B: '11.4', H: '4.1',  YARD: 'HHI',   SC_WF: 62.61,  FB_WF: 187.82,  AS_WF: 0,       PE1_WF: 751.26,  PT_WF: 0,       PE2_WF: 0,       ER_WF: 0,      SC_PS: '2024-12-09', SC_PF: '2024-12-27', SC_PP: 100,   SC_AS: '2024-11-01', SC_AF: '2024-11-08', SC_AP: 100, FB_PS: '2024-12-30', FB_PF: '2025-02-17', FB_PP: 98.4,  FB_AS: '2024-11-15', FB_AF: '2025-01-13', FB_AP: 100,  AS_PS: undefined,    AS_PF: undefined,    AS_PP: 0,    AS_AS: undefined,    AS_AF: undefined,    AS_AP: 0,    PE1_PS: '2025-02-18', PE1_PF: '2025-03-05', PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: undefined,    PT_PF: undefined,    PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: undefined,    PE2_PF: undefined,    PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: undefined,    ER_PF: undefined,    ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'LQ',      ZONE: 'Living Quarter', MOD: 'Level 2',             LEVEL: 'Level 2',             NAME: 'Deck Block',             G_EREC: 'LD210', G_2PE: 'LD210', G_1PE: 'LV211', EREC: '',      PE2: '',      PE1: '',      PBLK: '',      BLK: 'LV211', L: '5.5',  B: '13.2', H: '0.6',  YARD: 'HHI',   SC_WF: 24,     FB_WF: 0,       AS_WF: 62.61,   PE1_WF: 0,       PT_WF: 72.45,   PE2_WF: 45.56,   ER_WF: 0,      SC_PS: '2025-01-23', SC_PF: '2025-02-05', SC_PP: 100,   SC_AS: '2025-01-21', SC_AF: '2025-01-23', SC_AP: 100, FB_PS: undefined,    FB_PF: undefined,    FB_PP: 0,     FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: '2025-02-06', AS_PF: '2025-03-21', AS_PP: 15.3, AS_AS: undefined,    AS_AF: undefined,    AS_AP: 0,    PE1_PS: undefined,    PE1_PF: undefined,    PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: '2025-04-23', PT_PF: '2025-05-20', PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: '2025-05-21', PE2_PF: '2025-06-20', PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: undefined,    ER_PF: undefined,    ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'LQ',      ZONE: 'Living Quarter', MOD: 'Level 2',             LEVEL: 'Level 2',             NAME: 'Deck Block',             G_EREC: 'LD210', G_2PE: 'LD210', G_1PE: 'LV221', EREC: '',      PE2: '',      PE1: '',      PBLK: '',      BLK: 'LV221', L: '11.2', B: '6.6',  H: '0.6',  YARD: 'HHI',   SC_WF: 24,     FB_WF: 0,       AS_WF: 62.61,   PE1_WF: 0,       PT_WF: 24,      PE2_WF: 45.56,   ER_WF: 0,      SC_PS: '2025-01-23', SC_PF: '2025-02-05', SC_PP: 100,   SC_AS: '2025-01-21', SC_AF: '2025-01-23', SC_AP: 100, FB_PS: undefined,    FB_PF: undefined,    FB_PP: 0,     FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: '2025-02-06', AS_PF: '2025-03-21', AS_PP: 15.3, AS_AS: undefined,    AS_AF: undefined,    AS_AP: 0,    PE1_PS: undefined,    PE1_PF: undefined,    PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: '2025-04-23', PT_PF: '2025-05-20', PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: '2025-05-21', PE2_PF: '2025-06-20', PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: undefined,    ER_PF: undefined,    ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'LQ',      ZONE: 'Living Quarter', MOD: 'Level 2',             LEVEL: 'Level 2',             NAME: 'Deck Block',             G_EREC: 'LD210', G_2PE: 'LD210', G_1PE: 'LV231', EREC: '',      PE2: '',      PE1: '',      PBLK: '',      BLK: 'LV231', L: '9.8',  B: '6.7',  H: '4.4',  YARD: 'HHI',   SC_WF: 32,     FB_WF: 0,       AS_WF: 100.17,  PE1_WF: 0,       PT_WF: 45.54,   PE2_WF: 72.89,   ER_WF: 0,      SC_PS: '2025-01-20', SC_PF: '2025-01-31', SC_PP: 100,   SC_AS: '2025-01-23', SC_AF: '2025-01-31', SC_AP: 100, FB_PS: undefined,    FB_PF: undefined,    FB_PP: 0,     FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: '2025-02-03', AS_PF: '2025-03-18', AS_PP: 21.9, AS_AS: undefined,    AS_AF: undefined,    AS_AP: 0,    PE1_PS: undefined,    PE1_PF: undefined,    PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: '2025-04-11', PT_PF: '2025-05-08', PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: '2025-05-09', PE2_PF: '2025-06-18', PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: undefined,    ER_PF: undefined,    ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'LQ',      ZONE: 'Living Quarter', MOD: 'Level 2',             LEVEL: 'Level 2',             NAME: 'Deck Block',             G_EREC: 'LD210', G_2PE: 'LD210', G_1PE: 'LV241', EREC: '',      PE2: '',      PE1: '',      PBLK: '',      BLK: 'LV241', L: '14.3', B: '6.7',  H: '0.6',  YARD: 'HHI',   SC_WF: 32,     FB_WF: 0,       AS_WF: 100.17,  PE1_WF: 0,       PT_WF: 37.26,   PE2_WF: 72.89,   ER_WF: 0,      SC_PS: '2025-01-20', SC_PF: '2025-01-31', SC_PP: 100,   SC_AS: '2025-01-21', SC_AF: '2025-01-31', SC_AP: 100, FB_PS: undefined,    FB_PF: undefined,    FB_PP: 0,     FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: '2025-02-03', AS_PF: '2025-03-18', AS_PP: 21.9, AS_AS: undefined,    AS_AF: undefined,    AS_AP: 0,    PE1_PS: undefined,    PE1_PF: undefined,    PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: '2025-04-18', PT_PF: '2025-05-15', PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: '2025-05-16', PE2_PF: '2025-06-13', PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: undefined,    ER_PF: undefined,    ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'LQ',      ZONE: 'Living Quarter', MOD: 'Level 2',             LEVEL: 'Level 2',             NAME: 'Deck Block',             G_EREC: 'LD210', G_2PE: 'LD210', G_1PE: 'LV250', EREC: '',      PE2: '',      PE1: '',      PBLK: '',      BLK: 'LV251', L: '19.2', B: '6.8',  H: '4.6',  YARD: 'HHI',   SC_WF: 31.3,   FB_WF: 0,       AS_WF: 125.21,  PE1_WF: 0,       PT_WF: 72.45,   PE2_WF: 91.12,   ER_WF: 0,      SC_PS: '2025-01-23', SC_PF: '2025-02-05', SC_PP: 100,   SC_AS: '2025-01-22', SC_AF: '2025-02-11', SC_AP: 100, FB_PS: undefined,    FB_PF: undefined,    FB_PP: 0,     FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: '2025-02-06', AS_PF: '2025-03-21', AS_PP: 15.3, AS_AS: undefined,    AS_AF: undefined,    AS_AP: 0,    PE1_PS: undefined,    PE1_PF: undefined,    PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: '2025-04-23', PT_PF: '2025-05-20', PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: '2025-05-21', PE2_PF: '2025-06-13', PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: undefined,    ER_PF: undefined,    ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'LQ',      ZONE: 'Living Quarter', MOD: 'Level 2',             LEVEL: 'Level 2',             NAME: 'Column & Brace',         G_EREC: 'LD210', G_2PE: 'LD210', G_1PE: 'LV250', EREC: '',      PE2: '',      PE1: '',      PBLK: '',      BLK: 'LC221', L: '8.5',  B: '6.1',  H: '3.9',  YARD: 'HHI',   SC_WF: 8,      FB_WF: 16,      AS_WF: 16,      PE1_WF: 0,       PT_WF: 0,       PE2_WF: 0,       ER_WF: 0,      SC_PS: '2024-12-24', SC_PF: '2025-01-13', SC_PP: 100,   SC_AS: '2024-12-21', SC_AF: '2025-01-01', SC_AP: 100, FB_PS: '2025-01-14', FB_PF: '2025-02-03', FB_PP: 100,   FB_AS: '2025-01-02', FB_AF: '2025-01-17', FB_AP: 100,  AS_PS: '2025-02-04', AS_PF: '2025-02-26', AS_PP: 38.8, AS_AS: '2025-01-07', AS_AF: '2025-01-31', AS_AP: 100,  PE1_PS: undefined,    PE1_PF: undefined,    PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: undefined,    PT_PF: undefined,    PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: undefined,    PE2_PF: undefined,    PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: undefined,    ER_PF: undefined,    ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'LQ',      ZONE: 'Living Quarter', MOD: 'Level 2',             LEVEL: 'Level 2',             NAME: 'Deck Block',             G_EREC: 'LD210', G_2PE: 'LD210', G_1PE: 'LV261', EREC: '',      PE2: '',      PE1: '',      PBLK: '',      BLK: 'LV261', L: '6.9',  B: '13.4', H: '0.6',  YARD: 'HHI',   SC_WF: 24,     FB_WF: 0,       AS_WF: 62.61,   PE1_WF: 0,       PT_WF: 72.45,   PE2_WF: 45.56,   ER_WF: 0,      SC_PS: '2025-01-23', SC_PF: '2025-02-05', SC_PP: 100,   SC_AS: '2025-02-11', SC_AF: undefined,    SC_AP: 40,  FB_PS: undefined,    FB_PF: undefined,    FB_PP: 0,     FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: '2025-02-06', AS_PF: '2025-03-21', AS_PP: 15.3, AS_AS: undefined,    AS_AF: undefined,    AS_AP: 0,    PE1_PS: undefined,    PE1_PF: undefined,    PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: '2025-04-23', PT_PF: '2025-05-20', PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: '2025-05-21', PE2_PF: '2025-06-13', PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: undefined,    ER_PF: undefined,    ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'LQ',      ZONE: 'Living Quarter', MOD: 'Level 3',             LEVEL: 'Level 3',             NAME: 'Deck Block',             G_EREC: 'LD310', G_2PE: 'LD310', G_1PE: 'LD310', EREC: 'LD310', PE2: 'LD310', PE1: 'LD310', PBLK: 'LD310', BLK: 'LD311', L: '16.5', B: '12',   H: '4.5',  YARD: 'HHI',   SC_WF: 125.21, FB_WF: 375.63,  AS_WF: 500.84,  PE1_WF: 956.73,  PT_WF: 1713.89, PE2_WF: 956.73,  ER_WF: 2073.7, SC_PS: '2024-11-20', SC_PF: '2024-11-28', SC_PP: 100,   SC_AS: '2024-11-19', SC_AF: '2024-11-27', SC_AP: 100, FB_PS: '2024-12-02', FB_PF: '2025-01-09', FB_PP: 100,   FB_AS: '2024-12-05', FB_AF: '2025-01-17', FB_AP: 100,  AS_PS: '2025-01-10', AS_PF: '2025-03-13', AS_PP: 41.2, AS_AS: '2025-01-24', AS_AF: undefined,    AS_AP: 50,   PE1_PS: '2025-03-14', PE1_PF: '2025-04-22', PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: '2025-04-23', PT_PF: '2025-05-20', PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: '2025-05-21', PE2_PF: '2025-06-27', PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: '2025-06-30', ER_PF: '2026-05-08', ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'LQ',      ZONE: 'Living Quarter', MOD: 'Level 3',             LEVEL: 'Level 3',             NAME: 'Deck Block',             G_EREC: 'LD310', G_2PE: 'LD310', G_1PE: 'LD310', EREC: '',      PE2: '',      PE1: '',      PBLK: '',      BLK: 'LD312', L: '19.9', B: '12.1', H: '4.5',  YARD: 'HHI',   SC_WF: 125.21, FB_WF: 375.63,  AS_WF: 500.84,  PE1_WF: 0,       PT_WF: 0,       PE2_WF: 0,       ER_WF: 0,      SC_PS: '2024-11-20', SC_PF: '2024-11-28', SC_PP: 100,   SC_AS: '2024-11-19', SC_AF: '2024-11-27', SC_AP: 100, FB_PS: '2024-12-02', FB_PF: '2025-01-09', FB_PP: 100,   FB_AS: '2024-12-05', FB_AF: '2025-01-17', FB_AP: 100,  AS_PS: '2025-01-10', AS_PF: '2025-03-13', AS_PP: 41.2, AS_AS: '2025-01-24', AS_AF: undefined,    AS_AP: 50,   PE1_PS: undefined,    PE1_PF: undefined,    PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: undefined,    PT_PF: undefined,    PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: undefined,    PE2_PF: undefined,    PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: undefined,    ER_PF: undefined,    ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'LQ',      ZONE: 'Living Quarter', MOD: 'Level 3',             LEVEL: 'Level 3',             NAME: 'Wall',                   G_EREC: 'LD310', G_2PE: 'LD310', G_1PE: 'LD310', EREC: '',      PE2: '',      PE1: '',      PBLK: '',      BLK: 'LW311', L: '34.3', B: '11.4', H: '4.3',  YARD: 'HHI',   SC_WF: 78.26,  FB_WF: 234.77,  AS_WF: 0,       PE1_WF: 939.09,  PT_WF: 0,       PE2_WF: 0,       ER_WF: 0,      SC_PS: '2024-12-16', SC_PF: '2025-01-07', SC_PP: 99.7,  SC_AS: '2024-11-13', SC_AF: '2024-12-04', SC_AP: 100, FB_PS: '2025-01-08', FB_PF: '2025-02-25', FB_PP: 71.6,  FB_AS: '2025-01-11', FB_AF: undefined,    FB_AP: 30,   AS_PS: undefined,    AS_PF: undefined,    AS_PP: 0,    AS_AS: undefined,    AS_AF: undefined,    AS_AP: 0,    PE1_PS: '2025-02-26', PE1_PF: '2025-03-13', PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: undefined,    PT_PF: undefined,    PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: undefined,    PE2_PF: undefined,    PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: undefined,    ER_PF: undefined,    ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'LQ',      ZONE: 'Living Quarter', MOD: 'Level 3',             LEVEL: 'Level 3',             NAME: 'Deck Block',             G_EREC: 'LD310', G_2PE: 'LD310', G_1PE: 'LD320', EREC: '',      PE2: '',      PE1: 'LD320', PBLK: 'LD320', BLK: 'LD321', L: '18.8', B: '12.4', H: '4.6',  YARD: 'HHI',   SC_WF: 125.21, FB_WF: 375.63,  AS_WF: 500.84,  PE1_WF: 546.7,   PT_WF: 869.37,  PE2_WF: 546.7,   ER_WF: 0,      SC_PS: '2024-11-20', SC_PF: '2024-11-28', SC_PP: 100,   SC_AS: '2024-11-19', SC_AF: '2024-11-27', SC_AP: 100, FB_PS: '2024-12-02', FB_PF: '2025-01-09', FB_PP: 100,   FB_AS: '2024-12-05', FB_AF: '2025-01-11', FB_AP: 100,  AS_PS: '2025-01-10', AS_PF: '2025-03-13', AS_PP: 41.2, AS_AS: '2025-01-23', AS_AF: undefined,    AS_AP: 50,   PE1_PS: '2025-03-14', PE1_PF: '2025-04-22', PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: '2025-04-23', PT_PF: '2025-05-20', PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: '2025-05-21', PE2_PF: '2025-06-27', PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: undefined,    ER_PF: undefined,    ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'LQ',      ZONE: 'Living Quarter', MOD: 'Level 3',             LEVEL: 'Level 3',             NAME: 'Deck Block',             G_EREC: 'LD310', G_2PE: 'LD310', G_1PE: 'LD320', EREC: '',      PE2: '',      PE1: '',      PBLK: '',      BLK: 'LW321', L: '17',   B: '11.5', H: '4.3',  YARD: 'HHI',   SC_WF: 62.61,  FB_WF: 187.82,  AS_WF: 0,       PE1_WF: 751.26,  PT_WF: 0,       PE2_WF: 0,       ER_WF: 0,      SC_PS: '2024-12-16', SC_PF: '2025-01-07', SC_PP: 100,   SC_AS: '2024-11-13', SC_AF: '2024-12-04', SC_AP: 100, FB_PS: '2025-01-08', FB_PF: '2025-02-25', FB_PP: 71.6,  FB_AS: '2025-01-11', FB_AF: undefined,    FB_AP: 30,   AS_PS: undefined,    AS_PF: undefined,    AS_PP: 0,    AS_AS: undefined,    AS_AF: undefined,    AS_AP: 0,    PE1_PS: '2025-02-26', PE1_PF: '2025-03-13', PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: undefined,    PT_PF: undefined,    PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: undefined,    PE2_PF: undefined,    PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: undefined,    ER_PF: undefined,    ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'LQ',      ZONE: 'Living Quarter', MOD: 'Level 3',             LEVEL: 'Level 3',             NAME: 'Deck Block',             G_EREC: 'LD310', G_2PE: 'LD310', G_1PE: 'LV311', EREC: '',      PE2: '',      PE1: '',      PBLK: '',      BLK: 'LV311', L: '3.6',  B: '7.8',  H: '2',    YARD: 'HHI',   SC_WF: 16,     FB_WF: 0,       AS_WF: 40,      PE1_WF: 0,       PT_WF: 72.45,   PE2_WF: 27.34,   ER_WF: 0,      SC_PS: '2025-02-04', SC_PF: '2025-02-12', SC_PP: 100,   SC_AS: '2025-02-11', SC_AF: undefined,    SC_AP: 80,  FB_PS: undefined,    FB_PF: undefined,    FB_PP: 0,     FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: '2025-02-13', AS_PF: '2025-03-28', AS_PP: 4.4,  AS_AS: undefined,    AS_AF: undefined,    AS_AP: 0,    PE1_PS: undefined,    PE1_PF: undefined,    PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: '2025-04-30', PT_PF: '2025-05-27', PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: '2025-05-28', PE2_PF: '2025-06-27', PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: undefined,    ER_PF: undefined,    ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'LQ',      ZONE: 'Living Quarter', MOD: 'Level 3',             LEVEL: 'Level 3',             NAME: 'Deck Block',             G_EREC: 'LD310', G_2PE: 'LD310', G_1PE: 'LV321', EREC: '',      PE2: '',      PE1: '',      PBLK: '',      BLK: 'LV321', L: '8',    B: '4.3',  H: '4.5',  YARD: 'HHI',   SC_WF: 16,     FB_WF: 0,       AS_WF: 50.08,   PE1_WF: 0,       PT_WF: 16,      PE2_WF: 36.45,   ER_WF: 0,      SC_PS: '2025-02-04', SC_PF: '2025-02-12', SC_PP: 100,   SC_AS: '2025-02-11', SC_AF: '2025-02-15', SC_AP: 100, FB_PS: undefined,    FB_PF: undefined,    FB_PP: 0,     FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: '2025-02-13', AS_PF: '2025-03-28', AS_PP: 4.4,  AS_AS: undefined,    AS_AF: undefined,    AS_AP: 0,    PE1_PS: undefined,    PE1_PF: undefined,    PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: '2025-04-30', PT_PF: '2025-05-27', PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: '2025-05-28', PE2_PF: '2025-06-27', PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: undefined,    ER_PF: undefined,    ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'LQ',      ZONE: 'Living Quarter', MOD: 'Level 3',             LEVEL: 'Level 3',             NAME: 'Deck Block',             G_EREC: 'LD310', G_2PE: 'LD310', G_1PE: 'LV331', EREC: '',      PE2: '',      PE1: '',      PBLK: '',      BLK: 'LV331', L: '9.8',  B: '4.5',  H: '4.9',  YARD: 'HHI',   SC_WF: 32,     FB_WF: 0,       AS_WF: 100.17,  PE1_WF: 0,       PT_WF: 31.05,   PE2_WF: 72.89,   ER_WF: 0,      SC_PS: '2025-02-06', SC_PF: '2025-02-14', SC_PP: 100,   SC_AS: '2025-02-01', SC_AF: '2025-02-12', SC_AP: 100, FB_PS: undefined,    FB_PF: undefined,    FB_PP: 0,     FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: '2025-02-17', AS_PF: '2025-04-01', AS_PP: 0,    AS_AS: undefined,    AS_AF: undefined,    AS_AP: 0,    PE1_PS: undefined,    PE1_PF: undefined,    PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: '2025-05-07', PT_PF: '2025-05-29', PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: '2025-05-30', PE2_PF: '2025-06-27', PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: undefined,    ER_PF: undefined,    ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'LQ',      ZONE: 'Living Quarter', MOD: 'Level 3',             LEVEL: 'Level 3',             NAME: 'Deck Block',             G_EREC: 'LD310', G_2PE: 'LD310', G_1PE: 'LV341', EREC: '',      PE2: '',      PE1: '',      PBLK: '',      BLK: 'LV341', L: '3.6',  B: '12.9', H: '0.6',  YARD: 'HHI',   SC_WF: 24,     FB_WF: 0,       AS_WF: 87.65,   PE1_WF: 0,       PT_WF: 37.26,   PE2_WF: 63.78,   ER_WF: 0,      SC_PS: '2025-02-06', SC_PF: '2025-02-14', SC_PP: 100,   SC_AS: '2025-02-01', SC_AF: '2025-02-12', SC_AP: 100, FB_PS: undefined,    FB_PF: undefined,    FB_PP: 0,     FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: '2025-02-17', AS_PF: '2025-04-01', AS_PP: 0,    AS_AS: undefined,    AS_AF: undefined,    AS_AP: 0,    PE1_PS: undefined,    PE1_PF: undefined,    PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: '2025-05-07', PT_PF: '2025-05-29', PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: '2025-05-30', PE2_PF: '2025-06-27', PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: undefined,    ER_PF: undefined,    ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'LQ',      ZONE: 'Living Quarter', MOD: 'Level 4',             LEVEL: 'Level 4',             NAME: 'Deck Block',             G_EREC: 'LD410', G_2PE: 'LD410', G_1PE: 'LD410', EREC: 'LD410', PE2: 'LD410', PE1: 'LD410', PBLK: 'LD410', BLK: 'LD411', L: '17.2', B: '12.3', H: '5.4',  YARD: 'HHI',   SC_WF: 125.21, FB_WF: 375.63,  AS_WF: 500.84,  PE1_WF: 956.73,  PT_WF: 1713.89, PE2_WF: 956.73,  ER_WF: 2073.7, SC_PS: '2024-11-29', SC_PF: '2024-12-09', SC_PP: 99.8,  SC_AS: '2024-11-25', SC_AF: '2024-12-13', SC_AP: 100, FB_PS: '2024-12-10', FB_PF: '2025-01-20', FB_PP: 100,   FB_AS: '2024-12-07', FB_AF: '2025-01-31', FB_AP: 100,  AS_PS: '2025-01-21', AS_PF: '2025-03-10', AS_PP: 35,   AS_AS: undefined,    AS_AF: undefined,    AS_AP: 0,    PE1_PS: '2025-03-11', PE1_PF: '2025-04-17', PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: '2025-04-18', PT_PF: '2025-05-15', PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: '2025-05-16', PE2_PF: '2025-07-08', PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: '2025-07-09', ER_PF: '2026-05-08', ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'LQ',      ZONE: 'Living Quarter', MOD: 'Level 4',             LEVEL: 'Level 4',             NAME: 'Deck Block',             G_EREC: 'LD410', G_2PE: 'LD410', G_1PE: 'LD410', EREC: '',      PE2: '',      PE1: '',      PBLK: '',      BLK: 'LD412', L: '20.4', B: '12.2', H: '4.4',  YARD: 'HHI',   SC_WF: 125.21, FB_WF: 375.63,  AS_WF: 500.84,  PE1_WF: 0,       PT_WF: 0,       PE2_WF: 0,       ER_WF: 0,      SC_PS: '2024-11-29', SC_PF: '2024-12-09', SC_PP: 99.8,  SC_AS: '2024-11-25', SC_AF: '2024-12-13', SC_AP: 100, FB_PS: '2024-12-10', FB_PF: '2025-01-20', FB_PP: 100,   FB_AS: '2024-12-07', FB_AF: '2025-01-31', FB_AP: 100,  AS_PS: '2025-01-21', AS_PF: '2025-03-10', AS_PP: 35,   AS_AS: undefined,    AS_AF: undefined,    AS_AP: 0,    PE1_PS: undefined,    PE1_PF: undefined,    PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: undefined,    PT_PF: undefined,    PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: undefined,    PE2_PF: undefined,    PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: undefined,    ER_PF: undefined,    ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'LQ',      ZONE: 'Living Quarter', MOD: 'Level 4',             LEVEL: 'Level 4',             NAME: 'Deck Block',             G_EREC: 'LD410', G_2PE: 'LD410', G_1PE: 'LD410', EREC: '',      PE2: '',      PE1: '',      PBLK: '',      BLK: 'LW411', L: '34.3', B: '11.4', H: '4.1',  YARD: 'HHI',   SC_WF: 78.26,  FB_WF: 234.77,  AS_WF: 0,       PE1_WF: 1878.18, PT_WF: 0,       PE2_WF: 0,       ER_WF: 0,      SC_PS: '2024-12-18', SC_PF: '2025-02-06', SC_PP: 99.7,  SC_AS: '2024-11-13', SC_AF: '2024-12-10', SC_AP: 100, FB_PS: '2025-02-07', FB_PF: '2025-03-10', FB_PP: 19.1,  FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: undefined,    AS_PF: undefined,    AS_PP: 0,    AS_AS: undefined,    AS_AF: undefined,    AS_AP: 0,    PE1_PS: '2025-03-11', PE1_PF: '2025-04-16', PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: undefined,    PT_PF: undefined,    PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: undefined,    PE2_PF: undefined,    PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: undefined,    ER_PF: undefined,    ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'LQ',      ZONE: 'Living Quarter', MOD: 'Level 4',             LEVEL: 'Level 4',             NAME: 'Deck Block',             G_EREC: 'LD410', G_2PE: 'LD410', G_1PE: 'LD420', EREC: '',      PE2: '',      PE1: 'LD420', PBLK: 'LD420', BLK: 'LD421', L: '17.5', B: '12.9', H: '5.4',  YARD: 'HHI',   SC_WF: 125.21, FB_WF: 375.63,  AS_WF: 500.84,  PE1_WF: 546.7,   PT_WF: 869.37,  PE2_WF: 546.7,   ER_WF: 0,      SC_PS: '2024-11-29', SC_PF: '2024-12-09', SC_PP: 99.8,  SC_AS: '2024-11-25', SC_AF: '2024-12-13', SC_AP: 100, FB_PS: '2024-12-10', FB_PF: '2025-01-20', FB_PP: 100,   FB_AS: '2024-12-07', FB_AF: '2025-01-31', FB_AP: 100,  AS_PS: '2025-01-21', AS_PF: '2025-03-24', AS_PP: 26.7, AS_AS: undefined,    AS_AF: undefined,    AS_AP: 0,    PE1_PS: '2025-03-25', PE1_PF: '2025-05-06', PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: '2025-05-07', PT_PF: '2025-05-29', PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: '2025-05-30', PE2_PF: '2025-07-08', PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: undefined,    ER_PF: undefined,    ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'LQ',      ZONE: 'Living Quarter', MOD: 'Level 4',             LEVEL: 'Level 4',             NAME: 'Wall',                   G_EREC: 'LD410', G_2PE: 'LD410', G_1PE: 'LD420', EREC: '',      PE2: '',      PE1: '',      PBLK: '',      BLK: 'LW421', L: '17',   B: '11.4', H: '4.3',  YARD: 'HHI',   SC_WF: 62.61,  FB_WF: 187.82,  AS_WF: 0,       PE1_WF: 1001.68, PT_WF: 0,       PE2_WF: 0,       ER_WF: 0,      SC_PS: '2024-12-23', SC_PF: '2025-01-14', SC_PP: 100,   SC_AS: '2024-11-13', SC_AF: '2024-12-10', SC_AP: 100, FB_PS: '2025-01-15', FB_PF: '2025-03-06', FB_PP: 46.5,  FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: undefined,    AS_PF: undefined,    AS_PP: 0,    AS_AS: undefined,    AS_AF: undefined,    AS_AP: 0,    PE1_PS: '2025-03-07', PE1_PF: '2025-03-24', PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: undefined,    PT_PF: undefined,    PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: undefined,    PE2_PF: undefined,    PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: undefined,    ER_PF: undefined,    ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'LQ',      ZONE: 'Living Quarter', MOD: 'Level 4',             LEVEL: 'Level 4',             NAME: 'Deck Block',             G_EREC: 'LD410', G_2PE: 'LD410', G_1PE: 'LV411', EREC: '',      PE2: '',      PE1: '',      PBLK: '',      BLK: 'LV411', L: '5.4',  B: '15.8', H: '1.3',  YARD: 'HHI',   SC_WF: 24,     FB_WF: 0,       AS_WF: 87.65,   PE1_WF: 0,       PT_WF: 72.45,   PE2_WF: 63.78,   ER_WF: 0,      SC_PS: '2025-02-07', SC_PF: '2025-02-17', SC_PP: 81.4,  SC_AS: '2025-02-03', SC_AF: undefined,    SC_AP: 86,  FB_PS: undefined,    FB_PF: undefined,    FB_PP: 0,     FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: '2025-02-18', AS_PF: '2025-04-02', AS_PP: 0,    AS_AS: undefined,    AS_AF: undefined,    AS_AP: 0,    PE1_PS: undefined,    PE1_PF: undefined,    PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: '2025-05-07', PT_PF: '2025-05-29', PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: '2025-05-30', PE2_PF: '2025-07-08', PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: undefined,    ER_PF: undefined,    ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'LQ',      ZONE: 'Living Quarter', MOD: 'Level 4',             LEVEL: 'Level 4',             NAME: 'Deck Block',             G_EREC: 'LD410', G_2PE: 'LD410', G_1PE: 'LV421', EREC: '',      PE2: '',      PE1: '',      PBLK: '',      BLK: 'LV421', L: '11.2', B: '4.4',  H: '0.7',  YARD: 'HHI',   SC_WF: 24,     FB_WF: 0,       AS_WF: 62.61,   PE1_WF: 0,       PT_WF: 24,      PE2_WF: 45.56,   ER_WF: 0,      SC_PS: '2025-02-07', SC_PF: '2025-02-17', SC_PP: 81.4,  SC_AS: undefined,    SC_AF: undefined,    SC_AP: 0,   FB_PS: undefined,    FB_PF: undefined,    FB_PP: 0,     FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: '2025-02-18', AS_PF: '2025-04-02', AS_PP: 0,    AS_AS: undefined,    AS_AF: undefined,    AS_AP: 0,    PE1_PS: undefined,    PE1_PF: undefined,    PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: '2025-05-07', PT_PF: '2025-05-29', PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: '2025-05-30', PE2_PF: '2025-07-08', PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: undefined,    ER_PF: undefined,    ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'LQ',      ZONE: 'Living Quarter', MOD: 'Level 4',             LEVEL: 'Level 4',             NAME: 'Deck Block',             G_EREC: 'LD410', G_2PE: 'LD410', G_1PE: 'LV431', EREC: '',      PE2: '',      PE1: '',      PBLK: '',      BLK: 'LV431', L: '9.8',  B: '4.5',  H: '4.6',  YARD: 'HHI',   SC_WF: 32,     FB_WF: 0,       AS_WF: 100.17,  PE1_WF: 0,       PT_WF: 31.05,   PE2_WF: 72.89,   ER_WF: 0,      SC_PS: '2025-02-07', SC_PF: '2025-02-17', SC_PP: 81.4,  SC_AS: '2025-01-16', SC_AF: '2025-01-31', SC_AP: 100, FB_PS: undefined,    FB_PF: undefined,    FB_PP: 0,     FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: '2025-02-18', AS_PF: '2025-04-02', AS_PP: 0,    AS_AS: undefined,    AS_AF: undefined,    AS_AP: 0,    PE1_PS: undefined,    PE1_PF: undefined,    PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: '2025-05-07', PT_PF: '2025-05-29', PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: '2025-05-30', PE2_PF: '2025-07-08', PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: undefined,    ER_PF: undefined,    ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'LQ',      ZONE: 'Living Quarter', MOD: 'Level 4',             LEVEL: 'Level 4',             NAME: 'Deck Block',             G_EREC: 'LD410', G_2PE: 'LD410', G_1PE: 'LD511', EREC: '',      PE2: '',      PE1: '',      PBLK: '',      BLK: 'LD511', L: '11.1', B: '10.3', H: '4.4',  YARD: 'HHI',   SC_WF: 78.26,  FB_WF: 234.77,  AS_WF: 313.03,  PE1_WF: 0,       PT_WF: 72.45,   PE2_WF: 227.79,  ER_WF: 0,      SC_PS: '2024-12-13', SC_PF: '2024-12-23', SC_PP: 100,   SC_AS: '2025-01-03', SC_AF: '2025-01-14', SC_AP: 100, FB_PS: '2024-12-24', FB_PF: '2025-02-06', FB_PP: 100,   FB_AS: '2025-01-15', FB_AF: '2025-01-31', FB_AP: 100,  AS_PS: '2025-02-07', AS_PF: '2025-03-24', AS_PP: 13.1, AS_AS: undefined,    AS_AF: undefined,    AS_AP: 0,    PE1_PS: undefined,    PE1_PF: undefined,    PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: '2025-04-18', PT_PF: '2025-05-15', PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: '2025-05-16', PE2_PF: '2025-07-04', PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: undefined,    ER_PF: undefined,    ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'LQ',      ZONE: 'Living Quarter', MOD: 'Level 4',             LEVEL: 'Level 4',             NAME: 'Deck Block',             G_EREC: 'LN4A0', G_2PE: 'LN4A0', G_1PE: 'LN4A0', EREC: 'LN4A0', PE2: '',      PE1: '',      PBLK: 'LN4A1', BLK: 'LN4A1', L: '1',    B: '1.2',  H: '1.1',  YARD: 'HHI',   SC_WF: 0,      FB_WF: 0,       AS_WF: 0,       PE1_WF: 0,       PT_WF: 0,       PE2_WF: 0,       ER_WF: 0,      SC_PS: undefined,    SC_PF: undefined,    SC_PP: 0,     SC_AS: undefined,    SC_AF: undefined,    SC_AP: 0,   FB_PS: undefined,    FB_PF: undefined,    FB_PP: 0,     FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: undefined,    AS_PF: undefined,    AS_PP: 0,    AS_AS: undefined,    AS_AF: undefined,    AS_AP: 0,    PE1_PS: undefined,    PE1_PF: undefined,    PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: undefined,    PT_PF: undefined,    PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: undefined,    PE2_PF: undefined,    PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: undefined,    ER_PF: undefined,    ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'LQ',      ZONE: 'Living Quarter', MOD: 'Level 4',             LEVEL: 'Level 4',             NAME: 'Deck Block',             G_EREC: 'LN4B0', G_2PE: 'LN4B0', G_1PE: 'LN4B0', EREC: 'LN4B0', PE2: '',      PE1: '',      PBLK: 'LN4B1', BLK: 'LN4B1', L: '1',    B: '1.2',  H: '1.1',  YARD: 'HHI',   SC_WF: 0,      FB_WF: 0,       AS_WF: 0,       PE1_WF: 0,       PT_WF: 0,       PE2_WF: 0,       ER_WF: 0,      SC_PS: undefined,    SC_PF: undefined,    SC_PP: 0,     SC_AS: undefined,    SC_AF: undefined,    SC_AP: 0,   FB_PS: undefined,    FB_PF: undefined,    FB_PP: 0,     FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: undefined,    AS_PF: undefined,    AS_PP: 0,    AS_AS: undefined,    AS_AF: undefined,    AS_AP: 0,    PE1_PS: undefined,    PE1_PF: undefined,    PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: undefined,    PT_PF: undefined,    PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: undefined,    PE2_PF: undefined,    PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: undefined,    ER_PF: undefined,    ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'LQ',      ZONE: 'Living Quarter', MOD: 'Level 4',             LEVEL: 'Level 4',             NAME: 'Deck Block',             G_EREC: 'LN4C0', G_2PE: 'LN4C0', G_1PE: 'LN4C0', EREC: 'LN4C0', PE2: '',      PE1: '',      PBLK: 'LN4C1', BLK: 'LN4C1', L: '1',    B: '1.2',  H: '1.1',  YARD: 'HHI',   SC_WF: 0,      FB_WF: 0,       AS_WF: 0,       PE1_WF: 0,       PT_WF: 0,       PE2_WF: 0,       ER_WF: 0,      SC_PS: undefined,    SC_PF: undefined,    SC_PP: 0,     SC_AS: undefined,    SC_AF: undefined,    SC_AP: 0,   FB_PS: undefined,    FB_PF: undefined,    FB_PP: 0,     FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: undefined,    AS_PF: undefined,    AS_PP: 0,    AS_AS: undefined,    AS_AF: undefined,    AS_AP: 0,    PE1_PS: undefined,    PE1_PF: undefined,    PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: undefined,    PT_PF: undefined,    PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: undefined,    PE2_PF: undefined,    PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: undefined,    ER_PF: undefined,    ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'LQ',      ZONE: 'Living Quarter', MOD: 'Level 4',             LEVEL: 'Level 4',             NAME: 'Heli Deck Support',      G_EREC: 'LD410', G_2PE: 'LD410', G_1PE: 'LHS11', EREC: 'LHS10', PE2: '',      PE1: '',      PBLK: 'LHS10', BLK: 'LHS11', L: '8.8',  B: '12.4', H: '5.7',  YARD: 'HHI',   SC_WF: 140.86, FB_WF: 422.59,  AS_WF: 563.45,  PE1_WF: 0,       PT_WF: 429.88,  PE2_WF: 410.03,  ER_WF: 2073.7, SC_PS: '2025-05-02', SC_PF: '2025-05-14', SC_PP: 0,     SC_AS: undefined,    SC_AF: undefined,    SC_AP: 0,   FB_PS: '2025-05-15', FB_PF: '2025-06-23', FB_PP: 0,     FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: '2025-06-24', AS_PF: '2025-08-19', AS_PP: 0,    AS_AS: undefined,    AS_AF: undefined,    AS_AP: 0,    PE1_PS: undefined,    PE1_PF: undefined,    PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: '2025-09-04', PT_PF: '2025-09-26', PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: '2025-09-29', PE2_PF: '2025-10-21', PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: '2025-10-22', ER_PF: '2026-05-11', ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: 'Y', AREA: 'LQ',      ZONE: 'Living Quarter', MOD: 'Level 4',             LEVEL: 'Level 4',             NAME: 'LQ-Stair',               G_EREC: 'LD410', G_2PE: 'LD410', G_1PE: 'LV521', EREC: 'LV520', PE2: '',      PE1: '',      PBLK: 'LV520', BLK: 'LV521', L: '3.4',  B: '3.1',  H: '5',    YARD: 'HHI',   SC_WF: 16,     FB_WF: 0,       AS_WF: 40,      PE1_WF: 0,       PT_WF: 87.76,   PE2_WF: 27.34,   ER_WF: 0,      SC_PS: '2026-01-21', SC_PF: '2026-01-29', SC_PP: 0,     SC_AS: undefined,    SC_AF: undefined,    SC_AP: 0,   FB_PS: undefined,    FB_PF: undefined,    FB_PP: 0,     FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: '2026-01-30', AS_PF: '2026-03-19', AS_PP: 0,    AS_AS: undefined,    AS_AF: undefined,    AS_AP: 0,    PE1_PS: undefined,    PE1_PF: undefined,    PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: '2026-04-08', PT_PF: '2026-04-30', PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: '2026-05-04', PE2_PF: '2026-05-27', PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: undefined,    ER_PF: undefined,    ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: 'Y', AREA: 'LQ',      ZONE: 'Living Quarter', MOD: 'Level 4',             LEVEL: 'Level 4',             NAME: 'LQ-Access P/F',          G_EREC: 'LD410', G_2PE: 'LD410', G_1PE: 'LV531', EREC: 'LV530', PE2: '',      PE1: '',      PBLK: 'LV530', BLK: 'LV531', L: '5.1',  B: '4.5',  H: '4.3',  YARD: 'HHI',   SC_WF: 16,     FB_WF: 0,       AS_WF: 40,      PE1_WF: 0,       PT_WF: 87.76,   PE2_WF: 0,       ER_WF: 0,      SC_PS: '2026-01-21', SC_PF: '2026-01-29', SC_PP: 0,     SC_AS: undefined,    SC_AF: undefined,    SC_AP: 0,   FB_PS: undefined,    FB_PF: undefined,    FB_PP: 0,     FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: '2026-01-30', AS_PF: '2026-03-19', AS_PP: 0,    AS_AS: undefined,    AS_AF: undefined,    AS_AP: 0,    PE1_PS: undefined,    PE1_PF: undefined,    PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: '2026-04-08', PT_PF: '2026-04-30', PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: undefined,    PE2_PF: undefined,    PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: undefined,    ER_PF: undefined,    ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: 'Y', AREA: 'LQ',      ZONE: 'HOUSE',    MOD: 'Stair House',         LEVEL: 'Stair House',         NAME: 'LQ-Stair House',         G_EREC: 'LV510', G_2PE: 'LV510', G_1PE: 'LV511', EREC: 'LV510', PE2: '',      PE1: '',      PBLK: 'LV510', BLK: 'LV511', L: '10.5', B: '6.2',  H: '4.4',  YARD: 'HHI',   SC_WF: 28.17,  FB_WF: 0,       AS_WF: 112.69,  PE1_WF: 0,       PT_WF: 24,      PE2_WF: 82.01,   ER_WF: 0,      SC_PS: '2025-02-07', SC_PF: '2025-02-17', SC_PP: 81.4,  SC_AS: '2025-02-04', SC_AF: undefined,    SC_AP: 75,  FB_PS: undefined,    FB_PF: undefined,    FB_PP: 0,     FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: '2025-02-18', AS_PF: '2025-04-02', AS_PP: 0,    AS_AS: undefined,    AS_AF: undefined,    AS_AP: 0,    PE1_PS: undefined,    PE1_PF: undefined,    PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: '2025-05-07', PT_PF: '2025-05-29', PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: '2025-05-30', PE2_PF: '2025-07-08', PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: undefined,    ER_PF: undefined,    ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: 'Y', AREA: 'LQ',      ZONE: 'HELI',      MOD: 'Heli Deck',           LEVEL: 'Heli Deck',           NAME: 'Heli Deck',              G_EREC: 'LH110', G_2PE: 'LH110', G_1PE: 'LH111', EREC: 'LH110', PE2: '',      PE1: '',      PBLK: 'LH110', BLK: 'LH111', L: '24.4', B: '24.9', H: '6.5',  YARD: 'HHI',   SC_WF: 0,      FB_WF: 0,       AS_WF: 0,       PE1_WF: 0,       PT_WF: 0,       PE2_WF: 0,       ER_WF: 0,      SC_PS: undefined,    SC_PF: undefined,    SC_PP: 0,     SC_AS: undefined,    SC_AF: undefined,    SC_AP: 0,   FB_PS: undefined,    FB_PF: undefined,    FB_PP: 0,     FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: undefined,    AS_PF: undefined,    AS_PP: 0,    AS_AS: undefined,    AS_AF: undefined,    AS_AP: 0,    PE1_PS: undefined,    PE1_PF: undefined,    PE1_PP: 0,    PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0,  PT_PS: undefined,    PT_PF: undefined,    PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: undefined,    PE2_PF: undefined,    PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: undefined,    ER_PF: undefined,    ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      ],
    
    // Outfitting
    SQL2: [
      { SN: 1,   ITEM: 'COM',   AREA: 'HULL',    ZONE: 'NODE',       MOD: 'WN100',       LEVEL: 'NW, Node',            G_EREC: 'WN112', G_2PE: 'WN112', G_1PE: 'WN111', EREC: 'WN112', PE2: '',      PE1: 'WN111', BLK: 'WN110', YARD: 'HHI',   PS_BK: 'WN110', PS_TOT: 167,  PS_WO: 153, PS_SO: 24, PS_IST: 0, EQ_TOT: 3,  EQ_PO: 3,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 2,   ITEM: 'COM',   AREA: 'HULL',    ZONE: 'NODE',       MOD: 'WN100',       LEVEL: 'NW, Node',            G_EREC: 'WN112', G_2PE: 'WN112', G_1PE: 'WN111', EREC: '',      PE2: '',      PE1: '',      BLK: 'WNSA0', YARD: 'HHI',   PS_BK: 'WNSA0', PS_TOT: 0,     PS_WO: 0,    PS_SO: 0,   PS_IST: 0,  EQ_TOT: 0,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 3,   ITEM: 'COM',   AREA: 'HULL',    ZONE: 'NODE',       MOD: 'WN100',       LEVEL: 'NW, Node',            G_EREC: 'WN112', G_2PE: 'WN112', G_1PE: 'WN121', EREC: '',      PE2: '',      PE1: 'WN121', BLK: 'WN120', YARD: 'HHI',   PS_BK: 'WN120', PS_TOT: 128,  PS_WO: 1,   PS_SO: 0,  PS_IST: 0, EQ_TOT: 0,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 4,   ITEM: 'COM',   AREA: 'HULL',    ZONE: 'NODE',       MOD: 'WN100',       LEVEL: 'NW, Node',            G_EREC: 'WN112', G_2PE: 'WN112', G_1PE: 'WN121', EREC: '',      PE2: '',      PE1: '',      BLK: 'WN130', YARD: 'HHI',   PS_BK: 'WN130', PS_TOT: 3,    PS_WO: 3,   PS_SO: 0,  PS_IST: 0, EQ_TOT: 0,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 5,   ITEM: 'COM',   AREA: 'HULL',    ZONE: 'NODE',       MOD: 'WN100',       LEVEL: 'NW, Node',            G_EREC: 'WN112', G_2PE: 'WN112', G_1PE: 'WN121', EREC: '',      PE2: '',      PE1: '',      BLK: 'WN140', YARD: 'HHI',   PS_BK: 'WN140', PS_TOT: 13,   PS_WO: 10,  PS_SO: 0,  PS_IST: 0, EQ_TOT: 0,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 6,   ITEM: 'COM',   AREA: 'HULL',    ZONE: 'NODE',       MOD: 'WN100',       LEVEL: 'NW, Node',            G_EREC: 'WN112', G_2PE: 'WN112', G_1PE: 'WN121', EREC: '',      PE2: '',      PE1: '',      BLK: 'WN150', YARD: 'HHI',   PS_BK: 'WN150', PS_TOT: 7,    PS_WO: 5,   PS_SO: 0,  PS_IST: 0, EQ_TOT: 0,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 7,   ITEM: 'COM',   AREA: 'HULL',    ZONE: 'NODE',       MOD: 'WN100',       LEVEL: 'NW, Node',            G_EREC: 'WN112', G_2PE: 'WN112', G_1PE: 'WN121', EREC: '',      PE2: '',      PE1: '',      BLK: 'WNS10', YARD: 'HHI',   PS_BK: 'WNS10', PS_TOT: 0,     PS_WO: 0,    PS_SO: 0,   PS_IST: 0,  EQ_TOT: 0,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 8,   ITEM: 'COM',   AREA: 'HULL',    ZONE: 'NODE',       MOD: 'WN100',       LEVEL: 'NW, Node',            G_EREC: 'WN112', G_2PE: 'WN112', G_1PE: 'WNG10', EREC: '',      PE2: '',      PE1: '',      BLK: 'WNG10', YARD: 'HHI',   PS_BK: 'WNG10', PS_TOT: 0,     PS_WO: 0,    PS_SO: 0,   PS_IST: 0,  EQ_TOT: 0,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 9,   ITEM: 'COM',   AREA: 'HULL',    ZONE: 'NODE',       MOD: 'WN100',       LEVEL: 'NW, Node',            G_EREC: 'WN112', G_2PE: 'WN112', G_1PE: 'WNG20', EREC: '',      PE2: '',      PE1: '',      BLK: 'WNG20', YARD: 'HHI',   PS_BK: 'WNG20', PS_TOT: 10,   PS_WO: 0,    PS_SO: 0,   PS_IST: 0,  EQ_TOT: 0,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 10,  ITEM: 'COM',   AREA: 'HULL',    ZONE: 'NODE',       MOD: 'WN100',       LEVEL: 'NW, Node',            G_EREC: 'WN112', G_2PE: 'WN112', G_1PE: 'WNG30', EREC: '',      PE2: '',      PE1: '',      BLK: 'WNG30', YARD: 'HHI',   PS_BK: 'WNG30', PS_TOT: 12,   PS_WO: 0,    PS_SO: 0,   PS_IST: 0,  EQ_TOT: 0,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 11,  ITEM: 'COM',   AREA: 'HULL',    ZONE: 'NODE',       MOD: 'WN1D0',       LEVEL: 'NW, Node',            G_EREC: 'WN161', G_2PE: 'WN161', G_1PE: 'WN161', EREC: 'WN161', PE2: '',      PE1: 'WN161', BLK: 'WN160', YARD: 'HHI',   PS_BK: 'WN160', PS_TOT: 0,     PS_WO: 0,    PS_SO: 0,   PS_IST: 0,  EQ_TOT: 0,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 12,  ITEM: 'COM',   AREA: 'HULL',    ZONE: 'NODE',       MOD: 'WN1D0',       LEVEL: 'NW, Node',            G_EREC: 'WN161', G_2PE: 'WN161', G_1PE: 'WN161', EREC: '',      PE2: '',      PE1: '',      BLK: 'WN170', YARD: 'HHI',   PS_BK: 'WN170', PS_TOT: 0,     PS_WO: 0,    PS_SO: 0,   PS_IST: 0,  EQ_TOT: 0,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 13,  ITEM: 'COM',   AREA: 'HULL',    ZONE: 'NODE',       MOD: 'WN200',       LEVEL: 'SW, Node',            G_EREC: 'WN212', G_2PE: 'WN212', G_1PE: 'WN211', EREC: 'WN212', PE2: '',      PE1: 'WN211', BLK: 'WN210', YARD: 'HHI',   PS_BK: 'WN210', PS_TOT: 166,  PS_WO: 32,  PS_SO: 0,  PS_IST: 0, EQ_TOT: 5,  EQ_PO: 5,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 14,  ITEM: 'COM',   AREA: 'HULL',    ZONE: 'NODE',       MOD: 'WN200',       LEVEL: 'SW, Node',            G_EREC: 'WN212', G_2PE: 'WN212', G_1PE: 'WN211', EREC: '',      PE2: '',      PE1: '',      BLK: 'WNSB0', YARD: 'HHI',   PS_BK: 'WNSB0', PS_TOT: 0,     PS_WO: 0,    PS_SO: 0,   PS_IST: 0,  EQ_TOT: 0,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 15,  ITEM: 'COM',   AREA: 'HULL',    ZONE: 'NODE',       MOD: 'WN200',       LEVEL: 'SW, Node',            G_EREC: 'WN212', G_2PE: 'WN212', G_1PE: 'WN221', EREC: '',      PE2: '',      PE1: 'WN221', BLK: 'WN220', YARD: 'HHI',   PS_BK: 'WN220', PS_TOT: 139,  PS_WO: 0,    PS_SO: 0,   PS_IST: 0,  EQ_TOT: 0,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 16,  ITEM: 'COM',   AREA: 'HULL',    ZONE: 'NODE',       MOD: 'WN200',       LEVEL: 'SW, Node',            G_EREC: 'WN212', G_2PE: 'WN212', G_1PE: 'WN221', EREC: '',      PE2: '',      PE1: '',      BLK: 'WN230', YARD: 'HHI',   PS_BK: 'WN230', PS_TOT: 3,    PS_WO: 0,    PS_SO: 0,   PS_IST: 0,  EQ_TOT: 0,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 17,  ITEM: 'COM',   AREA: 'HULL',    ZONE: 'NODE',       MOD: 'WN200',       LEVEL: 'SW, Node',            G_EREC: 'WN212', G_2PE: 'WN212', G_1PE: 'WN221', EREC: '',      PE2: '',      PE1: '',      BLK: 'WN240', YARD: 'HHI',   PS_BK: 'WN240', PS_TOT: 13,   PS_WO: 0,    PS_SO: 0,   PS_IST: 0,  EQ_TOT: 0,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 18,  ITEM: 'COM',   AREA: 'HULL',    ZONE: 'NODE',       MOD: 'WN200',       LEVEL: 'SW, Node',            G_EREC: 'WN212', G_2PE: 'WN212', G_1PE: 'WN221', EREC: '',      PE2: '',      PE1: '',      BLK: 'WN250', YARD: 'HHI',   PS_BK: 'WN250', PS_TOT: 9,    PS_WO: 0,    PS_SO: 0,   PS_IST: 0,  EQ_TOT: 0,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 19,  ITEM: 'COM',   AREA: 'HULL',    ZONE: 'NODE',       MOD: 'WN200',       LEVEL: 'SW, Node',            G_EREC: 'WN212', G_2PE: 'WN212', G_1PE: 'WN221', EREC: '',      PE2: '',      PE1: '',      BLK: 'WNS20', YARD: 'HHI',   PS_BK: 'WNS20', PS_TOT: 0,     PS_WO: 0,    PS_SO: 0,   PS_IST: 0,  EQ_TOT: 0,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 20,  ITEM: 'COM',   AREA: 'HULL',    ZONE: 'NODE',       MOD: 'WN200',       LEVEL: 'SW, Node',            G_EREC: 'WN212', G_2PE: 'WN212', G_1PE: 'WNGA0', EREC: '',      PE2: '',      PE1: '',      BLK: 'WNGA0', YARD: 'HHI',   PS_BK: 'WNGA0', PS_TOT: 0,     PS_WO: 0,    PS_SO: 0,   PS_IST: 0,  EQ_TOT: 0,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 21,  ITEM: 'COM',   AREA: 'HULL',    ZONE: 'NODE',       MOD: 'WN200',       LEVEL: 'SW, Node',            G_EREC: 'WN212', G_2PE: 'WN212', G_1PE: 'WNGB0', EREC: '',      PE2: '',      PE1: '',      BLK: 'WNGB0', YARD: 'HHI',   PS_BK: 'WNGB0', PS_TOT: 23,   PS_WO: 0,    PS_SO: 0,   PS_IST: 0,  EQ_TOT: 0,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 22,  ITEM: 'COM',   AREA: 'HULL',    ZONE: 'NODE',       MOD: 'WN200',       LEVEL: 'SW, Node',            G_EREC: 'WN212', G_2PE: 'WN212', G_1PE: 'WNGC0', EREC: '',      PE2: '',      PE1: '',      BLK: 'WNGC0', YARD: 'HHI',   PS_BK: 'WNGC0', PS_TOT: 12,   PS_WO: 0,    PS_SO: 0,   PS_IST: 0,  EQ_TOT: 0,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 23,  ITEM: 'COM',   AREA: 'HULL',    ZONE: 'NODE',       MOD: 'WN2D0',       LEVEL: 'SW, Node',            G_EREC: 'WN261', G_2PE: 'WN261', G_1PE: 'WN261', EREC: 'WN261', PE2: '',      PE1: 'WN261', BLK: 'WN260', YARD: 'HHI',   PS_BK: 'WN260', PS_TOT: 0,     PS_WO: 0,    PS_SO: 0,   PS_IST: 0,  EQ_TOT: 0,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 24,  ITEM: 'COM',   AREA: 'HULL',    ZONE: 'NODE',       MOD: 'WN2D0',       LEVEL: 'SW, Node',            G_EREC: 'WN261', G_2PE: 'WN261', G_1PE: 'WN261', EREC: '',      PE2: '',      PE1: '',      BLK: 'WN270', YARD: 'HHI',   PS_BK: 'WN270', PS_TOT: 0,     PS_WO: 0,    PS_SO: 0,   PS_IST: 0,  EQ_TOT: 0,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 25,  ITEM: 'COM',   AREA: 'HULL',    ZONE: 'NODE',       MOD: 'EN100',       LEVEL: 'NE, Node',            G_EREC: 'EN112', G_2PE: 'EN112', G_1PE: 'EN111', EREC: 'EN112', PE2: 'EN112', PE1: 'EN111', BLK: 'EN110', YARD: 'HHI',   PS_BK: 'EN110', PS_TOT: 168,  PS_WO: 160, PS_SO: 32, PS_IST: 0, EQ_TOT: 5,  EQ_PO: 4,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 26,  ITEM: 'COM',   AREA: 'HULL',    ZONE: 'NODE',       MOD: 'EN100',       LEVEL: 'NE, Node',            G_EREC: 'EN112', G_2PE: 'EN112', G_1PE: 'EN111', EREC: '',      PE2: '',      PE1: '',      BLK: 'ENSA0', YARD: 'HHI',   PS_BK: 'ENSA0', PS_TOT: 0,     PS_WO: 0,    PS_SO: 0,   PS_IST: 0,  EQ_TOT: 0,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 27,  ITEM: 'COM',   AREA: 'HULL',    ZONE: 'NODE',       MOD: 'EN100',       LEVEL: 'NE, Node',            G_EREC: 'EN112', G_2PE: 'EN112', G_1PE: 'EN121', EREC: '',      PE2: '',      PE1: 'EN121', BLK: 'EN120', YARD: 'HHI',   PS_BK: 'EN120', PS_TOT: 143,  PS_WO: 133, PS_SO: 1,  PS_IST: 0, EQ_TOT: 0,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 28,  ITEM: 'COM',   AREA: 'HULL',    ZONE: 'NODE',       MOD: 'EN100',       LEVEL: 'NE, Node',            G_EREC: 'EN112', G_2PE: 'EN112', G_1PE: 'EN121', EREC: '',      PE2: '',      PE1: '',      BLK: 'EN130', YARD: 'HHI',   PS_BK: 'EN130', PS_TOT: 3,    PS_WO: 3,   PS_SO: 3,  PS_IST: 0, EQ_TOT: 0,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 29,  ITEM: 'COM',   AREA: 'HULL',    ZONE: 'NODE',       MOD: 'EN100',       LEVEL: 'NE, Node',            G_EREC: 'EN112', G_2PE: 'EN112', G_1PE: 'EN121', EREC: '',      PE2: '',      PE1: '',      BLK: 'EN140', YARD: 'HHI',   PS_BK: 'EN140', PS_TOT: 10,   PS_WO: 10,  PS_SO: 10, PS_IST: 0, EQ_TOT: 0,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 30,  ITEM: 'COM',   AREA: 'HULL',    ZONE: 'NODE',       MOD: 'EN100',       LEVEL: 'NE, Node',            G_EREC: 'EN112', G_2PE: 'EN112', G_1PE: 'EN121', EREC: '',      PE2: '',      PE1: '',      BLK: 'EN150', YARD: 'HHI',   PS_BK: 'EN150', PS_TOT: 7,    PS_WO: 7,   PS_SO: 7,  PS_IST: 0, EQ_TOT: 0,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 31,  ITEM: 'COM',   AREA: 'HULL',    ZONE: 'NODE',       MOD: 'EN100',       LEVEL: 'NE, Node',            G_EREC: 'EN112', G_2PE: 'EN112', G_1PE: 'EN121', EREC: '',      PE2: '',      PE1: '',      BLK: 'ENS10', YARD: 'HHI',   PS_BK: 'ENS10', PS_TOT: 0,     PS_WO: 0,    PS_SO: 0,   PS_IST: 0,  EQ_TOT: 0,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 32,  ITEM: 'COM',   AREA: 'HULL',    ZONE: 'NODE',       MOD: 'EN100',       LEVEL: 'NE, Node',            G_EREC: 'EN112', G_2PE: 'EN112', G_1PE: 'ENG10', EREC: '',      PE2: '',      PE1: '',      BLK: 'ENG10', YARD: 'HHI',   PS_BK: 'ENG10', PS_TOT: 0,     PS_WO: 0,    PS_SO: 0,   PS_IST: 0,  EQ_TOT: 0,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 33,  ITEM: 'COM',   AREA: 'HULL',    ZONE: 'NODE',       MOD: 'EN100',       LEVEL: 'NE, Node',            G_EREC: 'EN112', G_2PE: 'EN112', G_1PE: 'ENG20', EREC: '',      PE2: '',      PE1: '',      BLK: 'ENG20', YARD: 'HHI',   PS_BK: 'ENG20', PS_TOT: 28,   PS_WO: 27,  PS_SO: 0,  PS_IST: 0, EQ_TOT: 0,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 34,  ITEM: 'COM',   AREA: 'HULL',    ZONE: 'NODE',       MOD: 'EN100',       LEVEL: 'NE, Node',            G_EREC: 'EN112', G_2PE: 'EN112', G_1PE: 'ENG30', EREC: '',      PE2: '',      PE1: '',      BLK: 'ENG30', YARD: 'HHI',   PS_BK: 'ENG30', PS_TOT: 12,   PS_WO: 8,   PS_SO: 0,  PS_IST: 0, EQ_TOT: 0,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 35,  ITEM: 'COM',   AREA: 'HULL',    ZONE: 'NODE',       MOD: 'EN1D0',       LEVEL: 'NE, Node',            G_EREC: 'EN161', G_2PE: 'EN161', G_1PE: 'EN161', EREC: 'EN161', PE2: '',      PE1: 'EN161', BLK: 'EN160', YARD: 'HHI',   PS_BK: 'EN160', PS_TOT: 0,     PS_WO: 0,    PS_SO: 0,   PS_IST: 0,  EQ_TOT: 0,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 36,  ITEM: 'COM',   AREA: 'HULL',    ZONE: 'NODE',       MOD: 'EN1D0',       LEVEL: 'NE, Node',            G_EREC: 'EN161', G_2PE: 'EN161', G_1PE: 'EN161', EREC: '',      PE2: '',      PE1: '',      BLK: 'EN170', YARD: 'HHI',   PS_BK: 'EN170', PS_TOT: 0,     PS_WO: 0,    PS_SO: 0,   PS_IST: 0,  EQ_TOT: 0,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 37,  ITEM: 'COM',   AREA: 'HULL',    ZONE: 'NODE',       MOD: 'EN200',       LEVEL: 'SE, Node',            G_EREC: 'EN212', G_2PE: 'EN212', G_1PE: 'EN211', EREC: 'EN212', PE2: 'EN212', PE1: 'EN211', BLK: 'EN210', YARD: 'HHI',   PS_BK: 'EN210', PS_TOT: 166,  PS_WO: 153, PS_SO: 9,  PS_IST: 0, EQ_TOT: 3,  EQ_PO: 3,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 38,  ITEM: 'COM',   AREA: 'HULL',    ZONE: 'NODE',       MOD: 'EN200',       LEVEL: 'SE, Node',            G_EREC: 'EN212', G_2PE: 'EN212', G_1PE: 'EN211', EREC: '',      PE2: '',      PE1: '',      BLK: 'ENSB0', YARD: 'HHI',   PS_BK: 'ENSB0', PS_TOT: 0,     PS_WO: 0,    PS_SO: 0,   PS_IST: 0,  EQ_TOT: 0,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 39,  ITEM: 'COM',   AREA: 'HULL',    ZONE: 'NODE',       MOD: 'EN200',       LEVEL: 'SE, Node',            G_EREC: 'EN212', G_2PE: 'EN212', G_1PE: 'EN221', EREC: '',      PE2: '',      PE1: 'EN221', BLK: 'EN220', YARD: 'HHI',   PS_BK: 'EN220', PS_TOT: 127,  PS_WO: 124, PS_SO: 0,  PS_IST: 0, EQ_TOT: 0,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 40,  ITEM: 'COM',   AREA: 'HULL',    ZONE: 'NODE',       MOD: 'EN200',       LEVEL: 'SE, Node',            G_EREC: 'EN212', G_2PE: 'EN212', G_1PE: 'EN221', EREC: '',      PE2: '',      PE1: '',      BLK: 'EN230', YARD: 'HHI',   PS_BK: 'EN230', PS_TOT: 3,    PS_WO: 3,   PS_SO: 0,  PS_IST: 0, EQ_TOT: 0,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 41,  ITEM: 'COM',   AREA: 'HULL',    ZONE: 'NODE',       MOD: 'EN200',       LEVEL: 'SE, Node',            G_EREC: 'EN212', G_2PE: 'EN212', G_1PE: 'EN221', EREC: '',      PE2: '',      PE1: '',      BLK: 'EN240', YARD: 'HHI',   PS_BK: 'EN240', PS_TOT: 13,   PS_WO: 10,  PS_SO: 0,  PS_IST: 0, EQ_TOT: 0,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 42,  ITEM: 'COM',   AREA: 'HULL',    ZONE: 'NODE',       MOD: 'EN200',       LEVEL: 'SE, Node',            G_EREC: 'EN212', G_2PE: 'EN212', G_1PE: 'EN221', EREC: '',      PE2: '',      PE1: '',      BLK: 'EN250', YARD: 'HHI',   PS_BK: 'EN250', PS_TOT: 7,    PS_WO: 5,   PS_SO: 0,  PS_IST: 0, EQ_TOT: 0,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 43,  ITEM: 'COM',   AREA: 'HULL',    ZONE: 'NODE',       MOD: 'EN200',       LEVEL: 'SE, Node',            G_EREC: 'EN212', G_2PE: 'EN212', G_1PE: 'EN221', EREC: '',      PE2: '',      PE1: '',      BLK: 'ENS20', YARD: 'HHI',   PS_BK: 'ENS20', PS_TOT: 0,     PS_WO: 0,    PS_SO: 0,   PS_IST: 0,  EQ_TOT: 0,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 44,  ITEM: 'COM',   AREA: 'HULL',    ZONE: 'NODE',       MOD: 'EN200',       LEVEL: 'SE, Node',            G_EREC: 'EN212', G_2PE: 'EN212', G_1PE: 'ENGA0', EREC: '',      PE2: '',      PE1: '',      BLK: 'ENGA0', YARD: 'HHI',   PS_BK: 'ENGA0', PS_TOT: 0,     PS_WO: 0,    PS_SO: 0,   PS_IST: 0,  EQ_TOT: 0,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 45,  ITEM: 'COM',   AREA: 'HULL',    ZONE: 'NODE',       MOD: 'EN200',       LEVEL: 'SE, Node',            G_EREC: 'EN212', G_2PE: 'EN212', G_1PE: 'ENGB0', EREC: '',      PE2: '',      PE1: '',      BLK: 'ENGB0', YARD: 'HHI',   PS_BK: 'ENGB0', PS_TOT: 10,   PS_WO: 0,    PS_SO: 0,   PS_IST: 0,  EQ_TOT: 0,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 46,  ITEM: 'COM',   AREA: 'HULL',    ZONE: 'NODE',       MOD: 'EN200',       LEVEL: 'SE, Node',            G_EREC: 'EN212', G_2PE: 'EN212', G_1PE: 'ENGC0', EREC: '',      PE2: '',      PE1: '',      BLK: 'ENGC0', YARD: 'HHI',   PS_BK: 'ENGC0', PS_TOT: 12,   PS_WO: 0,    PS_SO: 0,   PS_IST: 0,  EQ_TOT: 0,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 47,  ITEM: 'COM',   AREA: 'HULL',    ZONE: 'NODE',       MOD: 'EN2D0',       LEVEL: 'SE, Node',            G_EREC: 'EN261', G_2PE: 'EN261', G_1PE: 'EN261', EREC: 'EN261', PE2: '',      PE1: 'EN261', BLK: 'EN260', YARD: 'HHI',   PS_BK: 'EN260', PS_TOT: 0,     PS_WO: 0,    PS_SO: 0,   PS_IST: 0,  EQ_TOT: 0,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 48,  ITEM: 'COM',   AREA: 'HULL',    ZONE: 'NODE',       MOD: 'EN2D0',       LEVEL: 'SE, Node',            G_EREC: 'EN261', G_2PE: 'EN261', G_1PE: 'EN261', EREC: '',      PE2: '',      PE1: '',      BLK: 'EN270', YARD: 'HHI',   PS_BK: 'EN270', PS_TOT: 0,     PS_WO: 0,    PS_SO: 0,   PS_IST: 0,  EQ_TOT: 0,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 49,  ITEM: 'COM',   AREA: 'HULL',    ZONE: 'NODE',       MOD: 'WN410',       LEVEL: 'North-West',          G_EREC: 'WN410', G_2PE: 'WN410', G_1PE: 'WN410', EREC: 'WN410', PE2: '',      PE1: '',      BLK: 'WN410', YARD: 'HHI',   PS_BK: 'WN410', PS_TOT: 0,     PS_WO: 0,    PS_SO: 0,   PS_IST: 0,  EQ_TOT: 0,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 50,  ITEM: 'COM',   AREA: 'HULL',    ZONE: 'NODE',       MOD: 'WN420',       LEVEL: 'North-West',          G_EREC: 'WN420', G_2PE: 'WN420', G_1PE: 'WN420', EREC: 'WN420', PE2: '',      PE1: '',      BLK: 'WN420', YARD: 'HHI',   PS_BK: 'WN420', PS_TOT: 0,     PS_WO: 0,    PS_SO: 0,   PS_IST: 0,  EQ_TOT: 0,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 51,  ITEM: 'COM',   AREA: 'HULL',    ZONE: 'NODE',       MOD: 'WN510',       LEVEL: 'South-West',          G_EREC: 'WN510', G_2PE: 'WN510', G_1PE: 'WN510', EREC: 'WN510', PE2: '',      PE1: '',      BLK: 'WN510', YARD: 'HHI',   PS_BK: 'WN510', PS_TOT: 0,     PS_WO: 0,    PS_SO: 0,   PS_IST: 0,  EQ_TOT: 0,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 52,  ITEM: 'COM',   AREA: 'HULL',    ZONE: 'NODE',       MOD: 'WN520',       LEVEL: 'South-West',          G_EREC: 'WN520', G_2PE: 'WN520', G_1PE: 'WN520', EREC: 'WN520', PE2: '',      PE1: '',      BLK: 'WN520', YARD: 'HHI',   PS_BK: 'WN520', PS_TOT: 0,     PS_WO: 0,    PS_SO: 0,   PS_IST: 0,  EQ_TOT: 0,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 53,  ITEM: 'COM',   AREA: 'HULL',    ZONE: 'NODE',       MOD: 'EN410',       LEVEL: 'North-East',          G_EREC: 'EN410', G_2PE: 'EN410', G_1PE: 'EN410', EREC: 'EN410', PE2: '',      PE1: '',      BLK: 'EN410', YARD: 'HHI',   PS_BK: 'EN410', PS_TOT: 0,     PS_WO: 0,    PS_SO: 0,   PS_IST: 0,  EQ_TOT: 0,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 54,  ITEM: 'COM',   AREA: 'HULL',    ZONE: 'NODE',       MOD: 'EN420',       LEVEL: 'North-East',          G_EREC: 'EN420', G_2PE: 'EN420', G_1PE: 'EN420', EREC: 'EN420', PE2: '',      PE1: '',      BLK: 'EN420', YARD: 'HHI',   PS_BK: 'EN420', PS_TOT: 0,     PS_WO: 0,    PS_SO: 0,   PS_IST: 0,  EQ_TOT: 0,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 55,  ITEM: 'COM',   AREA: 'HULL',    ZONE: 'NODE',       MOD: 'EN510',       LEVEL: 'South-East',          G_EREC: 'EN510', G_2PE: 'EN510', G_1PE: 'EN510', EREC: 'EN510', PE2: '',      PE1: '',      BLK: 'EN510', YARD: 'HHI',   PS_BK: 'EN510', PS_TOT: 0,     PS_WO: 0,    PS_SO: 0,   PS_IST: 0,  EQ_TOT: 0,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 56,  ITEM: 'COM',   AREA: 'HULL',    ZONE: 'NODE',       MOD: 'EN520',       LEVEL: 'South-East',          G_EREC: 'EN520', G_2PE: 'EN520', G_1PE: 'EN520', EREC: 'EN520', PE2: '',      PE1: '',      BLK: 'EN520', YARD: 'HHI',   PS_BK: 'EN520', PS_TOT: 0,     PS_WO: 0,    PS_SO: 0,   PS_IST: 0,  EQ_TOT: 0,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 57,  ITEM: 'COM',   AREA: 'HULL',    ZONE: 'PONTOON',    MOD: 'EP100',       LEVEL: 'East Pontoon',        G_EREC: 'EP112', G_2PE: 'EP112', G_1PE: 'EP111', EREC: 'EP112', PE2: 'EP112', PE1: 'EP111', BLK: 'EP110', YARD: 'HHI',   PS_BK: 'EP110', PS_TOT: 2,    PS_WO: 2,   PS_SO: 0,  PS_IST: 0, EQ_TOT: 0,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 58,  ITEM: 'COM',   AREA: 'HULL',    ZONE: 'PONTOON',    MOD: 'EP100',       LEVEL: 'East Pontoon',        G_EREC: 'EP112', G_2PE: 'EP112', G_1PE: 'EP111', EREC: '',      PE2: '',      PE1: '',      BLK: 'EP120', YARD: 'HHI',   PS_BK: 'EP120', PS_TOT: 0,     PS_WO: 0,    PS_SO: 0,   PS_IST: 0,  EQ_TOT: 0,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 59,  ITEM: 'COM',   AREA: 'HULL',    ZONE: 'PONTOON',    MOD: 'EP100',       LEVEL: 'East Pontoon',        G_EREC: 'EP112', G_2PE: 'EP112', G_1PE: 'EP131', EREC: '',      PE2: '',      PE1: 'EP131', BLK: 'EP130', YARD: 'HHI',   PS_BK: 'EP130', PS_TOT: 0,     PS_WO: 0,    PS_SO: 0,   PS_IST: 0,  EQ_TOT: 0,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 60,  ITEM: 'COM',   AREA: 'HULL',    ZONE: 'PONTOON',    MOD: 'EP100',       LEVEL: 'East Pontoon',        G_EREC: 'EP112', G_2PE: 'EP112', G_1PE: 'EP131', EREC: '',      PE2: '',      PE1: '',      BLK: 'EP140', YARD: 'HHI',   PS_BK: 'EP140', PS_TOT: 0,     PS_WO: 0,    PS_SO: 0,   PS_IST: 0,  EQ_TOT: 0,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 61,  ITEM: 'COM',   AREA: 'HULL',    ZONE: 'PONTOON',    MOD: 'EP100',       LEVEL: 'East Pontoon',        G_EREC: 'EP112', G_2PE: 'EP112', G_1PE: 'EP151', EREC: '',      PE2: '',      PE1: 'EP151', BLK: 'EP150', YARD: 'HHI',   PS_BK: 'EP150', PS_TOT: 2,    PS_WO: 2,   PS_SO: 0,  PS_IST: 0, EQ_TOT: 0,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 62,  ITEM: 'COM',   AREA: 'HULL',    ZONE: 'PONTOON',    MOD: 'EP100',       LEVEL: 'East Pontoon',        G_EREC: 'EP112', G_2PE: 'EP112', G_1PE: 'EP151', EREC: '',      PE2: '',      PE1: '',      BLK: 'EP160', YARD: 'HHI',   PS_BK: 'EP160', PS_TOT: 0,     PS_WO: 0,    PS_SO: 0,   PS_IST: 0,  EQ_TOT: 0,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 63,  ITEM: 'COM',   AREA: 'HULL',    ZONE: 'PONTOON',    MOD: 'WP100',       LEVEL: 'West Pontoon',        G_EREC: 'WP111', G_2PE: 'WP111', G_1PE: 'WP110', EREC: 'WP111', PE2: 'WP111', PE1: '',      BLK: 'WP110', YARD: 'COSCO', PS_BK: 'WP110', PS_TOT: 2,    PS_WO: 0,    PS_SO: 0,   PS_IST: 0,  EQ_TOT: 0,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 64,  ITEM: 'COM',   AREA: 'HULL',    ZONE: 'PONTOON',    MOD: 'WP100',       LEVEL: 'West Pontoon',        G_EREC: 'WP111', G_2PE: 'WP111', G_1PE: 'WP120', EREC: '',      PE2: '',      PE1: '',      BLK: 'WP120', YARD: 'COSCO', PS_BK: 'WP120', PS_TOT: 0,     PS_WO: 0,    PS_SO: 0,   PS_IST: 0,  EQ_TOT: 0,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 65,  ITEM: 'COM',   AREA: 'HULL',    ZONE: 'PONTOON',    MOD: 'WP100',       LEVEL: 'West Pontoon',        G_EREC: 'WP111', G_2PE: 'WP111', G_1PE: 'WPS30', EREC: '',      PE2: '',      PE1: '',      BLK: 'WPS30', YARD: 'COSCO', PS_BK: 'WPS30', PS_TOT: 0,     PS_WO: 0,    PS_SO: 0,   PS_IST: 0,  EQ_TOT: 0,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 66,  ITEM: 'COM',   AREA: 'HULL',    ZONE: 'PONTOON',    MOD: 'WP100',       LEVEL: 'West Pontoon',        G_EREC: 'WP111', G_2PE: 'WP111', G_1PE: 'WPP10', EREC: '',      PE2: '',      PE1: '',      BLK: 'WPP10', YARD: 'COSCO', PS_BK: 'WPP10', PS_TOT: 0,     PS_WO: 0,    PS_SO: 0,   PS_IST: 0,  EQ_TOT: 0,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 67,  ITEM: 'COM',   AREA: 'HULL',    ZONE: 'PONTOON',    MOD: 'WP100',       LEVEL: 'West Pontoon',        G_EREC: 'WP112', G_2PE: 'WP131', G_1PE: 'WP130', EREC: 'WP112', PE2: 'WP131', PE1: '',      BLK: 'WP130', YARD: 'COSCO', PS_BK: 'WP130', PS_TOT: 0,     PS_WO: 0,    PS_SO: 0,   PS_IST: 0,  EQ_TOT: 0,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 68,  ITEM: 'COM',   AREA: 'HULL',    ZONE: 'PONTOON',    MOD: 'WP100',       LEVEL: 'West Pontoon',        G_EREC: 'WP112', G_2PE: 'WP131', G_1PE: 'WP140', EREC: '',      PE2: '',      PE1: '',      BLK: 'WP140', YARD: 'COSCO', PS_BK: 'WP140', PS_TOT: 0,     PS_WO: 0,    PS_SO: 0,   PS_IST: 0,  EQ_TOT: 0,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 69,  ITEM: 'COM',   AREA: 'HULL',    ZONE: 'PONTOON',    MOD: 'WP100',       LEVEL: 'West Pontoon',        G_EREC: 'WP112', G_2PE: 'WP131', G_1PE: 'WPP20', EREC: '',      PE2: '',      PE1: '',      BLK: 'WPP20', YARD: 'COSCO', PS_BK: 'WPP20', PS_TOT: 0,     PS_WO: 0,    PS_SO: 0,   PS_IST: 0,  EQ_TOT: 0,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 70,  ITEM: 'COM',   AREA: 'HULL',    ZONE: 'PONTOON',    MOD: 'WP100',       LEVEL: 'West Pontoon',        G_EREC: 'WP112', G_2PE: 'WP131', G_1PE: 'WPS10', EREC: '',      PE2: '',      PE1: '',      BLK: 'WPS10', YARD: 'COSCO', PS_BK: 'WPS10', PS_TOT: 0,     PS_WO: 0,    PS_SO: 0,   PS_IST: 0,  EQ_TOT: 0,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 71,  ITEM: 'COM',   AREA: 'HULL',    ZONE: 'PONTOON',    MOD: 'WP100',       LEVEL: 'West Pontoon',        G_EREC: 'WP112', G_2PE: 'WP151', G_1PE: 'WP150', EREC: '',      PE2: 'WP151', PE1: '',      BLK: 'WP150', YARD: 'COSCO', PS_BK: 'WP150', PS_TOT: 2,    PS_WO: 0,    PS_SO: 0,   PS_IST: 0,  EQ_TOT: 0,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 72,  ITEM: 'COM',   AREA: 'HULL',    ZONE: 'PONTOON',    MOD: 'WP100',       LEVEL: 'West Pontoon',        G_EREC: 'WP112', G_2PE: 'WP151', G_1PE: 'WP160', EREC: '',      PE2: '',      PE1: '',      BLK: 'WP160', YARD: 'COSCO', PS_BK: 'WP160', PS_TOT: 0,     PS_WO: 0,    PS_SO: 0,   PS_IST: 0,  EQ_TOT: 0,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 73,  ITEM: 'COM',   AREA: 'HULL',    ZONE: 'PONTOON',    MOD: 'WP100',       LEVEL: 'West Pontoon',        G_EREC: 'WP112', G_2PE: 'WP151', G_1PE: 'WPS20', EREC: '',      PE2: '',      PE1: '',      BLK: 'WPS20', YARD: 'COSCO', PS_BK: 'WPS20', PS_TOT: 0,     PS_WO: 0,    PS_SO: 0,   PS_IST: 0,  EQ_TOT: 0,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 74,  ITEM: 'COM',   AREA: 'HULL',    ZONE: 'PONTOON',    MOD: 'WP100',       LEVEL: 'West Pontoon',        G_EREC: 'WPG10', G_2PE: 'WPG10', G_1PE: 'WPG10', EREC: 'WPG10', PE2: 'WPG10', PE1: '',      BLK: 'WPG10', YARD: 'COSCO', PS_BK: 'WPG10', PS_TOT: 0,     PS_WO: 0,    PS_SO: 0,   PS_IST: 0,  EQ_TOT: 0,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 75,  ITEM: 'COM',   AREA: 'HULL',    ZONE: 'PONTOON',    MOD: 'WP100',       LEVEL: 'West Pontoon',        G_EREC: 'WPB10', G_2PE: 'WPB10', G_1PE: 'WPB10', EREC: 'WPB10', PE2: '',      PE1: '',      BLK: 'WPB10', YARD: 'HHI',   PS_BK: 'WPB10', PS_TOT: 0,     PS_WO: 0,    PS_SO: 0,   PS_IST: 0,  EQ_TOT: 0,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 76,  ITEM: 'COM',   AREA: 'HULL',    ZONE: 'PONTOON',    MOD: 'SP100',       LEVEL: 'South Pontoon',       G_EREC: 'SP112', G_2PE: 'SP111', G_1PE: 'SP110', EREC: 'SP112', PE2: 'SP111', PE1: '',      BLK: 'SP110', YARD: 'COSCO', PS_BK: 'SP110', PS_TOT: 2,    PS_WO: 0,    PS_SO: 0,   PS_IST: 0,  EQ_TOT: 0,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 77,  ITEM: 'COM',   AREA: 'HULL',    ZONE: 'PONTOON',    MOD: 'SP100',       LEVEL: 'South Pontoon',       G_EREC: 'SP112', G_2PE: 'SP111', G_1PE: 'SP120', EREC: '',      PE2: '',      PE1: '',      BLK: 'SP120', YARD: 'COSCO', PS_BK: 'SP120', PS_TOT: 0,     PS_WO: 0,    PS_SO: 0,   PS_IST: 0,  EQ_TOT: 0,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 78,  ITEM: 'COM',   AREA: 'HULL',    ZONE: 'PONTOON',    MOD: 'SP100',       LEVEL: 'South Pontoon',       G_EREC: 'SP112', G_2PE: 'SP131', G_1PE: 'SP130', EREC: '',      PE2: 'SP131', PE1: '',      BLK: 'SP130', YARD: 'COSCO', PS_BK: 'SP130', PS_TOT: 0,     PS_WO: 0,    PS_SO: 0,   PS_IST: 0,  EQ_TOT: 0,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 79,  ITEM: 'COM',   AREA: 'HULL',    ZONE: 'PONTOON',    MOD: 'SP100',       LEVEL: 'South Pontoon',       G_EREC: 'SP112', G_2PE: 'SP131', G_1PE: 'SP140', EREC: '',      PE2: '',      PE1: '',      BLK: 'SP140', YARD: 'COSCO', PS_BK: 'SP140', PS_TOT: 0,     PS_WO: 0,    PS_SO: 0,   PS_IST: 0,  EQ_TOT: 0,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 80,  ITEM: 'COM',   AREA: 'HULL',    ZONE: 'PONTOON',    MOD: 'SP100',       LEVEL: 'South Pontoon',       G_EREC: 'SP151', G_2PE: 'SP151', G_1PE: 'SP150', EREC: 'SP151', PE2: 'SP151', PE1: '',      BLK: 'SP150', YARD: 'COSCO', PS_BK: 'SP150', PS_TOT: 2,    PS_WO: 0,    PS_SO: 0,   PS_IST: 0,  EQ_TOT: 0,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 81,  ITEM: 'COM',   AREA: 'HULL',    ZONE: 'PONTOON',    MOD: 'SP100',       LEVEL: 'South Pontoon',       G_EREC: 'SP151', G_2PE: 'SP151', G_1PE: 'SP160', EREC: '',      PE2: '',      PE1: '',      BLK: 'SP160', YARD: 'COSCO', PS_BK: 'SP160', PS_TOT: 0,     PS_WO: 0,    PS_SO: 0,   PS_IST: 0,  EQ_TOT: 0,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 82,  ITEM: 'COM',   AREA: 'HULL',    ZONE: 'PONTOON',    MOD: 'NP100',       LEVEL: 'North Pontoon',       G_EREC: 'NP112', G_2PE: 'NP111', G_1PE: 'NP110', EREC: 'NP112', PE2: 'NP111', PE1: '',      BLK: 'NP110', YARD: 'COSCO', PS_BK: 'NP110', PS_TOT: 2,    PS_WO: 0,    PS_SO: 0,   PS_IST: 0,  EQ_TOT: 0,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 83,  ITEM: 'COM',   AREA: 'HULL',    ZONE: 'PONTOON',    MOD: 'NP100',       LEVEL: 'North Pontoon',       G_EREC: 'NP112', G_2PE: 'NP111', G_1PE: 'NP120', EREC: '',      PE2: '',      PE1: '',      BLK: 'NP120', YARD: 'COSCO', PS_BK: 'NP120', PS_TOT: 0,     PS_WO: 0,    PS_SO: 0,   PS_IST: 0,  EQ_TOT: 0,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 84,  ITEM: 'COM',   AREA: 'HULL',    ZONE: 'PONTOON',    MOD: 'NP100',       LEVEL: 'North Pontoon',       G_EREC: 'NP112', G_2PE: 'NP111', G_1PE: 'NPP10', EREC: '',      PE2: '',      PE1: '',      BLK: 'NPP10', YARD: 'COSCO', PS_BK: 'NPP10', PS_TOT: 0,     PS_WO: 0,    PS_SO: 0,   PS_IST: 0,  EQ_TOT: 0,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 85,  ITEM: 'COM',   AREA: 'HULL',    ZONE: 'PONTOON',    MOD: 'NP100',       LEVEL: 'North Pontoon',       G_EREC: 'NP112', G_2PE: 'NP111', G_1PE: 'NPS10', EREC: '',      PE2: '',      PE1: '',      BLK: 'NPS10', YARD: 'COSCO', PS_BK: 'NPS10', PS_TOT: 0,     PS_WO: 0,    PS_SO: 0,   PS_IST: 0,  EQ_TOT: 0,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 86,  ITEM: 'COM',   AREA: 'HULL',    ZONE: 'PONTOON',    MOD: 'NP100',       LEVEL: 'North Pontoon',       G_EREC: 'NP112', G_2PE: 'NP131', G_1PE: 'NP130', EREC: '',      PE2: 'NP131', PE1: '',      BLK: 'NP130', YARD: 'COSCO', PS_BK: 'NP130', PS_TOT: 0,     PS_WO: 0,    PS_SO: 0,   PS_IST: 0,  EQ_TOT: 0,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 87,  ITEM: 'COM',   AREA: 'HULL',    ZONE: 'PONTOON',    MOD: 'NP100',       LEVEL: 'North Pontoon',       G_EREC: 'NP112', G_2PE: 'NP131', G_1PE: 'NP140', EREC: '',      PE2: '',      PE1: '',      BLK: 'NP140', YARD: 'COSCO', PS_BK: 'NP140', PS_TOT: 0,     PS_WO: 0,    PS_SO: 0,   PS_IST: 0,  EQ_TOT: 0,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 88,  ITEM: 'COM',   AREA: 'HULL',    ZONE: 'PONTOON',    MOD: 'NP100',       LEVEL: 'North Pontoon',       G_EREC: 'NP112', G_2PE: 'NP131', G_1PE: 'NPP20', EREC: '',      PE2: '',      PE1: '',      BLK: 'NPP20', YARD: 'COSCO', PS_BK: 'NPP20', PS_TOT: 0,     PS_WO: 0,    PS_SO: 0,   PS_IST: 0,  EQ_TOT: 0,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 89,  ITEM: 'COM',   AREA: 'HULL',    ZONE: 'PONTOON',    MOD: 'NP100',       LEVEL: 'North Pontoon',       G_EREC: 'NP112', G_2PE: 'NP131', G_1PE: 'NPS20', EREC: '',      PE2: '',      PE1: '',      BLK: 'NPS20', YARD: 'COSCO', PS_BK: 'NPS20', PS_TOT: 0,     PS_WO: 0,    PS_SO: 0,   PS_IST: 0,  EQ_TOT: 0,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 90,  ITEM: 'COM',   AREA: 'HULL',    ZONE: 'PONTOON',    MOD: 'NP100',       LEVEL: 'North Pontoon',       G_EREC: 'NP151', G_2PE: 'NP151', G_1PE: 'NP150', EREC: 'NP151', PE2: 'NP151', PE1: '',      BLK: 'NP150', YARD: 'COSCO', PS_BK: 'NP150', PS_TOT: 2,    PS_WO: 0,    PS_SO: 0,   PS_IST: 0,  EQ_TOT: 0,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 91,  ITEM: 'COM',   AREA: 'HULL',    ZONE: 'PONTOON',    MOD: 'NP100',       LEVEL: 'North Pontoon',       G_EREC: 'NP151', G_2PE: 'NP151', G_1PE: 'NP160', EREC: '',      PE2: '',      PE1: '',      BLK: 'NP160', YARD: 'COSCO', PS_BK: 'NP160', PS_TOT: 0,     PS_WO: 0,    PS_SO: 0,   PS_IST: 0,  EQ_TOT: 0,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 92,  ITEM: 'COM',   AREA: 'HULL',    ZONE: 'PONTOON',    MOD: 'NP100',       LEVEL: 'North Pontoon',       G_EREC: 'NPB10', G_2PE: 'NPB10', G_1PE: 'NPB10', EREC: 'NPB10', PE2: '',      PE1: '',      BLK: 'NPB10', YARD: 'HHI',   PS_BK: 'NPB10', PS_TOT: 0,     PS_WO: 0,    PS_SO: 0,   PS_IST: 0,  EQ_TOT: 0,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 93,  ITEM: 'COM',   AREA: 'HULL',    ZONE: 'COLUMN',     MOD: 'WC100',       LEVEL: 'NW, Lower Column',    G_EREC: 'WC112', G_2PE: 'WC111', G_1PE: 'WC110', EREC: 'WC112', PE2: 'WC111', PE1: '',      BLK: 'WC110', YARD: 'COSCO', PS_BK: 'WC110', PS_TOT: 153,  PS_WO: 4,   PS_SO: 0,  PS_IST: 0, EQ_TOT: 0,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 94,  ITEM: 'COM',   AREA: 'HULL',    ZONE: 'COLUMN',     MOD: 'WC100',       LEVEL: 'NW, Lower Column',    G_EREC: 'WC112', G_2PE: 'WC111', G_1PE: 'WC120', EREC: '',      PE2: '',      PE1: '',      BLK: 'WC120', YARD: 'COSCO', PS_BK: 'WC120', PS_TOT: 4,    PS_WO: 3,   PS_SO: 0,  PS_IST: 0, EQ_TOT: 0,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 95,  ITEM: 'COM',   AREA: 'HULL',    ZONE: 'COLUMN',     MOD: 'WC100',       LEVEL: 'NW, Lower Column',    G_EREC: 'WC112', G_2PE: 'WC111', G_1PE: 'WC130', EREC: '',      PE2: '',      PE1: '',      BLK: 'WC130', YARD: 'COSCO', PS_BK: 'WC130', PS_TOT: 8,    PS_WO: 11,  PS_SO: 0,  PS_IST: 0, EQ_TOT: 0,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 96,  ITEM: 'COM',   AREA: 'HULL',    ZONE: 'COLUMN',     MOD: 'WC100',       LEVEL: 'NW, Lower Column',    G_EREC: 'WC112', G_2PE: 'WC111', G_1PE: 'WC140', EREC: '',      PE2: '',      PE1: '',      BLK: 'WC140', YARD: 'COSCO', PS_BK: 'WC140', PS_TOT: 6,    PS_WO: 9,   PS_SO: 0,  PS_IST: 0, EQ_TOT: 0,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 97,  ITEM: 'COM',   AREA: 'HULL',    ZONE: 'COLUMN',     MOD: 'WC100',       LEVEL: 'NW, Lower Column',    G_EREC: 'WC112', G_2PE: 'WC111', G_1PE: 'WC310', EREC: '',      PE2: '',      PE1: '',      BLK: 'WC310', YARD: 'COSCO', PS_BK: 'WC310', PS_TOT: 0,     PS_WO: 0,    PS_SO: 0,   PS_IST: 0,  EQ_TOT: 0,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 98,  ITEM: 'COM',   AREA: 'HULL',    ZONE: 'COLUMN',     MOD: 'WC100',       LEVEL: 'NW, Lower Column',    G_EREC: 'WC112', G_2PE: 'WC151', G_1PE: 'WC150', EREC: '',      PE2: 'WC151', PE1: '',      BLK: 'WC150', YARD: 'COSCO', PS_BK: 'WC150', PS_TOT: 132,  PS_WO: 3,   PS_SO: 0,  PS_IST: 0, EQ_TOT: 0,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 99,  ITEM: 'COM',   AREA: 'HULL',    ZONE: 'COLUMN',     MOD: 'WC100',       LEVEL: 'NW, Lower Column',    G_EREC: 'WC112', G_2PE: 'WC151', G_1PE: 'WC160', EREC: '',      PE2: '',      PE1: '',      BLK: 'WC160', YARD: 'COSCO', PS_BK: 'WC160', PS_TOT: 4,    PS_WO: 3,   PS_SO: 0,  PS_IST: 0, EQ_TOT: 0,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 100, ITEM: 'COM',   AREA: 'HULL',    ZONE: 'COLUMN',     MOD: 'WC100',       LEVEL: 'NW, Lower Column',    G_EREC: 'WC112', G_2PE: 'WC151', G_1PE: 'WC170', EREC: '',      PE2: '',      PE1: '',      BLK: 'WC170', YARD: 'COSCO', PS_BK: 'WC170', PS_TOT: 4,    PS_WO: 3,   PS_SO: 0,  PS_IST: 0, EQ_TOT: 0,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 101, ITEM: 'COM',   AREA: 'HULL',    ZONE: 'COLUMN',     MOD: 'WC100',       LEVEL: 'NW, Lower Column',    G_EREC: 'WC112', G_2PE: 'WC151', G_1PE: 'WC180', EREC: '',      PE2: '',      PE1: '',      BLK: 'WC180', YARD: 'COSCO', PS_BK: 'WC180', PS_TOT: 4,    PS_WO: 3,   PS_SO: 0,  PS_IST: 0, EQ_TOT: 0,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 102, ITEM: 'COM',   AREA: 'HULL',    ZONE: 'COLUMN',     MOD: 'WC100',       LEVEL: 'NW, Lower Column',    G_EREC: 'WC112', G_2PE: 'WC151', G_1PE: 'WC320', EREC: '',      PE2: '',      PE1: '',      BLK: 'WC320', YARD: 'COSCO', PS_BK: 'WC320', PS_TOT: 0,     PS_WO: 0,    PS_SO: 0,   PS_IST: 0,  EQ_TOT: 0,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 103, ITEM: 'COM',   AREA: 'HULL',    ZONE: 'COLUMN',     MOD: 'WC100',       LEVEL: 'NW, Lower Column',    G_EREC: 'WC112', G_2PE: 'WC151', G_1PE: 'WCC10', EREC: '',      PE2: '',      PE1: '',      BLK: 'WCC10', YARD: 'COSCO', PS_BK: 'WCC10', PS_TOT: 0,     PS_WO: 0,    PS_SO: 0,   PS_IST: 0,  EQ_TOT: 0,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 104, ITEM: 'COM',   AREA: 'HULL',    ZONE: 'COLUMN',     MOD: 'WC100',       LEVEL: 'NW, Lower Column',    G_EREC: 'WC112', G_2PE: 'WC151', G_1PE: 'WCC20', EREC: '',      PE2: '',      PE1: '',      BLK: 'WCC20', YARD: 'COSCO', PS_BK: 'WCC20', PS_TOT: 0,     PS_WO: 0,    PS_SO: 0,   PS_IST: 0,  EQ_TOT: 0,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 105, ITEM: 'COM',   AREA: 'HULL',    ZONE: 'COLUMN',     MOD: 'WC1M0',       LEVEL: 'NW, Middle Column',   G_EREC: 'WC1K2', G_2PE: 'WC1K0', G_1PE: 'WC1K0', EREC: 'WC1K3', PE2: 'WC1K2', PE1: '',      BLK: 'WC1K0', YARD: 'COSCO', PS_BK: 'WC1K0', PS_TOT: 116,  PS_WO: 0,    PS_SO: 0,   PS_IST: 0,  EQ_TOT: 0,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 106, ITEM: 'COM',   AREA: 'HULL',    ZONE: 'COLUMN',     MOD: 'WC1M0',       LEVEL: 'NW, Middle Column',   G_EREC: 'WC1K2', G_2PE: 'WC1L0', G_1PE: 'WC1L0', EREC: '',      PE2: '',      PE1: '',      BLK: 'WC1L0', YARD: 'COSCO', PS_BK: 'WC1L0', PS_TOT: 3,    PS_WO: 0,    PS_SO: 0,   PS_IST: 0,  EQ_TOT: 0,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 107, ITEM: 'COM',   AREA: 'HULL',    ZONE: 'COLUMN',     MOD: 'WC1M0',       LEVEL: 'NW, Middle Column',   G_EREC: 'WC1K2', G_2PE: 'WC1M0', G_1PE: 'WC1M0', EREC: '',      PE2: '',      PE1: '',      BLK: 'WC1M0', YARD: 'COSCO', PS_BK: 'WC1M0', PS_TOT: 3,    PS_WO: 0,    PS_SO: 0,   PS_IST: 0,  EQ_TOT: 0,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 108, ITEM: 'COM',   AREA: 'HULL',    ZONE: 'COLUMN',     MOD: 'WC1M0',       LEVEL: 'NW, Middle Column',   G_EREC: 'WC1K2', G_2PE: 'WC1N0', G_1PE: 'WC1N0', EREC: '',      PE2: '',      PE1: '',      BLK: 'WC1N0', YARD: 'COSCO', PS_BK: 'WC1N0', PS_TOT: 3,    PS_WO: 0,    PS_SO: 0,   PS_IST: 0,  EQ_TOT: 0,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 109, ITEM: 'COM',   AREA: 'HULL',    ZONE: 'COLUMN',     MOD: 'WC1M0',       LEVEL: 'NW, Middle Column',   G_EREC: 'WC1K2', G_2PE: 'WC1P0', G_1PE: 'WC1P0', EREC: '',      PE2: '',      PE1: '',      BLK: 'WC1P0', YARD: 'COSCO', PS_BK: 'WC1P0', PS_TOT: 110,  PS_WO: 0,    PS_SO: 0,   PS_IST: 0,  EQ_TOT: 0,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 110, ITEM: 'COM',   AREA: 'HULL',    ZONE: 'COLUMN',     MOD: 'WC1M0',       LEVEL: 'NW, Middle Column',   G_EREC: 'WC1K2', G_2PE: 'WC1Q0', G_1PE: 'WC1Q0', EREC: '',      PE2: '',      PE1: '',      BLK: 'WC1Q0', YARD: 'COSCO', PS_BK: 'WC1Q0', PS_TOT: 3,    PS_WO: 0,    PS_SO: 0,   PS_IST: 0,  EQ_TOT: 0,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 111, ITEM: 'COM',   AREA: 'HULL',    ZONE: 'COLUMN',     MOD: 'WC1M0',       LEVEL: 'NW, Middle Column',   G_EREC: 'WC1K2', G_2PE: 'WC1R0', G_1PE: 'WC1R0', EREC: '',      PE2: '',      PE1: '',      BLK: 'WC1R0', YARD: 'COSCO', PS_BK: 'WC1R0', PS_TOT: 2,    PS_WO: 0,    PS_SO: 0,   PS_IST: 0,  EQ_TOT: 0,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 112, ITEM: 'COM',   AREA: 'HULL',    ZONE: 'COLUMN',     MOD: 'WC1M0',       LEVEL: 'NW, Middle Column',   G_EREC: 'WC1K2', G_2PE: 'WC1S0', G_1PE: 'WC1S0', EREC: '',      PE2: '',      PE1: '',      BLK: 'WC1S0', YARD: 'COSCO', PS_BK: 'WC1S0', PS_TOT: 1,    PS_WO: 0,    PS_SO: 0,   PS_IST: 0,  EQ_TOT: 0,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 113, ITEM: 'COM',   AREA: 'HULL',    ZONE: 'COLUMN',     MOD: 'WC1M0',       LEVEL: 'NW, Middle Column',   G_EREC: 'WC1K2', G_2PE: 'WC330', G_1PE: 'WC330', EREC: '',      PE2: '',      PE1: '',      BLK: 'WC330', YARD: 'COSCO', PS_BK: 'WC330', PS_TOT: 0,     PS_WO: 0,    PS_SO: 0,   PS_IST: 0,  EQ_TOT: 0,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 114, ITEM: 'COM',   AREA: 'HULL',    ZONE: 'COLUMN',     MOD: 'WC1M0',       LEVEL: 'NW, Middle Column',   G_EREC: 'WC1K2', G_2PE: 'WCC30', G_1PE: 'WCC30', EREC: '',      PE2: '',      PE1: '',      BLK: 'WCC30', YARD: 'COSCO', PS_BK: 'WCC30', PS_TOT: 0,     PS_WO: 0,    PS_SO: 0,   PS_IST: 0,  EQ_TOT: 0,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 115, ITEM: 'COM',   AREA: 'HULL',    ZONE: 'COLUMN',     MOD: 'WC1M0',       LEVEL: 'NW, Middle Column',   G_EREC: 'WC1K2', G_2PE: 'WCC40', G_1PE: 'WCC40', EREC: '',      PE2: '',      PE1: '',      BLK: 'WCC40', YARD: 'COSCO', PS_BK: 'WCC40', PS_TOT: 0,     PS_WO: 0,    PS_SO: 0,   PS_IST: 0,  EQ_TOT: 0,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 116, ITEM: 'COM',   AREA: 'HULL',    ZONE: 'COLUMN',     MOD: 'WC1M0',       LEVEL: 'NW, Middle Column',   G_EREC: 'WCG10', G_2PE: 'WCG10', G_1PE: 'WCG10', EREC: 'WCG10', PE2: '',      PE1: '',      BLK: 'WCG10', YARD: 'COSCO', PS_BK: 'WCG10', PS_TOT: 0,     PS_WO: 0,    PS_SO: 0,   PS_IST: 0,  EQ_TOT: 0,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 117, ITEM: 'COM',   AREA: 'HULL',    ZONE: 'COLUMN',     MOD: 'WC1M0',       LEVEL: 'NW, Middle Column',   G_EREC: 'WCG20', G_2PE: 'WCG20', G_1PE: 'WCG20', EREC: 'WCG20', PE2: '',      PE1: '',      BLK: 'WCG20', YARD: 'COSCO', PS_BK: 'WCG20', PS_TOT: 0,     PS_WO: 0,    PS_SO: 0,   PS_IST: 0,  EQ_TOT: 0,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 118, ITEM: 'COM',   AREA: 'HULL',    ZONE: 'COLUMN',     MOD: 'WC3A0',       LEVEL: 'NW, Middle Column',   G_EREC: 'WC3A0', G_2PE: 'WC3A0', G_1PE: 'WC3A0', EREC: 'WC3A0', PE2: '',      PE1: '',      BLK: 'WC3A0', YARD: 'HHI',   PS_BK: 'WC3A0', PS_TOT: 0,     PS_WO: 0,    PS_SO: 0,   PS_IST: 0,  EQ_TOT: 0,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 119, ITEM: 'COM',   AREA: 'HULL',    ZONE: 'COLUMN',     MOD: 'WC1U0',       LEVEL: 'NW, Upper Column',    G_EREC: 'WC1G2', G_2PE: 'WC1G2', G_1PE: 'WC1G1', EREC: 'WC1G2', PE2: 'WC1G2', PE1: 'WC1G1', BLK: 'WC1G0', YARD: 'HHI',   PS_BK: 'WC1G0', PS_TOT: 87,   PS_WO: 0,    PS_SO: 0,   PS_IST: 0,  EQ_TOT: 22, EQ_PO: 3,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 120, ITEM: 'COM',   AREA: 'HULL',    ZONE: 'COLUMN',     MOD: 'WC1U0',       LEVEL: 'NW, Upper Column',    G_EREC: 'WC1G2', G_2PE: 'WC1G2', G_1PE: 'WC1G1', EREC: '',      PE2: '',      PE1: '',      BLK: 'WC340', YARD: 'HHI',   PS_BK: 'WC340', PS_TOT: 0,     PS_WO: 0,    PS_SO: 0,   PS_IST: 0,  EQ_TOT: 0,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 121, ITEM: 'COM',   AREA: 'HULL',    ZONE: 'COLUMN',     MOD: 'WC1U0',       LEVEL: 'NW, Upper Column',    G_EREC: 'WC1G2', G_2PE: 'WC1G2', G_1PE: 'WC1H1', EREC: '',      PE2: '',      PE1: 'WC1H1', BLK: 'WC1H0', YARD: 'HHI',   PS_BK: 'WC1H0', PS_TOT: 153,  PS_WO: 0,    PS_SO: 0,   PS_IST: 0,  EQ_TOT: 0,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 122, ITEM: 'COM',   AREA: 'HULL',    ZONE: 'COLUMN',     MOD: 'WC1U0',       LEVEL: 'NW, Upper Column',    G_EREC: 'WC1G2', G_2PE: 'WC1G2', G_1PE: 'WC1H1', EREC: '',      PE2: '',      PE1: '',      BLK: 'WC350', YARD: 'HHI',   PS_BK: 'WC350', PS_TOT: 0,     PS_WO: 0,    PS_SO: 0,   PS_IST: 0,  EQ_TOT: 0,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 123, ITEM: 'COM',   AREA: 'HULL',    ZONE: 'COLUMN',     MOD: 'WC1U0',       LEVEL: 'NW, Upper Column',    G_EREC: 'WC1G2', G_2PE: 'WC1G2', G_1PE: 'WC1H1', EREC: '',      PE2: '',      PE1: '',      BLK: 'WC1J0', YARD: 'HHI',   PS_BK: 'WC1J0', PS_TOT: 0,     PS_WO: 0,    PS_SO: 0,   PS_IST: 0,  EQ_TOT: 0,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 124, ITEM: 'COM',   AREA: 'HULL',    ZONE: 'COLUMN',     MOD: 'WC1U0',       LEVEL: 'NW, Upper Column',    G_EREC: 'WC1G2', G_2PE: 'WC1G2', G_1PE: 'WC1H1', EREC: '',      PE2: '',      PE1: '',      BLK: 'WF220', YARD: 'HHI',   PS_BK: 'WF220', PS_TOT: 0,     PS_WO: 0,    PS_SO: 0,   PS_IST: 0,  EQ_TOT: 0,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 125, ITEM: 'COM',   AREA: 'HULL',    ZONE: 'COLUMN',     MOD: 'WC1U0',       LEVEL: 'NW, Upper Column',    G_EREC: 'WC1G2', G_2PE: 'WC1G2', G_1PE: 'WC1H1', EREC: '',      PE2: '',      PE1: '',      BLK: 'NF220', YARD: 'HHI',   PS_BK: 'NF220', PS_TOT: 0,     PS_WO: 0,    PS_SO: 0,   PS_IST: 0,  EQ_TOT: 0,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 126, ITEM: 'COM',   AREA: 'HULL',    ZONE: 'COLUMN',     MOD: 'WC1U0',       LEVEL: 'NW, Upper Column',    G_EREC: 'WCP10', G_2PE: 'WCP10', G_1PE: 'WCP10', EREC: 'WCP10', PE2: '',      PE1: '',      BLK: 'WCP10', YARD: 'HHI',   PS_BK: 'WCP10', PS_TOT: 0,     PS_WO: 0,    PS_SO: 0,   PS_IST: 0,  EQ_TOT: 1,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 127, ITEM: 'COM',   AREA: 'HULL',    ZONE: 'COLUMN',     MOD: 'WC1U0',       LEVEL: 'NW, Upper Column',    G_EREC: 'WCP20', G_2PE: 'WCP20', G_1PE: 'WCP20', EREC: 'WCP20', PE2: '',      PE1: '',      BLK: 'WCP20', YARD: 'HHI',   PS_BK: 'WCP20', PS_TOT: 3,    PS_WO: 0,    PS_SO: 0,   PS_IST: 0,  EQ_TOT: 0,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 128, ITEM: 'COM',   AREA: 'HULL',    ZONE: 'COLUMN',     MOD: 'WC1U0',       LEVEL: 'NW, Upper Column',    G_EREC: 'WCP30', G_2PE: 'WCP30', G_1PE: 'WCP30', EREC: 'WCP30', PE2: '',      PE1: '',      BLK: 'WCP30', YARD: 'HHI',   PS_BK: 'WCP30', PS_TOT: 0,     PS_WO: 0,    PS_SO: 0,   PS_IST: 0,  EQ_TOT: 0,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 129, ITEM: 'COM',   AREA: 'HULL',    ZONE: 'COLUMN',     MOD: 'WC1U0',       LEVEL: 'NW, Upper Column',    G_EREC: 'WCP40', G_2PE: 'WCP40', G_1PE: 'WCP40', EREC: 'WCP40', PE2: '',      PE1: '',      BLK: 'WCP40', YARD: 'HHI',   PS_BK: 'WCP40', PS_TOT: 0,     PS_WO: 0,    PS_SO: 0,   PS_IST: 0,  EQ_TOT: 0,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 130, ITEM: 'COM',   AREA: 'HULL',    ZONE: 'COLUMN',     MOD: 'WC1U0',       LEVEL: 'NW, Upper Column',    G_EREC: 'WCP50', G_2PE: 'WCP50', G_1PE: 'WCP50', EREC: 'WCP50', PE2: '',      PE1: '',      BLK: 'WCP50', YARD: 'HHI',   PS_BK: 'WCP50', PS_TOT: 8,    PS_WO: 0,    PS_SO: 0,   PS_IST: 0,  EQ_TOT: 0,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 131, ITEM: 'COM',   AREA: 'HULL',    ZONE: 'COLUMN',     MOD: 'WC1U0',       LEVEL: 'NW, Upper Column',    G_EREC: 'WCP60', G_2PE: 'WCP60', G_1PE: 'WCP60', EREC: 'WCP60', PE2: '',      PE1: '',      BLK: 'WCP60', YARD: 'HHI',   PS_BK: 'WCP60', PS_TOT: 0,     PS_WO: 0,    PS_SO: 0,   PS_IST: 0,  EQ_TOT: 0,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 132, ITEM: 'COM',   AREA: 'HULL',    ZONE: 'COLUMN',     MOD: 'WC200',       LEVEL: 'SW, Lower Column',    G_EREC: 'WC212', G_2PE: 'WC212', G_1PE: 'WC211', EREC: 'WC212', PE2: 'WC212', PE1: 'WC211', BLK: 'WC210', YARD: 'COSCO', PS_BK: 'WC210', PS_TOT: 159,  PS_WO: 4,   PS_SO: 0,  PS_IST: 0, EQ_TOT: 0,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 133, ITEM: 'COM',   AREA: 'HULL',    ZONE: 'COLUMN',     MOD: 'WC200',       LEVEL: 'SW, Lower Column',    G_EREC: 'WC212', G_2PE: 'WC212', G_1PE: 'WC211', EREC: '',      PE2: '',      PE1: '',      BLK: 'WC220', YARD: 'COSCO', PS_BK: 'WC220', PS_TOT: 4,    PS_WO: 3,   PS_SO: 0,  PS_IST: 0, EQ_TOT: 0,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 134, ITEM: 'COM',   AREA: 'HULL',    ZONE: 'COLUMN',     MOD: 'WC200',       LEVEL: 'SW, Lower Column',    G_EREC: 'WC212', G_2PE: 'WC212', G_1PE: 'WC211', EREC: '',      PE2: '',      PE1: '',      BLK: 'WC230', YARD: 'COSCO', PS_BK: 'WC230', PS_TOT: 8,    PS_WO: 11,  PS_SO: 0,  PS_IST: 0, EQ_TOT: 0,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 135, ITEM: 'COM',   AREA: 'HULL',    ZONE: 'COLUMN',     MOD: 'WC200',       LEVEL: 'SW, Lower Column',    G_EREC: 'WC212', G_2PE: 'WC212', G_1PE: 'WC211', EREC: '',      PE2: '',      PE1: '',      BLK: 'WC240', YARD: 'COSCO', PS_BK: 'WC240', PS_TOT: 6,    PS_WO: 9,   PS_SO: 0,  PS_IST: 0, EQ_TOT: 0,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 136, ITEM: 'COM',   AREA: 'HULL',    ZONE: 'COLUMN',     MOD: 'WC200',       LEVEL: 'SW, Lower Column',    G_EREC: 'WC212', G_2PE: 'WC212', G_1PE: 'WC211', EREC: '',      PE2: '',      PE1: '',      BLK: 'WC410', YARD: 'COSCO', PS_BK: 'WC410', PS_TOT: 0,     PS_WO: 0,    PS_SO: 0,   PS_IST: 0,  EQ_TOT: 0,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 137, ITEM: 'COM',   AREA: 'HULL',    ZONE: 'COLUMN',     MOD: 'WC200',       LEVEL: 'SW, Lower Column',    G_EREC: 'WC212', G_2PE: 'WC212', G_1PE: 'WC251', EREC: '',      PE2: '',      PE1: 'WC251', BLK: 'WC250', YARD: 'COSCO', PS_BK: 'WC250', PS_TOT: 137,  PS_WO: 3,   PS_SO: 0,  PS_IST: 0, EQ_TOT: 0,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 138, ITEM: 'COM',   AREA: 'HULL',    ZONE: 'COLUMN',     MOD: 'WC200',       LEVEL: 'SW, Lower Column',    G_EREC: 'WC212', G_2PE: 'WC212', G_1PE: 'WC251', EREC: '',      PE2: '',      PE1: '',      BLK: 'WC260', YARD: 'COSCO', PS_BK: 'WC260', PS_TOT: 4,    PS_WO: 3,   PS_SO: 0,  PS_IST: 0, EQ_TOT: 0,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 139, ITEM: 'COM',   AREA: 'HULL',    ZONE: 'COLUMN',     MOD: 'WC200',       LEVEL: 'SW, Lower Column',    G_EREC: 'WC212', G_2PE: 'WC212', G_1PE: 'WC251', EREC: '',      PE2: '',      PE1: '',      BLK: 'WC270', YARD: 'COSCO', PS_BK: 'WC270', PS_TOT: 6,    PS_WO: 4,   PS_SO: 0,  PS_IST: 0, EQ_TOT: 0,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 140, ITEM: 'COM',   AREA: 'HULL',    ZONE: 'COLUMN',     MOD: 'WC200',       LEVEL: 'SW, Lower Column',    G_EREC: 'WC212', G_2PE: 'WC212', G_1PE: 'WC251', EREC: '',      PE2: '',      PE1: '',      BLK: 'WC280', YARD: 'COSCO', PS_BK: 'WC280', PS_TOT: 6,    PS_WO: 4,   PS_SO: 0,  PS_IST: 0, EQ_TOT: 0,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 141, ITEM: 'COM',   AREA: 'HULL',    ZONE: 'COLUMN',     MOD: 'WC200',       LEVEL: 'SW, Lower Column',    G_EREC: 'WC212', G_2PE: 'WC212', G_1PE: 'WC251', EREC: '',      PE2: '',      PE1: '',      BLK: 'WC420', YARD: 'COSCO', PS_BK: 'WC420', PS_TOT: 0,     PS_WO: 0,    PS_SO: 0,   PS_IST: 0,  EQ_TOT: 0,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 142, ITEM: 'COM',   AREA: 'HULL',    ZONE: 'COLUMN',     MOD: 'WC2M0',       LEVEL: 'SW, Middle Column',   G_EREC: 'WC2K2', G_2PE: 'WC2K2', G_1PE: 'WC2K0', EREC: 'WC2K2', PE2: 'WC2K2', PE1: '',      BLK: 'WC2K0', YARD: 'COSCO', PS_BK: 'WC2K0', PS_TOT: 141,  PS_WO: 0,    PS_SO: 0,   PS_IST: 0,  EQ_TOT: 0,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 143, ITEM: 'COM',   AREA: 'HULL',    ZONE: 'COLUMN',     MOD: 'WC2M0',       LEVEL: 'SW, Middle Column',   G_EREC: 'WC2K2', G_2PE: 'WC2K2', G_1PE: 'WC2L0', EREC: '',      PE2: '',      PE1: '',      BLK: 'WC2L0', YARD: 'COSCO', PS_BK: 'WC2L0', PS_TOT: 3,    PS_WO: 0,    PS_SO: 0,   PS_IST: 0,  EQ_TOT: 0,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 144, ITEM: 'COM',   AREA: 'HULL',    ZONE: 'COLUMN',     MOD: 'WC2M0',       LEVEL: 'SW, Middle Column',   G_EREC: 'WC2K2', G_2PE: 'WC2K2', G_1PE: 'WC2M0', EREC: '',      PE2: '',      PE1: '',      BLK: 'WC2M0', YARD: 'COSCO', PS_BK: 'WC2M0', PS_TOT: 3,    PS_WO: 0,    PS_SO: 0,   PS_IST: 0,  EQ_TOT: 0,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 145, ITEM: 'COM',   AREA: 'HULL',    ZONE: 'COLUMN',     MOD: 'WC2M0',       LEVEL: 'SW, Middle Column',   G_EREC: 'WC2K2', G_2PE: 'WC2K2', G_1PE: 'WC2N0', EREC: '',      PE2: '',      PE1: '',      BLK: 'WC2N0', YARD: 'COSCO', PS_BK: 'WC2N0', PS_TOT: 3,    PS_WO: 0,    PS_SO: 0,   PS_IST: 0,  EQ_TOT: 0,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 146, ITEM: 'COM',   AREA: 'HULL',    ZONE: 'COLUMN',     MOD: 'WC2M0',       LEVEL: 'SW, Middle Column',   G_EREC: 'WC2K2', G_2PE: 'WC2K2', G_1PE: 'WC2P0', EREC: '',      PE2: '',      PE1: '',      BLK: 'WC2P0', YARD: 'COSCO', PS_BK: 'WC2P0', PS_TOT: 119,  PS_WO: 0,    PS_SO: 0,   PS_IST: 0,  EQ_TOT: 0,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 147, ITEM: 'COM',   AREA: 'HULL',    ZONE: 'COLUMN',     MOD: 'WC2M0',       LEVEL: 'SW, Middle Column',   G_EREC: 'WC2K2', G_2PE: 'WC2K2', G_1PE: 'WC2Q0', EREC: '',      PE2: '',      PE1: '',      BLK: 'WC2Q0', YARD: 'COSCO', PS_BK: 'WC2Q0', PS_TOT: 3,    PS_WO: 0,    PS_SO: 0,   PS_IST: 0,  EQ_TOT: 0,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 148, ITEM: 'COM',   AREA: 'HULL',    ZONE: 'COLUMN',     MOD: 'WC2M0',       LEVEL: 'SW, Middle Column',   G_EREC: 'WC2K2', G_2PE: 'WC2K2', G_1PE: 'WC2R0', EREC: '',      PE2: '',      PE1: '',      BLK: 'WC2R0', YARD: 'COSCO', PS_BK: 'WC2R0', PS_TOT: 2,    PS_WO: 0,    PS_SO: 0,   PS_IST: 0,  EQ_TOT: 0,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 149, ITEM: 'COM',   AREA: 'HULL',    ZONE: 'COLUMN',     MOD: 'WC2M0',       LEVEL: 'SW, Middle Column',   G_EREC: 'WC2K2', G_2PE: 'WC2K2', G_1PE: 'WC2S0', EREC: '',      PE2: '',      PE1: '',      BLK: 'WC2S0', YARD: 'COSCO', PS_BK: 'WC2S0', PS_TOT: 1,    PS_WO: 0,    PS_SO: 0,   PS_IST: 0,  EQ_TOT: 0,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 150, ITEM: 'COM',   AREA: 'HULL',    ZONE: 'COLUMN',     MOD: 'WC2M0',       LEVEL: 'SW, Middle Column',   G_EREC: 'WC2K2', G_2PE: 'WC2K2', G_1PE: 'WC430', EREC: '',      PE2: '',      PE1: '',      BLK: 'WC430', YARD: 'COSCO', PS_BK: 'WC430', PS_TOT: 0,     PS_WO: 0,    PS_SO: 0,   PS_IST: 0,  EQ_TOT: 0,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 151, ITEM: 'COM',   AREA: 'HULL',    ZONE: 'COLUMN',     MOD: 'WC4A0',       LEVEL: 'SW, Middle Column',   G_EREC: 'WC4A0', G_2PE: 'WC4A0', G_1PE: 'WC4A0', EREC: 'WC4A0', PE2: '',      PE1: '',      BLK: 'WC4A0', YARD: 'HHI',   PS_BK: 'WC4A0', PS_TOT: 0,     PS_WO: 0,    PS_SO: 0,   PS_IST: 0,  EQ_TOT: 0,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 152, ITEM: 'COM',   AREA: 'HULL',    ZONE: 'COLUMN',     MOD: 'WC2U0',       LEVEL: 'SW, Upper Column',    G_EREC: 'WC2G2', G_2PE: 'WC2G2', G_1PE: 'WC2G1', EREC: 'WC2G2', PE2: 'WC2G2', PE1: 'WC2G1', BLK: 'WC2G0', YARD: 'HHI',   PS_BK: 'WC2G0', PS_TOT: 101,  PS_WO: 0,    PS_SO: 0,   PS_IST: 0,  EQ_TOT: 22, EQ_PO: 5,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 153, ITEM: 'COM',   AREA: 'HULL',    ZONE: 'COLUMN',     MOD: 'WC2U0',       LEVEL: 'SW, Upper Column',    G_EREC: 'WC2G2', G_2PE: 'WC2G2', G_1PE: 'WC2G1', EREC: '',      PE2: '',      PE1: '',      BLK: 'WC440', YARD: 'HHI',   PS_BK: 'WC440', PS_TOT: 0,     PS_WO: 0,    PS_SO: 0,   PS_IST: 0,  EQ_TOT: 0,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 154, ITEM: 'COM',   AREA: 'HULL',    ZONE: 'COLUMN',     MOD: 'WC2U0',       LEVEL: 'SW, Upper Column',    G_EREC: 'WC2G2', G_2PE: 'WC2G2', G_1PE: 'WC2H1', EREC: '',      PE2: '',      PE1: 'WC2H1', BLK: 'WC2H0', YARD: 'HHI',   PS_BK: 'WC2H0', PS_TOT: 384,  PS_WO: 0,    PS_SO: 0,   PS_IST: 0,  EQ_TOT: 0,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 155, ITEM: 'COM',   AREA: 'HULL',    ZONE: 'COLUMN',     MOD: 'WC2U0',       LEVEL: 'SW, Upper Column',    G_EREC: 'WC2G2', G_2PE: 'WC2G2', G_1PE: 'WC2H1', EREC: '',      PE2: '',      PE1: '',      BLK: 'WCW10', YARD: 'HHI',   PS_BK: 'WCW10', PS_TOT: 0,     PS_WO: 0,    PS_SO: 0,   PS_IST: 0,  EQ_TOT: 0,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 156, ITEM: 'COM',   AREA: 'HULL',    ZONE: 'COLUMN',     MOD: 'WC2U0',       LEVEL: 'SW, Upper Column',    G_EREC: 'WC2G2', G_2PE: 'WC2G2', G_1PE: 'WC2H1', EREC: '',      PE2: '',      PE1: '',      BLK: 'WC450', YARD: 'HHI',   PS_BK: 'WC450', PS_TOT: 0,     PS_WO: 0,    PS_SO: 0,   PS_IST: 0,  EQ_TOT: 0,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 157, ITEM: 'COM',   AREA: 'HULL',    ZONE: 'COLUMN',     MOD: 'WC2U0',       LEVEL: 'SW, Upper Column',    G_EREC: 'WC2G2', G_2PE: 'WC2G2', G_1PE: 'WC2H1', EREC: '',      PE2: '',      PE1: '',      BLK: 'WC2J0', YARD: 'HHI',   PS_BK: 'WC2J0', PS_TOT: 0,     PS_WO: 0,    PS_SO: 0,   PS_IST: 0,  EQ_TOT: 0,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 158, ITEM: 'COM',   AREA: 'HULL',    ZONE: 'COLUMN',     MOD: 'WC2U0',       LEVEL: 'SW, Upper Column',    G_EREC: 'WC2G2', G_2PE: 'WC2G2', G_1PE: 'WC2H1', EREC: '',      PE2: '',      PE1: '',      BLK: 'WF230', YARD: 'HHI',   PS_BK: 'WF230', PS_TOT: 3,    PS_WO: 0,    PS_SO: 0,   PS_IST: 0,  EQ_TOT: 0,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 159, ITEM: 'COM',   AREA: 'HULL',    ZONE: 'COLUMN',     MOD: 'WC2U0',       LEVEL: 'SW, Upper Column',    G_EREC: 'WC2G2', G_2PE: 'WC2G2', G_1PE: 'WC2H1', EREC: '',      PE2: '',      PE1: '',      BLK: 'SF220', YARD: 'HHI',   PS_BK: 'SF220', PS_TOT: 19,   PS_WO: 0,    PS_SO: 0,   PS_IST: 0,  EQ_TOT: 0,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 160, ITEM: 'COM',   AREA: 'HULL',    ZONE: 'COLUMN',     MOD: 'WC2U0',       LEVEL: 'SW, Upper Column',    G_EREC: 'WC2G2', G_2PE: 'WC2G2', G_1PE: 'WC2H1', EREC: '',      PE2: '',      PE1: '',      BLK: 'WCPA0', YARD: 'HHI',   PS_BK: 'WCPA0', PS_TOT: 0,     PS_WO: 0,    PS_SO: 0,   PS_IST: 0,  EQ_TOT: 0,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 161, ITEM: 'COM',   AREA: 'HULL',    ZONE: 'COLUMN',     MOD: 'WC2U0',       LEVEL: 'SW, Upper Column',    G_EREC: 'WCP70', G_2PE: 'WCP70', G_1PE: 'WCP70', EREC: 'WCP70', PE2: '',      PE1: '',      BLK: 'WCP70', YARD: 'HHI',   PS_BK: 'WCP70', PS_TOT: 0,     PS_WO: 0,    PS_SO: 0,   PS_IST: 0,  EQ_TOT: 0,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 162, ITEM: 'COM',   AREA: 'HULL',    ZONE: 'COLUMN',     MOD: 'WC2U0',       LEVEL: 'SW, Upper Column',    G_EREC: 'WCP80', G_2PE: 'WCP80', G_1PE: 'WCP80', EREC: 'WCP80', PE2: '',      PE1: '',      BLK: 'WCP80', YARD: 'HHI',   PS_BK: 'WCP80', PS_TOT: 0,     PS_WO: 0,    PS_SO: 0,   PS_IST: 0,  EQ_TOT: 4,  EQ_PO: 2,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 163, ITEM: 'COM',   AREA: 'HULL',    ZONE: 'COLUMN',     MOD: 'WC2U0',       LEVEL: 'SW, Upper Column',    G_EREC: 'WCP90', G_2PE: 'WCP90', G_1PE: 'WCP90', EREC: 'WCP90', PE2: '',      PE1: '',      BLK: 'WCP90', YARD: 'HHI',   PS_BK: 'WCP90', PS_TOT: 0,     PS_WO: 0,    PS_SO: 0,   PS_IST: 0,  EQ_TOT: 1,  EQ_PO: 1,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 164, ITEM: 'COM',   AREA: 'HULL',    ZONE: 'COLUMN',     MOD: 'EC100',       LEVEL: 'NE, Lower Column',    G_EREC: 'EC112', G_2PE: 'EC112', G_1PE: 'EC111', EREC: 'EC112', PE2: 'EC112', PE1: 'EC111', BLK: 'EC110', YARD: 'COSCO', PS_BK: 'EC110', PS_TOT: 177,  PS_WO: 4,   PS_SO: 0,  PS_IST: 0, EQ_TOT: 0,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 165, ITEM: 'COM',   AREA: 'HULL',    ZONE: 'COLUMN',     MOD: 'EC100',       LEVEL: 'NE, Lower Column',    G_EREC: 'EC112', G_2PE: 'EC112', G_1PE: 'EC111', EREC: '',      PE2: '',      PE1: '',      BLK: 'EC120', YARD: 'COSCO', PS_BK: 'EC120', PS_TOT: 4,    PS_WO: 3,   PS_SO: 0,  PS_IST: 0, EQ_TOT: 0,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 166, ITEM: 'COM',   AREA: 'HULL',    ZONE: 'COLUMN',     MOD: 'EC100',       LEVEL: 'NE, Lower Column',    G_EREC: 'EC112', G_2PE: 'EC112', G_1PE: 'EC111', EREC: '',      PE2: '',      PE1: '',      BLK: 'EC130', YARD: 'COSCO', PS_BK: 'EC130', PS_TOT: 8,    PS_WO: 11,  PS_SO: 0,  PS_IST: 0, EQ_TOT: 0,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 167, ITEM: 'COM',   AREA: 'HULL',    ZONE: 'COLUMN',     MOD: 'EC100',       LEVEL: 'NE, Lower Column',    G_EREC: 'EC112', G_2PE: 'EC112', G_1PE: 'EC111', EREC: '',      PE2: '',      PE1: '',      BLK: 'EC140', YARD: 'COSCO', PS_BK: 'EC140', PS_TOT: 6,    PS_WO: 9,   PS_SO: 0,  PS_IST: 0, EQ_TOT: 0,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 168, ITEM: 'COM',   AREA: 'HULL',    ZONE: 'COLUMN',     MOD: 'EC100',       LEVEL: 'NE, Lower Column',    G_EREC: 'EC112', G_2PE: 'EC112', G_1PE: 'EC111', EREC: '',      PE2: '',      PE1: '',      BLK: 'EC310', YARD: 'COSCO', PS_BK: 'EC310', PS_TOT: 0,     PS_WO: 0,    PS_SO: 0,   PS_IST: 0,  EQ_TOT: 0,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 169, ITEM: 'COM',   AREA: 'HULL',    ZONE: 'COLUMN',     MOD: 'EC100',       LEVEL: 'NE, Lower Column',    G_EREC: 'EC112', G_2PE: 'EC112', G_1PE: 'EC151', EREC: '',      PE2: '',      PE1: 'EC151', BLK: 'EC150', YARD: 'COSCO', PS_BK: 'EC150', PS_TOT: 166,  PS_WO: 3,   PS_SO: 0,  PS_IST: 0, EQ_TOT: 0,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 170, ITEM: 'COM',   AREA: 'HULL',    ZONE: 'COLUMN',     MOD: 'EC100',       LEVEL: 'NE, Lower Column',    G_EREC: 'EC112', G_2PE: 'EC112', G_1PE: 'EC151', EREC: '',      PE2: '',      PE1: '',      BLK: 'EC160', YARD: 'COSCO', PS_BK: 'EC160', PS_TOT: 4,    PS_WO: 3,   PS_SO: 0,  PS_IST: 0, EQ_TOT: 0,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 171, ITEM: 'COM',   AREA: 'HULL',    ZONE: 'COLUMN',     MOD: 'EC100',       LEVEL: 'NE, Lower Column',    G_EREC: 'EC112', G_2PE: 'EC112', G_1PE: 'EC151', EREC: '',      PE2: '',      PE1: '',      BLK: 'EC170', YARD: 'COSCO', PS_BK: 'EC170', PS_TOT: 7,    PS_WO: 9,   PS_SO: 0,  PS_IST: 0, EQ_TOT: 0,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 172, ITEM: 'COM',   AREA: 'HULL',    ZONE: 'COLUMN',     MOD: 'EC100',       LEVEL: 'NE, Lower Column',    G_EREC: 'EC112', G_2PE: 'EC112', G_1PE: 'EC151', EREC: '',      PE2: '',      PE1: '',      BLK: 'EC180', YARD: 'COSCO', PS_BK: 'EC180', PS_TOT: 7,    PS_WO: 8,   PS_SO: 0,  PS_IST: 0, EQ_TOT: 0,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 173, ITEM: 'COM',   AREA: 'HULL',    ZONE: 'COLUMN',     MOD: 'EC100',       LEVEL: 'NE, Lower Column',    G_EREC: 'EC112', G_2PE: 'EC112', G_1PE: 'EC151', EREC: '',      PE2: '',      PE1: '',      BLK: 'EC320', YARD: 'COSCO', PS_BK: 'EC320', PS_TOT: 0,     PS_WO: 0,    PS_SO: 0,   PS_IST: 0,  EQ_TOT: 0,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 174, ITEM: 'COM',   AREA: 'HULL',    ZONE: 'COLUMN',     MOD: 'EC1M0',       LEVEL: 'NE, Middle Column',   G_EREC: 'EC1K2', G_2PE: 'EC1K2', G_1PE: 'EC1K0', EREC: 'EC1K2', PE2: 'EC1K2', PE1: '',      BLK: 'EC1K0', YARD: 'COSCO', PS_BK: 'EC1K0', PS_TOT: 144,  PS_WO: 0,    PS_SO: 0,   PS_IST: 0,  EQ_TOT: 0,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 175, ITEM: 'COM',   AREA: 'HULL',    ZONE: 'COLUMN',     MOD: 'EC1M0',       LEVEL: 'NE, Middle Column',   G_EREC: 'EC1K2', G_2PE: 'EC1K2', G_1PE: 'EC1L0', EREC: '',      PE2: '',      PE1: '',      BLK: 'EC1L0', YARD: 'COSCO', PS_BK: 'EC1L0', PS_TOT: 3,    PS_WO: 0,    PS_SO: 0,   PS_IST: 0,  EQ_TOT: 0,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 176, ITEM: 'COM',   AREA: 'HULL',    ZONE: 'COLUMN',     MOD: 'EC1M0',       LEVEL: 'NE, Middle Column',   G_EREC: 'EC1K2', G_2PE: 'EC1K2', G_1PE: 'EC1M0', EREC: '',      PE2: '',      PE1: '',      BLK: 'EC1M0', YARD: 'COSCO', PS_BK: 'EC1M0', PS_TOT: 3,    PS_WO: 0,    PS_SO: 0,   PS_IST: 0,  EQ_TOT: 0,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 177, ITEM: 'COM',   AREA: 'HULL',    ZONE: 'COLUMN',     MOD: 'EC1M0',       LEVEL: 'NE, Middle Column',   G_EREC: 'EC1K2', G_2PE: 'EC1K2', G_1PE: 'EC1N0', EREC: '',      PE2: '',      PE1: '',      BLK: 'EC1N0', YARD: 'COSCO', PS_BK: 'EC1N0', PS_TOT: 3,    PS_WO: 0,    PS_SO: 0,   PS_IST: 0,  EQ_TOT: 0,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 178, ITEM: 'COM',   AREA: 'HULL',    ZONE: 'COLUMN',     MOD: 'EC1M0',       LEVEL: 'NE, Middle Column',   G_EREC: 'EC1K2', G_2PE: 'EC1K2', G_1PE: 'EC1P0', EREC: '',      PE2: '',      PE1: '',      BLK: 'EC1P0', YARD: 'COSCO', PS_BK: 'EC1P0', PS_TOT: 132,  PS_WO: 0,    PS_SO: 0,   PS_IST: 0,  EQ_TOT: 0,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 179, ITEM: 'COM',   AREA: 'HULL',    ZONE: 'COLUMN',     MOD: 'EC1M0',       LEVEL: 'NE, Middle Column',   G_EREC: 'EC1K2', G_2PE: 'EC1K2', G_1PE: 'EC1Q0', EREC: '',      PE2: '',      PE1: '',      BLK: 'EC1Q0', YARD: 'COSCO', PS_BK: 'EC1Q0', PS_TOT: 3,    PS_WO: 0,    PS_SO: 0,   PS_IST: 0,  EQ_TOT: 0,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 180, ITEM: 'COM',   AREA: 'HULL',    ZONE: 'COLUMN',     MOD: 'EC1M0',       LEVEL: 'NE, Middle Column',   G_EREC: 'EC1K2', G_2PE: 'EC1K2', G_1PE: 'EC1R0', EREC: '',      PE2: '',      PE1: '',      BLK: 'EC1R0', YARD: 'COSCO', PS_BK: 'EC1R0', PS_TOT: 2,    PS_WO: 0,    PS_SO: 0,   PS_IST: 0,  EQ_TOT: 0,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 181, ITEM: 'COM',   AREA: 'HULL',    ZONE: 'COLUMN',     MOD: 'EC1M0',       LEVEL: 'NE, Middle Column',   G_EREC: 'EC1K2', G_2PE: 'EC1K2', G_1PE: 'EC1S0', EREC: '',      PE2: '',      PE1: '',      BLK: 'EC1S0', YARD: 'COSCO', PS_BK: 'EC1S0', PS_TOT: 1,    PS_WO: 0,    PS_SO: 0,   PS_IST: 0,  EQ_TOT: 0,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 182, ITEM: 'COM',   AREA: 'HULL',    ZONE: 'COLUMN',     MOD: 'EC1M0',       LEVEL: 'NE, Middle Column',   G_EREC: 'EC1K2', G_2PE: 'EC1K2', G_1PE: 'EC330', EREC: '',      PE2: '',      PE1: '',      BLK: 'EC330', YARD: 'COSCO', PS_BK: 'EC330', PS_TOT: 0,     PS_WO: 0,    PS_SO: 0,   PS_IST: 0,  EQ_TOT: 0,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 183, ITEM: 'COM',   AREA: 'HULL',    ZONE: 'COLUMN',     MOD: 'EC3A0',       LEVEL: 'NE, Middle Column',   G_EREC: 'EC3A0', G_2PE: 'EC3A0', G_1PE: 'EC3A0', EREC: 'EC3A0', PE2: '',      PE1: '',      BLK: 'EC3A0', YARD: 'HHI',   PS_BK: 'EC3A0', PS_TOT: 0,     PS_WO: 0,    PS_SO: 0,   PS_IST: 0,  EQ_TOT: 0,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 184, ITEM: 'COM',   AREA: 'HULL',    ZONE: 'COLUMN',     MOD: 'EC1U0',       LEVEL: 'NE, Upper Column',    G_EREC: 'EC1G2', G_2PE: 'EC1G2', G_1PE: 'EC1G1', EREC: 'EC1G2', PE2: 'EC1G2', PE1: 'EC1G1', BLK: 'EC1G0', YARD: 'HHI',   PS_BK: 'EC1G0', PS_TOT: 102,  PS_WO: 0,    PS_SO: 0,   PS_IST: 0,  EQ_TOT: 23, EQ_PO: 5,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 185, ITEM: 'COM',   AREA: 'HULL',    ZONE: 'COLUMN',     MOD: 'EC1U0',       LEVEL: 'NE, Upper Column',    G_EREC: 'EC1G2', G_2PE: 'EC1G2', G_1PE: 'EC1G1', EREC: '',      PE2: '',      PE1: '',      BLK: 'EC340', YARD: 'HHI',   PS_BK: 'EC340', PS_TOT: 0,     PS_WO: 0,    PS_SO: 0,   PS_IST: 0,  EQ_TOT: 0,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 186, ITEM: 'COM',   AREA: 'HULL',    ZONE: 'COLUMN',     MOD: 'EC1U0',       LEVEL: 'NE, Upper Column',    G_EREC: 'EC1G2', G_2PE: 'EC1G2', G_1PE: 'EC1H1', EREC: '',      PE2: '',      PE1: 'EC1H1', BLK: 'EC1H0', YARD: 'HHI',   PS_BK: 'EC1H0', PS_TOT: 291,  PS_WO: 0,    PS_SO: 0,   PS_IST: 0,  EQ_TOT: 0,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 187, ITEM: 'COM',   AREA: 'HULL',    ZONE: 'COLUMN',     MOD: 'EC1U0',       LEVEL: 'NE, Upper Column',    G_EREC: 'EC1G2', G_2PE: 'EC1G2', G_1PE: 'EC1H1', EREC: '',      PE2: '',      PE1: '',      BLK: 'ECW10', YARD: 'HHI',   PS_BK: 'ECW10', PS_TOT: 0,     PS_WO: 0,    PS_SO: 0,   PS_IST: 0,  EQ_TOT: 0,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 188, ITEM: 'COM',   AREA: 'HULL',    ZONE: 'COLUMN',     MOD: 'EC1U0',       LEVEL: 'NE, Upper Column',    G_EREC: 'EC1G2', G_2PE: 'EC1G2', G_1PE: 'EC1H1', EREC: '',      PE2: '',      PE1: '',      BLK: 'EC350', YARD: 'HHI',   PS_BK: 'EC350', PS_TOT: 0,     PS_WO: 0,    PS_SO: 0,   PS_IST: 0,  EQ_TOT: 0,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 189, ITEM: 'COM',   AREA: 'HULL',    ZONE: 'COLUMN',     MOD: 'EC1U0',       LEVEL: 'NE, Upper Column',    G_EREC: 'EC1G2', G_2PE: 'EC1G2', G_1PE: 'EC1H1', EREC: '',      PE2: '',      PE1: '',      BLK: 'EC1J0', YARD: 'HHI',   PS_BK: 'EC1J0', PS_TOT: 0,     PS_WO: 0,    PS_SO: 0,   PS_IST: 0,  EQ_TOT: 0,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 190, ITEM: 'COM',   AREA: 'HULL',    ZONE: 'COLUMN',     MOD: 'EC1U0',       LEVEL: 'NE, Upper Column',    G_EREC: 'EC1G2', G_2PE: 'EC1G2', G_1PE: 'EC1H1', EREC: '',      PE2: '',      PE1: '',      BLK: 'NF230', YARD: 'HHI',   PS_BK: 'NF230', PS_TOT: 4,    PS_WO: 0,    PS_SO: 0,   PS_IST: 0,  EQ_TOT: 0,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 191, ITEM: 'COM',   AREA: 'HULL',    ZONE: 'COLUMN',     MOD: 'EC1U0',       LEVEL: 'NE, Upper Column',    G_EREC: 'EC1G2', G_2PE: 'EC1G2', G_1PE: 'EC1H1', EREC: '',      PE2: '',      PE1: '',      BLK: 'EF220', YARD: 'HHI',   PS_BK: 'EF220', PS_TOT: 6,    PS_WO: 0,    PS_SO: 0,   PS_IST: 0,  EQ_TOT: 0,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 192, ITEM: 'COM',   AREA: 'HULL',    ZONE: 'COLUMN',     MOD: 'EC1U0',       LEVEL: 'NE, Upper Column',    G_EREC: 'EC1G2', G_2PE: 'EC1G2', G_1PE: 'EC1H1', EREC: '',      PE2: '',      PE1: '',      BLK: 'ECP20', YARD: 'HHI',   PS_BK: 'ECP20', PS_TOT: 0,     PS_WO: 0,    PS_SO: 0,   PS_IST: 0,  EQ_TOT: 0,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 193, ITEM: 'COM',   AREA: 'HULL',    ZONE: 'COLUMN',     MOD: 'EC1U0',       LEVEL: 'NE, Upper Column',    G_EREC: 'ECP30', G_2PE: 'ECP30', G_1PE: 'ECP30', EREC: 'ECP30', PE2: '',      PE1: '',      BLK: 'ECP30', YARD: 'HHI',   PS_BK: 'ECP30', PS_TOT: 0,     PS_WO: 0,    PS_SO: 0,   PS_IST: 0,  EQ_TOT: 0,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 194, ITEM: 'COM',   AREA: 'HULL',    ZONE: 'COLUMN',     MOD: 'EC1U0',       LEVEL: 'NE, Upper Column',    G_EREC: 'ECP10', G_2PE: 'ECP10', G_1PE: 'ECP10', EREC: 'ECP10', PE2: '',      PE1: '',      BLK: 'ECP10', YARD: 'HHI',   PS_BK: 'ECP10', PS_TOT: 32,   PS_WO: 0,    PS_SO: 0,   PS_IST: 0,  EQ_TOT: 4,  EQ_PO: 1,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 195, ITEM: 'COM',   AREA: 'HULL',    ZONE: 'COLUMN',     MOD: 'EC200',       LEVEL: 'SE, Lower Column',    G_EREC: 'EC212', G_2PE: 'EC212', G_1PE: 'EC211', EREC: 'EC212', PE2: 'EC212', PE1: 'EC211', BLK: 'EC210', YARD: 'COSCO', PS_BK: 'EC210', PS_TOT: 155,  PS_WO: 4,   PS_SO: 0,  PS_IST: 0, EQ_TOT: 0,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 196, ITEM: 'COM',   AREA: 'HULL',    ZONE: 'COLUMN',     MOD: 'EC200',       LEVEL: 'SE, Lower Column',    G_EREC: 'EC212', G_2PE: 'EC212', G_1PE: 'EC211', EREC: '',      PE2: '',      PE1: '',      BLK: 'EC220', YARD: 'COSCO', PS_BK: 'EC220', PS_TOT: 4,    PS_WO: 3,   PS_SO: 0,  PS_IST: 0, EQ_TOT: 0,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 197, ITEM: 'COM',   AREA: 'HULL',    ZONE: 'COLUMN',     MOD: 'EC200',       LEVEL: 'SE, Lower Column',    G_EREC: 'EC212', G_2PE: 'EC212', G_1PE: 'EC211', EREC: '',      PE2: '',      PE1: '',      BLK: 'EC230', YARD: 'COSCO', PS_BK: 'EC230', PS_TOT: 8,    PS_WO: 11,  PS_SO: 0,  PS_IST: 0, EQ_TOT: 0,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 198, ITEM: 'COM',   AREA: 'HULL',    ZONE: 'COLUMN',     MOD: 'EC200',       LEVEL: 'SE, Lower Column',    G_EREC: 'EC212', G_2PE: 'EC212', G_1PE: 'EC211', EREC: '',      PE2: '',      PE1: '',      BLK: 'EC240', YARD: 'COSCO', PS_BK: 'EC240', PS_TOT: 6,    PS_WO: 9,   PS_SO: 0,  PS_IST: 0, EQ_TOT: 0,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 199, ITEM: 'COM',   AREA: 'HULL',    ZONE: 'COLUMN',     MOD: 'EC200',       LEVEL: 'SE, Lower Column',    G_EREC: 'EC212', G_2PE: 'EC212', G_1PE: 'EC211', EREC: '',      PE2: '',      PE1: '',      BLK: 'EC410', YARD: 'COSCO', PS_BK: 'EC410', PS_TOT: 0,     PS_WO: 0,    PS_SO: 0,   PS_IST: 0,  EQ_TOT: 0,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 200, ITEM: 'COM',   AREA: 'HULL',    ZONE: 'COLUMN',     MOD: 'EC200',       LEVEL: 'SE, Lower Column',    G_EREC: 'EC212', G_2PE: 'EC212', G_1PE: 'EC251', EREC: '',      PE2: '',      PE1: 'EC251', BLK: 'EC250', YARD: 'COSCO', PS_BK: 'EC250', PS_TOT: 132,  PS_WO: 3,   PS_SO: 0,  PS_IST: 0, EQ_TOT: 0,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 201, ITEM: 'COM',   AREA: 'HULL',    ZONE: 'COLUMN',     MOD: 'EC200',       LEVEL: 'SE, Lower Column',    G_EREC: 'EC212', G_2PE: 'EC212', G_1PE: 'EC251', EREC: '',      PE2: '',      PE1: '',      BLK: 'EC260', YARD: 'COSCO', PS_BK: 'EC260', PS_TOT: 4,    PS_WO: 3,   PS_SO: 0,  PS_IST: 0, EQ_TOT: 0,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 202, ITEM: 'COM',   AREA: 'HULL',    ZONE: 'COLUMN',     MOD: 'EC200',       LEVEL: 'SE, Lower Column',    G_EREC: 'EC212', G_2PE: 'EC212', G_1PE: 'EC251', EREC: '',      PE2: '',      PE1: '',      BLK: 'EC270', YARD: 'COSCO', PS_BK: 'EC270', PS_TOT: 4,    PS_WO: 3,   PS_SO: 0,  PS_IST: 0, EQ_TOT: 0,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 203, ITEM: 'COM',   AREA: 'HULL',    ZONE: 'COLUMN',     MOD: 'EC200',       LEVEL: 'SE, Lower Column',    G_EREC: 'EC212', G_2PE: 'EC212', G_1PE: 'EC251', EREC: '',      PE2: '',      PE1: '',      BLK: 'EC280', YARD: 'COSCO', PS_BK: 'EC280', PS_TOT: 4,    PS_WO: 2,   PS_SO: 0,  PS_IST: 0, EQ_TOT: 0,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 204, ITEM: 'COM',   AREA: 'HULL',    ZONE: 'COLUMN',     MOD: 'EC200',       LEVEL: 'SE, Lower Column',    G_EREC: 'EC212', G_2PE: 'EC212', G_1PE: 'EC251', EREC: '',      PE2: '',      PE1: '',      BLK: 'EC420', YARD: 'COSCO', PS_BK: 'EC420', PS_TOT: 0,     PS_WO: 0,    PS_SO: 0,   PS_IST: 0,  EQ_TOT: 0,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 205, ITEM: 'COM',   AREA: 'HULL',    ZONE: 'COLUMN',     MOD: 'EC2M0',       LEVEL: 'SE, Middle Column',   G_EREC: 'EC2K2', G_2PE: 'EC2K2', G_1PE: 'EC2K0', EREC: 'EC2K2', PE2: 'EC2K2', PE1: '',      BLK: 'EC2K0', YARD: 'COSCO', PS_BK: 'EC2K0', PS_TOT: 121,  PS_WO: 0,    PS_SO: 0,   PS_IST: 0,  EQ_TOT: 0,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 206, ITEM: 'COM',   AREA: 'HULL',    ZONE: 'COLUMN',     MOD: 'EC2M0',       LEVEL: 'SE, Middle Column',   G_EREC: 'EC2K2', G_2PE: 'EC2K2', G_1PE: 'EC2L0', EREC: '',      PE2: '',      PE1: '',      BLK: 'EC2L0', YARD: 'COSCO', PS_BK: 'EC2L0', PS_TOT: 3,    PS_WO: 0,    PS_SO: 0,   PS_IST: 0,  EQ_TOT: 0,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 207, ITEM: 'COM',   AREA: 'HULL',    ZONE: 'COLUMN',     MOD: 'EC2M0',       LEVEL: 'SE, Middle Column',   G_EREC: 'EC2K2', G_2PE: 'EC2K2', G_1PE: 'EC2M0', EREC: '',      PE2: '',      PE1: '',      BLK: 'EC2M0', YARD: 'COSCO', PS_BK: 'EC2M0', PS_TOT: 3,    PS_WO: 0,    PS_SO: 0,   PS_IST: 0,  EQ_TOT: 0,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 208, ITEM: 'COM',   AREA: 'HULL',    ZONE: 'COLUMN',     MOD: 'EC2M0',       LEVEL: 'SE, Middle Column',   G_EREC: 'EC2K2', G_2PE: 'EC2K2', G_1PE: 'EC2N0', EREC: '',      PE2: '',      PE1: '',      BLK: 'EC2N0', YARD: 'COSCO', PS_BK: 'EC2N0', PS_TOT: 3,    PS_WO: 0,    PS_SO: 0,   PS_IST: 0,  EQ_TOT: 0,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 209, ITEM: 'COM',   AREA: 'HULL',    ZONE: 'COLUMN',     MOD: 'EC2M0',       LEVEL: 'SE, Middle Column',   G_EREC: 'EC2K2', G_2PE: 'EC2K2', G_1PE: 'EC2P0', EREC: '',      PE2: '',      PE1: '',      BLK: 'EC2P0', YARD: 'COSCO', PS_BK: 'EC2P0', PS_TOT: 112,  PS_WO: 0,    PS_SO: 0,   PS_IST: 0,  EQ_TOT: 0,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 210, ITEM: 'COM',   AREA: 'HULL',    ZONE: 'COLUMN',     MOD: 'EC2M0',       LEVEL: 'SE, Middle Column',   G_EREC: 'EC2K2', G_2PE: 'EC2K2', G_1PE: 'EC2Q0', EREC: '',      PE2: '',      PE1: '',      BLK: 'EC2Q0', YARD: 'COSCO', PS_BK: 'EC2Q0', PS_TOT: 3,    PS_WO: 0,    PS_SO: 0,   PS_IST: 0,  EQ_TOT: 0,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 211, ITEM: 'COM',   AREA: 'HULL',    ZONE: 'COLUMN',     MOD: 'EC2M0',       LEVEL: 'SE, Middle Column',   G_EREC: 'EC2K2', G_2PE: 'EC2K2', G_1PE: 'EC2R0', EREC: '',      PE2: '',      PE1: '',      BLK: 'EC2R0', YARD: 'COSCO', PS_BK: 'EC2R0', PS_TOT: 2,    PS_WO: 0,    PS_SO: 0,   PS_IST: 0,  EQ_TOT: 0,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 212, ITEM: 'COM',   AREA: 'HULL',    ZONE: 'COLUMN',     MOD: 'EC2M0',       LEVEL: 'SE, Middle Column',   G_EREC: 'EC2K2', G_2PE: 'EC2K2', G_1PE: 'EC2S0', EREC: '',      PE2: '',      PE1: '',      BLK: 'EC2S0', YARD: 'COSCO', PS_BK: 'EC2S0', PS_TOT: 1,    PS_WO: 0,    PS_SO: 0,   PS_IST: 0,  EQ_TOT: 0,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 213, ITEM: 'COM',   AREA: 'HULL',    ZONE: 'COLUMN',     MOD: 'EC2M0',       LEVEL: 'SE, Middle Column',   G_EREC: 'EC2K2', G_2PE: 'EC2K2', G_1PE: 'EC430', EREC: '',      PE2: '',      PE1: '',      BLK: 'EC430', YARD: 'COSCO', PS_BK: 'EC430', PS_TOT: 0,     PS_WO: 0,    PS_SO: 0,   PS_IST: 0,  EQ_TOT: 0,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 214, ITEM: 'COM',   AREA: 'HULL',    ZONE: 'COLUMN',     MOD: 'EC4A0',       LEVEL: 'SE, Middle Column',   G_EREC: 'EC4A0', G_2PE: 'EC4A0', G_1PE: 'EC4A0', EREC: 'EC4A0', PE2: '',      PE1: '',      BLK: 'EC4A0', YARD: 'HHI',   PS_BK: 'EC4A0', PS_TOT: 0,     PS_WO: 0,    PS_SO: 0,   PS_IST: 0,  EQ_TOT: 0,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 215, ITEM: 'COM',   AREA: 'HULL',    ZONE: 'COLUMN',     MOD: 'EC2U0',       LEVEL: 'SE, Upper Column',    G_EREC: 'EC2G2', G_2PE: 'EC2G2', G_1PE: 'EC2G1', EREC: 'EC2G2', PE2: 'EC2G2', PE1: 'EC2G1', BLK: 'EC2G0', YARD: 'HHI',   PS_BK: 'EC2G0', PS_TOT: 91,   PS_WO: 0,    PS_SO: 0,   PS_IST: 0,  EQ_TOT: 27, EQ_PO: 9,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 216, ITEM: 'COM',   AREA: 'HULL',    ZONE: 'COLUMN',     MOD: 'EC2U0',       LEVEL: 'SE, Upper Column',    G_EREC: 'EC2G2', G_2PE: 'EC2G2', G_1PE: 'EC2G1', EREC: '',      PE2: '',      PE1: '',      BLK: 'EC440', YARD: 'HHI',   PS_BK: 'EC440', PS_TOT: 0,     PS_WO: 0,    PS_SO: 0,   PS_IST: 0,  EQ_TOT: 0,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 217, ITEM: 'COM',   AREA: 'HULL',    ZONE: 'COLUMN',     MOD: 'EC2U0',       LEVEL: 'SE, Upper Column',    G_EREC: 'EC2G2', G_2PE: 'EC2G2', G_1PE: 'EC2H1', EREC: '',      PE2: '',      PE1: 'EC2H1', BLK: 'EC2H0', YARD: 'HHI',   PS_BK: 'EC2H0', PS_TOT: 520,  PS_WO: 0,    PS_SO: 0,   PS_IST: 0,  EQ_TOT: 0,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 218, ITEM: 'COM',   AREA: 'HULL',    ZONE: 'COLUMN',     MOD: 'EC2U0',       LEVEL: 'SE, Upper Column',    G_EREC: 'EC2G2', G_2PE: 'EC2G2', G_1PE: 'EC2H1', EREC: '',      PE2: '',      PE1: '',      BLK: 'EC450', YARD: 'HHI',   PS_BK: 'EC450', PS_TOT: 0,     PS_WO: 0,    PS_SO: 0,   PS_IST: 0,  EQ_TOT: 0,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 219, ITEM: 'COM',   AREA: 'HULL',    ZONE: 'COLUMN',     MOD: 'EC2U0',       LEVEL: 'SE, Upper Column',    G_EREC: 'EC2G2', G_2PE: 'EC2G2', G_1PE: 'EC2H1', EREC: '',      PE2: '',      PE1: '',      BLK: 'EC2J0', YARD: 'HHI',   PS_BK: 'EC2J0', PS_TOT: 0,     PS_WO: 0,    PS_SO: 0,   PS_IST: 0,  EQ_TOT: 0,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 220, ITEM: 'COM',   AREA: 'HULL',    ZONE: 'COLUMN',     MOD: 'EC2U0',       LEVEL: 'SE, Upper Column',    G_EREC: 'EC2G2', G_2PE: 'EC2G2', G_1PE: 'EC2H1', EREC: '',      PE2: '',      PE1: '',      BLK: 'EF230', YARD: 'HHI',   PS_BK: 'EF230', PS_TOT: 0,     PS_WO: 0,    PS_SO: 0,   PS_IST: 0,  EQ_TOT: 0,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 221, ITEM: 'COM',   AREA: 'HULL',    ZONE: 'COLUMN',     MOD: 'EC2U0',       LEVEL: 'SE, Upper Column',    G_EREC: 'EC2G2', G_2PE: 'EC2G2', G_1PE: 'EC2H1', EREC: '',      PE2: '',      PE1: '',      BLK: 'SF230', YARD: 'HHI',   PS_BK: 'SF230', PS_TOT: 6,    PS_WO: 0,    PS_SO: 0,   PS_IST: 0,  EQ_TOT: 0,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 222, ITEM: 'COM',   AREA: 'HULL',    ZONE: 'COLUMN',     MOD: 'EC2U0',       LEVEL: 'South-East',          G_EREC: 'ECP40', G_2PE: 'ECP40', G_1PE: 'ECP40', EREC: 'ECP40', PE2: '',      PE1: '',      BLK: 'ECP40', YARD: 'HHI',   PS_BK: 'ECP40', PS_TOT: 30,   PS_WO: 0,    PS_SO: 0,   PS_IST: 0,  EQ_TOT: 3,  EQ_PO: 1,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 223, ITEM: 'COM',   AREA: 'HULL',    ZONE: 'COLUMN',     MOD: 'EC2U0',       LEVEL: 'South-East',          G_EREC: 'ECP50', G_2PE: 'ECP50', G_1PE: 'ECP50', EREC: 'ECP50', PE2: '',      PE1: '',      BLK: 'ECP50', YARD: 'HHI',   PS_BK: 'ECP50', PS_TOT: 27,   PS_WO: 0,    PS_SO: 0,   PS_IST: 0,  EQ_TOT: 0,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 224, ITEM: 'COM',   AREA: 'HULL',    ZONE: 'COLUMN',     MOD: 'EC2U0',       LEVEL: 'South-East',          G_EREC: 'ECP60', G_2PE: 'ECP60', G_1PE: 'ECP60', EREC: 'ECP60', PE2: '',      PE1: '',      BLK: 'ECP60', YARD: 'HHI',   PS_BK: 'ECP60', PS_TOT: 0,     PS_WO: 0,    PS_SO: 0,   PS_IST: 0,  EQ_TOT: 6,  EQ_PO: 5,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 225, ITEM: 'COM',   AREA: 'HULL',    ZONE: 'COLUMN',     MOD: 'EC2U0',       LEVEL: 'South-East',          G_EREC: 'ECP70', G_2PE: 'ECP70', G_1PE: 'ECP70', EREC: 'ECP70', PE2: '',      PE1: '',      BLK: 'ECP70', YARD: 'HHI',   PS_BK: 'ECP70', PS_TOT: 0,     PS_WO: 0,    PS_SO: 0,   PS_IST: 0,  EQ_TOT: 0,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 226, ITEM: 'COM',   AREA: 'HULL',    ZONE: 'UCF',        MOD: 'EF200',       LEVEL: 'East',                G_EREC: 'EF212', G_2PE: 'EF212', G_1PE: 'EF210', EREC: 'EF212', PE2: 'EF212', PE1: 'EF210', BLK: 'EF210', YARD: 'HHI',   PS_BK: 'EF210', PS_TOT: 45,   PS_WO: 0,    PS_SO: 0,   PS_IST: 0,  EQ_TOT: 0,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 227, ITEM: 'COM',   AREA: 'HULL',    ZONE: 'UCF',        MOD: 'EF200',       LEVEL: 'East',                G_EREC: 'EF212', G_2PE: 'EF212', G_1PE: 'EF21A', EREC: '',      PE2: '',      PE1: 'EF21A', BLK: 'EF21A', YARD: 'HHI',   PS_BK: 'EF21A', PS_TOT: 0,     PS_WO: 0,    PS_SO: 0,   PS_IST: 0,  EQ_TOT: 0,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 228, ITEM: 'COM',   AREA: 'HULL',    ZONE: 'UCF',        MOD: 'EF200',       LEVEL: 'East',                G_EREC: 'EF2B0', G_2PE: 'EF2B0', G_1PE: 'EF2B0', EREC: 'EF2B0', PE2: '',      PE1: '',      BLK: 'EF2B0', YARD: 'HHI',   PS_BK: 'EF2B0', PS_TOT: 0,     PS_WO: 0,    PS_SO: 0,   PS_IST: 0,  EQ_TOT: 0,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 229, ITEM: 'COM',   AREA: 'HULL',    ZONE: 'UCF',        MOD: 'EF200',       LEVEL: 'East',                G_EREC: 'EF2C0', G_2PE: 'EF2C0', G_1PE: 'EF2C0', EREC: 'EF2C0', PE2: '',      PE1: '',      BLK: 'EF2C0', YARD: 'HHI',   PS_BK: 'EF2C0', PS_TOT: 0,     PS_WO: 0,    PS_SO: 0,   PS_IST: 0,  EQ_TOT: 0,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 230, ITEM: 'COM',   AREA: 'HULL',    ZONE: 'UCF',        MOD: 'WF200',       LEVEL: 'West',                G_EREC: 'WF212', G_2PE: 'WF212', G_1PE: 'WF210', EREC: 'WF212', PE2: 'WF212', PE1: 'WF210', BLK: 'WF210', YARD: 'HHI',   PS_BK: 'WF210', PS_TOT: 8,    PS_WO: 0,    PS_SO: 0,   PS_IST: 0,  EQ_TOT: 0,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 231, ITEM: 'COM',   AREA: 'HULL',    ZONE: 'UCF',        MOD: 'WF200',       LEVEL: 'West',                G_EREC: 'WF212', G_2PE: 'WF212', G_1PE: 'WF2A0', EREC: '',      PE2: '',      PE1: 'WF2A0', BLK: 'WF2A0', YARD: 'HHI',   PS_BK: 'WF2A0', PS_TOT: 0,     PS_WO: 0,    PS_SO: 0,   PS_IST: 0,  EQ_TOT: 0,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 232, ITEM: 'COM',   AREA: 'HULL',    ZONE: 'UCF',        MOD: 'WF200',       LEVEL: 'West',                G_EREC: 'WF2B0', G_2PE: 'WF2B0', G_1PE: 'WF2B0', EREC: 'WF2B0', PE2: '',      PE1: '',      BLK: 'WF2B0', YARD: 'HHI',   PS_BK: 'WF2B0', PS_TOT: 0,     PS_WO: 0,    PS_SO: 0,   PS_IST: 0,  EQ_TOT: 0,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 233, ITEM: 'COM',   AREA: 'HULL',    ZONE: 'UCF',        MOD: 'WF200',       LEVEL: 'West',                G_EREC: 'WF2C0', G_2PE: 'WF2C0', G_1PE: 'WF2C0', EREC: 'WF2C0', PE2: '',      PE1: '',      BLK: 'WF2C0', YARD: 'HHI',   PS_BK: 'WF2C0', PS_TOT: 0,     PS_WO: 0,    PS_SO: 0,   PS_IST: 0,  EQ_TOT: 0,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 234, ITEM: 'COM',   AREA: 'HULL',    ZONE: 'UCF',        MOD: 'SF200',       LEVEL: 'South',               G_EREC: 'SF212', G_2PE: 'SF212', G_1PE: 'SF210', EREC: 'SF212', PE2: 'SF212', PE1: 'SF210', BLK: 'SF210', YARD: 'HHI',   PS_BK: 'SF210', PS_TOT: 27,   PS_WO: 0,    PS_SO: 0,   PS_IST: 0,  EQ_TOT: 0,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 235, ITEM: 'COM',   AREA: 'HULL',    ZONE: 'UCF',        MOD: 'SF200',       LEVEL: 'South',               G_EREC: 'SF212', G_2PE: 'SF212', G_1PE: 'SF2A0', EREC: '',      PE2: '',      PE1: 'SF2A0', BLK: 'SF2A0', YARD: 'HHI',   PS_BK: 'SF2A0', PS_TOT: 0,     PS_WO: 0,    PS_SO: 0,   PS_IST: 0,  EQ_TOT: 0,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 236, ITEM: 'COM',   AREA: 'HULL',    ZONE: 'UCF',        MOD: 'NF200',       LEVEL: 'North',               G_EREC: 'NF212', G_2PE: 'NF212', G_1PE: 'NF210', EREC: 'NF212', PE2: 'NF212', PE1: 'NF210', BLK: 'NF210', YARD: 'HHI',   PS_BK: 'NF210', PS_TOT: 22,   PS_WO: 0,    PS_SO: 0,   PS_IST: 0,  EQ_TOT: 3,  EQ_PO: 1,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 237, ITEM: 'COM',   AREA: 'HULL',    ZONE: 'UCF',        MOD: 'NF200',       LEVEL: 'North',               G_EREC: 'NF212', G_2PE: 'NF212', G_1PE: 'NF2A0', EREC: '',      PE2: '',      PE1: 'NF2A0', BLK: 'NF2A0', YARD: 'HHI',   PS_BK: 'NF2A0', PS_TOT: 0,     PS_WO: 0,    PS_SO: 0,   PS_IST: 0,  EQ_TOT: 0,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 238, ITEM: 'COM',   AREA: 'HULL',    ZONE: 'UCF',        MOD: 'NF200',       LEVEL: 'North',               G_EREC: 'NF212', G_2PE: 'NF212', G_1PE: 'NFP10', EREC: '',      PE2: '',      PE1: 'NFP10', BLK: 'NFP10', YARD: 'HHI',   PS_BK: 'NFP10', PS_TOT: 0,     PS_WO: 0,    PS_SO: 0,   PS_IST: 0,  EQ_TOT: 0,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 239, ITEM: 'COM',   AREA: 'HULL',    ZONE: 'UCF',        MOD: 'CF100',       LEVEL: 'Center',              G_EREC: 'CF112', G_2PE: 'CF112', G_1PE: 'CF111', EREC: 'CF112', PE2: 'CF112', PE1: 'CF111', BLK: 'CF110', YARD: 'HHI',   PS_BK: 'CF110', PS_TOT: 0,     PS_WO: 0,    PS_SO: 0,   PS_IST: 0,  EQ_TOT: 0,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 240, ITEM: 'COM',   AREA: 'HULL',    ZONE: 'UCF',        MOD: 'CF100',       LEVEL: 'Center',              G_EREC: 'CF112', G_2PE: 'CF112', G_1PE: 'CF111', EREC: '',      PE2: '',      PE1: '',      BLK: 'CF120', YARD: 'HHI',   PS_BK: 'CF120', PS_TOT: 0,     PS_WO: 0,    PS_SO: 0,   PS_IST: 0,  EQ_TOT: 0,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 241, ITEM: 'COM',   AREA: 'HULL',    ZONE: 'UCF',        MOD: 'CF100',       LEVEL: 'Center',              G_EREC: 'CF112', G_2PE: 'CF112', G_1PE: 'CF111', EREC: '',      PE2: '',      PE1: '',      BLK: 'CF130', YARD: 'HHI',   PS_BK: 'CF130', PS_TOT: 0,     PS_WO: 0,    PS_SO: 0,   PS_IST: 0,  EQ_TOT: 0,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 242, ITEM: 'COM',   AREA: 'HULL',    ZONE: 'UCF',        MOD: 'CF100',       LEVEL: 'Center',              G_EREC: 'CF112', G_2PE: 'CF112', G_1PE: 'CF111', EREC: '',      PE2: '',      PE1: '',      BLK: 'CF140', YARD: 'HHI',   PS_BK: 'CF140', PS_TOT: 0,     PS_WO: 0,    PS_SO: 0,   PS_IST: 0,  EQ_TOT: 0,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 243, ITEM: 'COM',   AREA: 'HULL',    ZONE: 'UCF',        MOD: 'CF100',       LEVEL: 'Center',              G_EREC: 'CF112', G_2PE: 'CF112', G_1PE: 'WF111', EREC: '',      PE2: '',      PE1: 'WF111', BLK: 'WF110', YARD: 'HHI',   PS_BK: 'WF110', PS_TOT: 0,     PS_WO: 0,    PS_SO: 0,   PS_IST: 0,  EQ_TOT: 0,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 244, ITEM: 'COM',   AREA: 'HULL',    ZONE: 'UCF',        MOD: 'CF100',       LEVEL: 'Center',              G_EREC: 'CF112', G_2PE: 'CF112', G_1PE: 'WF111', EREC: '',      PE2: '',      PE1: '',      BLK: 'WF1A0', YARD: 'HHI',   PS_BK: 'WF1A0', PS_TOT: 0,     PS_WO: 0,    PS_SO: 0,   PS_IST: 0,  EQ_TOT: 0,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 245, ITEM: 'COM',   AREA: 'HULL',    ZONE: 'UCF',        MOD: 'CF100',       LEVEL: 'Center',              G_EREC: 'CF112', G_2PE: 'CF112', G_1PE: 'WF121', EREC: '',      PE2: '',      PE1: 'WF121', BLK: 'WF120', YARD: 'HHI',   PS_BK: 'WF120', PS_TOT: 0,     PS_WO: 0,    PS_SO: 0,   PS_IST: 0,  EQ_TOT: 0,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 246, ITEM: 'COM',   AREA: 'HULL',    ZONE: 'UCF',        MOD: 'CF100',       LEVEL: 'Center',              G_EREC: 'CF112', G_2PE: 'CF112', G_1PE: 'WF121', EREC: '',      PE2: '',      PE1: '',      BLK: 'WF1B0', YARD: 'HHI',   PS_BK: 'WF1B0', PS_TOT: 0,     PS_WO: 0,    PS_SO: 0,   PS_IST: 0,  EQ_TOT: 0,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 247, ITEM: 'COM',   AREA: 'HULL',    ZONE: 'UCF',        MOD: 'CF100',       LEVEL: 'Center',              G_EREC: 'CF112', G_2PE: 'CF112', G_1PE: 'EF111', EREC: '',      PE2: '',      PE1: 'EF111', BLK: 'EF110', YARD: 'HHI',   PS_BK: 'EF110', PS_TOT: 0,     PS_WO: 0,    PS_SO: 0,   PS_IST: 0,  EQ_TOT: 0,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 248, ITEM: 'COM',   AREA: 'HULL',    ZONE: 'UCF',        MOD: 'CF100',       LEVEL: 'Center',              G_EREC: 'CF112', G_2PE: 'CF112', G_1PE: 'EF111', EREC: '',      PE2: '',      PE1: '',      BLK: 'EF1A0', YARD: 'HHI',   PS_BK: 'EF1A0', PS_TOT: 0,     PS_WO: 0,    PS_SO: 0,   PS_IST: 0,  EQ_TOT: 0,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 249, ITEM: 'COM',   AREA: 'HULL',    ZONE: 'UCF',        MOD: 'CF100',       LEVEL: 'Center',              G_EREC: 'CF112', G_2PE: 'CF112', G_1PE: 'EF121', EREC: '',      PE2: '',      PE1: 'EF121', BLK: 'EF120', YARD: 'HHI',   PS_BK: 'EF120', PS_TOT: 0,     PS_WO: 0,    PS_SO: 0,   PS_IST: 0,  EQ_TOT: 0,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 250, ITEM: 'COM',   AREA: 'HULL',    ZONE: 'UCF',        MOD: 'CF100',       LEVEL: 'Center',              G_EREC: 'CF112', G_2PE: 'CF112', G_1PE: 'EF120', EREC: '',      PE2: '',      PE1: '',      BLK: 'EF1B0', YARD: 'HHI',   PS_BK: 'EF1B0', PS_TOT: 0,     PS_WO: 0,    PS_SO: 0,   PS_IST: 0,  EQ_TOT: 0,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 251, ITEM: 'COM',   AREA: 'HULL',    ZONE: 'CAISION',    MOD: 'CC410',       LEVEL: 'NW',                  G_EREC: 'CC410', G_2PE: 'CC410', G_1PE: 'CC410', EREC: 'CC410', PE2: '',      PE1: '',      BLK: 'CC410', YARD: 'HHI',   PS_BK: 'CC410', PS_TOT: 0,     PS_WO: 0,    PS_SO: 0,   PS_IST: 0,  EQ_TOT: 0,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 252, ITEM: 'COM',   AREA: 'HULL',    ZONE: 'CAISION',    MOD: 'CC420',       LEVEL: 'SW',                  G_EREC: 'CC420', G_2PE: 'CC420', G_1PE: 'CC420', EREC: 'CC420', PE2: '',      PE1: '',      BLK: 'CC420', YARD: 'HHI',   PS_BK: 'CC420', PS_TOT: 0,     PS_WO: 0,    PS_SO: 0,   PS_IST: 0,  EQ_TOT: 0,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 253, ITEM: 'COM',   AREA: 'HULL',    ZONE: 'CAISION',    MOD: 'CC430',       LEVEL: 'NE',                  G_EREC: 'CC430', G_2PE: 'CC430', G_1PE: 'CC430', EREC: 'CC430', PE2: '',      PE1: '',      BLK: 'CC430', YARD: 'HHI',   PS_BK: 'CC430', PS_TOT: 0,     PS_WO: 0,    PS_SO: 0,   PS_IST: 0,  EQ_TOT: 0,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 254, ITEM: 'COM',   AREA: 'HULL',    ZONE: 'CAISION',    MOD: 'CC440',       LEVEL: 'SE',                  G_EREC: 'CC440', G_2PE: 'CC440', G_1PE: 'CC440', EREC: 'CC440', PE2: '',      PE1: '',      BLK: 'CC440', YARD: 'HHI',   PS_BK: 'CC440', PS_TOT: 0,     PS_WO: 0,    PS_SO: 0,   PS_IST: 0,  EQ_TOT: 0,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 255, ITEM: 'COM',   AREA: 'HULL',    ZONE: 'RISER PIPE', MOD: '',            LEVEL: 'NW',                  G_EREC: 'RC410', G_2PE: 'RC410', G_1PE: 'RC410', EREC: 'RC410', PE2: '',      PE1: '',      BLK: 'RC410', YARD: 'HHI',   PS_BK: 'RC410', PS_TOT: 0,     PS_WO: 0,    PS_SO: 0,   PS_IST: 0,  EQ_TOT: 0,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 256, ITEM: 'COM',   AREA: 'HULL',    ZONE: 'RISER PIPE', MOD: '',            LEVEL: 'SW',                  G_EREC: 'RC420', G_2PE: 'RC420', G_1PE: 'RC420', EREC: 'RC420', PE2: '',      PE1: '',      BLK: 'RC420', YARD: 'HHI',   PS_BK: 'RC420', PS_TOT: 0,     PS_WO: 0,    PS_SO: 0,   PS_IST: 0,  EQ_TOT: 0,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 257, ITEM: 'COM',   AREA: 'HULL',    ZONE: 'SUMP PILE',  MOD: '',            LEVEL: 'NE',                  G_EREC: 'SC410', G_2PE: 'SC410', G_1PE: 'SC410', EREC: 'SC410', PE2: '',      PE1: '',      BLK: 'SC410', YARD: 'HHI',   PS_BK: 'SC410', PS_TOT: 0,     PS_WO: 0,    PS_SO: 0,   PS_IST: 0,  EQ_TOT: 0,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 258, ITEM: 'COM',   AREA: 'HULL',    ZONE: 'SUMP PILE',  MOD: '',            LEVEL: 'SE',                  G_EREC: 'SC420', G_2PE: 'SC420', G_1PE: 'SC420', EREC: 'SC420', PE2: '',      PE1: '',      BLK: 'SC420', YARD: 'HHI',   PS_BK: 'SC420', PS_TOT: 0,     PS_WO: 0,    PS_SO: 0,   PS_IST: 0,  EQ_TOT: 0,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 259, ITEM: 'COM',   AREA: 'TOPSIDE', ZONE: 'NORTH',      MOD: 'ND100',       LEVEL: 'Production Deck',     G_EREC: 'ND110', G_2PE: 'ND110', G_1PE: 'ND110', EREC: 'ND110', PE2: 'ND110', PE1: 'ND110', BLK: 'ND111', YARD: 'HHI',   PS_BK: 'ND111', PS_TOT: 469,  PS_WO: 0,    PS_SO: 0,   PS_IST: 0,  EQ_TOT: 16, EQ_PO: 9,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 260, ITEM: 'COM',   AREA: 'TOPSIDE', ZONE: 'NORTH',      MOD: 'ND100',       LEVEL: 'Production Deck',     G_EREC: 'ND110', G_2PE: 'ND110', G_1PE: 'ND110', EREC: '',      PE2: '',      PE1: '',      BLK: 'ND112', YARD: 'HHI',   PS_BK: 'ND112', PS_TOT: 0,     PS_WO: 0,    PS_SO: 0,   PS_IST: 0,  EQ_TOT: 0,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 261, ITEM: 'COM',   AREA: 'TOPSIDE', ZONE: 'NORTH',      MOD: 'ND100',       LEVEL: 'Production Deck',     G_EREC: 'ND110', G_2PE: 'ND110', G_1PE: 'ND110', EREC: '',      PE2: '',      PE1: '',      BLK: 'ND113', YARD: 'HHI',   PS_BK: 'ND113', PS_TOT: 0,     PS_WO: 0,    PS_SO: 0,   PS_IST: 0,  EQ_TOT: 0,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 262, ITEM: 'COM',   AREA: 'TOPSIDE', ZONE: 'NORTH',      MOD: 'ND100',       LEVEL: 'Production Deck',     G_EREC: 'ND110', G_2PE: 'ND110', G_1PE: 'ND110', EREC: '',      PE2: '',      PE1: '',      BLK: 'ND114', YARD: 'HHI',   PS_BK: 'ND114', PS_TOT: 0,     PS_WO: 0,    PS_SO: 0,   PS_IST: 0,  EQ_TOT: 0,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 263, ITEM: 'COM',   AREA: 'TOPSIDE', ZONE: 'NORTH',      MOD: 'ND100',       LEVEL: 'Production Deck',     G_EREC: 'ND110', G_2PE: 'ND110', G_1PE: 'ND110', EREC: '',      PE2: '',      PE1: '',      BLK: 'NC111', YARD: 'HHI',   PS_BK: 'NC111', PS_TOT: 0,     PS_WO: 0,    PS_SO: 0,   PS_IST: 0,  EQ_TOT: 0,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 264, ITEM: 'COM',   AREA: 'TOPSIDE', ZONE: 'NORTH',      MOD: 'ND100',       LEVEL: 'Production Deck',     G_EREC: 'ND110', G_2PE: 'ND110', G_1PE: 'ND110', EREC: '',      PE2: '',      PE1: '',      BLK: 'ND1A1', YARD: 'HHI',   PS_BK: 'ND1A1', PS_TOT: 0,     PS_WO: 0,    PS_SO: 0,   PS_IST: 0,  EQ_TOT: 0,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 265, ITEM: 'COM',   AREA: 'TOPSIDE', ZONE: 'NORTH',      MOD: 'ND100',       LEVEL: 'Production Deck',     G_EREC: 'ND110', G_2PE: 'ND110', G_1PE: 'NV111', EREC: '',      PE2: '',      PE1: '',      BLK: 'NV111', YARD: 'HHI',   PS_BK: 'NV111', PS_TOT: 0,     PS_WO: 0,    PS_SO: 0,   PS_IST: 0,  EQ_TOT: 0,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 266, ITEM: 'COM',   AREA: 'TOPSIDE', ZONE: 'NORTH',      MOD: 'ND100',       LEVEL: 'Production Deck',     G_EREC: 'ND110', G_2PE: 'ND110', G_1PE: 'NV121', EREC: '',      PE2: '',      PE1: '',      BLK: 'NV121', YARD: 'HHI',   PS_BK: 'NV121', PS_TOT: 0,     PS_WO: 0,    PS_SO: 0,   PS_IST: 0,  EQ_TOT: 0,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 267, ITEM: 'COM',   AREA: 'TOPSIDE', ZONE: 'NORTH',      MOD: 'ND100',       LEVEL: 'Production Deck',     G_EREC: 'ND110', G_2PE: 'ND110', G_1PE: 'NV131', EREC: '',      PE2: '',      PE1: '',      BLK: 'NV131', YARD: 'HHI',   PS_BK: 'NV131', PS_TOT: 0,     PS_WO: 0,    PS_SO: 0,   PS_IST: 0,  EQ_TOT: 0,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 268, ITEM: 'COM',   AREA: 'TOPSIDE', ZONE: 'NORTH',      MOD: 'ND100',       LEVEL: 'Production Deck',     G_EREC: 'ND110', G_2PE: 'ND110', G_1PE: 'NV141', EREC: '',      PE2: '',      PE1: '',      BLK: 'NV141', YARD: 'HHI',   PS_BK: 'NV141', PS_TOT: 0,     PS_WO: 0,    PS_SO: 0,   PS_IST: 0,  EQ_TOT: 0,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 269, ITEM: 'COM',   AREA: 'TOPSIDE', ZONE: 'NORTH',      MOD: 'ND100',       LEVEL: 'Production Deck',     G_EREC: 'ND110', G_2PE: 'ND110', G_1PE: 'NV151', EREC: '',      PE2: '',      PE1: '',      BLK: 'NV151', YARD: 'HHI',   PS_BK: 'NV151', PS_TOT: 0,     PS_WO: 0,    PS_SO: 0,   PS_IST: 0,  EQ_TOT: 0,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 270, ITEM: 'COM',   AREA: 'TOPSIDE', ZONE: 'NORTH',      MOD: 'ND100',       LEVEL: 'Production Deck',     G_EREC: 'ND120', G_2PE: 'ND120', G_1PE: 'ND120', EREC: 'ND120', PE2: 'ND120', PE1: 'ND120', BLK: 'ND121', YARD: 'HHI',   PS_BK: 'ND121', PS_TOT: 478,  PS_WO: 0,    PS_SO: 0,   PS_IST: 0,  EQ_TOT: 34, EQ_PO: 12, EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 271, ITEM: 'COM',   AREA: 'TOPSIDE', ZONE: 'NORTH',      MOD: 'ND100',       LEVEL: 'Production Deck',     G_EREC: 'ND120', G_2PE: 'ND120', G_1PE: 'ND120', EREC: '',      PE2: '',      PE1: '',      BLK: 'ND122', YARD: 'HHI',   PS_BK: 'ND122', PS_TOT: 0,     PS_WO: 0,    PS_SO: 0,   PS_IST: 0,  EQ_TOT: 0,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 272, ITEM: 'COM',   AREA: 'TOPSIDE', ZONE: 'NORTH',      MOD: 'ND100',       LEVEL: 'Production Deck',     G_EREC: 'ND120', G_2PE: 'ND120', G_1PE: 'ND120', EREC: '',      PE2: '',      PE1: '',      BLK: 'ND123', YARD: 'HHI',   PS_BK: 'ND123', PS_TOT: 0,     PS_WO: 0,    PS_SO: 0,   PS_IST: 0,  EQ_TOT: 0,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 273, ITEM: 'COM',   AREA: 'TOPSIDE', ZONE: 'NORTH',      MOD: 'ND100',       LEVEL: 'Production Deck',     G_EREC: 'ND120', G_2PE: 'ND120', G_1PE: 'ND120', EREC: '',      PE2: '',      PE1: '',      BLK: 'NC121', YARD: 'HHI',   PS_BK: 'NC121', PS_TOT: 0,     PS_WO: 0,    PS_SO: 0,   PS_IST: 0,  EQ_TOT: 0,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 274, ITEM: 'COM',   AREA: 'TOPSIDE', ZONE: 'NORTH',      MOD: 'ND100',       LEVEL: 'Production Deck',     G_EREC: 'ND120', G_2PE: 'ND120', G_1PE: 'ND120', EREC: '',      PE2: '',      PE1: '',      BLK: 'NT111', YARD: 'HHI',   PS_BK: 'NT111', PS_TOT: 0,     PS_WO: 0,    PS_SO: 0,   PS_IST: 0,  EQ_TOT: 0,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 275, ITEM: 'COM',   AREA: 'TOPSIDE', ZONE: 'NORTH',      MOD: 'ND100',       LEVEL: 'Production Deck',     G_EREC: 'ND120', G_2PE: 'ND120', G_1PE: 'ND120', EREC: '',      PE2: '',      PE1: '',      BLK: 'NT121', YARD: 'HHI',   PS_BK: 'NT121', PS_TOT: 0,     PS_WO: 0,    PS_SO: 0,   PS_IST: 0,  EQ_TOT: 0,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 276, ITEM: 'COM',   AREA: 'TOPSIDE', ZONE: 'NORTH',      MOD: 'ND100',       LEVEL: 'Production Deck',     G_EREC: 'ND120', G_2PE: 'ND120', G_1PE: 'ND130', EREC: '',      PE2: '',      PE1: 'ND130', BLK: 'ND131', YARD: 'HHI',   PS_BK: 'ND131', PS_TOT: 261,  PS_WO: 0,    PS_SO: 0,   PS_IST: 0,  EQ_TOT: 0,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 277, ITEM: 'COM',   AREA: 'TOPSIDE', ZONE: 'NORTH',      MOD: 'ND100',       LEVEL: 'Production Deck',     G_EREC: 'ND120', G_2PE: 'ND120', G_1PE: 'ND130', EREC: '',      PE2: '',      PE1: '',      BLK: 'NC131', YARD: 'HHI',   PS_BK: 'NC131', PS_TOT: 0,     PS_WO: 0,    PS_SO: 0,   PS_IST: 0,  EQ_TOT: 0,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 278, ITEM: 'COM',   AREA: 'TOPSIDE', ZONE: 'NORTH',      MOD: 'ND100',       LEVEL: 'Production Deck',     G_EREC: 'ND120', G_2PE: 'ND120', G_1PE: 'ND140', EREC: '',      PE2: '',      PE1: 'ND140', BLK: 'ND141', YARD: 'HHI',   PS_BK: 'ND141', PS_TOT: 1,    PS_WO: 0,    PS_SO: 0,   PS_IST: 0,  EQ_TOT: 0,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 279, ITEM: 'COM',   AREA: 'TOPSIDE', ZONE: 'NORTH',      MOD: 'ND100',       LEVEL: 'Production Deck',     G_EREC: 'ND120', G_2PE: 'ND120', G_1PE: 'ND140', EREC: '',      PE2: '',      PE1: '',      BLK: 'ND142', YARD: 'HHI',   PS_BK: 'ND142', PS_TOT: 0,     PS_WO: 0,    PS_SO: 0,   PS_IST: 0,  EQ_TOT: 0,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 280, ITEM: 'COM',   AREA: 'TOPSIDE', ZONE: 'NORTH',      MOD: 'ND100',       LEVEL: 'Production Deck',     G_EREC: 'ND120', G_2PE: 'ND120', G_1PE: 'ND140', EREC: '',      PE2: '',      PE1: '',      BLK: 'ND143', YARD: 'HHI',   PS_BK: 'ND143', PS_TOT: 0,     PS_WO: 0,    PS_SO: 0,   PS_IST: 0,  EQ_TOT: 0,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 281, ITEM: 'COM',   AREA: 'TOPSIDE', ZONE: 'NORTH',      MOD: 'ND100',       LEVEL: 'Production Deck',     G_EREC: 'ND120', G_2PE: 'ND120', G_1PE: 'ND140', EREC: '',      PE2: '',      PE1: '',      BLK: 'ND144', YARD: 'HHI',   PS_BK: 'ND144', PS_TOT: 0,     PS_WO: 0,    PS_SO: 0,   PS_IST: 0,  EQ_TOT: 0,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 282, ITEM: 'COM',   AREA: 'TOPSIDE', ZONE: 'NORTH',      MOD: 'ND100',       LEVEL: 'Production Deck',     G_EREC: 'ND120', G_2PE: 'ND120', G_1PE: 'ND140', EREC: '',      PE2: '',      PE1: '',      BLK: 'NC141', YARD: 'HHI',   PS_BK: 'NC141', PS_TOT: 0,     PS_WO: 0,    PS_SO: 0,   PS_IST: 0,  EQ_TOT: 0,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 283, ITEM: 'COM',   AREA: 'TOPSIDE', ZONE: 'NORTH',      MOD: 'ND100',       LEVEL: 'Production Deck',     G_EREC: 'ND120', G_2PE: 'ND120', G_1PE: 'NV161', EREC: '',      PE2: '',      PE1: '',      BLK: 'NV161', YARD: 'HHI',   PS_BK: 'NV161', PS_TOT: 0,     PS_WO: 0,    PS_SO: 0,   PS_IST: 0,  EQ_TOT: 0,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 284, ITEM: 'COM',   AREA: 'TOPSIDE', ZONE: 'NORTH',      MOD: 'ND100',       LEVEL: 'Production Deck',     G_EREC: 'ND120', G_2PE: 'ND120', G_1PE: 'NV171', EREC: '',      PE2: '',      PE1: '',      BLK: 'NV171', YARD: 'HHI',   PS_BK: 'NV171', PS_TOT: 0,     PS_WO: 0,    PS_SO: 0,   PS_IST: 0,  EQ_TOT: 0,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 285, ITEM: 'COM',   AREA: 'TOPSIDE', ZONE: 'NORTH',      MOD: 'ND100',       LEVEL: 'Production Deck',     G_EREC: 'ND120', G_2PE: 'ND120', G_1PE: 'NV181', EREC: '',      PE2: '',      PE1: '',      BLK: 'NV181', YARD: 'HHI',   PS_BK: 'NV181', PS_TOT: 0,     PS_WO: 0,    PS_SO: 0,   PS_IST: 0,  EQ_TOT: 0,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 286, ITEM: 'COM',   AREA: 'TOPSIDE', ZONE: 'NORTH',      MOD: 'ND100',       LEVEL: 'Production Deck',     G_EREC: 'ND120', G_2PE: 'ND120', G_1PE: 'NV191', EREC: '',      PE2: '',      PE1: '',      BLK: 'NV191', YARD: 'HHI',   PS_BK: 'NV191', PS_TOT: 0,     PS_WO: 0,    PS_SO: 0,   PS_IST: 0,  EQ_TOT: 0,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 287, ITEM: 'COM',   AREA: 'TOPSIDE', ZONE: 'NORTH',      MOD: 'ND100',       LEVEL: 'Production Deck',     G_EREC: 'ND120', G_2PE: 'ND120', G_1PE: 'NV1A1', EREC: '',      PE2: '',      PE1: '',      BLK: 'NV1A1', YARD: 'HHI',   PS_BK: 'NV1A1', PS_TOT: 0,     PS_WO: 0,    PS_SO: 0,   PS_IST: 0,  EQ_TOT: 0,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 288, ITEM: 'COM',   AREA: 'TOPSIDE', ZONE: 'NORTH',      MOD: 'ND100',       LEVEL: 'Production Deck',     G_EREC: 'ND120', G_2PE: 'ND120', G_1PE: 'NV1B1', EREC: '',      PE2: '',      PE1: '',      BLK: 'NV1B1', YARD: 'HHI',   PS_BK: 'NV1B1', PS_TOT: 0,     PS_WO: 0,    PS_SO: 0,   PS_IST: 0,  EQ_TOT: 0,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 289, ITEM: 'COM',   AREA: 'TOPSIDE', ZONE: 'NORTH',      MOD: 'ND200',       LEVEL: 'Mezz. Deck',          G_EREC: 'ND210', G_2PE: 'ND210', G_1PE: 'ND211', EREC: 'ND210', PE2: '',      PE1: '',      BLK: 'ND211', YARD: 'HHI',   PS_BK: 'ND211', PS_TOT: 63,   PS_WO: 0,    PS_SO: 0,   PS_IST: 0,  EQ_TOT: 2,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 290, ITEM: 'COM',   AREA: 'TOPSIDE', ZONE: 'NORTH',      MOD: 'ND200',       LEVEL: 'Mezz. Deck',          G_EREC: 'ND220', G_2PE: 'ND220', G_1PE: 'ND221', EREC: 'ND220', PE2: '',      PE1: '',      BLK: 'ND221', YARD: 'HHI',   PS_BK: 'ND221', PS_TOT: 279,  PS_WO: 0,    PS_SO: 0,   PS_IST: 0,  EQ_TOT: 1,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 291, ITEM: 'COM',   AREA: 'TOPSIDE', ZONE: 'NORTH',      MOD: 'ND200',       LEVEL: 'Mezz. Deck',          G_EREC: 'ND230', G_2PE: 'ND230', G_1PE: 'ND231', EREC: 'ND230', PE2: 'ND230', PE1: '',      BLK: 'ND231', YARD: 'HHI',   PS_BK: 'ND231', PS_TOT: 200,  PS_WO: 0,    PS_SO: 0,   PS_IST: 0,  EQ_TOT: 9,  EQ_PO: 3,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 292, ITEM: 'COM',   AREA: 'TOPSIDE', ZONE: 'NORTH',      MOD: 'ND200',       LEVEL: 'Mezz. Deck',          G_EREC: 'ND230', G_2PE: 'ND230', G_1PE: 'ND240', EREC: '',      PE2: '',      PE1: 'ND240', BLK: 'ND241', YARD: 'HHI',   PS_BK: 'ND241', PS_TOT: 319,  PS_WO: 0,    PS_SO: 0,   PS_IST: 0,  EQ_TOT: 0,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 293, ITEM: 'COM',   AREA: 'TOPSIDE', ZONE: 'NORTH',      MOD: 'ND200',       LEVEL: 'Mezz. Deck',          G_EREC: 'ND230', G_2PE: 'ND230', G_1PE: 'ND240', EREC: '',      PE2: '',      PE1: '',      BLK: 'ND242', YARD: 'HHI',   PS_BK: 'ND242', PS_TOT: 0,     PS_WO: 0,    PS_SO: 0,   PS_IST: 0,  EQ_TOT: 0,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 294, ITEM: 'COM',   AREA: 'TOPSIDE', ZONE: 'NORTH',      MOD: 'ND200',       LEVEL: 'Mezz. Deck',          G_EREC: 'ND250', G_2PE: 'ND250', G_1PE: 'ND251', EREC: 'ND250', PE2: '',      PE1: '',      BLK: 'ND251', YARD: 'HHI',   PS_BK: 'ND251', PS_TOT: 0,     PS_WO: 0,    PS_SO: 0,   PS_IST: 0,  EQ_TOT: 0,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 295, ITEM: 'COM',   AREA: 'TOPSIDE', ZONE: 'NORTH',      MOD: 'ND300',       LEVEL: 'Main Deck',           G_EREC: 'ND310', G_2PE: 'ND310', G_1PE: 'ND311', EREC: 'ND310', PE2: 'ND310', PE1: '',      BLK: 'ND311', YARD: 'HHI',   PS_BK: 'ND311', PS_TOT: 246,  PS_WO: 0,    PS_SO: 0,   PS_IST: 0,  EQ_TOT: 13, EQ_PO: 6,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 296, ITEM: 'COM',   AREA: 'TOPSIDE', ZONE: 'NORTH',      MOD: 'ND300',       LEVEL: 'Main Deck',           G_EREC: 'ND310', G_2PE: 'ND310', G_1PE: 'ND320', EREC: '',      PE2: '',      PE1: 'ND320', BLK: 'ND321', YARD: 'HHI',   PS_BK: 'ND321', PS_TOT: 1237, PS_WO: 0,    PS_SO: 0,   PS_IST: 0,  EQ_TOT: 0,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 297, ITEM: 'COM',   AREA: 'TOPSIDE', ZONE: 'NORTH',      MOD: 'ND300',       LEVEL: 'Main Deck',           G_EREC: 'ND310', G_2PE: 'ND310', G_1PE: 'ND320', EREC: '',      PE2: '',      PE1: '',      BLK: 'ND322', YARD: 'HHI',   PS_BK: 'ND322', PS_TOT: 0,     PS_WO: 0,    PS_SO: 0,   PS_IST: 0,  EQ_TOT: 0,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 298, ITEM: 'COM',   AREA: 'TOPSIDE', ZONE: 'NORTH',      MOD: 'ND300',       LEVEL: 'Main Deck',           G_EREC: 'ND310', G_2PE: 'ND310', G_1PE: 'ND320', EREC: '',      PE2: '',      PE1: '',      BLK: 'ND323', YARD: 'HHI',   PS_BK: 'ND323', PS_TOT: 0,     PS_WO: 0,    PS_SO: 0,   PS_IST: 0,  EQ_TOT: 0,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 299, ITEM: 'COM',   AREA: 'TOPSIDE', ZONE: 'NORTH',      MOD: 'ND300',       LEVEL: 'Main Deck',           G_EREC: 'ND310', G_2PE: 'ND310', G_1PE: 'ND320', EREC: '',      PE2: '',      PE1: '',      BLK: 'NT311', YARD: 'HHI',   PS_BK: 'NT311', PS_TOT: 0,     PS_WO: 0,    PS_SO: 0,   PS_IST: 0,  EQ_TOT: 0,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 300, ITEM: 'COM',   AREA: 'TOPSIDE', ZONE: 'NORTH',      MOD: 'ND300',       LEVEL: 'Main Deck',           G_EREC: 'ND310', G_2PE: 'ND310', G_1PE: 'ND320', EREC: '',      PE2: '',      PE1: '',      BLK: 'NT321', YARD: 'HHI',   PS_BK: 'NT321', PS_TOT: 0,     PS_WO: 0,    PS_SO: 0,   PS_IST: 0,  EQ_TOT: 0,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 301, ITEM: 'COM',   AREA: 'TOPSIDE', ZONE: 'NORTH',      MOD: 'ND300',       LEVEL: 'Main Deck',           G_EREC: 'ND310', G_2PE: 'ND310', G_1PE: 'ND320', EREC: '',      PE2: '',      PE1: '',      BLK: 'NT331', YARD: 'HHI',   PS_BK: 'NT331', PS_TOT: 0,     PS_WO: 0,    PS_SO: 0,   PS_IST: 0,  EQ_TOT: 0,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 302, ITEM: 'COM',   AREA: 'TOPSIDE', ZONE: 'NORTH',      MOD: 'ND301',       LEVEL: 'Main Deck',           G_EREC: 'ND310', G_2PE: 'ND310', G_1PE: 'ND320', EREC: '',      PE2: '',      PE1: '',      BLK: 'NT341', YARD: 'HHI',   PS_BK: 'NT341', PS_TOT: 0,     PS_WO: 0,    PS_SO: 0,   PS_IST: 0,  EQ_TOT: 0,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 303, ITEM: 'COM',   AREA: 'TOPSIDE', ZONE: 'NORTH',      MOD: 'ND300',       LEVEL: 'Main Deck',           G_EREC: 'ND310', G_2PE: 'ND310', G_1PE: 'NV311', EREC: '',      PE2: '',      PE1: '',      BLK: 'NV311', YARD: 'HHI',   PS_BK: 'NV311', PS_TOT: 0,     PS_WO: 0,    PS_SO: 0,   PS_IST: 0,  EQ_TOT: 0,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 304, ITEM: 'COM',   AREA: 'TOPSIDE', ZONE: 'NORTH',      MOD: 'ND300',       LEVEL: 'Main Deck',           G_EREC: 'ND310', G_2PE: 'ND310', G_1PE: 'NV321', EREC: '',      PE2: '',      PE1: '',      BLK: 'NV321', YARD: 'HHI',   PS_BK: 'NV321', PS_TOT: 0,     PS_WO: 0,    PS_SO: 0,   PS_IST: 0,  EQ_TOT: 0,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 305, ITEM: 'COM',   AREA: 'TOPSIDE', ZONE: 'NORTH',      MOD: 'ND300',       LEVEL: 'Main Deck',           G_EREC: 'ND310', G_2PE: 'ND310', G_1PE: 'NV331', EREC: '',      PE2: '',      PE1: '',      BLK: 'NV331', YARD: 'HHI',   PS_BK: 'NV331', PS_TOT: 0,     PS_WO: 0,    PS_SO: 0,   PS_IST: 0,  EQ_TOT: 0,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 306, ITEM: 'COM',   AREA: 'TOPSIDE', ZONE: 'NORTH',      MOD: 'ND300',       LEVEL: 'Main Deck',           G_EREC: 'ND310', G_2PE: 'ND310', G_1PE: 'NV341', EREC: '',      PE2: '',      PE1: '',      BLK: 'NV341', YARD: 'HHI',   PS_BK: 'NV341', PS_TOT: 0,     PS_WO: 0,    PS_SO: 0,   PS_IST: 0,  EQ_TOT: 0,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 307, ITEM: 'COM',   AREA: 'TOPSIDE', ZONE: 'NORTH',      MOD: 'ND300',       LEVEL: 'Main Deck',           G_EREC: 'ND310', G_2PE: 'ND310', G_1PE: 'NV3A1', EREC: '',      PE2: '',      PE1: '',      BLK: 'NV3A1', YARD: 'HHI',   PS_BK: 'NV3A1', PS_TOT: 0,     PS_WO: 0,    PS_SO: 0,   PS_IST: 0,  EQ_TOT: 0,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 308, ITEM: 'COM',   AREA: 'TOPSIDE', ZONE: 'NORTH',      MOD: 'ND300',       LEVEL: 'Main Deck',           G_EREC: 'ND310', G_2PE: 'ND310', G_1PE: 'NV3B1', EREC: '',      PE2: '',      PE1: '',      BLK: 'NV3B1', YARD: 'HHI',   PS_BK: 'NV3B1', PS_TOT: 0,     PS_WO: 0,    PS_SO: 0,   PS_IST: 0,  EQ_TOT: 0,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 309, ITEM: 'COM',   AREA: 'TOPSIDE', ZONE: 'NORTH',      MOD: 'ND300',       LEVEL: 'Main Deck',           G_EREC: 'ND310', G_2PE: 'ND310', G_1PE: 'NV3C1', EREC: '',      PE2: '',      PE1: '',      BLK: 'NV3C1', YARD: 'HHI',   PS_BK: 'NV3C1', PS_TOT: 0,     PS_WO: 0,    PS_SO: 0,   PS_IST: 0,  EQ_TOT: 0,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 310, ITEM: 'COM',   AREA: 'TOPSIDE', ZONE: 'NORTH',      MOD: 'ND300',       LEVEL: 'Main Deck',           G_EREC: 'ND310', G_2PE: 'ND310', G_1PE: 'NV3D1', EREC: '',      PE2: '',      PE1: '',      BLK: 'NV3D1', YARD: 'HHI',   PS_BK: 'NV3D1', PS_TOT: 0,     PS_WO: 0,    PS_SO: 0,   PS_IST: 0,  EQ_TOT: 0,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 311, ITEM: 'COM',   AREA: 'TOPSIDE', ZONE: 'NORTH',      MOD: 'ND300',       LEVEL: 'Main Deck',           G_EREC: 'ND310', G_2PE: 'ND310', G_1PE: 'NV3E1', EREC: '',      PE2: '',      PE1: '',      BLK: 'NV3E1', YARD: 'HHI',   PS_BK: 'NV3E1', PS_TOT: 0,     PS_WO: 0,    PS_SO: 0,   PS_IST: 0,  EQ_TOT: 0,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 312, ITEM: 'COM',   AREA: 'TOPSIDE', ZONE: 'NORTH',      MOD: 'ND300',       LEVEL: 'Main Deck',           G_EREC: 'ND310', G_2PE: 'ND310', G_1PE: 'NV3F1', EREC: '',      PE2: '',      PE1: '',      BLK: 'NV3F1', YARD: 'HHI',   PS_BK: 'NV3F1', PS_TOT: 0,     PS_WO: 0,    PS_SO: 0,   PS_IST: 0,  EQ_TOT: 0,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 313, ITEM: 'COM',   AREA: 'TOPSIDE', ZONE: 'NORTH',      MOD: 'ND300',       LEVEL: 'Main Deck',           G_EREC: 'ND310', G_2PE: 'ND310', G_1PE: 'NV3G1', EREC: '',      PE2: '',      PE1: '',      BLK: 'NV3G1', YARD: 'HHI',   PS_BK: 'NV3G1', PS_TOT: 0,     PS_WO: 0,    PS_SO: 0,   PS_IST: 0,  EQ_TOT: 0,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 314, ITEM: 'COM',   AREA: 'TOPSIDE', ZONE: 'NORTH',      MOD: 'ND300',       LEVEL: 'Main Deck',           G_EREC: 'ND310', G_2PE: 'ND310', G_1PE: 'NV3H1', EREC: '',      PE2: '',      PE1: '',      BLK: 'NV3H1', YARD: 'HHI',   PS_BK: 'NV3H1', PS_TOT: 0,     PS_WO: 0,    PS_SO: 0,   PS_IST: 0,  EQ_TOT: 0,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 315, ITEM: 'COM',   AREA: 'TOPSIDE', ZONE: 'NORTH',      MOD: 'ND300',       LEVEL: 'Main Deck',           G_EREC: 'ND310', G_2PE: 'ND310', G_1PE: 'NV3J1', EREC: '',      PE2: '',      PE1: '',      BLK: 'NV3J1', YARD: 'HHI',   PS_BK: 'NV3J1', PS_TOT: 0,     PS_WO: 0,    PS_SO: 0,   PS_IST: 0,  EQ_TOT: 0,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 316, ITEM: 'COM',   AREA: 'TOPSIDE', ZONE: 'NORTH',      MOD: 'ND300',       LEVEL: 'Main Deck',           G_EREC: 'ND310', G_2PE: 'ND310', G_1PE: 'NV3K1', EREC: '',      PE2: '',      PE1: '',      BLK: 'NV3K1', YARD: 'HHI',   PS_BK: 'NV3K1', PS_TOT: 0,     PS_WO: 0,    PS_SO: 0,   PS_IST: 0,  EQ_TOT: 0,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 317, ITEM: 'COM',   AREA: 'TOPSIDE', ZONE: 'NORTH',      MOD: 'ND300',       LEVEL: 'Main Deck',           G_EREC: 'ND310', G_2PE: 'ND310', G_1PE: 'NP311', EREC: '',      PE2: '',      PE1: '',      BLK: 'NP311', YARD: 'HHI',   PS_BK: 'NP311', PS_TOT: 0,     PS_WO: 0,    PS_SO: 0,   PS_IST: 0,  EQ_TOT: 0,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 318, ITEM: 'COM',   AREA: 'TOPSIDE', ZONE: 'NORTH',      MOD: 'ND300',       LEVEL: 'Main Deck',           G_EREC: 'ND310', G_2PE: 'ND310', G_1PE: 'NP321', EREC: '',      PE2: '',      PE1: '',      BLK: 'NP321', YARD: 'HHI',   PS_BK: 'NP321', PS_TOT: 0,     PS_WO: 0,    PS_SO: 0,   PS_IST: 0,  EQ_TOT: 0,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 319, ITEM: 'COM',   AREA: 'TOPSIDE', ZONE: 'NORTH',      MOD: 'ND300',       LEVEL: 'Main Deck',           G_EREC: 'ND310', G_2PE: 'ND310', G_1PE: 'NP331', EREC: '',      PE2: '',      PE1: '',      BLK: 'NP331', YARD: 'HHI',   PS_BK: 'NP331', PS_TOT: 0,     PS_WO: 0,    PS_SO: 0,   PS_IST: 0,  EQ_TOT: 0,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 320, ITEM: 'COM',   AREA: 'TOPSIDE', ZONE: 'NORTH',      MOD: 'ND300',       LEVEL: 'Main Deck',           G_EREC: 'ND310', G_2PE: 'ND310', G_1PE: 'NP341', EREC: '',      PE2: '',      PE1: '',      BLK: 'NP341', YARD: 'HHI',   PS_BK: 'NP341', PS_TOT: 0,     PS_WO: 0,    PS_SO: 0,   PS_IST: 0,  EQ_TOT: 0,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 321, ITEM: 'COM',   AREA: 'TOPSIDE', ZONE: 'NORTH',      MOD: 'ND300',       LEVEL: 'Main Deck',           G_EREC: 'ND310', G_2PE: 'ND310', G_1PE: 'NP351', EREC: '',      PE2: '',      PE1: '',      BLK: 'NP351', YARD: 'HHI',   PS_BK: 'NP351', PS_TOT: 0,     PS_WO: 0,    PS_SO: 0,   PS_IST: 0,  EQ_TOT: 0,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 322, ITEM: 'COM',   AREA: 'TOPSIDE', ZONE: 'NORTH',      MOD: 'ND300',       LEVEL: 'Main Deck',           G_EREC: 'ND310', G_2PE: 'ND310', G_1PE: 'NP361', EREC: '',      PE2: '',      PE1: '',      BLK: 'NP361', YARD: 'HHI',   PS_BK: 'NP361', PS_TOT: 0,     PS_WO: 0,    PS_SO: 0,   PS_IST: 0,  EQ_TOT: 0,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 323, ITEM: 'COM',   AREA: 'TOPSIDE', ZONE: 'NORTH',      MOD: 'ND300',       LEVEL: 'Main Deck',           G_EREC: 'ND330', G_2PE: 'ND330', G_1PE: 'ND330', EREC: 'ND330', PE2: 'ND330', PE1: 'ND330', BLK: 'ND331', YARD: 'HHI',   PS_BK: 'ND331', PS_TOT: 1274, PS_WO: 0,    PS_SO: 0,   PS_IST: 0,  EQ_TOT: 7,  EQ_PO: 4,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 324, ITEM: 'COM',   AREA: 'TOPSIDE', ZONE: 'NORTH',      MOD: 'ND300',       LEVEL: 'Main Deck',           G_EREC: 'ND330', G_2PE: 'ND330', G_1PE: 'ND330', EREC: '',      PE2: '',      PE1: '',      BLK: 'ND332', YARD: 'HHI',   PS_BK: 'ND332', PS_TOT: 0,     PS_WO: 0,    PS_SO: 0,   PS_IST: 0,  EQ_TOT: 0,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 325, ITEM: 'COM',   AREA: 'TOPSIDE', ZONE: 'NORTH',      MOD: 'ND300',       LEVEL: 'Main Deck',           G_EREC: 'ND330', G_2PE: 'ND330', G_1PE: 'ND330', EREC: '',      PE2: '',      PE1: '',      BLK: 'ND333', YARD: 'HHI',   PS_BK: 'ND333', PS_TOT: 0,     PS_WO: 0,    PS_SO: 0,   PS_IST: 0,  EQ_TOT: 0,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 326, ITEM: 'COM',   AREA: 'TOPSIDE', ZONE: 'NORTH',      MOD: 'ND300',       LEVEL: 'Main Deck',           G_EREC: 'ND330', G_2PE: 'ND330', G_1PE: 'ND330', EREC: '',      PE2: '',      PE1: '',      BLK: 'NT351', YARD: 'HHI',   PS_BK: 'NT351', PS_TOT: 0,     PS_WO: 0,    PS_SO: 0,   PS_IST: 0,  EQ_TOT: 0,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 327, ITEM: 'COM',   AREA: 'TOPSIDE', ZONE: 'NORTH',      MOD: 'ND300',       LEVEL: 'Main Deck',           G_EREC: 'ND330', G_2PE: 'ND330', G_1PE: 'ND330', EREC: '',      PE2: '',      PE1: '',      BLK: 'NT361', YARD: 'HHI',   PS_BK: 'NT361', PS_TOT: 0,     PS_WO: 0,    PS_SO: 0,   PS_IST: 0,  EQ_TOT: 0,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 328, ITEM: 'COM',   AREA: 'TOPSIDE', ZONE: 'NORTH',      MOD: 'ND300',       LEVEL: 'Main Deck',           G_EREC: 'ND330', G_2PE: 'ND330', G_1PE: 'ND330', EREC: '',      PE2: '',      PE1: '',      BLK: 'NT371', YARD: 'HHI',   PS_BK: 'NT371', PS_TOT: 0,     PS_WO: 0,    PS_SO: 0,   PS_IST: 0,  EQ_TOT: 0,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 329, ITEM: 'COM',   AREA: 'TOPSIDE', ZONE: 'NORTH',      MOD: 'ND300',       LEVEL: 'Main Deck',           G_EREC: 'ND330', G_2PE: 'ND330', G_1PE: 'ND330', EREC: '',      PE2: '',      PE1: '',      BLK: 'NT381', YARD: 'HHI',   PS_BK: 'NT381', PS_TOT: 0,     PS_WO: 0,    PS_SO: 0,   PS_IST: 0,  EQ_TOT: 0,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 330, ITEM: 'COM',   AREA: 'TOPSIDE', ZONE: 'NORTH',      MOD: 'ND300',       LEVEL: 'Main Deck',           G_EREC: 'ND330', G_2PE: 'ND330', G_1PE: 'ND341', EREC: '',      PE2: '',      PE1: '',      BLK: 'ND341', YARD: 'HHI',   PS_BK: 'ND341', PS_TOT: 0,     PS_WO: 0,    PS_SO: 0,   PS_IST: 0,  EQ_TOT: 0,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 331, ITEM: 'COM',   AREA: 'TOPSIDE', ZONE: 'NORTH',      MOD: 'ND300',       LEVEL: 'Main Deck',           G_EREC: 'ND330', G_2PE: 'ND330', G_1PE: 'NV351', EREC: '',      PE2: '',      PE1: '',      BLK: 'NV351', YARD: 'HHI',   PS_BK: 'NV351', PS_TOT: 0,     PS_WO: 0,    PS_SO: 0,   PS_IST: 0,  EQ_TOT: 0,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 332, ITEM: 'COM',   AREA: 'TOPSIDE', ZONE: 'NORTH',      MOD: 'ND300',       LEVEL: 'Main Deck',           G_EREC: 'ND330', G_2PE: 'ND330', G_1PE: 'NV3L1', EREC: '',      PE2: '',      PE1: '',      BLK: 'NV3L1', YARD: 'HHI',   PS_BK: 'NV3L1', PS_TOT: 0,     PS_WO: 0,    PS_SO: 0,   PS_IST: 0,  EQ_TOT: 0,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 333, ITEM: 'COM',   AREA: 'TOPSIDE', ZONE: 'NORTH',      MOD: 'ND300',       LEVEL: 'Main Deck',           G_EREC: 'ND330', G_2PE: 'ND330', G_1PE: 'NV3M1', EREC: '',      PE2: '',      PE1: '',      BLK: 'NV3M1', YARD: 'HHI',   PS_BK: 'NV3M1', PS_TOT: 0,     PS_WO: 0,    PS_SO: 0,   PS_IST: 0,  EQ_TOT: 0,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 334, ITEM: 'COM',   AREA: 'TOPSIDE', ZONE: 'NORTH',      MOD: 'ND300',       LEVEL: 'Main Deck',           G_EREC: 'ND330', G_2PE: 'ND330', G_1PE: 'NV3N1', EREC: '',      PE2: '',      PE1: '',      BLK: 'NV3N1', YARD: 'HHI',   PS_BK: 'NV3N1', PS_TOT: 0,     PS_WO: 0,    PS_SO: 0,   PS_IST: 0,  EQ_TOT: 0,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 335, ITEM: 'COM',   AREA: 'TOPSIDE', ZONE: 'NORTH',      MOD: 'ND300',       LEVEL: 'Main Deck',           G_EREC: 'ND330', G_2PE: 'ND330', G_1PE: 'NV3P1', EREC: '',      PE2: '',      PE1: '',      BLK: 'NV3P1', YARD: 'HHI',   PS_BK: 'NV3P1', PS_TOT: 0,     PS_WO: 0,    PS_SO: 0,   PS_IST: 0,  EQ_TOT: 0,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 336, ITEM: 'COM',   AREA: 'TOPSIDE', ZONE: 'NORTH',      MOD: 'ND300',       LEVEL: 'Main Deck',           G_EREC: 'ND330', G_2PE: 'ND330', G_1PE: 'NV3Q1', EREC: '',      PE2: '',      PE1: '',      BLK: 'NV3Q1', YARD: 'HHI',   PS_BK: 'NV3Q1', PS_TOT: 0,     PS_WO: 0,    PS_SO: 0,   PS_IST: 0,  EQ_TOT: 0,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 337, ITEM: 'COM',   AREA: 'TOPSIDE', ZONE: 'NORTH',      MOD: 'ND300',       LEVEL: 'Main Deck',           G_EREC: 'ND330', G_2PE: 'ND330', G_1PE: 'NV3R1', EREC: '',      PE2: '',      PE1: '',      BLK: 'NV3R1', YARD: 'HHI',   PS_BK: 'NV3R1', PS_TOT: 0,     PS_WO: 0,    PS_SO: 0,   PS_IST: 0,  EQ_TOT: 0,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 338, ITEM: 'COM',   AREA: 'TOPSIDE', ZONE: 'NORTH',      MOD: 'ND300',       LEVEL: 'Main Deck',           G_EREC: 'ND330', G_2PE: 'ND330', G_1PE: 'NV3S1', EREC: '',      PE2: '',      PE1: '',      BLK: 'NV3S1', YARD: 'HHI',   PS_BK: 'NV3S1', PS_TOT: 0,     PS_WO: 0,    PS_SO: 0,   PS_IST: 0,  EQ_TOT: 0,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 339, ITEM: 'COM',   AREA: 'TOPSIDE', ZONE: 'NORTH',      MOD: 'ND300',       LEVEL: 'Main Deck',           G_EREC: 'ND330', G_2PE: 'ND330', G_1PE: 'NV3T1', EREC: '',      PE2: '',      PE1: '',      BLK: 'NV3T1', YARD: 'HHI',   PS_BK: 'NV3T1', PS_TOT: 0,     PS_WO: 0,    PS_SO: 0,   PS_IST: 0,  EQ_TOT: 0,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 340, ITEM: 'COM',   AREA: 'TOPSIDE', ZONE: 'NORTH',      MOD: 'ND300',       LEVEL: 'Main Deck',           G_EREC: 'ND330', G_2PE: 'ND330', G_1PE: 'NV3U1', EREC: '',      PE2: '',      PE1: '',      BLK: 'NV3U1', YARD: 'HHI',   PS_BK: 'NV3U1', PS_TOT: 0,     PS_WO: 0,    PS_SO: 0,   PS_IST: 0,  EQ_TOT: 0,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 341, ITEM: 'COM',   AREA: 'TOPSIDE', ZONE: 'NORTH',      MOD: 'ND300',       LEVEL: 'Main Deck',           G_EREC: 'ND330', G_2PE: 'ND330', G_1PE: 'NV3V1', EREC: '',      PE2: '',      PE1: '',      BLK: 'NV3V1', YARD: 'HHI',   PS_BK: 'NV3V1', PS_TOT: 0,     PS_WO: 0,    PS_SO: 0,   PS_IST: 0,  EQ_TOT: 0,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 342, ITEM: 'COM',   AREA: 'TOPSIDE', ZONE: 'NORTH',      MOD: 'ND300',       LEVEL: 'Main Deck',           G_EREC: 'ND330', G_2PE: 'ND330', G_1PE: 'NV3W1', EREC: '',      PE2: '',      PE1: '',      BLK: 'NV3W1', YARD: 'HHI',   PS_BK: 'NV3W1', PS_TOT: 0,     PS_WO: 0,    PS_SO: 0,   PS_IST: 0,  EQ_TOT: 0,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 343, ITEM: 'COM',   AREA: 'TOPSIDE', ZONE: 'NORTH',      MOD: 'ND300',       LEVEL: 'Main Deck',           G_EREC: 'ND330', G_2PE: 'ND330', G_1PE: 'NV3X1', EREC: '',      PE2: '',      PE1: '',      BLK: 'NV3X1', YARD: 'HHI',   PS_BK: 'NV3X1', PS_TOT: 0,     PS_WO: 0,    PS_SO: 0,   PS_IST: 0,  EQ_TOT: 0,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 344, ITEM: 'COM',   AREA: 'TOPSIDE', ZONE: 'NORTH',      MOD: 'ND300',       LEVEL: 'Main Deck',           G_EREC: 'ND330', G_2PE: 'ND330', G_1PE: 'NP371', EREC: '',      PE2: '',      PE1: '',      BLK: 'NP371', YARD: 'HHI',   PS_BK: 'NP371', PS_TOT: 0,     PS_WO: 0,    PS_SO: 0,   PS_IST: 0,  EQ_TOT: 0,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 345, ITEM: 'COM',   AREA: 'TOPSIDE', ZONE: 'NORTH',      MOD: 'ND300',       LEVEL: 'Main Deck',           G_EREC: 'ND330', G_2PE: 'ND330', G_1PE: 'NP381', EREC: '',      PE2: '',      PE1: '',      BLK: 'NP381', YARD: 'HHI',   PS_BK: 'NP381', PS_TOT: 0,     PS_WO: 0,    PS_SO: 0,   PS_IST: 0,  EQ_TOT: 0,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 346, ITEM: 'COM',   AREA: 'TOPSIDE', ZONE: 'NORTH',      MOD: 'ND300',       LEVEL: 'Main Deck',           G_EREC: 'ND330', G_2PE: 'ND330', G_1PE: 'NP391', EREC: '',      PE2: '',      PE1: '',      BLK: 'NP391', YARD: 'HHI',   PS_BK: 'NP391', PS_TOT: 0,     PS_WO: 0,    PS_SO: 0,   PS_IST: 0,  EQ_TOT: 0,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 347, ITEM: 'COM',   AREA: 'TOPSIDE', ZONE: 'NORTH',      MOD: 'NX100',       LEVEL: 'Main Deck',           G_EREC: 'NX110', G_2PE: 'NX110', G_1PE: 'NX111', EREC: 'NX110', PE2: ' ',     PE1: '',      BLK: 'NX111', YARD: 'HHI',   PS_BK: 'NX111', PS_TOT: 0,     PS_WO: 0,    PS_SO: 0,   PS_IST: 0,  EQ_TOT: 0,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 348, ITEM: 'COM',   AREA: 'TOPSIDE', ZONE: 'NORTH',      MOD: 'KD100',       LEVEL: 'Compressor Platform', G_EREC: 'KD110', G_2PE: 'KD110', G_1PE: 'KD110', EREC: 'KD110', PE2: 'KD110', PE1: 'KD110', BLK: 'KD111', YARD: 'HHI',   PS_BK: 'KD111', PS_TOT: 941,  PS_WO: 0,    PS_SO: 0,   PS_IST: 0,  EQ_TOT: 18, EQ_PO: 6,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 349, ITEM: 'COM',   AREA: 'TOPSIDE', ZONE: 'NORTH',      MOD: 'KD100',       LEVEL: 'Compressor Platform', G_EREC: 'KD110', G_2PE: 'KD110', G_1PE: 'KD110', EREC: '',      PE2: '',      PE1: '',      BLK: 'KD112', YARD: 'HHI',   PS_BK: 'KD112', PS_TOT: 0,     PS_WO: 0,    PS_SO: 0,   PS_IST: 0,  EQ_TOT: 0,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 350, ITEM: 'COM',   AREA: 'TOPSIDE', ZONE: 'NORTH',      MOD: 'KD100',       LEVEL: 'Compressor Platform', G_EREC: 'KD110', G_2PE: 'KD110', G_1PE: 'KD110', EREC: '',      PE2: '',      PE1: '',      BLK: 'KD113', YARD: 'HHI',   PS_BK: 'KD113', PS_TOT: 0,     PS_WO: 0,    PS_SO: 0,   PS_IST: 0,  EQ_TOT: 0,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 351, ITEM: 'COM',   AREA: 'TOPSIDE', ZONE: 'NORTH',      MOD: 'KD100',       LEVEL: 'Compressor Platform', G_EREC: 'KD110', G_2PE: 'KD110', G_1PE: 'KD110', EREC: '',      PE2: '',      PE1: '',      BLK: 'KD114', YARD: 'HHI',   PS_BK: 'KD114', PS_TOT: 0,     PS_WO: 0,    PS_SO: 0,   PS_IST: 0,  EQ_TOT: 0,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 352, ITEM: 'COM',   AREA: 'TOPSIDE', ZONE: 'NORTH',      MOD: 'KD100',       LEVEL: 'Compressor Platform', G_EREC: 'KD110', G_2PE: 'KD110', G_1PE: 'KD110', EREC: '',      PE2: '',      PE1: '',      BLK: 'KC111', YARD: 'HHI',   PS_BK: 'KC111', PS_TOT: 0,     PS_WO: 0,    PS_SO: 0,   PS_IST: 0,  EQ_TOT: 0,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 353, ITEM: 'COM',   AREA: 'TOPSIDE', ZONE: 'NORTH',      MOD: 'KD100',       LEVEL: 'Compressor Platform', G_EREC: 'KD110', G_2PE: 'KD110', G_1PE: 'KP111', EREC: '',      PE2: '',      PE1: '',      BLK: 'KP111', YARD: 'HHI',   PS_BK: 'KP111', PS_TOT: 0,     PS_WO: 0,    PS_SO: 0,   PS_IST: 0,  EQ_TOT: 0,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 354, ITEM: 'COM',   AREA: 'TOPSIDE', ZONE: 'NORTH',      MOD: 'KD100',       LEVEL: 'Compressor Platform', G_EREC: 'KD110', G_2PE: 'KD110', G_1PE: 'KV111', EREC: '',      PE2: '',      PE1: '',      BLK: 'KV111', YARD: 'HHI',   PS_BK: 'KV111', PS_TOT: 0,     PS_WO: 0,    PS_SO: 0,   PS_IST: 0,  EQ_TOT: 0,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 355, ITEM: 'COM',   AREA: 'TOPSIDE', ZONE: 'NORTH',      MOD: 'KD100',       LEVEL: 'Compressor Platform', G_EREC: 'KD110', G_2PE: 'KD110', G_1PE: 'KV121', EREC: '',      PE2: '',      PE1: '',      BLK: 'KV121', YARD: 'HHI',   PS_BK: 'KV121', PS_TOT: 0,     PS_WO: 0,    PS_SO: 0,   PS_IST: 0,  EQ_TOT: 0,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 356, ITEM: 'COM',   AREA: 'TOPSIDE', ZONE: 'NORTH',      MOD: 'KD100',       LEVEL: 'Compressor Platform', G_EREC: 'KD110', G_2PE: 'KD110', G_1PE: 'KV131', EREC: '',      PE2: '',      PE1: '',      BLK: 'KV131', YARD: 'HHI',   PS_BK: 'KV131', PS_TOT: 0,     PS_WO: 0,    PS_SO: 0,   PS_IST: 0,  EQ_TOT: 0,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 357, ITEM: 'COM',   AREA: 'TOPSIDE', ZONE: 'NORTH',      MOD: 'KD100',       LEVEL: 'Compressor Platform', G_EREC: 'KD110', G_2PE: 'KD110', G_1PE: 'KV141', EREC: '',      PE2: '',      PE1: '',      BLK: 'KV141', YARD: 'HHI',   PS_BK: 'KV141', PS_TOT: 0,     PS_WO: 0,    PS_SO: 0,   PS_IST: 0,  EQ_TOT: 0,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 358, ITEM: 'COM',   AREA: 'TOPSIDE', ZONE: 'CENTRAL',    MOD: 'CD100',       LEVEL: 'Production Deck',     G_EREC: 'CD110', G_2PE: 'CD110', G_1PE: 'CD110', EREC: 'CD110', PE2: 'CD110', PE1: 'CD110', BLK: 'CD111', YARD: 'HHI',   PS_BK: 'CD111', PS_TOT: 313,  PS_WO: 0,    PS_SO: 0,   PS_IST: 0,  EQ_TOT: 25, EQ_PO: 16, EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 359, ITEM: 'COM',   AREA: 'TOPSIDE', ZONE: 'CENTRAL',    MOD: 'CD100',       LEVEL: 'Production Deck',     G_EREC: 'CD110', G_2PE: 'CD110', G_1PE: 'CD110', EREC: '',      PE2: '',      PE1: '',      BLK: 'CD112', YARD: 'HHI',   PS_BK: 'CD112', PS_TOT: 0,     PS_WO: 0,    PS_SO: 0,   PS_IST: 0,  EQ_TOT: 0,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 360, ITEM: 'COM',   AREA: 'TOPSIDE', ZONE: 'CENTRAL',    MOD: 'CD100',       LEVEL: 'Production Deck',     G_EREC: 'CD110', G_2PE: 'CD110', G_1PE: 'CD110', EREC: '',      PE2: '',      PE1: '',      BLK: 'CD113', YARD: 'HHI',   PS_BK: 'CD113', PS_TOT: 0,     PS_WO: 0,    PS_SO: 0,   PS_IST: 0,  EQ_TOT: 0,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 361, ITEM: 'COM',   AREA: 'TOPSIDE', ZONE: 'CENTRAL',    MOD: 'CD100',       LEVEL: 'Production Deck',     G_EREC: 'CD110', G_2PE: 'CD110', G_1PE: 'CD110', EREC: '',      PE2: '',      PE1: '',      BLK: 'CC111', YARD: 'HHI',   PS_BK: 'CC111', PS_TOT: 0,     PS_WO: 0,    PS_SO: 0,   PS_IST: 0,  EQ_TOT: 0,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 362, ITEM: 'COM',   AREA: 'TOPSIDE', ZONE: 'CENTRAL',    MOD: 'CD100',       LEVEL: 'Production Deck',     G_EREC: 'CD110', G_2PE: 'CD110', G_1PE: 'CD120', EREC: '',      PE2: '',      PE1: 'CD120', BLK: 'CD121', YARD: 'HHI',   PS_BK: 'CD121', PS_TOT: 380,  PS_WO: 0,    PS_SO: 0,   PS_IST: 0,  EQ_TOT: 0,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 363, ITEM: 'COM',   AREA: 'TOPSIDE', ZONE: 'CENTRAL',    MOD: 'CD100',       LEVEL: 'Production Deck',     G_EREC: 'CD110', G_2PE: 'CD110', G_1PE: 'CD120', EREC: '',      PE2: '',      PE1: '',      BLK: 'CD122', YARD: 'HHI',   PS_BK: 'CD122', PS_TOT: 0,     PS_WO: 0,    PS_SO: 0,   PS_IST: 0,  EQ_TOT: 0,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 364, ITEM: 'COM',   AREA: 'TOPSIDE', ZONE: 'CENTRAL',    MOD: 'CD100',       LEVEL: 'Production Deck',     G_EREC: 'CD110', G_2PE: 'CD110', G_1PE: 'CD120', EREC: '',      PE2: '',      PE1: '',      BLK: 'CC121', YARD: 'HHI',   PS_BK: 'CC121', PS_TOT: 0,     PS_WO: 0,    PS_SO: 0,   PS_IST: 0,  EQ_TOT: 0,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 365, ITEM: 'COM',   AREA: 'TOPSIDE', ZONE: 'CENTRAL',    MOD: 'CD100',       LEVEL: 'Production Deck',     G_EREC: 'CD110', G_2PE: 'CD110', G_1PE: 'CV111', EREC: '',      PE2: '',      PE1: '',      BLK: 'CV111', YARD: 'HHI',   PS_BK: 'CV111', PS_TOT: 0,     PS_WO: 0,    PS_SO: 0,   PS_IST: 0,  EQ_TOT: 0,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 366, ITEM: 'COM',   AREA: 'TOPSIDE', ZONE: 'CENTRAL',    MOD: 'CD100',       LEVEL: 'Production Deck',     G_EREC: 'CD110', G_2PE: 'CD110', G_1PE: 'CV121', EREC: '',      PE2: '',      PE1: '',      BLK: 'CV121', YARD: 'HHI',   PS_BK: 'CV121', PS_TOT: 0,     PS_WO: 0,    PS_SO: 0,   PS_IST: 0,  EQ_TOT: 0,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 367, ITEM: 'COM',   AREA: 'TOPSIDE', ZONE: 'CENTRAL',    MOD: 'CD100',       LEVEL: 'Production Deck',     G_EREC: 'CD110', G_2PE: 'CD110', G_1PE: 'CV131', EREC: '',      PE2: '',      PE1: '',      BLK: 'CV131', YARD: 'HHI',   PS_BK: 'CV131', PS_TOT: 0,     PS_WO: 0,    PS_SO: 0,   PS_IST: 0,  EQ_TOT: 0,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 368, ITEM: 'COM',   AREA: 'TOPSIDE', ZONE: 'CENTRAL',    MOD: 'CD100',       LEVEL: 'Production Deck',     G_EREC: 'CD110', G_2PE: 'CD110', G_1PE: 'CV141', EREC: '',      PE2: '',      PE1: '',      BLK: 'CV141', YARD: 'HHI',   PS_BK: 'CV141', PS_TOT: 0,     PS_WO: 0,    PS_SO: 0,   PS_IST: 0,  EQ_TOT: 0,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 369, ITEM: 'COM',   AREA: 'TOPSIDE', ZONE: 'CENTRAL',    MOD: 'CD100',       LEVEL: 'Production Deck',     G_EREC: 'CD110', G_2PE: 'CD110', G_1PE: 'CV151', EREC: '',      PE2: '',      PE1: '',      BLK: 'CV151', YARD: 'HHI',   PS_BK: 'CV151', PS_TOT: 0,     PS_WO: 0,    PS_SO: 0,   PS_IST: 0,  EQ_TOT: 0,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 370, ITEM: 'COM',   AREA: 'TOPSIDE', ZONE: 'CENTRAL',    MOD: 'CD100',       LEVEL: 'Production Deck',     G_EREC: 'CD110', G_2PE: 'CD110', G_1PE: 'CV161', EREC: '',      PE2: '',      PE1: '',      BLK: 'CV161', YARD: 'HHI',   PS_BK: 'CV161', PS_TOT: 0,     PS_WO: 0,    PS_SO: 0,   PS_IST: 0,  EQ_TOT: 0,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 371, ITEM: 'COM',   AREA: 'TOPSIDE', ZONE: 'CENTRAL',    MOD: 'CD100',       LEVEL: 'Production Deck',     G_EREC: 'CD110', G_2PE: 'CD110', G_1PE: 'CV171', EREC: '',      PE2: '',      PE1: '',      BLK: 'CV171', YARD: 'HHI',   PS_BK: 'CV171', PS_TOT: 0,     PS_WO: 0,    PS_SO: 0,   PS_IST: 0,  EQ_TOT: 0,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 372, ITEM: 'COM',   AREA: 'TOPSIDE', ZONE: 'CENTRAL',    MOD: 'CD100',       LEVEL: 'Production Deck',     G_EREC: 'CD110', G_2PE: 'CD110', G_1PE: 'CV181', EREC: '',      PE2: '',      PE1: '',      BLK: 'CV181', YARD: 'HHI',   PS_BK: 'CV181', PS_TOT: 0,     PS_WO: 0,    PS_SO: 0,   PS_IST: 0,  EQ_TOT: 0,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 373, ITEM: 'COM',   AREA: 'TOPSIDE', ZONE: 'CENTRAL',    MOD: 'CD100',       LEVEL: 'Production Deck',     G_EREC: 'CD130', G_2PE: 'CD130', G_1PE: 'CD130', EREC: 'CD130', PE2: 'CD130', PE1: 'CD130', BLK: 'CD131', YARD: 'HHI',   PS_BK: 'CD131', PS_TOT: 304,  PS_WO: 0,    PS_SO: 0,   PS_IST: 0,  EQ_TOT: 40, EQ_PO: 29, EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 374, ITEM: 'COM',   AREA: 'TOPSIDE', ZONE: 'CENTRAL',    MOD: 'CD100',       LEVEL: 'Production Deck',     G_EREC: 'CD130', G_2PE: 'CD130', G_1PE: 'CD130', EREC: '',      PE2: '',      PE1: '',      BLK: 'CD132', YARD: 'HHI',   PS_BK: 'CD132', PS_TOT: 0,     PS_WO: 0,    PS_SO: 0,   PS_IST: 0,  EQ_TOT: 0,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 375, ITEM: 'COM',   AREA: 'TOPSIDE', ZONE: 'CENTRAL',    MOD: 'CD100',       LEVEL: 'Production Deck',     G_EREC: 'CD130', G_2PE: 'CD130', G_1PE: 'CD130', EREC: '',      PE2: '',      PE1: '',      BLK: 'CC131', YARD: 'HHI',   PS_BK: 'CC131', PS_TOT: 0,     PS_WO: 0,    PS_SO: 0,   PS_IST: 0,  EQ_TOT: 0,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 376, ITEM: 'COM',   AREA: 'TOPSIDE', ZONE: 'CENTRAL',    MOD: 'CD100',       LEVEL: 'Production Deck',     G_EREC: 'CD130', G_2PE: 'CD130', G_1PE: 'CD140', EREC: '',      PE2: '',      PE1: 'CD140', BLK: 'CD141', YARD: 'HHI',   PS_BK: 'CD141', PS_TOT: 176,  PS_WO: 0,    PS_SO: 0,   PS_IST: 0,  EQ_TOT: 0,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 377, ITEM: 'COM',   AREA: 'TOPSIDE', ZONE: 'CENTRAL',    MOD: 'CD100',       LEVEL: 'Production Deck',     G_EREC: 'CD130', G_2PE: 'CD130', G_1PE: 'CD140', EREC: '',      PE2: '',      PE1: '',      BLK: 'CD142', YARD: 'HHI',   PS_BK: 'CD142', PS_TOT: 0,     PS_WO: 0,    PS_SO: 0,   PS_IST: 0,  EQ_TOT: 0,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 378, ITEM: 'COM',   AREA: 'TOPSIDE', ZONE: 'CENTRAL',    MOD: 'CD100',       LEVEL: 'Production Deck',     G_EREC: 'CD130', G_2PE: 'CD130', G_1PE: 'CD140', EREC: '',      PE2: '',      PE1: '',      BLK: 'CC141', YARD: 'HHI',   PS_BK: 'CC141', PS_TOT: 0,     PS_WO: 0,    PS_SO: 0,   PS_IST: 0,  EQ_TOT: 0,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 379, ITEM: 'COM',   AREA: 'TOPSIDE', ZONE: 'CENTRAL',    MOD: 'CD100',       LEVEL: 'Production Deck',     G_EREC: 'CD130', G_2PE: 'CD130', G_1PE: 'CD150', EREC: '',      PE2: '',      PE1: 'CD150', BLK: 'CD151', YARD: 'HHI',   PS_BK: 'CD151', PS_TOT: 225,  PS_WO: 0,    PS_SO: 0,   PS_IST: 0,  EQ_TOT: 0,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 380, ITEM: 'COM',   AREA: 'TOPSIDE', ZONE: 'CENTRAL',    MOD: 'CD100',       LEVEL: 'Production Deck',     G_EREC: 'CD130', G_2PE: 'CD130', G_1PE: 'CD150', EREC: '',      PE2: '',      PE1: '',      BLK: 'CD152', YARD: 'HHI',   PS_BK: 'CD152', PS_TOT: 0,     PS_WO: 0,    PS_SO: 0,   PS_IST: 0,  EQ_TOT: 0,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 381, ITEM: 'COM',   AREA: 'TOPSIDE', ZONE: 'CENTRAL',    MOD: 'CD100',       LEVEL: 'Production Deck',     G_EREC: 'CD130', G_2PE: 'CD130', G_1PE: 'CD150', EREC: '',      PE2: '',      PE1: '',      BLK: 'CC151', YARD: 'HHI',   PS_BK: 'CC151', PS_TOT: 0,     PS_WO: 0,    PS_SO: 0,   PS_IST: 0,  EQ_TOT: 0,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 382, ITEM: 'COM',   AREA: 'TOPSIDE', ZONE: 'CENTRAL',    MOD: 'CD100',       LEVEL: 'Production Deck',     G_EREC: 'CD130', G_2PE: 'CD130', G_1PE: 'CV191', EREC: '',      PE2: '',      PE1: '',      BLK: 'CV191', YARD: 'HHI',   PS_BK: 'CV191', PS_TOT: 0,     PS_WO: 0,    PS_SO: 0,   PS_IST: 0,  EQ_TOT: 0,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 383, ITEM: 'COM',   AREA: 'TOPSIDE', ZONE: 'CENTRAL',    MOD: 'CD100',       LEVEL: 'Production Deck',     G_EREC: 'CD130', G_2PE: 'CD130', G_1PE: 'CV1A1', EREC: '',      PE2: '',      PE1: '',      BLK: 'CV1A1', YARD: 'HHI',   PS_BK: 'CV1A1', PS_TOT: 0,     PS_WO: 0,    PS_SO: 0,   PS_IST: 0,  EQ_TOT: 0,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 384, ITEM: 'COM',   AREA: 'TOPSIDE', ZONE: 'CENTRAL',    MOD: 'CD100',       LEVEL: 'Production Deck',     G_EREC: 'CD130', G_2PE: 'CD130', G_1PE: 'CV1B1', EREC: '',      PE2: '',      PE1: '',      BLK: 'CV1B1', YARD: 'HHI',   PS_BK: 'CV1B1', PS_TOT: 0,     PS_WO: 0,    PS_SO: 0,   PS_IST: 0,  EQ_TOT: 0,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 385, ITEM: 'COM',   AREA: 'TOPSIDE', ZONE: 'CENTRAL',    MOD: 'CD100',       LEVEL: 'Production Deck',     G_EREC: 'CD130', G_2PE: 'CD130', G_1PE: 'CV1C1', EREC: '',      PE2: '',      PE1: '',      BLK: 'CV1C1', YARD: 'HHI',   PS_BK: 'CV1C1', PS_TOT: 0,     PS_WO: 0,    PS_SO: 0,   PS_IST: 0,  EQ_TOT: 0,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 386, ITEM: 'COM',   AREA: 'TOPSIDE', ZONE: 'CENTRAL',    MOD: 'CD100',       LEVEL: 'Production Deck',     G_EREC: 'CD130', G_2PE: 'CD130', G_1PE: 'CV1D1', EREC: '',      PE2: '',      PE1: '',      BLK: 'CV1D1', YARD: 'HHI',   PS_BK: 'CV1D1', PS_TOT: 0,     PS_WO: 0,    PS_SO: 0,   PS_IST: 0,  EQ_TOT: 0,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 387, ITEM: 'COM',   AREA: 'TOPSIDE', ZONE: 'CENTRAL',    MOD: 'CD100',       LEVEL: 'Production Deck',     G_EREC: 'CD130', G_2PE: 'CD130', G_1PE: 'CV1E1', EREC: '',      PE2: '',      PE1: '',      BLK: 'CV1E1', YARD: 'HHI',   PS_BK: 'CV1E1', PS_TOT: 0,     PS_WO: 0,    PS_SO: 0,   PS_IST: 0,  EQ_TOT: 0,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 388, ITEM: 'COM',   AREA: 'TOPSIDE', ZONE: 'CENTRAL',    MOD: 'CD100',       LEVEL: 'Production Deck',     G_EREC: 'CD130', G_2PE: 'CD130', G_1PE: 'CV1F1', EREC: '',      PE2: '',      PE1: '',      BLK: 'CV1F1', YARD: 'HHI',   PS_BK: 'CV1F1', PS_TOT: 0,     PS_WO: 0,    PS_SO: 0,   PS_IST: 0,  EQ_TOT: 0,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 389, ITEM: 'COM',   AREA: 'TOPSIDE', ZONE: 'CENTRAL',    MOD: 'CD100',       LEVEL: 'Production Deck',     G_EREC: 'CD130', G_2PE: 'CD130', G_1PE: 'CV1G1', EREC: '',      PE2: '',      PE1: '',      BLK: 'CV1G1', YARD: 'HHI',   PS_BK: 'CV1G1', PS_TOT: 0,     PS_WO: 0,    PS_SO: 0,   PS_IST: 0,  EQ_TOT: 0,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 390, ITEM: 'COM',   AREA: 'TOPSIDE', ZONE: 'CENTRAL',    MOD: 'CD100',       LEVEL: 'Production Deck',     G_EREC: 'CD130', G_2PE: 'CD130', G_1PE: 'CV1H1', EREC: '',      PE2: '',      PE1: '',      BLK: 'CV1H1', YARD: 'HHI',   PS_BK: 'CV1H1', PS_TOT: 0,     PS_WO: 0,    PS_SO: 0,   PS_IST: 0,  EQ_TOT: 0,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 391, ITEM: 'COM',   AREA: 'TOPSIDE', ZONE: 'CENTRAL',    MOD: 'CD100',       LEVEL: 'Production Deck',     G_EREC: 'CD130', G_2PE: 'CD130', G_1PE: 'CV1J1', EREC: '',      PE2: '',      PE1: '',      BLK: 'CV1J1', YARD: 'HHI',   PS_BK: 'CV1J1', PS_TOT: 0,     PS_WO: 0,    PS_SO: 0,   PS_IST: 0,  EQ_TOT: 0,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 392, ITEM: 'COM',   AREA: 'TOPSIDE', ZONE: 'CENTRAL',    MOD: 'CD200',       LEVEL: 'Mezz. Deck',          G_EREC: 'CD210', G_2PE: 'CD210', G_1PE: 'CD211', EREC: 'CD210', PE2: '',      PE1: '',      BLK: 'CD211', YARD: 'HHI',   PS_BK: 'CD211', PS_TOT: 171,  PS_WO: 0,    PS_SO: 0,   PS_IST: 0,  EQ_TOT: 4,  EQ_PO: 2,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 393, ITEM: 'COM',   AREA: 'TOPSIDE', ZONE: 'CENTRAL',    MOD: 'CD200',       LEVEL: 'Mezz. Deck',          G_EREC: 'CD220', G_2PE: 'CD220', G_1PE: 'CD220', EREC: 'CD220', PE2: '',      PE1: 'CD220', BLK: 'CD221', YARD: 'HHI',   PS_BK: 'CD221', PS_TOT: 215,  PS_WO: 0,    PS_SO: 0,   PS_IST: 0,  EQ_TOT: 6,  EQ_PO: 1,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 394, ITEM: 'COM',   AREA: 'TOPSIDE', ZONE: 'CENTRAL',    MOD: 'CD200',       LEVEL: 'Mezz. Deck',          G_EREC: 'CD220', G_2PE: 'CD220', G_1PE: 'CD220', EREC: '',      PE2: '',      PE1: '',      BLK: 'CV211', YARD: 'HHI',   PS_BK: 'CV211', PS_TOT: 0,     PS_WO: 0,    PS_SO: 0,   PS_IST: 0,  EQ_TOT: 0,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 395, ITEM: 'COM',   AREA: 'TOPSIDE', ZONE: 'CENTRAL',    MOD: 'CD200',       LEVEL: 'Mezz. Deck',          G_EREC: 'CD230', G_2PE: 'CD230', G_1PE: 'CD231', EREC: 'CD230', PE2: '',      PE1: '',      BLK: 'CD231', YARD: 'HHI',   PS_BK: 'CD231', PS_TOT: 119,  PS_WO: 0,    PS_SO: 0,   PS_IST: 0,  EQ_TOT: 0,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 396, ITEM: 'COM',   AREA: 'TOPSIDE', ZONE: 'CENTRAL',    MOD: 'CD200',       LEVEL: 'Mezz. Deck',          G_EREC: 'CD240', G_2PE: 'CD240', G_1PE: 'CD240', EREC: 'CD240', PE2: '',      PE1: 'CD240', BLK: 'CD241', YARD: 'HHI',   PS_BK: 'CD241', PS_TOT: 320,  PS_WO: 0,    PS_SO: 0,   PS_IST: 0,  EQ_TOT: 7,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 397, ITEM: 'COM',   AREA: 'TOPSIDE', ZONE: 'CENTRAL',    MOD: 'CD200',       LEVEL: 'Mezz. Deck',          G_EREC: 'CD240', G_2PE: 'CD240', G_1PE: 'CD240', EREC: '',      PE2: '',      PE1: '',      BLK: 'CD242', YARD: 'HHI',   PS_BK: 'CD242', PS_TOT: 0,     PS_WO: 0,    PS_SO: 0,   PS_IST: 0,  EQ_TOT: 0,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 398, ITEM: 'COM',   AREA: 'TOPSIDE', ZONE: 'CENTRAL',    MOD: 'CD200',       LEVEL: 'Mezz. Deck',          G_EREC: 'CD250', G_2PE: 'CD250', G_1PE: 'CD250', EREC: 'CD250', PE2: '',      PE1: 'CD250', BLK: 'CD251', YARD: 'HHI',   PS_BK: 'CD251', PS_TOT: 341,  PS_WO: 0,    PS_SO: 0,   PS_IST: 0,  EQ_TOT: 4,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 399, ITEM: 'COM',   AREA: 'TOPSIDE', ZONE: 'CENTRAL',    MOD: 'CD200',       LEVEL: 'Mezz. Deck',          G_EREC: 'CD25',  G_2PE: 'CD25',  G_1PE: 'CD250', EREC: '',      PE2: '',      PE1: '',      BLK: 'CD252', YARD: 'HHI',   PS_BK: 'CD252', PS_TOT: 0,     PS_WO: 0,    PS_SO: 0,   PS_IST: 0,  EQ_TOT: 0,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 400, ITEM: 'COM',   AREA: 'TOPSIDE', ZONE: 'CENTRAL',    MOD: 'CD200',       LEVEL: 'Mezz. Deck',          G_EREC: 'CD260', G_2PE: 'CD260', G_1PE: 'CD261', EREC: 'CD260', PE2: '',      PE1: '',      BLK: 'CD261', YARD: 'HHI',   PS_BK: 'CD261', PS_TOT: 0,     PS_WO: 0,    PS_SO: 0,   PS_IST: 0,  EQ_TOT: 0,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 401, ITEM: 'COM',   AREA: 'TOPSIDE', ZONE: 'CENTRAL',    MOD: 'CD200',       LEVEL: 'Mezz. Deck',          G_EREC: 'CD270', G_2PE: 'CD270', G_1PE: 'CD271', EREC: 'CD270', PE2: '',      PE1: '',      BLK: 'CD271', YARD: 'HHI',   PS_BK: 'CD271', PS_TOT: 0,     PS_WO: 0,    PS_SO: 0,   PS_IST: 0,  EQ_TOT: 0,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 402, ITEM: 'COM',   AREA: 'TOPSIDE', ZONE: 'CENTRAL',    MOD: 'CD300',       LEVEL: 'Main Deck',           G_EREC: 'CD310', G_2PE: 'CD310', G_1PE: 'CD310', EREC: 'CD310', PE2: 'CD310', PE1: 'CD310', BLK: 'CD311', YARD: 'HHI',   PS_BK: 'CD311', PS_TOT: 420,  PS_WO: 0,    PS_SO: 0,   PS_IST: 0,  EQ_TOT: 16, EQ_PO: 6,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 403, ITEM: 'COM',   AREA: 'TOPSIDE', ZONE: 'CENTRAL',    MOD: 'CD300',       LEVEL: 'Main Deck',           G_EREC: 'CD310', G_2PE: 'CD310', G_1PE: 'CD310', EREC: '',      PE2: '',      PE1: '',      BLK: 'CD312', YARD: 'HHI',   PS_BK: 'CD312', PS_TOT: 0,     PS_WO: 0,    PS_SO: 0,   PS_IST: 0,  EQ_TOT: 0,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 404, ITEM: 'COM',   AREA: 'TOPSIDE', ZONE: 'CENTRAL',    MOD: 'CD300',       LEVEL: 'Main Deck',           G_EREC: 'CD310', G_2PE: 'CD310', G_1PE: 'CD310', EREC: '',      PE2: '',      PE1: '',      BLK: 'CT311', YARD: 'HHI',   PS_BK: 'CT311', PS_TOT: 0,     PS_WO: 0,    PS_SO: 0,   PS_IST: 0,  EQ_TOT: 0,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 405, ITEM: 'COM',   AREA: 'TOPSIDE', ZONE: 'CENTRAL',    MOD: 'CD300',       LEVEL: 'Main Deck',           G_EREC: 'CD310', G_2PE: 'CD310', G_1PE: 'CD310', EREC: '',      PE2: '',      PE1: '',      BLK: 'CT331', YARD: 'HHI',   PS_BK: 'CT331', PS_TOT: 0,     PS_WO: 0,    PS_SO: 0,   PS_IST: 0,  EQ_TOT: 0,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 406, ITEM: 'COM',   AREA: 'TOPSIDE', ZONE: 'CENTRAL',    MOD: 'CD300',       LEVEL: 'Main Deck',           G_EREC: 'CD310', G_2PE: 'CD310', G_1PE: 'CD320', EREC: '',      PE2: '',      PE1: 'CD320', BLK: 'CD321', YARD: 'HHI',   PS_BK: 'CD321', PS_TOT: 140,  PS_WO: 0,    PS_SO: 0,   PS_IST: 0,  EQ_TOT: 0,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 407, ITEM: 'COM',   AREA: 'TOPSIDE', ZONE: 'CENTRAL',    MOD: 'CD300',       LEVEL: 'Main Deck',           G_EREC: 'CD310', G_2PE: 'CD310', G_1PE: 'CD320', EREC: '',      PE2: '',      PE1: '',      BLK: 'CD322', YARD: 'HHI',   PS_BK: 'CD322', PS_TOT: 0,     PS_WO: 0,    PS_SO: 0,   PS_IST: 0,  EQ_TOT: 0,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 408, ITEM: 'COM',   AREA: 'TOPSIDE', ZONE: 'CENTRAL',    MOD: 'CD300',       LEVEL: 'Main Deck',           G_EREC: 'CD310', G_2PE: 'CD310', G_1PE: 'CD320', EREC: '',      PE2: '',      PE1: '',      BLK: 'CT321', YARD: 'HHI',   PS_BK: 'CT321', PS_TOT: 0,     PS_WO: 0,    PS_SO: 0,   PS_IST: 0,  EQ_TOT: 0,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 409, ITEM: 'COM',   AREA: 'TOPSIDE', ZONE: 'CENTRAL',    MOD: 'CD300',       LEVEL: 'Main Deck',           G_EREC: 'CD310', G_2PE: 'CD310', G_1PE: 'CD320', EREC: '',      PE2: '',      PE1: '',      BLK: 'CT341', YARD: 'HHI',   PS_BK: 'CT341', PS_TOT: 0,     PS_WO: 0,    PS_SO: 0,   PS_IST: 0,  EQ_TOT: 0,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 410, ITEM: 'COM',   AREA: 'TOPSIDE', ZONE: 'CENTRAL',    MOD: 'CD300',       LEVEL: 'Main Deck',           G_EREC: 'CD310', G_2PE: 'CD310', G_1PE: 'CD330', EREC: '',      PE2: '',      PE1: 'CD330', BLK: 'CD331', YARD: 'HHI',   PS_BK: 'CD331', PS_TOT: 881,  PS_WO: 0,    PS_SO: 0,   PS_IST: 0,  EQ_TOT: 0,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 411, ITEM: 'COM',   AREA: 'TOPSIDE', ZONE: 'CENTRAL',    MOD: 'CD300',       LEVEL: 'Main Deck',           G_EREC: 'CD310', G_2PE: 'CD310', G_1PE: 'CD330', EREC: '',      PE2: '',      PE1: '',      BLK: 'CD332', YARD: 'HHI',   PS_BK: 'CD332', PS_TOT: 0,     PS_WO: 0,    PS_SO: 0,   PS_IST: 0,  EQ_TOT: 0,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 412, ITEM: 'COM',   AREA: 'TOPSIDE', ZONE: 'CENTRAL',    MOD: 'CD300',       LEVEL: 'Main Deck',           G_EREC: 'CD310', G_2PE: 'CD310', G_1PE: 'CV311', EREC: '',      PE2: '',      PE1: '',      BLK: 'CV311', YARD: 'HHI',   PS_BK: 'CV311', PS_TOT: 0,     PS_WO: 0,    PS_SO: 0,   PS_IST: 0,  EQ_TOT: 0,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 413, ITEM: 'COM',   AREA: 'TOPSIDE', ZONE: 'CENTRAL',    MOD: 'CD300',       LEVEL: 'Main Deck',           G_EREC: 'CD310', G_2PE: 'CD310', G_1PE: 'CV321', EREC: '',      PE2: '',      PE1: '',      BLK: 'CV321', YARD: 'HHI',   PS_BK: 'CV321', PS_TOT: 0,     PS_WO: 0,    PS_SO: 0,   PS_IST: 0,  EQ_TOT: 0,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 414, ITEM: 'COM',   AREA: 'TOPSIDE', ZONE: 'CENTRAL',    MOD: 'CD300',       LEVEL: 'Main Deck',           G_EREC: 'CD310', G_2PE: 'CD310', G_1PE: 'CV331', EREC: '',      PE2: '',      PE1: '',      BLK: 'CV331', YARD: 'HHI',   PS_BK: 'CV331', PS_TOT: 0,     PS_WO: 0,    PS_SO: 0,   PS_IST: 0,  EQ_TOT: 0,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 415, ITEM: 'COM',   AREA: 'TOPSIDE', ZONE: 'CENTRAL',    MOD: 'CD300',       LEVEL: 'Main Deck',           G_EREC: 'CD310', G_2PE: 'CD310', G_1PE: 'CV341', EREC: '',      PE2: '',      PE1: '',      BLK: 'CV341', YARD: 'HHI',   PS_BK: 'CV341', PS_TOT: 0,     PS_WO: 0,    PS_SO: 0,   PS_IST: 0,  EQ_TOT: 0,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 416, ITEM: 'COM',   AREA: 'TOPSIDE', ZONE: 'CENTRAL',    MOD: 'CD300',       LEVEL: 'Main Deck',           G_EREC: 'CD310', G_2PE: 'CD310', G_1PE: 'CK161', EREC: '',      PE2: '',      PE1: '',      BLK: 'CK161', YARD: 'HHI',   PS_BK: 'CK161', PS_TOT: 0,     PS_WO: 0,    PS_SO: 0,   PS_IST: 0,  EQ_TOT: 0,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 417, ITEM: 'COM',   AREA: 'TOPSIDE', ZONE: 'CENTRAL',    MOD: 'CD300',       LEVEL: 'Main Deck',           G_EREC: 'CD310', G_2PE: 'CD310', G_1PE: 'CP311', EREC: '',      PE2: '',      PE1: '',      BLK: 'CP311', YARD: 'HHI',   PS_BK: 'CP311', PS_TOT: 0,     PS_WO: 0,    PS_SO: 0,   PS_IST: 0,  EQ_TOT: 0,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 418, ITEM: 'COM',   AREA: 'TOPSIDE', ZONE: 'CENTRAL',    MOD: 'CD300',       LEVEL: 'Main Deck',           G_EREC: 'CD310', G_2PE: 'CD310', G_1PE: 'CP321', EREC: '',      PE2: '',      PE1: '',      BLK: 'CP321', YARD: 'HHI',   PS_BK: 'CP321', PS_TOT: 0,     PS_WO: 0,    PS_SO: 0,   PS_IST: 0,  EQ_TOT: 0,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 419, ITEM: 'COM',   AREA: 'TOPSIDE', ZONE: 'CENTRAL',    MOD: 'CD300',       LEVEL: 'Main Deck',           G_EREC: 'CD310', G_2PE: 'CD310', G_1PE: 'CP331', EREC: '',      PE2: '',      PE1: '',      BLK: 'CP331', YARD: 'HHI',   PS_BK: 'CP331', PS_TOT: 0,     PS_WO: 0,    PS_SO: 0,   PS_IST: 0,  EQ_TOT: 0,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 420, ITEM: 'COM',   AREA: 'TOPSIDE', ZONE: 'CENTRAL',    MOD: 'CD300',       LEVEL: 'Main Deck',           G_EREC: 'CD310', G_2PE: 'CD310', G_1PE: 'CP341', EREC: '',      PE2: '',      PE1: '',      BLK: 'CP341', YARD: 'HHI',   PS_BK: 'CP341', PS_TOT: 0,     PS_WO: 0,    PS_SO: 0,   PS_IST: 0,  EQ_TOT: 0,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 421, ITEM: 'COM',   AREA: 'TOPSIDE', ZONE: 'CENTRAL',    MOD: 'CD300',       LEVEL: 'Main Deck',           G_EREC: 'CD310', G_2PE: 'CD310', G_1PE: 'CP351', EREC: '',      PE2: '',      PE1: '',      BLK: 'CP351', YARD: 'HHI',   PS_BK: 'CP351', PS_TOT: 0,     PS_WO: 0,    PS_SO: 0,   PS_IST: 0,  EQ_TOT: 0,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 422, ITEM: 'COM',   AREA: 'TOPSIDE', ZONE: 'CENTRAL',    MOD: 'CD300',       LEVEL: 'Main Deck',           G_EREC: 'CD310', G_2PE: 'CD310', G_1PE: 'CP361', EREC: '',      PE2: '',      PE1: '',      BLK: 'CP361', YARD: 'HHI',   PS_BK: 'CP361', PS_TOT: 0,     PS_WO: 0,    PS_SO: 0,   PS_IST: 0,  EQ_TOT: 0,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 423, ITEM: 'COM',   AREA: 'TOPSIDE', ZONE: 'CENTRAL',    MOD: 'CD300',       LEVEL: 'Main Deck',           G_EREC: 'CD340', G_2PE: 'CD340', G_1PE: 'CD340', EREC: 'CD340', PE2: 'CD340', PE1: 'CD340', BLK: 'CD341', YARD: 'HHI',   PS_BK: 'CD341', PS_TOT: 856,  PS_WO: 0,    PS_SO: 0,   PS_IST: 0,  EQ_TOT: 20, EQ_PO: 9,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 424, ITEM: 'COM',   AREA: 'TOPSIDE', ZONE: 'CENTRAL',    MOD: 'CD300',       LEVEL: 'Main Deck',           G_EREC: 'CD340', G_2PE: 'CD340', G_1PE: 'CD340', EREC: '',      PE2: '',      PE1: '',      BLK: 'CD342', YARD: 'HHI',   PS_BK: 'CD342', PS_TOT: 0,     PS_WO: 0,    PS_SO: 0,   PS_IST: 0,  EQ_TOT: 0,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 425, ITEM: 'COM',   AREA: 'TOPSIDE', ZONE: 'CENTRAL',    MOD: 'CD300',       LEVEL: 'Main Deck',           G_EREC: 'CD340', G_2PE: 'CD340', G_1PE: 'CD340', EREC: '',      PE2: '',      PE1: '',      BLK: 'CT351', YARD: 'HHI',   PS_BK: 'CT351', PS_TOT: 0,     PS_WO: 0,    PS_SO: 0,   PS_IST: 0,  EQ_TOT: 0,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 426, ITEM: 'COM',   AREA: 'TOPSIDE', ZONE: 'CENTRAL',    MOD: 'CD300',       LEVEL: 'Main Deck',           G_EREC: 'CD340', G_2PE: 'CD340', G_1PE: 'CD340', EREC: '',      PE2: '',      PE1: '',      BLK: 'CT371', YARD: 'HHI',   PS_BK: 'CT371', PS_TOT: 0,     PS_WO: 0,    PS_SO: 0,   PS_IST: 0,  EQ_TOT: 0,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 427, ITEM: 'COM',   AREA: 'TOPSIDE', ZONE: 'CENTRAL',    MOD: 'CD300',       LEVEL: 'Main Deck',           G_EREC: 'CD340', G_2PE: 'CD340', G_1PE: 'CD350', EREC: '',      PE2: '',      PE1: 'CD350', BLK: 'CD351', YARD: 'HHI',   PS_BK: 'CD351', PS_TOT: 798,  PS_WO: 0,    PS_SO: 0,   PS_IST: 0,  EQ_TOT: 0,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 428, ITEM: 'COM',   AREA: 'TOPSIDE', ZONE: 'CENTRAL',    MOD: 'CD300',       LEVEL: 'Main Deck',           G_EREC: 'CD340', G_2PE: 'CD340', G_1PE: 'CD350', EREC: '',      PE2: '',      PE1: '',      BLK: 'CD352', YARD: 'HHI',   PS_BK: 'CD352', PS_TOT: 0,     PS_WO: 0,    PS_SO: 0,   PS_IST: 0,  EQ_TOT: 0,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 429, ITEM: 'COM',   AREA: 'TOPSIDE', ZONE: 'CENTRAL',    MOD: 'CD300',       LEVEL: 'Main Deck',           G_EREC: 'CD340', G_2PE: 'CD340', G_1PE: 'CD350', EREC: '',      PE2: '',      PE1: '',      BLK: 'CT361', YARD: 'HHI',   PS_BK: 'CT361', PS_TOT: 0,     PS_WO: 0,    PS_SO: 0,   PS_IST: 0,  EQ_TOT: 0,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 430, ITEM: 'COM',   AREA: 'TOPSIDE', ZONE: 'CENTRAL',    MOD: 'CD300',       LEVEL: 'Main Deck',           G_EREC: 'CD340', G_2PE: 'CD340', G_1PE: 'CD350', EREC: '',      PE2: '',      PE1: '',      BLK: 'CT381', YARD: 'HHI',   PS_BK: 'CT381', PS_TOT: 0,     PS_WO: 0,    PS_SO: 0,   PS_IST: 0,  EQ_TOT: 0,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 431, ITEM: 'COM',   AREA: 'TOPSIDE', ZONE: 'CENTRAL',    MOD: 'CD300',       LEVEL: 'Main Deck',           G_EREC: 'CD340', G_2PE: 'CD340', G_1PE: 'CV3A1', EREC: '',      PE2: '',      PE1: '',      BLK: 'CV3A1', YARD: 'HHI',   PS_BK: 'CV3A1', PS_TOT: 0,     PS_WO: 0,    PS_SO: 0,   PS_IST: 0,  EQ_TOT: 0,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 432, ITEM: 'COM',   AREA: 'TOPSIDE', ZONE: 'CENTRAL',    MOD: 'CD300',       LEVEL: 'Main Deck',           G_EREC: 'CD340', G_2PE: 'CD340', G_1PE: 'CV3B1', EREC: '',      PE2: '',      PE1: '',      BLK: 'CV3B1', YARD: 'HHI',   PS_BK: 'CV3B1', PS_TOT: 0,     PS_WO: 0,    PS_SO: 0,   PS_IST: 0,  EQ_TOT: 0,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 433, ITEM: 'COM',   AREA: 'TOPSIDE', ZONE: 'CENTRAL',    MOD: 'CD300',       LEVEL: 'Main Deck',           G_EREC: 'CD340', G_2PE: 'CD340', G_1PE: 'CV3C1', EREC: '',      PE2: '',      PE1: '',      BLK: 'CV3C1', YARD: 'HHI',   PS_BK: 'CV3C1', PS_TOT: 0,     PS_WO: 0,    PS_SO: 0,   PS_IST: 0,  EQ_TOT: 0,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 434, ITEM: 'COM',   AREA: 'TOPSIDE', ZONE: 'CENTRAL',    MOD: 'CD300',       LEVEL: 'Main Deck',           G_EREC: 'CD340', G_2PE: 'CD340', G_1PE: 'CV3D1', EREC: '',      PE2: '',      PE1: '',      BLK: 'CV3D1', YARD: 'HHI',   PS_BK: 'CV3D1', PS_TOT: 0,     PS_WO: 0,    PS_SO: 0,   PS_IST: 0,  EQ_TOT: 0,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 435, ITEM: 'COM',   AREA: 'TOPSIDE', ZONE: 'CENTRAL',    MOD: 'CD300',       LEVEL: 'Main Deck',           G_EREC: 'CD340', G_2PE: 'CD340', G_1PE: 'CV3E1', EREC: '',      PE2: '',      PE1: '',      BLK: 'CV3E1', YARD: 'HHI',   PS_BK: 'CV3E1', PS_TOT: 0,     PS_WO: 0,    PS_SO: 0,   PS_IST: 0,  EQ_TOT: 0,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 436, ITEM: 'COM',   AREA: 'TOPSIDE', ZONE: 'CENTRAL',    MOD: 'CD300',       LEVEL: 'Main Deck',           G_EREC: 'CD340', G_2PE: 'CD340', G_1PE: 'CV351', EREC: '',      PE2: '',      PE1: '',      BLK: 'CV351', YARD: 'HHI',   PS_BK: 'CV351', PS_TOT: 0,     PS_WO: 0,    PS_SO: 0,   PS_IST: 0,  EQ_TOT: 0,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 437, ITEM: 'COM',   AREA: 'TOPSIDE', ZONE: 'CENTRAL',    MOD: 'CD300',       LEVEL: 'Main Deck',           G_EREC: 'CD340', G_2PE: 'CD340', G_1PE: 'CK131', EREC: '',      PE2: '',      PE1: '',      BLK: 'CK131', YARD: 'HHI',   PS_BK: 'CK131', PS_TOT: 0,     PS_WO: 0,    PS_SO: 0,   PS_IST: 0,  EQ_TOT: 0,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 438, ITEM: 'COM',   AREA: 'TOPSIDE', ZONE: 'CENTRAL',    MOD: 'CD300',       LEVEL: 'Main Deck',           G_EREC: 'CD340', G_2PE: 'CD340', G_1PE: 'CP371', EREC: '',      PE2: '',      PE1: '',      BLK: 'CP371', YARD: 'HHI',   PS_BK: 'CP371', PS_TOT: 0,     PS_WO: 0,    PS_SO: 0,   PS_IST: 0,  EQ_TOT: 0,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 439, ITEM: 'COM',   AREA: 'TOPSIDE', ZONE: 'CENTRAL',    MOD: 'CD300',       LEVEL: 'Main Deck',           G_EREC: 'CD340', G_2PE: 'CD340', G_1PE: 'CP381', EREC: '',      PE2: '',      PE1: '',      BLK: 'CP381', YARD: 'HHI',   PS_BK: 'CP381', PS_TOT: 0,     PS_WO: 0,    PS_SO: 0,   PS_IST: 0,  EQ_TOT: 0,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 440, ITEM: 'COM',   AREA: 'TOPSIDE', ZONE: 'CENTRAL',    MOD: 'CD300',       LEVEL: 'Main Deck',           G_EREC: 'CD340', G_2PE: 'CD340', G_1PE: 'CP391', EREC: '',      PE2: '',      PE1: '',      BLK: 'CP391', YARD: 'HHI',   PS_BK: 'CP391', PS_TOT: 0,     PS_WO: 0,    PS_SO: 0,   PS_IST: 0,  EQ_TOT: 0,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 441, ITEM: 'COM',   AREA: 'TOPSIDE', ZONE: 'CENTRAL',    MOD: 'CD300',       LEVEL: 'Main Deck',           G_EREC: 'CD340', G_2PE: 'CD340', G_1PE: 'CP3A1', EREC: '',      PE2: '',      PE1: '',      BLK: 'CP3A1', YARD: 'HHI',   PS_BK: 'CP3A1', PS_TOT: 0,     PS_WO: 0,    PS_SO: 0,   PS_IST: 0,  EQ_TOT: 0,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 442, ITEM: 'COM',   AREA: 'TOPSIDE', ZONE: 'CENTRAL',    MOD: 'CD300',       LEVEL: 'Main Deck',           G_EREC: 'CD340', G_2PE: 'CD340', G_1PE: 'CP3B1', EREC: '',      PE2: '',      PE1: '',      BLK: 'CP3B1', YARD: 'HHI',   PS_BK: 'CP3B1', PS_TOT: 0,     PS_WO: 0,    PS_SO: 0,   PS_IST: 0,  EQ_TOT: 0,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 443, ITEM: 'COM',   AREA: 'TOPSIDE', ZONE: 'CENTRAL',    MOD: 'CD300',       LEVEL: 'Main Deck',           G_EREC: 'CD340', G_2PE: 'CD340', G_1PE: 'CP3C1', EREC: '',      PE2: '',      PE1: '',      BLK: 'CP3C1', YARD: 'HHI',   PS_BK: 'CP3C1', PS_TOT: 0,     PS_WO: 0,    PS_SO: 0,   PS_IST: 0,  EQ_TOT: 0,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 444, ITEM: 'COM',   AREA: 'TOPSIDE', ZONE: 'CENTRAL',    MOD: 'CX100',       LEVEL: 'Main Deck',           G_EREC: 'CX110', G_2PE: 'CX110', G_1PE: 'CX111', EREC: 'CX110', PE2: '',      PE1: '',      BLK: 'CX111', YARD: 'HHI',   PS_BK: 'CX111', PS_TOT: 0,     PS_WO: 0,    PS_SO: 0,   PS_IST: 0,  EQ_TOT: 0,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 445, ITEM: 'COM',   AREA: 'TOPSIDE', ZONE: 'CENTRAL',    MOD: 'CK100',       LEVEL: 'Main Deck',           G_EREC: 'CK110', G_2PE: 'CK110', G_1PE: 'CK110', EREC: 'CK110', PE2: '',      PE1: 'CK110', BLK: 'CK111', YARD: 'HHI',   PS_BK: 'CK111', PS_TOT: 0,     PS_WO: 0,    PS_SO: 0,   PS_IST: 0,  EQ_TOT: 1,  EQ_PO: 1,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 446, ITEM: 'COM',   AREA: 'TOPSIDE', ZONE: 'CENTRAL',    MOD: 'CK100',       LEVEL: 'Main Deck',           G_EREC: 'CK110', G_2PE: 'CK110', G_1PE: 'CK110', EREC: '',      PE2: '',      PE1: '',      BLK: 'CK121', YARD: 'HHI',   PS_BK: 'CK121', PS_TOT: 0,     PS_WO: 0,    PS_SO: 0,   PS_IST: 0,  EQ_TOT: 0,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 447, ITEM: 'COM',   AREA: 'TOPSIDE', ZONE: 'CENTRAL',    MOD: 'CK100',       LEVEL: 'Main Deck',           G_EREC: 'CK110', G_2PE: 'CK110', G_1PE: 'CK110', EREC: '',      PE2: '',      PE1: '',      BLK: 'CV411', YARD: 'HHI',   PS_BK: 'CV411', PS_TOT: 0,     PS_WO: 0,    PS_SO: 0,   PS_IST: 0,  EQ_TOT: 0,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 448, ITEM: 'COM',   AREA: 'TOPSIDE', ZONE: 'CENTRAL',    MOD: 'CK100',       LEVEL: 'Main Deck',           G_EREC: 'CK110', G_2PE: 'CK110', G_1PE: 'CK110', EREC: '',      PE2: '',      PE1: '',      BLK: 'CV421', YARD: 'HHI',   PS_BK: 'CV421', PS_TOT: 0,     PS_WO: 0,    PS_SO: 0,   PS_IST: 0,  EQ_TOT: 0,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 449, ITEM: 'COM',   AREA: 'TOPSIDE', ZONE: 'CENTRAL',    MOD: 'CK100',       LEVEL: 'Main Deck',           G_EREC: 'CK140', G_2PE: 'CK140', G_1PE: 'CK140', EREC: 'CK140', PE2: '',      PE1: 'CK140', BLK: 'CK141', YARD: 'HHI',   PS_BK: 'CK141', PS_TOT: 0,     PS_WO: 0,    PS_SO: 0,   PS_IST: 0,  EQ_TOT: 1,  EQ_PO: 1,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 450, ITEM: 'COM',   AREA: 'TOPSIDE', ZONE: 'CENTRAL',    MOD: 'CK100',       LEVEL: 'Main Deck',           G_EREC: 'CK140', G_2PE: 'CK140', G_1PE: 'CK140', EREC: '',      PE2: '',      PE1: '',      BLK: 'CK151', YARD: 'HHI',   PS_BK: 'CK151', PS_TOT: 0,     PS_WO: 0,    PS_SO: 0,   PS_IST: 0,  EQ_TOT: 0,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 451, ITEM: 'COM',   AREA: 'TOPSIDE', ZONE: 'SOUTH',      MOD: 'SD100',       LEVEL: 'Production Deck',     G_EREC: 'SD110', G_2PE: 'SD110', G_1PE: 'SD110', EREC: 'SD110', PE2: 'SD110', PE1: 'SD110', BLK: 'SD111', YARD: 'HHI',   PS_BK: 'SD111', PS_TOT: 408,  PS_WO: 0,    PS_SO: 0,   PS_IST: 0,  EQ_TOT: 22, EQ_PO: 12, EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 452, ITEM: 'COM',   AREA: 'TOPSIDE', ZONE: 'SOUTH',      MOD: 'SD100',       LEVEL: 'Production Deck',     G_EREC: 'SD110', G_2PE: 'SD110', G_1PE: 'SD110', EREC: '',      PE2: '',      PE1: '',      BLK: 'SD112', YARD: 'HHI',   PS_BK: 'SD112', PS_TOT: 0,     PS_WO: 0,    PS_SO: 0,   PS_IST: 0,  EQ_TOT: 0,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 453, ITEM: 'COM',   AREA: 'TOPSIDE', ZONE: 'SOUTH',      MOD: 'SD100',       LEVEL: 'Production Deck',     G_EREC: 'SD110', G_2PE: 'SD110', G_1PE: 'SD110', EREC: '',      PE2: '',      PE1: '',      BLK: 'SD113', YARD: 'HHI',   PS_BK: 'SD113', PS_TOT: 0,     PS_WO: 0,    PS_SO: 0,   PS_IST: 0,  EQ_TOT: 0,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 454, ITEM: 'COM',   AREA: 'TOPSIDE', ZONE: 'SOUTH',      MOD: 'SD100',       LEVEL: 'Production Deck',     G_EREC: 'SD110', G_2PE: 'SD110', G_1PE: 'SD110', EREC: '',      PE2: '',      PE1: '',      BLK: 'SD114', YARD: 'HHI',   PS_BK: 'SD114', PS_TOT: 0,     PS_WO: 0,    PS_SO: 0,   PS_IST: 0,  EQ_TOT: 0,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 455, ITEM: 'COM',   AREA: 'TOPSIDE', ZONE: 'SOUTH',      MOD: 'SD100',       LEVEL: 'Production Deck',     G_EREC: 'SD110', G_2PE: 'SD110', G_1PE: 'SD110', EREC: '',      PE2: '',      PE1: '',      BLK: 'SC110', YARD: 'HHI',   PS_BK: 'SC110', PS_TOT: 0,     PS_WO: 0,    PS_SO: 0,   PS_IST: 0,  EQ_TOT: 0,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 456, ITEM: 'COM',   AREA: 'TOPSIDE', ZONE: 'SOUTH',      MOD: 'SD100',       LEVEL: 'Production Deck',     G_EREC: 'SD110', G_2PE: 'SD110', G_1PE: 'SV111', EREC: '',      PE2: '',      PE1: '',      BLK: 'SV111', YARD: 'HHI',   PS_BK: 'SV111', PS_TOT: 0,     PS_WO: 0,    PS_SO: 0,   PS_IST: 0,  EQ_TOT: 0,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 457, ITEM: 'COM',   AREA: 'TOPSIDE', ZONE: 'SOUTH',      MOD: 'SD100',       LEVEL: 'Production Deck',     G_EREC: 'SD110', G_2PE: 'SD110', G_1PE: 'SV121', EREC: '',      PE2: '',      PE1: '',      BLK: 'SV121', YARD: 'HHI',   PS_BK: 'SV121', PS_TOT: 0,     PS_WO: 0,    PS_SO: 0,   PS_IST: 0,  EQ_TOT: 0,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 458, ITEM: 'COM',   AREA: 'TOPSIDE', ZONE: 'SOUTH',      MOD: 'SD100',       LEVEL: 'Production Deck',     G_EREC: 'SD110', G_2PE: 'SD110', G_1PE: 'SV131', EREC: '',      PE2: '',      PE1: '',      BLK: 'SV131', YARD: 'HHI',   PS_BK: 'SV131', PS_TOT: 0,     PS_WO: 0,    PS_SO: 0,   PS_IST: 0,  EQ_TOT: 0,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 459, ITEM: 'COM',   AREA: 'TOPSIDE', ZONE: 'SOUTH',      MOD: 'SD100',       LEVEL: 'Production Deck',     G_EREC: 'SD110', G_2PE: 'SD110', G_1PE: 'SV141', EREC: '',      PE2: '',      PE1: '',      BLK: 'SV141', YARD: 'HHI',   PS_BK: 'SV141', PS_TOT: 0,     PS_WO: 0,    PS_SO: 0,   PS_IST: 0,  EQ_TOT: 0,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 460, ITEM: 'COM',   AREA: 'TOPSIDE', ZONE: 'SOUTH',      MOD: 'SD100',       LEVEL: 'Production Deck',     G_EREC: 'SD110', G_2PE: 'SD110', G_1PE: 'SV151', EREC: '',      PE2: '',      PE1: '',      BLK: 'SV151', YARD: 'HHI',   PS_BK: 'SV151', PS_TOT: 0,     PS_WO: 0,    PS_SO: 0,   PS_IST: 0,  EQ_TOT: 0,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 461, ITEM: 'COM',   AREA: 'TOPSIDE', ZONE: 'SOUTH',      MOD: 'SD100',       LEVEL: 'Production Deck',     G_EREC: 'SD110', G_2PE: 'SD110', G_1PE: 'SV161', EREC: '',      PE2: '',      PE1: '',      BLK: 'SV161', YARD: 'HHI',   PS_BK: 'SV161', PS_TOT: 0,     PS_WO: 0,    PS_SO: 0,   PS_IST: 0,  EQ_TOT: 0,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 462, ITEM: 'COM',   AREA: 'TOPSIDE', ZONE: 'SOUTH',      MOD: 'SD100',       LEVEL: 'Production Deck',     G_EREC: 'SD110', G_2PE: 'SD110', G_1PE: 'SV171', EREC: '',      PE2: '',      PE1: '',      BLK: 'SV171', YARD: 'HHI',   PS_BK: 'SV171', PS_TOT: 0,     PS_WO: 0,    PS_SO: 0,   PS_IST: 0,  EQ_TOT: 0,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 463, ITEM: 'COM',   AREA: 'TOPSIDE', ZONE: 'SOUTH',      MOD: 'SD100',       LEVEL: 'Production Deck',     G_EREC: 'SD120', G_2PE: 'SD120', G_1PE: 'SD120', EREC: 'SD120', PE2: 'SD120', PE1: 'SD120', BLK: 'SD121', YARD: 'HHI',   PS_BK: 'SD121', PS_TOT: 622,  PS_WO: 0,    PS_SO: 0,   PS_IST: 0,  EQ_TOT: 46, EQ_PO: 21, EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 464, ITEM: 'COM',   AREA: 'TOPSIDE', ZONE: 'SOUTH',      MOD: 'SD100',       LEVEL: 'Production Deck',     G_EREC: 'SD120', G_2PE: 'SD120', G_1PE: 'SD120', EREC: '',      PE2: '',      PE1: '',      BLK: 'SD122', YARD: 'HHI',   PS_BK: 'SD122', PS_TOT: 0,     PS_WO: 0,    PS_SO: 0,   PS_IST: 0,  EQ_TOT: 0,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 465, ITEM: 'COM',   AREA: 'TOPSIDE', ZONE: 'SOUTH',      MOD: 'SD100',       LEVEL: 'Production Deck',     G_EREC: 'SD120', G_2PE: 'SD120', G_1PE: 'SD120', EREC: '',      PE2: '',      PE1: '',      BLK: 'SD123', YARD: 'HHI',   PS_BK: 'SD123', PS_TOT: 0,     PS_WO: 0,    PS_SO: 0,   PS_IST: 0,  EQ_TOT: 0,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 466, ITEM: 'COM',   AREA: 'TOPSIDE', ZONE: 'SOUTH',      MOD: 'SD100',       LEVEL: 'Production Deck',     G_EREC: 'SD120', G_2PE: 'SD120', G_1PE: 'SD120', EREC: '',      PE2: '',      PE1: '',      BLK: 'SD124', YARD: 'HHI',   PS_BK: 'SD124', PS_TOT: 0,     PS_WO: 0,    PS_SO: 0,   PS_IST: 0,  EQ_TOT: 0,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 467, ITEM: 'COM',   AREA: 'TOPSIDE', ZONE: 'SOUTH',      MOD: 'SD100',       LEVEL: 'Production Deck',     G_EREC: 'SD120', G_2PE: 'SD120', G_1PE: 'SD120', EREC: '',      PE2: '',      PE1: '',      BLK: 'SC121', YARD: 'HHI',   PS_BK: 'SC121', PS_TOT: 0,     PS_WO: 0,    PS_SO: 0,   PS_IST: 0,  EQ_TOT: 0,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 468, ITEM: 'COM',   AREA: 'TOPSIDE', ZONE: 'SOUTH',      MOD: 'SD100',       LEVEL: 'Production Deck',     G_EREC: 'SD120', G_2PE: 'SD120', G_1PE: 'SD130', EREC: '',      PE2: '',      PE1: 'SD130', BLK: 'SD131', YARD: 'HHI',   PS_BK: 'SD131', PS_TOT: 258,  PS_WO: 0,    PS_SO: 0,   PS_IST: 0,  EQ_TOT: 0,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 469, ITEM: 'COM',   AREA: 'TOPSIDE', ZONE: 'SOUTH',      MOD: 'SD100',       LEVEL: 'Production Deck',     G_EREC: 'SD120', G_2PE: 'SD120', G_1PE: 'SD130', EREC: '',      PE2: '',      PE1: '',      BLK: 'SD132', YARD: 'HHI',   PS_BK: 'SD132', PS_TOT: 0,     PS_WO: 0,    PS_SO: 0,   PS_IST: 0,  EQ_TOT: 0,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 470, ITEM: 'COM',   AREA: 'TOPSIDE', ZONE: 'SOUTH',      MOD: 'SD100',       LEVEL: 'Production Deck',     G_EREC: 'SD120', G_2PE: 'SD120', G_1PE: 'SD130', EREC: '',      PE2: '',      PE1: '',      BLK: 'SC130', YARD: 'HHI',   PS_BK: 'SC130', PS_TOT: 0,     PS_WO: 0,    PS_SO: 0,   PS_IST: 0,  EQ_TOT: 0,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 471, ITEM: 'COM',   AREA: 'TOPSIDE', ZONE: 'SOUTH',      MOD: 'SD100',       LEVEL: 'Production Deck',     G_EREC: 'SD120', G_2PE: 'SD120', G_1PE: 'ER110', EREC: '',      PE2: '',      PE1: 'ER110', BLK: 'ER111', YARD: 'HHI',   PS_BK: 'ER111', PS_TOT: 0,     PS_WO: 0,    PS_SO: 0,   PS_IST: 0,  EQ_TOT: 0,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 472, ITEM: 'COM',   AREA: 'TOPSIDE', ZONE: 'SOUTH',      MOD: 'SD100',       LEVEL: 'Production Deck',     G_EREC: 'SD120', G_2PE: 'SD120', G_1PE: 'ER110', EREC: '',      PE2: '',      PE1: '',      BLK: 'ES111', YARD: 'HHI',   PS_BK: 'ES111', PS_TOT: 0,     PS_WO: 0,    PS_SO: 0,   PS_IST: 0,  EQ_TOT: 0,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 473, ITEM: 'COM',   AREA: 'TOPSIDE', ZONE: 'SOUTH',      MOD: 'SD100',       LEVEL: 'Production Deck',     G_EREC: 'SD120', G_2PE: 'SD120', G_1PE: 'ER110', EREC: '',      PE2: '',      PE1: '',      BLK: 'ER121', YARD: 'HHI',   PS_BK: 'ER121', PS_TOT: 0,     PS_WO: 0,    PS_SO: 0,   PS_IST: 0,  EQ_TOT: 0,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 474, ITEM: 'COM',   AREA: 'TOPSIDE', ZONE: 'SOUTH',      MOD: 'SD100',       LEVEL: 'Production Deck',     G_EREC: 'SD120', G_2PE: 'SD120', G_1PE: 'SR110', EREC: '',      PE2: '',      PE1: 'SR110', BLK: 'SR111', YARD: 'HHI',   PS_BK: 'SR111', PS_TOT: 0,     PS_WO: 0,    PS_SO: 0,   PS_IST: 0,  EQ_TOT: 0,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 475, ITEM: 'COM',   AREA: 'TOPSIDE', ZONE: 'SOUTH',      MOD: 'SD100',       LEVEL: 'Production Deck',     G_EREC: 'SD120', G_2PE: 'SD120', G_1PE: 'SR110', EREC: '',      PE2: '',      PE1: '',      BLK: 'SS111', YARD: 'HHI',   PS_BK: 'SS111', PS_TOT: 0,     PS_WO: 0,    PS_SO: 0,   PS_IST: 0,  EQ_TOT: 0,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 476, ITEM: 'COM',   AREA: 'TOPSIDE', ZONE: 'SOUTH',      MOD: 'SD100',       LEVEL: 'Production Deck',     G_EREC: 'SD120', G_2PE: 'SD120', G_1PE: 'SR110', EREC: '',      PE2: '',      PE1: '',      BLK: 'SR121', YARD: 'HHI',   PS_BK: 'SR121', PS_TOT: 0,     PS_WO: 0,    PS_SO: 0,   PS_IST: 0,  EQ_TOT: 0,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 477, ITEM: 'COM',   AREA: 'TOPSIDE', ZONE: 'SOUTH',      MOD: 'SD100',       LEVEL: 'Production Deck',     G_EREC: 'SD120', G_2PE: 'SD120', G_1PE: 'SV181', EREC: '',      PE2: '',      PE1: '',      BLK: 'SV181', YARD: 'HHI',   PS_BK: 'SV181', PS_TOT: 0,     PS_WO: 0,    PS_SO: 0,   PS_IST: 0,  EQ_TOT: 0,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 478, ITEM: 'COM',   AREA: 'TOPSIDE', ZONE: 'SOUTH',      MOD: 'SD100',       LEVEL: 'Production Deck',     G_EREC: 'SD120', G_2PE: 'SD120', G_1PE: 'SV191', EREC: '',      PE2: '',      PE1: '',      BLK: 'SV191', YARD: 'HHI',   PS_BK: 'SV191', PS_TOT: 0,     PS_WO: 0,    PS_SO: 0,   PS_IST: 0,  EQ_TOT: 0,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 479, ITEM: 'COM',   AREA: 'TOPSIDE', ZONE: 'SOUTH',      MOD: 'SD100',       LEVEL: 'Production Deck',     G_EREC: 'SD120', G_2PE: 'SD120', G_1PE: 'SV1A1', EREC: '',      PE2: '',      PE1: '',      BLK: 'SV1A1', YARD: 'HHI',   PS_BK: 'SV1A1', PS_TOT: 0,     PS_WO: 0,    PS_SO: 0,   PS_IST: 0,  EQ_TOT: 0,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 480, ITEM: 'COM',   AREA: 'TOPSIDE', ZONE: 'SOUTH',      MOD: 'SD100',       LEVEL: 'Production Deck',     G_EREC: 'SD120', G_2PE: 'SD120', G_1PE: 'SV1B1', EREC: '',      PE2: '',      PE1: '',      BLK: 'SV1B1', YARD: 'HHI',   PS_BK: 'SV1B1', PS_TOT: 0,     PS_WO: 0,    PS_SO: 0,   PS_IST: 0,  EQ_TOT: 0,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 481, ITEM: 'COM',   AREA: 'TOPSIDE', ZONE: 'SOUTH',      MOD: 'SD100',       LEVEL: 'Production Deck',     G_EREC: 'SD120', G_2PE: 'SD120', G_1PE: 'SV1C1', EREC: '',      PE2: '',      PE1: '',      BLK: 'SV1C1', YARD: 'HHI',   PS_BK: 'SV1C1', PS_TOT: 0,     PS_WO: 0,    PS_SO: 0,   PS_IST: 0,  EQ_TOT: 0,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 482, ITEM: 'COM',   AREA: 'TOPSIDE', ZONE: 'SOUTH',      MOD: 'SD100',       LEVEL: 'Production Deck',     G_EREC: 'SD120', G_2PE: 'SD120', G_1PE: 'SV1D1', EREC: '',      PE2: '',      PE1: '',      BLK: 'SV1D1', YARD: 'HHI',   PS_BK: 'SV1D1', PS_TOT: 0,     PS_WO: 0,    PS_SO: 0,   PS_IST: 0,  EQ_TOT: 0,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 483, ITEM: 'COM',   AREA: 'TOPSIDE', ZONE: 'SOUTH',      MOD: 'SD100',       LEVEL: 'Production Deck',     G_EREC: 'SD120', G_2PE: 'SD120', G_1PE: 'SV1E1', EREC: '',      PE2: '',      PE1: '',      BLK: 'SV1E1', YARD: 'HHI',   PS_BK: 'SV1E1', PS_TOT: 0,     PS_WO: 0,    PS_SO: 0,   PS_IST: 0,  EQ_TOT: 0,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 484, ITEM: 'COM',   AREA: 'TOPSIDE', ZONE: 'SOUTH',      MOD: 'SD200',       LEVEL: 'Mezz. Deck',          G_EREC: 'SD120', G_2PE: 'SD120', G_1PE: 'SD210', EREC: '',      PE2: '',      PE1: 'SD210', BLK: 'SD211', YARD: 'HHI',   PS_BK: 'SD211', PS_TOT: 0,     PS_WO: 0,    PS_SO: 0,   PS_IST: 0,  EQ_TOT: 0,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 485, ITEM: 'COM',   AREA: 'TOPSIDE', ZONE: 'SOUTH',      MOD: 'SD200',       LEVEL: 'Mezz. Deck',          G_EREC: 'SD120', G_2PE: 'SD120', G_1PE: 'SD210', EREC: '',      PE2: '',      PE1: '',      BLK: 'SC211', YARD: 'HHI',   PS_BK: 'SC211', PS_TOT: 0,     PS_WO: 0,    PS_SO: 0,   PS_IST: 0,  EQ_TOT: 0,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 486, ITEM: 'COM',   AREA: 'TOPSIDE', ZONE: 'SOUTH',      MOD: 'SD200',       LEVEL: 'Mezz. Deck',          G_EREC: 'SD120', G_2PE: 'SD120', G_1PE: 'SD210', EREC: '',      PE2: '',      PE1: '',      BLK: 'SV211', YARD: 'HHI',   PS_BK: 'SV211', PS_TOT: 0,     PS_WO: 0,    PS_SO: 0,   PS_IST: 0,  EQ_TOT: 0,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 487, ITEM: 'COM',   AREA: 'TOPSIDE', ZONE: 'SOUTH',      MOD: 'SD300',       LEVEL: 'Main Deck',           G_EREC: 'SD310', G_2PE: 'SD310', G_1PE: 'SD310', EREC: 'SD310', PE2: 'SD310', PE1: 'SD310', BLK: 'SD311', YARD: 'HHI',   PS_BK: 'SD311', PS_TOT: 258,  PS_WO: 0,    PS_SO: 0,   PS_IST: 0,  EQ_TOT: 15, EQ_PO: 3,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 488, ITEM: 'COM',   AREA: 'TOPSIDE', ZONE: 'SOUTH',      MOD: 'SD300',       LEVEL: 'Main Deck',           G_EREC: 'SD310', G_2PE: 'SD310', G_1PE: 'SD310', EREC: '',      PE2: '',      PE1: '',      BLK: 'SD312', YARD: 'HHI',   PS_BK: 'SD312', PS_TOT: 0,     PS_WO: 0,    PS_SO: 0,   PS_IST: 0,  EQ_TOT: 0,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 489, ITEM: 'COM',   AREA: 'TOPSIDE', ZONE: 'SOUTH',      MOD: 'SD300',       LEVEL: 'Main Deck',           G_EREC: 'SD310', G_2PE: 'SD310', G_1PE: 'SD310', EREC: '',      PE2: '',      PE1: '',      BLK: 'SD313', YARD: 'HHI',   PS_BK: 'SD313', PS_TOT: 0,     PS_WO: 0,    PS_SO: 0,   PS_IST: 0,  EQ_TOT: 0,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 490, ITEM: 'COM',   AREA: 'TOPSIDE', ZONE: 'SOUTH',      MOD: 'SD300',       LEVEL: 'Main Deck',           G_EREC: 'SD310', G_2PE: 'SD310', G_1PE: 'SD310', EREC: '',      PE2: '',      PE1: '',      BLK: 'SD314', YARD: 'HHI',   PS_BK: 'SD314', PS_TOT: 0,     PS_WO: 0,    PS_SO: 0,   PS_IST: 0,  EQ_TOT: 0,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 491, ITEM: 'COM',   AREA: 'TOPSIDE', ZONE: 'SOUTH',      MOD: 'SD300',       LEVEL: 'Main Deck',           G_EREC: 'SD310', G_2PE: 'SD310', G_1PE: 'SD310', EREC: '',      PE2: '',      PE1: '',      BLK: 'ST311', YARD: 'HHI',   PS_BK: 'ST311', PS_TOT: 0,     PS_WO: 0,    PS_SO: 0,   PS_IST: 0,  EQ_TOT: 0,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 492, ITEM: 'COM',   AREA: 'TOPSIDE', ZONE: 'SOUTH',      MOD: 'SD300',       LEVEL: 'Main Deck',           G_EREC: 'SD310', G_2PE: 'SD310', G_1PE: 'SD310', EREC: '',      PE2: '',      PE1: '',      BLK: 'ST321', YARD: 'HHI',   PS_BK: 'ST321', PS_TOT: 0,     PS_WO: 0,    PS_SO: 0,   PS_IST: 0,  EQ_TOT: 0,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 493, ITEM: 'COM',   AREA: 'TOPSIDE', ZONE: 'SOUTH',      MOD: 'SD300',       LEVEL: 'Main Deck',           G_EREC: 'SD310', G_2PE: 'SD310', G_1PE: 'SD310', EREC: '',      PE2: '',      PE1: '',      BLK: 'ST331', YARD: 'HHI',   PS_BK: 'ST331', PS_TOT: 0,     PS_WO: 0,    PS_SO: 0,   PS_IST: 0,  EQ_TOT: 0,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 494, ITEM: 'COM',   AREA: 'TOPSIDE', ZONE: 'SOUTH',      MOD: 'SD300',       LEVEL: 'Main Deck',           G_EREC: 'SD310', G_2PE: 'SD310', G_1PE: 'SD310', EREC: '',      PE2: '',      PE1: '',      BLK: 'ST341', YARD: 'HHI',   PS_BK: 'ST341', PS_TOT: 0,     PS_WO: 0,    PS_SO: 0,   PS_IST: 0,  EQ_TOT: 0,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 495, ITEM: 'COM',   AREA: 'TOPSIDE', ZONE: 'SOUTH',      MOD: 'SD300',       LEVEL: 'Main Deck',           G_EREC: 'SD310', G_2PE: 'SD310', G_1PE: 'SD321', EREC: '',      PE2: '',      PE1: '',      BLK: 'SD321', YARD: 'HHI',   PS_BK: 'SD321', PS_TOT: 805,  PS_WO: 0,    PS_SO: 0,   PS_IST: 0,  EQ_TOT: 0,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 496, ITEM: 'COM',   AREA: 'TOPSIDE', ZONE: 'SOUTH',      MOD: 'SD300',       LEVEL: 'Main Deck',           G_EREC: 'SD310', G_2PE: 'SD310', G_1PE: 'SV311', EREC: '',      PE2: '',      PE1: '',      BLK: 'SV311', YARD: 'HHI',   PS_BK: 'SV311', PS_TOT: 0,     PS_WO: 0,    PS_SO: 0,   PS_IST: 0,  EQ_TOT: 0,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 497, ITEM: 'COM',   AREA: 'TOPSIDE', ZONE: 'SOUTH',      MOD: 'SD300',       LEVEL: 'Main Deck',           G_EREC: 'SD310', G_2PE: 'SD310', G_1PE: 'SV321', EREC: '',      PE2: '',      PE1: '',      BLK: 'SV321', YARD: 'HHI',   PS_BK: 'SV321', PS_TOT: 0,     PS_WO: 0,    PS_SO: 0,   PS_IST: 0,  EQ_TOT: 0,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 498, ITEM: 'COM',   AREA: 'TOPSIDE', ZONE: 'SOUTH',      MOD: 'SD300',       LEVEL: 'Main Deck',           G_EREC: 'SD310', G_2PE: 'SD310', G_1PE: 'SV331', EREC: '',      PE2: '',      PE1: '',      BLK: 'SV331', YARD: 'HHI',   PS_BK: 'SV331', PS_TOT: 0,     PS_WO: 0,    PS_SO: 0,   PS_IST: 0,  EQ_TOT: 0,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 499, ITEM: 'COM',   AREA: 'TOPSIDE', ZONE: 'SOUTH',      MOD: 'SD300',       LEVEL: 'Main Deck',           G_EREC: 'SD310', G_2PE: 'SD310', G_1PE: 'SV341', EREC: '',      PE2: '',      PE1: '',      BLK: 'SV341', YARD: 'HHI',   PS_BK: 'SV341', PS_TOT: 0,     PS_WO: 0,    PS_SO: 0,   PS_IST: 0,  EQ_TOT: 0,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 500, ITEM: 'COM',   AREA: 'TOPSIDE', ZONE: 'SOUTH',      MOD: 'SD300',       LEVEL: 'Main Deck',           G_EREC: 'SD310', G_2PE: 'SD310', G_1PE: 'SV351', EREC: '',      PE2: '',      PE1: '',      BLK: 'SV351', YARD: 'HHI',   PS_BK: 'SV351', PS_TOT: 0,     PS_WO: 0,    PS_SO: 0,   PS_IST: 0,  EQ_TOT: 0,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 501, ITEM: 'COM',   AREA: 'TOPSIDE', ZONE: 'SOUTH',      MOD: 'SD300',       LEVEL: 'Main Deck',           G_EREC: 'SD310', G_2PE: 'SD310', G_1PE: 'SV361', EREC: '',      PE2: '',      PE1: '',      BLK: 'SV361', YARD: 'HHI',   PS_BK: 'SV361', PS_TOT: 0,     PS_WO: 0,    PS_SO: 0,   PS_IST: 0,  EQ_TOT: 0,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 502, ITEM: 'COM',   AREA: 'TOPSIDE', ZONE: 'SOUTH',      MOD: 'SD300',       LEVEL: 'Main Deck',           G_EREC: 'SD310', G_2PE: 'SD310', G_1PE: 'SP311', EREC: '',      PE2: '',      PE1: '',      BLK: 'SP311', YARD: 'HHI',   PS_BK: 'SP311', PS_TOT: 0,     PS_WO: 0,    PS_SO: 0,   PS_IST: 0,  EQ_TOT: 0,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 503, ITEM: 'COM',   AREA: 'TOPSIDE', ZONE: 'SOUTH',      MOD: 'SD300',       LEVEL: 'Main Deck',           G_EREC: 'SD310', G_2PE: 'SD310', G_1PE: 'SP321', EREC: '',      PE2: '',      PE1: '',      BLK: 'SP321', YARD: 'HHI',   PS_BK: 'SP321', PS_TOT: 0,     PS_WO: 0,    PS_SO: 0,   PS_IST: 0,  EQ_TOT: 0,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 504, ITEM: 'COM',   AREA: 'TOPSIDE', ZONE: 'SOUTH',      MOD: 'SD300',       LEVEL: 'Main Deck',           G_EREC: 'SD310', G_2PE: 'SD310', G_1PE: 'SP331', EREC: '',      PE2: '',      PE1: '',      BLK: 'SP331', YARD: 'HHI',   PS_BK: 'SP331', PS_TOT: 0,     PS_WO: 0,    PS_SO: 0,   PS_IST: 0,  EQ_TOT: 0,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 505, ITEM: 'COM',   AREA: 'TOPSIDE', ZONE: 'SOUTH',      MOD: 'SD300',       LEVEL: 'Main Deck',           G_EREC: 'SD310', G_2PE: 'SD310', G_1PE: 'SP341', EREC: '',      PE2: '',      PE1: '',      BLK: 'SP341', YARD: 'HHI',   PS_BK: 'SP341', PS_TOT: 0,     PS_WO: 0,    PS_SO: 0,   PS_IST: 0,  EQ_TOT: 0,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 506, ITEM: 'COM',   AREA: 'TOPSIDE', ZONE: 'SOUTH',      MOD: 'SD300',       LEVEL: 'Main Deck',           G_EREC: 'SD310', G_2PE: 'SD310', G_1PE: 'SP351', EREC: '',      PE2: '',      PE1: '',      BLK: 'SP351', YARD: 'HHI',   PS_BK: 'SP351', PS_TOT: 0,     PS_WO: 0,    PS_SO: 0,   PS_IST: 0,  EQ_TOT: 0,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 507, ITEM: 'COM',   AREA: 'TOPSIDE', ZONE: 'SOUTH',      MOD: 'SD300',       LEVEL: 'Main Deck',           G_EREC: 'SD330', G_2PE: 'SD330', G_1PE: 'SD330', EREC: 'SD330', PE2: 'SD330', PE1: 'SD330', BLK: 'SD331', YARD: 'HHI',   PS_BK: 'SD331', PS_TOT: 427,  PS_WO: 0,    PS_SO: 0,   PS_IST: 0,  EQ_TOT: 2,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 508, ITEM: 'COM',   AREA: 'TOPSIDE', ZONE: 'SOUTH',      MOD: 'SD300',       LEVEL: 'Main Deck',           G_EREC: 'SD330', G_2PE: 'SD330', G_1PE: 'SD330', EREC: '',      PE2: '',      PE1: '',      BLK: 'SD332', YARD: 'HHI',   PS_BK: 'SD332', PS_TOT: 0,     PS_WO: 0,    PS_SO: 0,   PS_IST: 0,  EQ_TOT: 0,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 509, ITEM: 'COM',   AREA: 'TOPSIDE', ZONE: 'SOUTH',      MOD: 'SD300',       LEVEL: 'Main Deck',           G_EREC: 'SD330', G_2PE: 'SD330', G_1PE: 'SD330', EREC: '',      PE2: '',      PE1: '',      BLK: 'SD333', YARD: 'HHI',   PS_BK: 'SD333', PS_TOT: 0,     PS_WO: 0,    PS_SO: 0,   PS_IST: 0,  EQ_TOT: 0,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 510, ITEM: 'COM',   AREA: 'TOPSIDE', ZONE: 'SOUTH',      MOD: 'SD300',       LEVEL: 'Main Deck',           G_EREC: 'SD330', G_2PE: 'SD330', G_1PE: 'SD330', EREC: '',      PE2: '',      PE1: '',      BLK: 'ST351', YARD: 'HHI',   PS_BK: 'ST351', PS_TOT: 0,     PS_WO: 0,    PS_SO: 0,   PS_IST: 0,  EQ_TOT: 0,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 511, ITEM: 'COM',   AREA: 'TOPSIDE', ZONE: 'SOUTH',      MOD: 'SD300',       LEVEL: 'Main Deck',           G_EREC: 'SD330', G_2PE: 'SD330', G_1PE: 'SD330', EREC: '',      PE2: '',      PE1: '',      BLK: 'ST361', YARD: 'HHI',   PS_BK: 'ST361', PS_TOT: 0,     PS_WO: 0,    PS_SO: 0,   PS_IST: 0,  EQ_TOT: 0,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 512, ITEM: 'COM',   AREA: 'TOPSIDE', ZONE: 'SOUTH',      MOD: 'SD300',       LEVEL: 'Main Deck',           G_EREC: 'SD330', G_2PE: 'SD330', G_1PE: 'SD330', EREC: '',      PE2: '',      PE1: '',      BLK: 'ST371', YARD: 'HHI',   PS_BK: 'ST371', PS_TOT: 0,     PS_WO: 0,    PS_SO: 0,   PS_IST: 0,  EQ_TOT: 0,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 513, ITEM: 'COM',   AREA: 'TOPSIDE', ZONE: 'SOUTH',      MOD: 'SD300',       LEVEL: 'Main Deck',           G_EREC: 'SD330', G_2PE: 'SD330', G_1PE: 'SD330', EREC: '',      PE2: '',      PE1: '',      BLK: 'ST381', YARD: 'HHI',   PS_BK: 'ST381', PS_TOT: 0,     PS_WO: 0,    PS_SO: 0,   PS_IST: 0,  EQ_TOT: 0,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 514, ITEM: 'COM',   AREA: 'TOPSIDE', ZONE: 'SOUTH',      MOD: 'SD300',       LEVEL: 'Main Deck',           G_EREC: 'SD330', G_2PE: 'SD330', G_1PE: 'SP361', EREC: '',      PE2: '',      PE1: '',      BLK: 'SP361', YARD: 'HHI',   PS_BK: 'SP361', PS_TOT: 0,     PS_WO: 0,    PS_SO: 0,   PS_IST: 0,  EQ_TOT: 0,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 515, ITEM: 'COM',   AREA: 'TOPSIDE', ZONE: 'SOUTH',      MOD: 'SD300',       LEVEL: 'Main Deck',           G_EREC: 'SD330', G_2PE: 'SD330', G_1PE: 'SP371', EREC: '',      PE2: '',      PE1: '',      BLK: 'SP371', YARD: 'HHI',   PS_BK: 'SP371', PS_TOT: 0,     PS_WO: 0,    PS_SO: 0,   PS_IST: 0,  EQ_TOT: 0,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 516, ITEM: 'COM',   AREA: 'TOPSIDE', ZONE: 'SOUTH',      MOD: 'SD300',       LEVEL: 'Main Deck',           G_EREC: 'SD330', G_2PE: 'SD330', G_1PE: 'SP381', EREC: '',      PE2: '',      PE1: '',      BLK: 'SP381', YARD: 'HHI',   PS_BK: 'SP381', PS_TOT: 0,     PS_WO: 0,    PS_SO: 0,   PS_IST: 0,  EQ_TOT: 0,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 517, ITEM: 'COM',   AREA: 'TOPSIDE', ZONE: 'SOUTH',      MOD: 'SD300',       LEVEL: 'Main Deck',           G_EREC: 'SD330', G_2PE: 'SD330', G_1PE: 'SP391', EREC: '',      PE2: '',      PE1: '',      BLK: 'SP391', YARD: 'HHI',   PS_BK: 'SP391', PS_TOT: 0,     PS_WO: 0,    PS_SO: 0,   PS_IST: 0,  EQ_TOT: 0,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 518, ITEM: 'COM',   AREA: 'TOPSIDE', ZONE: 'SOUTH',      MOD: 'SX100',       LEVEL: 'Main Deck',           G_EREC: 'SX110', G_2PE: 'SX110', G_1PE: 'SX111', EREC: 'SX110', PE2: '',      PE1: '',      BLK: 'SX111', YARD: 'HHI',   PS_BK: 'SX111', PS_TOT: 0,     PS_WO: 0,    PS_SO: 0,   PS_IST: 0,  EQ_TOT: 0,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 519, ITEM: 'COM',   AREA: 'TOPSIDE', ZONE: 'SOUTH',      MOD: 'GD100',       LEVEL: 'Generator Building',  G_EREC: 'GD110', G_2PE: 'GD110', G_1PE: 'GD110', EREC: 'GD110', PE2: 'GD110', PE1: 'GD110', BLK: 'GD111', YARD: 'HHI',   PS_BK: 'GD111', PS_TOT: 519,  PS_WO: 0,    PS_SO: 0,   PS_IST: 0,  EQ_TOT: 20, EQ_PO: 6,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 520, ITEM: 'COM',   AREA: 'TOPSIDE', ZONE: 'SOUTH',      MOD: 'GD100',       LEVEL: 'Generator Building',  G_EREC: 'GD110', G_2PE: 'GD110', G_1PE: 'GD110', EREC: '',      PE2: '',      PE1: '',      BLK: 'GD112', YARD: 'HHI',   PS_BK: 'GD112', PS_TOT: 0,     PS_WO: 0,    PS_SO: 0,   PS_IST: 0,  EQ_TOT: 0,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 521, ITEM: 'COM',   AREA: 'TOPSIDE', ZONE: 'SOUTH',      MOD: 'GD100',       LEVEL: 'Generator Building',  G_EREC: 'GD110', G_2PE: 'GD110', G_1PE: 'GD110', EREC: '',      PE2: '',      PE1: '',      BLK: 'GD113', YARD: 'HHI',   PS_BK: 'GD113', PS_TOT: 0,     PS_WO: 0,    PS_SO: 0,   PS_IST: 0,  EQ_TOT: 0,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 522, ITEM: 'COM',   AREA: 'TOPSIDE', ZONE: 'SOUTH',      MOD: 'GD100',       LEVEL: 'Generator Building',  G_EREC: 'GD110', G_2PE: 'GD110', G_1PE: 'GD110', EREC: '',      PE2: '',      PE1: '',      BLK: 'GD114', YARD: 'HHI',   PS_BK: 'GD114', PS_TOT: 0,     PS_WO: 0,    PS_SO: 0,   PS_IST: 0,  EQ_TOT: 0,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 523, ITEM: 'COM',   AREA: 'TOPSIDE', ZONE: 'SOUTH',      MOD: 'GD100',       LEVEL: 'Generator Building',  G_EREC: 'GD110', G_2PE: 'GD110', G_1PE: 'GD110', EREC: '',      PE2: '',      PE1: '',      BLK: 'GC111', YARD: 'HHI',   PS_BK: 'GC111', PS_TOT: 0,     PS_WO: 0,    PS_SO: 0,   PS_IST: 0,  EQ_TOT: 0,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 524, ITEM: 'COM',   AREA: 'TOPSIDE', ZONE: 'SOUTH',      MOD: 'GD100',       LEVEL: 'Generator Building',  G_EREC: 'GD110', G_2PE: 'GD110', G_1PE: 'GV111', EREC: '',      PE2: '',      PE1: '',      BLK: 'GV111', YARD: 'HHI',   PS_BK: 'GV111', PS_TOT: 0,     PS_WO: 0,    PS_SO: 0,   PS_IST: 0,  EQ_TOT: 0,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 525, ITEM: 'COM',   AREA: 'TOPSIDE', ZONE: 'SOUTH',      MOD: 'GD100',       LEVEL: 'Generator Building',  G_EREC: 'GD110', G_2PE: 'GD110', G_1PE: 'GV121', EREC: '',      PE2: '',      PE1: '',      BLK: 'GV121', YARD: 'HHI',   PS_BK: 'GV121', PS_TOT: 0,     PS_WO: 0,    PS_SO: 0,   PS_IST: 0,  EQ_TOT: 0,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 526, ITEM: 'COM',   AREA: 'TOPSIDE', ZONE: 'SOUTH',      MOD: 'GD100',       LEVEL: 'Generator Building',  G_EREC: 'GD110', G_2PE: 'GD110', G_1PE: 'GV131', EREC: '',      PE2: '',      PE1: '',      BLK: 'GV131', YARD: 'HHI',   PS_BK: 'GV131', PS_TOT: 0,     PS_WO: 0,    PS_SO: 0,   PS_IST: 0,  EQ_TOT: 0,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 527, ITEM: 'COM',   AREA: 'TOPSIDE', ZONE: 'SOUTH',      MOD: 'GD100',       LEVEL: 'Generator Building',  G_EREC: 'GD110', G_2PE: 'GD110', G_1PE: 'GV141', EREC: '',      PE2: '',      PE1: '',      BLK: 'GV141', YARD: 'HHI',   PS_BK: 'GV141', PS_TOT: 0,     PS_WO: 0,    PS_SO: 0,   PS_IST: 0,  EQ_TOT: 0,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 528, ITEM: 'COM',   AREA: 'TOPSIDE', ZONE: 'SOUTH',      MOD: 'GD100',       LEVEL: 'Generator Building',  G_EREC: 'GD110', G_2PE: 'GD110', G_1PE: 'GV151', EREC: '',      PE2: '',      PE1: '',      BLK: 'GV151', YARD: 'HHI',   PS_BK: 'GV151', PS_TOT: 0,     PS_WO: 0,    PS_SO: 0,   PS_IST: 0,  EQ_TOT: 0,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 529, ITEM: 'COM',   AREA: 'TOPSIDE', ZONE: 'SOUTH',      MOD: 'GD100',       LEVEL: 'Generator Building',  G_EREC: 'GD110', G_2PE: 'GD110', G_1PE: 'GV161', EREC: '',      PE2: '',      PE1: '',      BLK: 'GV161', YARD: 'HHI',   PS_BK: 'GV161', PS_TOT: 0,     PS_WO: 0,    PS_SO: 0,   PS_IST: 0,  EQ_TOT: 0,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 530, ITEM: 'COM',   AREA: 'TOPSIDE', ZONE: 'SOUTH',      MOD: 'GD100',       LEVEL: 'Generator Building',  G_EREC: 'GD110', G_2PE: 'GD110', G_1PE: 'GV171', EREC: '',      PE2: '',      PE1: '',      BLK: 'GV171', YARD: 'HHI',   PS_BK: 'GV171', PS_TOT: 0,     PS_WO: 0,    PS_SO: 0,   PS_IST: 0,  EQ_TOT: 0,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 531, ITEM: 'COM',   AREA: 'TOPSIDE', ZONE: 'SOUTH',      MOD: 'GD100',       LEVEL: 'Generator Building',  G_EREC: 'GD110', G_2PE: 'GD110', G_1PE: 'GV181', EREC: '',      PE2: '',      PE1: '',      BLK: 'GV181', YARD: 'HHI',   PS_BK: 'GV181', PS_TOT: 0,     PS_WO: 0,    PS_SO: 0,   PS_IST: 0,  EQ_TOT: 0,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 532, ITEM: 'COM',   AREA: 'TOPSIDE', ZONE: 'SOUTH',      MOD: 'GD100',       LEVEL: 'Generator Building',  G_EREC: 'GD110', G_2PE: 'GD110', G_1PE: 'GV191', EREC: '',      PE2: '',      PE1: '',      BLK: 'GV191', YARD: 'HHI',   PS_BK: 'GV191', PS_TOT: 0,     PS_WO: 0,    PS_SO: 0,   PS_IST: 0,  EQ_TOT: 0,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 533, ITEM: 'COM',   AREA: 'TOPSIDE', ZONE: 'SOUTH',      MOD: 'GD100',       LEVEL: 'Generator Building',  G_EREC: 'GD110', G_2PE: 'GD110', G_1PE: 'GP111', EREC: '',      PE2: '',      PE1: '',      BLK: 'GP111', YARD: 'HHI',   PS_BK: 'GP111', PS_TOT: 0,     PS_WO: 0,    PS_SO: 0,   PS_IST: 0,  EQ_TOT: 0,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 534, ITEM: 'COM',   AREA: 'TOPSIDE', ZONE: 'SOUTH',      MOD: 'GD100',       LEVEL: 'Generator Building',  G_EREC: 'GD110', G_2PE: 'GD110', G_1PE: 'GP121', EREC: '',      PE2: '',      PE1: '',      BLK: 'GP121', YARD: 'HHI',   PS_BK: 'GP121', PS_TOT: 0,     PS_WO: 0,    PS_SO: 0,   PS_IST: 0,  EQ_TOT: 0,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 535, ITEM: 'COM',   AREA: 'TOPSIDE', ZONE: 'SOUTH',      MOD: 'GD100',       LEVEL: 'Generator Building',  G_EREC: 'GD110', G_2PE: 'GD110', G_1PE: 'GB111', EREC: '',      PE2: '',      PE1: '',      BLK: 'GB111', YARD: 'HHI',   PS_BK: 'GB111', PS_TOT: 0,     PS_WO: 0,    PS_SO: 0,   PS_IST: 0,  EQ_TOT: 0,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 536, ITEM: 'COM',   AREA: 'TOPSIDE', ZONE: 'SOUTH',      MOD: 'GD100',       LEVEL: 'Generator Building',  G_EREC: 'GD110', G_2PE: 'GD110', G_1PE: 'GB121', EREC: '',      PE2: '',      PE1: '',      BLK: 'GB121', YARD: 'HHI',   PS_BK: 'GB121', PS_TOT: 0,     PS_WO: 0,    PS_SO: 0,   PS_IST: 0,  EQ_TOT: 0,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 537, ITEM: 'COM',   AREA: 'TOPSIDE', ZONE: 'SOUTH',      MOD: 'GD100',       LEVEL: 'Generator Building',  G_EREC: 'GD110', G_2PE: 'GD110', G_1PE: 'GB131', EREC: '',      PE2: '',      PE1: '',      BLK: 'GB131', YARD: 'HHI',   PS_BK: 'GB131', PS_TOT: 0,     PS_WO: 0,    PS_SO: 0,   PS_IST: 0,  EQ_TOT: 0,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 538, ITEM: 'COM',   AREA: 'TOPSIDE', ZONE: 'SOUTH',      MOD: 'ER300',       LEVEL: 'Erectrical Building', G_EREC: 'ER310', G_2PE: 'ER310', G_1PE: 'ER310', EREC: 'ER310', PE2: 'ER310', PE1: 'ER310', BLK: 'ER311', YARD: 'HHI',   PS_BK: 'ER311', PS_TOT: 0,     PS_WO: 0,    PS_SO: 0,   PS_IST: 0,  EQ_TOT: 95, EQ_PO: 54, EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 539, ITEM: 'COM',   AREA: 'TOPSIDE', ZONE: 'SOUTH',      MOD: 'ER300',       LEVEL: 'Erectrical Building', G_EREC: 'ER310', G_2PE: 'ER310', G_1PE: 'ER310', EREC: '',      PE2: '',      PE1: '',      BLK: 'ES311', YARD: 'HHI',   PS_BK: 'ES311', PS_TOT: 0,     PS_WO: 0,    PS_SO: 0,   PS_IST: 0,  EQ_TOT: 0,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 540, ITEM: 'COM',   AREA: 'TOPSIDE', ZONE: 'SOUTH',      MOD: 'ER300',       LEVEL: 'Erectrical Building', G_EREC: 'ER310', G_2PE: 'ER310', G_1PE: 'ER310', EREC: '',      PE2: '',      PE1: '',      BLK: 'ER321', YARD: 'HHI',   PS_BK: 'ER321', PS_TOT: 0,     PS_WO: 0,    PS_SO: 0,   PS_IST: 0,  EQ_TOT: 0,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 541, ITEM: 'COM',   AREA: 'TOPSIDE', ZONE: 'SOUTH',      MOD: 'ER300',       LEVEL: 'Erectrical Building', G_EREC: 'ER310', G_2PE: 'ER310', G_1PE: 'ER310', EREC: '',      PE2: '',      PE1: '',      BLK: 'ES321', YARD: 'HHI',   PS_BK: 'ES321', PS_TOT: 0,     PS_WO: 0,    PS_SO: 0,   PS_IST: 0,  EQ_TOT: 0,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 542, ITEM: 'COM',   AREA: 'TOPSIDE', ZONE: 'SOUTH',      MOD: 'ER300',       LEVEL: 'Erectrical Building', G_EREC: 'ER310', G_2PE: 'ER310', G_1PE: 'ER310', EREC: '',      PE2: '',      PE1: '',      BLK: 'ER331', YARD: 'HHI',   PS_BK: 'ER331', PS_TOT: 0,     PS_WO: 0,    PS_SO: 0,   PS_IST: 0,  EQ_TOT: 0,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 543, ITEM: 'COM',   AREA: 'TOPSIDE', ZONE: 'SOUTH',      MOD: 'ER300',       LEVEL: 'Erectrical Building', G_EREC: 'ER310', G_2PE: 'ER310', G_1PE: 'ER310', EREC: '',      PE2: '',      PE1: '',      BLK: 'EV111', YARD: 'HHI',   PS_BK: 'EV111', PS_TOT: 0,     PS_WO: 0,    PS_SO: 0,   PS_IST: 0,  EQ_TOT: 0,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 544, ITEM: 'COM',   AREA: 'TOPSIDE', ZONE: 'SOUTH',      MOD: 'ER300',       LEVEL: 'Erectrical Building', G_EREC: 'ER310', G_2PE: 'ER310', G_1PE: 'ER310', EREC: '',      PE2: '',      PE1: '',      BLK: 'EV121', YARD: 'HHI',   PS_BK: 'EV121', PS_TOT: 0,     PS_WO: 0,    PS_SO: 0,   PS_IST: 0,  EQ_TOT: 0,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 545, ITEM: 'COM',   AREA: 'TOPSIDE', ZONE: 'SOUTH',      MOD: 'ER300',       LEVEL: 'Erectrical Building', G_EREC: 'ER310', G_2PE: 'ER310', G_1PE: 'ER310', EREC: '',      PE2: '',      PE1: '',      BLK: 'EV131', YARD: 'HHI',   PS_BK: 'EV131', PS_TOT: 0,     PS_WO: 0,    PS_SO: 0,   PS_IST: 0,  EQ_TOT: 0,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 546, ITEM: 'COM',   AREA: 'TOPSIDE', ZONE: 'SOUTH',      MOD: 'ER300',       LEVEL: 'Erectrical Building', G_EREC: 'ER310', G_2PE: 'ER310', G_1PE: 'ER310', EREC: '',      PE2: '',      PE1: '',      BLK: 'EV141', YARD: 'HHI',   PS_BK: 'EV141', PS_TOT: 0,     PS_WO: 0,    PS_SO: 0,   PS_IST: 0,  EQ_TOT: 0,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 547, ITEM: 'COM',   AREA: 'TOPSIDE', ZONE: 'SOUTH',      MOD: 'ER300',       LEVEL: 'Erectrical Building', G_EREC: 'ER310', G_2PE: 'ER310', G_1PE: 'ER310', EREC: '',      PE2: '',      PE1: '',      BLK: 'EV211', YARD: 'HHI',   PS_BK: 'EV211', PS_TOT: 0,     PS_WO: 0,    PS_SO: 0,   PS_IST: 0,  EQ_TOT: 0,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 548, ITEM: 'COM',   AREA: 'TOPSIDE', ZONE: 'SOUTH',      MOD: 'ER300',       LEVEL: 'Erectrical Building', G_EREC: 'ER310', G_2PE: 'ER310', G_1PE: 'ER310', EREC: '',      PE2: '',      PE1: '',      BLK: 'EV221', YARD: 'HHI',   PS_BK: 'EV221', PS_TOT: 0,     PS_WO: 0,    PS_SO: 0,   PS_IST: 0,  EQ_TOT: 0,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 549, ITEM: 'COM',   AREA: 'TOPSIDE', ZONE: 'SOUTH',      MOD: 'ER300',       LEVEL: 'Erectrical Building', G_EREC: 'ER310', G_2PE: 'ER310', G_1PE: 'ER310', EREC: '',      PE2: '',      PE1: '',      BLK: 'EV231', YARD: 'HHI',   PS_BK: 'EV231', PS_TOT: 0,     PS_WO: 0,    PS_SO: 0,   PS_IST: 0,  EQ_TOT: 0,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 550, ITEM: 'COM',   AREA: 'TOPSIDE', ZONE: 'SOUTH',      MOD: 'ER300',       LEVEL: 'Erectrical Building', G_EREC: 'ER310', G_2PE: 'ER310', G_1PE: 'ER310', EREC: '',      PE2: '',      PE1: '',      BLK: 'EV241', YARD: 'HHI',   PS_BK: 'EV241', PS_TOT: 0,     PS_WO: 0,    PS_SO: 0,   PS_IST: 0,  EQ_TOT: 0,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 551, ITEM: 'COM',   AREA: 'TOPSIDE', ZONE: 'SOUTH',      MOD: 'ER300',       LEVEL: 'Erectrical Building', G_EREC: 'ER310', G_2PE: 'ER310', G_1PE: 'ER310', EREC: '',      PE2: '',      PE1: '',      BLK: 'EV3A1', YARD: 'HHI',   PS_BK: 'EV3A1', PS_TOT: 0,     PS_WO: 0,    PS_SO: 0,   PS_IST: 0,  EQ_TOT: 0,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 552, ITEM: 'COM',   AREA: 'TOPSIDE', ZONE: 'FLARE',      MOD: 'NF100',       LEVEL: 'Flare Tower',         G_EREC: 'NF110', G_2PE: 'NF110', G_1PE: 'NF110', EREC: 'NF110', PE2: 'NF110', PE1: 'NF110', BLK: 'NF111', YARD: 'HHI',   PS_BK: 'NF111', PS_TOT: 149,  PS_WO: 0,    PS_SO: 0,   PS_IST: 0,  EQ_TOT: 2,  EQ_PO: 1,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 553, ITEM: 'COM',   AREA: 'TOPSIDE', ZONE: 'FLARE',      MOD: 'NF100',       LEVEL: 'Flare Tower',         G_EREC: 'NF110', G_2PE: 'NF110', G_1PE: 'NF110', EREC: '',      PE2: '',      PE1: '',      BLK: 'NF112', YARD: 'HHI',   PS_BK: 'NF112', PS_TOT: 0,     PS_WO: 0,    PS_SO: 0,   PS_IST: 0,  EQ_TOT: 0,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 554, ITEM: 'COM',   AREA: 'TOPSIDE', ZONE: 'FLARE',      MOD: 'NF100',       LEVEL: 'Flare Tower',         G_EREC: 'NF110', G_2PE: 'NF110', G_1PE: 'NF120', EREC: '',      PE2: '',      PE1: 'NF120', BLK: 'NF121', YARD: 'HHI',   PS_BK: 'NF121', PS_TOT: 0,     PS_WO: 0,    PS_SO: 0,   PS_IST: 0,  EQ_TOT: 0,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 555, ITEM: 'COM',   AREA: 'TOPSIDE', ZONE: 'FLARE',      MOD: 'NF100',       LEVEL: 'Flare Tower',         G_EREC: 'NF110', G_2PE: 'NF110', G_1PE: 'NF120', EREC: '',      PE2: '',      PE1: '',      BLK: 'NF122', YARD: 'HHI',   PS_BK: 'NF122', PS_TOT: 0,     PS_WO: 0,    PS_SO: 0,   PS_IST: 0,  EQ_TOT: 0,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 556, ITEM: 'COM',   AREA: 'TOPSIDE', ZONE: 'FLARE',      MOD: 'NF100',       LEVEL: 'Flare Tower',         G_EREC: 'NF110', G_2PE: 'NF110', G_1PE: 'NF130', EREC: '',      PE2: '',      PE1: 'NF130', BLK: 'NF131', YARD: 'HHI',   PS_BK: 'NF131', PS_TOT: 0,     PS_WO: 0,    PS_SO: 0,   PS_IST: 0,  EQ_TOT: 0,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 557, ITEM: 'COM',   AREA: 'TOPSIDE', ZONE: 'FLARE',      MOD: 'NF100',       LEVEL: 'Flare Tower',         G_EREC: 'NF110', G_2PE: 'NF110', G_1PE: 'NF130', EREC: '',      PE2: '',      PE1: '',      BLK: 'NF132', YARD: 'HHI',   PS_BK: 'NF132', PS_TOT: 0,     PS_WO: 0,    PS_SO: 0,   PS_IST: 0,  EQ_TOT: 0,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 558, ITEM: 'COM',   AREA: 'LQ',      ZONE: 'LQ',         MOD: 'LD100',       LEVEL: 'LQ-Level 1',          G_EREC: 'LD110', G_2PE: 'LD110', G_1PE: 'LD110', EREC: 'LD110', PE2: 'LD110', PE1: 'LD110', BLK: 'LD111', YARD: 'HHI',   PS_BK: 'LD111', PS_TOT: 0,     PS_WO: 0,    PS_SO: 0,   PS_IST: 0,  EQ_TOT: 72, EQ_PO: 17, EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 559, ITEM: 'COM',   AREA: 'LQ',      ZONE: 'LQ',         MOD: 'LD100',       LEVEL: 'LQ-Level 1',          G_EREC: 'LD110', G_2PE: 'LD110', G_1PE: 'LD110', EREC: '',      PE2: '',      PE1: '',      BLK: 'LD112', YARD: 'HHI',   PS_BK: 'LD112', PS_TOT: 23,   PS_WO: 0,    PS_SO: 0,   PS_IST: 0,  EQ_TOT: 0,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 560, ITEM: 'COM',   AREA: 'LQ',      ZONE: 'LQ',         MOD: 'LD100',       LEVEL: 'LQ-Level 1',          G_EREC: 'LD110', G_2PE: 'LD110', G_1PE: 'LD110', EREC: '',      PE2: '',      PE1: '',      BLK: 'LC111', YARD: 'HHI',   PS_BK: 'LC111', PS_TOT: 0,     PS_WO: 0,    PS_SO: 0,   PS_IST: 0,  EQ_TOT: 0,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 561, ITEM: 'COM',   AREA: 'LQ',      ZONE: 'LQ',         MOD: 'LD100',       LEVEL: 'LQ-Level 1',          G_EREC: 'LD110', G_2PE: 'LD110', G_1PE: 'LD120', EREC: '',      PE2: '',      PE1: 'LD120', BLK: 'LD121', YARD: 'HHI',   PS_BK: 'LD121', PS_TOT: 33,   PS_WO: 0,    PS_SO: 0,   PS_IST: 0,  EQ_TOT: 0,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 562, ITEM: 'COM',   AREA: 'LQ',      ZONE: 'LQ',         MOD: 'LD100',       LEVEL: 'LQ-Level 1',          G_EREC: 'LD110', G_2PE: 'LD110', G_1PE: 'LD120', EREC: '',      PE2: '',      PE1: '',      BLK: 'LC121', YARD: 'HHI',   PS_BK: 'LC121', PS_TOT: 0,     PS_WO: 0,    PS_SO: 0,   PS_IST: 0,  EQ_TOT: 0,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 563, ITEM: 'COM',   AREA: 'LQ',      ZONE: 'LQ',         MOD: 'LD200',       LEVEL: 'LQ-Level 2',          G_EREC: 'LD210', G_2PE: 'LD210', G_1PE: 'LD210', EREC: 'LD210', PE2: 'LD210', PE1: 'LD210', BLK: 'LD211', YARD: 'HHI',   PS_BK: 'LD211', PS_TOT: 21,   PS_WO: 0,    PS_SO: 0,   PS_IST: 0,  EQ_TOT: 12, EQ_PO: 8,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 564, ITEM: 'COM',   AREA: 'LQ',      ZONE: 'LQ',         MOD: 'LD200',       LEVEL: 'LQ-Level 2',          G_EREC: 'LD210', G_2PE: 'LD210', G_1PE: 'LD210', EREC: '',      PE2: '',      PE1: '',      BLK: 'LD212', YARD: 'HHI',   PS_BK: 'LD212', PS_TOT: 38,   PS_WO: 0,    PS_SO: 0,   PS_IST: 0,  EQ_TOT: 0,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 565, ITEM: 'COM',   AREA: 'LQ',      ZONE: 'LQ',         MOD: 'LD200',       LEVEL: 'LQ-Level 2',          G_EREC: 'LD210', G_2PE: 'LD210', G_1PE: 'LD210', EREC: '',      PE2: '',      PE1: '',      BLK: 'LW211', YARD: 'HHI',   PS_BK: 'LW211', PS_TOT: 0,     PS_WO: 0,    PS_SO: 0,   PS_IST: 0,  EQ_TOT: 0,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 566, ITEM: 'COM',   AREA: 'LQ',      ZONE: 'LQ',         MOD: 'LD200',       LEVEL: 'LQ-Level 2',          G_EREC: 'LD210', G_2PE: 'LD210', G_1PE: 'LD220', EREC: '',      PE2: '',      PE1: 'LD220', BLK: 'LD221', YARD: 'HHI',   PS_BK: 'LD221', PS_TOT: 0,     PS_WO: 8,   PS_SO: 0,  PS_IST: 0, EQ_TOT: 0,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 567, ITEM: 'COM',   AREA: 'LQ',      ZONE: 'LQ',         MOD: 'LD200',       LEVEL: 'LQ-Level 2',          G_EREC: 'LD210', G_2PE: 'LD210', G_1PE: 'LD220', EREC: '',      PE2: '',      PE1: 'LD220', BLK: 'LD221', YARD: 'HHI',   PS_BK: 'LD221', PS_TOT: 68,   PS_WO: 0,    PS_SO: 0,   PS_IST: 0,  EQ_TOT: 0,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 568, ITEM: 'COM',   AREA: 'LQ',      ZONE: 'LQ',         MOD: 'LD200',       LEVEL: 'LQ-Level 2',          G_EREC: 'LD210', G_2PE: 'LD210', G_1PE: 'LD220', EREC: '',      PE2: '',      PE1: '',      BLK: 'LW221', YARD: 'HHI',   PS_BK: 'LW221', PS_TOT: 0,     PS_WO: 0,    PS_SO: 0,   PS_IST: 0,  EQ_TOT: 0,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 569, ITEM: 'COM',   AREA: 'LQ',      ZONE: 'LQ',         MOD: 'LD200',       LEVEL: 'LQ-Level 2',          G_EREC: 'LD210', G_2PE: 'LD210', G_1PE: 'LV211', EREC: '',      PE2: '',      PE1: '',      BLK: 'LV211', YARD: 'HHI',   PS_BK: 'LV211', PS_TOT: 0,     PS_WO: 0,    PS_SO: 0,   PS_IST: 0,  EQ_TOT: 0,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 570, ITEM: 'COM',   AREA: 'LQ',      ZONE: 'LQ',         MOD: 'LD200',       LEVEL: 'LQ-Level 2',          G_EREC: 'LD210', G_2PE: 'LD210', G_1PE: 'LV221', EREC: '',      PE2: '',      PE1: '',      BLK: 'LV221', YARD: 'HHI',   PS_BK: 'LV221', PS_TOT: 0,     PS_WO: 0,    PS_SO: 0,   PS_IST: 0,  EQ_TOT: 0,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 571, ITEM: 'COM',   AREA: 'LQ',      ZONE: 'LQ',         MOD: 'LD200',       LEVEL: 'LQ-Level 2',          G_EREC: 'LD210', G_2PE: 'LD210', G_1PE: 'LV231', EREC: '',      PE2: '',      PE1: '',      BLK: 'LV231', YARD: 'HHI',   PS_BK: 'LV231', PS_TOT: 0,     PS_WO: 0,    PS_SO: 0,   PS_IST: 0,  EQ_TOT: 0,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 572, ITEM: 'COM',   AREA: 'LQ',      ZONE: 'LQ',         MOD: 'LD200',       LEVEL: 'LQ-Level 2',          G_EREC: 'LD210', G_2PE: 'LD210', G_1PE: 'LV2A1', EREC: '',      PE2: '',      PE1: '',      BLK: 'LV2A1', YARD: 'HHI',   PS_BK: 'LV2A1', PS_TOT: 0,     PS_WO: 0,    PS_SO: 0,   PS_IST: 0,  EQ_TOT: 0,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 573, ITEM: 'COM',   AREA: 'LQ',      ZONE: 'LQ',         MOD: 'LD200',       LEVEL: 'LQ-Level 2',          G_EREC: 'LD210', G_2PE: 'LD210', G_1PE: 'LV241', EREC: '',      PE2: '',      PE1: '',      BLK: 'LV241', YARD: 'HHI',   PS_BK: 'LV241', PS_TOT: 0,     PS_WO: 0,    PS_SO: 0,   PS_IST: 0,  EQ_TOT: 0,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 574, ITEM: 'COM',   AREA: 'LQ',      ZONE: 'LQ',         MOD: 'LD200',       LEVEL: 'LQ-Level 2',          G_EREC: 'LD210', G_2PE: 'LD210', G_1PE: 'LV250', EREC: '',      PE2: '',      PE1: '',      BLK: 'LV251', YARD: 'HHI',   PS_BK: 'LV251', PS_TOT: 0,     PS_WO: 0,    PS_SO: 0,   PS_IST: 0,  EQ_TOT: 0,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 575, ITEM: 'COM',   AREA: 'LQ',      ZONE: 'LQ',         MOD: 'LD200',       LEVEL: 'LQ-Level 2',          G_EREC: 'LD210', G_2PE: 'LD210', G_1PE: 'LV250', EREC: '',      PE2: '',      PE1: '',      BLK: 'LC221', YARD: 'HHI',   PS_BK: 'LC221', PS_TOT: 0,     PS_WO: 0,    PS_SO: 0,   PS_IST: 0,  EQ_TOT: 0,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 576, ITEM: 'COM',   AREA: 'LQ',      ZONE: 'LQ',         MOD: 'LD200',       LEVEL: 'LQ-Level 2',          G_EREC: 'LD210', G_2PE: 'LD210', G_1PE: 'LV261', EREC: '',      PE2: '',      PE1: '',      BLK: 'LV261', YARD: 'HHI',   PS_BK: 'LV261', PS_TOT: 0,     PS_WO: 0,    PS_SO: 0,   PS_IST: 0,  EQ_TOT: 0,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 577, ITEM: 'COM',   AREA: 'LQ',      ZONE: 'LQ',         MOD: 'LD300',       LEVEL: 'LQ-Level 3',          G_EREC: 'LD310', G_2PE: 'LD310', G_1PE: 'LD310', EREC: 'LD310', PE2: 'LD310', PE1: 'LD310', BLK: 'LD311', YARD: 'HHI',   PS_BK: 'LD311', PS_TOT: 31,   PS_WO: 0,    PS_SO: 0,   PS_IST: 0,  EQ_TOT: 5,  EQ_PO: 4,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 578, ITEM: 'COM',   AREA: 'LQ',      ZONE: 'LQ',         MOD: 'LD300',       LEVEL: 'LQ-Level 3',          G_EREC: 'LD310', G_2PE: 'LD310', G_1PE: 'LD310', EREC: '',      PE2: '',      PE1: '',      BLK: 'LD312', YARD: 'HHI',   PS_BK: 'LD312', PS_TOT: 2,    PS_WO: 0,    PS_SO: 0,   PS_IST: 0,  EQ_TOT: 0,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 579, ITEM: 'COM',   AREA: 'LQ',      ZONE: 'LQ',         MOD: 'LD300',       LEVEL: 'LQ-Level 3',          G_EREC: 'LD310', G_2PE: 'LD310', G_1PE: 'LD310', EREC: '',      PE2: '',      PE1: '',      BLK: 'LW311', YARD: 'HHI',   PS_BK: 'LW311', PS_TOT: 0,     PS_WO: 0,    PS_SO: 0,   PS_IST: 0,  EQ_TOT: 0,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 580, ITEM: 'COM',   AREA: 'LQ',      ZONE: 'LQ',         MOD: 'LD300',       LEVEL: 'LQ-Level 3',          G_EREC: 'LD310', G_2PE: 'LD310', G_1PE: 'LD320', EREC: '',      PE2: '',      PE1: 'LD320', BLK: 'LD321', YARD: 'HHI',   PS_BK: 'LD321', PS_TOT: 27,   PS_WO: 0,    PS_SO: 0,   PS_IST: 0,  EQ_TOT: 0,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 581, ITEM: 'COM',   AREA: 'LQ',      ZONE: 'LQ',         MOD: 'LD300',       LEVEL: 'LQ-Level 3',          G_EREC: 'LD310', G_2PE: 'LD310', G_1PE: 'LD320', EREC: '',      PE2: '',      PE1: '',      BLK: 'LW321', YARD: 'HHI',   PS_BK: 'LW321', PS_TOT: 0,     PS_WO: 0,    PS_SO: 0,   PS_IST: 0,  EQ_TOT: 0,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 582, ITEM: 'COM',   AREA: 'LQ',      ZONE: 'LQ',         MOD: 'LD300',       LEVEL: 'LQ-Level 3',          G_EREC: 'LD310', G_2PE: 'LD310', G_1PE: 'LV311', EREC: '',      PE2: '',      PE1: '',      BLK: 'LV311', YARD: 'HHI',   PS_BK: 'LV311', PS_TOT: 0,     PS_WO: 0,    PS_SO: 0,   PS_IST: 0,  EQ_TOT: 0,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 583, ITEM: 'COM',   AREA: 'LQ',      ZONE: 'LQ',         MOD: 'LD300',       LEVEL: 'LQ-Level 3',          G_EREC: 'LD310', G_2PE: 'LD310', G_1PE: 'LV321', EREC: '',      PE2: '',      PE1: '',      BLK: 'LV321', YARD: 'HHI',   PS_BK: 'LV321', PS_TOT: 0,     PS_WO: 0,    PS_SO: 0,   PS_IST: 0,  EQ_TOT: 0,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 584, ITEM: 'COM',   AREA: 'LQ',      ZONE: 'LQ',         MOD: 'LD300',       LEVEL: 'LQ-Level 3',          G_EREC: 'LD310', G_2PE: 'LD310', G_1PE: 'LV331', EREC: '',      PE2: '',      PE1: '',      BLK: 'LV331', YARD: 'HHI',   PS_BK: 'LV331', PS_TOT: 0,     PS_WO: 0,    PS_SO: 0,   PS_IST: 0,  EQ_TOT: 0,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 585, ITEM: 'COM',   AREA: 'LQ',      ZONE: 'LQ',         MOD: 'LD300',       LEVEL: 'LQ-Level 3',          G_EREC: 'LD310', G_2PE: 'LD310', G_1PE: 'LV341', EREC: '',      PE2: '',      PE1: '',      BLK: 'LV341', YARD: 'HHI',   PS_BK: 'LV341', PS_TOT: 0,     PS_WO: 0,    PS_SO: 0,   PS_IST: 0,  EQ_TOT: 0,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 586, ITEM: 'COM',   AREA: 'LQ',      ZONE: 'LQ',         MOD: 'LD400',       LEVEL: 'LQ-Level 4',          G_EREC: 'LD410', G_2PE: 'LD410', G_1PE: 'LD410', EREC: 'LD410', PE2: 'LD410', PE1: 'LD410', BLK: 'LD411', YARD: 'HHI',   PS_BK: 'LD411', PS_TOT: 25,   PS_WO: 0,    PS_SO: 0,   PS_IST: 0,  EQ_TOT: 24, EQ_PO: 12, EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 587, ITEM: 'COM',   AREA: 'LQ',      ZONE: 'LQ',         MOD: 'LD400',       LEVEL: 'LQ-Level 4',          G_EREC: 'LD410', G_2PE: 'LD410', G_1PE: 'LD410', EREC: '',      PE2: '',      PE1: '',      BLK: 'LD412', YARD: 'HHI',   PS_BK: 'LD412', PS_TOT: 14,   PS_WO: 0,    PS_SO: 0,   PS_IST: 0,  EQ_TOT: 0,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 588, ITEM: 'COM',   AREA: 'LQ',      ZONE: 'LQ',         MOD: 'LD400',       LEVEL: 'LQ-Level 4',          G_EREC: 'LD410', G_2PE: 'LD410', G_1PE: 'LD410', EREC: '',      PE2: '',      PE1: '',      BLK: 'LW411', YARD: 'HHI',   PS_BK: 'LW411', PS_TOT: 0,     PS_WO: 0,    PS_SO: 0,   PS_IST: 0,  EQ_TOT: 0,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 589, ITEM: 'COM',   AREA: 'LQ',      ZONE: 'LQ',         MOD: 'LD400',       LEVEL: 'LQ-Level 4',          G_EREC: 'LD410', G_2PE: 'LD410', G_1PE: 'LD420', EREC: '',      PE2: '',      PE1: 'LD420', BLK: 'LD421', YARD: 'HHI',   PS_BK: 'LD421', PS_TOT: 66,   PS_WO: 0,    PS_SO: 0,   PS_IST: 0,  EQ_TOT: 0,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 590, ITEM: 'COM',   AREA: 'LQ',      ZONE: 'LQ',         MOD: 'LD400',       LEVEL: 'LQ-Level 4',          G_EREC: 'LD410', G_2PE: 'LD410', G_1PE: 'LD420', EREC: '',      PE2: '',      PE1: '',      BLK: 'LW421', YARD: 'HHI',   PS_BK: 'LW421', PS_TOT: 0,     PS_WO: 0,    PS_SO: 0,   PS_IST: 0,  EQ_TOT: 0,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 591, ITEM: 'COM',   AREA: 'LQ',      ZONE: 'LQ',         MOD: 'LD400',       LEVEL: 'LQ-Level 4',          G_EREC: 'LD410', G_2PE: 'LD410', G_1PE: 'LV411', EREC: '',      PE2: '',      PE1: '',      BLK: 'LV411', YARD: 'HHI',   PS_BK: 'LV411', PS_TOT: 0,     PS_WO: 0,    PS_SO: 0,   PS_IST: 0,  EQ_TOT: 0,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 592, ITEM: 'COM',   AREA: 'LQ',      ZONE: 'LQ',         MOD: 'LD400',       LEVEL: 'LQ-Level 4',          G_EREC: 'LD410', G_2PE: 'LD410', G_1PE: 'LV421', EREC: '',      PE2: '',      PE1: '',      BLK: 'LV421', YARD: 'HHI',   PS_BK: 'LV421', PS_TOT: 0,     PS_WO: 0,    PS_SO: 0,   PS_IST: 0,  EQ_TOT: 0,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 593, ITEM: 'COM',   AREA: 'LQ',      ZONE: 'LQ',         MOD: 'LD400',       LEVEL: 'LQ-Level 4',          G_EREC: 'LD410', G_2PE: 'LD410', G_1PE: 'LV431', EREC: '',      PE2: '',      PE1: '',      BLK: 'LV431', YARD: 'HHI',   PS_BK: 'LV431', PS_TOT: 0,     PS_WO: 0,    PS_SO: 0,   PS_IST: 0,  EQ_TOT: 0,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 594, ITEM: 'COM',   AREA: 'LQ',      ZONE: 'LQ',         MOD: 'LD500',       LEVEL: 'LQ-Level 4',          G_EREC: 'LD410', G_2PE: 'LD410', G_1PE: 'LD511', EREC: '',      PE2: '',      PE1: '',      BLK: 'LD511', YARD: 'HHI',   PS_BK: 'LD511', PS_TOT: 25,   PS_WO: 0,    PS_SO: 0,   PS_IST: 0,  EQ_TOT: 0,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 596, ITEM: 'COM',   AREA: 'LQ',      ZONE: 'LQ',         MOD: 'LD500',       LEVEL: 'LQ-Level 4',          G_EREC: 'LN4A0', G_2PE: 'LV520', G_1PE: 'LN4A0', EREC: 'LV520', PE2: '',      PE1: '',      BLK: 'LN4A1', YARD: 'HHI',   PS_BK: 'LV521', PS_TOT: 0,     PS_WO: 0,    PS_SO: 0,   PS_IST: 0,  EQ_TOT: 1,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 596, ITEM: 'COM',   AREA: 'LQ',      ZONE: 'LQ',         MOD: 'LD500',       LEVEL: 'LQ-Level 4',          G_EREC: 'LN4B0', G_2PE: 'LV520', G_1PE: 'LN4B0', EREC: 'LV520', PE2: '',      PE1: '',      BLK: 'LN4B1', YARD: 'HHI',   PS_BK: 'LV521', PS_TOT: 0,     PS_WO: 0,    PS_SO: 0,   PS_IST: 0,  EQ_TOT: 1,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 596, ITEM: 'COM',   AREA: 'LQ',      ZONE: 'LQ',         MOD: 'LD500',       LEVEL: 'LQ-Level 4',          G_EREC: 'LN4C0', G_2PE: 'LV520', G_1PE: 'LN4C0', EREC: 'LV520', PE2: '',      PE1: '',      BLK: 'LN4C1', YARD: 'HHI',   PS_BK: 'LV521', PS_TOT: 0,     PS_WO: 0,    PS_SO: 0,   PS_IST: 0,  EQ_TOT: 1,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 595, ITEM: 'COM',   AREA: 'LQ',      ZONE: 'LQ',         MOD: 'LH100',       LEVEL: 'LQ-Level 4',          G_EREC: 'LHS10', G_2PE: 'LHS10', G_1PE: 'LHS11', EREC: 'LHS10', PE2: '',      PE1: '',      BLK: 'LHS11', YARD: 'HHI',   PS_BK: 'LHS11', PS_TOT: 0,     PS_WO: 0,    PS_SO: 0,   PS_IST: 0,  EQ_TOT: 0,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 596, ITEM: 'COM',   AREA: 'LQ',      ZONE: 'LQ',         MOD: 'LD500',       LEVEL: 'LQ-Level 4',          G_EREC: 'LV520', G_2PE: 'LV520', G_1PE: 'LV521', EREC: 'LV520', PE2: '',      PE1: '',      BLK: 'LV521', YARD: 'HHI',   PS_BK: 'LV521', PS_TOT: 0,     PS_WO: 0,    PS_SO: 0,   PS_IST: 0,  EQ_TOT: 1,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 597, ITEM: 'COM',   AREA: 'LQ',      ZONE: 'LQ',         MOD: 'LD500',       LEVEL: 'LQ-Level 4',          G_EREC: 'LV530', G_2PE: 'LV530', G_1PE: 'LV531', EREC: 'LV530', PE2: '',      PE1: '',      BLK: 'LV531', YARD: 'HHI',   PS_BK: 'LV531', PS_TOT: 0,     PS_WO: 0,    PS_SO: 0,   PS_IST: 0,  EQ_TOT: 0,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 598, ITEM: 'COM',   AREA: 'LQ',      ZONE: 'LQ',         MOD: 'LD500',       LEVEL: 'Stair House',          G_EREC: 'LV510', G_2PE: 'LV510', G_1PE: 'LV511', EREC: 'LV510', PE2: '',      PE1: '',      BLK: 'LV511', YARD: 'HHI',   PS_BK: 'LV511', PS_TOT: 0,     PS_WO: 0,    PS_SO: 0,   PS_IST: 0,  EQ_TOT: 0,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 599, ITEM: 'COM',   AREA: 'LQ',      ZONE: 'LQ',         MOD: 'LH100',       LEVEL: 'Heli Deck',           G_EREC: 'LH110', G_2PE: 'LH110', G_1PE: 'LH111', EREC: 'LH110', PE2: '',      PE1: '',      BLK: 'LH111', YARD: 'HHI',   PS_BK: 'LH111', PS_TOT: 0,     PS_WO: 0,    PS_SO: 0,   PS_IST: 0,  EQ_TOT: 1,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 600, ITEM: 'SPOOL', AREA: 'HULL',    ZONE: 'COMMON',     MOD: 'Integration', LEVEL: 'Integration',         G_EREC: 'SPL',   G_2PE: '',      G_1PE: '',      EREC: 'SPL',   PE2: '',      PE1: '',      BLK: 'H_INT', YARD: 'HHI',   PS_BK: 'H_INT', PS_TOT: 645,  PS_WO: 0,    PS_SO: 0,   PS_IST: 0,  EQ_TOT: 0,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      { SN: 601, ITEM: 'SPOOL', AREA: 'TOPSIDE', ZONE: 'COMMON',     MOD: 'Integration', LEVEL: 'Integration',         G_EREC: 'SPL',   G_2PE: '',      G_1PE: '',      EREC: 'SPL',   PE2: '',      PE1: '',      BLK: 'T_INT', YARD: 'HHI',   PS_BK: 'T_INT', PS_TOT: 210,  PS_WO: 0,    PS_SO: 0,   PS_IST: 0,  EQ_TOT: 0,  EQ_PO: 0,  EQ_FAT: 0, EQ_SITE: 0, EQ_IST: 0, },
      ],

    // Equipment Layout
    SQL3: [
      { SN: 1,   AREA: 'TOPSIDE', ZONE: 'CENTRAL', LEVEL: 'PD',  SVG: 'PD',  ID: 1,  BLK: 'CD130', ALIGN: 'start', TAG: '101630-MBL-50700',       DESC: 'INSTRUMENT AIR RECEIVER 1',                                    PO_NO: 'QU557BCQ9B',  PO_DATE: '2024-04-01', VENDOR: 'Wooyang HC',                       COUNTRY: 'Korea',       INCOTERMS: 'DAP', FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: '2025-04-20', SRD: '2025-07-29', INSTALL: '', SHIP_MODE: 'NA',       SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: 'NA', G_EREC: 'CD130', ER_PS: '2025-08-12', ER_PF: '2026-03-10', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 100, DT_ETA: 114,  DT_SRD: 14, },
      { SN: 2,   AREA: 'TOPSIDE', ZONE: 'CENTRAL', LEVEL: 'PD',  SVG: 'PD',  ID: 2,  BLK: 'CD130', ALIGN: 'start', TAG: '101630-MBL-50800',       DESC: 'INSTRUMENT AIR RECEIVER 2',                                    PO_NO: 'QU557BCQ9B',  PO_DATE: '2024-04-01', VENDOR: 'Wooyang HC',                       COUNTRY: 'Korea',       INCOTERMS: 'DAP', FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: '2025-04-20', SRD: '2025-07-29', INSTALL: '', SHIP_MODE: 'NA',       SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: 'NA', G_EREC: 'CD130', ER_PS: '2025-08-12', ER_PF: '2026-03-10', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 100, DT_ETA: 114,  DT_SRD: 14, },
      { SN: 3,   AREA: 'TOPSIDE', ZONE: 'CENTRAL', LEVEL: 'PD',  SVG: 'PD',  ID: 3,  BLK: 'CD130', ALIGN: 'start', TAG: '101530-SSW-23005',       DESC: 'EYE WASH & SAFETY SHOWER STATION',                             PO_NO: '',            PO_DATE: undefined,    VENDOR: '',                                 COUNTRY: '',            INCOTERMS: '',    FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: undefined,    SRD: undefined,    INSTALL: '', SHIP_MODE: '',         SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: '',   G_EREC: 'CD130', ER_PS: '2025-08-12', ER_PF: '2026-03-10', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 0,    DT_ETA: 0,     DT_SRD: 0, },
      { SN: 4,   AREA: 'TOPSIDE', ZONE: 'CENTRAL', LEVEL: 'PD',  SVG: 'PD',  ID: 4,  BLK: 'CD130', ALIGN: 'start', TAG: '101420-ZZZ-35000',       DESC: 'WATER INJECTION CHEMICAL SKID 1',                              PO_NO: 'QD557AFQ52',  PO_DATE: '2024-03-28', VENDOR: 'Cameron',                          COUNTRY: 'China',       INCOTERMS: 'FOB', FAT_PLAN: '2025-06-16', FAT_FC: undefined,    FAT_ACT: undefined,    ETA: '2025-09-30', SRD: '2025-07-29', INSTALL: '', SHIP_MODE: 'NA',       SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: 'NA', G_EREC: 'CD130', ER_PS: '2025-08-12', ER_PF: '2026-03-10', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: -63, DT_ETA: -49,  DT_SRD: 14, },
      { SN: 5,   AREA: 'TOPSIDE', ZONE: 'CENTRAL', LEVEL: 'PD',  SVG: 'PD',  ID: 5,  BLK: 'CD130', ALIGN: 'start', TAG: '101630-ZZZ-50600',       DESC: 'AIR DRYER AND FILTRATION SKID',                                PO_NO: 'QU557BRQ06',  PO_DATE: '2024-03-08', VENDOR: 'Gardner Denver Korea',             COUNTRY: 'Korea',       INCOTERMS: 'DAP', FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: '2025-05-31', SRD: '2025-07-29', INSTALL: '', SHIP_MODE: 'by Barge', SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: 'NA', G_EREC: 'CD130', ER_PS: '2025-08-12', ER_PF: '2026-03-10', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 59,  DT_ETA: 73,   DT_SRD: 14, },
      { SN: 6,   AREA: 'TOPSIDE', ZONE: 'CENTRAL', LEVEL: 'PD',  SVG: 'PD',  ID: 6,  BLK: 'CD130', ALIGN: 'start', TAG: '101530-SSW-23002',       DESC: 'EYE WASH & SAFETY SHOWER STATION',                             PO_NO: '',            PO_DATE: undefined,    VENDOR: '',                                 COUNTRY: '',            INCOTERMS: '',    FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: undefined,    SRD: undefined,    INSTALL: '', SHIP_MODE: '',         SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: '',   G_EREC: 'CD130', ER_PS: '2025-08-12', ER_PF: '2026-03-10', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 0,    DT_ETA: 0,     DT_SRD: 0, },
      { SN: 7,   AREA: 'TOPSIDE', ZONE: 'CENTRAL', LEVEL: 'PD',  SVG: 'PD',  ID: 7,  BLK: 'CD130', ALIGN: 'start', TAG: '101420-ZZZ-37000',       DESC: 'WATER INJECTION CHEMICAL SKID 2',                              PO_NO: 'QD557AFQ52',  PO_DATE: '2024-03-28', VENDOR: 'Cameron',                          COUNTRY: 'China',       INCOTERMS: 'FOB', FAT_PLAN: '2025-06-16', FAT_FC: undefined,    FAT_ACT: undefined,    ETA: '2025-09-30', SRD: '2025-07-29', INSTALL: '', SHIP_MODE: 'NA',       SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: 'NA', G_EREC: 'CD130', ER_PS: '2025-08-12', ER_PF: '2026-03-10', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: -63, DT_ETA: -49,  DT_SRD: 14, },
      { SN: 8,   AREA: 'TOPSIDE', ZONE: 'CENTRAL', LEVEL: 'PD',  SVG: 'PD',  ID: 8,  BLK: 'CD130', ALIGN: 'start', TAG: '101440-MBJ-14630',       DESC: 'PRODUCED WATER DEGASSER',                                      PO_NO: 'QU557BCQ9A',  PO_DATE: '2024-04-01', VENDOR: 'Wooyang HC',                       COUNTRY: 'Korea',       INCOTERMS: 'DAP', FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: '2025-04-20', SRD: '2025-07-29', INSTALL: '', SHIP_MODE: 'NA',       SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: 'NA', G_EREC: 'CD130', ER_PS: '2025-08-12', ER_PF: '2026-03-10', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 100, DT_ETA: 114,  DT_SRD: 14, },
      { SN: 9,   AREA: 'TOPSIDE', ZONE: 'CENTRAL', LEVEL: 'PD',  SVG: 'PD',  ID: 9,  BLK: 'CD130', ALIGN: 'start', TAG: '101720-SFE-23005',       DESC: 'WHEELED FIRE EXTINGUISHER (56.7 KG DRY CHEM.)',                PO_NO: '',            PO_DATE: undefined,    VENDOR: '',                                 COUNTRY: '',            INCOTERMS: '',    FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: undefined,    SRD: undefined,    INSTALL: '', SHIP_MODE: '',         SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: '',   G_EREC: 'CD130', ER_PS: '2025-08-12', ER_PF: '2026-03-10', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 0,    DT_ETA: 0,     DT_SRD: 0, },
      { SN: 10,  AREA: 'TOPSIDE', ZONE: 'CENTRAL', LEVEL: 'PD',  SVG: 'PD',  ID: 10, BLK: 'CD130', ALIGN: 'start', TAG: '101290-PAT-20710',       DESC: 'WATER INJECTION BOOSTER PUMP 1',                               PO_NO: 'QD557AFQ52',  PO_DATE: '2024-03-28', VENDOR: 'Cameron',                          COUNTRY: 'China',       INCOTERMS: 'FOB', FAT_PLAN: '2025-06-16', FAT_FC: undefined,    FAT_ACT: undefined,    ETA: '2025-08-21', SRD: '2025-07-29', INSTALL: '', SHIP_MODE: 'NA',       SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: 'NA', G_EREC: 'CD130', ER_PS: '2025-08-12', ER_PF: '2026-03-10', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: -23, DT_ETA: -9,   DT_SRD: 14, },
      { SN: 11,  AREA: 'TOPSIDE', ZONE: 'CENTRAL', LEVEL: 'PD',  SVG: 'PD',  ID: 11, BLK: 'CD130', ALIGN: 'start', TAG: '101720-SFE-23006',       DESC: 'FIRE EXTINGUISHER (9.1 KG DRY CHEMICAL)',                      PO_NO: '',            PO_DATE: undefined,    VENDOR: '',                                 COUNTRY: '',            INCOTERMS: '',    FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: undefined,    SRD: undefined,    INSTALL: '', SHIP_MODE: '',         SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: '',   G_EREC: 'CD130', ER_PS: '2025-08-12', ER_PF: '2026-03-10', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 0,    DT_ETA: 0,     DT_SRD: 0, },
      { SN: 12,  AREA: 'TOPSIDE', ZONE: 'CENTRAL', LEVEL: 'PD',  SVG: 'PD',  ID: 12, BLK: 'CD130', ALIGN: 'start', TAG: '101720-SFE-23007',       DESC: 'FIRE EXTINGUISHER (6.8 KG CO2)',                               PO_NO: '',            PO_DATE: undefined,    VENDOR: '',                                 COUNTRY: '',            INCOTERMS: '',    FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: undefined,    SRD: undefined,    INSTALL: '', SHIP_MODE: '',         SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: '',   G_EREC: 'CD130', ER_PS: '2025-08-12', ER_PF: '2026-03-10', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 0,    DT_ETA: 0,     DT_SRD: 0, },
      { SN: 13,  AREA: 'TOPSIDE', ZONE: 'CENTRAL', LEVEL: 'PD',  SVG: 'PD',  ID: 13, BLK: 'CD130', ALIGN: 'start', TAG: '101200-HBG-10170',       DESC: 'CRUDE OIL HEATER 2',                                           PO_NO: 'QU557BRQ68',  PO_DATE: '2024-03-28', VENDOR: 'Wooyang HC',                       COUNTRY: 'Korea',       INCOTERMS: 'DAP', FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: '2025-05-01', SRD: '2025-07-29', INSTALL: '', SHIP_MODE: 'by Truck', SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: 'NA', G_EREC: 'CD130', ER_PS: '2025-08-12', ER_PF: '2026-03-10', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 89,  DT_ETA: 103,  DT_SRD: 14, },
      { SN: 14,  AREA: 'TOPSIDE', ZONE: 'CENTRAL', LEVEL: 'PD',  SVG: 'PD',  ID: 14, BLK: 'CD130', ALIGN: 'start', TAG: '101200-HBG-10160',       DESC: 'CRUDE OIL HEATER 1',                                           PO_NO: 'QU557BRQ68',  PO_DATE: '2024-03-28', VENDOR: 'Wooyang HC',                       COUNTRY: 'Korea',       INCOTERMS: 'DAP', FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: '2025-05-01', SRD: '2025-07-29', INSTALL: '', SHIP_MODE: 'by Truck', SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: 'NA', G_EREC: 'CD130', ER_PS: '2025-08-12', ER_PF: '2026-03-10', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 89,  DT_ETA: 103,  DT_SRD: 14, },
      { SN: 15,  AREA: 'TOPSIDE', ZONE: 'CENTRAL', LEVEL: 'PD',  SVG: 'PD',  ID: 15, BLK: 'CD110', ALIGN: 'start', TAG: '101200-NBK-10200',       DESC: 'BULK OIL TREATER',                                             PO_NO: 'QD557BRQ5E',  PO_DATE: '2024-04-15', VENDOR: 'Cameron',                          COUNTRY: 'China',       INCOTERMS: 'FOB', FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: '2025-05-24', SRD: '2025-07-08', INSTALL: '', SHIP_MODE: 'by Sea',   SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: 'NA', G_EREC: 'CD110', ER_PS: '2025-07-22', ER_PF: '2026-03-10', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 45,  DT_ETA: 59,   DT_SRD: 14, },
      { SN: 16,  AREA: 'TOPSIDE', ZONE: 'CENTRAL', LEVEL: 'PD',  SVG: 'PD',  ID: 16, BLK: 'CD110', ALIGN: 'start', TAG: '101200-MBD-10180',       DESC: 'IP SEPARATOR',                                                 PO_NO: 'QU557BCQ9A',  PO_DATE: '2024-04-01', VENDOR: 'Wooyang HC',                       COUNTRY: 'Korea',       INCOTERMS: 'DAP', FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: '2025-04-20', SRD: '2025-07-08', INSTALL: '', SHIP_MODE: 'NA',       SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: 'NA', G_EREC: 'CD110', ER_PS: '2025-07-22', ER_PF: '2026-03-10', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 79,  DT_ETA: 93,   DT_SRD: 14, },
      { SN: 17,  AREA: 'TOPSIDE', ZONE: 'CENTRAL', LEVEL: 'PD',  SVG: 'PD',  ID: 17, BLK: 'CD110', ALIGN: 'start', TAG: '101720-TQZ-87048',       DESC: 'FOAM HOSE REEL (end FRONT)',                                   PO_NO: '',            PO_DATE: undefined,    VENDOR: '',                                 COUNTRY: '',            INCOTERMS: '',    FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: undefined,    SRD: undefined,    INSTALL: '', SHIP_MODE: '',         SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: '',   G_EREC: 'CD110', ER_PS: '2025-07-22', ER_PF: '2026-03-10', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 0,    DT_ETA: 0,     DT_SRD: 0, },
      { SN: 18,  AREA: 'TOPSIDE', ZONE: 'CENTRAL', LEVEL: 'PD',  SVG: 'PD',  ID: 18, BLK: 'CD110', ALIGN: 'start', TAG: '101720-CPS-23001',       DESC: 'CHEMICAL PERSONNEL PROTECTION STORAGE BOX',                    PO_NO: '',            PO_DATE: undefined,    VENDOR: '',                                 COUNTRY: '',            INCOTERMS: '',    FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: undefined,    SRD: undefined,    INSTALL: '', SHIP_MODE: '',         SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: '',   G_EREC: 'CD110', ER_PS: '2025-07-22', ER_PF: '2026-03-10', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 0,    DT_ETA: 0,     DT_SRD: 0, },
      { SN: 19,  AREA: 'TOPSIDE', ZONE: 'CENTRAL', LEVEL: 'PD',  SVG: 'PD',  ID: 19, BLK: 'CD110', ALIGN: 'start', TAG: '101720-SFE-23002',       DESC: 'FIRE EXTINGUISHER (9.1 KG DRY CHEMICAL)',                      PO_NO: '',            PO_DATE: undefined,    VENDOR: '',                                 COUNTRY: '',            INCOTERMS: '',    FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: undefined,    SRD: undefined,    INSTALL: '', SHIP_MODE: '',         SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: '',   G_EREC: 'CD110', ER_PS: '2025-07-22', ER_PF: '2026-03-10', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 0,    DT_ETA: 0,     DT_SRD: 0, },
      { SN: 20,  AREA: 'TOPSIDE', ZONE: 'CENTRAL', LEVEL: 'PD',  SVG: 'PD',  ID: 20, BLK: 'CD110', ALIGN: 'start', TAG: '101180-KPLA-10020',      DESC: 'FLOWLINE PIG LAUNCHER / RECEIVER 2',                           PO_NO: 'QD557BH1P22', PO_DATE: '2024-04-15', VENDOR: 'Applied Engineering',              COUNTRY: 'Singapore',   INCOTERMS: 'FOB', FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: '2025-06-06', SRD: '2025-08-19', INSTALL: '', SHIP_MODE: 'NA',       SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: 'NA', G_EREC: 'CD110', ER_PS: '2025-07-22', ER_PF: '2026-03-10', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 74,  DT_ETA: 46,   DT_SRD: -28, },
      { SN: 21,  AREA: 'TOPSIDE', ZONE: 'CENTRAL', LEVEL: 'PD',  SVG: 'PD',  ID: 21, BLK: 'CD110', ALIGN: 'start', TAG: '101180-KPLA-10010',      DESC: 'FLOWLINE PIG LAUNCHER / RECEIVER 1',                           PO_NO: 'QD557BH1P22', PO_DATE: '2024-04-15', VENDOR: 'Applied Engineering',              COUNTRY: 'Singapore',   INCOTERMS: 'FOB', FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: '2025-06-06', SRD: '2025-08-19', INSTALL: '', SHIP_MODE: 'NA',       SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: 'NA', G_EREC: 'CD110', ER_PS: '2025-07-22', ER_PF: '2026-03-10', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 74,  DT_ETA: 46,   DT_SRD: -28, },
      { SN: 22,  AREA: 'TOPSIDE', ZONE: 'CENTRAL', LEVEL: 'PD',  SVG: 'PD',  ID: 22, BLK: 'CD110', ALIGN: 'start', TAG: '101390-ZZZ-40280',       DESC: 'WEST LOADING HOSE REEL SKID',                                  PO_NO: 'QE557BDO07',  PO_DATE: '2024-03-27', VENDOR: 'Techflow Marine',                  COUNTRY: 'UK',          INCOTERMS: 'FOB', FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: '2025-03-09', SRD: '2025-07-08', INSTALL: '', SHIP_MODE: 'by Sea',   SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: 'NA', G_EREC: 'CD110', ER_PS: '2025-07-22', ER_PF: '2026-03-10', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 121, DT_ETA: 135,  DT_SRD: 14, },
      { SN: 23,  AREA: 'TOPSIDE', ZONE: 'CENTRAL', LEVEL: 'PD',  SVG: 'PD',  ID: 23, BLK: 'CD110', ALIGN: 'start', TAG: '101210-PBA-10260',       DESC: 'CRUDE BOOSTER PUMP 3',                                         PO_NO: 'QU557BKQ27',  PO_DATE: '2024-04-09', VENDOR: 'ITT Goulds',                       COUNTRY: 'Korea',       INCOTERMS: 'DDP', FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: '2025-07-09', SRD: '2025-07-08', INSTALL: '', SHIP_MODE: 'NA',       SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: 'NA', G_EREC: 'CD110', ER_PS: '2025-07-22', ER_PF: '2026-03-10', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: -1,  DT_ETA: 13,   DT_SRD: 14, },
      { SN: 24,  AREA: 'TOPSIDE', ZONE: 'CENTRAL', LEVEL: 'PD',  SVG: 'PD',  ID: 24, BLK: 'CD110', ALIGN: 'start', TAG: '101210-PBA-10250',       DESC: 'CRUDE BOOSTER PUMP 2',                                         PO_NO: 'QU557BKQ27',  PO_DATE: '2024-04-09', VENDOR: 'ITT Goulds',                       COUNTRY: 'Korea',       INCOTERMS: 'DDP', FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: '2025-07-09', SRD: '2025-07-08', INSTALL: '', SHIP_MODE: 'NA',       SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: 'NA', G_EREC: 'CD110', ER_PS: '2025-07-22', ER_PF: '2026-03-10', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: -1,  DT_ETA: 13,   DT_SRD: 14, },
      { SN: 25,  AREA: 'TOPSIDE', ZONE: 'CENTRAL', LEVEL: 'PD',  SVG: 'PD',  ID: 25, BLK: 'CD110', ALIGN: 'start', TAG: '101210-PBA-10240',       DESC: 'CRUDE BOOSTER PUMP 1',                                         PO_NO: 'QU557BKQ27',  PO_DATE: '2024-04-09', VENDOR: 'ITT Goulds',                       COUNTRY: 'Korea',       INCOTERMS: 'DDP', FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: '2025-07-09', SRD: '2025-07-08', INSTALL: '', SHIP_MODE: 'by Truck', SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: 'NA', G_EREC: 'CD110', ER_PS: '2025-07-22', ER_PF: '2026-03-10', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: -1,  DT_ETA: 13,   DT_SRD: 14, },
      { SN: 26,  AREA: 'TOPSIDE', ZONE: 'CENTRAL', LEVEL: 'PD',  SVG: 'PD',  ID: 26, BLK: 'CD110', ALIGN: 'start', TAG: '101720-SLBY-23001',      DESC: 'LIFE RING BUOY',                                               PO_NO: '',            PO_DATE: undefined,    VENDOR: '',                                 COUNTRY: '',            INCOTERMS: '',    FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: undefined,    SRD: undefined,    INSTALL: '', SHIP_MODE: '',         SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: '',   G_EREC: 'CD110', ER_PS: '2025-07-22', ER_PF: '2026-03-10', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 0,    DT_ETA: 0,     DT_SRD: 0, },
      { SN: 27,  AREA: 'TOPSIDE', ZONE: 'CENTRAL', LEVEL: 'PD',  SVG: 'PD',  ID: 27, BLK: 'CD110', ALIGN: 'end',   TAG: '101720-SFE-23001',       DESC: 'FIRE EXTINGUISHER (9.1 KG DRY CHEMICAL)',                      PO_NO: '',            PO_DATE: undefined,    VENDOR: '',                                 COUNTRY: '',            INCOTERMS: '',    FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: undefined,    SRD: undefined,    INSTALL: '', SHIP_MODE: '',         SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: '',   G_EREC: 'CD110', ER_PS: '2025-07-22', ER_PF: '2026-03-10', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 0,    DT_ETA: 0,     DT_SRD: 0, },
      { SN: 28,  AREA: 'TOPSIDE', ZONE: 'CENTRAL', LEVEL: 'PD',  SVG: 'PD',  ID: 28, BLK: 'CD110', ALIGN: 'end',   TAG: '101720-TQZ-87043',       DESC: 'FOAM HOSE REEL (start FRONT)',                                 PO_NO: '',            PO_DATE: undefined,    VENDOR: '',                                 COUNTRY: '',            INCOTERMS: '',    FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: undefined,    SRD: undefined,    INSTALL: '', SHIP_MODE: '',         SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: '',   G_EREC: 'CD110', ER_PS: '2025-07-22', ER_PF: '2026-03-10', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 0,    DT_ETA: 0,     DT_SRD: 0, },
      { SN: 29,  AREA: 'TOPSIDE', ZONE: 'CENTRAL', LEVEL: 'PD',  SVG: 'PD',  ID: 29, BLK: 'CD110', ALIGN: 'end',   TAG: '101720-SFE-23003',       DESC: 'FIRE EXTINGUISHER (9.1 KG DRY CHEMICAL)',                      PO_NO: '',            PO_DATE: undefined,    VENDOR: '',                                 COUNTRY: '',            INCOTERMS: '',    FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: undefined,    SRD: undefined,    INSTALL: '', SHIP_MODE: '',         SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: '',   G_EREC: 'CD110', ER_PS: '2025-07-22', ER_PF: '2026-03-10', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 0,    DT_ETA: 0,     DT_SRD: 0, },
      { SN: 30,  AREA: 'TOPSIDE', ZONE: 'CENTRAL', LEVEL: 'PD',  SVG: 'PD',  ID: 30, BLK: 'CD110', ALIGN: 'end',   TAG: '101200-HAP-10130',       DESC: 'OIL/OIL CROSS EXCHANGER 1',                                    PO_NO: 'QU557BRQ66',  PO_DATE: '2024-04-24', VENDOR: '엘에이치이',                            COUNTRY: 'Korea',       INCOTERMS: 'DAP', FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: '2025-05-01', SRD: '2025-07-08', INSTALL: '', SHIP_MODE: 'by Truck', SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: 'NA', G_EREC: 'CD110', ER_PS: '2025-07-22', ER_PF: '2026-03-10', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 68,  DT_ETA: 82,   DT_SRD: 14, },
      { SN: 31,  AREA: 'TOPSIDE', ZONE: 'CENTRAL', LEVEL: 'PD',  SVG: 'PD',  ID: 31, BLK: 'CD110', ALIGN: 'end',   TAG: '101200-HAP-10140',       DESC: 'OIL/OIL CROSS EXCHANGER 2',                                    PO_NO: 'QU557BRQ66',  PO_DATE: '2024-04-24', VENDOR: '엘에이치이',                            COUNTRY: 'Korea',       INCOTERMS: 'DAP', FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: '2025-05-01', SRD: '2025-07-08', INSTALL: '', SHIP_MODE: 'by Truck', SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: 'NA', G_EREC: 'CD110', ER_PS: '2025-07-22', ER_PF: '2026-03-10', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 68,  DT_ETA: 82,   DT_SRD: 14, },
      { SN: 32,  AREA: 'TOPSIDE', ZONE: 'CENTRAL', LEVEL: 'PD',  SVG: 'PD',  ID: 32, BLK: 'CD110', ALIGN: 'end',   TAG: '101200-MBD-10110',       DESC: 'INLET SEPARATOR 1',                                            PO_NO: 'QU557BCQ9B',  PO_DATE: '2024-04-01', VENDOR: 'Wooyang HC',                       COUNTRY: 'Korea',       INCOTERMS: 'DAP', FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: '2025-04-20', SRD: '2025-07-08', INSTALL: '', SHIP_MODE: 'NA',       SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: 'NA', G_EREC: 'CD110', ER_PS: '2025-07-22', ER_PF: '2026-03-10', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 79,  DT_ETA: 93,   DT_SRD: 14, },
      { SN: 33,  AREA: 'TOPSIDE', ZONE: 'CENTRAL', LEVEL: 'PD',  SVG: 'PD',  ID: 33, BLK: 'CD110', ALIGN: 'end',   TAG: '101200-MBD-10120',       DESC: 'INLET SEPARATOR 2',                                            PO_NO: 'QU557BCQ9B',  PO_DATE: '2024-04-01', VENDOR: 'Wooyang HC',                       COUNTRY: 'Korea',       INCOTERMS: 'DAP', FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: '2025-04-20', SRD: '2025-07-08', INSTALL: '', SHIP_MODE: 'NA',       SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: 'NA', G_EREC: 'CD110', ER_PS: '2025-07-22', ER_PF: '2026-03-10', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 79,  DT_ETA: 93,   DT_SRD: 14, },
      { SN: 34,  AREA: 'TOPSIDE', ZONE: 'CENTRAL', LEVEL: 'PD',  SVG: 'PD',  ID: 34, BLK: 'CD130', ALIGN: 'start', TAG: '101440-MBJ-14600',       DESC: 'REJECT OIL SETTLING VESSEL',                                   PO_NO: 'QU557BCQ9A',  PO_DATE: '2024-04-01', VENDOR: 'Wooyang HC',                       COUNTRY: 'Korea',       INCOTERMS: 'DAP', FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: '2025-04-20', SRD: '2025-07-29', INSTALL: '', SHIP_MODE: 'NA',       SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: 'NA', G_EREC: 'CD130', ER_PS: '2025-08-12', ER_PF: '2026-03-10', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 100, DT_ETA: 114,  DT_SRD: 14, },
      { SN: 35,  AREA: 'TOPSIDE', ZONE: 'CENTRAL', LEVEL: 'PD',  SVG: 'PD',  ID: 35, BLK: 'CD130', ALIGN: 'start', TAG: '101440-MBM-14640',       DESC: 'IP HYDROCYCLONE',                                              PO_NO: 'QD557AFQ54',  PO_DATE: '2024-03-28', VENDOR: 'Cameron',                          COUNTRY: 'China',       INCOTERMS: 'FOB', FAT_PLAN: '2025-03-11', FAT_FC: undefined,    FAT_ACT: undefined,    ETA: '2025-05-30', SRD: '2025-07-29', INSTALL: '', SHIP_MODE: 'NA',       SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: 'NA', G_EREC: 'CD130', ER_PS: '2025-08-12', ER_PF: '2026-03-10', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 60,  DT_ETA: 74,   DT_SRD: 14, },
      { SN: 36,  AREA: 'TOPSIDE', ZONE: 'CENTRAL', LEVEL: 'PD',  SVG: 'PD',  ID: 36, BLK: 'CD130', ALIGN: 'start', TAG: '101440-HAP-14650',       DESC: 'PRODUCED WATER COOLER',                                        PO_NO: 'QD557BRQ64',  PO_DATE: '2024-04-24', VENDOR: 'Tranter China',                    COUNTRY: 'China',       INCOTERMS: 'DAP', FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: '2025-03-17', SRD: '2025-07-29', INSTALL: '', SHIP_MODE: 'by Sea',   SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: 'NA', G_EREC: 'CD130', ER_PS: '2025-08-12', ER_PF: '2026-03-10', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 134, DT_ETA: 148,  DT_SRD: 14, },
      { SN: 37,  AREA: 'TOPSIDE', ZONE: 'CENTRAL', LEVEL: 'PD',  SVG: 'PD',  ID: 37, BLK: 'CD130', ALIGN: 'start', TAG: '101440-MBM-14610',       DESC: 'INLET HYDROCYCLONE 1',                                         PO_NO: 'QD557AFQ54',  PO_DATE: '2024-03-28', VENDOR: 'Cameron',                          COUNTRY: 'China',       INCOTERMS: 'FOB', FAT_PLAN: '2025-03-11', FAT_FC: undefined,    FAT_ACT: undefined,    ETA: '2025-05-30', SRD: '2025-07-29', INSTALL: '', SHIP_MODE: 'NA',       SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: 'NA', G_EREC: 'CD130', ER_PS: '2025-08-12', ER_PF: '2026-03-10', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 60,  DT_ETA: 74,   DT_SRD: 14, },
      { SN: 38,  AREA: 'TOPSIDE', ZONE: 'CENTRAL', LEVEL: 'PD',  SVG: 'PD',  ID: 38, BLK: 'CD130', ALIGN: 'start', TAG: '101440-MBM-14620',       DESC: 'INLET HYDROCYCLONE 2',                                         PO_NO: 'QD557AFQ54',  PO_DATE: '2024-03-28', VENDOR: 'Cameron',                          COUNTRY: 'China',       INCOTERMS: 'FOB', FAT_PLAN: '2025-03-11', FAT_FC: undefined,    FAT_ACT: undefined,    ETA: '2025-05-30', SRD: '2025-07-29', INSTALL: '', SHIP_MODE: 'NA',       SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: 'NA', G_EREC: 'CD130', ER_PS: '2025-08-12', ER_PF: '2026-03-10', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 60,  DT_ETA: 74,   DT_SRD: 14, },
      { SN: 39,  AREA: 'TOPSIDE', ZONE: 'CENTRAL', LEVEL: 'PD',  SVG: 'PD',  ID: 39, BLK: 'CD130', ALIGN: 'start', TAG: '101290-PAT-20720',       DESC: 'WATER INJECTION BOOSTER PUMP 2',                               PO_NO: 'QD557AFQ52',  PO_DATE: '2024-03-28', VENDOR: 'Cameron',                          COUNTRY: 'China',       INCOTERMS: 'FOB', FAT_PLAN: '2025-06-16', FAT_FC: undefined,    FAT_ACT: undefined,    ETA: '2025-08-21', SRD: '2025-07-29', INSTALL: '', SHIP_MODE: 'NA',       SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: 'NA', G_EREC: 'CD130', ER_PS: '2025-08-12', ER_PF: '2026-03-10', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: -23, DT_ETA: -9,   DT_SRD: 14, },
      { SN: 40,  AREA: 'TOPSIDE', ZONE: 'CENTRAL', LEVEL: 'PD',  SVG: 'PD',  ID: 40, BLK: 'CD130', ALIGN: 'start', TAG: '101290-PBE-20410',       DESC: 'MEMBRANE BOOSTER PUMP 2',                                      PO_NO: 'QD557AFQ52',  PO_DATE: '2024-03-28', VENDOR: 'Cameron',                          COUNTRY: 'China',       INCOTERMS: 'FOB', FAT_PLAN: '2025-06-16', FAT_FC: undefined,    FAT_ACT: undefined,    ETA: '2025-08-21', SRD: '2025-07-29', INSTALL: '', SHIP_MODE: 'NA',       SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: 'NA', G_EREC: 'CD130', ER_PS: '2025-08-12', ER_PF: '2026-03-10', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: -23, DT_ETA: -9,   DT_SRD: 14, },
      { SN: 41,  AREA: 'TOPSIDE', ZONE: 'CENTRAL', LEVEL: 'PD',  SVG: 'PD',  ID: 41, BLK: 'CD130', ALIGN: 'start', TAG: '101440-PBA-14830',       DESC: 'PRODUCED WATER TRANSFER PUMP 3',                               PO_NO: 'QA557BWMSC',  PO_DATE: '2024-05-23', VENDOR: 'Sulzer Pumps(US)',                 COUNTRY: 'Mexico',      INCOTERMS: 'FOB', FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: '2025-07-29', SRD: '2025-07-29', INSTALL: '', SHIP_MODE: 'by Air',   SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: 'NA', G_EREC: 'CD130', ER_PS: '2025-08-12', ER_PF: '2026-03-10', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 0,   DT_ETA: 14,   DT_SRD: 14, },
      { SN: 42,  AREA: 'TOPSIDE', ZONE: 'CENTRAL', LEVEL: 'PD',  SVG: 'PD',  ID: 42, BLK: 'CD130', ALIGN: 'start', TAG: '101440-PBA-14820',       DESC: 'PRODUCED WATER TRANSFER PUMP 2',                               PO_NO: 'QA557BWMSC',  PO_DATE: '2024-05-23', VENDOR: 'Sulzer Pumps(US)',                 COUNTRY: 'Mexico',      INCOTERMS: 'FOB', FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: '2025-07-29', SRD: '2025-07-29', INSTALL: '', SHIP_MODE: 'by Air',   SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: 'NA', G_EREC: 'CD130', ER_PS: '2025-08-12', ER_PF: '2026-03-10', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 0,   DT_ETA: 14,   DT_SRD: 14, },
      { SN: 43,  AREA: 'TOPSIDE', ZONE: 'CENTRAL', LEVEL: 'PD',  SVG: 'PD',  ID: 43, BLK: 'CD130', ALIGN: 'start', TAG: '101440-PBA-14810',       DESC: 'PRODUCED WATER TRANSFER PUMP 1',                               PO_NO: 'QA557BWMSC',  PO_DATE: '2024-05-23', VENDOR: 'Sulzer Pumps(US)',                 COUNTRY: 'Mexico',      INCOTERMS: 'FOB', FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: '2025-07-29', SRD: '2025-07-29', INSTALL: '', SHIP_MODE: 'by Air',   SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: 'NA', G_EREC: 'CD130', ER_PS: '2025-08-12', ER_PF: '2026-03-10', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 0,   DT_ETA: 14,   DT_SRD: 14, },
      { SN: 44,  AREA: 'TOPSIDE', ZONE: 'CENTRAL', LEVEL: 'PD',  SVG: 'PD',  ID: 44, BLK: 'CD130', ALIGN: 'end',   TAG: '101530-SSW-23003',       DESC: 'EYE WASH & SAFETY SHOWER STATION',                             PO_NO: '',            PO_DATE: undefined,    VENDOR: '',                                 COUNTRY: '',            INCOTERMS: '',    FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: undefined,    SRD: undefined,    INSTALL: '', SHIP_MODE: '',         SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: '',   G_EREC: 'CD130', ER_PS: '2025-08-12', ER_PF: '2026-03-10', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 0,    DT_ETA: 0,     DT_SRD: 0, },
      { SN: 45,  AREA: 'TOPSIDE', ZONE: 'CENTRAL', LEVEL: 'PD',  SVG: 'PD',  ID: 45, BLK: 'CD130', ALIGN: 'end',   TAG: '101290-PBE-20310',       DESC: 'MEMBRANE BOOSTER PUMP 1',                                      PO_NO: 'QD557AFQ52',  PO_DATE: '2024-03-28', VENDOR: 'Cameron',                          COUNTRY: 'China',       INCOTERMS: 'FOB', FAT_PLAN: '2025-06-16', FAT_FC: undefined,    FAT_ACT: undefined,    ETA: '2025-08-21', SRD: '2025-07-29', INSTALL: '', SHIP_MODE: 'NA',       SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: 'NA', G_EREC: 'CD130', ER_PS: '2025-08-12', ER_PF: '2026-03-10', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: -23, DT_ETA: -9,   DT_SRD: 14, },
      { SN: 46,  AREA: 'TOPSIDE', ZONE: 'CENTRAL', LEVEL: 'PD',  SVG: 'PD',  ID: 46, BLK: 'CD130', ALIGN: 'end',   TAG: '101720-TQZ-87041',       DESC: 'FOAM HOSE REEL (start FRONT)',                                 PO_NO: '',            PO_DATE: undefined,    VENDOR: '',                                 COUNTRY: '',            INCOTERMS: '',    FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: undefined,    SRD: undefined,    INSTALL: '', SHIP_MODE: '',         SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: '',   G_EREC: 'CD130', ER_PS: '2025-08-12', ER_PF: '2026-03-10', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 0,    DT_ETA: 0,     DT_SRD: 0, },
      { SN: 47,  AREA: 'TOPSIDE', ZONE: 'CENTRAL', LEVEL: 'PD',  SVG: 'PD',  ID: 47, BLK: 'CD130', ALIGN: 'end',   TAG: '101620-MAJ-50440',       DESC: 'DIESEL COARSE FILTER 1',                                       PO_NO: 'QU557AFQ49',  PO_DATE: '2024-03-08', VENDOR: 'Parker Korea',                     COUNTRY: 'Korea',       INCOTERMS: 'DAP', FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: '2025-03-15', SRD: '2025-07-29', INSTALL: '', SHIP_MODE: 'NA',       SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: 'NA', G_EREC: 'CD130', ER_PS: '2025-08-12', ER_PF: '2026-03-10', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 136, DT_ETA: 150,  DT_SRD: 14, },
      { SN: 48,  AREA: 'TOPSIDE', ZONE: 'CENTRAL', LEVEL: 'PD',  SVG: 'PD',  ID: 48, BLK: 'CD130', ALIGN: 'end',   TAG: '101460-ZZZ-36000',       DESC: 'TOPSIDES METHANOL INJECTION SKID',                             PO_NO: 'QU557AEQ55',  PO_DATE: '2024-03-27', VENDOR: 'Decatech',                         COUNTRY: 'Korea',       INCOTERMS: 'DAP', FAT_PLAN: '2025-05-12', FAT_FC: undefined,    FAT_ACT: undefined,    ETA: '2025-09-30', SRD: '2025-07-29', INSTALL: '', SHIP_MODE: 'NA',       SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: 'NA', G_EREC: 'CD130', ER_PS: '2025-08-12', ER_PF: '2026-03-10', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: -63, DT_ETA: -49,  DT_SRD: 14, },
      { SN: 49,  AREA: 'TOPSIDE', ZONE: 'CENTRAL', LEVEL: 'PD',  SVG: 'PD',  ID: 49, BLK: 'CD130', ALIGN: 'end',   TAG: '101460-ZZZ-38000',       DESC: 'SUBSEA METHANOL INJECTION SKID',                               PO_NO: 'QU557AEQ55',  PO_DATE: '2024-03-27', VENDOR: 'Decatech',                         COUNTRY: 'Korea',       INCOTERMS: 'DAP', FAT_PLAN: '2025-05-12', FAT_FC: undefined,    FAT_ACT: undefined,    ETA: '2025-09-30', SRD: '2025-07-29', INSTALL: '', SHIP_MODE: 'NA',       SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: 'NA', G_EREC: 'CD130', ER_PS: '2025-08-12', ER_PF: '2026-03-10', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: -63, DT_ETA: -49,  DT_SRD: 14, },
      { SN: 50,  AREA: 'TOPSIDE', ZONE: 'CENTRAL', LEVEL: 'PD',  SVG: 'PD',  ID: 50, BLK: 'CD130', ALIGN: 'end',   TAG: '101460-MBJ-36100',       DESC: 'BULK METHANOL STORAGE VESSEL',                                 PO_NO: 'QU557BCQ9A',  PO_DATE: '2024-04-01', VENDOR: 'Wooyang HC',                       COUNTRY: 'Korea',       INCOTERMS: 'DAP', FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: '2025-04-20', SRD: '2025-07-29', INSTALL: '', SHIP_MODE: 'NA',       SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: 'NA', G_EREC: 'CD130', ER_PS: '2025-08-12', ER_PF: '2026-03-10', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 100, DT_ETA: 114,  DT_SRD: 14, },
      { SN: 51,  AREA: 'TOPSIDE', ZONE: 'CENTRAL', LEVEL: 'PD',  SVG: 'PD',  ID: 51, BLK: 'CD130', ALIGN: 'end',   TAG: '101390-ZZZ-40270',       DESC: 'EAST LOADING HOSE REEL SKID',                                  PO_NO: 'QE557BDO07',  PO_DATE: '2024-03-27', VENDOR: 'Techflow Marine',                  COUNTRY: 'UK',          INCOTERMS: 'FOB', FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: '2025-03-09', SRD: '2025-07-29', INSTALL: '', SHIP_MODE: 'by Sea',   SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: 'NA', G_EREC: 'CD130', ER_PS: '2025-08-12', ER_PF: '2026-03-10', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 142, DT_ETA: 156,  DT_SRD: 14, },
      { SN: 52,  AREA: 'TOPSIDE', ZONE: 'CENTRAL', LEVEL: 'PD',  SVG: 'PD',  ID: 52, BLK: 'CD130', ALIGN: 'end',   TAG: '101720-WVB-13002',       DESC: 'WORK VEST STORAGE BOX',                                        PO_NO: '',            PO_DATE: undefined,    VENDOR: '',                                 COUNTRY: '',            INCOTERMS: '',    FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: undefined,    SRD: undefined,    INSTALL: '', SHIP_MODE: '',         SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: '',   G_EREC: 'CD130', ER_PS: '2025-08-12', ER_PF: '2026-03-10', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 0,    DT_ETA: 0,     DT_SRD: 0, },
      { SN: 53,  AREA: 'TOPSIDE', ZONE: 'CENTRAL', LEVEL: 'PD',  SVG: 'PD',  ID: 53, BLK: 'CD130', ALIGN: 'end',   TAG: '101630-ZZZ-50500',       DESC: 'AIR COMPRESSOR SKID',                                          PO_NO: 'QU557BRQ06',  PO_DATE: '2024-03-08', VENDOR: 'Gardner Denver Korea',             COUNTRY: 'Korea',       INCOTERMS: 'DAP', FAT_PLAN: '2025-05-06', FAT_FC: undefined,    FAT_ACT: undefined,    ETA: '2025-05-31', SRD: '2025-07-29', INSTALL: '', SHIP_MODE: 'by Barge', SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: 'NA', G_EREC: 'CD130', ER_PS: '2025-08-12', ER_PF: '2026-03-10', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 59,  DT_ETA: 73,   DT_SRD: 14, },
      { SN: 54,  AREA: 'TOPSIDE', ZONE: 'CENTRAL', LEVEL: 'PD',  SVG: 'PD',  ID: 54, BLK: 'CD130', ALIGN: 'end',   TAG: '101620-ZZZ-50400',       DESC: 'DIESEL TRANSFER SKID',                                         PO_NO: 'QA557BDQ56',  PO_DATE: '2024-04-16', VENDOR: 'DXP Enterprises',                  COUNTRY: 'USA',         INCOTERMS: 'FOB', FAT_PLAN: '2025-08-01', FAT_FC: undefined,    FAT_ACT: undefined,    ETA: '2025-10-13', SRD: '2025-07-29', INSTALL: '', SHIP_MODE: 'by Sea',   SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: 'NA', G_EREC: 'CD130', ER_PS: '2025-08-12', ER_PF: '2026-03-10', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: -76, DT_ETA: -62,  DT_SRD: 14, },
      { SN: 55,  AREA: 'TOPSIDE', ZONE: 'CENTRAL', LEVEL: 'PD',  SVG: 'PD',  ID: 55, BLK: 'CD130', ALIGN: 'end',   TAG: '101720-TQZ-87045',       DESC: 'FOAM HOSE REEL (end FRONT)',                                   PO_NO: '',            PO_DATE: undefined,    VENDOR: '',                                 COUNTRY: '',            INCOTERMS: '',    FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: undefined,    SRD: undefined,    INSTALL: '', SHIP_MODE: '',         SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: '',   G_EREC: 'CD130', ER_PS: '2025-08-12', ER_PF: '2026-03-10', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 0,    DT_ETA: 0,     DT_SRD: 0, },
      { SN: 56,  AREA: 'TOPSIDE', ZONE: 'CENTRAL', LEVEL: 'PD',  SVG: 'PD',  ID: 56, BLK: 'CD130', ALIGN: 'end',   TAG: '101720-SFE-23008',       DESC: 'FIRE EXTINGUISHER (9.1 KG DRY CHEMICAL)',                      PO_NO: '',            PO_DATE: undefined,    VENDOR: '',                                 COUNTRY: '',            INCOTERMS: '',    FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: undefined,    SRD: undefined,    INSTALL: '', SHIP_MODE: '',         SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: '',   G_EREC: 'CD130', ER_PS: '2025-08-12', ER_PF: '2026-03-10', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 0,    DT_ETA: 0,     DT_SRD: 0, },
      { SN: 57,  AREA: 'TOPSIDE', ZONE: 'CENTRAL', LEVEL: 'PD',  SVG: 'PD',  ID: 57, BLK: 'CD130', ALIGN: 'end',   TAG: '101620-MAJ-50450',       DESC: 'DIESEL COARSE FILTER 2',                                       PO_NO: 'QU557AFQ49',  PO_DATE: '2024-03-08', VENDOR: 'Parker Korea',                     COUNTRY: 'Korea',       INCOTERMS: 'DAP', FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: '2025-03-15', SRD: '2025-07-29', INSTALL: '', SHIP_MODE: 'NA',       SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: 'NA', G_EREC: 'CD130', ER_PS: '2025-08-12', ER_PF: '2026-03-10', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 136, DT_ETA: 150,  DT_SRD: 14, },
      { SN: 58,  AREA: 'TOPSIDE', ZONE: 'CENTRAL', LEVEL: 'PD',  SVG: 'PD',  ID: 58, BLK: 'CD110', ALIGN: 'start', TAG: '101720-SFE-23004',       DESC: 'FIRE EXTINGUISHER (9.1 KG DRY CHEMICAL)',                      PO_NO: '',            PO_DATE: undefined,    VENDOR: '',                                 COUNTRY: '',            INCOTERMS: '',    FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: undefined,    SRD: undefined,    INSTALL: '', SHIP_MODE: '',         SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: '',   G_EREC: 'CD110', ER_PS: '2025-07-22', ER_PF: '2026-03-10', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 0,    DT_ETA: 0,     DT_SRD: 0, },
      { SN: 59,  AREA: 'TOPSIDE', ZONE: 'CENTRAL', LEVEL: 'PD',  SVG: 'PD',  ID: 59, BLK: 'CD110', ALIGN: 'start', TAG: '101720-TQZ-87044',       DESC: 'FOAM HOSE REEL (end FRONT)',                                   PO_NO: '',            PO_DATE: undefined,    VENDOR: '',                                 COUNTRY: '',            INCOTERMS: '',    FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: undefined,    SRD: undefined,    INSTALL: '', SHIP_MODE: '',         SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: '',   G_EREC: 'CD110', ER_PS: '2025-07-22', ER_PF: '2026-03-10', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 0,    DT_ETA: 0,     DT_SRD: 0, },
      { SN: 60,  AREA: 'TOPSIDE', ZONE: 'CENTRAL', LEVEL: 'PD',  SVG: 'PD',  ID: 60, BLK: 'CD110', ALIGN: 'start', TAG: '101200-PBA-10300',       DESC: 'VESSEL SAMPLE SINK PUMP 4',                                    PO_NO: 'QU557BWPCP',  PO_DATE: '2024-04-01', VENDOR: 'Decatech',                         COUNTRY: 'Korea',       INCOTERMS: 'DAP', FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: '2025-04-01', SRD: '2025-07-08', INSTALL: '', SHIP_MODE: 'NA',       SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: 'NA', G_EREC: 'CD110', ER_PS: '2025-07-22', ER_PF: '2026-03-10', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 98,  DT_ETA: 112,  DT_SRD: 14, },
      { SN: 61,  AREA: 'TOPSIDE', ZONE: 'CENTRAL', LEVEL: 'PD',  SVG: 'PD',  ID: 61, BLK: 'CD110', ALIGN: 'start', TAG: '101200-PBA-10290',       DESC: 'VESSEL SAMPLE SINK PUMP 3',                                    PO_NO: 'QU557BWPCP',  PO_DATE: '2024-04-01', VENDOR: 'Decatech',                         COUNTRY: 'Korea',       INCOTERMS: 'DAP', FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: '2025-04-01', SRD: '2025-07-08', INSTALL: '', SHIP_MODE: 'NA',       SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: 'NA', G_EREC: 'CD110', ER_PS: '2025-07-22', ER_PF: '2026-03-10', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 98,  DT_ETA: 112,  DT_SRD: 14, },
      { SN: 62,  AREA: 'TOPSIDE', ZONE: 'CENTRAL', LEVEL: 'PD',  SVG: 'PD',  ID: 62, BLK: 'CD110', ALIGN: 'end',   TAG: '101200-PBA-10280',       DESC: 'VESSEL SAMPLE SINK PUMP 2',                                    PO_NO: 'QU557BWPCP',  PO_DATE: '2024-04-01', VENDOR: 'Decatech',                         COUNTRY: 'Korea',       INCOTERMS: 'DAP', FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: '2025-04-01', SRD: '2025-07-08', INSTALL: '', SHIP_MODE: 'NA',       SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: 'NA', G_EREC: 'CD110', ER_PS: '2025-07-22', ER_PF: '2026-03-10', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 98,  DT_ETA: 112,  DT_SRD: 14, },
      { SN: 63,  AREA: 'TOPSIDE', ZONE: 'CENTRAL', LEVEL: 'PD',  SVG: 'PD',  ID: 63, BLK: 'CD110', ALIGN: 'end',   TAG: '101200-PBA-10270',       DESC: 'VESSEL SAMPLE SINK PUMP 1',                                    PO_NO: 'QU557BWPCP',  PO_DATE: '2024-04-01', VENDOR: 'Decatech',                         COUNTRY: 'Korea',       INCOTERMS: 'DAP', FAT_PLAN: '2024-03-07', FAT_FC: undefined,    FAT_ACT: undefined,    ETA: '2025-04-01', SRD: '2025-07-08', INSTALL: '', SHIP_MODE: 'NA',       SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: 'NA', G_EREC: 'CD110', ER_PS: '2025-07-22', ER_PF: '2026-03-10', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 98,  DT_ETA: 112,  DT_SRD: 14, },
      { SN: 64,  AREA: 'TOPSIDE', ZONE: 'CENTRAL', LEVEL: 'PD',  SVG: 'PD',  ID: 64, BLK: 'CD130', ALIGN: 'end',   TAG: '101720-SLBY-23002',      DESC: 'LIFE RING BUOY',                                               PO_NO: '',            PO_DATE: undefined,    VENDOR: '',                                 COUNTRY: '',            INCOTERMS: '',    FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: undefined,    SRD: undefined,    INSTALL: '', SHIP_MODE: '',         SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: '',   G_EREC: 'CD130', ER_PS: '2025-08-12', ER_PF: '2026-03-10', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 0,    DT_ETA: 0,     DT_SRD: 0, },
      { SN: 65,  AREA: 'TOPSIDE', ZONE: 'CENTRAL', LEVEL: 'PD',  SVG: 'PD',  ID: 65, BLK: 'CD130', ALIGN: 'end',   TAG: '101460-MAJ-36110',       DESC: 'METHANOL LOADING FILTER',                                      PO_NO: 'QU557AEQ55',  PO_DATE: '2024-03-27', VENDOR: 'Decatech',                         COUNTRY: 'Korea',       INCOTERMS: 'DAP', FAT_PLAN: '2025-05-12', FAT_FC: undefined,    FAT_ACT: undefined,    ETA: '2025-09-30', SRD: '2025-07-29', INSTALL: '', SHIP_MODE: 'NA',       SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: 'NA', G_EREC: 'CD130', ER_PS: '2025-08-12', ER_PF: '2026-03-10', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: -63, DT_ETA: -49,  DT_SRD: 14, },
      { SN: 66,  AREA: 'TOPSIDE', ZONE: 'CENTRAL', LEVEL: 'MZ',  SVG: 'MZ',  ID: 1,  BLK: 'CD240', ALIGN: 'start', TAG: '101720-SFE-22009',       DESC: 'FIRE EXTINGUISHER (6.8 KG CO2)',                               PO_NO: '',            PO_DATE: undefined,    VENDOR: '',                                 COUNTRY: '',            INCOTERMS: '',    FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: undefined,    SRD: undefined,    INSTALL: '', SHIP_MODE: '',         SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: '',   G_EREC: 'CD240', ER_PS: '2025-09-18', ER_PF: '2026-03-10', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 0,    DT_ETA: 0,     DT_SRD: 0, },
      { SN: 67,  AREA: 'TOPSIDE', ZONE: 'CENTRAL', LEVEL: 'MZ',  SVG: 'MZ',  ID: 2,  BLK: 'CD240', ALIGN: 'start', TAG: '101720-SFE-22010',       DESC: 'FIRE EXTINGUISHER (9.1 KG DRY CHEMICAL)',                      PO_NO: '',            PO_DATE: undefined,    VENDOR: '',                                 COUNTRY: '',            INCOTERMS: '',    FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: undefined,    SRD: undefined,    INSTALL: '', SHIP_MODE: '',         SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: '',   G_EREC: 'CD240', ER_PS: '2025-09-18', ER_PF: '2026-03-10', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 0,    DT_ETA: 0,     DT_SRD: 0, },
      { SN: 68,  AREA: 'TOPSIDE', ZONE: 'CENTRAL', LEVEL: 'MZ',  SVG: 'MZ',  ID: 3,  BLK: 'CD240', ALIGN: 'start', TAG: '101720-SFE-22008',       DESC: 'WHEELED FIRE EXTINGUISHER (56.7 KG DRY CHEM.)',                PO_NO: '',            PO_DATE: undefined,    VENDOR: '',                                 COUNTRY: '',            INCOTERMS: '',    FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: undefined,    SRD: undefined,    INSTALL: '', SHIP_MODE: '',         SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: '',   G_EREC: 'CD240', ER_PS: '2025-09-18', ER_PF: '2026-03-10', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 0,    DT_ETA: 0,     DT_SRD: 0, },
      { SN: 69,  AREA: 'TOPSIDE', ZONE: 'CENTRAL', LEVEL: 'MZ',  SVG: 'MZ',  ID: 4,  BLK: 'CD240', ALIGN: 'start', TAG: '101200-EXFMR-10220',     DESC: 'BULK OIL TREATER AUTOTRANSFORMER',                             PO_NO: '',            PO_DATE: undefined,    VENDOR: '',                                 COUNTRY: '',            INCOTERMS: '',    FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: undefined,    SRD: undefined,    INSTALL: '', SHIP_MODE: '',         SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: '',   G_EREC: 'CD240', ER_PS: '2025-09-18', ER_PF: '2026-03-10', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 0,    DT_ETA: 0,     DT_SRD: 0, },
      { SN: 70,  AREA: 'TOPSIDE', ZONE: 'CENTRAL', LEVEL: 'MZ',  SVG: 'MZ',  ID: 5,  BLK: 'CD240', ALIGN: 'start', TAG: '101720-TQZ-87034',       DESC: 'HOSE REEL (end FRONT)',                                        PO_NO: '',            PO_DATE: undefined,    VENDOR: '',                                 COUNTRY: '',            INCOTERMS: '',    FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: undefined,    SRD: undefined,    INSTALL: '', SHIP_MODE: '',         SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: '',   G_EREC: 'CD240', ER_PS: '2025-09-18', ER_PF: '2026-03-10', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 0,    DT_ETA: 0,     DT_SRD: 0, },
      { SN: 71,  AREA: 'TOPSIDE', ZONE: 'CENTRAL', LEVEL: 'MZ',  SVG: 'MZ',  ID: 6,  BLK: 'CD240', ALIGN: 'start', TAG: '101200-EXFMR-10210',     DESC: 'BULK OIL TREATER POWER UNIT',                                  PO_NO: '',            PO_DATE: undefined,    VENDOR: '',                                 COUNTRY: '',            INCOTERMS: '',    FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: undefined,    SRD: undefined,    INSTALL: '', SHIP_MODE: '',         SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: '',   G_EREC: 'CD240', ER_PS: '2025-09-18', ER_PF: '2026-03-10', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 0,    DT_ETA: 0,     DT_SRD: 0, },
      { SN: 72,  AREA: 'TOPSIDE', ZONE: 'CENTRAL', LEVEL: 'MZ',  SVG: 'MZ',  ID: 7,  BLK: 'CD240', ALIGN: 'start', TAG: '101720-TQZ-87033',       DESC: 'HOSE REEL (end FRONT)',                                        PO_NO: '',            PO_DATE: undefined,    VENDOR: '',                                 COUNTRY: '',            INCOTERMS: '',    FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: undefined,    SRD: undefined,    INSTALL: '', SHIP_MODE: '',         SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: '',   G_EREC: 'CD240', ER_PS: '2025-09-18', ER_PF: '2026-03-10', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 0,    DT_ETA: 0,     DT_SRD: 0, },
      { SN: 73,  AREA: 'TOPSIDE', ZONE: 'CENTRAL', LEVEL: 'MZ',  SVG: 'MZ',  ID: 8,  BLK: 'CD210', ALIGN: 'start', TAG: '101720-SFE-22003',       DESC: 'FIRE EXTINGUISHER (9.1 KG DRY CHEMICAL)',                      PO_NO: '',            PO_DATE: undefined,    VENDOR: '',                                 COUNTRY: '',            INCOTERMS: '',    FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: undefined,    SRD: undefined,    INSTALL: '', SHIP_MODE: '',         SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: '',   G_EREC: 'CD210', ER_PS: '2025-09-02', ER_PF: '2026-03-10', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 0,    DT_ETA: 0,     DT_SRD: 0, },
      { SN: 74,  AREA: 'TOPSIDE', ZONE: 'CENTRAL', LEVEL: 'MZ',  SVG: 'MZ',  ID: 9,  BLK: 'CD210', ALIGN: 'start', TAG: '101720-TQZ-87032',       DESC: 'HOSE REEL (end FRONT)',                                        PO_NO: '',            PO_DATE: undefined,    VENDOR: '',                                 COUNTRY: '',            INCOTERMS: '',    FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: undefined,    SRD: undefined,    INSTALL: '', SHIP_MODE: '',         SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: '',   G_EREC: 'CD210', ER_PS: '2025-09-02', ER_PF: '2026-03-10', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 0,    DT_ETA: 0,     DT_SRD: 0, },
      { SN: 75,  AREA: 'TOPSIDE', ZONE: 'CENTRAL', LEVEL: 'MZ',  SVG: 'MZ',  ID: 10, BLK: 'CD210', ALIGN: 'start', TAG: '101210-PBA-10410',       DESC: 'DISPLACEMENT OIL PUMP 1',                                      PO_NO: 'QU557BKQ27',  PO_DATE: '2024-04-09', VENDOR: 'ITT Goulds',                       COUNTRY: 'Korea',       INCOTERMS: 'DDP', FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: '2025-07-09', SRD: '2025-08-19', INSTALL: '', SHIP_MODE: 'NA',       SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: 'NA', G_EREC: 'CD210', ER_PS: '2025-09-02', ER_PF: '2026-03-10', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 41,  DT_ETA: 55,   DT_SRD: 14, },
      { SN: 76,  AREA: 'TOPSIDE', ZONE: 'CENTRAL', LEVEL: 'MZ',  SVG: 'MZ',  ID: 11, BLK: 'CD210', ALIGN: 'start', TAG: '101210-PBA-10420',       DESC: 'DISPLACEMENT OIL PUMP 2',                                      PO_NO: 'QU557BKQ27',  PO_DATE: '2024-04-09', VENDOR: 'ITT Goulds',                       COUNTRY: 'Korea',       INCOTERMS: 'DDP', FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: '2025-07-09', SRD: '2025-08-19', INSTALL: '', SHIP_MODE: 'NA',       SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: 'NA', G_EREC: 'CD210', ER_PS: '2025-09-02', ER_PF: '2026-03-10', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 41,  DT_ETA: 55,   DT_SRD: 14, },
      { SN: 77,  AREA: 'TOPSIDE', ZONE: 'CENTRAL', LEVEL: 'MZ',  SVG: 'MZ',  ID: 12, BLK: 'CD220', ALIGN: 'end',   TAG: '101720-SFE-22006',       DESC: 'WHEELED FIRE EXTINGUISHER (56.7 KG DRY CHEM.)',                PO_NO: '',            PO_DATE: undefined,    VENDOR: '',                                 COUNTRY: '',            INCOTERMS: '',    FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: undefined,    SRD: undefined,    INSTALL: '', SHIP_MODE: '',         SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: '',   G_EREC: 'CD220', ER_PS: '2025-09-17', ER_PF: '2026-03-10', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 0,    DT_ETA: 0,     DT_SRD: 0, },
      { SN: 78,  AREA: 'TOPSIDE', ZONE: 'CENTRAL', LEVEL: 'MZ',  SVG: 'MZ',  ID: 13, BLK: 'CD220', ALIGN: 'end',   TAG: '101720-SFE-22005',       DESC: 'FIRE EXTINGUISHER (9.1 KG DRY CHEMICAL)',                      PO_NO: '',            PO_DATE: undefined,    VENDOR: '',                                 COUNTRY: '',            INCOTERMS: '',    FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: undefined,    SRD: undefined,    INSTALL: '', SHIP_MODE: '',         SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: '',   G_EREC: 'CD220', ER_PS: '2025-09-17', ER_PF: '2026-03-10', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 0,    DT_ETA: 0,     DT_SRD: 0, },
      { SN: 79,  AREA: 'TOPSIDE', ZONE: 'CENTRAL', LEVEL: 'MZ',  SVG: 'MZ',  ID: 14, BLK: 'CD220', ALIGN: 'end',   TAG: '101210-HAP-10150',       DESC: 'CRUDE OIL COOLER',                                             PO_NO: 'QD557BRQ64',  PO_DATE: '2024-04-24', VENDOR: 'Tranter China',                    COUNTRY: 'China',       INCOTERMS: 'DAP', FAT_PLAN: '2025-02-20', FAT_FC: undefined,    FAT_ACT: undefined,    ETA: '2025-03-13', SRD: '2025-09-03', INSTALL: '', SHIP_MODE: 'by Sea',   SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: 'NA', G_EREC: 'CD220', ER_PS: '2025-09-17', ER_PF: '2026-03-10', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 174, DT_ETA: 188,  DT_SRD: 14, },
      { SN: 80,  AREA: 'TOPSIDE', ZONE: 'CENTRAL', LEVEL: 'MZ',  SVG: 'MZ',  ID: 15, BLK: 'CD220', ALIGN: 'end',   TAG: '101720-SFE-22004',       DESC: 'FIRE EXTINGUISHER (6.8 KG CO2)',                               PO_NO: '',            PO_DATE: undefined,    VENDOR: '',                                 COUNTRY: '',            INCOTERMS: '',    FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: undefined,    SRD: undefined,    INSTALL: '', SHIP_MODE: '',         SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: '',   G_EREC: 'CD220', ER_PS: '2025-09-17', ER_PF: '2026-03-10', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 0,    DT_ETA: 0,     DT_SRD: 0, },
      { SN: 81,  AREA: 'TOPSIDE', ZONE: 'CENTRAL', LEVEL: 'MZ',  SVG: 'MZ',  ID: 16, BLK: 'CD220', ALIGN: 'end',   TAG: '101720-SFE-22001',       DESC: 'FIRE EXTINGUISHER (9.1 KG DRY CHEMICAL)',                      PO_NO: '',            PO_DATE: undefined,    VENDOR: '',                                 COUNTRY: '',            INCOTERMS: '',    FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: undefined,    SRD: undefined,    INSTALL: '', SHIP_MODE: '',         SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: '',   G_EREC: 'CD220', ER_PS: '2025-09-17', ER_PF: '2026-03-10', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 0,    DT_ETA: 0,     DT_SRD: 0, },
      { SN: 82,  AREA: 'TOPSIDE', ZONE: 'CENTRAL', LEVEL: 'MZ',  SVG: 'MZ',  ID: 17, BLK: 'CD220', ALIGN: 'end',   TAG: '101720-TQZ-87031',       DESC: 'HOSE REEL (end FRONT)',                                        PO_NO: '',            PO_DATE: undefined,    VENDOR: '',                                 COUNTRY: '',            INCOTERMS: '',    FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: undefined,    SRD: undefined,    INSTALL: '', SHIP_MODE: '',         SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: '',   G_EREC: 'CD220', ER_PS: '2025-09-17', ER_PF: '2026-03-10', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 0,    DT_ETA: 0,     DT_SRD: 0, },
      { SN: 83,  AREA: 'TOPSIDE', ZONE: 'CENTRAL', LEVEL: 'MZ',  SVG: 'MZ',  ID: 18, BLK: 'CD250', ALIGN: 'end',   TAG: '101720-SFE-22002',       DESC: 'FIRE EXTINGUISHER (9.1 KG DRY CHEMICAL)',                      PO_NO: '',            PO_DATE: undefined,    VENDOR: '',                                 COUNTRY: '',            INCOTERMS: '',    FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: undefined,    SRD: undefined,    INSTALL: '', SHIP_MODE: '',         SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: '',   G_EREC: 'CD250', ER_PS: '2025-09-19', ER_PF: '2026-03-10', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 0,    DT_ETA: 0,     DT_SRD: 0, },
      { SN: 84,  AREA: 'TOPSIDE', ZONE: 'CENTRAL', LEVEL: 'MZ',  SVG: 'MZ',  ID: 19, BLK: 'CD250', ALIGN: 'end',   TAG: '101720-TQZ-87035',       DESC: 'HOSE REEL (start FRONT)',                                      PO_NO: '',            PO_DATE: undefined,    VENDOR: '',                                 COUNTRY: '',            INCOTERMS: '',    FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: undefined,    SRD: undefined,    INSTALL: '', SHIP_MODE: '',         SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: '',   G_EREC: 'CD250', ER_PS: '2025-09-19', ER_PF: '2026-03-10', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 0,    DT_ETA: 0,     DT_SRD: 0, },
      { SN: 85,  AREA: 'TOPSIDE', ZONE: 'CENTRAL', LEVEL: 'MZ',  SVG: 'MZ',  ID: 20, BLK: 'CD250', ALIGN: 'end',   TAG: '101720-SFE-22007',       DESC: 'FIRE EXTINGUISHER (9.1 KG DRY CHEMICAL)',                      PO_NO: '',            PO_DATE: undefined,    VENDOR: '',                                 COUNTRY: '',            INCOTERMS: '',    FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: undefined,    SRD: undefined,    INSTALL: '', SHIP_MODE: '',         SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: '',   G_EREC: 'CD250', ER_PS: '2025-09-19', ER_PF: '2026-03-10', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 0,    DT_ETA: 0,     DT_SRD: 0, },
      { SN: 86,  AREA: 'TOPSIDE', ZONE: 'CENTRAL', LEVEL: 'MZ',  SVG: 'MZ',  ID: 21, BLK: 'CD250', ALIGN: 'end',   TAG: '101720-TQZ-87036',       DESC: 'HOSE REEL (end FRONT)',                                        PO_NO: '',            PO_DATE: undefined,    VENDOR: '',                                 COUNTRY: '',            INCOTERMS: '',    FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: undefined,    SRD: undefined,    INSTALL: '', SHIP_MODE: '',         SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: '',   G_EREC: 'CD250', ER_PS: '2025-09-19', ER_PF: '2026-03-10', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 0,    DT_ETA: 0,     DT_SRD: 0, },
      { SN: 87,  AREA: 'TOPSIDE', ZONE: 'CENTRAL', LEVEL: 'MD',  SVG: 'MD',  ID: 1,  BLK: 'CD340', ALIGN: 'start', TAG: '101290-ZZZ-20600',       DESC: 'CIP SKID',                                                     PO_NO: 'QD557AFQ52',  PO_DATE: '2024-03-28', VENDOR: 'Cameron',                          COUNTRY: 'China',       INCOTERMS: 'FOB', FAT_PLAN: '2025-06-16', FAT_FC: undefined,    FAT_ACT: undefined,    ETA: '2025-09-18', SRD: '2025-11-06', INSTALL: '', SHIP_MODE: 'NA',       SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: 'NA', G_EREC: 'CD340', ER_PS: '2025-11-20', ER_PF: '2026-03-10', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 49,  DT_ETA: 63,   DT_SRD: 14, },
      { SN: 88,  AREA: 'TOPSIDE', ZONE: 'CENTRAL', LEVEL: 'MD',  SVG: 'MD',  ID: 2,  BLK: 'CD340', ALIGN: 'start', TAG: '101290-ZZZ-20500',       DESC: 'SRU MEMBRANE SKID',                                            PO_NO: 'QD557AFQ52',  PO_DATE: '2024-03-28', VENDOR: 'Cameron',                          COUNTRY: 'China',       INCOTERMS: 'FOB', FAT_PLAN: '2025-06-16', FAT_FC: undefined,    FAT_ACT: undefined,    ETA: '2025-09-23', SRD: '2025-11-06', INSTALL: '', SHIP_MODE: 'NA',       SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: 'NA', G_EREC: 'CD340', ER_PS: '2025-11-20', ER_PF: '2026-03-10', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 44,  DT_ETA: 58,   DT_SRD: 14, },
      { SN: 89,  AREA: 'TOPSIDE', ZONE: 'CENTRAL', LEVEL: 'MD',  SVG: 'MD',  ID: 3,  BLK: 'CD340', ALIGN: 'start', TAG: '101610-ZZZ-50200',       DESC: 'HELICOPTER REFUELING SKID',                                    PO_NO: 'QE557AFQ82',  PO_DATE: '2024-04-19', VENDOR: 'Imenco',                           COUNTRY: 'Norway',      INCOTERMS: 'FOB', FAT_PLAN: '2025-03-17', FAT_FC: undefined,    FAT_ACT: undefined,    ETA: '2025-10-18', SRD: '2025-11-06', INSTALL: '', SHIP_MODE: 'NA',       SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: 'NA', G_EREC: 'CD340', ER_PS: '2025-11-20', ER_PF: '2026-03-10', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 19,  DT_ETA: 33,   DT_SRD: 14, },
      { SN: 90,  AREA: 'TOPSIDE', ZONE: 'CENTRAL', LEVEL: 'MD',  SVG: 'MD',  ID: 4,  BLK: 'CD340', ALIGN: 'start', TAG: '101530-SSW-21004',       DESC: 'EYE WASH & SAFETY SHOWER STATION',                             PO_NO: '',            PO_DATE: undefined,    VENDOR: '',                                 COUNTRY: '',            INCOTERMS: '',    FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: undefined,    SRD: undefined,    INSTALL: '', SHIP_MODE: '',         SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: '',   G_EREC: 'CD340', ER_PS: '2025-11-20', ER_PF: '2026-03-10', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 0,    DT_ETA: 0,     DT_SRD: 0, },
      { SN: 91,  AREA: 'TOPSIDE', ZONE: 'CENTRAL', LEVEL: 'MD',  SVG: 'MD',  ID: 5,  BLK: 'CD340', ALIGN: 'start', TAG: '101800-ZZZ-60600',       DESC: 'NA',                                                           PO_NO: '',            PO_DATE: undefined,    VENDOR: '',                                 COUNTRY: '',            INCOTERMS: '',    FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: undefined,    SRD: undefined,    INSTALL: '', SHIP_MODE: '',         SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: '',   G_EREC: 'CD340', ER_PS: '2025-11-20', ER_PF: '2026-03-10', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 0,    DT_ETA: 0,     DT_SRD: 0, },
      { SN: 92,  AREA: 'TOPSIDE', ZONE: 'CENTRAL', LEVEL: 'MD',  SVG: 'MD',  ID: 6,  BLK: 'CD310', ALIGN: 'start', TAG: '101210-MBJ-10230',       DESC: 'DRY OIL TANK',                                                 PO_NO: 'QU557BCQ9A',  PO_DATE: '2024-04-01', VENDOR: 'Wooyang HC',                       COUNTRY: 'Korea',       INCOTERMS: 'DAP', FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: '2025-04-20', SRD: '2025-10-01', INSTALL: '', SHIP_MODE: 'NA',       SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: 'NA', G_EREC: 'CD310', ER_PS: '2025-10-15', ER_PF: '2026-03-10', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 164, DT_ETA: 178,  DT_SRD: 14, },
      { SN: 93,  AREA: 'TOPSIDE', ZONE: 'CENTRAL', LEVEL: 'MD',  SVG: 'MD',  ID: 7,  BLK: 'CD310', ALIGN: 'start', TAG: '101200-MAM-10190',       DESC: 'BULK OIL DEGASSER',                                            PO_NO: 'QD557BRQ5E',  PO_DATE: '2024-04-15', VENDOR: 'Cameron',                          COUNTRY: 'China',       INCOTERMS: 'FOB', FAT_PLAN: '2025-03-20', FAT_FC: undefined,    FAT_ACT: undefined,    ETA: '2025-05-24', SRD: '2025-10-01', INSTALL: '', SHIP_MODE: 'by Sea',   SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: 'NA', G_EREC: 'CD310', ER_PS: '2025-10-15', ER_PF: '2026-03-10', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 130, DT_ETA: 144,  DT_SRD: 14, },
      { SN: 94,  AREA: 'TOPSIDE', ZONE: 'CENTRAL', LEVEL: 'MD',  SVG: 'MD',  ID: 8,  BLK: 'CD310', ALIGN: 'start', TAG: '101720-SFE-21005',       DESC: 'FIRE EXTINGUISHER (9.1 KG DRY CHEMICAL)',                      PO_NO: '',            PO_DATE: undefined,    VENDOR: '',                                 COUNTRY: '',            INCOTERMS: '',    FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: undefined,    SRD: undefined,    INSTALL: '', SHIP_MODE: '',         SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: '',   G_EREC: 'CD310', ER_PS: '2025-10-15', ER_PF: '2026-03-10', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 0,    DT_ETA: 0,     DT_SRD: 0, },
      { SN: 95,  AREA: 'TOPSIDE', ZONE: 'CENTRAL', LEVEL: 'MD',  SVG: 'MD',  ID: 9,  BLK: 'CK110', ALIGN: 'start', TAG: '101730-TLC-54000',       DESC: 'PEDESTAL CRANE 1 (WEST)',                                      PO_NO: 'QE557AFQ71',  PO_DATE: '2024-04-30', VENDOR: 'Liebherr',                         COUNTRY: 'Germany',     INCOTERMS: 'FOB', FAT_PLAN: '2025-06-17', FAT_FC: undefined,    FAT_ACT: undefined,    ETA: '2025-12-26', SRD: '2026-02-02', INSTALL: '', SHIP_MODE: 'NA',       SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: 'NA', G_EREC: 'CK110', ER_PS: '2026-01-06', ER_PF: '2026-03-10', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 38,  DT_ETA: 11,   DT_SRD: -27, },
      { SN: 96,  AREA: 'TOPSIDE', ZONE: 'CENTRAL', LEVEL: 'MD',  SVG: 'MD',  ID: 10, BLK: 'CD310', ALIGN: 'start', TAG: '101720-TQZ-87007',       DESC: 'FOAM HOSE REEL (end FRONT)',                                   PO_NO: '',            PO_DATE: undefined,    VENDOR: '',                                 COUNTRY: '',            INCOTERMS: '',    FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: undefined,    SRD: undefined,    INSTALL: '', SHIP_MODE: '',         SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: '',   G_EREC: 'CD310', ER_PS: '2025-10-15', ER_PF: '2026-03-10', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 0,    DT_ETA: 0,     DT_SRD: 0, },
      { SN: 97,  AREA: 'TOPSIDE', ZONE: 'CENTRAL', LEVEL: 'MD',  SVG: 'MD',  ID: 11, BLK: 'CD310', ALIGN: 'start', TAG: '101720-SFE-21003',       DESC: 'FIRE EXTINGUISHER (9.1 KG DRY CHEMICAL)',                      PO_NO: '',            PO_DATE: undefined,    VENDOR: '',                                 COUNTRY: '',            INCOTERMS: '',    FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: undefined,    SRD: undefined,    INSTALL: '', SHIP_MODE: '',         SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: '',   G_EREC: 'CD310', ER_PS: '2025-10-15', ER_PF: '2026-03-10', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 0,    DT_ETA: 0,     DT_SRD: 0, },
      { SN: 98,  AREA: 'TOPSIDE', ZONE: 'CENTRAL', LEVEL: 'MD',  SVG: 'MD',  ID: 12, BLK: 'CD310', ALIGN: 'end',   TAG: '101720-TQZ-87057',       DESC: 'FOAM HOSE REEL (end FRONT)',                                   PO_NO: '',            PO_DATE: undefined,    VENDOR: '',                                 COUNTRY: '',            INCOTERMS: '',    FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: undefined,    SRD: undefined,    INSTALL: '', SHIP_MODE: '',         SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: '',   G_EREC: 'CD310', ER_PS: '2025-10-15', ER_PF: '2026-03-10', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 0,    DT_ETA: 0,     DT_SRD: 0, },
      { SN: 99,  AREA: 'TOPSIDE', ZONE: 'CENTRAL', LEVEL: 'MD',  SVG: 'MD',  ID: 13, BLK: 'CD310', ALIGN: 'end',   TAG: '101410-HBG-40450',       DESC: 'HEATING MEDIUM TRIM COOLER',                                   PO_NO: 'QU557BRQ68',  PO_DATE: '2024-03-28', VENDOR: 'Wooyang HC',                       COUNTRY: 'Korea',       INCOTERMS: 'DAP', FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: '2025-05-01', SRD: '2025-10-01', INSTALL: '', SHIP_MODE: 'by Truck', SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: 'NA', G_EREC: 'CD310', ER_PS: '2025-10-15', ER_PF: '2026-03-10', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 153, DT_ETA: 167,  DT_SRD: 14, },
      { SN: 100, AREA: 'TOPSIDE', ZONE: 'CENTRAL', LEVEL: 'MD',  SVG: 'MD',  ID: 14, BLK: 'CD310', ALIGN: 'end',   TAG: '101270-ZZZ-13200',       DESC: 'GAS CHROMATOGRAPH ENCLOSURE',                                  PO_NO: 'QE557BKQ5C',  PO_DATE: '2024-04-12', VENDOR: 'ODS Metering',                     COUNTRY: 'Netherlands', INCOTERMS: 'FOB', FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: '2025-11-29', SRD: '2025-10-01', INSTALL: '', SHIP_MODE: 'NA',       SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: 'NA', G_EREC: 'CD310', ER_PS: '2025-10-15', ER_PF: '2026-03-10', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: -59, DT_ETA: -45,  DT_SRD: 14, },
      { SN: 101, AREA: 'TOPSIDE', ZONE: 'CENTRAL', LEVEL: 'MD',  SVG: 'MD',  ID: 15, BLK: 'CD310', ALIGN: 'end',   TAG: '101270-ZZZ-13100',       DESC: 'SALES AND BUY-BACK GAS METER SKID',                            PO_NO: 'QE557BKQ5C',  PO_DATE: '2024-04-12', VENDOR: 'ODS Metering',                     COUNTRY: 'Netherlands', INCOTERMS: 'FOB', FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: '2025-06-25', SRD: '2025-10-01', INSTALL: '', SHIP_MODE: 'NA',       SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: 'NA', G_EREC: 'CD310', ER_PS: '2025-10-15', ER_PF: '2026-03-10', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 98,  DT_ETA: 112,  DT_SRD: 14, },
      { SN: 102, AREA: 'TOPSIDE', ZONE: 'CENTRAL', LEVEL: 'MD',  SVG: 'MD',  ID: 16, BLK: 'CD310', ALIGN: 'end',   TAG: '101720-CPS-21001',       DESC: 'CHEMICAL PERSONNEL PROTECTION STORAGE BOX',                    PO_NO: '',            PO_DATE: undefined,    VENDOR: '',                                 COUNTRY: '',            INCOTERMS: '',    FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: undefined,    SRD: undefined,    INSTALL: '', SHIP_MODE: '',         SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: '',   G_EREC: 'CD310', ER_PS: '2025-10-15', ER_PF: '2026-03-10', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 0,    DT_ETA: 0,     DT_SRD: 0, },
      { SN: 103, AREA: 'TOPSIDE', ZONE: 'CENTRAL', LEVEL: 'MD',  SVG: 'MD',  ID: 17, BLK: 'CD310', ALIGN: 'end',   TAG: '101720-TQZ-87008',       DESC: 'FOAM HOSE REEL (end FRONT)',                                   PO_NO: '',            PO_DATE: undefined,    VENDOR: '',                                 COUNTRY: '',            INCOTERMS: '',    FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: undefined,    SRD: undefined,    INSTALL: '', SHIP_MODE: '',         SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: '',   G_EREC: 'CD310', ER_PS: '2025-10-15', ER_PF: '2026-03-10', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 0,    DT_ETA: 0,     DT_SRD: 0, },
      { SN: 104, AREA: 'TOPSIDE', ZONE: 'CENTRAL', LEVEL: 'MD',  SVG: 'MD',  ID: 18, BLK: 'CD340', ALIGN: 'end',   TAG: '101720-TQZ-87010',       DESC: 'HOSE REEL (end FRONT)',                                        PO_NO: '',            PO_DATE: undefined,    VENDOR: '',                                 COUNTRY: '',            INCOTERMS: '',    FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: undefined,    SRD: undefined,    INSTALL: '', SHIP_MODE: '',         SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: '',   G_EREC: 'CD340', ER_PS: '2025-11-20', ER_PF: '2026-03-10', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 0,    DT_ETA: 0,     DT_SRD: 0, },
      { SN: 105, AREA: 'TOPSIDE', ZONE: 'CENTRAL', LEVEL: 'MD',  SVG: 'MD',  ID: 19, BLK: 'CD340', ALIGN: 'end',   TAG: '101290-ZZZ-20800',       DESC: 'WATER INJECTION PUMP SKID',                                    PO_NO: 'QE557BKQ22',  PO_DATE: '2024-03-15', VENDOR: 'Baker Hughes',                     COUNTRY: 'Italy',       INCOTERMS: 'FOB', FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: '2026-01-03', SRD: '2025-11-06', INSTALL: '', SHIP_MODE: 'by Sea',   SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: 'NA', G_EREC: 'CD340', ER_PS: '2025-11-20', ER_PF: '2026-03-10', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: -58, DT_ETA: -44,  DT_SRD: 14, },
      { SN: 106, AREA: 'TOPSIDE', ZONE: 'CENTRAL', LEVEL: 'MD',  SVG: 'MD',  ID: 20, BLK: 'CD340', ALIGN: 'end',   TAG: '101720-SFE-21006',       DESC: 'FIRE EXTINGUISHER (9.1 KG DRY CHEMICAL)',                      PO_NO: '',            PO_DATE: undefined,    VENDOR: '',                                 COUNTRY: '',            INCOTERMS: '',    FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: undefined,    SRD: undefined,    INSTALL: '', SHIP_MODE: '',         SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: '',   G_EREC: 'CD340', ER_PS: '2025-11-20', ER_PF: '2026-03-10', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 0,    DT_ETA: 0,     DT_SRD: 0, },
      { SN: 107, AREA: 'TOPSIDE', ZONE: 'CENTRAL', LEVEL: 'MD',  SVG: 'MD',  ID: 21, BLK: 'CD310', ALIGN: 'end',   TAG: '101720-TQZ-87009',       DESC: 'HOSE REEL (end FRONT)',                                        PO_NO: '',            PO_DATE: undefined,    VENDOR: '',                                 COUNTRY: '',            INCOTERMS: '',    FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: undefined,    SRD: undefined,    INSTALL: '', SHIP_MODE: '',         SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: '',   G_EREC: 'CD310', ER_PS: '2025-10-15', ER_PF: '2026-03-10', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 0,    DT_ETA: 0,     DT_SRD: 0, },
      { SN: 108, AREA: 'TOPSIDE', ZONE: 'CENTRAL', LEVEL: 'MD',  SVG: 'MD',  ID: 22, BLK: 'CD310', ALIGN: 'end',   TAG: '101380-ZZZ-40000',       DESC: 'TEG REGENERATION SKID',                                        PO_NO: 'QU557BRQ51',  PO_DATE: '2024-03-13', VENDOR: 'NOV Fjord Processing Korea',       COUNTRY: 'Indonesia',   INCOTERMS: 'FOB', FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: '2025-07-27', SRD: '2025-10-01', INSTALL: '', SHIP_MODE: 'by Sea',   SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: 'NA', G_EREC: 'CD310', ER_PS: '2025-10-15', ER_PF: '2026-03-10', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 66,  DT_ETA: 80,   DT_SRD: 14, },
      { SN: 109, AREA: 'TOPSIDE', ZONE: 'CENTRAL', LEVEL: 'MD',  SVG: 'MD',  ID: 23, BLK: 'CD340', ALIGN: 'end',   TAG: '101290-ABJ-20130',       DESC: 'ULTRA FILTRATION BUFFER VESSEL',                               PO_NO: 'QD557AFQ52',  PO_DATE: '2024-03-28', VENDOR: 'Cameron',                          COUNTRY: 'China',       INCOTERMS: 'FOB', FAT_PLAN: '2025-06-16', FAT_FC: undefined,    FAT_ACT: undefined,    ETA: '2025-08-15', SRD: '2025-11-06', INSTALL: '', SHIP_MODE: 'NA',       SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: 'NA', G_EREC: 'CD340', ER_PS: '2025-11-20', ER_PF: '2026-03-10', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 83,  DT_ETA: 97,   DT_SRD: 14, },
      { SN: 110, AREA: 'TOPSIDE', ZONE: 'CENTRAL', LEVEL: 'MD',  SVG: 'MD',  ID: 24, BLK: 'CD340', ALIGN: 'end',   TAG: '101290-MBM-20650',       DESC: 'DEAERATION TOWER',                                             PO_NO: 'QD557AFQ52',  PO_DATE: '2024-03-28', VENDOR: 'Cameron',                          COUNTRY: 'China',       INCOTERMS: 'FOB', FAT_PLAN: '2025-06-16', FAT_FC: undefined,    FAT_ACT: undefined,    ETA: '2025-08-15', SRD: '2025-11-06', INSTALL: '', SHIP_MODE: 'NA',       SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: 'NA', G_EREC: 'CD340', ER_PS: '2025-11-20', ER_PF: '2026-03-10', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 83,  DT_ETA: 97,   DT_SRD: 14, },
      { SN: 111, AREA: 'TOPSIDE', ZONE: 'CENTRAL', LEVEL: 'MD',  SVG: 'MD',  ID: 25, BLK: 'CD340', ALIGN: 'end',   TAG: '101290-ZZZ-20200',       DESC: 'ULTRA FILTRATION SKID',                                        PO_NO: 'QD557AFQ52',  PO_DATE: '2024-03-28', VENDOR: 'Cameron',                          COUNTRY: 'China',       INCOTERMS: 'FOB', FAT_PLAN: '2025-06-16', FAT_FC: undefined,    FAT_ACT: undefined,    ETA: '2025-08-30', SRD: '2025-11-06', INSTALL: '', SHIP_MODE: 'NA',       SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: 'NA', G_EREC: 'CD340', ER_PS: '2025-11-20', ER_PF: '2026-03-10', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 68,  DT_ETA: 82,   DT_SRD: 14, },
      { SN: 112, AREA: 'TOPSIDE', ZONE: 'CENTRAL', LEVEL: 'MD',  SVG: 'MD',  ID: 26, BLK: 'CD340', ALIGN: 'end',   TAG: '101530-SSW-21002',       DESC: 'EYE WASH & SAFETY SHOWER STATION',                             PO_NO: '',            PO_DATE: undefined,    VENDOR: '',                                 COUNTRY: '',            INCOTERMS: '',    FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: undefined,    SRD: undefined,    INSTALL: '', SHIP_MODE: '',         SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: '',   G_EREC: 'CD340', ER_PS: '2025-11-20', ER_PF: '2026-03-10', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 0,    DT_ETA: 0,     DT_SRD: 0, },
      { SN: 113, AREA: 'TOPSIDE', ZONE: 'CENTRAL', LEVEL: 'MD',  SVG: 'MD',  ID: 27, BLK: 'CD340', ALIGN: 'end',   TAG: '101720-SLBY-21001',      DESC: 'LIFE RING BUOY',                                               PO_NO: '',            PO_DATE: undefined,    VENDOR: '',                                 COUNTRY: '',            INCOTERMS: '',    FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: undefined,    SRD: undefined,    INSTALL: '', SHIP_MODE: '',         SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: '',   G_EREC: 'CD340', ER_PS: '2025-11-20', ER_PF: '2026-03-10', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 0,    DT_ETA: 0,     DT_SRD: 0, },
      { SN: 114, AREA: 'TOPSIDE', ZONE: 'CENTRAL', LEVEL: 'MD',  SVG: 'MD',  ID: 28, BLK: 'CK140', ALIGN: 'end',   TAG: '101730-TLC-54010',       DESC: 'PEDESTAL CRANE 2 (EAST)',                                      PO_NO: 'QE557AFQ71',  PO_DATE: '2024-04-30', VENDOR: 'Liebherr',                         COUNTRY: 'Germany',     INCOTERMS: 'FOB', FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: '2025-12-26', SRD: '2025-12-30', INSTALL: '', SHIP_MODE: 'NA',       SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: 'NA', G_EREC: 'CK140', ER_PS: undefined,    ER_PF: undefined,    ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 4,   DT_ETA: 0,     DT_SRD: 0, },
      { SN: 115, AREA: 'TOPSIDE', ZONE: 'CENTRAL', LEVEL: 'MD',  SVG: 'MD',  ID: 29, BLK: 'CD340', ALIGN: 'end',   TAG: '101720-SFE-21002',       DESC: 'FIRE EXTINGUISHER (9.1 KG DRY CHEMICAL)',                      PO_NO: '',            PO_DATE: undefined,    VENDOR: '',                                 COUNTRY: '',            INCOTERMS: '',    FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: undefined,    SRD: undefined,    INSTALL: '', SHIP_MODE: '',         SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: '',   G_EREC: 'CD340', ER_PS: '2025-11-20', ER_PF: '2026-03-10', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 0,    DT_ETA: 0,     DT_SRD: 0, },
      { SN: 116, AREA: 'TOPSIDE', ZONE: 'CENTRAL', LEVEL: 'MD',  SVG: 'MD',  ID: 30, BLK: 'CD340', ALIGN: 'end',   TAG: '101720-TQZ-87016',       DESC: 'HOSE REEL (start FRONT)',                                      PO_NO: '',            PO_DATE: undefined,    VENDOR: '',                                 COUNTRY: '',            INCOTERMS: '',    FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: undefined,    SRD: undefined,    INSTALL: '', SHIP_MODE: '',         SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: '',   G_EREC: 'CD340', ER_PS: '2025-11-20', ER_PF: '2026-03-10', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 0,    DT_ETA: 0,     DT_SRD: 0, },
      { SN: 117, AREA: 'TOPSIDE', ZONE: 'CENTRAL', LEVEL: 'MD',  SVG: 'MD',  ID: 31, BLK: 'CD340', ALIGN: 'start', TAG: '101720-TQZ-87005',       DESC: 'FOAM HOSE REEL (end FRONT)',                                   PO_NO: '',            PO_DATE: undefined,    VENDOR: '',                                 COUNTRY: '',            INCOTERMS: '',    FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: undefined,    SRD: undefined,    INSTALL: '', SHIP_MODE: '',         SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: '',   G_EREC: 'CD340', ER_PS: '2025-11-20', ER_PF: '2026-03-10', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 0,    DT_ETA: 0,     DT_SRD: 0, },
      { SN: 118, AREA: 'TOPSIDE', ZONE: 'CENTRAL', LEVEL: 'MD',  SVG: 'MD',  ID: 32, BLK: 'CD340', ALIGN: 'start', TAG: '101720-SFE-21008',       DESC: 'FIRE EXTINGUISHER (6.8 KG CO2)',                               PO_NO: '',            PO_DATE: undefined,    VENDOR: '',                                 COUNTRY: '',            INCOTERMS: '',    FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: undefined,    SRD: undefined,    INSTALL: '', SHIP_MODE: '',         SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: '',   G_EREC: 'CD340', ER_PS: '2025-11-20', ER_PF: '2026-03-10', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 0,    DT_ETA: 0,     DT_SRD: 0, },
      { SN: 119, AREA: 'TOPSIDE', ZONE: 'CENTRAL', LEVEL: 'MD',  SVG: 'MD',  ID: 33, BLK: 'CD340', ALIGN: 'start', TAG: '101720-SFE-21009',       DESC: 'FIRE EXTINGUISHER (9.1 KG DRY CHEMICAL)',                      PO_NO: '',            PO_DATE: undefined,    VENDOR: '',                                 COUNTRY: '',            INCOTERMS: '',    FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: undefined,    SRD: undefined,    INSTALL: '', SHIP_MODE: '',         SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: '',   G_EREC: 'CD340', ER_PS: '2025-11-20', ER_PF: '2026-03-10', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 0,    DT_ETA: 0,     DT_SRD: 0, },
      { SN: 120, AREA: 'TOPSIDE', ZONE: 'CENTRAL', LEVEL: 'MD',  SVG: 'MD',  ID: 34, BLK: 'CD310', ALIGN: 'start', TAG: '101530-SSW-21001',       DESC: 'EYE WASH & SAFETY SHOWER STATION',                             PO_NO: '',            PO_DATE: undefined,    VENDOR: '',                                 COUNTRY: '',            INCOTERMS: '',    FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: undefined,    SRD: undefined,    INSTALL: '', SHIP_MODE: '',         SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: '',   G_EREC: 'CD310', ER_PS: '2025-10-15', ER_PF: '2026-03-10', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 0,    DT_ETA: 0,     DT_SRD: 0, },
      { SN: 121, AREA: 'TOPSIDE', ZONE: 'CENTRAL', LEVEL: 'MD',  SVG: 'MD',  ID: 35, BLK: 'CD310', ALIGN: 'start', TAG: '101720-TQZ-87006',       DESC: 'FOAM HOSE REEL (end FRONT)',                                   PO_NO: '',            PO_DATE: undefined,    VENDOR: '',                                 COUNTRY: '',            INCOTERMS: '',    FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: undefined,    SRD: undefined,    INSTALL: '', SHIP_MODE: '',         SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: '',   G_EREC: 'CD310', ER_PS: '2025-10-15', ER_PF: '2026-03-10', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 0,    DT_ETA: 0,     DT_SRD: 0, },
      { SN: 122, AREA: 'TOPSIDE', ZONE: 'CENTRAL', LEVEL: 'MD',  SVG: 'MD',  ID: 36, BLK: 'CD310', ALIGN: 'start', TAG: '101720-CPS-21002',       DESC: 'CHEMICAL PP STORAGE BOX (SP-0061)',                            PO_NO: '',            PO_DATE: undefined,    VENDOR: '',                                 COUNTRY: '',            INCOTERMS: '',    FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: undefined,    SRD: undefined,    INSTALL: '', SHIP_MODE: '',         SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: '',   G_EREC: 'CD310', ER_PS: '2025-10-15', ER_PF: '2026-03-10', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 0,    DT_ETA: 0,     DT_SRD: 0, },
      { SN: 123, AREA: 'TOPSIDE', ZONE: 'CENTRAL', LEVEL: 'MD',  SVG: 'MD',  ID: 37, BLK: 'CD340', ALIGN: 'end',   TAG: '101290-ZZZ-21000',       DESC: 'NA',                                                           PO_NO: 'QD557AFQ52',  PO_DATE: '2024-03-28', VENDOR: 'Cameron',                          COUNTRY: 'China',       INCOTERMS: 'FOB', FAT_PLAN: '2025-06-16', FAT_FC: undefined,    FAT_ACT: undefined,    ETA: '2025-08-15', SRD: '2025-11-06', INSTALL: '', SHIP_MODE: 'NA',       SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: 'NA', G_EREC: 'CD340', ER_PS: '2025-11-20', ER_PF: '2026-03-10', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 83,  DT_ETA: 97,   DT_SRD: 14, },
      { SN: 124, AREA: 'TOPSIDE', ZONE: 'CENTRAL', LEVEL: 'MD',  SVG: 'MD',  ID: 38, BLK: 'CD340', ALIGN: 'end',   TAG: '101290-ZZZ-20100',       DESC: 'DEAERATOR VACUUM PUMP SKID',                                   PO_NO: 'QD557AFQ52',  PO_DATE: '2024-03-28', VENDOR: 'Cameron',                          COUNTRY: 'China',       INCOTERMS: 'FOB', FAT_PLAN: '2025-06-16', FAT_FC: undefined,    FAT_ACT: undefined,    ETA: '2025-09-12', SRD: '2025-11-06', INSTALL: '', SHIP_MODE: 'NA',       SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: 'NA', G_EREC: 'CD340', ER_PS: '2025-11-20', ER_PF: '2026-03-10', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 55,  DT_ETA: 69,   DT_SRD: 14, },
      { SN: 125, AREA: 'TOPSIDE', ZONE: 'NORTH',   LEVEL: 'PD',  SVG: 'PD',  ID: 1,  BLK: 'ND120', ALIGN: 'start', TAG: '101440-ZZZ-15500',       DESC: 'PRODUCED WATER POLISHING SKID (FUTURE)',                       PO_NO: '',            PO_DATE: undefined,    VENDOR: '',                                 COUNTRY: '',            INCOTERMS: '',    FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: undefined,    SRD: undefined,    INSTALL: '', SHIP_MODE: '',         SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: '',   G_EREC: 'ND120', ER_PS: '2025-09-29', ER_PF: '2026-04-08', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 0,    DT_ETA: 0,     DT_SRD: 0, },
      { SN: 126, AREA: 'TOPSIDE', ZONE: 'NORTH',   LEVEL: 'PD',  SVG: 'PD',  ID: 2,  BLK: 'ND120', ALIGN: 'start', TAG: '101530-SSW-23004',       DESC: 'EYE WASH & SAFETY SHOWER STATION',                             PO_NO: '',            PO_DATE: undefined,    VENDOR: '',                                 COUNTRY: '',            INCOTERMS: '',    FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: undefined,    SRD: undefined,    INSTALL: '', SHIP_MODE: '',         SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: '',   G_EREC: 'ND120', ER_PS: '2025-09-29', ER_PF: '2026-04-08', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 0,    DT_ETA: 0,     DT_SRD: 0, },
      { SN: 127, AREA: 'TOPSIDE', ZONE: 'NORTH',   LEVEL: 'PD',  SVG: 'PD',  ID: 3,  BLK: 'ND120', ALIGN: 'start', TAG: '101420-ZZZ-33000',       DESC: 'SUBSEA CHEMICAL INJECTION SKID',                               PO_NO: 'QU557AEQ55',  PO_DATE: '2024-03-27', VENDOR: 'Decatech',                         COUNTRY: 'Korea',       INCOTERMS: 'DAP', FAT_PLAN: '2025-05-12', FAT_FC: undefined,    FAT_ACT: undefined,    ETA: '2025-09-30', SRD: '2025-09-15', INSTALL: '', SHIP_MODE: 'NA',       SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: 'NA', G_EREC: 'ND120', ER_PS: '2025-09-29', ER_PF: '2026-04-08', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: -15, DT_ETA: -1,   DT_SRD: 14, },
      { SN: 128, AREA: 'TOPSIDE', ZONE: 'NORTH',   LEVEL: 'PD',  SVG: 'PD',  ID: 4,  BLK: 'ND120', ALIGN: 'start', TAG: '101230-MBF-12130',       DESC: 'FGC SUCTION SCRUBBER',                                         PO_NO: 'QU557BCQ9B',  PO_DATE: '2024-04-01', VENDOR: 'Wooyang HC',                       COUNTRY: 'Korea',       INCOTERMS: 'DAP', FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: '2025-04-20', SRD: '2025-09-15', INSTALL: '', SHIP_MODE: 'NA',       SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: 'NA', G_EREC: 'ND120', ER_PS: '2025-09-29', ER_PF: '2026-04-08', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 148, DT_ETA: 162,  DT_SRD: 14, },
      { SN: 129, AREA: 'TOPSIDE', ZONE: 'NORTH',   LEVEL: 'PD',  SVG: 'PD',  ID: 5,  BLK: 'ND120', ALIGN: 'start', TAG: '101230-MBF-12480',       DESC: 'GIC SUCTION SCRUBBER',                                         PO_NO: 'QU557BCQ9C',  PO_DATE: '2024-04-01', VENDOR: 'Wooyang HC',                       COUNTRY: 'Korea',       INCOTERMS: 'DAP', FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: '2025-05-08', SRD: '2025-09-15', INSTALL: '', SHIP_MODE: 'NA',       SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: 'NA', G_EREC: 'ND120', ER_PS: '2025-09-29', ER_PF: '2026-04-08', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 130, DT_ETA: 144,  DT_SRD: 14, },
      { SN: 130, AREA: 'TOPSIDE', ZONE: 'NORTH',   LEVEL: 'PD',  SVG: 'PD',  ID: 6,  BLK: 'ND120', ALIGN: 'start', TAG: '101420-ZZZ-30000',       DESC: 'TOPSIDES CHEMICAL INJECTION SKID',                             PO_NO: 'QU557AEQ55',  PO_DATE: '2024-03-27', VENDOR: 'Decatech',                         COUNTRY: 'Korea',       INCOTERMS: 'DAP', FAT_PLAN: '2025-05-12', FAT_FC: undefined,    FAT_ACT: undefined,    ETA: '2025-09-30', SRD: '2025-09-15', INSTALL: '', SHIP_MODE: 'NA',       SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: 'NA', G_EREC: 'ND120', ER_PS: '2025-09-29', ER_PF: '2026-04-08', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: -15, DT_ETA: -1,   DT_SRD: 14, },
      { SN: 131, AREA: 'TOPSIDE', ZONE: 'NORTH',   LEVEL: 'PD',  SVG: 'PD',  ID: 7,  BLK: 'ND120', ALIGN: 'start', TAG: '101230-MBF-12040',       DESC: 'VRC SUCTION SCRUBBER',                                         PO_NO: 'QU557BCQ9A',  PO_DATE: '2024-04-01', VENDOR: 'Wooyang HC',                       COUNTRY: 'Korea',       INCOTERMS: 'DAP', FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: '2025-04-20', SRD: '2025-09-15', INSTALL: '', SHIP_MODE: 'NA',       SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: 'NA', G_EREC: 'ND120', ER_PS: '2025-09-29', ER_PF: '2026-04-08', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 148, DT_ETA: 162,  DT_SRD: 14, },
      { SN: 132, AREA: 'TOPSIDE', ZONE: 'NORTH',   LEVEL: 'PD',  SVG: 'PD',  ID: 8,  BLK: 'ND120', ALIGN: 'start', TAG: '101230-PBA-12020',       DESC: 'VRC SCRUBBER PUMP 2',                                          PO_NO: 'QA557BWMSC',  PO_DATE: '2024-05-23', VENDOR: 'Sulzer Pumps(US)',                 COUNTRY: 'Mexico',      INCOTERMS: 'FOB', FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: '2025-06-23', SRD: '2025-09-15', INSTALL: '', SHIP_MODE: 'by Sea',   SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: 'NA', G_EREC: 'ND120', ER_PS: '2025-09-29', ER_PF: '2026-04-08', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 84,  DT_ETA: 98,   DT_SRD: 14, },
      { SN: 133, AREA: 'TOPSIDE', ZONE: 'NORTH',   LEVEL: 'PD',  SVG: 'PD',  ID: 9,  BLK: 'ND120', ALIGN: 'start', TAG: '101230-PBA-12010',       DESC: 'VRC SCRUBBER PUMP 1',                                          PO_NO: 'QA557BWMSC',  PO_DATE: '2024-05-23', VENDOR: 'Sulzer Pumps(US)',                 COUNTRY: 'Mexico',      INCOTERMS: 'FOB', FAT_PLAN: '2024-03-19', FAT_FC: undefined,    FAT_ACT: undefined,    ETA: '2025-06-23', SRD: '2025-09-15', INSTALL: '', SHIP_MODE: 'by Sea',   SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: 'NA', G_EREC: 'ND120', ER_PS: '2025-09-29', ER_PF: '2026-04-08', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 84,  DT_ETA: 98,   DT_SRD: 14, },
      { SN: 134, AREA: 'TOPSIDE', ZONE: 'NORTH',   LEVEL: 'PD',  SVG: 'PD',  ID: 10, BLK: 'ND120', ALIGN: 'start', TAG: '101450-ZZZ-40500',       DESC: 'FUEL GAS SKID',                                                PO_NO: 'QU557BRQ53',  PO_DATE: '2024-04-15', VENDOR: 'NOV Fjord Processing Korea',       COUNTRY: 'Indonesia',   INCOTERMS: 'FOB', FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: '2025-11-24', SRD: '2025-09-15', INSTALL: '', SHIP_MODE: 'by Sea',   SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: 'NA', G_EREC: 'ND120', ER_PS: '2025-09-29', ER_PF: '2026-04-08', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: -70, DT_ETA: -56,  DT_SRD: 14, },
      { SN: 135, AREA: 'TOPSIDE', ZONE: 'NORTH',   LEVEL: 'PD',  SVG: 'PD',  ID: 11, BLK: 'ND110', ALIGN: 'start', TAG: '101720-SFE-13005',       DESC: 'FIRE EXTINGUISHER (6.8 KG CO2)',                               PO_NO: '',            PO_DATE: undefined,    VENDOR: '',                                 COUNTRY: '',            INCOTERMS: '',    FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: undefined,    SRD: undefined,    INSTALL: '', SHIP_MODE: '',         SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: '',   G_EREC: 'ND110', ER_PS: '2025-09-22', ER_PF: '2026-04-08', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 0,    DT_ETA: 0,     DT_SRD: 0, },
      { SN: 136, AREA: 'TOPSIDE', ZONE: 'NORTH',   LEVEL: 'PD',  SVG: 'PD',  ID: 12, BLK: 'ND110', ALIGN: 'start', TAG: '101210-PBA-10330',       DESC: 'CRUDE OIL TRANSFER PUMP 3',                                    PO_NO: 'QU557BKQ27',  PO_DATE: '2024-04-09', VENDOR: 'ITT Goulds',                       COUNTRY: 'Korea',       INCOTERMS: 'DDP', FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: '2025-07-09', SRD: '2025-09-08', INSTALL: '', SHIP_MODE: 'NA',       SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: 'NA', G_EREC: 'ND110', ER_PS: '2025-09-22', ER_PF: '2026-04-08', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 61,  DT_ETA: 75,   DT_SRD: 14, },
      { SN: 137, AREA: 'TOPSIDE', ZONE: 'NORTH',   LEVEL: 'PD',  SVG: 'PD',  ID: 13, BLK: 'ND110', ALIGN: 'start', TAG: '101210-PBA-10320',       DESC: 'CRUDE OIL TRANSFER PUMP 2',                                    PO_NO: 'QU557BKQ27',  PO_DATE: '2024-04-09', VENDOR: 'ITT Goulds',                       COUNTRY: 'Korea',       INCOTERMS: 'DDP', FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: '2025-07-09', SRD: '2025-09-08', INSTALL: '', SHIP_MODE: 'NA',       SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: 'NA', G_EREC: 'ND110', ER_PS: '2025-09-22', ER_PF: '2026-04-08', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 61,  DT_ETA: 75,   DT_SRD: 14, },
      { SN: 138, AREA: 'TOPSIDE', ZONE: 'NORTH',   LEVEL: 'PD',  SVG: 'PD',  ID: 14, BLK: 'ND110', ALIGN: 'start', TAG: '101210-PBA-10310',       DESC: 'CRUDE OIL TRANSFER PUMP 1',                                    PO_NO: 'QU557BKQ27',  PO_DATE: '2024-04-09', VENDOR: 'ITT Goulds',                       COUNTRY: 'Korea',       INCOTERMS: 'DDP', FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: '2025-07-09', SRD: '2025-09-08', INSTALL: '', SHIP_MODE: 'NA',       SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: 'NA', G_EREC: 'ND110', ER_PS: '2025-09-22', ER_PF: '2026-04-08', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 61,  DT_ETA: 75,   DT_SRD: 14, },
      { SN: 139, AREA: 'TOPSIDE', ZONE: 'NORTH',   LEVEL: 'PD',  SVG: 'PD',  ID: 15, BLK: 'ND110', ALIGN: 'start', TAG: '101250-MAK-12640',       DESC: 'GAS FILTER SEPARATOR 2',                                       PO_NO: 'QU557BRQ51',  PO_DATE: '2024-03-13', VENDOR: 'NOV Fjord Processing Korea',       COUNTRY: 'Indonesia',   INCOTERMS: 'FOB', FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: '2025-07-27', SRD: '2025-09-08', INSTALL: '', SHIP_MODE: 'by Sea',   SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: 'NA', G_EREC: 'ND110', ER_PS: '2025-09-22', ER_PF: '2026-04-08', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 43,  DT_ETA: 57,   DT_SRD: 14, },
      { SN: 140, AREA: 'TOPSIDE', ZONE: 'NORTH',   LEVEL: 'PD',  SVG: 'PD',  ID: 16, BLK: 'ND110', ALIGN: 'start', TAG: '101250-MAK-12630',       DESC: 'GAS FILTER SEPARATOR 1',                                       PO_NO: 'QU557BRQ51',  PO_DATE: '2024-03-13', VENDOR: 'NOV Fjord Processing Korea',       COUNTRY: 'Indonesia',   INCOTERMS: 'FOB', FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: '2025-07-27', SRD: '2025-09-08', INSTALL: '', SHIP_MODE: 'by Sea',   SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: 'NA', G_EREC: 'ND110', ER_PS: '2025-09-22', ER_PF: '2026-04-08', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 43,  DT_ETA: 57,   DT_SRD: 14, },
      { SN: 141, AREA: 'TOPSIDE', ZONE: 'NORTH',   LEVEL: 'PD',  SVG: 'PD',  ID: 17, BLK: 'ND110', ALIGN: 'start', TAG: '101250-MAF-13000',       DESC: 'TEG CONTACTOR',                                                PO_NO: 'QU557BRQ51',  PO_DATE: '2024-03-13', VENDOR: 'NOV Fjord Processing Korea',       COUNTRY: 'Indonesia',   INCOTERMS: 'FOB', FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: '2025-07-27', SRD: '2025-10-01', INSTALL: '', SHIP_MODE: 'by Sea',   SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: 'NA', G_EREC: 'ND110', ER_PS: '2025-09-22', ER_PF: '2026-04-08', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 66,  DT_ETA: 57,   DT_SRD: -9, },
      { SN: 142, AREA: 'TOPSIDE', ZONE: 'NORTH',   LEVEL: 'PD',  SVG: 'PD',  ID: 18, BLK: 'ND110', ALIGN: 'start', TAG: '101210-KPLA-10340',      DESC: 'OIL TRANSFER PIPELINE PIG LAUNCHER / RECEIVER',                PO_NO: 'QD557BH1P22', PO_DATE: '2024-04-15', VENDOR: 'Applied Engineering',              COUNTRY: 'Singapore',   INCOTERMS: 'FOB', FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: '2025-06-06', SRD: '2025-09-30', INSTALL: '', SHIP_MODE: 'NA',       SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: 'NA', G_EREC: 'ND110', ER_PS: '2025-09-22', ER_PF: '2026-04-08', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 116, DT_ETA: 108,  DT_SRD: -8, },
      { SN: 143, AREA: 'TOPSIDE', ZONE: 'NORTH',   LEVEL: 'PD',  SVG: 'PD',  ID: 19, BLK: 'ND110', ALIGN: 'start', TAG: '101270-KPLA-13210',      DESC: 'GAS EXPORT PIG LAUNCHER/RECEIVER',                             PO_NO: 'QD557BH1P22', PO_DATE: '2024-04-15', VENDOR: 'Applied Engineering',              COUNTRY: 'Singapore',   INCOTERMS: 'FOB', FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: '2025-06-06', SRD: '2025-09-30', INSTALL: '', SHIP_MODE: 'NA',       SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: 'NA', G_EREC: 'ND110', ER_PS: '2025-09-22', ER_PF: '2026-04-08', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 116, DT_ETA: 108,  DT_SRD: -8, },
      { SN: 144, AREA: 'TOPSIDE', ZONE: 'NORTH',   LEVEL: 'PD',  SVG: 'PD',  ID: 20, BLK: 'ND110', ALIGN: 'end',   TAG: '101720-SFE-13001',       DESC: 'FIRE EXTINGUISHER (9.1 KG DRY CHEMICAL)',                      PO_NO: '',            PO_DATE: undefined,    VENDOR: '',                                 COUNTRY: '',            INCOTERMS: '',    FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: undefined,    SRD: undefined,    INSTALL: '', SHIP_MODE: '',         SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: '',   G_EREC: 'ND110', ER_PS: '2025-09-22', ER_PF: '2026-04-08', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 0,    DT_ETA: 0,     DT_SRD: 0, },
      { SN: 145, AREA: 'TOPSIDE', ZONE: 'NORTH',   LEVEL: 'PD',  SVG: 'PD',  ID: 21, BLK: 'ND110', ALIGN: 'end',   TAG: '101720-WVB-13001',       DESC: 'WORK VEST STORAGE BOX',                                        PO_NO: '',            PO_DATE: undefined,    VENDOR: '',                                 COUNTRY: '',            INCOTERMS: '',    FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: undefined,    SRD: undefined,    INSTALL: '', SHIP_MODE: '',         SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: '',   G_EREC: 'ND110', ER_PS: '2025-09-22', ER_PF: '2026-04-08', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 0,    DT_ETA: 0,     DT_SRD: 0, },
      { SN: 146, AREA: 'TOPSIDE', ZONE: 'NORTH',   LEVEL: 'PD',  SVG: 'PD',  ID: 22, BLK: 'ND110', ALIGN: 'end',   TAG: '101720-TQZ-87056',       DESC: 'FOAM HOSE REEL (end FRONT)',                                   PO_NO: '',            PO_DATE: undefined,    VENDOR: '',                                 COUNTRY: '',            INCOTERMS: '',    FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: undefined,    SRD: undefined,    INSTALL: '', SHIP_MODE: '',         SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: '',   G_EREC: 'ND110', ER_PS: '2025-09-22', ER_PF: '2026-04-08', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 0,    DT_ETA: 0,     DT_SRD: 0, },
      { SN: 147, AREA: 'TOPSIDE', ZONE: 'NORTH',   LEVEL: 'PD',  SVG: 'PD',  ID: 23, BLK: 'ND120', ALIGN: 'end',   TAG: '101720-SFE-13007',       DESC: 'FIRE EXTINGUISHER (9.1 KG DRY CHEMICAL)',                      PO_NO: '',            PO_DATE: undefined,    VENDOR: '',                                 COUNTRY: '',            INCOTERMS: '',    FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: undefined,    SRD: undefined,    INSTALL: '', SHIP_MODE: '',         SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: '',   G_EREC: 'ND120', ER_PS: '2025-09-29', ER_PF: '2026-04-08', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 0,    DT_ETA: 0,     DT_SRD: 0, },
      { SN: 148, AREA: 'TOPSIDE', ZONE: 'NORTH',   LEVEL: 'PD',  SVG: 'PD',  ID: 24, BLK: 'ND120', ALIGN: 'end',   TAG: '101720-TQZ-87038',       DESC: 'FOAM HOSE REEL (end FRONT)',                                   PO_NO: '',            PO_DATE: undefined,    VENDOR: '',                                 COUNTRY: '',            INCOTERMS: '',    FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: undefined,    SRD: undefined,    INSTALL: '', SHIP_MODE: '',         SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: '',   G_EREC: 'ND120', ER_PS: '2025-09-29', ER_PF: '2026-04-08', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 0,    DT_ETA: 0,     DT_SRD: 0, },
      { SN: 149, AREA: 'TOPSIDE', ZONE: 'NORTH',   LEVEL: 'PD',  SVG: 'PD',  ID: 25, BLK: 'ND120', ALIGN: 'end',   TAG: '101720-CPS-13001',       DESC: 'CHEMICAL PERSONNEL PROTECTION STORAGE BOX',                    PO_NO: '',            PO_DATE: undefined,    VENDOR: '',                                 COUNTRY: '',            INCOTERMS: '',    FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: undefined,    SRD: undefined,    INSTALL: '', SHIP_MODE: '',         SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: '',   G_EREC: 'ND120', ER_PS: '2025-09-29', ER_PF: '2026-04-08', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 0,    DT_ETA: 0,     DT_SRD: 0, },
      { SN: 150, AREA: 'TOPSIDE', ZONE: 'NORTH',   LEVEL: 'PD',  SVG: 'PD',  ID: 26, BLK: 'ND110', ALIGN: 'end',   TAG: '101650-ZZZ-52200',       DESC: 'TOPSIDES HYDRAULIC POWER UNIT',                                PO_NO: 'QD557ALP07',  PO_DATE: '2024-04-26', VENDOR: 'Proserv',                          COUNTRY: 'UAE',         INCOTERMS: 'FOB', FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: '2025-05-28', SRD: '2025-09-08', INSTALL: '', SHIP_MODE: 'by Sea',   SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: 'NA', G_EREC: 'ND110', ER_PS: '2025-09-22', ER_PF: '2026-04-08', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 103, DT_ETA: 117,  DT_SRD: 14, },
      { SN: 151, AREA: 'TOPSIDE', ZONE: 'NORTH',   LEVEL: 'PD',  SVG: 'PD',  ID: 27, BLK: 'ND120', ALIGN: 'end',   TAG: '101760-LDY-54210',       DESC: 'SURVIVAL CRAFT 2 DAVIT',                                       PO_NO: '',            PO_DATE: undefined,    VENDOR: '',                                 COUNTRY: '',            INCOTERMS: '',    FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: undefined,    SRD: undefined,    INSTALL: '', SHIP_MODE: '',         SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: '',   G_EREC: 'ND120', ER_PS: '2025-09-29', ER_PF: '2026-04-08', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 0,    DT_ETA: 0,     DT_SRD: 0, },
      { SN: 152, AREA: 'TOPSIDE', ZONE: 'NORTH',   LEVEL: 'PD',  SVG: 'PD',  ID: 28, BLK: 'ND120', ALIGN: 'end',   TAG: '101720-SFE-13010',       DESC: 'FIRE EXTINGUISHER (9.1 KG DRY CHEMICAL)',                      PO_NO: '',            PO_DATE: undefined,    VENDOR: '',                                 COUNTRY: '',            INCOTERMS: '',    FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: undefined,    SRD: undefined,    INSTALL: '', SHIP_MODE: '',         SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: '',   G_EREC: 'ND120', ER_PS: '2025-09-29', ER_PF: '2026-04-08', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 0,    DT_ETA: 0,     DT_SRD: 0, },
      { SN: 153, AREA: 'TOPSIDE', ZONE: 'NORTH',   LEVEL: 'PD',  SVG: 'PD',  ID: 29, BLK: 'ND120', ALIGN: 'end',   TAG: '101760-SLB-54200',       DESC: 'SURVIVAL CRAFT 2',                                             PO_NO: 'QA557BWSVC',  PO_DATE: '2024-05-13', VENDOR: 'Survival Systems International',   COUNTRY: 'USA',         INCOTERMS: 'FOB', FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: '2025-07-27', SRD: '2025-09-15', INSTALL: '', SHIP_MODE: 'NA',       SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: 'NA', G_EREC: 'ND120', ER_PS: '2025-09-29', ER_PF: '2026-04-08', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 50,  DT_ETA: 64,   DT_SRD: 14, },
      { SN: 154, AREA: 'TOPSIDE', ZONE: 'NORTH',   LEVEL: 'PD',  SVG: 'PD',  ID: 30, BLK: 'ND120', ALIGN: 'end',   TAG: '101430-NBF-14000',       DESC: 'HP/LT FLARE SCRUBBER',                                         PO_NO: 'QU557BCQ9A',  PO_DATE: '2024-04-01', VENDOR: 'Wooyang HC',                       COUNTRY: 'Korea',       INCOTERMS: 'DAP', FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: '2025-04-20', SRD: '2025-09-15', INSTALL: '', SHIP_MODE: 'NA',       SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: 'NA', G_EREC: 'ND120', ER_PS: '2025-09-29', ER_PF: '2026-04-08', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 148, DT_ETA: 162,  DT_SRD: 14, },
      { SN: 155, AREA: 'TOPSIDE', ZONE: 'NORTH',   LEVEL: 'PD',  SVG: 'PD',  ID: 31, BLK: 'ND120', ALIGN: 'end',   TAG: '101720-LJB-13001',       DESC: 'LIFE JACKET STORAGE BOX (NOMINAL 40 JACKETS)',                 PO_NO: '',            PO_DATE: undefined,    VENDOR: '',                                 COUNTRY: '',            INCOTERMS: '',    FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: undefined,    SRD: undefined,    INSTALL: '', SHIP_MODE: '',         SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: '',   G_EREC: 'ND120', ER_PS: '2025-09-29', ER_PF: '2026-04-08', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 0,    DT_ETA: 0,     DT_SRD: 0, },
      { SN: 156, AREA: 'TOPSIDE', ZONE: 'NORTH',   LEVEL: 'PD',  SVG: 'PD',  ID: 32, BLK: 'ND120', ALIGN: 'end',   TAG: '101720-SFE-13011',       DESC: 'FIRE EXTINGUISHER (9.1 KG DRY CHEMICAL)',                      PO_NO: '',            PO_DATE: undefined,    VENDOR: '',                                 COUNTRY: '',            INCOTERMS: '',    FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: undefined,    SRD: undefined,    INSTALL: '', SHIP_MODE: '',         SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: '',   G_EREC: 'ND120', ER_PS: '2025-09-29', ER_PF: '2026-04-08', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 0,    DT_ETA: 0,     DT_SRD: 0, },
      { SN: 157, AREA: 'TOPSIDE', ZONE: 'NORTH',   LEVEL: 'PD',  SVG: 'PD',  ID: 33, BLK: 'ND120', ALIGN: 'end',   TAG: '101720-TQZ-87039',       DESC: 'FOAM HOSE REEL (start FRONT)',                                 PO_NO: '',            PO_DATE: undefined,    VENDOR: '',                                 COUNTRY: '',            INCOTERMS: '',    FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: undefined,    SRD: undefined,    INSTALL: '', SHIP_MODE: '',         SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: '',   G_EREC: 'ND120', ER_PS: '2025-09-29', ER_PF: '2026-04-08', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 0,    DT_ETA: 0,     DT_SRD: 0, },
      { SN: 158, AREA: 'TOPSIDE', ZONE: 'NORTH',   LEVEL: 'PD',  SVG: 'PD',  ID: 34, BLK: 'ND120', ALIGN: 'end',   TAG: '101435-MBF-14300',       DESC: 'LP FLARE SCRUBBER',                                            PO_NO: 'QU557BCQ9A',  PO_DATE: '2024-04-01', VENDOR: 'Wooyang HC',                       COUNTRY: 'Korea',       INCOTERMS: 'DAP', FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: '2025-04-20', SRD: '2025-09-15', INSTALL: '', SHIP_MODE: 'NA',       SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: 'NA', G_EREC: 'ND120', ER_PS: '2025-09-29', ER_PF: '2026-04-08', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 148, DT_ETA: 162,  DT_SRD: 14, },
      { SN: 159, AREA: 'TOPSIDE', ZONE: 'NORTH',   LEVEL: 'PD',  SVG: 'PD',  ID: 35, BLK: 'ND120', ALIGN: 'end',   TAG: '101720-SLBY-13002',      DESC: 'LIFE RING BUOY',                                               PO_NO: '',            PO_DATE: undefined,    VENDOR: '',                                 COUNTRY: '',            INCOTERMS: '',    FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: undefined,    SRD: undefined,    INSTALL: '', SHIP_MODE: '',         SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: '',   G_EREC: 'ND120', ER_PS: '2025-09-29', ER_PF: '2026-04-08', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 0,    DT_ETA: 0,     DT_SRD: 0, },
      { SN: 160, AREA: 'TOPSIDE', ZONE: 'NORTH',   LEVEL: 'PD',  SVG: 'PD',  ID: 36, BLK: 'ND120', ALIGN: 'end',   TAG: '101720-LJB-13002',       DESC: 'LIFE JACKET STORAGE BOX (NOMINAL 40 JACKETS)',                 PO_NO: '',            PO_DATE: undefined,    VENDOR: '',                                 COUNTRY: '',            INCOTERMS: '',    FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: undefined,    SRD: undefined,    INSTALL: '', SHIP_MODE: '',         SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: '',   G_EREC: 'ND120', ER_PS: '2025-09-29', ER_PF: '2026-04-08', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 0,    DT_ETA: 0,     DT_SRD: 0, },
      { SN: 161, AREA: 'TOPSIDE', ZONE: 'NORTH',   LEVEL: 'PD',  SVG: 'PD',  ID: 37, BLK: 'ND120', ALIGN: 'end',   TAG: '101440-ZZZ-14700',       DESC: 'FLOTATION CELL SKID',                                          PO_NO: 'QD557AFQ54',  PO_DATE: '2024-03-28', VENDOR: 'Cameron',                          COUNTRY: 'China',       INCOTERMS: 'FOB', FAT_PLAN: '2025-03-11', FAT_FC: undefined,    FAT_ACT: undefined,    ETA: '2025-05-30', SRD: '2025-09-15', INSTALL: '', SHIP_MODE: 'NA',       SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: 'NA', G_EREC: 'ND120', ER_PS: '2025-09-29', ER_PF: '2026-04-08', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 108, DT_ETA: 122,  DT_SRD: 14, },
      { SN: 162, AREA: 'TOPSIDE', ZONE: 'NORTH',   LEVEL: 'PD',  SVG: 'PD',  ID: 38, BLK: 'ND120', ALIGN: 'end',   TAG: '101720-SFE-13013',       DESC: 'FIRE EXTINGUISHER (9.1 KG DRY CHEMICAL)',                      PO_NO: '',            PO_DATE: undefined,    VENDOR: '',                                 COUNTRY: '',            INCOTERMS: '',    FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: undefined,    SRD: undefined,    INSTALL: '', SHIP_MODE: '',         SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: '',   G_EREC: 'ND120', ER_PS: '2025-09-29', ER_PF: '2026-04-08', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 0,    DT_ETA: 0,     DT_SRD: 0, },
      { SN: 163, AREA: 'TOPSIDE', ZONE: 'NORTH',   LEVEL: 'PD',  SVG: 'PD',  ID: 39, BLK: 'ND120', ALIGN: 'end',   TAG: '101720-TQZ-87040',       DESC: 'FOAM HOSE REEL (end FRONT)',                                   PO_NO: '',            PO_DATE: undefined,    VENDOR: '',                                 COUNTRY: '',            INCOTERMS: '',    FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: undefined,    SRD: undefined,    INSTALL: '', SHIP_MODE: '',         SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: '',   G_EREC: 'ND120', ER_PS: '2025-09-29', ER_PF: '2026-04-08', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 0,    DT_ETA: 0,     DT_SRD: 0, },
      { SN: 164, AREA: 'TOPSIDE', ZONE: 'NORTH',   LEVEL: 'PD',  SVG: 'PD',  ID: 40, BLK: 'ND120', ALIGN: 'start', TAG: '101720-SFE-13012',       DESC: 'FIRE EXTINGUISHER (6.8 KG CO2)',                               PO_NO: '',            PO_DATE: undefined,    VENDOR: '',                                 COUNTRY: '',            INCOTERMS: '',    FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: undefined,    SRD: undefined,    INSTALL: '', SHIP_MODE: '',         SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: '',   G_EREC: 'ND120', ER_PS: '2025-09-29', ER_PF: '2026-04-08', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 0,    DT_ETA: 0,     DT_SRD: 0, },
      { SN: 165, AREA: 'TOPSIDE', ZONE: 'NORTH',   LEVEL: 'PD',  SVG: 'PD',  ID: 41, BLK: 'ND120', ALIGN: 'start', TAG: '101720-SFE-13014',       DESC: 'WHEELED FIRE EXTINGUISHER (56.7 KG DRY CHEM.)',                PO_NO: '',            PO_DATE: undefined,    VENDOR: '',                                 COUNTRY: '',            INCOTERMS: '',    FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: undefined,    SRD: undefined,    INSTALL: '', SHIP_MODE: '',         SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: '',   G_EREC: 'ND120', ER_PS: '2025-09-29', ER_PF: '2026-04-08', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 0,    DT_ETA: 0,     DT_SRD: 0, },
      { SN: 166, AREA: 'TOPSIDE', ZONE: 'NORTH',   LEVEL: 'PD',  SVG: 'PD',  ID: 42, BLK: 'ND120', ALIGN: 'start', TAG: '101720-CPS-13002',       DESC: 'CHEMICAL PP STORAGE BOX (SP-0061)',                            PO_NO: '',            PO_DATE: undefined,    VENDOR: '',                                 COUNTRY: '',            INCOTERMS: '',    FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: undefined,    SRD: undefined,    INSTALL: '', SHIP_MODE: '',         SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: '',   G_EREC: 'ND120', ER_PS: '2025-09-29', ER_PF: '2026-04-08', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 0,    DT_ETA: 0,     DT_SRD: 0, },
      { SN: 167, AREA: 'TOPSIDE', ZONE: 'NORTH',   LEVEL: 'PD',  SVG: 'PD',  ID: 43, BLK: 'ND120', ALIGN: 'start', TAG: '101530-SSW-23001',       DESC: 'EYE WASH & SAFETY SHOWER STATION',                             PO_NO: '',            PO_DATE: undefined,    VENDOR: '',                                 COUNTRY: '',            INCOTERMS: '',    FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: undefined,    SRD: undefined,    INSTALL: '', SHIP_MODE: '',         SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: '',   G_EREC: 'ND120', ER_PS: '2025-09-29', ER_PF: '2026-04-08', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 0,    DT_ETA: 0,     DT_SRD: 0, },
      { SN: 168, AREA: 'TOPSIDE', ZONE: 'NORTH',   LEVEL: 'PD',  SVG: 'PD',  ID: 44, BLK: 'ND120', ALIGN: 'start', TAG: '101720-SFE-13009',       DESC: 'FIRE EXTINGUISHER (6.8 KG CO2)',                               PO_NO: '',            PO_DATE: undefined,    VENDOR: '',                                 COUNTRY: '',            INCOTERMS: '',    FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: undefined,    SRD: undefined,    INSTALL: '', SHIP_MODE: '',         SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: '',   G_EREC: 'ND120', ER_PS: '2025-09-29', ER_PF: '2026-04-08', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 0,    DT_ETA: 0,     DT_SRD: 0, },
      { SN: 169, AREA: 'TOPSIDE', ZONE: 'NORTH',   LEVEL: 'PD',  SVG: 'PD',  ID: 45, BLK: 'ND120', ALIGN: 'start', TAG: '101720-SFE-13006',       DESC: 'WHEELED FIRE EXTINGUISHER (56.7 KG DRY CHEM.)',                PO_NO: '',            PO_DATE: undefined,    VENDOR: '',                                 COUNTRY: '',            INCOTERMS: '',    FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: undefined,    SRD: undefined,    INSTALL: '', SHIP_MODE: '',         SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: '',   G_EREC: 'ND120', ER_PS: '2025-09-29', ER_PF: '2026-04-08', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 0,    DT_ETA: 0,     DT_SRD: 0, },
      { SN: 170, AREA: 'TOPSIDE', ZONE: 'NORTH',   LEVEL: 'PD',  SVG: 'PD',  ID: 46, BLK: 'ND120', ALIGN: 'start', TAG: '101720-SFE-13008',       DESC: 'FIRE EXTINGUISHER (9.1 KG DRY CHEMICAL)',                      PO_NO: '',            PO_DATE: undefined,    VENDOR: '',                                 COUNTRY: '',            INCOTERMS: '',    FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: undefined,    SRD: undefined,    INSTALL: '', SHIP_MODE: '',         SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: '',   G_EREC: 'ND120', ER_PS: '2025-09-29', ER_PF: '2026-04-08', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 0,    DT_ETA: 0,     DT_SRD: 0, },
      { SN: 171, AREA: 'TOPSIDE', ZONE: 'NORTH',   LEVEL: 'PD',  SVG: 'PD',  ID: 47, BLK: 'ND120', ALIGN: 'start', TAG: '101720-TQZ-87042',       DESC: 'FOAM HOSE REEL (end FRONT)',                                   PO_NO: '',            PO_DATE: undefined,    VENDOR: '',                                 COUNTRY: '',            INCOTERMS: '',    FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: undefined,    SRD: undefined,    INSTALL: '', SHIP_MODE: '',         SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: '',   G_EREC: 'ND120', ER_PS: '2025-09-29', ER_PF: '2026-04-08', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 0,    DT_ETA: 0,     DT_SRD: 0, },
      { SN: 172, AREA: 'TOPSIDE', ZONE: 'NORTH',   LEVEL: 'PD',  SVG: 'PD',  ID: 48, BLK: 'ND110', ALIGN: 'start', TAG: '101720-SFE-13004',       DESC: 'FIRE EXTINGUISHER (9.1 KG DRY CHEMICAL)',                      PO_NO: '',            PO_DATE: undefined,    VENDOR: '',                                 COUNTRY: '',            INCOTERMS: '',    FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: undefined,    SRD: undefined,    INSTALL: '', SHIP_MODE: '',         SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: '',   G_EREC: 'ND110', ER_PS: '2025-09-22', ER_PF: '2026-04-08', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 0,    DT_ETA: 0,     DT_SRD: 0, },
      { SN: 173, AREA: 'TOPSIDE', ZONE: 'NORTH',   LEVEL: 'PD',  SVG: 'PD',  ID: 49, BLK: 'ND110', ALIGN: 'start', TAG: '101720-SFE-13002',       DESC: 'FIRE EXTINGUISHER (9.1 KG DRY CHEMICAL)',                      PO_NO: '',            PO_DATE: undefined,    VENDOR: '',                                 COUNTRY: '',            INCOTERMS: '',    FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: undefined,    SRD: undefined,    INSTALL: '', SHIP_MODE: '',         SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: '',   G_EREC: 'ND110', ER_PS: '2025-09-22', ER_PF: '2026-04-08', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 0,    DT_ETA: 0,     DT_SRD: 0, },
      { SN: 174, AREA: 'TOPSIDE', ZONE: 'NORTH',   LEVEL: 'PD',  SVG: 'PD',  ID: 50, BLK: 'ND110', ALIGN: 'start', TAG: '101720-SFE-13003',       DESC: 'FIRE EXTINGUISHER (6.8 KG CO2)',                               PO_NO: '',            PO_DATE: undefined,    VENDOR: '',                                 COUNTRY: '',            INCOTERMS: '',    FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: undefined,    SRD: undefined,    INSTALL: '', SHIP_MODE: '',         SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: '',   G_EREC: 'ND110', ER_PS: '2025-09-22', ER_PF: '2026-04-08', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 0,    DT_ETA: 0,     DT_SRD: 0, },
      { SN: 175, AREA: 'TOPSIDE', ZONE: 'NORTH',   LEVEL: 'MZ',  SVG: 'MZ',  ID: 1,  BLK: 'ND230', ALIGN: 'start', TAG: '101720-TQZ-87027',       DESC: 'HOSE REEL (end FRONT)',                                        PO_NO: '',            PO_DATE: undefined,    VENDOR: '',                                 COUNTRY: '',            INCOTERMS: '',    FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: undefined,    SRD: undefined,    INSTALL: '', SHIP_MODE: '',         SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: '',   G_EREC: 'ND230', ER_PS: '2025-10-17', ER_PF: '2026-04-08', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 0,    DT_ETA: 0,     DT_SRD: 0, },
      { SN: 176, AREA: 'TOPSIDE', ZONE: 'NORTH',   LEVEL: 'MZ',  SVG: 'MZ',  ID: 2,  BLK: 'ND230', ALIGN: 'start', TAG: '101720-SFE-12004',       DESC: 'FIRE EXTINGUISHER (9.1 KG DRY CHEMICAL)',                      PO_NO: '',            PO_DATE: undefined,    VENDOR: '',                                 COUNTRY: '',            INCOTERMS: '',    FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: undefined,    SRD: undefined,    INSTALL: '', SHIP_MODE: '',         SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: '',   G_EREC: 'ND230', ER_PS: '2025-10-17', ER_PF: '2026-04-08', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 0,    DT_ETA: 0,     DT_SRD: 0, },
      { SN: 177, AREA: 'TOPSIDE', ZONE: 'NORTH',   LEVEL: 'MZ',  SVG: 'MZ',  ID: 3,  BLK: 'ND230', ALIGN: 'start', TAG: '101435-CBA-14500',       DESC: 'NA',                                                           PO_NO: '',            PO_DATE: undefined,    VENDOR: '',                                 COUNTRY: '',            INCOTERMS: '',    FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: undefined,    SRD: undefined,    INSTALL: '', SHIP_MODE: '',         SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: '',   G_EREC: 'ND230', ER_PS: '2025-10-17', ER_PF: '2026-04-08', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 0,    DT_ETA: 0,     DT_SRD: 0, },
      { SN: 178, AREA: 'TOPSIDE', ZONE: 'NORTH',   LEVEL: 'MZ',  SVG: 'MZ',  ID: 4,  BLK: 'ND230', ALIGN: 'start', TAG: '101230-HAP-12620',       DESC: 'GIC DISCHARGE COOLER',                                         PO_NO: 'QE557BCQ6A',  PO_DATE: '2024-04-24', VENDOR: 'Heatric',                          COUNTRY: 'UK',          INCOTERMS: 'FOB', FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: '2025-09-07', SRD: '2025-10-03', INSTALL: '', SHIP_MODE: 'NA',       SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: 'NA', G_EREC: 'ND230', ER_PS: '2025-10-17', ER_PF: '2026-04-08', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 26,  DT_ETA: 40,   DT_SRD: 14, },
      { SN: 179, AREA: 'TOPSIDE', ZONE: 'NORTH',   LEVEL: 'MZ',  SVG: 'MZ',  ID: 5,  BLK: 'ND230', ALIGN: 'start', TAG: '101230-HBG-12030',       DESC: 'VRC SUCTION COOLER',                                           PO_NO: 'QU557BRQ68',  PO_DATE: '2024-03-28', VENDOR: 'Wooyang HC',                       COUNTRY: 'Korea',       INCOTERMS: 'DAP', FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: '2025-05-01', SRD: '2025-10-03', INSTALL: '', SHIP_MODE: 'by Truck', SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: 'NA', G_EREC: 'ND230', ER_PS: '2025-10-17', ER_PF: '2026-04-08', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 155, DT_ETA: 169,  DT_SRD: 14, },
      { SN: 180, AREA: 'TOPSIDE', ZONE: 'NORTH',   LEVEL: 'MZ',  SVG: 'MZ',  ID: 6,  BLK: 'ND220', ALIGN: 'start', TAG: '101720-CPS-12001',       DESC: 'CHEMICAL PERSONNEL PROTECTION STORAGE BOX',                    PO_NO: '',            PO_DATE: undefined,    VENDOR: '',                                 COUNTRY: '',            INCOTERMS: '',    FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: undefined,    SRD: undefined,    INSTALL: '', SHIP_MODE: '',         SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: '',   G_EREC: 'ND220', ER_PS: '2025-10-14', ER_PF: '2026-04-08', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 0,    DT_ETA: 0,     DT_SRD: 0, },
      { SN: 181, AREA: 'TOPSIDE', ZONE: 'NORTH',   LEVEL: 'MZ',  SVG: 'MZ',  ID: 7,  BLK: 'ND210', ALIGN: 'end',   TAG: '101720-SFE-12001',       DESC: 'FIRE EXTINGUISHER (9.1 KG DRY CHEMICAL)',                      PO_NO: '',            PO_DATE: undefined,    VENDOR: '',                                 COUNTRY: '',            INCOTERMS: '',    FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: undefined,    SRD: undefined,    INSTALL: '', SHIP_MODE: '',         SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: '',   G_EREC: 'ND210', ER_PS: '2025-10-15', ER_PF: '2026-04-08', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 0,    DT_ETA: 0,     DT_SRD: 0, },
      { SN: 182, AREA: 'TOPSIDE', ZONE: 'NORTH',   LEVEL: 'MZ',  SVG: 'MZ',  ID: 8,  BLK: 'ND210', ALIGN: 'end',   TAG: '101720-TQZ-87030',       DESC: 'HOSE REEL (end REAR)',                                         PO_NO: '',            PO_DATE: undefined,    VENDOR: '',                                 COUNTRY: '',            INCOTERMS: '',    FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: undefined,    SRD: undefined,    INSTALL: '', SHIP_MODE: '',         SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: '',   G_EREC: 'ND210', ER_PS: '2025-10-15', ER_PF: '2026-04-08', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 0,    DT_ETA: 0,     DT_SRD: 0, },
      { SN: 183, AREA: 'TOPSIDE', ZONE: 'NORTH',   LEVEL: 'MZ',  SVG: 'MZ',  ID: 9,  BLK: 'ND230', ALIGN: 'end',   TAG: '101720-SFE-12002',       DESC: 'FIRE EXTINGUISHER (9.1 KG DRY CHEMICAL)',                      PO_NO: '',            PO_DATE: undefined,    VENDOR: '',                                 COUNTRY: '',            INCOTERMS: '',    FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: undefined,    SRD: undefined,    INSTALL: '', SHIP_MODE: '',         SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: '',   G_EREC: 'ND230', ER_PS: '2025-10-17', ER_PF: '2026-04-08', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 0,    DT_ETA: 0,     DT_SRD: 0, },
      { SN: 184, AREA: 'TOPSIDE', ZONE: 'NORTH',   LEVEL: 'MZ',  SVG: 'MZ',  ID: 10, BLK: 'ND230', ALIGN: 'end',   TAG: '101720-TQZ-87029',       DESC: 'HOSE REEL (end FRONT)',                                        PO_NO: '',            PO_DATE: undefined,    VENDOR: '',                                 COUNTRY: '',            INCOTERMS: '',    FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: undefined,    SRD: undefined,    INSTALL: '', SHIP_MODE: '',         SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: '',   G_EREC: 'ND230', ER_PS: '2025-10-17', ER_PF: '2026-04-08', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 0,    DT_ETA: 0,     DT_SRD: 0, },
      { SN: 185, AREA: 'TOPSIDE', ZONE: 'NORTH',   LEVEL: 'MZ',  SVG: 'MZ',  ID: 11, BLK: 'ND230', ALIGN: 'end',   TAG: '101230-HBG-12140',       DESC: 'FGC SUCTION COOLER',                                           PO_NO: 'QU557BRQ68',  PO_DATE: '2024-03-28', VENDOR: 'Wooyang HC',                       COUNTRY: 'Korea',       INCOTERMS: 'DAP', FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: '2025-05-01', SRD: '2025-10-03', INSTALL: '', SHIP_MODE: 'by Truck', SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: 'NA', G_EREC: 'ND230', ER_PS: '2025-10-17', ER_PF: '2026-04-08', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 155, DT_ETA: 169,  DT_SRD: 14, },
      { SN: 186, AREA: 'TOPSIDE', ZONE: 'NORTH',   LEVEL: 'MZ',  SVG: 'MZ',  ID: 12, BLK: 'ND230', ALIGN: 'end',   TAG: '101720-SFE-12003',       DESC: 'FIRE EXTINGUISHER (9.1 KG DRY CHEMICAL)',                      PO_NO: '',            PO_DATE: undefined,    VENDOR: '',                                 COUNTRY: '',            INCOTERMS: '',    FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: undefined,    SRD: undefined,    INSTALL: '', SHIP_MODE: '',         SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: '',   G_EREC: 'ND230', ER_PS: '2025-10-17', ER_PF: '2026-04-08', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 0,    DT_ETA: 0,     DT_SRD: 0, },
      { SN: 187, AREA: 'TOPSIDE', ZONE: 'NORTH',   LEVEL: 'MD',  SVG: 'MD',  ID: 1,  BLK: 'ND330', ALIGN: 'start', TAG: '101230-TZB-12511',       DESC: 'OIL MIST ELIMINATOR',                                          PO_NO: '',            PO_DATE: undefined,    VENDOR: '',                                 COUNTRY: '',            INCOTERMS: '',    FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: undefined,    SRD: undefined,    INSTALL: '', SHIP_MODE: '',         SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: '',   G_EREC: 'ND330', ER_PS: '2026-01-12', ER_PF: '2026-04-08', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 0,    DT_ETA: 0,     DT_SRD: 0, },
      { SN: 188, AREA: 'TOPSIDE', ZONE: 'NORTH',   LEVEL: 'MD',  SVG: 'MD',  ID: 2,  BLK: 'ND330', ALIGN: 'start', TAG: '101230-TZB-13420',       DESC: 'OIL MIST ELIMINATOR',                                          PO_NO: '',            PO_DATE: undefined,    VENDOR: '',                                 COUNTRY: '',            INCOTERMS: '',    FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: undefined,    SRD: undefined,    INSTALL: '', SHIP_MODE: '',         SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: '',   G_EREC: 'ND330', ER_PS: '2026-01-12', ER_PF: '2026-04-08', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 0,    DT_ETA: 0,     DT_SRD: 0, },
      { SN: 189, AREA: 'TOPSIDE', ZONE: 'NORTH',   LEVEL: 'MD',  SVG: 'MD',  ID: 3,  BLK: 'ND330', ALIGN: 'start', TAG: '101230-ZZZ-13300',       DESC: 'VRC / FGC SKID',                                               PO_NO: 'QE557BDQ02',  PO_DATE: '2024-03-15', VENDOR: 'Nuovo Pignone International',      COUNTRY: 'Italy',       INCOTERMS: 'FOB', FAT_PLAN: '2025-07-07', FAT_FC: undefined,    FAT_ACT: undefined,    ETA: '2025-11-22', SRD: '2025-12-29', INSTALL: '', SHIP_MODE: 'by Sea',   SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: 'NA', G_EREC: 'ND330', ER_PS: '2026-01-12', ER_PF: '2026-04-08', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 37,  DT_ETA: 51,   DT_SRD: 14, },
      { SN: 190, AREA: 'TOPSIDE', ZONE: 'NORTH',   LEVEL: 'MD',  SVG: 'MD',  ID: 4,  BLK: 'ND310', ALIGN: 'start', TAG: '101230-ZZZ-12400',       DESC: 'MAIN GAS COMPRESSOR (MGC) SKID 2',                             PO_NO: 'Q557PM001',   PO_DATE: '2023-07-01', VENDOR: 'BakerHughes',                      COUNTRY: 'Italy',       INCOTERMS: 'FOB', FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: '2025-11-08', SRD: undefined,    INSTALL: '', SHIP_MODE: 'NA',       SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: 'NA', G_EREC: 'ND310', ER_PS: '2026-01-07', ER_PF: '2026-04-08', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 0,    DT_ETA: 60,   DT_SRD: 0, },
      { SN: 191, AREA: 'TOPSIDE', ZONE: 'NORTH',   LEVEL: 'MD',  SVG: 'MD',  ID: 5,  BLK: 'ND310', ALIGN: 'start', TAG: '101720-SFE-11008',       DESC: 'FIRE EXTINGUISHER (6.8 KG CO2)',                               PO_NO: '',            PO_DATE: undefined,    VENDOR: '',                                 COUNTRY: '',            INCOTERMS: '',    FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: undefined,    SRD: undefined,    INSTALL: '', SHIP_MODE: '',         SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: '',   G_EREC: 'ND310', ER_PS: '2026-01-07', ER_PF: '2026-04-08', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 0,    DT_ETA: 0,     DT_SRD: 0, },
      { SN: 192, AREA: 'TOPSIDE', ZONE: 'NORTH',   LEVEL: 'MD',  SVG: 'MD',  ID: 6,  BLK: 'ND310', ALIGN: 'start', TAG: '101230-MBF-12460',       DESC: 'GEC SUCTION SCRUBBER',                                         PO_NO: 'QU557BCQ9C',  PO_DATE: '2024-04-01', VENDOR: 'Wooyang HC',                       COUNTRY: 'Korea',       INCOTERMS: 'DAP', FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: '2025-05-08', SRD: '2025-12-24', INSTALL: '', SHIP_MODE: 'NA',       SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: 'NA', G_EREC: 'ND310', ER_PS: '2026-01-07', ER_PF: '2026-04-08', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 230, DT_ETA: 244,  DT_SRD: 14, },
      { SN: 193, AREA: 'TOPSIDE', ZONE: 'NORTH',   LEVEL: 'MD',  SVG: 'MD',  ID: 7,  BLK: 'ND310', ALIGN: 'start', TAG: '101230-MBF-12430',       DESC: 'MGC SUCTION SCRUBBER - TRAIN 2',                               PO_NO: 'QU557BCQ9C',  PO_DATE: '2024-04-01', VENDOR: 'Wooyang HC',                       COUNTRY: 'Korea',       INCOTERMS: 'DAP', FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: '2025-05-08', SRD: '2025-12-24', INSTALL: '', SHIP_MODE: 'NA',       SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: 'NA', G_EREC: 'ND310', ER_PS: '2026-01-07', ER_PF: '2026-04-08', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 230, DT_ETA: 244,  DT_SRD: 14, },
      { SN: 194, AREA: 'TOPSIDE', ZONE: 'NORTH',   LEVEL: 'MD',  SVG: 'MD',  ID: 8,  BLK: 'ND310', ALIGN: 'start', TAG: '101720-SLBY-11001',      DESC: 'LIFE RING BUOY',                                               PO_NO: '',            PO_DATE: undefined,    VENDOR: '',                                 COUNTRY: '',            INCOTERMS: '',    FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: undefined,    SRD: undefined,    INSTALL: '', SHIP_MODE: '',         SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: '',   G_EREC: 'ND310', ER_PS: '2026-01-07', ER_PF: '2026-04-08', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 0,    DT_ETA: 0,     DT_SRD: 0, },
      { SN: 195, AREA: 'TOPSIDE', ZONE: 'NORTH',   LEVEL: 'MD',  SVG: 'MD',  ID: 9,  BLK: 'ND310', ALIGN: 'start', TAG: '101720-SFE-11007',       DESC: 'FIRE EXTINGUISHER (9.1 KG DRY CHEMICAL)',                      PO_NO: '',            PO_DATE: undefined,    VENDOR: '',                                 COUNTRY: '',            INCOTERMS: '',    FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: undefined,    SRD: undefined,    INSTALL: '', SHIP_MODE: '',         SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: '',   G_EREC: 'ND310', ER_PS: '2026-01-07', ER_PF: '2026-04-08', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 0,    DT_ETA: 0,     DT_SRD: 0, },
      { SN: 196, AREA: 'TOPSIDE', ZONE: 'NORTH',   LEVEL: 'MD',  SVG: 'MD',  ID: 10, BLK: 'ND310', ALIGN: 'end',   TAG: '101230-MBF-12360',       DESC: 'BC SUCTION SCRUBBER',                                          PO_NO: 'QU557BCQ9C',  PO_DATE: '2024-04-01', VENDOR: 'Wooyang HC',                       COUNTRY: 'Korea',       INCOTERMS: 'DAP', FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: '2025-05-08', SRD: '2025-12-24', INSTALL: '', SHIP_MODE: 'NA',       SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: 'NA', G_EREC: 'ND310', ER_PS: '2026-01-07', ER_PF: '2026-04-08', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 230, DT_ETA: 244,  DT_SRD: 14, },
      { SN: 197, AREA: 'TOPSIDE', ZONE: 'NORTH',   LEVEL: 'MD',  SVG: 'MD',  ID: 11, BLK: 'ND310', ALIGN: 'end',   TAG: '101230-MBF-12330',       DESC: 'MGC SUCTION SCRUBBER - TRAIN 1',                               PO_NO: 'QU557BCQ9C',  PO_DATE: '2024-04-01', VENDOR: 'Wooyang HC',                       COUNTRY: 'Korea',       INCOTERMS: 'DAP', FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: '2025-05-08', SRD: '2025-12-24', INSTALL: '', SHIP_MODE: 'NA',       SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: 'NA', G_EREC: 'ND310', ER_PS: '2026-01-07', ER_PF: '2026-04-08', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 230, DT_ETA: 244,  DT_SRD: 14, },
      { SN: 198, AREA: 'TOPSIDE', ZONE: 'NORTH',   LEVEL: 'MD',  SVG: 'MD',  ID: 12, BLK: 'ND310', ALIGN: 'end',   TAG: '101720-SFE-11006',       DESC: 'FIRE EXTINGUISHER (9.1 KG DRY CHEMICAL)',                      PO_NO: '',            PO_DATE: undefined,    VENDOR: '',                                 COUNTRY: '',            INCOTERMS: '',    FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: undefined,    SRD: undefined,    INSTALL: '', SHIP_MODE: '',         SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: '',   G_EREC: 'ND310', ER_PS: '2026-01-07', ER_PF: '2026-04-08', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 0,    DT_ETA: 0,     DT_SRD: 0, },
      { SN: 199, AREA: 'TOPSIDE', ZONE: 'NORTH',   LEVEL: 'MD',  SVG: 'MD',  ID: 13, BLK: 'ND310', ALIGN: 'end',   TAG: '101720-SFE-11010',       DESC: 'FIRE EXTINGUISHER (6.8 KG CO2)',                               PO_NO: '',            PO_DATE: undefined,    VENDOR: '',                                 COUNTRY: '',            INCOTERMS: '',    FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: undefined,    SRD: undefined,    INSTALL: '', SHIP_MODE: '',         SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: '',   G_EREC: 'ND310', ER_PS: '2026-01-07', ER_PF: '2026-04-08', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 0,    DT_ETA: 0,     DT_SRD: 0, },
      { SN: 200, AREA: 'TOPSIDE', ZONE: 'NORTH',   LEVEL: 'MD',  SVG: 'MD',  ID: 14, BLK: 'ND310', ALIGN: 'end',   TAG: '101720-SLBY-11002',      DESC: 'LIFE RING BUOY',                                               PO_NO: '',            PO_DATE: undefined,    VENDOR: '',                                 COUNTRY: '',            INCOTERMS: '',    FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: undefined,    SRD: undefined,    INSTALL: '', SHIP_MODE: '',         SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: '',   G_EREC: 'ND310', ER_PS: '2026-01-07', ER_PF: '2026-04-08', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 0,    DT_ETA: 0,     DT_SRD: 0, },
      { SN: 201, AREA: 'TOPSIDE', ZONE: 'NORTH',   LEVEL: 'MD',  SVG: 'MD',  ID: 15, BLK: 'ND310', ALIGN: 'end',   TAG: '101230-ZZZ-12300',       DESC: 'MAIN GAS COMPRESSOR (MGC) SKID 1',                             PO_NO: 'Q557PM001',   PO_DATE: '2023-07-01', VENDOR: 'BakerHughes',                      COUNTRY: 'Italy',       INCOTERMS: 'FOB', FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: '2025-11-08', SRD: undefined,    INSTALL: '', SHIP_MODE: 'by Sea',   SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: 'NA', G_EREC: 'ND310', ER_PS: '2026-01-07', ER_PF: '2026-04-08', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 0,    DT_ETA: 60,   DT_SRD: 0, },
      { SN: 202, AREA: 'TOPSIDE', ZONE: 'NORTH',   LEVEL: 'MD',  SVG: 'MD',  ID: 16, BLK: 'ND310', ALIGN: 'end',   TAG: '101720-SFE-11009',       DESC: 'FIRE EXTINGUISHER (9.1 KG DRY CHEMICAL)',                      PO_NO: '',            PO_DATE: undefined,    VENDOR: '',                                 COUNTRY: '',            INCOTERMS: '',    FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: undefined,    SRD: undefined,    INSTALL: '', SHIP_MODE: '',         SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: '',   G_EREC: 'ND310', ER_PS: '2026-01-07', ER_PF: '2026-04-08', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 0,    DT_ETA: 0,     DT_SRD: 0, },
      { SN: 203, AREA: 'TOPSIDE', ZONE: 'NORTH',   LEVEL: 'MD',  SVG: 'MD',  ID: 17, BLK: 'ND330', ALIGN: 'end',   TAG: '101230-ZZZ-12500',       DESC: 'GAS INJECTION COMPRESSOR (GIC) SKID',                          PO_NO: 'Q557PM002',   PO_DATE: '2023-07-01', VENDOR: 'BakerHughes',                      COUNTRY: 'Italy',       INCOTERMS: 'FOB', FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: '2025-11-08', SRD: undefined,    INSTALL: '', SHIP_MODE: 'by Sea',   SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: 'NA', G_EREC: 'ND330', ER_PS: '2026-01-12', ER_PF: '2026-04-08', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 0,    DT_ETA: 65,   DT_SRD: 0, },
      { SN: 204, AREA: 'TOPSIDE', ZONE: 'NORTH',   LEVEL: 'MD',  SVG: 'MD',  ID: 18, BLK: 'ND330', ALIGN: 'end',   TAG: '101720-SLBY-11003',      DESC: 'NA',                                                           PO_NO: '',            PO_DATE: undefined,    VENDOR: '',                                 COUNTRY: '',            INCOTERMS: '',    FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: undefined,    SRD: undefined,    INSTALL: '', SHIP_MODE: '',         SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: '',   G_EREC: 'ND330', ER_PS: '2026-01-12', ER_PF: '2026-04-08', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 0,    DT_ETA: 0,     DT_SRD: 0, },
      { SN: 205, AREA: 'TOPSIDE', ZONE: 'NORTH',   LEVEL: 'MD',  SVG: 'MD',  ID: 19, BLK: 'ND330', ALIGN: 'end',   TAG: '101430-ZZZ-14150',       DESC: 'PROPANE CYLINDER SKID',                                        PO_NO: 'QA557AFQ89',  PO_DATE: '2024-04-29', VENDOR: 'GBA Corona',                       COUNTRY: 'USA',         INCOTERMS: 'FOB', FAT_PLAN: '2025-05-05', FAT_FC: undefined,    FAT_ACT: undefined,    ETA: '2025-07-27', SRD: '2025-12-29', INSTALL: '', SHIP_MODE: 'NA',       SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: 'NA', G_EREC: 'ND330', ER_PS: '2026-01-12', ER_PF: '2026-04-08', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 155, DT_ETA: 169,  DT_SRD: 14, },
      { SN: 206, AREA: 'TOPSIDE', ZONE: 'NORTH',   LEVEL: 'MD',  SVG: 'MD',  ID: 20, BLK: 'ND330', ALIGN: 'end',   TAG: '101430-ZZZ-14200',       DESC: 'FLARE CONTROL SKID',                                           PO_NO: 'QA557AFQ89',  PO_DATE: '2024-04-29', VENDOR: 'GBA Corona',                       COUNTRY: 'USA',         INCOTERMS: 'FOB', FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: '2025-07-27', SRD: '2025-12-29', INSTALL: '', SHIP_MODE: 'NA',       SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: 'NA', G_EREC: 'ND330', ER_PS: '2026-01-12', ER_PF: '2026-04-08', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 155, DT_ETA: 169,  DT_SRD: 14, },
      { SN: 207, AREA: 'TOPSIDE', ZONE: 'NORTH',   LEVEL: 'KD',  SVG: 'KD',  ID: 1,  BLK: 'KD110', ALIGN: 'start', TAG: '101720-SFE-11005',       DESC: 'FIRE EXTINGUISHER (9.1 KG DRY CHEMICAL)',                      PO_NO: '',            PO_DATE: undefined,    VENDOR: '',                                 COUNTRY: '',            INCOTERMS: '',    FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: undefined,    SRD: undefined,    INSTALL: '', SHIP_MODE: '',         SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: '',   G_EREC: 'KD110', ER_PS: '2026-01-20', ER_PF: '2026-04-08', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 0,    DT_ETA: 0,     DT_SRD: 0, },
      { SN: 208, AREA: 'TOPSIDE', ZONE: 'NORTH',   LEVEL: 'KD',  SVG: 'KD',  ID: 2,  BLK: 'KD110', ALIGN: 'start', TAG: '101720-SFE-11002',       DESC: 'FIRE EXTINGUISHER (9.1 KG DRY CHEMICAL)',                      PO_NO: '',            PO_DATE: undefined,    VENDOR: '',                                 COUNTRY: '',            INCOTERMS: '',    FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: undefined,    SRD: undefined,    INSTALL: '', SHIP_MODE: '',         SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: '',   G_EREC: 'KD110', ER_PS: '2026-01-20', ER_PF: '2026-04-08', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 0,    DT_ETA: 0,     DT_SRD: 0, },
      { SN: 209, AREA: 'TOPSIDE', ZONE: 'NORTH',   LEVEL: 'KD',  SVG: 'KD',  ID: 3,  BLK: 'KD110', ALIGN: 'start', TAG: '101230-HBG-12440',       DESC: 'MGC DISCHARGE COOLER - TRAIN 2',                               PO_NO: 'QU557BRQ68',  PO_DATE: '2024-03-28', VENDOR: 'Wooyang HC',                       COUNTRY: 'Korea',       INCOTERMS: 'DAP', FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: '2025-05-01', SRD: '2026-01-06', INSTALL: '', SHIP_MODE: 'by Truck', SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: 'NA', G_EREC: 'KD110', ER_PS: '2026-01-20', ER_PF: '2026-04-08', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 250, DT_ETA: 264,  DT_SRD: 14, },
      { SN: 210, AREA: 'TOPSIDE', ZONE: 'NORTH',   LEVEL: 'KD',  SVG: 'KD',  ID: 4,  BLK: 'KD110', ALIGN: 'start', TAG: '101230-HAP-12470',       DESC: 'GEC DISCHARGE COOLER',                                         PO_NO: 'QE557BCQ6A',  PO_DATE: '2024-04-24', VENDOR: 'Heatric',                          COUNTRY: 'UK',          INCOTERMS: 'FOB', FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: '2025-09-07', SRD: '2026-01-06', INSTALL: '', SHIP_MODE: 'NA',       SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: 'NA', G_EREC: 'KD110', ER_PS: '2026-01-20', ER_PF: '2026-04-08', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 121, DT_ETA: 135,  DT_SRD: 14, },
      { SN: 211, AREA: 'TOPSIDE', ZONE: 'NORTH',   LEVEL: 'KD',  SVG: 'KD',  ID: 5,  BLK: 'KD110', ALIGN: 'start', TAG: '101720-TQZ-87022',       DESC: 'HOSE REEL (end FRONT)',                                        PO_NO: '',            PO_DATE: undefined,    VENDOR: '',                                 COUNTRY: '',            INCOTERMS: '',    FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: undefined,    SRD: undefined,    INSTALL: '', SHIP_MODE: '',         SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: '',   G_EREC: 'KD110', ER_PS: '2026-01-20', ER_PF: '2026-04-08', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 0,    DT_ETA: 0,     DT_SRD: 0, },
      { SN: 212, AREA: 'TOPSIDE', ZONE: 'NORTH',   LEVEL: 'KD',  SVG: 'KD',  ID: 6,  BLK: 'KD110', ALIGN: 'start', TAG: '101720-SFE-21001',       DESC: 'FIRE EXTINGUISHER (9.1 KG DRY CHEMICAL)',                      PO_NO: '',            PO_DATE: undefined,    VENDOR: '',                                 COUNTRY: '',            INCOTERMS: '',    FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: undefined,    SRD: undefined,    INSTALL: '', SHIP_MODE: '',         SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: '',   G_EREC: 'KD110', ER_PS: '2026-01-20', ER_PF: '2026-04-08', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 0,    DT_ETA: 0,     DT_SRD: 0, },
      { SN: 213, AREA: 'TOPSIDE', ZONE: 'NORTH',   LEVEL: 'KD',  SVG: 'KD',  ID: 7,  BLK: 'KD110', ALIGN: 'start', TAG: '101720-SFE-11004',       DESC: 'FIRE EXTINGUISHER (9.1 KG DRY CHEMICAL)',                      PO_NO: '',            PO_DATE: undefined,    VENDOR: '',                                 COUNTRY: '',            INCOTERMS: '',    FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: undefined,    SRD: undefined,    INSTALL: '', SHIP_MODE: '',         SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: '',   G_EREC: 'KD110', ER_PS: '2026-01-20', ER_PF: '2026-04-08', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 0,    DT_ETA: 0,     DT_SRD: 0, },
      { SN: 214, AREA: 'TOPSIDE', ZONE: 'NORTH',   LEVEL: 'KD',  SVG: 'KD',  ID: 8,  BLK: 'KD110', ALIGN: 'end',   TAG: '101230-HBG-12340',       DESC: 'MGC DISCHARGE COOLER - TRAIN 1',                               PO_NO: 'QU557BRQ68',  PO_DATE: '2024-03-28', VENDOR: 'Wooyang HC',                       COUNTRY: 'Korea',       INCOTERMS: 'DAP', FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: '2025-05-01', SRD: '2026-01-06', INSTALL: '', SHIP_MODE: 'by Truck', SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: 'NA', G_EREC: 'KD110', ER_PS: '2026-01-20', ER_PF: '2026-04-08', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 250, DT_ETA: 264,  DT_SRD: 14, },
      { SN: 215, AREA: 'TOPSIDE', ZONE: 'NORTH',   LEVEL: 'KD',  SVG: 'KD',  ID: 9,  BLK: 'KD110', ALIGN: 'end',   TAG: '101230-HAP-12370',       DESC: 'BC DISCHARGE COOLER',                                          PO_NO: 'QE557BCQ6A',  PO_DATE: '2024-04-24', VENDOR: 'Heatric',                          COUNTRY: 'UK',          INCOTERMS: 'FOB', FAT_PLAN: '2025-05-02', FAT_FC: undefined,    FAT_ACT: undefined,    ETA: '2025-09-07', SRD: '2026-01-06', INSTALL: '', SHIP_MODE: 'NA',       SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: 'NA', G_EREC: 'KD110', ER_PS: '2026-01-20', ER_PF: '2026-04-08', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 121, DT_ETA: 135,  DT_SRD: 14, },
      { SN: 216, AREA: 'TOPSIDE', ZONE: 'NORTH',   LEVEL: 'KD',  SVG: 'KD',  ID: 10, BLK: 'KD110', ALIGN: 'end',   TAG: '101720-TQZ-87020',       DESC: 'HOSE REEL (end FRONT)',                                        PO_NO: '',            PO_DATE: undefined,    VENDOR: '',                                 COUNTRY: '',            INCOTERMS: '',    FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: undefined,    SRD: undefined,    INSTALL: '', SHIP_MODE: '',         SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: '',   G_EREC: 'KD110', ER_PS: '2026-01-20', ER_PF: '2026-04-08', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 0,    DT_ETA: 0,     DT_SRD: 0, },
      { SN: 217, AREA: 'TOPSIDE', ZONE: 'NORTH',   LEVEL: 'KD',  SVG: 'KD',  ID: 11, BLK: 'KD110', ALIGN: 'end',   TAG: '101720-SFE-11003',       DESC: 'FIRE EXTINGUISHER (9.1 KG DRY CHEMICAL)',                      PO_NO: '',            PO_DATE: undefined,    VENDOR: '',                                 COUNTRY: '',            INCOTERMS: '',    FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: undefined,    SRD: undefined,    INSTALL: '', SHIP_MODE: '',         SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: '',   G_EREC: 'KD110', ER_PS: '2026-01-20', ER_PF: '2026-04-08', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 0,    DT_ETA: 0,     DT_SRD: 0, },
      { SN: 218, AREA: 'TOPSIDE', ZONE: 'NORTH',   LEVEL: 'KD',  SVG: 'KD',  ID: 12, BLK: 'KD110', ALIGN: 'end',   TAG: '101720-TQZ-87019',       DESC: 'HOSE REEL (start FRONT)',                                      PO_NO: '',            PO_DATE: undefined,    VENDOR: '',                                 COUNTRY: '',            INCOTERMS: '',    FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: undefined,    SRD: undefined,    INSTALL: '', SHIP_MODE: '',         SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: '',   G_EREC: 'KD110', ER_PS: '2026-01-20', ER_PF: '2026-04-08', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 0,    DT_ETA: 0,     DT_SRD: 0, },
      { SN: 219, AREA: 'TOPSIDE', ZONE: 'NORTH',   LEVEL: 'KD',  SVG: 'KD',  ID: 13, BLK: 'KD110', ALIGN: 'end',   TAG: '101720-SFE-11001',       DESC: 'FIRE EXTINGUISHER (9.1 KG DRY CHEMICAL)',                      PO_NO: '',            PO_DATE: undefined,    VENDOR: '',                                 COUNTRY: '',            INCOTERMS: '',    FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: undefined,    SRD: undefined,    INSTALL: '', SHIP_MODE: '',         SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: '',   G_EREC: 'KD110', ER_PS: '2026-01-20', ER_PF: '2026-04-08', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 0,    DT_ETA: 0,     DT_SRD: 0, },
      { SN: 220, AREA: 'TOPSIDE', ZONE: 'NORTH',   LEVEL: 'KD',  SVG: 'KD',  ID: 14, BLK: 'KD110', ALIGN: 'end',   TAG: '101230-TZB-12311',       DESC: 'OIL MIST ELIMINATOR',                                          PO_NO: '',            PO_DATE: undefined,    VENDOR: '',                                 COUNTRY: '',            INCOTERMS: '',    FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: undefined,    SRD: undefined,    INSTALL: '', SHIP_MODE: '',         SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: '',   G_EREC: 'KD110', ER_PS: '2026-01-20', ER_PF: '2026-04-08', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 0,    DT_ETA: 0,     DT_SRD: 0, },
      { SN: 221, AREA: 'TOPSIDE', ZONE: 'NORTH',   LEVEL: 'KD',  SVG: 'KD',  ID: 15, BLK: 'KD110', ALIGN: 'end',   TAG: '101720-TQZ-87018',       DESC: 'HOSE REEL (start FRONT)',                                      PO_NO: '',            PO_DATE: undefined,    VENDOR: '',                                 COUNTRY: '',            INCOTERMS: '',    FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: undefined,    SRD: undefined,    INSTALL: '', SHIP_MODE: '',         SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: '',   G_EREC: 'KD110', ER_PS: '2026-01-20', ER_PF: '2026-04-08', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 0,    DT_ETA: 0,     DT_SRD: 0, },
      { SN: 222, AREA: 'TOPSIDE', ZONE: 'NORTH',   LEVEL: 'KD',  SVG: 'KD',  ID: 16, BLK: 'KD110', ALIGN: 'end',   TAG: '101230-HBG-12420',       DESC: 'MGC SUCTION COOLER - TRAIN 2',                                 PO_NO: 'QU557BRQ68',  PO_DATE: '2024-03-28', VENDOR: 'Wooyang HC',                       COUNTRY: 'Korea',       INCOTERMS: 'DAP', FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: '2025-05-01', SRD: '2026-01-06', INSTALL: '', SHIP_MODE: 'by Truck', SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: 'NA', G_EREC: 'KD110', ER_PS: '2026-01-20', ER_PF: '2026-04-08', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 250, DT_ETA: 264,  DT_SRD: 14, },
      { SN: 223, AREA: 'TOPSIDE', ZONE: 'NORTH',   LEVEL: 'KD',  SVG: 'KD',  ID: 17, BLK: 'KD110', ALIGN: 'end',   TAG: '101230-HBG-12320',       DESC: 'MGC SUCTION COOLER - TRAIN 1',                                 PO_NO: 'QU557BRQ68',  PO_DATE: '2024-03-28', VENDOR: 'Wooyang HC',                       COUNTRY: 'Korea',       INCOTERMS: 'DAP', FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: '2025-05-01', SRD: '2026-01-06', INSTALL: '', SHIP_MODE: 'by Truck', SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: 'NA', G_EREC: 'KD110', ER_PS: '2026-01-20', ER_PF: '2026-04-08', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 250, DT_ETA: 264,  DT_SRD: 14, },
      { SN: 224, AREA: 'TOPSIDE', ZONE: 'NORTH',   LEVEL: 'KD',  SVG: 'KD',  ID: 18, BLK: 'KD110', ALIGN: 'end',   TAG: '101230-TZB-12411',       DESC: 'OIL MIST ELIMINATOR',                                          PO_NO: '',            PO_DATE: undefined,    VENDOR: '',                                 COUNTRY: '',            INCOTERMS: '',    FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: undefined,    SRD: undefined,    INSTALL: '', SHIP_MODE: '',         SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: '',   G_EREC: 'KD110', ER_PS: '2026-01-20', ER_PF: '2026-04-08', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 0,    DT_ETA: 0,     DT_SRD: 0, },
      { SN: 225, AREA: 'TOPSIDE', ZONE: 'SOUTH',   LEVEL: 'PD',  SVG: 'PD',  ID: 1,  BLK: 'SD120', ALIGN: 'start', TAG: '101720-SFE-33006',       DESC: 'FIRE EXTINGUISHER (9.1 KG DRY CHEMICAL)',                      PO_NO: '',            PO_DATE: undefined,    VENDOR: '',                                 COUNTRY: '',            INCOTERMS: '',    FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: undefined,    SRD: undefined,    INSTALL: '', SHIP_MODE: '',         SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: '',   G_EREC: 'SD120', ER_PS: '2025-08-29', ER_PF: '2026-03-25', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 0,    DT_ETA: 0,     DT_SRD: 0, },
      { SN: 226, AREA: 'TOPSIDE', ZONE: 'SOUTH',   LEVEL: 'PD',  SVG: 'PD',  ID: 2,  BLK: 'SD120', ALIGN: 'start', TAG: '101720-TQZ-87055',       DESC: 'HOSE REEL (end FRONT)',                                        PO_NO: '',            PO_DATE: undefined,    VENDOR: '',                                 COUNTRY: '',            INCOTERMS: '',    FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: undefined,    SRD: undefined,    INSTALL: '', SHIP_MODE: '',         SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: '',   G_EREC: 'SD120', ER_PS: '2025-08-29', ER_PF: '2026-03-25', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 0,    DT_ETA: 0,     DT_SRD: 0, },
      { SN: 227, AREA: 'TOPSIDE', ZONE: 'SOUTH',   LEVEL: 'PD',  SVG: 'PD',  ID: 3,  BLK: 'SD120', ALIGN: 'start', TAG: '101710-ZZZ-53100',       DESC: 'FIREWATER PUMP 1 GENERATOR SKID',                              PO_NO: 'QE557BKQ21',  PO_DATE: '2024-03-11', VENDOR: 'Framo AS',                         COUNTRY: 'Norway',      INCOTERMS: 'FOB', FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: '2025-10-05', SRD: '2025-08-15', INSTALL: '', SHIP_MODE: 'by Sea',   SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: 'NA', G_EREC: 'SD120', ER_PS: '2025-08-29', ER_PF: '2026-03-25', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: -51, DT_ETA: -37,  DT_SRD: 14, },
      { SN: 228, AREA: 'TOPSIDE', ZONE: 'SOUTH',   LEVEL: 'PD',  SVG: 'PD',  ID: 4,  BLK: 'SD120', ALIGN: 'start', TAG: '101400-PBE-40380',       DESC: 'START-UP COOLING MEDIUM CIRCULATION PUMP',                     PO_NO: 'QA557BWMSC',  PO_DATE: '2024-05-23', VENDOR: 'Sulzer Pumps(US)',                 COUNTRY: 'Mexico',      INCOTERMS: 'FOB', FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: '2025-06-23', SRD: '2025-08-15', INSTALL: '', SHIP_MODE: 'by Sea',   SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: 'NA', G_EREC: 'SD120', ER_PS: '2025-08-29', ER_PF: '2026-03-25', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 53,  DT_ETA: 67,   DT_SRD: 14, },
      { SN: 229, AREA: 'TOPSIDE', ZONE: 'SOUTH',   LEVEL: 'PD',  SVG: 'PD',  ID: 5,  BLK: 'SD120', ALIGN: 'start', TAG: '101720-SFE-33004',       DESC: 'FIRE EXTINGUISHER (9.1 KG DRY CHEMICAL)',                      PO_NO: '',            PO_DATE: undefined,    VENDOR: '',                                 COUNTRY: '',            INCOTERMS: '',    FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: undefined,    SRD: undefined,    INSTALL: '', SHIP_MODE: '',         SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: '',   G_EREC: 'SD120', ER_PS: '2025-08-29', ER_PF: '2026-03-25', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 0,    DT_ETA: 0,     DT_SRD: 0, },
      { SN: 230, AREA: 'TOPSIDE', ZONE: 'SOUTH',   LEVEL: 'PD',  SVG: 'PD',  ID: 6,  BLK: 'SD120', ALIGN: 'start', TAG: '101720-TQZ-87054',       DESC: 'HOSE REEL (start FRONT)',                                      PO_NO: '',            PO_DATE: undefined,    VENDOR: '',                                 COUNTRY: '',            INCOTERMS: '',    FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: undefined,    SRD: undefined,    INSTALL: '', SHIP_MODE: '',         SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: '',   G_EREC: 'SD120', ER_PS: '2025-08-29', ER_PF: '2026-03-25', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 0,    DT_ETA: 0,     DT_SRD: 0, },
      { SN: 231, AREA: 'TOPSIDE', ZONE: 'SOUTH',   LEVEL: 'PD',  SVG: 'PD',  ID: 7,  BLK: 'SD120', ALIGN: 'start', TAG: '101290-MAJ-21020',       DESC: 'SEAWATER STRAINER (WI) 2',                                     PO_NO: 'QD557AFQ52',  PO_DATE: '2024-03-28', VENDOR: 'Cameron',                          COUNTRY: 'China',       INCOTERMS: 'FOB', FAT_PLAN: '2025-06-16', FAT_FC: undefined,    FAT_ACT: undefined,    ETA: '2025-07-16', SRD: '2025-08-15', INSTALL: '', SHIP_MODE: 'NA',       SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: 'NA', G_EREC: 'SD120', ER_PS: '2025-08-29', ER_PF: '2026-03-25', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 30,  DT_ETA: 44,   DT_SRD: 14, },
      { SN: 232, AREA: 'TOPSIDE', ZONE: 'SOUTH',   LEVEL: 'PD',  SVG: 'PD',  ID: 8,  BLK: 'SD120', ALIGN: 'start', TAG: '101290-MAJ-21010',       DESC: 'SEAWATER STRAINER (WI) 1',                                     PO_NO: 'QD557AFQ52',  PO_DATE: '2024-03-28', VENDOR: 'Cameron',                          COUNTRY: 'China',       INCOTERMS: 'FOB', FAT_PLAN: '2025-06-16', FAT_FC: undefined,    FAT_ACT: undefined,    ETA: '2025-07-16', SRD: '2025-08-15', INSTALL: '', SHIP_MODE: 'NA',       SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: 'NA', G_EREC: 'SD120', ER_PS: '2025-08-29', ER_PF: '2026-03-25', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 30,  DT_ETA: 44,   DT_SRD: 14, },
      { SN: 233, AREA: 'TOPSIDE', ZONE: 'SOUTH',   LEVEL: 'PD',  SVG: 'PD',  ID: 9,  BLK: 'SD120', ALIGN: 'start', TAG: '101530-ABJ-50140',       DESC: 'DEMIN WATER TANK',                                             PO_NO: 'QU557BCQ99',  PO_DATE: '2024-04-01', VENDOR: 'Decatech',                         COUNTRY: 'Korea',       INCOTERMS: 'DAP', FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: '2025-05-08', SRD: '2025-08-06', INSTALL: '', SHIP_MODE: 'NA',       SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: 'NA', G_EREC: 'SD120', ER_PS: '2025-08-29', ER_PF: '2026-03-25', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 90,  DT_ETA: 113,  DT_SRD: 23, },
      { SN: 234, AREA: 'TOPSIDE', ZONE: 'SOUTH',   LEVEL: 'PD',  SVG: 'PD',  ID: 10, BLK: 'SD120', ALIGN: 'start', TAG: '101530-PBE-50150',       DESC: 'DEMIN WATER PUMP 1',                                           PO_NO: 'QA557BWMSC',  PO_DATE: '2024-05-23', VENDOR: 'Sulzer Pumps(US)',                 COUNTRY: 'Mexico',      INCOTERMS: 'FOB', FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: '2025-06-23', SRD: '2025-08-15', INSTALL: '', SHIP_MODE: 'by Sea',   SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: 'NA', G_EREC: 'SD120', ER_PS: '2025-08-29', ER_PF: '2026-03-25', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 53,  DT_ETA: 67,   DT_SRD: 14, },
      { SN: 235, AREA: 'TOPSIDE', ZONE: 'SOUTH',   LEVEL: 'PD',  SVG: 'PD',  ID: 11, BLK: 'SD120', ALIGN: 'start', TAG: '101530-PBE-50160',       DESC: 'DEMIN WATER PUMP 2',                                           PO_NO: 'QA557BWMSC',  PO_DATE: '2024-05-23', VENDOR: 'Sulzer Pumps(US)',                 COUNTRY: 'Mexico',      INCOTERMS: 'FOB', FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: '2025-06-23', SRD: '2025-08-15', INSTALL: '', SHIP_MODE: 'by Sea',   SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: 'NA', G_EREC: 'SD120', ER_PS: '2025-08-29', ER_PF: '2026-03-25', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 53,  DT_ETA: 67,   DT_SRD: 14, },
      { SN: 236, AREA: 'TOPSIDE', ZONE: 'SOUTH',   LEVEL: 'PD',  SVG: 'PD',  ID: 12, BLK: 'SD120', ALIGN: 'start', TAG: '101410-PBE-40410',       DESC: 'HEATING MEDIUM CIRCULATION PUMP 1',                            PO_NO: 'QA557BWMSC',  PO_DATE: '2024-05-23', VENDOR: 'Sulzer Pumps(US)',                 COUNTRY: 'Mexico',      INCOTERMS: 'FOB', FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: '2025-06-23', SRD: '2025-08-15', INSTALL: '', SHIP_MODE: 'by Sea',   SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: 'NA', G_EREC: 'SD120', ER_PS: '2025-08-29', ER_PF: '2026-03-25', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 53,  DT_ETA: 67,   DT_SRD: 14, },
      { SN: 237, AREA: 'TOPSIDE', ZONE: 'SOUTH',   LEVEL: 'PD',  SVG: 'PD',  ID: 13, BLK: 'SD120', ALIGN: 'start', TAG: '101410-PBE-40420',       DESC: 'HEATING MEDIUM CIRCULATION PUMP 2',                            PO_NO: 'QA557BWMSC',  PO_DATE: '2024-05-23', VENDOR: 'Sulzer Pumps(US)',                 COUNTRY: 'Mexico',      INCOTERMS: 'FOB', FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: '2025-06-23', SRD: '2025-08-15', INSTALL: '', SHIP_MODE: 'by Sea',   SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: 'NA', G_EREC: 'SD120', ER_PS: '2025-08-29', ER_PF: '2026-03-25', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 53,  DT_ETA: 67,   DT_SRD: 14, },
      { SN: 238, AREA: 'TOPSIDE', ZONE: 'SOUTH',   LEVEL: 'PD',  SVG: 'PD',  ID: 14, BLK: 'SD110', ALIGN: 'start', TAG: '101530-ZZZ-50000',       DESC: 'WATER PRESSURE SET',                                           PO_NO: 'QU557BWQ92',  PO_DATE: '2024-04-30', VENDOR: 'Decatech',                         COUNTRY: 'Korea',       INCOTERMS: 'DAP', FAT_PLAN: '2025-07-12', FAT_FC: undefined,    FAT_ACT: undefined,    ETA: '2025-07-30', SRD: '2025-08-06', INSTALL: '', SHIP_MODE: 'NA',       SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: 'NA', G_EREC: 'SD110', ER_PS: '2025-08-20', ER_PF: '2026-03-25', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 7,   DT_ETA: 21,   DT_SRD: 14, },
      { SN: 239, AREA: 'TOPSIDE', ZONE: 'SOUTH',   LEVEL: 'PD',  SVG: 'PD',  ID: 15, BLK: 'SD110', ALIGN: 'start', TAG: '101530-ABJ-50100A',      DESC: 'FRESH WATER DAY TANK A',                                       PO_NO: 'QU557BCQ99',  PO_DATE: '2024-04-01', VENDOR: 'Decatech',                         COUNTRY: 'Korea',       INCOTERMS: 'DAP', FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: '2025-05-08', SRD: '2025-08-06', INSTALL: '', SHIP_MODE: 'NA',       SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: 'NA', G_EREC: 'SD110', ER_PS: '2025-08-20', ER_PF: '2026-03-25', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 90,  DT_ETA: 104,  DT_SRD: 14, },
      { SN: 240, AREA: 'TOPSIDE', ZONE: 'SOUTH',   LEVEL: 'PD',  SVG: 'PD',  ID: 16, BLK: 'SD110', ALIGN: 'start', TAG: '101530-ABJ-50100B',      DESC: 'FRESH WATER DAY TANK B',                                       PO_NO: 'QU557BCQ99',  PO_DATE: '2024-04-01', VENDOR: 'Decatech',                         COUNTRY: 'Korea',       INCOTERMS: 'DAP', FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: '2025-05-08', SRD: '2025-08-06', INSTALL: '', SHIP_MODE: 'NA',       SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: 'NA', G_EREC: 'SD110', ER_PS: '2025-08-20', ER_PF: '2026-03-25', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 90,  DT_ETA: 104,  DT_SRD: 14, },
      { SN: 241, AREA: 'TOPSIDE', ZONE: 'SOUTH',   LEVEL: 'PD',  SVG: 'PD',  ID: 17, BLK: 'SD110', ALIGN: 'start', TAG: '101720-TQZ-87051',       DESC: 'HOSE REEL (start FRONT)',                                      PO_NO: '',            PO_DATE: undefined,    VENDOR: '',                                 COUNTRY: '',            INCOTERMS: '',    FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: undefined,    SRD: undefined,    INSTALL: '', SHIP_MODE: '',         SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: '',   G_EREC: 'SD110', ER_PS: '2025-08-20', ER_PF: '2026-03-25', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 0,    DT_ETA: 0,     DT_SRD: 0, },
      { SN: 242, AREA: 'TOPSIDE', ZONE: 'SOUTH',   LEVEL: 'PD',  SVG: 'PD',  ID: 18, BLK: 'SD110', ALIGN: 'start', TAG: '101650-ZZZ-52100',       DESC: 'SUBSEA HYDRAULIC POWER UNIT',                                  PO_NO: '',            PO_DATE: undefined,    VENDOR: '',                                 COUNTRY: '',            INCOTERMS: '',    FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: undefined,    SRD: undefined,    INSTALL: '', SHIP_MODE: '',         SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: '',   G_EREC: 'SD110', ER_PS: '2025-08-20', ER_PF: '2026-03-25', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 0,    DT_ETA: 0,     DT_SRD: 0, },
      { SN: 243, AREA: 'TOPSIDE', ZONE: 'SOUTH',   LEVEL: 'PD',  SVG: 'PD',  ID: 19, BLK: 'SD110', ALIGN: 'start', TAG: '101720-SFE-33001',       DESC: 'FIRE EXTINGUISHER (9.1 KG DRY CHEMICAL)',                      PO_NO: '',            PO_DATE: undefined,    VENDOR: '',                                 COUNTRY: '',            INCOTERMS: '',    FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: undefined,    SRD: undefined,    INSTALL: '', SHIP_MODE: '',         SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: '',   G_EREC: 'SD110', ER_PS: '2025-08-20', ER_PF: '2026-03-25', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 0,    DT_ETA: 0,     DT_SRD: 0, },
      { SN: 244, AREA: 'TOPSIDE', ZONE: 'SOUTH',   LEVEL: 'PD',  SVG: 'PD',  ID: 20, BLK: 'SD110', ALIGN: 'start', TAG: '101720-WVB-33001',       DESC: 'WORK VEST STORAGE BOX',                                        PO_NO: '',            PO_DATE: undefined,    VENDOR: '',                                 COUNTRY: '',            INCOTERMS: '',    FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: undefined,    SRD: undefined,    INSTALL: '', SHIP_MODE: '',         SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: '',   G_EREC: 'SD110', ER_PS: '2025-08-20', ER_PF: '2026-03-25', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 0,    DT_ETA: 0,     DT_SRD: 0, },
      { SN: 245, AREA: 'TOPSIDE', ZONE: 'SOUTH',   LEVEL: 'PD',  SVG: 'PD',  ID: 21, BLK: 'SD110', ALIGN: 'start', TAG: '101720-TQZ-87050',       DESC: 'HOSE REEL (start FRONT)',                                      PO_NO: '',            PO_DATE: undefined,    VENDOR: '',                                 COUNTRY: '',            INCOTERMS: '',    FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: undefined,    SRD: undefined,    INSTALL: '', SHIP_MODE: '',         SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: '',   G_EREC: 'SD110', ER_PS: '2025-08-20', ER_PF: '2026-03-25', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 0,    DT_ETA: 0,     DT_SRD: 0, },
      { SN: 246, AREA: 'TOPSIDE', ZONE: 'SOUTH',   LEVEL: 'PD',  SVG: 'PD',  ID: 22, BLK: 'SD110', ALIGN: 'end',   TAG: '101640-MBL-51010',       DESC: 'NITROGEN RECEIVER',                                            PO_NO: 'QU557BCQ9B',  PO_DATE: '2024-04-01', VENDOR: 'Wooyang HC',                       COUNTRY: 'Korea',       INCOTERMS: 'DAP', FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: '2025-04-20', SRD: '2025-08-06', INSTALL: '', SHIP_MODE: 'NA',       SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: 'NA', G_EREC: 'SD110', ER_PS: '2025-08-20', ER_PF: '2026-03-25', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 108, DT_ETA: 122,  DT_SRD: 14, },
      { SN: 247, AREA: 'TOPSIDE', ZONE: 'SOUTH',   LEVEL: 'PD',  SVG: 'PD',  ID: 23, BLK: 'SD110', ALIGN: 'end',   TAG: '101640-ZZZ-51000',       DESC: 'NITROGEN GENERATION SKID',                                     PO_NO: 'QU557BRQ06',  PO_DATE: '2024-03-08', VENDOR: 'Gardner Denver Korea',             COUNTRY: 'Korea',       INCOTERMS: 'DAP', FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: '2025-05-31', SRD: '2025-08-06', INSTALL: '', SHIP_MODE: 'by Barge', SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: 'NA', G_EREC: 'SD110', ER_PS: '2025-08-20', ER_PF: '2026-03-25', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 67,  DT_ETA: 81,   DT_SRD: 14, },
      { SN: 248, AREA: 'TOPSIDE', ZONE: 'SOUTH',   LEVEL: 'PD',  SVG: 'PD',  ID: 24, BLK: 'SD110', ALIGN: 'end',   TAG: '101720-SLBY-33001',      DESC: 'LIFE RING BUOY',                                               PO_NO: '',            PO_DATE: undefined,    VENDOR: '',                                 COUNTRY: '',            INCOTERMS: '',    FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: undefined,    SRD: undefined,    INSTALL: '', SHIP_MODE: '',         SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: '',   G_EREC: 'SD110', ER_PS: '2025-08-20', ER_PF: '2026-03-25', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 0,    DT_ETA: 0,     DT_SRD: 0, },
      { SN: 249, AREA: 'TOPSIDE', ZONE: 'SOUTH',   LEVEL: 'PD',  SVG: 'PD',  ID: 25, BLK: 'SD110', ALIGN: 'end',   TAG: '101720-TQZ-87049',       DESC: 'FOAM HOSE REEL (end FRONT)',                                   PO_NO: '',            PO_DATE: undefined,    VENDOR: '',                                 COUNTRY: '',            INCOTERMS: '',    FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: undefined,    SRD: undefined,    INSTALL: '', SHIP_MODE: '',         SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: '',   G_EREC: 'SD110', ER_PS: '2025-08-20', ER_PF: '2026-03-25', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 0,    DT_ETA: 0,     DT_SRD: 0, },
      { SN: 250, AREA: 'TOPSIDE', ZONE: 'SOUTH',   LEVEL: 'PD',  SVG: 'PD',  ID: 26, BLK: 'SD110', ALIGN: 'end',   TAG: '101640-MBL-51180',       DESC: 'HP NITROGEN RECEIVER',                                         PO_NO: 'QU557BCQ9C',  PO_DATE: '2024-04-01', VENDOR: 'Wooyang HC',                       COUNTRY: 'Korea',       INCOTERMS: 'DAP', FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: '2025-05-08', SRD: '2025-08-15', INSTALL: '', SHIP_MODE: 'NA',       SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: 'NA', G_EREC: 'SD110', ER_PS: '2025-08-20', ER_PF: '2026-03-25', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 99,  DT_ETA: 104,  DT_SRD: 5, },
      { SN: 251, AREA: 'TOPSIDE', ZONE: 'SOUTH',   LEVEL: 'PD',  SVG: 'PD',  ID: 27, BLK: 'SD110', ALIGN: 'end',   TAG: '101800-ZZZ-60400',       DESC: 'AUXILIARY GENERATOR SKID',                                     PO_NO: 'QD557BDQ15',  PO_DATE: '2024-03-25', VENDOR: 'SATOMAS Pte Ltd',                  COUNTRY: 'Singapore',   INCOTERMS: 'FOB', FAT_PLAN: '2025-05-26', FAT_FC: undefined,    FAT_ACT: undefined,    ETA: '2025-08-24', SRD: '2025-08-06', INSTALL: '', SHIP_MODE: 'by Sea',   SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: 'NA', G_EREC: 'SD110', ER_PS: '2025-08-20', ER_PF: '2026-03-25', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: -18, DT_ETA: -4,   DT_SRD: 14, },
      { SN: 252, AREA: 'TOPSIDE', ZONE: 'SOUTH',   LEVEL: 'PD',  SVG: 'PD',  ID: 28, BLK: 'SD120', ALIGN: 'end',   TAG: '101720-SFE-33003',       DESC: 'FIRE EXTINGUISHER (9.1 KG DRY CHEMICAL)',                      PO_NO: '',            PO_DATE: undefined,    VENDOR: '',                                 COUNTRY: '',            INCOTERMS: '',    FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: undefined,    SRD: undefined,    INSTALL: '', SHIP_MODE: '',         SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: '',   G_EREC: 'SD120', ER_PS: '2025-08-29', ER_PF: '2026-03-25', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 0,    DT_ETA: 0,     DT_SRD: 0, },
      { SN: 253, AREA: 'TOPSIDE', ZONE: 'SOUTH',   LEVEL: 'PD',  SVG: 'PD',  ID: 29, BLK: 'SD110', ALIGN: 'end',   TAG: '101500-HAP-40730',       DESC: 'SEAWATER EXCHANGER 3',                                         PO_NO: 'QD557BRQ64',  PO_DATE: '2024-04-24', VENDOR: 'Tranter China',                    COUNTRY: 'China',       INCOTERMS: 'DAP', FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: '2025-03-13', SRD: '2025-08-15', INSTALL: '', SHIP_MODE: 'by Sea',   SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: 'NA', G_EREC: 'SD110', ER_PS: '2025-08-20', ER_PF: '2026-03-25', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 155, DT_ETA: 160,  DT_SRD: 5, },
      { SN: 254, AREA: 'TOPSIDE', ZONE: 'SOUTH',   LEVEL: 'PD',  SVG: 'PD',  ID: 30, BLK: 'SD110', ALIGN: 'end',   TAG: '101500-HAP-40720',       DESC: 'SEAWATER EXCHANGER 2',                                         PO_NO: 'QD557BRQ64',  PO_DATE: '2024-04-24', VENDOR: 'Tranter China',                    COUNTRY: 'China',       INCOTERMS: 'DAP', FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: '2025-03-13', SRD: '2025-08-15', INSTALL: '', SHIP_MODE: 'by Sea',   SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: 'NA', G_EREC: 'SD110', ER_PS: '2025-08-20', ER_PF: '2026-03-25', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 155, DT_ETA: 160,  DT_SRD: 5, },
      { SN: 255, AREA: 'TOPSIDE', ZONE: 'SOUTH',   LEVEL: 'PD',  SVG: 'PD',  ID: 31, BLK: 'SD110', ALIGN: 'end',   TAG: '101500-HAP-40710',       DESC: 'SEAWATER EXCHANGER 1',                                         PO_NO: 'QD557BRQ64',  PO_DATE: '2024-04-24', VENDOR: 'Tranter China',                    COUNTRY: 'China',       INCOTERMS: 'DAP', FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: '2025-03-13', SRD: '2025-08-15', INSTALL: '', SHIP_MODE: 'by Sea',   SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: 'NA', G_EREC: 'SD110', ER_PS: '2025-08-20', ER_PF: '2026-03-25', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 155, DT_ETA: 160,  DT_SRD: 5, },
      { SN: 256, AREA: 'TOPSIDE', ZONE: 'SOUTH',   LEVEL: 'PD',  SVG: 'PD',  ID: 32, BLK: 'SD120', ALIGN: 'end',   TAG: '101400-MAJ-40350',       DESC: 'COOLING MEDIUM FILTER',                                        PO_NO: 'QU557AFQ49',  PO_DATE: '2024-03-08', VENDOR: 'Parker Korea',                     COUNTRY: 'Korea',       INCOTERMS: 'DAP', FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: '2025-03-15', SRD: '2025-08-15', INSTALL: '', SHIP_MODE: 'NA',       SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: 'NA', G_EREC: 'SD120', ER_PS: '2025-08-29', ER_PF: '2026-03-25', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 153, DT_ETA: 167,  DT_SRD: 14, },
      { SN: 257, AREA: 'TOPSIDE', ZONE: 'SOUTH',   LEVEL: 'PD',  SVG: 'PD',  ID: 33, BLK: 'SD120', ALIGN: 'end',   TAG: '101500-MAJ-40630',       DESC: 'SEAWATER STRAINER (CW) 1',                                     PO_NO: 'QE557AFQ4A',  PO_DATE: '2024-04-01', VENDOR: 'Filtrex',                          COUNTRY: 'Italy',       INCOTERMS: 'FOB', FAT_PLAN: '2025-04-24', FAT_FC: undefined,    FAT_ACT: undefined,    ETA: '2025-07-29', SRD: '2025-08-15', INSTALL: '', SHIP_MODE: 'NA',       SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: 'NA', G_EREC: 'SD120', ER_PS: '2025-08-29', ER_PF: '2026-03-25', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 17,  DT_ETA: 31,   DT_SRD: 14, },
      { SN: 258, AREA: 'TOPSIDE', ZONE: 'SOUTH',   LEVEL: 'PD',  SVG: 'PD',  ID: 34, BLK: 'SD120', ALIGN: 'end',   TAG: '101720-TQZ-87053',       DESC: 'HOSE REEL (end FRONT)',                                        PO_NO: '',            PO_DATE: undefined,    VENDOR: '',                                 COUNTRY: '',            INCOTERMS: '',    FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: undefined,    SRD: undefined,    INSTALL: '', SHIP_MODE: '',         SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: '',   G_EREC: 'SD120', ER_PS: '2025-08-29', ER_PF: '2026-03-25', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 0,    DT_ETA: 0,     DT_SRD: 0, },
      { SN: 259, AREA: 'TOPSIDE', ZONE: 'SOUTH',   LEVEL: 'PD',  SVG: 'PD',  ID: 35, BLK: 'SD120', ALIGN: 'end',   TAG: '101500-MAJ-40640',       DESC: 'SEAWATER STRAINER (CW) 2',                                     PO_NO: 'QE557AFQ4A',  PO_DATE: '2024-04-01', VENDOR: 'Filtrex',                          COUNTRY: 'Italy',       INCOTERMS: 'FOB', FAT_PLAN: '2025-04-24', FAT_FC: undefined,    FAT_ACT: undefined,    ETA: '2025-07-29', SRD: '2025-08-15', INSTALL: '', SHIP_MODE: 'NA',       SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: 'NA', G_EREC: 'SD120', ER_PS: '2025-08-29', ER_PF: '2026-03-25', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 17,  DT_ETA: 31,   DT_SRD: 14, },
      { SN: 260, AREA: 'TOPSIDE', ZONE: 'SOUTH',   LEVEL: 'PD',  SVG: 'PD',  ID: 36, BLK: 'SD120', ALIGN: 'end',   TAG: '101720-TQZ-87047',       DESC: 'HOSE REEL (start FRONT)',                                      PO_NO: '',            PO_DATE: undefined,    VENDOR: '',                                 COUNTRY: '',            INCOTERMS: '',    FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: undefined,    SRD: undefined,    INSTALL: '', SHIP_MODE: '',         SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: '',   G_EREC: 'SD120', ER_PS: '2025-08-29', ER_PF: '2026-03-25', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 0,    DT_ETA: 0,     DT_SRD: 0, },
      { SN: 261, AREA: 'TOPSIDE', ZONE: 'SOUTH',   LEVEL: 'PD',  SVG: 'PD',  ID: 37, BLK: 'SD120', ALIGN: 'end',   TAG: '101400-PBE-40310',       DESC: 'COOLING MEDIUM CIRCULATION PUMP 1',                            PO_NO: 'QA557BWMSC',  PO_DATE: '2024-05-23', VENDOR: 'Sulzer Pumps(US)',                 COUNTRY: 'Mexico',      INCOTERMS: 'FOB', FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: '2025-06-23', SRD: '2025-08-15', INSTALL: '', SHIP_MODE: 'by Sea',   SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: 'NA', G_EREC: 'SD120', ER_PS: '2025-08-29', ER_PF: '2026-03-25', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 53,  DT_ETA: 67,   DT_SRD: 14, },
      { SN: 262, AREA: 'TOPSIDE', ZONE: 'SOUTH',   LEVEL: 'PD',  SVG: 'PD',  ID: 38, BLK: 'SD120', ALIGN: 'end',   TAG: '101400-PBE-40320',       DESC: 'COOLING MEDIUM CIRCULATION PUMP 2',                            PO_NO: 'QA557BWMSC',  PO_DATE: '2024-05-23', VENDOR: 'Sulzer Pumps(US)',                 COUNTRY: 'Mexico',      INCOTERMS: 'FOB', FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: '2025-06-23', SRD: '2025-08-15', INSTALL: '', SHIP_MODE: 'by Sea',   SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: 'NA', G_EREC: 'SD120', ER_PS: '2025-08-29', ER_PF: '2026-03-25', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 53,  DT_ETA: 67,   DT_SRD: 14, },
      { SN: 263, AREA: 'TOPSIDE', ZONE: 'SOUTH',   LEVEL: 'PD',  SVG: 'PD',  ID: 39, BLK: 'SD120', ALIGN: 'end',   TAG: '101400-PBE-40330',       DESC: 'COOLING MEDIUM CIRCULATION PUMP 3',                            PO_NO: 'QA557BWMSC',  PO_DATE: '2024-05-23', VENDOR: 'Sulzer Pumps(US)',                 COUNTRY: 'Mexico',      INCOTERMS: 'FOB', FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: '2025-06-23', SRD: '2025-08-15', INSTALL: '', SHIP_MODE: 'by Sea',   SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: 'NA', G_EREC: 'SD120', ER_PS: '2025-08-29', ER_PF: '2026-03-25', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 53,  DT_ETA: 67,   DT_SRD: 14, },
      { SN: 264, AREA: 'TOPSIDE', ZONE: 'SOUTH',   LEVEL: 'PD',  SVG: 'PD',  ID: 40, BLK: 'SD120', ALIGN: 'end',   TAG: '101720-SFE-33005',       DESC: 'FIRE EXTINGUISHER (9.1 KG DRY CHEMICAL)',                      PO_NO: '',            PO_DATE: undefined,    VENDOR: '',                                 COUNTRY: '',            INCOTERMS: '',    FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: undefined,    SRD: undefined,    INSTALL: '', SHIP_MODE: '',         SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: '',   G_EREC: 'SD120', ER_PS: '2025-08-29', ER_PF: '2026-03-25', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 0,    DT_ETA: 0,     DT_SRD: 0, },
      { SN: 265, AREA: 'TOPSIDE', ZONE: 'SOUTH',   LEVEL: 'PD',  SVG: 'PD',  ID: 41, BLK: 'SD120', ALIGN: 'end',   TAG: '101840-ZZZ-60500',       DESC: 'EMERGENCY GENERATOR SKID',                                     PO_NO: 'QD557BDQ15',  PO_DATE: '2024-03-25', VENDOR: 'SATOMAS Pte Ltd',                  COUNTRY: 'Singapore',   INCOTERMS: 'FOB', FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: '2025-08-24', SRD: '2025-08-15', INSTALL: '', SHIP_MODE: 'by Sea',   SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: 'NA', G_EREC: 'SD120', ER_PS: '2025-08-29', ER_PF: '2026-03-25', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: -9,  DT_ETA: 5,    DT_SRD: 14, },
      { SN: 266, AREA: 'TOPSIDE', ZONE: 'SOUTH',   LEVEL: 'PD',  SVG: 'PD',  ID: 42, BLK: 'SD120', ALIGN: 'end',   TAG: '101720-CPS-33002',       DESC: 'CHEMICAL PERSONNEL PROTECTION STORAGE BOX',                    PO_NO: '',            PO_DATE: undefined,    VENDOR: '',                                 COUNTRY: '',            INCOTERMS: '',    FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: undefined,    SRD: undefined,    INSTALL: '', SHIP_MODE: '',         SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: '',   G_EREC: 'SD120', ER_PS: '2025-08-29', ER_PF: '2026-03-25', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 0,    DT_ETA: 0,     DT_SRD: 0, },
      { SN: 267, AREA: 'TOPSIDE', ZONE: 'SOUTH',   LEVEL: 'PD',  SVG: 'PD',  ID: 43, BLK: 'SD120', ALIGN: 'end',   TAG: '101840-EXFMR-62440',     DESC: 'NA',                                                           PO_NO: 'QE557BKE06',  PO_DATE: '2024-04-30', VENDOR: 'ABB AS',                           COUNTRY: 'Norway',      INCOTERMS: 'FOB', FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: '2025-07-09', SRD: '2025-08-30', INSTALL: '', SHIP_MODE: 'NA',       SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: 'NA', G_EREC: 'SD120', ER_PS: '2025-08-29', ER_PF: '2026-03-25', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 52,  DT_ETA: 51,   DT_SRD: -1, },
      { SN: 268, AREA: 'TOPSIDE', ZONE: 'SOUTH',   LEVEL: 'PD',  SVG: 'PD',  ID: 44, BLK: 'SD120', ALIGN: 'end',   TAG: '101720-TQZ-87046',       DESC: 'HOSE REEL (start FRONT)',                                      PO_NO: '',            PO_DATE: undefined,    VENDOR: '',                                 COUNTRY: '',            INCOTERMS: '',    FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: undefined,    SRD: undefined,    INSTALL: '', SHIP_MODE: '',         SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: '',   G_EREC: 'SD120', ER_PS: '2025-08-29', ER_PF: '2026-03-25', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 0,    DT_ETA: 0,     DT_SRD: 0, },
      { SN: 269, AREA: 'TOPSIDE', ZONE: 'SOUTH',   LEVEL: 'PD',  SVG: 'PD',  ID: 45, BLK: 'SD120', ALIGN: 'end',   TAG: '101720-SLBY-33002',      DESC: 'LIFE RING BUOY',                                               PO_NO: '',            PO_DATE: undefined,    VENDOR: '',                                 COUNTRY: '',            INCOTERMS: '',    FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: undefined,    SRD: undefined,    INSTALL: '', SHIP_MODE: '',         SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: '',   G_EREC: 'SD120', ER_PS: '2025-08-29', ER_PF: '2026-03-25', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 0,    DT_ETA: 0,     DT_SRD: 0, },
      { SN: 270, AREA: 'TOPSIDE', ZONE: 'SOUTH',   LEVEL: 'PD',  SVG: 'PD',  ID: 46, BLK: 'SD120', ALIGN: 'start', TAG: '101720-WVB-33002',       DESC: 'WORK VEST STORAGE BOX',                                        PO_NO: '',            PO_DATE: undefined,    VENDOR: '',                                 COUNTRY: '',            INCOTERMS: '',    FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: undefined,    SRD: undefined,    INSTALL: '', SHIP_MODE: '',         SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: '',   G_EREC: 'SD120', ER_PS: '2025-08-29', ER_PF: '2026-03-25', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 0,    DT_ETA: 0,     DT_SRD: 0, },
      { SN: 271, AREA: 'TOPSIDE', ZONE: 'SOUTH',   LEVEL: 'PD',  SVG: 'PD',  ID: 47, BLK: 'SD120', ALIGN: 'start', TAG: '101860-JTCAB-780001',    DESC: 'NA',                                                           PO_NO: '',            PO_DATE: undefined,    VENDOR: '',                                 COUNTRY: '',            INCOTERMS: '',    FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: undefined,    SRD: undefined,    INSTALL: '', SHIP_MODE: '',         SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: '',   G_EREC: 'SD120', ER_PS: '2025-08-29', ER_PF: '2026-03-25', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 0,    DT_ETA: 0,     DT_SRD: 0, },
      { SN: 272, AREA: 'TOPSIDE', ZONE: 'SOUTH',   LEVEL: 'PD',  SVG: 'PD',  ID: 48, BLK: 'SD120', ALIGN: 'start', TAG: '101930-EDB-70410',       DESC: 'NA',                                                           PO_NO: 'QD557BKH03',  PO_DATE: '2024-03-15', VENDOR: 'Eaton Singapore',                  COUNTRY: 'Korea',       INCOTERMS: 'DDP', FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: '2025-06-01', SRD: '2025-09-29', INSTALL: '', SHIP_MODE: 'NA',       SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: 'NA', G_EREC: 'SD120', ER_PS: '2025-08-29', ER_PF: '2026-03-25', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 120, DT_ETA: 89,   DT_SRD: -31, },
      { SN: 273, AREA: 'TOPSIDE', ZONE: 'SOUTH',   LEVEL: 'PD',  SVG: 'PD',  ID: 49, BLK: 'SD110', ALIGN: 'start', TAG: '101720-TQZ-87052',       DESC: 'HOSE REEL (start FRONT)',                                      PO_NO: '',            PO_DATE: undefined,    VENDOR: '',                                 COUNTRY: '',            INCOTERMS: '',    FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: undefined,    SRD: undefined,    INSTALL: '', SHIP_MODE: '',         SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: '',   G_EREC: 'SD110', ER_PS: '2025-08-20', ER_PF: '2026-03-25', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 0,    DT_ETA: 0,     DT_SRD: 0, },
      { SN: 274, AREA: 'TOPSIDE', ZONE: 'SOUTH',   LEVEL: 'PD',  SVG: 'PD',  ID: 50, BLK: 'SD110', ALIGN: 'start', TAG: '101720-SFE-33002',       DESC: 'FIRE EXTINGUISHER (9.1 KG DRY CHEMICAL)',                      PO_NO: '',            PO_DATE: undefined,    VENDOR: '',                                 COUNTRY: '',            INCOTERMS: '',    FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: undefined,    SRD: undefined,    INSTALL: '', SHIP_MODE: '',         SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: '',   G_EREC: 'SD110', ER_PS: '2025-08-20', ER_PF: '2026-03-25', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 0,    DT_ETA: 0,     DT_SRD: 0, },
      { SN: 275, AREA: 'TOPSIDE', ZONE: 'SOUTH',   LEVEL: 'PD',  SVG: 'PD',  ID: 51, BLK: 'SD110', ALIGN: 'start', TAG: '101530-MAJ-50110',       DESC: 'FRESH WATER LOADING FILTER',                                   PO_NO: 'QU557AFQ49',  PO_DATE: '2024-03-08', VENDOR: 'Parker Korea',                     COUNTRY: 'Korea',       INCOTERMS: 'DAP', FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: '2025-03-15', SRD: '2025-08-06', INSTALL: '', SHIP_MODE: 'NA',       SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: 'NA', G_EREC: 'SD110', ER_PS: '2025-08-20', ER_PF: '2026-03-25', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 144, DT_ETA: 158,  DT_SRD: 14, },
      { SN: 276, AREA: 'TOPSIDE', ZONE: 'SOUTH',   LEVEL: 'PD',  SVG: 'PD',  ID: 52, BLK: 'SD110', ALIGN: 'start', TAG: '101720-CPS-33001',       DESC: 'EMERGENCY EQUIPMENT CABINET',                                  PO_NO: '',            PO_DATE: undefined,    VENDOR: '',                                 COUNTRY: '',            INCOTERMS: '',    FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: undefined,    SRD: undefined,    INSTALL: '', SHIP_MODE: '',         SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: '',   G_EREC: 'SD110', ER_PS: '2025-08-20', ER_PF: '2026-03-25', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 0,    DT_ETA: 0,     DT_SRD: 0, },
      { SN: 277, AREA: 'TOPSIDE', ZONE: 'SOUTH',   LEVEL: 'PD',  SVG: 'PD',  ID: 53, BLK: 'SD110', ALIGN: 'start', TAG: '101800-ZZZ-61430',       DESC: 'AUXILIARY GENERATOR FIRE CABINET',                             PO_NO: 'QD557BDQ15',  PO_DATE: '2024-03-25', VENDOR: 'SATOMAS Pte Ltd',                  COUNTRY: 'Singapore',   INCOTERMS: 'FOB', FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: '2025-08-24', SRD: '2025-08-06', INSTALL: '', SHIP_MODE: 'by Sea',   SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: 'NA', G_EREC: 'SD110', ER_PS: '2025-08-20', ER_PF: '2026-03-25', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: -18, DT_ETA: -4,   DT_SRD: 14, },
      { SN: 278, AREA: 'TOPSIDE', ZONE: 'SOUTH',   LEVEL: 'PD',  SVG: 'PD',  ID: 54, BLK: 'SD120', ALIGN: 'start', TAG: '101860-CJB-778007',      DESC: 'NA',                                                           PO_NO: '',            PO_DATE: undefined,    VENDOR: '',                                 COUNTRY: '',            INCOTERMS: '',    FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: undefined,    SRD: undefined,    INSTALL: '', SHIP_MODE: '',         SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: '',   G_EREC: 'SD120', ER_PS: '2025-08-29', ER_PF: '2026-03-25', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 0,    DT_ETA: 0,     DT_SRD: 0, },
      { SN: 279, AREA: 'TOPSIDE', ZONE: 'SOUTH',   LEVEL: 'PD',  SVG: 'PD',  ID: 55, BLK: 'SD120', ALIGN: 'start', TAG: '101860-JTHAN-778007',    DESC: 'NA',                                                           PO_NO: '',            PO_DATE: undefined,    VENDOR: '',                                 COUNTRY: '',            INCOTERMS: '',    FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: undefined,    SRD: undefined,    INSTALL: '', SHIP_MODE: '',         SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: '',   G_EREC: 'SD120', ER_PS: '2025-08-29', ER_PF: '2026-03-25', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 0,    DT_ETA: 0,     DT_SRD: 0, },
      { SN: 280, AREA: 'TOPSIDE', ZONE: 'SOUTH',   LEVEL: 'PD',  SVG: 'PD',  ID: 56, BLK: 'SD120', ALIGN: 'start', TAG: '101870-EPNL-60510C',     DESC: 'NA',                                                           PO_NO: '',            PO_DATE: undefined,    VENDOR: '',                                 COUNTRY: '',            INCOTERMS: '',    FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: undefined,    SRD: undefined,    INSTALL: '', SHIP_MODE: '',         SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: '',   G_EREC: 'SD120', ER_PS: '2025-08-29', ER_PF: '2026-03-25', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 0,    DT_ETA: 0,     DT_SRD: 0, },
      { SN: 281, AREA: 'TOPSIDE', ZONE: 'SOUTH',   LEVEL: 'PD',  SVG: 'PD',  ID: 57, BLK: 'SD120', ALIGN: 'start', TAG: '101870-EPNL-60510B',     DESC: 'NA',                                                           PO_NO: '',            PO_DATE: undefined,    VENDOR: '',                                 COUNTRY: '',            INCOTERMS: '',    FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: undefined,    SRD: undefined,    INSTALL: '', SHIP_MODE: '',         SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: '',   G_EREC: 'SD120', ER_PS: '2025-08-29', ER_PF: '2026-03-25', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 0,    DT_ETA: 0,     DT_SRD: 0, },
      { SN: 282, AREA: 'TOPSIDE', ZONE: 'SOUTH',   LEVEL: 'PD',  SVG: 'PD',  ID: 58, BLK: 'SD120', ALIGN: 'start', TAG: '101870-EPNL-60510A',     DESC: 'NA',                                                           PO_NO: '',            PO_DATE: undefined,    VENDOR: '',                                 COUNTRY: '',            INCOTERMS: '',    FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: undefined,    SRD: undefined,    INSTALL: '', SHIP_MODE: '',         SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: '',   G_EREC: 'SD120', ER_PS: '2025-08-29', ER_PF: '2026-03-25', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 0,    DT_ETA: 0,     DT_SRD: 0, },
      { SN: 283, AREA: 'TOPSIDE', ZONE: 'SOUTH',   LEVEL: 'PD',  SVG: 'PD',  ID: 59, BLK: 'SD120', ALIGN: 'end',   TAG: '101860-JTCAB-778001',    DESC: 'NA',                                                           PO_NO: '',            PO_DATE: undefined,    VENDOR: '',                                 COUNTRY: '',            INCOTERMS: '',    FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: undefined,    SRD: undefined,    INSTALL: '', SHIP_MODE: '',         SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: '',   G_EREC: 'SD120', ER_PS: '2025-08-29', ER_PF: '2026-03-25', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 0,    DT_ETA: 0,     DT_SRD: 0, },
      { SN: 284, AREA: 'TOPSIDE', ZONE: 'SOUTH',   LEVEL: 'PD',  SVG: 'PD',  ID: 60, BLK: 'SD120', ALIGN: 'end',   TAG: '101700-FACP-64020',      DESC: 'NA',                                                           PO_NO: '',            PO_DATE: undefined,    VENDOR: '',                                 COUNTRY: '',            INCOTERMS: '',    FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: undefined,    SRD: undefined,    INSTALL: '', SHIP_MODE: '',         SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: '',   G_EREC: 'SD120', ER_PS: '2025-08-29', ER_PF: '2026-03-25', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 0,    DT_ETA: 0,     DT_SRD: 0, },
      { SN: 285, AREA: 'TOPSIDE', ZONE: 'SOUTH',   LEVEL: 'PD',  SVG: 'PD',  ID: 61, BLK: 'SD120', ALIGN: 'end',   TAG: '101840-EDB-62440',       DESC: 'NA',                                                           PO_NO: 'QU557BKE7A',  PO_DATE: '2024-06-05', VENDOR: 'R.STAHL KOREA',                    COUNTRY: 'India',       INCOTERMS: 'FOB', FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: '2025-08-30', SRD: '2025-09-22', INSTALL: '', SHIP_MODE: 'NA',       SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: 'NA', G_EREC: 'SD120', ER_PS: '2025-08-29', ER_PF: '2026-03-25', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 23,  DT_ETA: -1,   DT_SRD: -24, },
      { SN: 286, AREA: 'TOPSIDE', ZONE: 'SOUTH',   LEVEL: 'PD',  SVG: 'PD',  ID: 62, BLK: 'SD120', ALIGN: 'end',   TAG: '101860-CJB-778502',      DESC: 'NA',                                                           PO_NO: '',            PO_DATE: undefined,    VENDOR: '',                                 COUNTRY: '',            INCOTERMS: '',    FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: undefined,    SRD: undefined,    INSTALL: '', SHIP_MODE: '',         SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: '',   G_EREC: 'SD120', ER_PS: '2025-08-29', ER_PF: '2026-03-25', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 0,    DT_ETA: 0,     DT_SRD: 0, },
      { SN: 287, AREA: 'TOPSIDE', ZONE: 'SOUTH',   LEVEL: 'PD',  SVG: 'PD',  ID: 63, BLK: 'SD120', ALIGN: 'end',   TAG: '101860-CJB-778002',      DESC: 'NA',                                                           PO_NO: '',            PO_DATE: undefined,    VENDOR: '',                                 COUNTRY: '',            INCOTERMS: '',    FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: undefined,    SRD: undefined,    INSTALL: '', SHIP_MODE: '',         SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: '',   G_EREC: 'SD120', ER_PS: '2025-08-29', ER_PF: '2026-03-25', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 0,    DT_ETA: 0,     DT_SRD: 0, },
      { SN: 288, AREA: 'TOPSIDE', ZONE: 'SOUTH',   LEVEL: 'PD',  SVG: 'PD',  ID: 64, BLK: 'SD120', ALIGN: 'end',   TAG: '101860-CJB-778507',      DESC: 'NA',                                                           PO_NO: '',            PO_DATE: undefined,    VENDOR: '',                                 COUNTRY: '',            INCOTERMS: '',    FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: undefined,    SRD: undefined,    INSTALL: '', SHIP_MODE: '',         SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: '',   G_EREC: 'SD120', ER_PS: '2025-08-29', ER_PF: '2026-03-25', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 0,    DT_ETA: 0,     DT_SRD: 0, },
      { SN: 289, AREA: 'TOPSIDE', ZONE: 'SOUTH',   LEVEL: 'PD',  SVG: 'PD',  ID: 65, BLK: 'SD120', ALIGN: 'end',   TAG: '101860-JTHAN-778507',    DESC: 'NA',                                                           PO_NO: '',            PO_DATE: undefined,    VENDOR: '',                                 COUNTRY: '',            INCOTERMS: '',    FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: undefined,    SRD: undefined,    INSTALL: '', SHIP_MODE: '',         SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: '',   G_EREC: 'SD120', ER_PS: '2025-08-29', ER_PF: '2026-03-25', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 0,    DT_ETA: 0,     DT_SRD: 0, },
      { SN: 290, AREA: 'TOPSIDE', ZONE: 'SOUTH',   LEVEL: 'PD',  SVG: 'PD',  ID: 66, BLK: 'SD120', ALIGN: 'end',   TAG: '101850-EBAT-62790',      DESC: 'NA',                                                           PO_NO: 'QE557BCE03',  PO_DATE: '2024-05-02', VENDOR: 'Chloride',                         COUNTRY: 'France',      INCOTERMS: 'FOB', FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: '2025-06-15', SRD: '2025-10-30', INSTALL: '', SHIP_MODE: 'NA',       SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: 'NA', G_EREC: 'SD120', ER_PS: '2025-08-29', ER_PF: '2026-03-25', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 137, DT_ETA: 75,   DT_SRD: -62, },
      { SN: 291, AREA: 'TOPSIDE', ZONE: 'SOUTH',   LEVEL: 'PD',  SVG: 'PD',  ID: 67, BLK: 'SD120', ALIGN: 'end',   TAG: '101850-EBC-6279062800',  DESC: 'NA',                                                           PO_NO: '',            PO_DATE: undefined,    VENDOR: '',                                 COUNTRY: '',            INCOTERMS: '',    FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: undefined,    SRD: undefined,    INSTALL: '', SHIP_MODE: '',         SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: '',   G_EREC: 'SD120', ER_PS: '2025-08-29', ER_PF: '2026-03-25', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 0,    DT_ETA: 0,     DT_SRD: 0, },
      { SN: 292, AREA: 'TOPSIDE', ZONE: 'SOUTH',   LEVEL: 'PD',  SVG: 'PD',  ID: 68, BLK: 'SD120', ALIGN: 'end',   TAG: '101840-ESB-63000',       DESC: 'NA',                                                           PO_NO: 'QE557BKE02',  PO_DATE: '2024-04-30', VENDOR: 'ABB AS',                           COUNTRY: 'Norway',      INCOTERMS: 'FOB', FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: '2025-08-09', SRD: '2025-08-30', INSTALL: '', SHIP_MODE: 'NA',       SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: 'NA', G_EREC: 'SD120', ER_PS: '2025-08-29', ER_PF: '2026-03-25', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 21,  DT_ETA: 20,   DT_SRD: -1, },
      { SN: 293, AREA: 'TOPSIDE', ZONE: 'SOUTH',   LEVEL: 'MD',  SVG: 'MD',  ID: 1,  BLK: 'SD310', ALIGN: 'start', TAG: '101720-TQZ-87000',       DESC: 'HOSE REEL (start FRONT)',                                      PO_NO: '',            PO_DATE: undefined,    VENDOR: '',                                 COUNTRY: '',            INCOTERMS: '',    FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: undefined,    SRD: undefined,    INSTALL: '', SHIP_MODE: '',         SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: '',   G_EREC: 'SD310', ER_PS: '2025-11-26', ER_PF: '2026-03-25', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 0,    DT_ETA: 0,     DT_SRD: 0, },
      { SN: 294, AREA: 'TOPSIDE', ZONE: 'SOUTH',   LEVEL: 'MD',  SVG: 'MD',  ID: 2,  BLK: 'SD310', ALIGN: 'start', TAG: '101800-ZZZ-60190',       DESC: 'TURBINE GENERATOR 1 LIQUID FUEL BOOST SKID',                   PO_NO: '',            PO_DATE: undefined,    VENDOR: '',                                 COUNTRY: '',            INCOTERMS: '',    FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: undefined,    SRD: undefined,    INSTALL: '', SHIP_MODE: '',         SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: '',   G_EREC: 'SD310', ER_PS: '2025-11-26', ER_PF: '2026-03-25', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 0,    DT_ETA: 0,     DT_SRD: 0, },
      { SN: 295, AREA: 'TOPSIDE', ZONE: 'SOUTH',   LEVEL: 'MD',  SVG: 'MD',  ID: 3,  BLK: 'SD310', ALIGN: 'start', TAG: '101720-SFE-31007',       DESC: 'FIRE EXTINGUISHER (9.1 KG DRY CHEMICAL)',                      PO_NO: '',            PO_DATE: undefined,    VENDOR: '',                                 COUNTRY: '',            INCOTERMS: '',    FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: undefined,    SRD: undefined,    INSTALL: '', SHIP_MODE: '',         SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: '',   G_EREC: 'SD310', ER_PS: '2025-11-26', ER_PF: '2026-03-25', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 0,    DT_ETA: 0,     DT_SRD: 0, },
      { SN: 296, AREA: 'TOPSIDE', ZONE: 'SOUTH',   LEVEL: 'MD',  SVG: 'MD',  ID: 4,  BLK: 'SD310', ALIGN: 'start', TAG: '101720-SLBY-31001',      DESC: 'LIFE RING BUOY',                                               PO_NO: '',            PO_DATE: undefined,    VENDOR: '',                                 COUNTRY: '',            INCOTERMS: '',    FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: undefined,    SRD: undefined,    INSTALL: '', SHIP_MODE: '',         SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: '',   G_EREC: 'SD310', ER_PS: '2025-11-26', ER_PF: '2026-03-25', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 0,    DT_ETA: 0,     DT_SRD: 0, },
      { SN: 297, AREA: 'TOPSIDE', ZONE: 'SOUTH',   LEVEL: 'MD',  SVG: 'MD',  ID: 5,  BLK: 'SD310', ALIGN: 'end',   TAG: '101720-SFE-31009',       DESC: 'FIRE EXTINGUISHER (9.1 KG DRY CHEMICAL)',                      PO_NO: '',            PO_DATE: undefined,    VENDOR: '',                                 COUNTRY: '',            INCOTERMS: '',    FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: undefined,    SRD: undefined,    INSTALL: '', SHIP_MODE: '',         SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: '',   G_EREC: 'SD310', ER_PS: '2025-11-26', ER_PF: '2026-03-25', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 0,    DT_ETA: 0,     DT_SRD: 0, },
      { SN: 298, AREA: 'TOPSIDE', ZONE: 'SOUTH',   LEVEL: 'MD',  SVG: 'MD',  ID: 6,  BLK: 'SD310', ALIGN: 'end',   TAG: '101800-ZZZ-60100',       DESC: 'TURBINE GENERATOR SKID 1',                                     PO_NO: 'Q557PM003',   PO_DATE: '2023-07-01', VENDOR: 'BakerHughes',                      COUNTRY: 'Italy',       INCOTERMS: 'FOB', FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: '2025-09-28', SRD: undefined,    INSTALL: '', SHIP_MODE: 'NA',       SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: 'NA', G_EREC: 'SD310', ER_PS: '2025-11-26', ER_PF: '2026-03-25', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 0,    DT_ETA: 59,   DT_SRD: 0, },
      { SN: 299, AREA: 'TOPSIDE', ZONE: 'SOUTH',   LEVEL: 'MD',  SVG: 'MD',  ID: 7,  BLK: 'SD310', ALIGN: 'end',   TAG: '101800-ZZZ-60300',       DESC: 'TURBINE GENERATOR SKID 3',                                     PO_NO: 'Q557PM003',   PO_DATE: '2023-07-01', VENDOR: 'BakerHughes',                      COUNTRY: 'Italy',       INCOTERMS: 'FOB', FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: '2025-09-28', SRD: undefined,    INSTALL: '', SHIP_MODE: 'NA',       SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: 'NA', G_EREC: 'SD310', ER_PS: '2025-11-26', ER_PF: '2026-03-25', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 0,    DT_ETA: 59,   DT_SRD: 0, },
      { SN: 300, AREA: 'TOPSIDE', ZONE: 'SOUTH',   LEVEL: 'MD',  SVG: 'MD',  ID: 8,  BLK: 'SD310', ALIGN: 'end',   TAG: '101720-TQZ-87001',       DESC: 'HOSE REEL (end FRONT)',                                        PO_NO: '',            PO_DATE: undefined,    VENDOR: '',                                 COUNTRY: '',            INCOTERMS: '',    FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: undefined,    SRD: undefined,    INSTALL: '', SHIP_MODE: '',         SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: '',   G_EREC: 'SD310', ER_PS: '2025-11-26', ER_PF: '2026-03-25', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 0,    DT_ETA: 0,     DT_SRD: 0, },
      { SN: 301, AREA: 'TOPSIDE', ZONE: 'SOUTH',   LEVEL: 'MD',  SVG: 'MD',  ID: 9,  BLK: 'SD310', ALIGN: 'end',   TAG: '101800-ZZZ-60200',       DESC: 'TURBINE GENERATOR SKID 2',                                     PO_NO: 'Q557PM003',   PO_DATE: '2023-07-01', VENDOR: 'BakerHughes',                      COUNTRY: 'Italy',       INCOTERMS: 'FOB', FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: '2025-09-28', SRD: undefined,    INSTALL: '', SHIP_MODE: 'NA',       SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: 'NA', G_EREC: 'SD310', ER_PS: '2025-11-26', ER_PF: '2026-03-25', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 0,    DT_ETA: 59,   DT_SRD: 0, },
      { SN: 302, AREA: 'TOPSIDE', ZONE: 'SOUTH',   LEVEL: 'MD',  SVG: 'MD',  ID: 10, BLK: 'SD330', ALIGN: 'end',   TAG: '101930-ZZZ-70200',       DESC: 'NA',                                                           PO_NO: '',            PO_DATE: undefined,    VENDOR: '',                                 COUNTRY: '',            INCOTERMS: '',    FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: undefined,    SRD: undefined,    INSTALL: '', SHIP_MODE: '',         SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: '',   G_EREC: 'SD330', ER_PS: '2025-12-10', ER_PF: '2026-03-25', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 0,    DT_ETA: 0,     DT_SRD: 0, },
      { SN: 303, AREA: 'TOPSIDE', ZONE: 'SOUTH',   LEVEL: 'MD',  SVG: 'MD',  ID: 11, BLK: 'SD330', ALIGN: 'end',   TAG: '101720-SLBY-31002',      DESC: 'NA',                                                           PO_NO: '',            PO_DATE: undefined,    VENDOR: '',                                 COUNTRY: '',            INCOTERMS: '',    FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: undefined,    SRD: undefined,    INSTALL: '', SHIP_MODE: '',         SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: '',   G_EREC: 'SD330', ER_PS: '2025-12-10', ER_PF: '2026-03-25', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 0,    DT_ETA: 0,     DT_SRD: 0, },
      { SN: 304, AREA: 'TOPSIDE', ZONE: 'SOUTH',   LEVEL: 'MD',  SVG: 'MD',  ID: 12, BLK: 'SD310', ALIGN: 'start', TAG: '101800-ZZZ-60390',       DESC: 'TURBINE GENERATOR 3 LIQUID FUEL BOOST SKID',                   PO_NO: '',            PO_DATE: undefined,    VENDOR: '',                                 COUNTRY: '',            INCOTERMS: '',    FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: undefined,    SRD: undefined,    INSTALL: '', SHIP_MODE: '',         SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: '',   G_EREC: 'SD310', ER_PS: '2025-11-26', ER_PF: '2026-03-25', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 0,    DT_ETA: 0,     DT_SRD: 0, },
      { SN: 305, AREA: 'TOPSIDE', ZONE: 'SOUTH',   LEVEL: 'MD',  SVG: 'MD',  ID: 13, BLK: 'SD310', ALIGN: 'start', TAG: '101720-SFE-31011',       DESC: 'FIRE EXTINGUISHER (9.1 KG DRY CHEMICAL)',                      PO_NO: '',            PO_DATE: undefined,    VENDOR: '',                                 COUNTRY: '',            INCOTERMS: '',    FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: undefined,    SRD: undefined,    INSTALL: '', SHIP_MODE: '',         SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: '',   G_EREC: 'SD310', ER_PS: '2025-11-26', ER_PF: '2026-03-25', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 0,    DT_ETA: 0,     DT_SRD: 0, },
      { SN: 306, AREA: 'TOPSIDE', ZONE: 'SOUTH',   LEVEL: 'MD',  SVG: 'MD',  ID: 14, BLK: 'SD310', ALIGN: 'start', TAG: '101720-CPS-31001',       DESC: 'EMERGENCY EQUIPMENT CABINET',                                  PO_NO: '',            PO_DATE: undefined,    VENDOR: '',                                 COUNTRY: '',            INCOTERMS: '',    FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: undefined,    SRD: undefined,    INSTALL: '', SHIP_MODE: '',         SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: '',   G_EREC: 'SD310', ER_PS: '2025-11-26', ER_PF: '2026-03-25', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 0,    DT_ETA: 0,     DT_SRD: 0, },
      { SN: 307, AREA: 'TOPSIDE', ZONE: 'SOUTH',   LEVEL: 'MD',  SVG: 'MD',  ID: 15, BLK: 'SD310', ALIGN: 'start', TAG: '101800-ZZZ-60290',       DESC: 'TURBINE GENERATOR 2 LIQUID FUEL BOOST SKID',                   PO_NO: '',            PO_DATE: undefined,    VENDOR: '',                                 COUNTRY: '',            INCOTERMS: '',    FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: undefined,    SRD: undefined,    INSTALL: '', SHIP_MODE: '',         SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: '',   G_EREC: 'SD310', ER_PS: '2025-11-26', ER_PF: '2026-03-25', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 0,    DT_ETA: 0,     DT_SRD: 0, },
      { SN: 308, AREA: 'TOPSIDE', ZONE: 'SOUTH',   LEVEL: 'MD',  SVG: 'MD',  ID: 16, BLK: 'SD310', ALIGN: 'start', TAG: '101800-ZZZ-61980',       DESC: 'TURBINE GENERATOR ENGINE CLEANING PACKAGE',                    PO_NO: '',            PO_DATE: undefined,    VENDOR: '',                                 COUNTRY: '',            INCOTERMS: '',    FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: undefined,    SRD: undefined,    INSTALL: '', SHIP_MODE: '',         SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: '',   G_EREC: 'SD310', ER_PS: '2025-11-26', ER_PF: '2026-03-25', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 0,    DT_ETA: 0,     DT_SRD: 0, },
      { SN: 309, AREA: 'TOPSIDE', ZONE: 'SOUTH',   LEVEL: 'MD',  SVG: 'MD',  ID: 17, BLK: 'SD310', ALIGN: 'start', TAG: '101720-SFE-31008',       DESC: 'FIRE EXTINGUISHER (9.1 KG DRY CHEMICAL)',                      PO_NO: '',            PO_DATE: undefined,    VENDOR: '',                                 COUNTRY: '',            INCOTERMS: '',    FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: undefined,    SRD: undefined,    INSTALL: '', SHIP_MODE: '',         SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: '',   G_EREC: 'SD310', ER_PS: '2025-11-26', ER_PF: '2026-03-25', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 0,    DT_ETA: 0,     DT_SRD: 0, },
      { SN: 310, AREA: 'TOPSIDE', ZONE: 'SOUTH',   LEVEL: 'GD',  SVG: 'GD',  ID: 1,  BLK: 'GD110', ALIGN: 'start', TAG: '101720-SFE-31006',       DESC: 'FIRE EXTINGUISHER (12.0 KG DRY CHEMICAL)',                     PO_NO: '',            PO_DATE: undefined,    VENDOR: '',                                 COUNTRY: '',            INCOTERMS: '',    FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: undefined,    SRD: undefined,    INSTALL: '', SHIP_MODE: '',         SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: '',   G_EREC: 'GD110', ER_PS: '2026-01-14', ER_PF: '2026-03-25', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 0,    DT_ETA: 0,     DT_SRD: 0, },
      { SN: 311, AREA: 'TOPSIDE', ZONE: 'SOUTH',   LEVEL: 'GD',  SVG: 'GD',  ID: 2,  BLK: 'GD110', ALIGN: 'start', TAG: '101800-ZZZ-60360',       DESC: 'TURBINE GENERATOR 3 FIREFIGHTING SKID',                        PO_NO: '',            PO_DATE: undefined,    VENDOR: '',                                 COUNTRY: '',            INCOTERMS: '',    FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: undefined,    SRD: undefined,    INSTALL: '', SHIP_MODE: '',         SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: '',   G_EREC: 'GD110', ER_PS: '2026-01-14', ER_PF: '2026-03-25', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 0,    DT_ETA: 0,     DT_SRD: 0, },
      { SN: 312, AREA: 'TOPSIDE', ZONE: 'SOUTH',   LEVEL: 'GD',  SVG: 'GD',  ID: 3,  BLK: 'GD110', ALIGN: 'start', TAG: '101800-ZZZ-60370',       DESC: 'TURBINE GENERATOR 3 SYNTHETIC LUBE OIL VAPOUR SEPARATOR SKID', PO_NO: '',            PO_DATE: undefined,    VENDOR: '',                                 COUNTRY: '',            INCOTERMS: '',    FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: undefined,    SRD: undefined,    INSTALL: '', SHIP_MODE: '',         SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: '',   G_EREC: 'GD110', ER_PS: '2026-01-14', ER_PF: '2026-03-25', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 0,    DT_ETA: 0,     DT_SRD: 0, },
      { SN: 313, AREA: 'TOPSIDE', ZONE: 'SOUTH',   LEVEL: 'GD',  SVG: 'GD',  ID: 4,  BLK: 'GD110', ALIGN: 'start', TAG: '101720-SFE-31004',       DESC: 'FIRE EXTINGUISHER (9.1 KG DRY CHEMICAL)',                      PO_NO: '',            PO_DATE: undefined,    VENDOR: '',                                 COUNTRY: '',            INCOTERMS: '',    FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: undefined,    SRD: undefined,    INSTALL: '', SHIP_MODE: '',         SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: '',   G_EREC: 'GD110', ER_PS: '2026-01-14', ER_PF: '2026-03-25', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 0,    DT_ETA: 0,     DT_SRD: 0, },
      { SN: 314, AREA: 'TOPSIDE', ZONE: 'SOUTH',   LEVEL: 'GD',  SVG: 'GD',  ID: 5,  BLK: 'GD110', ALIGN: 'start', TAG: '101800-ZZZ-60260',       DESC: 'TURBINE GENERATOR 2 FIREFIGHTING SKID',                        PO_NO: '',            PO_DATE: undefined,    VENDOR: '',                                 COUNTRY: '',            INCOTERMS: '',    FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: undefined,    SRD: undefined,    INSTALL: '', SHIP_MODE: '',         SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: '',   G_EREC: 'GD110', ER_PS: '2026-01-14', ER_PF: '2026-03-25', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 0,    DT_ETA: 0,     DT_SRD: 0, },
      { SN: 315, AREA: 'TOPSIDE', ZONE: 'SOUTH',   LEVEL: 'GD',  SVG: 'GD',  ID: 6,  BLK: 'GD110', ALIGN: 'start', TAG: '101800-ZZZ-60270',       DESC: 'TURBINE GENERATOR 2 SYNTHETIC LUBE OIL VAPOUR SEPARATOR SKID', PO_NO: '',            PO_DATE: undefined,    VENDOR: '',                                 COUNTRY: '',            INCOTERMS: '',    FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: undefined,    SRD: undefined,    INSTALL: '', SHIP_MODE: '',         SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: '',   G_EREC: 'GD110', ER_PS: '2026-01-14', ER_PF: '2026-03-25', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 0,    DT_ETA: 0,     DT_SRD: 0, },
      { SN: 316, AREA: 'TOPSIDE', ZONE: 'SOUTH',   LEVEL: 'GD',  SVG: 'GD',  ID: 7,  BLK: 'GD110', ALIGN: 'start', TAG: '101800-ZZZ-60160',       DESC: 'TURBINE GENERATOR 1 FIREFIGHTING SKID',                        PO_NO: '',            PO_DATE: undefined,    VENDOR: '',                                 COUNTRY: '',            INCOTERMS: '',    FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: undefined,    SRD: undefined,    INSTALL: '', SHIP_MODE: '',         SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: '',   G_EREC: 'GD110', ER_PS: '2026-01-14', ER_PF: '2026-03-25', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 0,    DT_ETA: 0,     DT_SRD: 0, },
      { SN: 317, AREA: 'TOPSIDE', ZONE: 'SOUTH',   LEVEL: 'GD',  SVG: 'GD',  ID: 8,  BLK: 'GD110', ALIGN: 'start', TAG: '101800-ZZZ-60170',       DESC: 'TURBINE GENERATOR 1 SYNTHETIC LUBE OIL VAPOUR SEPARATOR SKID', PO_NO: '',            PO_DATE: undefined,    VENDOR: '',                                 COUNTRY: '',            INCOTERMS: '',    FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: undefined,    SRD: undefined,    INSTALL: '', SHIP_MODE: '',         SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: '',   G_EREC: 'GD110', ER_PS: '2026-01-14', ER_PF: '2026-03-25', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 0,    DT_ETA: 0,     DT_SRD: 0, },
      { SN: 318, AREA: 'TOPSIDE', ZONE: 'SOUTH',   LEVEL: 'GD',  SVG: 'GD',  ID: 9,  BLK: 'GD110', ALIGN: 'start', TAG: '101720-TQZ-87026',       DESC: 'HOSE REEL (start FRONT)',                                      PO_NO: '',            PO_DATE: undefined,    VENDOR: '',                                 COUNTRY: '',            INCOTERMS: '',    FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: undefined,    SRD: undefined,    INSTALL: '', SHIP_MODE: '',         SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: '',   G_EREC: 'GD110', ER_PS: '2026-01-14', ER_PF: '2026-03-25', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 0,    DT_ETA: 0,     DT_SRD: 0, },
      { SN: 319, AREA: 'TOPSIDE', ZONE: 'SOUTH',   LEVEL: 'GD',  SVG: 'GD',  ID: 10, BLK: 'GD110', ALIGN: 'start', TAG: '101720-SFE-31002',       DESC: 'FIRE EXTINGUISHER (9.1 KG DRY CHEMICAL)',                      PO_NO: '',            PO_DATE: undefined,    VENDOR: '',                                 COUNTRY: '',            INCOTERMS: '',    FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: undefined,    SRD: undefined,    INSTALL: '', SHIP_MODE: '',         SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: '',   G_EREC: 'GD110', ER_PS: '2026-01-14', ER_PF: '2026-03-25', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 0,    DT_ETA: 0,     DT_SRD: 0, },
      { SN: 320, AREA: 'TOPSIDE', ZONE: 'SOUTH',   LEVEL: 'GD',  SVG: 'GD',  ID: 11, BLK: 'GD110', ALIGN: 'start', TAG: '101720-TQZ-87025',       DESC: 'HOSE REEL (start FRONT)',                                      PO_NO: '',            PO_DATE: undefined,    VENDOR: '',                                 COUNTRY: '',            INCOTERMS: '',    FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: undefined,    SRD: undefined,    INSTALL: '', SHIP_MODE: '',         SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: '',   G_EREC: 'GD110', ER_PS: '2026-01-14', ER_PF: '2026-03-25', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 0,    DT_ETA: 0,     DT_SRD: 0, },
      { SN: 321, AREA: 'TOPSIDE', ZONE: 'SOUTH',   LEVEL: 'GD',  SVG: 'GD',  ID: 12, BLK: 'GD110', ALIGN: 'start', TAG: '101720-SFE-31001',       DESC: 'FIRE EXTINGUISHER (9.1 KG DRY CHEMICAL)',                      PO_NO: '',            PO_DATE: undefined,    VENDOR: '',                                 COUNTRY: '',            INCOTERMS: '',    FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: undefined,    SRD: undefined,    INSTALL: '', SHIP_MODE: '',         SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: '',   G_EREC: 'GD110', ER_PS: '2026-01-14', ER_PF: '2026-03-25', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 0,    DT_ETA: 0,     DT_SRD: 0, },
      { SN: 322, AREA: 'TOPSIDE', ZONE: 'SOUTH',   LEVEL: 'GD',  SVG: 'GD',  ID: 13, BLK: 'GD110', ALIGN: 'end',   TAG: '101410-MAJ-40440',       DESC: 'HEATING MEDIUM FILTER',                                        PO_NO: 'QU557AFQ49',  PO_DATE: '2024-03-08', VENDOR: 'Parker Korea',                     COUNTRY: 'Korea',       INCOTERMS: 'DAP', FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: '2025-03-15', SRD: '2025-12-31', INSTALL: '', SHIP_MODE: 'NA',       SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: 'NA', G_EREC: 'GD110', ER_PS: '2026-01-14', ER_PF: '2026-03-25', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 291, DT_ETA: 305,  DT_SRD: 14, },
      { SN: 323, AREA: 'TOPSIDE', ZONE: 'SOUTH',   LEVEL: 'GD',  SVG: 'GD',  ID: 14, BLK: 'GD110', ALIGN: 'end',   TAG: '101410-MBL-40400',       DESC: 'HEATING MEDIUM EXPANSION VESSEL',                              PO_NO: 'QU557BCQ9B',  PO_DATE: '2024-04-01', VENDOR: 'Wooyang HC',                       COUNTRY: 'Korea',       INCOTERMS: 'DAP', FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: '2025-04-20', SRD: '2025-12-31', INSTALL: '', SHIP_MODE: 'NA',       SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: 'NA', G_EREC: 'GD110', ER_PS: '2026-01-14', ER_PF: '2026-03-25', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 255, DT_ETA: 269,  DT_SRD: 14, },
      { SN: 324, AREA: 'TOPSIDE', ZONE: 'SOUTH',   LEVEL: 'GD',  SVG: 'GD',  ID: 15, BLK: 'GD110', ALIGN: 'end',   TAG: '101590-EBN-40910',       DESC: 'WASTE HEAT RECOVERY UNIT 1',                                   PO_NO: 'QE557ANWH1',  PO_DATE: '2024-03-25', VENDOR: 'Struthers Energy & Power Limited', COUNTRY: 'UK',          INCOTERMS: 'FOB', FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: '2025-09-24', SRD: '2025-12-31', INSTALL: '', SHIP_MODE: 'NA',       SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: 'NA', G_EREC: 'GD110', ER_PS: '2026-01-14', ER_PF: '2026-03-25', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 98,  DT_ETA: 112,  DT_SRD: 14, },
      { SN: 325, AREA: 'TOPSIDE', ZONE: 'SOUTH',   LEVEL: 'GD',  SVG: 'GD',  ID: 16, BLK: 'GD110', ALIGN: 'end',   TAG: '101400-MBL-40340',       DESC: 'COOLING MEDIUM EXPANSION VESSEL',                              PO_NO: 'QU557BCQ9B',  PO_DATE: '2024-04-01', VENDOR: 'Wooyang HC',                       COUNTRY: 'Korea',       INCOTERMS: 'DAP', FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: '2025-04-20', SRD: '2025-12-31', INSTALL: '', SHIP_MODE: 'NA',       SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: 'NA', G_EREC: 'GD110', ER_PS: '2026-01-14', ER_PF: '2026-03-25', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 255, DT_ETA: 269,  DT_SRD: 14, },
      { SN: 326, AREA: 'TOPSIDE', ZONE: 'SOUTH',   LEVEL: 'GD',  SVG: 'GD',  ID: 17, BLK: 'GD110', ALIGN: 'end',   TAG: '101590-EBN-40920',       DESC: 'WASTE HEAT RECOVERY UNIT 2',                                   PO_NO: 'QE557ANWH1',  PO_DATE: '2024-03-25', VENDOR: 'Struthers Energy & Power Limited', COUNTRY: 'UK',          INCOTERMS: 'FOB', FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: '2025-09-24', SRD: '2025-12-31', INSTALL: '', SHIP_MODE: 'NA',       SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: 'NA', G_EREC: 'GD110', ER_PS: '2026-01-14', ER_PF: '2026-03-25', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 98,  DT_ETA: 112,  DT_SRD: 14, },
      { SN: 327, AREA: 'TOPSIDE', ZONE: 'SOUTH',   LEVEL: 'GD',  SVG: 'GD',  ID: 18, BLK: 'GD110', ALIGN: 'end',   TAG: '101590-EBN-40930',       DESC: 'WASTE HEAT RECOVERY UNIT 3',                                   PO_NO: 'QE557ANWH1',  PO_DATE: '2024-03-25', VENDOR: 'Struthers Energy & Power Limited', COUNTRY: 'UK',          INCOTERMS: 'FOB', FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: '2025-09-24', SRD: '2025-12-31', INSTALL: '', SHIP_MODE: 'NA',       SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: 'NA', G_EREC: 'GD110', ER_PS: '2026-01-14', ER_PF: '2026-03-25', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 98,  DT_ETA: 112,  DT_SRD: 14, },
      { SN: 328, AREA: 'TOPSIDE', ZONE: 'SOUTH',   LEVEL: 'GD',  SVG: 'GD',  ID: 19, BLK: 'GD110', ALIGN: 'end',   TAG: '101720-TQZ-87023',       DESC: 'HOSE REEL (end FRONT)',                                        PO_NO: '',            PO_DATE: undefined,    VENDOR: '',                                 COUNTRY: '',            INCOTERMS: '',    FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: undefined,    SRD: undefined,    INSTALL: '', SHIP_MODE: '',         SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: '',   G_EREC: 'GD110', ER_PS: '2026-01-14', ER_PF: '2026-03-25', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 0,    DT_ETA: 0,     DT_SRD: 0, },
      { SN: 329, AREA: 'TOPSIDE', ZONE: 'SOUTH',   LEVEL: 'GD',  SVG: 'GD',  ID: 20, BLK: 'GD110', ALIGN: 'start', TAG: '101720-TQZ-87024',       DESC: 'HOSE REEL (start FRONT)',                                      PO_NO: '',            PO_DATE: undefined,    VENDOR: '',                                 COUNTRY: '',            INCOTERMS: '',    FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: undefined,    SRD: undefined,    INSTALL: '', SHIP_MODE: '',         SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: '',   G_EREC: 'GD110', ER_PS: '2026-01-14', ER_PF: '2026-03-25', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 0,    DT_ETA: 0,     DT_SRD: 0, },
      { SN: 330, AREA: 'TOPSIDE', ZONE: 'SOUTH',   LEVEL: 'ER1', SVG: 'ER1', ID: 1,  BLK: 'ER310', ALIGN: 'start', TAG: '101870-EPNL-60120',      DESC: 'MAIN GENERATOR 1 EXCITATION PANEL',                            PO_NO: '',            PO_DATE: undefined,    VENDOR: '',                                 COUNTRY: '',            INCOTERMS: '',    FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: undefined,    SRD: undefined,    INSTALL: '', SHIP_MODE: '',         SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: '',   G_EREC: 'ER310', ER_PS: '2025-12-24', ER_PF: '2026-03-25', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 0,    DT_ETA: 0,     DT_SRD: 0, },
      { SN: 331, AREA: 'TOPSIDE', ZONE: 'SOUTH',   LEVEL: 'ER1', SVG: 'ER1', ID: 2,  BLK: 'ER310', ALIGN: 'start', TAG: '101870-EPNL-60110',      DESC: 'MAIN GENERATOR 1 CONTROL PANEL',                               PO_NO: '',            PO_DATE: undefined,    VENDOR: '',                                 COUNTRY: '',            INCOTERMS: '',    FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: undefined,    SRD: undefined,    INSTALL: '', SHIP_MODE: '',         SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: '',   G_EREC: 'ER310', ER_PS: '2025-12-24', ER_PF: '2026-03-25', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 0,    DT_ETA: 0,     DT_SRD: 0, },
      { SN: 332, AREA: 'TOPSIDE', ZONE: 'SOUTH',   LEVEL: 'ER1', SVG: 'ER1', ID: 3,  BLK: 'ER310', ALIGN: 'start', TAG: '101870-EPNL-60220',      DESC: 'MAIN GENERATOR 2 EXCITATION PANEL',                            PO_NO: '',            PO_DATE: undefined,    VENDOR: '',                                 COUNTRY: '',            INCOTERMS: '',    FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: undefined,    SRD: undefined,    INSTALL: '', SHIP_MODE: '',         SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: '',   G_EREC: 'ER310', ER_PS: '2025-12-24', ER_PF: '2026-03-25', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 0,    DT_ETA: 0,     DT_SRD: 0, },
      { SN: 333, AREA: 'TOPSIDE', ZONE: 'SOUTH',   LEVEL: 'ER1', SVG: 'ER1', ID: 4,  BLK: 'ER310', ALIGN: 'start', TAG: '101870-EPNL-60320',      DESC: 'MAIN GENERATOR 3 EXCITATION PANEL',                            PO_NO: '',            PO_DATE: undefined,    VENDOR: '',                                 COUNTRY: '',            INCOTERMS: '',    FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: undefined,    SRD: undefined,    INSTALL: '', SHIP_MODE: '',         SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: '',   G_EREC: 'ER310', ER_PS: '2025-12-24', ER_PF: '2026-03-25', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 0,    DT_ETA: 0,     DT_SRD: 0, },
      { SN: 334, AREA: 'TOPSIDE', ZONE: 'SOUTH',   LEVEL: 'ER1', SVG: 'ER1', ID: 5,  BLK: 'ER310', ALIGN: 'start', TAG: '101870-EPNL-60210',      DESC: 'MAIN GENERATOR 2 CONTROL PANEL',                               PO_NO: '',            PO_DATE: undefined,    VENDOR: '',                                 COUNTRY: '',            INCOTERMS: '',    FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: undefined,    SRD: undefined,    INSTALL: '', SHIP_MODE: '',         SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: '',   G_EREC: 'ER310', ER_PS: '2025-12-24', ER_PF: '2026-03-25', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 0,    DT_ETA: 0,     DT_SRD: 0, },
      { SN: 335, AREA: 'TOPSIDE', ZONE: 'SOUTH',   LEVEL: 'ER1', SVG: 'ER1', ID: 6,  BLK: 'ER310', ALIGN: 'start', TAG: '101870-EPNL-60310',      DESC: 'MAIN GENERATOR 3 CONTROL PANEL',                               PO_NO: '',            PO_DATE: undefined,    VENDOR: '',                                 COUNTRY: '',            INCOTERMS: '',    FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: undefined,    SRD: undefined,    INSTALL: '', SHIP_MODE: '',         SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: '',   G_EREC: 'ER310', ER_PS: '2025-12-24', ER_PF: '2026-03-25', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 0,    DT_ETA: 0,     DT_SRD: 0, },
      { SN: 336, AREA: 'TOPSIDE', ZONE: 'SOUTH',   LEVEL: 'ER1', SVG: 'ER1', ID: 7,  BLK: 'ER310', ALIGN: 'start', TAG: '101820-ESB-61000',       DESC: 'LOW VOLTAGE NORMAL SWITCHBOARD (690 V) BACK TO BACK',          PO_NO: 'QE557BKE02',  PO_DATE: '2024-04-30', VENDOR: 'ABB AS',                           COUNTRY: 'Norway',      INCOTERMS: 'FOB', FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: '2025-10-16', SRD: '2025-10-30', INSTALL: '', SHIP_MODE: 'by Sea',   SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: 'NA', G_EREC: 'ER310', ER_PS: '2025-12-24', ER_PF: '2026-03-25', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 14,  DT_ETA: 69,   DT_SRD: 55, },
      { SN: 337, AREA: 'TOPSIDE', ZONE: 'SOUTH',   LEVEL: 'ER1', SVG: 'ER1', ID: 8,  BLK: 'ER310', ALIGN: 'start', TAG: '101820-ESB-62000',       DESC: 'NA',                                                           PO_NO: 'QE557BKE02',  PO_DATE: '2024-04-30', VENDOR: 'ABB AS',                           COUNTRY: 'Norway',      INCOTERMS: 'FOB', FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: '2025-10-16', SRD: '2025-10-30', INSTALL: '', SHIP_MODE: 'NA',       SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: 'NA', G_EREC: 'ER310', ER_PS: '2025-12-24', ER_PF: '2026-03-25', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 14,  DT_ETA: 69,   DT_SRD: 55, },
      { SN: 338, AREA: 'TOPSIDE', ZONE: 'SOUTH',   LEVEL: 'ER1', SVG: 'ER1', ID: 9,  BLK: 'ER310', ALIGN: 'start', TAG: '101800-ESB-60000B',      DESC: 'NA',                                                           PO_NO: '',            PO_DATE: undefined,    VENDOR: '',                                 COUNTRY: '',            INCOTERMS: '',    FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: undefined,    SRD: undefined,    INSTALL: '', SHIP_MODE: '',         SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: '',   G_EREC: 'ER310', ER_PS: '2025-12-24', ER_PF: '2026-03-25', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 0,    DT_ETA: 0,     DT_SRD: 0, },
      { SN: 339, AREA: 'TOPSIDE', ZONE: 'SOUTH',   LEVEL: 'ER1', SVG: 'ER1', ID: 10, BLK: 'ER310', ALIGN: 'start', TAG: '101800-ESB-60000A',      DESC: 'NA',                                                           PO_NO: '',            PO_DATE: undefined,    VENDOR: '',                                 COUNTRY: '',            INCOTERMS: '',    FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: undefined,    SRD: undefined,    INSTALL: '', SHIP_MODE: '',         SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: '',   G_EREC: 'ER310', ER_PS: '2025-12-24', ER_PF: '2026-03-25', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 0,    DT_ETA: 0,     DT_SRD: 0, },
      { SN: 340, AREA: 'TOPSIDE', ZONE: 'SOUTH',   LEVEL: 'ER1', SVG: 'ER1', ID: 11, BLK: 'ER310', ALIGN: 'start', TAG: '101860-CJB-775001',      DESC: 'PAGA A JUNCTION BOX',                                          PO_NO: '',            PO_DATE: undefined,    VENDOR: '',                                 COUNTRY: '',            INCOTERMS: '',    FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: undefined,    SRD: undefined,    INSTALL: '', SHIP_MODE: '',         SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: '',   G_EREC: 'ER310', ER_PS: '2025-12-24', ER_PF: '2026-03-25', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 0,    DT_ETA: 0,     DT_SRD: 0, },
      { SN: 341, AREA: 'TOPSIDE', ZONE: 'SOUTH',   LEVEL: 'ER1', SVG: 'ER1', ID: 12, BLK: 'ER310', ALIGN: 'start', TAG: '101870-EPNL-62910',      DESC: 'PCMS CONTROL PANEL',                                           PO_NO: 'QE557BKE01',  PO_DATE: '2024-04-30', VENDOR: 'ABB AS',                           COUNTRY: 'Norway',      INCOTERMS: 'FOB', FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: '2025-10-18', SRD: '2025-10-30', INSTALL: '', SHIP_MODE: 'NA',       SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: 'NA', G_EREC: 'ER310', ER_PS: '2025-12-24', ER_PF: '2026-03-25', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 12,  DT_ETA: 67,   DT_SRD: 55, },
      { SN: 342, AREA: 'TOPSIDE', ZONE: 'SOUTH',   LEVEL: 'ER1', SVG: 'ER1', ID: 13, BLK: 'ER310', ALIGN: 'start', TAG: '101870-EPNL-62900',      DESC: 'PCMS CONTROL PANEL',                                           PO_NO: 'QE557BKE01',  PO_DATE: '2024-04-30', VENDOR: 'ABB AS',                           COUNTRY: 'Norway',      INCOTERMS: 'FOB', FAT_PLAN: '2025-01-21', FAT_FC: undefined,    FAT_ACT: undefined,    ETA: '2025-10-18', SRD: '2025-10-30', INSTALL: '', SHIP_MODE: 'NA',       SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: 'NA', G_EREC: 'ER310', ER_PS: '2025-12-24', ER_PF: '2026-03-25', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 12,  DT_ETA: 67,   DT_SRD: 55, },
      { SN: 343, AREA: 'TOPSIDE', ZONE: 'SOUTH',   LEVEL: 'ER1', SVG: 'ER1', ID: 14, BLK: 'ER310', ALIGN: 'start', TAG: '101880-ENER-60110',      DESC: 'GTG-1 NEUTRAL EARTHING RESISTOR',                              PO_NO: 'QE557BKE05',  PO_DATE: '2024-04-30', VENDOR: 'ABB AS',                           COUNTRY: 'Norway',      INCOTERMS: 'FOB', FAT_PLAN: '2025-03-04', FAT_FC: undefined,    FAT_ACT: undefined,    ETA: '2025-08-08', SRD: '2025-10-30', INSTALL: '', SHIP_MODE: 'by Sea',   SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: 'NA', G_EREC: 'ER310', ER_PS: '2025-12-24', ER_PF: '2026-03-25', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 83,  DT_ETA: 138,  DT_SRD: 55, },
      { SN: 344, AREA: 'TOPSIDE', ZONE: 'SOUTH',   LEVEL: 'ER1', SVG: 'ER1', ID: 15, BLK: 'ER310', ALIGN: 'start', TAG: '101880-ENER-60210',      DESC: 'GTG-2 NEUTRAL EARTHING RESISTOR (PER STACK)',                  PO_NO: 'QE557BKE05',  PO_DATE: '2024-04-30', VENDOR: 'ABB AS',                           COUNTRY: 'Norway',      INCOTERMS: 'FOB', FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: '2025-08-08', SRD: '2025-10-30', INSTALL: '', SHIP_MODE: 'NA',       SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: 'NA', G_EREC: 'ER310', ER_PS: '2025-12-24', ER_PF: '2026-03-25', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 83,  DT_ETA: 138,  DT_SRD: 55, },
      { SN: 345, AREA: 'TOPSIDE', ZONE: 'SOUTH',   LEVEL: 'ER1', SVG: 'ER1', ID: 16, BLK: 'ER310', ALIGN: 'start', TAG: '101880-ENER-60310',      DESC: 'GTG-3 NEUTRAL EARTHING RESISTOR',                              PO_NO: 'QE557BKE05',  PO_DATE: '2024-04-30', VENDOR: 'ABB AS',                           COUNTRY: 'Norway',      INCOTERMS: 'FOB', FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: '2025-08-08', SRD: '2025-10-30', INSTALL: '', SHIP_MODE: 'NA',       SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: 'NA', G_EREC: 'ER310', ER_PS: '2025-12-24', ER_PF: '2026-03-25', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 83,  DT_ETA: 138,  DT_SRD: 55, },
      { SN: 346, AREA: 'TOPSIDE', ZONE: 'SOUTH',   LEVEL: 'ER1', SVG: 'ER1', ID: 17, BLK: 'ER310', ALIGN: 'end',   TAG: '101880-ENER-62200',      DESC: 'LV NEUTRAL EARTHING RESISTOR (PER STACK)',                     PO_NO: 'QE557BKE05',  PO_DATE: '2024-04-30', VENDOR: 'ABB AS',                           COUNTRY: 'Norway',      INCOTERMS: 'FOB', FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: '2025-08-08', SRD: '2025-10-30', INSTALL: '', SHIP_MODE: 'NA',       SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: 'NA', G_EREC: 'ER310', ER_PS: '2025-12-24', ER_PF: '2026-03-25', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 83,  DT_ETA: 138,  DT_SRD: 55, },
      { SN: 347, AREA: 'TOPSIDE', ZONE: 'SOUTH',   LEVEL: 'ER1', SVG: 'ER1', ID: 18, BLK: 'ER310', ALIGN: 'end',   TAG: '101880-ENER-62100',      DESC: 'LV NEUTRAL EARTHING RESISTOR',                                 PO_NO: 'QE557BKE05',  PO_DATE: '2024-04-30', VENDOR: 'ABB AS',                           COUNTRY: 'Norway',      INCOTERMS: 'FOB', FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: '2025-08-08', SRD: '2025-10-30', INSTALL: '', SHIP_MODE: 'NA',       SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: 'NA', G_EREC: 'ER310', ER_PS: '2025-12-24', ER_PF: '2026-03-25', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 83,  DT_ETA: 138,  DT_SRD: 55, },
      { SN: 348, AREA: 'TOPSIDE', ZONE: 'SOUTH',   LEVEL: 'ER1', SVG: 'ER1', ID: 19, BLK: 'ER310', ALIGN: 'end',   TAG: '101880-ENER-61200',      DESC: 'LV NEUTRAL EARTHING RESISTOR (PER STACK)',                     PO_NO: 'QE557BKE05',  PO_DATE: '2024-04-30', VENDOR: 'ABB AS',                           COUNTRY: 'Norway',      INCOTERMS: 'FOB', FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: '2025-08-08', SRD: '2025-10-30', INSTALL: '', SHIP_MODE: 'NA',       SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: 'NA', G_EREC: 'ER310', ER_PS: '2025-12-24', ER_PF: '2026-03-25', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 83,  DT_ETA: 138,  DT_SRD: 55, },
      { SN: 349, AREA: 'TOPSIDE', ZONE: 'SOUTH',   LEVEL: 'ER1', SVG: 'ER1', ID: 20, BLK: 'ER310', ALIGN: 'end',   TAG: '101880-ENER-61100',      DESC: 'LV NEUTRAL EARTHING RESISTOR',                                 PO_NO: 'QE557BKE05',  PO_DATE: '2024-04-30', VENDOR: 'ABB AS',                           COUNTRY: 'Norway',      INCOTERMS: 'FOB', FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: '2025-08-08', SRD: '2025-10-30', INSTALL: '', SHIP_MODE: 'NA',       SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: 'NA', G_EREC: 'ER310', ER_PS: '2025-12-24', ER_PF: '2026-03-25', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 83,  DT_ETA: 138,  DT_SRD: 55, },
      { SN: 350, AREA: 'TOPSIDE', ZONE: 'SOUTH',   LEVEL: 'ER1', SVG: 'ER1', ID: 21, BLK: 'ER310', ALIGN: 'end',   TAG: '101870-EVSD-60310',      DESC: 'MAIN GENERATOR 3 VFD',                                         PO_NO: '',            PO_DATE: undefined,    VENDOR: '',                                 COUNTRY: '',            INCOTERMS: '',    FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: undefined,    SRD: undefined,    INSTALL: '', SHIP_MODE: '',         SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: '',   G_EREC: 'ER310', ER_PS: '2025-12-24', ER_PF: '2026-03-25', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 0,    DT_ETA: 0,     DT_SRD: 0, },
      { SN: 351, AREA: 'TOPSIDE', ZONE: 'SOUTH',   LEVEL: 'ER1', SVG: 'ER1', ID: 22, BLK: 'ER310', ALIGN: 'end',   TAG: '101870-EVSD-60110',      DESC: 'MAIN GENERATOR 1 VFD',                                         PO_NO: '',            PO_DATE: undefined,    VENDOR: '',                                 COUNTRY: '',            INCOTERMS: '',    FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: undefined,    SRD: undefined,    INSTALL: '', SHIP_MODE: '',         SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: '',   G_EREC: 'ER310', ER_PS: '2025-12-24', ER_PF: '2026-03-25', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 0,    DT_ETA: 0,     DT_SRD: 0, },
      { SN: 352, AREA: 'TOPSIDE', ZONE: 'SOUTH',   LEVEL: 'ER1', SVG: 'ER1', ID: 23, BLK: 'ER310', ALIGN: 'end',   TAG: '101870-EVSD-60210',      DESC: 'MAIN GENERATOR 2 VFD',                                         PO_NO: '',            PO_DATE: undefined,    VENDOR: '',                                 COUNTRY: '',            INCOTERMS: '',    FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: undefined,    SRD: undefined,    INSTALL: '', SHIP_MODE: '',         SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: '',   G_EREC: 'ER310', ER_PS: '2025-12-24', ER_PF: '2026-03-25', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 0,    DT_ETA: 0,     DT_SRD: 0, },
      { SN: 353, AREA: 'TOPSIDE', ZONE: 'SOUTH',   LEVEL: 'ER1', SVG: 'ER1', ID: 24, BLK: 'ER310', ALIGN: 'end',   TAG: '101850-EBAT-62770',      DESC: 'NORMAL SWITCHBOARD CHARGER BATTERY BANK',                      PO_NO: 'QE557BCE03',  PO_DATE: '2024-05-02', VENDOR: 'Chloride',                         COUNTRY: 'France',      INCOTERMS: 'FOB', FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: '2025-09-15', SRD: '2025-10-30', INSTALL: '', SHIP_MODE: 'NA',       SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: 'NA', G_EREC: 'ER310', ER_PS: '2025-12-24', ER_PF: '2026-03-25', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 45,  DT_ETA: 100,  DT_SRD: 55, },
      { SN: 354, AREA: 'TOPSIDE', ZONE: 'SOUTH',   LEVEL: 'ER1', SVG: 'ER1', ID: 25, BLK: 'ER310', ALIGN: 'end',   TAG: '101850-EBAT-62570',      DESC: 'TELECOMS UPS "A" BATTERY BANK',                                PO_NO: 'QE557BCE03',  PO_DATE: '2024-05-02', VENDOR: 'Chloride',                         COUNTRY: 'France',      INCOTERMS: 'FOB', FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: '2025-09-15', SRD: '2025-10-30', INSTALL: '', SHIP_MODE: 'NA',       SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: 'NA', G_EREC: 'ER310', ER_PS: '2025-12-24', ER_PF: '2026-03-25', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 45,  DT_ETA: 100,  DT_SRD: 55, },
      { SN: 355, AREA: 'TOPSIDE', ZONE: 'SOUTH',   LEVEL: 'ER1', SVG: 'ER1', ID: 26, BLK: 'ER310', ALIGN: 'end',   TAG: '101850-EBAT-62580',      DESC: 'TELECOMS UPS "B" BATTERY BANK',                                PO_NO: 'QE557BCE03',  PO_DATE: '2024-05-02', VENDOR: 'Chloride',                         COUNTRY: 'France',      INCOTERMS: 'FOB', FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: '2025-09-15', SRD: '2025-10-30', INSTALL: '', SHIP_MODE: 'NA',       SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: 'NA', G_EREC: 'ER310', ER_PS: '2025-12-24', ER_PF: '2026-03-25', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 45,  DT_ETA: 100,  DT_SRD: 55, },
      { SN: 356, AREA: 'TOPSIDE', ZONE: 'SOUTH',   LEVEL: 'ER1', SVG: 'ER1', ID: 27, BLK: 'ER310', ALIGN: 'end',   TAG: '101850-EBAT-62540',      DESC: 'ESCAPE LIGHT UPS BATTERY BANK',                                PO_NO: 'QE557BCE03',  PO_DATE: '2024-05-02', VENDOR: 'Chloride',                         COUNTRY: 'France',      INCOTERMS: 'FOB', FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: '2025-09-15', SRD: '2025-10-30', INSTALL: '', SHIP_MODE: 'NA',       SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: 'NA', G_EREC: 'ER310', ER_PS: '2025-12-24', ER_PF: '2026-03-25', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 45,  DT_ETA: 100,  DT_SRD: 55, },
      { SN: 357, AREA: 'TOPSIDE', ZONE: 'SOUTH',   LEVEL: 'ER1', SVG: 'ER1', ID: 28, BLK: 'ER310', ALIGN: 'end',   TAG: '101790-SRIO-68425',      DESC: 'SIS REMOTE I/O CENTER CABINET',                                PO_NO: 'QU557BWI01',  PO_DATE: '2024-06-28', VENDOR: 'Honeywell Korea',                  COUNTRY: 'Korea',       INCOTERMS: 'DAP', FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: '2025-10-01', SRD: '2025-11-11', INSTALL: '', SHIP_MODE: 'NA',       SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: 'NA', G_EREC: 'ER310', ER_PS: '2025-12-24', ER_PF: '2026-03-25', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 41,  DT_ETA: 84,   DT_SRD: 43, },
      { SN: 358, AREA: 'TOPSIDE', ZONE: 'SOUTH',   LEVEL: 'ER1', SVG: 'ER1', ID: 29, BLK: 'ER310', ALIGN: 'end',   TAG: '101790-SRIO-68450',      DESC: 'NA',                                                           PO_NO: 'QU557BWI01',  PO_DATE: '2024-06-28', VENDOR: 'Honeywell Korea',                  COUNTRY: 'Korea',       INCOTERMS: 'DAP', FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: '2025-10-01', SRD: '2025-11-11', INSTALL: '', SHIP_MODE: 'NA',       SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: 'NA', G_EREC: 'ER310', ER_PS: '2025-12-24', ER_PF: '2026-03-25', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 41,  DT_ETA: 84,   DT_SRD: 43, },
      { SN: 359, AREA: 'TOPSIDE', ZONE: 'SOUTH',   LEVEL: 'ER1', SVG: 'ER1', ID: 30, BLK: 'ER310', ALIGN: 'end',   TAG: '101790-SRIO-68400',      DESC: 'SIS REMOTE I/O SOUTH CABINET',                                 PO_NO: 'QU557BWI01',  PO_DATE: '2024-06-28', VENDOR: 'Honeywell Korea',                  COUNTRY: 'Korea',       INCOTERMS: 'DAP', FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: '2025-10-01', SRD: '2025-11-11', INSTALL: '', SHIP_MODE: 'NA',       SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: 'NA', G_EREC: 'ER310', ER_PS: '2025-12-24', ER_PF: '2026-03-25', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 41,  DT_ETA: 84,   DT_SRD: 43, },
      { SN: 360, AREA: 'TOPSIDE', ZONE: 'SOUTH',   LEVEL: 'ER1', SVG: 'ER1', ID: 31, BLK: 'ER310', ALIGN: 'end',   TAG: '101860-JTHAN-775501',    DESC: 'PAGA B HANDSET UNIT',                                          PO_NO: '',            PO_DATE: undefined,    VENDOR: '',                                 COUNTRY: '',            INCOTERMS: '',    FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: undefined,    SRD: undefined,    INSTALL: '', SHIP_MODE: '',         SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: '',   G_EREC: 'ER310', ER_PS: '2025-12-24', ER_PF: '2026-03-25', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 0,    DT_ETA: 0,     DT_SRD: 0, },
      { SN: 361, AREA: 'TOPSIDE', ZONE: 'SOUTH',   LEVEL: 'ER1', SVG: 'ER1', ID: 32, BLK: 'ER310', ALIGN: 'end',   TAG: '101850-EBAT-62600',      DESC: 'IMMS UPS BATTERY BANK',                                        PO_NO: 'QE557BCE03',  PO_DATE: '2024-05-02', VENDOR: 'Chloride',                         COUNTRY: 'France',      INCOTERMS: 'FOB', FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: '2025-09-15', SRD: '2025-10-30', INSTALL: '', SHIP_MODE: 'NA',       SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: 'NA', G_EREC: 'ER310', ER_PS: '2025-12-24', ER_PF: '2026-03-25', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 45,  DT_ETA: 100,  DT_SRD: 55, },
      { SN: 362, AREA: 'TOPSIDE', ZONE: 'SOUTH',   LEVEL: 'ER1', SVG: 'ER1', ID: 33, BLK: 'ER310', ALIGN: 'end',   TAG: '101850-EBAT-62510',      DESC: 'MAIN UPS "A" BATTERY BANK',                                    PO_NO: 'QE557BCE03',  PO_DATE: '2024-05-02', VENDOR: 'Chloride',                         COUNTRY: 'France',      INCOTERMS: 'FOB', FAT_PLAN: '2025-03-10', FAT_FC: undefined,    FAT_ACT: undefined,    ETA: '2025-09-15', SRD: '2025-10-30', INSTALL: '', SHIP_MODE: 'NA',       SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: 'NA', G_EREC: 'ER310', ER_PS: '2025-12-24', ER_PF: '2026-03-25', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 45,  DT_ETA: 100,  DT_SRD: 55, },
      { SN: 363, AREA: 'TOPSIDE', ZONE: 'SOUTH',   LEVEL: 'ER1', SVG: 'ER1', ID: 34, BLK: 'ER310', ALIGN: 'end',   TAG: '101850-EBAT-62520',      DESC: 'MAIN UPS "B" BATTERY BANK',                                    PO_NO: 'QE557BCE03',  PO_DATE: '2024-05-02', VENDOR: 'Chloride',                         COUNTRY: 'France',      INCOTERMS: 'FOB', FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: '2025-09-15', SRD: '2025-10-30', INSTALL: '', SHIP_MODE: 'NA',       SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: 'NA', G_EREC: 'ER310', ER_PS: '2025-12-24', ER_PF: '2026-03-25', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 45,  DT_ETA: 100,  DT_SRD: 55, },
      { SN: 364, AREA: 'TOPSIDE', ZONE: 'SOUTH',   LEVEL: 'ER1', SVG: 'ER1', ID: 35, BLK: 'ER310', ALIGN: 'end',   TAG: '101850-EBAT-62810',      DESC: 'NAV AID LANTERN & FOG HORN BATTERY BANK',                      PO_NO: 'QU557BF1E08', PO_DATE: '2024-06-28', VENDOR: 'KME',                              COUNTRY: 'Korea',       INCOTERMS: 'DAP', FAT_PLAN: '2025-05-19', FAT_FC: undefined,    FAT_ACT: undefined,    ETA: '2025-10-03', SRD: '2025-10-30', INSTALL: '', SHIP_MODE: 'NA',       SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: 'NA', G_EREC: 'ER310', ER_PS: '2025-12-24', ER_PF: '2026-03-25', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 27,  DT_ETA: 82,   DT_SRD: 55, },
      { SN: 365, AREA: 'TOPSIDE', ZONE: 'SOUTH',   LEVEL: 'ER2', SVG: 'ER2', ID: 1,  BLK: 'ER310', ALIGN: 'start', TAG: '101850-EJB-62890',       DESC: 'NA',                                                           PO_NO: '',            PO_DATE: undefined,    VENDOR: '',                                 COUNTRY: '',            INCOTERMS: '',    FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: undefined,    SRD: undefined,    INSTALL: '', SHIP_MODE: '',         SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: '',   G_EREC: 'ER310', ER_PS: '2025-12-24', ER_PF: '2026-03-25', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 0,    DT_ETA: 0,     DT_SRD: 0, },
      { SN: 366, AREA: 'TOPSIDE', ZONE: 'SOUTH',   LEVEL: 'ER2', SVG: 'ER2', ID: 2,  BLK: 'ER310', ALIGN: 'start', TAG: '101860-EPNL-62830',      DESC: 'NA',                                                           PO_NO: 'QU557BF1E08', PO_DATE: '2024-06-28', VENDOR: 'KME',                              COUNTRY: 'Korea',       INCOTERMS: 'DAP', FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: '2025-10-03', SRD: '2025-10-30', INSTALL: '', SHIP_MODE: 'NA',       SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: 'NA', G_EREC: 'ER310', ER_PS: '2025-12-24', ER_PF: '2026-03-25', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 27,  DT_ETA: 82,   DT_SRD: 55, },
      { SN: 367, AREA: 'TOPSIDE', ZONE: 'SOUTH',   LEVEL: 'ER2', SVG: 'ER2', ID: 3,  BLK: 'ER310', ALIGN: 'start', TAG: '101850-EDB-62570',       DESC: 'TELECOMS UPS "A" DISTRIBUTION BOARD',                          PO_NO: 'QU557BKE7A',  PO_DATE: '2024-06-05', VENDOR: 'R.STAHL KOREA',                    COUNTRY: 'India',       INCOTERMS: 'FOB', FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: '2025-08-30', SRD: '2025-10-07', INSTALL: '', SHIP_MODE: 'NA',       SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: 'NA', G_EREC: 'ER310', ER_PS: '2025-12-24', ER_PF: '2026-03-25', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 38,  DT_ETA: 116,  DT_SRD: 78, },
      { SN: 368, AREA: 'TOPSIDE', ZONE: 'SOUTH',   LEVEL: 'ER2', SVG: 'ER2', ID: 4,  BLK: 'ER310', ALIGN: 'start', TAG: '101850-EDB-62580',       DESC: 'TELECOMS UPS "B" DISTRIBUTION BOARD',                          PO_NO: 'QU557BKE7A',  PO_DATE: '2024-06-05', VENDOR: 'R.STAHL KOREA',                    COUNTRY: 'India',       INCOTERMS: 'FOB', FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: '2025-08-30', SRD: '2025-10-07', INSTALL: '', SHIP_MODE: 'NA',       SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: 'NA', G_EREC: 'ER310', ER_PS: '2025-12-24', ER_PF: '2026-03-25', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 38,  DT_ETA: 116,  DT_SRD: 78, },
      { SN: 369, AREA: 'TOPSIDE', ZONE: 'SOUTH',   LEVEL: 'ER2', SVG: 'ER2', ID: 5,  BLK: 'ER310', ALIGN: 'start', TAG: '101850-EDB-62540',       DESC: 'ESCAPE LIGHTING UPS "A" DISTRIBUTION BOARD',                   PO_NO: 'QU557BKE7A',  PO_DATE: '2024-06-05', VENDOR: 'R.STAHL KOREA',                    COUNTRY: 'India',       INCOTERMS: 'FOB', FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: '2025-08-30', SRD: '2025-10-07', INSTALL: '', SHIP_MODE: 'NA',       SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: 'NA', G_EREC: 'ER310', ER_PS: '2025-12-24', ER_PF: '2026-03-25', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 38,  DT_ETA: 116,  DT_SRD: 78, },
      { SN: 370, AREA: 'TOPSIDE', ZONE: 'SOUTH',   LEVEL: 'ER2', SVG: 'ER2', ID: 6,  BLK: 'ER310', ALIGN: 'start', TAG: '101850-EDB-62550',       DESC: 'ESCAPE LIGHTING UPS "B" DISTRIBUTION BOARD',                   PO_NO: 'QU557BKE7A',  PO_DATE: '2024-06-05', VENDOR: 'R.STAHL KOREA',                    COUNTRY: 'India',       INCOTERMS: 'FOB', FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: '2025-08-30', SRD: '2025-10-07', INSTALL: '', SHIP_MODE: 'NA',       SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: 'NA', G_EREC: 'ER310', ER_PS: '2025-12-24', ER_PF: '2026-03-25', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 38,  DT_ETA: 116,  DT_SRD: 78, },
      { SN: 371, AREA: 'TOPSIDE', ZONE: 'SOUTH',   LEVEL: 'ER2', SVG: 'ER2', ID: 7,  BLK: 'ER310', ALIGN: 'start', TAG: '101850-EDB-62520',       DESC: 'MAIN UPS "B" DISTRIBUTION BOARD',                              PO_NO: 'QU557BKE7A',  PO_DATE: '2024-06-05', VENDOR: 'R.STAHL KOREA',                    COUNTRY: 'India',       INCOTERMS: 'FOB', FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: '2025-08-30', SRD: '2025-10-07', INSTALL: '', SHIP_MODE: 'NA',       SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: 'NA', G_EREC: 'ER310', ER_PS: '2025-12-24', ER_PF: '2026-03-25', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 38,  DT_ETA: 116,  DT_SRD: 78, },
      { SN: 372, AREA: 'TOPSIDE', ZONE: 'SOUTH',   LEVEL: 'ER2', SVG: 'ER2', ID: 8,  BLK: 'ER310', ALIGN: 'start', TAG: '101850-EDB-62510',       DESC: 'MAIN UPS "A" DISTRIBUTION BOARD',                              PO_NO: 'QU557BKE7A',  PO_DATE: '2024-06-05', VENDOR: 'R.STAHL KOREA',                    COUNTRY: 'India',       INCOTERMS: 'FOB', FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: '2025-08-30', SRD: '2025-10-07', INSTALL: '', SHIP_MODE: 'NA',       SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: 'NA', G_EREC: 'ER310', ER_PS: '2025-12-24', ER_PF: '2026-03-25', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 38,  DT_ETA: 116,  DT_SRD: 78, },
      { SN: 373, AREA: 'TOPSIDE', ZONE: 'SOUTH',   LEVEL: 'ER2', SVG: 'ER2', ID: 9,  BLK: 'ER310', ALIGN: 'start', TAG: '101850-EUPS-62600',      DESC: '2.5kVA IMMS UPS',                                              PO_NO: 'QE557BCE03',  PO_DATE: '2024-05-02', VENDOR: 'Chloride',                         COUNTRY: 'France',      INCOTERMS: 'FOB', FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: '2025-06-15', SRD: '2025-10-30', INSTALL: '', SHIP_MODE: 'NA',       SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: 'NA', G_EREC: 'ER310', ER_PS: '2025-12-24', ER_PF: '2026-03-25', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 137, DT_ETA: 192,  DT_SRD: 55, },
      { SN: 374, AREA: 'TOPSIDE', ZONE: 'SOUTH',   LEVEL: 'ER2', SVG: 'ER2', ID: 10, BLK: 'ER310', ALIGN: 'start', TAG: '101860-CJB-776522',      DESC: 'PAGA B JUNCTION BOX',                                          PO_NO: '',            PO_DATE: undefined,    VENDOR: '',                                 COUNTRY: '',            INCOTERMS: '',    FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: undefined,    SRD: undefined,    INSTALL: '', SHIP_MODE: '',         SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: '',   G_EREC: 'ER310', ER_PS: '2025-12-24', ER_PF: '2026-03-25', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 0,    DT_ETA: 0,     DT_SRD: 0, },
      { SN: 375, AREA: 'TOPSIDE', ZONE: 'SOUTH',   LEVEL: 'ER2', SVG: 'ER2', ID: 11, BLK: 'ER310', ALIGN: 'start', TAG: '101860-JTHAN-776522E',   DESC: 'NA',                                                           PO_NO: '',            PO_DATE: undefined,    VENDOR: '',                                 COUNTRY: '',            INCOTERMS: '',    FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: undefined,    SRD: undefined,    INSTALL: '', SHIP_MODE: '',         SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: '',   G_EREC: 'ER310', ER_PS: '2025-12-24', ER_PF: '2026-03-25', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 0,    DT_ETA: 0,     DT_SRD: 0, },
      { SN: 376, AREA: 'TOPSIDE', ZONE: 'SOUTH',   LEVEL: 'ER2', SVG: 'ER2', ID: 12, BLK: 'ER310', ALIGN: 'start', TAG: '101960-NPNL-68901AB',    DESC: 'NA',                                                           PO_NO: '',            PO_DATE: undefined,    VENDOR: '',                                 COUNTRY: '',            INCOTERMS: '',    FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: undefined,    SRD: undefined,    INSTALL: '', SHIP_MODE: '',         SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: '',   G_EREC: 'ER310', ER_PS: '2025-12-24', ER_PF: '2026-03-25', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 0,    DT_ETA: 0,     DT_SRD: 0, },
      { SN: 377, AREA: 'TOPSIDE', ZONE: 'SOUTH',   LEVEL: 'ER2', SVG: 'ER2', ID: 13, BLK: 'ER310', ALIGN: 'start', TAG: '101860-JTCAB-776002',    DESC: 'NA',                                                           PO_NO: 'QU557BKT02',  PO_DATE: '2024-08-19', VENDOR: 'SHINKI (Gai-Tronics)',             COUNTRY: 'USA',         INCOTERMS: 'FOB', FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: '2025-06-17', SRD: '2025-08-01', INSTALL: '', SHIP_MODE: 'NA',       SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: 'NA', G_EREC: 'ER310', ER_PS: '2025-12-24', ER_PF: '2026-03-25', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 45,  DT_ETA: 190,  DT_SRD: 145, },
      { SN: 378, AREA: 'TOPSIDE', ZONE: 'SOUTH',   LEVEL: 'ER2', SVG: 'ER2', ID: 14, BLK: 'ER310', ALIGN: 'start', TAG: '101860-JTCAB-776502',    DESC: 'NA',                                                           PO_NO: '',            PO_DATE: undefined,    VENDOR: '',                                 COUNTRY: '',            INCOTERMS: '',    FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: undefined,    SRD: undefined,    INSTALL: '', SHIP_MODE: '',         SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: '',   G_EREC: 'ER310', ER_PS: '2025-12-24', ER_PF: '2026-03-25', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 0,    DT_ETA: 0,     DT_SRD: 0, },
      { SN: 379, AREA: 'TOPSIDE', ZONE: 'SOUTH',   LEVEL: 'ER2', SVG: 'ER2', ID: 15, BLK: 'ER310', ALIGN: 'start', TAG: '101860-JTCAB-776001',    DESC: 'NA',                                                           PO_NO: 'QU557BKT03',  PO_DATE: '2024-08-20', VENDOR: 'DONGYANG',                         COUNTRY: 'Korea',       INCOTERMS: 'DAP', FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: '2025-07-01', SRD: '2025-08-06', INSTALL: '', SHIP_MODE: 'NA',       SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: 'NA', G_EREC: 'ER310', ER_PS: '2025-12-24', ER_PF: '2026-03-25', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 36,  DT_ETA: 176,  DT_SRD: 140, },
      { SN: 380, AREA: 'TOPSIDE', ZONE: 'SOUTH',   LEVEL: 'ER2', SVG: 'ER2', ID: 16, BLK: 'ER310', ALIGN: 'start', TAG: '101860-JTCAB-776501',    DESC: 'NA',                                                           PO_NO: '',            PO_DATE: undefined,    VENDOR: '',                                 COUNTRY: '',            INCOTERMS: '',    FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: undefined,    SRD: undefined,    INSTALL: '', SHIP_MODE: '',         SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: '',   G_EREC: 'ER310', ER_PS: '2025-12-24', ER_PF: '2026-03-25', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 0,    DT_ETA: 0,     DT_SRD: 0, },
      { SN: 381, AREA: 'TOPSIDE', ZONE: 'SOUTH',   LEVEL: 'ER2', SVG: 'ER2', ID: 17, BLK: 'ER310', ALIGN: 'start', TAG: '101960-NPNL-68900D',     DESC: 'NA',                                                           PO_NO: '',            PO_DATE: undefined,    VENDOR: '',                                 COUNTRY: '',            INCOTERMS: '',    FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: undefined,    SRD: undefined,    INSTALL: '', SHIP_MODE: '',         SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: '',   G_EREC: 'ER310', ER_PS: '2025-12-24', ER_PF: '2026-03-25', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 0,    DT_ETA: 0,     DT_SRD: 0, },
      { SN: 382, AREA: 'TOPSIDE', ZONE: 'SOUTH',   LEVEL: 'ER2', SVG: 'ER2', ID: 18, BLK: 'ER310', ALIGN: 'start', TAG: '101960-NPNL-68900C',     DESC: 'NA',                                                           PO_NO: '',            PO_DATE: undefined,    VENDOR: '',                                 COUNTRY: '',            INCOTERMS: '',    FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: undefined,    SRD: undefined,    INSTALL: '', SHIP_MODE: '',         SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: '',   G_EREC: 'ER310', ER_PS: '2025-12-24', ER_PF: '2026-03-25', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 0,    DT_ETA: 0,     DT_SRD: 0, },
      { SN: 383, AREA: 'TOPSIDE', ZONE: 'SOUTH',   LEVEL: 'ER2', SVG: 'ER2', ID: 19, BLK: 'ER310', ALIGN: 'start', TAG: '101960-NPNL-68900B',     DESC: 'NA',                                                           PO_NO: '',            PO_DATE: undefined,    VENDOR: '',                                 COUNTRY: '',            INCOTERMS: '',    FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: undefined,    SRD: undefined,    INSTALL: '', SHIP_MODE: '',         SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: '',   G_EREC: 'ER310', ER_PS: '2025-12-24', ER_PF: '2026-03-25', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 0,    DT_ETA: 0,     DT_SRD: 0, },
      { SN: 384, AREA: 'TOPSIDE', ZONE: 'SOUTH',   LEVEL: 'ER2', SVG: 'ER2', ID: 20, BLK: 'ER310', ALIGN: 'start', TAG: '101960-NPNL-68900A',     DESC: 'NA',                                                           PO_NO: '',            PO_DATE: undefined,    VENDOR: '',                                 COUNTRY: '',            INCOTERMS: '',    FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: undefined,    SRD: undefined,    INSTALL: '', SHIP_MODE: '',         SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: '',   G_EREC: 'ER310', ER_PS: '2025-12-24', ER_PF: '2026-03-25', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 0,    DT_ETA: 0,     DT_SRD: 0, },
      { SN: 385, AREA: 'TOPSIDE', ZONE: 'SOUTH',   LEVEL: 'ER2', SVG: 'ER2', ID: 21, BLK: 'ER310', ALIGN: 'start', TAG: '101850-EDB-62560',       DESC: 'NA',                                                           PO_NO: 'QU557BKE7A',  PO_DATE: '2024-06-05', VENDOR: 'R.STAHL KOREA',                    COUNTRY: 'India',       INCOTERMS: 'FOB', FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: '2025-08-30', SRD: '2025-10-07', INSTALL: '', SHIP_MODE: 'NA',       SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: 'NA', G_EREC: 'ER310', ER_PS: '2025-12-24', ER_PF: '2026-03-25', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 38,  DT_ETA: 116,  DT_SRD: 78, },
      { SN: 386, AREA: 'TOPSIDE', ZONE: 'SOUTH',   LEVEL: 'ER2', SVG: 'ER2', ID: 22, BLK: 'ER310', ALIGN: 'start', TAG: '101850-EDB-62530',       DESC: 'NA',                                                           PO_NO: 'QU557BKE7A',  PO_DATE: '2024-06-05', VENDOR: 'R.STAHL KOREA',                    COUNTRY: 'India',       INCOTERMS: 'FOB', FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: '2025-08-30', SRD: '2025-10-07', INSTALL: '', SHIP_MODE: 'NA',       SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: 'NA', G_EREC: 'ER310', ER_PS: '2025-12-24', ER_PF: '2026-03-25', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 38,  DT_ETA: 116,  DT_SRD: 78, },
      { SN: 387, AREA: 'TOPSIDE', ZONE: 'SOUTH',   LEVEL: 'ER2', SVG: 'ER2', ID: 23, BLK: 'ER310', ALIGN: 'start', TAG: '101290-PPNL-20800',      DESC: 'NA',                                                           PO_NO: '',            PO_DATE: undefined,    VENDOR: '',                                 COUNTRY: '',            INCOTERMS: '',    FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: undefined,    SRD: undefined,    INSTALL: '', SHIP_MODE: '',         SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: '',   G_EREC: 'ER310', ER_PS: '2025-12-24', ER_PF: '2026-03-25', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 0,    DT_ETA: 0,     DT_SRD: 0, },
      { SN: 388, AREA: 'TOPSIDE', ZONE: 'SOUTH',   LEVEL: 'ER2', SVG: 'ER2', ID: 24, BLK: 'ER310', ALIGN: 'start', TAG: '101820-EDB-62410',       DESC: 'NORMAL LIGHTING AND SMALL POWER DISTRIBUTION BOARD',           PO_NO: 'QU557BKE7A',  PO_DATE: '2024-06-05', VENDOR: 'R.STAHL KOREA',                    COUNTRY: 'India',       INCOTERMS: 'FOB', FAT_PLAN: '2025-04-02', FAT_FC: undefined,    FAT_ACT: undefined,    ETA: '2025-08-30', SRD: '2025-10-07', INSTALL: '', SHIP_MODE: 'NA',       SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: 'NA', G_EREC: 'ER310', ER_PS: '2025-12-24', ER_PF: '2026-03-25', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 38,  DT_ETA: 116,  DT_SRD: 78, },
      { SN: 389, AREA: 'TOPSIDE', ZONE: 'SOUTH',   LEVEL: 'ER2', SVG: 'ER2', ID: 25, BLK: 'ER310', ALIGN: 'start', TAG: '101860-CJB-776022',      DESC: 'PAGA A JUNCTION BOX',                                          PO_NO: '',            PO_DATE: undefined,    VENDOR: '',                                 COUNTRY: '',            INCOTERMS: '',    FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: undefined,    SRD: undefined,    INSTALL: '', SHIP_MODE: '',         SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: '',   G_EREC: 'ER310', ER_PS: '2025-12-24', ER_PF: '2026-03-25', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 0,    DT_ETA: 0,     DT_SRD: 0, },
      { SN: 390, AREA: 'TOPSIDE', ZONE: 'SOUTH',   LEVEL: 'ER2', SVG: 'ER2', ID: 26, BLK: 'ER310', ALIGN: 'start', TAG: '101860-JTHAN-776022E',   DESC: 'NA',                                                           PO_NO: '',            PO_DATE: undefined,    VENDOR: '',                                 COUNTRY: '',            INCOTERMS: '',    FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: undefined,    SRD: undefined,    INSTALL: '', SHIP_MODE: '',         SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: '',   G_EREC: 'ER310', ER_PS: '2025-12-24', ER_PF: '2026-03-25', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 0,    DT_ETA: 0,     DT_SRD: 0, },
      { SN: 391, AREA: 'TOPSIDE', ZONE: 'SOUTH',   LEVEL: 'ER2', SVG: 'ER2', ID: 27, BLK: 'ER310', ALIGN: 'start', TAG: '101290-PPNL-20200',      DESC: 'NA',                                                           PO_NO: '',            PO_DATE: undefined,    VENDOR: '',                                 COUNTRY: '',            INCOTERMS: '',    FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: undefined,    SRD: undefined,    INSTALL: '', SHIP_MODE: '',         SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: '',   G_EREC: 'ER310', ER_PS: '2025-12-24', ER_PF: '2026-03-25', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 0,    DT_ETA: 0,     DT_SRD: 0, },
      { SN: 392, AREA: 'TOPSIDE', ZONE: 'SOUTH',   LEVEL: 'ER2', SVG: 'ER2', ID: 28, BLK: 'ER310', ALIGN: 'end',   TAG: '101870-EPNL-12310',      DESC: 'MAIN GAS COMPRESSOR TRAIN 1 PANEL',                            PO_NO: '',            PO_DATE: undefined,    VENDOR: '',                                 COUNTRY: '',            INCOTERMS: '',    FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: undefined,    SRD: undefined,    INSTALL: '', SHIP_MODE: '',         SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: '',   G_EREC: 'ER310', ER_PS: '2025-12-24', ER_PF: '2026-03-25', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 0,    DT_ETA: 0,     DT_SRD: 0, },
      { SN: 393, AREA: 'TOPSIDE', ZONE: 'SOUTH',   LEVEL: 'ER2', SVG: 'ER2', ID: 29, BLK: 'ER310', ALIGN: 'end',   TAG: '101870-EPNL-12410',      DESC: 'MAIN GAS COMPRESSOR TRAIN 2 PANEL',                            PO_NO: '',            PO_DATE: undefined,    VENDOR: '',                                 COUNTRY: '',            INCOTERMS: '',    FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: undefined,    SRD: undefined,    INSTALL: '', SHIP_MODE: '',         SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: '',   G_EREC: 'ER310', ER_PS: '2025-12-24', ER_PF: '2026-03-25', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 0,    DT_ETA: 0,     DT_SRD: 0, },
      { SN: 394, AREA: 'TOPSIDE', ZONE: 'SOUTH',   LEVEL: 'ER2', SVG: 'ER2', ID: 30, BLK: 'ER310', ALIGN: 'end',   TAG: '101870-EPNL-13310',      DESC: 'NA',                                                           PO_NO: '',            PO_DATE: undefined,    VENDOR: '',                                 COUNTRY: '',            INCOTERMS: '',    FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: undefined,    SRD: undefined,    INSTALL: '', SHIP_MODE: '',         SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: '',   G_EREC: 'ER310', ER_PS: '2025-12-24', ER_PF: '2026-03-25', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 0,    DT_ETA: 0,     DT_SRD: 0, },
      { SN: 395, AREA: 'TOPSIDE', ZONE: 'SOUTH',   LEVEL: 'ER2', SVG: 'ER2', ID: 31, BLK: 'ER310', ALIGN: 'end',   TAG: '101870-EPNL-12510',      DESC: 'NA',                                                           PO_NO: '',            PO_DATE: undefined,    VENDOR: '',                                 COUNTRY: '',            INCOTERMS: '',    FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: undefined,    SRD: undefined,    INSTALL: '', SHIP_MODE: '',         SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: '',   G_EREC: 'ER310', ER_PS: '2025-12-24', ER_PF: '2026-03-25', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 0,    DT_ETA: 0,     DT_SRD: 0, },
      { SN: 396, AREA: 'TOPSIDE', ZONE: 'SOUTH',   LEVEL: 'ER2', SVG: 'ER2', ID: 32, BLK: 'ER310', ALIGN: 'end',   TAG: '101820-EXFMR-62410',     DESC: 'NA',                                                           PO_NO: 'QE557BKE06',  PO_DATE: '2024-04-30', VENDOR: 'ABB AS',                           COUNTRY: 'Norway',      INCOTERMS: 'FOB', FAT_PLAN: '2025-03-10', FAT_FC: undefined,    FAT_ACT: undefined,    ETA: '2025-07-09', SRD: '2025-10-30', INSTALL: '', SHIP_MODE: 'by Sea',   SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: 'NA', G_EREC: 'ER310', ER_PS: '2025-12-24', ER_PF: '2026-03-25', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 113, DT_ETA: 168,  DT_SRD: 55, },
      { SN: 397, AREA: 'TOPSIDE', ZONE: 'SOUTH',   LEVEL: 'ER2', SVG: 'ER2', ID: 33, BLK: 'ER310', ALIGN: 'end',   TAG: '101780-CRIO-68325F',     DESC: 'NA',                                                           PO_NO: 'QU557BWI01',  PO_DATE: '2024-06-28', VENDOR: 'Honeywell Korea',                  COUNTRY: 'Korea',       INCOTERMS: 'DAP', FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: '2025-10-01', SRD: '2025-11-11', INSTALL: '', SHIP_MODE: 'NA',       SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: 'NA', G_EREC: 'ER310', ER_PS: '2025-12-24', ER_PF: '2026-03-25', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 41,  DT_ETA: 84,   DT_SRD: 43, },
      { SN: 398, AREA: 'TOPSIDE', ZONE: 'SOUTH',   LEVEL: 'ER2', SVG: 'ER2', ID: 34, BLK: 'ER310', ALIGN: 'end',   TAG: '101780-CRIO-68301F',     DESC: 'NA',                                                           PO_NO: 'QU557BWI01',  PO_DATE: '2024-06-28', VENDOR: 'Honeywell Korea',                  COUNTRY: 'Korea',       INCOTERMS: 'DAP', FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: '2025-10-01', SRD: '2025-11-11', INSTALL: '', SHIP_MODE: 'NA',       SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: 'NA', G_EREC: 'ER310', ER_PS: '2025-12-24', ER_PF: '2026-03-25', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 41,  DT_ETA: 84,   DT_SRD: 43, },
      { SN: 399, AREA: 'TOPSIDE', ZONE: 'SOUTH',   LEVEL: 'ER2', SVG: 'ER2', ID: 35, BLK: 'ER310', ALIGN: 'end',   TAG: '101780-CRIO-68350F',     DESC: 'NA',                                                           PO_NO: '',            PO_DATE: undefined,    VENDOR: '',                                 COUNTRY: '',            INCOTERMS: '',    FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: undefined,    SRD: undefined,    INSTALL: '', SHIP_MODE: '',         SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: '',   G_EREC: 'ER310', ER_PS: '2025-12-24', ER_PF: '2026-03-25', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 0,    DT_ETA: 0,     DT_SRD: 0, },
      { SN: 400, AREA: 'TOPSIDE', ZONE: 'SOUTH',   LEVEL: 'ER2', SVG: 'ER2', ID: 36, BLK: 'ER310', ALIGN: 'end',   TAG: '101610-ZZZ-50300',       DESC: 'NA',                                                           PO_NO: 'QE557AFQ82',  PO_DATE: '2024-04-19', VENDOR: 'Imenco',                           COUNTRY: 'Norway',      INCOTERMS: 'FOB', FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: '2025-10-18', SRD: '2025-10-08', INSTALL: '', SHIP_MODE: 'NA',       SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: 'NA', G_EREC: 'ER310', ER_PS: '2025-12-24', ER_PF: '2026-03-25', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: -10, DT_ETA: 67,   DT_SRD: 77, },
      { SN: 401, AREA: 'TOPSIDE', ZONE: 'SOUTH',   LEVEL: 'ER2', SVG: 'ER2', ID: 37, BLK: 'ER310', ALIGN: 'end',   TAG: '101780-CPNL-68300FRE',   DESC: 'NA',                                                           PO_NO: '',            PO_DATE: undefined,    VENDOR: '',                                 COUNTRY: '',            INCOTERMS: '',    FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: undefined,    SRD: undefined,    INSTALL: '', SHIP_MODE: '',         SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: '',   G_EREC: 'ER310', ER_PS: '2025-12-24', ER_PF: '2026-03-25', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 0,    DT_ETA: 0,     DT_SRD: 0, },
      { SN: 402, AREA: 'TOPSIDE', ZONE: 'SOUTH',   LEVEL: 'ER2', SVG: 'ER2', ID: 38, BLK: 'ER310', ALIGN: 'end',   TAG: '101870-NPNL-68325',      DESC: 'NA',                                                           PO_NO: 'QU557BWI01',  PO_DATE: '2024-06-28', VENDOR: 'Honeywell Korea',                  COUNTRY: 'Korea',       INCOTERMS: 'DAP', FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: '2025-10-01', SRD: '2025-11-11', INSTALL: '', SHIP_MODE: 'NA',       SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: 'NA', G_EREC: 'ER310', ER_PS: '2025-12-24', ER_PF: '2026-03-25', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 41,  DT_ETA: 84,   DT_SRD: 43, },
      { SN: 403, AREA: 'TOPSIDE', ZONE: 'SOUTH',   LEVEL: 'ER2', SVG: 'ER2', ID: 39, BLK: 'ER310', ALIGN: 'end',   TAG: '101870-NPNL-68323B',     DESC: 'NA',                                                           PO_NO: 'QU557BWI01',  PO_DATE: '2024-06-28', VENDOR: 'Honeywell Korea',                  COUNTRY: 'Korea',       INCOTERMS: 'DAP', FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: '2025-10-01', SRD: '2025-11-11', INSTALL: '', SHIP_MODE: 'NA',       SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: 'NA', G_EREC: 'ER310', ER_PS: '2025-12-24', ER_PF: '2026-03-25', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 41,  DT_ETA: 84,   DT_SRD: 43, },
      { SN: 404, AREA: 'TOPSIDE', ZONE: 'SOUTH',   LEVEL: 'ER2', SVG: 'ER2', ID: 40, BLK: 'ER310', ALIGN: 'end',   TAG: '101870-NPNL-68323A',     DESC: 'NA',                                                           PO_NO: 'QU557BWI01',  PO_DATE: '2024-06-28', VENDOR: 'Honeywell Korea',                  COUNTRY: 'Korea',       INCOTERMS: 'DAP', FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: '2025-10-01', SRD: '2025-11-11', INSTALL: '', SHIP_MODE: 'NA',       SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: 'NA', G_EREC: 'ER310', ER_PS: '2025-12-24', ER_PF: '2026-03-25', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 41,  DT_ETA: 84,   DT_SRD: 43, },
      { SN: 405, AREA: 'TOPSIDE', ZONE: 'SOUTH',   LEVEL: 'ER2', SVG: 'ER2', ID: 41, BLK: 'ER310', ALIGN: 'end',   TAG: '101870-NPNL-68988B',     DESC: 'NA',                                                           PO_NO: '',            PO_DATE: undefined,    VENDOR: '',                                 COUNTRY: '',            INCOTERMS: '',    FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: undefined,    SRD: undefined,    INSTALL: '', SHIP_MODE: '',         SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: '',   G_EREC: 'ER310', ER_PS: '2025-12-24', ER_PF: '2026-03-25', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 0,    DT_ETA: 0,     DT_SRD: 0, },
      { SN: 406, AREA: 'TOPSIDE', ZONE: 'SOUTH',   LEVEL: 'ER2', SVG: 'ER2', ID: 42, BLK: 'ER310', ALIGN: 'end',   TAG: '101870-NPNL-68988A',     DESC: 'NA',                                                           PO_NO: '',            PO_DATE: undefined,    VENDOR: '',                                 COUNTRY: '',            INCOTERMS: '',    FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: undefined,    SRD: undefined,    INSTALL: '', SHIP_MODE: '',         SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: '',   G_EREC: 'ER310', ER_PS: '2025-12-24', ER_PF: '2026-03-25', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 0,    DT_ETA: 0,     DT_SRD: 0, },
      { SN: 407, AREA: 'TOPSIDE', ZONE: 'SOUTH',   LEVEL: 'ER2', SVG: 'ER2', ID: 43, BLK: 'ER310', ALIGN: 'end',   TAG: '101870-NPNL-68324',      DESC: 'NA',                                                           PO_NO: 'QU557BWI01',  PO_DATE: '2024-06-28', VENDOR: 'Honeywell Korea',                  COUNTRY: 'Korea',       INCOTERMS: 'DAP', FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: '2025-10-01', SRD: '2025-11-11', INSTALL: '', SHIP_MODE: 'NA',       SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: 'NA', G_EREC: 'ER310', ER_PS: '2025-12-24', ER_PF: '2026-03-25', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 41,  DT_ETA: 84,   DT_SRD: 43, },
      { SN: 408, AREA: 'TOPSIDE', ZONE: 'SOUTH',   LEVEL: 'ER2', SVG: 'ER2', ID: 44, BLK: 'ER310', ALIGN: 'end',   TAG: '101850-EUPS-6257062580', DESC: 'NA',                                                           PO_NO: '',            PO_DATE: undefined,    VENDOR: '',                                 COUNTRY: '',            INCOTERMS: '',    FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: undefined,    SRD: undefined,    INSTALL: '', SHIP_MODE: '',         SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: '',   G_EREC: 'ER310', ER_PS: '2025-12-24', ER_PF: '2026-03-25', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 0,    DT_ETA: 0,     DT_SRD: 0, },
      { SN: 409, AREA: 'TOPSIDE', ZONE: 'SOUTH',   LEVEL: 'ER2', SVG: 'ER2', ID: 45, BLK: 'ER310', ALIGN: 'end',   TAG: '101960-EVSD-68921',      DESC: 'NA',                                                           PO_NO: '',            PO_DATE: undefined,    VENDOR: '',                                 COUNTRY: '',            INCOTERMS: '',    FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: undefined,    SRD: undefined,    INSTALL: '', SHIP_MODE: '',         SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: '',   G_EREC: 'ER310', ER_PS: '2025-12-24', ER_PF: '2026-03-25', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 0,    DT_ETA: 0,     DT_SRD: 0, },
      { SN: 410, AREA: 'TOPSIDE', ZONE: 'SOUTH',   LEVEL: 'ER2', SVG: 'ER2', ID: 46, BLK: 'ER310', ALIGN: 'end',   TAG: '101960-EVSD-68920',      DESC: 'NA',                                                           PO_NO: '',            PO_DATE: undefined,    VENDOR: '',                                 COUNTRY: '',            INCOTERMS: '',    FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: undefined,    SRD: undefined,    INSTALL: '', SHIP_MODE: '',         SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: '',   G_EREC: 'ER310', ER_PS: '2025-12-24', ER_PF: '2026-03-25', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 0,    DT_ETA: 0,     DT_SRD: 0, },
      { SN: 411, AREA: 'TOPSIDE', ZONE: 'SOUTH',   LEVEL: 'ER2', SVG: 'ER2', ID: 47, BLK: 'ER310', ALIGN: 'end',   TAG: '101850-EUPS-6251062520', DESC: 'NA',                                                           PO_NO: '',            PO_DATE: undefined,    VENDOR: '',                                 COUNTRY: '',            INCOTERMS: '',    FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: undefined,    SRD: undefined,    INSTALL: '', SHIP_MODE: '',         SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: '',   G_EREC: 'ER310', ER_PS: '2025-12-24', ER_PF: '2026-03-25', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 0,    DT_ETA: 0,     DT_SRD: 0, },
      { SN: 412, AREA: 'TOPSIDE', ZONE: 'SOUTH',   LEVEL: 'ER2', SVG: 'ER2', ID: 48, BLK: 'ER310', ALIGN: 'end',   TAG: '101840-EDB-62770',       DESC: 'SWITCHBOARD DC CONTROL POWER DISTRIBUTION BOARD',              PO_NO: 'QU557BKE7A',  PO_DATE: '2024-06-05', VENDOR: 'R.STAHL KOREA',                    COUNTRY: 'India',       INCOTERMS: 'FOB', FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: '2025-08-30', SRD: '2025-10-07', INSTALL: '', SHIP_MODE: 'NA',       SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: 'NA', G_EREC: 'ER310', ER_PS: '2025-12-24', ER_PF: '2026-03-25', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 38,  DT_ETA: 116,  DT_SRD: 78, },
      { SN: 413, AREA: 'TOPSIDE', ZONE: 'SOUTH',   LEVEL: 'ER2', SVG: 'ER2', ID: 49, BLK: 'ER310', ALIGN: 'end',   TAG: '101860-JTAR-776033',     DESC: 'NA',                                                           PO_NO: '',            PO_DATE: undefined,    VENDOR: '',                                 COUNTRY: '',            INCOTERMS: '',    FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: undefined,    SRD: undefined,    INSTALL: '', SHIP_MODE: '',         SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: '',   G_EREC: 'ER310', ER_PS: '2025-12-24', ER_PF: '2026-03-25', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 0,    DT_ETA: 0,     DT_SRD: 0, },
      { SN: 414, AREA: 'TOPSIDE', ZONE: 'SOUTH',   LEVEL: 'ER2', SVG: 'ER2', ID: 50, BLK: 'ER310', ALIGN: 'end',   TAG: '101850-EBC-62780',       DESC: 'SWITCHBOARD CONTROL POWER BATTERY CHARGER 2',                  PO_NO: 'QE557BCE03',  PO_DATE: '2024-05-02', VENDOR: 'Chloride',                         COUNTRY: 'France',      INCOTERMS: 'FOB', FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: '2025-06-15', SRD: '2025-10-30', INSTALL: '', SHIP_MODE: 'NA',       SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: 'NA', G_EREC: 'ER310', ER_PS: '2025-12-24', ER_PF: '2026-03-25', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 137, DT_ETA: 192,  DT_SRD: 55, },
      { SN: 415, AREA: 'TOPSIDE', ZONE: 'SOUTH',   LEVEL: 'ER2', SVG: 'ER2', ID: 51, BLK: 'ER310', ALIGN: 'end',   TAG: '101850-EBC-62770',       DESC: 'SWITCHBOARD CONTROL POWER BATTERY CHARGER 1',                  PO_NO: 'QE557BCE03',  PO_DATE: '2024-05-02', VENDOR: 'Chloride',                         COUNTRY: 'France',      INCOTERMS: 'FOB', FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: '2025-06-15', SRD: '2025-10-30', INSTALL: '', SHIP_MODE: 'NA',       SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: 'NA', G_EREC: 'ER310', ER_PS: '2025-12-24', ER_PF: '2026-03-25', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 137, DT_ETA: 192,  DT_SRD: 55, },
      { SN: 416, AREA: 'TOPSIDE', ZONE: 'SOUTH',   LEVEL: 'ER2', SVG: 'ER2', ID: 52, BLK: 'ER310', ALIGN: 'end',   TAG: '101850-EUPS-6254062550', DESC: 'NA',                                                           PO_NO: '',            PO_DATE: undefined,    VENDOR: '',                                 COUNTRY: '',            INCOTERMS: '',    FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: undefined,    SRD: undefined,    INSTALL: '', SHIP_MODE: '',         SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: '',   G_EREC: 'ER310', ER_PS: '2025-12-24', ER_PF: '2026-03-25', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 0,    DT_ETA: 0,     DT_SRD: 0, },
      { SN: 417, AREA: 'TOPSIDE', ZONE: 'SOUTH',   LEVEL: 'ER3', SVG: 'ER3', ID: 1,  BLK: 'ER310', ALIGN: 'start', TAG: '101800-EXFMR-62200',     DESC: '5 MVA LIQUID FILLED POWER TRANSFORMER (ESSENTIAL "B")',        PO_NO: 'QE557BKE04',  PO_DATE: '2024-04-30', VENDOR: 'ABB AS',                           COUNTRY: 'Norway',      INCOTERMS: 'FOB', FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: '2025-09-21', SRD: '2025-10-30', INSTALL: '', SHIP_MODE: 'NA',       SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: 'NA', G_EREC: 'ER310', ER_PS: '2025-12-24', ER_PF: '2026-03-25', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 39,  DT_ETA: 94,   DT_SRD: 55, },
      { SN: 418, AREA: 'TOPSIDE', ZONE: 'SOUTH',   LEVEL: 'ER3', SVG: 'ER3', ID: 2,  BLK: 'ER310', ALIGN: 'start', TAG: '101800-EXFMR-61200',     DESC: '5 MVA LIQUID FILLED POWER TRANSFORMER (NORMAL "B")',           PO_NO: 'QE557BKE04',  PO_DATE: '2024-04-30', VENDOR: 'ABB AS',                           COUNTRY: 'Norway',      INCOTERMS: 'FOB', FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: '2025-09-21', SRD: '2025-10-30', INSTALL: '', SHIP_MODE: 'NA',       SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: 'NA', G_EREC: 'ER310', ER_PS: '2025-12-24', ER_PF: '2026-03-25', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 39,  DT_ETA: 94,   DT_SRD: 55, },
      { SN: 419, AREA: 'TOPSIDE', ZONE: 'SOUTH',   LEVEL: 'ER3', SVG: 'ER3', ID: 3,  BLK: 'ER310', ALIGN: 'start', TAG: '101800-EXFMR-62100',     DESC: '5 MVA LIQUID FILLED POWER TRANSFORMER (ESSENTIAL "A")',        PO_NO: 'QE557BKE04',  PO_DATE: '2024-04-30', VENDOR: 'ABB AS',                           COUNTRY: 'Norway',      INCOTERMS: 'FOB', FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: '2025-09-21', SRD: '2025-10-30', INSTALL: '', SHIP_MODE: 'NA',       SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: 'NA', G_EREC: 'ER310', ER_PS: '2025-12-24', ER_PF: '2026-03-25', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 39,  DT_ETA: 94,   DT_SRD: 55, },
      { SN: 420, AREA: 'TOPSIDE', ZONE: 'SOUTH',   LEVEL: 'ER3', SVG: 'ER3', ID: 4,  BLK: 'ER310', ALIGN: 'start', TAG: '101800-EXFMR-61100',     DESC: '5 MVA LIQUID FILLED POWER TRANSFORMER (NORMAL "A")',           PO_NO: 'QE557BKE04',  PO_DATE: '2024-04-30', VENDOR: 'ABB AS',                           COUNTRY: 'Norway',      INCOTERMS: 'FOB', FAT_PLAN: '2025-05-26', FAT_FC: undefined,    FAT_ACT: undefined,    ETA: '2025-09-21', SRD: '2025-10-30', INSTALL: '', SHIP_MODE: 'by Sea',   SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: 'NA', G_EREC: 'ER310', ER_PS: '2025-12-24', ER_PF: '2026-03-25', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 39,  DT_ETA: 94,   DT_SRD: 55, },
      { SN: 421, AREA: 'TOPSIDE', ZONE: 'SOUTH',   LEVEL: 'ER3', SVG: 'ER3', ID: 5,  BLK: 'ER310', ALIGN: 'end',   TAG: '101970-HAL-71501',       DESC: 'NA',                                                           PO_NO: 'QU557BKH02',  PO_DATE: '2024-09-13', VENDOR: 'HI AIR',                           COUNTRY: 'Korea',       INCOTERMS: 'DAP', FAT_PLAN: '2025-05-19', FAT_FC: undefined,    FAT_ACT: undefined,    ETA: '2025-09-30', SRD: undefined,    INSTALL: '', SHIP_MODE: 'NA',       SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: 'NA', G_EREC: 'ER310', ER_PS: '2025-12-24', ER_PF: '2026-03-25', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 0,    DT_ETA: 85,   DT_SRD: 0, },
      { SN: 422, AREA: 'TOPSIDE', ZONE: 'SOUTH',   LEVEL: 'ER3', SVG: 'ER3', ID: 6,  BLK: 'ER310', ALIGN: 'end',   TAG: '101970-THAH-71501',      DESC: 'NA',                                                           PO_NO: 'QU557BKH02',  PO_DATE: '2024-09-13', VENDOR: 'HI AIR',                           COUNTRY: 'Korea',       INCOTERMS: 'DAP', FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: '2025-09-30', SRD: undefined,    INSTALL: '', SHIP_MODE: 'NA',       SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: 'NA', G_EREC: 'ER310', ER_PS: '2025-12-24', ER_PF: '2026-03-25', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 0,    DT_ETA: 85,   DT_SRD: 0, },
      { SN: 423, AREA: 'TOPSIDE', ZONE: 'SOUTH',   LEVEL: 'ER3', SVG: 'ER3', ID: 7,  BLK: 'ER310', ALIGN: 'end',   TAG: '101970-HAL-71502',       DESC: 'NA',                                                           PO_NO: 'QU557BKH02',  PO_DATE: '2024-09-13', VENDOR: 'HI AIR',                           COUNTRY: 'Korea',       INCOTERMS: 'DAP', FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: '2025-09-30', SRD: undefined,    INSTALL: '', SHIP_MODE: 'NA',       SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: 'NA', G_EREC: 'ER310', ER_PS: '2025-12-24', ER_PF: '2026-03-25', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 0,    DT_ETA: 85,   DT_SRD: 0, },
      { SN: 424, AREA: 'TOPSIDE', ZONE: 'SOUTH',   LEVEL: 'ER3', SVG: 'ER3', ID: 8,  BLK: 'ER310', ALIGN: 'end',   TAG: '101970-THAH-71502',      DESC: 'NA',                                                           PO_NO: 'QU557BKH02',  PO_DATE: '2024-09-13', VENDOR: 'HI AIR',                           COUNTRY: 'Korea',       INCOTERMS: 'DAP', FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: '2025-09-30', SRD: undefined,    INSTALL: '', SHIP_MODE: 'NA',       SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: 'NA', G_EREC: 'ER310', ER_PS: '2025-12-24', ER_PF: '2026-03-25', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 0,    DT_ETA: 85,   DT_SRD: 0, },
      { SN: 425, AREA: 'LQ',      ZONE: 'LQ',      LEVEL: 'F1',  SVG: 'F1',  ID: 1,  BLK: 'LD110', ALIGN: 'start', TAG: '101850-EDB-71112A',      DESC: 'NA',                                                           PO_NO: 'QU557BKE7A',  PO_DATE: '2024-06-05', VENDOR: 'R.STAHL KOREA',                    COUNTRY: 'India',       INCOTERMS: 'FOB', FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: '2025-08-30', SRD: '2025-09-22', INSTALL: '', SHIP_MODE: 'NA',       SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: 'NA', G_EREC: 'LD110', ER_PS: '2025-05-28', ER_PF: '2026-05-08', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 23,  DT_ETA: -94,  DT_SRD: -117, },
      { SN: 426, AREA: 'LQ',      ZONE: 'LQ',      LEVEL: 'F1',  SVG: 'F1',  ID: 2,  BLK: 'LD110', ALIGN: 'start', TAG: '101850-EDB-71114A',      DESC: 'NA',                                                           PO_NO: 'QU557BKE7A',  PO_DATE: '2024-06-05', VENDOR: 'R.STAHL KOREA',                    COUNTRY: 'India',       INCOTERMS: 'FOB', FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: '2025-08-30', SRD: '2025-09-22', INSTALL: '', SHIP_MODE: 'NA',       SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: 'NA', G_EREC: 'LD110', ER_PS: '2025-05-28', ER_PF: '2026-05-08', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 23,  DT_ETA: -94,  DT_SRD: -117, },
      { SN: 427, AREA: 'LQ',      ZONE: 'LQ',      LEVEL: 'F1',  SVG: 'F1',  ID: 3,  BLK: 'LD110', ALIGN: 'start', TAG: '101850-EDB-71112B',      DESC: 'NA',                                                           PO_NO: 'QU557BKE7A',  PO_DATE: '2024-06-05', VENDOR: 'R.STAHL KOREA',                    COUNTRY: 'India',       INCOTERMS: 'FOB', FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: '2025-08-30', SRD: '2025-09-22', INSTALL: '', SHIP_MODE: 'NA',       SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: 'NA', G_EREC: 'LD110', ER_PS: '2025-05-28', ER_PF: '2026-05-08', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 23,  DT_ETA: -94,  DT_SRD: -117, },
      { SN: 428, AREA: 'LQ',      ZONE: 'LQ',      LEVEL: 'F1',  SVG: 'F1',  ID: 4,  BLK: 'LD110', ALIGN: 'start', TAG: '101850-EDB-71114B',      DESC: 'NA',                                                           PO_NO: 'QU557BKE7A',  PO_DATE: '2024-06-05', VENDOR: 'R.STAHL KOREA',                    COUNTRY: 'India',       INCOTERMS: 'FOB', FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: '2025-08-30', SRD: '2025-09-22', INSTALL: '', SHIP_MODE: 'NA',       SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: 'NA', G_EREC: 'LD110', ER_PS: '2025-05-28', ER_PF: '2026-05-08', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 23,  DT_ETA: -94,  DT_SRD: -117, },
      { SN: 429, AREA: 'LQ',      ZONE: 'LQ',      LEVEL: 'F1',  SVG: 'F1',  ID: 5,  BLK: 'LD110', ALIGN: 'start', TAG: '101870-NPNL-69624BI',    DESC: 'NA',                                                           PO_NO: '',            PO_DATE: undefined,    VENDOR: '',                                 COUNTRY: '',            INCOTERMS: '',    FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: undefined,    SRD: undefined,    INSTALL: '', SHIP_MODE: '',         SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: '',   G_EREC: 'LD110', ER_PS: '2025-05-28', ER_PF: '2026-05-08', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 0,    DT_ETA: 0,     DT_SRD: 0, },
      { SN: 430, AREA: 'LQ',      ZONE: 'LQ',      LEVEL: 'F1',  SVG: 'F1',  ID: 6,  BLK: 'LD110', ALIGN: 'start', TAG: '101860-JTCAB-750012',    DESC: 'NA',                                                           PO_NO: '',            PO_DATE: undefined,    VENDOR: '',                                 COUNTRY: '',            INCOTERMS: '',    FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: undefined,    SRD: undefined,    INSTALL: '', SHIP_MODE: '',         SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: '',   G_EREC: 'LD110', ER_PS: '2025-05-28', ER_PF: '2026-05-08', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 0,    DT_ETA: 0,     DT_SRD: 0, },
      { SN: 431, AREA: 'LQ',      ZONE: 'LQ',      LEVEL: 'F1',  SVG: 'F1',  ID: 7,  BLK: 'LD110', ALIGN: 'start', TAG: '101860-JTCAB-750512',    DESC: 'NA',                                                           PO_NO: '',            PO_DATE: undefined,    VENDOR: '',                                 COUNTRY: '',            INCOTERMS: '',    FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: undefined,    SRD: undefined,    INSTALL: '', SHIP_MODE: '',         SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: '',   G_EREC: 'LD110', ER_PS: '2025-05-28', ER_PF: '2026-05-08', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 0,    DT_ETA: 0,     DT_SRD: 0, },
      { SN: 432, AREA: 'LQ',      ZONE: 'LQ',      LEVEL: 'F1',  SVG: 'F1',  ID: 8,  BLK: 'LD110', ALIGN: 'start', TAG: '101870-NPNL-69680BI',    DESC: 'NA',                                                           PO_NO: '',            PO_DATE: undefined,    VENDOR: '',                                 COUNTRY: '',            INCOTERMS: '',    FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: undefined,    SRD: undefined,    INSTALL: '', SHIP_MODE: '',         SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: '',   G_EREC: 'LD110', ER_PS: '2025-05-28', ER_PF: '2026-05-08', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 0,    DT_ETA: 0,     DT_SRD: 0, },
      { SN: 433, AREA: 'LQ',      ZONE: 'LQ',      LEVEL: 'F1',  SVG: 'F1',  ID: 9,  BLK: 'LD110', ALIGN: 'start', TAG: '101860-JTCAB-750508',    DESC: 'NA',                                                           PO_NO: '',            PO_DATE: undefined,    VENDOR: '',                                 COUNTRY: '',            INCOTERMS: '',    FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: undefined,    SRD: undefined,    INSTALL: '', SHIP_MODE: '',         SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: '',   G_EREC: 'LD110', ER_PS: '2025-05-28', ER_PF: '2026-05-08', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 0,    DT_ETA: 0,     DT_SRD: 0, },
      { SN: 434, AREA: 'LQ',      ZONE: 'LQ',      LEVEL: 'F1',  SVG: 'F1',  ID: 10, BLK: 'LD110', ALIGN: 'start', TAG: '101860-JTCAB-750010',    DESC: 'NA',                                                           PO_NO: '',            PO_DATE: undefined,    VENDOR: '',                                 COUNTRY: '',            INCOTERMS: '',    FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: undefined,    SRD: undefined,    INSTALL: '', SHIP_MODE: '',         SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: '',   G_EREC: 'LD110', ER_PS: '2025-05-28', ER_PF: '2026-05-08', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 0,    DT_ETA: 0,     DT_SRD: 0, },
      { SN: 435, AREA: 'LQ',      ZONE: 'LQ',      LEVEL: 'F1',  SVG: 'F1',  ID: 11, BLK: 'LD110', ALIGN: 'start', TAG: '101860-JTCAB-750020',    DESC: 'NA',                                                           PO_NO: '',            PO_DATE: undefined,    VENDOR: '',                                 COUNTRY: '',            INCOTERMS: '',    FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: undefined,    SRD: undefined,    INSTALL: '', SHIP_MODE: '',         SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: '',   G_EREC: 'LD110', ER_PS: '2025-05-28', ER_PF: '2026-05-08', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 0,    DT_ETA: 0,     DT_SRD: 0, },
      { SN: 436, AREA: 'LQ',      ZONE: 'LQ',      LEVEL: 'F1',  SVG: 'F1',  ID: 12, BLK: 'LD110', ALIGN: 'start', TAG: '101860-JTCAB-750231',    DESC: 'NA',                                                           PO_NO: '',            PO_DATE: undefined,    VENDOR: '',                                 COUNTRY: '',            INCOTERMS: '',    FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: undefined,    SRD: undefined,    INSTALL: '', SHIP_MODE: '',         SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: '',   G_EREC: 'LD110', ER_PS: '2025-05-28', ER_PF: '2026-05-08', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 0,    DT_ETA: 0,     DT_SRD: 0, },
      { SN: 437, AREA: 'LQ',      ZONE: 'LQ',      LEVEL: 'F1',  SVG: 'F1',  ID: 13, BLK: 'LD110', ALIGN: 'start', TAG: '101860-JTCAB-750232',    DESC: 'NA',                                                           PO_NO: '',            PO_DATE: undefined,    VENDOR: '',                                 COUNTRY: '',            INCOTERMS: '',    FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: undefined,    SRD: undefined,    INSTALL: '', SHIP_MODE: '',         SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: '',   G_EREC: 'LD110', ER_PS: '2025-05-28', ER_PF: '2026-05-08', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 0,    DT_ETA: 0,     DT_SRD: 0, },
      { SN: 438, AREA: 'LQ',      ZONE: 'LQ',      LEVEL: 'F1',  SVG: 'F1',  ID: 14, BLK: 'LD110', ALIGN: 'start', TAG: '101860-JTCAB-750004',    DESC: 'NA',                                                           PO_NO: '',            PO_DATE: undefined,    VENDOR: '',                                 COUNTRY: '',            INCOTERMS: '',    FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: undefined,    SRD: undefined,    INSTALL: '', SHIP_MODE: '',         SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: '',   G_EREC: 'LD110', ER_PS: '2025-05-28', ER_PF: '2026-05-08', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 0,    DT_ETA: 0,     DT_SRD: 0, },
      { SN: 439, AREA: 'LQ',      ZONE: 'LQ',      LEVEL: 'F1',  SVG: 'F1',  ID: 15, BLK: 'LD110', ALIGN: 'start', TAG: '101860-JTCAB-750005',    DESC: 'NA',                                                           PO_NO: 'QD557BKT01',  PO_DATE: '2024-09-04', VENDOR: 'OMEGA',                            COUNTRY: 'Singapore',   INCOTERMS: 'FOB', FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: '2025-08-30', SRD: '2025-10-06', INSTALL: '', SHIP_MODE: 'by Sea',   SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: 'NA', G_EREC: 'LD110', ER_PS: '2025-05-28', ER_PF: '2026-05-08', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 37,  DT_ETA: -94,  DT_SRD: -131, },
      { SN: 440, AREA: 'LQ',      ZONE: 'LQ',      LEVEL: 'F1',  SVG: 'F1',  ID: 16, BLK: 'LD110', ALIGN: 'start', TAG: '101860-JTCAB-750006',    DESC: 'NA',                                                           PO_NO: '',            PO_DATE: undefined,    VENDOR: '',                                 COUNTRY: '',            INCOTERMS: '',    FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: undefined,    SRD: undefined,    INSTALL: '', SHIP_MODE: '',         SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: '',   G_EREC: 'LD110', ER_PS: '2025-05-28', ER_PF: '2026-05-08', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 0,    DT_ETA: 0,     DT_SRD: 0, },
      { SN: 441, AREA: 'LQ',      ZONE: 'LQ',      LEVEL: 'F1',  SVG: 'F1',  ID: 17, BLK: 'LD110', ALIGN: 'start', TAG: '101860-JTCAB-750229',    DESC: 'NA',                                                           PO_NO: '',            PO_DATE: undefined,    VENDOR: '',                                 COUNTRY: '',            INCOTERMS: '',    FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: undefined,    SRD: undefined,    INSTALL: '', SHIP_MODE: '',         SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: '',   G_EREC: 'LD110', ER_PS: '2025-05-28', ER_PF: '2026-05-08', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 0,    DT_ETA: 0,     DT_SRD: 0, },
      { SN: 442, AREA: 'LQ',      ZONE: 'LQ',      LEVEL: 'F1',  SVG: 'F1',  ID: 18, BLK: 'LD110', ALIGN: 'start', TAG: '101860-JTCAB-750230',    DESC: 'NA',                                                           PO_NO: '',            PO_DATE: undefined,    VENDOR: '',                                 COUNTRY: '',            INCOTERMS: '',    FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: undefined,    SRD: undefined,    INSTALL: '', SHIP_MODE: '',         SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: '',   G_EREC: 'LD110', ER_PS: '2025-05-28', ER_PF: '2026-05-08', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 0,    DT_ETA: 0,     DT_SRD: 0, },
      { SN: 443, AREA: 'LQ',      ZONE: 'LQ',      LEVEL: 'F1',  SVG: 'F1',  ID: 19, BLK: 'LD110', ALIGN: 'start', TAG: '101270-LPNL-64376I',     DESC: 'NA',                                                           PO_NO: '',            PO_DATE: undefined,    VENDOR: '',                                 COUNTRY: '',            INCOTERMS: '',    FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: undefined,    SRD: undefined,    INSTALL: '', SHIP_MODE: '',         SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: '',   G_EREC: 'LD110', ER_PS: '2025-05-28', ER_PF: '2026-05-08', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 0,    DT_ETA: 0,     DT_SRD: 0, },
      { SN: 444, AREA: 'LQ',      ZONE: 'LQ',      LEVEL: 'F1',  SVG: 'F1',  ID: 20, BLK: 'LD110', ALIGN: 'start', TAG: '101860-JTCAB-750023T',   DESC: 'NA',                                                           PO_NO: '',            PO_DATE: undefined,    VENDOR: '',                                 COUNTRY: '',            INCOTERMS: '',    FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: undefined,    SRD: undefined,    INSTALL: '', SHIP_MODE: '',         SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: '',   G_EREC: 'LD110', ER_PS: '2025-05-28', ER_PF: '2026-05-08', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 0,    DT_ETA: 0,     DT_SRD: 0, },
      { SN: 445, AREA: 'LQ',      ZONE: 'LQ',      LEVEL: 'F1',  SVG: 'F1',  ID: 21, BLK: 'LD110', ALIGN: 'start', TAG: '101780-CPNL-69900I',     DESC: 'NA',                                                           PO_NO: '',            PO_DATE: undefined,    VENDOR: '',                                 COUNTRY: '',            INCOTERMS: '',    FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: undefined,    SRD: undefined,    INSTALL: '', SHIP_MODE: '',         SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: '',   G_EREC: 'LD110', ER_PS: '2025-05-28', ER_PF: '2026-05-08', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 0,    DT_ETA: 0,     DT_SRD: 0, },
      { SN: 446, AREA: 'LQ',      ZONE: 'LQ',      LEVEL: 'F1',  SVG: 'F1',  ID: 22, BLK: 'LD110', ALIGN: 'start', TAG: '101860-JTCAB-750022T',   DESC: 'NA',                                                           PO_NO: '',            PO_DATE: undefined,    VENDOR: '',                                 COUNTRY: '',            INCOTERMS: '',    FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: undefined,    SRD: undefined,    INSTALL: '', SHIP_MODE: '',         SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: '',   G_EREC: 'LD110', ER_PS: '2025-05-28', ER_PF: '2026-05-08', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 0,    DT_ETA: 0,     DT_SRD: 0, },
      { SN: 447, AREA: 'LQ',      ZONE: 'LQ',      LEVEL: 'F1',  SVG: 'F1',  ID: 23, BLK: 'LD110', ALIGN: 'start', TAG: '101700-FACP-71000I',     DESC: 'NA',                                                           PO_NO: '',            PO_DATE: undefined,    VENDOR: '',                                 COUNTRY: '',            INCOTERMS: '',    FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: undefined,    SRD: undefined,    INSTALL: '', SHIP_MODE: '',         SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: '',   G_EREC: 'LD110', ER_PS: '2025-05-28', ER_PF: '2026-05-08', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 0,    DT_ETA: 0,     DT_SRD: 0, },
      { SN: 448, AREA: 'LQ',      ZONE: 'LQ',      LEVEL: 'F1',  SVG: 'F1',  ID: 24, BLK: 'LD110', ALIGN: 'start', TAG: '101700-FPNL-69800I',     DESC: 'NA',                                                           PO_NO: '',            PO_DATE: undefined,    VENDOR: '',                                 COUNTRY: '',            INCOTERMS: '',    FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: undefined,    SRD: undefined,    INSTALL: '', SHIP_MODE: '',         SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: '',   G_EREC: 'LD110', ER_PS: '2025-05-28', ER_PF: '2026-05-08', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 0,    DT_ETA: 0,     DT_SRD: 0, },
      { SN: 449, AREA: 'LQ',      ZONE: 'LQ',      LEVEL: 'F1',  SVG: 'F1',  ID: 25, BLK: 'LD110', ALIGN: 'start', TAG: '101790-SPNL-69700I',     DESC: 'NA',                                                           PO_NO: '',            PO_DATE: undefined,    VENDOR: '',                                 COUNTRY: '',            INCOTERMS: '',    FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: undefined,    SRD: undefined,    INSTALL: '', SHIP_MODE: '',         SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: '',   G_EREC: 'LD110', ER_PS: '2025-05-28', ER_PF: '2026-05-08', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 0,    DT_ETA: 0,     DT_SRD: 0, },
      { SN: 450, AREA: 'LQ',      ZONE: 'LQ',      LEVEL: 'F1',  SVG: 'F1',  ID: 26, BLK: 'LD110', ALIGN: 'start', TAG: '101820-EDB-71223',       DESC: 'NA',                                                           PO_NO: 'QU557BKE7A',  PO_DATE: '2024-06-05', VENDOR: 'R.STAHL KOREA',                    COUNTRY: 'India',       INCOTERMS: 'FOB', FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: '2025-08-30', SRD: '2025-09-22', INSTALL: '', SHIP_MODE: 'NA',       SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: 'NA', G_EREC: 'LD110', ER_PS: '2025-05-28', ER_PF: '2026-05-08', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 23,  DT_ETA: -94,  DT_SRD: -117, },
      { SN: 451, AREA: 'LQ',      ZONE: 'LQ',      LEVEL: 'F1',  SVG: 'F1',  ID: 27, BLK: 'LD110', ALIGN: 'start', TAG: '101820-EDB-71212',       DESC: 'NA',                                                           PO_NO: 'QU557BKE7A',  PO_DATE: '2024-06-05', VENDOR: 'R.STAHL KOREA',                    COUNTRY: 'India',       INCOTERMS: 'FOB', FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: '2025-08-30', SRD: '2025-09-22', INSTALL: '', SHIP_MODE: 'NA',       SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: 'NA', G_EREC: 'LD110', ER_PS: '2025-05-28', ER_PF: '2026-05-08', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 23,  DT_ETA: -94,  DT_SRD: -117, },
      { SN: 452, AREA: 'LQ',      ZONE: 'LQ',      LEVEL: 'F1',  SVG: 'F1',  ID: 28, BLK: 'LD110', ALIGN: 'start', TAG: '101720-TQZ-87071',       DESC: 'NA',                                                           PO_NO: '',            PO_DATE: undefined,    VENDOR: '',                                 COUNTRY: '',            INCOTERMS: '',    FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: undefined,    SRD: undefined,    INSTALL: '', SHIP_MODE: '',         SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: '',   G_EREC: 'LD110', ER_PS: '2025-05-28', ER_PF: '2026-05-08', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 0,    DT_ETA: 0,     DT_SRD: 0, },
      { SN: 453, AREA: 'LQ',      ZONE: 'LQ',      LEVEL: 'F1',  SVG: 'F1',  ID: 29, BLK: 'LD110', ALIGN: 'start', TAG: '101860-JTCAB-750021T',   DESC: 'NA',                                                           PO_NO: '',            PO_DATE: undefined,    VENDOR: '',                                 COUNTRY: '',            INCOTERMS: '',    FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: undefined,    SRD: undefined,    INSTALL: '', SHIP_MODE: '',         SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: '',   G_EREC: 'LD110', ER_PS: '2025-05-28', ER_PF: '2026-05-08', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 0,    DT_ETA: 0,     DT_SRD: 0, },
      { SN: 454, AREA: 'LQ',      ZONE: 'LQ',      LEVEL: 'F1',  SVG: 'F1',  ID: 30, BLK: 'LD110', ALIGN: 'start', TAG: '101860-JTCAB-750011T',   DESC: 'NA',                                                           PO_NO: '',            PO_DATE: undefined,    VENDOR: '',                                 COUNTRY: '',            INCOTERMS: '',    FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: undefined,    SRD: undefined,    INSTALL: '', SHIP_MODE: '',         SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: '',   G_EREC: 'LD110', ER_PS: '2025-05-28', ER_PF: '2026-05-08', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 0,    DT_ETA: 0,     DT_SRD: 0, },
      { SN: 455, AREA: 'LQ',      ZONE: 'LQ',      LEVEL: 'F1',  SVG: 'F1',  ID: 31, BLK: 'LD110', ALIGN: 'start', TAG: '101860-JTCAB-750014T',   DESC: 'NA',                                                           PO_NO: '',            PO_DATE: undefined,    VENDOR: '',                                 COUNTRY: '',            INCOTERMS: '',    FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: undefined,    SRD: undefined,    INSTALL: '', SHIP_MODE: '',         SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: '',   G_EREC: 'LD110', ER_PS: '2025-05-28', ER_PF: '2026-05-08', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 0,    DT_ETA: 0,     DT_SRD: 0, },
      { SN: 456, AREA: 'LQ',      ZONE: 'LQ',      LEVEL: 'F1',  SVG: 'F1',  ID: 32, BLK: 'LD110', ALIGN: 'start', TAG: '101860-JTCAB-750013T',   DESC: 'NA',                                                           PO_NO: '',            PO_DATE: undefined,    VENDOR: '',                                 COUNTRY: '',            INCOTERMS: '',    FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: undefined,    SRD: undefined,    INSTALL: '', SHIP_MODE: '',         SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: '',   G_EREC: 'LD110', ER_PS: '2025-05-28', ER_PF: '2026-05-08', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 0,    DT_ETA: 0,     DT_SRD: 0, },
      { SN: 457, AREA: 'LQ',      ZONE: 'LQ',      LEVEL: 'F1',  SVG: 'F1',  ID: 33, BLK: 'LD110', ALIGN: 'start', TAG: '101870-NPNL-69624AI',    DESC: 'NA',                                                           PO_NO: '',            PO_DATE: undefined,    VENDOR: '',                                 COUNTRY: '',            INCOTERMS: '',    FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: undefined,    SRD: undefined,    INSTALL: '', SHIP_MODE: '',         SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: '',   G_EREC: 'LD110', ER_PS: '2025-05-28', ER_PF: '2026-05-08', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 0,    DT_ETA: 0,     DT_SRD: 0, },
      { SN: 458, AREA: 'LQ',      ZONE: 'LQ',      LEVEL: 'F1',  SVG: 'F1',  ID: 34, BLK: 'LD110', ALIGN: 'end',   TAG: '101850-EDB-71110A',      DESC: 'NA',                                                           PO_NO: '',            PO_DATE: undefined,    VENDOR: '',                                 COUNTRY: '',            INCOTERMS: '',    FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: undefined,    SRD: undefined,    INSTALL: '', SHIP_MODE: '',         SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: '',   G_EREC: 'LD110', ER_PS: '2025-05-28', ER_PF: '2026-05-08', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 0,    DT_ETA: 0,     DT_SRD: 0, },
      { SN: 459, AREA: 'LQ',      ZONE: 'LQ',      LEVEL: 'F1',  SVG: 'F1',  ID: 35, BLK: 'LD110', ALIGN: 'end',   TAG: '101850-EDB-71113A',      DESC: 'NA',                                                           PO_NO: 'QU557BWI01',  PO_DATE: '2024-06-28', VENDOR: 'Honeywell Korea',                  COUNTRY: 'Korea',       INCOTERMS: 'DAP', FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: '2025-10-01', SRD: '2025-10-30', INSTALL: '', SHIP_MODE: 'NA',       SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: 'NA', G_EREC: 'LD110', ER_PS: '2025-05-28', ER_PF: '2026-05-08', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 29,  DT_ETA: -126, DT_SRD: -155, },
      { SN: 460, AREA: 'LQ',      ZONE: 'LQ',      LEVEL: 'F1',  SVG: 'F1',  ID: 36, BLK: 'LD110', ALIGN: 'end',   TAG: '101850-EDB-71110B',      DESC: 'NA',                                                           PO_NO: 'QU557BWI01',  PO_DATE: '2024-06-28', VENDOR: 'Honeywell Korea',                  COUNTRY: 'Korea',       INCOTERMS: 'DAP', FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: '2025-10-01', SRD: '2025-10-30', INSTALL: '', SHIP_MODE: 'NA',       SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: 'NA', G_EREC: 'LD110', ER_PS: '2025-05-28', ER_PF: '2026-05-08', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 29,  DT_ETA: -126, DT_SRD: -155, },
      { SN: 461, AREA: 'LQ',      ZONE: 'LQ',      LEVEL: 'F1',  SVG: 'F1',  ID: 37, BLK: 'LD110', ALIGN: 'end',   TAG: '101850-EDB-71113B',      DESC: 'NA',                                                           PO_NO: 'QU557BWI01',  PO_DATE: '2024-06-28', VENDOR: 'Honeywell Korea',                  COUNTRY: 'Korea',       INCOTERMS: 'DAP', FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: '2025-10-01', SRD: '2025-10-30', INSTALL: '', SHIP_MODE: 'NA',       SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: 'NA', G_EREC: 'LD110', ER_PS: '2025-05-28', ER_PF: '2026-05-08', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 29,  DT_ETA: -126, DT_SRD: -155, },
      { SN: 462, AREA: 'LQ',      ZONE: 'LQ',      LEVEL: 'F1',  SVG: 'F1',  ID: 38, BLK: 'LD110', ALIGN: 'end',   TAG: '101870-NPNL-69989AT',    DESC: 'NA',                                                           PO_NO: '',            PO_DATE: undefined,    VENDOR: '',                                 COUNTRY: '',            INCOTERMS: '',    FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: undefined,    SRD: undefined,    INSTALL: '', SHIP_MODE: '',         SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: '',   G_EREC: 'LD110', ER_PS: '2025-05-28', ER_PF: '2026-05-08', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 0,    DT_ETA: 0,     DT_SRD: 0, },
      { SN: 463, AREA: 'LQ',      ZONE: 'LQ',      LEVEL: 'F1',  SVG: 'F1',  ID: 39, BLK: 'LD110', ALIGN: 'end',   TAG: '101870-NPNL-69970AI',    DESC: 'NA',                                                           PO_NO: '',            PO_DATE: undefined,    VENDOR: '',                                 COUNTRY: '',            INCOTERMS: '',    FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: undefined,    SRD: undefined,    INSTALL: '', SHIP_MODE: '',         SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: '',   G_EREC: 'LD110', ER_PS: '2025-05-28', ER_PF: '2026-05-08', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 0,    DT_ETA: 0,     DT_SRD: 0, },
      { SN: 464, AREA: 'LQ',      ZONE: 'LQ',      LEVEL: 'F1',  SVG: 'F1',  ID: 40, BLK: 'LD110', ALIGN: 'end',   TAG: '101870-NPNL-69971AI',    DESC: 'NA',                                                           PO_NO: '',            PO_DATE: undefined,    VENDOR: '',                                 COUNTRY: '',            INCOTERMS: '',    FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: undefined,    SRD: undefined,    INSTALL: '', SHIP_MODE: '',         SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: '',   G_EREC: 'LD110', ER_PS: '2025-05-28', ER_PF: '2026-05-08', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 0,    DT_ETA: 0,     DT_SRD: 0, },
      { SN: 465, AREA: 'LQ',      ZONE: 'LQ',      LEVEL: 'F1',  SVG: 'F1',  ID: 41, BLK: 'LD110', ALIGN: 'end',   TAG: '101870-NPNL-69674AI',    DESC: 'NA',                                                           PO_NO: '',            PO_DATE: undefined,    VENDOR: '',                                 COUNTRY: '',            INCOTERMS: '',    FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: undefined,    SRD: undefined,    INSTALL: '', SHIP_MODE: '',         SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: '',   G_EREC: 'LD110', ER_PS: '2025-05-28', ER_PF: '2026-05-08', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 0,    DT_ETA: 0,     DT_SRD: 0, },
      { SN: 466, AREA: 'LQ',      ZONE: 'LQ',      LEVEL: 'F1',  SVG: 'F1',  ID: 42, BLK: 'LD110', ALIGN: 'end',   TAG: '101870-NPNL-69649AI',    DESC: 'NA',                                                           PO_NO: '',            PO_DATE: undefined,    VENDOR: '',                                 COUNTRY: '',            INCOTERMS: '',    FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: undefined,    SRD: undefined,    INSTALL: '', SHIP_MODE: '',         SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: '',   G_EREC: 'LD110', ER_PS: '2025-05-28', ER_PF: '2026-05-08', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 0,    DT_ETA: 0,     DT_SRD: 0, },
      { SN: 467, AREA: 'LQ',      ZONE: 'LQ',      LEVEL: 'F1',  SVG: 'F1',  ID: 43, BLK: 'LD110', ALIGN: 'end',   TAG: '101700-FPNL-69825RI',    DESC: 'NA',                                                           PO_NO: '',            PO_DATE: undefined,    VENDOR: '',                                 COUNTRY: '',            INCOTERMS: '',    FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: undefined,    SRD: undefined,    INSTALL: '', SHIP_MODE: '',         SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: '',   G_EREC: 'LD110', ER_PS: '2025-05-28', ER_PF: '2026-05-08', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 0,    DT_ETA: 0,     DT_SRD: 0, },
      { SN: 468, AREA: 'LQ',      ZONE: 'LQ',      LEVEL: 'F1',  SVG: 'F1',  ID: 44, BLK: 'LD110', ALIGN: 'end',   TAG: '101700-FPNL-69850FI',    DESC: 'NA',                                                           PO_NO: '',            PO_DATE: undefined,    VENDOR: '',                                 COUNTRY: '',            INCOTERMS: '',    FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: undefined,    SRD: undefined,    INSTALL: '', SHIP_MODE: '',         SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: '',   G_EREC: 'LD110', ER_PS: '2025-05-28', ER_PF: '2026-05-08', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 0,    DT_ETA: 0,     DT_SRD: 0, },
      { SN: 469, AREA: 'LQ',      ZONE: 'LQ',      LEVEL: 'F1',  SVG: 'F1',  ID: 45, BLK: 'LD110', ALIGN: 'end',   TAG: '101790-SPNL-69726RI',    DESC: 'NA',                                                           PO_NO: '',            PO_DATE: undefined,    VENDOR: '',                                 COUNTRY: '',            INCOTERMS: '',    FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: undefined,    SRD: undefined,    INSTALL: '', SHIP_MODE: '',         SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: '',   G_EREC: 'LD110', ER_PS: '2025-05-28', ER_PF: '2026-05-08', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 0,    DT_ETA: 0,     DT_SRD: 0, },
      { SN: 470, AREA: 'LQ',      ZONE: 'LQ',      LEVEL: 'F1',  SVG: 'F1',  ID: 46, BLK: 'LD110', ALIGN: 'end',   TAG: '101790-SPNL-69725RI',    DESC: 'NA',                                                           PO_NO: '',            PO_DATE: undefined,    VENDOR: '',                                 COUNTRY: '',            INCOTERMS: '',    FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: undefined,    SRD: undefined,    INSTALL: '', SHIP_MODE: '',         SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: '',   G_EREC: 'LD110', ER_PS: '2025-05-28', ER_PF: '2026-05-08', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 0,    DT_ETA: 0,     DT_SRD: 0, },
      { SN: 471, AREA: 'LQ',      ZONE: 'LQ',      LEVEL: 'F1',  SVG: 'F1',  ID: 47, BLK: 'LD110', ALIGN: 'end',   TAG: '101790-SPNL-69751FI',    DESC: 'NA',                                                           PO_NO: '',            PO_DATE: undefined,    VENDOR: '',                                 COUNTRY: '',            INCOTERMS: '',    FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: undefined,    SRD: undefined,    INSTALL: '', SHIP_MODE: '',         SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: '',   G_EREC: 'LD110', ER_PS: '2025-05-28', ER_PF: '2026-05-08', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 0,    DT_ETA: 0,     DT_SRD: 0, },
      { SN: 472, AREA: 'LQ',      ZONE: 'LQ',      LEVEL: 'F1',  SVG: 'F1',  ID: 48, BLK: 'LD110', ALIGN: 'end',   TAG: '101790-SPNL-69750FI',    DESC: 'NA',                                                           PO_NO: '',            PO_DATE: undefined,    VENDOR: '',                                 COUNTRY: '',            INCOTERMS: '',    FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: undefined,    SRD: undefined,    INSTALL: '', SHIP_MODE: '',         SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: '',   G_EREC: 'LD110', ER_PS: '2025-05-28', ER_PF: '2026-05-08', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 0,    DT_ETA: 0,     DT_SRD: 0, },
      { SN: 473, AREA: 'LQ',      ZONE: 'LQ',      LEVEL: 'F1',  SVG: 'F1',  ID: 49, BLK: 'LD110', ALIGN: 'end',   TAG: '101840-EDB-71442',       DESC: 'NA',                                                           PO_NO: 'QU557BKE7A',  PO_DATE: '2024-06-05', VENDOR: 'R.STAHL KOREA',                    COUNTRY: 'India',       INCOTERMS: 'FOB', FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: '2025-08-30', SRD: '2025-09-22', INSTALL: '', SHIP_MODE: 'NA',       SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: 'NA', G_EREC: 'LD110', ER_PS: '2025-05-28', ER_PF: '2026-05-08', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 23,  DT_ETA: -94,  DT_SRD: -117, },
      { SN: 474, AREA: 'LQ',      ZONE: 'LQ',      LEVEL: 'F1',  SVG: 'F1',  ID: 50, BLK: 'LD110', ALIGN: 'end',   TAG: '101840-EDB-71230',       DESC: 'NA',                                                           PO_NO: 'QU557BKE7A',  PO_DATE: '2024-06-05', VENDOR: 'R.STAHL KOREA',                    COUNTRY: 'India',       INCOTERMS: 'FOB', FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: '2025-08-30', SRD: '2025-09-22', INSTALL: '', SHIP_MODE: 'NA',       SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: 'NA', G_EREC: 'LD110', ER_PS: '2025-05-28', ER_PF: '2026-05-08', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 23,  DT_ETA: -94,  DT_SRD: -117, },
      { SN: 475, AREA: 'LQ',      ZONE: 'LQ',      LEVEL: 'F1',  SVG: 'F1',  ID: 51, BLK: 'LD110', ALIGN: 'end',   TAG: '101820-EDB-71214',       DESC: 'NA',                                                           PO_NO: 'QU557BKE7A',  PO_DATE: '2024-06-05', VENDOR: 'R.STAHL KOREA',                    COUNTRY: 'India',       INCOTERMS: 'FOB', FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: '2025-08-30', SRD: '2025-09-22', INSTALL: '', SHIP_MODE: 'NA',       SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: 'NA', G_EREC: 'LD110', ER_PS: '2025-05-28', ER_PF: '2026-05-08', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 23,  DT_ETA: -94,  DT_SRD: -117, },
      { SN: 476, AREA: 'LQ',      ZONE: 'LQ',      LEVEL: 'F1',  SVG: 'F1',  ID: 52, BLK: 'LD110', ALIGN: 'end',   TAG: '101850-EDB-71111B',      DESC: 'NA',                                                           PO_NO: 'QU557BKE7A',  PO_DATE: '2024-06-05', VENDOR: 'R.STAHL KOREA',                    COUNTRY: 'India',       INCOTERMS: 'FOB', FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: '2025-08-30', SRD: '2025-09-22', INSTALL: '', SHIP_MODE: 'NA',       SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: 'NA', G_EREC: 'LD110', ER_PS: '2025-05-28', ER_PF: '2026-05-08', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 23,  DT_ETA: -94,  DT_SRD: -117, },
      { SN: 477, AREA: 'LQ',      ZONE: 'LQ',      LEVEL: 'F1',  SVG: 'F1',  ID: 53, BLK: 'LD110', ALIGN: 'end',   TAG: '101850-EDB-71111A',      DESC: 'NA',                                                           PO_NO: 'QU557BKE7A',  PO_DATE: '2024-06-05', VENDOR: 'R.STAHL KOREA',                    COUNTRY: 'India',       INCOTERMS: 'FOB', FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: '2025-08-30', SRD: '2025-09-22', INSTALL: '', SHIP_MODE: 'NA',       SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: 'NA', G_EREC: 'LD110', ER_PS: '2025-05-28', ER_PF: '2026-05-08', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 23,  DT_ETA: -94,  DT_SRD: -117, },
      { SN: 478, AREA: 'LQ',      ZONE: 'LQ',      LEVEL: 'F1',  SVG: 'F1',  ID: 54, BLK: 'LD110', ALIGN: 'end',   TAG: '101870-NPNL-69649BI',    DESC: 'NA',                                                           PO_NO: '',            PO_DATE: undefined,    VENDOR: '',                                 COUNTRY: '',            INCOTERMS: '',    FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: undefined,    SRD: undefined,    INSTALL: '', SHIP_MODE: '',         SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: '',   G_EREC: 'LD110', ER_PS: '2025-05-28', ER_PF: '2026-05-08', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 0,    DT_ETA: 0,     DT_SRD: 0, },
      { SN: 479, AREA: 'LQ',      ZONE: 'LQ',      LEVEL: 'F1',  SVG: 'F1',  ID: 55, BLK: 'LD110', ALIGN: 'end',   TAG: '101870-NPNL-69674BI',    DESC: 'NA',                                                           PO_NO: '',            PO_DATE: undefined,    VENDOR: '',                                 COUNTRY: '',            INCOTERMS: '',    FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: undefined,    SRD: undefined,    INSTALL: '', SHIP_MODE: '',         SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: '',   G_EREC: 'LD110', ER_PS: '2025-05-28', ER_PF: '2026-05-08', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 0,    DT_ETA: 0,     DT_SRD: 0, },
      { SN: 480, AREA: 'LQ',      ZONE: 'LQ',      LEVEL: 'F1',  SVG: 'F1',  ID: 56, BLK: 'LD110', ALIGN: 'end',   TAG: '101870-NPNL-69971BI',    DESC: 'NA',                                                           PO_NO: '',            PO_DATE: undefined,    VENDOR: '',                                 COUNTRY: '',            INCOTERMS: '',    FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: undefined,    SRD: undefined,    INSTALL: '', SHIP_MODE: '',         SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: '',   G_EREC: 'LD110', ER_PS: '2025-05-28', ER_PF: '2026-05-08', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 0,    DT_ETA: 0,     DT_SRD: 0, },
      { SN: 481, AREA: 'LQ',      ZONE: 'LQ',      LEVEL: 'F1',  SVG: 'F1',  ID: 57, BLK: 'LD110', ALIGN: 'end',   TAG: '101870-NPNL-69970BI',    DESC: 'NA',                                                           PO_NO: '',            PO_DATE: undefined,    VENDOR: '',                                 COUNTRY: '',            INCOTERMS: '',    FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: undefined,    SRD: undefined,    INSTALL: '', SHIP_MODE: '',         SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: '',   G_EREC: 'LD110', ER_PS: '2025-05-28', ER_PF: '2026-05-08', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 0,    DT_ETA: 0,     DT_SRD: 0, },
      { SN: 482, AREA: 'LQ',      ZONE: 'LQ',      LEVEL: 'F1',  SVG: 'F1',  ID: 58, BLK: 'LD110', ALIGN: 'end',   TAG: '101870-NPNL-69989BT',    DESC: 'NA',                                                           PO_NO: '',            PO_DATE: undefined,    VENDOR: '',                                 COUNTRY: '',            INCOTERMS: '',    FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: undefined,    SRD: undefined,    INSTALL: '', SHIP_MODE: '',         SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: '',   G_EREC: 'LD110', ER_PS: '2025-05-28', ER_PF: '2026-05-08', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 0,    DT_ETA: 0,     DT_SRD: 0, },
      { SN: 483, AREA: 'LQ',      ZONE: 'LQ',      LEVEL: 'F1',  SVG: 'F1',  ID: 59, BLK: 'LD110', ALIGN: 'end',   TAG: '101720-TQZ-87072',       DESC: 'NA',                                                           PO_NO: '',            PO_DATE: undefined,    VENDOR: '',                                 COUNTRY: '',            INCOTERMS: '',    FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: undefined,    SRD: undefined,    INSTALL: '', SHIP_MODE: '',         SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: '',   G_EREC: 'LD110', ER_PS: '2025-05-28', ER_PF: '2026-05-08', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 0,    DT_ETA: 0,     DT_SRD: 0, },
      { SN: 484, AREA: 'LQ',      ZONE: 'LQ',      LEVEL: 'F1',  SVG: 'F1',  ID: 60, BLK: 'LD110', ALIGN: 'end',   TAG: '101820-EDB-71213',       DESC: 'NA',                                                           PO_NO: 'QU557BKE7A',  PO_DATE: '2024-06-05', VENDOR: 'R.STAHL KOREA',                    COUNTRY: 'India',       INCOTERMS: 'FOB', FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: '2025-08-30', SRD: '2025-09-22', INSTALL: '', SHIP_MODE: 'NA',       SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: 'NA', G_EREC: 'LD110', ER_PS: '2025-05-28', ER_PF: '2026-05-08', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 23,  DT_ETA: -94,  DT_SRD: -117, },
      { SN: 485, AREA: 'LQ',      ZONE: 'LQ',      LEVEL: 'F1',  SVG: 'F1',  ID: 61, BLK: 'LD110', ALIGN: 'end',   TAG: '101860-JTCAB-750509',    DESC: 'NA',                                                           PO_NO: '',            PO_DATE: undefined,    VENDOR: '',                                 COUNTRY: '',            INCOTERMS: '',    FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: undefined,    SRD: undefined,    INSTALL: '', SHIP_MODE: '',         SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: '',   G_EREC: 'LD110', ER_PS: '2025-05-28', ER_PF: '2026-05-08', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 0,    DT_ETA: 0,     DT_SRD: 0, },
      { SN: 486, AREA: 'LQ',      ZONE: 'LQ',      LEVEL: 'F1',  SVG: 'F1',  ID: 62, BLK: 'LD110', ALIGN: 'end',   TAG: '101860-JTCAB-750009',    DESC: 'NA',                                                           PO_NO: '',            PO_DATE: undefined,    VENDOR: '',                                 COUNTRY: '',            INCOTERMS: '',    FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: undefined,    SRD: undefined,    INSTALL: '', SHIP_MODE: '',         SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: '',   G_EREC: 'LD110', ER_PS: '2025-05-28', ER_PF: '2026-05-08', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 0,    DT_ETA: 0,     DT_SRD: 0, },
      { SN: 487, AREA: 'LQ',      ZONE: 'LQ',      LEVEL: 'F1',  SVG: 'F1',  ID: 63, BLK: 'LD110', ALIGN: 'end',   TAG: '101860-JTCAB-750008',    DESC: 'NA',                                                           PO_NO: '',            PO_DATE: undefined,    VENDOR: '',                                 COUNTRY: '',            INCOTERMS: '',    FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: undefined,    SRD: undefined,    INSTALL: '', SHIP_MODE: '',         SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: '',   G_EREC: 'LD110', ER_PS: '2025-05-28', ER_PF: '2026-05-08', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 0,    DT_ETA: 0,     DT_SRD: 0, },
      { SN: 488, AREA: 'LQ',      ZONE: 'LQ',      LEVEL: 'F1',  SVG: 'F1',  ID: 64, BLK: 'LD110', ALIGN: 'end',   TAG: '101860-JTCAB-750007',    DESC: 'NA',                                                           PO_NO: '',            PO_DATE: undefined,    VENDOR: '',                                 COUNTRY: '',            INCOTERMS: '',    FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: undefined,    SRD: undefined,    INSTALL: '', SHIP_MODE: '',         SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: '',   G_EREC: 'LD110', ER_PS: '2025-05-28', ER_PF: '2026-05-08', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 0,    DT_ETA: 0,     DT_SRD: 0, },
      { SN: 489, AREA: 'LQ',      ZONE: 'LQ',      LEVEL: 'F1',  SVG: 'F1',  ID: 65, BLK: 'LD110', ALIGN: 'end',   TAG: '101860-JTCAB-750003',    DESC: 'NA',                                                           PO_NO: '',            PO_DATE: undefined,    VENDOR: '',                                 COUNTRY: '',            INCOTERMS: '',    FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: undefined,    SRD: undefined,    INSTALL: '', SHIP_MODE: '',         SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: '',   G_EREC: 'LD110', ER_PS: '2025-05-28', ER_PF: '2026-05-08', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 0,    DT_ETA: 0,     DT_SRD: 0, },
      { SN: 490, AREA: 'LQ',      ZONE: 'LQ',      LEVEL: 'F1',  SVG: 'F1',  ID: 66, BLK: 'LD110', ALIGN: 'end',   TAG: '101860-JTCAB-750002',    DESC: 'NA',                                                           PO_NO: 'QU557BKT03',  PO_DATE: '2024-08-20', VENDOR: 'DONGYANG',                         COUNTRY: 'Korea',       INCOTERMS: 'DAP', FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: '2025-07-01', SRD: '2025-08-22', INSTALL: '', SHIP_MODE: 'NA',       SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: 'NA', G_EREC: 'LD110', ER_PS: '2025-05-28', ER_PF: '2026-05-08', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 52,  DT_ETA: -34,  DT_SRD: -86, },
      { SN: 491, AREA: 'LQ',      ZONE: 'LQ',      LEVEL: 'F1',  SVG: 'F1',  ID: 67, BLK: 'LD110', ALIGN: 'end',   TAG: '101860-JTCAB-750501',    DESC: 'NA',                                                           PO_NO: '',            PO_DATE: undefined,    VENDOR: '',                                 COUNTRY: '',            INCOTERMS: '',    FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: undefined,    SRD: undefined,    INSTALL: '', SHIP_MODE: '',         SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: '',   G_EREC: 'LD110', ER_PS: '2025-05-28', ER_PF: '2026-05-08', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 0,    DT_ETA: 0,     DT_SRD: 0, },
      { SN: 492, AREA: 'LQ',      ZONE: 'LQ',      LEVEL: 'F1',  SVG: 'F1',  ID: 68, BLK: 'LD110', ALIGN: 'end',   TAG: '101860-JTCAB-750001T',   DESC: 'NA',                                                           PO_NO: '',            PO_DATE: undefined,    VENDOR: '',                                 COUNTRY: '',            INCOTERMS: '',    FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: undefined,    SRD: undefined,    INSTALL: '', SHIP_MODE: '',         SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: '',   G_EREC: 'LD110', ER_PS: '2025-05-28', ER_PF: '2026-05-08', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 0,    DT_ETA: 0,     DT_SRD: 0, },
      { SN: 493, AREA: 'LQ',      ZONE: 'LQ',      LEVEL: 'F1',  SVG: 'F1',  ID: 69, BLK: 'LD110', ALIGN: 'end',   TAG: '101860-JTCAB-752901T',   DESC: 'NA',                                                           PO_NO: '',            PO_DATE: undefined,    VENDOR: '',                                 COUNTRY: '',            INCOTERMS: '',    FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: undefined,    SRD: undefined,    INSTALL: '', SHIP_MODE: '',         SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: '',   G_EREC: 'LD110', ER_PS: '2025-05-28', ER_PF: '2026-05-08', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 0,    DT_ETA: 0,     DT_SRD: 0, },
      { SN: 494, AREA: 'LQ',      ZONE: 'LQ',      LEVEL: 'F1',  SVG: 'F1',  ID: 70, BLK: 'LD110', ALIGN: 'end',   TAG: '101780-CPNL-69600I',     DESC: 'NA',                                                           PO_NO: '',            PO_DATE: undefined,    VENDOR: '',                                 COUNTRY: '',            INCOTERMS: '',    FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: undefined,    SRD: undefined,    INSTALL: '', SHIP_MODE: '',         SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: '',   G_EREC: 'LD110', ER_PS: '2025-05-28', ER_PF: '2026-05-08', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 0,    DT_ETA: 0,     DT_SRD: 0, },
      { SN: 495, AREA: 'LQ',      ZONE: 'LQ',      LEVEL: 'F1',  SVG: 'F1',  ID: 71, BLK: 'LD110', ALIGN: 'end',   TAG: '101780-CPNL-69625I',     DESC: 'NA',                                                           PO_NO: '',            PO_DATE: undefined,    VENDOR: '',                                 COUNTRY: '',            INCOTERMS: '',    FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: undefined,    SRD: undefined,    INSTALL: '', SHIP_MODE: '',         SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: '',   G_EREC: 'LD110', ER_PS: '2025-05-28', ER_PF: '2026-05-08', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 0,    DT_ETA: 0,     DT_SRD: 0, },
      { SN: 496, AREA: 'LQ',      ZONE: 'LQ',      LEVEL: 'F1',  SVG: 'F1',  ID: 72, BLK: 'LD110', ALIGN: 'end',   TAG: '101780-CPNL-69650I',     DESC: 'NA',                                                           PO_NO: '',            PO_DATE: undefined,    VENDOR: '',                                 COUNTRY: '',            INCOTERMS: '',    FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: undefined,    SRD: undefined,    INSTALL: '', SHIP_MODE: '',         SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: '',   G_EREC: 'LD110', ER_PS: '2025-05-28', ER_PF: '2026-05-08', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 0,    DT_ETA: 0,     DT_SRD: 0, },
      { SN: 497, AREA: 'LQ',      ZONE: 'LQ',      LEVEL: 'F2',  SVG: 'F2',  ID: 1,  BLK: 'LD210', ALIGN: 'start', TAG: '101720-TQZ-87073',       DESC: 'NA',                                                           PO_NO: '',            PO_DATE: undefined,    VENDOR: '',                                 COUNTRY: '',            INCOTERMS: '',    FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: undefined,    SRD: undefined,    INSTALL: '', SHIP_MODE: '',         SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: '',   G_EREC: 'LD210', ER_PS: '2025-06-23', ER_PF: '2026-05-08', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 0,    DT_ETA: 0,     DT_SRD: 0, },
      { SN: 498, AREA: 'LQ',      ZONE: 'LQ',      LEVEL: 'F2',  SVG: 'F2',  ID: 2,  BLK: 'LD210', ALIGN: 'start', TAG: '101930-TZQ-85032',       DESC: 'NA',                                                           PO_NO: '',            PO_DATE: undefined,    VENDOR: '',                                 COUNTRY: '',            INCOTERMS: '',    FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: undefined,    SRD: undefined,    INSTALL: '', SHIP_MODE: '',         SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: '',   G_EREC: 'LD210', ER_PS: '2025-06-23', ER_PF: '2026-05-08', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 0,    DT_ETA: 0,     DT_SRD: 0, },
      { SN: 499, AREA: 'LQ',      ZONE: 'LQ',      LEVEL: 'F2',  SVG: 'F2',  ID: 3,  BLK: 'LD210', ALIGN: 'start', TAG: '101970-THAH-75520',      DESC: 'NA',                                                           PO_NO: 'QU557BKH01',  PO_DATE: '2024-05-17', VENDOR: '하이에어코리아',                          COUNTRY: 'Korea',       INCOTERMS: 'DAP', FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: '2025-06-13', SRD: undefined,    INSTALL: '', SHIP_MODE: 'NA',       SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: 'NA', G_EREC: 'LD210', ER_PS: '2025-06-23', ER_PF: '2026-05-08', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 0,    DT_ETA: 10,   DT_SRD: 0, },
      { SN: 500, AREA: 'LQ',      ZONE: 'LQ',      LEVEL: 'F2',  SVG: 'F2',  ID: 4,  BLK: 'LD210', ALIGN: 'start', TAG: '101970-HAL-75520',       DESC: 'NA',                                                           PO_NO: 'QU557BKH01',  PO_DATE: '2024-05-17', VENDOR: '하이에어코리아',                          COUNTRY: 'Korea',       INCOTERMS: 'DAP', FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: '2025-06-13', SRD: undefined,    INSTALL: '', SHIP_MODE: 'NA',       SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: 'NA', G_EREC: 'LD210', ER_PS: '2025-06-23', ER_PF: '2026-05-08', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 0,    DT_ETA: 10,   DT_SRD: 0, },
      { SN: 501, AREA: 'LQ',      ZONE: 'LQ',      LEVEL: 'F2',  SVG: 'F2',  ID: 5,  BLK: 'LD210', ALIGN: 'start', TAG: '101840-EDB-71440',       DESC: 'NA',                                                           PO_NO: 'QU557BKE7A',  PO_DATE: '2024-06-05', VENDOR: 'R.STAHL KOREA',                    COUNTRY: 'India',       INCOTERMS: 'FOB', FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: '2025-08-30', SRD: '2025-10-25', INSTALL: '', SHIP_MODE: 'NA',       SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: 'NA', G_EREC: 'LD210', ER_PS: '2025-06-23', ER_PF: '2026-05-08', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 56,  DT_ETA: -68,  DT_SRD: -124, },
      { SN: 502, AREA: 'LQ',      ZONE: 'LQ',      LEVEL: 'F2',  SVG: 'F2',  ID: 6,  BLK: 'LD210', ALIGN: 'start', TAG: '101840-EDB-71441',       DESC: 'NA',                                                           PO_NO: 'QU557BKE7A',  PO_DATE: '2024-06-05', VENDOR: 'R.STAHL KOREA',                    COUNTRY: 'India',       INCOTERMS: 'FOB', FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: '2025-08-30', SRD: '2025-09-22', INSTALL: '', SHIP_MODE: 'NA',       SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: 'NA', G_EREC: 'LD210', ER_PS: '2025-06-23', ER_PF: '2026-05-08', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 23,  DT_ETA: -68,  DT_SRD: -91, },
      { SN: 503, AREA: 'LQ',      ZONE: 'LQ',      LEVEL: 'F2',  SVG: 'F2',  ID: 7,  BLK: 'LD210', ALIGN: 'start', TAG: '101860-EPNL-62930E',     DESC: 'NA',                                                           PO_NO: '',            PO_DATE: undefined,    VENDOR: '',                                 COUNTRY: '',            INCOTERMS: '',    FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: undefined,    SRD: undefined,    INSTALL: '', SHIP_MODE: '',         SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: '',   G_EREC: 'LD210', ER_PS: '2025-06-23', ER_PF: '2026-05-08', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 0,    DT_ETA: 0,     DT_SRD: 0, },
      { SN: 504, AREA: 'LQ',      ZONE: 'LQ',      LEVEL: 'F2',  SVG: 'F2',  ID: 8,  BLK: 'LD210', ALIGN: 'end',   TAG: '101820-EDB-71420',       DESC: 'NA',                                                           PO_NO: 'QU557BKE7A',  PO_DATE: '2024-06-05', VENDOR: 'R.STAHL KOREA',                    COUNTRY: 'India',       INCOTERMS: 'FOB', FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: '2025-08-30', SRD: '2025-10-25', INSTALL: '', SHIP_MODE: 'NA',       SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: 'NA', G_EREC: 'LD210', ER_PS: '2025-06-23', ER_PF: '2026-05-08', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 56,  DT_ETA: -68,  DT_SRD: -124, },
      { SN: 505, AREA: 'LQ',      ZONE: 'LQ',      LEVEL: 'F2',  SVG: 'F2',  ID: 9,  BLK: 'LD210', ALIGN: 'end',   TAG: '101820-EDB-71211',       DESC: 'NA',                                                           PO_NO: 'QU557BKE7A',  PO_DATE: '2024-06-05', VENDOR: 'R.STAHL KOREA',                    COUNTRY: 'India',       INCOTERMS: 'FOB', FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: '2025-08-30', SRD: '2025-09-22', INSTALL: '', SHIP_MODE: 'NA',       SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: 'NA', G_EREC: 'LD210', ER_PS: '2025-06-23', ER_PF: '2026-05-08', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 23,  DT_ETA: -68,  DT_SRD: -91, },
      { SN: 506, AREA: 'LQ',      ZONE: 'LQ',      LEVEL: 'F2',  SVG: 'F2',  ID: 10, BLK: 'LD210', ALIGN: 'end',   TAG: '101820-EDB-71221',       DESC: 'NA',                                                           PO_NO: 'QU557BKE7A',  PO_DATE: '2024-06-05', VENDOR: 'R.STAHL KOREA',                    COUNTRY: 'India',       INCOTERMS: 'FOB', FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: '2025-08-30', SRD: '2025-09-22', INSTALL: '', SHIP_MODE: 'NA',       SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: 'NA', G_EREC: 'LD210', ER_PS: '2025-06-23', ER_PF: '2026-05-08', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 23,  DT_ETA: -68,  DT_SRD: -91, },
      { SN: 507, AREA: 'LQ',      ZONE: 'LQ',      LEVEL: 'F2',  SVG: 'F2',  ID: 11, BLK: 'LD210', ALIGN: 'end',   TAG: '101820-EDB-71222',       DESC: 'NA',                                                           PO_NO: 'QU557BKE7A',  PO_DATE: '2024-06-05', VENDOR: 'R.STAHL KOREA',                    COUNTRY: 'India',       INCOTERMS: 'FOB', FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: '2025-08-30', SRD: '2025-09-22', INSTALL: '', SHIP_MODE: 'NA',       SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: 'NA', G_EREC: 'LD210', ER_PS: '2025-06-23', ER_PF: '2026-05-08', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 23,  DT_ETA: -68,  DT_SRD: -91, },
      { SN: 508, AREA: 'LQ',      ZONE: 'LQ',      LEVEL: 'F2',  SVG: 'F2',  ID: 12, BLK: 'LD210', ALIGN: 'end',   TAG: '101720-TQZ-87074',       DESC: 'NA',                                                           PO_NO: '',            PO_DATE: undefined,    VENDOR: '',                                 COUNTRY: '',            INCOTERMS: '',    FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: undefined,    SRD: undefined,    INSTALL: '', SHIP_MODE: '',         SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: '',   G_EREC: 'LD210', ER_PS: '2025-06-23', ER_PF: '2026-05-08', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 0,    DT_ETA: 0,     DT_SRD: 0, },
      { SN: 509, AREA: 'LQ',      ZONE: 'LQ',      LEVEL: 'F3',  SVG: 'F3',  ID: 1,  BLK: 'LV310', ALIGN: 'start', TAG: '101820-EDB-71411',       DESC: 'NA',                                                           PO_NO: 'QU557BKE7A',  PO_DATE: '2024-06-05', VENDOR: 'R.STAHL KOREA',                    COUNTRY: 'India',       INCOTERMS: 'FOB', FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: '2025-08-30', SRD: '2025-11-01', INSTALL: '', SHIP_MODE: 'NA',       SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: 'NA', G_EREC: 'LD310', ER_PS: '2025-06-30', ER_PF: '2026-05-08', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 63,  DT_ETA: -61,  DT_SRD: -124, },
      { SN: 510, AREA: 'LQ',      ZONE: 'LQ',      LEVEL: 'F3',  SVG: 'F3',  ID: 2,  BLK: 'LV310', ALIGN: 'start', TAG: '101820-EDB-71410',       DESC: 'NA',                                                           PO_NO: 'QU557BKE7A',  PO_DATE: '2024-06-05', VENDOR: 'R.STAHL KOREA',                    COUNTRY: 'India',       INCOTERMS: 'FOB', FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: '2025-08-30', SRD: '2025-11-01', INSTALL: '', SHIP_MODE: 'NA',       SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: 'NA', G_EREC: 'LD310', ER_PS: '2025-06-30', ER_PF: '2026-05-08', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 63,  DT_ETA: -61,  DT_SRD: -124, },
      { SN: 511, AREA: 'LQ',      ZONE: 'LQ',      LEVEL: 'F3',  SVG: 'F3',  ID: 3,  BLK: 'LV310', ALIGN: 'start', TAG: '101820-EDB-71421',       DESC: 'NA',                                                           PO_NO: 'QU557BKE7A',  PO_DATE: '2024-06-05', VENDOR: 'R.STAHL KOREA',                    COUNTRY: 'India',       INCOTERMS: 'FOB', FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: '2025-08-30', SRD: '2025-11-01', INSTALL: '', SHIP_MODE: 'NA',       SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: 'NA', G_EREC: 'LD310', ER_PS: '2025-06-30', ER_PF: '2026-05-08', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 63,  DT_ETA: -61,  DT_SRD: -124, },
      { SN: 512, AREA: 'LQ',      ZONE: 'LQ',      LEVEL: 'F3',  SVG: 'F3',  ID: 4,  BLK: 'LV310', ALIGN: 'start', TAG: '101820-EDB-71422',       DESC: 'NA',                                                           PO_NO: 'QU557BKE7A',  PO_DATE: '2024-06-05', VENDOR: 'R.STAHL KOREA',                    COUNTRY: 'India',       INCOTERMS: 'FOB', FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: '2025-08-30', SRD: '2025-11-01', INSTALL: '', SHIP_MODE: 'NA',       SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: 'NA', G_EREC: 'LD310', ER_PS: '2025-06-30', ER_PF: '2026-05-08', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 63,  DT_ETA: -61,  DT_SRD: -124, },
      { SN: 513, AREA: 'LQ',      ZONE: 'LQ',      LEVEL: 'F3',  SVG: 'F3',  ID: 5,  BLK: 'LV310', ALIGN: 'end',   TAG: '101720-TQZ-87076',       DESC: 'NA',                                                           PO_NO: '',            PO_DATE: undefined,    VENDOR: '',                                 COUNTRY: '',            INCOTERMS: '',    FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: undefined,    SRD: undefined,    INSTALL: '', SHIP_MODE: '',         SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: '',   G_EREC: 'LD310', ER_PS: '2025-06-30', ER_PF: '2026-05-08', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 0,    DT_ETA: 0,     DT_SRD: 0, },
      { SN: 514, AREA: 'LQ',      ZONE: 'LQ',      LEVEL: 'F4',  SVG: 'F4',  ID: 1,  BLK: 'LH110', ALIGN: 'start', TAG: '101720-TQZ-87083',       DESC: 'NA',                                                           PO_NO: '',            PO_DATE: undefined,    VENDOR: '',                                 COUNTRY: '',            INCOTERMS: '',    FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: undefined,    SRD: undefined,    INSTALL: '', SHIP_MODE: '',         SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: '',   G_EREC: 'LH110', ER_PS: undefined,    ER_PF: undefined,    ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 0,    DT_ETA: 0,     DT_SRD: 0, },
      { SN: 515, AREA: 'LQ',      ZONE: 'LQ',      LEVEL: 'F4',  SVG: 'F4',  ID: 2,  BLK: 'LD410', ALIGN: 'start', TAG: '101710-ZZZ-53300',       DESC: 'NA',                                                           PO_NO: '',            PO_DATE: undefined,    VENDOR: '',                                 COUNTRY: '',            INCOTERMS: '',    FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: undefined,    SRD: undefined,    INSTALL: '', SHIP_MODE: '',         SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: '',   G_EREC: 'LD410', ER_PS: '2025-07-09', ER_PF: '2026-05-08', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 0,    DT_ETA: 0,     DT_SRD: 0, },
      { SN: 516, AREA: 'LQ',      ZONE: 'LQ',      LEVEL: 'F4',  SVG: 'F4',  ID: 3,  BLK: 'LD410', ALIGN: 'start', TAG: '101610-ZZZ-50300',       DESC: 'NA',                                                           PO_NO: 'QE557AFQ82',  PO_DATE: '2024-04-19', VENDOR: 'Imenco',                           COUNTRY: 'Norway',      INCOTERMS: 'FOB', FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: '2025-10-18', SRD: '2025-10-08', INSTALL: '', SHIP_MODE: 'NA',       SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: 'NA', G_EREC: 'LD410', ER_PS: '2025-07-09', ER_PF: '2026-05-08', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: -10, DT_ETA: -101, DT_SRD: -91, },
      { SN: 517, AREA: 'LQ',      ZONE: 'LQ',      LEVEL: 'F4',  SVG: 'F4',  ID: 4,  BLK: 'LD410', ALIGN: 'start', TAG: '101820-EXFMR-62460',     DESC: 'NA',                                                           PO_NO: 'QE557BKE06',  PO_DATE: '2024-04-30', VENDOR: 'ABB AS',                           COUNTRY: 'Norway',      INCOTERMS: 'FOB', FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: '2025-07-09', SRD: '2025-08-30', INSTALL: '', SHIP_MODE: 'NA',       SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: 'NA', G_EREC: 'LD410', ER_PS: '2025-07-09', ER_PF: '2026-05-08', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 52,  DT_ETA: 0,    DT_SRD: -52, },
      { SN: 518, AREA: 'LQ',      ZONE: 'LQ',      LEVEL: 'F4',  SVG: 'F4',  ID: 5,  BLK: 'LD410', ALIGN: 'start', TAG: '101840-EXFMR-71440',     DESC: 'NA',                                                           PO_NO: 'QE557BKE06',  PO_DATE: '2024-04-30', VENDOR: 'ABB AS',                           COUNTRY: 'Norway',      INCOTERMS: 'FOB', FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: '2025-07-09', SRD: '2025-08-30', INSTALL: '', SHIP_MODE: 'NA',       SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: 'NA', G_EREC: 'LD410', ER_PS: '2025-07-09', ER_PF: '2026-05-08', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 52,  DT_ETA: 0,    DT_SRD: -52, },
      { SN: 519, AREA: 'LQ',      ZONE: 'LQ',      LEVEL: 'F4',  SVG: 'F4',  ID: 6,  BLK: 'LD410', ALIGN: 'start', TAG: '101720-TQZ-87080',       DESC: 'NA',                                                           PO_NO: '',            PO_DATE: undefined,    VENDOR: '',                                 COUNTRY: '',            INCOTERMS: '',    FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: undefined,    SRD: undefined,    INSTALL: '', SHIP_MODE: '',         SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: '',   G_EREC: 'LD410', ER_PS: '2025-07-09', ER_PF: '2026-05-08', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 0,    DT_ETA: 0,     DT_SRD: 0, },
      { SN: 520, AREA: 'LQ',      ZONE: 'LQ',      LEVEL: 'F4',  SVG: 'F4',  ID: 7,  BLK: 'LD510', ALIGN: 'start', TAG: '101970-HAL-77520',       DESC: 'NA',                                                           PO_NO: 'QU557BKH01',  PO_DATE: '2024-05-17', VENDOR: '하이에어코리아',                          COUNTRY: 'Korea',       INCOTERMS: 'DAP', FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: '2025-06-13', SRD: undefined,    INSTALL: '', SHIP_MODE: 'NA',       SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: 'NA', G_EREC: 'LD410', ER_PS: '2025-07-09', ER_PF: '2026-05-08', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 0,    DT_ETA: 26,   DT_SRD: 0, },
      { SN: 521, AREA: 'LQ',      ZONE: 'LQ',      LEVEL: 'F4',  SVG: 'F4',  ID: 8,  BLK: 'LD410', ALIGN: 'start', TAG: '101720-TQZ-87077',       DESC: 'NA',                                                           PO_NO: '',            PO_DATE: undefined,    VENDOR: '',                                 COUNTRY: '',            INCOTERMS: '',    FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: undefined,    SRD: undefined,    INSTALL: '', SHIP_MODE: '',         SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: '',   G_EREC: 'LD410', ER_PS: '2025-07-09', ER_PF: '2026-05-08', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 0,    DT_ETA: 0,     DT_SRD: 0, },
      { SN: 522, AREA: 'LQ',      ZONE: 'LQ',      LEVEL: 'F4',  SVG: 'F4',  ID: 9,  BLK: 'LD410', ALIGN: 'end',   TAG: '101820-EXFMR-71420',     DESC: 'NA',                                                           PO_NO: 'QE557BKE06',  PO_DATE: '2024-04-30', VENDOR: 'ABB AS',                           COUNTRY: 'Norway',      INCOTERMS: 'FOB', FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: '2025-07-09', SRD: '2025-08-30', INSTALL: '', SHIP_MODE: 'NA',       SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: 'NA', G_EREC: 'LD410', ER_PS: '2025-07-09', ER_PF: '2026-05-08', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 52,  DT_ETA: 0,    DT_SRD: -52, },
      { SN: 523, AREA: 'LQ',      ZONE: 'LQ',      LEVEL: 'F4',  SVG: 'F4',  ID: 10, BLK: 'LD410', ALIGN: 'end',   TAG: '101820-EXFMR-71220',     DESC: 'NA',                                                           PO_NO: 'QE557BKE06',  PO_DATE: '2024-04-30', VENDOR: 'ABB AS',                           COUNTRY: 'Norway',      INCOTERMS: 'FOB', FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: '2025-07-09', SRD: '2025-08-30', INSTALL: '', SHIP_MODE: 'NA',       SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: 'NA', G_EREC: 'LD410', ER_PS: '2025-07-09', ER_PF: '2026-05-08', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 52,  DT_ETA: 0,    DT_SRD: -52, },
      { SN: 524, AREA: 'LQ',      ZONE: 'LQ',      LEVEL: 'F4',  SVG: 'F4',  ID: 11, BLK: 'LV520', ALIGN: 'end',   TAG: '101720-TQZ-87082',       DESC: 'NA',                                                           PO_NO: '',            PO_DATE: undefined,    VENDOR: '',                                 COUNTRY: '',            INCOTERMS: '',    FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: undefined,    SRD: undefined,    INSTALL: '', SHIP_MODE: '',         SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: '',   G_EREC: 'LV520', ER_PS: undefined,    ER_PF: undefined,    ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 0,    DT_ETA: 0,     DT_SRD: 0, },
      { SN: 525, AREA: 'LQ',      ZONE: 'LQ',      LEVEL: 'F4',  SVG: 'F4',  ID: 12, BLK: 'LD410', ALIGN: 'end',   TAG: '101720-TQZ-87081',       DESC: 'NA',                                                           PO_NO: '',            PO_DATE: undefined,    VENDOR: '',                                 COUNTRY: '',            INCOTERMS: '',    FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: undefined,    SRD: undefined,    INSTALL: '', SHIP_MODE: '',         SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: '',   G_EREC: 'LD410', ER_PS: '2025-07-09', ER_PF: '2026-05-08', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 0,    DT_ETA: 0,     DT_SRD: 0, },
      { SN: 526, AREA: 'LQ',      ZONE: 'LQ',      LEVEL: 'F4',  SVG: 'F4',  ID: 13, BLK: 'LD410', ALIGN: 'start', TAG: '101720-TQZ-87078',       DESC: 'NA',                                                           PO_NO: '',            PO_DATE: undefined,    VENDOR: '',                                 COUNTRY: '',            INCOTERMS: '',    FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: undefined,    SRD: undefined,    INSTALL: '', SHIP_MODE: '',         SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: '',   G_EREC: 'LD410', ER_PS: '2025-07-09', ER_PF: '2026-05-08', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 0,    DT_ETA: 0,     DT_SRD: 0, },
      { SN: 527, AREA: 'LQ',      ZONE: 'LQ',      LEVEL: 'F4',  SVG: 'F4',  ID: 14, BLK: 'LD410', ALIGN: 'start', TAG: '101860-EPNL-62920E',     DESC: 'NA',                                                           PO_NO: '',            PO_DATE: undefined,    VENDOR: '',                                 COUNTRY: '',            INCOTERMS: '',    FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: undefined,    SRD: undefined,    INSTALL: '', SHIP_MODE: '',         SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: '',   G_EREC: 'LD410', ER_PS: '2025-07-09', ER_PF: '2026-05-08', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 0,    DT_ETA: 0,     DT_SRD: 0, },
      { SN: 528, AREA: 'LQ',      ZONE: 'LQ',      LEVEL: 'F4',  SVG: 'F4',  ID: 15, BLK: 'LD410', ALIGN: 'start', TAG: '101820-EXFMR-71410',     DESC: 'NA',                                                           PO_NO: 'QE557BKE06',  PO_DATE: '2024-04-30', VENDOR: 'ABB AS',                           COUNTRY: 'Norway',      INCOTERMS: 'FOB', FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: '2025-07-09', SRD: '2025-08-30', INSTALL: '', SHIP_MODE: 'NA',       SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: 'NA', G_EREC: 'LD410', ER_PS: '2025-07-09', ER_PF: '2026-05-08', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 52,  DT_ETA: 0,    DT_SRD: -52, },
      { SN: 529, AREA: 'LQ',      ZONE: 'LQ',      LEVEL: 'F4',  SVG: 'F4',  ID: 16, BLK: 'LD410', ALIGN: 'start', TAG: '101720-TQZ-87079',       DESC: 'NA',                                                           PO_NO: '',            PO_DATE: undefined,    VENDOR: '',                                 COUNTRY: '',            INCOTERMS: '',    FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: undefined,    SRD: undefined,    INSTALL: '', SHIP_MODE: '',         SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: '',   G_EREC: 'LD410', ER_PS: '2025-07-09', ER_PF: '2026-05-08', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 0,    DT_ETA: 0,     DT_SRD: 0, },
      { SN: 530, AREA: 'LQ',      ZONE: 'LQ',      LEVEL: 'F4',  SVG: 'F4',  ID: 17, BLK: 'LD410', ALIGN: 'start', TAG: '101820-ESB-71120',       DESC: 'NA',                                                           PO_NO: 'QE557BKE02',  PO_DATE: '2024-04-30', VENDOR: 'ABB AS',                           COUNTRY: 'Norway',      INCOTERMS: 'FOB', FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: '2025-08-09', SRD: '2025-08-30', INSTALL: '', SHIP_MODE: 'NA',       SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: 'NA', G_EREC: 'LD410', ER_PS: '2025-07-09', ER_PF: '2026-05-08', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 21,  DT_ETA: -31,  DT_SRD: -52, },
      { SN: 531, AREA: 'LQ',      ZONE: 'LQ',      LEVEL: 'F4',  SVG: 'F4',  ID: 18, BLK: 'LD410', ALIGN: 'end',   TAG: '101930-ABJ-71050',       DESC: 'NA',                                                           PO_NO: '',            PO_DATE: undefined,    VENDOR: '',                                 COUNTRY: '',            INCOTERMS: '',    FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: undefined,    SRD: undefined,    INSTALL: '', SHIP_MODE: '',         SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: '',   G_EREC: 'LD410', ER_PS: '2025-07-09', ER_PF: '2026-05-08', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 0,    DT_ETA: 0,     DT_SRD: 0, },
      { SN: 532, AREA: 'LQ',      ZONE: 'LQ',      LEVEL: 'F4',  SVG: 'F4',  ID: 19, BLK: 'LD410', ALIGN: 'end',   TAG: '101930-VAP-71053A',      DESC: 'NA',                                                           PO_NO: '',            PO_DATE: undefined,    VENDOR: '',                                 COUNTRY: '',            INCOTERMS: '',    FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: undefined,    SRD: undefined,    INSTALL: '', SHIP_MODE: '',         SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: '',   G_EREC: 'LD410', ER_PS: '2025-07-09', ER_PF: '2026-05-08', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 0,    DT_ETA: 0,     DT_SRD: 0, },
      { SN: 533, AREA: 'LQ',      ZONE: 'LQ',      LEVEL: 'F4',  SVG: 'F4',  ID: 20, BLK: 'LD410', ALIGN: 'end',   TAG: '101930-VAP-71053B',      DESC: 'NA',                                                           PO_NO: '',            PO_DATE: undefined,    VENDOR: '',                                 COUNTRY: '',            INCOTERMS: '',    FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: undefined,    SRD: undefined,    INSTALL: '', SHIP_MODE: '',         SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: '',   G_EREC: 'LD410', ER_PS: '2025-07-09', ER_PF: '2026-05-08', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 0,    DT_ETA: 0,     DT_SRD: 0, },
      { SN: 534, AREA: 'LQ',      ZONE: 'LQ',      LEVEL: 'F4',  SVG: 'F4',  ID: 21, BLK: 'LD410', ALIGN: 'end',   TAG: '101930-VAP-71053C',      DESC: 'NA',                                                           PO_NO: '',            PO_DATE: undefined,    VENDOR: '',                                 COUNTRY: '',            INCOTERMS: '',    FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: undefined,    SRD: undefined,    INSTALL: '', SHIP_MODE: '',         SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: '',   G_EREC: 'LD410', ER_PS: '2025-07-09', ER_PF: '2026-05-08', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 0,    DT_ETA: 0,     DT_SRD: 0, },
      { SN: 535, AREA: 'LQ',      ZONE: 'LQ',      LEVEL: 'F4',  SVG: 'F4',  ID: 22, BLK: 'LD410', ALIGN: 'end',   TAG: '101820-ESB-71130',       DESC: 'NA',                                                           PO_NO: '',            PO_DATE: undefined,    VENDOR: '',                                 COUNTRY: '',            INCOTERMS: '',    FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: undefined,    SRD: undefined,    INSTALL: '', SHIP_MODE: '',         SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: '',   G_EREC: 'LD410', ER_PS: '2025-07-09', ER_PF: '2026-05-08', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 0,    DT_ETA: 0,     DT_SRD: 0, },
      { SN: 536, AREA: 'LQ',      ZONE: 'LQ',      LEVEL: 'F4',  SVG: 'F4',  ID: 23, BLK: 'LD410', ALIGN: 'end',   TAG: '101840-EXFMR-71230',     DESC: 'NA',                                                           PO_NO: 'QE557BKE06',  PO_DATE: '2024-04-30', VENDOR: 'ABB AS',                           COUNTRY: 'Norway',      INCOTERMS: 'FOB', FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: '2025-07-09', SRD: '2025-08-30', INSTALL: '', SHIP_MODE: 'NA',       SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: 'NA', G_EREC: 'LD410', ER_PS: '2025-07-09', ER_PF: '2026-05-08', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 52,  DT_ETA: 0,    DT_SRD: -52, },
      { SN: 537, AREA: 'LQ',      ZONE: 'LQ',      LEVEL: 'F4',  SVG: 'F4',  ID: 24, BLK: 'LD410', ALIGN: 'end',   TAG: '101840-EXFMR-71230',     DESC: 'NA',                                                           PO_NO: 'QE557BKE06',  PO_DATE: '2024-04-30', VENDOR: 'ABB AS',                           COUNTRY: 'Norway',      INCOTERMS: 'FOB', FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: '2025-07-09', SRD: '2025-08-30', INSTALL: '', SHIP_MODE: 'NA',       SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: 'NA', G_EREC: 'LD410', ER_PS: '2025-07-09', ER_PF: '2026-05-08', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 52,  DT_ETA: 0,    DT_SRD: -52, },
      { SN: 538, AREA: 'LQ',      ZONE: 'LQ',      LEVEL: 'F4',  SVG: 'F4',  ID: 25, BLK: 'LD410', ALIGN: 'end',   TAG: '101820-EDB-71220',       DESC: 'NA',                                                           PO_NO: 'QU557BKE7A',  PO_DATE: '2024-06-05', VENDOR: 'R.STAHL KOREA',                    COUNTRY: 'India',       INCOTERMS: 'FOB', FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: '2025-08-30', SRD: '2025-09-22', INSTALL: '', SHIP_MODE: 'NA',       SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: 'NA', G_EREC: 'LD410', ER_PS: '2025-07-09', ER_PF: '2026-05-08', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 23,  DT_ETA: -52,  DT_SRD: -75, },
      { SN: 539, AREA: 'LQ',      ZONE: 'LQ',      LEVEL: 'F4',  SVG: 'F4',  ID: 26, BLK: 'LD410', ALIGN: 'end',   TAG: '101820-EDB-62460',       DESC: 'NA',                                                           PO_NO: 'QU557BKE7A',  PO_DATE: '2024-06-05', VENDOR: 'R.STAHL KOREA',                    COUNTRY: 'India',       INCOTERMS: 'FOB', FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: '2025-08-30', SRD: '2025-10-25', INSTALL: '', SHIP_MODE: 'NA',       SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: 'NA', G_EREC: 'LD410', ER_PS: '2025-07-09', ER_PF: '2026-05-08', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 56,  DT_ETA: -52,  DT_SRD: -108, },
      { SN: 540, AREA: 'HULL',    ZONE: 'HULL',    LEVEL: 'NE',  SVG: 'NE',  ID: 1,  BLK: 'EC1H1', ALIGN: 'start', TAG: '101970-HAL-91730',       DESC: 'AIR COOLED CONDENSING UNIT',                                   PO_NO: '',            PO_DATE: undefined,    VENDOR: '',                                 COUNTRY: '',            INCOTERMS: '',    FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: undefined,    SRD: undefined,    INSTALL: '', SHIP_MODE: '',         SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: '',   G_EREC: 'EC1G2', ER_PS: undefined,    ER_PF: undefined,    ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 0,    DT_ETA: 0,     DT_SRD: 0, },
      { SN: 541, AREA: 'HULL',    ZONE: 'HULL',    LEVEL: 'NE',  SVG: 'NE',  ID: 2,  BLK: 'EC1H1', ALIGN: 'start', TAG: '101850-EJB-96140B',      DESC: 'NA',                                                           PO_NO: '',            PO_DATE: undefined,    VENDOR: '',                                 COUNTRY: '',            INCOTERMS: '',    FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: undefined,    SRD: undefined,    INSTALL: '', SHIP_MODE: '',         SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: '',   G_EREC: 'EC1G2', ER_PS: undefined,    ER_PF: undefined,    ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 0,    DT_ETA: 0,     DT_SRD: 0, },
      { SN: 542, AREA: 'HULL',    ZONE: 'HULL',    LEVEL: 'NE',  SVG: 'NE',  ID: 3,  BLK: 'EC1H1', ALIGN: 'start', TAG: '101840-EJB-96120',       DESC: 'NA',                                                           PO_NO: '',            PO_DATE: undefined,    VENDOR: '',                                 COUNTRY: '',            INCOTERMS: '',    FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: undefined,    SRD: undefined,    INSTALL: '', SHIP_MODE: '',         SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: '',   G_EREC: 'EC1G2', ER_PS: undefined,    ER_PF: undefined,    ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 0,    DT_ETA: 0,     DT_SRD: 0, },
      { SN: 543, AREA: 'HULL',    ZONE: 'HULL',    LEVEL: 'NE',  SVG: 'NE',  ID: 4,  BLK: 'EC1H1', ALIGN: 'start', TAG: '101850-EJB-96140A',      DESC: 'NA',                                                           PO_NO: '',            PO_DATE: undefined,    VENDOR: '',                                 COUNTRY: '',            INCOTERMS: '',    FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: undefined,    SRD: undefined,    INSTALL: '', SHIP_MODE: '',         SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: '',   G_EREC: 'EC1G2', ER_PS: undefined,    ER_PF: undefined,    ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 0,    DT_ETA: 0,     DT_SRD: 0, },
      { SN: 544, AREA: 'HULL',    ZONE: 'HULL',    LEVEL: 'NE',  SVG: 'NE',  ID: 5,  BLK: 'EC1H1', ALIGN: 'start', TAG: '101820-EJB-96110',       DESC: 'NA',                                                           PO_NO: '',            PO_DATE: undefined,    VENDOR: '',                                 COUNTRY: '',            INCOTERMS: '',    FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: undefined,    SRD: undefined,    INSTALL: '', SHIP_MODE: '',         SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: '',   G_EREC: 'EC1G2', ER_PS: undefined,    ER_PF: undefined,    ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 0,    DT_ETA: 0,     DT_SRD: 0, },
      { SN: 545, AREA: 'HULL',    ZONE: 'HULL',    LEVEL: 'NE',  SVG: 'NE',  ID: 6,  BLK: 'EC1H1', ALIGN: 'start', TAG: '101730-LDY-91540',       DESC: 'DAVIT CRANE 1',                                                PO_NO: 'NA',          PO_DATE: undefined,    VENDOR: 'NA',                               COUNTRY: 'NA',          INCOTERMS: 'NA',  FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: undefined,    SRD: '2025-10-30', INSTALL: '', SHIP_MODE: 'NA',       SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: 'NA', G_EREC: 'EC1G2', ER_PS: undefined,    ER_PF: undefined,    ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 0,    DT_ETA: 0,     DT_SRD: 0, },
      { SN: 546, AREA: 'HULL',    ZONE: 'HULL',    LEVEL: 'NE',  SVG: 'NE',  ID: 7,  BLK: 'EC1H1', ALIGN: 'start', TAG: '101720-TQZ-97102',       DESC: 'NA',                                                           PO_NO: '',            PO_DATE: undefined,    VENDOR: '',                                 COUNTRY: '',            INCOTERMS: '',    FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: undefined,    SRD: undefined,    INSTALL: '', SHIP_MODE: '',         SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: '',   G_EREC: 'EC1G2', ER_PS: undefined,    ER_PF: undefined,    ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 0,    DT_ETA: 0,     DT_SRD: 0, },
      { SN: 547, AREA: 'HULL',    ZONE: 'HULL',    LEVEL: 'NE',  SVG: 'NE',  ID: 8,  BLK: 'ECP10', ALIGN: 'start', TAG: '101560-SP-96521',        DESC: 'NA',                                                           PO_NO: '',            PO_DATE: undefined,    VENDOR: '',                                 COUNTRY: '',            INCOTERMS: '',    FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: undefined,    SRD: undefined,    INSTALL: '', SHIP_MODE: '',         SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: '',   G_EREC: 'ECP10', ER_PS: '2025-11-14', ER_PF: '2025-12-04', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 0,    DT_ETA: 0,     DT_SRD: 0, },
      { SN: 548, AREA: 'HULL',    ZONE: 'HULL',    LEVEL: 'NE',  SVG: 'NE',  ID: 9,  BLK: 'ECP10', ALIGN: 'start', TAG: '101560-PDT-91851',       DESC: 'NA',                                                           PO_NO: '',            PO_DATE: undefined,    VENDOR: '',                                 COUNTRY: '',            INCOTERMS: '',    FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: undefined,    SRD: undefined,    INSTALL: '', SHIP_MODE: '',         SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: '',   G_EREC: 'ECP10', ER_PS: '2025-11-14', ER_PF: '2025-12-04', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 0,    DT_ETA: 0,     DT_SRD: 0, },
      { SN: 549, AREA: 'HULL',    ZONE: 'HULL',    LEVEL: 'NE',  SVG: 'NE',  ID: 10, BLK: 'ECP10', ALIGN: 'start', TAG: '101560-ZZZ-91800',       DESC: 'NORTH DISPOSAL CAISSON PACKAGE',                               PO_NO: 'QA557BDQ88',  PO_DATE: '2024-01-15', VENDOR: 'Energy Specialties International', COUNTRY: 'USA',         INCOTERMS: 'FOB', FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: '2025-10-30', SRD: '2025-11-12', INSTALL: '', SHIP_MODE: 'by Sea',   SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: 'NA', G_EREC: 'ECP10', ER_PS: '2025-11-14', ER_PF: '2025-12-04', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 13,  DT_ETA: 15,   DT_SRD: 2, },
      { SN: 550, AREA: 'HULL',    ZONE: 'HULL',    LEVEL: 'NE',  SVG: 'NE',  ID: 11, BLK: 'ECP10', ALIGN: 'start', TAG: '101560-LT-91852I',       DESC: 'NA',                                                           PO_NO: '',            PO_DATE: undefined,    VENDOR: '',                                 COUNTRY: '',            INCOTERMS: '',    FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: undefined,    SRD: undefined,    INSTALL: '', SHIP_MODE: '',         SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: '',   G_EREC: 'ECP10', ER_PS: '2025-11-14', ER_PF: '2025-12-04', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 0,    DT_ETA: 0,     DT_SRD: 0, },
      { SN: 551, AREA: 'HULL',    ZONE: 'HULL',    LEVEL: 'NE',  SVG: 'NE',  ID: 12, BLK: 'EC1H1', ALIGN: 'start', TAG: '101970-HAL-91530A',      DESC: 'NA',                                                           PO_NO: '',            PO_DATE: undefined,    VENDOR: '',                                 COUNTRY: '',            INCOTERMS: '',    FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: undefined,    SRD: undefined,    INSTALL: '', SHIP_MODE: '',         SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: '',   G_EREC: 'EC1G2', ER_PS: undefined,    ER_PF: undefined,    ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 0,    DT_ETA: 0,     DT_SRD: 0, },
      { SN: 552, AREA: 'HULL',    ZONE: 'HULL',    LEVEL: 'NE',  SVG: 'NE',  ID: 13, BLK: 'EC1H1', ALIGN: 'start', TAG: '101970-HAL-91530B',      DESC: 'NA',                                                           PO_NO: '',            PO_DATE: undefined,    VENDOR: '',                                 COUNTRY: '',            INCOTERMS: '',    FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: undefined,    SRD: undefined,    INSTALL: '', SHIP_MODE: '',         SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: '',   G_EREC: 'EC1G2', ER_PS: undefined,    ER_PF: undefined,    ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 0,    DT_ETA: 0,     DT_SRD: 0, },
      { SN: 553, AREA: 'HULL',    ZONE: 'HULL',    LEVEL: 'NE',  SVG: 'NE',  ID: 14, BLK: 'EC1H1', ALIGN: 'start', TAG: '101780-PJB-67910',       DESC: 'NA',                                                           PO_NO: '',            PO_DATE: undefined,    VENDOR: '',                                 COUNTRY: '',            INCOTERMS: '',    FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: undefined,    SRD: undefined,    INSTALL: '', SHIP_MODE: '',         SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: '',   G_EREC: 'EC1G2', ER_PS: undefined,    ER_PF: undefined,    ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 0,    DT_ETA: 0,     DT_SRD: 0, },
      { SN: 554, AREA: 'HULL',    ZONE: 'HULL',    LEVEL: 'NE',  SVG: 'NE',  ID: 15, BLK: 'EC1G1', ALIGN: 'start', TAG: '101970-THAH-91700',      DESC: 'AIR HANDLING UNIT',                                            PO_NO: '',            PO_DATE: undefined,    VENDOR: '',                                 COUNTRY: '',            INCOTERMS: '',    FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: undefined,    SRD: undefined,    INSTALL: '', SHIP_MODE: '',         SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: '',   G_EREC: 'EC1G2', ER_PS: undefined,    ER_PF: undefined,    ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 0,    DT_ETA: 0,     DT_SRD: 0, },
      { SN: 555, AREA: 'HULL',    ZONE: 'HULL',    LEVEL: 'NE',  SVG: 'NE',  ID: 16, BLK: 'EC1H1', ALIGN: 'start', TAG: '101720-TQZ-97101',       DESC: 'NA',                                                           PO_NO: 'NA',          PO_DATE: undefined,    VENDOR: 'NA',                               COUNTRY: 'NA',          INCOTERMS: 'NA',  FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: undefined,    SRD: '2025-08-07', INSTALL: '', SHIP_MODE: 'NA',       SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: 'NA', G_EREC: 'EC1G2', ER_PS: undefined,    ER_PF: undefined,    ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 0,    DT_ETA: 0,     DT_SRD: 0, },
      { SN: 556, AREA: 'HULL',    ZONE: 'HULL',    LEVEL: 'NE',  SVG: 'NE',  ID: 17, BLK: 'EC1G1', ALIGN: 'start', TAG: '101440-ABJ-91630',       DESC: 'OILY WATER HOLDING TANK',                                      PO_NO: 'QU557BCQ99',  PO_DATE: '2024-04-01', VENDOR: 'Decatech',                         COUNTRY: 'Korea',       INCOTERMS: 'DAP', FAT_PLAN: '2025-04-30', FAT_FC: undefined,    FAT_ACT: undefined,    ETA: '2025-05-08', SRD: '2025-08-07', INSTALL: '', SHIP_MODE: 'NA',       SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: 'NA', G_EREC: 'EC1G2', ER_PS: undefined,    ER_PF: undefined,    ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 91,  DT_ETA: 0,     DT_SRD: 0, },
      { SN: 557, AREA: 'HULL',    ZONE: 'HULL',    LEVEL: 'NE',  SVG: 'NE',  ID: 18, BLK: 'EC1G1', ALIGN: 'start', TAG: '101440-ZZZ-91620',       DESC: 'OILY WATER SEPARATOR',                                         PO_NO: 'QA557AFHQ54', PO_DATE: '2024-03-26', VENDOR: 'Compass',                          COUNTRY: 'USA',         INCOTERMS: 'FOB', FAT_PLAN: '2025-03-20', FAT_FC: undefined,    FAT_ACT: undefined,    ETA: '2025-06-19', SRD: '2025-08-07', INSTALL: '', SHIP_MODE: 'NA',       SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: 'NA', G_EREC: 'EC1G2', ER_PS: undefined,    ER_PF: undefined,    ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 49,  DT_ETA: 0,     DT_SRD: 0, },
      { SN: 558, AREA: 'HULL',    ZONE: 'HULL',    LEVEL: 'NE',  SVG: 'NE',  ID: 19, BLK: 'EN111', ALIGN: 'start', TAG: '101620-PBE-91600',       DESC: 'DIESEL TRANSFER PUMP 1',                                       PO_NO: 'QU557BWQ31A', PO_DATE: '2024-03-07', VENDOR: 'Goulds Pumps',                     COUNTRY: 'Korea',       INCOTERMS: 'DAP', FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: '2025-02-01', SRD: '2025-03-25', INSTALL: '', SHIP_MODE: 'NA',       SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: 'NA', G_EREC: 'EN112', ER_PS: '2025-06-30', ER_PF: '2025-07-25', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 52,  DT_ETA: 149,  DT_SRD: 97, },
      { SN: 559, AREA: 'HULL',    ZONE: 'HULL',    LEVEL: 'NE',  SVG: 'NE',  ID: 20, BLK: 'EN111', ALIGN: 'start', TAG: '101620-PBE-91610',       DESC: 'DIESEL TRANSFER PUMP 2',                                       PO_NO: 'QU557BWQ31A', PO_DATE: '2024-03-07', VENDOR: 'Goulds Pumps',                     COUNTRY: 'Korea',       INCOTERMS: 'DAP', FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: '2025-02-01', SRD: '2025-03-25', INSTALL: '', SHIP_MODE: 'NA',       SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: 'NA', G_EREC: 'EN112', ER_PS: '2025-06-30', ER_PF: '2025-07-25', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 52,  DT_ETA: 149,  DT_SRD: 97, },
      { SN: 560, AREA: 'HULL',    ZONE: 'HULL',    LEVEL: 'NE',  SVG: 'NE',  ID: 21, BLK: 'EN111', ALIGN: 'start', TAG: '101440-PBE-91440',       DESC: 'DAILY BILGE PUMP 1',                                           PO_NO: 'QU557BWQ32',  PO_DATE: '2024-01-26', VENDOR: 'Jec_Verder',                       COUNTRY: 'Korea',       INCOTERMS: 'DAP', FAT_PLAN: '2024-11-11', FAT_FC: undefined,    FAT_ACT: undefined,    ETA: '2025-01-26', SRD: '2025-03-25', INSTALL: '', SHIP_MODE: 'NA',       SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: 'NA', G_EREC: 'EN112', ER_PS: '2025-06-30', ER_PF: '2025-07-25', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 58,  DT_ETA: 155,  DT_SRD: 97, },
      { SN: 561, AREA: 'HULL',    ZONE: 'HULL',    LEVEL: 'NE',  SVG: 'NE',  ID: 22, BLK: 'EN111', ALIGN: 'end',   TAG: '101520-PBE-91410',       DESC: 'BALLAST/MAIN BILGE PUMP 1',                                    PO_NO: '',            PO_DATE: undefined,    VENDOR: '',                                 COUNTRY: '',            INCOTERMS: '',    FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: undefined,    SRD: undefined,    INSTALL: '', SHIP_MODE: '',         SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: '',   G_EREC: 'EN112', ER_PS: '2025-06-30', ER_PF: '2025-07-25', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 0,    DT_ETA: 0,     DT_SRD: 0, },
      { SN: 562, AREA: 'HULL',    ZONE: 'HULL',    LEVEL: 'NE',  SVG: 'NE',  ID: 23, BLK: 'EN111', ALIGN: 'end',   TAG: '101520-PBE-91420',       DESC: 'BALLAST/MAIN BILGE PUMP 2',                                    PO_NO: 'QE557BWQ31',  PO_DATE: '2024-01-31', VENDOR: 'Eureka Pumps',                     COUNTRY: 'Norway',      INCOTERMS: 'DAP', FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: '2025-04-30', SRD: '2025-03-25', INSTALL: '', SHIP_MODE: 'NA',       SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: 'NA', G_EREC: 'EN112', ER_PS: '2025-06-30', ER_PF: '2025-07-25', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: -36, DT_ETA: 61,   DT_SRD: 97, },
      { SN: 563, AREA: 'HULL',    ZONE: 'HULL',    LEVEL: 'NE',  SVG: 'NE',  ID: 24, BLK: 'EC1G1', ALIGN: 'end',   TAG: '101650-ZZZ-91370',       DESC: 'SOLENOID VALVE CABINET 1',                                     PO_NO: 'QU557ALH01',  PO_DATE: '2024-03-29', VENDOR: 'Emerson Korea',                    COUNTRY: 'Korea',       INCOTERMS: 'DAP', FAT_PLAN: '2024-12-17', FAT_FC: undefined,    FAT_ACT: undefined,    ETA: '2025-03-28', SRD: '2025-05-30', INSTALL: '', SHIP_MODE: 'NA',       SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: 'NA', G_EREC: 'EC1G2', ER_PS: undefined,    ER_PF: undefined,    ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 63,  DT_ETA: 0,     DT_SRD: 0, },
      { SN: 564, AREA: 'HULL',    ZONE: 'HULL',    LEVEL: 'NE',  SVG: 'NE',  ID: 25, BLK: 'EC1G1', ALIGN: 'end',   TAG: '101650-ZZZ-91470',       DESC: 'HYDRAULIC POWER UNIT 1',                                       PO_NO: 'QU557ALH01',  PO_DATE: '2024-03-29', VENDOR: 'Emerson Korea',                    COUNTRY: 'Korea',       INCOTERMS: 'DAP', FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: '2025-03-28', SRD: '2025-05-30', INSTALL: '', SHIP_MODE: 'by Truck', SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: 'NA', G_EREC: 'EC1G2', ER_PS: undefined,    ER_PF: undefined,    ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 63,  DT_ETA: 0,     DT_SRD: 0, },
      { SN: 565, AREA: 'HULL',    ZONE: 'HULL',    LEVEL: 'NE',  SVG: 'NE',  ID: 26, BLK: 'EC1H1', ALIGN: 'end',   TAG: '101520-LT-91171I',       DESC: 'NA',                                                           PO_NO: '',            PO_DATE: undefined,    VENDOR: '',                                 COUNTRY: '',            INCOTERMS: '',    FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: undefined,    SRD: undefined,    INSTALL: '', SHIP_MODE: '',         SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: '',   G_EREC: 'EC1G2', ER_PS: undefined,    ER_PF: undefined,    ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 0,    DT_ETA: 0,     DT_SRD: 0, },
      { SN: 566, AREA: 'HULL',    ZONE: 'HULL',    LEVEL: 'NE',  SVG: 'NE',  ID: 27, BLK: 'EC1G1', ALIGN: 'end',   TAG: '101970-THAH-91500',      DESC: 'AIR HANDLING UNIT',                                            PO_NO: 'QU557BKHH1',  PO_DATE: '2024-07-19', VENDOR: 'HI AIR',                           COUNTRY: 'Korea',       INCOTERMS: 'DAP', FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: '2025-08-15', SRD: '2025-09-29', INSTALL: '', SHIP_MODE: 'NA',       SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: 'NA', G_EREC: 'EC1G2', ER_PS: undefined,    ER_PF: undefined,    ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 45,  DT_ETA: 0,     DT_SRD: 0, },
      { SN: 567, AREA: 'HULL',    ZONE: 'HULL',    LEVEL: 'NE',  SVG: 'NE',  ID: 28, BLK: 'ECP20', ALIGN: 'end',   TAG: '101860-ENAV-62830',      DESC: 'NA',                                                           PO_NO: '',            PO_DATE: undefined,    VENDOR: '',                                 COUNTRY: '',            INCOTERMS: '',    FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: undefined,    SRD: undefined,    INSTALL: '', SHIP_MODE: '',         SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: '',   G_EREC: 'EC1G2', ER_PS: undefined,    ER_PF: undefined,    ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 0,    DT_ETA: 0,     DT_SRD: 0, },
      { SN: 568, AREA: 'HULL',    ZONE: 'HULL',    LEVEL: 'NE',  SVG: 'NE',  ID: 29, BLK: 'ECP20', ALIGN: 'end',   TAG: '101940-MJB-97101',       DESC: 'NA',                                                           PO_NO: '',            PO_DATE: undefined,    VENDOR: '',                                 COUNTRY: '',            INCOTERMS: '',    FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: undefined,    SRD: undefined,    INSTALL: '', SHIP_MODE: '',         SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: '',   G_EREC: 'EC1G2', ER_PS: undefined,    ER_PF: undefined,    ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 0,    DT_ETA: 0,     DT_SRD: 0, },
      { SN: 569, AREA: 'HULL',    ZONE: 'HULL',    LEVEL: 'NE',  SVG: 'NE',  ID: 30, BLK: 'EC1H1', ALIGN: 'end',   TAG: '101530-SSW-97000A',      DESC: 'NA',                                                           PO_NO: '',            PO_DATE: undefined,    VENDOR: '',                                 COUNTRY: '',            INCOTERMS: '',    FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: undefined,    SRD: undefined,    INSTALL: '', SHIP_MODE: '',         SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: '',   G_EREC: 'EC1G2', ER_PS: undefined,    ER_PF: undefined,    ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 0,    DT_ETA: 0,     DT_SRD: 0, },
      { SN: 570, AREA: 'HULL',    ZONE: 'HULL',    LEVEL: 'NE',  SVG: 'NE',  ID: 31, BLK: 'EC1H1', ALIGN: 'end',   TAG: '101620-ZZZ-91250',       DESC: 'NA',                                                           PO_NO: '',            PO_DATE: undefined,    VENDOR: '',                                 COUNTRY: '',            INCOTERMS: '',    FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: undefined,    SRD: undefined,    INSTALL: '', SHIP_MODE: '',         SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: '',   G_EREC: 'EC1G2', ER_PS: undefined,    ER_PF: undefined,    ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 0,    DT_ETA: 0,     DT_SRD: 0, },
      { SN: 571, AREA: 'HULL',    ZONE: 'HULL',    LEVEL: 'NE',  SVG: 'NE',  ID: 32, BLK: 'EC1H1', ALIGN: 'end',   TAG: '101470-ZZZ-91190',       DESC: 'HYPOCHLORITE GENERATOR PACKAGE',                               PO_NO: '',            PO_DATE: undefined,    VENDOR: '',                                 COUNTRY: '',            INCOTERMS: '',    FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: undefined,    SRD: undefined,    INSTALL: '', SHIP_MODE: '',         SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: '',   G_EREC: 'EC1G2', ER_PS: undefined,    ER_PF: undefined,    ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 0,    DT_ETA: 0,     DT_SRD: 0, },
      { SN: 572, AREA: 'HULL',    ZONE: 'HULL',    LEVEL: 'NW',  SVG: 'NW',  ID: 1,  BLK: 'WC1H1', ALIGN: 'start', TAG: '101970-HAL-92530A',      DESC: 'NA',                                                           PO_NO: '',            PO_DATE: undefined,    VENDOR: '',                                 COUNTRY: '',            INCOTERMS: '',    FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: undefined,    SRD: undefined,    INSTALL: '', SHIP_MODE: '',         SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: '',   G_EREC: 'WC1G2', ER_PS: undefined,    ER_PF: undefined,    ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 0,    DT_ETA: 0,     DT_SRD: 0, },
      { SN: 573, AREA: 'HULL',    ZONE: 'HULL',    LEVEL: 'NW',  SVG: 'NW',  ID: 2,  BLK: 'WC1H1', ALIGN: 'start', TAG: '101850-EJB-96240B',      DESC: 'NA',                                                           PO_NO: '',            PO_DATE: undefined,    VENDOR: '',                                 COUNTRY: '',            INCOTERMS: '',    FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: undefined,    SRD: undefined,    INSTALL: '', SHIP_MODE: '',         SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: '',   G_EREC: 'WC1G2', ER_PS: undefined,    ER_PF: undefined,    ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 0,    DT_ETA: 0,     DT_SRD: 0, },
      { SN: 574, AREA: 'HULL',    ZONE: 'HULL',    LEVEL: 'NW',  SVG: 'NW',  ID: 3,  BLK: 'WC1H1', ALIGN: 'start', TAG: '101850-EJB-96240A',      DESC: 'NA',                                                           PO_NO: '',            PO_DATE: undefined,    VENDOR: '',                                 COUNTRY: '',            INCOTERMS: '',    FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: undefined,    SRD: undefined,    INSTALL: '', SHIP_MODE: '',         SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: '',   G_EREC: 'WC1G2', ER_PS: undefined,    ER_PF: undefined,    ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 0,    DT_ETA: 0,     DT_SRD: 0, },
      { SN: 575, AREA: 'HULL',    ZONE: 'HULL',    LEVEL: 'NW',  SVG: 'NW',  ID: 4,  BLK: 'WC1H1', ALIGN: 'start', TAG: '101720-TQZ-97201',       DESC: 'NA',                                                           PO_NO: '',            PO_DATE: undefined,    VENDOR: '',                                 COUNTRY: '',            INCOTERMS: '',    FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: undefined,    SRD: undefined,    INSTALL: '', SHIP_MODE: '',         SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: '',   G_EREC: 'WC1G2', ER_PS: undefined,    ER_PF: undefined,    ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 0,    DT_ETA: 0,     DT_SRD: 0, },
      { SN: 576, AREA: 'HULL',    ZONE: 'HULL',    LEVEL: 'NW',  SVG: 'NW',  ID: 5,  BLK: 'WC1H1', ALIGN: 'start', TAG: '101180-ZZZ-92250',       DESC: 'NA',                                                           PO_NO: '',            PO_DATE: undefined,    VENDOR: '',                                 COUNTRY: '',            INCOTERMS: '',    FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: undefined,    SRD: undefined,    INSTALL: '', SHIP_MODE: '',         SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: '',   G_EREC: 'WC1G2', ER_PS: undefined,    ER_PF: undefined,    ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 0,    DT_ETA: 0,     DT_SRD: 0, },
      { SN: 577, AREA: 'HULL',    ZONE: 'HULL',    LEVEL: 'NW',  SVG: 'NW',  ID: 6,  BLK: 'WC1H1', ALIGN: 'start', TAG: '101180-ZZZ-92260',       DESC: 'NA',                                                           PO_NO: '',            PO_DATE: undefined,    VENDOR: '',                                 COUNTRY: '',            INCOTERMS: '',    FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: undefined,    SRD: undefined,    INSTALL: '', SHIP_MODE: '',         SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: '',   G_EREC: 'WC1G2', ER_PS: undefined,    ER_PF: undefined,    ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 0,    DT_ETA: 0,     DT_SRD: 0, },
      { SN: 578, AREA: 'HULL',    ZONE: 'HULL',    LEVEL: 'NW',  SVG: 'NW',  ID: 7,  BLK: 'WC1H1', ALIGN: 'start', TAG: '101530-SSW-97000B',      DESC: 'NA',                                                           PO_NO: '',            PO_DATE: undefined,    VENDOR: '',                                 COUNTRY: '',            INCOTERMS: '',    FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: undefined,    SRD: undefined,    INSTALL: '', SHIP_MODE: '',         SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: '',   G_EREC: 'WC1G2', ER_PS: undefined,    ER_PF: undefined,    ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 0,    DT_ETA: 0,     DT_SRD: 0, },
      { SN: 579, AREA: 'HULL',    ZONE: 'HULL',    LEVEL: 'NW',  SVG: 'NW',  ID: 8,  BLK: 'WCP10', ALIGN: 'start', TAG: '101860-ENAV-62840',      DESC: 'NA',                                                           PO_NO: '',            PO_DATE: undefined,    VENDOR: '',                                 COUNTRY: '',            INCOTERMS: '',    FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: undefined,    SRD: undefined,    INSTALL: '', SHIP_MODE: '',         SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: '',   G_EREC: 'WCP10', ER_PS: '2025-12-03', ER_PF: '2025-12-23', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 0,    DT_ETA: 0,     DT_SRD: 0, },
      { SN: 580, AREA: 'HULL',    ZONE: 'HULL',    LEVEL: 'NW',  SVG: 'NW',  ID: 9,  BLK: 'WC1H1', ALIGN: 'start', TAG: '101940-MJB-97201',       DESC: 'NA',                                                           PO_NO: '',            PO_DATE: undefined,    VENDOR: '',                                 COUNTRY: '',            INCOTERMS: '',    FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: undefined,    SRD: undefined,    INSTALL: '', SHIP_MODE: '',         SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: '',   G_EREC: 'WC1G2', ER_PS: undefined,    ER_PF: undefined,    ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 0,    DT_ETA: 0,     DT_SRD: 0, },
      { SN: 581, AREA: 'HULL',    ZONE: 'HULL',    LEVEL: 'NW',  SVG: 'NW',  ID: 10, BLK: 'WC1G1', ALIGN: 'start', TAG: '101650-ZZZ-92370',       DESC: 'SOLENOID VALVE CABINET 2',                                     PO_NO: 'QU557ALH01',  PO_DATE: '2024-03-29', VENDOR: 'Emerson Korea',                    COUNTRY: 'Korea',       INCOTERMS: 'DAP', FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: '2025-03-28', SRD: '2025-05-30', INSTALL: '', SHIP_MODE: 'NA',       SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: 'NA', G_EREC: 'WC1G2', ER_PS: undefined,    ER_PF: undefined,    ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 63,  DT_ETA: 0,     DT_SRD: 0, },
      { SN: 582, AREA: 'HULL',    ZONE: 'HULL',    LEVEL: 'NW',  SVG: 'NW',  ID: 11, BLK: 'WC1G1', ALIGN: 'start', TAG: '101650-ZZZ-92470',       DESC: 'HYDRAULIC POWER UNIT 2',                                       PO_NO: 'QU557ALH01',  PO_DATE: '2024-03-29', VENDOR: 'Emerson Korea',                    COUNTRY: 'Korea',       INCOTERMS: 'DAP', FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: '2025-03-28', SRD: '2025-05-30', INSTALL: '', SHIP_MODE: 'by Truck', SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: 'NA', G_EREC: 'WC1G2', ER_PS: undefined,    ER_PF: undefined,    ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 63,  DT_ETA: 0,     DT_SRD: 0, },
      { SN: 583, AREA: 'HULL',    ZONE: 'HULL',    LEVEL: 'NW',  SVG: 'NW',  ID: 12, BLK: 'WN111', ALIGN: 'end',   TAG: '101520-PBE-92420',       DESC: 'BALLAST / MAIN BILGE PUMP 4',                                  PO_NO: 'QE557BWQ31',  PO_DATE: '2024-01-31', VENDOR: 'Eureka Pumps',                     COUNTRY: 'Norway',      INCOTERMS: 'DAP', FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: '2025-04-30', SRD: '2025-05-15', INSTALL: '', SHIP_MODE: 'NA',       SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: 'NA', G_EREC: 'WN112', ER_PS: '2025-07-24', ER_PF: '2025-09-02', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 15,  DT_ETA: 85,   DT_SRD: 70, },
      { SN: 584, AREA: 'HULL',    ZONE: 'HULL',    LEVEL: 'NW',  SVG: 'NW',  ID: 13, BLK: 'WN111', ALIGN: 'end',   TAG: '101520-PBE-92410',       DESC: 'BALLAST / MAIN BILGE PUMP 3',                                  PO_NO: 'QE557BWQ31',  PO_DATE: '2024-01-31', VENDOR: 'Eureka Pumps',                     COUNTRY: 'Norway',      INCOTERMS: 'DAP', FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: '2025-04-30', SRD: '2025-05-15', INSTALL: '', SHIP_MODE: 'NA',       SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: 'NA', G_EREC: 'WN112', ER_PS: '2025-07-24', ER_PF: '2025-09-02', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 15,  DT_ETA: 85,   DT_SRD: 70, },
      { SN: 585, AREA: 'HULL',    ZONE: 'HULL',    LEVEL: 'NW',  SVG: 'NW',  ID: 14, BLK: 'WN111', ALIGN: 'end',   TAG: '101440-PBE-92440',       DESC: 'DAILY BILGE PUMP 2',                                           PO_NO: 'QU557BWQ32',  PO_DATE: '2024-01-26', VENDOR: 'Jec_Verder',                       COUNTRY: 'Korea',       INCOTERMS: 'DAP', FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: '2025-01-26', SRD: '2025-05-15', INSTALL: '', SHIP_MODE: 'NA',       SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: 'NA', G_EREC: 'WN112', ER_PS: '2025-07-24', ER_PF: '2025-09-02', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 109, DT_ETA: 179,  DT_SRD: 70, },
      { SN: 586, AREA: 'HULL',    ZONE: 'HULL',    LEVEL: 'NW',  SVG: 'NW',  ID: 15, BLK: 'WC1H1', ALIGN: 'end',   TAG: '101790-SJB-67920',       DESC: 'NA',                                                           PO_NO: '',            PO_DATE: undefined,    VENDOR: '',                                 COUNTRY: '',            INCOTERMS: '',    FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: undefined,    SRD: undefined,    INSTALL: '', SHIP_MODE: '',         SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: '',   G_EREC: 'WC1G2', ER_PS: undefined,    ER_PF: undefined,    ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 0,    DT_ETA: 0,     DT_SRD: 0, },
      { SN: 587, AREA: 'HULL',    ZONE: 'HULL',    LEVEL: 'NW',  SVG: 'NW',  ID: 16, BLK: 'WC1H1', ALIGN: 'end',   TAG: '101180-ZZZ-92270',       DESC: 'NA',                                                           PO_NO: '',            PO_DATE: undefined,    VENDOR: '',                                 COUNTRY: '',            INCOTERMS: '',    FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: undefined,    SRD: undefined,    INSTALL: '', SHIP_MODE: '',         SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: '',   G_EREC: 'WC1G2', ER_PS: undefined,    ER_PF: undefined,    ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 0,    DT_ETA: 0,     DT_SRD: 0, },
      { SN: 588, AREA: 'HULL',    ZONE: 'HULL',    LEVEL: 'NW',  SVG: 'NW',  ID: 17, BLK: 'WC1H1', ALIGN: 'end',   TAG: '101520-LT-92171I',       DESC: 'NA',                                                           PO_NO: '',            PO_DATE: undefined,    VENDOR: '',                                 COUNTRY: '',            INCOTERMS: '',    FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: undefined,    SRD: undefined,    INSTALL: '', SHIP_MODE: '',         SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: '',   G_EREC: 'WC1G2', ER_PS: undefined,    ER_PF: undefined,    ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 0,    DT_ETA: 0,     DT_SRD: 0, },
      { SN: 589, AREA: 'HULL',    ZONE: 'HULL',    LEVEL: 'NW',  SVG: 'NW',  ID: 18, BLK: 'WC1H1', ALIGN: 'end',   TAG: '101700-FJB-67921',       DESC: 'NA',                                                           PO_NO: '',            PO_DATE: undefined,    VENDOR: '',                                 COUNTRY: '',            INCOTERMS: '',    FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: undefined,    SRD: undefined,    INSTALL: '', SHIP_MODE: '',         SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: '',   G_EREC: 'WC1G2', ER_PS: undefined,    ER_PF: undefined,    ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 0,    DT_ETA: 0,     DT_SRD: 0, },
      { SN: 590, AREA: 'HULL',    ZONE: 'HULL',    LEVEL: 'NW',  SVG: 'NW',  ID: 19, BLK: 'WC1H1', ALIGN: 'end',   TAG: '101700-FJB-67920',       DESC: 'NA',                                                           PO_NO: '',            PO_DATE: undefined,    VENDOR: '',                                 COUNTRY: '',            INCOTERMS: '',    FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: undefined,    SRD: undefined,    INSTALL: '', SHIP_MODE: '',         SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: '',   G_EREC: 'WC1G2', ER_PS: undefined,    ER_PF: undefined,    ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 0,    DT_ETA: 0,     DT_SRD: 0, },
      { SN: 591, AREA: 'HULL',    ZONE: 'HULL',    LEVEL: 'NW',  SVG: 'NW',  ID: 20, BLK: 'WC1H1', ALIGN: 'end',   TAG: '101780-PJB-67920',       DESC: 'NA',                                                           PO_NO: '',            PO_DATE: undefined,    VENDOR: '',                                 COUNTRY: '',            INCOTERMS: '',    FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: undefined,    SRD: undefined,    INSTALL: '', SHIP_MODE: '',         SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: '',   G_EREC: 'WC1G2', ER_PS: undefined,    ER_PF: undefined,    ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 0,    DT_ETA: 0,     DT_SRD: 0, },
      { SN: 592, AREA: 'HULL',    ZONE: 'HULL',    LEVEL: 'NW',  SVG: 'NW',  ID: 21, BLK: 'WC1G1', ALIGN: 'end',   TAG: '101970-THAH-92500',      DESC: 'AIR HANDLING UNIT',                                            PO_NO: 'QU557BKHH1',  PO_DATE: '2024-07-19', VENDOR: 'HI AIR',                           COUNTRY: 'Korea',       INCOTERMS: 'DAP', FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: '2025-08-15', SRD: '2025-10-09', INSTALL: '', SHIP_MODE: 'NA',       SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: 'NA', G_EREC: 'WC1G2', ER_PS: undefined,    ER_PF: undefined,    ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 55,  DT_ETA: 0,     DT_SRD: 0, },
      { SN: 593, AREA: 'HULL',    ZONE: 'HULL',    LEVEL: 'NW',  SVG: 'NW',  ID: 22, BLK: 'WC1H1', ALIGN: 'end',   TAG: '101720-TQZ-97202',       DESC: 'NA',                                                           PO_NO: '',            PO_DATE: undefined,    VENDOR: '',                                 COUNTRY: '',            INCOTERMS: '',    FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: undefined,    SRD: undefined,    INSTALL: '', SHIP_MODE: '',         SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: '',   G_EREC: 'WC1G2', ER_PS: undefined,    ER_PF: undefined,    ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 0,    DT_ETA: 0,     DT_SRD: 0, },
      { SN: 594, AREA: 'HULL',    ZONE: 'HULL',    LEVEL: 'NW',  SVG: 'NW',  ID: 23, BLK: 'WC1H1', ALIGN: 'end',   TAG: '101790-SJB-67922',       DESC: 'NA',                                                           PO_NO: '',            PO_DATE: undefined,    VENDOR: '',                                 COUNTRY: '',            INCOTERMS: '',    FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: undefined,    SRD: undefined,    INSTALL: '', SHIP_MODE: '',         SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: '',   G_EREC: 'WC1G2', ER_PS: undefined,    ER_PF: undefined,    ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 0,    DT_ETA: 0,     DT_SRD: 0, },
      { SN: 595, AREA: 'HULL',    ZONE: 'HULL',    LEVEL: 'NW',  SVG: 'NW',  ID: 24, BLK: 'WC1H1', ALIGN: 'end',   TAG: '101790-SJB-67921',       DESC: 'NA',                                                           PO_NO: '',            PO_DATE: undefined,    VENDOR: '',                                 COUNTRY: '',            INCOTERMS: '',    FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: undefined,    SRD: undefined,    INSTALL: '', SHIP_MODE: '',         SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: '',   G_EREC: 'WC1G2', ER_PS: undefined,    ER_PF: undefined,    ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 0,    DT_ETA: 0,     DT_SRD: 0, },
      { SN: 596, AREA: 'HULL',    ZONE: 'HULL',    LEVEL: 'NW',  SVG: 'NW',  ID: 25, BLK: 'WC1H1', ALIGN: 'end',   TAG: '101730-LDY-92540',       DESC: 'DAVIT CRANE 2',                                                PO_NO: 'NA',          PO_DATE: undefined,    VENDOR: 'NA',                               COUNTRY: 'NA',          INCOTERMS: 'NA',  FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: undefined,    SRD: '2025-10-30', INSTALL: '', SHIP_MODE: 'NA',       SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: 'NA', G_EREC: 'WC1G2', ER_PS: undefined,    ER_PF: undefined,    ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 0,    DT_ETA: 0,     DT_SRD: 0, },
      { SN: 597, AREA: 'HULL',    ZONE: 'HULL',    LEVEL: 'NW',  SVG: 'NW',  ID: 26, BLK: 'WC1H1', ALIGN: 'end',   TAG: '101970-HAL-92530B',      DESC: 'NA',                                                           PO_NO: '',            PO_DATE: undefined,    VENDOR: '',                                 COUNTRY: '',            INCOTERMS: '',    FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: undefined,    SRD: undefined,    INSTALL: '', SHIP_MODE: '',         SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: '',   G_EREC: 'WC1G2', ER_PS: undefined,    ER_PF: undefined,    ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 0,    DT_ETA: 0,     DT_SRD: 0, },
      { SN: 598, AREA: 'HULL',    ZONE: 'HULL',    LEVEL: 'SE',  SVG: 'SE',  ID: 1,  BLK: 'EC2H1', ALIGN: 'start', TAG: '101660-ZZZ-94940',       DESC: 'STU SOLIDS HANDLING SYSTEM',                                   PO_NO: 'QA557BHHQ86', PO_DATE: '2024-03-06', VENDOR: 'De Nora Water Technologies, LLC',  COUNTRY: 'USA',         INCOTERMS: 'FOB', FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: '2025-07-01', SRD: '2025-08-12', INSTALL: '', SHIP_MODE: 'NA',       SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: 'NA', G_EREC: 'EC2G2', ER_PS: undefined,    ER_PF: undefined,    ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 42,  DT_ETA: 0,     DT_SRD: 0, },
      { SN: 599, AREA: 'HULL',    ZONE: 'HULL',    LEVEL: 'SE',  SVG: 'SE',  ID: 2,  BLK: 'EC2H1', ALIGN: 'start', TAG: '101940-MJB-97401',       DESC: 'NA',                                                           PO_NO: '',            PO_DATE: undefined,    VENDOR: '',                                 COUNTRY: '',            INCOTERMS: '',    FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: undefined,    SRD: undefined,    INSTALL: '', SHIP_MODE: '',         SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: '',   G_EREC: 'EC2G2', ER_PS: undefined,    ER_PF: undefined,    ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 0,    DT_ETA: 0,     DT_SRD: 0, },
      { SN: 600, AREA: 'HULL',    ZONE: 'HULL',    LEVEL: 'SE',  SVG: 'SE',  ID: 3,  BLK: 'EC2H1', ALIGN: 'start', TAG: '101720-TQZ-97401',       DESC: 'NA',                                                           PO_NO: 'NA',          PO_DATE: undefined,    VENDOR: 'NA',                               COUNTRY: 'NA',          INCOTERMS: 'NA',  FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: undefined,    SRD: '2025-08-12', INSTALL: '', SHIP_MODE: 'NA',       SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: 'NA', G_EREC: 'EC2G2', ER_PS: undefined,    ER_PF: undefined,    ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 0,    DT_ETA: 0,     DT_SRD: 0, },
      { SN: 601, AREA: 'HULL',    ZONE: 'HULL',    LEVEL: 'SE',  SVG: 'SE',  ID: 4,  BLK: 'EC2H1', ALIGN: 'start', TAG: '101660-ZZZ-94990',       DESC: 'SEWAGE TREATMENT UNIT',                                        PO_NO: 'QA557BHHQ86', PO_DATE: '2024-03-06', VENDOR: 'De Nora Water Technologies, LLC',  COUNTRY: 'USA',         INCOTERMS: 'FOB', FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: '2025-07-01', SRD: '2025-08-12', INSTALL: '', SHIP_MODE: 'NA',       SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: 'NA', G_EREC: 'EC2G2', ER_PS: undefined,    ER_PF: undefined,    ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 42,  DT_ETA: 0,     DT_SRD: 0, },
      { SN: 602, AREA: 'HULL',    ZONE: 'HULL',    LEVEL: 'SE',  SVG: 'SE',  ID: 5,  BLK: 'EC2H1', ALIGN: 'start', TAG: '101660-ZZZ-94930',       DESC: 'DECHLORINATION UNIT',                                          PO_NO: 'QA557BHHQ86', PO_DATE: '2024-03-06', VENDOR: 'De Nora Water Technologies, LLC',  COUNTRY: 'USA',         INCOTERMS: 'FOB', FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: '2025-07-01', SRD: '2025-08-12', INSTALL: '', SHIP_MODE: 'NA',       SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: 'NA', G_EREC: 'EC2G2', ER_PS: undefined,    ER_PF: undefined,    ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 42,  DT_ETA: 0,     DT_SRD: 0, },
      { SN: 603, AREA: 'HULL',    ZONE: 'HULL',    LEVEL: 'SE',  SVG: 'SE',  ID: 6,  BLK: 'EC2H1', ALIGN: 'start', TAG: '101660-ZZZ-94920',       DESC: 'SEWAGE COLLECTION TANK',                                       PO_NO: 'QA557BHHQ86', PO_DATE: '2024-03-06', VENDOR: 'De Nora Water Technologies, LLC',  COUNTRY: 'USA',         INCOTERMS: 'FOB', FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: '2025-07-01', SRD: '2025-08-12', INSTALL: '', SHIP_MODE: 'NA',       SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: 'NA', G_EREC: 'EC2G2', ER_PS: undefined,    ER_PF: undefined,    ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 42,  DT_ETA: 0,     DT_SRD: 0, },
      { SN: 604, AREA: 'HULL',    ZONE: 'HULL',    LEVEL: 'SE',  SVG: 'SE',  ID: 7,  BLK: 'EC2G1', ALIGN: 'start', TAG: '101970-THAH-94500',      DESC: 'AIR HANDLING UNIT',                                            PO_NO: 'QU557BKHH1',  PO_DATE: '2024-07-19', VENDOR: 'HI AIR',                           COUNTRY: 'Korea',       INCOTERMS: 'DAP', FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: '2025-08-15', SRD: '2025-10-02', INSTALL: '', SHIP_MODE: 'NA',       SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: 'NA', G_EREC: 'EC2G2', ER_PS: undefined,    ER_PF: undefined,    ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 48,  DT_ETA: 0,     DT_SRD: 0, },
      { SN: 605, AREA: 'HULL',    ZONE: 'HULL',    LEVEL: 'SE',  SVG: 'SE',  ID: 8,  BLK: 'EC2H1', ALIGN: 'start', TAG: '101520-LT-94171I',       DESC: 'NA',                                                           PO_NO: '',            PO_DATE: undefined,    VENDOR: '',                                 COUNTRY: '',            INCOTERMS: '',    FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: undefined,    SRD: undefined,    INSTALL: '', SHIP_MODE: '',         SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: '',   G_EREC: 'EC2G2', ER_PS: undefined,    ER_PF: undefined,    ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 0,    DT_ETA: 0,     DT_SRD: 0, },
      { SN: 606, AREA: 'HULL',    ZONE: 'HULL',    LEVEL: 'SE',  SVG: 'SE',  ID: 9,  BLK: 'EC2H1', ALIGN: 'start', TAG: '101970-HAL-94530B',      DESC: 'NA',                                                           PO_NO: '',            PO_DATE: undefined,    VENDOR: '',                                 COUNTRY: '',            INCOTERMS: '',    FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: undefined,    SRD: undefined,    INSTALL: '', SHIP_MODE: '',         SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: '',   G_EREC: 'EC2G2', ER_PS: undefined,    ER_PF: undefined,    ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 0,    DT_ETA: 0,     DT_SRD: 0, },
      { SN: 607, AREA: 'HULL',    ZONE: 'HULL',    LEVEL: 'SE',  SVG: 'SE',  ID: 10, BLK: 'EC2H1', ALIGN: 'start', TAG: '101780-PJB-67941',       DESC: 'NA',                                                           PO_NO: '',            PO_DATE: undefined,    VENDOR: '',                                 COUNTRY: '',            INCOTERMS: '',    FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: undefined,    SRD: undefined,    INSTALL: '', SHIP_MODE: '',         SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: '',   G_EREC: 'EC2G2', ER_PS: undefined,    ER_PF: undefined,    ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 0,    DT_ETA: 0,     DT_SRD: 0, },
      { SN: 608, AREA: 'HULL',    ZONE: 'HULL',    LEVEL: 'SE',  SVG: 'SE',  ID: 11, BLK: 'EC2H1', ALIGN: 'start', TAG: '101780-PJB-67942',       DESC: 'NA',                                                           PO_NO: '',            PO_DATE: undefined,    VENDOR: '',                                 COUNTRY: '',            INCOTERMS: '',    FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: undefined,    SRD: undefined,    INSTALL: '', SHIP_MODE: '',         SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: '',   G_EREC: 'EC2G2', ER_PS: undefined,    ER_PF: undefined,    ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 0,    DT_ETA: 0,     DT_SRD: 0, },
      { SN: 609, AREA: 'HULL',    ZONE: 'HULL',    LEVEL: 'SE',  SVG: 'SE',  ID: 12, BLK: 'EC2H1', ALIGN: 'start', TAG: '101780-PJB-67943',       DESC: 'NA',                                                           PO_NO: '',            PO_DATE: undefined,    VENDOR: '',                                 COUNTRY: '',            INCOTERMS: '',    FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: undefined,    SRD: undefined,    INSTALL: '', SHIP_MODE: '',         SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: '',   G_EREC: 'EC2G2', ER_PS: undefined,    ER_PF: undefined,    ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 0,    DT_ETA: 0,     DT_SRD: 0, },
      { SN: 610, AREA: 'HULL',    ZONE: 'HULL',    LEVEL: 'SE',  SVG: 'SE',  ID: 13, BLK: 'EC2H1', ALIGN: 'start', TAG: '101530-SSW-97000D',      DESC: 'NA',                                                           PO_NO: '',            PO_DATE: undefined,    VENDOR: '',                                 COUNTRY: '',            INCOTERMS: '',    FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: undefined,    SRD: undefined,    INSTALL: '', SHIP_MODE: '',         SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: '',   G_EREC: 'EC2G2', ER_PS: undefined,    ER_PF: undefined,    ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 0,    DT_ETA: 0,     DT_SRD: 0, },
      { SN: 611, AREA: 'HULL',    ZONE: 'HULL',    LEVEL: 'SE',  SVG: 'SE',  ID: 14, BLK: 'EC2H1', ALIGN: 'start', TAG: '101730-LDY-94540',       DESC: 'DAVIT CRANE 4',                                                PO_NO: 'NA',          PO_DATE: undefined,    VENDOR: 'NA',                               COUNTRY: 'NA',          INCOTERMS: 'NA',  FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: undefined,    SRD: '2025-10-30', INSTALL: '', SHIP_MODE: 'NA',       SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: 'NA', G_EREC: 'EC2G2', ER_PS: undefined,    ER_PF: undefined,    ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 0,    DT_ETA: 0,     DT_SRD: 0, },
      { SN: 612, AREA: 'HULL',    ZONE: 'HULL',    LEVEL: 'SE',  SVG: 'SE',  ID: 15, BLK: 'EC2H1', ALIGN: 'start', TAG: '101970-HAL-94530A',      DESC: 'NA',                                                           PO_NO: '',            PO_DATE: undefined,    VENDOR: '',                                 COUNTRY: '',            INCOTERMS: '',    FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: undefined,    SRD: undefined,    INSTALL: '', SHIP_MODE: '',         SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: '',   G_EREC: 'EC2G2', ER_PS: undefined,    ER_PF: undefined,    ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 0,    DT_ETA: 0,     DT_SRD: 0, },
      { SN: 613, AREA: 'HULL',    ZONE: 'HULL',    LEVEL: 'SE',  SVG: 'SE',  ID: 16, BLK: 'EC2H1', ALIGN: 'start', TAG: '101720-TQZ-97402',       DESC: 'NA',                                                           PO_NO: '',            PO_DATE: undefined,    VENDOR: '',                                 COUNTRY: '',            INCOTERMS: '',    FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: undefined,    SRD: undefined,    INSTALL: '', SHIP_MODE: '',         SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: '',   G_EREC: 'EC2G2', ER_PS: undefined,    ER_PF: undefined,    ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 0,    DT_ETA: 0,     DT_SRD: 0, },
      { SN: 614, AREA: 'HULL',    ZONE: 'HULL',    LEVEL: 'SE',  SVG: 'SE',  ID: 17, BLK: 'EC2H1', ALIGN: 'start', TAG: '101560-SP-96558',        DESC: 'NA',                                                           PO_NO: '',            PO_DATE: undefined,    VENDOR: '',                                 COUNTRY: '',            INCOTERMS: '',    FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: undefined,    SRD: undefined,    INSTALL: '', SHIP_MODE: '',         SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: '',   G_EREC: 'EC2G2', ER_PS: undefined,    ER_PF: undefined,    ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 0,    DT_ETA: 0,     DT_SRD: 0, },
      { SN: 615, AREA: 'HULL',    ZONE: 'HULL',    LEVEL: 'SE',  SVG: 'SE',  ID: 18, BLK: 'EN211', ALIGN: 'start', TAG: '101520-PBE-94420',       DESC: 'BALLAST/MAIN BILGE PUMP 8',                                    PO_NO: 'QE557BWQ31',  PO_DATE: '2024-01-31', VENDOR: 'Eureka Pumps',                     COUNTRY: 'Norway',      INCOTERMS: 'DAP', FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: '2025-04-30', SRD: '2025-05-08', INSTALL: '', SHIP_MODE: 'NA',       SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: 'NA', G_EREC: 'EN212', ER_PS: '2025-07-17', ER_PF: '2025-08-26', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 8,   DT_ETA: 78,   DT_SRD: 70, },
      { SN: 616, AREA: 'HULL',    ZONE: 'HULL',    LEVEL: 'SE',  SVG: 'SE',  ID: 19, BLK: 'EN211', ALIGN: 'start', TAG: '101520-PBE-94410',       DESC: 'BALLAST/MAIN BILGE PUMP 7',                                    PO_NO: 'QE557BWQ31',  PO_DATE: '2024-01-31', VENDOR: 'Eureka Pumps',                     COUNTRY: 'Norway',      INCOTERMS: 'DAP', FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: '2025-04-30', SRD: '2025-05-08', INSTALL: '', SHIP_MODE: 'NA',       SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: 'NA', G_EREC: 'EN212', ER_PS: '2025-07-17', ER_PF: '2025-08-26', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 8,   DT_ETA: 78,   DT_SRD: 70, },
      { SN: 617, AREA: 'HULL',    ZONE: 'HULL',    LEVEL: 'SE',  SVG: 'SE',  ID: 20, BLK: 'EN211', ALIGN: 'end',   TAG: '101440-PBE-94440',       DESC: 'DAILY BILGE PUMP 4',                                           PO_NO: 'QU557BWQ32',  PO_DATE: '2024-01-26', VENDOR: 'Jec_Verder',                       COUNTRY: 'Korea',       INCOTERMS: 'DAP', FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: '2025-01-26', SRD: '2025-05-08', INSTALL: '', SHIP_MODE: 'NA',       SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: 'NA', G_EREC: 'EN212', ER_PS: '2025-07-17', ER_PF: '2025-08-26', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 102, DT_ETA: 172,  DT_SRD: 70, },
      { SN: 618, AREA: 'HULL',    ZONE: 'HULL',    LEVEL: 'SE',  SVG: 'SE',  ID: 21, BLK: 'EC2G1', ALIGN: 'end',   TAG: '101650-ZZZ-94370',       DESC: 'SOLENOID VALVE CABINET 4',                                     PO_NO: 'QU557ALH01',  PO_DATE: '2024-03-29', VENDOR: 'Emerson Korea',                    COUNTRY: 'Korea',       INCOTERMS: 'DAP', FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: '2025-03-28', SRD: '2025-05-30', INSTALL: '', SHIP_MODE: 'NA',       SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: 'NA', G_EREC: 'EC2G2', ER_PS: undefined,    ER_PF: undefined,    ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 63,  DT_ETA: 0,     DT_SRD: 0, },
      { SN: 619, AREA: 'HULL',    ZONE: 'HULL',    LEVEL: 'SE',  SVG: 'SE',  ID: 22, BLK: 'EC2H1', ALIGN: 'end',   TAG: '101500-PBE-94040',       DESC: 'SEAWATER SERVICE BOOSTER PUMP 2',                              PO_NO: 'QU557BWQ31A', PO_DATE: '2024-03-07', VENDOR: 'Goulds Pumps',                     COUNTRY: 'Korea',       INCOTERMS: 'DAP', FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: '2025-02-01', SRD: '2025-08-12', INSTALL: '', SHIP_MODE: 'NA',       SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: 'NA', G_EREC: 'EC2G2', ER_PS: undefined,    ER_PF: undefined,    ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 192, DT_ETA: 0,     DT_SRD: 0, },
      { SN: 620, AREA: 'HULL',    ZONE: 'HULL',    LEVEL: 'SE',  SVG: 'SE',  ID: 23, BLK: 'ECP40', ALIGN: 'end',   TAG: '101560-SP-96556',        DESC: 'NA',                                                           PO_NO: '',            PO_DATE: undefined,    VENDOR: '',                                 COUNTRY: '',            INCOTERMS: '',    FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: undefined,    SRD: undefined,    INSTALL: '', SHIP_MODE: '',         SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: '',   G_EREC: 'ECP40', ER_PS: '2025-11-19', ER_PF: '2025-12-09', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 0,    DT_ETA: 0,     DT_SRD: 0, },
      { SN: 621, AREA: 'HULL',    ZONE: 'HULL',    LEVEL: 'SE',  SVG: 'SE',  ID: 24, BLK: 'EC2G1', ALIGN: 'end',   TAG: '101650-ZZZ-94470',       DESC: 'HYDRAULIC POWER UNIT 4',                                       PO_NO: 'QU557ALH01',  PO_DATE: '2024-03-29', VENDOR: 'Emerson Korea',                    COUNTRY: 'Korea',       INCOTERMS: 'DAP', FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: '2025-03-28', SRD: '2025-05-30', INSTALL: '', SHIP_MODE: 'by Truck', SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: 'NA', G_EREC: 'EC2G2', ER_PS: undefined,    ER_PF: undefined,    ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 63,  DT_ETA: 0,     DT_SRD: 0, },
      { SN: 622, AREA: 'HULL',    ZONE: 'HULL',    LEVEL: 'SE',  SVG: 'SE',  ID: 25, BLK: 'EC2H1', ALIGN: 'end',   TAG: '101500-PBE-94030',       DESC: 'NA',                                                           PO_NO: 'QU557BWQ31A', PO_DATE: '2024-03-07', VENDOR: 'Goulds Pumps',                     COUNTRY: 'Korea',       INCOTERMS: 'DAP', FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: '2025-02-01', SRD: '2025-08-12', INSTALL: '', SHIP_MODE: 'NA',       SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: 'NA', G_EREC: 'EC2G2', ER_PS: undefined,    ER_PF: undefined,    ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 192, DT_ETA: 0,     DT_SRD: 0, },
      { SN: 623, AREA: 'HULL',    ZONE: 'HULL',    LEVEL: 'SE',  SVG: 'SE',  ID: 26, BLK: 'ECP40', ALIGN: 'end',   TAG: '101560-ZZZ-94800',       DESC: 'SOUTH DISPOSAL CAISSON',                                       PO_NO: 'QA557BDQ88',  PO_DATE: '2024-01-15', VENDOR: 'Energy Specialties International', COUNTRY: 'USA',         INCOTERMS: 'FOB', FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: '2025-10-30', SRD: '2025-11-12', INSTALL: '', SHIP_MODE: 'by Sea',   SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: 'NA', G_EREC: 'ECP40', ER_PS: '2025-11-19', ER_PF: '2025-12-09', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 13,  DT_ETA: 20,   DT_SRD: 7, },
      { SN: 624, AREA: 'HULL',    ZONE: 'HULL',    LEVEL: 'SE',  SVG: 'SE',  ID: 27, BLK: 'ECP40', ALIGN: 'end',   TAG: '101560-LT-94853I',       DESC: 'NA',                                                           PO_NO: '',            PO_DATE: undefined,    VENDOR: '',                                 COUNTRY: '',            INCOTERMS: '',    FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: undefined,    SRD: undefined,    INSTALL: '', SHIP_MODE: '',         SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: '',   G_EREC: 'ECP40', ER_PS: '2025-11-19', ER_PF: '2025-12-09', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 0,    DT_ETA: 0,     DT_SRD: 0, },
      { SN: 625, AREA: 'HULL',    ZONE: 'HULL',    LEVEL: 'SE',  SVG: 'SE',  ID: 28, BLK: 'EC2H1', ALIGN: 'end',   TAG: '101790-SJB-67940',       DESC: 'NA',                                                           PO_NO: '',            PO_DATE: undefined,    VENDOR: '',                                 COUNTRY: '',            INCOTERMS: '',    FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: undefined,    SRD: undefined,    INSTALL: '', SHIP_MODE: '',         SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: '',   G_EREC: 'EC2G2', ER_PS: undefined,    ER_PF: undefined,    ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 0,    DT_ETA: 0,     DT_SRD: 0, },
      { SN: 626, AREA: 'HULL',    ZONE: 'HULL',    LEVEL: 'SE',  SVG: 'SE',  ID: 29, BLK: 'EC2H1', ALIGN: 'end',   TAG: '101790-SJB-67941',       DESC: 'NA',                                                           PO_NO: '',            PO_DATE: undefined,    VENDOR: '',                                 COUNTRY: '',            INCOTERMS: '',    FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: undefined,    SRD: undefined,    INSTALL: '', SHIP_MODE: '',         SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: '',   G_EREC: 'EC2G2', ER_PS: undefined,    ER_PF: undefined,    ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 0,    DT_ETA: 0,     DT_SRD: 0, },
      { SN: 627, AREA: 'HULL',    ZONE: 'HULL',    LEVEL: 'SE',  SVG: 'SE',  ID: 30, BLK: 'EC2H1', ALIGN: 'end',   TAG: '101700-FJB-67940',       DESC: 'NA',                                                           PO_NO: '',            PO_DATE: undefined,    VENDOR: '',                                 COUNTRY: '',            INCOTERMS: '',    FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: undefined,    SRD: undefined,    INSTALL: '', SHIP_MODE: '',         SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: '',   G_EREC: 'EC2G2', ER_PS: undefined,    ER_PF: undefined,    ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 0,    DT_ETA: 0,     DT_SRD: 0, },
      { SN: 628, AREA: 'HULL',    ZONE: 'HULL',    LEVEL: 'SE',  SVG: 'SE',  ID: 31, BLK: 'EC2H1', ALIGN: 'end',   TAG: '101850-EJB-96440A',      DESC: 'NA',                                                           PO_NO: '',            PO_DATE: undefined,    VENDOR: '',                                 COUNTRY: '',            INCOTERMS: '',    FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: undefined,    SRD: undefined,    INSTALL: '', SHIP_MODE: '',         SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: '',   G_EREC: 'EC2G2', ER_PS: undefined,    ER_PF: undefined,    ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 0,    DT_ETA: 0,     DT_SRD: 0, },
      { SN: 629, AREA: 'HULL',    ZONE: 'HULL',    LEVEL: 'SE',  SVG: 'SE',  ID: 32, BLK: 'EC2H1', ALIGN: 'end',   TAG: '101850-EJB-96440B',      DESC: 'NA',                                                           PO_NO: '',            PO_DATE: undefined,    VENDOR: '',                                 COUNTRY: '',            INCOTERMS: '',    FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: undefined,    SRD: undefined,    INSTALL: '', SHIP_MODE: '',         SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: '',   G_EREC: 'EC2G2', ER_PS: undefined,    ER_PF: undefined,    ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 0,    DT_ETA: 0,     DT_SRD: 0, },
      { SN: 630, AREA: 'HULL',    ZONE: 'HULL',    LEVEL: 'SE',  SVG: 'SE',  ID: 33, BLK: 'ECP60', ALIGN: 'end',   TAG: '101860-ENAV-62850',      DESC: 'NA',                                                           PO_NO: '',            PO_DATE: undefined,    VENDOR: '',                                 COUNTRY: '',            INCOTERMS: '',    FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: undefined,    SRD: undefined,    INSTALL: '', SHIP_MODE: '',         SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: '',   G_EREC: 'ECP60', ER_PS: '2025-11-19', ER_PF: '2025-12-09', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 0,    DT_ETA: 0,     DT_SRD: 0, },
      { SN: 631, AREA: 'HULL',    ZONE: 'HULL',    LEVEL: 'SE',  SVG: 'SE',  ID: 34, BLK: 'ECP60', ALIGN: 'end',   TAG: '101290-PBE-94610',       DESC: 'WATER INJECTION SEAWATER LIFT PUMP 1',                         PO_NO: 'QE557BWSWL',  PO_DATE: '2024-03-11', VENDOR: 'Framo AS',                         COUNTRY: 'Norway',      INCOTERMS: 'FOB', FAT_PLAN: '2025-04-28', FAT_FC: undefined,    FAT_ACT: undefined,    ETA: '2025-10-05', SRD: '2025-11-05', INSTALL: '', SHIP_MODE: 'NA',       SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: 'NA', G_EREC: 'ECP60', ER_PS: '2025-11-19', ER_PF: '2025-12-09', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 31,  DT_ETA: 45,   DT_SRD: 14, },
      { SN: 632, AREA: 'HULL',    ZONE: 'HULL',    LEVEL: 'SE',  SVG: 'SE',  ID: 35, BLK: 'EC2H1', ALIGN: 'end',   TAG: '101780-PJB-67940',       DESC: 'NA',                                                           PO_NO: '',            PO_DATE: undefined,    VENDOR: '',                                 COUNTRY: '',            INCOTERMS: '',    FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: undefined,    SRD: undefined,    INSTALL: '', SHIP_MODE: '',         SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: '',   G_EREC: 'EC2G2', ER_PS: undefined,    ER_PF: undefined,    ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 0,    DT_ETA: 0,     DT_SRD: 0, },
      { SN: 633, AREA: 'HULL',    ZONE: 'HULL',    LEVEL: 'SE',  SVG: 'SE',  ID: 36, BLK: 'ECP60', ALIGN: 'end',   TAG: '101290-PBE-94620',       DESC: 'WATER INJECTION SEAWATER LIFT PUMP 2',                         PO_NO: 'QE557BWSWL',  PO_DATE: '2024-03-11', VENDOR: 'Framo AS',                         COUNTRY: 'Norway',      INCOTERMS: 'FOB', FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: '2025-10-05', SRD: '2025-11-05', INSTALL: '', SHIP_MODE: 'NA',       SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: 'NA', G_EREC: 'ECP60', ER_PS: '2025-11-19', ER_PF: '2025-12-09', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 31,  DT_ETA: 45,   DT_SRD: 14, },
      { SN: 634, AREA: 'HULL',    ZONE: 'HULL',    LEVEL: 'SE',  SVG: 'SE',  ID: 37, BLK: 'ECP60', ALIGN: 'end',   TAG: '101710-PBE-94950',       DESC: 'FIREWATER PUMP 1',                                             PO_NO: 'QE557BKQ21',  PO_DATE: '2024-03-11', VENDOR: 'Framo AS',                         COUNTRY: 'Norway',      INCOTERMS: 'FOB', FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: '2025-10-05', SRD: '2025-11-05', INSTALL: '', SHIP_MODE: 'by Sea',   SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: 'NA', G_EREC: 'ECP60', ER_PS: '2025-11-19', ER_PF: '2025-12-09', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 31,  DT_ETA: 45,   DT_SRD: 14, },
      { SN: 635, AREA: 'HULL',    ZONE: 'HULL',    LEVEL: 'SE',  SVG: 'SE',  ID: 38, BLK: 'ECP60', ALIGN: 'end',   TAG: '101500-PBE-94560',       DESC: 'SEAWATER SERVICE PUMP 1',                                      PO_NO: 'QE557BWQ24',  PO_DATE: '2024-02-29', VENDOR: 'Framo AS',                         COUNTRY: 'Norway',      INCOTERMS: 'FOB', FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: '2025-11-01', SRD: '2025-11-14', INSTALL: '', SHIP_MODE: 'NA',       SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: 'NA', G_EREC: 'ECP60', ER_PS: '2025-11-19', ER_PF: '2025-12-09', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 13,  DT_ETA: 18,   DT_SRD: 5, },
      { SN: 636, AREA: 'HULL',    ZONE: 'HULL',    LEVEL: 'SE',  SVG: 'SE',  ID: 39, BLK: 'ECP60', ALIGN: 'end',   TAG: '101500-PBE-94590',       DESC: 'SEAWATER SERVICE PUMP 2',                                      PO_NO: 'QE557BWQ24',  PO_DATE: '2024-02-29', VENDOR: 'Framo AS',                         COUNTRY: 'Norway',      INCOTERMS: 'FOB', FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: '2025-11-01', SRD: '2025-11-14', INSTALL: '', SHIP_MODE: 'NA',       SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: 'NA', G_EREC: 'ECP60', ER_PS: '2025-11-19', ER_PF: '2025-12-09', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 13,  DT_ETA: 18,   DT_SRD: 5, },
      { SN: 637, AREA: 'HULL',    ZONE: 'HULL',    LEVEL: 'SW',  SVG: 'SW',  ID: 1,  BLK: 'WC2H1', ALIGN: 'start', TAG: '101780-PJB-67931',       DESC: 'NA',                                                           PO_NO: '',            PO_DATE: undefined,    VENDOR: '',                                 COUNTRY: '',            INCOTERMS: '',    FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: undefined,    SRD: undefined,    INSTALL: '', SHIP_MODE: '',         SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: '',   G_EREC: 'WC2G2', ER_PS: undefined,    ER_PF: undefined,    ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 0,    DT_ETA: 0,     DT_SRD: 0, },
      { SN: 638, AREA: 'HULL',    ZONE: 'HULL',    LEVEL: 'SW',  SVG: 'SW',  ID: 2,  BLK: 'WC2H1', ALIGN: 'start', TAG: '101780-PJB-67932',       DESC: 'NA',                                                           PO_NO: '',            PO_DATE: undefined,    VENDOR: '',                                 COUNTRY: '',            INCOTERMS: '',    FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: undefined,    SRD: undefined,    INSTALL: '', SHIP_MODE: '',         SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: '',   G_EREC: 'WC2G2', ER_PS: undefined,    ER_PF: undefined,    ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 0,    DT_ETA: 0,     DT_SRD: 0, },
      { SN: 639, AREA: 'HULL',    ZONE: 'HULL',    LEVEL: 'SW',  SVG: 'SW',  ID: 3,  BLK: 'WC2H1', ALIGN: 'start', TAG: '101780-PJB-67930',       DESC: 'NA',                                                           PO_NO: '',            PO_DATE: undefined,    VENDOR: '',                                 COUNTRY: '',            INCOTERMS: '',    FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: undefined,    SRD: undefined,    INSTALL: '', SHIP_MODE: '',         SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: '',   G_EREC: 'WC2G2', ER_PS: undefined,    ER_PF: undefined,    ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 0,    DT_ETA: 0,     DT_SRD: 0, },
      { SN: 640, AREA: 'HULL',    ZONE: 'HULL',    LEVEL: 'SW',  SVG: 'SW',  ID: 4,  BLK: 'WC2H1', ALIGN: 'start', TAG: '101720-TQZ-97301',       DESC: 'NA',                                                           PO_NO: '',            PO_DATE: undefined,    VENDOR: '',                                 COUNTRY: '',            INCOTERMS: '',    FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: undefined,    SRD: undefined,    INSTALL: '', SHIP_MODE: '',         SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: '',   G_EREC: 'WC2G2', ER_PS: undefined,    ER_PF: undefined,    ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 0,    DT_ETA: 0,     DT_SRD: 0, },
      { SN: 641, AREA: 'HULL',    ZONE: 'HULL',    LEVEL: 'SW',  SVG: 'SW',  ID: 5,  BLK: 'WC2H1', ALIGN: 'start', TAG: '101530-SSW-97000C',      DESC: 'NA',                                                           PO_NO: '',            PO_DATE: undefined,    VENDOR: '',                                 COUNTRY: '',            INCOTERMS: '',    FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: undefined,    SRD: undefined,    INSTALL: '', SHIP_MODE: '',         SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: '',   G_EREC: 'WC2G2', ER_PS: undefined,    ER_PF: undefined,    ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 0,    DT_ETA: 0,     DT_SRD: 0, },
      { SN: 642, AREA: 'HULL',    ZONE: 'HULL',    LEVEL: 'SW',  SVG: 'SW',  ID: 6,  BLK: 'WC2G1', ALIGN: 'start', TAG: '101970-THAH-93500',      DESC: 'AIR HANDLING UNIT',                                            PO_NO: 'QU557BKHH1',  PO_DATE: '2024-07-19', VENDOR: 'HI AIR',                           COUNTRY: 'Korea',       INCOTERMS: 'DAP', FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: '2025-08-15', SRD: '2025-10-14', INSTALL: '', SHIP_MODE: 'NA',       SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: 'NA', G_EREC: 'WC2G2', ER_PS: undefined,    ER_PF: undefined,    ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 60,  DT_ETA: 0,     DT_SRD: 0, },
      { SN: 643, AREA: 'HULL',    ZONE: 'HULL',    LEVEL: 'SW',  SVG: 'SW',  ID: 7,  BLK: 'WCP90', ALIGN: 'start', TAG: '101710-PBE-93950',       DESC: 'NA',                                                           PO_NO: 'QE557BKQ21',  PO_DATE: '2024-03-11', VENDOR: 'Framo AS',                         COUNTRY: 'Norway',      INCOTERMS: 'FOB', FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: '2025-10-05', SRD: '2025-11-17', INSTALL: '', SHIP_MODE: 'by Sea',   SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: 'NA', G_EREC: 'WCP90', ER_PS: '2025-12-01', ER_PF: '2025-12-19', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 43,  DT_ETA: 57,   DT_SRD: 14, },
      { SN: 644, AREA: 'HULL',    ZONE: 'HULL',    LEVEL: 'SW',  SVG: 'SW',  ID: 8,  BLK: 'WC2H1', ALIGN: 'start', TAG: '101940-MJB-97301',       DESC: 'NA',                                                           PO_NO: '',            PO_DATE: undefined,    VENDOR: '',                                 COUNTRY: '',            INCOTERMS: '',    FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: undefined,    SRD: undefined,    INSTALL: '', SHIP_MODE: '',         SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: '',   G_EREC: 'WC2G2', ER_PS: undefined,    ER_PF: undefined,    ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 0,    DT_ETA: 0,     DT_SRD: 0, },
      { SN: 645, AREA: 'HULL',    ZONE: 'HULL',    LEVEL: 'SW',  SVG: 'SW',  ID: 9,  BLK: 'WCP80', ALIGN: 'start', TAG: '101500-PBE-93660',       DESC: 'COOLING MEDIUM SEAWATER LIFT PUMP 2',                          PO_NO: 'QE557BWSWL',  PO_DATE: '2024-03-11', VENDOR: 'Framo AS',                         COUNTRY: 'Norway',      INCOTERMS: 'FOB', FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: '2025-10-05', SRD: '2025-11-17', INSTALL: '', SHIP_MODE: 'NA',       SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: 'NA', G_EREC: 'WCP80', ER_PS: '2025-12-01', ER_PF: '2025-12-19', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 43,  DT_ETA: 57,   DT_SRD: 14, },
      { SN: 646, AREA: 'HULL',    ZONE: 'HULL',    LEVEL: 'SW',  SVG: 'SW',  ID: 10, BLK: 'WCP80', ALIGN: 'start', TAG: '101860-ENAV-62860',      DESC: 'NA',                                                           PO_NO: '',            PO_DATE: undefined,    VENDOR: '',                                 COUNTRY: '',            INCOTERMS: '',    FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: undefined,    SRD: undefined,    INSTALL: '', SHIP_MODE: '',         SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: '',   G_EREC: 'WCP80', ER_PS: '2025-12-01', ER_PF: '2025-12-19', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 0,    DT_ETA: 0,     DT_SRD: 0, },
      { SN: 647, AREA: 'HULL',    ZONE: 'HULL',    LEVEL: 'SW',  SVG: 'SW',  ID: 11, BLK: 'WCP80', ALIGN: 'start', TAG: '101500-PBE-93650',       DESC: 'COOLING MEDIUM SEAWATER LIFT PUMP 1',                          PO_NO: 'QE557BWSWL',  PO_DATE: '2024-03-11', VENDOR: 'Framo AS',                         COUNTRY: 'Norway',      INCOTERMS: 'FOB', FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: '2025-10-05', SRD: '2025-11-17', INSTALL: '', SHIP_MODE: 'NA',       SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: 'NA', G_EREC: 'WCP80', ER_PS: '2025-12-01', ER_PF: '2025-12-19', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 43,  DT_ETA: 57,   DT_SRD: 14, },
      { SN: 648, AREA: 'HULL',    ZONE: 'HULL',    LEVEL: 'SW',  SVG: 'SW',  ID: 12, BLK: 'WCP80', ALIGN: 'start', TAG: '101500-PBE-93990',       DESC: 'COOLING MEDIUM SEAWATER LIFT PUMP 3',                          PO_NO: '',            PO_DATE: undefined,    VENDOR: '',                                 COUNTRY: '',            INCOTERMS: '',    FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: undefined,    SRD: undefined,    INSTALL: '', SHIP_MODE: '',         SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: '',   G_EREC: 'WCP80', ER_PS: '2025-12-01', ER_PF: '2025-12-19', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 0,    DT_ETA: 0,     DT_SRD: 0, },
      { SN: 649, AREA: 'HULL',    ZONE: 'HULL',    LEVEL: 'SW',  SVG: 'SW',  ID: 13, BLK: 'WC2G1', ALIGN: 'start', TAG: '101650-ZZZ-93470',       DESC: 'HYDRAULIC POWER UNIT 3',                                       PO_NO: 'QU557ALH01',  PO_DATE: '2024-03-29', VENDOR: 'Emerson Korea',                    COUNTRY: 'Korea',       INCOTERMS: 'DAP', FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: '2025-03-28', SRD: '2025-05-30', INSTALL: '', SHIP_MODE: 'by Truck', SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: 'NA', G_EREC: 'WC2G2', ER_PS: undefined,    ER_PF: undefined,    ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 63,  DT_ETA: 0,     DT_SRD: 0, },
      { SN: 650, AREA: 'HULL',    ZONE: 'HULL',    LEVEL: 'SW',  SVG: 'SW',  ID: 14, BLK: 'WC2G1', ALIGN: 'start', TAG: '101650-ZZZ-93370',       DESC: 'SOLENOID VALVE CABINET 3',                                     PO_NO: 'QU557ALH01',  PO_DATE: '2024-03-29', VENDOR: 'Emerson Korea',                    COUNTRY: 'Korea',       INCOTERMS: 'DAP', FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: '2025-03-28', SRD: '2025-05-30', INSTALL: '', SHIP_MODE: 'NA',       SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: 'NA', G_EREC: 'WC2G2', ER_PS: undefined,    ER_PF: undefined,    ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 63,  DT_ETA: 0,     DT_SRD: 0, },
      { SN: 651, AREA: 'HULL',    ZONE: 'HULL',    LEVEL: 'SW',  SVG: 'SW',  ID: 15, BLK: 'WC2H1', ALIGN: 'start', TAG: '101180-ZZZ-93250',       DESC: 'NA',                                                           PO_NO: '',            PO_DATE: undefined,    VENDOR: '',                                 COUNTRY: '',            INCOTERMS: '',    FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: undefined,    SRD: undefined,    INSTALL: '', SHIP_MODE: '',         SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: '',   G_EREC: 'WC2G2', ER_PS: undefined,    ER_PF: undefined,    ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 0,    DT_ETA: 0,     DT_SRD: 0, },
      { SN: 652, AREA: 'HULL',    ZONE: 'HULL',    LEVEL: 'SW',  SVG: 'SW',  ID: 16, BLK: 'WN211', ALIGN: 'end',   TAG: '101440-PBE-93440',       DESC: 'DAILY BILGE PUMP 3',                                           PO_NO: 'QU557BWQ32',  PO_DATE: '2024-01-26', VENDOR: 'Jec_Verder',                       COUNTRY: 'Korea',       INCOTERMS: 'DAP', FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: '2025-01-26', SRD: '2025-07-04', INSTALL: '', SHIP_MODE: 'NA',       SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: 'NA', G_EREC: 'WN212', ER_PS: undefined,    ER_PF: undefined,    ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 159, DT_ETA: 0,     DT_SRD: 0, },
      { SN: 653, AREA: 'HULL',    ZONE: 'HULL',    LEVEL: 'SW',  SVG: 'SW',  ID: 17, BLK: 'WN211', ALIGN: 'end',   TAG: '101520-PBE-93410',       DESC: 'BALLAST / MAIN BILGE PUMP 5',                                  PO_NO: 'QE557BWQ31',  PO_DATE: '2024-01-31', VENDOR: 'Eureka Pumps',                     COUNTRY: 'Norway',      INCOTERMS: 'DAP', FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: '2025-04-30', SRD: '2025-07-04', INSTALL: '', SHIP_MODE: 'NA',       SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: 'NA', G_EREC: 'WN212', ER_PS: undefined,    ER_PF: undefined,    ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 65,  DT_ETA: 0,     DT_SRD: 0, },
      { SN: 654, AREA: 'HULL',    ZONE: 'HULL',    LEVEL: 'SW',  SVG: 'SW',  ID: 18, BLK: 'WN211', ALIGN: 'end',   TAG: '101530-PBE-93710',       DESC: 'FRESH WATER PUMP 1',                                           PO_NO: 'QU557BWQ31A', PO_DATE: '2024-03-07', VENDOR: 'Goulds Pumps',                     COUNTRY: 'Korea',       INCOTERMS: 'DAP', FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: '2025-02-01', SRD: '2025-07-04', INSTALL: '', SHIP_MODE: 'NA',       SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: 'NA', G_EREC: 'WN212', ER_PS: undefined,    ER_PF: undefined,    ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 153, DT_ETA: 0,     DT_SRD: 0, },
      { SN: 655, AREA: 'HULL',    ZONE: 'HULL',    LEVEL: 'SW',  SVG: 'SW',  ID: 19, BLK: 'WN211', ALIGN: 'end',   TAG: '101530-PBE-93720',       DESC: 'FRESH WATER PUMP 2',                                           PO_NO: 'QU557BWQ31A', PO_DATE: '2024-03-07', VENDOR: 'Goulds Pumps',                     COUNTRY: 'Korea',       INCOTERMS: 'DAP', FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: '2025-02-01', SRD: '2025-07-04', INSTALL: '', SHIP_MODE: 'NA',       SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: 'NA', G_EREC: 'WN212', ER_PS: undefined,    ER_PF: undefined,    ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 153, DT_ETA: 0,     DT_SRD: 0, },
      { SN: 656, AREA: 'HULL',    ZONE: 'HULL',    LEVEL: 'SW',  SVG: 'SW',  ID: 20, BLK: 'WN211', ALIGN: 'end',   TAG: '101520-PBE-93420',       DESC: 'BALLAST / MAIN BILGE PUMP 6',                                  PO_NO: 'QE557BWQ31',  PO_DATE: '2024-01-31', VENDOR: 'Eureka Pumps',                     COUNTRY: 'Norway',      INCOTERMS: 'DAP', FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: '2025-04-30', SRD: '2025-07-04', INSTALL: '', SHIP_MODE: 'NA',       SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: 'NA', G_EREC: 'WN212', ER_PS: undefined,    ER_PF: undefined,    ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 65,  DT_ETA: 0,     DT_SRD: 0, },
      { SN: 657, AREA: 'HULL',    ZONE: 'HULL',    LEVEL: 'SW',  SVG: 'SW',  ID: 21, BLK: 'WC2H1', ALIGN: 'end',   TAG: '101970-HAL-93530A',      DESC: 'NA',                                                           PO_NO: '',            PO_DATE: undefined,    VENDOR: '',                                 COUNTRY: '',            INCOTERMS: '',    FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: undefined,    SRD: undefined,    INSTALL: '', SHIP_MODE: '',         SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: '',   G_EREC: 'WC2G2', ER_PS: undefined,    ER_PF: undefined,    ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 0,    DT_ETA: 0,     DT_SRD: 0, },
      { SN: 658, AREA: 'HULL',    ZONE: 'HULL',    LEVEL: 'SW',  SVG: 'SW',  ID: 22, BLK: 'WC2H1', ALIGN: 'end',   TAG: '101850-EJB-96340B',      DESC: 'NA',                                                           PO_NO: '',            PO_DATE: undefined,    VENDOR: '',                                 COUNTRY: '',            INCOTERMS: '',    FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: undefined,    SRD: undefined,    INSTALL: '', SHIP_MODE: '',         SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: '',   G_EREC: 'WC2G2', ER_PS: undefined,    ER_PF: undefined,    ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 0,    DT_ETA: 0,     DT_SRD: 0, },
      { SN: 659, AREA: 'HULL',    ZONE: 'HULL',    LEVEL: 'SW',  SVG: 'SW',  ID: 23, BLK: 'WC2H1', ALIGN: 'end',   TAG: '101840-EJB-96320',       DESC: 'NA',                                                           PO_NO: '',            PO_DATE: undefined,    VENDOR: '',                                 COUNTRY: '',            INCOTERMS: '',    FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: undefined,    SRD: undefined,    INSTALL: '', SHIP_MODE: '',         SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: '',   G_EREC: 'WC2G2', ER_PS: undefined,    ER_PF: undefined,    ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 0,    DT_ETA: 0,     DT_SRD: 0, },
      { SN: 660, AREA: 'HULL',    ZONE: 'HULL',    LEVEL: 'SW',  SVG: 'SW',  ID: 24, BLK: 'WC2H1', ALIGN: 'end',   TAG: '101970-HAL-93730',       DESC: 'AIR COOLED CONDENSING UNIT',                                   PO_NO: '',            PO_DATE: undefined,    VENDOR: '',                                 COUNTRY: '',            INCOTERMS: '',    FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: undefined,    SRD: undefined,    INSTALL: '', SHIP_MODE: '',         SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: '',   G_EREC: 'WC2G2', ER_PS: undefined,    ER_PF: undefined,    ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 0,    DT_ETA: 0,     DT_SRD: 0, },
      { SN: 661, AREA: 'HULL',    ZONE: 'HULL',    LEVEL: 'SW',  SVG: 'SW',  ID: 25, BLK: 'WC2H1', ALIGN: 'end',   TAG: '101850-EJB-96340A',      DESC: 'NA',                                                           PO_NO: '',            PO_DATE: undefined,    VENDOR: '',                                 COUNTRY: '',            INCOTERMS: '',    FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: undefined,    SRD: undefined,    INSTALL: '', SHIP_MODE: '',         SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: '',   G_EREC: 'WC2G2', ER_PS: undefined,    ER_PF: undefined,    ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 0,    DT_ETA: 0,     DT_SRD: 0, },
      { SN: 662, AREA: 'HULL',    ZONE: 'HULL',    LEVEL: 'SW',  SVG: 'SW',  ID: 26, BLK: 'WC2H1', ALIGN: 'end',   TAG: '101820-EJB-96310',       DESC: 'NA',                                                           PO_NO: '',            PO_DATE: undefined,    VENDOR: '',                                 COUNTRY: '',            INCOTERMS: '',    FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: undefined,    SRD: undefined,    INSTALL: '', SHIP_MODE: '',         SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: '',   G_EREC: 'WC2G2', ER_PS: undefined,    ER_PF: undefined,    ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 0,    DT_ETA: 0,     DT_SRD: 0, },
      { SN: 663, AREA: 'HULL',    ZONE: 'HULL',    LEVEL: 'SW',  SVG: 'SW',  ID: 27, BLK: 'WC2H1', ALIGN: 'end',   TAG: '101720-TQZ-97302',       DESC: 'NA',                                                           PO_NO: '',            PO_DATE: undefined,    VENDOR: '',                                 COUNTRY: '',            INCOTERMS: '',    FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: undefined,    SRD: undefined,    INSTALL: '', SHIP_MODE: '',         SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: '',   G_EREC: 'WC2G2', ER_PS: undefined,    ER_PF: undefined,    ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 0,    DT_ETA: 0,     DT_SRD: 0, },
      { SN: 664, AREA: 'HULL',    ZONE: 'HULL',    LEVEL: 'SW',  SVG: 'SW',  ID: 28, BLK: 'WC2G1', ALIGN: 'end',   TAG: '101530-TZAR-93700',      DESC: 'WATERMAKER SKID',                                              PO_NO: 'QA557BWQ84',  PO_DATE: '2024-02-29', VENDOR: 'ITT Ctreat',                       COUNTRY: 'USA',         INCOTERMS: 'FOB', FAT_PLAN: '2025-04-08', FAT_FC: undefined,    FAT_ACT: undefined,    ETA: '2025-07-13', SRD: '2025-08-22', INSTALL: '', SHIP_MODE: 'NA',       SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: 'NA', G_EREC: 'WC2G2', ER_PS: undefined,    ER_PF: undefined,    ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 40,  DT_ETA: 0,     DT_SRD: 0, },
      { SN: 665, AREA: 'HULL',    ZONE: 'HULL',    LEVEL: 'SW',  SVG: 'SW',  ID: 29, BLK: 'WC2H1', ALIGN: 'end',   TAG: '101530-ZZZ-93750',       DESC: 'CHLORINATION UNIT',                                            PO_NO: 'QA557BWQ84',  PO_DATE: '2024-02-29', VENDOR: 'ITT Ctreat',                       COUNTRY: 'USA',         INCOTERMS: 'FOB', FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: '2025-07-13', SRD: '2025-08-22', INSTALL: '', SHIP_MODE: 'NA',       SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: 'NA', G_EREC: 'WC2G2', ER_PS: undefined,    ER_PF: undefined,    ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 40,  DT_ETA: 0,     DT_SRD: 0, },
      { SN: 666, AREA: 'HULL',    ZONE: 'HULL',    LEVEL: 'SW',  SVG: 'SW',  ID: 30, BLK: 'WC2H1', ALIGN: 'end',   TAG: '101730-LDY-93540',       DESC: 'DAVIT CRANE 3',                                                PO_NO: 'NA',          PO_DATE: undefined,    VENDOR: 'NA',                               COUNTRY: 'NA',          INCOTERMS: 'NA',  FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: undefined,    SRD: '2025-10-30', INSTALL: '', SHIP_MODE: 'NA',       SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: 'NA', G_EREC: 'WC2G2', ER_PS: undefined,    ER_PF: undefined,    ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 0,    DT_ETA: 0,     DT_SRD: 0, },
      { SN: 667, AREA: 'HULL',    ZONE: 'HULL',    LEVEL: 'SW',  SVG: 'SW',  ID: 31, BLK: 'WC2G1', ALIGN: 'end',   TAG: '101970-THAH-93700',      DESC: 'AIR HANDLING UNIT',                                            PO_NO: '',            PO_DATE: undefined,    VENDOR: '',                                 COUNTRY: '',            INCOTERMS: '',    FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: undefined,    SRD: undefined,    INSTALL: '', SHIP_MODE: '',         SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: '',   G_EREC: 'WC2G2', ER_PS: undefined,    ER_PF: undefined,    ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 0,    DT_ETA: 0,     DT_SRD: 0, },
      { SN: 668, AREA: 'HULL',    ZONE: 'HULL',    LEVEL: 'SW',  SVG: 'SW',  ID: 32, BLK: 'WC2H1', ALIGN: 'end',   TAG: '101970-HAL-93530B',      DESC: 'NA',                                                           PO_NO: '',            PO_DATE: undefined,    VENDOR: '',                                 COUNTRY: '',            INCOTERMS: '',    FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: undefined,    SRD: undefined,    INSTALL: '', SHIP_MODE: '',         SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: '',   G_EREC: 'WC2G2', ER_PS: undefined,    ER_PF: undefined,    ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 0,    DT_ETA: 0,     DT_SRD: 0, },
      { SN: 669, AREA: 'HULL',    ZONE: 'HULL',    LEVEL: 'UCF', SVG: 'UCF', ID: 1,  BLK: 'NF212', ALIGN: 'start', TAG: '101720-TQZ-97209',       DESC: 'NA',                                                           PO_NO: '',            PO_DATE: undefined,    VENDOR: '',                                 COUNTRY: '',            INCOTERMS: '',    FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: undefined,    SRD: undefined,    INSTALL: '', SHIP_MODE: '',         SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: '',   G_EREC: 'NF212', ER_PS: '2025-12-03', ER_PF: '2025-12-31', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 0,    DT_ETA: 0,     DT_SRD: 0, },
      { SN: 670, AREA: 'HULL',    ZONE: 'HULL',    LEVEL: 'UCF', SVG: 'UCF', ID: 2,  BLK: 'NF212', ALIGN: 'start', TAG: '101570-ZZZ-40800',       DESC: 'NA',                                                           PO_NO: 'QU557BDQ57',  PO_DATE: '2024-04-12', VENDOR: 'Decatech',                         COUNTRY: 'Korea',       INCOTERMS: 'DAP', FAT_PLAN: '2025-10-31', FAT_FC: undefined,    FAT_ACT: undefined,    ETA: '2025-11-08', SRD: '2025-11-20', INSTALL: '', SHIP_MODE: 'by Truck', SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: 'NA', G_EREC: 'NF212', ER_PS: undefined,    ER_PF: undefined,    ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 12,  DT_ETA: 0,     DT_SRD: 0, },
      { SN: 671, AREA: 'HULL',    ZONE: 'HULL',    LEVEL: 'UCF', SVG: 'UCF', ID: 3,  BLK: 'NF212', ALIGN: 'start', TAG: '101720-TQZ-97208',       DESC: 'NA',                                                           PO_NO: '',            PO_DATE: undefined,    VENDOR: '',                                 COUNTRY: '',            INCOTERMS: '',    FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: undefined,    SRD: undefined,    INSTALL: '', SHIP_MODE: '',         SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: '',   G_EREC: 'NF212', ER_PS: '2025-12-03', ER_PF: '2025-12-31', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 0,    DT_ETA: 0,     DT_SRD: 0, },
      { SN: 672, AREA: 'TOPSIDE', ZONE: 'FLARE',   LEVEL: 'FL',  SVG: 'FL',  ID: 1,  BLK: 'NF110', ALIGN: 'start', TAG: '101430-TTF-14120',       DESC: 'NA',                                                           PO_NO: 'QA557AFQ89',  PO_DATE: '2024-04-29', VENDOR: 'GBA Corona',                       COUNTRY: 'USA',         INCOTERMS: 'FOB', FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: '2025-07-27', SRD: '2026-11-12', INSTALL: '', SHIP_MODE: 'NA',       SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: 'NA', G_EREC: 'NF110', ER_PS: '2025-12-03', ER_PF: '2025-12-31', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 473, DT_ETA: 129,  DT_SRD: -344, },
      { SN: 673, AREA: 'TOPSIDE', ZONE: 'FLARE',   LEVEL: 'FL',  SVG: 'FL',  ID: 2,  BLK: 'NF110', ALIGN: 'end',   TAG: '101435-TTF-14310',       DESC: 'NA',                                                           PO_NO: '',            PO_DATE: undefined,    VENDOR: '',                                 COUNTRY: '',            INCOTERMS: '',    FAT_PLAN: undefined,    FAT_FC: undefined,    FAT_ACT: undefined,    ETA: undefined,    SRD: undefined,    INSTALL: '', SHIP_MODE: '',         SHIP_ETD: undefined,    SHIP_ETA: undefined,    VESSEL: '',   G_EREC: 'NF110', ER_PS: '2025-12-03', ER_PF: '2025-12-31', ER_AS: undefined,    ER_AF: undefined,    ETA_SRD: 0,    DT_ETA: 0,     DT_SRD: 0, },
      ],

    SQL4: [
      {
        S1_T: `[MAT_CODE] != 'NA'`,
        S1_A: `[MAT_CODE] != 'NA' AND [A_DATE] IS NOT NULL`,
        S2_T: ``,
        S2_A: `[S_OUT] IS NOT NULL`,
        S3_T: ``,
        S3_A: `[INSTALL_DATE] IS NOT NULL`,
        E1_T: ``,
        E1_A: `[PO_DATE] IS NOT NULL`,
        E2_T: ``,
        E2_A: `[FAT_ACT] IS NOT NULL`,
        E3_T: ``,
        E3_A: `[ON_SITE] IS NOT NULL`,
        E4_T: ``,
        E4_A: `[INSTALL] IS NOT NULL`,
      }
    ],
  },
  
}