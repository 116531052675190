/* 
  ### Chart Default Data ### 
  Predefined component's behaviors for the Filter & Action
*/


// Dashboard
import TR_DP_MAN_OVERALL_Data         from '../10_Trion/Dashboard/Planning/Man_Overall/includes/primitives/data'
import TR_DP_MAN_DROPDOWN_Data        from '../10_Trion/Dashboard/Planning/Man_Dropdown/includes/primitives/data'
import TR_DP_MAN_DISC_Data            from '../10_Trion/Dashboard/Planning/Man_Discipline/includes/primitives/data'
import TR_DP_MAN_OVERALL_WEEK_Data    from '../10_Trion/Dashboard/Planning/Man_Overall_Week/includes/primitives/data'
import TR_DP_MAN_DROPDOWN_WEEK_Data   from '../10_Trion/Dashboard/Planning/Man_Dropdown_Week/includes/primitives/data'
import TR_DP_MAN_DISC_WEEK_Data       from '../10_Trion/Dashboard/Planning/Man_Discipline_Week/includes/primitives/data'
import TR_DP_MAN_OVERALL_MH_Data         from '../10_Trion/Dashboard/Planning/Man_Overall_MH/includes/primitives/data'
import TR_DP_MAN_DROPDOWN_MH_Data        from '../10_Trion/Dashboard/Planning/Man_Dropdown_MH/includes/primitives/data'
import TR_DP_MAN_DISC_MH_Data            from '../10_Trion/Dashboard/Planning/Man_Discipline_MH/includes/primitives/data'
import TR_DP_MAN_OVERALL_WEEK_MH_Data    from '../10_Trion/Dashboard/Planning/Man_Overall_Week_MH/includes/primitives/data'
import TR_DP_MAN_DROPDOWN_WEEK_MH_Data   from '../10_Trion/Dashboard/Planning/Man_Dropdown_Week_MH/includes/primitives/data'
import TR_DP_MAN_DISC_WEEK_MH_Data       from '../10_Trion/Dashboard/Planning/Man_Discipline_Week_MH/includes/primitives/data'

import TR_DC_DISCIPLINE_AREA_Data     from '../10_Trion/Dashboard/Construction/Discipline_Area/includes/primitives/data'
import TR_DC_STR_STAGE_Data           from '../10_Trion/Dashboard/Construction/STR_Stage/includes/primitives/data'
import TR_DC_STR_SCH_Data             from '../10_Trion/Dashboard/Construction/STR_Schedule/includes/primitives/data'
import TR_DC_PIP_AREA_Data            from '../10_Trion/Dashboard/Construction/PIP_Area/includes/primitives/data'
import TR_DC_PIP_TABLE_Data           from '../10_Trion/Dashboard/Construction/PIP_Table/includes/primitives/data'

import TR_DE_SHOP_OVERALL_Data        from '../10_Trion/Dashboard/Engineering/Shop_Overall/includes/primitives/data'
import TR_DE_SHOP_AREA_Data           from '../10_Trion/Dashboard/Engineering/Shop_Area/includes/primitives/data'

// 3D Visualization
import TR_Erection_Network_Data       from '../10_Trion/Visualization/Erection_Network/includes/primitives/data'
import TR_Yard_Timeline_Data          from '../10_Trion/Visualization/Yard_Timeline/includes/primitives/data'
import TR_Block_Division_Data         from '../10_Trion/Visualization/Block_Division/includes/primitives/data'
import TR_Layout_Equipment_Data       from '../10_Trion/Visualization/Layout_Equipment/includes/primitives/data'
import TR_Layout_Yard_Data            from '../10_Trion/Visualization/Layout_Yard/includes/primitives/data'

// Skyline Charts
import TR_SKYLINE_BLOCK_Data          from '../10_Trion/Skylines/Blocks/includes/primitives/data'
import TR_SKYLINE_RFQ_Data            from '../10_Trion/Skylines/RFQ/includes/primitives/data'
import TR_SKYLINE_RFQ_TAG_Data        from '../10_Trion/Skylines/RFQ_tag/includes/primitives/data'

// Custom Charts
import TR_SCURVE_ENG_Data             from '../10_Trion/CustomChart/SCurve_ENG/includes/primitives/data'
import TR_SCURVE_PRO_Data             from '../10_Trion/CustomChart/SCurve_PRO/includes/primitives/data'
import TR_SCURVE_CON_Data             from '../10_Trion/CustomChart/SCurve_CON/includes/primitives/data'

// Modal
import TR_MW_SCH_AREA_Data            from '../10_Trion/ModalWindow/Schedule/Area/includes/primitives/data'
import TR_MW_SCH_ZONE_Data            from '../10_Trion/ModalWindow/Schedule/Zone/includes/primitives/data'
import TR_MW_SCH_EREC_Data            from '../10_Trion/ModalWindow/Schedule/Erection/includes/primitives/data'
import TR_MW_SCH_PE1_Data             from '../10_Trion/ModalWindow/Schedule/PE1/includes/primitives/data'
import TR_MW_SCH_BLOCK_Data           from '../10_Trion/ModalWindow/Schedule/Block/includes/primitives/data'

import TR_MW_OUT_AREA_SPOOL_Data      from '../10_Trion/ModalWindow/Outfitting/Area_Spool/includes/primitives/data'
import TR_MW_OUT_AREA_EQ_Data         from '../10_Trion/ModalWindow/Outfitting/Area_EQ/includes/primitives/data'
import TR_MW_OUT_OVERALL_Data         from '../10_Trion/ModalWindow/Outfitting/Overall_Disc/includes/primitives/data'

export default {

  // Dashboard
  TR_DP_MAN_OVERALL_Data,
  TR_DP_MAN_DROPDOWN_Data,
  TR_DP_MAN_DISC_Data,
  TR_DP_MAN_OVERALL_WEEK_Data,
  TR_DP_MAN_DROPDOWN_WEEK_Data,
  TR_DP_MAN_DISC_WEEK_Data,
  TR_DP_MAN_OVERALL_MH_Data,
  TR_DP_MAN_DROPDOWN_MH_Data,
  TR_DP_MAN_DISC_MH_Data,
  TR_DP_MAN_OVERALL_WEEK_MH_Data,
  TR_DP_MAN_DROPDOWN_WEEK_MH_Data,
  TR_DP_MAN_DISC_WEEK_MH_Data,

  TR_DC_DISCIPLINE_AREA_Data,
  TR_DC_STR_STAGE_Data,
  TR_DC_STR_SCH_Data,
  TR_DC_PIP_AREA_Data,
  TR_DC_PIP_TABLE_Data,
  TR_DE_SHOP_OVERALL_Data,
  TR_DE_SHOP_AREA_Data,

  // 3D Visualization
  TR_Erection_Network_Data,
  TR_Yard_Timeline_Data,
  TR_Block_Division_Data,
  TR_Layout_Equipment_Data,
  TR_Layout_Yard_Data,

  // Skyline Charts
  TR_SKYLINE_BLOCK_Data,
  TR_SKYLINE_RFQ_Data,
  TR_SKYLINE_RFQ_TAG_Data,

  // Custom Charts
  TR_SCURVE_ENG_Data,
  TR_SCURVE_PRO_Data,
  TR_SCURVE_CON_Data,

  // Modal
  TR_MW_SCH_AREA_Data,
  TR_MW_SCH_ZONE_Data,
  TR_MW_SCH_EREC_Data,
  TR_MW_SCH_PE1_Data,
  TR_MW_SCH_BLOCK_Data,
  
  TR_MW_OUT_AREA_SPOOL_Data,
  TR_MW_OUT_AREA_EQ_Data,
  TR_MW_OUT_OVERALL_Data,


}
