import __C from '../../../../../../includes/primitives/_constant_'

export default {
    Canvas: {
      CanvasWidth: 1400,
      CanvasHeight: 460,
      CanvasBorderWeight: .5,
      CanvasBorderColor: '#E0E0DF',
      CanvasFillColor: '#fff',
      CanvasOpacity: 1,

      CanvasChartX: 0,
      CanvasChartY: 0,
      CanvasChartWidth: 400,
      CanvasChartHeight: 200,
      CanvasChartBorderWeight: 0,
      CanvasChartBorderColor: '#757575',
      CanvasChartFillColor: '#757575',
      CanvasChartOpacity: 0,
      CanvasChartSpace: 0,

      CanvasFontFamily: 'roboto',
    },
    
    Text: {
      Text: [],
      Filter: [],
      Sql: []
    },

    DataItems: [
      { CDATE: '2023-07-28', TOTAL: 153744.8, P_WK: 699.3,   A_WK: 619.2,  D_WK: -80,  P_CUM: 699.3,    A_CUM: 619.2,   D_CUM: -80, },
      { CDATE: '2023-08-25', TOTAL: 153744.8, P_WK: 935,     A_WK: 811.9,  D_WK: -123, P_CUM: 1634.3,   A_CUM: 1431.1,  D_CUM: -203, },
      { CDATE: '2023-09-29', TOTAL: 153744.8, P_WK: 1633.4,  A_WK: 1291.6, D_WK: -342, P_CUM: 3267.7,   A_CUM: 2722.7,  D_CUM: -545, },
      { CDATE: '2023-10-27', TOTAL: 153744.8, P_WK: 1430.6,  A_WK: 1183.6, D_WK: -247, P_CUM: 4698.3,   A_CUM: 3906.3,  D_CUM: -792, },
      { CDATE: '2023-11-24', TOTAL: 153744.8, P_WK: 1475.2,  A_WK: 1202.4, D_WK: -273, P_CUM: 6173.5,   A_CUM: 5108.7,  D_CUM: -1065, },
      { CDATE: '2023-12-29', TOTAL: 153744.8, P_WK: 1933.7,  A_WK: 1588.5, D_WK: -345, P_CUM: 8107.2,   A_CUM: 6697.2,  D_CUM: -1410, },
      { CDATE: '2024-01-26', TOTAL: 153744.8, P_WK: 1699.7,  A_WK: 1488.1, D_WK: -212, P_CUM: 9806.9,   A_CUM: 8185.3,  D_CUM: -1622, },
      { CDATE: '2024-02-23', TOTAL: 153744.8, P_WK: 1722.7,  A_WK: 1758.4, D_WK: 36,   P_CUM: 11529.6,  A_CUM: 9943.7,  D_CUM: -1586, },
      { CDATE: '2024-03-29', TOTAL: 153744.8, P_WK: 2146,    A_WK: 2237,   D_WK: 91,   P_CUM: 13675.6,  A_CUM: 12180.7, D_CUM: -1495, },
      { CDATE: '2024-04-26', TOTAL: 153744.8, P_WK: 1799.8,  A_WK: 1984.6, D_WK: 185,  P_CUM: 15475.4,  A_CUM: 14165.3, D_CUM: -1310, },
      { CDATE: '2024-05-31', TOTAL: 153744.8, P_WK: 2241.6,  A_WK: 2435.9, D_WK: 194,  P_CUM: 17717,    A_CUM: 16601.2, D_CUM: -1116, },
      { CDATE: '2024-06-28', TOTAL: 153744.8, P_WK: 1773.3,  A_WK: 2082.1, D_WK: 309,  P_CUM: 19490.3,  A_CUM: 18683.3, D_CUM: -807, },
      { CDATE: '2024-07-26', TOTAL: 153744.8, P_WK: 1872.3,  A_WK: 2083.1, D_WK: 211,  P_CUM: 21362.6,  A_CUM: 20766.4, D_CUM: -596, },
      { CDATE: '2024-08-30', TOTAL: 153744.8, P_WK: 2157.4,  A_WK: 2502.7, D_WK: 345,  P_CUM: 23520,    A_CUM: 23269.1, D_CUM: -251, },
      { CDATE: '2024-09-27', TOTAL: 153744.8, P_WK: 1778.9,  A_WK: 2167.7, D_WK: 389,  P_CUM: 25298.9,  A_CUM: 25436.8, D_CUM: 138, },
      { CDATE: '2024-10-25', TOTAL: 153744.8, P_WK: 1642.1,  A_WK: 2146.1, D_WK: 504,  P_CUM: 26941,    A_CUM: 27582.9, D_CUM: 642, },
      { CDATE: '2024-11-29', TOTAL: 153744.8, P_WK: 2667.7,  A_WK: 3323.9, D_WK: 656,  P_CUM: 29608.7,  A_CUM: 30906.8, D_CUM: 1298, },
      { CDATE: '2024-12-27', TOTAL: 153744.8, P_WK: 3362.4,  A_WK: 0,      D_WK: 0,    P_CUM: 32971.1,  A_CUM: 30906.8, D_CUM: 0, },
      { CDATE: '2025-01-31', TOTAL: 153744.8, P_WK: 6283.2,  A_WK: 0,      D_WK: 0,    P_CUM: 39254.3,  A_CUM: 30906.8, D_CUM: 0, },
      { CDATE: '2025-02-28', TOTAL: 153744.8, P_WK: 6429.9,  A_WK: 0,      D_WK: 0,    P_CUM: 45684.2,  A_CUM: 30906.8, D_CUM: 0, },
      { CDATE: '2025-03-28', TOTAL: 153744.8, P_WK: 6908.9,  A_WK: 0,      D_WK: 0,    P_CUM: 52593.1,  A_CUM: 30906.8, D_CUM: 0, },
      { CDATE: '2025-04-25', TOTAL: 153744.8, P_WK: 8295.8,  A_WK: 0,      D_WK: 0,    P_CUM: 60888.9,  A_CUM: 30906.8, D_CUM: 0, },
      { CDATE: '2025-05-30', TOTAL: 153744.8, P_WK: 11517.1, A_WK: 0,      D_WK: 0,    P_CUM: 72406,    A_CUM: 30906.8, D_CUM: 0, },
      { CDATE: '2025-06-27', TOTAL: 153744.8, P_WK: 8885.8,  A_WK: 0,      D_WK: 0,    P_CUM: 81291.8,  A_CUM: 30906.8, D_CUM: 0, },
      { CDATE: '2025-07-25', TOTAL: 153744.8, P_WK: 9779.7,  A_WK: 0,      D_WK: 0,    P_CUM: 91071.5,  A_CUM: 30906.8, D_CUM: 0, },
      { CDATE: '2025-08-29', TOTAL: 153744.8, P_WK: 8748.1,  A_WK: 0,      D_WK: 0,    P_CUM: 99819.6,  A_CUM: 30906.8, D_CUM: 0, },
      { CDATE: '2025-09-26', TOTAL: 153744.8, P_WK: 7344,    A_WK: 0,      D_WK: 0,    P_CUM: 107163.6, A_CUM: 30906.8, D_CUM: 0, },
      { CDATE: '2025-10-31', TOTAL: 153744.8, P_WK: 5414.7,  A_WK: 0,      D_WK: 0,    P_CUM: 112578.3, A_CUM: 30906.8, D_CUM: 0, },
      { CDATE: '2025-11-28', TOTAL: 153744.8, P_WK: 5709,    A_WK: 0,      D_WK: 0,    P_CUM: 118287.3, A_CUM: 30906.8, D_CUM: 0, },
      { CDATE: '2025-12-26', TOTAL: 153744.8, P_WK: 4205.9,  A_WK: 0,      D_WK: 0,    P_CUM: 122493.2, A_CUM: 30906.8, D_CUM: 0, },
      { CDATE: '2026-01-30', TOTAL: 153744.8, P_WK: 4879.5,  A_WK: 0,      D_WK: 0,    P_CUM: 127372.7, A_CUM: 30906.8, D_CUM: 0, },
      { CDATE: '2026-02-27', TOTAL: 153744.8, P_WK: 2496.1,  A_WK: 0,      D_WK: 0,    P_CUM: 129868.8, A_CUM: 30906.8, D_CUM: 0, },
      { CDATE: '2026-03-27', TOTAL: 153744.8, P_WK: 1821.5,  A_WK: 0,      D_WK: 0,    P_CUM: 131690.3, A_CUM: 30906.8, D_CUM: 0, },
      { CDATE: '2026-04-24', TOTAL: 153744.8, P_WK: 1527.1,  A_WK: 0,      D_WK: 0,    P_CUM: 133217.4, A_CUM: 30906.8, D_CUM: 0, },
      { CDATE: '2026-05-29', TOTAL: 153744.8, P_WK: 1552.6,  A_WK: 0,      D_WK: 0,    P_CUM: 134770,   A_CUM: 30906.8, D_CUM: 0, },
      { CDATE: '2026-06-26', TOTAL: 153744.8, P_WK: 2162.9,  A_WK: 0,      D_WK: 0,    P_CUM: 136932.9, A_CUM: 30906.8, D_CUM: 0, },
      { CDATE: '2026-07-31', TOTAL: 153744.8, P_WK: 3016.6,  A_WK: 0,      D_WK: 0,    P_CUM: 139949.5, A_CUM: 30906.8, D_CUM: 0, },
      { CDATE: '2026-08-28', TOTAL: 153744.8, P_WK: 2823.4,  A_WK: 0,      D_WK: 0,    P_CUM: 142772.9, A_CUM: 30906.8, D_CUM: 0, },
      { CDATE: '2026-09-25', TOTAL: 153744.8, P_WK: 2888.7,  A_WK: 0,      D_WK: 0,    P_CUM: 145661.6, A_CUM: 30906.8, D_CUM: 0, },
      { CDATE: '2026-10-30', TOTAL: 153744.8, P_WK: 2906.1,  A_WK: 0,      D_WK: 0,    P_CUM: 148567.7, A_CUM: 30906.8, D_CUM: 0, },
      { CDATE: '2026-11-27', TOTAL: 153744.8, P_WK: 1813.6,  A_WK: 0,      D_WK: 0,    P_CUM: 150381.3, A_CUM: 30906.8, D_CUM: 0, },
      { CDATE: '2026-12-25', TOTAL: 153744.8, P_WK: 1511.7,  A_WK: 0,      D_WK: 0,    P_CUM: 151893,   A_CUM: 30906.8, D_CUM: 0, },
      { CDATE: '2027-01-29', TOTAL: 153744.8, P_WK: 765.1,   A_WK: 0,      D_WK: 0,    P_CUM: 152658.1, A_CUM: 30906.8, D_CUM: 0, },
      { CDATE: '2027-02-26', TOTAL: 153744.8, P_WK: 555.6,   A_WK: 0,      D_WK: 0,    P_CUM: 153213.7, A_CUM: 30906.8, D_CUM: 0, },
      { CDATE: '2027-03-26', TOTAL: 153744.8, P_WK: 405.9,   A_WK: 0,      D_WK: 0,    P_CUM: 153619.6, A_CUM: 30906.8, D_CUM: 0, },
      { CDATE: '2027-04-30', TOTAL: 153744.8, P_WK: 101.4,   A_WK: 0,      D_WK: 0,    P_CUM: 153721,   A_CUM: 30906.8, D_CUM: 0, },
      { CDATE: '2027-05-28', TOTAL: 153744.8, P_WK: 24,      A_WK: 0,      D_WK: 0,    P_CUM: 153745,   A_CUM: 30906.8, D_CUM: 0, },
      { CDATE: '2027-06-11', TOTAL: 153744.8, P_WK: 0,       A_WK: 0,      D_WK: 0,    P_CUM: 153745,   A_CUM: 30906.8, D_CUM: 0, },
],


    Queries: {
      SQL1: [
        { CDATE: '2024-11-29', PHASE: 'Engineering',   GROUP: 'Detail Eng',     SUBCONT: 'Exmar',        TOTAL: 5228.3,  P_WK: 163.4,  A_WK: 243.5,  D_WK: 80,  P_CUM: 4914.3,  A_CUM: 4917.5,  D_CUM: 3, },
        { CDATE: '2024-11-29', PHASE: 'Engineering',   GROUP: 'Detail Eng',     SUBCONT: 'HHI',          TOTAL: 9460.6,  P_WK: 633.3,  A_WK: 788.9,  D_WK: 156, P_CUM: 7557.9,  A_CUM: 7664.2,  D_CUM: 106, },
        { CDATE: '2024-11-29', PHASE: 'Engineering',   GROUP: 'Detail Eng',     SUBCONT: 'WOOD',         TOTAL: 17673.2, P_WK: 1094.2, A_WK: 1269.2, D_WK: 175, P_CUM: 14100.7, A_CUM: 14731.8, D_CUM: 631, },
        { CDATE: '2024-11-29', PHASE: 'Engineering',   GROUP: 'Detail Eng',     SUBCONT: 'OVERALL',      TOTAL: 32362.2, P_WK: 1890.9, A_WK: 2301.6, D_WK: 411, P_CUM: 26572.9, A_CUM: 27313.5, D_CUM: 741, },
        { CDATE: '2024-11-29', PHASE: 'Engineering',   GROUP: 'Shop Drawing',   SUBCONT: 'HHI',          TOTAL: 12252.5, P_WK: 246.7,  A_WK: 192.1,  D_WK: -55, P_CUM: 349.7,   A_CUM: 295.1,   D_CUM: -55, },
        { CDATE: '2024-11-29', PHASE: 'Engineering',   GROUP: 'Commissing Eng', SUBCONT: 'HHI',          TOTAL: 185,     P_WK: 10,     A_WK: 5.3,    D_WK: -5,  P_CUM: 34,      A_CUM: 24.5,    D_CUM: -10, },
        { CDATE: '2024-11-29', PHASE: 'Engineering',   GROUP: 'Commissing Eng', SUBCONT: 'Gate',         TOTAL: 551,     P_WK: 15,     A_WK: 9.3,    D_WK: -6,  P_CUM: 80,      A_CUM: 71.1,    D_CUM: -9, },
        { CDATE: '2024-11-29', PHASE: 'Engineering',   GROUP: 'Commissing Eng', SUBCONT: 'OVERALL',      TOTAL: 736,     P_WK: 25,     A_WK: 14.6,   D_WK: -10, P_CUM: 114,     A_CUM: 95.6,    D_CUM: -18, },
        { CDATE: '2024-11-29', PHASE: 'Engineering',   GROUP: 'OVERALL',        SUBCONT: 'OVERALL',      TOTAL: 45350.7, P_WK: 2162.6, A_WK: 2508.3, D_WK: 346, P_CUM: 27036.6, A_CUM: 27704.2, D_CUM: 668, },
        { CDATE: '2024-11-29', PHASE: 'Construction',  GROUP: 'HHI',            SUBCONT: 'HHI',          TOTAL: 68489.9, P_WK: 199.1,  A_WK: 133.5,  D_WK: -66, P_CUM: 199.1,   A_CUM: 133.5,   D_CUM: -66, },
        { CDATE: '2024-11-29', PHASE: 'Construction',  GROUP: 'Subcontractor',  SUBCONT: 'ShinHwaTech.', TOTAL: 0,       P_WK: 0,      A_WK: 140,    D_WK: 140, P_CUM: 0,       A_CUM: 140,     D_CUM: 140, },
        { CDATE: '2024-11-29', PHASE: 'Construction',  GROUP: 'Subcontractor',  SUBCONT: 'Sung-II',      TOTAL: 0,       P_WK: 0,      A_WK: 67,     D_WK: 67,  P_CUM: 0,       A_CUM: 67,      D_CUM: 67, },
        { CDATE: '2024-11-29', PHASE: 'Construction',  GROUP: 'Subcontractor',  SUBCONT: 'COSCO',        TOTAL: 29533.2, P_WK: 106,    A_WK: 225.1,  D_WK: 119, P_CUM: 106,     A_CUM: 225.1,   D_CUM: 119, },
        { CDATE: '2024-11-29', PHASE: 'Construction',  GROUP: 'Subcontractor',  SUBCONT: 'OVERALL',      TOTAL: 29533.2, P_WK: 106,    A_WK: 432.1,  D_WK: 326, P_CUM: 106,     A_CUM: 432.1,   D_CUM: 326, },
        { CDATE: '2024-11-29', PHASE: 'Construction',  GROUP: 'OVERALL',        SUBCONT: 'OVERALL',      TOTAL: 98023.1, P_WK: 305.1,  A_WK: 565.6,  D_WK: 261, P_CUM: 305.1,   A_CUM: 565.6,   D_CUM: 261, },
        { CDATE: '2024-11-29', PHASE: 'Commissioning', GROUP: 'Commissioning',  SUBCONT: 'HHI',          TOTAL: 3199.9,  P_WK: 0,      A_WK: 0,      D_WK: 0,   P_CUM: 0,       A_CUM: 0,       D_CUM: 0, },
        { CDATE: '2024-11-29', PHASE: 'Management',    GROUP: 'Management',     SUBCONT: 'HHI',          TOTAL: 7171,    P_WK: 200,    A_WK: 250,    D_WK: 50,  P_CUM: 2267,    A_CUM: 2637,    D_CUM: 370, },
        { CDATE: '2024-11-29', PHASE: 'OVERALL',       GROUP: 'OVERALL',        SUBCONT: 'OVERALL',      TOTAL: 153744.8, P_WK: 2667.7, A_WK: 3323.9, D_WK: 656, P_CUM: 29608.7, A_CUM: 30906.8, D_CUM: 1298, },
      ],

      SQL2: [
        {
          // Timeline
          CUTOFF      : '2024-11-29',
          START_DATE  : '2023-07-28',
          END_DATE    : '2027-06-11',
        },
      ],

    },


}