import { START_LOCATION } from "vue-router";

export default {
  Canvas: {
    CanvasWidth: 1750, //previous value : 1800
    CanvasHeight: 1120,
    CanvasBorderWeight: 0,
    CanvasBorderColor: '#bcbcbc',
    CanvasFillColor: '#fff',
    CanvasOpacity: 1,

    CanvasChartX: 0,
    CanvasChartY: 0,
    CanvasChartWidth: 1300,
    CanvasChartHeight: 760,
    CanvasChartBorderWeight: 0,
    CanvasChartBorderColor: '#757575',
    CanvasChartFillColor: '#000',
    CanvasChartOpacity: 0,
    CanvasChartSpace: 0,

    CanvasFontFamily: 'roboto',
  },
  


  DataItems: [
    {
      CUTOFF:                 '2025-02-28',
      TITLE_SUB:              'SkylineChart for RFQ Tagged ',
    }
  ],

  Queries: { //move from data.js

    SQL1: [
      { RFQ: '1A-04', ETA: '2025-06-01', SRD: '2025-06-18', ETA_SRD: 17, TAG: '101930-LPNL-71701', },
      { RFQ: '1A-04', ETA: '2025-06-01', SRD: '2025-06-18', ETA_SRD: 17, TAG: '101930-PB-71701', },
      { RFQ: '1A-04', ETA: '2025-06-01', SRD: '2025-06-18', ETA_SRD: 17, TAG: '101930-PB-71702', },
      { RFQ: '1A-04', ETA: '2025-06-01', SRD: '2025-06-18', ETA_SRD: 17, TAG: '101930-PB-71703', },
      { RFQ: '1A-04', ETA: '2025-06-01', SRD: '2025-06-18', ETA_SRD: 17, TAG: '101930-PB-71704', },
      { RFQ: '1A-04', ETA: '2025-06-01', SRD: '2025-06-18', ETA_SRD: 17, TAG: '101930-RME-71706', },
      { RFQ: '1A-04', ETA: '2025-06-01', SRD: '2025-06-18', ETA_SRD: 17, TAG: '101930-TLE-71700', },
      { RFQ: '1E-01', ETA: '2025-10-18', SRD: '2025-10-28', ETA_SRD: 10, TAG: '101870-EPNL-62900', },
      { RFQ: '1E-01', ETA: '2025-10-18', SRD: '2025-10-28', ETA_SRD: 10, TAG: '101870-EPNL-62910', },
      { RFQ: '1E-01', ETA: '2025-10-02', SRD: '2025-10-28', ETA_SRD: 26, TAG: '101800-ESB-60000', },
      { RFQ: '1E-02', ETA: '2025-08-09', SRD: '2025-08-30', ETA_SRD: 21, TAG: '101820-ESB-71120 ', },
      { RFQ: '1E-02', ETA: '2025-08-09', SRD: '2025-08-30', ETA_SRD: 21, TAG: '101840-ESB-63000', },
      { RFQ: '1E-02', ETA: '2025-08-09', SRD: '2025-08-30', ETA_SRD: 21, TAG: '101840-ESB-71130 ', },
      { RFQ: '1E-02', ETA: '2025-10-16', SRD: '2025-10-28', ETA_SRD: 12, TAG: '101820-ESB-61000', },
      { RFQ: '1E-02', ETA: '2025-10-16', SRD: '2025-10-28', ETA_SRD: 12, TAG: '101820-ESB-62000', },
      { RFQ: '1E-03', ETA: '2025-08-18', SRD: '2025-10-28', ETA_SRD: 71, TAG: '101850-EBAT-62790', },
      { RFQ: '1E-03', ETA: '2025-08-18', SRD: '2025-10-28', ETA_SRD: 71, TAG: '101850-EBC-62770', },
      { RFQ: '1E-03', ETA: '2025-08-18', SRD: '2025-10-28', ETA_SRD: 71, TAG: '101850-EBC-62780', },
      { RFQ: '1E-03', ETA: '2025-08-18', SRD: '2025-10-28', ETA_SRD: 71, TAG: '101850-EBC-62790', },
      { RFQ: '1E-03', ETA: '2025-08-18', SRD: '2025-10-28', ETA_SRD: 71, TAG: '101850-EBC-62800', },
      { RFQ: '1E-03', ETA: '2025-08-18', SRD: '2025-10-28', ETA_SRD: 71, TAG: '101850-EUPS-62510', },
      { RFQ: '1E-03', ETA: '2025-08-18', SRD: '2025-10-28', ETA_SRD: 71, TAG: '101850-EUPS-62520', },
      { RFQ: '1E-03', ETA: '2025-08-18', SRD: '2025-10-28', ETA_SRD: 71, TAG: '101850-EUPS-62540', },
      { RFQ: '1E-03', ETA: '2025-08-18', SRD: '2025-10-28', ETA_SRD: 71, TAG: '101850-EUPS-62550', },
      { RFQ: '1E-03', ETA: '2025-08-18', SRD: '2025-10-28', ETA_SRD: 71, TAG: '101850-EUPS-62570', },
      { RFQ: '1E-03', ETA: '2025-08-18', SRD: '2025-10-28', ETA_SRD: 71, TAG: '101850-EUPS-62580', },
      { RFQ: '1E-03', ETA: '2025-08-18', SRD: '2025-10-28', ETA_SRD: 71, TAG: '101850-EUPS-62600', },
      { RFQ: '1E-03', ETA: '2025-09-15', SRD: '2025-10-28', ETA_SRD: 43, TAG: '101850-EBAT-62510', },
      { RFQ: '1E-03', ETA: '2025-09-15', SRD: '2025-10-28', ETA_SRD: 43, TAG: '101850-EBAT-62520', },
      { RFQ: '1E-03', ETA: '2025-09-15', SRD: '2025-10-28', ETA_SRD: 43, TAG: '101850-EBAT-62540', },
      { RFQ: '1E-03', ETA: '2025-09-15', SRD: '2025-10-28', ETA_SRD: 43, TAG: '101850-EBAT-62570', },
      { RFQ: '1E-03', ETA: '2025-09-15', SRD: '2025-10-28', ETA_SRD: 43, TAG: '101850-EBAT-62580', },
      { RFQ: '1E-03', ETA: '2025-09-15', SRD: '2025-10-28', ETA_SRD: 43, TAG: '101850-EBAT-62600', },
      { RFQ: '1E-03', ETA: '2025-09-15', SRD: '2025-10-28', ETA_SRD: 43, TAG: '101850-EBAT-62770', },
      { RFQ: '1E-04', ETA: '2025-09-21', SRD: '2025-10-28', ETA_SRD: 37, TAG: '101800-EXFMR-61100', },
      { RFQ: '1E-04', ETA: '2025-09-21', SRD: '2025-10-28', ETA_SRD: 37, TAG: '101800-EXFMR-61200', },
      { RFQ: '1E-04', ETA: '2025-09-21', SRD: '2025-10-28', ETA_SRD: 37, TAG: '101800-EXFMR-62100', },
      { RFQ: '1E-04', ETA: '2025-09-21', SRD: '2025-10-28', ETA_SRD: 37, TAG: '101800-EXFMR-62200', },
      { RFQ: '1E-05', ETA: '2025-08-08', SRD: '2025-10-28', ETA_SRD: 81, TAG: '101880-ENER-60110', },
      { RFQ: '1E-05', ETA: '2025-08-08', SRD: '2025-10-28', ETA_SRD: 81, TAG: '101880-ENER-60210', },
      { RFQ: '1E-05', ETA: '2025-08-08', SRD: '2025-10-28', ETA_SRD: 81, TAG: '101880-ENER-60310', },
      { RFQ: '1E-05', ETA: '2025-08-08', SRD: '2025-10-28', ETA_SRD: 81, TAG: '101880-ENER-61100', },
      { RFQ: '1E-05', ETA: '2025-08-08', SRD: '2025-10-28', ETA_SRD: 81, TAG: '101880-ENER-61200', },
      { RFQ: '1E-05', ETA: '2025-08-08', SRD: '2025-10-28', ETA_SRD: 81, TAG: '101880-ENER-62100', },
      { RFQ: '1E-05', ETA: '2025-08-08', SRD: '2025-10-28', ETA_SRD: 81, TAG: '101880-ENER-62200', },
      { RFQ: '1E-06', ETA: '2025-07-09', SRD: '2025-10-28', ETA_SRD: 111, TAG: '101820-EXFMR-62410', },
      { RFQ: '1E-06', ETA: '2025-07-09', SRD: '2025-08-30', ETA_SRD: 52, TAG: '101820-EXFMR-62460', },
      { RFQ: '1E-06', ETA: '2025-07-09', SRD: '2025-08-30', ETA_SRD: 52, TAG: '101820-EXFMR-71220', },
      { RFQ: '1E-06', ETA: '2025-07-09', SRD: '2025-08-30', ETA_SRD: 52, TAG: '101820-EXFMR-71410', },
      { RFQ: '1E-06', ETA: '2025-07-09', SRD: '2025-08-30', ETA_SRD: 52, TAG: '101820-EXFMR-71420', },
      { RFQ: '1E-06', ETA: '2025-07-09', SRD: '2025-08-30', ETA_SRD: 52, TAG: '101840-EXFMR-62440', },
      { RFQ: '1E-06', ETA: '2025-07-09', SRD: '2025-08-30', ETA_SRD: 52, TAG: '101840-EXFMR-71230', },
      { RFQ: '1E-06', ETA: '2025-07-09', SRD: '2025-08-30', ETA_SRD: 52, TAG: '101840-EXFMR-71440', },
      { RFQ: '1E-06', ETA: '2025-07-09', SRD: '2025-10-28', ETA_SRD: 111, TAG: '101930-EXFMR-70410', },
      { RFQ: '1E-07A', ETA: '2025-08-30', SRD: '2025-10-07', ETA_SRD: 38, TAG: '101820-EDB-62410', },
      { RFQ: '1E-07A', ETA: '2025-08-30', SRD: '2025-10-25', ETA_SRD: 56, TAG: '101820-EDB-62460', },
      { RFQ: '1E-07A', ETA: '2025-08-30', SRD: '2025-08-30', ETA_SRD: 0, TAG: '101820-EDB-71211', },
      { RFQ: '1E-07A', ETA: '2025-08-30', SRD: '2025-08-30', ETA_SRD: 0, TAG: '101820-EDB-71212', },
      { RFQ: '1E-07A', ETA: '2025-08-30', SRD: '2025-08-30', ETA_SRD: 0, TAG: '101820-EDB-71213', },
      { RFQ: '1E-07A', ETA: '2025-08-30', SRD: '2025-08-30', ETA_SRD: 0, TAG: '101820-EDB-71214', },
      { RFQ: '1E-07A', ETA: '2025-08-30', SRD: '2025-08-30', ETA_SRD: 0, TAG: '101820-EDB-71220', },
      { RFQ: '1E-07A', ETA: '2025-08-30', SRD: '2025-08-30', ETA_SRD: 0, TAG: '101820-EDB-71221', },
      { RFQ: '1E-07A', ETA: '2025-08-30', SRD: '2025-08-30', ETA_SRD: 0, TAG: '101820-EDB-71222', },
      { RFQ: '1E-07A', ETA: '2025-08-30', SRD: '2025-08-30', ETA_SRD: 0, TAG: '101820-EDB-71223', },
      { RFQ: '1E-07A', ETA: '2025-08-30', SRD: '2025-08-30', ETA_SRD: 0, TAG: '101820-EDB-71410', },
      { RFQ: '1E-07A', ETA: '2025-08-30', SRD: '2025-08-30', ETA_SRD: 0, TAG: '101820-EDB-71411', },
      { RFQ: '1E-07A', ETA: '2025-08-30', SRD: '2025-08-30', ETA_SRD: 0, TAG: '101820-EDB-71420', },
      { RFQ: '1E-07A', ETA: '2025-08-30', SRD: '2025-08-30', ETA_SRD: 0, TAG: '101820-EDB-71421', },
      { RFQ: '1E-07A', ETA: '2025-08-30', SRD: '2025-08-30', ETA_SRD: 0, TAG: '101820-EDB-71422', },
      { RFQ: '1E-07A', ETA: '2025-08-30', SRD: '2025-08-30', ETA_SRD: 0, TAG: '101840-EDB-62440', },
      { RFQ: '1E-07A', ETA: '2025-08-30', SRD: '2025-10-07', ETA_SRD: 38, TAG: '101840-EDB-62770', },
      { RFQ: '1E-07A', ETA: '2025-08-30', SRD: '2025-08-30', ETA_SRD: 0, TAG: '101840-EDB-71230', },
      { RFQ: '1E-07A', ETA: '2025-08-30', SRD: '2025-08-30', ETA_SRD: 0, TAG: '101840-EDB-71440', },
      { RFQ: '1E-07A', ETA: '2025-08-30', SRD: '2025-08-30', ETA_SRD: 0, TAG: '101840-EDB-71441', },
      { RFQ: '1E-07A', ETA: '2025-08-30', SRD: '2025-08-30', ETA_SRD: 0, TAG: '101840-EDB-71442', },
      { RFQ: '1E-07A', ETA: '2025-08-30', SRD: '2025-10-07', ETA_SRD: 38, TAG: '101850-EDB-62510', },
      { RFQ: '1E-07A', ETA: '2025-08-30', SRD: '2025-10-07', ETA_SRD: 38, TAG: '101850-EDB-62520', },
      { RFQ: '1E-07A', ETA: '2025-08-30', SRD: '2025-10-07', ETA_SRD: 38, TAG: '101850-EDB-62530', },
      { RFQ: '1E-07A', ETA: '2025-08-30', SRD: '2025-10-07', ETA_SRD: 38, TAG: '101850-EDB-62540', },
      { RFQ: '1E-07A', ETA: '2025-08-30', SRD: '2025-10-07', ETA_SRD: 38, TAG: '101850-EDB-62550', },
      { RFQ: '1E-07A', ETA: '2025-08-30', SRD: '2025-10-07', ETA_SRD: 38, TAG: '101850-EDB-62560', },
      { RFQ: '1E-07A', ETA: '2025-08-30', SRD: '2025-10-07', ETA_SRD: 38, TAG: '101850-EDB-62570', },
      { RFQ: '1E-07A', ETA: '2025-08-30', SRD: '2025-10-07', ETA_SRD: 38, TAG: '101850-EDB-62580', },
      { RFQ: '1E-07A', ETA: '2025-08-30', SRD: '2025-08-30', ETA_SRD: 0, TAG: '101850-EDB-71111A', },
      { RFQ: '1E-07A', ETA: '2025-08-30', SRD: '2025-08-30', ETA_SRD: 0, TAG: '101850-EDB-71111B', },
      { RFQ: '1E-07A', ETA: '2025-08-30', SRD: '2025-08-30', ETA_SRD: 0, TAG: '101850-EDB-71112A', },
      { RFQ: '1E-07A', ETA: '2025-08-30', SRD: '2025-08-30', ETA_SRD: 0, TAG: '101850-EDB-71112B', },
      { RFQ: '1E-07A', ETA: '2025-08-30', SRD: '2025-08-30', ETA_SRD: 0, TAG: '101850-EDB-71114A', },
      { RFQ: '1E-07A', ETA: '2025-08-30', SRD: '2025-08-30', ETA_SRD: 0, TAG: '101850-EDB-71114B', },
      { RFQ: '1E-07B', ETA: '2025-08-30', SRD: '2025-08-30', ETA_SRD: 0, TAG: '101820-EDB-60000', },
      { RFQ: '1E-07B', ETA: '2025-08-30', SRD: '2025-08-30', ETA_SRD: 0, TAG: '101820-EDB-61000', },
      { RFQ: '1E-07B', ETA: '2025-08-30', SRD: '2025-09-30', ETA_SRD: 31, TAG: '101820-EDB-61001', },
      { RFQ: '1E-07B', ETA: '2025-08-30', SRD: '2025-10-29', ETA_SRD: 60, TAG: '101820-EDB-62000', },
      { RFQ: '1E-07B', ETA: '2025-08-30', SRD: '2025-10-15', ETA_SRD: 46, TAG: '101820-EDB-62001', },
      { RFQ: '1E-07B', ETA: '2025-08-30', SRD: '2025-10-29', ETA_SRD: 60, TAG: '101820-EDB-62002', },
      { RFQ: '1E-07B', ETA: '2025-08-30', SRD: '2025-11-17', ETA_SRD: 79, TAG: '101820-EDB-62003', },
      { RFQ: '1E-07B', ETA: '2025-08-30', SRD: '2025-11-06', ETA_SRD: 68, TAG: '101820-EDB-62004', },
      { RFQ: '1E-07B', ETA: '2025-08-30', SRD: '2025-09-22', ETA_SRD: 23, TAG: '101840-EDB-60000', },
      { RFQ: '1E-07B', ETA: '2025-08-30', SRD: '2025-10-03', ETA_SRD: 34, TAG: '101840-EDB-61000', },
      { RFQ: '1E-07B', ETA: '2025-08-30', SRD: '2025-10-01', ETA_SRD: 32, TAG: '101840-EDB-62000', },
      { RFQ: '1E-07B', ETA: '2025-08-30', SRD: '2025-08-30', ETA_SRD: 0, TAG: '101850-EDB-60000', },
      { RFQ: '1E-07B', ETA: '2025-08-30', SRD: '2025-08-30', ETA_SRD: 0, TAG: '101850-EDB-60001', },
      { RFQ: '1E-07B', ETA: '2025-08-30', SRD: '2025-08-30', ETA_SRD: 0, TAG: '101850-EDB-60002', },
      { RFQ: '1E-07B', ETA: '2025-08-30', SRD: '2025-08-30', ETA_SRD: 0, TAG: '101850-EDB-60003', },
      { RFQ: '1E-07B', ETA: '2025-08-30', SRD: '2025-08-30', ETA_SRD: 0, TAG: '101850-EDB-61000', },
      { RFQ: '1E-07B', ETA: '2025-08-30', SRD: '2025-08-30', ETA_SRD: 0, TAG: '101850-EDB-61001', },
      { RFQ: '1E-07B', ETA: '2025-08-30', SRD: '2025-08-30', ETA_SRD: 0, TAG: '101850-EDB-61002', },
      { RFQ: '1E-07B', ETA: '2025-08-30', SRD: '2025-09-30', ETA_SRD: 31, TAG: '101850-EDB-61003', },
      { RFQ: '1E-07B', ETA: '2025-08-30', SRD: '2025-08-30', ETA_SRD: 0, TAG: '101850-EDB-61004', },
      { RFQ: '1E-07B', ETA: '2025-08-30', SRD: '2025-08-30', ETA_SRD: 0, TAG: '101850-EDB-61005', },
      { RFQ: '1E-07B', ETA: '2025-08-30', SRD: '2025-08-30', ETA_SRD: 0, TAG: '101850-EDB-61006', },
      { RFQ: '1E-07B', ETA: '2025-08-30', SRD: '2025-09-30', ETA_SRD: 31, TAG: '101850-EDB-61007', },
      { RFQ: '1E-07B', ETA: '2025-08-30', SRD: '2025-08-30', ETA_SRD: 0, TAG: '101850-EDB-61008', },
      { RFQ: '1E-07B', ETA: '2025-08-30', SRD: '2025-10-03', ETA_SRD: 34, TAG: '101850-EDB-61009', },
      { RFQ: '1E-07B', ETA: '2025-08-30', SRD: '2025-10-29', ETA_SRD: 60, TAG: '101850-EDB-62001', },
      { RFQ: '1E-07B', ETA: '2025-08-30', SRD: '2025-10-24', ETA_SRD: 55, TAG: '101850-EDB-62002', },
      { RFQ: '1E-07B', ETA: '2025-08-30', SRD: '2025-10-01', ETA_SRD: 32, TAG: '101850-EDB-62004', },
      { RFQ: '1E-07B', ETA: '2025-08-30', SRD: '2025-10-15', ETA_SRD: 46, TAG: '101850-EDB-62005', },
      { RFQ: '1E-07B', ETA: '2025-08-30', SRD: '2025-11-11', ETA_SRD: 73, TAG: '101850-EDB-62006', },
      { RFQ: '1E-07B', ETA: '2025-08-30', SRD: '2025-11-17', ETA_SRD: 79, TAG: '101850-EDB-62007', },
      { RFQ: '1E-07B', ETA: '2025-08-30', SRD: '2025-10-29', ETA_SRD: 60, TAG: '101850-EDB-62009', },
      { RFQ: '1E-07B', ETA: '2025-08-30', SRD: '2025-10-01', ETA_SRD: 32, TAG: '101850-EDB-62010', },
      { RFQ: '1E-07B', ETA: '2025-08-30', SRD: '2025-11-17', ETA_SRD: 79, TAG: '101850-EDB-62011', },
      { RFQ: '1E-07B', ETA: '2025-08-30', SRD: '2025-10-24', ETA_SRD: 55, TAG: '101850-EDB-62012', },
      { RFQ: '1E-07B', ETA: '2025-08-30', SRD: '2025-10-15', ETA_SRD: 46, TAG: '101850-EDB-62013', },
      { RFQ: '1E-07B', ETA: '2025-08-30', SRD: '2025-10-01', ETA_SRD: 32, TAG: '101850-EDB-62014', },
      { RFQ: '1E-08', ETA: '2025-10-03', SRD: '2025-10-28', ETA_SRD: 25, TAG: '101850-EBAT-62810', },
      { RFQ: '1E-08', ETA: '2025-10-03', SRD: '2025-10-28', ETA_SRD: 25, TAG: '101850-EBC-62810', },
      { RFQ: '1E-08', ETA: '2025-10-03', SRD: '2025-10-28', ETA_SRD: 25, TAG: '101860-EJB-62890', },
      { RFQ: '1E-08', ETA: '2025-10-03', SRD: '2025-10-28', ETA_SRD: 25, TAG: '101860-EPNL-62830', },
      { RFQ: '1E-08', ETA: '2025-10-03', SRD: '2025-10-25', ETA_SRD: 22, TAG: '101860-EPNL-62920', },
      { RFQ: '1E-09', ETA: '2025-06-30', SRD: '2025-10-01', ETA_SRD: 93, TAG: '101800-XXX-XXXX0', },
      { RFQ: '1E-09', ETA: '2025-06-30', SRD: '2025-10-24', ETA_SRD: 116, TAG: '101800-XXX-XXXX1', },
      { RFQ: '1E-09', ETA: '2025-06-30', SRD: '2025-11-11', ETA_SRD: 134, TAG: '101800-XXX-XXXX2', },
      { RFQ: '1F-03A', ETA: '2025-07-01', SRD: '2025-09-19', ETA_SRD: 80, TAG: '101720-XV-53411', },
      { RFQ: '1F-03A', ETA: '2025-07-01', SRD: '2025-09-19', ETA_SRD: 80, TAG: '101720-XV-53412', },
      { RFQ: '1F-03A', ETA: '2025-07-01', SRD: '2025-09-19', ETA_SRD: 80, TAG: '101720-XV-53413', },
      { RFQ: '1F-03A', ETA: '2025-07-01', SRD: '2025-09-19', ETA_SRD: 80, TAG: '101720-XV-53414', },
      { RFQ: '1F-03A', ETA: '2025-07-01', SRD: '2025-09-19', ETA_SRD: 80, TAG: '101720-XV-53415', },
      { RFQ: '1F-03A', ETA: '2025-07-01', SRD: '2025-09-19', ETA_SRD: 80, TAG: '101720-XV-53416', },
      { RFQ: '1F-03A', ETA: '2025-07-01', SRD: '2025-09-19', ETA_SRD: 80, TAG: '101720-XV-53418', },
      { RFQ: '1F-03A', ETA: '2025-07-01', SRD: '2025-09-19', ETA_SRD: 80, TAG: '101720-XV-53419', },
      { RFQ: '1F-03A', ETA: '2025-07-01', SRD: '2025-09-19', ETA_SRD: 80, TAG: '101720-XV-53420', },
      { RFQ: '1F-03A', ETA: '2025-07-01', SRD: '2025-09-19', ETA_SRD: 80, TAG: '101720-XV-53421', },
      { RFQ: '1F-03A', ETA: '2025-07-01', SRD: '2025-09-19', ETA_SRD: 80, TAG: '101720-XV-53422', },
      { RFQ: '1F-03A', ETA: '2025-07-01', SRD: '2025-09-19', ETA_SRD: 80, TAG: '101720-XV-53425', },
      { RFQ: '1F-09', ETA: '2025-08-04', SRD: '2025-10-28', ETA_SRD: 85, TAG: '101900-ZZZ-61280', },
      { RFQ: '1H-01', ETA: '2025-06-13', SRD: '2025-08-30', ETA_SRD: 78, TAG: '101820-EMCC-71125', },
      { RFQ: '1H-01', ETA: '2025-06-13', SRD: '2025-08-30', ETA_SRD: 78, TAG: '101840-EMCC-71135', },
      { RFQ: '1H-01', ETA: '2025-06-13', SRD: '2025-08-30', ETA_SRD: 78, TAG: '101970-EPNL-77510', },
      { RFQ: '1H-01', ETA: '2025-06-13', SRD: '2025-08-30', ETA_SRD: 78, TAG: '101970-EVSD-77510A', },
      { RFQ: '1H-01', ETA: '2025-06-13', SRD: '2025-08-30', ETA_SRD: 78, TAG: '101970-EVSD-77510B', },
      { RFQ: '1H-01', ETA: '2025-06-13', SRD: '2025-05-14', ETA_SRD: -30, TAG: '101970-HAL-74510', },
      { RFQ: '1H-01', ETA: '2025-06-13', SRD: '2025-05-14', ETA_SRD: -30, TAG: '101970-HAL-74520', },
      { RFQ: '1H-01', ETA: '2025-06-13', SRD: '2025-06-09', ETA_SRD: -4, TAG: '101970-HAL-75520', },
      { RFQ: '1H-01', ETA: '2025-06-13', SRD: '2025-06-16', ETA_SRD: 3, TAG: '101970-HAL-76510', },
      { RFQ: '1H-01', ETA: '2025-06-13', SRD: '2025-06-16', ETA_SRD: 3, TAG: '101970-HAL-76520', },
      { RFQ: '1H-01', ETA: '2025-06-13', SRD: '2025-06-25', ETA_SRD: 12, TAG: '101970-HAL-77510A', },
      { RFQ: '1H-01', ETA: '2025-06-13', SRD: '2025-06-25', ETA_SRD: 12, TAG: '101970-HAL-77510B', },
      { RFQ: '1H-01', ETA: '2025-06-13', SRD: '2025-06-25', ETA_SRD: 12, TAG: '101970-HAL-77520', },
      { RFQ: '1H-01', ETA: '2025-06-13', SRD: '2025-05-14', ETA_SRD: -30, TAG: '101970-TFAN-74510', },
      { RFQ: '1H-01', ETA: '2025-06-13', SRD: '2025-05-14', ETA_SRD: -30, TAG: '101970-TFAN-74520', },
      { RFQ: '1H-01', ETA: '2025-06-13', SRD: '2025-06-09', ETA_SRD: -4, TAG: '101970-TFAN-75510', },
      { RFQ: '1H-01', ETA: '2025-06-13', SRD: '2025-06-09', ETA_SRD: -4, TAG: '101970-TFAN-75520', },
      { RFQ: '1H-01', ETA: '2025-06-13', SRD: '2025-06-09', ETA_SRD: -4, TAG: '101970-TFAN-75530', },
      { RFQ: '1H-01', ETA: '2025-06-13', SRD: '2025-06-09', ETA_SRD: -4, TAG: '101970-TFAN-75540', },
      { RFQ: '1H-01', ETA: '2025-06-13', SRD: '2025-06-09', ETA_SRD: -4, TAG: '101970-TFAN-75550', },
      { RFQ: '1H-01', ETA: '2025-06-13', SRD: '2025-06-16', ETA_SRD: 3, TAG: '101970-TFAN-76510', },
      { RFQ: '1H-01', ETA: '2025-06-13', SRD: '2025-06-16', ETA_SRD: 3, TAG: '101970-TFAN-76520', },
      { RFQ: '1H-01', ETA: '2025-06-13', SRD: '2025-06-16', ETA_SRD: 3, TAG: '101970-TFAN-76530', },
      { RFQ: '1H-01', ETA: '2025-06-13', SRD: '2025-06-16', ETA_SRD: 3, TAG: '101970-TFAN-76540', },
      { RFQ: '1H-01', ETA: '2025-06-13', SRD: '2025-06-16', ETA_SRD: 3, TAG: '101970-TFAN-76550', },
      { RFQ: '1H-01', ETA: '2025-06-13', SRD: '2025-06-25', ETA_SRD: 12, TAG: '101970-TFAN-77510', },
      { RFQ: '1H-01', ETA: '2025-06-13', SRD: '2025-05-14', ETA_SRD: -30, TAG: '101970-THAH-74510A', },
      { RFQ: '1H-01', ETA: '2025-06-13', SRD: '2025-05-14', ETA_SRD: -30, TAG: '101970-THAH-74510B', },
      { RFQ: '1H-01', ETA: '2025-06-13', SRD: '2025-05-14', ETA_SRD: -30, TAG: '101970-THAH-74520', },
      { RFQ: '1H-01', ETA: '2025-06-13', SRD: '2025-06-09', ETA_SRD: -4, TAG: '101970-THAH-75520', },
      { RFQ: '1H-01', ETA: '2025-06-13', SRD: '2025-06-16', ETA_SRD: 3, TAG: '101970-THAH-76510', },
      { RFQ: '1H-01', ETA: '2025-06-13', SRD: '2025-06-16', ETA_SRD: 3, TAG: '101970-THAH-76520', },
      { RFQ: '1H-01', ETA: '2025-06-13', SRD: '2025-06-25', ETA_SRD: 12, TAG: '101970-THAH-77510A', },
      { RFQ: '1H-01', ETA: '2025-06-13', SRD: '2025-06-25', ETA_SRD: 12, TAG: '101970-THAH-77510B', },
      { RFQ: '1H-01', ETA: '2025-06-13', SRD: '2025-06-25', ETA_SRD: 12, TAG: '101970-THAH-77520', },
      { RFQ: '1H-02', ETA: '2025-09-30', SRD: '2025-10-30', ETA_SRD: 30, TAG: '101970-EPNL-71510', },
      { RFQ: '1H-02', ETA: '2025-09-30', SRD: '2025-08-30', ETA_SRD: -31, TAG: '101970-EPNL-72510', },
      { RFQ: '1H-02', ETA: '2025-09-30', SRD: '2025-08-30', ETA_SRD: -31, TAG: '101970-EPNL-73510', },
      { RFQ: '1H-02', ETA: '2025-09-30', SRD: '2025-11-17', ETA_SRD: 48, TAG: '101970-HAL-71501', },
      { RFQ: '1H-02', ETA: '2025-09-30', SRD: '2025-11-17', ETA_SRD: 48, TAG: '101970-HAL-71502', },
      { RFQ: '1H-02', ETA: '2025-09-30', SRD: '2025-08-15', ETA_SRD: -46, TAG: '101970-HAL-72501', },
      { RFQ: '1H-02', ETA: '2025-09-30', SRD: '2025-08-15', ETA_SRD: -46, TAG: '101970-HAL-72502', },
      { RFQ: '1H-02', ETA: '2025-09-30', SRD: '2025-08-15', ETA_SRD: -46, TAG: '101970-HAL-73501', },
      { RFQ: '1H-02', ETA: '2025-09-30', SRD: '2025-11-17', ETA_SRD: 48, TAG: '101970-TFAN-71501', },
      { RFQ: '1H-02', ETA: '2025-09-30', SRD: '2025-11-17', ETA_SRD: 48, TAG: '101970-TFAN-71502', },
      { RFQ: '1H-02', ETA: '2025-09-30', SRD: '2025-11-17', ETA_SRD: 48, TAG: '101970-TFAN-71503', },
      { RFQ: '1H-02', ETA: '2025-09-30', SRD: '2025-08-15', ETA_SRD: -46, TAG: '101970-TFAN-73501', },
      { RFQ: '1H-02', ETA: '2025-09-30', SRD: '2025-08-15', ETA_SRD: -46, TAG: '101970-TFAN-73502', },
      { RFQ: '1H-02', ETA: '2025-09-30', SRD: '2025-11-17', ETA_SRD: 48, TAG: '101970-THAH-71501', },
      { RFQ: '1H-02', ETA: '2025-09-30', SRD: '2025-11-17', ETA_SRD: 48, TAG: '101970-THAH-71502', },
      { RFQ: '1H-02', ETA: '2025-09-30', SRD: '2025-08-15', ETA_SRD: -46, TAG: '101970-THAH-72501', },
      { RFQ: '1H-02', ETA: '2025-09-30', SRD: '2025-08-15', ETA_SRD: -46, TAG: '101970-THAH-72502', },
      { RFQ: '1H-02', ETA: '2025-09-30', SRD: '2025-08-15', ETA_SRD: -46, TAG: '101970-THAH-73501', },
      { RFQ: '1I-01', ETA: '2025-09-01', SRD: '2025-09-01', ETA_SRD: 0, TAG: '101700-FRIO-67225', },
      { RFQ: '1I-01', ETA: '2025-09-01', SRD: '2025-09-01', ETA_SRD: 0, TAG: '101700-FRIO-67226', },
      { RFQ: '1I-01', ETA: '2025-09-01', SRD: '2025-09-01', ETA_SRD: 0, TAG: '101700-FRIO-67227', },
      { RFQ: '1I-01', ETA: '2025-09-01', SRD: '2025-09-01', ETA_SRD: 0, TAG: '101700-FRIO-67228', },
      { RFQ: '1I-01', ETA: '2025-09-01', SRD: '2025-09-01', ETA_SRD: 0, TAG: '101780-CRIO-67025', },
      { RFQ: '1I-01', ETA: '2025-09-01', SRD: '2025-09-01', ETA_SRD: 0, TAG: '101780-CRIO-67026', },
      { RFQ: '1I-01', ETA: '2025-09-01', SRD: '2025-09-01', ETA_SRD: 0, TAG: '101780-CRIO-67027', },
      { RFQ: '1I-01', ETA: '2025-09-01', SRD: '2025-09-01', ETA_SRD: 0, TAG: '101780-CRIO-67028', },
      { RFQ: '1I-01', ETA: '2025-09-01', SRD: '2025-09-01', ETA_SRD: 0, TAG: '101780-CRIO-67029', },
      { RFQ: '1I-01', ETA: '2025-09-01', SRD: '2025-09-01', ETA_SRD: 0, TAG: '101780-CRIO-67030', },
      { RFQ: '1I-01', ETA: '2025-09-01', SRD: '2025-08-16', ETA_SRD: -16, TAG: '101780-CRIO-67910F', },
      { RFQ: '1I-01', ETA: '2025-09-01', SRD: '2025-09-01', ETA_SRD: 0, TAG: '101780-FOPP-67025A', },
      { RFQ: '1I-01', ETA: '2025-09-01', SRD: '2025-09-01', ETA_SRD: 0, TAG: '101780-FOPP-67025B', },
      { RFQ: '1I-01', ETA: '2025-09-01', SRD: '2025-11-11', ETA_SRD: 71, TAG: '101790-SRIO-66125', },
      { RFQ: '1I-01', ETA: '2025-09-01', SRD: '2025-10-01', ETA_SRD: 30, TAG: '101790-SRIO-66126', },
      { RFQ: '1I-01', ETA: '2025-09-01', SRD: '2025-10-01', ETA_SRD: 30, TAG: '101790-SRIO-66127', },
      { RFQ: '1I-01', ETA: '2025-09-01', SRD: '2025-09-01', ETA_SRD: 0, TAG: '101790-SRIO-67125', },
      { RFQ: '1I-01', ETA: '2025-09-01', SRD: '2025-09-01', ETA_SRD: 0, TAG: '101790-SRIO-67126', },
      { RFQ: '1I-01', ETA: '2025-09-01', SRD: '2025-09-01', ETA_SRD: 0, TAG: '101790-SRIO-67127', },
      { RFQ: '1I-01', ETA: '2025-09-01', SRD: '2025-09-01', ETA_SRD: 0, TAG: '101790-SRIO-67128', },
      { RFQ: '1I-01', ETA: '2025-09-01', SRD: '2025-08-16', ETA_SRD: -16, TAG: '101790-SRIO-67910F', },
      { RFQ: '1I-01', ETA: '2025-09-01', SRD: '2025-08-16', ETA_SRD: -16, TAG: '101790-SRIO-67911', },
      { RFQ: '1I-01', ETA: '2025-09-20', SRD: '2025-08-30', ETA_SRD: -21, TAG: '101780-CRIO-67930', },
      { RFQ: '1I-01', ETA: '2025-09-20', SRD: '2025-08-30', ETA_SRD: -21, TAG: '101790-SRIO-67930F', },
      { RFQ: '1I-01', ETA: '2025-10-01', SRD: '2025-10-01', ETA_SRD: 0, TAG: '101700-FPNL-69800', },
      { RFQ: '1I-01', ETA: '2025-10-01', SRD: '2025-10-01', ETA_SRD: 0, TAG: '101700-FPNL-69850F', },
      { RFQ: '1I-01', ETA: '2025-10-01', SRD: '2025-10-24', ETA_SRD: 23, TAG: '101700-FRIO-66200', },
      { RFQ: '1I-01', ETA: '2025-10-01', SRD: '2025-10-01', ETA_SRD: 0, TAG: '101700-FRIO-66225', },
      { RFQ: '1I-01', ETA: '2025-10-01', SRD: '2025-10-01', ETA_SRD: 0, TAG: '101700-FRIO-66226', },
      { RFQ: '1I-01', ETA: '2025-10-01', SRD: '2025-11-11', ETA_SRD: 41, TAG: '101700-FRIO-66250', },
      { RFQ: '1I-01', ETA: '2025-10-01', SRD: '2025-11-11', ETA_SRD: 41, TAG: '101700-FRIO-66251', },
      { RFQ: '1I-01', ETA: '2025-10-01', SRD: '2025-10-01', ETA_SRD: 0, TAG: '101700-FRIO-67200', },
      { RFQ: '1I-01', ETA: '2025-10-01', SRD: '2025-11-11', ETA_SRD: 41, TAG: '101700-FRIO-67250', },
      { RFQ: '1I-01', ETA: '2025-10-01', SRD: '2025-10-01', ETA_SRD: 0, TAG: '101700-FRIO-67251', },
      { RFQ: '1I-01', ETA: '2025-10-01', SRD: '2025-10-01', ETA_SRD: 0, TAG: '101700-FRIO-67252', },
      { RFQ: '1I-01', ETA: '2025-10-01', SRD: '2025-11-11', ETA_SRD: 41, TAG: '101780-CPNL-68300F', },
      { RFQ: '1I-01', ETA: '2025-10-01', SRD: '2025-10-01', ETA_SRD: 0, TAG: '101780-CPNL-69600', },
      { RFQ: '1I-01', ETA: '2025-10-01', SRD: '2025-10-01', ETA_SRD: 0, TAG: '101780-CPNL-69900', },
      { RFQ: '1I-01', ETA: '2025-10-01', SRD: '2025-10-24', ETA_SRD: 23, TAG: '101780-CRIO-66000', },
      { RFQ: '1I-01', ETA: '2025-10-01', SRD: '2025-11-11', ETA_SRD: 41, TAG: '101780-CRIO-66001', },
      { RFQ: '1I-01', ETA: '2025-10-01', SRD: '2025-11-11', ETA_SRD: 41, TAG: '101780-CRIO-66025', },
      { RFQ: '1I-01', ETA: '2025-10-01', SRD: '2025-10-01', ETA_SRD: 0, TAG: '101780-CRIO-66026', },
      { RFQ: '1I-01', ETA: '2025-10-01', SRD: '2025-10-01', ETA_SRD: 0, TAG: '101780-CRIO-66027', },
      { RFQ: '1I-01', ETA: '2025-10-01', SRD: '2025-11-11', ETA_SRD: 41, TAG: '101780-CRIO-66050', },
      { RFQ: '1I-01', ETA: '2025-10-01', SRD: '2025-11-11', ETA_SRD: 41, TAG: '101780-CRIO-66051', },
      { RFQ: '1I-01', ETA: '2025-10-01', SRD: '2025-11-11', ETA_SRD: 41, TAG: '101780-CRIO-66052', },
      { RFQ: '1I-01', ETA: '2025-10-01', SRD: '2025-10-01', ETA_SRD: 0, TAG: '101780-CRIO-67000', },
      { RFQ: '1I-01', ETA: '2025-10-01', SRD: '2025-10-01', ETA_SRD: 0, TAG: '101780-CRIO-67001', },
      { RFQ: '1I-01', ETA: '2025-10-01', SRD: '2025-11-11', ETA_SRD: 41, TAG: '101780-CRIO-67050', },
      { RFQ: '1I-01', ETA: '2025-10-01', SRD: '2025-10-01', ETA_SRD: 0, TAG: '101780-CRIO-67051', },
      { RFQ: '1I-01', ETA: '2025-10-01', SRD: '2025-10-01', ETA_SRD: 0, TAG: '101780-CRIO-67052', },
      { RFQ: '1I-01', ETA: '2025-10-01', SRD: '2025-10-01', ETA_SRD: 0, TAG: '101780-CRIO-67053', },
      { RFQ: '1I-01', ETA: '2025-10-01', SRD: '2025-10-01', ETA_SRD: 0, TAG: '101780-CRIO-67054', },
      { RFQ: '1I-01', ETA: '2025-10-01', SRD: '2025-10-01', ETA_SRD: 0, TAG: '101780-CRIO-67055', },
      { RFQ: '1I-01', ETA: '2025-10-01', SRD: '2025-11-11', ETA_SRD: 41, TAG: '101780-CRIO-68301F', },
      { RFQ: '1I-01', ETA: '2025-10-01', SRD: '2025-11-11', ETA_SRD: 41, TAG: '101780-CRIO-68325F', },
      { RFQ: '1I-01', ETA: '2025-10-01', SRD: '2025-11-11', ETA_SRD: 41, TAG: '101780-FOPP-66000A', },
      { RFQ: '1I-01', ETA: '2025-10-01', SRD: '2025-11-11', ETA_SRD: 41, TAG: '101780-FOPP-66000B', },
      { RFQ: '1I-01', ETA: '2025-10-01', SRD: '2025-10-01', ETA_SRD: 0, TAG: '101780-FOPP-66025A', },
      { RFQ: '1I-01', ETA: '2025-10-01', SRD: '2025-10-01', ETA_SRD: 0, TAG: '101780-FOPP-66025B', },
      { RFQ: '1I-01', ETA: '2025-10-01', SRD: '2025-11-11', ETA_SRD: 41, TAG: '101780-FOPP-66050A', },
      { RFQ: '1I-01', ETA: '2025-10-01', SRD: '2025-11-11', ETA_SRD: 41, TAG: '101780-FOPP-66050B', },
      { RFQ: '1I-01', ETA: '2025-10-01', SRD: '2025-10-01', ETA_SRD: 0, TAG: '101780-FOPP-67000A', },
      { RFQ: '1I-01', ETA: '2025-10-01', SRD: '2025-10-01', ETA_SRD: 0, TAG: '101780-FOPP-67000B', },
      { RFQ: '1I-01', ETA: '2025-10-01', SRD: '2025-11-11', ETA_SRD: 41, TAG: '101780-FOPP-67050A', },
      { RFQ: '1I-01', ETA: '2025-10-01', SRD: '2025-10-01', ETA_SRD: 0, TAG: '101780-FOPP-67050B', },
      { RFQ: '1I-01', ETA: '2025-10-01', SRD: '2025-11-11', ETA_SRD: 41, TAG: '101780-OCON-68969', },
      { RFQ: '1I-01', ETA: '2025-10-01', SRD: '2025-10-01', ETA_SRD: 0, TAG: '101780-OCON-69960', },
      { RFQ: '1I-01', ETA: '2025-10-01', SRD: '2025-10-01', ETA_SRD: 0, TAG: '101780-OCON-69961', },
      { RFQ: '1I-01', ETA: '2025-10-01', SRD: '2025-10-01', ETA_SRD: 0, TAG: '101780-OCON-69962', },
      { RFQ: '1I-01', ETA: '2025-10-01', SRD: '2025-10-01', ETA_SRD: 0, TAG: '101790-SPNL-69700', },
      { RFQ: '1I-01', ETA: '2025-10-01', SRD: '2025-10-01', ETA_SRD: 0, TAG: '101790-SPNL-69750F', },
      { RFQ: '1I-01', ETA: '2025-10-01', SRD: '2025-10-01', ETA_SRD: 0, TAG: '101790-SPNL-69751F', },
      { RFQ: '1I-01', ETA: '2025-10-01', SRD: '2025-10-01', ETA_SRD: 0, TAG: '101790-SPNL-69979', },
      { RFQ: '1I-01', ETA: '2025-10-01', SRD: '2025-11-11', ETA_SRD: 41, TAG: '101790-SRIO-66100', },
      { RFQ: '1I-01', ETA: '2025-10-01', SRD: '2025-11-11', ETA_SRD: 41, TAG: '101790-SRIO-66101', },
      { RFQ: '1I-01', ETA: '2025-10-01', SRD: '2025-11-11', ETA_SRD: 41, TAG: '101790-SRIO-66150', },
      { RFQ: '1I-01', ETA: '2025-10-01', SRD: '2025-11-11', ETA_SRD: 41, TAG: '101790-SRIO-66151', },
      { RFQ: '1I-01', ETA: '2025-10-01', SRD: '2025-11-11', ETA_SRD: 41, TAG: '101790-SRIO-66152', },
      { RFQ: '1I-01', ETA: '2025-10-01', SRD: '2025-10-01', ETA_SRD: 0, TAG: '101790-SRIO-67100', },
      { RFQ: '1I-01', ETA: '2025-10-01', SRD: '2025-10-01', ETA_SRD: 0, TAG: '101790-SRIO-67101', },
      { RFQ: '1I-01', ETA: '2025-10-01', SRD: '2025-10-01', ETA_SRD: 0, TAG: '101790-SRIO-67150', },
      { RFQ: '1I-01', ETA: '2025-10-01', SRD: '2025-10-01', ETA_SRD: 0, TAG: '101790-SRIO-67151', },
      { RFQ: '1I-01', ETA: '2025-10-01', SRD: '2025-10-01', ETA_SRD: 0, TAG: '101790-SRIO-67152', },
      { RFQ: '1I-01', ETA: '2025-10-01', SRD: '2025-10-01', ETA_SRD: 0, TAG: '101790-SRIO-67153', },
      { RFQ: '1I-01', ETA: '2025-10-01', SRD: '2025-10-01', ETA_SRD: 0, TAG: '101790-SRIO-67154', },
      { RFQ: '1I-01', ETA: '2025-10-01', SRD: '2025-10-01', ETA_SRD: 0, TAG: '101790-SRIO-67155', },
      { RFQ: '1I-01', ETA: '2025-10-01', SRD: '2025-11-11', ETA_SRD: 41, TAG: '101790-SRIO-68400', },
      { RFQ: '1I-01', ETA: '2025-10-01', SRD: '2025-11-11', ETA_SRD: 41, TAG: '101790-SRIO-68425', },
      { RFQ: '1I-01', ETA: '2025-10-01', SRD: '2025-11-11', ETA_SRD: 41, TAG: '101790-SRIO-68450', },
      { RFQ: '1I-01', ETA: '2025-10-01', SRD: '2025-11-11', ETA_SRD: 41, TAG: '101790-SRIO-68451F', },
      { RFQ: '1I-01', ETA: '2025-10-01', SRD: '2025-10-01', ETA_SRD: 0, TAG: '101850-EDB-71110B', },
      { RFQ: '1I-01', ETA: '2025-10-01', SRD: '2025-10-01', ETA_SRD: 0, TAG: '101850-EDB-71113A', },
      { RFQ: '1I-01', ETA: '2025-10-01', SRD: '2025-10-01', ETA_SRD: 0, TAG: '101850-EDB-71113B', },
      { RFQ: '1I-01', ETA: '2025-10-01', SRD: '2025-11-11', ETA_SRD: 41, TAG: '101870-NPNL-68323A', },
      { RFQ: '1I-01', ETA: '2025-10-01', SRD: '2025-11-11', ETA_SRD: 41, TAG: '101870-NPNL-68323B', },
      { RFQ: '1I-01', ETA: '2025-10-01', SRD: '2025-11-11', ETA_SRD: 41, TAG: '101870-NPNL-68324', },
      { RFQ: '1I-01', ETA: '2025-10-01', SRD: '2025-11-11', ETA_SRD: 41, TAG: '101870-NPNL-68325', },
      { RFQ: '1I-01', ETA: '2025-10-01', SRD: '2025-10-01', ETA_SRD: 0, TAG: '101870-NPNL-69624A', },
      { RFQ: '1I-01', ETA: '2025-10-01', SRD: '2025-10-01', ETA_SRD: 0, TAG: '101870-NPNL-69624B', },
      { RFQ: '1I-01', ETA: '2025-10-01', SRD: '2025-10-01', ETA_SRD: 0, TAG: '101870-NPNL-69649A', },
      { RFQ: '1I-01', ETA: '2025-10-01', SRD: '2025-10-01', ETA_SRD: 0, TAG: '101870-NPNL-69649B', },
      { RFQ: '1I-01', ETA: '2025-10-01', SRD: '2025-10-01', ETA_SRD: 0, TAG: '101870-NPNL-69674A', },
      { RFQ: '1I-01', ETA: '2025-10-01', SRD: '2025-10-01', ETA_SRD: 0, TAG: '101870-NPNL-69674B', },
      { RFQ: '1I-01', ETA: '2025-10-01', SRD: '2025-10-01', ETA_SRD: 0, TAG: '101870-NPNL-69680B', },
      { RFQ: '1I-01', ETA: '2025-10-01', SRD: '2025-10-01', ETA_SRD: 0, TAG: '101870-NPNL-69970A', },
      { RFQ: '1I-01', ETA: '2025-10-01', SRD: '2025-10-01', ETA_SRD: 0, TAG: '101870-NPNL-69971A', },
      { RFQ: '1I-01', ETA: '2025-10-01', SRD: '2025-10-01', ETA_SRD: 0, TAG: '101870-NPNL-69971B', },
      { RFQ: '1I-02', ETA: '2025-06-16', SRD: '2025-07-19', ETA_SRD: 33, TAG: '101700-FACP-71000', },
      { RFQ: '1I-02', ETA: '2025-06-16', SRD: '2025-08-19', ETA_SRD: 64, TAG: '101700-FACP-97140', },
      { RFQ: '1I-03', ETA: '2025-08-23', SRD: '2025-10-01', ETA_SRD: 39, TAG: '101700-FD-22401', },
      { RFQ: '1I-03', ETA: '2025-08-23', SRD: '2025-10-24', ETA_SRD: 62, TAG: '101700-FD-31101', },
      { RFQ: '1I-03', ETA: '2025-08-23', SRD: '2025-11-11', ETA_SRD: 80, TAG: '101700-GD-12404', },
      { RFQ: '1I-03', ETA: '2025-08-23', SRD: '2025-08-30', ETA_SRD: 7, TAG: '101700-GD-411401', },
      { RFQ: '1I-03', ETA: '2025-08-23', SRD: '2025-10-14', ETA_SRD: 52, TAG: '101700-GD-95107', },
      { RFQ: '1I-03A', ETA: '2025-08-24', SRD: '2025-10-01', ETA_SRD: 38, TAG: '101700-MPNL-64452', },
      { RFQ: '1I-05', ETA: '2025-08-20', SRD: '2025-10-01', ETA_SRD: 42, TAG: '101180-ZS-10011', },
      { RFQ: '1I-05', ETA: '2025-08-20', SRD: '2025-11-11', ETA_SRD: 83, TAG: '101210-ZS-10341', },
      { RFQ: '1I-06', ETA: '2025-08-29', SRD: '2025-11-11', ETA_SRD: 74, TAG: '101200-QKE-10121', },
      { RFQ: '1I-08', ETA: '2025-07-15', SRD: '2025-10-01', ETA_SRD: 78, TAG: '101200-LT-10111', },
      { RFQ: '1I-08', ETA: '2025-07-15', SRD: '2025-11-11', ETA_SRD: 119, TAG: '101230-LT-12132', },
      { RFQ: '1I-08', ETA: '2025-07-15', SRD: '2025-10-24', ETA_SRD: 101, TAG: '101400-LT-40341', },
      { RFQ: '1I-12', ETA: '2025-10-29', SRD: '2025-10-29', ETA_SRD: 0, TAG: '101640-VUT-36131', },
      { RFQ: '1I-12', ETA: '2025-10-29', SRD: undefined,    ETA_SRD: 0, TAG: '101870-BPNL-67931', },
      { RFQ: '1I-12', ETA: '2025-10-29', SRD: undefined,    ETA_SRD: 0, TAG: '101870-BPNL-67941', },
      { RFQ: '1O-01', ETA: '2025-05-06', SRD: '2025-12-30', ETA_SRD: 238, TAG: '101530-SSW-59501', },
      { RFQ: '1O-01', ETA: '2025-05-06', SRD: '2026-06-30', ETA_SRD: 420, TAG: '101730-TLPB-00001', },
      { RFQ: '1O-01', ETA: '2025-05-06', SRD: '2026-06-30', ETA_SRD: 420, TAG: '101760-SBA-00005', },
      { RFQ: '1O-01', ETA: '2025-05-06', SRD: '2026-06-30', ETA_SRD: 420, TAG: '101760-SCB-00001', },
      { RFQ: '1O-01', ETA: '2025-05-06', SRD: '2026-06-30', ETA_SRD: 420, TAG: '101760-SCB-00021', },
      { RFQ: '1O-01', ETA: '2025-05-06', SRD: '2026-06-30', ETA_SRD: 420, TAG: '101760-SCB-00101', },
      { RFQ: '1O-01', ETA: '2025-05-06', SRD: '2026-06-30', ETA_SRD: 420, TAG: '101760-SCB-00201', },
      { RFQ: '1O-01', ETA: '2025-05-06', SRD: '2026-06-30', ETA_SRD: 420, TAG: '101760-SCB-00301', },
      { RFQ: '1O-01', ETA: '2025-05-06', SRD: '2026-06-30', ETA_SRD: 420, TAG: '101760-SCB-00401', },
      { RFQ: '1O-01', ETA: '2025-05-06', SRD: '2026-06-30', ETA_SRD: 420, TAG: '101760-SFAK-00001', },
      { RFQ: '1O-01', ETA: '2025-05-06', SRD: '2026-06-30', ETA_SRD: 420, TAG: '101760-SLBY-00001', },
      { RFQ: '1O-01', ETA: '2025-05-06', SRD: '2026-06-30', ETA_SRD: 420, TAG: '101760-SLR-54401', },
      { RFQ: '1O-03', ETA: '2025-07-27', SRD: '2025-08-06', ETA_SRD: 10, TAG: '101760-LDY-54110', },
      { RFQ: '1O-03', ETA: '2025-07-27', SRD: '2025-09-08', ETA_SRD: 43, TAG: '101760-LDY-54120', },
      { RFQ: '1O-03', ETA: '2025-06-29', SRD: '2025-08-06', ETA_SRD: 38, TAG: '101760-LDY-54310', },
      { RFQ: '1O-03', ETA: '2025-07-27', SRD: '2025-08-06', ETA_SRD: 10, TAG: '101760-SLB-54100', },
      { RFQ: '1O-03', ETA: '2025-07-27', SRD: '2025-09-08', ETA_SRD: 43, TAG: '101760-SLB-54200', },
      { RFQ: '1O-03', ETA: '2025-06-29', SRD: '2025-08-06', ETA_SRD: 38, TAG: '101760-YWC-54300', },
      { RFQ: '1O-07', ETA: '2025-06-15', SRD: '2025-07-29', ETA_SRD: 44, TAG: '101390-ZZZ-40270', },
      { RFQ: '1O-07', ETA: '2025-06-15', SRD: '2025-07-08', ETA_SRD: 23, TAG: '101390-ZZZ-40280', },
      { RFQ: '1P-01', ETA: '2025-10-30', SRD: '2025-11-17', ETA_SRD: 18, TAG: 'Central – Main/Above Main Deck ', },
      { RFQ: '1P-01', ETA: '2025-10-30', SRD: '2025-08-19', ETA_SRD: -72, TAG: 'Central – Production/Mezz Deck', },
      { RFQ: '1P-01', ETA: '2025-10-30', SRD: '2025-11-03', ETA_SRD: 4, TAG: 'North - Main/Above Main Deck ', },
      { RFQ: '1P-01', ETA: '2025-10-30', SRD: '2025-10-01', ETA_SRD: -29, TAG: 'North - Production/Mezz Deck', },
      { RFQ: '1P-01', ETA: '2025-10-30', SRD: '2025-09-04', ETA_SRD: -56, TAG: 'South - Production/Mezz Deck', },
      { RFQ: '1P-01', ETA: '2025-10-30', SRD: '2025-10-26', ETA_SRD: -4, TAG: 'South – Main/Above Main Deck', },
      { RFQ: '1P-02', ETA: '2025-10-20', SRD: '2025-10-20', ETA_SRD: 0, TAG: 'Central – Main/Above Main Deck ', },
      { RFQ: '1P-02', ETA: '2025-10-20', SRD: '2025-09-05', ETA_SRD: -45, TAG: 'Central – Production/Mezz Deck', },
      { RFQ: '1P-02', ETA: '2025-10-20', SRD: '2025-11-03', ETA_SRD: 14, TAG: 'North - Main/Above Main Deck ', },
      { RFQ: '1P-02', ETA: '2025-10-20', SRD: '2025-09-30', ETA_SRD: -20, TAG: 'North - Production/Mezz Deck', },
      { RFQ: '1P-02', ETA: '2025-10-20', SRD: '2025-09-04', ETA_SRD: -46, TAG: 'South - Production/Mezz Deck', },
      { RFQ: '1P-02', ETA: '2025-10-20', SRD: '2025-10-19', ETA_SRD: -1, TAG: 'South – Main/Above Main Deck', },
      { RFQ: '1P-03', ETA: '2025-10-13', SRD: '2025-07-08', ETA_SRD: -97, TAG: '101180-PV-10053', },
      { RFQ: '1P-03', ETA: '2025-10-13', SRD: '2025-11-17', ETA_SRD: 35, TAG: 'Central – Main/Above Main Deck ', },
      { RFQ: '1P-03', ETA: '2025-10-13', SRD: '2025-10-01', ETA_SRD: -12, TAG: 'Central – Production/Mezz Deck', },
      { RFQ: '1P-03', ETA: '2025-10-13', SRD: undefined,    ETA_SRD: 0, TAG: 'North - Main/Above Main Deck ', },
      { RFQ: '1P-03', ETA: '2025-10-13', SRD: '2025-10-01', ETA_SRD: -12, TAG: 'North - Production/Mezz Deck', },
      { RFQ: '1P-03', ETA: '2025-10-13', SRD: undefined,    ETA_SRD: 0, TAG: 'South - Production/Mezz Deck', },
      { RFQ: '1P-03', ETA: '2025-10-13', SRD: undefined,    ETA_SRD: 0, TAG: 'South – Main/Above Main Deck', },
      { RFQ: '1P-04', ETA: '2025-08-21', SRD: '2025-10-20', ETA_SRD: 60, TAG: 'Central – Main/Above Main Deck ', },
      { RFQ: '1P-04', ETA: '2025-08-21', SRD: '2025-08-19', ETA_SRD: -2, TAG: 'Central – Production/Mezz Deck', },
      { RFQ: '1P-04', ETA: '2025-08-21', SRD: '2025-11-03', ETA_SRD: 74, TAG: 'North - Main/Above Main Deck ', },
      { RFQ: '1P-04', ETA: '2025-08-21', SRD: '2025-10-01', ETA_SRD: 41, TAG: 'North - Production/Mezz Deck', },
      { RFQ: '1P-04', ETA: '2025-08-21', SRD: '2025-09-04', ETA_SRD: 14, TAG: 'South - Production/Mezz Deck', },
      { RFQ: '1P-04', ETA: '2025-08-21', SRD: '2025-10-26', ETA_SRD: 66, TAG: 'South – Main/Above Main Deck', },
      { RFQ: '1P-04A', ETA: '2025-10-01', SRD: '2025-11-03', ETA_SRD: 33, TAG: 'Central – Main/Above Main Deck ', },
      { RFQ: '1P-04A', ETA: '2025-10-01', SRD: undefined,    ETA_SRD: 0, TAG: 'Central – Production/Mezz Deck', },
      { RFQ: '1P-04A', ETA: '2025-10-01', SRD: undefined,    ETA_SRD: 0, TAG: 'North - Main/Above Main Deck ', },
      { RFQ: '1P-04A', ETA: '2025-10-01', SRD: undefined,    ETA_SRD: 0, TAG: 'North - Production/Mezz Deck', },
      { RFQ: '1P-04A', ETA: '2025-10-01', SRD: undefined,    ETA_SRD: 0, TAG: 'South - Production/Mezz Deck', },
      { RFQ: '1P-04A', ETA: '2025-10-01', SRD: undefined,    ETA_SRD: 0, TAG: 'South – Main/Above Main Deck', },
      { RFQ: '1P-04B', ETA: '2025-12-01', SRD: undefined,    ETA_SRD: 0, TAG: 'Central – Main/Above Main Deck ', },
      { RFQ: '1P-04B', ETA: '2025-12-01', SRD: '2025-09-09', ETA_SRD: -83, TAG: 'Central – Production/Mezz Deck', },
      { RFQ: '1P-04B', ETA: '2025-12-01', SRD: undefined,    ETA_SRD: 0, TAG: 'North - Main/Above Main Deck ', },
      { RFQ: '1P-04B', ETA: '2025-12-01', SRD: undefined,    ETA_SRD: 0, TAG: 'North - Production/Mezz Deck', },
      { RFQ: '1P-04B', ETA: '2025-12-01', SRD: undefined,    ETA_SRD: 0, TAG: 'South - Production/Mezz Deck', },
      { RFQ: '1P-04B', ETA: '2025-12-01', SRD: undefined,    ETA_SRD: 0, TAG: 'South – Main/Above Main Deck', },
      { RFQ: '1P-05', ETA: '2025-07-01', SRD: '2025-12-01', ETA_SRD: 153, TAG: '101450-PCV-14142', },
      { RFQ: '1P-05', ETA: '2025-07-01', SRD: undefined,    ETA_SRD: 0, TAG: 'Central – Main/Above Main Deck ', },
      { RFQ: '1P-05', ETA: '2025-07-01', SRD: undefined,    ETA_SRD: 0, TAG: 'Central – Production/Mezz Deck', },
      { RFQ: '1P-05', ETA: '2025-07-01', SRD: '2025-11-13', ETA_SRD: 135, TAG: 'North - Main/Above Main Deck ', },
      { RFQ: '1P-05', ETA: '2025-07-01', SRD: '2025-10-03', ETA_SRD: 94, TAG: 'North - Production/Mezz Deck', },
      { RFQ: '1P-05', ETA: '2025-07-01', SRD: '2025-09-04', ETA_SRD: 65, TAG: 'South - Production/Mezz Deck', },
      { RFQ: '1P-05', ETA: '2025-07-01', SRD: '2025-10-26', ETA_SRD: 117, TAG: 'South – Main/Above Main Deck', },
      { RFQ: '1P-06', ETA: '2025-08-25', SRD: undefined,    ETA_SRD: 0, TAG: '101180-BSDV-10052', },
      { RFQ: '1P-06', ETA: '2025-08-25', SRD: undefined,    ETA_SRD: 0, TAG: 'Central – Main/Above Main Deck ', },
      { RFQ: '1P-06', ETA: '2025-08-18', SRD: undefined,    ETA_SRD: 0, TAG: 'Central – Production/Mezz Deck', },
      { RFQ: '1P-06', ETA: '2025-08-25', SRD: undefined,    ETA_SRD: 0, TAG: 'North - Main/Above Main Deck ', },
      { RFQ: '1P-06', ETA: '2025-08-25', SRD: undefined,    ETA_SRD: 0, TAG: 'North - Production/Mezz Deck', },
      { RFQ: '1P-06', ETA: '2025-08-25', SRD: undefined,    ETA_SRD: 0, TAG: 'South - Production/Mezz Deck', },
      { RFQ: '1P-06', ETA: '2025-08-25', SRD: undefined,    ETA_SRD: 0, TAG: 'South – Main/Above Main Deck', },
      { RFQ: '1P-07', ETA: '2025-05-28', SRD: '2025-09-08', ETA_SRD: 103, TAG: '101650-ZZZ-52200', },
      { RFQ: '1P-12', ETA: '2025-12-23', SRD: '2025-09-04', ETA_SRD: -110, TAG: 'South - Production/Mezz Deck', },
      { RFQ: '1P-12', ETA: '2025-12-23', SRD: '2025-10-20', ETA_SRD: -64, TAG: 'South – Main/Above Main Deck', },
      { RFQ: '1P-12', ETA: '2026-05-09', SRD: '2025-09-04', ETA_SRD: -247, TAG: 'Central – Main/Above Main Deck ', },
      { RFQ: '1P-12', ETA: '2026-05-09', SRD: '2025-08-19', ETA_SRD: -263, TAG: 'Central – Production/Mezz Deck', },
      { RFQ: '1P-12', ETA: '2026-05-09', SRD: '2025-11-03', ETA_SRD: -187, TAG: 'North - Main/Above Main Deck ', },
      { RFQ: '1P-12', ETA: '2026-05-09', SRD: '2025-10-01', ETA_SRD: -220, TAG: 'North - Production/Mezz Deck', },
      { RFQ: '1P-13', ETA: '2025-09-01', SRD: '2026-10-28', ETA_SRD: 422, TAG: '101430-FE-14001', },
      { RFQ: '1P-13', ETA: '2025-09-01', SRD: '2025-10-16', ETA_SRD: 45, TAG: 'North - Main/Above Main Deck ', },
      { RFQ: '1P-15', ETA: '2025-07-02', SRD: '2025-12-01', ETA_SRD: 152, TAG: '101450-FE-14141', },
      { RFQ: '1P-15', ETA: '2025-07-02', SRD: undefined,    ETA_SRD: 0, TAG: 'Central – Main/Above Main Deck ', },
      { RFQ: '1P-15', ETA: '2025-07-02', SRD: undefined,    ETA_SRD: 0, TAG: 'Central – Production/Mezz Deck', },
      { RFQ: '1P-15', ETA: '2025-07-02', SRD: '2025-11-13', ETA_SRD: 134, TAG: 'North - Main/Above Main Deck ', },
      { RFQ: '1P-15', ETA: '2025-07-02', SRD: '2025-10-03', ETA_SRD: 93, TAG: 'North - Production/Mezz Deck', },
      { RFQ: '1P-15', ETA: '2025-07-02', SRD: undefined,    ETA_SRD: 0, TAG: 'South - Production/Mezz Deck', },
      { RFQ: '1P-15', ETA: '2025-07-02', SRD: '2025-10-26', ETA_SRD: 116, TAG: 'South – Main/Above Main Deck', },
      { RFQ: '1P-16', ETA: '2025-06-21', SRD: '2025-11-11', ETA_SRD: 143, TAG: '101210-FE-10361', },
      { RFQ: '1P-16', ETA: '2025-06-21', SRD: undefined,    ETA_SRD: 0, TAG: 'Central – Main/Above Main Deck ', },
      { RFQ: '1P-16', ETA: '2025-06-21', SRD: '2025-10-15', ETA_SRD: 116, TAG: 'Central – Production/Mezz Deck', },
      { RFQ: '1P-16', ETA: '2025-06-21', SRD: undefined,    ETA_SRD: 0, TAG: 'North - Main/Above Main Deck ', },
      { RFQ: '1P-16', ETA: '2025-06-21', SRD: '2025-11-11', ETA_SRD: 143, TAG: 'North - Production/Mezz Deck', },
      { RFQ: '1P-16', ETA: '2025-06-21', SRD: undefined,    ETA_SRD: 0, TAG: 'South - Production/Mezz Deck', },
      { RFQ: '1P-16', ETA: '2025-06-21', SRD: undefined,    ETA_SRD: 0, TAG: 'South – Main/Above Main Deck', },
      { RFQ: '1P-17', ETA: '2025-06-21', SRD: '2025-10-01', ETA_SRD: 102, TAG: '101440-FE-14812', },
      { RFQ: '1P-17', ETA: '2025-06-21', SRD: '2025-10-20', ETA_SRD: 121, TAG: 'Central – Main/Above Main Deck ', },
      { RFQ: '1P-17', ETA: '2025-06-21', SRD: '2025-09-04', ETA_SRD: 75, TAG: 'Central – Production/Mezz Deck', },
      { RFQ: '1P-17', ETA: '2025-06-21', SRD: undefined,    ETA_SRD: 0, TAG: 'North - Main/Above Main Deck ', },
      { RFQ: '1P-17', ETA: '2025-06-21', SRD: undefined,    ETA_SRD: 0, TAG: 'North - Production/Mezz Deck', },
      { RFQ: '1P-17', ETA: '2025-06-21', SRD: '2025-09-04', ETA_SRD: 75, TAG: 'South - Production/Mezz Deck', },
      { RFQ: '1P-17', ETA: '2025-06-21', SRD: undefined,    ETA_SRD: 0, TAG: 'South – Main/Above Main Deck', },
      { RFQ: '1P-22', ETA: '2025-06-06', SRD: '2025-07-08', ETA_SRD: 32, TAG: '101180-KPLA-10010', },
      { RFQ: '1P-22', ETA: '2025-06-06', SRD: '2025-07-08', ETA_SRD: 32, TAG: '101180-KPLA-10020', },
      { RFQ: '1P-22', ETA: '2025-06-06', SRD: '2025-09-08', ETA_SRD: 94, TAG: '101210-KPLA-10340', },
      { RFQ: '1P-22', ETA: '2025-06-06', SRD: '2025-09-08', ETA_SRD: 94, TAG: '101270-KPLA-13210', },
      { RFQ: '1P-23', ETA: '2026-02-22', SRD: '2025-08-28', ETA_SRD: -178, TAG: 'Central – Main/Above Main Deck ', },
      { RFQ: '1P-23', ETA: '2026-02-22', SRD: '2025-05-30', ETA_SRD: -268, TAG: 'Central – Production/Mezz Deck', },
      { RFQ: '1P-23', ETA: '2026-02-22', SRD: undefined,    ETA_SRD: 0, TAG: 'K2WX', },
      { RFQ: '1P-23', ETA: '2026-02-22', SRD: '2025-10-16', ETA_SRD: -129, TAG: 'North - Main/Above Main Deck ', },
      { RFQ: '1P-23', ETA: '2026-02-22', SRD: '2025-08-04', ETA_SRD: -202, TAG: 'North - Production/Mezz Deck', },
      { RFQ: '1P-23', ETA: '2026-02-22', SRD: undefined,    ETA_SRD: 0, TAG: 'South - Production/Mezz Deck', },
      { RFQ: '1P-23', ETA: '2026-02-22', SRD: undefined,    ETA_SRD: 0, TAG: 'South – Main/Above Main Deck', },
      { RFQ: '1P-24', ETA: '2025-07-01', SRD: undefined,    ETA_SRD: 0, TAG: 'A2RF-L', },
      { RFQ: '1P-24', ETA: '2025-07-01', SRD: '2025-08-20', ETA_SRD: 50, TAG: 'Central – Main/Above Main Deck ', },
      { RFQ: '1P-24', ETA: '2025-07-01', SRD: '2025-05-30', ETA_SRD: -32, TAG: 'Central – Production/Mezz Deck', },
      { RFQ: '1P-24', ETA: '2025-07-01', SRD: '2025-10-16', ETA_SRD: 107, TAG: 'North - Main/Above Main Deck ', },
      { RFQ: '1P-24', ETA: '2025-07-01', SRD: '2025-08-04', ETA_SRD: 34, TAG: 'North - Production/Mezz Deck', },
      { RFQ: '1P-24', ETA: '2025-07-01', SRD: '2025-06-17', ETA_SRD: -14, TAG: 'South - Production/Mezz Deck', },
      { RFQ: '1P-24', ETA: '2025-07-01', SRD: '2025-09-30', ETA_SRD: 91, TAG: 'South – Main/Above Main Deck', },
      { RFQ: '1P-25', ETA: '2025-06-01', SRD: undefined,    ETA_SRD: 0, TAG: 'A7F-B', },
      { RFQ: '1P-25', ETA: '2025-06-01', SRD: '2025-09-04', ETA_SRD: 95, TAG: 'Central – Main/Above Main Deck ', },
      { RFQ: '1P-25', ETA: '2025-06-01', SRD: '2025-05-30', ETA_SRD: -2, TAG: 'Central – Production/Mezz Deck', },
      { RFQ: '1P-25', ETA: '2025-06-01', SRD: '2025-10-16', ETA_SRD: 137, TAG: 'North - Main/Above Main Deck ', },
      { RFQ: '1P-25', ETA: '2025-06-01', SRD: '2025-08-04', ETA_SRD: 64, TAG: 'North - Production/Mezz Deck', },
      { RFQ: '1P-25', ETA: '2025-06-01', SRD: '2025-06-17', ETA_SRD: 16, TAG: 'South - Production/Mezz Deck', },
      { RFQ: '1P-25', ETA: '2025-06-01', SRD: '2025-09-30', ETA_SRD: 121, TAG: 'South – Main/Above Main Deck', },
      { RFQ: '1P-26', ETA: '2025-06-01', SRD: undefined,    ETA_SRD: 0, TAG: 'A2R-S', },
      { RFQ: '1P-26', ETA: '2025-06-01', SRD: '2025-08-20', ETA_SRD: 80, TAG: 'Central – Main/Above Main Deck ', },
      { RFQ: '1P-26', ETA: '2025-06-01', SRD: '2025-05-30', ETA_SRD: -2, TAG: 'Central – Production/Mezz Deck', },
      { RFQ: '1P-26', ETA: '2025-06-01', SRD: '2025-10-16', ETA_SRD: 137, TAG: 'North - Main/Above Main Deck ', },
      { RFQ: '1P-26', ETA: '2025-06-01', SRD: '2025-08-04', ETA_SRD: 64, TAG: 'North - Production/Mezz Deck', },
      { RFQ: '1P-26', ETA: '2025-06-01', SRD: '2025-06-17', ETA_SRD: 16, TAG: 'South - Production/Mezz Deck', },
      { RFQ: '1P-26', ETA: '2025-06-01', SRD: '2025-09-18', ETA_SRD: 109, TAG: 'South – Main/Above Main Deck', },
      { RFQ: '1P-27', ETA: '2025-07-30', SRD: undefined,    ETA_SRD: 0, TAG: 'B5R', },
      { RFQ: '1P-27', ETA: '2025-07-30', SRD: '2025-10-20', ETA_SRD: 82, TAG: 'Central – Main/Above Main Deck ', },
      { RFQ: '1P-27', ETA: '2025-07-30', SRD: '2025-08-19', ETA_SRD: 20, TAG: 'Central – Production/Mezz Deck', },
      { RFQ: '1P-27', ETA: '2025-07-30', SRD: '2025-11-03', ETA_SRD: 96, TAG: 'North - Main/Above Main Deck ', },
      { RFQ: '1P-27', ETA: '2025-07-30', SRD: '2025-10-01', ETA_SRD: 63, TAG: 'North - Production/Mezz Deck', },
      { RFQ: '1P-27', ETA: '2025-07-30', SRD: '2025-09-04', ETA_SRD: 36, TAG: 'South - Production/Mezz Deck', },
      { RFQ: '1P-27', ETA: '2025-07-30', SRD: '2025-10-19', ETA_SRD: 81, TAG: 'South – Main/Above Main Deck', },
      { RFQ: '1P-28', ETA: '2025-06-01', SRD: undefined,    ETA_SRD: 0, TAG: 'A4R', },
      { RFQ: '1P-28', ETA: '2025-06-01', SRD: '2025-10-20', ETA_SRD: 141, TAG: 'Central – Main/Above Main Deck ', },
      { RFQ: '1P-28', ETA: '2025-06-01', SRD: '2025-08-19', ETA_SRD: 79, TAG: 'Central – Production/Mezz Deck', },
      { RFQ: '1P-28', ETA: '2025-06-01', SRD: '2025-11-03', ETA_SRD: 155, TAG: 'North - Main/Above Main Deck ', },
      { RFQ: '1P-28', ETA: '2025-06-01', SRD: '2025-10-01', ETA_SRD: 122, TAG: 'North - Production/Mezz Deck', },
      { RFQ: '1P-28', ETA: '2025-06-01', SRD: '2025-09-04', ETA_SRD: 95, TAG: 'South - Production/Mezz Deck', },
      { RFQ: '1P-28', ETA: '2025-06-01', SRD: '2025-10-26', ETA_SRD: 147, TAG: 'South – Main/Above Main Deck', },
      { RFQ: '1P-29', ETA: '2026-01-12', SRD: '2025-11-17', ETA_SRD: -56, TAG: 'Central – Main/Above Main Deck ', },
      { RFQ: '1P-29', ETA: '2026-01-12', SRD: '2025-09-09', ETA_SRD: -125, TAG: 'Central – Production/Mezz Deck', },
      { RFQ: '1P-29', ETA: '2026-01-12', SRD: undefined,    ETA_SRD: 0, TAG: 'K9JOS', },
      { RFQ: '1P-29', ETA: '2026-01-12', SRD: '2025-11-14', ETA_SRD: -59, TAG: 'North - Main/Above Main Deck ', },
      { RFQ: '1P-29', ETA: '2026-01-12', SRD: '2025-10-01', ETA_SRD: -103, TAG: 'North - Production/Mezz Deck', },
      { RFQ: '1P-29', ETA: '2026-01-12', SRD: '2025-09-04', ETA_SRD: -130, TAG: 'South - Production/Mezz Deck', },
      { RFQ: '1P-29', ETA: '2026-01-12', SRD: '2025-10-26', ETA_SRD: -78, TAG: 'South – Main/Above Main Deck', },
      { RFQ: '1P-29A', ETA: '2025-06-10', SRD: '2025-10-20', ETA_SRD: 132, TAG: 'Central - Main/Above Main Deck ', },
      { RFQ: '1P-29A', ETA: '2025-06-10', SRD: '2025-08-19', ETA_SRD: 70, TAG: 'Central - Production/Mezz Deck', },
      { RFQ: '1P-29A', ETA: '2025-06-10', SRD: '2025-11-03', ETA_SRD: 146, TAG: 'North - Main/Above Main Deck ', },
      { RFQ: '1P-29A', ETA: '2025-06-10', SRD: '2025-09-30', ETA_SRD: 112, TAG: 'North - Production/Mezz Deck', },
      { RFQ: '1P-29A', ETA: '2025-06-10', SRD: '2025-10-26', ETA_SRD: 138, TAG: 'South - Main/Above Main Deck', },
      { RFQ: '1P-29A', ETA: '2025-06-10', SRD: '2025-09-04', ETA_SRD: 86, TAG: 'South - Production/Mezz Deck', },
      { RFQ: '1P-30', ETA: '2025-05-01', SRD: '2025-08-28', ETA_SRD: 119, TAG: 'Central – Main/Above Main Deck ', },
      { RFQ: '1P-30', ETA: '2025-05-01', SRD: '2025-06-09', ETA_SRD: 39, TAG: 'Central – Production/Mezz Deck', },
      { RFQ: '1P-30', ETA: '2025-05-01', SRD: undefined,    ETA_SRD: 0, TAG: 'G6S', },
      { RFQ: '1P-30', ETA: '2025-05-01', SRD: '2025-10-16', ETA_SRD: 168, TAG: 'North - Main/Above Main Deck ', },
      { RFQ: '1P-30', ETA: '2025-05-01', SRD: '2025-08-04', ETA_SRD: 95, TAG: 'North - Production/Mezz Deck', },
      { RFQ: '1P-30', ETA: '2025-05-01', SRD: '2025-06-17', ETA_SRD: 47, TAG: 'South - Production/Mezz Deck', },
      { RFQ: '1P-30', ETA: '2025-05-01', SRD: '2025-09-30', ETA_SRD: 152, TAG: 'South – Main/Above Main Deck', },
      { RFQ: '1P-31', ETA: '2025-08-15', SRD: '2025-11-17', ETA_SRD: 94, TAG: 'Central – Main/Above Main Deck ', },
      { RFQ: '1P-31', ETA: '2025-08-15', SRD: '2025-08-19', ETA_SRD: 4, TAG: 'Central – Production/Mezz Deck', },
      { RFQ: '1P-31', ETA: '2025-08-15', SRD: '2025-11-03', ETA_SRD: 80, TAG: 'North - Main/Above Main Deck ', },
      { RFQ: '1P-31', ETA: '2025-08-15', SRD: '2025-10-01', ETA_SRD: 47, TAG: 'North - Production/Mezz Deck', },
      { RFQ: '1P-31', ETA: '2025-08-15', SRD: undefined,    ETA_SRD: 0, TAG: 'SP-80030', },
      { RFQ: '1P-31', ETA: '2025-08-15', SRD: '2025-09-04', ETA_SRD: 20, TAG: 'South - Production/Mezz Deck', },
      { RFQ: '1P-31', ETA: '2025-08-15', SRD: undefined,    ETA_SRD: 0, TAG: 'South – Main/Above Main Deck', },
      { RFQ: '1P-39', ETA: '2025-06-23', SRD: undefined,    ETA_SRD: 0, TAG: 'Central – Main/Above Main Deck ', },
      { RFQ: '1P-39', ETA: '2025-06-23', SRD: '2025-03-28', ETA_SRD: -87, TAG: 'Central – Production/Mezz Deck', },
      { RFQ: '1P-39', ETA: '2025-06-23', SRD: undefined,    ETA_SRD: 0, TAG: 'North - Main/Above Main Deck ', },
      { RFQ: '1P-39', ETA: '2025-06-23', SRD: '2025-05-26', ETA_SRD: -28, TAG: 'North - Production/Mezz Deck', },
      { RFQ: '1P-39', ETA: '2025-06-23', SRD: undefined,    ETA_SRD: 0, TAG: 'SP-20000A', },
      { RFQ: '1P-39', ETA: '2025-06-23', SRD: '2025-05-01', ETA_SRD: -53, TAG: 'South - Production/Mezz Deck', },
      { RFQ: '1P-39', ETA: '2025-06-23', SRD: undefined,    ETA_SRD: 0, TAG: 'South – Main/Above Main Deck', },
      { RFQ: '1Q-02', ETA: '2025-11-22', SRD: '2025-11-17', ETA_SRD: -5, TAG: '101230-ZZZ-13300', },
      { RFQ: '1Q-06', ETA: '2025-07-07', SRD: '2025-07-29', ETA_SRD: 22, TAG: '101630-ZZZ-50500', },
      { RFQ: '1Q-06', ETA: '2025-07-07', SRD: '2025-07-29', ETA_SRD: 22, TAG: '101630-ZZZ-50600', },
      { RFQ: '1Q-06', ETA: '2025-07-07', SRD: '2025-08-06', ETA_SRD: 30, TAG: '101640-ZZZ-51000', },
      { RFQ: '1Q-15', ETA: '2025-10-12', SRD: '2025-08-15', ETA_SRD: -58, TAG: '101840-BPNL-60500', },
      { RFQ: '1Q-15', ETA: '2025-10-12', SRD: '2025-08-15', ETA_SRD: -58, TAG: '101840-EBM-60500C', },
      { RFQ: '1Q-15', ETA: '2025-10-12', SRD: '2025-08-15', ETA_SRD: -58, TAG: '101840-EPNL-60500', },
      { RFQ: '1Q-15', ETA: '2025-10-12', SRD: '2025-08-15', ETA_SRD: -58, TAG: '101840-FPNL-60500', },
      { RFQ: '1Q-15', ETA: '2025-10-12', SRD: '2025-08-15', ETA_SRD: -58, TAG: '101840-ZZZ-60500', },
      { RFQ: '1Q-15', ETA: '2025-10-12', SRD: '2025-08-15', ETA_SRD: -58, TAG: '101840-ZZZ-61530', },
      { RFQ: '1Q-15', ETA: '2025-10-16', SRD: '2025-08-06', ETA_SRD: -71, TAG: '101800-ZZZ-60400', },
      { RFQ: '1Q-15', ETA: '2025-10-16', SRD: '2025-08-06', ETA_SRD: -71, TAG: '101800-ZZZ-61430', },
      { RFQ: '1Q-21', ETA: '2025-10-05', SRD: '2025-10-17', ETA_SRD: 12, TAG: '101710-PBE-93950', },
      { RFQ: '1Q-21', ETA: '2025-10-05', SRD: '2025-10-06', ETA_SRD: 1, TAG: '101710-PBE-94950', },
      { RFQ: '1Q-21', ETA: '2025-10-05', SRD: '2025-08-15', ETA_SRD: -51, TAG: '101710-ZZZ-53100', },
      { RFQ: '1Q-21', ETA: '2025-10-05', SRD: '2025-08-15', ETA_SRD: -51, TAG: '101710-ZZZ-57100', },
      { RFQ: '1Q-22', ETA: '2026-01-03', SRD: '2025-10-29', ETA_SRD: -66, TAG: '101290-EPNL-20800', },
      { RFQ: '1Q-22', ETA: '2026-01-03', SRD: '2025-10-15', ETA_SRD: -80, TAG: '101290-ZZZ-20800', },
      { RFQ: '1Q-22', ETA: '2026-01-03', SRD: '2025-10-15', ETA_SRD: -80, TAG: '101290-ZZZ-20811', },
      { RFQ: '1Q-24', ETA: '2025-10-05', SRD: '2025-10-06', ETA_SRD: 1, TAG: '101290-PBE-94610', },
      { RFQ: '1Q-24', ETA: '2025-10-05', SRD: '2025-10-06', ETA_SRD: 1, TAG: '101290-PBE-94620', },
      { RFQ: '1Q-24', ETA: '2025-10-05', SRD: '2025-10-17', ETA_SRD: 12, TAG: '101500-PBE-93650', },
      { RFQ: '1Q-24', ETA: '2025-10-05', SRD: '2025-10-17', ETA_SRD: 12, TAG: '101500-PBE-93660', },
      { RFQ: '1Q-24', ETA: '2025-10-05', SRD: '2025-10-17', ETA_SRD: 12, TAG: '101500-PBE-93670', },
      { RFQ: '1Q-27', ETA: '2025-07-09', SRD: '2025-07-08', ETA_SRD: -1, TAG: '101210-PBA-10240', },
      { RFQ: '1Q-27', ETA: '2025-07-09', SRD: '2025-07-08', ETA_SRD: -1, TAG: '101210-PBA-10250', },
      { RFQ: '1Q-27', ETA: '2025-07-09', SRD: '2025-07-08', ETA_SRD: -1, TAG: '101210-PBA-10260', },
      { RFQ: '1Q-27', ETA: '2025-07-09', SRD: '2025-09-08', ETA_SRD: 61, TAG: '101210-PBA-10310', },
      { RFQ: '1Q-27', ETA: '2025-07-09', SRD: '2025-09-08', ETA_SRD: 61, TAG: '101210-PBA-10320', },
      { RFQ: '1Q-27', ETA: '2025-07-09', SRD: '2025-09-08', ETA_SRD: 61, TAG: '101210-PBA-10330', },
      { RFQ: '1Q-27', ETA: '2025-07-09', SRD: '2025-08-19', ETA_SRD: 41, TAG: '101210-PBA-10410', },
      { RFQ: '1Q-27', ETA: '2025-07-09', SRD: '2025-08-19', ETA_SRD: 41, TAG: '101210-PBA-10420', },
      { RFQ: '1Q-27', ETA: '2025-07-09', SRD: '2025-09-08', ETA_SRD: 61, TAG: '101210-ZZZ-10310', },
      { RFQ: '1Q-27', ETA: '2025-07-09', SRD: '2025-09-08', ETA_SRD: 61, TAG: '101210-ZZZ-10320', },
      { RFQ: '1Q-27', ETA: '2025-07-09', SRD: '2025-09-08', ETA_SRD: 61, TAG: '101210-ZZZ-10330', },
      { RFQ: '1Q-31', ETA: '2025-07-18', SRD: '2025-09-15', ETA_SRD: 59, TAG: '101230-PBA-12010', },
      { RFQ: '1Q-31', ETA: '2025-07-18', SRD: '2025-09-15', ETA_SRD: 59, TAG: '101230-PBA-12020', },
      { RFQ: '1Q-31', ETA: '2025-07-18', SRD: '2025-08-15', ETA_SRD: 28, TAG: '101400-PBE-40310', },
      { RFQ: '1Q-31', ETA: '2025-07-18', SRD: '2025-08-15', ETA_SRD: 28, TAG: '101400-PBE-40320', },
      { RFQ: '1Q-31', ETA: '2025-07-18', SRD: '2025-08-15', ETA_SRD: 28, TAG: '101400-PBE-40330', },
      { RFQ: '1Q-31', ETA: '2025-07-18', SRD: '2025-08-15', ETA_SRD: 28, TAG: '101400-PBE-40380', },
      { RFQ: '1Q-31', ETA: '2025-07-18', SRD: '2025-08-15', ETA_SRD: 28, TAG: '101410-PBE-40410', },
      { RFQ: '1Q-31', ETA: '2025-07-18', SRD: '2025-08-15', ETA_SRD: 28, TAG: '101410-PBE-40420', },
      { RFQ: '1Q-31', ETA: '2025-07-18', SRD: '2025-08-15', ETA_SRD: 28, TAG: '101530-PBE-50150', },
      { RFQ: '1Q-31', ETA: '2025-07-18', SRD: '2025-08-15', ETA_SRD: 28, TAG: '101530-PBE-50160', },
      { RFQ: '1Q-31', ETA: '2025-09-02', SRD: '2025-07-29', ETA_SRD: -35, TAG: '101440-PBA-14810', },
      { RFQ: '1Q-31', ETA: '2025-09-02', SRD: '2025-07-29', ETA_SRD: -35, TAG: '101440-PBA-14820', },
      { RFQ: '1Q-31', ETA: '2025-09-02', SRD: '2025-07-29', ETA_SRD: -35, TAG: '101440-PBA-14830', },
      { RFQ: '1Q-32', ETA: '2025-04-01', SRD: '2025-07-08', ETA_SRD: 98, TAG: '101200-PBA-10270', },
      { RFQ: '1Q-32', ETA: '2025-04-01', SRD: '2025-07-08', ETA_SRD: 98, TAG: '101200-PBA-10280', },
      { RFQ: '1Q-32', ETA: '2025-04-01', SRD: '2025-07-08', ETA_SRD: 98, TAG: '101200-PBA-10290', },
      { RFQ: '1Q-32', ETA: '2025-04-01', SRD: '2025-07-08', ETA_SRD: 98, TAG: '101200-PBA-10300', },
      { RFQ: '1Q-49', ETA: '2025-03-19', SRD: '2025-08-15', ETA_SRD: 149, TAG: '101400-MAJ-40350', },
      { RFQ: '1Q-49', ETA: '2025-03-19', SRD: '2025-12-01', ETA_SRD: 257, TAG: '101410-MAJ-40440', },
      { RFQ: '1Q-49', ETA: '2025-03-19', SRD: '2025-08-06', ETA_SRD: 140, TAG: '101530-MAJ-50110', },
      { RFQ: '1Q-49', ETA: '2025-03-19', SRD: '2025-07-29', ETA_SRD: 132, TAG: '101620-MAJ-50440', },
      { RFQ: '1Q-49', ETA: '2025-03-19', SRD: '2025-07-29', ETA_SRD: 132, TAG: '101620-MAJ-50450', },
      { RFQ: '1Q-4A', ETA: '2025-07-29', SRD: '2025-08-15', ETA_SRD: 17, TAG: '101500-MAJ-40630', },
      { RFQ: '1Q-4A', ETA: '2025-07-29', SRD: '2025-08-15', ETA_SRD: 17, TAG: '101500-MAJ-40640', },
      { RFQ: '1Q-51', ETA: '2025-08-22', SRD: '2025-10-01', ETA_SRD: 40, TAG: '101250-MAF-13000', },
      { RFQ: '1Q-51', ETA: '2025-08-22', SRD: '2025-09-08', ETA_SRD: 17, TAG: '101250-MAK-12630', },
      { RFQ: '1Q-51', ETA: '2025-08-22', SRD: '2025-09-08', ETA_SRD: 17, TAG: '101250-MAK-12640', },
      { RFQ: '1Q-51', ETA: '2025-11-16', SRD: '2025-10-01', ETA_SRD: -46, TAG: '101380-ZZZ-40000', },
      { RFQ: '1Q-52', ETA: '2025-07-16', SRD: '2025-08-15', ETA_SRD: 30, TAG: '101290-MAJ-21010', },
      { RFQ: '1Q-52', ETA: '2025-07-16', SRD: '2025-08-15', ETA_SRD: 30, TAG: '101290-MAJ-21020', },
      { RFQ: '1Q-52', ETA: '2025-08-15', SRD: '2025-10-15', ETA_SRD: 61, TAG: '101290-ABJ-20130', },
      { RFQ: '1Q-52', ETA: '2025-08-15', SRD: '2025-10-28', ETA_SRD: 74, TAG: '101290-EPNL-20600', },
      { RFQ: '1Q-52', ETA: '2025-08-15', SRD: '2025-10-15', ETA_SRD: 61, TAG: '101290-MBM-20650', },
      { RFQ: '1Q-52', ETA: '2025-08-15', SRD: '2025-10-15', ETA_SRD: 61, TAG: '101290-ZZZ-21000', },
      { RFQ: '1Q-52', ETA: '2025-08-21', SRD: '2025-07-29', ETA_SRD: -23, TAG: '101290-PAT-20710', },
      { RFQ: '1Q-52', ETA: '2025-08-21', SRD: '2025-07-29', ETA_SRD: -23, TAG: '101290-PAT-20720', },
      { RFQ: '1Q-52', ETA: '2025-08-21', SRD: '2025-07-29', ETA_SRD: -23, TAG: '101290-PBE-20310', },
      { RFQ: '1Q-52', ETA: '2025-08-21', SRD: '2025-07-29', ETA_SRD: -23, TAG: '101290-PBE-20410', },
      { RFQ: '1Q-52', ETA: '2025-09-12', SRD: '2025-10-28', ETA_SRD: 46, TAG: '101290-EPNL-20200', },
      { RFQ: '1Q-52', ETA: '2025-09-12', SRD: '2025-10-15', ETA_SRD: 33, TAG: '101290-PPNL-20211', },
      { RFQ: '1Q-52', ETA: '2025-09-19', SRD: '2025-10-15', ETA_SRD: 26, TAG: '101290-ZZZ-20100', },
      { RFQ: '1Q-52', ETA: '2025-10-20', SRD: '2025-10-15', ETA_SRD: -5, TAG: '101290-ZZZ-20600', },
      { RFQ: '1Q-52', ETA: '2025-10-24', SRD: '2025-07-29', ETA_SRD: -87, TAG: '101420-ZZZ-35000', },
      { RFQ: '1Q-52', ETA: '2025-10-24', SRD: '2025-07-29', ETA_SRD: -87, TAG: '101420-ZZZ-37000', },
      { RFQ: '1Q-52', ETA: '2025-11-02', SRD: '2025-10-15', ETA_SRD: -18, TAG: '101290-ZZZ-20200', },
      { RFQ: '1Q-52', ETA: '2025-11-02', SRD: '2025-10-15', ETA_SRD: -18, TAG: '101290-ZZZ-20500', },
      { RFQ: '1Q-53', ETA: '2025-12-07', SRD: '2025-09-15', ETA_SRD: -83, TAG: '101430-EAH-14110', },
      { RFQ: '1Q-53', ETA: '2025-12-07', SRD: '2025-09-15', ETA_SRD: -83, TAG: '101450-ZZZ-40500', },
      { RFQ: '1Q-54', ETA: '2025-06-07', SRD: '2025-07-29', ETA_SRD: 52, TAG: '101440-MBM-14610', },
      { RFQ: '1Q-54', ETA: '2025-06-07', SRD: '2025-07-29', ETA_SRD: 52, TAG: '101440-MBM-14620', },
      { RFQ: '1Q-54', ETA: '2025-06-07', SRD: '2025-07-29', ETA_SRD: 52, TAG: '101440-MBM-14640', },
      { RFQ: '1Q-54', ETA: '2025-08-28', SRD: '2025-09-15', ETA_SRD: 18, TAG: '101440-ZZZ-14700', },
      { RFQ: '1Q-55', ETA: '2025-09-30', SRD: '2025-09-15', ETA_SRD: -15, TAG: '101420-ZZZ-30000', },
      { RFQ: '1Q-55', ETA: '2025-09-30', SRD: '2025-09-15', ETA_SRD: -15, TAG: '101420-ZZZ-33000', },
      { RFQ: '1Q-55', ETA: '2025-09-30', SRD: '2025-07-29', ETA_SRD: -63, TAG: '101460-MAJ-36110', },
      { RFQ: '1Q-55', ETA: '2025-09-30', SRD: '2025-07-29', ETA_SRD: -63, TAG: '101460-ZZZ-36000', },
      { RFQ: '1Q-55', ETA: '2025-09-30', SRD: '2025-07-29', ETA_SRD: -63, TAG: '101460-ZZZ-38000', },
      { RFQ: '1Q-55', ETA: '2025-09-30', SRD: '2025-10-28', ETA_SRD: 28, TAG: '101960-ESVD-68920', },
      { RFQ: '1Q-55', ETA: '2025-09-30', SRD: '2025-10-28', ETA_SRD: 28, TAG: '101960-ESVD-68921', },
      { RFQ: '1Q-56', ETA: '2025-10-13', SRD: '2025-07-29', ETA_SRD: -76, TAG: '101620-ZZZ-50400', },
      { RFQ: '1Q-57', ETA: '2025-11-08', SRD: '2025-10-06', ETA_SRD: -33, TAG: '101570-ZZZ-40800', },
      { RFQ: '1Q-5C', ETA: '2025-06-25', SRD: '2025-08-30', ETA_SRD: 66, TAG: '101270-LPNL-64376', },
      { RFQ: '1Q-5C', ETA: '2025-06-25', SRD: '2025-10-01', ETA_SRD: 98, TAG: '101270-ZZZ-13100', },
      { RFQ: '1Q-5C', ETA: '2025-11-29', SRD: '2025-10-01', ETA_SRD: -59, TAG: '101270-ZZZ-13200', },
      { RFQ: '1Q-5E', ETA: '2025-07-12', SRD: '2025-09-04', ETA_SRD: 54, TAG: '101200-VXFMR-10210', },
      { RFQ: '1Q-5E', ETA: '2025-07-12', SRD: '2025-09-04', ETA_SRD: 54, TAG: '101200-VXFMR-10220', },
      { RFQ: '1Q-5E', ETA: '2025-07-07', SRD: '2025-10-01', ETA_SRD: 86, TAG: '101200-MAM-10190', },
      { RFQ: '1Q-5E', ETA: '2025-07-07', SRD: '2025-07-08', ETA_SRD: 1, TAG: '101200-NBK-10200', },
      { RFQ: '1Q-63', ETA: '2025-09-24', SRD: '2025-12-01', ETA_SRD: 68, TAG: '101590-EBN-40910', },
      { RFQ: '1Q-63', ETA: '2025-09-24', SRD: '2025-12-01', ETA_SRD: 68, TAG: '101590-EBN-40920', },
      { RFQ: '1Q-63', ETA: '2025-09-24', SRD: '2025-12-01', ETA_SRD: 68, TAG: '101590-EBN-40930', },
      { RFQ: '1Q-63', ETA: '2025-09-24', SRD: '2025-12-01', ETA_SRD: 68, TAG: '101590-TZF-40940', },
      { RFQ: '1Q-63', ETA: '2025-09-24', SRD: '2025-12-01', ETA_SRD: 68, TAG: '101590-TZF-40950', },
      { RFQ: '1Q-63', ETA: '2025-09-24', SRD: '2025-12-01', ETA_SRD: 68, TAG: '101590-TZF-40960', },
      { RFQ: '1Q-64', ETA: '2025-03-13', SRD: '2025-09-03', ETA_SRD: 174, TAG: '101210-HAP-10150', },
      { RFQ: '1Q-64', ETA: '2025-03-17', SRD: '2025-07-29', ETA_SRD: 134, TAG: '101440-HAP-14650', },
      { RFQ: '1Q-64', ETA: '2025-03-13', SRD: '2025-08-15', ETA_SRD: 155, TAG: '101500-HAP-40710', },
      { RFQ: '1Q-64', ETA: '2025-03-13', SRD: '2025-08-15', ETA_SRD: 155, TAG: '101500-HAP-40720', },
      { RFQ: '1Q-64', ETA: '2025-03-13', SRD: '2025-08-15', ETA_SRD: 155, TAG: '101500-HAP-40730', },
      { RFQ: '1Q-66', ETA: '2025-05-01', SRD: '2025-07-08', ETA_SRD: 68, TAG: '101200-HAP-10130', },
      { RFQ: '1Q-66', ETA: '2025-05-01', SRD: '2025-07-08', ETA_SRD: 68, TAG: '101200-HAP-10140', },
      { RFQ: '1Q-68', ETA: '2025-05-01', SRD: '2025-07-29', ETA_SRD: 89, TAG: '101200-HBG-10160', },
      { RFQ: '1Q-68', ETA: '2025-05-01', SRD: '2025-07-29', ETA_SRD: 89, TAG: '101200-HBG-10170', },
      { RFQ: '1Q-68', ETA: '2025-05-01', SRD: '2025-10-03', ETA_SRD: 155, TAG: '101230-HBG-12030', },
      { RFQ: '1Q-68', ETA: '2025-05-01', SRD: '2025-10-03', ETA_SRD: 155, TAG: '101230-HBG-12140', },
      { RFQ: '1Q-68', ETA: '2025-05-01', SRD: '2025-12-10', ETA_SRD: 223, TAG: '101230-HBG-12320', },
      { RFQ: '1Q-68', ETA: '2025-05-01', SRD: '2025-12-10', ETA_SRD: 223, TAG: '101230-HBG-12340', },
      { RFQ: '1Q-68', ETA: '2025-05-01', SRD: '2025-12-10', ETA_SRD: 223, TAG: '101230-HBG-12420', },
      { RFQ: '1Q-68', ETA: '2025-05-01', SRD: '2025-12-10', ETA_SRD: 223, TAG: '101230-HBG-12440', },
      { RFQ: '1Q-68', ETA: '2025-05-01', SRD: '2025-10-01', ETA_SRD: 153, TAG: '101410-HBG-40450', },
      { RFQ: '1Q-6A', ETA: '2025-09-07', SRD: '2025-12-10', ETA_SRD: 94, TAG: '101230-HAP-12370', },
      { RFQ: '1Q-6A', ETA: '2025-09-07', SRD: '2025-12-10', ETA_SRD: 94, TAG: '101230-HAP-12470', },
      { RFQ: '1Q-6A', ETA: '2025-09-07', SRD: '2025-10-03', ETA_SRD: 26, TAG: '101230-HAP-12620', },
      { RFQ: '1Q-6Z', ETA: '2025-04-16', SRD: '2025-06-12', ETA_SRD: 57, TAG: 'EWH-1101930-ABJ-71060', },
      { RFQ: '1Q-6Z', ETA: '2025-04-16', SRD: '2025-06-12', ETA_SRD: 57, TAG: 'EWH-1101930-ABJ-71070', },
      { RFQ: '1Q-6Z', ETA: '2025-04-16', SRD: '2025-06-12', ETA_SRD: 57, TAG: 'EWH-1101930-ABJ-71080', },
      { RFQ: '1Q-71', ETA: '2025-10-21', SRD: '2025-11-07', ETA_SRD: 17, TAG: 'NA_1', },
      { RFQ: '1Q-71', ETA: '2025-10-21', SRD: '2025-11-12', ETA_SRD: 22, TAG: 'NA_2', },
      { RFQ: '1Q-71', ETA: '2025-12-25', SRD: '2026-01-27', ETA_SRD: 33, TAG: '101730-TLC-54000', },
      { RFQ: '1Q-71', ETA: '2025-12-25', SRD: '2025-11-12', ETA_SRD: -43, TAG: '101730-TLC-54010', },
      { RFQ: '1Q-82', ETA: '2025-10-18', SRD: '2025-10-15', ETA_SRD: -3, TAG: '101610-ZZZ-50200', },
      { RFQ: '1Q-82', ETA: '2025-10-18', SRD: '2025-09-29', ETA_SRD: -19, TAG: '101610-ZZZ-50300', },
      { RFQ: '1Q-89', ETA: '2025-08-15', SRD: '2026-09-12', ETA_SRD: 393, TAG: '101430-TTF-14120', },
      { RFQ: '1Q-89', ETA: '2025-08-15', SRD: '2026-09-12', ETA_SRD: 393, TAG: '101430-TTF-14310', },
      { RFQ: '1Q-89', ETA: '2025-08-15', SRD: '2025-11-17', ETA_SRD: 94, TAG: '101430-ZZZ-14150', },
      { RFQ: '1Q-89', ETA: '2025-08-15', SRD: '2025-11-17', ETA_SRD: 94, TAG: '101430-ZZZ-14200', },
      { RFQ: '1Q-92', ETA: '2025-09-30', SRD: '2025-08-06', ETA_SRD: -55, TAG: '101530-ZZZ-50000', },
      { RFQ: '1Q-99', ETA: '2025-05-08', SRD: '2025-08-07', ETA_SRD: 91, TAG: '101440-ABJ-91630', },
      { RFQ: '1Q-99', ETA: '2025-05-08', SRD: '2025-08-06', ETA_SRD: 90, TAG: '101530-ABJ-50100A', },
      { RFQ: '1Q-99', ETA: '2025-05-08', SRD: '2025-08-06', ETA_SRD: 90, TAG: '101530-ABJ-50100B', },
      { RFQ: '1Q-99', ETA: '2025-05-08', SRD: '2025-08-06', ETA_SRD: 90, TAG: '101530-ABJ-50140', },
      { RFQ: '1Q-9A', ETA: '2025-04-20', SRD: '2025-07-08', ETA_SRD: 79, TAG: '101200-MBD-10180', },
      { RFQ: '1Q-9A', ETA: '2025-04-20', SRD: '2025-10-01', ETA_SRD: 164, TAG: '101210-MBJ-10230', },
      { RFQ: '1Q-9A', ETA: '2025-04-20', SRD: '2025-09-15', ETA_SRD: 148, TAG: '101230-MBF-12040', },
      { RFQ: '1Q-9A', ETA: '2025-04-20', SRD: '2025-09-15', ETA_SRD: 148, TAG: '101430-NBF-14000', },
      { RFQ: '1Q-9A', ETA: '2025-04-20', SRD: '2025-09-15', ETA_SRD: 148, TAG: '101435-MBF-14300', },
      { RFQ: '1Q-9A', ETA: '2025-04-20', SRD: '2025-07-29', ETA_SRD: 100, TAG: '101440-MBJ-14600', },
      { RFQ: '1Q-9A', ETA: '2025-04-20', SRD: '2025-07-29', ETA_SRD: 100, TAG: '101440-MBJ-14630', },
      { RFQ: '1Q-9A', ETA: '2025-04-20', SRD: '2025-07-29', ETA_SRD: 100, TAG: '101460-MBJ-36100', },
      { RFQ: '1Q-9B', ETA: '2025-04-20', SRD: '2025-07-08', ETA_SRD: 79, TAG: '101200-MBD-10110', },
      { RFQ: '1Q-9B', ETA: '2025-04-20', SRD: '2025-07-08', ETA_SRD: 79, TAG: '101200-MBD-10120', },
      { RFQ: '1Q-9B', ETA: '2025-04-20', SRD: '2025-09-15', ETA_SRD: 148, TAG: '101230-MBF-12130', },
      { RFQ: '1Q-9B', ETA: '2025-04-20', SRD: '2025-12-01', ETA_SRD: 225, TAG: '101400-MBL-40340', },
      { RFQ: '1Q-9B', ETA: '2025-04-20', SRD: '2025-12-01', ETA_SRD: 225, TAG: '101410-MBL-40400', },
      { RFQ: '1Q-9B', ETA: '2025-04-20', SRD: '2025-07-29', ETA_SRD: 100, TAG: '101630-MBL-50700', },
      { RFQ: '1Q-9B', ETA: '2025-04-20', SRD: '2025-07-29', ETA_SRD: 100, TAG: '101630-MBL-50800', },
      { RFQ: '1Q-9B', ETA: '2025-04-20', SRD: '2025-08-06', ETA_SRD: 108, TAG: '101640-MBL-51010', },
      { RFQ: '1Q-9C', ETA: '2025-05-08', SRD: '2025-11-11', ETA_SRD: 187, TAG: '101230-MBF-12330', },
      { RFQ: '1Q-9C', ETA: '2025-05-08', SRD: '2025-11-11', ETA_SRD: 187, TAG: '101230-MBF-12360', },
      { RFQ: '1Q-9C', ETA: '2025-05-08', SRD: '2025-11-11', ETA_SRD: 187, TAG: '101230-MBF-12430', },
      { RFQ: '1Q-9C', ETA: '2025-05-08', SRD: '2025-11-11', ETA_SRD: 187, TAG: '101230-MBF-12460', },
      { RFQ: '1Q-9C', ETA: '2025-05-08', SRD: '2025-09-15', ETA_SRD: 130, TAG: '101230-MBF-12480', },
      { RFQ: '1Q-9C', ETA: '2025-05-08', SRD: '2025-08-06', ETA_SRD: 90, TAG: '101640-MBL-51180', },
      { RFQ: '1Q-9Z', ETA: '2025-04-04', SRD: '2025-06-12', ETA_SRD: 69, TAG: 'ET-1101930-ABJ-71050', },
      { RFQ: '1T-01', ETA: '2025-08-30', SRD: '2025-10-06', ETA_SRD: 37, TAG: '101860-JTCAB-750005', },
      { RFQ: '1T-01', ETA: '2025-08-30', SRD: '2025-10-06', ETA_SRD: 37, TAG: '101860-JTCC-760013', },
      { RFQ: '1T-01', ETA: '2025-08-30', SRD: '2025-10-06', ETA_SRD: 37, TAG: '101860-JTCC-765016', },
      { RFQ: '1T-01', ETA: '2025-08-30', SRD: '2025-10-06', ETA_SRD: 37, TAG: '101860-JTRDR-791000', },
      { RFQ: '1T-01', ETA: '2025-08-30', SRD: '2025-10-06', ETA_SRD: 37, TAG: '101860-JTRP-776009', },
      { RFQ: '1T-02', ETA: '2025-08-06', SRD: '2025-08-01', ETA_SRD: -5, TAG: '101860-JTCAB-750001', },
      { RFQ: '1T-02', ETA: '2025-08-06', SRD: '2025-10-24', ETA_SRD: 79, TAG: '101860-JTCAB-776002', },
      { RFQ: '1T-02', ETA: '2025-08-06', SRD: '2025-11-11', ETA_SRD: 97, TAG: '101860-JTHAN-760509', },
      { RFQ: '1T-02', ETA: '2025-08-06', SRD: '2025-10-01', ETA_SRD: 56, TAG: '101860-JTHAN-765014', },
      { RFQ: '1T-02', ETA: '2025-08-06', SRD: '2025-08-01', ETA_SRD: -5, TAG: '101860-JTHAN-791013', },
      { RFQ: '1T-03', ETA: '2025-07-01', SRD: '2025-11-11', ETA_SRD: 133, TAG: '101860-CJB-760502', },
      { RFQ: '1T-03', ETA: '2025-07-01', SRD: '2025-10-01', ETA_SRD: 92, TAG: '101860-CJB-765507', },
      { RFQ: '1T-03', ETA: '2025-07-01', SRD: '2025-08-22', ETA_SRD: 52, TAG: '101860-JTCAB-750002', },
      { RFQ: '1T-03', ETA: '2025-07-01', SRD: '2025-10-24', ETA_SRD: 115, TAG: '101860-JTCAB-776001', },
      { RFQ: '1T-03', ETA: '2025-07-01', SRD: '2025-08-07', ETA_SRD: 37, TAG: '101860-JTCAB-793019', },
      { RFQ: '3Q-01', ETA: '2025-11-08', SRD: '2025-10-16', ETA_SRD: -23, TAG: '101230-MV-10025', },
      { RFQ: '3Q-01', ETA: '2025-11-08', SRD: '2025-10-16', ETA_SRD: -23, TAG: '101230-MV-10576', },
      { RFQ: '3Q-01', ETA: '2025-11-08', SRD: '2025-10-16', ETA_SRD: -23, TAG: '101230-PV-12301', },
      { RFQ: '3Q-01', ETA: '2025-11-08', SRD: '2025-10-16', ETA_SRD: -23, TAG: '101230-PV-12351', },
      { RFQ: '3Q-01', ETA: '2025-11-08', SRD: '2025-10-16', ETA_SRD: -23, TAG: '101230-PV-12401', },
      { RFQ: '3Q-01', ETA: '2025-11-08', SRD: '2025-10-16', ETA_SRD: -23, TAG: '101230-PV-12451', },
      { RFQ: '3Q-01', ETA: '2025-11-08', SRD: '2025-10-16', ETA_SRD: -23, TAG: '101230-SP-10012', },
      { RFQ: '3Q-01', ETA: '2025-11-08', SRD: '2025-10-16', ETA_SRD: -23, TAG: '101230-SP-10021', },
      { RFQ: '3Q-01', ETA: '2025-11-08', SRD: '2025-10-16', ETA_SRD: -23, TAG: '101230-TZB-12311', },
      { RFQ: '3Q-01', ETA: '2025-11-08', SRD: '2025-10-16', ETA_SRD: -23, TAG: '101230-TZB-12411', },
      { RFQ: '3Q-01', ETA: '2025-11-08', SRD: '2025-10-16', ETA_SRD: -23, TAG: '101230-ZZZ-12300', },
      { RFQ: '3Q-01', ETA: '2025-11-08', SRD: '2025-10-16', ETA_SRD: -23, TAG: '101230-ZZZ-12400', },
      { RFQ: '3Q-01', ETA: '2025-11-08', SRD: '2025-10-16', ETA_SRD: -23, TAG: '101870-EPNL-12310', },
      { RFQ: '3Q-01', ETA: '2025-11-08', SRD: '2025-10-16', ETA_SRD: -23, TAG: '101870-EPNL-12410', },
      { RFQ: '3Q-03', ETA: '2025-11-08', SRD: '2025-10-22', ETA_SRD: -17, TAG: '101230-MV-10626', },
      { RFQ: '3Q-03', ETA: '2025-11-08', SRD: '2025-10-22', ETA_SRD: -17, TAG: '101230-PV-12501', },
      { RFQ: '3Q-03', ETA: '2025-11-08', SRD: '2025-10-22', ETA_SRD: -17, TAG: '101230-SP-10017', },
      { RFQ: '3Q-03', ETA: '2025-11-08', SRD: '2025-10-22', ETA_SRD: -17, TAG: '101230-TZB-12511', },
      { RFQ: '3Q-03', ETA: '2025-11-08', SRD: '2025-10-22', ETA_SRD: -17, TAG: '101230-ZZZ-12500', },
      { RFQ: '3Q-03', ETA: '2025-11-08', SRD: '2025-10-22', ETA_SRD: -17, TAG: '101870-EPNL-12510', },
      { RFQ: '3Q-11', ETA: '2025-06-22', SRD: '2025-11-14', ETA_SRD: 145, TAG: '101800-EGEN-60110', },
      { RFQ: '3Q-11', ETA: '2025-04-25', SRD: '2025-11-14', ETA_SRD: 203, TAG: '101800-EGEN-60210', },
      { RFQ: '3Q-11', ETA: '2025-09-28', SRD: '2025-09-28', ETA_SRD: 0, TAG: '101450-TZAL-83062', },
      { RFQ: '3Q-11', ETA: '2025-09-28', SRD: '2025-09-28', ETA_SRD: 0, TAG: '101450-TZAL-83063', },
      { RFQ: '3Q-11', ETA: '2025-09-28', SRD: '2025-09-28', ETA_SRD: 0, TAG: '101450-TZAL-83064', },
      { RFQ: '3Q-11', ETA: '2025-09-28', SRD: '2025-09-28', ETA_SRD: 0, TAG: '101800-SP-10025', },
      { RFQ: '3Q-11', ETA: '2025-09-28', SRD: '2025-09-28', ETA_SRD: 0, TAG: '101800-SP-10026', },
      { RFQ: '3Q-11', ETA: '2025-09-28', SRD: '2025-09-28', ETA_SRD: 0, TAG: '101800-SP-10027', },
      { RFQ: '3Q-11', ETA: '2025-09-28', SRD: '2025-09-28', ETA_SRD: 0, TAG: '101800-SP-10028', },
      { RFQ: '3Q-11', ETA: '2025-09-28', SRD: '2025-09-28', ETA_SRD: 0, TAG: '101800-SP-10029', },
      { RFQ: '3Q-11', ETA: '2025-09-28', SRD: '2025-09-28', ETA_SRD: 0, TAG: '101800-SP-10030', },
      { RFQ: '3Q-11', ETA: '2025-09-28', SRD: '2025-09-28', ETA_SRD: 0, TAG: '101800-ZZZ-60100', },
      { RFQ: '3Q-11', ETA: '2025-09-28', SRD: '2025-09-28', ETA_SRD: 0, TAG: '101800-ZZZ-60120', },
      { RFQ: '3Q-11', ETA: '2025-09-28', SRD: '2025-09-28', ETA_SRD: 0, TAG: '101800-ZZZ-60160', },
      { RFQ: '3Q-11', ETA: '2025-09-28', SRD: '2025-09-28', ETA_SRD: 0, TAG: '101800-ZZZ-60170', },
      { RFQ: '3Q-11', ETA: '2025-09-28', SRD: '2025-09-28', ETA_SRD: 0, TAG: '101800-ZZZ-60190', },
      { RFQ: '3Q-11', ETA: '2025-09-28', SRD: '2025-09-28', ETA_SRD: 0, TAG: '101800-ZZZ-60200', },
      { RFQ: '3Q-11', ETA: '2025-09-28', SRD: '2025-09-28', ETA_SRD: 0, TAG: '101800-ZZZ-60220', },
      { RFQ: '3Q-11', ETA: '2025-09-28', SRD: '2025-09-28', ETA_SRD: 0, TAG: '101800-ZZZ-60260', },
      { RFQ: '3Q-11', ETA: '2025-09-28', SRD: '2025-09-28', ETA_SRD: 0, TAG: '101800-ZZZ-60270', },
      { RFQ: '3Q-11', ETA: '2025-09-28', SRD: '2025-09-28', ETA_SRD: 0, TAG: '101800-ZZZ-60290', },
      { RFQ: '3Q-11', ETA: '2025-09-28', SRD: '2025-09-28', ETA_SRD: 0, TAG: '101800-ZZZ-60300', },
      { RFQ: '3Q-11', ETA: '2025-09-28', SRD: '2025-09-28', ETA_SRD: 0, TAG: '101800-ZZZ-60320', },
      { RFQ: '3Q-11', ETA: '2025-09-28', SRD: '2025-09-28', ETA_SRD: 0, TAG: '101800-ZZZ-60360', },
      { RFQ: '3Q-11', ETA: '2025-09-28', SRD: '2025-09-28', ETA_SRD: 0, TAG: '101800-ZZZ-60370', },
      { RFQ: '3Q-11', ETA: '2025-09-28', SRD: '2025-09-28', ETA_SRD: 0, TAG: '101800-ZZZ-60390', },
      { RFQ: '3Q-11', ETA: '2026-05-16', SRD: '2025-11-14', ETA_SRD: -183, TAG: '101800-EGEN-60310', },
      { RFQ: 'HE-01', ETA: '2025-08-07', SRD: '2025-08-07', ETA_SRD: 0, TAG: '101820-EMCC-96110', },
      { RFQ: 'HE-01', ETA: '2025-08-07', SRD: '2025-08-07', ETA_SRD: 0, TAG: '101820-EMCC-96310', },
      { RFQ: 'HE-01', ETA: '2025-08-07', SRD: '2025-08-07', ETA_SRD: 0, TAG: '101840-EMCC-96120', },
      { RFQ: 'HE-01', ETA: '2025-08-07', SRD: '2025-08-07', ETA_SRD: 0, TAG: '101840-EMCC-96320', },
      { RFQ: 'HE-02', ETA: '2025-07-10', SRD: '2025-08-07', ETA_SRD: 28, TAG: '101660-EXFMR-94900', },
      { RFQ: 'HE-02', ETA: '2025-07-10', SRD: '2025-08-07', ETA_SRD: 28, TAG: '101820-EXFMR-96130', },
      { RFQ: 'HE-02', ETA: '2025-07-10', SRD: '2025-08-07', ETA_SRD: 28, TAG: '101820-EXFMR-96230', },
      { RFQ: 'HE-02', ETA: '2025-07-10', SRD: '2025-08-07', ETA_SRD: 28, TAG: '101820-EXFMR-96330', },
      { RFQ: 'HE-02', ETA: '2025-07-10', SRD: '2025-08-07', ETA_SRD: 28, TAG: '101820-EXFMR-96430', },
      { RFQ: 'HE-02', ETA: '2025-07-10', SRD: '2025-08-07', ETA_SRD: 28, TAG: '101840-EXFMR-96170', },
      { RFQ: 'HE-02', ETA: '2025-07-10', SRD: '2025-08-07', ETA_SRD: 28, TAG: '101840-EXFMR-96190', },
      { RFQ: 'HE-02', ETA: '2025-07-10', SRD: '2025-08-07', ETA_SRD: 28, TAG: '101840-EXFMR-96270', },
      { RFQ: 'HE-02', ETA: '2025-07-10', SRD: '2025-08-07', ETA_SRD: 28, TAG: '101840-EXFMR-96290', },
      { RFQ: 'HE-02', ETA: '2025-07-10', SRD: '2025-08-07', ETA_SRD: 28, TAG: '101840-EXFMR-96370', },
      { RFQ: 'HE-02', ETA: '2025-07-10', SRD: '2025-08-07', ETA_SRD: 28, TAG: '101840-EXFMR-96390', },
      { RFQ: 'HE-02', ETA: '2025-07-10', SRD: '2025-08-07', ETA_SRD: 28, TAG: '101840-EXFMR-96470', },
      { RFQ: 'HE-02', ETA: '2025-07-10', SRD: '2025-08-07', ETA_SRD: 28, TAG: '101840-EXFMR-96490', },
      { RFQ: 'HE-03', ETA: '2025-06-01', SRD: '2025-08-07', ETA_SRD: 67, TAG: '101820-EDB-96135', },
      { RFQ: 'HE-03', ETA: '2025-06-01', SRD: '2025-08-07', ETA_SRD: 67, TAG: '101820-EDB-96235', },
      { RFQ: 'HE-03', ETA: '2025-06-01', SRD: '2025-08-07', ETA_SRD: 67, TAG: '101820-EDB-96335', },
      { RFQ: 'HE-03', ETA: '2025-06-01', SRD: '2025-08-07', ETA_SRD: 67, TAG: '101820-EDB-96435', },
      { RFQ: 'HE-03', ETA: '2025-06-01', SRD: '2025-08-07', ETA_SRD: 67, TAG: '101840-EDB-96175', },
      { RFQ: 'HE-03', ETA: '2025-06-01', SRD: '2025-08-07', ETA_SRD: 67, TAG: '101840-EDB-96195', },
      { RFQ: 'HE-03', ETA: '2025-06-01', SRD: '2025-08-07', ETA_SRD: 67, TAG: '101840-EDB-96275', },
      { RFQ: 'HE-03', ETA: '2025-06-01', SRD: '2025-08-07', ETA_SRD: 67, TAG: '101840-EDB-96295', },
      { RFQ: 'HE-03', ETA: '2025-06-01', SRD: '2025-08-07', ETA_SRD: 67, TAG: '101840-EDB-96375', },
      { RFQ: 'HE-03', ETA: '2025-06-01', SRD: '2025-08-07', ETA_SRD: 67, TAG: '101840-EDB-96395', },
      { RFQ: 'HE-03', ETA: '2025-06-01', SRD: '2025-08-07', ETA_SRD: 67, TAG: '101840-EDB-96475', },
      { RFQ: 'HE-03', ETA: '2025-06-01', SRD: '2025-08-07', ETA_SRD: 67, TAG: '101840-EDB-96495', },
      { RFQ: 'HE-03', ETA: '2025-06-01', SRD: '2025-08-07', ETA_SRD: 67, TAG: '101850-EDB-96150A', },
      { RFQ: 'HE-03', ETA: '2025-06-01', SRD: '2025-08-07', ETA_SRD: 67, TAG: '101850-EDB-96150B', },
      { RFQ: 'HE-03', ETA: '2025-06-01', SRD: '2025-08-07', ETA_SRD: 67, TAG: '101850-EDB-96155A', },
      { RFQ: 'HE-03', ETA: '2025-06-01', SRD: '2025-08-07', ETA_SRD: 67, TAG: '101850-EDB-96155B', },
      { RFQ: 'HE-03', ETA: '2025-06-01', SRD: '2025-08-07', ETA_SRD: 67, TAG: '101850-EDB-96250A', },
      { RFQ: 'HE-03', ETA: '2025-06-01', SRD: '2025-08-07', ETA_SRD: 67, TAG: '101850-EDB-96250B', },
      { RFQ: 'HE-03', ETA: '2025-06-01', SRD: '2025-08-07', ETA_SRD: 67, TAG: '101850-EDB-96255A', },
      { RFQ: 'HE-03', ETA: '2025-06-01', SRD: '2025-08-07', ETA_SRD: 67, TAG: '101850-EDB-96255B', },
      { RFQ: 'HE-03', ETA: '2025-06-01', SRD: '2025-08-07', ETA_SRD: 67, TAG: '101850-EDB-96350A', },
      { RFQ: 'HE-03', ETA: '2025-06-01', SRD: '2025-08-07', ETA_SRD: 67, TAG: '101850-EDB-96350B', },
      { RFQ: 'HE-03', ETA: '2025-06-01', SRD: '2025-08-07', ETA_SRD: 67, TAG: '101850-EDB-96355A', },
      { RFQ: 'HE-03', ETA: '2025-06-01', SRD: '2025-08-07', ETA_SRD: 67, TAG: '101850-EDB-96355B', },
      { RFQ: 'HE-03', ETA: '2025-06-01', SRD: '2025-08-07', ETA_SRD: 67, TAG: '101850-EDB-96450A', },
      { RFQ: 'HE-03', ETA: '2025-06-01', SRD: '2025-08-07', ETA_SRD: 67, TAG: '101850-EDB-96450B', },
      { RFQ: 'HE-03', ETA: '2025-06-01', SRD: '2025-08-07', ETA_SRD: 67, TAG: '101850-EDB-96455A', },
      { RFQ: 'HE-03', ETA: '2025-06-01', SRD: '2025-09-29', ETA_SRD: 120, TAG: '101930-EDB-70410', },
      { RFQ: 'HH-01', ETA: '2025-08-15', SRD: '2025-08-07', ETA_SRD: -8, TAG: '101970-HAL-91530', },
      { RFQ: 'HH-01', ETA: '2025-08-15', SRD: '2025-08-19', ETA_SRD: 4, TAG: '101970-HAL-92530', },
      { RFQ: 'HH-01', ETA: '2025-08-15', SRD: '2025-08-22', ETA_SRD: 7, TAG: '101970-HAL-93530', },
      { RFQ: 'HH-01', ETA: '2025-08-15', SRD: '2025-08-12', ETA_SRD: -3, TAG: '101970-HAL-94530', },
      { RFQ: 'HH-01', ETA: '2025-08-15', SRD: '2025-08-07', ETA_SRD: -8, TAG: '101970-LPNL-91701', },
      { RFQ: 'HH-01', ETA: '2025-08-15', SRD: '2025-08-19', ETA_SRD: 4, TAG: '101970-LPNL-93701', },
      { RFQ: 'HH-01', ETA: '2025-08-15', SRD: '2025-08-07', ETA_SRD: -8, TAG: '101970-THAH-91500', },
      { RFQ: 'HH-01', ETA: '2025-08-15', SRD: '2025-08-19', ETA_SRD: 4, TAG: '101970-THAH-92500', },
      { RFQ: 'HH-01', ETA: '2025-08-15', SRD: '2025-08-22', ETA_SRD: 7, TAG: '101970-THAH-93500', },
      { RFQ: 'HH-01', ETA: '2025-08-15', SRD: '2025-08-12', ETA_SRD: -3, TAG: '101970-THAH-94500', },
      { RFQ: 'HH-03', ETA: '2025-03-25', SRD: '2025-03-25', ETA_SRD: 0, TAG: '101910-LDW-91010', },
      { RFQ: 'HH-03', ETA: '2025-03-25', SRD: '2025-03-25', ETA_SRD: 0, TAG: '101910-LDW-91020', },
      { RFQ: 'HH-03', ETA: '2025-05-08', SRD: '2025-05-12', ETA_SRD: 4, TAG: '101910-LDW-94010', },
      { RFQ: 'HH-03', ETA: '2025-05-08', SRD: '2025-05-12', ETA_SRD: 4, TAG: '101910-LDW-94020', },
      { RFQ: 'HH-03', ETA: '2025-05-15', SRD: '2025-05-15', ETA_SRD: 0, TAG: '101910-LDW-92010', },
      { RFQ: 'HH-03', ETA: '2025-05-15', SRD: '2025-05-15', ETA_SRD: 0, TAG: '101910-LDW-92020', },
      { RFQ: 'HH-03', ETA: '2025-05-26', SRD: '2025-08-07', ETA_SRD: 73, TAG: '101910-LDW-91030', },
      { RFQ: 'HH-03', ETA: '2025-05-29', SRD: '2025-08-08', ETA_SRD: 71, TAG: '101910-LDW-94030', },
      { RFQ: 'HH-03', ETA: '2025-06-05', SRD: '2025-08-19', ETA_SRD: 75, TAG: '101910-LDW-92030', },
      { RFQ: 'HH-03', ETA: '2025-06-10', SRD: '2025-08-21', ETA_SRD: 72, TAG: '101910-LDW-93030', },
      { RFQ: 'HH-03', ETA: '2025-07-04', SRD: '2025-06-20', ETA_SRD: -14, TAG: '101910-LDW-93010', },
      { RFQ: 'HH-03', ETA: '2025-07-04', SRD: '2025-06-20', ETA_SRD: -14, TAG: '101910-LDW-93020', },
      { RFQ: 'HH-03', ETA: '2025-08-07', SRD: '2025-08-07', ETA_SRD: 0, TAG: '101910-WDR-91010', },
      { RFQ: 'HH-03', ETA: '2025-08-07', SRD: '2025-08-07', ETA_SRD: 0, TAG: '101910-WDR-91020', },
      { RFQ: 'HH-03', ETA: '2025-08-22', SRD: '2025-08-21', ETA_SRD: -1, TAG: '101910-WDR-93010', },
      { RFQ: 'HH-03', ETA: '2025-08-22', SRD: '2025-08-21', ETA_SRD: -1, TAG: '101910-WDR-93020', },
      { RFQ: 'HI-01', ETA: '2025-09-08', SRD: '2025-08-16', ETA_SRD: -23, TAG: '101780-CPNL-67911', },
      { RFQ: 'HI-01', ETA: '2025-09-08', SRD: '2025-08-16', ETA_SRD: -23, TAG: '101780-CPNL-67912', },
      { RFQ: 'HI-01', ETA: '2025-09-08', SRD: '2025-08-16', ETA_SRD: -23, TAG: '101780-CPNL-67941', },
      { RFQ: 'HI-01', ETA: '2025-09-08', SRD: '2025-08-16', ETA_SRD: -23, TAG: '101780-CPNL-67942', },
      { RFQ: 'HI-01', ETA: '2025-09-08', SRD: '2025-08-16', ETA_SRD: -23, TAG: '101870-NPNL-67910', },
      { RFQ: 'HI-01', ETA: '2025-09-08', SRD: '2025-08-16', ETA_SRD: -23, TAG: '101870-NPNL-67940', },
      { RFQ: 'HI-01', ETA: '2025-09-15', SRD: '2025-08-28', ETA_SRD: -18, TAG: '101780-CPNL-67921', },
      { RFQ: 'HI-01', ETA: '2025-09-15', SRD: '2025-08-28', ETA_SRD: -18, TAG: '101780-CPNL-67922', },
      { RFQ: 'HI-01', ETA: '2025-09-15', SRD: '2025-08-28', ETA_SRD: -18, TAG: '101780-CPNL-67931', },
      { RFQ: 'HI-01', ETA: '2025-09-15', SRD: '2025-08-28', ETA_SRD: -18, TAG: '101780-CPNL-67932', },
      { RFQ: 'HI-01', ETA: '2025-09-15', SRD: '2025-08-28', ETA_SRD: -18, TAG: '101870-NPNL-67920', },
      { RFQ: 'HI-01', ETA: '2025-09-15', SRD: '2025-08-28', ETA_SRD: -18, TAG: '101870-NPNL-67930', },
      { RFQ: 'HI-05', ETA: '2025-06-24', SRD: '2025-10-16', ETA_SRD: 114, TAG: '101540-LSH-94401', },
      { RFQ: 'HP-01', ETA: '2025-02-24', SRD: '2025-06-27', ETA_SRD: 123, TAG: 'East-North Column', },
      { RFQ: 'HP-01', ETA: '2025-02-24', SRD: '2025-03-25', ETA_SRD: 29, TAG: 'East-North Node', },
      { RFQ: 'HP-01', ETA: '2025-02-24', SRD: '2025-07-02', ETA_SRD: 128, TAG: 'East-South Column', },
      { RFQ: 'HP-01', ETA: '2025-02-24', SRD: '2025-05-08', ETA_SRD: 73, TAG: 'East-South Node', },
      { RFQ: 'HP-01', ETA: '2025-02-24', SRD: '2025-07-09', ETA_SRD: 135, TAG: 'West-North Column', },
      { RFQ: 'HP-01', ETA: '2025-02-24', SRD: '2025-05-15', ETA_SRD: 80, TAG: 'West-North Node', },
      { RFQ: 'HP-01', ETA: '2025-02-24', SRD: '2025-07-15', ETA_SRD: 141, TAG: 'West-South Column', },
      { RFQ: 'HP-01', ETA: '2025-02-24', SRD: '2025-06-13', ETA_SRD: 109, TAG: 'West-South Node', },
      { RFQ: 'HP-01', ETA: '2025-03-28', SRD: '2025-05-30', ETA_SRD: 63, TAG: '101650-ZZZ-91370', },
      { RFQ: 'HP-01', ETA: '2025-03-28', SRD: '2025-05-30', ETA_SRD: 63, TAG: '101650-ZZZ-91470', },
      { RFQ: 'HP-01', ETA: '2025-03-28', SRD: '2025-05-30', ETA_SRD: 63, TAG: '101650-ZZZ-92370', },
      { RFQ: 'HP-01', ETA: '2025-03-28', SRD: '2025-05-30', ETA_SRD: 63, TAG: '101650-ZZZ-92470', },
      { RFQ: 'HP-01', ETA: '2025-03-28', SRD: '2025-05-30', ETA_SRD: 63, TAG: '101650-ZZZ-93370', },
      { RFQ: 'HP-01', ETA: '2025-03-28', SRD: '2025-05-30', ETA_SRD: 63, TAG: '101650-ZZZ-93470', },
      { RFQ: 'HP-01', ETA: '2025-03-28', SRD: '2025-05-30', ETA_SRD: 63, TAG: '101650-ZZZ-94370', },
      { RFQ: 'HP-01', ETA: '2025-03-28', SRD: '2025-05-30', ETA_SRD: 63, TAG: '101650-ZZZ-94470', },
      { RFQ: 'HP-02', ETA: '2025-05-29', SRD: '2025-08-08', ETA_SRD: 71, TAG: '101500-XV-94020', },
      { RFQ: 'HP-02', ETA: '2025-05-29', SRD: '2025-08-08', ETA_SRD: 71, TAG: 'East-South Column', },
      { RFQ: 'HP-02', ETA: '2025-05-29', SRD: '2025-08-21', ETA_SRD: 84, TAG: 'West-South Column', },
      { RFQ: 'HP-03', ETA: '2025-01-25', SRD: '2025-04-01', ETA_SRD: 66, TAG: 'East-North Node', },
      { RFQ: 'HP-03', ETA: '2025-01-25', SRD: '2025-05-15', ETA_SRD: 110, TAG: 'East-South Node', },
      { RFQ: 'HP-03', ETA: '2025-01-25', SRD: '2025-05-22', ETA_SRD: 117, TAG: 'West-North Node', },
      { RFQ: 'HP-03', ETA: '2025-01-25', SRD: '2025-06-20', ETA_SRD: 146, TAG: 'West-South Node', },
      { RFQ: 'HP-04', ETA: '2025-06-15', SRD: '2025-06-27', ETA_SRD: 12, TAG: 'East-North Column', },
      { RFQ: 'HP-04', ETA: '2025-06-15', SRD: '2025-07-02', ETA_SRD: 17, TAG: 'East-South Column', },
      { RFQ: 'HP-04', ETA: '2025-06-15', SRD: '2025-07-09', ETA_SRD: 24, TAG: 'West-North Column', },
      { RFQ: 'HP-04', ETA: '2025-06-15', SRD: '2025-07-15', ETA_SRD: 30, TAG: 'West-South Column', },
      { RFQ: 'HP-07', ETA: '2025-03-02', SRD: '2025-03-25', ETA_SRD: 23, TAG: 'East-North Node', },
      { RFQ: 'HP-07', ETA: '2025-03-02', SRD: '2025-08-08', ETA_SRD: 159, TAG: 'East-South Column', },
      { RFQ: 'HP-07', ETA: '2025-03-02', SRD: '2025-05-08', ETA_SRD: 67, TAG: 'East-South Node', },
      { RFQ: 'HP-07', ETA: '2025-03-02', SRD: '2025-05-15', ETA_SRD: 74, TAG: 'West-North Node', },
      { RFQ: 'HP-07', ETA: '2025-03-02', SRD: '2025-08-21', ETA_SRD: 172, TAG: 'West-South Column', },
      { RFQ: 'HP-07', ETA: '2025-03-02', SRD: '2025-06-13', ETA_SRD: 103, TAG: 'West-South Node', },
      { RFQ: 'HP-08', ETA: '2025-03-25', SRD: '2025-04-30', ETA_SRD: 36, TAG: 'East-North Column', },
      { RFQ: 'HP-08', ETA: '2025-03-25', SRD: '2025-03-25', ETA_SRD: 0, TAG: 'East-North Node', },
      { RFQ: 'HP-08', ETA: '2025-03-25', SRD: '2025-05-06', ETA_SRD: 42, TAG: 'East-South Column', },
      { RFQ: 'HP-08', ETA: '2025-03-25', SRD: '2025-03-11', ETA_SRD: -14, TAG: 'East-South Node', },
      { RFQ: 'HP-08', ETA: '2025-03-25', SRD: '2025-05-13', ETA_SRD: 49, TAG: 'West-North Column', },
      { RFQ: 'HP-08', ETA: '2025-03-25', SRD: '2025-02-25', ETA_SRD: -28, TAG: 'West-North Node', },
      { RFQ: 'HP-08', ETA: '2025-03-25', SRD: '2025-05-16', ETA_SRD: 52, TAG: 'West-South Column', },
      { RFQ: 'HP-08', ETA: '2025-03-25', SRD: '2025-04-16', ETA_SRD: 22, TAG: 'West-South Node', },
      { RFQ: 'HP-09', ETA: '2025-03-10', SRD: '2025-05-01', ETA_SRD: 52, TAG: 'East-North Column', },
      { RFQ: 'HP-09', ETA: '2025-03-10', SRD: '2025-03-25', ETA_SRD: 15, TAG: 'East-North Node', },
      { RFQ: 'HP-09', ETA: '2025-03-10', SRD: '2025-05-06', ETA_SRD: 57, TAG: 'East-South Column', },
      { RFQ: 'HP-09', ETA: '2025-03-10', SRD: '2025-03-11', ETA_SRD: 1, TAG: 'East-South Node', },
      { RFQ: 'HP-09', ETA: '2025-03-10', SRD: '2025-05-13', ETA_SRD: 64, TAG: 'West-North Column', },
      { RFQ: 'HP-09', ETA: '2025-03-10', SRD: '2025-02-25', ETA_SRD: -13, TAG: 'West-North Node', },
      { RFQ: 'HP-09', ETA: '2025-03-10', SRD: '2025-05-16', ETA_SRD: 67, TAG: 'West-South Column', },
      { RFQ: 'HP-09', ETA: '2025-03-10', SRD: '2025-04-16', ETA_SRD: 37, TAG: 'West-South Node', },
      { RFQ: 'HP-10', ETA: '2025-04-18', SRD: '2025-07-01', ETA_SRD: 74, TAG: 'East-North Column', },
      { RFQ: 'HP-10', ETA: '2025-04-18', SRD: '2025-03-25', ETA_SRD: -24, TAG: 'East-North Node', },
      { RFQ: 'HP-10', ETA: '2025-05-27', SRD: undefined,    ETA_SRD: 0, TAG: 'A4R-A', },
      { RFQ: 'HP-10', ETA: '2025-05-27', SRD: '2025-08-08', ETA_SRD: 73, TAG: 'East-South Column', },
      { RFQ: 'HP-10', ETA: '2025-05-27', SRD: '2025-05-08', ETA_SRD: -19, TAG: 'East-South Node', },
      { RFQ: 'HP-10', ETA: '2025-05-27', SRD: '2025-08-19', ETA_SRD: 84, TAG: 'West-North Column', },
      { RFQ: 'HP-10', ETA: '2025-05-27', SRD: '2025-05-15', ETA_SRD: -12, TAG: 'West-North Node', },
      { RFQ: 'HP-10', ETA: '2025-05-27', SRD: '2025-07-15', ETA_SRD: 49, TAG: 'West-South Column', },
      { RFQ: 'HP-10', ETA: '2025-05-27', SRD: '2025-06-13', ETA_SRD: 17, TAG: 'West-South Node', },
      { RFQ: 'HP-10A', ETA: '2025-04-18', SRD: '2025-03-25', ETA_SRD: -24, TAG: 'East-North Node', },
      { RFQ: 'HP-10A', ETA: '2025-05-27', SRD: '2025-07-02', ETA_SRD: 36, TAG: 'Eas-South Column', },
      { RFQ: 'HP-10A', ETA: '2025-05-27', SRD: '2025-07-01', ETA_SRD: 35, TAG: 'East-North Column', },
      { RFQ: 'HP-10A', ETA: '2025-05-27', SRD: '2025-05-12', ETA_SRD: -15, TAG: 'East-South Node', },
      { RFQ: 'HP-10A', ETA: '2025-05-27', SRD: '2025-07-09', ETA_SRD: 43, TAG: 'West-North Column', },
      { RFQ: 'HP-10A', ETA: '2025-05-27', SRD: '2025-05-19', ETA_SRD: -8, TAG: 'West-North Node', },
      { RFQ: 'HP-10A', ETA: '2025-05-27', SRD: '2025-07-15', ETA_SRD: 49, TAG: 'West-South Column', },
      { RFQ: 'HP-10A', ETA: '2025-05-27', SRD: '2025-06-20', ETA_SRD: 24, TAG: 'West-South Node', },
      { RFQ: 'HP-11', ETA: '2025-03-25', SRD: '2025-07-02', ETA_SRD: 99, TAG: 'Eas-South Column', },
      { RFQ: 'HP-11', ETA: '2025-03-25', SRD: '2025-06-27', ETA_SRD: 94, TAG: 'East-North Column', },
      { RFQ: 'HP-11', ETA: '2025-03-25', SRD: '2025-03-25', ETA_SRD: 0, TAG: 'East-North Node', },
      { RFQ: 'HP-11', ETA: '2025-03-25', SRD: '2025-05-08', ETA_SRD: 44, TAG: 'East-South Node', },
      { RFQ: 'HP-11', ETA: '2025-03-25', SRD: '2025-07-09', ETA_SRD: 106, TAG: 'West-North Column', },
      { RFQ: 'HP-11', ETA: '2025-03-25', SRD: '2025-05-15', ETA_SRD: 51, TAG: 'West-North Node', },
      { RFQ: 'HP-11', ETA: '2025-03-25', SRD: '2025-07-15', ETA_SRD: 112, TAG: 'West-South Column', },
      { RFQ: 'HP-11', ETA: '2025-03-25', SRD: '2025-06-13', ETA_SRD: 80, TAG: 'West-South Node', },
      { RFQ: 'HP-12', ETA: '2025-08-15', SRD: '2025-10-28', ETA_SRD: 74, TAG: 'East-North Column', },
      { RFQ: 'HP-12', ETA: '2025-08-15', SRD: '2025-09-30', ETA_SRD: 46, TAG: 'East-North Node', },
      { RFQ: 'HP-12', ETA: '2025-08-15', SRD: '2025-11-28', ETA_SRD: 105, TAG: 'East-South Column', },
      { RFQ: 'HP-12', ETA: '2025-08-15', SRD: '2025-09-30', ETA_SRD: 46, TAG: 'East-South Node', },
      { RFQ: 'HP-12', ETA: '2025-08-15', SRD: '2025-08-21', ETA_SRD: 6, TAG: 'West-North Column', },
      { RFQ: 'HP-12', ETA: '2025-08-15', SRD: '2025-09-30', ETA_SRD: 46, TAG: 'West-North Node', },
      { RFQ: 'HP-12', ETA: '2025-08-15', SRD: '2025-11-28', ETA_SRD: 105, TAG: 'West-South Column', },
      { RFQ: 'HP-12', ETA: '2025-08-15', SRD: '2025-09-12', ETA_SRD: 28, TAG: 'West-South Node', },
      { RFQ: 'HP-13', ETA: '2025-05-15', SRD: undefined,    ETA_SRD: 0, TAG: '101440-SP-95141', },
      { RFQ: 'HP-13', ETA: '2025-05-15', SRD: '2025-08-07', ETA_SRD: 84, TAG: 'East-North Column', },
      { RFQ: 'HP-13', ETA: '2025-05-15', SRD: '2025-08-08', ETA_SRD: 85, TAG: 'East-South Column', },
      { RFQ: 'HP-13', ETA: '2025-05-15', SRD: '2025-08-19', ETA_SRD: 96, TAG: 'West-North Column', },
      { RFQ: 'HP-13', ETA: '2025-05-15', SRD: '2025-08-21', ETA_SRD: 98, TAG: 'West-South Column', },
      { RFQ: 'HP-14', ETA: '2025-07-15', SRD: undefined,    ETA_SRD: 0, TAG: '101500-SP-94001', },
      { RFQ: 'HP-14', ETA: '2025-07-15', SRD: '2025-08-08', ETA_SRD: 24, TAG: 'East-South Column', },
      { RFQ: 'HP-14', ETA: '2025-07-15', SRD: '2025-08-21', ETA_SRD: 37, TAG: 'West-South Column', },
      { RFQ: 'HP-15', ETA: '2025-02-14', SRD: '2025-03-25', ETA_SRD: 39, TAG: '101520-SP-91001', },
      { RFQ: 'HP-15', ETA: '2025-02-14', SRD: '2025-07-17', ETA_SRD: 153, TAG: 'East-North Column', },
      { RFQ: 'HP-15', ETA: '2025-02-14', SRD: '2025-03-25', ETA_SRD: 39, TAG: 'East-North Node', },
      { RFQ: 'HP-15', ETA: '2025-02-14', SRD: '2025-05-27', ETA_SRD: 102, TAG: 'East-South Column', },
      { RFQ: 'HP-15', ETA: '2025-02-14', SRD: '2025-03-11', ETA_SRD: 25, TAG: 'East-South Node', },
      { RFQ: 'HP-15', ETA: '2025-02-14', SRD: '2025-02-25', ETA_SRD: 11, TAG: 'West-North Node', },
      { RFQ: 'HP-15', ETA: '2025-02-14', SRD: '2025-04-16', ETA_SRD: 61, TAG: 'West-South Node', },
      { RFQ: 'HP-16', ETA: '2025-07-10', SRD: '2025-08-07', ETA_SRD: 28, TAG: 'East-North Column', },
      { RFQ: 'HP-16', ETA: '2025-07-10', SRD: '2025-08-08', ETA_SRD: 29, TAG: 'East-South Column', },
      { RFQ: 'HP-16', ETA: '2025-07-10', SRD: '2025-08-19', ETA_SRD: 40, TAG: 'West-North Column', },
      { RFQ: 'HP-16', ETA: '2025-07-10', SRD: '2025-08-21', ETA_SRD: 42, TAG: 'West-South Column', },
      { RFQ: 'HP-16', ETA: '2025-09-18', SRD: '2025-08-07', ETA_SRD: -42, TAG: '101520-SP-91011', },
      { RFQ: 'HP-17', ETA: '2025-06-20', SRD: '2025-06-02', ETA_SRD: -18, TAG: '101470-SP-97722', },
      { RFQ: 'HP-17', ETA: '2025-08-01', SRD: '2025-10-28', ETA_SRD: 88, TAG: 'East-North Column', },
      { RFQ: 'HP-17', ETA: '2025-08-01', SRD: '2025-09-30', ETA_SRD: 60, TAG: 'East-North Node', },
      { RFQ: 'HP-17', ETA: '2025-08-01', SRD: '2025-11-23', ETA_SRD: 114, TAG: 'East-South Column', },
      { RFQ: 'HP-17', ETA: '2025-08-01', SRD: '2025-09-30', ETA_SRD: 60, TAG: 'East-South Node', },
      { RFQ: 'HP-17', ETA: '2025-08-01', SRD: '2025-11-04', ETA_SRD: 95, TAG: 'West-North Column', },
      { RFQ: 'HP-17', ETA: '2025-08-01', SRD: '2025-09-30', ETA_SRD: 60, TAG: 'West-North Node', },
      { RFQ: 'HP-17', ETA: '2025-08-01', SRD: '2025-11-13', ETA_SRD: 104, TAG: 'West-South Column', },
      { RFQ: 'HP-17', ETA: '2025-08-01', SRD: '2025-09-09', ETA_SRD: 39, TAG: 'West-South Node', },
      { RFQ: 'HQ-24', ETA: '2025-11-01', SRD: '2025-10-03', ETA_SRD: -29, TAG: '101500-PBE-94560', },
      { RFQ: 'HQ-24', ETA: '2025-11-01', SRD: '2025-10-03', ETA_SRD: -29, TAG: '101500-PBE-94590', },
      { RFQ: 'HQ-31', ETA: '2025-04-30', SRD: '2025-03-25', ETA_SRD: -36, TAG: '101510-PBE-91410', },
      { RFQ: 'HQ-31', ETA: '2025-04-30', SRD: '2025-03-25', ETA_SRD: -36, TAG: '101520-PBE-91420', },
      { RFQ: 'HQ-31', ETA: '2025-04-30', SRD: '2025-05-15', ETA_SRD: 15, TAG: '101520-PBE-92410', },
      { RFQ: 'HQ-31', ETA: '2025-04-30', SRD: '2025-05-15', ETA_SRD: 15, TAG: '101520-PBE-92420', },
      { RFQ: 'HQ-31', ETA: '2025-04-30', SRD: '2025-06-13', ETA_SRD: 44, TAG: '101520-PBE-93410', },
      { RFQ: 'HQ-31', ETA: '2025-04-30', SRD: '2025-06-13', ETA_SRD: 44, TAG: '101520-PBE-93420', },
      { RFQ: 'HQ-31', ETA: '2025-04-30', SRD: '2025-05-08', ETA_SRD: 8, TAG: '101520-PBE-94410', },
      { RFQ: 'HQ-31', ETA: '2025-04-30', SRD: '2025-05-08', ETA_SRD: 8, TAG: '101520-PBE-94420', },
      { RFQ: 'HQ-31A', ETA: '2025-02-21', SRD: '2025-08-08', ETA_SRD: 168, TAG: '101500-PBE-94030', },
      { RFQ: 'HQ-31A', ETA: '2025-02-21', SRD: '2025-08-08', ETA_SRD: 168, TAG: '101500-PBE-94040', },
      { RFQ: 'HQ-31A', ETA: '2025-02-21', SRD: '2025-06-13', ETA_SRD: 112, TAG: '101530-PBE-93710', },
      { RFQ: 'HQ-31A', ETA: '2025-02-21', SRD: '2025-06-13', ETA_SRD: 112, TAG: '101530-PBE-93720', },
      { RFQ: 'HQ-31A', ETA: '2025-02-21', SRD: '2025-03-25', ETA_SRD: 32, TAG: '101620-PBE-91600', },
      { RFQ: 'HQ-31A', ETA: '2025-02-21', SRD: '2025-03-25', ETA_SRD: 32, TAG: '101620-PBE-91610', },
      { RFQ: 'HQ-32', ETA: '2025-02-28', SRD: '2025-03-25', ETA_SRD: 25, TAG: '101440-PBE-91440', },
      { RFQ: 'HQ-32', ETA: '2025-02-28', SRD: '2025-05-15', ETA_SRD: 76, TAG: '101440-PBE-92440', },
      { RFQ: 'HQ-32', ETA: '2025-02-28', SRD: '2025-06-13', ETA_SRD: 105, TAG: '101440-PBE-93440', },
      { RFQ: 'HQ-32', ETA: '2025-02-28', SRD: '2025-05-08', ETA_SRD: 69, TAG: '101440-PBE-94440', },
      { RFQ: 'HQ-54', ETA: '2025-06-19', SRD: '2025-08-07', ETA_SRD: 49, TAG: '101440-ZZZ-91620', },
      { RFQ: 'HQ-83', ETA: '2025-07-15', SRD: '2025-08-21', ETA_SRD: 37, TAG: '101470-ZZZ-91190', },
      { RFQ: 'HQ-84', ETA: '2025-07-13', SRD: '2025-08-21', ETA_SRD: 39, TAG: '101530-TZAR-93700', },
      { RFQ: 'HQ-84', ETA: '2025-07-13', SRD: '2025-08-21', ETA_SRD: 39, TAG: '101530-ZZZ-93750', },
      { RFQ: 'HQ-86', ETA: '2025-07-01', SRD: '2025-08-08', ETA_SRD: 38, TAG: '101660-ZZZ-94920', },
      { RFQ: 'HQ-86', ETA: '2025-07-01', SRD: '2025-08-08', ETA_SRD: 38, TAG: '101660-ZZZ-94930', },
      { RFQ: 'HQ-86', ETA: '2025-07-01', SRD: '2025-08-08', ETA_SRD: 38, TAG: '101660-ZZZ-94940', },
      { RFQ: 'HQ-86', ETA: '2025-07-01', SRD: '2025-08-08', ETA_SRD: 38, TAG: '101660-ZZZ-94990', },
      { RFQ: 'HQ-88', ETA: '2025-10-30', SRD: '2025-10-24', ETA_SRD: -6, TAG: '101560-ZZZ-91800', },
      { RFQ: 'HQ-88', ETA: '2025-10-30', SRD: '2025-10-24', ETA_SRD: -6, TAG: '101560-ZZZ-94800', },
      { RFQ: 'HS-27', ETA: '2025-10-21', SRD: undefined,    ETA_SRD: 0, TAG: '101940-YFL-99110', },
      { RFQ: 'HS-27', ETA: '2025-10-21', SRD: undefined,    ETA_SRD: 0, TAG: '101940-YFL-99120', },
      { RFQ: 'HS-27', ETA: '2025-10-21', SRD: undefined,    ETA_SRD: 0, TAG: '101940-YFL-99130', },
      { RFQ: 'HS-27', ETA: '2025-10-21', SRD: undefined,    ETA_SRD: 0, TAG: '101940-YFL-99140', },
      { RFQ: 'HS-27', ETA: '2025-10-21', SRD: undefined,    ETA_SRD: 0, TAG: '101940-YFL-99210', },
      { RFQ: 'HS-27', ETA: '2025-10-21', SRD: undefined,    ETA_SRD: 0, TAG: '101940-YFL-99220', },
      { RFQ: 'HS-27', ETA: '2025-10-21', SRD: undefined,    ETA_SRD: 0, TAG: '101940-YFL-99230', },
      { RFQ: 'HS-27', ETA: '2025-10-21', SRD: undefined,    ETA_SRD: 0, TAG: '101940-YFL-99310', },
      { RFQ: 'HS-27', ETA: '2025-10-21', SRD: undefined,    ETA_SRD: 0, TAG: '101940-YFL-99320', },
      { RFQ: 'HS-27', ETA: '2025-10-21', SRD: undefined,    ETA_SRD: 0, TAG: '101940-YFL-99330', },
      { RFQ: 'HS-27', ETA: '2025-10-21', SRD: undefined,    ETA_SRD: 0, TAG: '101940-YFL-99410', },
      { RFQ: 'HS-27', ETA: '2025-10-21', SRD: undefined,    ETA_SRD: 0, TAG: '101940-YFL-99420', },
      { RFQ: 'HS-27', ETA: '2025-10-21', SRD: undefined,    ETA_SRD: 0, TAG: '101940-YFL-99430', },
      { RFQ: 'HS-27', ETA: '2025-10-21', SRD: undefined,    ETA_SRD: 0, TAG: '101940-YFL-99440', },
      { RFQ: 'HT-01', ETA: '2025-09-02', SRD: '2025-10-01', ETA_SRD: 29, TAG: '101860-JTAN-769952', },
      { RFQ: 'HT-01', ETA: '2025-09-02', SRD: '2025-09-13', ETA_SRD: 11, TAG: '101860-JTCAB-752901', },
      { RFQ: 'HT-01', ETA: '2025-09-02', SRD: '2025-10-13', ETA_SRD: 41, TAG: '101860-JTMJB-752901', },
      { RFQ: 'HT-01', ETA: '2025-09-02', SRD: '2025-11-11', ETA_SRD: 70, TAG: '101860-JTMS-759909', },
      { RFQ: 'HT-01', ETA: '2025-09-02', SRD: '2025-10-24', ETA_SRD: 52, TAG: '101860-JTMS-759910', },
    ]
    
  },

}