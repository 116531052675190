import __C from '../../../../../../includes/primitives/_constant_'

export default {
  Canvas: {
    CanvasWidth: 1400,
    CanvasHeight: 420, //previous value : 570
    CanvasBorderWeight: .5,
    CanvasBorderColor: '#E0E0DF',
    CanvasFillColor: '#fff',
    CanvasOpacity: 1,

    CanvasChartX: 0,
    CanvasChartY: 0,
    CanvasChartWidth: 400,
    CanvasChartHeight: 200,
    CanvasChartBorderWeight: 0,
    CanvasChartBorderColor: '#757575',
    CanvasChartFillColor: '#757575',
    CanvasChartOpacity: 0,
    CanvasChartSpace: 0,

    CanvasFontFamily: 'roboto',
  },
  
  Text: {
    Text: [],
    Filter: [],
    Sql: []
  },

  
  DataItems: [
    {
      CUTOFF               : '2025-01-10',
    }
  ],


  Queries: {
    
    SQL1: [
      { AREA: 'HULL',    ZONE: 'NODE',          SC_TOT: 56,  SC_CP: 29,   SC_AD: 4,    SC_BH: 2,    FB_TOT: 56,  FB_CP: 12,   FB_AD: 0,    FB_BH: 13,   AS_TOT: 36,   AS_CP: 1,    AS_AD: 0,    AS_BH: 13,   PE1_TOT: 44,  PE1_CP: 0,    PE1_AD: 0,    PE1_BH: 3,    PT_TOT: 32,  PT_CP: null, PT_AD: null, PT_BH: null, PE2_TOT: 22,  PE2_CP: null, PE2_AD: null, PE2_BH: null, ER_TOT: 11, ER_CP: null, ER_AD: null, ER_BH: null, },
      { AREA: 'HULL',    ZONE: 'PONTOON',       SC_TOT: 6,   SC_CP: 6,    SC_AD: 0,    SC_BH: 0,    FB_TOT: 6,   FB_CP: 6,    FB_AD: 0,    FB_BH: 0,    AS_TOT: 24,   AS_CP: 0,    AS_AD: 15,   AS_BH: 9,    PE1_TOT: 16,  PE1_CP: null, PE1_AD: null, PE1_BH: null, PT_TOT: 19,  PT_CP: null, PT_AD: null, PT_BH: null, PE2_TOT: 12,  PE2_CP: null, PE2_AD: null, PE2_BH: null, ER_TOT: 7,  ER_CP: null, ER_AD: null, ER_BH: null, },
      { AREA: 'HULL',    ZONE: 'COLUMN',        SC_TOT: 51,  SC_CP: null, SC_AD: null, SC_BH: null, FB_TOT: 51,  FB_CP: null, FB_AD: null, FB_BH: null, AS_TOT: 72,   AS_CP: 0,    AS_AD: 34,   AS_BH: 6,    PE1_TOT: 58,  PE1_CP: null, PE1_AD: null, PE1_BH: null, PT_TOT: 86,  PT_CP: null, PT_AD: null, PT_BH: null, PE2_TOT: 16,  PE2_CP: null, PE2_AD: null, PE2_BH: null, ER_TOT: 22, ER_CP: null, ER_AD: null, ER_BH: null, },
      { AREA: 'HULL',    ZONE: 'UCF',           SC_TOT: 13,  SC_CP: null, SC_AD: null, SC_BH: null, FB_TOT: 13,  FB_CP: null, FB_AD: null, FB_BH: null, AS_TOT: null, AS_CP: null, AS_AD: null, AS_BH: null, PE1_TOT: 8,   PE1_CP: null, PE1_AD: null, PE1_BH: null, PT_TOT: 13,  PT_CP: null, PT_AD: null, PT_BH: null, PE2_TOT: 10,  PE2_CP: null, PE2_AD: null, PE2_BH: null, ER_TOT: 5,  ER_CP: null, ER_AD: null, ER_BH: null, },
      { AREA: 'HULL',    ZONE: 'CAISION/RISER', SC_TOT: 4,   SC_CP: null, SC_AD: null, SC_BH: null, FB_TOT: 4,   FB_CP: null, FB_AD: null, FB_BH: null, AS_TOT: null, AS_CP: null, AS_AD: null, AS_BH: null, PE1_TOT: 4,   PE1_CP: null, PE1_AD: null, PE1_BH: null, PT_TOT: 4,   PT_CP: null, PT_AD: null, PT_BH: null, PE2_TOT: 6,   PE2_CP: null, PE2_AD: null, PE2_BH: null, ER_TOT: 6,  ER_CP: null, ER_AD: null, ER_BH: null, },
      { AREA: 'HULL',    ZONE: 'OVERALL',       SC_TOT: 130, SC_CP: 35,   SC_AD: 4,    SC_BH: 2,    FB_TOT: 130, FB_CP: 18,   FB_AD: 0,    FB_BH: 13,   AS_TOT: 132,  AS_CP: 1,    AS_AD: 49,   AS_BH: 28,   PE1_TOT: 130, PE1_CP: 0,    PE1_AD: 0,    PE1_BH: 3,    PT_TOT: 154, PT_CP: null, PT_AD: null, PT_BH: null, PE2_TOT: 66,  PE2_CP: null, PE2_AD: null, PE2_BH: null, ER_TOT: 51, ER_CP: null, ER_AD: null, ER_BH: null, },
      { AREA: 'TOPSIDE', ZONE: 'NORTH',         SC_TOT: 98,  SC_CP: null, SC_AD: null, SC_BH: null, FB_TOT: 45,  FB_CP: null, FB_AD: null, FB_BH: null, AS_TOT: 88,   AS_CP: null, AS_AD: null, AS_BH: null, PE1_TOT: 10,  PE1_CP: null, PE1_AD: null, PE1_BH: null, PT_TOT: 70,  PT_CP: null, PT_AD: null, PT_BH: null, PE2_TOT: 68,  PE2_CP: null, PE2_AD: null, PE2_BH: null, ER_TOT: 10, ER_CP: null, ER_AD: null, ER_BH: null, },
      { AREA: 'TOPSIDE', ZONE: 'CENTRAL',       SC_TOT: 91,  SC_CP: 2,    SC_AD: 0,    SC_BH: 14,   FB_TOT: 48,  FB_CP: 0,    FB_AD: 0,    FB_BH: 15,   AS_TOT: 80,   AS_CP: null, AS_AD: null, AS_BH: null, PE1_TOT: 14,  PE1_CP: null, PE1_AD: null, PE1_BH: null, PT_TOT: 69,  PT_CP: null, PT_AD: null, PT_BH: null, PE2_TOT: 64,  PE2_CP: null, PE2_AD: null, PE2_BH: null, ER_TOT: 14, ER_CP: null, ER_AD: null, ER_BH: null, },
      { AREA: 'TOPSIDE', ZONE: 'SOUTH',         SC_TOT: 95,  SC_CP: 3,    SC_AD: 1,    SC_BH: 9,    FB_TOT: 44,  FB_CP: 0,    FB_AD: 0,    FB_BH: 3,    AS_TOT: 83,   AS_CP: null, AS_AD: null, AS_BH: null, PE1_TOT: 8,   PE1_CP: null, PE1_AD: null, PE1_BH: null, PT_TOT: 55,  PT_CP: null, PT_AD: null, PT_BH: null, PE2_TOT: 52,  PE2_CP: null, PE2_AD: null, PE2_BH: null, ER_TOT: 7,  ER_CP: null, ER_AD: null, ER_BH: null, },
      { AREA: 'TOPSIDE', ZONE: 'FLARE',         SC_TOT: 6,   SC_CP: null, SC_AD: null, SC_BH: null, FB_TOT: 6,   FB_CP: null, FB_AD: null, FB_BH: null, AS_TOT: 4,    AS_CP: null, AS_AD: null, AS_BH: null, PE1_TOT: 3,   PE1_CP: null, PE1_AD: null, PE1_BH: null, PT_TOT: 3,   PT_CP: null, PT_AD: null, PT_BH: null, PE2_TOT: 3,   PE2_CP: null, PE2_AD: null, PE2_BH: null, ER_TOT: 1,  ER_CP: null, ER_AD: null, ER_BH: null, },
      { AREA: 'TOPSIDE', ZONE: 'OVERALL',       SC_TOT: 290, SC_CP: 5,    SC_AD: 1,    SC_BH: 23,   FB_TOT: 143, FB_CP: 0,    FB_AD: 0,    FB_BH: 18,   AS_TOT: 255,  AS_CP: null, AS_AD: null, AS_BH: null, PE1_TOT: 35,  PE1_CP: null, PE1_AD: null, PE1_BH: null, PT_TOT: 197, PT_CP: null, PT_AD: null, PT_BH: null, PE2_TOT: 187, PE2_CP: null, PE2_AD: null, PE2_BH: null, ER_TOT: 32, ER_CP: null, ER_AD: null, ER_BH: null, },
      { AREA: 'LQ',      ZONE: 'OVERALL',       SC_TOT: 39,  SC_CP: 31,   SC_AD: 1,    SC_BH: 4,    FB_TOT: 23,  FB_CP: 18,   FB_AD: 0,    FB_BH: 4,    AS_TOT: 33,   AS_CP: 6,    AS_AD: 4,    AS_BH: 14,   PE1_TOT: 13,  PE1_CP: 0,    PE1_AD: 1,    PE1_BH: 0,    PT_TOT: 25,  PT_CP: null, PT_AD: null, PT_BH: null, PE2_TOT: 24,  PE2_CP: null, PE2_AD: null, PE2_BH: null, ER_TOT: 5,  ER_CP: null, ER_AD: null, ER_BH: null, },
      { AREA: 'OVERALL', ZONE: 'OVERALL',       SC_TOT: 459, SC_CP: 71,   SC_AD: 6,    SC_BH: 29,   FB_TOT: 296, FB_CP: 36,   FB_AD: 0,    FB_BH: 35,   AS_TOT: 420,  AS_CP: 7,    AS_AD: 53,   AS_BH: 42,   PE1_TOT: 178, PE1_CP: 0,    PE1_AD: 1,    PE1_BH: 3,    PT_TOT: 376, PT_CP: null, PT_AD: null, PT_BH: null, PE2_TOT: 277, PE2_CP: null, PE2_AD: null, PE2_BH: null, ER_TOT: 88, ER_CP: null, ER_AD: null, ER_BH: null, },
      ],

  },
}