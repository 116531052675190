import __C from '../../../../../../includes/primitives/_constant_'

export default {
    Canvas: {
      CanvasWidth: 1400,
      CanvasHeight: 660,
      CanvasBorderWeight: .5,
      CanvasBorderColor: '#E0E0DF',
      CanvasFillColor: '#fff',
      CanvasOpacity: 1,

      CanvasChartX: 0,
      CanvasChartY: 0,
      CanvasChartWidth: 400,
      CanvasChartHeight: 200,
      CanvasChartBorderWeight: 0,
      CanvasChartBorderColor: '#757575',
      CanvasChartFillColor: '#757575',
      CanvasChartOpacity: 0,
      CanvasChartSpace: 0,

      CanvasFontFamily: 'roboto',
    },
    
    Text: {
      Text: [],
      Filter: [],
      Sql: []
    },

    DataItems: [
      { CDATE: '2023-07-28', PHASE: 'Engineering',   GROUP: 'Detail Eng',     SUBCONT: 'Exmar',        TOTAL: 5228.3,  P_WK: 166.9,  A_WK: 166.9,  D_WK: 0,    P_CUM: 166.9,   A_CUM: 166.9,   D_CUM: 0, },
{ CDATE: '2023-08-25', PHASE: 'Engineering',   GROUP: 'Detail Eng',     SUBCONT: 'Exmar',        TOTAL: 5228.3,  P_WK: 196.3,  A_WK: 195.8,  D_WK: -1,   P_CUM: 363.2,   A_CUM: 362.7,   D_CUM: -1, },
{ CDATE: '2023-09-29', PHASE: 'Engineering',   GROUP: 'Detail Eng',     SUBCONT: 'Exmar',        TOTAL: 5228.3,  P_WK: 444.7,  A_WK: 276.5,  D_WK: -168, P_CUM: 807.9,   A_CUM: 639.2,   D_CUM: -169, },
{ CDATE: '2023-10-27', PHASE: 'Engineering',   GROUP: 'Detail Eng',     SUBCONT: 'Exmar',        TOTAL: 5228.3,  P_WK: 367.8,  A_WK: 264.3,  D_WK: -104, P_CUM: 1175.7,  A_CUM: 903.5,   D_CUM: -272, },
{ CDATE: '2023-11-24', PHASE: 'Engineering',   GROUP: 'Detail Eng',     SUBCONT: 'Exmar',        TOTAL: 5228.3,  P_WK: 350.6,  A_WK: 266.9,  D_WK: -84,  P_CUM: 1526.3,  A_CUM: 1170.4,  D_CUM: -356, },
{ CDATE: '2023-12-29', PHASE: 'Engineering',   GROUP: 'Detail Eng',     SUBCONT: 'Exmar',        TOTAL: 5228.3,  P_WK: 440.5,  A_WK: 345.8,  D_WK: -95,  P_CUM: 1966.8,  A_CUM: 1516.2,  D_CUM: -451, },
{ CDATE: '2024-01-26', PHASE: 'Engineering',   GROUP: 'Detail Eng',     SUBCONT: 'Exmar',        TOTAL: 5228.3,  P_WK: 419.9,  A_WK: 328.1,  D_WK: -92,  P_CUM: 2386.7,  A_CUM: 1844.3,  D_CUM: -542, },
{ CDATE: '2024-02-23', PHASE: 'Engineering',   GROUP: 'Detail Eng',     SUBCONT: 'Exmar',        TOTAL: 5228.3,  P_WK: 407.7,  A_WK: 392.3,  D_WK: -15,  P_CUM: 2794.4,  A_CUM: 2236.6,  D_CUM: -558, },
{ CDATE: '2024-03-29', PHASE: 'Engineering',   GROUP: 'Detail Eng',     SUBCONT: 'Exmar',        TOTAL: 5228.3,  P_WK: 440,    A_WK: 483.7,  D_WK: 44,   P_CUM: 3234.4,  A_CUM: 2720.3,  D_CUM: -514, },
{ CDATE: '2024-04-26', PHASE: 'Engineering',   GROUP: 'Detail Eng',     SUBCONT: 'Exmar',        TOTAL: 5228.3,  P_WK: 353.4,  A_WK: 366.8,  D_WK: 13,   P_CUM: 3587.8,  A_CUM: 3087.1,  D_CUM: -501, },
{ CDATE: '2024-05-31', PHASE: 'Engineering',   GROUP: 'Detail Eng',     SUBCONT: 'Exmar',        TOTAL: 5228.3,  P_WK: 387.9,  A_WK: 361.8,  D_WK: -26,  P_CUM: 3975.7,  A_CUM: 3448.9,  D_CUM: -527, },
{ CDATE: '2024-06-28', PHASE: 'Engineering',   GROUP: 'Detail Eng',     SUBCONT: 'Exmar',        TOTAL: 5228.3,  P_WK: 211.6,  A_WK: 275.9,  D_WK: 64,   P_CUM: 4187.3,  A_CUM: 3724.8,  D_CUM: -462, },
{ CDATE: '2024-07-26', PHASE: 'Engineering',   GROUP: 'Detail Eng',     SUBCONT: 'Exmar',        TOTAL: 5228.3,  P_WK: 169.4,  A_WK: 229.9,  D_WK: 61,   P_CUM: 4356.7,  A_CUM: 3954.7,  D_CUM: -402, },
{ CDATE: '2024-08-30', PHASE: 'Engineering',   GROUP: 'Detail Eng',     SUBCONT: 'Exmar',        TOTAL: 5228.3,  P_WK: 179.8,  A_WK: 286.9,  D_WK: 107,  P_CUM: 4536.5,  A_CUM: 4241.6,  D_CUM: -295, },
{ CDATE: '2024-09-27', PHASE: 'Engineering',   GROUP: 'Detail Eng',     SUBCONT: 'Exmar',        TOTAL: 5228.3,  P_WK: 125,    A_WK: 227,    D_WK: 102,  P_CUM: 4661.5,  A_CUM: 4468.6,  D_CUM: -193, },
{ CDATE: '2024-10-25', PHASE: 'Engineering',   GROUP: 'Detail Eng',     SUBCONT: 'Exmar',        TOTAL: 5228.3,  P_WK: 89.4,   A_WK: 205.4,  D_WK: 116,  P_CUM: 4750.9,  A_CUM: 4674,    D_CUM: -77, },
{ CDATE: '2024-11-29', PHASE: 'Engineering',   GROUP: 'Detail Eng',     SUBCONT: 'Exmar',        TOTAL: 5228.3,  P_WK: 163.4,  A_WK: 243.5,  D_WK: 80,   P_CUM: 4914.3,  A_CUM: 4917.5,  D_CUM: 3, },
{ CDATE: '2024-12-27', PHASE: 'Engineering',   GROUP: 'Detail Eng',     SUBCONT: 'Exmar',        TOTAL: 5228.3,  P_WK: 116,    A_WK: 0,      D_WK: 0,    P_CUM: 5030.3,  A_CUM: 4917.5,  D_CUM: 0, },
{ CDATE: '2025-01-31', PHASE: 'Engineering',   GROUP: 'Detail Eng',     SUBCONT: 'Exmar',        TOTAL: 5228.3,  P_WK: 53.5,   A_WK: 0,      D_WK: 0,    P_CUM: 5083.8,  A_CUM: 4917.5,  D_CUM: 0, },
{ CDATE: '2025-02-28', PHASE: 'Engineering',   GROUP: 'Detail Eng',     SUBCONT: 'Exmar',        TOTAL: 5228.3,  P_WK: 18.6,   A_WK: 0,      D_WK: 0,    P_CUM: 5102.4,  A_CUM: 4917.5,  D_CUM: 0, },
{ CDATE: '2025-03-28', PHASE: 'Engineering',   GROUP: 'Detail Eng',     SUBCONT: 'Exmar',        TOTAL: 5228.3,  P_WK: 12.6,   A_WK: 0,      D_WK: 0,    P_CUM: 5115,    A_CUM: 4917.5,  D_CUM: 0, },
{ CDATE: '2025-04-25', PHASE: 'Engineering',   GROUP: 'Detail Eng',     SUBCONT: 'Exmar',        TOTAL: 5228.3,  P_WK: 8.2,    A_WK: 0,      D_WK: 0,    P_CUM: 5123.2,  A_CUM: 4917.5,  D_CUM: 0, },
{ CDATE: '2025-05-30', PHASE: 'Engineering',   GROUP: 'Detail Eng',     SUBCONT: 'Exmar',        TOTAL: 5228.3,  P_WK: 5.3,    A_WK: 0,      D_WK: 0,    P_CUM: 5128.5,  A_CUM: 4917.5,  D_CUM: 0, },
{ CDATE: '2025-06-27', PHASE: 'Engineering',   GROUP: 'Detail Eng',     SUBCONT: 'Exmar',        TOTAL: 5228.3,  P_WK: 4.2,    A_WK: 0,      D_WK: 0,    P_CUM: 5132.7,  A_CUM: 4917.5,  D_CUM: 0, },
{ CDATE: '2025-07-25', PHASE: 'Engineering',   GROUP: 'Detail Eng',     SUBCONT: 'Exmar',        TOTAL: 5228.3,  P_WK: 4.2,    A_WK: 0,      D_WK: 0,    P_CUM: 5136.9,  A_CUM: 4917.5,  D_CUM: 0, },
{ CDATE: '2025-08-29', PHASE: 'Engineering',   GROUP: 'Detail Eng',     SUBCONT: 'Exmar',        TOTAL: 5228.3,  P_WK: 5.3,    A_WK: 0,      D_WK: 0,    P_CUM: 5142.2,  A_CUM: 4917.5,  D_CUM: 0, },
{ CDATE: '2025-09-26', PHASE: 'Engineering',   GROUP: 'Detail Eng',     SUBCONT: 'Exmar',        TOTAL: 5228.3,  P_WK: 4.2,    A_WK: 0,      D_WK: 0,    P_CUM: 5146.4,  A_CUM: 4917.5,  D_CUM: 0, },
{ CDATE: '2025-10-31', PHASE: 'Engineering',   GROUP: 'Detail Eng',     SUBCONT: 'Exmar',        TOTAL: 5228.3,  P_WK: 5.3,    A_WK: 0,      D_WK: 0,    P_CUM: 5151.7,  A_CUM: 4917.5,  D_CUM: 0, },
{ CDATE: '2025-11-28', PHASE: 'Engineering',   GROUP: 'Detail Eng',     SUBCONT: 'Exmar',        TOTAL: 5228.3,  P_WK: 4.2,    A_WK: 0,      D_WK: 0,    P_CUM: 5155.9,  A_CUM: 4917.5,  D_CUM: 0, },
{ CDATE: '2025-12-26', PHASE: 'Engineering',   GROUP: 'Detail Eng',     SUBCONT: 'Exmar',        TOTAL: 5228.3,  P_WK: 4.2,    A_WK: 0,      D_WK: 0,    P_CUM: 5160.1,  A_CUM: 4917.5,  D_CUM: 0, },
{ CDATE: '2026-01-30', PHASE: 'Engineering',   GROUP: 'Detail Eng',     SUBCONT: 'Exmar',        TOTAL: 5228.3,  P_WK: 5.3,    A_WK: 0,      D_WK: 0,    P_CUM: 5165.4,  A_CUM: 4917.5,  D_CUM: 0, },
{ CDATE: '2026-02-27', PHASE: 'Engineering',   GROUP: 'Detail Eng',     SUBCONT: 'Exmar',        TOTAL: 5228.3,  P_WK: 4.2,    A_WK: 0,      D_WK: 0,    P_CUM: 5169.6,  A_CUM: 4917.5,  D_CUM: 0, },
{ CDATE: '2026-03-27', PHASE: 'Engineering',   GROUP: 'Detail Eng',     SUBCONT: 'Exmar',        TOTAL: 5228.3,  P_WK: 4.2,    A_WK: 0,      D_WK: 0,    P_CUM: 5173.8,  A_CUM: 4917.5,  D_CUM: 0, },
{ CDATE: '2026-04-24', PHASE: 'Engineering',   GROUP: 'Detail Eng',     SUBCONT: 'Exmar',        TOTAL: 5228.3,  P_WK: 4.2,    A_WK: 0,      D_WK: 0,    P_CUM: 5178,    A_CUM: 4917.5,  D_CUM: 0, },
{ CDATE: '2026-05-29', PHASE: 'Engineering',   GROUP: 'Detail Eng',     SUBCONT: 'Exmar',        TOTAL: 5228.3,  P_WK: 5.3,    A_WK: 0,      D_WK: 0,    P_CUM: 5183.3,  A_CUM: 4917.5,  D_CUM: 0, },
{ CDATE: '2026-06-26', PHASE: 'Engineering',   GROUP: 'Detail Eng',     SUBCONT: 'Exmar',        TOTAL: 5228.3,  P_WK: 4.2,    A_WK: 0,      D_WK: 0,    P_CUM: 5187.5,  A_CUM: 4917.5,  D_CUM: 0, },
{ CDATE: '2026-07-31', PHASE: 'Engineering',   GROUP: 'Detail Eng',     SUBCONT: 'Exmar',        TOTAL: 5228.3,  P_WK: 5.3,    A_WK: 0,      D_WK: 0,    P_CUM: 5192.8,  A_CUM: 4917.5,  D_CUM: 0, },
{ CDATE: '2026-08-28', PHASE: 'Engineering',   GROUP: 'Detail Eng',     SUBCONT: 'Exmar',        TOTAL: 5228.3,  P_WK: 4.2,    A_WK: 0,      D_WK: 0,    P_CUM: 5197,    A_CUM: 4917.5,  D_CUM: 0, },
{ CDATE: '2026-09-25', PHASE: 'Engineering',   GROUP: 'Detail Eng',     SUBCONT: 'Exmar',        TOTAL: 5228.3,  P_WK: 4.2,    A_WK: 0,      D_WK: 0,    P_CUM: 5201.2,  A_CUM: 4917.5,  D_CUM: 0, },
{ CDATE: '2026-10-30', PHASE: 'Engineering',   GROUP: 'Detail Eng',     SUBCONT: 'Exmar',        TOTAL: 5228.3,  P_WK: 5.3,    A_WK: 0,      D_WK: 0,    P_CUM: 5206.5,  A_CUM: 4917.5,  D_CUM: 0, },
{ CDATE: '2026-11-27', PHASE: 'Engineering',   GROUP: 'Detail Eng',     SUBCONT: 'Exmar',        TOTAL: 5228.3,  P_WK: 4.2,    A_WK: 0,      D_WK: 0,    P_CUM: 5210.7,  A_CUM: 4917.5,  D_CUM: 0, },
{ CDATE: '2026-12-25', PHASE: 'Engineering',   GROUP: 'Detail Eng',     SUBCONT: 'Exmar',        TOTAL: 5228.3,  P_WK: 4.2,    A_WK: 0,      D_WK: 0,    P_CUM: 5214.9,  A_CUM: 4917.5,  D_CUM: 0, },
{ CDATE: '2027-01-29', PHASE: 'Engineering',   GROUP: 'Detail Eng',     SUBCONT: 'Exmar',        TOTAL: 5228.3,  P_WK: 5.3,    A_WK: 0,      D_WK: 0,    P_CUM: 5220.2,  A_CUM: 4917.5,  D_CUM: 0, },
{ CDATE: '2027-02-26', PHASE: 'Engineering',   GROUP: 'Detail Eng',     SUBCONT: 'Exmar',        TOTAL: 5228.3,  P_WK: 4.2,    A_WK: 0,      D_WK: 0,    P_CUM: 5224.4,  A_CUM: 4917.5,  D_CUM: 0, },
{ CDATE: '2027-03-26', PHASE: 'Engineering',   GROUP: 'Detail Eng',     SUBCONT: 'Exmar',        TOTAL: 5228.3,  P_WK: 4.2,    A_WK: 0,      D_WK: 0,    P_CUM: 5228.6,  A_CUM: 4917.5,  D_CUM: 0, },
{ CDATE: '2027-04-30', PHASE: 'Engineering',   GROUP: 'Detail Eng',     SUBCONT: 'Exmar',        TOTAL: 5228.3,  P_WK: 0,      A_WK: 0,      D_WK: 0,    P_CUM: 5228.6,  A_CUM: 4917.5,  D_CUM: 0, },
{ CDATE: '2027-05-28', PHASE: 'Engineering',   GROUP: 'Detail Eng',     SUBCONT: 'Exmar',        TOTAL: 5228.3,  P_WK: 0,      A_WK: 0,      D_WK: 0,    P_CUM: 5228.6,  A_CUM: 4917.5,  D_CUM: 0, },
{ CDATE: '2027-06-11', PHASE: 'Engineering',   GROUP: 'Detail Eng',     SUBCONT: 'Exmar',        TOTAL: 5228.3,  P_WK: 0,      A_WK: 0,      D_WK: 0,    P_CUM: 5228.6,  A_CUM: 4917.5,  D_CUM: 0, },
{ CDATE: '2023-07-28', PHASE: 'Engineering',   GROUP: 'Detail Eng',     SUBCONT: 'HHI',          TOTAL: 9460.6,  P_WK: 179,    A_WK: 110.5,  D_WK: -69,  P_CUM: 179,     A_CUM: 110.5,   D_CUM: -69, },
{ CDATE: '2023-08-25', PHASE: 'Engineering',   GROUP: 'Detail Eng',     SUBCONT: 'HHI',          TOTAL: 9460.6,  P_WK: 178,    A_WK: 109.7,  D_WK: -68,  P_CUM: 357,     A_CUM: 220.2,   D_CUM: -137, },
{ CDATE: '2023-09-29', PHASE: 'Engineering',   GROUP: 'Detail Eng',     SUBCONT: 'HHI',          TOTAL: 9460.6,  P_WK: 283,    A_WK: 259.1,  D_WK: -24,  P_CUM: 640,     A_CUM: 479.3,   D_CUM: -161, },
{ CDATE: '2023-10-27', PHASE: 'Engineering',   GROUP: 'Detail Eng',     SUBCONT: 'HHI',          TOTAL: 9460.6,  P_WK: 248,    A_WK: 231.8,  D_WK: -16,  P_CUM: 888,     A_CUM: 711.1,   D_CUM: -177, },
{ CDATE: '2023-11-24', PHASE: 'Engineering',   GROUP: 'Detail Eng',     SUBCONT: 'HHI',          TOTAL: 9460.6,  P_WK: 260,    A_WK: 231.8,  D_WK: -28,  P_CUM: 1148,    A_CUM: 942.9,   D_CUM: -205, },
{ CDATE: '2023-12-29', PHASE: 'Engineering',   GROUP: 'Detail Eng',     SUBCONT: 'HHI',          TOTAL: 9460.6,  P_WK: 346,    A_WK: 330.1,  D_WK: -16,  P_CUM: 1494,    A_CUM: 1273,    D_CUM: -221, },
{ CDATE: '2024-01-26', PHASE: 'Engineering',   GROUP: 'Detail Eng',     SUBCONT: 'HHI',          TOTAL: 9460.6,  P_WK: 320,    A_WK: 303.2,  D_WK: -17,  P_CUM: 1814,    A_CUM: 1576.2,  D_CUM: -238, },
{ CDATE: '2024-02-23', PHASE: 'Engineering',   GROUP: 'Detail Eng',     SUBCONT: 'HHI',          TOTAL: 9460.6,  P_WK: 348,    A_WK: 327.1,  D_WK: -21,  P_CUM: 2162,    A_CUM: 1903.3,  D_CUM: -259, },
{ CDATE: '2024-03-29', PHASE: 'Engineering',   GROUP: 'Detail Eng',     SUBCONT: 'HHI',          TOTAL: 9460.6,  P_WK: 495,    A_WK: 464.4,  D_WK: -31,  P_CUM: 2657,    A_CUM: 2367.7,  D_CUM: -289, },
{ CDATE: '2024-04-26', PHASE: 'Engineering',   GROUP: 'Detail Eng',     SUBCONT: 'HHI',          TOTAL: 9460.6,  P_WK: 464,    A_WK: 497.7,  D_WK: 34,   P_CUM: 3121,    A_CUM: 2865.4,  D_CUM: -256, },
{ CDATE: '2024-05-31', PHASE: 'Engineering',   GROUP: 'Detail Eng',     SUBCONT: 'HHI',          TOTAL: 9460.6,  P_WK: 606,    A_WK: 692.4,  D_WK: 86,   P_CUM: 3727,    A_CUM: 3557.8,  D_CUM: -169, },
{ CDATE: '2024-06-28', PHASE: 'Engineering',   GROUP: 'Detail Eng',     SUBCONT: 'HHI',          TOTAL: 9460.6,  P_WK: 533,    A_WK: 658,    D_WK: 125,  P_CUM: 4260,    A_CUM: 4215.8,  D_CUM: -44, },
{ CDATE: '2024-07-26', PHASE: 'Engineering',   GROUP: 'Detail Eng',     SUBCONT: 'HHI',          TOTAL: 9460.6,  P_WK: 681.3,  A_WK: 702.5,  D_WK: 21,   P_CUM: 4941.3,  A_CUM: 4918.3,  D_CUM: -23, },
{ CDATE: '2024-08-30', PHASE: 'Engineering',   GROUP: 'Detail Eng',     SUBCONT: 'HHI',          TOTAL: 9460.6,  P_WK: 735.9,  A_WK: 701.1,  D_WK: -35,  P_CUM: 5677.2,  A_CUM: 5619.4,  D_CUM: -58, },
{ CDATE: '2024-09-27', PHASE: 'Engineering',   GROUP: 'Detail Eng',     SUBCONT: 'HHI',          TOTAL: 9460.6,  P_WK: 660.6,  A_WK: 636,    D_WK: -25,  P_CUM: 6337.8,  A_CUM: 6255.4,  D_CUM: -82, },
{ CDATE: '2024-10-25', PHASE: 'Engineering',   GROUP: 'Detail Eng',     SUBCONT: 'HHI',          TOTAL: 9460.6,  P_WK: 586.8,  A_WK: 619.9,  D_WK: 33,   P_CUM: 6924.6,  A_CUM: 6875.3,  D_CUM: -49, },
{ CDATE: '2024-11-29', PHASE: 'Engineering',   GROUP: 'Detail Eng',     SUBCONT: 'HHI',          TOTAL: 9460.6,  P_WK: 633.3,  A_WK: 788.9,  D_WK: 156,  P_CUM: 7557.9,  A_CUM: 7664.2,  D_CUM: 106, },
{ CDATE: '2024-12-27', PHASE: 'Engineering',   GROUP: 'Detail Eng',     SUBCONT: 'HHI',          TOTAL: 9460.6,  P_WK: 458.4,  A_WK: 0,      D_WK: 0,    P_CUM: 8016.3,  A_CUM: 7664.2,  D_CUM: 0, },
{ CDATE: '2025-01-31', PHASE: 'Engineering',   GROUP: 'Detail Eng',     SUBCONT: 'HHI',          TOTAL: 9460.6,  P_WK: 504.6,  A_WK: 0,      D_WK: 0,    P_CUM: 8520.9,  A_CUM: 7664.2,  D_CUM: 0, },
{ CDATE: '2025-02-28', PHASE: 'Engineering',   GROUP: 'Detail Eng',     SUBCONT: 'HHI',          TOTAL: 9460.6,  P_WK: 352.6,  A_WK: 0,      D_WK: 0,    P_CUM: 8873.5,  A_CUM: 7664.2,  D_CUM: 0, },
{ CDATE: '2025-03-28', PHASE: 'Engineering',   GROUP: 'Detail Eng',     SUBCONT: 'HHI',          TOTAL: 9460.6,  P_WK: 243.1,  A_WK: 0,      D_WK: 0,    P_CUM: 9116.6,  A_CUM: 7664.2,  D_CUM: 0, },
{ CDATE: '2025-04-25', PHASE: 'Engineering',   GROUP: 'Detail Eng',     SUBCONT: 'HHI',          TOTAL: 9460.6,  P_WK: 195.6,  A_WK: 0,      D_WK: 0,    P_CUM: 9312.2,  A_CUM: 7664.2,  D_CUM: 0, },
{ CDATE: '2025-05-30', PHASE: 'Engineering',   GROUP: 'Detail Eng',     SUBCONT: 'HHI',          TOTAL: 9460.6,  P_WK: 148.4,  A_WK: 0,      D_WK: 0,    P_CUM: 9460.6,  A_CUM: 7664.2,  D_CUM: 0, },
{ CDATE: '2025-06-27', PHASE: 'Engineering',   GROUP: 'Detail Eng',     SUBCONT: 'HHI',          TOTAL: 9460.6,  P_WK: 0,      A_WK: 0,      D_WK: 0,    P_CUM: 9460.6,  A_CUM: 7664.2,  D_CUM: 0, },
{ CDATE: '2025-07-25', PHASE: 'Engineering',   GROUP: 'Detail Eng',     SUBCONT: 'HHI',          TOTAL: 9460.6,  P_WK: 0,      A_WK: 0,      D_WK: 0,    P_CUM: 9460.6,  A_CUM: 7664.2,  D_CUM: 0, },
{ CDATE: '2025-08-29', PHASE: 'Engineering',   GROUP: 'Detail Eng',     SUBCONT: 'HHI',          TOTAL: 9460.6,  P_WK: 0,      A_WK: 0,      D_WK: 0,    P_CUM: 9460.6,  A_CUM: 7664.2,  D_CUM: 0, },
{ CDATE: '2025-09-26', PHASE: 'Engineering',   GROUP: 'Detail Eng',     SUBCONT: 'HHI',          TOTAL: 9460.6,  P_WK: 0,      A_WK: 0,      D_WK: 0,    P_CUM: 9460.6,  A_CUM: 7664.2,  D_CUM: 0, },
{ CDATE: '2025-10-31', PHASE: 'Engineering',   GROUP: 'Detail Eng',     SUBCONT: 'HHI',          TOTAL: 9460.6,  P_WK: 0,      A_WK: 0,      D_WK: 0,    P_CUM: 9460.6,  A_CUM: 7664.2,  D_CUM: 0, },
{ CDATE: '2025-11-28', PHASE: 'Engineering',   GROUP: 'Detail Eng',     SUBCONT: 'HHI',          TOTAL: 9460.6,  P_WK: 0,      A_WK: 0,      D_WK: 0,    P_CUM: 9460.6,  A_CUM: 7664.2,  D_CUM: 0, },
{ CDATE: '2025-12-26', PHASE: 'Engineering',   GROUP: 'Detail Eng',     SUBCONT: 'HHI',          TOTAL: 9460.6,  P_WK: 0,      A_WK: 0,      D_WK: 0,    P_CUM: 9460.6,  A_CUM: 7664.2,  D_CUM: 0, },
{ CDATE: '2026-01-30', PHASE: 'Engineering',   GROUP: 'Detail Eng',     SUBCONT: 'HHI',          TOTAL: 9460.6,  P_WK: 0,      A_WK: 0,      D_WK: 0,    P_CUM: 9460.6,  A_CUM: 7664.2,  D_CUM: 0, },
{ CDATE: '2026-02-27', PHASE: 'Engineering',   GROUP: 'Detail Eng',     SUBCONT: 'HHI',          TOTAL: 9460.6,  P_WK: 0,      A_WK: 0,      D_WK: 0,    P_CUM: 9460.6,  A_CUM: 7664.2,  D_CUM: 0, },
{ CDATE: '2026-03-27', PHASE: 'Engineering',   GROUP: 'Detail Eng',     SUBCONT: 'HHI',          TOTAL: 9460.6,  P_WK: 0,      A_WK: 0,      D_WK: 0,    P_CUM: 9460.6,  A_CUM: 7664.2,  D_CUM: 0, },
{ CDATE: '2026-04-24', PHASE: 'Engineering',   GROUP: 'Detail Eng',     SUBCONT: 'HHI',          TOTAL: 9460.6,  P_WK: 0,      A_WK: 0,      D_WK: 0,    P_CUM: 9460.6,  A_CUM: 7664.2,  D_CUM: 0, },
{ CDATE: '2026-05-29', PHASE: 'Engineering',   GROUP: 'Detail Eng',     SUBCONT: 'HHI',          TOTAL: 9460.6,  P_WK: 0,      A_WK: 0,      D_WK: 0,    P_CUM: 9460.6,  A_CUM: 7664.2,  D_CUM: 0, },
{ CDATE: '2026-06-26', PHASE: 'Engineering',   GROUP: 'Detail Eng',     SUBCONT: 'HHI',          TOTAL: 9460.6,  P_WK: 0,      A_WK: 0,      D_WK: 0,    P_CUM: 9460.6,  A_CUM: 7664.2,  D_CUM: 0, },
{ CDATE: '2026-07-31', PHASE: 'Engineering',   GROUP: 'Detail Eng',     SUBCONT: 'HHI',          TOTAL: 9460.6,  P_WK: 0,      A_WK: 0,      D_WK: 0,    P_CUM: 9460.6,  A_CUM: 7664.2,  D_CUM: 0, },
{ CDATE: '2026-08-28', PHASE: 'Engineering',   GROUP: 'Detail Eng',     SUBCONT: 'HHI',          TOTAL: 9460.6,  P_WK: 0,      A_WK: 0,      D_WK: 0,    P_CUM: 9460.6,  A_CUM: 7664.2,  D_CUM: 0, },
{ CDATE: '2026-09-25', PHASE: 'Engineering',   GROUP: 'Detail Eng',     SUBCONT: 'HHI',          TOTAL: 9460.6,  P_WK: 0,      A_WK: 0,      D_WK: 0,    P_CUM: 9460.6,  A_CUM: 7664.2,  D_CUM: 0, },
{ CDATE: '2026-10-30', PHASE: 'Engineering',   GROUP: 'Detail Eng',     SUBCONT: 'HHI',          TOTAL: 9460.6,  P_WK: 0,      A_WK: 0,      D_WK: 0,    P_CUM: 9460.6,  A_CUM: 7664.2,  D_CUM: 0, },
{ CDATE: '2026-11-27', PHASE: 'Engineering',   GROUP: 'Detail Eng',     SUBCONT: 'HHI',          TOTAL: 9460.6,  P_WK: 0,      A_WK: 0,      D_WK: 0,    P_CUM: 9460.6,  A_CUM: 7664.2,  D_CUM: 0, },
{ CDATE: '2026-12-25', PHASE: 'Engineering',   GROUP: 'Detail Eng',     SUBCONT: 'HHI',          TOTAL: 9460.6,  P_WK: 0,      A_WK: 0,      D_WK: 0,    P_CUM: 9460.6,  A_CUM: 7664.2,  D_CUM: 0, },
{ CDATE: '2027-01-29', PHASE: 'Engineering',   GROUP: 'Detail Eng',     SUBCONT: 'HHI',          TOTAL: 9460.6,  P_WK: 0,      A_WK: 0,      D_WK: 0,    P_CUM: 9460.6,  A_CUM: 7664.2,  D_CUM: 0, },
{ CDATE: '2027-02-26', PHASE: 'Engineering',   GROUP: 'Detail Eng',     SUBCONT: 'HHI',          TOTAL: 9460.6,  P_WK: 0,      A_WK: 0,      D_WK: 0,    P_CUM: 9460.6,  A_CUM: 7664.2,  D_CUM: 0, },
{ CDATE: '2027-03-26', PHASE: 'Engineering',   GROUP: 'Detail Eng',     SUBCONT: 'HHI',          TOTAL: 9460.6,  P_WK: 0,      A_WK: 0,      D_WK: 0,    P_CUM: 9460.6,  A_CUM: 7664.2,  D_CUM: 0, },
{ CDATE: '2027-04-30', PHASE: 'Engineering',   GROUP: 'Detail Eng',     SUBCONT: 'HHI',          TOTAL: 9460.6,  P_WK: 0,      A_WK: 0,      D_WK: 0,    P_CUM: 9460.6,  A_CUM: 7664.2,  D_CUM: 0, },
{ CDATE: '2027-05-28', PHASE: 'Engineering',   GROUP: 'Detail Eng',     SUBCONT: 'HHI',          TOTAL: 9460.6,  P_WK: 0,      A_WK: 0,      D_WK: 0,    P_CUM: 9460.6,  A_CUM: 7664.2,  D_CUM: 0, },
{ CDATE: '2027-06-11', PHASE: 'Engineering',   GROUP: 'Detail Eng',     SUBCONT: 'HHI',          TOTAL: 9460.6,  P_WK: 0,      A_WK: 0,      D_WK: 0,    P_CUM: 9460.6,  A_CUM: 7664.2,  D_CUM: 0, },
{ CDATE: '2023-07-28', PHASE: 'Engineering',   GROUP: 'Detail Eng',     SUBCONT: 'OVERALL',      TOTAL: 32362.2, P_WK: 648.3,  A_WK: 583.2,  D_WK: -65,  P_CUM: 648.3,   A_CUM: 583.2,   D_CUM: -65, },
{ CDATE: '2023-08-25', PHASE: 'Engineering',   GROUP: 'Detail Eng',     SUBCONT: 'OVERALL',      TOTAL: 32362.2, P_WK: 867,    A_WK: 755.9,  D_WK: -111, P_CUM: 1515.3,  A_CUM: 1339.1,  D_CUM: -176, },
{ CDATE: '2023-09-29', PHASE: 'Engineering',   GROUP: 'Detail Eng',     SUBCONT: 'OVERALL',      TOTAL: 32362.2, P_WK: 1524.4, A_WK: 1195.6, D_WK: -329, P_CUM: 3039.7,  A_CUM: 2534.7,  D_CUM: -505, },
{ CDATE: '2023-10-27', PHASE: 'Engineering',   GROUP: 'Detail Eng',     SUBCONT: 'OVERALL',      TOTAL: 32362.2, P_WK: 1330.6, A_WK: 1081.6, D_WK: -249, P_CUM: 4370.3,  A_CUM: 3616.3,  D_CUM: -754, },
{ CDATE: '2023-11-24', PHASE: 'Engineering',   GROUP: 'Detail Eng',     SUBCONT: 'OVERALL',      TOTAL: 32362.2, P_WK: 1372.2, A_WK: 1098.4, D_WK: -274, P_CUM: 5742.5,  A_CUM: 4714.7,  D_CUM: -1028, },
{ CDATE: '2023-12-29', PHASE: 'Engineering',   GROUP: 'Detail Eng',     SUBCONT: 'OVERALL',      TOTAL: 32362.2, P_WK: 1787.7, A_WK: 1454.5, D_WK: -333, P_CUM: 7530.2,  A_CUM: 6169.2,  D_CUM: -1361, },
{ CDATE: '2024-01-26', PHASE: 'Engineering',   GROUP: 'Detail Eng',     SUBCONT: 'OVERALL',      TOTAL: 32362.2, P_WK: 1579.7, A_WK: 1353.1, D_WK: -227, P_CUM: 9109.9,  A_CUM: 7522.3,  D_CUM: -1588, },
{ CDATE: '2024-02-23', PHASE: 'Engineering',   GROUP: 'Detail Eng',     SUBCONT: 'OVERALL',      TOTAL: 32362.2, P_WK: 1598.7, A_WK: 1600.4, D_WK: 2,    P_CUM: 10708.6, A_CUM: 9122.7,  D_CUM: -1586, },
{ CDATE: '2024-03-29', PHASE: 'Engineering',   GROUP: 'Detail Eng',     SUBCONT: 'OVERALL',      TOTAL: 32362.2, P_WK: 1991,   A_WK: 2024,   D_WK: 33,   P_CUM: 12699.6, A_CUM: 11146.7, D_CUM: -1553, },
{ CDATE: '2024-04-26', PHASE: 'Engineering',   GROUP: 'Detail Eng',     SUBCONT: 'OVERALL',      TOTAL: 32362.2, P_WK: 1675.8, A_WK: 1802.6, D_WK: 127,  P_CUM: 14375.4, A_CUM: 12949.3, D_CUM: -1426, },
{ CDATE: '2024-05-31', PHASE: 'Engineering',   GROUP: 'Detail Eng',     SUBCONT: 'OVERALL',      TOTAL: 32362.2, P_WK: 2078.6, A_WK: 2197.8, D_WK: 119,  P_CUM: 16454,   A_CUM: 15147.1, D_CUM: -1307, },
{ CDATE: '2024-06-28', PHASE: 'Engineering',   GROUP: 'Detail Eng',     SUBCONT: 'OVERALL',      TOTAL: 32362.2, P_WK: 1611.3, A_WK: 1887.3, D_WK: 276,  P_CUM: 18065.3, A_CUM: 17034.4, D_CUM: -1031, },
{ CDATE: '2024-07-26', PHASE: 'Engineering',   GROUP: 'Detail Eng',     SUBCONT: 'OVERALL',      TOTAL: 32362.2, P_WK: 1700.3, A_WK: 1880.3, D_WK: 180,  P_CUM: 19765.6, A_CUM: 18914.7, D_CUM: -851, },
{ CDATE: '2024-08-30', PHASE: 'Engineering',   GROUP: 'Detail Eng',     SUBCONT: 'OVERALL',      TOTAL: 32362.2, P_WK: 1942.4, A_WK: 2289.3, D_WK: 347,  P_CUM: 21708,   A_CUM: 21204,   D_CUM: -504, },
{ CDATE: '2024-09-27', PHASE: 'Engineering',   GROUP: 'Detail Eng',     SUBCONT: 'OVERALL',      TOTAL: 32362.2, P_WK: 1562.7, A_WK: 1925,   D_WK: 362,  P_CUM: 23270.7, A_CUM: 23129,   D_CUM: -142, },
{ CDATE: '2024-10-25', PHASE: 'Engineering',   GROUP: 'Detail Eng',     SUBCONT: 'OVERALL',      TOTAL: 32362.2, P_WK: 1411.3, A_WK: 1882.9, D_WK: 472,  P_CUM: 24682,   A_CUM: 25011.9, D_CUM: 330, },
{ CDATE: '2024-11-29', PHASE: 'Engineering',   GROUP: 'Detail Eng',     SUBCONT: 'OVERALL',      TOTAL: 32362.2, P_WK: 1890.9, A_WK: 2301.6, D_WK: 411,  P_CUM: 26572.9, A_CUM: 27313.5, D_CUM: 741, },
{ CDATE: '2024-12-27', PHASE: 'Engineering',   GROUP: 'Detail Eng',     SUBCONT: 'OVERALL',      TOTAL: 32362.2, P_WK: 1394.2, A_WK: 0,      D_WK: 0,    P_CUM: 27967.1, A_CUM: 27313.5, D_CUM: 0, },
{ CDATE: '2025-01-31', PHASE: 'Engineering',   GROUP: 'Detail Eng',     SUBCONT: 'OVERALL',      TOTAL: 32362.2, P_WK: 1436.7, A_WK: 0,      D_WK: 0,    P_CUM: 29403.8, A_CUM: 27313.5, D_CUM: 0, },
{ CDATE: '2025-02-28', PHASE: 'Engineering',   GROUP: 'Detail Eng',     SUBCONT: 'OVERALL',      TOTAL: 32362.2, P_WK: 1034,   A_WK: 0,      D_WK: 0,    P_CUM: 30437.8, A_CUM: 27313.5, D_CUM: 0, },
{ CDATE: '2025-03-28', PHASE: 'Engineering',   GROUP: 'Detail Eng',     SUBCONT: 'OVERALL',      TOTAL: 32362.2, P_WK: 782.9,  A_WK: 0,      D_WK: 0,    P_CUM: 31220.7, A_CUM: 27313.5, D_CUM: 0, },
{ CDATE: '2025-04-25', PHASE: 'Engineering',   GROUP: 'Detail Eng',     SUBCONT: 'OVERALL',      TOTAL: 32362.2, P_WK: 635.1,  A_WK: 0,      D_WK: 0,    P_CUM: 31855.8, A_CUM: 27313.5, D_CUM: 0, },
{ CDATE: '2025-05-30', PHASE: 'Engineering',   GROUP: 'Detail Eng',     SUBCONT: 'OVERALL',      TOTAL: 32362.2, P_WK: 406.5,  A_WK: 0,      D_WK: 0,    P_CUM: 32262.3, A_CUM: 27313.5, D_CUM: 0, },
{ CDATE: '2025-06-27', PHASE: 'Engineering',   GROUP: 'Detail Eng',     SUBCONT: 'OVERALL',      TOTAL: 32362.2, P_WK: 4.2,    A_WK: 0,      D_WK: 0,    P_CUM: 32266.5, A_CUM: 27313.5, D_CUM: 0, },
{ CDATE: '2025-07-25', PHASE: 'Engineering',   GROUP: 'Detail Eng',     SUBCONT: 'OVERALL',      TOTAL: 32362.2, P_WK: 4.2,    A_WK: 0,      D_WK: 0,    P_CUM: 32270.7, A_CUM: 27313.5, D_CUM: 0, },
{ CDATE: '2025-08-29', PHASE: 'Engineering',   GROUP: 'Detail Eng',     SUBCONT: 'OVERALL',      TOTAL: 32362.2, P_WK: 5.3,    A_WK: 0,      D_WK: 0,    P_CUM: 32276,   A_CUM: 27313.5, D_CUM: 0, },
{ CDATE: '2025-09-26', PHASE: 'Engineering',   GROUP: 'Detail Eng',     SUBCONT: 'OVERALL',      TOTAL: 32362.2, P_WK: 4.2,    A_WK: 0,      D_WK: 0,    P_CUM: 32280.2, A_CUM: 27313.5, D_CUM: 0, },
{ CDATE: '2025-10-31', PHASE: 'Engineering',   GROUP: 'Detail Eng',     SUBCONT: 'OVERALL',      TOTAL: 32362.2, P_WK: 5.3,    A_WK: 0,      D_WK: 0,    P_CUM: 32285.5, A_CUM: 27313.5, D_CUM: 0, },
{ CDATE: '2025-11-28', PHASE: 'Engineering',   GROUP: 'Detail Eng',     SUBCONT: 'OVERALL',      TOTAL: 32362.2, P_WK: 4.2,    A_WK: 0,      D_WK: 0,    P_CUM: 32289.7, A_CUM: 27313.5, D_CUM: 0, },
{ CDATE: '2025-12-26', PHASE: 'Engineering',   GROUP: 'Detail Eng',     SUBCONT: 'OVERALL',      TOTAL: 32362.2, P_WK: 4.2,    A_WK: 0,      D_WK: 0,    P_CUM: 32293.9, A_CUM: 27313.5, D_CUM: 0, },
{ CDATE: '2026-01-30', PHASE: 'Engineering',   GROUP: 'Detail Eng',     SUBCONT: 'OVERALL',      TOTAL: 32362.2, P_WK: 5.3,    A_WK: 0,      D_WK: 0,    P_CUM: 32299.2, A_CUM: 27313.5, D_CUM: 0, },
{ CDATE: '2026-02-27', PHASE: 'Engineering',   GROUP: 'Detail Eng',     SUBCONT: 'OVERALL',      TOTAL: 32362.2, P_WK: 4.2,    A_WK: 0,      D_WK: 0,    P_CUM: 32303.4, A_CUM: 27313.5, D_CUM: 0, },
{ CDATE: '2026-03-27', PHASE: 'Engineering',   GROUP: 'Detail Eng',     SUBCONT: 'OVERALL',      TOTAL: 32362.2, P_WK: 4.2,    A_WK: 0,      D_WK: 0,    P_CUM: 32307.6, A_CUM: 27313.5, D_CUM: 0, },
{ CDATE: '2026-04-24', PHASE: 'Engineering',   GROUP: 'Detail Eng',     SUBCONT: 'OVERALL',      TOTAL: 32362.2, P_WK: 4.2,    A_WK: 0,      D_WK: 0,    P_CUM: 32311.8, A_CUM: 27313.5, D_CUM: 0, },
{ CDATE: '2026-05-29', PHASE: 'Engineering',   GROUP: 'Detail Eng',     SUBCONT: 'OVERALL',      TOTAL: 32362.2, P_WK: 5.3,    A_WK: 0,      D_WK: 0,    P_CUM: 32317.1, A_CUM: 27313.5, D_CUM: 0, },
{ CDATE: '2026-06-26', PHASE: 'Engineering',   GROUP: 'Detail Eng',     SUBCONT: 'OVERALL',      TOTAL: 32362.2, P_WK: 4.2,    A_WK: 0,      D_WK: 0,    P_CUM: 32321.3, A_CUM: 27313.5, D_CUM: 0, },
{ CDATE: '2026-07-31', PHASE: 'Engineering',   GROUP: 'Detail Eng',     SUBCONT: 'OVERALL',      TOTAL: 32362.2, P_WK: 5.3,    A_WK: 0,      D_WK: 0,    P_CUM: 32326.6, A_CUM: 27313.5, D_CUM: 0, },
{ CDATE: '2026-08-28', PHASE: 'Engineering',   GROUP: 'Detail Eng',     SUBCONT: 'OVERALL',      TOTAL: 32362.2, P_WK: 4.2,    A_WK: 0,      D_WK: 0,    P_CUM: 32330.8, A_CUM: 27313.5, D_CUM: 0, },
{ CDATE: '2026-09-25', PHASE: 'Engineering',   GROUP: 'Detail Eng',     SUBCONT: 'OVERALL',      TOTAL: 32362.2, P_WK: 4.2,    A_WK: 0,      D_WK: 0,    P_CUM: 32335,   A_CUM: 27313.5, D_CUM: 0, },
{ CDATE: '2026-10-30', PHASE: 'Engineering',   GROUP: 'Detail Eng',     SUBCONT: 'OVERALL',      TOTAL: 32362.2, P_WK: 5.3,    A_WK: 0,      D_WK: 0,    P_CUM: 32340.3, A_CUM: 27313.5, D_CUM: 0, },
{ CDATE: '2026-11-27', PHASE: 'Engineering',   GROUP: 'Detail Eng',     SUBCONT: 'OVERALL',      TOTAL: 32362.2, P_WK: 4.2,    A_WK: 0,      D_WK: 0,    P_CUM: 32344.5, A_CUM: 27313.5, D_CUM: 0, },
{ CDATE: '2026-12-25', PHASE: 'Engineering',   GROUP: 'Detail Eng',     SUBCONT: 'OVERALL',      TOTAL: 32362.2, P_WK: 4.2,    A_WK: 0,      D_WK: 0,    P_CUM: 32348.7, A_CUM: 27313.5, D_CUM: 0, },
{ CDATE: '2027-01-29', PHASE: 'Engineering',   GROUP: 'Detail Eng',     SUBCONT: 'OVERALL',      TOTAL: 32362.2, P_WK: 5.3,    A_WK: 0,      D_WK: 0,    P_CUM: 32354,   A_CUM: 27313.5, D_CUM: 0, },
{ CDATE: '2027-02-26', PHASE: 'Engineering',   GROUP: 'Detail Eng',     SUBCONT: 'OVERALL',      TOTAL: 32362.2, P_WK: 4.2,    A_WK: 0,      D_WK: 0,    P_CUM: 32358.2, A_CUM: 27313.5, D_CUM: 0, },
{ CDATE: '2027-03-26', PHASE: 'Engineering',   GROUP: 'Detail Eng',     SUBCONT: 'OVERALL',      TOTAL: 32362.2, P_WK: 4.2,    A_WK: 0,      D_WK: 0,    P_CUM: 32362.4, A_CUM: 27313.5, D_CUM: 0, },
{ CDATE: '2027-04-30', PHASE: 'Engineering',   GROUP: 'Detail Eng',     SUBCONT: 'OVERALL',      TOTAL: 32362.2, P_WK: 0,      A_WK: 0,      D_WK: 0,    P_CUM: 32362.4, A_CUM: 27313.5, D_CUM: 0, },
{ CDATE: '2027-05-28', PHASE: 'Engineering',   GROUP: 'Detail Eng',     SUBCONT: 'OVERALL',      TOTAL: 32362.2, P_WK: 0,      A_WK: 0,      D_WK: 0,    P_CUM: 32362.4, A_CUM: 27313.5, D_CUM: 0, },
{ CDATE: '2027-06-11', PHASE: 'Engineering',   GROUP: 'Detail Eng',     SUBCONT: 'OVERALL',      TOTAL: 32362.2, P_WK: 0,      A_WK: 0,      D_WK: 0,    P_CUM: 32362.4, A_CUM: 27313.5, D_CUM: 0, },
{ CDATE: '2023-07-28', PHASE: 'Engineering',   GROUP: 'Detail Eng',     SUBCONT: 'WOOD',         TOTAL: 17673.2, P_WK: 302.4,  A_WK: 305.8,  D_WK: 3,    P_CUM: 302.4,   A_CUM: 305.8,   D_CUM: 3, },
{ CDATE: '2023-08-25', PHASE: 'Engineering',   GROUP: 'Detail Eng',     SUBCONT: 'WOOD',         TOTAL: 17673.2, P_WK: 492.7,  A_WK: 450.4,  D_WK: -42,  P_CUM: 795.1,   A_CUM: 756.2,   D_CUM: -39, },
{ CDATE: '2023-09-29', PHASE: 'Engineering',   GROUP: 'Detail Eng',     SUBCONT: 'WOOD',         TOTAL: 17673.2, P_WK: 796.7,  A_WK: 660,    D_WK: -137, P_CUM: 1591.8,  A_CUM: 1416.2,  D_CUM: -176, },
{ CDATE: '2023-10-27', PHASE: 'Engineering',   GROUP: 'Detail Eng',     SUBCONT: 'WOOD',         TOTAL: 17673.2, P_WK: 714.8,  A_WK: 585.5,  D_WK: -129, P_CUM: 2306.6,  A_CUM: 2001.7,  D_CUM: -305, },
{ CDATE: '2023-11-24', PHASE: 'Engineering',   GROUP: 'Detail Eng',     SUBCONT: 'WOOD',         TOTAL: 17673.2, P_WK: 761.6,  A_WK: 599.7,  D_WK: -162, P_CUM: 3068.2,  A_CUM: 2601.4,  D_CUM: -467, },
{ CDATE: '2023-12-29', PHASE: 'Engineering',   GROUP: 'Detail Eng',     SUBCONT: 'WOOD',         TOTAL: 17673.2, P_WK: 1001.2, A_WK: 778.6,  D_WK: -223, P_CUM: 4069.4,  A_CUM: 3380,    D_CUM: -689, },
{ CDATE: '2024-01-26', PHASE: 'Engineering',   GROUP: 'Detail Eng',     SUBCONT: 'WOOD',         TOTAL: 17673.2, P_WK: 839.8,  A_WK: 721.8,  D_WK: -118, P_CUM: 4909.2,  A_CUM: 4101.8,  D_CUM: -807, },
{ CDATE: '2024-02-23', PHASE: 'Engineering',   GROUP: 'Detail Eng',     SUBCONT: 'WOOD',         TOTAL: 17673.2, P_WK: 843,    A_WK: 881,    D_WK: 38,   P_CUM: 5752.2,  A_CUM: 4982.8,  D_CUM: -769, },
{ CDATE: '2024-03-29', PHASE: 'Engineering',   GROUP: 'Detail Eng',     SUBCONT: 'WOOD',         TOTAL: 17673.2, P_WK: 1056,   A_WK: 1075.9, D_WK: 20,   P_CUM: 6808.2,  A_CUM: 6058.7,  D_CUM: -749, },
{ CDATE: '2024-04-26', PHASE: 'Engineering',   GROUP: 'Detail Eng',     SUBCONT: 'WOOD',         TOTAL: 17673.2, P_WK: 858.4,  A_WK: 938.1,  D_WK: 80,   P_CUM: 7666.6,  A_CUM: 6996.8,  D_CUM: -670, },
{ CDATE: '2024-05-31', PHASE: 'Engineering',   GROUP: 'Detail Eng',     SUBCONT: 'WOOD',         TOTAL: 17673.2, P_WK: 1084.7, A_WK: 1143.6, D_WK: 59,   P_CUM: 8751.3,  A_CUM: 8140.4,  D_CUM: -611, },
{ CDATE: '2024-06-28', PHASE: 'Engineering',   GROUP: 'Detail Eng',     SUBCONT: 'WOOD',         TOTAL: 17673.2, P_WK: 866.7,  A_WK: 953.4,  D_WK: 87,   P_CUM: 9618,    A_CUM: 9093.8,  D_CUM: -524, },
{ CDATE: '2024-07-26', PHASE: 'Engineering',   GROUP: 'Detail Eng',     SUBCONT: 'WOOD',         TOTAL: 17673.2, P_WK: 849.6,  A_WK: 947.9,  D_WK: 98,   P_CUM: 10467.6, A_CUM: 10041.7, D_CUM: -426, },
{ CDATE: '2024-08-30', PHASE: 'Engineering',   GROUP: 'Detail Eng',     SUBCONT: 'WOOD',         TOTAL: 17673.2, P_WK: 1026.7, A_WK: 1301.3, D_WK: 275,  P_CUM: 11494.3, A_CUM: 11343,   D_CUM: -151, },
{ CDATE: '2024-09-27', PHASE: 'Engineering',   GROUP: 'Detail Eng',     SUBCONT: 'WOOD',         TOTAL: 17673.2, P_WK: 777.1,  A_WK: 1062,   D_WK: 285,  P_CUM: 12271.4, A_CUM: 12405,   D_CUM: 134, },
{ CDATE: '2024-10-25', PHASE: 'Engineering',   GROUP: 'Detail Eng',     SUBCONT: 'WOOD',         TOTAL: 17673.2, P_WK: 735.1,  A_WK: 1057.6, D_WK: 322,  P_CUM: 13006.5, A_CUM: 13462.6, D_CUM: 456, },
{ CDATE: '2024-11-29', PHASE: 'Engineering',   GROUP: 'Detail Eng',     SUBCONT: 'WOOD',         TOTAL: 17673.2, P_WK: 1094.2, A_WK: 1269.2, D_WK: 175,  P_CUM: 14100.7, A_CUM: 14731.8, D_CUM: 631, },
{ CDATE: '2024-12-27', PHASE: 'Engineering',   GROUP: 'Detail Eng',     SUBCONT: 'WOOD',         TOTAL: 17673.2, P_WK: 819.8,  A_WK: 0,      D_WK: 0,    P_CUM: 14920.5, A_CUM: 14731.8, D_CUM: 0, },
{ CDATE: '2025-01-31', PHASE: 'Engineering',   GROUP: 'Detail Eng',     SUBCONT: 'WOOD',         TOTAL: 17673.2, P_WK: 878.6,  A_WK: 0,      D_WK: 0,    P_CUM: 15799.1, A_CUM: 14731.8, D_CUM: 0, },
{ CDATE: '2025-02-28', PHASE: 'Engineering',   GROUP: 'Detail Eng',     SUBCONT: 'WOOD',         TOTAL: 17673.2, P_WK: 662.8,  A_WK: 0,      D_WK: 0,    P_CUM: 16461.9, A_CUM: 14731.8, D_CUM: 0, },
{ CDATE: '2025-03-28', PHASE: 'Engineering',   GROUP: 'Detail Eng',     SUBCONT: 'WOOD',         TOTAL: 17673.2, P_WK: 527.2,  A_WK: 0,      D_WK: 0,    P_CUM: 16989.1, A_CUM: 14731.8, D_CUM: 0, },
{ CDATE: '2025-04-25', PHASE: 'Engineering',   GROUP: 'Detail Eng',     SUBCONT: 'WOOD',         TOTAL: 17673.2, P_WK: 431.3,  A_WK: 0,      D_WK: 0,    P_CUM: 17420.4, A_CUM: 14731.8, D_CUM: 0, },
{ CDATE: '2025-05-30', PHASE: 'Engineering',   GROUP: 'Detail Eng',     SUBCONT: 'WOOD',         TOTAL: 17673.2, P_WK: 252.8,  A_WK: 0,      D_WK: 0,    P_CUM: 17673.2, A_CUM: 14731.8, D_CUM: 0, },
{ CDATE: '2025-06-27', PHASE: 'Engineering',   GROUP: 'Detail Eng',     SUBCONT: 'WOOD',         TOTAL: 17673.2, P_WK: 0,      A_WK: 0,      D_WK: 0,    P_CUM: 17673.2, A_CUM: 14731.8, D_CUM: 0, },
{ CDATE: '2025-07-25', PHASE: 'Engineering',   GROUP: 'Detail Eng',     SUBCONT: 'WOOD',         TOTAL: 17673.2, P_WK: 0,      A_WK: 0,      D_WK: 0,    P_CUM: 17673.2, A_CUM: 14731.8, D_CUM: 0, },
{ CDATE: '2025-08-29', PHASE: 'Engineering',   GROUP: 'Detail Eng',     SUBCONT: 'WOOD',         TOTAL: 17673.2, P_WK: 0,      A_WK: 0,      D_WK: 0,    P_CUM: 17673.2, A_CUM: 14731.8, D_CUM: 0, },
{ CDATE: '2025-09-26', PHASE: 'Engineering',   GROUP: 'Detail Eng',     SUBCONT: 'WOOD',         TOTAL: 17673.2, P_WK: 0,      A_WK: 0,      D_WK: 0,    P_CUM: 17673.2, A_CUM: 14731.8, D_CUM: 0, },
{ CDATE: '2025-10-31', PHASE: 'Engineering',   GROUP: 'Detail Eng',     SUBCONT: 'WOOD',         TOTAL: 17673.2, P_WK: 0,      A_WK: 0,      D_WK: 0,    P_CUM: 17673.2, A_CUM: 14731.8, D_CUM: 0, },
{ CDATE: '2025-11-28', PHASE: 'Engineering',   GROUP: 'Detail Eng',     SUBCONT: 'WOOD',         TOTAL: 17673.2, P_WK: 0,      A_WK: 0,      D_WK: 0,    P_CUM: 17673.2, A_CUM: 14731.8, D_CUM: 0, },
{ CDATE: '2025-12-26', PHASE: 'Engineering',   GROUP: 'Detail Eng',     SUBCONT: 'WOOD',         TOTAL: 17673.2, P_WK: 0,      A_WK: 0,      D_WK: 0,    P_CUM: 17673.2, A_CUM: 14731.8, D_CUM: 0, },
{ CDATE: '2026-01-30', PHASE: 'Engineering',   GROUP: 'Detail Eng',     SUBCONT: 'WOOD',         TOTAL: 17673.2, P_WK: 0,      A_WK: 0,      D_WK: 0,    P_CUM: 17673.2, A_CUM: 14731.8, D_CUM: 0, },
{ CDATE: '2026-02-27', PHASE: 'Engineering',   GROUP: 'Detail Eng',     SUBCONT: 'WOOD',         TOTAL: 17673.2, P_WK: 0,      A_WK: 0,      D_WK: 0,    P_CUM: 17673.2, A_CUM: 14731.8, D_CUM: 0, },
{ CDATE: '2026-03-27', PHASE: 'Engineering',   GROUP: 'Detail Eng',     SUBCONT: 'WOOD',         TOTAL: 17673.2, P_WK: 0,      A_WK: 0,      D_WK: 0,    P_CUM: 17673.2, A_CUM: 14731.8, D_CUM: 0, },
{ CDATE: '2026-04-24', PHASE: 'Engineering',   GROUP: 'Detail Eng',     SUBCONT: 'WOOD',         TOTAL: 17673.2, P_WK: 0,      A_WK: 0,      D_WK: 0,    P_CUM: 17673.2, A_CUM: 14731.8, D_CUM: 0, },
{ CDATE: '2026-05-29', PHASE: 'Engineering',   GROUP: 'Detail Eng',     SUBCONT: 'WOOD',         TOTAL: 17673.2, P_WK: 0,      A_WK: 0,      D_WK: 0,    P_CUM: 17673.2, A_CUM: 14731.8, D_CUM: 0, },
{ CDATE: '2026-06-26', PHASE: 'Engineering',   GROUP: 'Detail Eng',     SUBCONT: 'WOOD',         TOTAL: 17673.2, P_WK: 0,      A_WK: 0,      D_WK: 0,    P_CUM: 17673.2, A_CUM: 14731.8, D_CUM: 0, },
{ CDATE: '2026-07-31', PHASE: 'Engineering',   GROUP: 'Detail Eng',     SUBCONT: 'WOOD',         TOTAL: 17673.2, P_WK: 0,      A_WK: 0,      D_WK: 0,    P_CUM: 17673.2, A_CUM: 14731.8, D_CUM: 0, },
{ CDATE: '2026-08-28', PHASE: 'Engineering',   GROUP: 'Detail Eng',     SUBCONT: 'WOOD',         TOTAL: 17673.2, P_WK: 0,      A_WK: 0,      D_WK: 0,    P_CUM: 17673.2, A_CUM: 14731.8, D_CUM: 0, },
{ CDATE: '2026-09-25', PHASE: 'Engineering',   GROUP: 'Detail Eng',     SUBCONT: 'WOOD',         TOTAL: 17673.2, P_WK: 0,      A_WK: 0,      D_WK: 0,    P_CUM: 17673.2, A_CUM: 14731.8, D_CUM: 0, },
{ CDATE: '2026-10-30', PHASE: 'Engineering',   GROUP: 'Detail Eng',     SUBCONT: 'WOOD',         TOTAL: 17673.2, P_WK: 0,      A_WK: 0,      D_WK: 0,    P_CUM: 17673.2, A_CUM: 14731.8, D_CUM: 0, },
{ CDATE: '2026-11-27', PHASE: 'Engineering',   GROUP: 'Detail Eng',     SUBCONT: 'WOOD',         TOTAL: 17673.2, P_WK: 0,      A_WK: 0,      D_WK: 0,    P_CUM: 17673.2, A_CUM: 14731.8, D_CUM: 0, },
{ CDATE: '2026-12-25', PHASE: 'Engineering',   GROUP: 'Detail Eng',     SUBCONT: 'WOOD',         TOTAL: 17673.2, P_WK: 0,      A_WK: 0,      D_WK: 0,    P_CUM: 17673.2, A_CUM: 14731.8, D_CUM: 0, },
{ CDATE: '2027-01-29', PHASE: 'Engineering',   GROUP: 'Detail Eng',     SUBCONT: 'WOOD',         TOTAL: 17673.2, P_WK: 0,      A_WK: 0,      D_WK: 0,    P_CUM: 17673.2, A_CUM: 14731.8, D_CUM: 0, },
{ CDATE: '2027-02-26', PHASE: 'Engineering',   GROUP: 'Detail Eng',     SUBCONT: 'WOOD',         TOTAL: 17673.2, P_WK: 0,      A_WK: 0,      D_WK: 0,    P_CUM: 17673.2, A_CUM: 14731.8, D_CUM: 0, },
{ CDATE: '2027-03-26', PHASE: 'Engineering',   GROUP: 'Detail Eng',     SUBCONT: 'WOOD',         TOTAL: 17673.2, P_WK: 0,      A_WK: 0,      D_WK: 0,    P_CUM: 17673.2, A_CUM: 14731.8, D_CUM: 0, },
{ CDATE: '2027-04-30', PHASE: 'Engineering',   GROUP: 'Detail Eng',     SUBCONT: 'WOOD',         TOTAL: 17673.2, P_WK: 0,      A_WK: 0,      D_WK: 0,    P_CUM: 17673.2, A_CUM: 14731.8, D_CUM: 0, },
{ CDATE: '2027-05-28', PHASE: 'Engineering',   GROUP: 'Detail Eng',     SUBCONT: 'WOOD',         TOTAL: 17673.2, P_WK: 0,      A_WK: 0,      D_WK: 0,    P_CUM: 17673.2, A_CUM: 14731.8, D_CUM: 0, },
{ CDATE: '2027-06-11', PHASE: 'Engineering',   GROUP: 'Detail Eng',     SUBCONT: 'WOOD',         TOTAL: 17673.2, P_WK: 0,      A_WK: 0,      D_WK: 0,    P_CUM: 17673.2, A_CUM: 14731.8, D_CUM: 0, },
{ CDATE: '2023-07-28', PHASE: 'Engineering',   GROUP: 'Shop Drawing',   SUBCONT: 'HHI',          TOTAL: 12252.5, P_WK: 0,      A_WK: 0,      D_WK: 0,    P_CUM: 0,       A_CUM: 0,       D_CUM: 0, },
{ CDATE: '2023-08-25', PHASE: 'Engineering',   GROUP: 'Shop Drawing',   SUBCONT: 'HHI',          TOTAL: 12252.5, P_WK: 0,      A_WK: 0,      D_WK: 0,    P_CUM: 0,       A_CUM: 0,       D_CUM: 0, },
{ CDATE: '2023-09-29', PHASE: 'Engineering',   GROUP: 'Shop Drawing',   SUBCONT: 'HHI',          TOTAL: 12252.5, P_WK: 0,      A_WK: 0,      D_WK: 0,    P_CUM: 0,       A_CUM: 0,       D_CUM: 0, },
{ CDATE: '2023-10-27', PHASE: 'Engineering',   GROUP: 'Shop Drawing',   SUBCONT: 'HHI',          TOTAL: 12252.5, P_WK: 0,      A_WK: 0,      D_WK: 0,    P_CUM: 0,       A_CUM: 0,       D_CUM: 0, },
{ CDATE: '2023-11-24', PHASE: 'Engineering',   GROUP: 'Shop Drawing',   SUBCONT: 'HHI',          TOTAL: 12252.5, P_WK: 0,      A_WK: 0,      D_WK: 0,    P_CUM: 0,       A_CUM: 0,       D_CUM: 0, },
{ CDATE: '2023-12-29', PHASE: 'Engineering',   GROUP: 'Shop Drawing',   SUBCONT: 'HHI',          TOTAL: 12252.5, P_WK: 0,      A_WK: 0,      D_WK: 0,    P_CUM: 0,       A_CUM: 0,       D_CUM: 0, },
{ CDATE: '2024-01-26', PHASE: 'Engineering',   GROUP: 'Shop Drawing',   SUBCONT: 'HHI',          TOTAL: 12252.5, P_WK: 0,      A_WK: 0,      D_WK: 0,    P_CUM: 0,       A_CUM: 0,       D_CUM: 0, },
{ CDATE: '2024-02-23', PHASE: 'Engineering',   GROUP: 'Shop Drawing',   SUBCONT: 'HHI',          TOTAL: 12252.5, P_WK: 0,      A_WK: 0,      D_WK: 0,    P_CUM: 0,       A_CUM: 0,       D_CUM: 0, },
{ CDATE: '2024-03-29', PHASE: 'Engineering',   GROUP: 'Shop Drawing',   SUBCONT: 'HHI',          TOTAL: 12252.5, P_WK: 0,      A_WK: 0,      D_WK: 0,    P_CUM: 0,       A_CUM: 0,       D_CUM: 0, },
{ CDATE: '2024-04-26', PHASE: 'Engineering',   GROUP: 'Shop Drawing',   SUBCONT: 'HHI',          TOTAL: 12252.5, P_WK: 0,      A_WK: 0,      D_WK: 0,    P_CUM: 0,       A_CUM: 0,       D_CUM: 0, },
{ CDATE: '2024-05-31', PHASE: 'Engineering',   GROUP: 'Shop Drawing',   SUBCONT: 'HHI',          TOTAL: 12252.5, P_WK: 0,      A_WK: 0,      D_WK: 0,    P_CUM: 0,       A_CUM: 0,       D_CUM: 0, },
{ CDATE: '2024-06-28', PHASE: 'Engineering',   GROUP: 'Shop Drawing',   SUBCONT: 'HHI',          TOTAL: 12252.5, P_WK: 0,      A_WK: 0,      D_WK: 0,    P_CUM: 0,       A_CUM: 0,       D_CUM: 0, },
{ CDATE: '2024-07-26', PHASE: 'Engineering',   GROUP: 'Shop Drawing',   SUBCONT: 'HHI',          TOTAL: 12252.5, P_WK: 0,      A_WK: 0,      D_WK: 0,    P_CUM: 0,       A_CUM: 0,       D_CUM: 0, },
{ CDATE: '2024-08-30', PHASE: 'Engineering',   GROUP: 'Shop Drawing',   SUBCONT: 'HHI',          TOTAL: 12252.5, P_WK: 0,      A_WK: 0,      D_WK: 0,    P_CUM: 0,       A_CUM: 0,       D_CUM: 0, },
{ CDATE: '2024-09-27', PHASE: 'Engineering',   GROUP: 'Shop Drawing',   SUBCONT: 'HHI',          TOTAL: 12252.5, P_WK: 44.2,   A_WK: 44.2,   D_WK: 0,    P_CUM: 44.2,    A_CUM: 44.2,    D_CUM: 0, },
{ CDATE: '2024-10-25', PHASE: 'Engineering',   GROUP: 'Shop Drawing',   SUBCONT: 'HHI',          TOTAL: 12252.5, P_WK: 58.8,   A_WK: 58.8,   D_WK: 0,    P_CUM: 103,     A_CUM: 103,     D_CUM: 0, },
{ CDATE: '2024-11-29', PHASE: 'Engineering',   GROUP: 'Shop Drawing',   SUBCONT: 'HHI',          TOTAL: 12252.5, P_WK: 246.7,  A_WK: 192.1,  D_WK: -55,  P_CUM: 349.7,   A_CUM: 295.1,   D_CUM: -55, },
{ CDATE: '2024-12-27', PHASE: 'Engineering',   GROUP: 'Shop Drawing',   SUBCONT: 'HHI',          TOTAL: 12252.5, P_WK: 373.7,  A_WK: 0,      D_WK: 0,    P_CUM: 723.4,   A_CUM: 295.1,   D_CUM: 0, },
{ CDATE: '2025-01-31', PHASE: 'Engineering',   GROUP: 'Shop Drawing',   SUBCONT: 'HHI',          TOTAL: 12252.5, P_WK: 849.1,  A_WK: 0,      D_WK: 0,    P_CUM: 1572.5,  A_CUM: 295.1,   D_CUM: 0, },
{ CDATE: '2025-02-28', PHASE: 'Engineering',   GROUP: 'Shop Drawing',   SUBCONT: 'HHI',          TOTAL: 12252.5, P_WK: 986.8,  A_WK: 0,      D_WK: 0,    P_CUM: 2559.3,  A_CUM: 295.1,   D_CUM: 0, },
{ CDATE: '2025-03-28', PHASE: 'Engineering',   GROUP: 'Shop Drawing',   SUBCONT: 'HHI',          TOTAL: 12252.5, P_WK: 1436.1, A_WK: 0,      D_WK: 0,    P_CUM: 3995.4,  A_CUM: 295.1,   D_CUM: 0, },
{ CDATE: '2025-04-25', PHASE: 'Engineering',   GROUP: 'Shop Drawing',   SUBCONT: 'HHI',          TOTAL: 12252.5, P_WK: 1215.3, A_WK: 0,      D_WK: 0,    P_CUM: 5210.7,  A_CUM: 295.1,   D_CUM: 0, },
{ CDATE: '2025-05-30', PHASE: 'Engineering',   GROUP: 'Shop Drawing',   SUBCONT: 'HHI',          TOTAL: 12252.5, P_WK: 1750.8, A_WK: 0,      D_WK: 0,    P_CUM: 6961.5,  A_CUM: 295.1,   D_CUM: 0, },
{ CDATE: '2025-06-27', PHASE: 'Engineering',   GROUP: 'Shop Drawing',   SUBCONT: 'HHI',          TOTAL: 12252.5, P_WK: 1094.7, A_WK: 0,      D_WK: 0,    P_CUM: 8056.2,  A_CUM: 295.1,   D_CUM: 0, },
{ CDATE: '2025-07-25', PHASE: 'Engineering',   GROUP: 'Shop Drawing',   SUBCONT: 'HHI',          TOTAL: 12252.5, P_WK: 1058,   A_WK: 0,      D_WK: 0,    P_CUM: 9114.2,  A_CUM: 295.1,   D_CUM: 0, },
{ CDATE: '2025-08-29', PHASE: 'Engineering',   GROUP: 'Shop Drawing',   SUBCONT: 'HHI',          TOTAL: 12252.5, P_WK: 1231.5, A_WK: 0,      D_WK: 0,    P_CUM: 10345.7, A_CUM: 295.1,   D_CUM: 0, },
{ CDATE: '2025-09-26', PHASE: 'Engineering',   GROUP: 'Shop Drawing',   SUBCONT: 'HHI',          TOTAL: 12252.5, P_WK: 641.6,  A_WK: 0,      D_WK: 0,    P_CUM: 10987.3, A_CUM: 295.1,   D_CUM: 0, },
{ CDATE: '2025-10-31', PHASE: 'Engineering',   GROUP: 'Shop Drawing',   SUBCONT: 'HHI',          TOTAL: 12252.5, P_WK: 468.2,  A_WK: 0,      D_WK: 0,    P_CUM: 11455.5, A_CUM: 295.1,   D_CUM: 0, },
{ CDATE: '2025-11-28', PHASE: 'Engineering',   GROUP: 'Shop Drawing',   SUBCONT: 'HHI',          TOTAL: 12252.5, P_WK: 294.8,  A_WK: 0,      D_WK: 0,    P_CUM: 11750.3, A_CUM: 295.1,   D_CUM: 0, },
{ CDATE: '2025-12-26', PHASE: 'Engineering',   GROUP: 'Shop Drawing',   SUBCONT: 'HHI',          TOTAL: 12252.5, P_WK: 57,     A_WK: 0,      D_WK: 0,    P_CUM: 11807.3, A_CUM: 295.1,   D_CUM: 0, },
{ CDATE: '2026-01-30', PHASE: 'Engineering',   GROUP: 'Shop Drawing',   SUBCONT: 'HHI',          TOTAL: 12252.5, P_WK: 35.4,   A_WK: 0,      D_WK: 0,    P_CUM: 11842.7, A_CUM: 295.1,   D_CUM: 0, },
{ CDATE: '2026-02-27', PHASE: 'Engineering',   GROUP: 'Shop Drawing',   SUBCONT: 'HHI',          TOTAL: 12252.5, P_WK: 74,     A_WK: 0,      D_WK: 0,    P_CUM: 11916.7, A_CUM: 295.1,   D_CUM: 0, },
{ CDATE: '2026-03-27', PHASE: 'Engineering',   GROUP: 'Shop Drawing',   SUBCONT: 'HHI',          TOTAL: 12252.5, P_WK: 24.4,   A_WK: 0,      D_WK: 0,    P_CUM: 11941.1, A_CUM: 295.1,   D_CUM: 0, },
{ CDATE: '2026-04-24', PHASE: 'Engineering',   GROUP: 'Shop Drawing',   SUBCONT: 'HHI',          TOTAL: 12252.5, P_WK: 155.2,  A_WK: 0,      D_WK: 0,    P_CUM: 12096.3, A_CUM: 295.1,   D_CUM: 0, },
{ CDATE: '2026-05-29', PHASE: 'Engineering',   GROUP: 'Shop Drawing',   SUBCONT: 'HHI',          TOTAL: 12252.5, P_WK: 13.4,   A_WK: 0,      D_WK: 0,    P_CUM: 12109.7, A_CUM: 295.1,   D_CUM: 0, },
{ CDATE: '2026-06-26', PHASE: 'Engineering',   GROUP: 'Shop Drawing',   SUBCONT: 'HHI',          TOTAL: 12252.5, P_WK: 4.6,    A_WK: 0,      D_WK: 0,    P_CUM: 12114.3, A_CUM: 295.1,   D_CUM: 0, },
{ CDATE: '2026-07-31', PHASE: 'Engineering',   GROUP: 'Shop Drawing',   SUBCONT: 'HHI',          TOTAL: 12252.5, P_WK: 1.6,    A_WK: 0,      D_WK: 0,    P_CUM: 12115.9, A_CUM: 295.1,   D_CUM: 0, },
{ CDATE: '2026-08-28', PHASE: 'Engineering',   GROUP: 'Shop Drawing',   SUBCONT: 'HHI',          TOTAL: 12252.5, P_WK: 0,      A_WK: 0,      D_WK: 0,    P_CUM: 12115.9, A_CUM: 295.1,   D_CUM: 0, },
{ CDATE: '2026-09-25', PHASE: 'Engineering',   GROUP: 'Shop Drawing',   SUBCONT: 'HHI',          TOTAL: 12252.5, P_WK: 10.3,   A_WK: 0,      D_WK: 0,    P_CUM: 12126.2, A_CUM: 295.1,   D_CUM: 0, },
{ CDATE: '2026-10-30', PHASE: 'Engineering',   GROUP: 'Shop Drawing',   SUBCONT: 'HHI',          TOTAL: 12252.5, P_WK: 31.4,   A_WK: 0,      D_WK: 0,    P_CUM: 12157.6, A_CUM: 295.1,   D_CUM: 0, },
{ CDATE: '2026-11-27', PHASE: 'Engineering',   GROUP: 'Shop Drawing',   SUBCONT: 'HHI',          TOTAL: 12252.5, P_WK: 33,     A_WK: 0,      D_WK: 0,    P_CUM: 12190.6, A_CUM: 295.1,   D_CUM: 0, },
{ CDATE: '2026-12-25', PHASE: 'Engineering',   GROUP: 'Shop Drawing',   SUBCONT: 'HHI',          TOTAL: 12252.5, P_WK: 17.7,   A_WK: 0,      D_WK: 0,    P_CUM: 12208.3, A_CUM: 295.1,   D_CUM: 0, },
{ CDATE: '2027-01-29', PHASE: 'Engineering',   GROUP: 'Shop Drawing',   SUBCONT: 'HHI',          TOTAL: 12252.5, P_WK: 26.5,   A_WK: 0,      D_WK: 0,    P_CUM: 12234.8, A_CUM: 295.1,   D_CUM: 0, },
{ CDATE: '2027-02-26', PHASE: 'Engineering',   GROUP: 'Shop Drawing',   SUBCONT: 'HHI',          TOTAL: 12252.5, P_WK: 17.6,   A_WK: 0,      D_WK: 0,    P_CUM: 12252.4, A_CUM: 295.1,   D_CUM: 0, },
{ CDATE: '2027-03-26', PHASE: 'Engineering',   GROUP: 'Shop Drawing',   SUBCONT: 'HHI',          TOTAL: 12252.5, P_WK: 0,      A_WK: 0,      D_WK: 0,    P_CUM: 12252.4, A_CUM: 295.1,   D_CUM: 0, },
{ CDATE: '2027-04-30', PHASE: 'Engineering',   GROUP: 'Shop Drawing',   SUBCONT: 'HHI',          TOTAL: 12252.5, P_WK: 0,      A_WK: 0,      D_WK: 0,    P_CUM: 12252.4, A_CUM: 295.1,   D_CUM: 0, },
{ CDATE: '2027-05-28', PHASE: 'Engineering',   GROUP: 'Shop Drawing',   SUBCONT: 'HHI',          TOTAL: 12252.5, P_WK: 0,      A_WK: 0,      D_WK: 0,    P_CUM: 12252.4, A_CUM: 295.1,   D_CUM: 0, },
{ CDATE: '2027-06-11', PHASE: 'Engineering',   GROUP: 'Shop Drawing',   SUBCONT: 'HHI',          TOTAL: 12252.5, P_WK: 0,      A_WK: 0,      D_WK: 0,    P_CUM: 12252.4, A_CUM: 295.1,   D_CUM: 0, },
{ CDATE: '2023-07-28', PHASE: 'Engineering',   GROUP: 'Shop Drawing',   SUBCONT: 'OVERALL',      TOTAL: 12252.5, P_WK: 0,      A_WK: 0,      D_WK: 0,    P_CUM: 0,       A_CUM: 0,       D_CUM: 0, },
{ CDATE: '2023-08-25', PHASE: 'Engineering',   GROUP: 'Shop Drawing',   SUBCONT: 'OVERALL',      TOTAL: 12252.5, P_WK: 0,      A_WK: 0,      D_WK: 0,    P_CUM: 0,       A_CUM: 0,       D_CUM: 0, },
{ CDATE: '2023-09-29', PHASE: 'Engineering',   GROUP: 'Shop Drawing',   SUBCONT: 'OVERALL',      TOTAL: 12252.5, P_WK: 0,      A_WK: 0,      D_WK: 0,    P_CUM: 0,       A_CUM: 0,       D_CUM: 0, },
{ CDATE: '2023-10-27', PHASE: 'Engineering',   GROUP: 'Shop Drawing',   SUBCONT: 'OVERALL',      TOTAL: 12252.5, P_WK: 0,      A_WK: 0,      D_WK: 0,    P_CUM: 0,       A_CUM: 0,       D_CUM: 0, },
{ CDATE: '2023-11-24', PHASE: 'Engineering',   GROUP: 'Shop Drawing',   SUBCONT: 'OVERALL',      TOTAL: 12252.5, P_WK: 0,      A_WK: 0,      D_WK: 0,    P_CUM: 0,       A_CUM: 0,       D_CUM: 0, },
{ CDATE: '2023-12-29', PHASE: 'Engineering',   GROUP: 'Shop Drawing',   SUBCONT: 'OVERALL',      TOTAL: 12252.5, P_WK: 0,      A_WK: 0,      D_WK: 0,    P_CUM: 0,       A_CUM: 0,       D_CUM: 0, },
{ CDATE: '2024-01-26', PHASE: 'Engineering',   GROUP: 'Shop Drawing',   SUBCONT: 'OVERALL',      TOTAL: 12252.5, P_WK: 0,      A_WK: 0,      D_WK: 0,    P_CUM: 0,       A_CUM: 0,       D_CUM: 0, },
{ CDATE: '2024-02-23', PHASE: 'Engineering',   GROUP: 'Shop Drawing',   SUBCONT: 'OVERALL',      TOTAL: 12252.5, P_WK: 0,      A_WK: 0,      D_WK: 0,    P_CUM: 0,       A_CUM: 0,       D_CUM: 0, },
{ CDATE: '2024-03-29', PHASE: 'Engineering',   GROUP: 'Shop Drawing',   SUBCONT: 'OVERALL',      TOTAL: 12252.5, P_WK: 0,      A_WK: 0,      D_WK: 0,    P_CUM: 0,       A_CUM: 0,       D_CUM: 0, },
{ CDATE: '2024-04-26', PHASE: 'Engineering',   GROUP: 'Shop Drawing',   SUBCONT: 'OVERALL',      TOTAL: 12252.5, P_WK: 0,      A_WK: 0,      D_WK: 0,    P_CUM: 0,       A_CUM: 0,       D_CUM: 0, },
{ CDATE: '2024-05-31', PHASE: 'Engineering',   GROUP: 'Shop Drawing',   SUBCONT: 'OVERALL',      TOTAL: 12252.5, P_WK: 0,      A_WK: 0,      D_WK: 0,    P_CUM: 0,       A_CUM: 0,       D_CUM: 0, },
{ CDATE: '2024-06-28', PHASE: 'Engineering',   GROUP: 'Shop Drawing',   SUBCONT: 'OVERALL',      TOTAL: 12252.5, P_WK: 0,      A_WK: 0,      D_WK: 0,    P_CUM: 0,       A_CUM: 0,       D_CUM: 0, },
{ CDATE: '2024-07-26', PHASE: 'Engineering',   GROUP: 'Shop Drawing',   SUBCONT: 'OVERALL',      TOTAL: 12252.5, P_WK: 0,      A_WK: 0,      D_WK: 0,    P_CUM: 0,       A_CUM: 0,       D_CUM: 0, },
{ CDATE: '2024-08-30', PHASE: 'Engineering',   GROUP: 'Shop Drawing',   SUBCONT: 'OVERALL',      TOTAL: 12252.5, P_WK: 0,      A_WK: 0,      D_WK: 0,    P_CUM: 0,       A_CUM: 0,       D_CUM: 0, },
{ CDATE: '2024-09-27', PHASE: 'Engineering',   GROUP: 'Shop Drawing',   SUBCONT: 'OVERALL',      TOTAL: 12252.5, P_WK: 44.2,   A_WK: 44.2,   D_WK: 0,    P_CUM: 44.2,    A_CUM: 44.2,    D_CUM: 0, },
{ CDATE: '2024-10-25', PHASE: 'Engineering',   GROUP: 'Shop Drawing',   SUBCONT: 'OVERALL',      TOTAL: 12252.5, P_WK: 58.8,   A_WK: 58.8,   D_WK: 0,    P_CUM: 103,     A_CUM: 103,     D_CUM: 0, },
{ CDATE: '2024-11-29', PHASE: 'Engineering',   GROUP: 'Shop Drawing',   SUBCONT: 'OVERALL',      TOTAL: 12252.5, P_WK: 246.7,  A_WK: 192.1,  D_WK: -55,  P_CUM: 349.7,   A_CUM: 295.1,   D_CUM: -55, },
{ CDATE: '2024-12-27', PHASE: 'Engineering',   GROUP: 'Shop Drawing',   SUBCONT: 'OVERALL',      TOTAL: 12252.5, P_WK: 373.7,  A_WK: 0,      D_WK: 0,    P_CUM: 723.4,   A_CUM: 295.1,   D_CUM: 0, },
{ CDATE: '2025-01-31', PHASE: 'Engineering',   GROUP: 'Shop Drawing',   SUBCONT: 'OVERALL',      TOTAL: 12252.5, P_WK: 849.1,  A_WK: 0,      D_WK: 0,    P_CUM: 1572.5,  A_CUM: 295.1,   D_CUM: 0, },
{ CDATE: '2025-02-28', PHASE: 'Engineering',   GROUP: 'Shop Drawing',   SUBCONT: 'OVERALL',      TOTAL: 12252.5, P_WK: 986.8,  A_WK: 0,      D_WK: 0,    P_CUM: 2559.3,  A_CUM: 295.1,   D_CUM: 0, },
{ CDATE: '2025-03-28', PHASE: 'Engineering',   GROUP: 'Shop Drawing',   SUBCONT: 'OVERALL',      TOTAL: 12252.5, P_WK: 1436.1, A_WK: 0,      D_WK: 0,    P_CUM: 3995.4,  A_CUM: 295.1,   D_CUM: 0, },
{ CDATE: '2025-04-25', PHASE: 'Engineering',   GROUP: 'Shop Drawing',   SUBCONT: 'OVERALL',      TOTAL: 12252.5, P_WK: 1215.3, A_WK: 0,      D_WK: 0,    P_CUM: 5210.7,  A_CUM: 295.1,   D_CUM: 0, },
{ CDATE: '2025-05-30', PHASE: 'Engineering',   GROUP: 'Shop Drawing',   SUBCONT: 'OVERALL',      TOTAL: 12252.5, P_WK: 1750.8, A_WK: 0,      D_WK: 0,    P_CUM: 6961.5,  A_CUM: 295.1,   D_CUM: 0, },
{ CDATE: '2025-06-27', PHASE: 'Engineering',   GROUP: 'Shop Drawing',   SUBCONT: 'OVERALL',      TOTAL: 12252.5, P_WK: 1094.7, A_WK: 0,      D_WK: 0,    P_CUM: 8056.2,  A_CUM: 295.1,   D_CUM: 0, },
{ CDATE: '2025-07-25', PHASE: 'Engineering',   GROUP: 'Shop Drawing',   SUBCONT: 'OVERALL',      TOTAL: 12252.5, P_WK: 1058,   A_WK: 0,      D_WK: 0,    P_CUM: 9114.2,  A_CUM: 295.1,   D_CUM: 0, },
{ CDATE: '2025-08-29', PHASE: 'Engineering',   GROUP: 'Shop Drawing',   SUBCONT: 'OVERALL',      TOTAL: 12252.5, P_WK: 1231.5, A_WK: 0,      D_WK: 0,    P_CUM: 10345.7, A_CUM: 295.1,   D_CUM: 0, },
{ CDATE: '2025-09-26', PHASE: 'Engineering',   GROUP: 'Shop Drawing',   SUBCONT: 'OVERALL',      TOTAL: 12252.5, P_WK: 641.6,  A_WK: 0,      D_WK: 0,    P_CUM: 10987.3, A_CUM: 295.1,   D_CUM: 0, },
{ CDATE: '2025-10-31', PHASE: 'Engineering',   GROUP: 'Shop Drawing',   SUBCONT: 'OVERALL',      TOTAL: 12252.5, P_WK: 468.2,  A_WK: 0,      D_WK: 0,    P_CUM: 11455.5, A_CUM: 295.1,   D_CUM: 0, },
{ CDATE: '2025-11-28', PHASE: 'Engineering',   GROUP: 'Shop Drawing',   SUBCONT: 'OVERALL',      TOTAL: 12252.5, P_WK: 294.8,  A_WK: 0,      D_WK: 0,    P_CUM: 11750.3, A_CUM: 295.1,   D_CUM: 0, },
{ CDATE: '2025-12-26', PHASE: 'Engineering',   GROUP: 'Shop Drawing',   SUBCONT: 'OVERALL',      TOTAL: 12252.5, P_WK: 57,     A_WK: 0,      D_WK: 0,    P_CUM: 11807.3, A_CUM: 295.1,   D_CUM: 0, },
{ CDATE: '2026-01-30', PHASE: 'Engineering',   GROUP: 'Shop Drawing',   SUBCONT: 'OVERALL',      TOTAL: 12252.5, P_WK: 35.4,   A_WK: 0,      D_WK: 0,    P_CUM: 11842.7, A_CUM: 295.1,   D_CUM: 0, },
{ CDATE: '2026-02-27', PHASE: 'Engineering',   GROUP: 'Shop Drawing',   SUBCONT: 'OVERALL',      TOTAL: 12252.5, P_WK: 74,     A_WK: 0,      D_WK: 0,    P_CUM: 11916.7, A_CUM: 295.1,   D_CUM: 0, },
{ CDATE: '2026-03-27', PHASE: 'Engineering',   GROUP: 'Shop Drawing',   SUBCONT: 'OVERALL',      TOTAL: 12252.5, P_WK: 24.4,   A_WK: 0,      D_WK: 0,    P_CUM: 11941.1, A_CUM: 295.1,   D_CUM: 0, },
{ CDATE: '2026-04-24', PHASE: 'Engineering',   GROUP: 'Shop Drawing',   SUBCONT: 'OVERALL',      TOTAL: 12252.5, P_WK: 155.2,  A_WK: 0,      D_WK: 0,    P_CUM: 12096.3, A_CUM: 295.1,   D_CUM: 0, },
{ CDATE: '2026-05-29', PHASE: 'Engineering',   GROUP: 'Shop Drawing',   SUBCONT: 'OVERALL',      TOTAL: 12252.5, P_WK: 13.4,   A_WK: 0,      D_WK: 0,    P_CUM: 12109.7, A_CUM: 295.1,   D_CUM: 0, },
{ CDATE: '2026-06-26', PHASE: 'Engineering',   GROUP: 'Shop Drawing',   SUBCONT: 'OVERALL',      TOTAL: 12252.5, P_WK: 4.6,    A_WK: 0,      D_WK: 0,    P_CUM: 12114.3, A_CUM: 295.1,   D_CUM: 0, },
{ CDATE: '2026-07-31', PHASE: 'Engineering',   GROUP: 'Shop Drawing',   SUBCONT: 'OVERALL',      TOTAL: 12252.5, P_WK: 1.6,    A_WK: 0,      D_WK: 0,    P_CUM: 12115.9, A_CUM: 295.1,   D_CUM: 0, },
{ CDATE: '2026-08-28', PHASE: 'Engineering',   GROUP: 'Shop Drawing',   SUBCONT: 'OVERALL',      TOTAL: 12252.5, P_WK: 0,      A_WK: 0,      D_WK: 0,    P_CUM: 12115.9, A_CUM: 295.1,   D_CUM: 0, },
{ CDATE: '2026-09-25', PHASE: 'Engineering',   GROUP: 'Shop Drawing',   SUBCONT: 'OVERALL',      TOTAL: 12252.5, P_WK: 10.3,   A_WK: 0,      D_WK: 0,    P_CUM: 12126.2, A_CUM: 295.1,   D_CUM: 0, },
{ CDATE: '2026-10-30', PHASE: 'Engineering',   GROUP: 'Shop Drawing',   SUBCONT: 'OVERALL',      TOTAL: 12252.5, P_WK: 31.4,   A_WK: 0,      D_WK: 0,    P_CUM: 12157.6, A_CUM: 295.1,   D_CUM: 0, },
{ CDATE: '2026-11-27', PHASE: 'Engineering',   GROUP: 'Shop Drawing',   SUBCONT: 'OVERALL',      TOTAL: 12252.5, P_WK: 33,     A_WK: 0,      D_WK: 0,    P_CUM: 12190.6, A_CUM: 295.1,   D_CUM: 0, },
{ CDATE: '2026-12-25', PHASE: 'Engineering',   GROUP: 'Shop Drawing',   SUBCONT: 'OVERALL',      TOTAL: 12252.5, P_WK: 17.7,   A_WK: 0,      D_WK: 0,    P_CUM: 12208.3, A_CUM: 295.1,   D_CUM: 0, },
{ CDATE: '2027-01-29', PHASE: 'Engineering',   GROUP: 'Shop Drawing',   SUBCONT: 'OVERALL',      TOTAL: 12252.5, P_WK: 26.5,   A_WK: 0,      D_WK: 0,    P_CUM: 12234.8, A_CUM: 295.1,   D_CUM: 0, },
{ CDATE: '2027-02-26', PHASE: 'Engineering',   GROUP: 'Shop Drawing',   SUBCONT: 'OVERALL',      TOTAL: 12252.5, P_WK: 17.6,   A_WK: 0,      D_WK: 0,    P_CUM: 12252.4, A_CUM: 295.1,   D_CUM: 0, },
{ CDATE: '2027-03-26', PHASE: 'Engineering',   GROUP: 'Shop Drawing',   SUBCONT: 'OVERALL',      TOTAL: 12252.5, P_WK: 0,      A_WK: 0,      D_WK: 0,    P_CUM: 12252.4, A_CUM: 295.1,   D_CUM: 0, },
{ CDATE: '2027-04-30', PHASE: 'Engineering',   GROUP: 'Shop Drawing',   SUBCONT: 'OVERALL',      TOTAL: 12252.5, P_WK: 0,      A_WK: 0,      D_WK: 0,    P_CUM: 12252.4, A_CUM: 295.1,   D_CUM: 0, },
{ CDATE: '2027-05-28', PHASE: 'Engineering',   GROUP: 'Shop Drawing',   SUBCONT: 'OVERALL',      TOTAL: 12252.5, P_WK: 0,      A_WK: 0,      D_WK: 0,    P_CUM: 12252.4, A_CUM: 295.1,   D_CUM: 0, },
{ CDATE: '2027-06-11', PHASE: 'Engineering',   GROUP: 'Shop Drawing',   SUBCONT: 'OVERALL',      TOTAL: 12252.5, P_WK: 0,      A_WK: 0,      D_WK: 0,    P_CUM: 12252.4, A_CUM: 295.1,   D_CUM: 0, },
{ CDATE: '2023-07-28', PHASE: 'Engineering',   GROUP: 'Commissing Eng', SUBCONT: 'Gate',         TOTAL: 551,     P_WK: 0,      A_WK: 0,      D_WK: 0,    P_CUM: 0,       A_CUM: 0,       D_CUM: 0, },
{ CDATE: '2023-08-25', PHASE: 'Engineering',   GROUP: 'Commissing Eng', SUBCONT: 'Gate',         TOTAL: 551,     P_WK: 0,      A_WK: 0,      D_WK: 0,    P_CUM: 0,       A_CUM: 0,       D_CUM: 0, },
{ CDATE: '2023-09-29', PHASE: 'Engineering',   GROUP: 'Commissing Eng', SUBCONT: 'Gate',         TOTAL: 551,     P_WK: 0,      A_WK: 0,      D_WK: 0,    P_CUM: 0,       A_CUM: 0,       D_CUM: 0, },
{ CDATE: '2023-10-27', PHASE: 'Engineering',   GROUP: 'Commissing Eng', SUBCONT: 'Gate',         TOTAL: 551,     P_WK: 0,      A_WK: 0,      D_WK: 0,    P_CUM: 0,       A_CUM: 0,       D_CUM: 0, },
{ CDATE: '2023-11-24', PHASE: 'Engineering',   GROUP: 'Commissing Eng', SUBCONT: 'Gate',         TOTAL: 551,     P_WK: 0,      A_WK: 0,      D_WK: 0,    P_CUM: 0,       A_CUM: 0,       D_CUM: 0, },
{ CDATE: '2023-12-29', PHASE: 'Engineering',   GROUP: 'Commissing Eng', SUBCONT: 'Gate',         TOTAL: 551,     P_WK: 0,      A_WK: 0,      D_WK: 0,    P_CUM: 0,       A_CUM: 0,       D_CUM: 0, },
{ CDATE: '2024-01-26', PHASE: 'Engineering',   GROUP: 'Commissing Eng', SUBCONT: 'Gate',         TOTAL: 551,     P_WK: 0,      A_WK: 0,      D_WK: 0,    P_CUM: 0,       A_CUM: 0,       D_CUM: 0, },
{ CDATE: '2024-02-23', PHASE: 'Engineering',   GROUP: 'Commissing Eng', SUBCONT: 'Gate',         TOTAL: 551,     P_WK: 4,      A_WK: 1,      D_WK: -3,   P_CUM: 4,       A_CUM: 1,       D_CUM: -3, },
{ CDATE: '2024-03-29', PHASE: 'Engineering',   GROUP: 'Commissing Eng', SUBCONT: 'Gate',         TOTAL: 551,     P_WK: 5,      A_WK: 5,      D_WK: 0,    P_CUM: 9,       A_CUM: 6,       D_CUM: -3, },
{ CDATE: '2024-04-26', PHASE: 'Engineering',   GROUP: 'Commissing Eng', SUBCONT: 'Gate',         TOTAL: 551,     P_WK: 4,      A_WK: 6,      D_WK: 2,    P_CUM: 13,      A_CUM: 12,      D_CUM: -1, },
{ CDATE: '2024-05-31', PHASE: 'Engineering',   GROUP: 'Commissing Eng', SUBCONT: 'Gate',         TOTAL: 551,     P_WK: 10,     A_WK: 8.5,    D_WK: -2,   P_CUM: 23,      A_CUM: 20.5,    D_CUM: -3, },
{ CDATE: '2024-06-28', PHASE: 'Engineering',   GROUP: 'Commissing Eng', SUBCONT: 'Gate',         TOTAL: 551,     P_WK: 8,      A_WK: 8,      D_WK: 0,    P_CUM: 31,      A_CUM: 28.5,    D_CUM: -3, },
{ CDATE: '2024-07-26', PHASE: 'Engineering',   GROUP: 'Commissing Eng', SUBCONT: 'Gate',         TOTAL: 551,     P_WK: 8,      A_WK: 7.3,    D_WK: -1,   P_CUM: 39,      A_CUM: 35.8,    D_CUM: -3, },
{ CDATE: '2024-08-30', PHASE: 'Engineering',   GROUP: 'Commissing Eng', SUBCONT: 'Gate',         TOTAL: 551,     P_WK: 10,     A_WK: 10,     D_WK: 0,    P_CUM: 49,      A_CUM: 45.8,    D_CUM: -3, },
{ CDATE: '2024-09-27', PHASE: 'Engineering',   GROUP: 'Commissing Eng', SUBCONT: 'Gate',         TOTAL: 551,     P_WK: 8,      A_WK: 8,      D_WK: 0,    P_CUM: 57,      A_CUM: 53.8,    D_CUM: -3, },
{ CDATE: '2024-10-25', PHASE: 'Engineering',   GROUP: 'Commissing Eng', SUBCONT: 'Gate',         TOTAL: 551,     P_WK: 8,      A_WK: 8,      D_WK: 0,    P_CUM: 65,      A_CUM: 61.8,    D_CUM: -3, },
{ CDATE: '2024-11-29', PHASE: 'Engineering',   GROUP: 'Commissing Eng', SUBCONT: 'Gate',         TOTAL: 551,     P_WK: 15,     A_WK: 9.3,    D_WK: -6,   P_CUM: 80,      A_CUM: 71.1,    D_CUM: -9, },
{ CDATE: '2024-12-27', PHASE: 'Engineering',   GROUP: 'Commissing Eng', SUBCONT: 'Gate',         TOTAL: 551,     P_WK: 12,     A_WK: 0,      D_WK: 0,    P_CUM: 92,      A_CUM: 71.1,    D_CUM: 0, },
{ CDATE: '2025-01-31', PHASE: 'Engineering',   GROUP: 'Commissing Eng', SUBCONT: 'Gate',         TOTAL: 551,     P_WK: 40,     A_WK: 0,      D_WK: 0,    P_CUM: 132,     A_CUM: 71.1,    D_CUM: 0, },
{ CDATE: '2025-02-28', PHASE: 'Engineering',   GROUP: 'Commissing Eng', SUBCONT: 'Gate',         TOTAL: 551,     P_WK: 32,     A_WK: 0,      D_WK: 0,    P_CUM: 164,     A_CUM: 71.1,    D_CUM: 0, },
{ CDATE: '2025-03-28', PHASE: 'Engineering',   GROUP: 'Commissing Eng', SUBCONT: 'Gate',         TOTAL: 551,     P_WK: 32,     A_WK: 0,      D_WK: 0,    P_CUM: 196,     A_CUM: 71.1,    D_CUM: 0, },
{ CDATE: '2025-04-25', PHASE: 'Engineering',   GROUP: 'Commissing Eng', SUBCONT: 'Gate',         TOTAL: 551,     P_WK: 32,     A_WK: 0,      D_WK: 0,    P_CUM: 228,     A_CUM: 71.1,    D_CUM: 0, },
{ CDATE: '2025-05-30', PHASE: 'Engineering',   GROUP: 'Commissing Eng', SUBCONT: 'Gate',         TOTAL: 551,     P_WK: 40,     A_WK: 0,      D_WK: 0,    P_CUM: 268,     A_CUM: 71.1,    D_CUM: 0, },
{ CDATE: '2025-06-27', PHASE: 'Engineering',   GROUP: 'Commissing Eng', SUBCONT: 'Gate',         TOTAL: 551,     P_WK: 36,     A_WK: 0,      D_WK: 0,    P_CUM: 304,     A_CUM: 71.1,    D_CUM: 0, },
{ CDATE: '2025-07-25', PHASE: 'Engineering',   GROUP: 'Commissing Eng', SUBCONT: 'Gate',         TOTAL: 551,     P_WK: 36,     A_WK: 0,      D_WK: 0,    P_CUM: 340,     A_CUM: 71.1,    D_CUM: 0, },
{ CDATE: '2025-08-29', PHASE: 'Engineering',   GROUP: 'Commissing Eng', SUBCONT: 'Gate',         TOTAL: 551,     P_WK: 45,     A_WK: 0,      D_WK: 0,    P_CUM: 385,     A_CUM: 71.1,    D_CUM: 0, },
{ CDATE: '2025-09-26', PHASE: 'Engineering',   GROUP: 'Commissing Eng', SUBCONT: 'Gate',         TOTAL: 551,     P_WK: 36,     A_WK: 0,      D_WK: 0,    P_CUM: 421,     A_CUM: 71.1,    D_CUM: 0, },
{ CDATE: '2025-10-31', PHASE: 'Engineering',   GROUP: 'Commissing Eng', SUBCONT: 'Gate',         TOTAL: 551,     P_WK: 50,     A_WK: 0,      D_WK: 0,    P_CUM: 471,     A_CUM: 71.1,    D_CUM: 0, },
{ CDATE: '2025-11-28', PHASE: 'Engineering',   GROUP: 'Commissing Eng', SUBCONT: 'Gate',         TOTAL: 551,     P_WK: 40,     A_WK: 0,      D_WK: 0,    P_CUM: 511,     A_CUM: 71.1,    D_CUM: 0, },
{ CDATE: '2025-12-26', PHASE: 'Engineering',   GROUP: 'Commissing Eng', SUBCONT: 'Gate',         TOTAL: 551,     P_WK: 40,     A_WK: 0,      D_WK: 0,    P_CUM: 551,     A_CUM: 71.1,    D_CUM: 0, },
{ CDATE: '2026-01-30', PHASE: 'Engineering',   GROUP: 'Commissing Eng', SUBCONT: 'Gate',         TOTAL: 551,     P_WK: 0,      A_WK: 0,      D_WK: 0,    P_CUM: 551,     A_CUM: 71.1,    D_CUM: 0, },
{ CDATE: '2026-02-27', PHASE: 'Engineering',   GROUP: 'Commissing Eng', SUBCONT: 'Gate',         TOTAL: 551,     P_WK: 0,      A_WK: 0,      D_WK: 0,    P_CUM: 551,     A_CUM: 71.1,    D_CUM: 0, },
{ CDATE: '2026-03-27', PHASE: 'Engineering',   GROUP: 'Commissing Eng', SUBCONT: 'Gate',         TOTAL: 551,     P_WK: 0,      A_WK: 0,      D_WK: 0,    P_CUM: 551,     A_CUM: 71.1,    D_CUM: 0, },
{ CDATE: '2026-04-24', PHASE: 'Engineering',   GROUP: 'Commissing Eng', SUBCONT: 'Gate',         TOTAL: 551,     P_WK: 0,      A_WK: 0,      D_WK: 0,    P_CUM: 551,     A_CUM: 71.1,    D_CUM: 0, },
{ CDATE: '2026-05-29', PHASE: 'Engineering',   GROUP: 'Commissing Eng', SUBCONT: 'Gate',         TOTAL: 551,     P_WK: 0,      A_WK: 0,      D_WK: 0,    P_CUM: 551,     A_CUM: 71.1,    D_CUM: 0, },
{ CDATE: '2026-06-26', PHASE: 'Engineering',   GROUP: 'Commissing Eng', SUBCONT: 'Gate',         TOTAL: 551,     P_WK: 0,      A_WK: 0,      D_WK: 0,    P_CUM: 551,     A_CUM: 71.1,    D_CUM: 0, },
{ CDATE: '2026-07-31', PHASE: 'Engineering',   GROUP: 'Commissing Eng', SUBCONT: 'Gate',         TOTAL: 551,     P_WK: 0,      A_WK: 0,      D_WK: 0,    P_CUM: 551,     A_CUM: 71.1,    D_CUM: 0, },
{ CDATE: '2026-08-28', PHASE: 'Engineering',   GROUP: 'Commissing Eng', SUBCONT: 'Gate',         TOTAL: 551,     P_WK: 0,      A_WK: 0,      D_WK: 0,    P_CUM: 551,     A_CUM: 71.1,    D_CUM: 0, },
{ CDATE: '2026-09-25', PHASE: 'Engineering',   GROUP: 'Commissing Eng', SUBCONT: 'Gate',         TOTAL: 551,     P_WK: 0,      A_WK: 0,      D_WK: 0,    P_CUM: 551,     A_CUM: 71.1,    D_CUM: 0, },
{ CDATE: '2026-10-30', PHASE: 'Engineering',   GROUP: 'Commissing Eng', SUBCONT: 'Gate',         TOTAL: 551,     P_WK: 0,      A_WK: 0,      D_WK: 0,    P_CUM: 551,     A_CUM: 71.1,    D_CUM: 0, },
{ CDATE: '2026-11-27', PHASE: 'Engineering',   GROUP: 'Commissing Eng', SUBCONT: 'Gate',         TOTAL: 551,     P_WK: 0,      A_WK: 0,      D_WK: 0,    P_CUM: 551,     A_CUM: 71.1,    D_CUM: 0, },
{ CDATE: '2026-12-25', PHASE: 'Engineering',   GROUP: 'Commissing Eng', SUBCONT: 'Gate',         TOTAL: 551,     P_WK: 0,      A_WK: 0,      D_WK: 0,    P_CUM: 551,     A_CUM: 71.1,    D_CUM: 0, },
{ CDATE: '2027-01-29', PHASE: 'Engineering',   GROUP: 'Commissing Eng', SUBCONT: 'Gate',         TOTAL: 551,     P_WK: 0,      A_WK: 0,      D_WK: 0,    P_CUM: 551,     A_CUM: 71.1,    D_CUM: 0, },
{ CDATE: '2027-02-26', PHASE: 'Engineering',   GROUP: 'Commissing Eng', SUBCONT: 'Gate',         TOTAL: 551,     P_WK: 0,      A_WK: 0,      D_WK: 0,    P_CUM: 551,     A_CUM: 71.1,    D_CUM: 0, },
{ CDATE: '2027-03-26', PHASE: 'Engineering',   GROUP: 'Commissing Eng', SUBCONT: 'Gate',         TOTAL: 551,     P_WK: 0,      A_WK: 0,      D_WK: 0,    P_CUM: 551,     A_CUM: 71.1,    D_CUM: 0, },
{ CDATE: '2027-04-30', PHASE: 'Engineering',   GROUP: 'Commissing Eng', SUBCONT: 'Gate',         TOTAL: 551,     P_WK: 0,      A_WK: 0,      D_WK: 0,    P_CUM: 551,     A_CUM: 71.1,    D_CUM: 0, },
{ CDATE: '2027-05-28', PHASE: 'Engineering',   GROUP: 'Commissing Eng', SUBCONT: 'Gate',         TOTAL: 551,     P_WK: 0,      A_WK: 0,      D_WK: 0,    P_CUM: 551,     A_CUM: 71.1,    D_CUM: 0, },
{ CDATE: '2027-06-11', PHASE: 'Engineering',   GROUP: 'Commissing Eng', SUBCONT: 'Gate',         TOTAL: 551,     P_WK: 0,      A_WK: 0,      D_WK: 0,    P_CUM: 551,     A_CUM: 71.1,    D_CUM: 0, },
{ CDATE: '2023-07-28', PHASE: 'Engineering',   GROUP: 'Commissing Eng', SUBCONT: 'HHI',          TOTAL: 185,     P_WK: 0,      A_WK: 0,      D_WK: 0,    P_CUM: 0,       A_CUM: 0,       D_CUM: 0, },
{ CDATE: '2023-08-25', PHASE: 'Engineering',   GROUP: 'Commissing Eng', SUBCONT: 'HHI',          TOTAL: 185,     P_WK: 0,      A_WK: 0,      D_WK: 0,    P_CUM: 0,       A_CUM: 0,       D_CUM: 0, },
{ CDATE: '2023-09-29', PHASE: 'Engineering',   GROUP: 'Commissing Eng', SUBCONT: 'HHI',          TOTAL: 185,     P_WK: 0,      A_WK: 0,      D_WK: 0,    P_CUM: 0,       A_CUM: 0,       D_CUM: 0, },
{ CDATE: '2023-10-27', PHASE: 'Engineering',   GROUP: 'Commissing Eng', SUBCONT: 'HHI',          TOTAL: 185,     P_WK: 0,      A_WK: 0,      D_WK: 0,    P_CUM: 0,       A_CUM: 0,       D_CUM: 0, },
{ CDATE: '2023-11-24', PHASE: 'Engineering',   GROUP: 'Commissing Eng', SUBCONT: 'HHI',          TOTAL: 185,     P_WK: 0,      A_WK: 0,      D_WK: 0,    P_CUM: 0,       A_CUM: 0,       D_CUM: 0, },
{ CDATE: '2023-12-29', PHASE: 'Engineering',   GROUP: 'Commissing Eng', SUBCONT: 'HHI',          TOTAL: 185,     P_WK: 0,      A_WK: 0,      D_WK: 0,    P_CUM: 0,       A_CUM: 0,       D_CUM: 0, },
{ CDATE: '2024-01-26', PHASE: 'Engineering',   GROUP: 'Commissing Eng', SUBCONT: 'HHI',          TOTAL: 185,     P_WK: 0,      A_WK: 0,      D_WK: 0,    P_CUM: 0,       A_CUM: 0,       D_CUM: 0, },
{ CDATE: '2024-02-23', PHASE: 'Engineering',   GROUP: 'Commissing Eng', SUBCONT: 'HHI',          TOTAL: 185,     P_WK: 0,      A_WK: 0,      D_WK: 0,    P_CUM: 0,       A_CUM: 0,       D_CUM: 0, },
{ CDATE: '2024-03-29', PHASE: 'Engineering',   GROUP: 'Commissing Eng', SUBCONT: 'HHI',          TOTAL: 185,     P_WK: 0,      A_WK: 0,      D_WK: 0,    P_CUM: 0,       A_CUM: 0,       D_CUM: 0, },
{ CDATE: '2024-04-26', PHASE: 'Engineering',   GROUP: 'Commissing Eng', SUBCONT: 'HHI',          TOTAL: 185,     P_WK: 0,      A_WK: 0,      D_WK: 0,    P_CUM: 0,       A_CUM: 0,       D_CUM: 0, },
{ CDATE: '2024-05-31', PHASE: 'Engineering',   GROUP: 'Commissing Eng', SUBCONT: 'HHI',          TOTAL: 185,     P_WK: 3,      A_WK: 1.6,    D_WK: -1,   P_CUM: 3,       A_CUM: 1.6,     D_CUM: -1, },
{ CDATE: '2024-06-28', PHASE: 'Engineering',   GROUP: 'Commissing Eng', SUBCONT: 'HHI',          TOTAL: 185,     P_WK: 4,      A_WK: 2.8,    D_WK: -1,   P_CUM: 7,       A_CUM: 4.4,     D_CUM: -3, },
{ CDATE: '2024-07-26', PHASE: 'Engineering',   GROUP: 'Commissing Eng', SUBCONT: 'HHI',          TOTAL: 185,     P_WK: 4,      A_WK: 5.5,    D_WK: 2,    P_CUM: 11,      A_CUM: 9.9,     D_CUM: -1, },
{ CDATE: '2024-08-30', PHASE: 'Engineering',   GROUP: 'Commissing Eng', SUBCONT: 'HHI',          TOTAL: 185,     P_WK: 5,      A_WK: 2.4,    D_WK: -3,   P_CUM: 16,      A_CUM: 12.3,    D_CUM: -4, },
{ CDATE: '2024-09-27', PHASE: 'Engineering',   GROUP: 'Commissing Eng', SUBCONT: 'HHI',          TOTAL: 185,     P_WK: 4,      A_WK: 3.5,    D_WK: -1,   P_CUM: 20,      A_CUM: 15.8,    D_CUM: -4, },
{ CDATE: '2024-10-25', PHASE: 'Engineering',   GROUP: 'Commissing Eng', SUBCONT: 'HHI',          TOTAL: 185,     P_WK: 4,      A_WK: 3.4,    D_WK: -1,   P_CUM: 24,      A_CUM: 19.2,    D_CUM: -5, },
{ CDATE: '2024-11-29', PHASE: 'Engineering',   GROUP: 'Commissing Eng', SUBCONT: 'HHI',          TOTAL: 185,     P_WK: 10,     A_WK: 5.3,    D_WK: -5,   P_CUM: 34,      A_CUM: 24.5,    D_CUM: -10, },
{ CDATE: '2024-12-27', PHASE: 'Engineering',   GROUP: 'Commissing Eng', SUBCONT: 'HHI',          TOTAL: 185,     P_WK: 8,      A_WK: 0,      D_WK: 0,    P_CUM: 42,      A_CUM: 24.5,    D_CUM: 0, },
{ CDATE: '2025-01-31', PHASE: 'Engineering',   GROUP: 'Commissing Eng', SUBCONT: 'HHI',          TOTAL: 185,     P_WK: 10,     A_WK: 0,      D_WK: 0,    P_CUM: 52,      A_CUM: 24.5,    D_CUM: 0, },
{ CDATE: '2025-02-28', PHASE: 'Engineering',   GROUP: 'Commissing Eng', SUBCONT: 'HHI',          TOTAL: 185,     P_WK: 8,      A_WK: 0,      D_WK: 0,    P_CUM: 60,      A_CUM: 24.5,    D_CUM: 0, },
{ CDATE: '2025-03-28', PHASE: 'Engineering',   GROUP: 'Commissing Eng', SUBCONT: 'HHI',          TOTAL: 185,     P_WK: 8,      A_WK: 0,      D_WK: 0,    P_CUM: 68,      A_CUM: 24.5,    D_CUM: 0, },
{ CDATE: '2025-04-25', PHASE: 'Engineering',   GROUP: 'Commissing Eng', SUBCONT: 'HHI',          TOTAL: 185,     P_WK: 12,     A_WK: 0,      D_WK: 0,    P_CUM: 80,      A_CUM: 24.5,    D_CUM: 0, },
{ CDATE: '2025-05-30', PHASE: 'Engineering',   GROUP: 'Commissing Eng', SUBCONT: 'HHI',          TOTAL: 185,     P_WK: 15,     A_WK: 0,      D_WK: 0,    P_CUM: 95,      A_CUM: 24.5,    D_CUM: 0, },
{ CDATE: '2025-06-27', PHASE: 'Engineering',   GROUP: 'Commissing Eng', SUBCONT: 'HHI',          TOTAL: 185,     P_WK: 12,     A_WK: 0,      D_WK: 0,    P_CUM: 107,     A_CUM: 24.5,    D_CUM: 0, },
{ CDATE: '2025-07-25', PHASE: 'Engineering',   GROUP: 'Commissing Eng', SUBCONT: 'HHI',          TOTAL: 185,     P_WK: 12,     A_WK: 0,      D_WK: 0,    P_CUM: 119,     A_CUM: 24.5,    D_CUM: 0, },
{ CDATE: '2025-08-29', PHASE: 'Engineering',   GROUP: 'Commissing Eng', SUBCONT: 'HHI',          TOTAL: 185,     P_WK: 15,     A_WK: 0,      D_WK: 0,    P_CUM: 134,     A_CUM: 24.5,    D_CUM: 0, },
{ CDATE: '2025-09-26', PHASE: 'Engineering',   GROUP: 'Commissing Eng', SUBCONT: 'HHI',          TOTAL: 185,     P_WK: 12,     A_WK: 0,      D_WK: 0,    P_CUM: 146,     A_CUM: 24.5,    D_CUM: 0, },
{ CDATE: '2025-10-31', PHASE: 'Engineering',   GROUP: 'Commissing Eng', SUBCONT: 'HHI',          TOTAL: 185,     P_WK: 15,     A_WK: 0,      D_WK: 0,    P_CUM: 161,     A_CUM: 24.5,    D_CUM: 0, },
{ CDATE: '2025-11-28', PHASE: 'Engineering',   GROUP: 'Commissing Eng', SUBCONT: 'HHI',          TOTAL: 185,     P_WK: 12,     A_WK: 0,      D_WK: 0,    P_CUM: 173,     A_CUM: 24.5,    D_CUM: 0, },
{ CDATE: '2025-12-26', PHASE: 'Engineering',   GROUP: 'Commissing Eng', SUBCONT: 'HHI',          TOTAL: 185,     P_WK: 12,     A_WK: 0,      D_WK: 0,    P_CUM: 185,     A_CUM: 24.5,    D_CUM: 0, },
{ CDATE: '2026-01-30', PHASE: 'Engineering',   GROUP: 'Commissing Eng', SUBCONT: 'HHI',          TOTAL: 185,     P_WK: 0,      A_WK: 0,      D_WK: 0,    P_CUM: 185,     A_CUM: 24.5,    D_CUM: 0, },
{ CDATE: '2026-02-27', PHASE: 'Engineering',   GROUP: 'Commissing Eng', SUBCONT: 'HHI',          TOTAL: 185,     P_WK: 0,      A_WK: 0,      D_WK: 0,    P_CUM: 185,     A_CUM: 24.5,    D_CUM: 0, },
{ CDATE: '2026-03-27', PHASE: 'Engineering',   GROUP: 'Commissing Eng', SUBCONT: 'HHI',          TOTAL: 185,     P_WK: 0,      A_WK: 0,      D_WK: 0,    P_CUM: 185,     A_CUM: 24.5,    D_CUM: 0, },
{ CDATE: '2026-04-24', PHASE: 'Engineering',   GROUP: 'Commissing Eng', SUBCONT: 'HHI',          TOTAL: 185,     P_WK: 0,      A_WK: 0,      D_WK: 0,    P_CUM: 185,     A_CUM: 24.5,    D_CUM: 0, },
{ CDATE: '2026-05-29', PHASE: 'Engineering',   GROUP: 'Commissing Eng', SUBCONT: 'HHI',          TOTAL: 185,     P_WK: 0,      A_WK: 0,      D_WK: 0,    P_CUM: 185,     A_CUM: 24.5,    D_CUM: 0, },
{ CDATE: '2026-06-26', PHASE: 'Engineering',   GROUP: 'Commissing Eng', SUBCONT: 'HHI',          TOTAL: 185,     P_WK: 0,      A_WK: 0,      D_WK: 0,    P_CUM: 185,     A_CUM: 24.5,    D_CUM: 0, },
{ CDATE: '2026-07-31', PHASE: 'Engineering',   GROUP: 'Commissing Eng', SUBCONT: 'HHI',          TOTAL: 185,     P_WK: 0,      A_WK: 0,      D_WK: 0,    P_CUM: 185,     A_CUM: 24.5,    D_CUM: 0, },
{ CDATE: '2026-08-28', PHASE: 'Engineering',   GROUP: 'Commissing Eng', SUBCONT: 'HHI',          TOTAL: 185,     P_WK: 0,      A_WK: 0,      D_WK: 0,    P_CUM: 185,     A_CUM: 24.5,    D_CUM: 0, },
{ CDATE: '2026-09-25', PHASE: 'Engineering',   GROUP: 'Commissing Eng', SUBCONT: 'HHI',          TOTAL: 185,     P_WK: 0,      A_WK: 0,      D_WK: 0,    P_CUM: 185,     A_CUM: 24.5,    D_CUM: 0, },
{ CDATE: '2026-10-30', PHASE: 'Engineering',   GROUP: 'Commissing Eng', SUBCONT: 'HHI',          TOTAL: 185,     P_WK: 0,      A_WK: 0,      D_WK: 0,    P_CUM: 185,     A_CUM: 24.5,    D_CUM: 0, },
{ CDATE: '2026-11-27', PHASE: 'Engineering',   GROUP: 'Commissing Eng', SUBCONT: 'HHI',          TOTAL: 185,     P_WK: 0,      A_WK: 0,      D_WK: 0,    P_CUM: 185,     A_CUM: 24.5,    D_CUM: 0, },
{ CDATE: '2026-12-25', PHASE: 'Engineering',   GROUP: 'Commissing Eng', SUBCONT: 'HHI',          TOTAL: 185,     P_WK: 0,      A_WK: 0,      D_WK: 0,    P_CUM: 185,     A_CUM: 24.5,    D_CUM: 0, },
{ CDATE: '2027-01-29', PHASE: 'Engineering',   GROUP: 'Commissing Eng', SUBCONT: 'HHI',          TOTAL: 185,     P_WK: 0,      A_WK: 0,      D_WK: 0,    P_CUM: 185,     A_CUM: 24.5,    D_CUM: 0, },
{ CDATE: '2027-02-26', PHASE: 'Engineering',   GROUP: 'Commissing Eng', SUBCONT: 'HHI',          TOTAL: 185,     P_WK: 0,      A_WK: 0,      D_WK: 0,    P_CUM: 185,     A_CUM: 24.5,    D_CUM: 0, },
{ CDATE: '2027-03-26', PHASE: 'Engineering',   GROUP: 'Commissing Eng', SUBCONT: 'HHI',          TOTAL: 185,     P_WK: 0,      A_WK: 0,      D_WK: 0,    P_CUM: 185,     A_CUM: 24.5,    D_CUM: 0, },
{ CDATE: '2027-04-30', PHASE: 'Engineering',   GROUP: 'Commissing Eng', SUBCONT: 'HHI',          TOTAL: 185,     P_WK: 0,      A_WK: 0,      D_WK: 0,    P_CUM: 185,     A_CUM: 24.5,    D_CUM: 0, },
{ CDATE: '2027-05-28', PHASE: 'Engineering',   GROUP: 'Commissing Eng', SUBCONT: 'HHI',          TOTAL: 185,     P_WK: 0,      A_WK: 0,      D_WK: 0,    P_CUM: 185,     A_CUM: 24.5,    D_CUM: 0, },
{ CDATE: '2027-06-11', PHASE: 'Engineering',   GROUP: 'Commissing Eng', SUBCONT: 'HHI',          TOTAL: 185,     P_WK: 0,      A_WK: 0,      D_WK: 0,    P_CUM: 185,     A_CUM: 24.5,    D_CUM: 0, },
{ CDATE: '2023-07-28', PHASE: 'Engineering',   GROUP: 'Commissing Eng', SUBCONT: 'OVERALL',      TOTAL: 736,     P_WK: 0,      A_WK: 0,      D_WK: 0,    P_CUM: 0,       A_CUM: 0,       D_CUM: 0, },
{ CDATE: '2023-08-25', PHASE: 'Engineering',   GROUP: 'Commissing Eng', SUBCONT: 'OVERALL',      TOTAL: 736,     P_WK: 0,      A_WK: 0,      D_WK: 0,    P_CUM: 0,       A_CUM: 0,       D_CUM: 0, },
{ CDATE: '2023-09-29', PHASE: 'Engineering',   GROUP: 'Commissing Eng', SUBCONT: 'OVERALL',      TOTAL: 736,     P_WK: 0,      A_WK: 0,      D_WK: 0,    P_CUM: 0,       A_CUM: 0,       D_CUM: 0, },
{ CDATE: '2023-10-27', PHASE: 'Engineering',   GROUP: 'Commissing Eng', SUBCONT: 'OVERALL',      TOTAL: 736,     P_WK: 0,      A_WK: 0,      D_WK: 0,    P_CUM: 0,       A_CUM: 0,       D_CUM: 0, },
{ CDATE: '2023-11-24', PHASE: 'Engineering',   GROUP: 'Commissing Eng', SUBCONT: 'OVERALL',      TOTAL: 736,     P_WK: 0,      A_WK: 0,      D_WK: 0,    P_CUM: 0,       A_CUM: 0,       D_CUM: 0, },
{ CDATE: '2023-12-29', PHASE: 'Engineering',   GROUP: 'Commissing Eng', SUBCONT: 'OVERALL',      TOTAL: 736,     P_WK: 0,      A_WK: 0,      D_WK: 0,    P_CUM: 0,       A_CUM: 0,       D_CUM: 0, },
{ CDATE: '2024-01-26', PHASE: 'Engineering',   GROUP: 'Commissing Eng', SUBCONT: 'OVERALL',      TOTAL: 736,     P_WK: 0,      A_WK: 0,      D_WK: 0,    P_CUM: 0,       A_CUM: 0,       D_CUM: 0, },
{ CDATE: '2024-02-23', PHASE: 'Engineering',   GROUP: 'Commissing Eng', SUBCONT: 'OVERALL',      TOTAL: 736,     P_WK: 4,      A_WK: 1,      D_WK: -3,   P_CUM: 4,       A_CUM: 1,       D_CUM: -3, },
{ CDATE: '2024-03-29', PHASE: 'Engineering',   GROUP: 'Commissing Eng', SUBCONT: 'OVERALL',      TOTAL: 736,     P_WK: 5,      A_WK: 5,      D_WK: 0,    P_CUM: 9,       A_CUM: 6,       D_CUM: -3, },
{ CDATE: '2024-04-26', PHASE: 'Engineering',   GROUP: 'Commissing Eng', SUBCONT: 'OVERALL',      TOTAL: 736,     P_WK: 4,      A_WK: 6,      D_WK: 2,    P_CUM: 13,      A_CUM: 12,      D_CUM: -1, },
{ CDATE: '2024-05-31', PHASE: 'Engineering',   GROUP: 'Commissing Eng', SUBCONT: 'OVERALL',      TOTAL: 736,     P_WK: 13,     A_WK: 10.1,   D_WK: -3,   P_CUM: 26,      A_CUM: 22.1,    D_CUM: -4, },
{ CDATE: '2024-06-28', PHASE: 'Engineering',   GROUP: 'Commissing Eng', SUBCONT: 'OVERALL',      TOTAL: 736,     P_WK: 12,     A_WK: 10.8,   D_WK: -1,   P_CUM: 38,      A_CUM: 32.9,    D_CUM: -5, },
{ CDATE: '2024-07-26', PHASE: 'Engineering',   GROUP: 'Commissing Eng', SUBCONT: 'OVERALL',      TOTAL: 736,     P_WK: 12,     A_WK: 12.8,   D_WK: 1,    P_CUM: 50,      A_CUM: 45.7,    D_CUM: -4, },
{ CDATE: '2024-08-30', PHASE: 'Engineering',   GROUP: 'Commissing Eng', SUBCONT: 'OVERALL',      TOTAL: 736,     P_WK: 15,     A_WK: 12.4,   D_WK: -3,   P_CUM: 65,      A_CUM: 58.1,    D_CUM: -7, },
{ CDATE: '2024-09-27', PHASE: 'Engineering',   GROUP: 'Commissing Eng', SUBCONT: 'OVERALL',      TOTAL: 736,     P_WK: 12,     A_WK: 11.5,   D_WK: -1,   P_CUM: 77,      A_CUM: 69.6,    D_CUM: -7, },
{ CDATE: '2024-10-25', PHASE: 'Engineering',   GROUP: 'Commissing Eng', SUBCONT: 'OVERALL',      TOTAL: 736,     P_WK: 12,     A_WK: 11.4,   D_WK: -1,   P_CUM: 89,      A_CUM: 81,      D_CUM: -8, },
{ CDATE: '2024-11-29', PHASE: 'Engineering',   GROUP: 'Commissing Eng', SUBCONT: 'OVERALL',      TOTAL: 736,     P_WK: 25,     A_WK: 14.6,   D_WK: -10,  P_CUM: 114,     A_CUM: 95.6,    D_CUM: -18, },
{ CDATE: '2024-12-27', PHASE: 'Engineering',   GROUP: 'Commissing Eng', SUBCONT: 'OVERALL',      TOTAL: 736,     P_WK: 20,     A_WK: 0,      D_WK: 0,    P_CUM: 134,     A_CUM: 95.6,    D_CUM: 0, },
{ CDATE: '2025-01-31', PHASE: 'Engineering',   GROUP: 'Commissing Eng', SUBCONT: 'OVERALL',      TOTAL: 736,     P_WK: 50,     A_WK: 0,      D_WK: 0,    P_CUM: 184,     A_CUM: 95.6,    D_CUM: 0, },
{ CDATE: '2025-02-28', PHASE: 'Engineering',   GROUP: 'Commissing Eng', SUBCONT: 'OVERALL',      TOTAL: 736,     P_WK: 40,     A_WK: 0,      D_WK: 0,    P_CUM: 224,     A_CUM: 95.6,    D_CUM: 0, },
{ CDATE: '2025-03-28', PHASE: 'Engineering',   GROUP: 'Commissing Eng', SUBCONT: 'OVERALL',      TOTAL: 736,     P_WK: 40,     A_WK: 0,      D_WK: 0,    P_CUM: 264,     A_CUM: 95.6,    D_CUM: 0, },
{ CDATE: '2025-04-25', PHASE: 'Engineering',   GROUP: 'Commissing Eng', SUBCONT: 'OVERALL',      TOTAL: 736,     P_WK: 44,     A_WK: 0,      D_WK: 0,    P_CUM: 308,     A_CUM: 95.6,    D_CUM: 0, },
{ CDATE: '2025-05-30', PHASE: 'Engineering',   GROUP: 'Commissing Eng', SUBCONT: 'OVERALL',      TOTAL: 736,     P_WK: 55,     A_WK: 0,      D_WK: 0,    P_CUM: 363,     A_CUM: 95.6,    D_CUM: 0, },
{ CDATE: '2025-06-27', PHASE: 'Engineering',   GROUP: 'Commissing Eng', SUBCONT: 'OVERALL',      TOTAL: 736,     P_WK: 48,     A_WK: 0,      D_WK: 0,    P_CUM: 411,     A_CUM: 95.6,    D_CUM: 0, },
{ CDATE: '2025-07-25', PHASE: 'Engineering',   GROUP: 'Commissing Eng', SUBCONT: 'OVERALL',      TOTAL: 736,     P_WK: 48,     A_WK: 0,      D_WK: 0,    P_CUM: 459,     A_CUM: 95.6,    D_CUM: 0, },
{ CDATE: '2025-08-29', PHASE: 'Engineering',   GROUP: 'Commissing Eng', SUBCONT: 'OVERALL',      TOTAL: 736,     P_WK: 60,     A_WK: 0,      D_WK: 0,    P_CUM: 519,     A_CUM: 95.6,    D_CUM: 0, },
{ CDATE: '2025-09-26', PHASE: 'Engineering',   GROUP: 'Commissing Eng', SUBCONT: 'OVERALL',      TOTAL: 736,     P_WK: 48,     A_WK: 0,      D_WK: 0,    P_CUM: 567,     A_CUM: 95.6,    D_CUM: 0, },
{ CDATE: '2025-10-31', PHASE: 'Engineering',   GROUP: 'Commissing Eng', SUBCONT: 'OVERALL',      TOTAL: 736,     P_WK: 65,     A_WK: 0,      D_WK: 0,    P_CUM: 632,     A_CUM: 95.6,    D_CUM: 0, },
{ CDATE: '2025-11-28', PHASE: 'Engineering',   GROUP: 'Commissing Eng', SUBCONT: 'OVERALL',      TOTAL: 736,     P_WK: 52,     A_WK: 0,      D_WK: 0,    P_CUM: 684,     A_CUM: 95.6,    D_CUM: 0, },
{ CDATE: '2025-12-26', PHASE: 'Engineering',   GROUP: 'Commissing Eng', SUBCONT: 'OVERALL',      TOTAL: 736,     P_WK: 52,     A_WK: 0,      D_WK: 0,    P_CUM: 736,     A_CUM: 95.6,    D_CUM: 0, },
{ CDATE: '2026-01-30', PHASE: 'Engineering',   GROUP: 'Commissing Eng', SUBCONT: 'OVERALL',      TOTAL: 736,     P_WK: 0,      A_WK: 0,      D_WK: 0,    P_CUM: 736,     A_CUM: 95.6,    D_CUM: 0, },
{ CDATE: '2026-02-27', PHASE: 'Engineering',   GROUP: 'Commissing Eng', SUBCONT: 'OVERALL',      TOTAL: 736,     P_WK: 0,      A_WK: 0,      D_WK: 0,    P_CUM: 736,     A_CUM: 95.6,    D_CUM: 0, },
{ CDATE: '2026-03-27', PHASE: 'Engineering',   GROUP: 'Commissing Eng', SUBCONT: 'OVERALL',      TOTAL: 736,     P_WK: 0,      A_WK: 0,      D_WK: 0,    P_CUM: 736,     A_CUM: 95.6,    D_CUM: 0, },
{ CDATE: '2026-04-24', PHASE: 'Engineering',   GROUP: 'Commissing Eng', SUBCONT: 'OVERALL',      TOTAL: 736,     P_WK: 0,      A_WK: 0,      D_WK: 0,    P_CUM: 736,     A_CUM: 95.6,    D_CUM: 0, },
{ CDATE: '2026-05-29', PHASE: 'Engineering',   GROUP: 'Commissing Eng', SUBCONT: 'OVERALL',      TOTAL: 736,     P_WK: 0,      A_WK: 0,      D_WK: 0,    P_CUM: 736,     A_CUM: 95.6,    D_CUM: 0, },
{ CDATE: '2026-06-26', PHASE: 'Engineering',   GROUP: 'Commissing Eng', SUBCONT: 'OVERALL',      TOTAL: 736,     P_WK: 0,      A_WK: 0,      D_WK: 0,    P_CUM: 736,     A_CUM: 95.6,    D_CUM: 0, },
{ CDATE: '2026-07-31', PHASE: 'Engineering',   GROUP: 'Commissing Eng', SUBCONT: 'OVERALL',      TOTAL: 736,     P_WK: 0,      A_WK: 0,      D_WK: 0,    P_CUM: 736,     A_CUM: 95.6,    D_CUM: 0, },
{ CDATE: '2026-08-28', PHASE: 'Engineering',   GROUP: 'Commissing Eng', SUBCONT: 'OVERALL',      TOTAL: 736,     P_WK: 0,      A_WK: 0,      D_WK: 0,    P_CUM: 736,     A_CUM: 95.6,    D_CUM: 0, },
{ CDATE: '2026-09-25', PHASE: 'Engineering',   GROUP: 'Commissing Eng', SUBCONT: 'OVERALL',      TOTAL: 736,     P_WK: 0,      A_WK: 0,      D_WK: 0,    P_CUM: 736,     A_CUM: 95.6,    D_CUM: 0, },
{ CDATE: '2026-10-30', PHASE: 'Engineering',   GROUP: 'Commissing Eng', SUBCONT: 'OVERALL',      TOTAL: 736,     P_WK: 0,      A_WK: 0,      D_WK: 0,    P_CUM: 736,     A_CUM: 95.6,    D_CUM: 0, },
{ CDATE: '2026-11-27', PHASE: 'Engineering',   GROUP: 'Commissing Eng', SUBCONT: 'OVERALL',      TOTAL: 736,     P_WK: 0,      A_WK: 0,      D_WK: 0,    P_CUM: 736,     A_CUM: 95.6,    D_CUM: 0, },
{ CDATE: '2026-12-25', PHASE: 'Engineering',   GROUP: 'Commissing Eng', SUBCONT: 'OVERALL',      TOTAL: 736,     P_WK: 0,      A_WK: 0,      D_WK: 0,    P_CUM: 736,     A_CUM: 95.6,    D_CUM: 0, },
{ CDATE: '2027-01-29', PHASE: 'Engineering',   GROUP: 'Commissing Eng', SUBCONT: 'OVERALL',      TOTAL: 736,     P_WK: 0,      A_WK: 0,      D_WK: 0,    P_CUM: 736,     A_CUM: 95.6,    D_CUM: 0, },
{ CDATE: '2027-02-26', PHASE: 'Engineering',   GROUP: 'Commissing Eng', SUBCONT: 'OVERALL',      TOTAL: 736,     P_WK: 0,      A_WK: 0,      D_WK: 0,    P_CUM: 736,     A_CUM: 95.6,    D_CUM: 0, },
{ CDATE: '2027-03-26', PHASE: 'Engineering',   GROUP: 'Commissing Eng', SUBCONT: 'OVERALL',      TOTAL: 736,     P_WK: 0,      A_WK: 0,      D_WK: 0,    P_CUM: 736,     A_CUM: 95.6,    D_CUM: 0, },
{ CDATE: '2027-04-30', PHASE: 'Engineering',   GROUP: 'Commissing Eng', SUBCONT: 'OVERALL',      TOTAL: 736,     P_WK: 0,      A_WK: 0,      D_WK: 0,    P_CUM: 736,     A_CUM: 95.6,    D_CUM: 0, },
{ CDATE: '2027-05-28', PHASE: 'Engineering',   GROUP: 'Commissing Eng', SUBCONT: 'OVERALL',      TOTAL: 736,     P_WK: 0,      A_WK: 0,      D_WK: 0,    P_CUM: 736,     A_CUM: 95.6,    D_CUM: 0, },
{ CDATE: '2027-06-11', PHASE: 'Engineering',   GROUP: 'Commissing Eng', SUBCONT: 'OVERALL',      TOTAL: 736,     P_WK: 0,      A_WK: 0,      D_WK: 0,    P_CUM: 736,     A_CUM: 95.6,    D_CUM: 0, },
{ CDATE: '2023-07-28', PHASE: 'Engineering',   GROUP: 'OVERALL',        SUBCONT: 'OVERALL',      TOTAL: 45350.7, P_WK: 648.3,  A_WK: 583.2,  D_WK: -65,  P_CUM: 648.3,   A_CUM: 583.2,   D_CUM: -65, },
{ CDATE: '2023-08-25', PHASE: 'Engineering',   GROUP: 'OVERALL',        SUBCONT: 'OVERALL',      TOTAL: 45350.7, P_WK: 867,    A_WK: 755.9,  D_WK: -111, P_CUM: 1515.3,  A_CUM: 1339.1,  D_CUM: -176, },
{ CDATE: '2023-09-29', PHASE: 'Engineering',   GROUP: 'OVERALL',        SUBCONT: 'OVERALL',      TOTAL: 45350.7, P_WK: 1524.4, A_WK: 1195.6, D_WK: -329, P_CUM: 3039.7,  A_CUM: 2534.7,  D_CUM: -505, },
{ CDATE: '2023-10-27', PHASE: 'Engineering',   GROUP: 'OVERALL',        SUBCONT: 'OVERALL',      TOTAL: 45350.7, P_WK: 1330.6, A_WK: 1081.6, D_WK: -249, P_CUM: 4370.3,  A_CUM: 3616.3,  D_CUM: -754, },
{ CDATE: '2023-11-24', PHASE: 'Engineering',   GROUP: 'OVERALL',        SUBCONT: 'OVERALL',      TOTAL: 45350.7, P_WK: 1372.2, A_WK: 1098.4, D_WK: -274, P_CUM: 5742.5,  A_CUM: 4714.7,  D_CUM: -1028, },
{ CDATE: '2023-12-29', PHASE: 'Engineering',   GROUP: 'OVERALL',        SUBCONT: 'OVERALL',      TOTAL: 45350.7, P_WK: 1787.7, A_WK: 1454.5, D_WK: -333, P_CUM: 7530.2,  A_CUM: 6169.2,  D_CUM: -1361, },
{ CDATE: '2024-01-26', PHASE: 'Engineering',   GROUP: 'OVERALL',        SUBCONT: 'OVERALL',      TOTAL: 45350.7, P_WK: 1579.7, A_WK: 1353.1, D_WK: -227, P_CUM: 9109.9,  A_CUM: 7522.3,  D_CUM: -1588, },
{ CDATE: '2024-02-23', PHASE: 'Engineering',   GROUP: 'OVERALL',        SUBCONT: 'OVERALL',      TOTAL: 45350.7, P_WK: 1602.7, A_WK: 1601.4, D_WK: -1,   P_CUM: 10712.6, A_CUM: 9123.7,  D_CUM: -1589, },
{ CDATE: '2024-03-29', PHASE: 'Engineering',   GROUP: 'OVERALL',        SUBCONT: 'OVERALL',      TOTAL: 45350.7, P_WK: 1996,   A_WK: 2029,   D_WK: 33,   P_CUM: 12708.6, A_CUM: 11152.7, D_CUM: -1556, },
{ CDATE: '2024-04-26', PHASE: 'Engineering',   GROUP: 'OVERALL',        SUBCONT: 'OVERALL',      TOTAL: 45350.7, P_WK: 1679.8, A_WK: 1808.6, D_WK: 129,  P_CUM: 14388.4, A_CUM: 12961.3, D_CUM: -1427, },
{ CDATE: '2024-05-31', PHASE: 'Engineering',   GROUP: 'OVERALL',        SUBCONT: 'OVERALL',      TOTAL: 45350.7, P_WK: 2091.6, A_WK: 2207.9, D_WK: 116,  P_CUM: 16480,   A_CUM: 15169.2, D_CUM: -1311, },
{ CDATE: '2024-06-28', PHASE: 'Engineering',   GROUP: 'OVERALL',        SUBCONT: 'OVERALL',      TOTAL: 45350.7, P_WK: 1623.3, A_WK: 1898.1, D_WK: 275,  P_CUM: 18103.3, A_CUM: 17067.3, D_CUM: -1036, },
{ CDATE: '2024-07-26', PHASE: 'Engineering',   GROUP: 'OVERALL',        SUBCONT: 'OVERALL',      TOTAL: 45350.7, P_WK: 1712.3, A_WK: 1893.1, D_WK: 181,  P_CUM: 19815.6, A_CUM: 18960.4, D_CUM: -855, },
{ CDATE: '2024-08-30', PHASE: 'Engineering',   GROUP: 'OVERALL',        SUBCONT: 'OVERALL',      TOTAL: 45350.7, P_WK: 1957.4, A_WK: 2301.7, D_WK: 344,  P_CUM: 21773,   A_CUM: 21262.1, D_CUM: -511, },
{ CDATE: '2024-09-27', PHASE: 'Engineering',   GROUP: 'OVERALL',        SUBCONT: 'OVERALL',      TOTAL: 45350.7, P_WK: 1618.9, A_WK: 1980.7, D_WK: 362,  P_CUM: 23391.9, A_CUM: 23242.8, D_CUM: -149, },
{ CDATE: '2024-10-25', PHASE: 'Engineering',   GROUP: 'OVERALL',        SUBCONT: 'OVERALL',      TOTAL: 45350.7, P_WK: 1482.1, A_WK: 1953.1, D_WK: 471,  P_CUM: 24874,   A_CUM: 25195.9, D_CUM: 322, },
{ CDATE: '2024-11-29', PHASE: 'Engineering',   GROUP: 'OVERALL',        SUBCONT: 'OVERALL',      TOTAL: 45350.7, P_WK: 2162.6, A_WK: 2508.3, D_WK: 346,  P_CUM: 27036.6, A_CUM: 27704.2, D_CUM: 668, },
{ CDATE: '2024-12-27', PHASE: 'Engineering',   GROUP: 'OVERALL',        SUBCONT: 'OVERALL',      TOTAL: 45350.7, P_WK: 1787.9, A_WK: 0,      D_WK: 0,    P_CUM: 28824.5, A_CUM: 27704.2, D_CUM: 0, },
{ CDATE: '2025-01-31', PHASE: 'Engineering',   GROUP: 'OVERALL',        SUBCONT: 'OVERALL',      TOTAL: 45350.7, P_WK: 2335.8, A_WK: 0,      D_WK: 0,    P_CUM: 31160.3, A_CUM: 27704.2, D_CUM: 0, },
{ CDATE: '2025-02-28', PHASE: 'Engineering',   GROUP: 'OVERALL',        SUBCONT: 'OVERALL',      TOTAL: 45350.7, P_WK: 2060.8, A_WK: 0,      D_WK: 0,    P_CUM: 33221.1, A_CUM: 27704.2, D_CUM: 0, },
{ CDATE: '2025-03-28', PHASE: 'Engineering',   GROUP: 'OVERALL',        SUBCONT: 'OVERALL',      TOTAL: 45350.7, P_WK: 2259,   A_WK: 0,      D_WK: 0,    P_CUM: 35480.1, A_CUM: 27704.2, D_CUM: 0, },
{ CDATE: '2025-04-25', PHASE: 'Engineering',   GROUP: 'OVERALL',        SUBCONT: 'OVERALL',      TOTAL: 45350.7, P_WK: 1894.4, A_WK: 0,      D_WK: 0,    P_CUM: 37374.5, A_CUM: 27704.2, D_CUM: 0, },
{ CDATE: '2025-05-30', PHASE: 'Engineering',   GROUP: 'OVERALL',        SUBCONT: 'OVERALL',      TOTAL: 45350.7, P_WK: 2212.3, A_WK: 0,      D_WK: 0,    P_CUM: 39586.8, A_CUM: 27704.2, D_CUM: 0, },
{ CDATE: '2025-06-27', PHASE: 'Engineering',   GROUP: 'OVERALL',        SUBCONT: 'OVERALL',      TOTAL: 45350.7, P_WK: 1146.9, A_WK: 0,      D_WK: 0,    P_CUM: 40733.7, A_CUM: 27704.2, D_CUM: 0, },
{ CDATE: '2025-07-25', PHASE: 'Engineering',   GROUP: 'OVERALL',        SUBCONT: 'OVERALL',      TOTAL: 45350.7, P_WK: 1110.2, A_WK: 0,      D_WK: 0,    P_CUM: 41843.9, A_CUM: 27704.2, D_CUM: 0, },
{ CDATE: '2025-08-29', PHASE: 'Engineering',   GROUP: 'OVERALL',        SUBCONT: 'OVERALL',      TOTAL: 45350.7, P_WK: 1296.8, A_WK: 0,      D_WK: 0,    P_CUM: 43140.7, A_CUM: 27704.2, D_CUM: 0, },
{ CDATE: '2025-09-26', PHASE: 'Engineering',   GROUP: 'OVERALL',        SUBCONT: 'OVERALL',      TOTAL: 45350.7, P_WK: 693.8,  A_WK: 0,      D_WK: 0,    P_CUM: 43834.5, A_CUM: 27704.2, D_CUM: 0, },
{ CDATE: '2025-10-31', PHASE: 'Engineering',   GROUP: 'OVERALL',        SUBCONT: 'OVERALL',      TOTAL: 45350.7, P_WK: 538.5,  A_WK: 0,      D_WK: 0,    P_CUM: 44373,   A_CUM: 27704.2, D_CUM: 0, },
{ CDATE: '2025-11-28', PHASE: 'Engineering',   GROUP: 'OVERALL',        SUBCONT: 'OVERALL',      TOTAL: 45350.7, P_WK: 351,    A_WK: 0,      D_WK: 0,    P_CUM: 44724,   A_CUM: 27704.2, D_CUM: 0, },
{ CDATE: '2025-12-26', PHASE: 'Engineering',   GROUP: 'OVERALL',        SUBCONT: 'OVERALL',      TOTAL: 45350.7, P_WK: 113.2,  A_WK: 0,      D_WK: 0,    P_CUM: 44837.2, A_CUM: 27704.2, D_CUM: 0, },
{ CDATE: '2026-01-30', PHASE: 'Engineering',   GROUP: 'OVERALL',        SUBCONT: 'OVERALL',      TOTAL: 45350.7, P_WK: 40.7,   A_WK: 0,      D_WK: 0,    P_CUM: 44877.9, A_CUM: 27704.2, D_CUM: 0, },
{ CDATE: '2026-02-27', PHASE: 'Engineering',   GROUP: 'OVERALL',        SUBCONT: 'OVERALL',      TOTAL: 45350.7, P_WK: 78.2,   A_WK: 0,      D_WK: 0,    P_CUM: 44956.1, A_CUM: 27704.2, D_CUM: 0, },
{ CDATE: '2026-03-27', PHASE: 'Engineering',   GROUP: 'OVERALL',        SUBCONT: 'OVERALL',      TOTAL: 45350.7, P_WK: 28.6,   A_WK: 0,      D_WK: 0,    P_CUM: 44984.7, A_CUM: 27704.2, D_CUM: 0, },
{ CDATE: '2026-04-24', PHASE: 'Engineering',   GROUP: 'OVERALL',        SUBCONT: 'OVERALL',      TOTAL: 45350.7, P_WK: 159.4,  A_WK: 0,      D_WK: 0,    P_CUM: 45144.1, A_CUM: 27704.2, D_CUM: 0, },
{ CDATE: '2026-05-29', PHASE: 'Engineering',   GROUP: 'OVERALL',        SUBCONT: 'OVERALL',      TOTAL: 45350.7, P_WK: 18.7,   A_WK: 0,      D_WK: 0,    P_CUM: 45162.8, A_CUM: 27704.2, D_CUM: 0, },
{ CDATE: '2026-06-26', PHASE: 'Engineering',   GROUP: 'OVERALL',        SUBCONT: 'OVERALL',      TOTAL: 45350.7, P_WK: 8.8,    A_WK: 0,      D_WK: 0,    P_CUM: 45171.6, A_CUM: 27704.2, D_CUM: 0, },
{ CDATE: '2026-07-31', PHASE: 'Engineering',   GROUP: 'OVERALL',        SUBCONT: 'OVERALL',      TOTAL: 45350.7, P_WK: 6.9,    A_WK: 0,      D_WK: 0,    P_CUM: 45178.5, A_CUM: 27704.2, D_CUM: 0, },
{ CDATE: '2026-08-28', PHASE: 'Engineering',   GROUP: 'OVERALL',        SUBCONT: 'OVERALL',      TOTAL: 45350.7, P_WK: 4.2,    A_WK: 0,      D_WK: 0,    P_CUM: 45182.7, A_CUM: 27704.2, D_CUM: 0, },
{ CDATE: '2026-09-25', PHASE: 'Engineering',   GROUP: 'OVERALL',        SUBCONT: 'OVERALL',      TOTAL: 45350.7, P_WK: 14.5,   A_WK: 0,      D_WK: 0,    P_CUM: 45197.2, A_CUM: 27704.2, D_CUM: 0, },
{ CDATE: '2026-10-30', PHASE: 'Engineering',   GROUP: 'OVERALL',        SUBCONT: 'OVERALL',      TOTAL: 45350.7, P_WK: 36.7,   A_WK: 0,      D_WK: 0,    P_CUM: 45233.9, A_CUM: 27704.2, D_CUM: 0, },
{ CDATE: '2026-11-27', PHASE: 'Engineering',   GROUP: 'OVERALL',        SUBCONT: 'OVERALL',      TOTAL: 45350.7, P_WK: 37.2,   A_WK: 0,      D_WK: 0,    P_CUM: 45271.1, A_CUM: 27704.2, D_CUM: 0, },
{ CDATE: '2026-12-25', PHASE: 'Engineering',   GROUP: 'OVERALL',        SUBCONT: 'OVERALL',      TOTAL: 45350.7, P_WK: 21.9,   A_WK: 0,      D_WK: 0,    P_CUM: 45293,   A_CUM: 27704.2, D_CUM: 0, },
{ CDATE: '2027-01-29', PHASE: 'Engineering',   GROUP: 'OVERALL',        SUBCONT: 'OVERALL',      TOTAL: 45350.7, P_WK: 31.8,   A_WK: 0,      D_WK: 0,    P_CUM: 45324.8, A_CUM: 27704.2, D_CUM: 0, },
{ CDATE: '2027-02-26', PHASE: 'Engineering',   GROUP: 'OVERALL',        SUBCONT: 'OVERALL',      TOTAL: 45350.7, P_WK: 21.8,   A_WK: 0,      D_WK: 0,    P_CUM: 45346.6, A_CUM: 27704.2, D_CUM: 0, },
{ CDATE: '2027-03-26', PHASE: 'Engineering',   GROUP: 'OVERALL',        SUBCONT: 'OVERALL',      TOTAL: 45350.7, P_WK: 4.2,    A_WK: 0,      D_WK: 0,    P_CUM: 45350.8, A_CUM: 27704.2, D_CUM: 0, },
{ CDATE: '2027-04-30', PHASE: 'Engineering',   GROUP: 'OVERALL',        SUBCONT: 'OVERALL',      TOTAL: 45350.7, P_WK: 0,      A_WK: 0,      D_WK: 0,    P_CUM: 45350.8, A_CUM: 27704.2, D_CUM: 0, },
{ CDATE: '2027-05-28', PHASE: 'Engineering',   GROUP: 'OVERALL',        SUBCONT: 'OVERALL',      TOTAL: 45350.7, P_WK: 0,      A_WK: 0,      D_WK: 0,    P_CUM: 45350.8, A_CUM: 27704.2, D_CUM: 0, },
{ CDATE: '2027-06-11', PHASE: 'Engineering',   GROUP: 'OVERALL',        SUBCONT: 'OVERALL',      TOTAL: 45350.7, P_WK: 0,      A_WK: 0,      D_WK: 0,    P_CUM: 45350.8, A_CUM: 27704.2, D_CUM: 0, },
{ CDATE: '2023-07-28', PHASE: 'Construction',  GROUP: 'HHI',            SUBCONT: 'HHI',          TOTAL: 68489.9, P_WK: 0,      A_WK: 0,      D_WK: 0,    P_CUM: 0,       A_CUM: 0,       D_CUM: 0, },
{ CDATE: '2023-08-25', PHASE: 'Construction',  GROUP: 'HHI',            SUBCONT: 'HHI',          TOTAL: 68489.9, P_WK: 0,      A_WK: 0,      D_WK: 0,    P_CUM: 0,       A_CUM: 0,       D_CUM: 0, },
{ CDATE: '2023-09-29', PHASE: 'Construction',  GROUP: 'HHI',            SUBCONT: 'HHI',          TOTAL: 68489.9, P_WK: 0,      A_WK: 0,      D_WK: 0,    P_CUM: 0,       A_CUM: 0,       D_CUM: 0, },
{ CDATE: '2023-10-27', PHASE: 'Construction',  GROUP: 'HHI',            SUBCONT: 'HHI',          TOTAL: 68489.9, P_WK: 0,      A_WK: 0,      D_WK: 0,    P_CUM: 0,       A_CUM: 0,       D_CUM: 0, },
{ CDATE: '2023-11-24', PHASE: 'Construction',  GROUP: 'HHI',            SUBCONT: 'HHI',          TOTAL: 68489.9, P_WK: 0,      A_WK: 0,      D_WK: 0,    P_CUM: 0,       A_CUM: 0,       D_CUM: 0, },
{ CDATE: '2023-12-29', PHASE: 'Construction',  GROUP: 'HHI',            SUBCONT: 'HHI',          TOTAL: 68489.9, P_WK: 0,      A_WK: 0,      D_WK: 0,    P_CUM: 0,       A_CUM: 0,       D_CUM: 0, },
{ CDATE: '2024-01-26', PHASE: 'Construction',  GROUP: 'HHI',            SUBCONT: 'HHI',          TOTAL: 68489.9, P_WK: 0,      A_WK: 0,      D_WK: 0,    P_CUM: 0,       A_CUM: 0,       D_CUM: 0, },
{ CDATE: '2024-02-23', PHASE: 'Construction',  GROUP: 'HHI',            SUBCONT: 'HHI',          TOTAL: 68489.9, P_WK: 0,      A_WK: 0,      D_WK: 0,    P_CUM: 0,       A_CUM: 0,       D_CUM: 0, },
{ CDATE: '2024-03-29', PHASE: 'Construction',  GROUP: 'HHI',            SUBCONT: 'HHI',          TOTAL: 68489.9, P_WK: 0,      A_WK: 0,      D_WK: 0,    P_CUM: 0,       A_CUM: 0,       D_CUM: 0, },
{ CDATE: '2024-04-26', PHASE: 'Construction',  GROUP: 'HHI',            SUBCONT: 'HHI',          TOTAL: 68489.9, P_WK: 0,      A_WK: 0,      D_WK: 0,    P_CUM: 0,       A_CUM: 0,       D_CUM: 0, },
{ CDATE: '2024-05-31', PHASE: 'Construction',  GROUP: 'HHI',            SUBCONT: 'HHI',          TOTAL: 68489.9, P_WK: 0,      A_WK: 0,      D_WK: 0,    P_CUM: 0,       A_CUM: 0,       D_CUM: 0, },
{ CDATE: '2024-06-28', PHASE: 'Construction',  GROUP: 'HHI',            SUBCONT: 'HHI',          TOTAL: 68489.9, P_WK: 0,      A_WK: 0,      D_WK: 0,    P_CUM: 0,       A_CUM: 0,       D_CUM: 0, },
{ CDATE: '2024-07-26', PHASE: 'Construction',  GROUP: 'HHI',            SUBCONT: 'HHI',          TOTAL: 68489.9, P_WK: 0,      A_WK: 0,      D_WK: 0,    P_CUM: 0,       A_CUM: 0,       D_CUM: 0, },
{ CDATE: '2024-08-30', PHASE: 'Construction',  GROUP: 'HHI',            SUBCONT: 'HHI',          TOTAL: 68489.9, P_WK: 0,      A_WK: 0,      D_WK: 0,    P_CUM: 0,       A_CUM: 0,       D_CUM: 0, },
{ CDATE: '2024-09-27', PHASE: 'Construction',  GROUP: 'HHI',            SUBCONT: 'HHI',          TOTAL: 68489.9, P_WK: 0,      A_WK: 0,      D_WK: 0,    P_CUM: 0,       A_CUM: 0,       D_CUM: 0, },
{ CDATE: '2024-10-25', PHASE: 'Construction',  GROUP: 'HHI',            SUBCONT: 'HHI',          TOTAL: 68489.9, P_WK: 0,      A_WK: 0,      D_WK: 0,    P_CUM: 0,       A_CUM: 0,       D_CUM: 0, },
{ CDATE: '2024-11-29', PHASE: 'Construction',  GROUP: 'HHI',            SUBCONT: 'HHI',          TOTAL: 68489.9, P_WK: 199.1,  A_WK: 133.5,  D_WK: -66,  P_CUM: 199.1,   A_CUM: 133.5,   D_CUM: -66, },
{ CDATE: '2024-12-27', PHASE: 'Construction',  GROUP: 'HHI',            SUBCONT: 'HHI',          TOTAL: 68489.9, P_WK: 344.2,  A_WK: 0,      D_WK: 0,    P_CUM: 543.3,   A_CUM: 133.5,   D_CUM: 0, },
{ CDATE: '2025-01-31', PHASE: 'Construction',  GROUP: 'HHI',            SUBCONT: 'HHI',          TOTAL: 68489.9, P_WK: 495,    A_WK: 0,      D_WK: 0,    P_CUM: 1038.3,  A_CUM: 133.5,   D_CUM: 0, },
{ CDATE: '2025-02-28', PHASE: 'Construction',  GROUP: 'HHI',            SUBCONT: 'HHI',          TOTAL: 68489.9, P_WK: 1001.8, A_WK: 0,      D_WK: 0,    P_CUM: 2040.1,  A_CUM: 133.5,   D_CUM: 0, },
{ CDATE: '2025-03-28', PHASE: 'Construction',  GROUP: 'HHI',            SUBCONT: 'HHI',          TOTAL: 68489.9, P_WK: 1816.1, A_WK: 0,      D_WK: 0,    P_CUM: 3856.2,  A_CUM: 133.5,   D_CUM: 0, },
{ CDATE: '2025-04-25', PHASE: 'Construction',  GROUP: 'HHI',            SUBCONT: 'HHI',          TOTAL: 68489.9, P_WK: 2310.5, A_WK: 0,      D_WK: 0,    P_CUM: 6166.7,  A_CUM: 133.5,   D_CUM: 0, },
{ CDATE: '2025-05-30', PHASE: 'Construction',  GROUP: 'HHI',            SUBCONT: 'HHI',          TOTAL: 68489.9, P_WK: 3713.6, A_WK: 0,      D_WK: 0,    P_CUM: 9880.3,  A_CUM: 133.5,   D_CUM: 0, },
{ CDATE: '2025-06-27', PHASE: 'Construction',  GROUP: 'HHI',            SUBCONT: 'HHI',          TOTAL: 68489.9, P_WK: 4438.5, A_WK: 0,      D_WK: 0,    P_CUM: 14318.8, A_CUM: 133.5,   D_CUM: 0, },
{ CDATE: '2025-07-25', PHASE: 'Construction',  GROUP: 'HHI',            SUBCONT: 'HHI',          TOTAL: 68489.9, P_WK: 5738,   A_WK: 0,      D_WK: 0,    P_CUM: 20056.8, A_CUM: 133.5,   D_CUM: 0, },
{ CDATE: '2025-08-29', PHASE: 'Construction',  GROUP: 'HHI',            SUBCONT: 'HHI',          TOTAL: 68489.9, P_WK: 3949.2, A_WK: 0,      D_WK: 0,    P_CUM: 24006,   A_CUM: 133.5,   D_CUM: 0, },
{ CDATE: '2025-09-26', PHASE: 'Construction',  GROUP: 'HHI',            SUBCONT: 'HHI',          TOTAL: 68489.9, P_WK: 5489.6, A_WK: 0,      D_WK: 0,    P_CUM: 29495.6, A_CUM: 133.5,   D_CUM: 0, },
{ CDATE: '2025-10-31', PHASE: 'Construction',  GROUP: 'HHI',            SUBCONT: 'HHI',          TOTAL: 68489.9, P_WK: 4519.3, A_WK: 0,      D_WK: 0,    P_CUM: 34014.9, A_CUM: 133.5,   D_CUM: 0, },
{ CDATE: '2025-11-28', PHASE: 'Construction',  GROUP: 'HHI',            SUBCONT: 'HHI',          TOTAL: 68489.9, P_WK: 5158,   A_WK: 0,      D_WK: 0,    P_CUM: 39172.9, A_CUM: 133.5,   D_CUM: 0, },
{ CDATE: '2025-12-26', PHASE: 'Construction',  GROUP: 'HHI',            SUBCONT: 'HHI',          TOTAL: 68489.9, P_WK: 3922.7, A_WK: 0,      D_WK: 0,    P_CUM: 43095.6, A_CUM: 133.5,   D_CUM: 0, },
{ CDATE: '2026-01-30', PHASE: 'Construction',  GROUP: 'HHI',            SUBCONT: 'HHI',          TOTAL: 68489.9, P_WK: 4638.8, A_WK: 0,      D_WK: 0,    P_CUM: 47734.4, A_CUM: 133.5,   D_CUM: 0, },
{ CDATE: '2026-02-27', PHASE: 'Construction',  GROUP: 'HHI',            SUBCONT: 'HHI',          TOTAL: 68489.9, P_WK: 2224.3, A_WK: 0,      D_WK: 0,    P_CUM: 49958.7, A_CUM: 133.5,   D_CUM: 0, },
{ CDATE: '2026-03-27', PHASE: 'Construction',  GROUP: 'HHI',            SUBCONT: 'HHI',          TOTAL: 68489.9, P_WK: 1475,   A_WK: 0,      D_WK: 0,    P_CUM: 51433.7, A_CUM: 133.5,   D_CUM: 0, },
{ CDATE: '2026-04-24', PHASE: 'Construction',  GROUP: 'HHI',            SUBCONT: 'HHI',          TOTAL: 68489.9, P_WK: 953.2,  A_WK: 0,      D_WK: 0,    P_CUM: 52386.9, A_CUM: 133.5,   D_CUM: 0, },
{ CDATE: '2026-05-29', PHASE: 'Construction',  GROUP: 'HHI',            SUBCONT: 'HHI',          TOTAL: 68489.9, P_WK: 1278.7, A_WK: 0,      D_WK: 0,    P_CUM: 53665.6, A_CUM: 133.5,   D_CUM: 0, },
{ CDATE: '2026-06-26', PHASE: 'Construction',  GROUP: 'HHI',            SUBCONT: 'HHI',          TOTAL: 68489.9, P_WK: 1866.3, A_WK: 0,      D_WK: 0,    P_CUM: 55531.9, A_CUM: 133.5,   D_CUM: 0, },
{ CDATE: '2026-07-31', PHASE: 'Construction',  GROUP: 'HHI',            SUBCONT: 'HHI',          TOTAL: 68489.9, P_WK: 2533.3, A_WK: 0,      D_WK: 0,    P_CUM: 58065.2, A_CUM: 133.5,   D_CUM: 0, },
{ CDATE: '2026-08-28', PHASE: 'Construction',  GROUP: 'HHI',            SUBCONT: 'HHI',          TOTAL: 68489.9, P_WK: 2339.6, A_WK: 0,      D_WK: 0,    P_CUM: 60404.8, A_CUM: 133.5,   D_CUM: 0, },
{ CDATE: '2026-09-25', PHASE: 'Construction',  GROUP: 'HHI',            SUBCONT: 'HHI',          TOTAL: 68489.9, P_WK: 2373.7, A_WK: 0,      D_WK: 0,    P_CUM: 62778.5, A_CUM: 133.5,   D_CUM: 0, },
{ CDATE: '2026-10-30', PHASE: 'Construction',  GROUP: 'HHI',            SUBCONT: 'HHI',          TOTAL: 68489.9, P_WK: 2264.6, A_WK: 0,      D_WK: 0,    P_CUM: 65043.1, A_CUM: 133.5,   D_CUM: 0, },
{ CDATE: '2026-11-27', PHASE: 'Construction',  GROUP: 'HHI',            SUBCONT: 'HHI',          TOTAL: 68489.9, P_WK: 1423.3, A_WK: 0,      D_WK: 0,    P_CUM: 66466.4, A_CUM: 133.5,   D_CUM: 0, },
{ CDATE: '2026-12-25', PHASE: 'Construction',  GROUP: 'HHI',            SUBCONT: 'HHI',          TOTAL: 68489.9, P_WK: 951,    A_WK: 0,      D_WK: 0,    P_CUM: 67417.4, A_CUM: 133.5,   D_CUM: 0, },
{ CDATE: '2027-01-29', PHASE: 'Construction',  GROUP: 'HHI',            SUBCONT: 'HHI',          TOTAL: 68489.9, P_WK: 315.4,  A_WK: 0,      D_WK: 0,    P_CUM: 67732.8, A_CUM: 133.5,   D_CUM: 0, },
{ CDATE: '2027-02-26', PHASE: 'Construction',  GROUP: 'HHI',            SUBCONT: 'HHI',          TOTAL: 68489.9, P_WK: 408.2,  A_WK: 0,      D_WK: 0,    P_CUM: 68141,   A_CUM: 133.5,   D_CUM: 0, },
{ CDATE: '2027-03-26', PHASE: 'Construction',  GROUP: 'HHI',            SUBCONT: 'HHI',          TOTAL: 68489.9, P_WK: 247.4,  A_WK: 0,      D_WK: 0,    P_CUM: 68388.4, A_CUM: 133.5,   D_CUM: 0, },
{ CDATE: '2027-04-30', PHASE: 'Construction',  GROUP: 'HHI',            SUBCONT: 'HHI',          TOTAL: 68489.9, P_WK: 101.4,  A_WK: 0,      D_WK: 0,    P_CUM: 68489.8, A_CUM: 133.5,   D_CUM: 0, },
{ CDATE: '2027-05-28', PHASE: 'Construction',  GROUP: 'HHI',            SUBCONT: 'HHI',          TOTAL: 68489.9, P_WK: 0,      A_WK: 0,      D_WK: 0,    P_CUM: 68489.8, A_CUM: 133.5,   D_CUM: 0, },
{ CDATE: '2027-06-11', PHASE: 'Construction',  GROUP: 'HHI',            SUBCONT: 'HHI',          TOTAL: 68489.9, P_WK: 0,      A_WK: 0,      D_WK: 0,    P_CUM: 68489.8, A_CUM: 133.5,   D_CUM: 0, },
{ CDATE: '2023-07-28', PHASE: 'Construction',  GROUP: 'HHI',            SUBCONT: 'OVERALL',      TOTAL: 68489.9, P_WK: 0,      A_WK: 0,      D_WK: 0,    P_CUM: 0,       A_CUM: 0,       D_CUM: 0, },
{ CDATE: '2023-08-25', PHASE: 'Construction',  GROUP: 'HHI',            SUBCONT: 'OVERALL',      TOTAL: 68489.9, P_WK: 0,      A_WK: 0,      D_WK: 0,    P_CUM: 0,       A_CUM: 0,       D_CUM: 0, },
{ CDATE: '2023-09-29', PHASE: 'Construction',  GROUP: 'HHI',            SUBCONT: 'OVERALL',      TOTAL: 68489.9, P_WK: 0,      A_WK: 0,      D_WK: 0,    P_CUM: 0,       A_CUM: 0,       D_CUM: 0, },
{ CDATE: '2023-10-27', PHASE: 'Construction',  GROUP: 'HHI',            SUBCONT: 'OVERALL',      TOTAL: 68489.9, P_WK: 0,      A_WK: 0,      D_WK: 0,    P_CUM: 0,       A_CUM: 0,       D_CUM: 0, },
{ CDATE: '2023-11-24', PHASE: 'Construction',  GROUP: 'HHI',            SUBCONT: 'OVERALL',      TOTAL: 68489.9, P_WK: 0,      A_WK: 0,      D_WK: 0,    P_CUM: 0,       A_CUM: 0,       D_CUM: 0, },
{ CDATE: '2023-12-29', PHASE: 'Construction',  GROUP: 'HHI',            SUBCONT: 'OVERALL',      TOTAL: 68489.9, P_WK: 0,      A_WK: 0,      D_WK: 0,    P_CUM: 0,       A_CUM: 0,       D_CUM: 0, },
{ CDATE: '2024-01-26', PHASE: 'Construction',  GROUP: 'HHI',            SUBCONT: 'OVERALL',      TOTAL: 68489.9, P_WK: 0,      A_WK: 0,      D_WK: 0,    P_CUM: 0,       A_CUM: 0,       D_CUM: 0, },
{ CDATE: '2024-02-23', PHASE: 'Construction',  GROUP: 'HHI',            SUBCONT: 'OVERALL',      TOTAL: 68489.9, P_WK: 0,      A_WK: 0,      D_WK: 0,    P_CUM: 0,       A_CUM: 0,       D_CUM: 0, },
{ CDATE: '2024-03-29', PHASE: 'Construction',  GROUP: 'HHI',            SUBCONT: 'OVERALL',      TOTAL: 68489.9, P_WK: 0,      A_WK: 0,      D_WK: 0,    P_CUM: 0,       A_CUM: 0,       D_CUM: 0, },
{ CDATE: '2024-04-26', PHASE: 'Construction',  GROUP: 'HHI',            SUBCONT: 'OVERALL',      TOTAL: 68489.9, P_WK: 0,      A_WK: 0,      D_WK: 0,    P_CUM: 0,       A_CUM: 0,       D_CUM: 0, },
{ CDATE: '2024-05-31', PHASE: 'Construction',  GROUP: 'HHI',            SUBCONT: 'OVERALL',      TOTAL: 68489.9, P_WK: 0,      A_WK: 0,      D_WK: 0,    P_CUM: 0,       A_CUM: 0,       D_CUM: 0, },
{ CDATE: '2024-06-28', PHASE: 'Construction',  GROUP: 'HHI',            SUBCONT: 'OVERALL',      TOTAL: 68489.9, P_WK: 0,      A_WK: 0,      D_WK: 0,    P_CUM: 0,       A_CUM: 0,       D_CUM: 0, },
{ CDATE: '2024-07-26', PHASE: 'Construction',  GROUP: 'HHI',            SUBCONT: 'OVERALL',      TOTAL: 68489.9, P_WK: 0,      A_WK: 0,      D_WK: 0,    P_CUM: 0,       A_CUM: 0,       D_CUM: 0, },
{ CDATE: '2024-08-30', PHASE: 'Construction',  GROUP: 'HHI',            SUBCONT: 'OVERALL',      TOTAL: 68489.9, P_WK: 0,      A_WK: 0,      D_WK: 0,    P_CUM: 0,       A_CUM: 0,       D_CUM: 0, },
{ CDATE: '2024-09-27', PHASE: 'Construction',  GROUP: 'HHI',            SUBCONT: 'OVERALL',      TOTAL: 68489.9, P_WK: 0,      A_WK: 0,      D_WK: 0,    P_CUM: 0,       A_CUM: 0,       D_CUM: 0, },
{ CDATE: '2024-10-25', PHASE: 'Construction',  GROUP: 'HHI',            SUBCONT: 'OVERALL',      TOTAL: 68489.9, P_WK: 0,      A_WK: 0,      D_WK: 0,    P_CUM: 0,       A_CUM: 0,       D_CUM: 0, },
{ CDATE: '2024-11-29', PHASE: 'Construction',  GROUP: 'HHI',            SUBCONT: 'OVERALL',      TOTAL: 68489.9, P_WK: 199.1,  A_WK: 133.5,  D_WK: -66,  P_CUM: 199.1,   A_CUM: 133.5,   D_CUM: -66, },
{ CDATE: '2024-12-27', PHASE: 'Construction',  GROUP: 'HHI',            SUBCONT: 'OVERALL',      TOTAL: 68489.9, P_WK: 344.2,  A_WK: 0,      D_WK: 0,    P_CUM: 543.3,   A_CUM: 133.5,   D_CUM: 0, },
{ CDATE: '2025-01-31', PHASE: 'Construction',  GROUP: 'HHI',            SUBCONT: 'OVERALL',      TOTAL: 68489.9, P_WK: 495,    A_WK: 0,      D_WK: 0,    P_CUM: 1038.3,  A_CUM: 133.5,   D_CUM: 0, },
{ CDATE: '2025-02-28', PHASE: 'Construction',  GROUP: 'HHI',            SUBCONT: 'OVERALL',      TOTAL: 68489.9, P_WK: 1001.8, A_WK: 0,      D_WK: 0,    P_CUM: 2040.1,  A_CUM: 133.5,   D_CUM: 0, },
{ CDATE: '2025-03-28', PHASE: 'Construction',  GROUP: 'HHI',            SUBCONT: 'OVERALL',      TOTAL: 68489.9, P_WK: 1816.1, A_WK: 0,      D_WK: 0,    P_CUM: 3856.2,  A_CUM: 133.5,   D_CUM: 0, },
{ CDATE: '2025-04-25', PHASE: 'Construction',  GROUP: 'HHI',            SUBCONT: 'OVERALL',      TOTAL: 68489.9, P_WK: 2310.5, A_WK: 0,      D_WK: 0,    P_CUM: 6166.7,  A_CUM: 133.5,   D_CUM: 0, },
{ CDATE: '2025-05-30', PHASE: 'Construction',  GROUP: 'HHI',            SUBCONT: 'OVERALL',      TOTAL: 68489.9, P_WK: 3713.6, A_WK: 0,      D_WK: 0,    P_CUM: 9880.3,  A_CUM: 133.5,   D_CUM: 0, },
{ CDATE: '2025-06-27', PHASE: 'Construction',  GROUP: 'HHI',            SUBCONT: 'OVERALL',      TOTAL: 68489.9, P_WK: 4438.5, A_WK: 0,      D_WK: 0,    P_CUM: 14318.8, A_CUM: 133.5,   D_CUM: 0, },
{ CDATE: '2025-07-25', PHASE: 'Construction',  GROUP: 'HHI',            SUBCONT: 'OVERALL',      TOTAL: 68489.9, P_WK: 5738,   A_WK: 0,      D_WK: 0,    P_CUM: 20056.8, A_CUM: 133.5,   D_CUM: 0, },
{ CDATE: '2025-08-29', PHASE: 'Construction',  GROUP: 'HHI',            SUBCONT: 'OVERALL',      TOTAL: 68489.9, P_WK: 3949.2, A_WK: 0,      D_WK: 0,    P_CUM: 24006,   A_CUM: 133.5,   D_CUM: 0, },
{ CDATE: '2025-09-26', PHASE: 'Construction',  GROUP: 'HHI',            SUBCONT: 'OVERALL',      TOTAL: 68489.9, P_WK: 5489.6, A_WK: 0,      D_WK: 0,    P_CUM: 29495.6, A_CUM: 133.5,   D_CUM: 0, },
{ CDATE: '2025-10-31', PHASE: 'Construction',  GROUP: 'HHI',            SUBCONT: 'OVERALL',      TOTAL: 68489.9, P_WK: 4519.3, A_WK: 0,      D_WK: 0,    P_CUM: 34014.9, A_CUM: 133.5,   D_CUM: 0, },
{ CDATE: '2025-11-28', PHASE: 'Construction',  GROUP: 'HHI',            SUBCONT: 'OVERALL',      TOTAL: 68489.9, P_WK: 5158,   A_WK: 0,      D_WK: 0,    P_CUM: 39172.9, A_CUM: 133.5,   D_CUM: 0, },
{ CDATE: '2025-12-26', PHASE: 'Construction',  GROUP: 'HHI',            SUBCONT: 'OVERALL',      TOTAL: 68489.9, P_WK: 3922.7, A_WK: 0,      D_WK: 0,    P_CUM: 43095.6, A_CUM: 133.5,   D_CUM: 0, },
{ CDATE: '2026-01-30', PHASE: 'Construction',  GROUP: 'HHI',            SUBCONT: 'OVERALL',      TOTAL: 68489.9, P_WK: 4638.8, A_WK: 0,      D_WK: 0,    P_CUM: 47734.4, A_CUM: 133.5,   D_CUM: 0, },
{ CDATE: '2026-02-27', PHASE: 'Construction',  GROUP: 'HHI',            SUBCONT: 'OVERALL',      TOTAL: 68489.9, P_WK: 2224.3, A_WK: 0,      D_WK: 0,    P_CUM: 49958.7, A_CUM: 133.5,   D_CUM: 0, },
{ CDATE: '2026-03-27', PHASE: 'Construction',  GROUP: 'HHI',            SUBCONT: 'OVERALL',      TOTAL: 68489.9, P_WK: 1475,   A_WK: 0,      D_WK: 0,    P_CUM: 51433.7, A_CUM: 133.5,   D_CUM: 0, },
{ CDATE: '2026-04-24', PHASE: 'Construction',  GROUP: 'HHI',            SUBCONT: 'OVERALL',      TOTAL: 68489.9, P_WK: 953.2,  A_WK: 0,      D_WK: 0,    P_CUM: 52386.9, A_CUM: 133.5,   D_CUM: 0, },
{ CDATE: '2026-05-29', PHASE: 'Construction',  GROUP: 'HHI',            SUBCONT: 'OVERALL',      TOTAL: 68489.9, P_WK: 1278.7, A_WK: 0,      D_WK: 0,    P_CUM: 53665.6, A_CUM: 133.5,   D_CUM: 0, },
{ CDATE: '2026-06-26', PHASE: 'Construction',  GROUP: 'HHI',            SUBCONT: 'OVERALL',      TOTAL: 68489.9, P_WK: 1866.3, A_WK: 0,      D_WK: 0,    P_CUM: 55531.9, A_CUM: 133.5,   D_CUM: 0, },
{ CDATE: '2026-07-31', PHASE: 'Construction',  GROUP: 'HHI',            SUBCONT: 'OVERALL',      TOTAL: 68489.9, P_WK: 2533.3, A_WK: 0,      D_WK: 0,    P_CUM: 58065.2, A_CUM: 133.5,   D_CUM: 0, },
{ CDATE: '2026-08-28', PHASE: 'Construction',  GROUP: 'HHI',            SUBCONT: 'OVERALL',      TOTAL: 68489.9, P_WK: 2339.6, A_WK: 0,      D_WK: 0,    P_CUM: 60404.8, A_CUM: 133.5,   D_CUM: 0, },
{ CDATE: '2026-09-25', PHASE: 'Construction',  GROUP: 'HHI',            SUBCONT: 'OVERALL',      TOTAL: 68489.9, P_WK: 2373.7, A_WK: 0,      D_WK: 0,    P_CUM: 62778.5, A_CUM: 133.5,   D_CUM: 0, },
{ CDATE: '2026-10-30', PHASE: 'Construction',  GROUP: 'HHI',            SUBCONT: 'OVERALL',      TOTAL: 68489.9, P_WK: 2264.6, A_WK: 0,      D_WK: 0,    P_CUM: 65043.1, A_CUM: 133.5,   D_CUM: 0, },
{ CDATE: '2026-11-27', PHASE: 'Construction',  GROUP: 'HHI',            SUBCONT: 'OVERALL',      TOTAL: 68489.9, P_WK: 1423.3, A_WK: 0,      D_WK: 0,    P_CUM: 66466.4, A_CUM: 133.5,   D_CUM: 0, },
{ CDATE: '2026-12-25', PHASE: 'Construction',  GROUP: 'HHI',            SUBCONT: 'OVERALL',      TOTAL: 68489.9, P_WK: 951,    A_WK: 0,      D_WK: 0,    P_CUM: 67417.4, A_CUM: 133.5,   D_CUM: 0, },
{ CDATE: '2027-01-29', PHASE: 'Construction',  GROUP: 'HHI',            SUBCONT: 'OVERALL',      TOTAL: 68489.9, P_WK: 315.4,  A_WK: 0,      D_WK: 0,    P_CUM: 67732.8, A_CUM: 133.5,   D_CUM: 0, },
{ CDATE: '2027-02-26', PHASE: 'Construction',  GROUP: 'HHI',            SUBCONT: 'OVERALL',      TOTAL: 68489.9, P_WK: 408.2,  A_WK: 0,      D_WK: 0,    P_CUM: 68141,   A_CUM: 133.5,   D_CUM: 0, },
{ CDATE: '2027-03-26', PHASE: 'Construction',  GROUP: 'HHI',            SUBCONT: 'OVERALL',      TOTAL: 68489.9, P_WK: 247.4,  A_WK: 0,      D_WK: 0,    P_CUM: 68388.4, A_CUM: 133.5,   D_CUM: 0, },
{ CDATE: '2027-04-30', PHASE: 'Construction',  GROUP: 'HHI',            SUBCONT: 'OVERALL',      TOTAL: 68489.9, P_WK: 101.4,  A_WK: 0,      D_WK: 0,    P_CUM: 68489.8, A_CUM: 133.5,   D_CUM: 0, },
{ CDATE: '2027-05-28', PHASE: 'Construction',  GROUP: 'HHI',            SUBCONT: 'OVERALL',      TOTAL: 68489.9, P_WK: 0,      A_WK: 0,      D_WK: 0,    P_CUM: 68489.8, A_CUM: 133.5,   D_CUM: 0, },
{ CDATE: '2027-06-11', PHASE: 'Construction',  GROUP: 'HHI',            SUBCONT: 'OVERALL',      TOTAL: 68489.9, P_WK: 0,      A_WK: 0,      D_WK: 0,    P_CUM: 68489.8, A_CUM: 133.5,   D_CUM: 0, },
{ CDATE: '2023-07-28', PHASE: 'Construction',  GROUP: 'Subcontractor',  SUBCONT: 'COSCO',        TOTAL: 29533.2, P_WK: 0,      A_WK: 0,      D_WK: 0,    P_CUM: 0,       A_CUM: 0,       D_CUM: 0, },
{ CDATE: '2023-08-25', PHASE: 'Construction',  GROUP: 'Subcontractor',  SUBCONT: 'COSCO',        TOTAL: 29533.2, P_WK: 0,      A_WK: 0,      D_WK: 0,    P_CUM: 0,       A_CUM: 0,       D_CUM: 0, },
{ CDATE: '2023-09-29', PHASE: 'Construction',  GROUP: 'Subcontractor',  SUBCONT: 'COSCO',        TOTAL: 29533.2, P_WK: 0,      A_WK: 0,      D_WK: 0,    P_CUM: 0,       A_CUM: 0,       D_CUM: 0, },
{ CDATE: '2023-10-27', PHASE: 'Construction',  GROUP: 'Subcontractor',  SUBCONT: 'COSCO',        TOTAL: 29533.2, P_WK: 0,      A_WK: 0,      D_WK: 0,    P_CUM: 0,       A_CUM: 0,       D_CUM: 0, },
{ CDATE: '2023-11-24', PHASE: 'Construction',  GROUP: 'Subcontractor',  SUBCONT: 'COSCO',        TOTAL: 29533.2, P_WK: 0,      A_WK: 0,      D_WK: 0,    P_CUM: 0,       A_CUM: 0,       D_CUM: 0, },
{ CDATE: '2023-12-29', PHASE: 'Construction',  GROUP: 'Subcontractor',  SUBCONT: 'COSCO',        TOTAL: 29533.2, P_WK: 0,      A_WK: 0,      D_WK: 0,    P_CUM: 0,       A_CUM: 0,       D_CUM: 0, },
{ CDATE: '2024-01-26', PHASE: 'Construction',  GROUP: 'Subcontractor',  SUBCONT: 'COSCO',        TOTAL: 29533.2, P_WK: 0,      A_WK: 0,      D_WK: 0,    P_CUM: 0,       A_CUM: 0,       D_CUM: 0, },
{ CDATE: '2024-02-23', PHASE: 'Construction',  GROUP: 'Subcontractor',  SUBCONT: 'COSCO',        TOTAL: 29533.2, P_WK: 0,      A_WK: 0,      D_WK: 0,    P_CUM: 0,       A_CUM: 0,       D_CUM: 0, },
{ CDATE: '2024-03-29', PHASE: 'Construction',  GROUP: 'Subcontractor',  SUBCONT: 'COSCO',        TOTAL: 29533.2, P_WK: 0,      A_WK: 0,      D_WK: 0,    P_CUM: 0,       A_CUM: 0,       D_CUM: 0, },
{ CDATE: '2024-04-26', PHASE: 'Construction',  GROUP: 'Subcontractor',  SUBCONT: 'COSCO',        TOTAL: 29533.2, P_WK: 0,      A_WK: 0,      D_WK: 0,    P_CUM: 0,       A_CUM: 0,       D_CUM: 0, },
{ CDATE: '2024-05-31', PHASE: 'Construction',  GROUP: 'Subcontractor',  SUBCONT: 'COSCO',        TOTAL: 29533.2, P_WK: 0,      A_WK: 0,      D_WK: 0,    P_CUM: 0,       A_CUM: 0,       D_CUM: 0, },
{ CDATE: '2024-06-28', PHASE: 'Construction',  GROUP: 'Subcontractor',  SUBCONT: 'COSCO',        TOTAL: 29533.2, P_WK: 0,      A_WK: 0,      D_WK: 0,    P_CUM: 0,       A_CUM: 0,       D_CUM: 0, },
{ CDATE: '2024-07-26', PHASE: 'Construction',  GROUP: 'Subcontractor',  SUBCONT: 'COSCO',        TOTAL: 29533.2, P_WK: 0,      A_WK: 0,      D_WK: 0,    P_CUM: 0,       A_CUM: 0,       D_CUM: 0, },
{ CDATE: '2024-08-30', PHASE: 'Construction',  GROUP: 'Subcontractor',  SUBCONT: 'COSCO',        TOTAL: 29533.2, P_WK: 0,      A_WK: 0,      D_WK: 0,    P_CUM: 0,       A_CUM: 0,       D_CUM: 0, },
{ CDATE: '2024-09-27', PHASE: 'Construction',  GROUP: 'Subcontractor',  SUBCONT: 'COSCO',        TOTAL: 29533.2, P_WK: 0,      A_WK: 0,      D_WK: 0,    P_CUM: 0,       A_CUM: 0,       D_CUM: 0, },
{ CDATE: '2024-10-25', PHASE: 'Construction',  GROUP: 'Subcontractor',  SUBCONT: 'COSCO',        TOTAL: 29533.2, P_WK: 0,      A_WK: 0,      D_WK: 0,    P_CUM: 0,       A_CUM: 0,       D_CUM: 0, },
{ CDATE: '2024-11-29', PHASE: 'Construction',  GROUP: 'Subcontractor',  SUBCONT: 'COSCO',        TOTAL: 29533.2, P_WK: 106,    A_WK: 225.1,  D_WK: 119,  P_CUM: 106,     A_CUM: 225.1,   D_CUM: 119, },
{ CDATE: '2024-12-27', PHASE: 'Construction',  GROUP: 'Subcontractor',  SUBCONT: 'COSCO',        TOTAL: 29533.2, P_WK: 1040.3, A_WK: 0,      D_WK: 0,    P_CUM: 1146.3,  A_CUM: 225.1,   D_CUM: 0, },
{ CDATE: '2025-01-31', PHASE: 'Construction',  GROUP: 'Subcontractor',  SUBCONT: 'COSCO',        TOTAL: 29533.2, P_WK: 3202.4, A_WK: 0,      D_WK: 0,    P_CUM: 4348.7,  A_CUM: 225.1,   D_CUM: 0, },
{ CDATE: '2025-02-28', PHASE: 'Construction',  GROUP: 'Subcontractor',  SUBCONT: 'COSCO',        TOTAL: 29533.2, P_WK: 3167.3, A_WK: 0,      D_WK: 0,    P_CUM: 7516,    A_CUM: 225.1,   D_CUM: 0, },
{ CDATE: '2025-03-28', PHASE: 'Construction',  GROUP: 'Subcontractor',  SUBCONT: 'COSCO',        TOTAL: 29533.2, P_WK: 2633.8, A_WK: 0,      D_WK: 0,    P_CUM: 10149.8, A_CUM: 225.1,   D_CUM: 0, },
{ CDATE: '2025-04-25', PHASE: 'Construction',  GROUP: 'Subcontractor',  SUBCONT: 'COSCO',        TOTAL: 29533.2, P_WK: 3890.9, A_WK: 0,      D_WK: 0,    P_CUM: 14040.7, A_CUM: 225.1,   D_CUM: 0, },
{ CDATE: '2025-05-30', PHASE: 'Construction',  GROUP: 'Subcontractor',  SUBCONT: 'COSCO',        TOTAL: 29533.2, P_WK: 5341.2, A_WK: 0,      D_WK: 0,    P_CUM: 19381.9, A_CUM: 225.1,   D_CUM: 0, },
{ CDATE: '2025-06-27', PHASE: 'Construction',  GROUP: 'Subcontractor',  SUBCONT: 'COSCO',        TOTAL: 29533.2, P_WK: 3100.4, A_WK: 0,      D_WK: 0,    P_CUM: 22482.3, A_CUM: 225.1,   D_CUM: 0, },
{ CDATE: '2025-07-25', PHASE: 'Construction',  GROUP: 'Subcontractor',  SUBCONT: 'COSCO',        TOTAL: 29533.2, P_WK: 2731.5, A_WK: 0,      D_WK: 0,    P_CUM: 25213.8, A_CUM: 225.1,   D_CUM: 0, },
{ CDATE: '2025-08-29', PHASE: 'Construction',  GROUP: 'Subcontractor',  SUBCONT: 'COSCO',        TOTAL: 29533.2, P_WK: 3252.1, A_WK: 0,      D_WK: 0,    P_CUM: 28465.9, A_CUM: 225.1,   D_CUM: 0, },
{ CDATE: '2025-09-26', PHASE: 'Construction',  GROUP: 'Subcontractor',  SUBCONT: 'COSCO',        TOTAL: 29533.2, P_WK: 960.6,  A_WK: 0,      D_WK: 0,    P_CUM: 29426.5, A_CUM: 225.1,   D_CUM: 0, },
{ CDATE: '2025-10-31', PHASE: 'Construction',  GROUP: 'Subcontractor',  SUBCONT: 'COSCO',        TOTAL: 29533.2, P_WK: 106.9,  A_WK: 0,      D_WK: 0,    P_CUM: 29533.4, A_CUM: 225.1,   D_CUM: 0, },
{ CDATE: '2025-11-28', PHASE: 'Construction',  GROUP: 'Subcontractor',  SUBCONT: 'COSCO',        TOTAL: 29533.2, P_WK: 0,      A_WK: 0,      D_WK: 0,    P_CUM: 29533.4, A_CUM: 225.1,   D_CUM: 0, },
{ CDATE: '2025-12-26', PHASE: 'Construction',  GROUP: 'Subcontractor',  SUBCONT: 'COSCO',        TOTAL: 29533.2, P_WK: 0,      A_WK: 0,      D_WK: 0,    P_CUM: 29533.4, A_CUM: 225.1,   D_CUM: 0, },
{ CDATE: '2026-01-30', PHASE: 'Construction',  GROUP: 'Subcontractor',  SUBCONT: 'COSCO',        TOTAL: 29533.2, P_WK: 0,      A_WK: 0,      D_WK: 0,    P_CUM: 29533.4, A_CUM: 225.1,   D_CUM: 0, },
{ CDATE: '2026-02-27', PHASE: 'Construction',  GROUP: 'Subcontractor',  SUBCONT: 'COSCO',        TOTAL: 29533.2, P_WK: 0,      A_WK: 0,      D_WK: 0,    P_CUM: 29533.4, A_CUM: 225.1,   D_CUM: 0, },
{ CDATE: '2026-03-27', PHASE: 'Construction',  GROUP: 'Subcontractor',  SUBCONT: 'COSCO',        TOTAL: 29533.2, P_WK: 0,      A_WK: 0,      D_WK: 0,    P_CUM: 29533.4, A_CUM: 225.1,   D_CUM: 0, },
{ CDATE: '2026-04-24', PHASE: 'Construction',  GROUP: 'Subcontractor',  SUBCONT: 'COSCO',        TOTAL: 29533.2, P_WK: 0,      A_WK: 0,      D_WK: 0,    P_CUM: 29533.4, A_CUM: 225.1,   D_CUM: 0, },
{ CDATE: '2026-05-29', PHASE: 'Construction',  GROUP: 'Subcontractor',  SUBCONT: 'COSCO',        TOTAL: 29533.2, P_WK: 0,      A_WK: 0,      D_WK: 0,    P_CUM: 29533.4, A_CUM: 225.1,   D_CUM: 0, },
{ CDATE: '2026-06-26', PHASE: 'Construction',  GROUP: 'Subcontractor',  SUBCONT: 'COSCO',        TOTAL: 29533.2, P_WK: 0,      A_WK: 0,      D_WK: 0,    P_CUM: 29533.4, A_CUM: 225.1,   D_CUM: 0, },
{ CDATE: '2026-07-31', PHASE: 'Construction',  GROUP: 'Subcontractor',  SUBCONT: 'COSCO',        TOTAL: 29533.2, P_WK: 0,      A_WK: 0,      D_WK: 0,    P_CUM: 29533.4, A_CUM: 225.1,   D_CUM: 0, },
{ CDATE: '2026-08-28', PHASE: 'Construction',  GROUP: 'Subcontractor',  SUBCONT: 'COSCO',        TOTAL: 29533.2, P_WK: 0,      A_WK: 0,      D_WK: 0,    P_CUM: 29533.4, A_CUM: 225.1,   D_CUM: 0, },
{ CDATE: '2026-09-25', PHASE: 'Construction',  GROUP: 'Subcontractor',  SUBCONT: 'COSCO',        TOTAL: 29533.2, P_WK: 0,      A_WK: 0,      D_WK: 0,    P_CUM: 29533.4, A_CUM: 225.1,   D_CUM: 0, },
{ CDATE: '2026-10-30', PHASE: 'Construction',  GROUP: 'Subcontractor',  SUBCONT: 'COSCO',        TOTAL: 29533.2, P_WK: 0,      A_WK: 0,      D_WK: 0,    P_CUM: 29533.4, A_CUM: 225.1,   D_CUM: 0, },
{ CDATE: '2026-11-27', PHASE: 'Construction',  GROUP: 'Subcontractor',  SUBCONT: 'COSCO',        TOTAL: 29533.2, P_WK: 0,      A_WK: 0,      D_WK: 0,    P_CUM: 29533.4, A_CUM: 225.1,   D_CUM: 0, },
{ CDATE: '2026-12-25', PHASE: 'Construction',  GROUP: 'Subcontractor',  SUBCONT: 'COSCO',        TOTAL: 29533.2, P_WK: 0,      A_WK: 0,      D_WK: 0,    P_CUM: 29533.4, A_CUM: 225.1,   D_CUM: 0, },
{ CDATE: '2027-01-29', PHASE: 'Construction',  GROUP: 'Subcontractor',  SUBCONT: 'COSCO',        TOTAL: 29533.2, P_WK: 0,      A_WK: 0,      D_WK: 0,    P_CUM: 29533.4, A_CUM: 225.1,   D_CUM: 0, },
{ CDATE: '2027-02-26', PHASE: 'Construction',  GROUP: 'Subcontractor',  SUBCONT: 'COSCO',        TOTAL: 29533.2, P_WK: 0,      A_WK: 0,      D_WK: 0,    P_CUM: 29533.4, A_CUM: 225.1,   D_CUM: 0, },
{ CDATE: '2027-03-26', PHASE: 'Construction',  GROUP: 'Subcontractor',  SUBCONT: 'COSCO',        TOTAL: 29533.2, P_WK: 0,      A_WK: 0,      D_WK: 0,    P_CUM: 29533.4, A_CUM: 225.1,   D_CUM: 0, },
{ CDATE: '2027-04-30', PHASE: 'Construction',  GROUP: 'Subcontractor',  SUBCONT: 'COSCO',        TOTAL: 29533.2, P_WK: 0,      A_WK: 0,      D_WK: 0,    P_CUM: 29533.4, A_CUM: 225.1,   D_CUM: 0, },
{ CDATE: '2027-05-28', PHASE: 'Construction',  GROUP: 'Subcontractor',  SUBCONT: 'COSCO',        TOTAL: 29533.2, P_WK: 0,      A_WK: 0,      D_WK: 0,    P_CUM: 29533.4, A_CUM: 225.1,   D_CUM: 0, },
{ CDATE: '2027-06-11', PHASE: 'Construction',  GROUP: 'Subcontractor',  SUBCONT: 'COSCO',        TOTAL: 29533.2, P_WK: 0,      A_WK: 0,      D_WK: 0,    P_CUM: 29533.4, A_CUM: 225.1,   D_CUM: 0, },
{ CDATE: '2023-07-28', PHASE: 'Construction',  GROUP: 'Subcontractor',  SUBCONT: 'OVERALL',      TOTAL: 29533.2, P_WK: 0,      A_WK: 0,      D_WK: 0,    P_CUM: 0,       A_CUM: 0,       D_CUM: 0, },
{ CDATE: '2023-08-25', PHASE: 'Construction',  GROUP: 'Subcontractor',  SUBCONT: 'OVERALL',      TOTAL: 29533.2, P_WK: 0,      A_WK: 0,      D_WK: 0,    P_CUM: 0,       A_CUM: 0,       D_CUM: 0, },
{ CDATE: '2023-09-29', PHASE: 'Construction',  GROUP: 'Subcontractor',  SUBCONT: 'OVERALL',      TOTAL: 29533.2, P_WK: 0,      A_WK: 0,      D_WK: 0,    P_CUM: 0,       A_CUM: 0,       D_CUM: 0, },
{ CDATE: '2023-10-27', PHASE: 'Construction',  GROUP: 'Subcontractor',  SUBCONT: 'OVERALL',      TOTAL: 29533.2, P_WK: 0,      A_WK: 0,      D_WK: 0,    P_CUM: 0,       A_CUM: 0,       D_CUM: 0, },
{ CDATE: '2023-11-24', PHASE: 'Construction',  GROUP: 'Subcontractor',  SUBCONT: 'OVERALL',      TOTAL: 29533.2, P_WK: 0,      A_WK: 0,      D_WK: 0,    P_CUM: 0,       A_CUM: 0,       D_CUM: 0, },
{ CDATE: '2023-12-29', PHASE: 'Construction',  GROUP: 'Subcontractor',  SUBCONT: 'OVERALL',      TOTAL: 29533.2, P_WK: 0,      A_WK: 0,      D_WK: 0,    P_CUM: 0,       A_CUM: 0,       D_CUM: 0, },
{ CDATE: '2024-01-26', PHASE: 'Construction',  GROUP: 'Subcontractor',  SUBCONT: 'OVERALL',      TOTAL: 29533.2, P_WK: 0,      A_WK: 0,      D_WK: 0,    P_CUM: 0,       A_CUM: 0,       D_CUM: 0, },
{ CDATE: '2024-02-23', PHASE: 'Construction',  GROUP: 'Subcontractor',  SUBCONT: 'OVERALL',      TOTAL: 29533.2, P_WK: 0,      A_WK: 0,      D_WK: 0,    P_CUM: 0,       A_CUM: 0,       D_CUM: 0, },
{ CDATE: '2024-03-29', PHASE: 'Construction',  GROUP: 'Subcontractor',  SUBCONT: 'OVERALL',      TOTAL: 29533.2, P_WK: 0,      A_WK: 0,      D_WK: 0,    P_CUM: 0,       A_CUM: 0,       D_CUM: 0, },
{ CDATE: '2024-04-26', PHASE: 'Construction',  GROUP: 'Subcontractor',  SUBCONT: 'OVERALL',      TOTAL: 29533.2, P_WK: 0,      A_WK: 0,      D_WK: 0,    P_CUM: 0,       A_CUM: 0,       D_CUM: 0, },
{ CDATE: '2024-05-31', PHASE: 'Construction',  GROUP: 'Subcontractor',  SUBCONT: 'OVERALL',      TOTAL: 29533.2, P_WK: 0,      A_WK: 0,      D_WK: 0,    P_CUM: 0,       A_CUM: 0,       D_CUM: 0, },
{ CDATE: '2024-06-28', PHASE: 'Construction',  GROUP: 'Subcontractor',  SUBCONT: 'OVERALL',      TOTAL: 29533.2, P_WK: 0,      A_WK: 0,      D_WK: 0,    P_CUM: 0,       A_CUM: 0,       D_CUM: 0, },
{ CDATE: '2024-07-26', PHASE: 'Construction',  GROUP: 'Subcontractor',  SUBCONT: 'OVERALL',      TOTAL: 29533.2, P_WK: 0,      A_WK: 0,      D_WK: 0,    P_CUM: 0,       A_CUM: 0,       D_CUM: 0, },
{ CDATE: '2024-08-30', PHASE: 'Construction',  GROUP: 'Subcontractor',  SUBCONT: 'OVERALL',      TOTAL: 29533.2, P_WK: 0,      A_WK: 0,      D_WK: 0,    P_CUM: 0,       A_CUM: 0,       D_CUM: 0, },
{ CDATE: '2024-09-27', PHASE: 'Construction',  GROUP: 'Subcontractor',  SUBCONT: 'OVERALL',      TOTAL: 29533.2, P_WK: 0,      A_WK: 0,      D_WK: 0,    P_CUM: 0,       A_CUM: 0,       D_CUM: 0, },
{ CDATE: '2024-10-25', PHASE: 'Construction',  GROUP: 'Subcontractor',  SUBCONT: 'OVERALL',      TOTAL: 29533.2, P_WK: 0,      A_WK: 0,      D_WK: 0,    P_CUM: 0,       A_CUM: 0,       D_CUM: 0, },
{ CDATE: '2024-11-29', PHASE: 'Construction',  GROUP: 'Subcontractor',  SUBCONT: 'OVERALL',      TOTAL: 29533.2, P_WK: 106,    A_WK: 432.1,  D_WK: 326,  P_CUM: 106,     A_CUM: 432.1,   D_CUM: 326, },
{ CDATE: '2024-12-27', PHASE: 'Construction',  GROUP: 'Subcontractor',  SUBCONT: 'OVERALL',      TOTAL: 29533.2, P_WK: 1040.3, A_WK: 0,      D_WK: 0,    P_CUM: 1146.3,  A_CUM: 432.1,   D_CUM: 0, },
{ CDATE: '2025-01-31', PHASE: 'Construction',  GROUP: 'Subcontractor',  SUBCONT: 'OVERALL',      TOTAL: 29533.2, P_WK: 3202.4, A_WK: 0,      D_WK: 0,    P_CUM: 4348.7,  A_CUM: 432.1,   D_CUM: 0, },
{ CDATE: '2025-02-28', PHASE: 'Construction',  GROUP: 'Subcontractor',  SUBCONT: 'OVERALL',      TOTAL: 29533.2, P_WK: 3167.3, A_WK: 0,      D_WK: 0,    P_CUM: 7516,    A_CUM: 432.1,   D_CUM: 0, },
{ CDATE: '2025-03-28', PHASE: 'Construction',  GROUP: 'Subcontractor',  SUBCONT: 'OVERALL',      TOTAL: 29533.2, P_WK: 2633.8, A_WK: 0,      D_WK: 0,    P_CUM: 10149.8, A_CUM: 432.1,   D_CUM: 0, },
{ CDATE: '2025-04-25', PHASE: 'Construction',  GROUP: 'Subcontractor',  SUBCONT: 'OVERALL',      TOTAL: 29533.2, P_WK: 3890.9, A_WK: 0,      D_WK: 0,    P_CUM: 14040.7, A_CUM: 432.1,   D_CUM: 0, },
{ CDATE: '2025-05-30', PHASE: 'Construction',  GROUP: 'Subcontractor',  SUBCONT: 'OVERALL',      TOTAL: 29533.2, P_WK: 5341.2, A_WK: 0,      D_WK: 0,    P_CUM: 19381.9, A_CUM: 432.1,   D_CUM: 0, },
{ CDATE: '2025-06-27', PHASE: 'Construction',  GROUP: 'Subcontractor',  SUBCONT: 'OVERALL',      TOTAL: 29533.2, P_WK: 3100.4, A_WK: 0,      D_WK: 0,    P_CUM: 22482.3, A_CUM: 432.1,   D_CUM: 0, },
{ CDATE: '2025-07-25', PHASE: 'Construction',  GROUP: 'Subcontractor',  SUBCONT: 'OVERALL',      TOTAL: 29533.2, P_WK: 2731.5, A_WK: 0,      D_WK: 0,    P_CUM: 25213.8, A_CUM: 432.1,   D_CUM: 0, },
{ CDATE: '2025-08-29', PHASE: 'Construction',  GROUP: 'Subcontractor',  SUBCONT: 'OVERALL',      TOTAL: 29533.2, P_WK: 3252.1, A_WK: 0,      D_WK: 0,    P_CUM: 28465.9, A_CUM: 432.1,   D_CUM: 0, },
{ CDATE: '2025-09-26', PHASE: 'Construction',  GROUP: 'Subcontractor',  SUBCONT: 'OVERALL',      TOTAL: 29533.2, P_WK: 960.6,  A_WK: 0,      D_WK: 0,    P_CUM: 29426.5, A_CUM: 432.1,   D_CUM: 0, },
{ CDATE: '2025-10-31', PHASE: 'Construction',  GROUP: 'Subcontractor',  SUBCONT: 'OVERALL',      TOTAL: 29533.2, P_WK: 106.9,  A_WK: 0,      D_WK: 0,    P_CUM: 29533.4, A_CUM: 432.1,   D_CUM: 0, },
{ CDATE: '2025-11-28', PHASE: 'Construction',  GROUP: 'Subcontractor',  SUBCONT: 'OVERALL',      TOTAL: 29533.2, P_WK: 0,      A_WK: 0,      D_WK: 0,    P_CUM: 29533.4, A_CUM: 432.1,   D_CUM: 0, },
{ CDATE: '2025-12-26', PHASE: 'Construction',  GROUP: 'Subcontractor',  SUBCONT: 'OVERALL',      TOTAL: 29533.2, P_WK: 0,      A_WK: 0,      D_WK: 0,    P_CUM: 29533.4, A_CUM: 432.1,   D_CUM: 0, },
{ CDATE: '2026-01-30', PHASE: 'Construction',  GROUP: 'Subcontractor',  SUBCONT: 'OVERALL',      TOTAL: 29533.2, P_WK: 0,      A_WK: 0,      D_WK: 0,    P_CUM: 29533.4, A_CUM: 432.1,   D_CUM: 0, },
{ CDATE: '2026-02-27', PHASE: 'Construction',  GROUP: 'Subcontractor',  SUBCONT: 'OVERALL',      TOTAL: 29533.2, P_WK: 0,      A_WK: 0,      D_WK: 0,    P_CUM: 29533.4, A_CUM: 432.1,   D_CUM: 0, },
{ CDATE: '2026-03-27', PHASE: 'Construction',  GROUP: 'Subcontractor',  SUBCONT: 'OVERALL',      TOTAL: 29533.2, P_WK: 0,      A_WK: 0,      D_WK: 0,    P_CUM: 29533.4, A_CUM: 432.1,   D_CUM: 0, },
{ CDATE: '2026-04-24', PHASE: 'Construction',  GROUP: 'Subcontractor',  SUBCONT: 'OVERALL',      TOTAL: 29533.2, P_WK: 0,      A_WK: 0,      D_WK: 0,    P_CUM: 29533.4, A_CUM: 432.1,   D_CUM: 0, },
{ CDATE: '2026-05-29', PHASE: 'Construction',  GROUP: 'Subcontractor',  SUBCONT: 'OVERALL',      TOTAL: 29533.2, P_WK: 0,      A_WK: 0,      D_WK: 0,    P_CUM: 29533.4, A_CUM: 432.1,   D_CUM: 0, },
{ CDATE: '2026-06-26', PHASE: 'Construction',  GROUP: 'Subcontractor',  SUBCONT: 'OVERALL',      TOTAL: 29533.2, P_WK: 0,      A_WK: 0,      D_WK: 0,    P_CUM: 29533.4, A_CUM: 432.1,   D_CUM: 0, },
{ CDATE: '2026-07-31', PHASE: 'Construction',  GROUP: 'Subcontractor',  SUBCONT: 'OVERALL',      TOTAL: 29533.2, P_WK: 0,      A_WK: 0,      D_WK: 0,    P_CUM: 29533.4, A_CUM: 432.1,   D_CUM: 0, },
{ CDATE: '2026-08-28', PHASE: 'Construction',  GROUP: 'Subcontractor',  SUBCONT: 'OVERALL',      TOTAL: 29533.2, P_WK: 0,      A_WK: 0,      D_WK: 0,    P_CUM: 29533.4, A_CUM: 432.1,   D_CUM: 0, },
{ CDATE: '2026-09-25', PHASE: 'Construction',  GROUP: 'Subcontractor',  SUBCONT: 'OVERALL',      TOTAL: 29533.2, P_WK: 0,      A_WK: 0,      D_WK: 0,    P_CUM: 29533.4, A_CUM: 432.1,   D_CUM: 0, },
{ CDATE: '2026-10-30', PHASE: 'Construction',  GROUP: 'Subcontractor',  SUBCONT: 'OVERALL',      TOTAL: 29533.2, P_WK: 0,      A_WK: 0,      D_WK: 0,    P_CUM: 29533.4, A_CUM: 432.1,   D_CUM: 0, },
{ CDATE: '2026-11-27', PHASE: 'Construction',  GROUP: 'Subcontractor',  SUBCONT: 'OVERALL',      TOTAL: 29533.2, P_WK: 0,      A_WK: 0,      D_WK: 0,    P_CUM: 29533.4, A_CUM: 432.1,   D_CUM: 0, },
{ CDATE: '2026-12-25', PHASE: 'Construction',  GROUP: 'Subcontractor',  SUBCONT: 'OVERALL',      TOTAL: 29533.2, P_WK: 0,      A_WK: 0,      D_WK: 0,    P_CUM: 29533.4, A_CUM: 432.1,   D_CUM: 0, },
{ CDATE: '2027-01-29', PHASE: 'Construction',  GROUP: 'Subcontractor',  SUBCONT: 'OVERALL',      TOTAL: 29533.2, P_WK: 0,      A_WK: 0,      D_WK: 0,    P_CUM: 29533.4, A_CUM: 432.1,   D_CUM: 0, },
{ CDATE: '2027-02-26', PHASE: 'Construction',  GROUP: 'Subcontractor',  SUBCONT: 'OVERALL',      TOTAL: 29533.2, P_WK: 0,      A_WK: 0,      D_WK: 0,    P_CUM: 29533.4, A_CUM: 432.1,   D_CUM: 0, },
{ CDATE: '2027-03-26', PHASE: 'Construction',  GROUP: 'Subcontractor',  SUBCONT: 'OVERALL',      TOTAL: 29533.2, P_WK: 0,      A_WK: 0,      D_WK: 0,    P_CUM: 29533.4, A_CUM: 432.1,   D_CUM: 0, },
{ CDATE: '2027-04-30', PHASE: 'Construction',  GROUP: 'Subcontractor',  SUBCONT: 'OVERALL',      TOTAL: 29533.2, P_WK: 0,      A_WK: 0,      D_WK: 0,    P_CUM: 29533.4, A_CUM: 432.1,   D_CUM: 0, },
{ CDATE: '2027-05-28', PHASE: 'Construction',  GROUP: 'Subcontractor',  SUBCONT: 'OVERALL',      TOTAL: 29533.2, P_WK: 0,      A_WK: 0,      D_WK: 0,    P_CUM: 29533.4, A_CUM: 432.1,   D_CUM: 0, },
{ CDATE: '2027-06-11', PHASE: 'Construction',  GROUP: 'Subcontractor',  SUBCONT: 'OVERALL',      TOTAL: 29533.2, P_WK: 0,      A_WK: 0,      D_WK: 0,    P_CUM: 29533.4, A_CUM: 432.1,   D_CUM: 0, },
{ CDATE: '2023-07-28', PHASE: 'Construction',  GROUP: 'Subcontractor',  SUBCONT: 'ShinHwaTech.', TOTAL: 0,       P_WK: 0,      A_WK: 0,      D_WK: 0,    P_CUM: 0,       A_CUM: 0,       D_CUM: 0, },
{ CDATE: '2023-08-25', PHASE: 'Construction',  GROUP: 'Subcontractor',  SUBCONT: 'ShinHwaTech.', TOTAL: 0,       P_WK: 0,      A_WK: 0,      D_WK: 0,    P_CUM: 0,       A_CUM: 0,       D_CUM: 0, },
{ CDATE: '2023-09-29', PHASE: 'Construction',  GROUP: 'Subcontractor',  SUBCONT: 'ShinHwaTech.', TOTAL: 0,       P_WK: 0,      A_WK: 0,      D_WK: 0,    P_CUM: 0,       A_CUM: 0,       D_CUM: 0, },
{ CDATE: '2023-10-27', PHASE: 'Construction',  GROUP: 'Subcontractor',  SUBCONT: 'ShinHwaTech.', TOTAL: 0,       P_WK: 0,      A_WK: 0,      D_WK: 0,    P_CUM: 0,       A_CUM: 0,       D_CUM: 0, },
{ CDATE: '2023-11-24', PHASE: 'Construction',  GROUP: 'Subcontractor',  SUBCONT: 'ShinHwaTech.', TOTAL: 0,       P_WK: 0,      A_WK: 0,      D_WK: 0,    P_CUM: 0,       A_CUM: 0,       D_CUM: 0, },
{ CDATE: '2023-12-29', PHASE: 'Construction',  GROUP: 'Subcontractor',  SUBCONT: 'ShinHwaTech.', TOTAL: 0,       P_WK: 0,      A_WK: 0,      D_WK: 0,    P_CUM: 0,       A_CUM: 0,       D_CUM: 0, },
{ CDATE: '2024-01-26', PHASE: 'Construction',  GROUP: 'Subcontractor',  SUBCONT: 'ShinHwaTech.', TOTAL: 0,       P_WK: 0,      A_WK: 0,      D_WK: 0,    P_CUM: 0,       A_CUM: 0,       D_CUM: 0, },
{ CDATE: '2024-02-23', PHASE: 'Construction',  GROUP: 'Subcontractor',  SUBCONT: 'ShinHwaTech.', TOTAL: 0,       P_WK: 0,      A_WK: 0,      D_WK: 0,    P_CUM: 0,       A_CUM: 0,       D_CUM: 0, },
{ CDATE: '2024-03-29', PHASE: 'Construction',  GROUP: 'Subcontractor',  SUBCONT: 'ShinHwaTech.', TOTAL: 0,       P_WK: 0,      A_WK: 0,      D_WK: 0,    P_CUM: 0,       A_CUM: 0,       D_CUM: 0, },
{ CDATE: '2024-04-26', PHASE: 'Construction',  GROUP: 'Subcontractor',  SUBCONT: 'ShinHwaTech.', TOTAL: 0,       P_WK: 0,      A_WK: 0,      D_WK: 0,    P_CUM: 0,       A_CUM: 0,       D_CUM: 0, },
{ CDATE: '2024-05-31', PHASE: 'Construction',  GROUP: 'Subcontractor',  SUBCONT: 'ShinHwaTech.', TOTAL: 0,       P_WK: 0,      A_WK: 0,      D_WK: 0,    P_CUM: 0,       A_CUM: 0,       D_CUM: 0, },
{ CDATE: '2024-06-28', PHASE: 'Construction',  GROUP: 'Subcontractor',  SUBCONT: 'ShinHwaTech.', TOTAL: 0,       P_WK: 0,      A_WK: 0,      D_WK: 0,    P_CUM: 0,       A_CUM: 0,       D_CUM: 0, },
{ CDATE: '2024-07-26', PHASE: 'Construction',  GROUP: 'Subcontractor',  SUBCONT: 'ShinHwaTech.', TOTAL: 0,       P_WK: 0,      A_WK: 0,      D_WK: 0,    P_CUM: 0,       A_CUM: 0,       D_CUM: 0, },
{ CDATE: '2024-08-30', PHASE: 'Construction',  GROUP: 'Subcontractor',  SUBCONT: 'ShinHwaTech.', TOTAL: 0,       P_WK: 0,      A_WK: 0,      D_WK: 0,    P_CUM: 0,       A_CUM: 0,       D_CUM: 0, },
{ CDATE: '2024-09-27', PHASE: 'Construction',  GROUP: 'Subcontractor',  SUBCONT: 'ShinHwaTech.', TOTAL: 0,       P_WK: 0,      A_WK: 0,      D_WK: 0,    P_CUM: 0,       A_CUM: 0,       D_CUM: 0, },
{ CDATE: '2024-10-25', PHASE: 'Construction',  GROUP: 'Subcontractor',  SUBCONT: 'ShinHwaTech.', TOTAL: 0,       P_WK: 0,      A_WK: 0,      D_WK: 0,    P_CUM: 0,       A_CUM: 0,       D_CUM: 0, },
{ CDATE: '2024-11-29', PHASE: 'Construction',  GROUP: 'Subcontractor',  SUBCONT: 'ShinHwaTech.', TOTAL: 0,       P_WK: 0,      A_WK: 140,    D_WK: 140,  P_CUM: 0,       A_CUM: 140,     D_CUM: 140, },
{ CDATE: '2024-12-27', PHASE: 'Construction',  GROUP: 'Subcontractor',  SUBCONT: 'ShinHwaTech.', TOTAL: 0,       P_WK: 0,      A_WK: 0,      D_WK: 0,    P_CUM: 0,       A_CUM: 140,     D_CUM: 0, },
{ CDATE: '2025-01-31', PHASE: 'Construction',  GROUP: 'Subcontractor',  SUBCONT: 'ShinHwaTech.', TOTAL: 0,       P_WK: 0,      A_WK: 0,      D_WK: 0,    P_CUM: 0,       A_CUM: 140,     D_CUM: 0, },
{ CDATE: '2025-02-28', PHASE: 'Construction',  GROUP: 'Subcontractor',  SUBCONT: 'ShinHwaTech.', TOTAL: 0,       P_WK: 0,      A_WK: 0,      D_WK: 0,    P_CUM: 0,       A_CUM: 140,     D_CUM: 0, },
{ CDATE: '2025-03-28', PHASE: 'Construction',  GROUP: 'Subcontractor',  SUBCONT: 'ShinHwaTech.', TOTAL: 0,       P_WK: 0,      A_WK: 0,      D_WK: 0,    P_CUM: 0,       A_CUM: 140,     D_CUM: 0, },
{ CDATE: '2025-04-25', PHASE: 'Construction',  GROUP: 'Subcontractor',  SUBCONT: 'ShinHwaTech.', TOTAL: 0,       P_WK: 0,      A_WK: 0,      D_WK: 0,    P_CUM: 0,       A_CUM: 140,     D_CUM: 0, },
{ CDATE: '2025-05-30', PHASE: 'Construction',  GROUP: 'Subcontractor',  SUBCONT: 'ShinHwaTech.', TOTAL: 0,       P_WK: 0,      A_WK: 0,      D_WK: 0,    P_CUM: 0,       A_CUM: 140,     D_CUM: 0, },
{ CDATE: '2025-06-27', PHASE: 'Construction',  GROUP: 'Subcontractor',  SUBCONT: 'ShinHwaTech.', TOTAL: 0,       P_WK: 0,      A_WK: 0,      D_WK: 0,    P_CUM: 0,       A_CUM: 140,     D_CUM: 0, },
{ CDATE: '2025-07-25', PHASE: 'Construction',  GROUP: 'Subcontractor',  SUBCONT: 'ShinHwaTech.', TOTAL: 0,       P_WK: 0,      A_WK: 0,      D_WK: 0,    P_CUM: 0,       A_CUM: 140,     D_CUM: 0, },
{ CDATE: '2025-08-29', PHASE: 'Construction',  GROUP: 'Subcontractor',  SUBCONT: 'ShinHwaTech.', TOTAL: 0,       P_WK: 0,      A_WK: 0,      D_WK: 0,    P_CUM: 0,       A_CUM: 140,     D_CUM: 0, },
{ CDATE: '2025-09-26', PHASE: 'Construction',  GROUP: 'Subcontractor',  SUBCONT: 'ShinHwaTech.', TOTAL: 0,       P_WK: 0,      A_WK: 0,      D_WK: 0,    P_CUM: 0,       A_CUM: 140,     D_CUM: 0, },
{ CDATE: '2025-10-31', PHASE: 'Construction',  GROUP: 'Subcontractor',  SUBCONT: 'ShinHwaTech.', TOTAL: 0,       P_WK: 0,      A_WK: 0,      D_WK: 0,    P_CUM: 0,       A_CUM: 140,     D_CUM: 0, },
{ CDATE: '2025-11-28', PHASE: 'Construction',  GROUP: 'Subcontractor',  SUBCONT: 'ShinHwaTech.', TOTAL: 0,       P_WK: 0,      A_WK: 0,      D_WK: 0,    P_CUM: 0,       A_CUM: 140,     D_CUM: 0, },
{ CDATE: '2025-12-26', PHASE: 'Construction',  GROUP: 'Subcontractor',  SUBCONT: 'ShinHwaTech.', TOTAL: 0,       P_WK: 0,      A_WK: 0,      D_WK: 0,    P_CUM: 0,       A_CUM: 140,     D_CUM: 0, },
{ CDATE: '2026-01-30', PHASE: 'Construction',  GROUP: 'Subcontractor',  SUBCONT: 'ShinHwaTech.', TOTAL: 0,       P_WK: 0,      A_WK: 0,      D_WK: 0,    P_CUM: 0,       A_CUM: 140,     D_CUM: 0, },
{ CDATE: '2026-02-27', PHASE: 'Construction',  GROUP: 'Subcontractor',  SUBCONT: 'ShinHwaTech.', TOTAL: 0,       P_WK: 0,      A_WK: 0,      D_WK: 0,    P_CUM: 0,       A_CUM: 140,     D_CUM: 0, },
{ CDATE: '2026-03-27', PHASE: 'Construction',  GROUP: 'Subcontractor',  SUBCONT: 'ShinHwaTech.', TOTAL: 0,       P_WK: 0,      A_WK: 0,      D_WK: 0,    P_CUM: 0,       A_CUM: 140,     D_CUM: 0, },
{ CDATE: '2026-04-24', PHASE: 'Construction',  GROUP: 'Subcontractor',  SUBCONT: 'ShinHwaTech.', TOTAL: 0,       P_WK: 0,      A_WK: 0,      D_WK: 0,    P_CUM: 0,       A_CUM: 140,     D_CUM: 0, },
{ CDATE: '2026-05-29', PHASE: 'Construction',  GROUP: 'Subcontractor',  SUBCONT: 'ShinHwaTech.', TOTAL: 0,       P_WK: 0,      A_WK: 0,      D_WK: 0,    P_CUM: 0,       A_CUM: 140,     D_CUM: 0, },
{ CDATE: '2026-06-26', PHASE: 'Construction',  GROUP: 'Subcontractor',  SUBCONT: 'ShinHwaTech.', TOTAL: 0,       P_WK: 0,      A_WK: 0,      D_WK: 0,    P_CUM: 0,       A_CUM: 140,     D_CUM: 0, },
{ CDATE: '2026-07-31', PHASE: 'Construction',  GROUP: 'Subcontractor',  SUBCONT: 'ShinHwaTech.', TOTAL: 0,       P_WK: 0,      A_WK: 0,      D_WK: 0,    P_CUM: 0,       A_CUM: 140,     D_CUM: 0, },
{ CDATE: '2026-08-28', PHASE: 'Construction',  GROUP: 'Subcontractor',  SUBCONT: 'ShinHwaTech.', TOTAL: 0,       P_WK: 0,      A_WK: 0,      D_WK: 0,    P_CUM: 0,       A_CUM: 140,     D_CUM: 0, },
{ CDATE: '2026-09-25', PHASE: 'Construction',  GROUP: 'Subcontractor',  SUBCONT: 'ShinHwaTech.', TOTAL: 0,       P_WK: 0,      A_WK: 0,      D_WK: 0,    P_CUM: 0,       A_CUM: 140,     D_CUM: 0, },
{ CDATE: '2026-10-30', PHASE: 'Construction',  GROUP: 'Subcontractor',  SUBCONT: 'ShinHwaTech.', TOTAL: 0,       P_WK: 0,      A_WK: 0,      D_WK: 0,    P_CUM: 0,       A_CUM: 140,     D_CUM: 0, },
{ CDATE: '2026-11-27', PHASE: 'Construction',  GROUP: 'Subcontractor',  SUBCONT: 'ShinHwaTech.', TOTAL: 0,       P_WK: 0,      A_WK: 0,      D_WK: 0,    P_CUM: 0,       A_CUM: 140,     D_CUM: 0, },
{ CDATE: '2026-12-25', PHASE: 'Construction',  GROUP: 'Subcontractor',  SUBCONT: 'ShinHwaTech.', TOTAL: 0,       P_WK: 0,      A_WK: 0,      D_WK: 0,    P_CUM: 0,       A_CUM: 140,     D_CUM: 0, },
{ CDATE: '2027-01-29', PHASE: 'Construction',  GROUP: 'Subcontractor',  SUBCONT: 'ShinHwaTech.', TOTAL: 0,       P_WK: 0,      A_WK: 0,      D_WK: 0,    P_CUM: 0,       A_CUM: 140,     D_CUM: 0, },
{ CDATE: '2027-02-26', PHASE: 'Construction',  GROUP: 'Subcontractor',  SUBCONT: 'ShinHwaTech.', TOTAL: 0,       P_WK: 0,      A_WK: 0,      D_WK: 0,    P_CUM: 0,       A_CUM: 140,     D_CUM: 0, },
{ CDATE: '2027-03-26', PHASE: 'Construction',  GROUP: 'Subcontractor',  SUBCONT: 'ShinHwaTech.', TOTAL: 0,       P_WK: 0,      A_WK: 0,      D_WK: 0,    P_CUM: 0,       A_CUM: 140,     D_CUM: 0, },
{ CDATE: '2027-04-30', PHASE: 'Construction',  GROUP: 'Subcontractor',  SUBCONT: 'ShinHwaTech.', TOTAL: 0,       P_WK: 0,      A_WK: 0,      D_WK: 0,    P_CUM: 0,       A_CUM: 140,     D_CUM: 0, },
{ CDATE: '2027-05-28', PHASE: 'Construction',  GROUP: 'Subcontractor',  SUBCONT: 'ShinHwaTech.', TOTAL: 0,       P_WK: 0,      A_WK: 0,      D_WK: 0,    P_CUM: 0,       A_CUM: 140,     D_CUM: 0, },
{ CDATE: '2027-06-11', PHASE: 'Construction',  GROUP: 'Subcontractor',  SUBCONT: 'ShinHwaTech.', TOTAL: 0,       P_WK: 0,      A_WK: 0,      D_WK: 0,    P_CUM: 0,       A_CUM: 140,     D_CUM: 0, },
{ CDATE: '2023-07-28', PHASE: 'Construction',  GROUP: 'Subcontractor',  SUBCONT: 'Sung-II',      TOTAL: 0,       P_WK: 0,      A_WK: 0,      D_WK: 0,    P_CUM: 0,       A_CUM: 0,       D_CUM: 0, },
{ CDATE: '2023-08-25', PHASE: 'Construction',  GROUP: 'Subcontractor',  SUBCONT: 'Sung-II',      TOTAL: 0,       P_WK: 0,      A_WK: 0,      D_WK: 0,    P_CUM: 0,       A_CUM: 0,       D_CUM: 0, },
{ CDATE: '2023-09-29', PHASE: 'Construction',  GROUP: 'Subcontractor',  SUBCONT: 'Sung-II',      TOTAL: 0,       P_WK: 0,      A_WK: 0,      D_WK: 0,    P_CUM: 0,       A_CUM: 0,       D_CUM: 0, },
{ CDATE: '2023-10-27', PHASE: 'Construction',  GROUP: 'Subcontractor',  SUBCONT: 'Sung-II',      TOTAL: 0,       P_WK: 0,      A_WK: 0,      D_WK: 0,    P_CUM: 0,       A_CUM: 0,       D_CUM: 0, },
{ CDATE: '2023-11-24', PHASE: 'Construction',  GROUP: 'Subcontractor',  SUBCONT: 'Sung-II',      TOTAL: 0,       P_WK: 0,      A_WK: 0,      D_WK: 0,    P_CUM: 0,       A_CUM: 0,       D_CUM: 0, },
{ CDATE: '2023-12-29', PHASE: 'Construction',  GROUP: 'Subcontractor',  SUBCONT: 'Sung-II',      TOTAL: 0,       P_WK: 0,      A_WK: 0,      D_WK: 0,    P_CUM: 0,       A_CUM: 0,       D_CUM: 0, },
{ CDATE: '2024-01-26', PHASE: 'Construction',  GROUP: 'Subcontractor',  SUBCONT: 'Sung-II',      TOTAL: 0,       P_WK: 0,      A_WK: 0,      D_WK: 0,    P_CUM: 0,       A_CUM: 0,       D_CUM: 0, },
{ CDATE: '2024-02-23', PHASE: 'Construction',  GROUP: 'Subcontractor',  SUBCONT: 'Sung-II',      TOTAL: 0,       P_WK: 0,      A_WK: 0,      D_WK: 0,    P_CUM: 0,       A_CUM: 0,       D_CUM: 0, },
{ CDATE: '2024-03-29', PHASE: 'Construction',  GROUP: 'Subcontractor',  SUBCONT: 'Sung-II',      TOTAL: 0,       P_WK: 0,      A_WK: 0,      D_WK: 0,    P_CUM: 0,       A_CUM: 0,       D_CUM: 0, },
{ CDATE: '2024-04-26', PHASE: 'Construction',  GROUP: 'Subcontractor',  SUBCONT: 'Sung-II',      TOTAL: 0,       P_WK: 0,      A_WK: 0,      D_WK: 0,    P_CUM: 0,       A_CUM: 0,       D_CUM: 0, },
{ CDATE: '2024-05-31', PHASE: 'Construction',  GROUP: 'Subcontractor',  SUBCONT: 'Sung-II',      TOTAL: 0,       P_WK: 0,      A_WK: 0,      D_WK: 0,    P_CUM: 0,       A_CUM: 0,       D_CUM: 0, },
{ CDATE: '2024-06-28', PHASE: 'Construction',  GROUP: 'Subcontractor',  SUBCONT: 'Sung-II',      TOTAL: 0,       P_WK: 0,      A_WK: 0,      D_WK: 0,    P_CUM: 0,       A_CUM: 0,       D_CUM: 0, },
{ CDATE: '2024-07-26', PHASE: 'Construction',  GROUP: 'Subcontractor',  SUBCONT: 'Sung-II',      TOTAL: 0,       P_WK: 0,      A_WK: 0,      D_WK: 0,    P_CUM: 0,       A_CUM: 0,       D_CUM: 0, },
{ CDATE: '2024-08-30', PHASE: 'Construction',  GROUP: 'Subcontractor',  SUBCONT: 'Sung-II',      TOTAL: 0,       P_WK: 0,      A_WK: 0,      D_WK: 0,    P_CUM: 0,       A_CUM: 0,       D_CUM: 0, },
{ CDATE: '2024-09-27', PHASE: 'Construction',  GROUP: 'Subcontractor',  SUBCONT: 'Sung-II',      TOTAL: 0,       P_WK: 0,      A_WK: 0,      D_WK: 0,    P_CUM: 0,       A_CUM: 0,       D_CUM: 0, },
{ CDATE: '2024-10-25', PHASE: 'Construction',  GROUP: 'Subcontractor',  SUBCONT: 'Sung-II',      TOTAL: 0,       P_WK: 0,      A_WK: 0,      D_WK: 0,    P_CUM: 0,       A_CUM: 0,       D_CUM: 0, },
{ CDATE: '2024-11-29', PHASE: 'Construction',  GROUP: 'Subcontractor',  SUBCONT: 'Sung-II',      TOTAL: 0,       P_WK: 0,      A_WK: 67,     D_WK: 67,   P_CUM: 0,       A_CUM: 67,      D_CUM: 67, },
{ CDATE: '2024-12-27', PHASE: 'Construction',  GROUP: 'Subcontractor',  SUBCONT: 'Sung-II',      TOTAL: 0,       P_WK: 0,      A_WK: 0,      D_WK: 0,    P_CUM: 0,       A_CUM: 67,      D_CUM: 0, },
{ CDATE: '2025-01-31', PHASE: 'Construction',  GROUP: 'Subcontractor',  SUBCONT: 'Sung-II',      TOTAL: 0,       P_WK: 0,      A_WK: 0,      D_WK: 0,    P_CUM: 0,       A_CUM: 67,      D_CUM: 0, },
{ CDATE: '2025-02-28', PHASE: 'Construction',  GROUP: 'Subcontractor',  SUBCONT: 'Sung-II',      TOTAL: 0,       P_WK: 0,      A_WK: 0,      D_WK: 0,    P_CUM: 0,       A_CUM: 67,      D_CUM: 0, },
{ CDATE: '2025-03-28', PHASE: 'Construction',  GROUP: 'Subcontractor',  SUBCONT: 'Sung-II',      TOTAL: 0,       P_WK: 0,      A_WK: 0,      D_WK: 0,    P_CUM: 0,       A_CUM: 67,      D_CUM: 0, },
{ CDATE: '2025-04-25', PHASE: 'Construction',  GROUP: 'Subcontractor',  SUBCONT: 'Sung-II',      TOTAL: 0,       P_WK: 0,      A_WK: 0,      D_WK: 0,    P_CUM: 0,       A_CUM: 67,      D_CUM: 0, },
{ CDATE: '2025-05-30', PHASE: 'Construction',  GROUP: 'Subcontractor',  SUBCONT: 'Sung-II',      TOTAL: 0,       P_WK: 0,      A_WK: 0,      D_WK: 0,    P_CUM: 0,       A_CUM: 67,      D_CUM: 0, },
{ CDATE: '2025-06-27', PHASE: 'Construction',  GROUP: 'Subcontractor',  SUBCONT: 'Sung-II',      TOTAL: 0,       P_WK: 0,      A_WK: 0,      D_WK: 0,    P_CUM: 0,       A_CUM: 67,      D_CUM: 0, },
{ CDATE: '2025-07-25', PHASE: 'Construction',  GROUP: 'Subcontractor',  SUBCONT: 'Sung-II',      TOTAL: 0,       P_WK: 0,      A_WK: 0,      D_WK: 0,    P_CUM: 0,       A_CUM: 67,      D_CUM: 0, },
{ CDATE: '2025-08-29', PHASE: 'Construction',  GROUP: 'Subcontractor',  SUBCONT: 'Sung-II',      TOTAL: 0,       P_WK: 0,      A_WK: 0,      D_WK: 0,    P_CUM: 0,       A_CUM: 67,      D_CUM: 0, },
{ CDATE: '2025-09-26', PHASE: 'Construction',  GROUP: 'Subcontractor',  SUBCONT: 'Sung-II',      TOTAL: 0,       P_WK: 0,      A_WK: 0,      D_WK: 0,    P_CUM: 0,       A_CUM: 67,      D_CUM: 0, },
{ CDATE: '2025-10-31', PHASE: 'Construction',  GROUP: 'Subcontractor',  SUBCONT: 'Sung-II',      TOTAL: 0,       P_WK: 0,      A_WK: 0,      D_WK: 0,    P_CUM: 0,       A_CUM: 67,      D_CUM: 0, },
{ CDATE: '2025-11-28', PHASE: 'Construction',  GROUP: 'Subcontractor',  SUBCONT: 'Sung-II',      TOTAL: 0,       P_WK: 0,      A_WK: 0,      D_WK: 0,    P_CUM: 0,       A_CUM: 67,      D_CUM: 0, },
{ CDATE: '2025-12-26', PHASE: 'Construction',  GROUP: 'Subcontractor',  SUBCONT: 'Sung-II',      TOTAL: 0,       P_WK: 0,      A_WK: 0,      D_WK: 0,    P_CUM: 0,       A_CUM: 67,      D_CUM: 0, },
{ CDATE: '2026-01-30', PHASE: 'Construction',  GROUP: 'Subcontractor',  SUBCONT: 'Sung-II',      TOTAL: 0,       P_WK: 0,      A_WK: 0,      D_WK: 0,    P_CUM: 0,       A_CUM: 67,      D_CUM: 0, },
{ CDATE: '2026-02-27', PHASE: 'Construction',  GROUP: 'Subcontractor',  SUBCONT: 'Sung-II',      TOTAL: 0,       P_WK: 0,      A_WK: 0,      D_WK: 0,    P_CUM: 0,       A_CUM: 67,      D_CUM: 0, },
{ CDATE: '2026-03-27', PHASE: 'Construction',  GROUP: 'Subcontractor',  SUBCONT: 'Sung-II',      TOTAL: 0,       P_WK: 0,      A_WK: 0,      D_WK: 0,    P_CUM: 0,       A_CUM: 67,      D_CUM: 0, },
{ CDATE: '2026-04-24', PHASE: 'Construction',  GROUP: 'Subcontractor',  SUBCONT: 'Sung-II',      TOTAL: 0,       P_WK: 0,      A_WK: 0,      D_WK: 0,    P_CUM: 0,       A_CUM: 67,      D_CUM: 0, },
{ CDATE: '2026-05-29', PHASE: 'Construction',  GROUP: 'Subcontractor',  SUBCONT: 'Sung-II',      TOTAL: 0,       P_WK: 0,      A_WK: 0,      D_WK: 0,    P_CUM: 0,       A_CUM: 67,      D_CUM: 0, },
{ CDATE: '2026-06-26', PHASE: 'Construction',  GROUP: 'Subcontractor',  SUBCONT: 'Sung-II',      TOTAL: 0,       P_WK: 0,      A_WK: 0,      D_WK: 0,    P_CUM: 0,       A_CUM: 67,      D_CUM: 0, },
{ CDATE: '2026-07-31', PHASE: 'Construction',  GROUP: 'Subcontractor',  SUBCONT: 'Sung-II',      TOTAL: 0,       P_WK: 0,      A_WK: 0,      D_WK: 0,    P_CUM: 0,       A_CUM: 67,      D_CUM: 0, },
{ CDATE: '2026-08-28', PHASE: 'Construction',  GROUP: 'Subcontractor',  SUBCONT: 'Sung-II',      TOTAL: 0,       P_WK: 0,      A_WK: 0,      D_WK: 0,    P_CUM: 0,       A_CUM: 67,      D_CUM: 0, },
{ CDATE: '2026-09-25', PHASE: 'Construction',  GROUP: 'Subcontractor',  SUBCONT: 'Sung-II',      TOTAL: 0,       P_WK: 0,      A_WK: 0,      D_WK: 0,    P_CUM: 0,       A_CUM: 67,      D_CUM: 0, },
{ CDATE: '2026-10-30', PHASE: 'Construction',  GROUP: 'Subcontractor',  SUBCONT: 'Sung-II',      TOTAL: 0,       P_WK: 0,      A_WK: 0,      D_WK: 0,    P_CUM: 0,       A_CUM: 67,      D_CUM: 0, },
{ CDATE: '2026-11-27', PHASE: 'Construction',  GROUP: 'Subcontractor',  SUBCONT: 'Sung-II',      TOTAL: 0,       P_WK: 0,      A_WK: 0,      D_WK: 0,    P_CUM: 0,       A_CUM: 67,      D_CUM: 0, },
{ CDATE: '2026-12-25', PHASE: 'Construction',  GROUP: 'Subcontractor',  SUBCONT: 'Sung-II',      TOTAL: 0,       P_WK: 0,      A_WK: 0,      D_WK: 0,    P_CUM: 0,       A_CUM: 67,      D_CUM: 0, },
{ CDATE: '2027-01-29', PHASE: 'Construction',  GROUP: 'Subcontractor',  SUBCONT: 'Sung-II',      TOTAL: 0,       P_WK: 0,      A_WK: 0,      D_WK: 0,    P_CUM: 0,       A_CUM: 67,      D_CUM: 0, },
{ CDATE: '2027-02-26', PHASE: 'Construction',  GROUP: 'Subcontractor',  SUBCONT: 'Sung-II',      TOTAL: 0,       P_WK: 0,      A_WK: 0,      D_WK: 0,    P_CUM: 0,       A_CUM: 67,      D_CUM: 0, },
{ CDATE: '2027-03-26', PHASE: 'Construction',  GROUP: 'Subcontractor',  SUBCONT: 'Sung-II',      TOTAL: 0,       P_WK: 0,      A_WK: 0,      D_WK: 0,    P_CUM: 0,       A_CUM: 67,      D_CUM: 0, },
{ CDATE: '2027-04-30', PHASE: 'Construction',  GROUP: 'Subcontractor',  SUBCONT: 'Sung-II',      TOTAL: 0,       P_WK: 0,      A_WK: 0,      D_WK: 0,    P_CUM: 0,       A_CUM: 67,      D_CUM: 0, },
{ CDATE: '2027-05-28', PHASE: 'Construction',  GROUP: 'Subcontractor',  SUBCONT: 'Sung-II',      TOTAL: 0,       P_WK: 0,      A_WK: 0,      D_WK: 0,    P_CUM: 0,       A_CUM: 67,      D_CUM: 0, },
{ CDATE: '2027-06-11', PHASE: 'Construction',  GROUP: 'Subcontractor',  SUBCONT: 'Sung-II',      TOTAL: 0,       P_WK: 0,      A_WK: 0,      D_WK: 0,    P_CUM: 0,       A_CUM: 67,      D_CUM: 0, },
{ CDATE: '2023-07-28', PHASE: 'Construction',  GROUP: 'OVERALL',        SUBCONT: 'OVERALL',      TOTAL: 98023.1, P_WK: 0,      A_WK: 0,      D_WK: 0,    P_CUM: 0,       A_CUM: 0,       D_CUM: 0, },
{ CDATE: '2023-08-25', PHASE: 'Construction',  GROUP: 'OVERALL',        SUBCONT: 'OVERALL',      TOTAL: 98023.1, P_WK: 0,      A_WK: 0,      D_WK: 0,    P_CUM: 0,       A_CUM: 0,       D_CUM: 0, },
{ CDATE: '2023-09-29', PHASE: 'Construction',  GROUP: 'OVERALL',        SUBCONT: 'OVERALL',      TOTAL: 98023.1, P_WK: 0,      A_WK: 0,      D_WK: 0,    P_CUM: 0,       A_CUM: 0,       D_CUM: 0, },
{ CDATE: '2023-10-27', PHASE: 'Construction',  GROUP: 'OVERALL',        SUBCONT: 'OVERALL',      TOTAL: 98023.1, P_WK: 0,      A_WK: 0,      D_WK: 0,    P_CUM: 0,       A_CUM: 0,       D_CUM: 0, },
{ CDATE: '2023-11-24', PHASE: 'Construction',  GROUP: 'OVERALL',        SUBCONT: 'OVERALL',      TOTAL: 98023.1, P_WK: 0,      A_WK: 0,      D_WK: 0,    P_CUM: 0,       A_CUM: 0,       D_CUM: 0, },
{ CDATE: '2023-12-29', PHASE: 'Construction',  GROUP: 'OVERALL',        SUBCONT: 'OVERALL',      TOTAL: 98023.1, P_WK: 0,      A_WK: 0,      D_WK: 0,    P_CUM: 0,       A_CUM: 0,       D_CUM: 0, },
{ CDATE: '2024-01-26', PHASE: 'Construction',  GROUP: 'OVERALL',        SUBCONT: 'OVERALL',      TOTAL: 98023.1, P_WK: 0,      A_WK: 0,      D_WK: 0,    P_CUM: 0,       A_CUM: 0,       D_CUM: 0, },
{ CDATE: '2024-02-23', PHASE: 'Construction',  GROUP: 'OVERALL',        SUBCONT: 'OVERALL',      TOTAL: 98023.1, P_WK: 0,      A_WK: 0,      D_WK: 0,    P_CUM: 0,       A_CUM: 0,       D_CUM: 0, },
{ CDATE: '2024-03-29', PHASE: 'Construction',  GROUP: 'OVERALL',        SUBCONT: 'OVERALL',      TOTAL: 98023.1, P_WK: 0,      A_WK: 0,      D_WK: 0,    P_CUM: 0,       A_CUM: 0,       D_CUM: 0, },
{ CDATE: '2024-04-26', PHASE: 'Construction',  GROUP: 'OVERALL',        SUBCONT: 'OVERALL',      TOTAL: 98023.1, P_WK: 0,      A_WK: 0,      D_WK: 0,    P_CUM: 0,       A_CUM: 0,       D_CUM: 0, },
{ CDATE: '2024-05-31', PHASE: 'Construction',  GROUP: 'OVERALL',        SUBCONT: 'OVERALL',      TOTAL: 98023.1, P_WK: 0,      A_WK: 0,      D_WK: 0,    P_CUM: 0,       A_CUM: 0,       D_CUM: 0, },
{ CDATE: '2024-06-28', PHASE: 'Construction',  GROUP: 'OVERALL',        SUBCONT: 'OVERALL',      TOTAL: 98023.1, P_WK: 0,      A_WK: 0,      D_WK: 0,    P_CUM: 0,       A_CUM: 0,       D_CUM: 0, },
{ CDATE: '2024-07-26', PHASE: 'Construction',  GROUP: 'OVERALL',        SUBCONT: 'OVERALL',      TOTAL: 98023.1, P_WK: 0,      A_WK: 0,      D_WK: 0,    P_CUM: 0,       A_CUM: 0,       D_CUM: 0, },
{ CDATE: '2024-08-30', PHASE: 'Construction',  GROUP: 'OVERALL',        SUBCONT: 'OVERALL',      TOTAL: 98023.1, P_WK: 0,      A_WK: 0,      D_WK: 0,    P_CUM: 0,       A_CUM: 0,       D_CUM: 0, },
{ CDATE: '2024-09-27', PHASE: 'Construction',  GROUP: 'OVERALL',        SUBCONT: 'OVERALL',      TOTAL: 98023.1, P_WK: 0,      A_WK: 0,      D_WK: 0,    P_CUM: 0,       A_CUM: 0,       D_CUM: 0, },
{ CDATE: '2024-10-25', PHASE: 'Construction',  GROUP: 'OVERALL',        SUBCONT: 'OVERALL',      TOTAL: 98023.1, P_WK: 0,      A_WK: 0,      D_WK: 0,    P_CUM: 0,       A_CUM: 0,       D_CUM: 0, },
{ CDATE: '2024-11-29', PHASE: 'Construction',  GROUP: 'OVERALL',        SUBCONT: 'OVERALL',      TOTAL: 98023.1, P_WK: 305.1,  A_WK: 565.6,  D_WK: 261,  P_CUM: 305.1,   A_CUM: 565.6,   D_CUM: 261, },
{ CDATE: '2024-12-27', PHASE: 'Construction',  GROUP: 'OVERALL',        SUBCONT: 'OVERALL',      TOTAL: 98023.1, P_WK: 1384.5, A_WK: 0,      D_WK: 0,    P_CUM: 1689.6,  A_CUM: 565.6,   D_CUM: 0, },
{ CDATE: '2025-01-31', PHASE: 'Construction',  GROUP: 'OVERALL',        SUBCONT: 'OVERALL',      TOTAL: 98023.1, P_WK: 3697.4, A_WK: 0,      D_WK: 0,    P_CUM: 5387,    A_CUM: 565.6,   D_CUM: 0, },
{ CDATE: '2025-02-28', PHASE: 'Construction',  GROUP: 'OVERALL',        SUBCONT: 'OVERALL',      TOTAL: 98023.1, P_WK: 4169.1, A_WK: 0,      D_WK: 0,    P_CUM: 9556.1,  A_CUM: 565.6,   D_CUM: 0, },
{ CDATE: '2025-03-28', PHASE: 'Construction',  GROUP: 'OVERALL',        SUBCONT: 'OVERALL',      TOTAL: 98023.1, P_WK: 4449.9, A_WK: 0,      D_WK: 0,    P_CUM: 14006,   A_CUM: 565.6,   D_CUM: 0, },
{ CDATE: '2025-04-25', PHASE: 'Construction',  GROUP: 'OVERALL',        SUBCONT: 'OVERALL',      TOTAL: 98023.1, P_WK: 6201.4, A_WK: 0,      D_WK: 0,    P_CUM: 20207.4, A_CUM: 565.6,   D_CUM: 0, },
{ CDATE: '2025-05-30', PHASE: 'Construction',  GROUP: 'OVERALL',        SUBCONT: 'OVERALL',      TOTAL: 98023.1, P_WK: 9054.8, A_WK: 0,      D_WK: 0,    P_CUM: 29262.2, A_CUM: 565.6,   D_CUM: 0, },
{ CDATE: '2025-06-27', PHASE: 'Construction',  GROUP: 'OVERALL',        SUBCONT: 'OVERALL',      TOTAL: 98023.1, P_WK: 7538.9, A_WK: 0,      D_WK: 0,    P_CUM: 36801.1, A_CUM: 565.6,   D_CUM: 0, },
{ CDATE: '2025-07-25', PHASE: 'Construction',  GROUP: 'OVERALL',        SUBCONT: 'OVERALL',      TOTAL: 98023.1, P_WK: 8469.5, A_WK: 0,      D_WK: 0,    P_CUM: 45270.6, A_CUM: 565.6,   D_CUM: 0, },
{ CDATE: '2025-08-29', PHASE: 'Construction',  GROUP: 'OVERALL',        SUBCONT: 'OVERALL',      TOTAL: 98023.1, P_WK: 7201.3, A_WK: 0,      D_WK: 0,    P_CUM: 52471.9, A_CUM: 565.6,   D_CUM: 0, },
{ CDATE: '2025-09-26', PHASE: 'Construction',  GROUP: 'OVERALL',        SUBCONT: 'OVERALL',      TOTAL: 98023.1, P_WK: 6450.2, A_WK: 0,      D_WK: 0,    P_CUM: 58922.1, A_CUM: 565.6,   D_CUM: 0, },
{ CDATE: '2025-10-31', PHASE: 'Construction',  GROUP: 'OVERALL',        SUBCONT: 'OVERALL',      TOTAL: 98023.1, P_WK: 4626.2, A_WK: 0,      D_WK: 0,    P_CUM: 63548.3, A_CUM: 565.6,   D_CUM: 0, },
{ CDATE: '2025-11-28', PHASE: 'Construction',  GROUP: 'OVERALL',        SUBCONT: 'OVERALL',      TOTAL: 98023.1, P_WK: 5158,   A_WK: 0,      D_WK: 0,    P_CUM: 68706.3, A_CUM: 565.6,   D_CUM: 0, },
{ CDATE: '2025-12-26', PHASE: 'Construction',  GROUP: 'OVERALL',        SUBCONT: 'OVERALL',      TOTAL: 98023.1, P_WK: 3922.7, A_WK: 0,      D_WK: 0,    P_CUM: 72629,   A_CUM: 565.6,   D_CUM: 0, },
{ CDATE: '2026-01-30', PHASE: 'Construction',  GROUP: 'OVERALL',        SUBCONT: 'OVERALL',      TOTAL: 98023.1, P_WK: 4638.8, A_WK: 0,      D_WK: 0,    P_CUM: 77267.8, A_CUM: 565.6,   D_CUM: 0, },
{ CDATE: '2026-02-27', PHASE: 'Construction',  GROUP: 'OVERALL',        SUBCONT: 'OVERALL',      TOTAL: 98023.1, P_WK: 2224.3, A_WK: 0,      D_WK: 0,    P_CUM: 79492.1, A_CUM: 565.6,   D_CUM: 0, },
{ CDATE: '2026-03-27', PHASE: 'Construction',  GROUP: 'OVERALL',        SUBCONT: 'OVERALL',      TOTAL: 98023.1, P_WK: 1475,   A_WK: 0,      D_WK: 0,    P_CUM: 80967.1, A_CUM: 565.6,   D_CUM: 0, },
{ CDATE: '2026-04-24', PHASE: 'Construction',  GROUP: 'OVERALL',        SUBCONT: 'OVERALL',      TOTAL: 98023.1, P_WK: 953.2,  A_WK: 0,      D_WK: 0,    P_CUM: 81920.3, A_CUM: 565.6,   D_CUM: 0, },
{ CDATE: '2026-05-29', PHASE: 'Construction',  GROUP: 'OVERALL',        SUBCONT: 'OVERALL',      TOTAL: 98023.1, P_WK: 1278.7, A_WK: 0,      D_WK: 0,    P_CUM: 83199,   A_CUM: 565.6,   D_CUM: 0, },
{ CDATE: '2026-06-26', PHASE: 'Construction',  GROUP: 'OVERALL',        SUBCONT: 'OVERALL',      TOTAL: 98023.1, P_WK: 1866.3, A_WK: 0,      D_WK: 0,    P_CUM: 85065.3, A_CUM: 565.6,   D_CUM: 0, },
{ CDATE: '2026-07-31', PHASE: 'Construction',  GROUP: 'OVERALL',        SUBCONT: 'OVERALL',      TOTAL: 98023.1, P_WK: 2533.3, A_WK: 0,      D_WK: 0,    P_CUM: 87598.6, A_CUM: 565.6,   D_CUM: 0, },
{ CDATE: '2026-08-28', PHASE: 'Construction',  GROUP: 'OVERALL',        SUBCONT: 'OVERALL',      TOTAL: 98023.1, P_WK: 2339.6, A_WK: 0,      D_WK: 0,    P_CUM: 89938.2, A_CUM: 565.6,   D_CUM: 0, },
{ CDATE: '2026-09-25', PHASE: 'Construction',  GROUP: 'OVERALL',        SUBCONT: 'OVERALL',      TOTAL: 98023.1, P_WK: 2373.7, A_WK: 0,      D_WK: 0,    P_CUM: 92311.9, A_CUM: 565.6,   D_CUM: 0, },
{ CDATE: '2026-10-30', PHASE: 'Construction',  GROUP: 'OVERALL',        SUBCONT: 'OVERALL',      TOTAL: 98023.1, P_WK: 2264.6, A_WK: 0,      D_WK: 0,    P_CUM: 94576.5, A_CUM: 565.6,   D_CUM: 0, },
{ CDATE: '2026-11-27', PHASE: 'Construction',  GROUP: 'OVERALL',        SUBCONT: 'OVERALL',      TOTAL: 98023.1, P_WK: 1423.3, A_WK: 0,      D_WK: 0,    P_CUM: 95999.8, A_CUM: 565.6,   D_CUM: 0, },
{ CDATE: '2026-12-25', PHASE: 'Construction',  GROUP: 'OVERALL',        SUBCONT: 'OVERALL',      TOTAL: 98023.1, P_WK: 951,    A_WK: 0,      D_WK: 0,    P_CUM: 96950.8, A_CUM: 565.6,   D_CUM: 0, },
{ CDATE: '2027-01-29', PHASE: 'Construction',  GROUP: 'OVERALL',        SUBCONT: 'OVERALL',      TOTAL: 98023.1, P_WK: 315.4,  A_WK: 0,      D_WK: 0,    P_CUM: 97266.2, A_CUM: 565.6,   D_CUM: 0, },
{ CDATE: '2027-02-26', PHASE: 'Construction',  GROUP: 'OVERALL',        SUBCONT: 'OVERALL',      TOTAL: 98023.1, P_WK: 408.2,  A_WK: 0,      D_WK: 0,    P_CUM: 97674.4, A_CUM: 565.6,   D_CUM: 0, },
{ CDATE: '2027-03-26', PHASE: 'Construction',  GROUP: 'OVERALL',        SUBCONT: 'OVERALL',      TOTAL: 98023.1, P_WK: 247.4,  A_WK: 0,      D_WK: 0,    P_CUM: 97921.8, A_CUM: 565.6,   D_CUM: 0, },
{ CDATE: '2027-04-30', PHASE: 'Construction',  GROUP: 'OVERALL',        SUBCONT: 'OVERALL',      TOTAL: 98023.1, P_WK: 101.4,  A_WK: 0,      D_WK: 0,    P_CUM: 98023.2, A_CUM: 565.6,   D_CUM: 0, },
{ CDATE: '2027-05-28', PHASE: 'Construction',  GROUP: 'OVERALL',        SUBCONT: 'OVERALL',      TOTAL: 98023.1, P_WK: 0,      A_WK: 0,      D_WK: 0,    P_CUM: 98023.2, A_CUM: 565.6,   D_CUM: 0, },
{ CDATE: '2027-06-11', PHASE: 'Construction',  GROUP: 'OVERALL',        SUBCONT: 'OVERALL',      TOTAL: 98023.1, P_WK: 0,      A_WK: 0,      D_WK: 0,    P_CUM: 98023.2, A_CUM: 565.6,   D_CUM: 0, },
{ CDATE: '2023-07-28', PHASE: 'Commissioning', GROUP: 'Commissioning',  SUBCONT: 'HHI',          TOTAL: 3199.9,  P_WK: 0,      A_WK: 0,      D_WK: 0,    P_CUM: 0,       A_CUM: 0,       D_CUM: 0, },
{ CDATE: '2023-08-25', PHASE: 'Commissioning', GROUP: 'Commissioning',  SUBCONT: 'HHI',          TOTAL: 3199.9,  P_WK: 0,      A_WK: 0,      D_WK: 0,    P_CUM: 0,       A_CUM: 0,       D_CUM: 0, },
{ CDATE: '2023-09-29', PHASE: 'Commissioning', GROUP: 'Commissioning',  SUBCONT: 'HHI',          TOTAL: 3199.9,  P_WK: 0,      A_WK: 0,      D_WK: 0,    P_CUM: 0,       A_CUM: 0,       D_CUM: 0, },
{ CDATE: '2023-10-27', PHASE: 'Commissioning', GROUP: 'Commissioning',  SUBCONT: 'HHI',          TOTAL: 3199.9,  P_WK: 0,      A_WK: 0,      D_WK: 0,    P_CUM: 0,       A_CUM: 0,       D_CUM: 0, },
{ CDATE: '2023-11-24', PHASE: 'Commissioning', GROUP: 'Commissioning',  SUBCONT: 'HHI',          TOTAL: 3199.9,  P_WK: 0,      A_WK: 0,      D_WK: 0,    P_CUM: 0,       A_CUM: 0,       D_CUM: 0, },
{ CDATE: '2023-12-29', PHASE: 'Commissioning', GROUP: 'Commissioning',  SUBCONT: 'HHI',          TOTAL: 3199.9,  P_WK: 0,      A_WK: 0,      D_WK: 0,    P_CUM: 0,       A_CUM: 0,       D_CUM: 0, },
{ CDATE: '2024-01-26', PHASE: 'Commissioning', GROUP: 'Commissioning',  SUBCONT: 'HHI',          TOTAL: 3199.9,  P_WK: 0,      A_WK: 0,      D_WK: 0,    P_CUM: 0,       A_CUM: 0,       D_CUM: 0, },
{ CDATE: '2024-02-23', PHASE: 'Commissioning', GROUP: 'Commissioning',  SUBCONT: 'HHI',          TOTAL: 3199.9,  P_WK: 0,      A_WK: 0,      D_WK: 0,    P_CUM: 0,       A_CUM: 0,       D_CUM: 0, },
{ CDATE: '2024-03-29', PHASE: 'Commissioning', GROUP: 'Commissioning',  SUBCONT: 'HHI',          TOTAL: 3199.9,  P_WK: 0,      A_WK: 0,      D_WK: 0,    P_CUM: 0,       A_CUM: 0,       D_CUM: 0, },
{ CDATE: '2024-04-26', PHASE: 'Commissioning', GROUP: 'Commissioning',  SUBCONT: 'HHI',          TOTAL: 3199.9,  P_WK: 0,      A_WK: 0,      D_WK: 0,    P_CUM: 0,       A_CUM: 0,       D_CUM: 0, },
{ CDATE: '2024-05-31', PHASE: 'Commissioning', GROUP: 'Commissioning',  SUBCONT: 'HHI',          TOTAL: 3199.9,  P_WK: 0,      A_WK: 0,      D_WK: 0,    P_CUM: 0,       A_CUM: 0,       D_CUM: 0, },
{ CDATE: '2024-06-28', PHASE: 'Commissioning', GROUP: 'Commissioning',  SUBCONT: 'HHI',          TOTAL: 3199.9,  P_WK: 0,      A_WK: 0,      D_WK: 0,    P_CUM: 0,       A_CUM: 0,       D_CUM: 0, },
{ CDATE: '2024-07-26', PHASE: 'Commissioning', GROUP: 'Commissioning',  SUBCONT: 'HHI',          TOTAL: 3199.9,  P_WK: 0,      A_WK: 0,      D_WK: 0,    P_CUM: 0,       A_CUM: 0,       D_CUM: 0, },
{ CDATE: '2024-08-30', PHASE: 'Commissioning', GROUP: 'Commissioning',  SUBCONT: 'HHI',          TOTAL: 3199.9,  P_WK: 0,      A_WK: 0,      D_WK: 0,    P_CUM: 0,       A_CUM: 0,       D_CUM: 0, },
{ CDATE: '2024-09-27', PHASE: 'Commissioning', GROUP: 'Commissioning',  SUBCONT: 'HHI',          TOTAL: 3199.9,  P_WK: 0,      A_WK: 0,      D_WK: 0,    P_CUM: 0,       A_CUM: 0,       D_CUM: 0, },
{ CDATE: '2024-10-25', PHASE: 'Commissioning', GROUP: 'Commissioning',  SUBCONT: 'HHI',          TOTAL: 3199.9,  P_WK: 0,      A_WK: 0,      D_WK: 0,    P_CUM: 0,       A_CUM: 0,       D_CUM: 0, },
{ CDATE: '2024-11-29', PHASE: 'Commissioning', GROUP: 'Commissioning',  SUBCONT: 'HHI',          TOTAL: 3199.9,  P_WK: 0,      A_WK: 0,      D_WK: 0,    P_CUM: 0,       A_CUM: 0,       D_CUM: 0, },
{ CDATE: '2024-12-27', PHASE: 'Commissioning', GROUP: 'Commissioning',  SUBCONT: 'HHI',          TOTAL: 3199.9,  P_WK: 0,      A_WK: 0,      D_WK: 0,    P_CUM: 0,       A_CUM: 0,       D_CUM: 0, },
{ CDATE: '2025-01-31', PHASE: 'Commissioning', GROUP: 'Commissioning',  SUBCONT: 'HHI',          TOTAL: 3199.9,  P_WK: 0,      A_WK: 0,      D_WK: 0,    P_CUM: 0,       A_CUM: 0,       D_CUM: 0, },
{ CDATE: '2025-02-28', PHASE: 'Commissioning', GROUP: 'Commissioning',  SUBCONT: 'HHI',          TOTAL: 3199.9,  P_WK: 0,      A_WK: 0,      D_WK: 0,    P_CUM: 0,       A_CUM: 0,       D_CUM: 0, },
{ CDATE: '2025-03-28', PHASE: 'Commissioning', GROUP: 'Commissioning',  SUBCONT: 'HHI',          TOTAL: 3199.9,  P_WK: 0,      A_WK: 0,      D_WK: 0,    P_CUM: 0,       A_CUM: 0,       D_CUM: 0, },
{ CDATE: '2025-04-25', PHASE: 'Commissioning', GROUP: 'Commissioning',  SUBCONT: 'HHI',          TOTAL: 3199.9,  P_WK: 0,      A_WK: 0,      D_WK: 0,    P_CUM: 0,       A_CUM: 0,       D_CUM: 0, },
{ CDATE: '2025-05-30', PHASE: 'Commissioning', GROUP: 'Commissioning',  SUBCONT: 'HHI',          TOTAL: 3199.9,  P_WK: 0,      A_WK: 0,      D_WK: 0,    P_CUM: 0,       A_CUM: 0,       D_CUM: 0, },
{ CDATE: '2025-06-27', PHASE: 'Commissioning', GROUP: 'Commissioning',  SUBCONT: 'HHI',          TOTAL: 3199.9,  P_WK: 0,      A_WK: 0,      D_WK: 0,    P_CUM: 0,       A_CUM: 0,       D_CUM: 0, },
{ CDATE: '2025-07-25', PHASE: 'Commissioning', GROUP: 'Commissioning',  SUBCONT: 'HHI',          TOTAL: 3199.9,  P_WK: 0,      A_WK: 0,      D_WK: 0,    P_CUM: 0,       A_CUM: 0,       D_CUM: 0, },
{ CDATE: '2025-08-29', PHASE: 'Commissioning', GROUP: 'Commissioning',  SUBCONT: 'HHI',          TOTAL: 3199.9,  P_WK: 0,      A_WK: 0,      D_WK: 0,    P_CUM: 0,       A_CUM: 0,       D_CUM: 0, },
{ CDATE: '2025-09-26', PHASE: 'Commissioning', GROUP: 'Commissioning',  SUBCONT: 'HHI',          TOTAL: 3199.9,  P_WK: 0,      A_WK: 0,      D_WK: 0,    P_CUM: 0,       A_CUM: 0,       D_CUM: 0, },
{ CDATE: '2025-10-31', PHASE: 'Commissioning', GROUP: 'Commissioning',  SUBCONT: 'HHI',          TOTAL: 3199.9,  P_WK: 0,      A_WK: 0,      D_WK: 0,    P_CUM: 0,       A_CUM: 0,       D_CUM: 0, },
{ CDATE: '2025-11-28', PHASE: 'Commissioning', GROUP: 'Commissioning',  SUBCONT: 'HHI',          TOTAL: 3199.9,  P_WK: 0,      A_WK: 0,      D_WK: 0,    P_CUM: 0,       A_CUM: 0,       D_CUM: 0, },
{ CDATE: '2025-12-26', PHASE: 'Commissioning', GROUP: 'Commissioning',  SUBCONT: 'HHI',          TOTAL: 3199.9,  P_WK: 0,      A_WK: 0,      D_WK: 0,    P_CUM: 0,       A_CUM: 0,       D_CUM: 0, },
{ CDATE: '2026-01-30', PHASE: 'Commissioning', GROUP: 'Commissioning',  SUBCONT: 'HHI',          TOTAL: 3199.9,  P_WK: 0,      A_WK: 0,      D_WK: 0,    P_CUM: 0,       A_CUM: 0,       D_CUM: 0, },
{ CDATE: '2026-02-27', PHASE: 'Commissioning', GROUP: 'Commissioning',  SUBCONT: 'HHI',          TOTAL: 3199.9,  P_WK: 33.6,   A_WK: 0,      D_WK: 0,    P_CUM: 33.6,    A_CUM: 0,       D_CUM: 0, },
{ CDATE: '2026-03-27', PHASE: 'Commissioning', GROUP: 'Commissioning',  SUBCONT: 'HHI',          TOTAL: 3199.9,  P_WK: 157.9,  A_WK: 0,      D_WK: 0,    P_CUM: 191.5,   A_CUM: 0,       D_CUM: 0, },
{ CDATE: '2026-04-24', PHASE: 'Commissioning', GROUP: 'Commissioning',  SUBCONT: 'HHI',          TOTAL: 3199.9,  P_WK: 254.5,  A_WK: 0,      D_WK: 0,    P_CUM: 446,     A_CUM: 0,       D_CUM: 0, },
{ CDATE: '2026-05-29', PHASE: 'Commissioning', GROUP: 'Commissioning',  SUBCONT: 'HHI',          TOTAL: 3199.9,  P_WK: 55.2,   A_WK: 0,      D_WK: 0,    P_CUM: 501.2,   A_CUM: 0,       D_CUM: 0, },
{ CDATE: '2026-06-26', PHASE: 'Commissioning', GROUP: 'Commissioning',  SUBCONT: 'HHI',          TOTAL: 3199.9,  P_WK: 127.8,  A_WK: 0,      D_WK: 0,    P_CUM: 629,     A_CUM: 0,       D_CUM: 0, },
{ CDATE: '2026-07-31', PHASE: 'Commissioning', GROUP: 'Commissioning',  SUBCONT: 'HHI',          TOTAL: 3199.9,  P_WK: 306.4,  A_WK: 0,      D_WK: 0,    P_CUM: 935.4,   A_CUM: 0,       D_CUM: 0, },
{ CDATE: '2026-08-28', PHASE: 'Commissioning', GROUP: 'Commissioning',  SUBCONT: 'HHI',          TOTAL: 3199.9,  P_WK: 359.6,  A_WK: 0,      D_WK: 0,    P_CUM: 1295,    A_CUM: 0,       D_CUM: 0, },
{ CDATE: '2026-09-25', PHASE: 'Commissioning', GROUP: 'Commissioning',  SUBCONT: 'HHI',          TOTAL: 3199.9,  P_WK: 380.5,  A_WK: 0,      D_WK: 0,    P_CUM: 1675.5,  A_CUM: 0,       D_CUM: 0, },
{ CDATE: '2026-10-30', PHASE: 'Commissioning', GROUP: 'Commissioning',  SUBCONT: 'HHI',          TOTAL: 3199.9,  P_WK: 454.8,  A_WK: 0,      D_WK: 0,    P_CUM: 2130.3,  A_CUM: 0,       D_CUM: 0, },
{ CDATE: '2026-11-27', PHASE: 'Commissioning', GROUP: 'Commissioning',  SUBCONT: 'HHI',          TOTAL: 3199.9,  P_WK: 233.1,  A_WK: 0,      D_WK: 0,    P_CUM: 2363.4,  A_CUM: 0,       D_CUM: 0, },
{ CDATE: '2026-12-25', PHASE: 'Commissioning', GROUP: 'Commissioning',  SUBCONT: 'HHI',          TOTAL: 3199.9,  P_WK: 418.8,  A_WK: 0,      D_WK: 0,    P_CUM: 2782.2,  A_CUM: 0,       D_CUM: 0, },
{ CDATE: '2027-01-29', PHASE: 'Commissioning', GROUP: 'Commissioning',  SUBCONT: 'HHI',          TOTAL: 3199.9,  P_WK: 297.9,  A_WK: 0,      D_WK: 0,    P_CUM: 3080.1,  A_CUM: 0,       D_CUM: 0, },
{ CDATE: '2027-02-26', PHASE: 'Commissioning', GROUP: 'Commissioning',  SUBCONT: 'HHI',          TOTAL: 3199.9,  P_WK: 45.6,   A_WK: 0,      D_WK: 0,    P_CUM: 3125.7,  A_CUM: 0,       D_CUM: 0, },
{ CDATE: '2027-03-26', PHASE: 'Commissioning', GROUP: 'Commissioning',  SUBCONT: 'HHI',          TOTAL: 3199.9,  P_WK: 74.3,   A_WK: 0,      D_WK: 0,    P_CUM: 3200,    A_CUM: 0,       D_CUM: 0, },
{ CDATE: '2027-04-30', PHASE: 'Commissioning', GROUP: 'Commissioning',  SUBCONT: 'HHI',          TOTAL: 3199.9,  P_WK: 0,      A_WK: 0,      D_WK: 0,    P_CUM: 3200,    A_CUM: 0,       D_CUM: 0, },
{ CDATE: '2027-05-28', PHASE: 'Commissioning', GROUP: 'Commissioning',  SUBCONT: 'HHI',          TOTAL: 3199.9,  P_WK: 0,      A_WK: 0,      D_WK: 0,    P_CUM: 3200,    A_CUM: 0,       D_CUM: 0, },
{ CDATE: '2027-06-11', PHASE: 'Commissioning', GROUP: 'Commissioning',  SUBCONT: 'HHI',          TOTAL: 3199.9,  P_WK: 0,      A_WK: 0,      D_WK: 0,    P_CUM: 3200,    A_CUM: 0,       D_CUM: 0, },
{ CDATE: '2023-07-28', PHASE: 'Commissioning', GROUP: 'Commissioning',  SUBCONT: 'OVERALL',      TOTAL: 3199.9,  P_WK: 0,      A_WK: 0,      D_WK: 0,    P_CUM: 0,       A_CUM: 0,       D_CUM: 0, },
{ CDATE: '2023-08-25', PHASE: 'Commissioning', GROUP: 'Commissioning',  SUBCONT: 'OVERALL',      TOTAL: 3199.9,  P_WK: 0,      A_WK: 0,      D_WK: 0,    P_CUM: 0,       A_CUM: 0,       D_CUM: 0, },
{ CDATE: '2023-09-29', PHASE: 'Commissioning', GROUP: 'Commissioning',  SUBCONT: 'OVERALL',      TOTAL: 3199.9,  P_WK: 0,      A_WK: 0,      D_WK: 0,    P_CUM: 0,       A_CUM: 0,       D_CUM: 0, },
{ CDATE: '2023-10-27', PHASE: 'Commissioning', GROUP: 'Commissioning',  SUBCONT: 'OVERALL',      TOTAL: 3199.9,  P_WK: 0,      A_WK: 0,      D_WK: 0,    P_CUM: 0,       A_CUM: 0,       D_CUM: 0, },
{ CDATE: '2023-11-24', PHASE: 'Commissioning', GROUP: 'Commissioning',  SUBCONT: 'OVERALL',      TOTAL: 3199.9,  P_WK: 0,      A_WK: 0,      D_WK: 0,    P_CUM: 0,       A_CUM: 0,       D_CUM: 0, },
{ CDATE: '2023-12-29', PHASE: 'Commissioning', GROUP: 'Commissioning',  SUBCONT: 'OVERALL',      TOTAL: 3199.9,  P_WK: 0,      A_WK: 0,      D_WK: 0,    P_CUM: 0,       A_CUM: 0,       D_CUM: 0, },
{ CDATE: '2024-01-26', PHASE: 'Commissioning', GROUP: 'Commissioning',  SUBCONT: 'OVERALL',      TOTAL: 3199.9,  P_WK: 0,      A_WK: 0,      D_WK: 0,    P_CUM: 0,       A_CUM: 0,       D_CUM: 0, },
{ CDATE: '2024-02-23', PHASE: 'Commissioning', GROUP: 'Commissioning',  SUBCONT: 'OVERALL',      TOTAL: 3199.9,  P_WK: 0,      A_WK: 0,      D_WK: 0,    P_CUM: 0,       A_CUM: 0,       D_CUM: 0, },
{ CDATE: '2024-03-29', PHASE: 'Commissioning', GROUP: 'Commissioning',  SUBCONT: 'OVERALL',      TOTAL: 3199.9,  P_WK: 0,      A_WK: 0,      D_WK: 0,    P_CUM: 0,       A_CUM: 0,       D_CUM: 0, },
{ CDATE: '2024-04-26', PHASE: 'Commissioning', GROUP: 'Commissioning',  SUBCONT: 'OVERALL',      TOTAL: 3199.9,  P_WK: 0,      A_WK: 0,      D_WK: 0,    P_CUM: 0,       A_CUM: 0,       D_CUM: 0, },
{ CDATE: '2024-05-31', PHASE: 'Commissioning', GROUP: 'Commissioning',  SUBCONT: 'OVERALL',      TOTAL: 3199.9,  P_WK: 0,      A_WK: 0,      D_WK: 0,    P_CUM: 0,       A_CUM: 0,       D_CUM: 0, },
{ CDATE: '2024-06-28', PHASE: 'Commissioning', GROUP: 'Commissioning',  SUBCONT: 'OVERALL',      TOTAL: 3199.9,  P_WK: 0,      A_WK: 0,      D_WK: 0,    P_CUM: 0,       A_CUM: 0,       D_CUM: 0, },
{ CDATE: '2024-07-26', PHASE: 'Commissioning', GROUP: 'Commissioning',  SUBCONT: 'OVERALL',      TOTAL: 3199.9,  P_WK: 0,      A_WK: 0,      D_WK: 0,    P_CUM: 0,       A_CUM: 0,       D_CUM: 0, },
{ CDATE: '2024-08-30', PHASE: 'Commissioning', GROUP: 'Commissioning',  SUBCONT: 'OVERALL',      TOTAL: 3199.9,  P_WK: 0,      A_WK: 0,      D_WK: 0,    P_CUM: 0,       A_CUM: 0,       D_CUM: 0, },
{ CDATE: '2024-09-27', PHASE: 'Commissioning', GROUP: 'Commissioning',  SUBCONT: 'OVERALL',      TOTAL: 3199.9,  P_WK: 0,      A_WK: 0,      D_WK: 0,    P_CUM: 0,       A_CUM: 0,       D_CUM: 0, },
{ CDATE: '2024-10-25', PHASE: 'Commissioning', GROUP: 'Commissioning',  SUBCONT: 'OVERALL',      TOTAL: 3199.9,  P_WK: 0,      A_WK: 0,      D_WK: 0,    P_CUM: 0,       A_CUM: 0,       D_CUM: 0, },
{ CDATE: '2024-11-29', PHASE: 'Commissioning', GROUP: 'Commissioning',  SUBCONT: 'OVERALL',      TOTAL: 3199.9,  P_WK: 0,      A_WK: 0,      D_WK: 0,    P_CUM: 0,       A_CUM: 0,       D_CUM: 0, },
{ CDATE: '2024-12-27', PHASE: 'Commissioning', GROUP: 'Commissioning',  SUBCONT: 'OVERALL',      TOTAL: 3199.9,  P_WK: 0,      A_WK: 0,      D_WK: 0,    P_CUM: 0,       A_CUM: 0,       D_CUM: 0, },
{ CDATE: '2025-01-31', PHASE: 'Commissioning', GROUP: 'Commissioning',  SUBCONT: 'OVERALL',      TOTAL: 3199.9,  P_WK: 0,      A_WK: 0,      D_WK: 0,    P_CUM: 0,       A_CUM: 0,       D_CUM: 0, },
{ CDATE: '2025-02-28', PHASE: 'Commissioning', GROUP: 'Commissioning',  SUBCONT: 'OVERALL',      TOTAL: 3199.9,  P_WK: 0,      A_WK: 0,      D_WK: 0,    P_CUM: 0,       A_CUM: 0,       D_CUM: 0, },
{ CDATE: '2025-03-28', PHASE: 'Commissioning', GROUP: 'Commissioning',  SUBCONT: 'OVERALL',      TOTAL: 3199.9,  P_WK: 0,      A_WK: 0,      D_WK: 0,    P_CUM: 0,       A_CUM: 0,       D_CUM: 0, },
{ CDATE: '2025-04-25', PHASE: 'Commissioning', GROUP: 'Commissioning',  SUBCONT: 'OVERALL',      TOTAL: 3199.9,  P_WK: 0,      A_WK: 0,      D_WK: 0,    P_CUM: 0,       A_CUM: 0,       D_CUM: 0, },
{ CDATE: '2025-05-30', PHASE: 'Commissioning', GROUP: 'Commissioning',  SUBCONT: 'OVERALL',      TOTAL: 3199.9,  P_WK: 0,      A_WK: 0,      D_WK: 0,    P_CUM: 0,       A_CUM: 0,       D_CUM: 0, },
{ CDATE: '2025-06-27', PHASE: 'Commissioning', GROUP: 'Commissioning',  SUBCONT: 'OVERALL',      TOTAL: 3199.9,  P_WK: 0,      A_WK: 0,      D_WK: 0,    P_CUM: 0,       A_CUM: 0,       D_CUM: 0, },
{ CDATE: '2025-07-25', PHASE: 'Commissioning', GROUP: 'Commissioning',  SUBCONT: 'OVERALL',      TOTAL: 3199.9,  P_WK: 0,      A_WK: 0,      D_WK: 0,    P_CUM: 0,       A_CUM: 0,       D_CUM: 0, },
{ CDATE: '2025-08-29', PHASE: 'Commissioning', GROUP: 'Commissioning',  SUBCONT: 'OVERALL',      TOTAL: 3199.9,  P_WK: 0,      A_WK: 0,      D_WK: 0,    P_CUM: 0,       A_CUM: 0,       D_CUM: 0, },
{ CDATE: '2025-09-26', PHASE: 'Commissioning', GROUP: 'Commissioning',  SUBCONT: 'OVERALL',      TOTAL: 3199.9,  P_WK: 0,      A_WK: 0,      D_WK: 0,    P_CUM: 0,       A_CUM: 0,       D_CUM: 0, },
{ CDATE: '2025-10-31', PHASE: 'Commissioning', GROUP: 'Commissioning',  SUBCONT: 'OVERALL',      TOTAL: 3199.9,  P_WK: 0,      A_WK: 0,      D_WK: 0,    P_CUM: 0,       A_CUM: 0,       D_CUM: 0, },
{ CDATE: '2025-11-28', PHASE: 'Commissioning', GROUP: 'Commissioning',  SUBCONT: 'OVERALL',      TOTAL: 3199.9,  P_WK: 0,      A_WK: 0,      D_WK: 0,    P_CUM: 0,       A_CUM: 0,       D_CUM: 0, },
{ CDATE: '2025-12-26', PHASE: 'Commissioning', GROUP: 'Commissioning',  SUBCONT: 'OVERALL',      TOTAL: 3199.9,  P_WK: 0,      A_WK: 0,      D_WK: 0,    P_CUM: 0,       A_CUM: 0,       D_CUM: 0, },
{ CDATE: '2026-01-30', PHASE: 'Commissioning', GROUP: 'Commissioning',  SUBCONT: 'OVERALL',      TOTAL: 3199.9,  P_WK: 0,      A_WK: 0,      D_WK: 0,    P_CUM: 0,       A_CUM: 0,       D_CUM: 0, },
{ CDATE: '2026-02-27', PHASE: 'Commissioning', GROUP: 'Commissioning',  SUBCONT: 'OVERALL',      TOTAL: 3199.9,  P_WK: 33.6,   A_WK: 0,      D_WK: 0,    P_CUM: 33.6,    A_CUM: 0,       D_CUM: 0, },
{ CDATE: '2026-03-27', PHASE: 'Commissioning', GROUP: 'Commissioning',  SUBCONT: 'OVERALL',      TOTAL: 3199.9,  P_WK: 157.9,  A_WK: 0,      D_WK: 0,    P_CUM: 191.5,   A_CUM: 0,       D_CUM: 0, },
{ CDATE: '2026-04-24', PHASE: 'Commissioning', GROUP: 'Commissioning',  SUBCONT: 'OVERALL',      TOTAL: 3199.9,  P_WK: 254.5,  A_WK: 0,      D_WK: 0,    P_CUM: 446,     A_CUM: 0,       D_CUM: 0, },
{ CDATE: '2026-05-29', PHASE: 'Commissioning', GROUP: 'Commissioning',  SUBCONT: 'OVERALL',      TOTAL: 3199.9,  P_WK: 55.2,   A_WK: 0,      D_WK: 0,    P_CUM: 501.2,   A_CUM: 0,       D_CUM: 0, },
{ CDATE: '2026-06-26', PHASE: 'Commissioning', GROUP: 'Commissioning',  SUBCONT: 'OVERALL',      TOTAL: 3199.9,  P_WK: 127.8,  A_WK: 0,      D_WK: 0,    P_CUM: 629,     A_CUM: 0,       D_CUM: 0, },
{ CDATE: '2026-07-31', PHASE: 'Commissioning', GROUP: 'Commissioning',  SUBCONT: 'OVERALL',      TOTAL: 3199.9,  P_WK: 306.4,  A_WK: 0,      D_WK: 0,    P_CUM: 935.4,   A_CUM: 0,       D_CUM: 0, },
{ CDATE: '2026-08-28', PHASE: 'Commissioning', GROUP: 'Commissioning',  SUBCONT: 'OVERALL',      TOTAL: 3199.9,  P_WK: 359.6,  A_WK: 0,      D_WK: 0,    P_CUM: 1295,    A_CUM: 0,       D_CUM: 0, },
{ CDATE: '2026-09-25', PHASE: 'Commissioning', GROUP: 'Commissioning',  SUBCONT: 'OVERALL',      TOTAL: 3199.9,  P_WK: 380.5,  A_WK: 0,      D_WK: 0,    P_CUM: 1675.5,  A_CUM: 0,       D_CUM: 0, },
{ CDATE: '2026-10-30', PHASE: 'Commissioning', GROUP: 'Commissioning',  SUBCONT: 'OVERALL',      TOTAL: 3199.9,  P_WK: 454.8,  A_WK: 0,      D_WK: 0,    P_CUM: 2130.3,  A_CUM: 0,       D_CUM: 0, },
{ CDATE: '2026-11-27', PHASE: 'Commissioning', GROUP: 'Commissioning',  SUBCONT: 'OVERALL',      TOTAL: 3199.9,  P_WK: 233.1,  A_WK: 0,      D_WK: 0,    P_CUM: 2363.4,  A_CUM: 0,       D_CUM: 0, },
{ CDATE: '2026-12-25', PHASE: 'Commissioning', GROUP: 'Commissioning',  SUBCONT: 'OVERALL',      TOTAL: 3199.9,  P_WK: 418.8,  A_WK: 0,      D_WK: 0,    P_CUM: 2782.2,  A_CUM: 0,       D_CUM: 0, },
{ CDATE: '2027-01-29', PHASE: 'Commissioning', GROUP: 'Commissioning',  SUBCONT: 'OVERALL',      TOTAL: 3199.9,  P_WK: 297.9,  A_WK: 0,      D_WK: 0,    P_CUM: 3080.1,  A_CUM: 0,       D_CUM: 0, },
{ CDATE: '2027-02-26', PHASE: 'Commissioning', GROUP: 'Commissioning',  SUBCONT: 'OVERALL',      TOTAL: 3199.9,  P_WK: 45.6,   A_WK: 0,      D_WK: 0,    P_CUM: 3125.7,  A_CUM: 0,       D_CUM: 0, },
{ CDATE: '2027-03-26', PHASE: 'Commissioning', GROUP: 'Commissioning',  SUBCONT: 'OVERALL',      TOTAL: 3199.9,  P_WK: 74.3,   A_WK: 0,      D_WK: 0,    P_CUM: 3200,    A_CUM: 0,       D_CUM: 0, },
{ CDATE: '2027-04-30', PHASE: 'Commissioning', GROUP: 'Commissioning',  SUBCONT: 'OVERALL',      TOTAL: 3199.9,  P_WK: 0,      A_WK: 0,      D_WK: 0,    P_CUM: 3200,    A_CUM: 0,       D_CUM: 0, },
{ CDATE: '2027-05-28', PHASE: 'Commissioning', GROUP: 'Commissioning',  SUBCONT: 'OVERALL',      TOTAL: 3199.9,  P_WK: 0,      A_WK: 0,      D_WK: 0,    P_CUM: 3200,    A_CUM: 0,       D_CUM: 0, },
{ CDATE: '2027-06-11', PHASE: 'Commissioning', GROUP: 'Commissioning',  SUBCONT: 'OVERALL',      TOTAL: 3199.9,  P_WK: 0,      A_WK: 0,      D_WK: 0,    P_CUM: 3200,    A_CUM: 0,       D_CUM: 0, },
{ CDATE: '2023-07-28', PHASE: 'Commissioning', GROUP: 'OVERALL',        SUBCONT: 'OVERALL',      TOTAL: 3199.9,  P_WK: 0,      A_WK: 0,      D_WK: 0,    P_CUM: 0,       A_CUM: 0,       D_CUM: 0, },
{ CDATE: '2023-08-25', PHASE: 'Commissioning', GROUP: 'OVERALL',        SUBCONT: 'OVERALL',      TOTAL: 3199.9,  P_WK: 0,      A_WK: 0,      D_WK: 0,    P_CUM: 0,       A_CUM: 0,       D_CUM: 0, },
{ CDATE: '2023-09-29', PHASE: 'Commissioning', GROUP: 'OVERALL',        SUBCONT: 'OVERALL',      TOTAL: 3199.9,  P_WK: 0,      A_WK: 0,      D_WK: 0,    P_CUM: 0,       A_CUM: 0,       D_CUM: 0, },
{ CDATE: '2023-10-27', PHASE: 'Commissioning', GROUP: 'OVERALL',        SUBCONT: 'OVERALL',      TOTAL: 3199.9,  P_WK: 0,      A_WK: 0,      D_WK: 0,    P_CUM: 0,       A_CUM: 0,       D_CUM: 0, },
{ CDATE: '2023-11-24', PHASE: 'Commissioning', GROUP: 'OVERALL',        SUBCONT: 'OVERALL',      TOTAL: 3199.9,  P_WK: 0,      A_WK: 0,      D_WK: 0,    P_CUM: 0,       A_CUM: 0,       D_CUM: 0, },
{ CDATE: '2023-12-29', PHASE: 'Commissioning', GROUP: 'OVERALL',        SUBCONT: 'OVERALL',      TOTAL: 3199.9,  P_WK: 0,      A_WK: 0,      D_WK: 0,    P_CUM: 0,       A_CUM: 0,       D_CUM: 0, },
{ CDATE: '2024-01-26', PHASE: 'Commissioning', GROUP: 'OVERALL',        SUBCONT: 'OVERALL',      TOTAL: 3199.9,  P_WK: 0,      A_WK: 0,      D_WK: 0,    P_CUM: 0,       A_CUM: 0,       D_CUM: 0, },
{ CDATE: '2024-02-23', PHASE: 'Commissioning', GROUP: 'OVERALL',        SUBCONT: 'OVERALL',      TOTAL: 3199.9,  P_WK: 0,      A_WK: 0,      D_WK: 0,    P_CUM: 0,       A_CUM: 0,       D_CUM: 0, },
{ CDATE: '2024-03-29', PHASE: 'Commissioning', GROUP: 'OVERALL',        SUBCONT: 'OVERALL',      TOTAL: 3199.9,  P_WK: 0,      A_WK: 0,      D_WK: 0,    P_CUM: 0,       A_CUM: 0,       D_CUM: 0, },
{ CDATE: '2024-04-26', PHASE: 'Commissioning', GROUP: 'OVERALL',        SUBCONT: 'OVERALL',      TOTAL: 3199.9,  P_WK: 0,      A_WK: 0,      D_WK: 0,    P_CUM: 0,       A_CUM: 0,       D_CUM: 0, },
{ CDATE: '2024-05-31', PHASE: 'Commissioning', GROUP: 'OVERALL',        SUBCONT: 'OVERALL',      TOTAL: 3199.9,  P_WK: 0,      A_WK: 0,      D_WK: 0,    P_CUM: 0,       A_CUM: 0,       D_CUM: 0, },
{ CDATE: '2024-06-28', PHASE: 'Commissioning', GROUP: 'OVERALL',        SUBCONT: 'OVERALL',      TOTAL: 3199.9,  P_WK: 0,      A_WK: 0,      D_WK: 0,    P_CUM: 0,       A_CUM: 0,       D_CUM: 0, },
{ CDATE: '2024-07-26', PHASE: 'Commissioning', GROUP: 'OVERALL',        SUBCONT: 'OVERALL',      TOTAL: 3199.9,  P_WK: 0,      A_WK: 0,      D_WK: 0,    P_CUM: 0,       A_CUM: 0,       D_CUM: 0, },
{ CDATE: '2024-08-30', PHASE: 'Commissioning', GROUP: 'OVERALL',        SUBCONT: 'OVERALL',      TOTAL: 3199.9,  P_WK: 0,      A_WK: 0,      D_WK: 0,    P_CUM: 0,       A_CUM: 0,       D_CUM: 0, },
{ CDATE: '2024-09-27', PHASE: 'Commissioning', GROUP: 'OVERALL',        SUBCONT: 'OVERALL',      TOTAL: 3199.9,  P_WK: 0,      A_WK: 0,      D_WK: 0,    P_CUM: 0,       A_CUM: 0,       D_CUM: 0, },
{ CDATE: '2024-10-25', PHASE: 'Commissioning', GROUP: 'OVERALL',        SUBCONT: 'OVERALL',      TOTAL: 3199.9,  P_WK: 0,      A_WK: 0,      D_WK: 0,    P_CUM: 0,       A_CUM: 0,       D_CUM: 0, },
{ CDATE: '2024-11-29', PHASE: 'Commissioning', GROUP: 'OVERALL',        SUBCONT: 'OVERALL',      TOTAL: 3199.9,  P_WK: 0,      A_WK: 0,      D_WK: 0,    P_CUM: 0,       A_CUM: 0,       D_CUM: 0, },
{ CDATE: '2024-12-27', PHASE: 'Commissioning', GROUP: 'OVERALL',        SUBCONT: 'OVERALL',      TOTAL: 3199.9,  P_WK: 0,      A_WK: 0,      D_WK: 0,    P_CUM: 0,       A_CUM: 0,       D_CUM: 0, },
{ CDATE: '2025-01-31', PHASE: 'Commissioning', GROUP: 'OVERALL',        SUBCONT: 'OVERALL',      TOTAL: 3199.9,  P_WK: 0,      A_WK: 0,      D_WK: 0,    P_CUM: 0,       A_CUM: 0,       D_CUM: 0, },
{ CDATE: '2025-02-28', PHASE: 'Commissioning', GROUP: 'OVERALL',        SUBCONT: 'OVERALL',      TOTAL: 3199.9,  P_WK: 0,      A_WK: 0,      D_WK: 0,    P_CUM: 0,       A_CUM: 0,       D_CUM: 0, },
{ CDATE: '2025-03-28', PHASE: 'Commissioning', GROUP: 'OVERALL',        SUBCONT: 'OVERALL',      TOTAL: 3199.9,  P_WK: 0,      A_WK: 0,      D_WK: 0,    P_CUM: 0,       A_CUM: 0,       D_CUM: 0, },
{ CDATE: '2025-04-25', PHASE: 'Commissioning', GROUP: 'OVERALL',        SUBCONT: 'OVERALL',      TOTAL: 3199.9,  P_WK: 0,      A_WK: 0,      D_WK: 0,    P_CUM: 0,       A_CUM: 0,       D_CUM: 0, },
{ CDATE: '2025-05-30', PHASE: 'Commissioning', GROUP: 'OVERALL',        SUBCONT: 'OVERALL',      TOTAL: 3199.9,  P_WK: 0,      A_WK: 0,      D_WK: 0,    P_CUM: 0,       A_CUM: 0,       D_CUM: 0, },
{ CDATE: '2025-06-27', PHASE: 'Commissioning', GROUP: 'OVERALL',        SUBCONT: 'OVERALL',      TOTAL: 3199.9,  P_WK: 0,      A_WK: 0,      D_WK: 0,    P_CUM: 0,       A_CUM: 0,       D_CUM: 0, },
{ CDATE: '2025-07-25', PHASE: 'Commissioning', GROUP: 'OVERALL',        SUBCONT: 'OVERALL',      TOTAL: 3199.9,  P_WK: 0,      A_WK: 0,      D_WK: 0,    P_CUM: 0,       A_CUM: 0,       D_CUM: 0, },
{ CDATE: '2025-08-29', PHASE: 'Commissioning', GROUP: 'OVERALL',        SUBCONT: 'OVERALL',      TOTAL: 3199.9,  P_WK: 0,      A_WK: 0,      D_WK: 0,    P_CUM: 0,       A_CUM: 0,       D_CUM: 0, },
{ CDATE: '2025-09-26', PHASE: 'Commissioning', GROUP: 'OVERALL',        SUBCONT: 'OVERALL',      TOTAL: 3199.9,  P_WK: 0,      A_WK: 0,      D_WK: 0,    P_CUM: 0,       A_CUM: 0,       D_CUM: 0, },
{ CDATE: '2025-10-31', PHASE: 'Commissioning', GROUP: 'OVERALL',        SUBCONT: 'OVERALL',      TOTAL: 3199.9,  P_WK: 0,      A_WK: 0,      D_WK: 0,    P_CUM: 0,       A_CUM: 0,       D_CUM: 0, },
{ CDATE: '2025-11-28', PHASE: 'Commissioning', GROUP: 'OVERALL',        SUBCONT: 'OVERALL',      TOTAL: 3199.9,  P_WK: 0,      A_WK: 0,      D_WK: 0,    P_CUM: 0,       A_CUM: 0,       D_CUM: 0, },
{ CDATE: '2025-12-26', PHASE: 'Commissioning', GROUP: 'OVERALL',        SUBCONT: 'OVERALL',      TOTAL: 3199.9,  P_WK: 0,      A_WK: 0,      D_WK: 0,    P_CUM: 0,       A_CUM: 0,       D_CUM: 0, },
{ CDATE: '2026-01-30', PHASE: 'Commissioning', GROUP: 'OVERALL',        SUBCONT: 'OVERALL',      TOTAL: 3199.9,  P_WK: 0,      A_WK: 0,      D_WK: 0,    P_CUM: 0,       A_CUM: 0,       D_CUM: 0, },
{ CDATE: '2026-02-27', PHASE: 'Commissioning', GROUP: 'OVERALL',        SUBCONT: 'OVERALL',      TOTAL: 3199.9,  P_WK: 33.6,   A_WK: 0,      D_WK: 0,    P_CUM: 33.6,    A_CUM: 0,       D_CUM: 0, },
{ CDATE: '2026-03-27', PHASE: 'Commissioning', GROUP: 'OVERALL',        SUBCONT: 'OVERALL',      TOTAL: 3199.9,  P_WK: 157.9,  A_WK: 0,      D_WK: 0,    P_CUM: 191.5,   A_CUM: 0,       D_CUM: 0, },
{ CDATE: '2026-04-24', PHASE: 'Commissioning', GROUP: 'OVERALL',        SUBCONT: 'OVERALL',      TOTAL: 3199.9,  P_WK: 254.5,  A_WK: 0,      D_WK: 0,    P_CUM: 446,     A_CUM: 0,       D_CUM: 0, },
{ CDATE: '2026-05-29', PHASE: 'Commissioning', GROUP: 'OVERALL',        SUBCONT: 'OVERALL',      TOTAL: 3199.9,  P_WK: 55.2,   A_WK: 0,      D_WK: 0,    P_CUM: 501.2,   A_CUM: 0,       D_CUM: 0, },
{ CDATE: '2026-06-26', PHASE: 'Commissioning', GROUP: 'OVERALL',        SUBCONT: 'OVERALL',      TOTAL: 3199.9,  P_WK: 127.8,  A_WK: 0,      D_WK: 0,    P_CUM: 629,     A_CUM: 0,       D_CUM: 0, },
{ CDATE: '2026-07-31', PHASE: 'Commissioning', GROUP: 'OVERALL',        SUBCONT: 'OVERALL',      TOTAL: 3199.9,  P_WK: 306.4,  A_WK: 0,      D_WK: 0,    P_CUM: 935.4,   A_CUM: 0,       D_CUM: 0, },
{ CDATE: '2026-08-28', PHASE: 'Commissioning', GROUP: 'OVERALL',        SUBCONT: 'OVERALL',      TOTAL: 3199.9,  P_WK: 359.6,  A_WK: 0,      D_WK: 0,    P_CUM: 1295,    A_CUM: 0,       D_CUM: 0, },
{ CDATE: '2026-09-25', PHASE: 'Commissioning', GROUP: 'OVERALL',        SUBCONT: 'OVERALL',      TOTAL: 3199.9,  P_WK: 380.5,  A_WK: 0,      D_WK: 0,    P_CUM: 1675.5,  A_CUM: 0,       D_CUM: 0, },
{ CDATE: '2026-10-30', PHASE: 'Commissioning', GROUP: 'OVERALL',        SUBCONT: 'OVERALL',      TOTAL: 3199.9,  P_WK: 454.8,  A_WK: 0,      D_WK: 0,    P_CUM: 2130.3,  A_CUM: 0,       D_CUM: 0, },
{ CDATE: '2026-11-27', PHASE: 'Commissioning', GROUP: 'OVERALL',        SUBCONT: 'OVERALL',      TOTAL: 3199.9,  P_WK: 233.1,  A_WK: 0,      D_WK: 0,    P_CUM: 2363.4,  A_CUM: 0,       D_CUM: 0, },
{ CDATE: '2026-12-25', PHASE: 'Commissioning', GROUP: 'OVERALL',        SUBCONT: 'OVERALL',      TOTAL: 3199.9,  P_WK: 418.8,  A_WK: 0,      D_WK: 0,    P_CUM: 2782.2,  A_CUM: 0,       D_CUM: 0, },
{ CDATE: '2027-01-29', PHASE: 'Commissioning', GROUP: 'OVERALL',        SUBCONT: 'OVERALL',      TOTAL: 3199.9,  P_WK: 297.9,  A_WK: 0,      D_WK: 0,    P_CUM: 3080.1,  A_CUM: 0,       D_CUM: 0, },
{ CDATE: '2027-02-26', PHASE: 'Commissioning', GROUP: 'OVERALL',        SUBCONT: 'OVERALL',      TOTAL: 3199.9,  P_WK: 45.6,   A_WK: 0,      D_WK: 0,    P_CUM: 3125.7,  A_CUM: 0,       D_CUM: 0, },
{ CDATE: '2027-03-26', PHASE: 'Commissioning', GROUP: 'OVERALL',        SUBCONT: 'OVERALL',      TOTAL: 3199.9,  P_WK: 74.3,   A_WK: 0,      D_WK: 0,    P_CUM: 3200,    A_CUM: 0,       D_CUM: 0, },
{ CDATE: '2027-04-30', PHASE: 'Commissioning', GROUP: 'OVERALL',        SUBCONT: 'OVERALL',      TOTAL: 3199.9,  P_WK: 0,      A_WK: 0,      D_WK: 0,    P_CUM: 3200,    A_CUM: 0,       D_CUM: 0, },
{ CDATE: '2027-05-28', PHASE: 'Commissioning', GROUP: 'OVERALL',        SUBCONT: 'OVERALL',      TOTAL: 3199.9,  P_WK: 0,      A_WK: 0,      D_WK: 0,    P_CUM: 3200,    A_CUM: 0,       D_CUM: 0, },
{ CDATE: '2027-06-11', PHASE: 'Commissioning', GROUP: 'OVERALL',        SUBCONT: 'OVERALL',      TOTAL: 3199.9,  P_WK: 0,      A_WK: 0,      D_WK: 0,    P_CUM: 3200,    A_CUM: 0,       D_CUM: 0, },
{ CDATE: '2023-07-28', PHASE: 'Management',    GROUP: 'Management',     SUBCONT: 'Etc.',         TOTAL: 0,       P_WK: 0,      A_WK: 0,      D_WK: 0,    P_CUM: 0,       A_CUM: 0,       D_CUM: 0, },
{ CDATE: '2023-08-25', PHASE: 'Management',    GROUP: 'Management',     SUBCONT: 'Etc.',         TOTAL: 0,       P_WK: 0,      A_WK: 0,      D_WK: 0,    P_CUM: 0,       A_CUM: 0,       D_CUM: 0, },
{ CDATE: '2023-09-29', PHASE: 'Management',    GROUP: 'Management',     SUBCONT: 'Etc.',         TOTAL: 0,       P_WK: 0,      A_WK: 0,      D_WK: 0,    P_CUM: 0,       A_CUM: 0,       D_CUM: 0, },
{ CDATE: '2023-10-27', PHASE: 'Management',    GROUP: 'Management',     SUBCONT: 'Etc.',         TOTAL: 0,       P_WK: 0,      A_WK: 0,      D_WK: 0,    P_CUM: 0,       A_CUM: 0,       D_CUM: 0, },
{ CDATE: '2023-11-24', PHASE: 'Management',    GROUP: 'Management',     SUBCONT: 'Etc.',         TOTAL: 0,       P_WK: 0,      A_WK: 0,      D_WK: 0,    P_CUM: 0,       A_CUM: 0,       D_CUM: 0, },
{ CDATE: '2023-12-29', PHASE: 'Management',    GROUP: 'Management',     SUBCONT: 'Etc.',         TOTAL: 0,       P_WK: 0,      A_WK: 0,      D_WK: 0,    P_CUM: 0,       A_CUM: 0,       D_CUM: 0, },
{ CDATE: '2024-01-26', PHASE: 'Management',    GROUP: 'Management',     SUBCONT: 'Etc.',         TOTAL: 0,       P_WK: 0,      A_WK: 0,      D_WK: 0,    P_CUM: 0,       A_CUM: 0,       D_CUM: 0, },
{ CDATE: '2024-02-23', PHASE: 'Management',    GROUP: 'Management',     SUBCONT: 'Etc.',         TOTAL: 0,       P_WK: 0,      A_WK: 0,      D_WK: 0,    P_CUM: 0,       A_CUM: 0,       D_CUM: 0, },
{ CDATE: '2024-03-29', PHASE: 'Management',    GROUP: 'Management',     SUBCONT: 'Etc.',         TOTAL: 0,       P_WK: 0,      A_WK: 0,      D_WK: 0,    P_CUM: 0,       A_CUM: 0,       D_CUM: 0, },
{ CDATE: '2024-04-26', PHASE: 'Management',    GROUP: 'Management',     SUBCONT: 'Etc.',         TOTAL: 0,       P_WK: 0,      A_WK: 0,      D_WK: 0,    P_CUM: 0,       A_CUM: 0,       D_CUM: 0, },
{ CDATE: '2024-05-31', PHASE: 'Management',    GROUP: 'Management',     SUBCONT: 'Etc.',         TOTAL: 0,       P_WK: 0,      A_WK: 0,      D_WK: 0,    P_CUM: 0,       A_CUM: 0,       D_CUM: 0, },
{ CDATE: '2024-06-28', PHASE: 'Management',    GROUP: 'Management',     SUBCONT: 'Etc.',         TOTAL: 0,       P_WK: 0,      A_WK: 0,      D_WK: 0,    P_CUM: 0,       A_CUM: 0,       D_CUM: 0, },
{ CDATE: '2024-07-26', PHASE: 'Management',    GROUP: 'Management',     SUBCONT: 'Etc.',         TOTAL: 0,       P_WK: 0,      A_WK: 0,      D_WK: 0,    P_CUM: 0,       A_CUM: 0,       D_CUM: 0, },
{ CDATE: '2024-08-30', PHASE: 'Management',    GROUP: 'Management',     SUBCONT: 'Etc.',         TOTAL: 0,       P_WK: 0,      A_WK: 0,      D_WK: 0,    P_CUM: 0,       A_CUM: 0,       D_CUM: 0, },
{ CDATE: '2024-09-27', PHASE: 'Management',    GROUP: 'Management',     SUBCONT: 'Etc.',         TOTAL: 0,       P_WK: 0,      A_WK: 0,      D_WK: 0,    P_CUM: 0,       A_CUM: 0,       D_CUM: 0, },
{ CDATE: '2024-10-25', PHASE: 'Management',    GROUP: 'Management',     SUBCONT: 'Etc.',         TOTAL: 0,       P_WK: 0,      A_WK: 0,      D_WK: 0,    P_CUM: 0,       A_CUM: 0,       D_CUM: 0, },
{ CDATE: '2024-11-29', PHASE: 'Management',    GROUP: 'Management',     SUBCONT: 'Etc.',         TOTAL: 0,       P_WK: 0,      A_WK: 0,      D_WK: 0,    P_CUM: 0,       A_CUM: 0,       D_CUM: 0, },
{ CDATE: '2024-12-27', PHASE: 'Management',    GROUP: 'Management',     SUBCONT: 'Etc.',         TOTAL: 0,       P_WK: 0,      A_WK: 0,      D_WK: 0,    P_CUM: 0,       A_CUM: 0,       D_CUM: 0, },
{ CDATE: '2025-01-31', PHASE: 'Management',    GROUP: 'Management',     SUBCONT: 'Etc.',         TOTAL: 0,       P_WK: 0,      A_WK: 0,      D_WK: 0,    P_CUM: 0,       A_CUM: 0,       D_CUM: 0, },
{ CDATE: '2025-02-28', PHASE: 'Management',    GROUP: 'Management',     SUBCONT: 'Etc.',         TOTAL: 0,       P_WK: 0,      A_WK: 0,      D_WK: 0,    P_CUM: 0,       A_CUM: 0,       D_CUM: 0, },
{ CDATE: '2025-03-28', PHASE: 'Management',    GROUP: 'Management',     SUBCONT: 'Etc.',         TOTAL: 0,       P_WK: 0,      A_WK: 0,      D_WK: 0,    P_CUM: 0,       A_CUM: 0,       D_CUM: 0, },
{ CDATE: '2025-04-25', PHASE: 'Management',    GROUP: 'Management',     SUBCONT: 'Etc.',         TOTAL: 0,       P_WK: 0,      A_WK: 0,      D_WK: 0,    P_CUM: 0,       A_CUM: 0,       D_CUM: 0, },
{ CDATE: '2025-05-30', PHASE: 'Management',    GROUP: 'Management',     SUBCONT: 'Etc.',         TOTAL: 0,       P_WK: 0,      A_WK: 0,      D_WK: 0,    P_CUM: 0,       A_CUM: 0,       D_CUM: 0, },
{ CDATE: '2025-06-27', PHASE: 'Management',    GROUP: 'Management',     SUBCONT: 'Etc.',         TOTAL: 0,       P_WK: 0,      A_WK: 0,      D_WK: 0,    P_CUM: 0,       A_CUM: 0,       D_CUM: 0, },
{ CDATE: '2025-07-25', PHASE: 'Management',    GROUP: 'Management',     SUBCONT: 'Etc.',         TOTAL: 0,       P_WK: 0,      A_WK: 0,      D_WK: 0,    P_CUM: 0,       A_CUM: 0,       D_CUM: 0, },
{ CDATE: '2025-08-29', PHASE: 'Management',    GROUP: 'Management',     SUBCONT: 'Etc.',         TOTAL: 0,       P_WK: 0,      A_WK: 0,      D_WK: 0,    P_CUM: 0,       A_CUM: 0,       D_CUM: 0, },
{ CDATE: '2025-09-26', PHASE: 'Management',    GROUP: 'Management',     SUBCONT: 'Etc.',         TOTAL: 0,       P_WK: 0,      A_WK: 0,      D_WK: 0,    P_CUM: 0,       A_CUM: 0,       D_CUM: 0, },
{ CDATE: '2025-10-31', PHASE: 'Management',    GROUP: 'Management',     SUBCONT: 'Etc.',         TOTAL: 0,       P_WK: 0,      A_WK: 0,      D_WK: 0,    P_CUM: 0,       A_CUM: 0,       D_CUM: 0, },
{ CDATE: '2025-11-28', PHASE: 'Management',    GROUP: 'Management',     SUBCONT: 'Etc.',         TOTAL: 0,       P_WK: 0,      A_WK: 0,      D_WK: 0,    P_CUM: 0,       A_CUM: 0,       D_CUM: 0, },
{ CDATE: '2025-12-26', PHASE: 'Management',    GROUP: 'Management',     SUBCONT: 'Etc.',         TOTAL: 0,       P_WK: 0,      A_WK: 0,      D_WK: 0,    P_CUM: 0,       A_CUM: 0,       D_CUM: 0, },
{ CDATE: '2026-01-30', PHASE: 'Management',    GROUP: 'Management',     SUBCONT: 'Etc.',         TOTAL: 0,       P_WK: 0,      A_WK: 0,      D_WK: 0,    P_CUM: 0,       A_CUM: 0,       D_CUM: 0, },
{ CDATE: '2026-02-27', PHASE: 'Management',    GROUP: 'Management',     SUBCONT: 'Etc.',         TOTAL: 0,       P_WK: 0,      A_WK: 0,      D_WK: 0,    P_CUM: 0,       A_CUM: 0,       D_CUM: 0, },
{ CDATE: '2026-03-27', PHASE: 'Management',    GROUP: 'Management',     SUBCONT: 'Etc.',         TOTAL: 0,       P_WK: 0,      A_WK: 0,      D_WK: 0,    P_CUM: 0,       A_CUM: 0,       D_CUM: 0, },
{ CDATE: '2026-04-24', PHASE: 'Management',    GROUP: 'Management',     SUBCONT: 'Etc.',         TOTAL: 0,       P_WK: 0,      A_WK: 0,      D_WK: 0,    P_CUM: 0,       A_CUM: 0,       D_CUM: 0, },
{ CDATE: '2026-05-29', PHASE: 'Management',    GROUP: 'Management',     SUBCONT: 'Etc.',         TOTAL: 0,       P_WK: 0,      A_WK: 0,      D_WK: 0,    P_CUM: 0,       A_CUM: 0,       D_CUM: 0, },
{ CDATE: '2026-06-26', PHASE: 'Management',    GROUP: 'Management',     SUBCONT: 'Etc.',         TOTAL: 0,       P_WK: 0,      A_WK: 0,      D_WK: 0,    P_CUM: 0,       A_CUM: 0,       D_CUM: 0, },
{ CDATE: '2026-07-31', PHASE: 'Management',    GROUP: 'Management',     SUBCONT: 'Etc.',         TOTAL: 0,       P_WK: 0,      A_WK: 0,      D_WK: 0,    P_CUM: 0,       A_CUM: 0,       D_CUM: 0, },
{ CDATE: '2026-08-28', PHASE: 'Management',    GROUP: 'Management',     SUBCONT: 'Etc.',         TOTAL: 0,       P_WK: 0,      A_WK: 0,      D_WK: 0,    P_CUM: 0,       A_CUM: 0,       D_CUM: 0, },
{ CDATE: '2026-09-25', PHASE: 'Management',    GROUP: 'Management',     SUBCONT: 'Etc.',         TOTAL: 0,       P_WK: 0,      A_WK: 0,      D_WK: 0,    P_CUM: 0,       A_CUM: 0,       D_CUM: 0, },
{ CDATE: '2026-10-30', PHASE: 'Management',    GROUP: 'Management',     SUBCONT: 'Etc.',         TOTAL: 0,       P_WK: 0,      A_WK: 0,      D_WK: 0,    P_CUM: 0,       A_CUM: 0,       D_CUM: 0, },
{ CDATE: '2026-11-27', PHASE: 'Management',    GROUP: 'Management',     SUBCONT: 'Etc.',         TOTAL: 0,       P_WK: 0,      A_WK: 0,      D_WK: 0,    P_CUM: 0,       A_CUM: 0,       D_CUM: 0, },
{ CDATE: '2026-12-25', PHASE: 'Management',    GROUP: 'Management',     SUBCONT: 'Etc.',         TOTAL: 0,       P_WK: 0,      A_WK: 0,      D_WK: 0,    P_CUM: 0,       A_CUM: 0,       D_CUM: 0, },
{ CDATE: '2027-01-29', PHASE: 'Management',    GROUP: 'Management',     SUBCONT: 'Etc.',         TOTAL: 0,       P_WK: 0,      A_WK: 0,      D_WK: 0,    P_CUM: 0,       A_CUM: 0,       D_CUM: 0, },
{ CDATE: '2027-02-26', PHASE: 'Management',    GROUP: 'Management',     SUBCONT: 'Etc.',         TOTAL: 0,       P_WK: 0,      A_WK: 0,      D_WK: 0,    P_CUM: 0,       A_CUM: 0,       D_CUM: 0, },
{ CDATE: '2027-03-26', PHASE: 'Management',    GROUP: 'Management',     SUBCONT: 'Etc.',         TOTAL: 0,       P_WK: 0,      A_WK: 0,      D_WK: 0,    P_CUM: 0,       A_CUM: 0,       D_CUM: 0, },
{ CDATE: '2027-04-30', PHASE: 'Management',    GROUP: 'Management',     SUBCONT: 'Etc.',         TOTAL: 0,       P_WK: 0,      A_WK: 0,      D_WK: 0,    P_CUM: 0,       A_CUM: 0,       D_CUM: 0, },
{ CDATE: '2027-05-28', PHASE: 'Management',    GROUP: 'Management',     SUBCONT: 'Etc.',         TOTAL: 0,       P_WK: 0,      A_WK: 0,      D_WK: 0,    P_CUM: 0,       A_CUM: 0,       D_CUM: 0, },
{ CDATE: '2027-06-11', PHASE: 'Management',    GROUP: 'Management',     SUBCONT: 'Etc.',         TOTAL: 0,       P_WK: 0,      A_WK: 0,      D_WK: 0,    P_CUM: 0,       A_CUM: 0,       D_CUM: 0, },
{ CDATE: '2023-07-28', PHASE: 'Management',    GROUP: 'Management',     SUBCONT: 'HHI',          TOTAL: 7171,    P_WK: 51,     A_WK: 36,     D_WK: -15,  P_CUM: 51,      A_CUM: 36,      D_CUM: -15, },
{ CDATE: '2023-08-25', PHASE: 'Management',    GROUP: 'Management',     SUBCONT: 'HHI',          TOTAL: 7171,    P_WK: 68,     A_WK: 56,     D_WK: -12,  P_CUM: 119,     A_CUM: 92,      D_CUM: -27, },
{ CDATE: '2023-09-29', PHASE: 'Management',    GROUP: 'Management',     SUBCONT: 'HHI',          TOTAL: 7171,    P_WK: 109,    A_WK: 96,     D_WK: -13,  P_CUM: 228,     A_CUM: 188,     D_CUM: -40, },
{ CDATE: '2023-10-27', PHASE: 'Management',    GROUP: 'Management',     SUBCONT: 'HHI',          TOTAL: 7171,    P_WK: 100,    A_WK: 102,    D_WK: 2,    P_CUM: 328,     A_CUM: 290,     D_CUM: -38, },
{ CDATE: '2023-11-24', PHASE: 'Management',    GROUP: 'Management',     SUBCONT: 'HHI',          TOTAL: 7171,    P_WK: 103,    A_WK: 104,    D_WK: 1,    P_CUM: 431,     A_CUM: 394,     D_CUM: -37, },
{ CDATE: '2023-12-29', PHASE: 'Management',    GROUP: 'Management',     SUBCONT: 'HHI',          TOTAL: 7171,    P_WK: 146,    A_WK: 134,    D_WK: -12,  P_CUM: 577,     A_CUM: 528,     D_CUM: -49, },
{ CDATE: '2024-01-26', PHASE: 'Management',    GROUP: 'Management',     SUBCONT: 'HHI',          TOTAL: 7171,    P_WK: 120,    A_WK: 135,    D_WK: 15,   P_CUM: 697,     A_CUM: 663,     D_CUM: -34, },
{ CDATE: '2024-02-23', PHASE: 'Management',    GROUP: 'Management',     SUBCONT: 'HHI',          TOTAL: 7171,    P_WK: 120,    A_WK: 157,    D_WK: 37,   P_CUM: 817,     A_CUM: 820,     D_CUM: 3, },
{ CDATE: '2024-03-29', PHASE: 'Management',    GROUP: 'Management',     SUBCONT: 'HHI',          TOTAL: 7171,    P_WK: 150,    A_WK: 208,    D_WK: 58,   P_CUM: 967,     A_CUM: 1028,    D_CUM: 61, },
{ CDATE: '2024-04-26', PHASE: 'Management',    GROUP: 'Management',     SUBCONT: 'HHI',          TOTAL: 7171,    P_WK: 120,    A_WK: 176,    D_WK: 56,   P_CUM: 1087,    A_CUM: 1204,    D_CUM: 117, },
{ CDATE: '2024-05-31', PHASE: 'Management',    GROUP: 'Management',     SUBCONT: 'HHI',          TOTAL: 7171,    P_WK: 150,    A_WK: 228,    D_WK: 78,   P_CUM: 1237,    A_CUM: 1432,    D_CUM: 195, },
{ CDATE: '2024-06-28', PHASE: 'Management',    GROUP: 'Management',     SUBCONT: 'HHI',          TOTAL: 7171,    P_WK: 150,    A_WK: 184,    D_WK: 34,   P_CUM: 1387,    A_CUM: 1616,    D_CUM: 229, },
{ CDATE: '2024-07-26', PHASE: 'Management',    GROUP: 'Management',     SUBCONT: 'HHI',          TOTAL: 7171,    P_WK: 160,    A_WK: 190,    D_WK: 30,   P_CUM: 1547,    A_CUM: 1806,    D_CUM: 259, },
{ CDATE: '2024-08-30', PHASE: 'Management',    GROUP: 'Management',     SUBCONT: 'HHI',          TOTAL: 7171,    P_WK: 200,    A_WK: 201,    D_WK: 1,    P_CUM: 1747,    A_CUM: 2007,    D_CUM: 260, },
{ CDATE: '2024-09-27', PHASE: 'Management',    GROUP: 'Management',     SUBCONT: 'HHI',          TOTAL: 7171,    P_WK: 160,    A_WK: 187,    D_WK: 27,   P_CUM: 1907,    A_CUM: 2194,    D_CUM: 287, },
{ CDATE: '2024-10-25', PHASE: 'Management',    GROUP: 'Management',     SUBCONT: 'HHI',          TOTAL: 7171,    P_WK: 160,    A_WK: 193,    D_WK: 33,   P_CUM: 2067,    A_CUM: 2387,    D_CUM: 320, },
{ CDATE: '2024-11-29', PHASE: 'Management',    GROUP: 'Management',     SUBCONT: 'HHI',          TOTAL: 7171,    P_WK: 200,    A_WK: 250,    D_WK: 50,   P_CUM: 2267,    A_CUM: 2637,    D_CUM: 370, },
{ CDATE: '2024-12-27', PHASE: 'Management',    GROUP: 'Management',     SUBCONT: 'HHI',          TOTAL: 7171,    P_WK: 190,    A_WK: 0,      D_WK: 0,    P_CUM: 2457,    A_CUM: 2637,    D_CUM: 0, },
{ CDATE: '2025-01-31', PHASE: 'Management',    GROUP: 'Management',     SUBCONT: 'HHI',          TOTAL: 7171,    P_WK: 250,    A_WK: 0,      D_WK: 0,    P_CUM: 2707,    A_CUM: 2637,    D_CUM: 0, },
{ CDATE: '2025-02-28', PHASE: 'Management',    GROUP: 'Management',     SUBCONT: 'HHI',          TOTAL: 7171,    P_WK: 200,    A_WK: 0,      D_WK: 0,    P_CUM: 2907,    A_CUM: 2637,    D_CUM: 0, },
{ CDATE: '2025-03-28', PHASE: 'Management',    GROUP: 'Management',     SUBCONT: 'HHI',          TOTAL: 7171,    P_WK: 200,    A_WK: 0,      D_WK: 0,    P_CUM: 3107,    A_CUM: 2637,    D_CUM: 0, },
{ CDATE: '2025-04-25', PHASE: 'Management',    GROUP: 'Management',     SUBCONT: 'HHI',          TOTAL: 7171,    P_WK: 200,    A_WK: 0,      D_WK: 0,    P_CUM: 3307,    A_CUM: 2637,    D_CUM: 0, },
{ CDATE: '2025-05-30', PHASE: 'Management',    GROUP: 'Management',     SUBCONT: 'HHI',          TOTAL: 7171,    P_WK: 250,    A_WK: 0,      D_WK: 0,    P_CUM: 3557,    A_CUM: 2637,    D_CUM: 0, },
{ CDATE: '2025-06-27', PHASE: 'Management',    GROUP: 'Management',     SUBCONT: 'HHI',          TOTAL: 7171,    P_WK: 200,    A_WK: 0,      D_WK: 0,    P_CUM: 3757,    A_CUM: 2637,    D_CUM: 0, },
{ CDATE: '2025-07-25', PHASE: 'Management',    GROUP: 'Management',     SUBCONT: 'HHI',          TOTAL: 7171,    P_WK: 200,    A_WK: 0,      D_WK: 0,    P_CUM: 3957,    A_CUM: 2637,    D_CUM: 0, },
{ CDATE: '2025-08-29', PHASE: 'Management',    GROUP: 'Management',     SUBCONT: 'HHI',          TOTAL: 7171,    P_WK: 250,    A_WK: 0,      D_WK: 0,    P_CUM: 4207,    A_CUM: 2637,    D_CUM: 0, },
{ CDATE: '2025-09-26', PHASE: 'Management',    GROUP: 'Management',     SUBCONT: 'HHI',          TOTAL: 7171,    P_WK: 200,    A_WK: 0,      D_WK: 0,    P_CUM: 4407,    A_CUM: 2637,    D_CUM: 0, },
{ CDATE: '2025-10-31', PHASE: 'Management',    GROUP: 'Management',     SUBCONT: 'HHI',          TOTAL: 7171,    P_WK: 250,    A_WK: 0,      D_WK: 0,    P_CUM: 4657,    A_CUM: 2637,    D_CUM: 0, },
{ CDATE: '2025-11-28', PHASE: 'Management',    GROUP: 'Management',     SUBCONT: 'HHI',          TOTAL: 7171,    P_WK: 200,    A_WK: 0,      D_WK: 0,    P_CUM: 4857,    A_CUM: 2637,    D_CUM: 0, },
{ CDATE: '2025-12-26', PHASE: 'Management',    GROUP: 'Management',     SUBCONT: 'HHI',          TOTAL: 7171,    P_WK: 170,    A_WK: 0,      D_WK: 0,    P_CUM: 5027,    A_CUM: 2637,    D_CUM: 0, },
{ CDATE: '2026-01-30', PHASE: 'Management',    GROUP: 'Management',     SUBCONT: 'HHI',          TOTAL: 7171,    P_WK: 200,    A_WK: 0,      D_WK: 0,    P_CUM: 5227,    A_CUM: 2637,    D_CUM: 0, },
{ CDATE: '2026-02-27', PHASE: 'Management',    GROUP: 'Management',     SUBCONT: 'HHI',          TOTAL: 7171,    P_WK: 160,    A_WK: 0,      D_WK: 0,    P_CUM: 5387,    A_CUM: 2637,    D_CUM: 0, },
{ CDATE: '2026-03-27', PHASE: 'Management',    GROUP: 'Management',     SUBCONT: 'HHI',          TOTAL: 7171,    P_WK: 160,    A_WK: 0,      D_WK: 0,    P_CUM: 5547,    A_CUM: 2637,    D_CUM: 0, },
{ CDATE: '2026-04-24', PHASE: 'Management',    GROUP: 'Management',     SUBCONT: 'HHI',          TOTAL: 7171,    P_WK: 160,    A_WK: 0,      D_WK: 0,    P_CUM: 5707,    A_CUM: 2637,    D_CUM: 0, },
{ CDATE: '2026-05-29', PHASE: 'Management',    GROUP: 'Management',     SUBCONT: 'HHI',          TOTAL: 7171,    P_WK: 200,    A_WK: 0,      D_WK: 0,    P_CUM: 5907,    A_CUM: 2637,    D_CUM: 0, },
{ CDATE: '2026-06-26', PHASE: 'Management',    GROUP: 'Management',     SUBCONT: 'HHI',          TOTAL: 7171,    P_WK: 160,    A_WK: 0,      D_WK: 0,    P_CUM: 6067,    A_CUM: 2637,    D_CUM: 0, },
{ CDATE: '2026-07-31', PHASE: 'Management',    GROUP: 'Management',     SUBCONT: 'HHI',          TOTAL: 7171,    P_WK: 170,    A_WK: 0,      D_WK: 0,    P_CUM: 6237,    A_CUM: 2637,    D_CUM: 0, },
{ CDATE: '2026-08-28', PHASE: 'Management',    GROUP: 'Management',     SUBCONT: 'HHI',          TOTAL: 7171,    P_WK: 120,    A_WK: 0,      D_WK: 0,    P_CUM: 6357,    A_CUM: 2637,    D_CUM: 0, },
{ CDATE: '2026-09-25', PHASE: 'Management',    GROUP: 'Management',     SUBCONT: 'HHI',          TOTAL: 7171,    P_WK: 120,    A_WK: 0,      D_WK: 0,    P_CUM: 6477,    A_CUM: 2637,    D_CUM: 0, },
{ CDATE: '2026-10-30', PHASE: 'Management',    GROUP: 'Management',     SUBCONT: 'HHI',          TOTAL: 7171,    P_WK: 150,    A_WK: 0,      D_WK: 0,    P_CUM: 6627,    A_CUM: 2637,    D_CUM: 0, },
{ CDATE: '2026-11-27', PHASE: 'Management',    GROUP: 'Management',     SUBCONT: 'HHI',          TOTAL: 7171,    P_WK: 120,    A_WK: 0,      D_WK: 0,    P_CUM: 6747,    A_CUM: 2637,    D_CUM: 0, },
{ CDATE: '2026-12-25', PHASE: 'Management',    GROUP: 'Management',     SUBCONT: 'HHI',          TOTAL: 7171,    P_WK: 120,    A_WK: 0,      D_WK: 0,    P_CUM: 6867,    A_CUM: 2637,    D_CUM: 0, },
{ CDATE: '2027-01-29', PHASE: 'Management',    GROUP: 'Management',     SUBCONT: 'HHI',          TOTAL: 7171,    P_WK: 120,    A_WK: 0,      D_WK: 0,    P_CUM: 6987,    A_CUM: 2637,    D_CUM: 0, },
{ CDATE: '2027-02-26', PHASE: 'Management',    GROUP: 'Management',     SUBCONT: 'HHI',          TOTAL: 7171,    P_WK: 80,     A_WK: 0,      D_WK: 0,    P_CUM: 7067,    A_CUM: 2637,    D_CUM: 0, },
{ CDATE: '2027-03-26', PHASE: 'Management',    GROUP: 'Management',     SUBCONT: 'HHI',          TOTAL: 7171,    P_WK: 80,     A_WK: 0,      D_WK: 0,    P_CUM: 7147,    A_CUM: 2637,    D_CUM: 0, },
{ CDATE: '2027-04-30', PHASE: 'Management',    GROUP: 'Management',     SUBCONT: 'HHI',          TOTAL: 7171,    P_WK: 0,      A_WK: 0,      D_WK: 0,    P_CUM: 7147,    A_CUM: 2637,    D_CUM: 0, },
{ CDATE: '2027-05-28', PHASE: 'Management',    GROUP: 'Management',     SUBCONT: 'HHI',          TOTAL: 7171,    P_WK: 24,     A_WK: 0,      D_WK: 0,    P_CUM: 7171,    A_CUM: 2637,    D_CUM: 0, },
{ CDATE: '2027-06-11', PHASE: 'Management',    GROUP: 'Management',     SUBCONT: 'HHI',          TOTAL: 7171,    P_WK: 0,      A_WK: 0,      D_WK: 0,    P_CUM: 7171,    A_CUM: 2637,    D_CUM: 0, },
{ CDATE: '2023-07-28', PHASE: 'Management',    GROUP: 'Management',     SUBCONT: 'OVERALL',      TOTAL: 7171,    P_WK: 51,     A_WK: 36,     D_WK: -15,  P_CUM: 51,      A_CUM: 36,      D_CUM: -15, },
{ CDATE: '2023-08-25', PHASE: 'Management',    GROUP: 'Management',     SUBCONT: 'OVERALL',      TOTAL: 7171,    P_WK: 68,     A_WK: 56,     D_WK: -12,  P_CUM: 119,     A_CUM: 92,      D_CUM: -27, },
{ CDATE: '2023-09-29', PHASE: 'Management',    GROUP: 'Management',     SUBCONT: 'OVERALL',      TOTAL: 7171,    P_WK: 109,    A_WK: 96,     D_WK: -13,  P_CUM: 228,     A_CUM: 188,     D_CUM: -40, },
{ CDATE: '2023-10-27', PHASE: 'Management',    GROUP: 'Management',     SUBCONT: 'OVERALL',      TOTAL: 7171,    P_WK: 100,    A_WK: 102,    D_WK: 2,    P_CUM: 328,     A_CUM: 290,     D_CUM: -38, },
{ CDATE: '2023-11-24', PHASE: 'Management',    GROUP: 'Management',     SUBCONT: 'OVERALL',      TOTAL: 7171,    P_WK: 103,    A_WK: 104,    D_WK: 1,    P_CUM: 431,     A_CUM: 394,     D_CUM: -37, },
{ CDATE: '2023-12-29', PHASE: 'Management',    GROUP: 'Management',     SUBCONT: 'OVERALL',      TOTAL: 7171,    P_WK: 146,    A_WK: 134,    D_WK: -12,  P_CUM: 577,     A_CUM: 528,     D_CUM: -49, },
{ CDATE: '2024-01-26', PHASE: 'Management',    GROUP: 'Management',     SUBCONT: 'OVERALL',      TOTAL: 7171,    P_WK: 120,    A_WK: 135,    D_WK: 15,   P_CUM: 697,     A_CUM: 663,     D_CUM: -34, },
{ CDATE: '2024-02-23', PHASE: 'Management',    GROUP: 'Management',     SUBCONT: 'OVERALL',      TOTAL: 7171,    P_WK: 120,    A_WK: 157,    D_WK: 37,   P_CUM: 817,     A_CUM: 820,     D_CUM: 3, },
{ CDATE: '2024-03-29', PHASE: 'Management',    GROUP: 'Management',     SUBCONT: 'OVERALL',      TOTAL: 7171,    P_WK: 150,    A_WK: 208,    D_WK: 58,   P_CUM: 967,     A_CUM: 1028,    D_CUM: 61, },
{ CDATE: '2024-04-26', PHASE: 'Management',    GROUP: 'Management',     SUBCONT: 'OVERALL',      TOTAL: 7171,    P_WK: 120,    A_WK: 176,    D_WK: 56,   P_CUM: 1087,    A_CUM: 1204,    D_CUM: 117, },
{ CDATE: '2024-05-31', PHASE: 'Management',    GROUP: 'Management',     SUBCONT: 'OVERALL',      TOTAL: 7171,    P_WK: 150,    A_WK: 228,    D_WK: 78,   P_CUM: 1237,    A_CUM: 1432,    D_CUM: 195, },
{ CDATE: '2024-06-28', PHASE: 'Management',    GROUP: 'Management',     SUBCONT: 'OVERALL',      TOTAL: 7171,    P_WK: 150,    A_WK: 184,    D_WK: 34,   P_CUM: 1387,    A_CUM: 1616,    D_CUM: 229, },
{ CDATE: '2024-07-26', PHASE: 'Management',    GROUP: 'Management',     SUBCONT: 'OVERALL',      TOTAL: 7171,    P_WK: 160,    A_WK: 190,    D_WK: 30,   P_CUM: 1547,    A_CUM: 1806,    D_CUM: 259, },
{ CDATE: '2024-08-30', PHASE: 'Management',    GROUP: 'Management',     SUBCONT: 'OVERALL',      TOTAL: 7171,    P_WK: 200,    A_WK: 201,    D_WK: 1,    P_CUM: 1747,    A_CUM: 2007,    D_CUM: 260, },
{ CDATE: '2024-09-27', PHASE: 'Management',    GROUP: 'Management',     SUBCONT: 'OVERALL',      TOTAL: 7171,    P_WK: 160,    A_WK: 187,    D_WK: 27,   P_CUM: 1907,    A_CUM: 2194,    D_CUM: 287, },
{ CDATE: '2024-10-25', PHASE: 'Management',    GROUP: 'Management',     SUBCONT: 'OVERALL',      TOTAL: 7171,    P_WK: 160,    A_WK: 193,    D_WK: 33,   P_CUM: 2067,    A_CUM: 2387,    D_CUM: 320, },
{ CDATE: '2024-11-29', PHASE: 'Management',    GROUP: 'Management',     SUBCONT: 'OVERALL',      TOTAL: 7171,    P_WK: 200,    A_WK: 250,    D_WK: 50,   P_CUM: 2267,    A_CUM: 2637,    D_CUM: 370, },
{ CDATE: '2024-12-27', PHASE: 'Management',    GROUP: 'Management',     SUBCONT: 'OVERALL',      TOTAL: 7171,    P_WK: 190,    A_WK: 0,      D_WK: 0,    P_CUM: 2457,    A_CUM: 2637,    D_CUM: 0, },
{ CDATE: '2025-01-31', PHASE: 'Management',    GROUP: 'Management',     SUBCONT: 'OVERALL',      TOTAL: 7171,    P_WK: 250,    A_WK: 0,      D_WK: 0,    P_CUM: 2707,    A_CUM: 2637,    D_CUM: 0, },
{ CDATE: '2025-02-28', PHASE: 'Management',    GROUP: 'Management',     SUBCONT: 'OVERALL',      TOTAL: 7171,    P_WK: 200,    A_WK: 0,      D_WK: 0,    P_CUM: 2907,    A_CUM: 2637,    D_CUM: 0, },
{ CDATE: '2025-03-28', PHASE: 'Management',    GROUP: 'Management',     SUBCONT: 'OVERALL',      TOTAL: 7171,    P_WK: 200,    A_WK: 0,      D_WK: 0,    P_CUM: 3107,    A_CUM: 2637,    D_CUM: 0, },
{ CDATE: '2025-04-25', PHASE: 'Management',    GROUP: 'Management',     SUBCONT: 'OVERALL',      TOTAL: 7171,    P_WK: 200,    A_WK: 0,      D_WK: 0,    P_CUM: 3307,    A_CUM: 2637,    D_CUM: 0, },
{ CDATE: '2025-05-30', PHASE: 'Management',    GROUP: 'Management',     SUBCONT: 'OVERALL',      TOTAL: 7171,    P_WK: 250,    A_WK: 0,      D_WK: 0,    P_CUM: 3557,    A_CUM: 2637,    D_CUM: 0, },
{ CDATE: '2025-06-27', PHASE: 'Management',    GROUP: 'Management',     SUBCONT: 'OVERALL',      TOTAL: 7171,    P_WK: 200,    A_WK: 0,      D_WK: 0,    P_CUM: 3757,    A_CUM: 2637,    D_CUM: 0, },
{ CDATE: '2025-07-25', PHASE: 'Management',    GROUP: 'Management',     SUBCONT: 'OVERALL',      TOTAL: 7171,    P_WK: 200,    A_WK: 0,      D_WK: 0,    P_CUM: 3957,    A_CUM: 2637,    D_CUM: 0, },
{ CDATE: '2025-08-29', PHASE: 'Management',    GROUP: 'Management',     SUBCONT: 'OVERALL',      TOTAL: 7171,    P_WK: 250,    A_WK: 0,      D_WK: 0,    P_CUM: 4207,    A_CUM: 2637,    D_CUM: 0, },
{ CDATE: '2025-09-26', PHASE: 'Management',    GROUP: 'Management',     SUBCONT: 'OVERALL',      TOTAL: 7171,    P_WK: 200,    A_WK: 0,      D_WK: 0,    P_CUM: 4407,    A_CUM: 2637,    D_CUM: 0, },
{ CDATE: '2025-10-31', PHASE: 'Management',    GROUP: 'Management',     SUBCONT: 'OVERALL',      TOTAL: 7171,    P_WK: 250,    A_WK: 0,      D_WK: 0,    P_CUM: 4657,    A_CUM: 2637,    D_CUM: 0, },
{ CDATE: '2025-11-28', PHASE: 'Management',    GROUP: 'Management',     SUBCONT: 'OVERALL',      TOTAL: 7171,    P_WK: 200,    A_WK: 0,      D_WK: 0,    P_CUM: 4857,    A_CUM: 2637,    D_CUM: 0, },
{ CDATE: '2025-12-26', PHASE: 'Management',    GROUP: 'Management',     SUBCONT: 'OVERALL',      TOTAL: 7171,    P_WK: 170,    A_WK: 0,      D_WK: 0,    P_CUM: 5027,    A_CUM: 2637,    D_CUM: 0, },
{ CDATE: '2026-01-30', PHASE: 'Management',    GROUP: 'Management',     SUBCONT: 'OVERALL',      TOTAL: 7171,    P_WK: 200,    A_WK: 0,      D_WK: 0,    P_CUM: 5227,    A_CUM: 2637,    D_CUM: 0, },
{ CDATE: '2026-02-27', PHASE: 'Management',    GROUP: 'Management',     SUBCONT: 'OVERALL',      TOTAL: 7171,    P_WK: 160,    A_WK: 0,      D_WK: 0,    P_CUM: 5387,    A_CUM: 2637,    D_CUM: 0, },
{ CDATE: '2026-03-27', PHASE: 'Management',    GROUP: 'Management',     SUBCONT: 'OVERALL',      TOTAL: 7171,    P_WK: 160,    A_WK: 0,      D_WK: 0,    P_CUM: 5547,    A_CUM: 2637,    D_CUM: 0, },
{ CDATE: '2026-04-24', PHASE: 'Management',    GROUP: 'Management',     SUBCONT: 'OVERALL',      TOTAL: 7171,    P_WK: 160,    A_WK: 0,      D_WK: 0,    P_CUM: 5707,    A_CUM: 2637,    D_CUM: 0, },
{ CDATE: '2026-05-29', PHASE: 'Management',    GROUP: 'Management',     SUBCONT: 'OVERALL',      TOTAL: 7171,    P_WK: 200,    A_WK: 0,      D_WK: 0,    P_CUM: 5907,    A_CUM: 2637,    D_CUM: 0, },
{ CDATE: '2026-06-26', PHASE: 'Management',    GROUP: 'Management',     SUBCONT: 'OVERALL',      TOTAL: 7171,    P_WK: 160,    A_WK: 0,      D_WK: 0,    P_CUM: 6067,    A_CUM: 2637,    D_CUM: 0, },
{ CDATE: '2026-07-31', PHASE: 'Management',    GROUP: 'Management',     SUBCONT: 'OVERALL',      TOTAL: 7171,    P_WK: 170,    A_WK: 0,      D_WK: 0,    P_CUM: 6237,    A_CUM: 2637,    D_CUM: 0, },
{ CDATE: '2026-08-28', PHASE: 'Management',    GROUP: 'Management',     SUBCONT: 'OVERALL',      TOTAL: 7171,    P_WK: 120,    A_WK: 0,      D_WK: 0,    P_CUM: 6357,    A_CUM: 2637,    D_CUM: 0, },
{ CDATE: '2026-09-25', PHASE: 'Management',    GROUP: 'Management',     SUBCONT: 'OVERALL',      TOTAL: 7171,    P_WK: 120,    A_WK: 0,      D_WK: 0,    P_CUM: 6477,    A_CUM: 2637,    D_CUM: 0, },
{ CDATE: '2026-10-30', PHASE: 'Management',    GROUP: 'Management',     SUBCONT: 'OVERALL',      TOTAL: 7171,    P_WK: 150,    A_WK: 0,      D_WK: 0,    P_CUM: 6627,    A_CUM: 2637,    D_CUM: 0, },
{ CDATE: '2026-11-27', PHASE: 'Management',    GROUP: 'Management',     SUBCONT: 'OVERALL',      TOTAL: 7171,    P_WK: 120,    A_WK: 0,      D_WK: 0,    P_CUM: 6747,    A_CUM: 2637,    D_CUM: 0, },
{ CDATE: '2026-12-25', PHASE: 'Management',    GROUP: 'Management',     SUBCONT: 'OVERALL',      TOTAL: 7171,    P_WK: 120,    A_WK: 0,      D_WK: 0,    P_CUM: 6867,    A_CUM: 2637,    D_CUM: 0, },
{ CDATE: '2027-01-29', PHASE: 'Management',    GROUP: 'Management',     SUBCONT: 'OVERALL',      TOTAL: 7171,    P_WK: 120,    A_WK: 0,      D_WK: 0,    P_CUM: 6987,    A_CUM: 2637,    D_CUM: 0, },
{ CDATE: '2027-02-26', PHASE: 'Management',    GROUP: 'Management',     SUBCONT: 'OVERALL',      TOTAL: 7171,    P_WK: 80,     A_WK: 0,      D_WK: 0,    P_CUM: 7067,    A_CUM: 2637,    D_CUM: 0, },
{ CDATE: '2027-03-26', PHASE: 'Management',    GROUP: 'Management',     SUBCONT: 'OVERALL',      TOTAL: 7171,    P_WK: 80,     A_WK: 0,      D_WK: 0,    P_CUM: 7147,    A_CUM: 2637,    D_CUM: 0, },
{ CDATE: '2027-04-30', PHASE: 'Management',    GROUP: 'Management',     SUBCONT: 'OVERALL',      TOTAL: 7171,    P_WK: 0,      A_WK: 0,      D_WK: 0,    P_CUM: 7147,    A_CUM: 2637,    D_CUM: 0, },
{ CDATE: '2027-05-28', PHASE: 'Management',    GROUP: 'Management',     SUBCONT: 'OVERALL',      TOTAL: 7171,    P_WK: 24,     A_WK: 0,      D_WK: 0,    P_CUM: 7171,    A_CUM: 2637,    D_CUM: 0, },
{ CDATE: '2027-06-11', PHASE: 'Management',    GROUP: 'Management',     SUBCONT: 'OVERALL',      TOTAL: 7171,    P_WK: 0,      A_WK: 0,      D_WK: 0,    P_CUM: 7171,    A_CUM: 2637,    D_CUM: 0, },
{ CDATE: '2023-07-28', PHASE: 'Management',    GROUP: 'OVERALL',        SUBCONT: 'OVERALL',      TOTAL: 7171,    P_WK: 51,     A_WK: 36,     D_WK: -15,  P_CUM: 51,      A_CUM: 36,      D_CUM: -15, },
{ CDATE: '2023-08-25', PHASE: 'Management',    GROUP: 'OVERALL',        SUBCONT: 'OVERALL',      TOTAL: 7171,    P_WK: 68,     A_WK: 56,     D_WK: -12,  P_CUM: 119,     A_CUM: 92,      D_CUM: -27, },
{ CDATE: '2023-09-29', PHASE: 'Management',    GROUP: 'OVERALL',        SUBCONT: 'OVERALL',      TOTAL: 7171,    P_WK: 109,    A_WK: 96,     D_WK: -13,  P_CUM: 228,     A_CUM: 188,     D_CUM: -40, },
{ CDATE: '2023-10-27', PHASE: 'Management',    GROUP: 'OVERALL',        SUBCONT: 'OVERALL',      TOTAL: 7171,    P_WK: 100,    A_WK: 102,    D_WK: 2,    P_CUM: 328,     A_CUM: 290,     D_CUM: -38, },
{ CDATE: '2023-11-24', PHASE: 'Management',    GROUP: 'OVERALL',        SUBCONT: 'OVERALL',      TOTAL: 7171,    P_WK: 103,    A_WK: 104,    D_WK: 1,    P_CUM: 431,     A_CUM: 394,     D_CUM: -37, },
{ CDATE: '2023-12-29', PHASE: 'Management',    GROUP: 'OVERALL',        SUBCONT: 'OVERALL',      TOTAL: 7171,    P_WK: 146,    A_WK: 134,    D_WK: -12,  P_CUM: 577,     A_CUM: 528,     D_CUM: -49, },
{ CDATE: '2024-01-26', PHASE: 'Management',    GROUP: 'OVERALL',        SUBCONT: 'OVERALL',      TOTAL: 7171,    P_WK: 120,    A_WK: 135,    D_WK: 15,   P_CUM: 697,     A_CUM: 663,     D_CUM: -34, },
{ CDATE: '2024-02-23', PHASE: 'Management',    GROUP: 'OVERALL',        SUBCONT: 'OVERALL',      TOTAL: 7171,    P_WK: 120,    A_WK: 157,    D_WK: 37,   P_CUM: 817,     A_CUM: 820,     D_CUM: 3, },
{ CDATE: '2024-03-29', PHASE: 'Management',    GROUP: 'OVERALL',        SUBCONT: 'OVERALL',      TOTAL: 7171,    P_WK: 150,    A_WK: 208,    D_WK: 58,   P_CUM: 967,     A_CUM: 1028,    D_CUM: 61, },
{ CDATE: '2024-04-26', PHASE: 'Management',    GROUP: 'OVERALL',        SUBCONT: 'OVERALL',      TOTAL: 7171,    P_WK: 120,    A_WK: 176,    D_WK: 56,   P_CUM: 1087,    A_CUM: 1204,    D_CUM: 117, },
{ CDATE: '2024-05-31', PHASE: 'Management',    GROUP: 'OVERALL',        SUBCONT: 'OVERALL',      TOTAL: 7171,    P_WK: 150,    A_WK: 228,    D_WK: 78,   P_CUM: 1237,    A_CUM: 1432,    D_CUM: 195, },
{ CDATE: '2024-06-28', PHASE: 'Management',    GROUP: 'OVERALL',        SUBCONT: 'OVERALL',      TOTAL: 7171,    P_WK: 150,    A_WK: 184,    D_WK: 34,   P_CUM: 1387,    A_CUM: 1616,    D_CUM: 229, },
{ CDATE: '2024-07-26', PHASE: 'Management',    GROUP: 'OVERALL',        SUBCONT: 'OVERALL',      TOTAL: 7171,    P_WK: 160,    A_WK: 190,    D_WK: 30,   P_CUM: 1547,    A_CUM: 1806,    D_CUM: 259, },
{ CDATE: '2024-08-30', PHASE: 'Management',    GROUP: 'OVERALL',        SUBCONT: 'OVERALL',      TOTAL: 7171,    P_WK: 200,    A_WK: 201,    D_WK: 1,    P_CUM: 1747,    A_CUM: 2007,    D_CUM: 260, },
{ CDATE: '2024-09-27', PHASE: 'Management',    GROUP: 'OVERALL',        SUBCONT: 'OVERALL',      TOTAL: 7171,    P_WK: 160,    A_WK: 187,    D_WK: 27,   P_CUM: 1907,    A_CUM: 2194,    D_CUM: 287, },
{ CDATE: '2024-10-25', PHASE: 'Management',    GROUP: 'OVERALL',        SUBCONT: 'OVERALL',      TOTAL: 7171,    P_WK: 160,    A_WK: 193,    D_WK: 33,   P_CUM: 2067,    A_CUM: 2387,    D_CUM: 320, },
{ CDATE: '2024-11-29', PHASE: 'Management',    GROUP: 'OVERALL',        SUBCONT: 'OVERALL',      TOTAL: 7171,    P_WK: 200,    A_WK: 250,    D_WK: 50,   P_CUM: 2267,    A_CUM: 2637,    D_CUM: 370, },
{ CDATE: '2024-12-27', PHASE: 'Management',    GROUP: 'OVERALL',        SUBCONT: 'OVERALL',      TOTAL: 7171,    P_WK: 190,    A_WK: 0,      D_WK: 0,    P_CUM: 2457,    A_CUM: 2637,    D_CUM: 0, },
{ CDATE: '2025-01-31', PHASE: 'Management',    GROUP: 'OVERALL',        SUBCONT: 'OVERALL',      TOTAL: 7171,    P_WK: 250,    A_WK: 0,      D_WK: 0,    P_CUM: 2707,    A_CUM: 2637,    D_CUM: 0, },
{ CDATE: '2025-02-28', PHASE: 'Management',    GROUP: 'OVERALL',        SUBCONT: 'OVERALL',      TOTAL: 7171,    P_WK: 200,    A_WK: 0,      D_WK: 0,    P_CUM: 2907,    A_CUM: 2637,    D_CUM: 0, },
{ CDATE: '2025-03-28', PHASE: 'Management',    GROUP: 'OVERALL',        SUBCONT: 'OVERALL',      TOTAL: 7171,    P_WK: 200,    A_WK: 0,      D_WK: 0,    P_CUM: 3107,    A_CUM: 2637,    D_CUM: 0, },
{ CDATE: '2025-04-25', PHASE: 'Management',    GROUP: 'OVERALL',        SUBCONT: 'OVERALL',      TOTAL: 7171,    P_WK: 200,    A_WK: 0,      D_WK: 0,    P_CUM: 3307,    A_CUM: 2637,    D_CUM: 0, },
{ CDATE: '2025-05-30', PHASE: 'Management',    GROUP: 'OVERALL',        SUBCONT: 'OVERALL',      TOTAL: 7171,    P_WK: 250,    A_WK: 0,      D_WK: 0,    P_CUM: 3557,    A_CUM: 2637,    D_CUM: 0, },
{ CDATE: '2025-06-27', PHASE: 'Management',    GROUP: 'OVERALL',        SUBCONT: 'OVERALL',      TOTAL: 7171,    P_WK: 200,    A_WK: 0,      D_WK: 0,    P_CUM: 3757,    A_CUM: 2637,    D_CUM: 0, },
{ CDATE: '2025-07-25', PHASE: 'Management',    GROUP: 'OVERALL',        SUBCONT: 'OVERALL',      TOTAL: 7171,    P_WK: 200,    A_WK: 0,      D_WK: 0,    P_CUM: 3957,    A_CUM: 2637,    D_CUM: 0, },
{ CDATE: '2025-08-29', PHASE: 'Management',    GROUP: 'OVERALL',        SUBCONT: 'OVERALL',      TOTAL: 7171,    P_WK: 250,    A_WK: 0,      D_WK: 0,    P_CUM: 4207,    A_CUM: 2637,    D_CUM: 0, },
{ CDATE: '2025-09-26', PHASE: 'Management',    GROUP: 'OVERALL',        SUBCONT: 'OVERALL',      TOTAL: 7171,    P_WK: 200,    A_WK: 0,      D_WK: 0,    P_CUM: 4407,    A_CUM: 2637,    D_CUM: 0, },
{ CDATE: '2025-10-31', PHASE: 'Management',    GROUP: 'OVERALL',        SUBCONT: 'OVERALL',      TOTAL: 7171,    P_WK: 250,    A_WK: 0,      D_WK: 0,    P_CUM: 4657,    A_CUM: 2637,    D_CUM: 0, },
{ CDATE: '2025-11-28', PHASE: 'Management',    GROUP: 'OVERALL',        SUBCONT: 'OVERALL',      TOTAL: 7171,    P_WK: 200,    A_WK: 0,      D_WK: 0,    P_CUM: 4857,    A_CUM: 2637,    D_CUM: 0, },
{ CDATE: '2025-12-26', PHASE: 'Management',    GROUP: 'OVERALL',        SUBCONT: 'OVERALL',      TOTAL: 7171,    P_WK: 170,    A_WK: 0,      D_WK: 0,    P_CUM: 5027,    A_CUM: 2637,    D_CUM: 0, },
{ CDATE: '2026-01-30', PHASE: 'Management',    GROUP: 'OVERALL',        SUBCONT: 'OVERALL',      TOTAL: 7171,    P_WK: 200,    A_WK: 0,      D_WK: 0,    P_CUM: 5227,    A_CUM: 2637,    D_CUM: 0, },
{ CDATE: '2026-02-27', PHASE: 'Management',    GROUP: 'OVERALL',        SUBCONT: 'OVERALL',      TOTAL: 7171,    P_WK: 160,    A_WK: 0,      D_WK: 0,    P_CUM: 5387,    A_CUM: 2637,    D_CUM: 0, },
{ CDATE: '2026-03-27', PHASE: 'Management',    GROUP: 'OVERALL',        SUBCONT: 'OVERALL',      TOTAL: 7171,    P_WK: 160,    A_WK: 0,      D_WK: 0,    P_CUM: 5547,    A_CUM: 2637,    D_CUM: 0, },
{ CDATE: '2026-04-24', PHASE: 'Management',    GROUP: 'OVERALL',        SUBCONT: 'OVERALL',      TOTAL: 7171,    P_WK: 160,    A_WK: 0,      D_WK: 0,    P_CUM: 5707,    A_CUM: 2637,    D_CUM: 0, },
{ CDATE: '2026-05-29', PHASE: 'Management',    GROUP: 'OVERALL',        SUBCONT: 'OVERALL',      TOTAL: 7171,    P_WK: 200,    A_WK: 0,      D_WK: 0,    P_CUM: 5907,    A_CUM: 2637,    D_CUM: 0, },
{ CDATE: '2026-06-26', PHASE: 'Management',    GROUP: 'OVERALL',        SUBCONT: 'OVERALL',      TOTAL: 7171,    P_WK: 160,    A_WK: 0,      D_WK: 0,    P_CUM: 6067,    A_CUM: 2637,    D_CUM: 0, },
{ CDATE: '2026-07-31', PHASE: 'Management',    GROUP: 'OVERALL',        SUBCONT: 'OVERALL',      TOTAL: 7171,    P_WK: 170,    A_WK: 0,      D_WK: 0,    P_CUM: 6237,    A_CUM: 2637,    D_CUM: 0, },
{ CDATE: '2026-08-28', PHASE: 'Management',    GROUP: 'OVERALL',        SUBCONT: 'OVERALL',      TOTAL: 7171,    P_WK: 120,    A_WK: 0,      D_WK: 0,    P_CUM: 6357,    A_CUM: 2637,    D_CUM: 0, },
{ CDATE: '2026-09-25', PHASE: 'Management',    GROUP: 'OVERALL',        SUBCONT: 'OVERALL',      TOTAL: 7171,    P_WK: 120,    A_WK: 0,      D_WK: 0,    P_CUM: 6477,    A_CUM: 2637,    D_CUM: 0, },
{ CDATE: '2026-10-30', PHASE: 'Management',    GROUP: 'OVERALL',        SUBCONT: 'OVERALL',      TOTAL: 7171,    P_WK: 150,    A_WK: 0,      D_WK: 0,    P_CUM: 6627,    A_CUM: 2637,    D_CUM: 0, },
{ CDATE: '2026-11-27', PHASE: 'Management',    GROUP: 'OVERALL',        SUBCONT: 'OVERALL',      TOTAL: 7171,    P_WK: 120,    A_WK: 0,      D_WK: 0,    P_CUM: 6747,    A_CUM: 2637,    D_CUM: 0, },
{ CDATE: '2026-12-25', PHASE: 'Management',    GROUP: 'OVERALL',        SUBCONT: 'OVERALL',      TOTAL: 7171,    P_WK: 120,    A_WK: 0,      D_WK: 0,    P_CUM: 6867,    A_CUM: 2637,    D_CUM: 0, },
{ CDATE: '2027-01-29', PHASE: 'Management',    GROUP: 'OVERALL',        SUBCONT: 'OVERALL',      TOTAL: 7171,    P_WK: 120,    A_WK: 0,      D_WK: 0,    P_CUM: 6987,    A_CUM: 2637,    D_CUM: 0, },
{ CDATE: '2027-02-26', PHASE: 'Management',    GROUP: 'OVERALL',        SUBCONT: 'OVERALL',      TOTAL: 7171,    P_WK: 80,     A_WK: 0,      D_WK: 0,    P_CUM: 7067,    A_CUM: 2637,    D_CUM: 0, },
{ CDATE: '2027-03-26', PHASE: 'Management',    GROUP: 'OVERALL',        SUBCONT: 'OVERALL',      TOTAL: 7171,    P_WK: 80,     A_WK: 0,      D_WK: 0,    P_CUM: 7147,    A_CUM: 2637,    D_CUM: 0, },
{ CDATE: '2027-04-30', PHASE: 'Management',    GROUP: 'OVERALL',        SUBCONT: 'OVERALL',      TOTAL: 7171,    P_WK: 0,      A_WK: 0,      D_WK: 0,    P_CUM: 7147,    A_CUM: 2637,    D_CUM: 0, },
{ CDATE: '2027-05-28', PHASE: 'Management',    GROUP: 'OVERALL',        SUBCONT: 'OVERALL',      TOTAL: 7171,    P_WK: 24,     A_WK: 0,      D_WK: 0,    P_CUM: 7171,    A_CUM: 2637,    D_CUM: 0, },
{ CDATE: '2027-06-11', PHASE: 'Management',    GROUP: 'OVERALL',        SUBCONT: 'OVERALL',      TOTAL: 7171,    P_WK: 0,      A_WK: 0,      D_WK: 0,    P_CUM: 7171,    A_CUM: 2637,    D_CUM: 0, },
{ CDATE: '2023-07-28', PHASE: 'OVERALL',       GROUP: 'OVERALL',        SUBCONT: 'OVERALL',      TOTAL: 153744.8, P_WK: 699.3,  A_WK: 619.2,  D_WK: -80,  P_CUM: 699.3,   A_CUM: 619.2,   D_CUM: -80, },
{ CDATE: '2023-08-25', PHASE: 'OVERALL',       GROUP: 'OVERALL',        SUBCONT: 'OVERALL',      TOTAL: 153744.8, P_WK: 935,    A_WK: 811.9,  D_WK: -123, P_CUM: 1634.3,  A_CUM: 1431.1,  D_CUM: -203, },
{ CDATE: '2023-09-29', PHASE: 'OVERALL',       GROUP: 'OVERALL',        SUBCONT: 'OVERALL',      TOTAL: 153744.8, P_WK: 1633.4, A_WK: 1291.6, D_WK: -342, P_CUM: 3267.7,  A_CUM: 2722.7,  D_CUM: -545, },
{ CDATE: '2023-10-27', PHASE: 'OVERALL',       GROUP: 'OVERALL',        SUBCONT: 'OVERALL',      TOTAL: 153744.8, P_WK: 1430.6, A_WK: 1183.6, D_WK: -247, P_CUM: 4698.3,  A_CUM: 3906.3,  D_CUM: -792, },
{ CDATE: '2023-11-24', PHASE: 'OVERALL',       GROUP: 'OVERALL',        SUBCONT: 'OVERALL',      TOTAL: 153744.8, P_WK: 1475.2, A_WK: 1202.4, D_WK: -273, P_CUM: 6173.5,  A_CUM: 5108.7,  D_CUM: -1065, },
{ CDATE: '2023-12-29', PHASE: 'OVERALL',       GROUP: 'OVERALL',        SUBCONT: 'OVERALL',      TOTAL: 153744.8, P_WK: 1933.7, A_WK: 1588.5, D_WK: -345, P_CUM: 8107.2,  A_CUM: 6697.2,  D_CUM: -1410, },
{ CDATE: '2024-01-26', PHASE: 'OVERALL',       GROUP: 'OVERALL',        SUBCONT: 'OVERALL',      TOTAL: 153744.8, P_WK: 1699.7, A_WK: 1488.1, D_WK: -212, P_CUM: 9806.9,  A_CUM: 8185.3,  D_CUM: -1622, },
{ CDATE: '2024-02-23', PHASE: 'OVERALL',       GROUP: 'OVERALL',        SUBCONT: 'OVERALL',      TOTAL: 153744.8, P_WK: 1722.7, A_WK: 1758.4, D_WK: 36,   P_CUM: 11529.6, A_CUM: 9943.7,  D_CUM: -1586, },
{ CDATE: '2024-03-29', PHASE: 'OVERALL',       GROUP: 'OVERALL',        SUBCONT: 'OVERALL',      TOTAL: 153744.8, P_WK: 2146,   A_WK: 2237,   D_WK: 91,   P_CUM: 13675.6, A_CUM: 12180.7, D_CUM: -1495, },
{ CDATE: '2024-04-26', PHASE: 'OVERALL',       GROUP: 'OVERALL',        SUBCONT: 'OVERALL',      TOTAL: 153744.8, P_WK: 1799.8, A_WK: 1984.6, D_WK: 185,  P_CUM: 15475.4, A_CUM: 14165.3, D_CUM: -1310, },
{ CDATE: '2024-05-31', PHASE: 'OVERALL',       GROUP: 'OVERALL',        SUBCONT: 'OVERALL',      TOTAL: 153744.8, P_WK: 2241.6, A_WK: 2435.9, D_WK: 194,  P_CUM: 17717,   A_CUM: 16601.2, D_CUM: -1116, },
{ CDATE: '2024-06-28', PHASE: 'OVERALL',       GROUP: 'OVERALL',        SUBCONT: 'OVERALL',      TOTAL: 153744.8, P_WK: 1773.3, A_WK: 2082.1, D_WK: 309,  P_CUM: 19490.3, A_CUM: 18683.3, D_CUM: -807, },
{ CDATE: '2024-07-26', PHASE: 'OVERALL',       GROUP: 'OVERALL',        SUBCONT: 'OVERALL',      TOTAL: 153744.8, P_WK: 1872.3, A_WK: 2083.1, D_WK: 211,  P_CUM: 21362.6, A_CUM: 20766.4, D_CUM: -596, },
{ CDATE: '2024-08-30', PHASE: 'OVERALL',       GROUP: 'OVERALL',        SUBCONT: 'OVERALL',      TOTAL: 153744.8, P_WK: 2157.4, A_WK: 2502.7, D_WK: 345,  P_CUM: 23520,   A_CUM: 23269.1, D_CUM: -251, },
{ CDATE: '2024-09-27', PHASE: 'OVERALL',       GROUP: 'OVERALL',        SUBCONT: 'OVERALL',      TOTAL: 153744.8, P_WK: 1778.9, A_WK: 2167.7, D_WK: 389,  P_CUM: 25298.9, A_CUM: 25436.8, D_CUM: 138, },
{ CDATE: '2024-10-25', PHASE: 'OVERALL',       GROUP: 'OVERALL',        SUBCONT: 'OVERALL',      TOTAL: 153744.8, P_WK: 1642.1, A_WK: 2146.1, D_WK: 504,  P_CUM: 26941,   A_CUM: 27582.9, D_CUM: 642, },
{ CDATE: '2024-11-29', PHASE: 'OVERALL',       GROUP: 'OVERALL',        SUBCONT: 'OVERALL',      TOTAL: 153744.8, P_WK: 2667.7, A_WK: 3323.9, D_WK: 656,  P_CUM: 29608.7, A_CUM: 30906.8, D_CUM: 1298, },
{ CDATE: '2024-12-27', PHASE: 'OVERALL',       GROUP: 'OVERALL',        SUBCONT: 'OVERALL',      TOTAL: 153744.8, P_WK: 3362.4, A_WK: 0,      D_WK: 0,    P_CUM: 32971.1, A_CUM: 30906.8, D_CUM: 0, },
{ CDATE: '2025-01-31', PHASE: 'OVERALL',       GROUP: 'OVERALL',        SUBCONT: 'OVERALL',      TOTAL: 153744.8, P_WK: 6283.2, A_WK: 0,      D_WK: 0,    P_CUM: 39254.3, A_CUM: 30906.8, D_CUM: 0, },
{ CDATE: '2025-02-28', PHASE: 'OVERALL',       GROUP: 'OVERALL',        SUBCONT: 'OVERALL',      TOTAL: 153744.8, P_WK: 6429.9, A_WK: 0,      D_WK: 0,    P_CUM: 45684.2, A_CUM: 30906.8, D_CUM: 0, },
{ CDATE: '2025-03-28', PHASE: 'OVERALL',       GROUP: 'OVERALL',        SUBCONT: 'OVERALL',      TOTAL: 153744.8, P_WK: 6908.9, A_WK: 0,      D_WK: 0,    P_CUM: 52593.1, A_CUM: 30906.8, D_CUM: 0, },
{ CDATE: '2025-04-25', PHASE: 'OVERALL',       GROUP: 'OVERALL',        SUBCONT: 'OVERALL',      TOTAL: 153744.8, P_WK: 8295.8, A_WK: 0,      D_WK: 0,    P_CUM: 60888.9, A_CUM: 30906.8, D_CUM: 0, },
{ CDATE: '2025-05-30', PHASE: 'OVERALL',       GROUP: 'OVERALL',        SUBCONT: 'OVERALL',      TOTAL: 153744.8, P_WK: 11517.1, A_WK: 0,      D_WK: 0,    P_CUM: 72406,   A_CUM: 30906.8, D_CUM: 0, },
{ CDATE: '2025-06-27', PHASE: 'OVERALL',       GROUP: 'OVERALL',        SUBCONT: 'OVERALL',      TOTAL: 153744.8, P_WK: 8885.8, A_WK: 0,      D_WK: 0,    P_CUM: 81291.8, A_CUM: 30906.8, D_CUM: 0, },
{ CDATE: '2025-07-25', PHASE: 'OVERALL',       GROUP: 'OVERALL',        SUBCONT: 'OVERALL',      TOTAL: 153744.8, P_WK: 9779.7, A_WK: 0,      D_WK: 0,    P_CUM: 91071.5, A_CUM: 30906.8, D_CUM: 0, },
{ CDATE: '2025-08-29', PHASE: 'OVERALL',       GROUP: 'OVERALL',        SUBCONT: 'OVERALL',      TOTAL: 153744.8, P_WK: 8748.1, A_WK: 0,      D_WK: 0,    P_CUM: 99819.6, A_CUM: 30906.8, D_CUM: 0, },
{ CDATE: '2025-09-26', PHASE: 'OVERALL',       GROUP: 'OVERALL',        SUBCONT: 'OVERALL',      TOTAL: 153744.8, P_WK: 7344,   A_WK: 0,      D_WK: 0,    P_CUM: 107163.6, A_CUM: 30906.8, D_CUM: 0, },
{ CDATE: '2025-10-31', PHASE: 'OVERALL',       GROUP: 'OVERALL',        SUBCONT: 'OVERALL',      TOTAL: 153744.8, P_WK: 5414.7, A_WK: 0,      D_WK: 0,    P_CUM: 112578.3, A_CUM: 30906.8, D_CUM: 0, },
{ CDATE: '2025-11-28', PHASE: 'OVERALL',       GROUP: 'OVERALL',        SUBCONT: 'OVERALL',      TOTAL: 153744.8, P_WK: 5709,   A_WK: 0,      D_WK: 0,    P_CUM: 118287.3, A_CUM: 30906.8, D_CUM: 0, },
{ CDATE: '2025-12-26', PHASE: 'OVERALL',       GROUP: 'OVERALL',        SUBCONT: 'OVERALL',      TOTAL: 153744.8, P_WK: 4205.9, A_WK: 0,      D_WK: 0,    P_CUM: 122493.2, A_CUM: 30906.8, D_CUM: 0, },
{ CDATE: '2026-01-30', PHASE: 'OVERALL',       GROUP: 'OVERALL',        SUBCONT: 'OVERALL',      TOTAL: 153744.8, P_WK: 4879.5, A_WK: 0,      D_WK: 0,    P_CUM: 127372.7, A_CUM: 30906.8, D_CUM: 0, },
{ CDATE: '2026-02-27', PHASE: 'OVERALL',       GROUP: 'OVERALL',        SUBCONT: 'OVERALL',      TOTAL: 153744.8, P_WK: 2496.1, A_WK: 0,      D_WK: 0,    P_CUM: 129868.8, A_CUM: 30906.8, D_CUM: 0, },
{ CDATE: '2026-03-27', PHASE: 'OVERALL',       GROUP: 'OVERALL',        SUBCONT: 'OVERALL',      TOTAL: 153744.8, P_WK: 1821.5, A_WK: 0,      D_WK: 0,    P_CUM: 131690.3, A_CUM: 30906.8, D_CUM: 0, },
{ CDATE: '2026-04-24', PHASE: 'OVERALL',       GROUP: 'OVERALL',        SUBCONT: 'OVERALL',      TOTAL: 153744.8, P_WK: 1527.1, A_WK: 0,      D_WK: 0,    P_CUM: 133217.4, A_CUM: 30906.8, D_CUM: 0, },
{ CDATE: '2026-05-29', PHASE: 'OVERALL',       GROUP: 'OVERALL',        SUBCONT: 'OVERALL',      TOTAL: 153744.8, P_WK: 1552.6, A_WK: 0,      D_WK: 0,    P_CUM: 134770,  A_CUM: 30906.8, D_CUM: 0, },
{ CDATE: '2026-06-26', PHASE: 'OVERALL',       GROUP: 'OVERALL',        SUBCONT: 'OVERALL',      TOTAL: 153744.8, P_WK: 2162.9, A_WK: 0,      D_WK: 0,    P_CUM: 136932.9, A_CUM: 30906.8, D_CUM: 0, },
{ CDATE: '2026-07-31', PHASE: 'OVERALL',       GROUP: 'OVERALL',        SUBCONT: 'OVERALL',      TOTAL: 153744.8, P_WK: 3016.6, A_WK: 0,      D_WK: 0,    P_CUM: 139949.5, A_CUM: 30906.8, D_CUM: 0, },
{ CDATE: '2026-08-28', PHASE: 'OVERALL',       GROUP: 'OVERALL',        SUBCONT: 'OVERALL',      TOTAL: 153744.8, P_WK: 2823.4, A_WK: 0,      D_WK: 0,    P_CUM: 142772.9, A_CUM: 30906.8, D_CUM: 0, },
{ CDATE: '2026-09-25', PHASE: 'OVERALL',       GROUP: 'OVERALL',        SUBCONT: 'OVERALL',      TOTAL: 153744.8, P_WK: 2888.7, A_WK: 0,      D_WK: 0,    P_CUM: 145661.6, A_CUM: 30906.8, D_CUM: 0, },
{ CDATE: '2026-10-30', PHASE: 'OVERALL',       GROUP: 'OVERALL',        SUBCONT: 'OVERALL',      TOTAL: 153744.8, P_WK: 2906.1, A_WK: 0,      D_WK: 0,    P_CUM: 148567.7, A_CUM: 30906.8, D_CUM: 0, },
{ CDATE: '2026-11-27', PHASE: 'OVERALL',       GROUP: 'OVERALL',        SUBCONT: 'OVERALL',      TOTAL: 153744.8, P_WK: 1813.6, A_WK: 0,      D_WK: 0,    P_CUM: 150381.3, A_CUM: 30906.8, D_CUM: 0, },
{ CDATE: '2026-12-25', PHASE: 'OVERALL',       GROUP: 'OVERALL',        SUBCONT: 'OVERALL',      TOTAL: 153744.8, P_WK: 1511.7, A_WK: 0,      D_WK: 0,    P_CUM: 151893,  A_CUM: 30906.8, D_CUM: 0, },
{ CDATE: '2027-01-29', PHASE: 'OVERALL',       GROUP: 'OVERALL',        SUBCONT: 'OVERALL',      TOTAL: 153744.8, P_WK: 765.1,  A_WK: 0,      D_WK: 0,    P_CUM: 152658.1, A_CUM: 30906.8, D_CUM: 0, },
{ CDATE: '2027-02-26', PHASE: 'OVERALL',       GROUP: 'OVERALL',        SUBCONT: 'OVERALL',      TOTAL: 153744.8, P_WK: 555.6,  A_WK: 0,      D_WK: 0,    P_CUM: 153213.7, A_CUM: 30906.8, D_CUM: 0, },
{ CDATE: '2027-03-26', PHASE: 'OVERALL',       GROUP: 'OVERALL',        SUBCONT: 'OVERALL',      TOTAL: 153744.8, P_WK: 405.9,  A_WK: 0,      D_WK: 0,    P_CUM: 153619.6, A_CUM: 30906.8, D_CUM: 0, },
{ CDATE: '2027-04-30', PHASE: 'OVERALL',       GROUP: 'OVERALL',        SUBCONT: 'OVERALL',      TOTAL: 153744.8, P_WK: 101.4,  A_WK: 0,      D_WK: 0,    P_CUM: 153721,  A_CUM: 30906.8, D_CUM: 0, },
{ CDATE: '2027-05-28', PHASE: 'OVERALL',       GROUP: 'OVERALL',        SUBCONT: 'OVERALL',      TOTAL: 153744.8, P_WK: 24,     A_WK: 0,      D_WK: 0,    P_CUM: 153745,  A_CUM: 30906.8, D_CUM: 0, },
{ CDATE: '2027-06-11', PHASE: 'OVERALL',       GROUP: 'OVERALL',        SUBCONT: 'OVERALL',      TOTAL: 153744.8, P_WK: 0,      A_WK: 0,      D_WK: 0,    P_CUM: 153745,  A_CUM: 30906.8, D_CUM: 0, },

      ],


    Queries: {
      SQL1: [
        {
          // Timeline
          CUTOFF      : '2024-11-30',
          START_DATE  : '2023-07-28',
          END_DATE    : '2027-06-11',
        },
      ],

    },


}