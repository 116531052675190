import __C from '../../../../../../includes/primitives/_constant_'

export default {
  Canvas: {
    CanvasWidth: 1400,
    CanvasHeight: 650, //previous value : 570
    CanvasBorderWeight: .5,
    CanvasBorderColor: '#E0E0DF',
    CanvasFillColor: '#fff',
    CanvasOpacity: 1,

    CanvasChartX: 0,
    CanvasChartY: 0,
    CanvasChartWidth: 400,
    CanvasChartHeight: 200,
    CanvasChartBorderWeight: 0,
    CanvasChartBorderColor: '#757575',
    CanvasChartFillColor: '#757575',
    CanvasChartOpacity: 0,
    CanvasChartSpace: 0,

    CanvasFontFamily: 'roboto',
  },
  
  Text: {
    Text: [],
    Filter: [],
    Sql: []
  },

  
  DataItems: [
    { CDATE: '2024-10-04', AREA: 'HULL',    YARD: 'COSCO', STAGE: 'ASSEMBLY', PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 0,   PF_CUM: 0,   AS_CUM: 0,  AF_CUM: 0, },
    { CDATE: '2024-10-11', AREA: 'HULL',    YARD: 'COSCO', STAGE: 'ASSEMBLY', PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 0,   PF_CUM: 0,   AS_CUM: 0,  AF_CUM: 0, },
    { CDATE: '2024-10-18', AREA: 'HULL',    YARD: 'COSCO', STAGE: 'ASSEMBLY', PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 0,   PF_CUM: 0,   AS_CUM: 0,  AF_CUM: 0, },
    { CDATE: '2024-10-25', AREA: 'HULL',    YARD: 'COSCO', STAGE: 'ASSEMBLY', PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 0,   PF_CUM: 0,   AS_CUM: 0,  AF_CUM: 0, },
    { CDATE: '2024-11-01', AREA: 'HULL',    YARD: 'COSCO', STAGE: 'ASSEMBLY', PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 0,   PF_CUM: 0,   AS_CUM: 0,  AF_CUM: 0, },
    { CDATE: '2024-11-08', AREA: 'HULL',    YARD: 'COSCO', STAGE: 'ASSEMBLY', PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 0,   PF_CUM: 0,   AS_CUM: 0,  AF_CUM: 0, },
    { CDATE: '2024-11-15', AREA: 'HULL',    YARD: 'COSCO', STAGE: 'ASSEMBLY', PS_WK: 0,  PF_WK: 0,  AS_WK: 5,  AF_WK: 0, PS_CUM: 0,   PF_CUM: 0,   AS_CUM: 5,  AF_CUM: 0, },
    { CDATE: '2024-11-22', AREA: 'HULL',    YARD: 'COSCO', STAGE: 'ASSEMBLY', PS_WK: 0,  PF_WK: 0,  AS_WK: 7,  AF_WK: 0, PS_CUM: 0,   PF_CUM: 0,   AS_CUM: 12, AF_CUM: 0, },
    { CDATE: '2024-11-29', AREA: 'HULL',    YARD: 'COSCO', STAGE: 'ASSEMBLY', PS_WK: 5,  PF_WK: 0,  AS_WK: 3,  AF_WK: 0, PS_CUM: 5,   PF_CUM: 0,   AS_CUM: 15, AF_CUM: 0, },
    { CDATE: '2024-12-06', AREA: 'HULL',    YARD: 'COSCO', STAGE: 'ASSEMBLY', PS_WK: 14, PF_WK: 0,  AS_WK: 3,  AF_WK: 0, PS_CUM: 19,  PF_CUM: 0,   AS_CUM: 18, AF_CUM: 0, },
    { CDATE: '2024-12-13', AREA: 'HULL',    YARD: 'COSCO', STAGE: 'ASSEMBLY', PS_WK: 19, PF_WK: 0,  AS_WK: 6,  AF_WK: 0, PS_CUM: 38,  PF_CUM: 0,   AS_CUM: 24, AF_CUM: 0, },
    { CDATE: '2024-12-20', AREA: 'HULL',    YARD: 'COSCO', STAGE: 'ASSEMBLY', PS_WK: 12, PF_WK: 0,  AS_WK: 8,  AF_WK: 0, PS_CUM: 50,  PF_CUM: 0,   AS_CUM: 32, AF_CUM: 0, },
    { CDATE: '2024-12-27', AREA: 'HULL',    YARD: 'COSCO', STAGE: 'ASSEMBLY', PS_WK: 0,  PF_WK: 0,  AS_WK: 10, AF_WK: 0, PS_CUM: 50,  PF_CUM: 0,   AS_CUM: 42, AF_CUM: 0, },
    { CDATE: '2025-01-03', AREA: 'HULL',    YARD: 'COSCO', STAGE: 'ASSEMBLY', PS_WK: 0,  PF_WK: 0,  AS_WK: 6,  AF_WK: 0, PS_CUM: 50,  PF_CUM: 0,   AS_CUM: 48, AF_CUM: 0, },
    { CDATE: '2025-01-10', AREA: 'HULL',    YARD: 'COSCO', STAGE: 'ASSEMBLY', PS_WK: 0,  PF_WK: 0,  AS_WK: 2,  AF_WK: 0, PS_CUM: 50,  PF_CUM: 0,   AS_CUM: 50, AF_CUM: 0, },
    { CDATE: '2025-01-17', AREA: 'HULL',    YARD: 'COSCO', STAGE: 'ASSEMBLY', PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 50,  PF_CUM: 0,   AS_CUM: 50, AF_CUM: 0, },
    { CDATE: '2025-01-24', AREA: 'HULL',    YARD: 'COSCO', STAGE: 'ASSEMBLY', PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 50,  PF_CUM: 0,   AS_CUM: 50, AF_CUM: 0, },
    { CDATE: '2025-01-31', AREA: 'HULL',    YARD: 'COSCO', STAGE: 'ASSEMBLY', PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 50,  PF_CUM: 0,   AS_CUM: 50, AF_CUM: 0, },
    { CDATE: '2025-02-07', AREA: 'HULL',    YARD: 'COSCO', STAGE: 'ASSEMBLY', PS_WK: 1,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 51,  PF_CUM: 0,   AS_CUM: 50, AF_CUM: 0, },
    { CDATE: '2025-02-14', AREA: 'HULL',    YARD: 'COSCO', STAGE: 'ASSEMBLY', PS_WK: 7,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 58,  PF_CUM: 0,   AS_CUM: 50, AF_CUM: 0, },
    { CDATE: '2025-02-21', AREA: 'HULL',    YARD: 'COSCO', STAGE: 'ASSEMBLY', PS_WK: 7,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 65,  PF_CUM: 0,   AS_CUM: 50, AF_CUM: 0, },
    { CDATE: '2025-02-28', AREA: 'HULL',    YARD: 'COSCO', STAGE: 'ASSEMBLY', PS_WK: 3,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 68,  PF_CUM: 0,   AS_CUM: 50, AF_CUM: 0, },
    { CDATE: '2025-03-07', AREA: 'HULL',    YARD: 'COSCO', STAGE: 'ASSEMBLY', PS_WK: 8,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 76,  PF_CUM: 0,   AS_CUM: 50, AF_CUM: 0, },
    { CDATE: '2025-03-14', AREA: 'HULL',    YARD: 'COSCO', STAGE: 'ASSEMBLY', PS_WK: 6,  PF_WK: 6,  AS_WK: 0,  AF_WK: 0, PS_CUM: 82,  PF_CUM: 6,   AS_CUM: 50, AF_CUM: 0, },
    { CDATE: '2025-03-21', AREA: 'HULL',    YARD: 'COSCO', STAGE: 'ASSEMBLY', PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 82,  PF_CUM: 6,   AS_CUM: 50, AF_CUM: 0, },
    { CDATE: '2025-03-28', AREA: 'HULL',    YARD: 'COSCO', STAGE: 'ASSEMBLY', PS_WK: 0,  PF_WK: 5,  AS_WK: 0,  AF_WK: 0, PS_CUM: 82,  PF_CUM: 11,  AS_CUM: 50, AF_CUM: 0, },
    { CDATE: '2025-04-04', AREA: 'HULL',    YARD: 'COSCO', STAGE: 'ASSEMBLY', PS_WK: 0,  PF_WK: 13, AS_WK: 0,  AF_WK: 0, PS_CUM: 82,  PF_CUM: 24,  AS_CUM: 50, AF_CUM: 0, },
    { CDATE: '2025-04-11', AREA: 'HULL',    YARD: 'COSCO', STAGE: 'ASSEMBLY', PS_WK: 0,  PF_WK: 10, AS_WK: 0,  AF_WK: 0, PS_CUM: 82,  PF_CUM: 34,  AS_CUM: 50, AF_CUM: 0, },
    { CDATE: '2025-04-18', AREA: 'HULL',    YARD: 'COSCO', STAGE: 'ASSEMBLY', PS_WK: 0,  PF_WK: 2,  AS_WK: 0,  AF_WK: 0, PS_CUM: 82,  PF_CUM: 36,  AS_CUM: 50, AF_CUM: 0, },
    { CDATE: '2025-04-25', AREA: 'HULL',    YARD: 'COSCO', STAGE: 'ASSEMBLY', PS_WK: 0,  PF_WK: 6,  AS_WK: 0,  AF_WK: 0, PS_CUM: 82,  PF_CUM: 42,  AS_CUM: 50, AF_CUM: 0, },
    { CDATE: '2025-05-02', AREA: 'HULL',    YARD: 'COSCO', STAGE: 'ASSEMBLY', PS_WK: 0,  PF_WK: 8,  AS_WK: 0,  AF_WK: 0, PS_CUM: 82,  PF_CUM: 50,  AS_CUM: 50, AF_CUM: 0, },
    { CDATE: '2025-05-09', AREA: 'HULL',    YARD: 'COSCO', STAGE: 'ASSEMBLY', PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 82,  PF_CUM: 50,  AS_CUM: 50, AF_CUM: 0, },
    { CDATE: '2025-05-16', AREA: 'HULL',    YARD: 'COSCO', STAGE: 'ASSEMBLY', PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 82,  PF_CUM: 50,  AS_CUM: 50, AF_CUM: 0, },
    { CDATE: '2025-05-23', AREA: 'HULL',    YARD: 'COSCO', STAGE: 'ASSEMBLY', PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 82,  PF_CUM: 50,  AS_CUM: 50, AF_CUM: 0, },
    { CDATE: '2025-05-30', AREA: 'HULL',    YARD: 'COSCO', STAGE: 'ASSEMBLY', PS_WK: 0,  PF_WK: 2,  AS_WK: 0,  AF_WK: 0, PS_CUM: 82,  PF_CUM: 52,  AS_CUM: 50, AF_CUM: 0, },
    { CDATE: '2025-06-06', AREA: 'HULL',    YARD: 'COSCO', STAGE: 'ASSEMBLY', PS_WK: 0,  PF_WK: 10, AS_WK: 0,  AF_WK: 0, PS_CUM: 82,  PF_CUM: 62,  AS_CUM: 50, AF_CUM: 0, },
    { CDATE: '2025-06-13', AREA: 'HULL',    YARD: 'COSCO', STAGE: 'ASSEMBLY', PS_WK: 0,  PF_WK: 3,  AS_WK: 0,  AF_WK: 0, PS_CUM: 82,  PF_CUM: 65,  AS_CUM: 50, AF_CUM: 0, },
    { CDATE: '2025-06-20', AREA: 'HULL',    YARD: 'COSCO', STAGE: 'ASSEMBLY', PS_WK: 0,  PF_WK: 3,  AS_WK: 0,  AF_WK: 0, PS_CUM: 82,  PF_CUM: 68,  AS_CUM: 50, AF_CUM: 0, },
    { CDATE: '2025-06-27', AREA: 'HULL',    YARD: 'COSCO', STAGE: 'ASSEMBLY', PS_WK: 0,  PF_WK: 10, AS_WK: 0,  AF_WK: 0, PS_CUM: 82,  PF_CUM: 78,  AS_CUM: 50, AF_CUM: 0, },
    { CDATE: '2025-07-04', AREA: 'HULL',    YARD: 'COSCO', STAGE: 'ASSEMBLY', PS_WK: 0,  PF_WK: 4,  AS_WK: 0,  AF_WK: 0, PS_CUM: 82,  PF_CUM: 82,  AS_CUM: 50, AF_CUM: 0, },
    { CDATE: '2025-07-11', AREA: 'HULL',    YARD: 'COSCO', STAGE: 'ASSEMBLY', PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 82,  PF_CUM: 82,  AS_CUM: 50, AF_CUM: 0, },
    { CDATE: '2025-07-18', AREA: 'HULL',    YARD: 'COSCO', STAGE: 'ASSEMBLY', PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 82,  PF_CUM: 82,  AS_CUM: 50, AF_CUM: 0, },
    { CDATE: '2025-07-25', AREA: 'HULL',    YARD: 'COSCO', STAGE: 'ASSEMBLY', PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 82,  PF_CUM: 82,  AS_CUM: 50, AF_CUM: 0, },
    { CDATE: '2025-08-01', AREA: 'HULL',    YARD: 'COSCO', STAGE: 'ASSEMBLY', PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 82,  PF_CUM: 82,  AS_CUM: 50, AF_CUM: 0, },
    { CDATE: '2025-08-08', AREA: 'HULL',    YARD: 'COSCO', STAGE: 'ASSEMBLY', PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 82,  PF_CUM: 82,  AS_CUM: 50, AF_CUM: 0, },
    { CDATE: '2025-08-15', AREA: 'HULL',    YARD: 'COSCO', STAGE: 'ASSEMBLY', PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 82,  PF_CUM: 82,  AS_CUM: 50, AF_CUM: 0, },
    { CDATE: '2025-08-22', AREA: 'HULL',    YARD: 'COSCO', STAGE: 'ASSEMBLY', PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 82,  PF_CUM: 82,  AS_CUM: 50, AF_CUM: 0, },
    { CDATE: '2025-08-29', AREA: 'HULL',    YARD: 'COSCO', STAGE: 'ASSEMBLY', PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 82,  PF_CUM: 82,  AS_CUM: 50, AF_CUM: 0, },
    { CDATE: '2025-09-05', AREA: 'HULL',    YARD: 'COSCO', STAGE: 'ASSEMBLY', PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 82,  PF_CUM: 82,  AS_CUM: 50, AF_CUM: 0, },
    { CDATE: '2025-09-12', AREA: 'HULL',    YARD: 'COSCO', STAGE: 'ASSEMBLY', PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 82,  PF_CUM: 82,  AS_CUM: 50, AF_CUM: 0, },
    { CDATE: '2025-09-19', AREA: 'HULL',    YARD: 'COSCO', STAGE: 'ASSEMBLY', PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 82,  PF_CUM: 82,  AS_CUM: 50, AF_CUM: 0, },
    { CDATE: '2025-09-26', AREA: 'HULL',    YARD: 'COSCO', STAGE: 'ASSEMBLY', PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 82,  PF_CUM: 82,  AS_CUM: 50, AF_CUM: 0, },
    { CDATE: '2025-10-03', AREA: 'HULL',    YARD: 'COSCO', STAGE: 'ASSEMBLY', PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 82,  PF_CUM: 82,  AS_CUM: 50, AF_CUM: 0, },
    { CDATE: '2025-10-10', AREA: 'HULL',    YARD: 'COSCO', STAGE: 'ASSEMBLY', PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 82,  PF_CUM: 82,  AS_CUM: 50, AF_CUM: 0, },
    { CDATE: '2025-10-17', AREA: 'HULL',    YARD: 'COSCO', STAGE: 'ASSEMBLY', PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 82,  PF_CUM: 82,  AS_CUM: 50, AF_CUM: 0, },
    { CDATE: '2025-10-24', AREA: 'HULL',    YARD: 'COSCO', STAGE: 'ASSEMBLY', PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 82,  PF_CUM: 82,  AS_CUM: 50, AF_CUM: 0, },
    { CDATE: '2025-10-31', AREA: 'HULL',    YARD: 'COSCO', STAGE: 'ASSEMBLY', PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 82,  PF_CUM: 82,  AS_CUM: 50, AF_CUM: 0, },
    { CDATE: '2025-11-07', AREA: 'HULL',    YARD: 'COSCO', STAGE: 'ASSEMBLY', PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 82,  PF_CUM: 82,  AS_CUM: 50, AF_CUM: 0, },
    { CDATE: '2025-11-14', AREA: 'HULL',    YARD: 'COSCO', STAGE: 'ASSEMBLY', PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 82,  PF_CUM: 82,  AS_CUM: 50, AF_CUM: 0, },
    { CDATE: '2025-11-21', AREA: 'HULL',    YARD: 'COSCO', STAGE: 'ASSEMBLY', PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 82,  PF_CUM: 82,  AS_CUM: 50, AF_CUM: 0, },
    { CDATE: '2025-11-28', AREA: 'HULL',    YARD: 'COSCO', STAGE: 'ASSEMBLY', PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 82,  PF_CUM: 82,  AS_CUM: 50, AF_CUM: 0, },
    { CDATE: '2025-12-05', AREA: 'HULL',    YARD: 'COSCO', STAGE: 'ASSEMBLY', PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 82,  PF_CUM: 82,  AS_CUM: 50, AF_CUM: 0, },
    { CDATE: '2025-12-12', AREA: 'HULL',    YARD: 'COSCO', STAGE: 'ASSEMBLY', PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 82,  PF_CUM: 82,  AS_CUM: 50, AF_CUM: 0, },
    { CDATE: '2025-12-19', AREA: 'HULL',    YARD: 'COSCO', STAGE: 'ASSEMBLY', PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 82,  PF_CUM: 82,  AS_CUM: 50, AF_CUM: 0, },
    { CDATE: '2025-12-26', AREA: 'HULL',    YARD: 'COSCO', STAGE: 'ASSEMBLY', PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 82,  PF_CUM: 82,  AS_CUM: 50, AF_CUM: 0, },
    { CDATE: '2026-01-02', AREA: 'HULL',    YARD: 'COSCO', STAGE: 'ASSEMBLY', PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 82,  PF_CUM: 82,  AS_CUM: 50, AF_CUM: 0, },
    { CDATE: '2026-01-09', AREA: 'HULL',    YARD: 'COSCO', STAGE: 'ASSEMBLY', PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 82,  PF_CUM: 82,  AS_CUM: 50, AF_CUM: 0, },
    { CDATE: '2026-01-16', AREA: 'HULL',    YARD: 'COSCO', STAGE: 'ASSEMBLY', PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 82,  PF_CUM: 82,  AS_CUM: 50, AF_CUM: 0, },
    { CDATE: '2026-01-23', AREA: 'HULL',    YARD: 'COSCO', STAGE: 'ASSEMBLY', PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 82,  PF_CUM: 82,  AS_CUM: 50, AF_CUM: 0, },
    { CDATE: '2026-01-30', AREA: 'HULL',    YARD: 'COSCO', STAGE: 'ASSEMBLY', PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 82,  PF_CUM: 82,  AS_CUM: 50, AF_CUM: 0, },
    { CDATE: '2026-02-06', AREA: 'HULL',    YARD: 'COSCO', STAGE: 'ASSEMBLY', PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 82,  PF_CUM: 82,  AS_CUM: 50, AF_CUM: 0, },
    { CDATE: '2026-02-13', AREA: 'HULL',    YARD: 'COSCO', STAGE: 'ASSEMBLY', PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 82,  PF_CUM: 82,  AS_CUM: 50, AF_CUM: 0, },
    { CDATE: '2026-02-20', AREA: 'HULL',    YARD: 'COSCO', STAGE: 'ASSEMBLY', PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 82,  PF_CUM: 82,  AS_CUM: 50, AF_CUM: 0, },
    { CDATE: '2026-02-27', AREA: 'HULL',    YARD: 'COSCO', STAGE: 'ASSEMBLY', PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 82,  PF_CUM: 82,  AS_CUM: 50, AF_CUM: 0, },
    { CDATE: '2024-10-04', AREA: 'HULL',    YARD: 'COSCO', STAGE: 'CUTTING',  PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 0,   PF_CUM: 0,   AS_CUM: 0,  AF_CUM: 0, },
    { CDATE: '2024-10-11', AREA: 'HULL',    YARD: 'COSCO', STAGE: 'CUTTING',  PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 0,   PF_CUM: 0,   AS_CUM: 0,  AF_CUM: 0, },
    { CDATE: '2024-10-18', AREA: 'HULL',    YARD: 'COSCO', STAGE: 'CUTTING',  PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 0,   PF_CUM: 0,   AS_CUM: 0,  AF_CUM: 0, },
    { CDATE: '2024-10-25', AREA: 'HULL',    YARD: 'COSCO', STAGE: 'CUTTING',  PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 0,   PF_CUM: 0,   AS_CUM: 0,  AF_CUM: 0, },
    { CDATE: '2024-11-01', AREA: 'HULL',    YARD: 'COSCO', STAGE: 'CUTTING',  PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 0,   PF_CUM: 0,   AS_CUM: 0,  AF_CUM: 0, },
    { CDATE: '2024-11-08', AREA: 'HULL',    YARD: 'COSCO', STAGE: 'CUTTING',  PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 0,   PF_CUM: 0,   AS_CUM: 0,  AF_CUM: 0, },
    { CDATE: '2024-11-15', AREA: 'HULL',    YARD: 'COSCO', STAGE: 'CUTTING',  PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 0,   PF_CUM: 0,   AS_CUM: 0,  AF_CUM: 0, },
    { CDATE: '2024-11-22', AREA: 'HULL',    YARD: 'COSCO', STAGE: 'CUTTING',  PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 0,   PF_CUM: 0,   AS_CUM: 0,  AF_CUM: 0, },
    { CDATE: '2024-11-29', AREA: 'HULL',    YARD: 'COSCO', STAGE: 'CUTTING',  PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 0,   PF_CUM: 0,   AS_CUM: 0,  AF_CUM: 0, },
    { CDATE: '2024-12-06', AREA: 'HULL',    YARD: 'COSCO', STAGE: 'CUTTING',  PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 0,   PF_CUM: 0,   AS_CUM: 0,  AF_CUM: 0, },
    { CDATE: '2024-12-13', AREA: 'HULL',    YARD: 'COSCO', STAGE: 'CUTTING',  PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 0,   PF_CUM: 0,   AS_CUM: 0,  AF_CUM: 0, },
    { CDATE: '2024-12-20', AREA: 'HULL',    YARD: 'COSCO', STAGE: 'CUTTING',  PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 0,   PF_CUM: 0,   AS_CUM: 0,  AF_CUM: 0, },
    { CDATE: '2024-12-27', AREA: 'HULL',    YARD: 'COSCO', STAGE: 'CUTTING',  PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 0,   PF_CUM: 0,   AS_CUM: 0,  AF_CUM: 0, },
    { CDATE: '2025-01-03', AREA: 'HULL',    YARD: 'COSCO', STAGE: 'CUTTING',  PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 0,   PF_CUM: 0,   AS_CUM: 0,  AF_CUM: 0, },
    { CDATE: '2025-01-10', AREA: 'HULL',    YARD: 'COSCO', STAGE: 'CUTTING',  PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 0,   PF_CUM: 0,   AS_CUM: 0,  AF_CUM: 0, },
    { CDATE: '2025-01-17', AREA: 'HULL',    YARD: 'COSCO', STAGE: 'CUTTING',  PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 0,   PF_CUM: 0,   AS_CUM: 0,  AF_CUM: 0, },
    { CDATE: '2025-01-24', AREA: 'HULL',    YARD: 'COSCO', STAGE: 'CUTTING',  PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 0,   PF_CUM: 0,   AS_CUM: 0,  AF_CUM: 0, },
    { CDATE: '2025-01-31', AREA: 'HULL',    YARD: 'COSCO', STAGE: 'CUTTING',  PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 0,   PF_CUM: 0,   AS_CUM: 0,  AF_CUM: 0, },
    { CDATE: '2025-02-07', AREA: 'HULL',    YARD: 'COSCO', STAGE: 'CUTTING',  PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 0,   PF_CUM: 0,   AS_CUM: 0,  AF_CUM: 0, },
    { CDATE: '2025-02-14', AREA: 'HULL',    YARD: 'COSCO', STAGE: 'CUTTING',  PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 0,   PF_CUM: 0,   AS_CUM: 0,  AF_CUM: 0, },
    { CDATE: '2025-02-21', AREA: 'HULL',    YARD: 'COSCO', STAGE: 'CUTTING',  PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 0,   PF_CUM: 0,   AS_CUM: 0,  AF_CUM: 0, },
    { CDATE: '2025-02-28', AREA: 'HULL',    YARD: 'COSCO', STAGE: 'CUTTING',  PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 0,   PF_CUM: 0,   AS_CUM: 0,  AF_CUM: 0, },
    { CDATE: '2025-03-07', AREA: 'HULL',    YARD: 'COSCO', STAGE: 'CUTTING',  PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 0,   PF_CUM: 0,   AS_CUM: 0,  AF_CUM: 0, },
    { CDATE: '2025-03-14', AREA: 'HULL',    YARD: 'COSCO', STAGE: 'CUTTING',  PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 0,   PF_CUM: 0,   AS_CUM: 0,  AF_CUM: 0, },
    { CDATE: '2025-03-21', AREA: 'HULL',    YARD: 'COSCO', STAGE: 'CUTTING',  PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 0,   PF_CUM: 0,   AS_CUM: 0,  AF_CUM: 0, },
    { CDATE: '2025-03-28', AREA: 'HULL',    YARD: 'COSCO', STAGE: 'CUTTING',  PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 0,   PF_CUM: 0,   AS_CUM: 0,  AF_CUM: 0, },
    { CDATE: '2025-04-04', AREA: 'HULL',    YARD: 'COSCO', STAGE: 'CUTTING',  PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 0,   PF_CUM: 0,   AS_CUM: 0,  AF_CUM: 0, },
    { CDATE: '2025-04-11', AREA: 'HULL',    YARD: 'COSCO', STAGE: 'CUTTING',  PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 0,   PF_CUM: 0,   AS_CUM: 0,  AF_CUM: 0, },
    { CDATE: '2025-04-18', AREA: 'HULL',    YARD: 'COSCO', STAGE: 'CUTTING',  PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 0,   PF_CUM: 0,   AS_CUM: 0,  AF_CUM: 0, },
    { CDATE: '2025-04-25', AREA: 'HULL',    YARD: 'COSCO', STAGE: 'CUTTING',  PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 0,   PF_CUM: 0,   AS_CUM: 0,  AF_CUM: 0, },
    { CDATE: '2025-05-02', AREA: 'HULL',    YARD: 'COSCO', STAGE: 'CUTTING',  PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 0,   PF_CUM: 0,   AS_CUM: 0,  AF_CUM: 0, },
    { CDATE: '2025-05-09', AREA: 'HULL',    YARD: 'COSCO', STAGE: 'CUTTING',  PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 0,   PF_CUM: 0,   AS_CUM: 0,  AF_CUM: 0, },
    { CDATE: '2025-05-16', AREA: 'HULL',    YARD: 'COSCO', STAGE: 'CUTTING',  PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 0,   PF_CUM: 0,   AS_CUM: 0,  AF_CUM: 0, },
    { CDATE: '2025-05-23', AREA: 'HULL',    YARD: 'COSCO', STAGE: 'CUTTING',  PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 0,   PF_CUM: 0,   AS_CUM: 0,  AF_CUM: 0, },
    { CDATE: '2025-05-30', AREA: 'HULL',    YARD: 'COSCO', STAGE: 'CUTTING',  PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 0,   PF_CUM: 0,   AS_CUM: 0,  AF_CUM: 0, },
    { CDATE: '2025-06-06', AREA: 'HULL',    YARD: 'COSCO', STAGE: 'CUTTING',  PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 0,   PF_CUM: 0,   AS_CUM: 0,  AF_CUM: 0, },
    { CDATE: '2025-06-13', AREA: 'HULL',    YARD: 'COSCO', STAGE: 'CUTTING',  PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 0,   PF_CUM: 0,   AS_CUM: 0,  AF_CUM: 0, },
    { CDATE: '2025-06-20', AREA: 'HULL',    YARD: 'COSCO', STAGE: 'CUTTING',  PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 0,   PF_CUM: 0,   AS_CUM: 0,  AF_CUM: 0, },
    { CDATE: '2025-06-27', AREA: 'HULL',    YARD: 'COSCO', STAGE: 'CUTTING',  PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 0,   PF_CUM: 0,   AS_CUM: 0,  AF_CUM: 0, },
    { CDATE: '2025-07-04', AREA: 'HULL',    YARD: 'COSCO', STAGE: 'CUTTING',  PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 0,   PF_CUM: 0,   AS_CUM: 0,  AF_CUM: 0, },
    { CDATE: '2025-07-11', AREA: 'HULL',    YARD: 'COSCO', STAGE: 'CUTTING',  PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 0,   PF_CUM: 0,   AS_CUM: 0,  AF_CUM: 0, },
    { CDATE: '2025-07-18', AREA: 'HULL',    YARD: 'COSCO', STAGE: 'CUTTING',  PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 0,   PF_CUM: 0,   AS_CUM: 0,  AF_CUM: 0, },
    { CDATE: '2025-07-25', AREA: 'HULL',    YARD: 'COSCO', STAGE: 'CUTTING',  PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 0,   PF_CUM: 0,   AS_CUM: 0,  AF_CUM: 0, },
    { CDATE: '2025-08-01', AREA: 'HULL',    YARD: 'COSCO', STAGE: 'CUTTING',  PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 0,   PF_CUM: 0,   AS_CUM: 0,  AF_CUM: 0, },
    { CDATE: '2025-08-08', AREA: 'HULL',    YARD: 'COSCO', STAGE: 'CUTTING',  PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 0,   PF_CUM: 0,   AS_CUM: 0,  AF_CUM: 0, },
    { CDATE: '2025-08-15', AREA: 'HULL',    YARD: 'COSCO', STAGE: 'CUTTING',  PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 0,   PF_CUM: 0,   AS_CUM: 0,  AF_CUM: 0, },
    { CDATE: '2025-08-22', AREA: 'HULL',    YARD: 'COSCO', STAGE: 'CUTTING',  PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 0,   PF_CUM: 0,   AS_CUM: 0,  AF_CUM: 0, },
    { CDATE: '2025-08-29', AREA: 'HULL',    YARD: 'COSCO', STAGE: 'CUTTING',  PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 0,   PF_CUM: 0,   AS_CUM: 0,  AF_CUM: 0, },
    { CDATE: '2025-09-05', AREA: 'HULL',    YARD: 'COSCO', STAGE: 'CUTTING',  PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 0,   PF_CUM: 0,   AS_CUM: 0,  AF_CUM: 0, },
    { CDATE: '2025-09-12', AREA: 'HULL',    YARD: 'COSCO', STAGE: 'CUTTING',  PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 0,   PF_CUM: 0,   AS_CUM: 0,  AF_CUM: 0, },
    { CDATE: '2025-09-19', AREA: 'HULL',    YARD: 'COSCO', STAGE: 'CUTTING',  PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 0,   PF_CUM: 0,   AS_CUM: 0,  AF_CUM: 0, },
    { CDATE: '2025-09-26', AREA: 'HULL',    YARD: 'COSCO', STAGE: 'CUTTING',  PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 0,   PF_CUM: 0,   AS_CUM: 0,  AF_CUM: 0, },
    { CDATE: '2025-10-03', AREA: 'HULL',    YARD: 'COSCO', STAGE: 'CUTTING',  PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 0,   PF_CUM: 0,   AS_CUM: 0,  AF_CUM: 0, },
    { CDATE: '2025-10-10', AREA: 'HULL',    YARD: 'COSCO', STAGE: 'CUTTING',  PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 0,   PF_CUM: 0,   AS_CUM: 0,  AF_CUM: 0, },
    { CDATE: '2025-10-17', AREA: 'HULL',    YARD: 'COSCO', STAGE: 'CUTTING',  PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 0,   PF_CUM: 0,   AS_CUM: 0,  AF_CUM: 0, },
    { CDATE: '2025-10-24', AREA: 'HULL',    YARD: 'COSCO', STAGE: 'CUTTING',  PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 0,   PF_CUM: 0,   AS_CUM: 0,  AF_CUM: 0, },
    { CDATE: '2025-10-31', AREA: 'HULL',    YARD: 'COSCO', STAGE: 'CUTTING',  PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 0,   PF_CUM: 0,   AS_CUM: 0,  AF_CUM: 0, },
    { CDATE: '2025-11-07', AREA: 'HULL',    YARD: 'COSCO', STAGE: 'CUTTING',  PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 0,   PF_CUM: 0,   AS_CUM: 0,  AF_CUM: 0, },
    { CDATE: '2025-11-14', AREA: 'HULL',    YARD: 'COSCO', STAGE: 'CUTTING',  PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 0,   PF_CUM: 0,   AS_CUM: 0,  AF_CUM: 0, },
    { CDATE: '2025-11-21', AREA: 'HULL',    YARD: 'COSCO', STAGE: 'CUTTING',  PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 0,   PF_CUM: 0,   AS_CUM: 0,  AF_CUM: 0, },
    { CDATE: '2025-11-28', AREA: 'HULL',    YARD: 'COSCO', STAGE: 'CUTTING',  PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 0,   PF_CUM: 0,   AS_CUM: 0,  AF_CUM: 0, },
    { CDATE: '2025-12-05', AREA: 'HULL',    YARD: 'COSCO', STAGE: 'CUTTING',  PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 0,   PF_CUM: 0,   AS_CUM: 0,  AF_CUM: 0, },
    { CDATE: '2025-12-12', AREA: 'HULL',    YARD: 'COSCO', STAGE: 'CUTTING',  PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 0,   PF_CUM: 0,   AS_CUM: 0,  AF_CUM: 0, },
    { CDATE: '2025-12-19', AREA: 'HULL',    YARD: 'COSCO', STAGE: 'CUTTING',  PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 0,   PF_CUM: 0,   AS_CUM: 0,  AF_CUM: 0, },
    { CDATE: '2025-12-26', AREA: 'HULL',    YARD: 'COSCO', STAGE: 'CUTTING',  PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 0,   PF_CUM: 0,   AS_CUM: 0,  AF_CUM: 0, },
    { CDATE: '2026-01-02', AREA: 'HULL',    YARD: 'COSCO', STAGE: 'CUTTING',  PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 0,   PF_CUM: 0,   AS_CUM: 0,  AF_CUM: 0, },
    { CDATE: '2026-01-09', AREA: 'HULL',    YARD: 'COSCO', STAGE: 'CUTTING',  PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 0,   PF_CUM: 0,   AS_CUM: 0,  AF_CUM: 0, },
    { CDATE: '2026-01-16', AREA: 'HULL',    YARD: 'COSCO', STAGE: 'CUTTING',  PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 0,   PF_CUM: 0,   AS_CUM: 0,  AF_CUM: 0, },
    { CDATE: '2026-01-23', AREA: 'HULL',    YARD: 'COSCO', STAGE: 'CUTTING',  PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 0,   PF_CUM: 0,   AS_CUM: 0,  AF_CUM: 0, },
    { CDATE: '2026-01-30', AREA: 'HULL',    YARD: 'COSCO', STAGE: 'CUTTING',  PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 0,   PF_CUM: 0,   AS_CUM: 0,  AF_CUM: 0, },
    { CDATE: '2026-02-06', AREA: 'HULL',    YARD: 'COSCO', STAGE: 'CUTTING',  PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 0,   PF_CUM: 0,   AS_CUM: 0,  AF_CUM: 0, },
    { CDATE: '2026-02-13', AREA: 'HULL',    YARD: 'COSCO', STAGE: 'CUTTING',  PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 0,   PF_CUM: 0,   AS_CUM: 0,  AF_CUM: 0, },
    { CDATE: '2026-02-20', AREA: 'HULL',    YARD: 'COSCO', STAGE: 'CUTTING',  PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 0,   PF_CUM: 0,   AS_CUM: 0,  AF_CUM: 0, },
    { CDATE: '2026-02-27', AREA: 'HULL',    YARD: 'COSCO', STAGE: 'CUTTING',  PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 0,   PF_CUM: 0,   AS_CUM: 0,  AF_CUM: 0, },
    { CDATE: '2024-10-04', AREA: 'HULL',    YARD: 'COSCO', STAGE: 'PAINT',    PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 0,   PF_CUM: 0,   AS_CUM: 0,  AF_CUM: 0, },
    { CDATE: '2024-10-11', AREA: 'HULL',    YARD: 'COSCO', STAGE: 'PAINT',    PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 0,   PF_CUM: 0,   AS_CUM: 0,  AF_CUM: 0, },
    { CDATE: '2024-10-18', AREA: 'HULL',    YARD: 'COSCO', STAGE: 'PAINT',    PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 0,   PF_CUM: 0,   AS_CUM: 0,  AF_CUM: 0, },
    { CDATE: '2024-10-25', AREA: 'HULL',    YARD: 'COSCO', STAGE: 'PAINT',    PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 0,   PF_CUM: 0,   AS_CUM: 0,  AF_CUM: 0, },
    { CDATE: '2024-11-01', AREA: 'HULL',    YARD: 'COSCO', STAGE: 'PAINT',    PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 0,   PF_CUM: 0,   AS_CUM: 0,  AF_CUM: 0, },
    { CDATE: '2024-11-08', AREA: 'HULL',    YARD: 'COSCO', STAGE: 'PAINT',    PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 0,   PF_CUM: 0,   AS_CUM: 0,  AF_CUM: 0, },
    { CDATE: '2024-11-15', AREA: 'HULL',    YARD: 'COSCO', STAGE: 'PAINT',    PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 0,   PF_CUM: 0,   AS_CUM: 0,  AF_CUM: 0, },
    { CDATE: '2024-11-22', AREA: 'HULL',    YARD: 'COSCO', STAGE: 'PAINT',    PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 0,   PF_CUM: 0,   AS_CUM: 0,  AF_CUM: 0, },
    { CDATE: '2024-11-29', AREA: 'HULL',    YARD: 'COSCO', STAGE: 'PAINT',    PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 0,   PF_CUM: 0,   AS_CUM: 0,  AF_CUM: 0, },
    { CDATE: '2024-12-06', AREA: 'HULL',    YARD: 'COSCO', STAGE: 'PAINT',    PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 0,   PF_CUM: 0,   AS_CUM: 0,  AF_CUM: 0, },
    { CDATE: '2024-12-13', AREA: 'HULL',    YARD: 'COSCO', STAGE: 'PAINT',    PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 0,   PF_CUM: 0,   AS_CUM: 0,  AF_CUM: 0, },
    { CDATE: '2024-12-20', AREA: 'HULL',    YARD: 'COSCO', STAGE: 'PAINT',    PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 0,   PF_CUM: 0,   AS_CUM: 0,  AF_CUM: 0, },
    { CDATE: '2024-12-27', AREA: 'HULL',    YARD: 'COSCO', STAGE: 'PAINT',    PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 0,   PF_CUM: 0,   AS_CUM: 0,  AF_CUM: 0, },
    { CDATE: '2025-01-03', AREA: 'HULL',    YARD: 'COSCO', STAGE: 'PAINT',    PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 0,   PF_CUM: 0,   AS_CUM: 0,  AF_CUM: 0, },
    { CDATE: '2025-01-10', AREA: 'HULL',    YARD: 'COSCO', STAGE: 'PAINT',    PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 0,   PF_CUM: 0,   AS_CUM: 0,  AF_CUM: 0, },
    { CDATE: '2025-01-17', AREA: 'HULL',    YARD: 'COSCO', STAGE: 'PAINT',    PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 0,   PF_CUM: 0,   AS_CUM: 0,  AF_CUM: 0, },
    { CDATE: '2025-01-24', AREA: 'HULL',    YARD: 'COSCO', STAGE: 'PAINT',    PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 0,   PF_CUM: 0,   AS_CUM: 0,  AF_CUM: 0, },
    { CDATE: '2025-01-31', AREA: 'HULL',    YARD: 'COSCO', STAGE: 'PAINT',    PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 0,   PF_CUM: 0,   AS_CUM: 0,  AF_CUM: 0, },
    { CDATE: '2025-02-07', AREA: 'HULL',    YARD: 'COSCO', STAGE: 'PAINT',    PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 0,   PF_CUM: 0,   AS_CUM: 0,  AF_CUM: 0, },
    { CDATE: '2025-02-14', AREA: 'HULL',    YARD: 'COSCO', STAGE: 'PAINT',    PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 0,   PF_CUM: 0,   AS_CUM: 0,  AF_CUM: 0, },
    { CDATE: '2025-02-21', AREA: 'HULL',    YARD: 'COSCO', STAGE: 'PAINT',    PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 0,   PF_CUM: 0,   AS_CUM: 0,  AF_CUM: 0, },
    { CDATE: '2025-02-28', AREA: 'HULL',    YARD: 'COSCO', STAGE: 'PAINT',    PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 0,   PF_CUM: 0,   AS_CUM: 0,  AF_CUM: 0, },
    { CDATE: '2025-03-07', AREA: 'HULL',    YARD: 'COSCO', STAGE: 'PAINT',    PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 0,   PF_CUM: 0,   AS_CUM: 0,  AF_CUM: 0, },
    { CDATE: '2025-03-14', AREA: 'HULL',    YARD: 'COSCO', STAGE: 'PAINT',    PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 0,   PF_CUM: 0,   AS_CUM: 0,  AF_CUM: 0, },
    { CDATE: '2025-03-21', AREA: 'HULL',    YARD: 'COSCO', STAGE: 'PAINT',    PS_WK: 4,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 4,   PF_CUM: 0,   AS_CUM: 0,  AF_CUM: 0, },
    { CDATE: '2025-03-28', AREA: 'HULL',    YARD: 'COSCO', STAGE: 'PAINT',    PS_WK: 0,  PF_WK: 3,  AS_WK: 0,  AF_WK: 0, PS_CUM: 4,   PF_CUM: 3,   AS_CUM: 0,  AF_CUM: 0, },
    { CDATE: '2025-04-04', AREA: 'HULL',    YARD: 'COSCO', STAGE: 'PAINT',    PS_WK: 6,  PF_WK: 1,  AS_WK: 0,  AF_WK: 0, PS_CUM: 10,  PF_CUM: 4,   AS_CUM: 0,  AF_CUM: 0, },
    { CDATE: '2025-04-11', AREA: 'HULL',    YARD: 'COSCO', STAGE: 'PAINT',    PS_WK: 6,  PF_WK: 3,  AS_WK: 0,  AF_WK: 0, PS_CUM: 16,  PF_CUM: 7,   AS_CUM: 0,  AF_CUM: 0, },
    { CDATE: '2025-04-18', AREA: 'HULL',    YARD: 'COSCO', STAGE: 'PAINT',    PS_WK: 0,  PF_WK: 7,  AS_WK: 0,  AF_WK: 0, PS_CUM: 16,  PF_CUM: 14,  AS_CUM: 0,  AF_CUM: 0, },
    { CDATE: '2025-04-25', AREA: 'HULL',    YARD: 'COSCO', STAGE: 'PAINT',    PS_WK: 0,  PF_WK: 2,  AS_WK: 0,  AF_WK: 0, PS_CUM: 16,  PF_CUM: 16,  AS_CUM: 0,  AF_CUM: 0, },
    { CDATE: '2025-05-02', AREA: 'HULL',    YARD: 'COSCO', STAGE: 'PAINT',    PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 16,  PF_CUM: 16,  AS_CUM: 0,  AF_CUM: 0, },
    { CDATE: '2025-05-09', AREA: 'HULL',    YARD: 'COSCO', STAGE: 'PAINT',    PS_WK: 12, PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 28,  PF_CUM: 16,  AS_CUM: 0,  AF_CUM: 0, },
    { CDATE: '2025-05-16', AREA: 'HULL',    YARD: 'COSCO', STAGE: 'PAINT',    PS_WK: 4,  PF_WK: 2,  AS_WK: 0,  AF_WK: 0, PS_CUM: 32,  PF_CUM: 18,  AS_CUM: 0,  AF_CUM: 0, },
    { CDATE: '2025-05-23', AREA: 'HULL',    YARD: 'COSCO', STAGE: 'PAINT',    PS_WK: 0,  PF_WK: 12, AS_WK: 0,  AF_WK: 0, PS_CUM: 32,  PF_CUM: 30,  AS_CUM: 0,  AF_CUM: 0, },
    { CDATE: '2025-05-30', AREA: 'HULL',    YARD: 'COSCO', STAGE: 'PAINT',    PS_WK: 6,  PF_WK: 2,  AS_WK: 0,  AF_WK: 0, PS_CUM: 38,  PF_CUM: 32,  AS_CUM: 0,  AF_CUM: 0, },
    { CDATE: '2025-06-06', AREA: 'HULL',    YARD: 'COSCO', STAGE: 'PAINT',    PS_WK: 10, PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 48,  PF_CUM: 32,  AS_CUM: 0,  AF_CUM: 0, },
    { CDATE: '2025-06-13', AREA: 'HULL',    YARD: 'COSCO', STAGE: 'PAINT',    PS_WK: 0,  PF_WK: 6,  AS_WK: 0,  AF_WK: 0, PS_CUM: 48,  PF_CUM: 38,  AS_CUM: 0,  AF_CUM: 0, },
    { CDATE: '2025-06-20', AREA: 'HULL',    YARD: 'COSCO', STAGE: 'PAINT',    PS_WK: 0,  PF_WK: 10, AS_WK: 0,  AF_WK: 0, PS_CUM: 48,  PF_CUM: 48,  AS_CUM: 0,  AF_CUM: 0, },
    { CDATE: '2025-06-27', AREA: 'HULL',    YARD: 'COSCO', STAGE: 'PAINT',    PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 48,  PF_CUM: 48,  AS_CUM: 0,  AF_CUM: 0, },
    { CDATE: '2025-07-04', AREA: 'HULL',    YARD: 'COSCO', STAGE: 'PAINT',    PS_WK: 7,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 55,  PF_CUM: 48,  AS_CUM: 0,  AF_CUM: 0, },
    { CDATE: '2025-07-11', AREA: 'HULL',    YARD: 'COSCO', STAGE: 'PAINT',    PS_WK: 6,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 61,  PF_CUM: 48,  AS_CUM: 0,  AF_CUM: 0, },
    { CDATE: '2025-07-18', AREA: 'HULL',    YARD: 'COSCO', STAGE: 'PAINT',    PS_WK: 5,  PF_WK: 9,  AS_WK: 0,  AF_WK: 0, PS_CUM: 66,  PF_CUM: 57,  AS_CUM: 0,  AF_CUM: 0, },
    { CDATE: '2025-07-25', AREA: 'HULL',    YARD: 'COSCO', STAGE: 'PAINT',    PS_WK: 6,  PF_WK: 5,  AS_WK: 0,  AF_WK: 0, PS_CUM: 72,  PF_CUM: 62,  AS_CUM: 0,  AF_CUM: 0, },
    { CDATE: '2025-08-01', AREA: 'HULL',    YARD: 'COSCO', STAGE: 'PAINT',    PS_WK: 8,  PF_WK: 4,  AS_WK: 0,  AF_WK: 0, PS_CUM: 80,  PF_CUM: 66,  AS_CUM: 0,  AF_CUM: 0, },
    { CDATE: '2025-08-08', AREA: 'HULL',    YARD: 'COSCO', STAGE: 'PAINT',    PS_WK: 0,  PF_WK: 8,  AS_WK: 0,  AF_WK: 0, PS_CUM: 80,  PF_CUM: 74,  AS_CUM: 0,  AF_CUM: 0, },
    { CDATE: '2025-08-15', AREA: 'HULL',    YARD: 'COSCO', STAGE: 'PAINT',    PS_WK: 0,  PF_WK: 6,  AS_WK: 0,  AF_WK: 0, PS_CUM: 80,  PF_CUM: 80,  AS_CUM: 0,  AF_CUM: 0, },
    { CDATE: '2025-08-22', AREA: 'HULL',    YARD: 'COSCO', STAGE: 'PAINT',    PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 80,  PF_CUM: 80,  AS_CUM: 0,  AF_CUM: 0, },
    { CDATE: '2025-08-29', AREA: 'HULL',    YARD: 'COSCO', STAGE: 'PAINT',    PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 80,  PF_CUM: 80,  AS_CUM: 0,  AF_CUM: 0, },
    { CDATE: '2025-09-05', AREA: 'HULL',    YARD: 'COSCO', STAGE: 'PAINT',    PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 80,  PF_CUM: 80,  AS_CUM: 0,  AF_CUM: 0, },
    { CDATE: '2025-09-12', AREA: 'HULL',    YARD: 'COSCO', STAGE: 'PAINT',    PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 80,  PF_CUM: 80,  AS_CUM: 0,  AF_CUM: 0, },
    { CDATE: '2025-09-19', AREA: 'HULL',    YARD: 'COSCO', STAGE: 'PAINT',    PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 80,  PF_CUM: 80,  AS_CUM: 0,  AF_CUM: 0, },
    { CDATE: '2025-09-26', AREA: 'HULL',    YARD: 'COSCO', STAGE: 'PAINT',    PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 80,  PF_CUM: 80,  AS_CUM: 0,  AF_CUM: 0, },
    { CDATE: '2025-10-03', AREA: 'HULL',    YARD: 'COSCO', STAGE: 'PAINT',    PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 80,  PF_CUM: 80,  AS_CUM: 0,  AF_CUM: 0, },
    { CDATE: '2025-10-10', AREA: 'HULL',    YARD: 'COSCO', STAGE: 'PAINT',    PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 80,  PF_CUM: 80,  AS_CUM: 0,  AF_CUM: 0, },
    { CDATE: '2025-10-17', AREA: 'HULL',    YARD: 'COSCO', STAGE: 'PAINT',    PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 80,  PF_CUM: 80,  AS_CUM: 0,  AF_CUM: 0, },
    { CDATE: '2025-10-24', AREA: 'HULL',    YARD: 'COSCO', STAGE: 'PAINT',    PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 80,  PF_CUM: 80,  AS_CUM: 0,  AF_CUM: 0, },
    { CDATE: '2025-10-31', AREA: 'HULL',    YARD: 'COSCO', STAGE: 'PAINT',    PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 80,  PF_CUM: 80,  AS_CUM: 0,  AF_CUM: 0, },
    { CDATE: '2025-11-07', AREA: 'HULL',    YARD: 'COSCO', STAGE: 'PAINT',    PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 80,  PF_CUM: 80,  AS_CUM: 0,  AF_CUM: 0, },
    { CDATE: '2025-11-14', AREA: 'HULL',    YARD: 'COSCO', STAGE: 'PAINT',    PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 80,  PF_CUM: 80,  AS_CUM: 0,  AF_CUM: 0, },
    { CDATE: '2025-11-21', AREA: 'HULL',    YARD: 'COSCO', STAGE: 'PAINT',    PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 80,  PF_CUM: 80,  AS_CUM: 0,  AF_CUM: 0, },
    { CDATE: '2025-11-28', AREA: 'HULL',    YARD: 'COSCO', STAGE: 'PAINT',    PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 80,  PF_CUM: 80,  AS_CUM: 0,  AF_CUM: 0, },
    { CDATE: '2025-12-05', AREA: 'HULL',    YARD: 'COSCO', STAGE: 'PAINT',    PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 80,  PF_CUM: 80,  AS_CUM: 0,  AF_CUM: 0, },
    { CDATE: '2025-12-12', AREA: 'HULL',    YARD: 'COSCO', STAGE: 'PAINT',    PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 80,  PF_CUM: 80,  AS_CUM: 0,  AF_CUM: 0, },
    { CDATE: '2025-12-19', AREA: 'HULL',    YARD: 'COSCO', STAGE: 'PAINT',    PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 80,  PF_CUM: 80,  AS_CUM: 0,  AF_CUM: 0, },
    { CDATE: '2025-12-26', AREA: 'HULL',    YARD: 'COSCO', STAGE: 'PAINT',    PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 80,  PF_CUM: 80,  AS_CUM: 0,  AF_CUM: 0, },
    { CDATE: '2026-01-02', AREA: 'HULL',    YARD: 'COSCO', STAGE: 'PAINT',    PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 80,  PF_CUM: 80,  AS_CUM: 0,  AF_CUM: 0, },
    { CDATE: '2026-01-09', AREA: 'HULL',    YARD: 'COSCO', STAGE: 'PAINT',    PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 80,  PF_CUM: 80,  AS_CUM: 0,  AF_CUM: 0, },
    { CDATE: '2026-01-16', AREA: 'HULL',    YARD: 'COSCO', STAGE: 'PAINT',    PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 80,  PF_CUM: 80,  AS_CUM: 0,  AF_CUM: 0, },
    { CDATE: '2026-01-23', AREA: 'HULL',    YARD: 'COSCO', STAGE: 'PAINT',    PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 80,  PF_CUM: 80,  AS_CUM: 0,  AF_CUM: 0, },
    { CDATE: '2026-01-30', AREA: 'HULL',    YARD: 'COSCO', STAGE: 'PAINT',    PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 80,  PF_CUM: 80,  AS_CUM: 0,  AF_CUM: 0, },
    { CDATE: '2026-02-06', AREA: 'HULL',    YARD: 'COSCO', STAGE: 'PAINT',    PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 80,  PF_CUM: 80,  AS_CUM: 0,  AF_CUM: 0, },
    { CDATE: '2026-02-13', AREA: 'HULL',    YARD: 'COSCO', STAGE: 'PAINT',    PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 80,  PF_CUM: 80,  AS_CUM: 0,  AF_CUM: 0, },
    { CDATE: '2026-02-20', AREA: 'HULL',    YARD: 'COSCO', STAGE: 'PAINT',    PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 80,  PF_CUM: 80,  AS_CUM: 0,  AF_CUM: 0, },
    { CDATE: '2026-02-27', AREA: 'HULL',    YARD: 'COSCO', STAGE: 'PAINT',    PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 80,  PF_CUM: 80,  AS_CUM: 0,  AF_CUM: 0, },
    { CDATE: '2024-10-04', AREA: 'HULL',    YARD: 'HHI',   STAGE: 'ASSEMBLY', PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 0,   PF_CUM: 0,   AS_CUM: 0,  AF_CUM: 0, },
    { CDATE: '2024-10-11', AREA: 'HULL',    YARD: 'HHI',   STAGE: 'ASSEMBLY', PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 0,   PF_CUM: 0,   AS_CUM: 0,  AF_CUM: 0, },
    { CDATE: '2024-10-18', AREA: 'HULL',    YARD: 'HHI',   STAGE: 'ASSEMBLY', PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 0,   PF_CUM: 0,   AS_CUM: 0,  AF_CUM: 0, },
    { CDATE: '2024-10-25', AREA: 'HULL',    YARD: 'HHI',   STAGE: 'ASSEMBLY', PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 0,   PF_CUM: 0,   AS_CUM: 0,  AF_CUM: 0, },
    { CDATE: '2024-11-01', AREA: 'HULL',    YARD: 'HHI',   STAGE: 'ASSEMBLY', PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 0,   PF_CUM: 0,   AS_CUM: 0,  AF_CUM: 0, },
    { CDATE: '2024-11-08', AREA: 'HULL',    YARD: 'HHI',   STAGE: 'ASSEMBLY', PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 0,   PF_CUM: 0,   AS_CUM: 0,  AF_CUM: 0, },
    { CDATE: '2024-11-15', AREA: 'HULL',    YARD: 'HHI',   STAGE: 'ASSEMBLY', PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 0,   PF_CUM: 0,   AS_CUM: 0,  AF_CUM: 0, },
    { CDATE: '2024-11-22', AREA: 'HULL',    YARD: 'HHI',   STAGE: 'ASSEMBLY', PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 0,   PF_CUM: 0,   AS_CUM: 0,  AF_CUM: 0, },
    { CDATE: '2024-11-29', AREA: 'HULL',    YARD: 'HHI',   STAGE: 'ASSEMBLY', PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 0,   PF_CUM: 0,   AS_CUM: 0,  AF_CUM: 0, },
    { CDATE: '2024-12-06', AREA: 'HULL',    YARD: 'HHI',   STAGE: 'ASSEMBLY', PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 0,   PF_CUM: 0,   AS_CUM: 0,  AF_CUM: 0, },
    { CDATE: '2024-12-13', AREA: 'HULL',    YARD: 'HHI',   STAGE: 'ASSEMBLY', PS_WK: 1,  PF_WK: 0,  AS_WK: 1,  AF_WK: 0, PS_CUM: 1,   PF_CUM: 0,   AS_CUM: 1,  AF_CUM: 0, },
    { CDATE: '2024-12-20', AREA: 'HULL',    YARD: 'HHI',   STAGE: 'ASSEMBLY', PS_WK: 4,  PF_WK: 0,  AS_WK: 2,  AF_WK: 0, PS_CUM: 5,   PF_CUM: 0,   AS_CUM: 3,  AF_CUM: 0, },
    { CDATE: '2024-12-27', AREA: 'HULL',    YARD: 'HHI',   STAGE: 'ASSEMBLY', PS_WK: 2,  PF_WK: 0,  AS_WK: 2,  AF_WK: 0, PS_CUM: 7,   PF_CUM: 0,   AS_CUM: 5,  AF_CUM: 0, },
    { CDATE: '2025-01-03', AREA: 'HULL',    YARD: 'HHI',   STAGE: 'ASSEMBLY', PS_WK: 2,  PF_WK: 0,  AS_WK: 1,  AF_WK: 0, PS_CUM: 9,   PF_CUM: 0,   AS_CUM: 6,  AF_CUM: 0, },
    { CDATE: '2025-01-10', AREA: 'HULL',    YARD: 'HHI',   STAGE: 'ASSEMBLY', PS_WK: 2,  PF_WK: 0,  AS_WK: 2,  AF_WK: 0, PS_CUM: 11,  PF_CUM: 0,   AS_CUM: 8,  AF_CUM: 0, },
    { CDATE: '2025-01-17', AREA: 'HULL',    YARD: 'HHI',   STAGE: 'ASSEMBLY', PS_WK: 1,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 12,  PF_CUM: 0,   AS_CUM: 8,  AF_CUM: 0, },
    { CDATE: '2025-01-24', AREA: 'HULL',    YARD: 'HHI',   STAGE: 'ASSEMBLY', PS_WK: 1,  PF_WK: 0,  AS_WK: 1,  AF_WK: 0, PS_CUM: 13,  PF_CUM: 0,   AS_CUM: 9,  AF_CUM: 0, },
    { CDATE: '2025-01-31', AREA: 'HULL',    YARD: 'HHI',   STAGE: 'ASSEMBLY', PS_WK: 1,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 14,  PF_CUM: 0,   AS_CUM: 9,  AF_CUM: 0, },
    { CDATE: '2025-02-07', AREA: 'HULL',    YARD: 'HHI',   STAGE: 'ASSEMBLY', PS_WK: 1,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 15,  PF_CUM: 0,   AS_CUM: 9,  AF_CUM: 0, },
    { CDATE: '2025-02-14', AREA: 'HULL',    YARD: 'HHI',   STAGE: 'ASSEMBLY', PS_WK: 5,  PF_WK: 4,  AS_WK: 0,  AF_WK: 0, PS_CUM: 20,  PF_CUM: 4,   AS_CUM: 9,  AF_CUM: 0, },
    { CDATE: '2025-02-21', AREA: 'HULL',    YARD: 'HHI',   STAGE: 'ASSEMBLY', PS_WK: 1,  PF_WK: 1,  AS_WK: 0,  AF_WK: 0, PS_CUM: 21,  PF_CUM: 5,   AS_CUM: 9,  AF_CUM: 0, },
    { CDATE: '2025-02-28', AREA: 'HULL',    YARD: 'HHI',   STAGE: 'ASSEMBLY', PS_WK: 0,  PF_WK: 6,  AS_WK: 0,  AF_WK: 0, PS_CUM: 21,  PF_CUM: 11,  AS_CUM: 9,  AF_CUM: 0, },
    { CDATE: '2025-03-07', AREA: 'HULL',    YARD: 'HHI',   STAGE: 'ASSEMBLY', PS_WK: 2,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 23,  PF_CUM: 11,  AS_CUM: 9,  AF_CUM: 0, },
    { CDATE: '2025-03-14', AREA: 'HULL',    YARD: 'HHI',   STAGE: 'ASSEMBLY', PS_WK: 1,  PF_WK: 1,  AS_WK: 0,  AF_WK: 0, PS_CUM: 24,  PF_CUM: 12,  AS_CUM: 9,  AF_CUM: 0, },
    { CDATE: '2025-03-21', AREA: 'HULL',    YARD: 'HHI',   STAGE: 'ASSEMBLY', PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 24,  PF_CUM: 12,  AS_CUM: 9,  AF_CUM: 0, },
    { CDATE: '2025-03-28', AREA: 'HULL',    YARD: 'HHI',   STAGE: 'ASSEMBLY', PS_WK: 2,  PF_WK: 4,  AS_WK: 0,  AF_WK: 0, PS_CUM: 26,  PF_CUM: 16,  AS_CUM: 9,  AF_CUM: 0, },
    { CDATE: '2025-04-04', AREA: 'HULL',    YARD: 'HHI',   STAGE: 'ASSEMBLY', PS_WK: 0,  PF_WK: 3,  AS_WK: 0,  AF_WK: 0, PS_CUM: 26,  PF_CUM: 19,  AS_CUM: 9,  AF_CUM: 0, },
    { CDATE: '2025-04-11', AREA: 'HULL',    YARD: 'HHI',   STAGE: 'ASSEMBLY', PS_WK: 5,  PF_WK: 2,  AS_WK: 0,  AF_WK: 0, PS_CUM: 31,  PF_CUM: 21,  AS_CUM: 9,  AF_CUM: 0, },
    { CDATE: '2025-04-18', AREA: 'HULL',    YARD: 'HHI',   STAGE: 'ASSEMBLY', PS_WK: 3,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 34,  PF_CUM: 21,  AS_CUM: 9,  AF_CUM: 0, },
    { CDATE: '2025-04-25', AREA: 'HULL',    YARD: 'HHI',   STAGE: 'ASSEMBLY', PS_WK: 2,  PF_WK: 2,  AS_WK: 0,  AF_WK: 0, PS_CUM: 36,  PF_CUM: 23,  AS_CUM: 9,  AF_CUM: 0, },
    { CDATE: '2025-05-02', AREA: 'HULL',    YARD: 'HHI',   STAGE: 'ASSEMBLY', PS_WK: 2,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 38,  PF_CUM: 23,  AS_CUM: 9,  AF_CUM: 0, },
    { CDATE: '2025-05-09', AREA: 'HULL',    YARD: 'HHI',   STAGE: 'ASSEMBLY', PS_WK: 0,  PF_WK: 1,  AS_WK: 0,  AF_WK: 0, PS_CUM: 38,  PF_CUM: 24,  AS_CUM: 9,  AF_CUM: 0, },
    { CDATE: '2025-05-16', AREA: 'HULL',    YARD: 'HHI',   STAGE: 'ASSEMBLY', PS_WK: 0,  PF_WK: 2,  AS_WK: 0,  AF_WK: 0, PS_CUM: 38,  PF_CUM: 26,  AS_CUM: 9,  AF_CUM: 0, },
    { CDATE: '2025-05-23', AREA: 'HULL',    YARD: 'HHI',   STAGE: 'ASSEMBLY', PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 38,  PF_CUM: 26,  AS_CUM: 9,  AF_CUM: 0, },
    { CDATE: '2025-05-30', AREA: 'HULL',    YARD: 'HHI',   STAGE: 'ASSEMBLY', PS_WK: 2,  PF_WK: 2,  AS_WK: 0,  AF_WK: 0, PS_CUM: 40,  PF_CUM: 28,  AS_CUM: 9,  AF_CUM: 0, },
    { CDATE: '2025-06-06', AREA: 'HULL',    YARD: 'HHI',   STAGE: 'ASSEMBLY', PS_WK: 0,  PF_WK: 4,  AS_WK: 0,  AF_WK: 0, PS_CUM: 40,  PF_CUM: 32,  AS_CUM: 9,  AF_CUM: 0, },
    { CDATE: '2025-06-13', AREA: 'HULL',    YARD: 'HHI',   STAGE: 'ASSEMBLY', PS_WK: 2,  PF_WK: 2,  AS_WK: 0,  AF_WK: 0, PS_CUM: 42,  PF_CUM: 34,  AS_CUM: 9,  AF_CUM: 0, },
    { CDATE: '2025-06-20', AREA: 'HULL',    YARD: 'HHI',   STAGE: 'ASSEMBLY', PS_WK: 0,  PF_WK: 2,  AS_WK: 0,  AF_WK: 0, PS_CUM: 42,  PF_CUM: 36,  AS_CUM: 9,  AF_CUM: 0, },
    { CDATE: '2025-06-27', AREA: 'HULL',    YARD: 'HHI',   STAGE: 'ASSEMBLY', PS_WK: 0,  PF_WK: 2,  AS_WK: 0,  AF_WK: 0, PS_CUM: 42,  PF_CUM: 38,  AS_CUM: 9,  AF_CUM: 0, },
    { CDATE: '2025-07-04', AREA: 'HULL',    YARD: 'HHI',   STAGE: 'ASSEMBLY', PS_WK: 2,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 44,  PF_CUM: 38,  AS_CUM: 9,  AF_CUM: 0, },
    { CDATE: '2025-07-11', AREA: 'HULL',    YARD: 'HHI',   STAGE: 'ASSEMBLY', PS_WK: 2,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 46,  PF_CUM: 38,  AS_CUM: 9,  AF_CUM: 0, },
    { CDATE: '2025-07-18', AREA: 'HULL',    YARD: 'HHI',   STAGE: 'ASSEMBLY', PS_WK: 0,  PF_WK: 2,  AS_WK: 0,  AF_WK: 0, PS_CUM: 46,  PF_CUM: 40,  AS_CUM: 9,  AF_CUM: 0, },
    { CDATE: '2025-07-25', AREA: 'HULL',    YARD: 'HHI',   STAGE: 'ASSEMBLY', PS_WK: 4,  PF_WK: 2,  AS_WK: 0,  AF_WK: 0, PS_CUM: 50,  PF_CUM: 42,  AS_CUM: 9,  AF_CUM: 0, },
    { CDATE: '2025-08-01', AREA: 'HULL',    YARD: 'HHI',   STAGE: 'ASSEMBLY', PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 50,  PF_CUM: 42,  AS_CUM: 9,  AF_CUM: 0, },
    { CDATE: '2025-08-08', AREA: 'HULL',    YARD: 'HHI',   STAGE: 'ASSEMBLY', PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 50,  PF_CUM: 42,  AS_CUM: 9,  AF_CUM: 0, },
    { CDATE: '2025-08-15', AREA: 'HULL',    YARD: 'HHI',   STAGE: 'ASSEMBLY', PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 50,  PF_CUM: 42,  AS_CUM: 9,  AF_CUM: 0, },
    { CDATE: '2025-08-22', AREA: 'HULL',    YARD: 'HHI',   STAGE: 'ASSEMBLY', PS_WK: 0,  PF_WK: 2,  AS_WK: 0,  AF_WK: 0, PS_CUM: 50,  PF_CUM: 44,  AS_CUM: 9,  AF_CUM: 0, },
    { CDATE: '2025-08-29', AREA: 'HULL',    YARD: 'HHI',   STAGE: 'ASSEMBLY', PS_WK: 0,  PF_WK: 2,  AS_WK: 0,  AF_WK: 0, PS_CUM: 50,  PF_CUM: 46,  AS_CUM: 9,  AF_CUM: 0, },
    { CDATE: '2025-09-05', AREA: 'HULL',    YARD: 'HHI',   STAGE: 'ASSEMBLY', PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 50,  PF_CUM: 46,  AS_CUM: 9,  AF_CUM: 0, },
    { CDATE: '2025-09-12', AREA: 'HULL',    YARD: 'HHI',   STAGE: 'ASSEMBLY', PS_WK: 0,  PF_WK: 4,  AS_WK: 0,  AF_WK: 0, PS_CUM: 50,  PF_CUM: 50,  AS_CUM: 9,  AF_CUM: 0, },
    { CDATE: '2025-09-19', AREA: 'HULL',    YARD: 'HHI',   STAGE: 'ASSEMBLY', PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 50,  PF_CUM: 50,  AS_CUM: 9,  AF_CUM: 0, },
    { CDATE: '2025-09-26', AREA: 'HULL',    YARD: 'HHI',   STAGE: 'ASSEMBLY', PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 50,  PF_CUM: 50,  AS_CUM: 9,  AF_CUM: 0, },
    { CDATE: '2025-10-03', AREA: 'HULL',    YARD: 'HHI',   STAGE: 'ASSEMBLY', PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 50,  PF_CUM: 50,  AS_CUM: 9,  AF_CUM: 0, },
    { CDATE: '2025-10-10', AREA: 'HULL',    YARD: 'HHI',   STAGE: 'ASSEMBLY', PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 50,  PF_CUM: 50,  AS_CUM: 9,  AF_CUM: 0, },
    { CDATE: '2025-10-17', AREA: 'HULL',    YARD: 'HHI',   STAGE: 'ASSEMBLY', PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 50,  PF_CUM: 50,  AS_CUM: 9,  AF_CUM: 0, },
    { CDATE: '2025-10-24', AREA: 'HULL',    YARD: 'HHI',   STAGE: 'ASSEMBLY', PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 50,  PF_CUM: 50,  AS_CUM: 9,  AF_CUM: 0, },
    { CDATE: '2025-10-31', AREA: 'HULL',    YARD: 'HHI',   STAGE: 'ASSEMBLY', PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 50,  PF_CUM: 50,  AS_CUM: 9,  AF_CUM: 0, },
    { CDATE: '2025-11-07', AREA: 'HULL',    YARD: 'HHI',   STAGE: 'ASSEMBLY', PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 50,  PF_CUM: 50,  AS_CUM: 9,  AF_CUM: 0, },
    { CDATE: '2025-11-14', AREA: 'HULL',    YARD: 'HHI',   STAGE: 'ASSEMBLY', PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 50,  PF_CUM: 50,  AS_CUM: 9,  AF_CUM: 0, },
    { CDATE: '2025-11-21', AREA: 'HULL',    YARD: 'HHI',   STAGE: 'ASSEMBLY', PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 50,  PF_CUM: 50,  AS_CUM: 9,  AF_CUM: 0, },
    { CDATE: '2025-11-28', AREA: 'HULL',    YARD: 'HHI',   STAGE: 'ASSEMBLY', PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 50,  PF_CUM: 50,  AS_CUM: 9,  AF_CUM: 0, },
    { CDATE: '2025-12-05', AREA: 'HULL',    YARD: 'HHI',   STAGE: 'ASSEMBLY', PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 50,  PF_CUM: 50,  AS_CUM: 9,  AF_CUM: 0, },
    { CDATE: '2025-12-12', AREA: 'HULL',    YARD: 'HHI',   STAGE: 'ASSEMBLY', PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 50,  PF_CUM: 50,  AS_CUM: 9,  AF_CUM: 0, },
    { CDATE: '2025-12-19', AREA: 'HULL',    YARD: 'HHI',   STAGE: 'ASSEMBLY', PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 50,  PF_CUM: 50,  AS_CUM: 9,  AF_CUM: 0, },
    { CDATE: '2025-12-26', AREA: 'HULL',    YARD: 'HHI',   STAGE: 'ASSEMBLY', PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 50,  PF_CUM: 50,  AS_CUM: 9,  AF_CUM: 0, },
    { CDATE: '2026-01-02', AREA: 'HULL',    YARD: 'HHI',   STAGE: 'ASSEMBLY', PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 50,  PF_CUM: 50,  AS_CUM: 9,  AF_CUM: 0, },
    { CDATE: '2026-01-09', AREA: 'HULL',    YARD: 'HHI',   STAGE: 'ASSEMBLY', PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 50,  PF_CUM: 50,  AS_CUM: 9,  AF_CUM: 0, },
    { CDATE: '2026-01-16', AREA: 'HULL',    YARD: 'HHI',   STAGE: 'ASSEMBLY', PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 50,  PF_CUM: 50,  AS_CUM: 9,  AF_CUM: 0, },
    { CDATE: '2026-01-23', AREA: 'HULL',    YARD: 'HHI',   STAGE: 'ASSEMBLY', PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 50,  PF_CUM: 50,  AS_CUM: 9,  AF_CUM: 0, },
    { CDATE: '2026-01-30', AREA: 'HULL',    YARD: 'HHI',   STAGE: 'ASSEMBLY', PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 50,  PF_CUM: 50,  AS_CUM: 9,  AF_CUM: 0, },
    { CDATE: '2026-02-06', AREA: 'HULL',    YARD: 'HHI',   STAGE: 'ASSEMBLY', PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 50,  PF_CUM: 50,  AS_CUM: 9,  AF_CUM: 0, },
    { CDATE: '2026-02-13', AREA: 'HULL',    YARD: 'HHI',   STAGE: 'ASSEMBLY', PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 50,  PF_CUM: 50,  AS_CUM: 9,  AF_CUM: 0, },
    { CDATE: '2026-02-20', AREA: 'HULL',    YARD: 'HHI',   STAGE: 'ASSEMBLY', PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 50,  PF_CUM: 50,  AS_CUM: 9,  AF_CUM: 0, },
    { CDATE: '2026-02-27', AREA: 'HULL',    YARD: 'HHI',   STAGE: 'ASSEMBLY', PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 50,  PF_CUM: 50,  AS_CUM: 9,  AF_CUM: 0, },
    { CDATE: '2024-10-04', AREA: 'HULL',    YARD: 'HHI',   STAGE: 'CUTTING',  PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 0,   PF_CUM: 0,   AS_CUM: 0,  AF_CUM: 0, },
    { CDATE: '2024-10-11', AREA: 'HULL',    YARD: 'HHI',   STAGE: 'CUTTING',  PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 0,   PF_CUM: 0,   AS_CUM: 0,  AF_CUM: 0, },
    { CDATE: '2024-10-18', AREA: 'HULL',    YARD: 'HHI',   STAGE: 'CUTTING',  PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 0,   PF_CUM: 0,   AS_CUM: 0,  AF_CUM: 0, },
    { CDATE: '2024-10-25', AREA: 'HULL',    YARD: 'HHI',   STAGE: 'CUTTING',  PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 0,   PF_CUM: 0,   AS_CUM: 0,  AF_CUM: 0, },
    { CDATE: '2024-11-01', AREA: 'HULL',    YARD: 'HHI',   STAGE: 'CUTTING',  PS_WK: 1,  PF_WK: 0,  AS_WK: 1,  AF_WK: 0, PS_CUM: 1,   PF_CUM: 0,   AS_CUM: 1,  AF_CUM: 0, },
    { CDATE: '2024-11-08', AREA: 'HULL',    YARD: 'HHI',   STAGE: 'CUTTING',  PS_WK: 7,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 8,   PF_CUM: 0,   AS_CUM: 1,  AF_CUM: 0, },
    { CDATE: '2024-11-15', AREA: 'HULL',    YARD: 'HHI',   STAGE: 'CUTTING',  PS_WK: 3,  PF_WK: 1,  AS_WK: 5,  AF_WK: 2, PS_CUM: 11,  PF_CUM: 1,   AS_CUM: 6,  AF_CUM: 2, },
    { CDATE: '2024-11-22', AREA: 'HULL',    YARD: 'HHI',   STAGE: 'CUTTING',  PS_WK: 1,  PF_WK: 5,  AS_WK: 5,  AF_WK: 4, PS_CUM: 12,  PF_CUM: 6,   AS_CUM: 11, AF_CUM: 6, },
    { CDATE: '2024-11-29', AREA: 'HULL',    YARD: 'HHI',   STAGE: 'CUTTING',  PS_WK: 0,  PF_WK: 2,  AS_WK: 1,  AF_WK: 2, PS_CUM: 12,  PF_CUM: 8,   AS_CUM: 12, AF_CUM: 8, },
    { CDATE: '2024-12-06', AREA: 'HULL',    YARD: 'HHI',   STAGE: 'CUTTING',  PS_WK: 2,  PF_WK: 3,  AS_WK: 2,  AF_WK: 4, PS_CUM: 14,  PF_CUM: 11,  AS_CUM: 14, AF_CUM: 12, },
    { CDATE: '2024-12-13', AREA: 'HULL',    YARD: 'HHI',   STAGE: 'CUTTING',  PS_WK: 3,  PF_WK: 1,  AS_WK: 1,  AF_WK: 0, PS_CUM: 17,  PF_CUM: 12,  AS_CUM: 15, AF_CUM: 12, },
    { CDATE: '2024-12-20', AREA: 'HULL',    YARD: 'HHI',   STAGE: 'CUTTING',  PS_WK: 8,  PF_WK: 0,  AS_WK: 2,  AF_WK: 1, PS_CUM: 25,  PF_CUM: 12,  AS_CUM: 17, AF_CUM: 13, },
    { CDATE: '2024-12-27', AREA: 'HULL',    YARD: 'HHI',   STAGE: 'CUTTING',  PS_WK: 0,  PF_WK: 4,  AS_WK: 5,  AF_WK: 4, PS_CUM: 25,  PF_CUM: 16,  AS_CUM: 22, AF_CUM: 17, },
    { CDATE: '2025-01-03', AREA: 'HULL',    YARD: 'HHI',   STAGE: 'CUTTING',  PS_WK: 1,  PF_WK: 1,  AS_WK: 3,  AF_WK: 0, PS_CUM: 26,  PF_CUM: 17,  AS_CUM: 25, AF_CUM: 17, },
    { CDATE: '2025-01-10', AREA: 'HULL',    YARD: 'HHI',   STAGE: 'CUTTING',  PS_WK: 1,  PF_WK: 5,  AS_WK: 1,  AF_WK: 5, PS_CUM: 27,  PF_CUM: 22,  AS_CUM: 26, AF_CUM: 22, },
    { CDATE: '2025-01-17', AREA: 'HULL',    YARD: 'HHI',   STAGE: 'CUTTING',  PS_WK: 4,  PF_WK: 3,  AS_WK: 4,  AF_WK: 5, PS_CUM: 31,  PF_CUM: 25,  AS_CUM: 30, AF_CUM: 27, },
    { CDATE: '2025-01-24', AREA: 'HULL',    YARD: 'HHI',   STAGE: 'CUTTING',  PS_WK: 1,  PF_WK: 1,  AS_WK: 0,  AF_WK: 0, PS_CUM: 32,  PF_CUM: 26,  AS_CUM: 30, AF_CUM: 27, },
    { CDATE: '2025-01-31', AREA: 'HULL',    YARD: 'HHI',   STAGE: 'CUTTING',  PS_WK: 0,  PF_WK: 1,  AS_WK: 0,  AF_WK: 0, PS_CUM: 32,  PF_CUM: 27,  AS_CUM: 30, AF_CUM: 27, },
    { CDATE: '2025-02-07', AREA: 'HULL',    YARD: 'HHI',   STAGE: 'CUTTING',  PS_WK: 6,  PF_WK: 3,  AS_WK: 0,  AF_WK: 0, PS_CUM: 38,  PF_CUM: 30,  AS_CUM: 30, AF_CUM: 27, },
    { CDATE: '2025-02-14', AREA: 'HULL',    YARD: 'HHI',   STAGE: 'CUTTING',  PS_WK: 3,  PF_WK: 1,  AS_WK: 0,  AF_WK: 0, PS_CUM: 41,  PF_CUM: 31,  AS_CUM: 30, AF_CUM: 27, },
    { CDATE: '2025-02-21', AREA: 'HULL',    YARD: 'HHI',   STAGE: 'CUTTING',  PS_WK: 5,  PF_WK: 5,  AS_WK: 0,  AF_WK: 0, PS_CUM: 46,  PF_CUM: 36,  AS_CUM: 30, AF_CUM: 27, },
    { CDATE: '2025-02-28', AREA: 'HULL',    YARD: 'HHI',   STAGE: 'CUTTING',  PS_WK: 3,  PF_WK: 5,  AS_WK: 0,  AF_WK: 0, PS_CUM: 49,  PF_CUM: 41,  AS_CUM: 30, AF_CUM: 27, },
    { CDATE: '2025-03-07', AREA: 'HULL',    YARD: 'HHI',   STAGE: 'CUTTING',  PS_WK: 5,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 54,  PF_CUM: 41,  AS_CUM: 30, AF_CUM: 27, },
    { CDATE: '2025-03-14', AREA: 'HULL',    YARD: 'HHI',   STAGE: 'CUTTING',  PS_WK: 0,  PF_WK: 8,  AS_WK: 0,  AF_WK: 0, PS_CUM: 54,  PF_CUM: 49,  AS_CUM: 30, AF_CUM: 27, },
    { CDATE: '2025-03-21', AREA: 'HULL',    YARD: 'HHI',   STAGE: 'CUTTING',  PS_WK: 1,  PF_WK: 2,  AS_WK: 0,  AF_WK: 0, PS_CUM: 55,  PF_CUM: 51,  AS_CUM: 30, AF_CUM: 27, },
    { CDATE: '2025-03-28', AREA: 'HULL',    YARD: 'HHI',   STAGE: 'CUTTING',  PS_WK: 11, PF_WK: 3,  AS_WK: 0,  AF_WK: 0, PS_CUM: 66,  PF_CUM: 54,  AS_CUM: 30, AF_CUM: 27, },
    { CDATE: '2025-04-04', AREA: 'HULL',    YARD: 'HHI',   STAGE: 'CUTTING',  PS_WK: 10, PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 76,  PF_CUM: 54,  AS_CUM: 30, AF_CUM: 27, },
    { CDATE: '2025-04-11', AREA: 'HULL',    YARD: 'HHI',   STAGE: 'CUTTING',  PS_WK: 6,  PF_WK: 5,  AS_WK: 0,  AF_WK: 0, PS_CUM: 82,  PF_CUM: 59,  AS_CUM: 30, AF_CUM: 27, },
    { CDATE: '2025-04-18', AREA: 'HULL',    YARD: 'HHI',   STAGE: 'CUTTING',  PS_WK: 10, PF_WK: 12, AS_WK: 0,  AF_WK: 0, PS_CUM: 92,  PF_CUM: 71,  AS_CUM: 30, AF_CUM: 27, },
    { CDATE: '2025-04-25', AREA: 'HULL',    YARD: 'HHI',   STAGE: 'CUTTING',  PS_WK: 2,  PF_WK: 10, AS_WK: 0,  AF_WK: 0, PS_CUM: 94,  PF_CUM: 81,  AS_CUM: 30, AF_CUM: 27, },
    { CDATE: '2025-05-02', AREA: 'HULL',    YARD: 'HHI',   STAGE: 'CUTTING',  PS_WK: 1,  PF_WK: 1,  AS_WK: 0,  AF_WK: 0, PS_CUM: 95,  PF_CUM: 82,  AS_CUM: 30, AF_CUM: 27, },
    { CDATE: '2025-05-09', AREA: 'HULL',    YARD: 'HHI',   STAGE: 'CUTTING',  PS_WK: 0,  PF_WK: 10, AS_WK: 0,  AF_WK: 0, PS_CUM: 95,  PF_CUM: 92,  AS_CUM: 30, AF_CUM: 27, },
    { CDATE: '2025-05-16', AREA: 'HULL',    YARD: 'HHI',   STAGE: 'CUTTING',  PS_WK: 0,  PF_WK: 2,  AS_WK: 0,  AF_WK: 0, PS_CUM: 95,  PF_CUM: 94,  AS_CUM: 30, AF_CUM: 27, },
    { CDATE: '2025-05-23', AREA: 'HULL',    YARD: 'HHI',   STAGE: 'CUTTING',  PS_WK: 2,  PF_WK: 1,  AS_WK: 0,  AF_WK: 0, PS_CUM: 97,  PF_CUM: 95,  AS_CUM: 30, AF_CUM: 27, },
    { CDATE: '2025-05-30', AREA: 'HULL',    YARD: 'HHI',   STAGE: 'CUTTING',  PS_WK: 9,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 106, PF_CUM: 95,  AS_CUM: 30, AF_CUM: 27, },
    { CDATE: '2025-06-06', AREA: 'HULL',    YARD: 'HHI',   STAGE: 'CUTTING',  PS_WK: 2,  PF_WK: 1,  AS_WK: 0,  AF_WK: 0, PS_CUM: 108, PF_CUM: 96,  AS_CUM: 30, AF_CUM: 27, },
    { CDATE: '2025-06-13', AREA: 'HULL',    YARD: 'HHI',   STAGE: 'CUTTING',  PS_WK: 7,  PF_WK: 1,  AS_WK: 0,  AF_WK: 0, PS_CUM: 115, PF_CUM: 97,  AS_CUM: 30, AF_CUM: 27, },
    { CDATE: '2025-06-20', AREA: 'HULL',    YARD: 'HHI',   STAGE: 'CUTTING',  PS_WK: 0,  PF_WK: 9,  AS_WK: 0,  AF_WK: 0, PS_CUM: 115, PF_CUM: 106, AS_CUM: 30, AF_CUM: 27, },
    { CDATE: '2025-06-27', AREA: 'HULL',    YARD: 'HHI',   STAGE: 'CUTTING',  PS_WK: 2,  PF_WK: 5,  AS_WK: 0,  AF_WK: 0, PS_CUM: 117, PF_CUM: 111, AS_CUM: 30, AF_CUM: 27, },
    { CDATE: '2025-07-04', AREA: 'HULL',    YARD: 'HHI',   STAGE: 'CUTTING',  PS_WK: 4,  PF_WK: 4,  AS_WK: 0,  AF_WK: 0, PS_CUM: 121, PF_CUM: 115, AS_CUM: 30, AF_CUM: 27, },
    { CDATE: '2025-07-11', AREA: 'HULL',    YARD: 'HHI',   STAGE: 'CUTTING',  PS_WK: 3,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 124, PF_CUM: 115, AS_CUM: 30, AF_CUM: 27, },
    { CDATE: '2025-07-18', AREA: 'HULL',    YARD: 'HHI',   STAGE: 'CUTTING',  PS_WK: 6,  PF_WK: 6,  AS_WK: 0,  AF_WK: 0, PS_CUM: 130, PF_CUM: 121, AS_CUM: 30, AF_CUM: 27, },
    { CDATE: '2025-07-25', AREA: 'HULL',    YARD: 'HHI',   STAGE: 'CUTTING',  PS_WK: 0,  PF_WK: 1,  AS_WK: 0,  AF_WK: 0, PS_CUM: 130, PF_CUM: 122, AS_CUM: 30, AF_CUM: 27, },
    { CDATE: '2025-08-01', AREA: 'HULL',    YARD: 'HHI',   STAGE: 'CUTTING',  PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 130, PF_CUM: 122, AS_CUM: 30, AF_CUM: 27, },
    { CDATE: '2025-08-08', AREA: 'HULL',    YARD: 'HHI',   STAGE: 'CUTTING',  PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 130, PF_CUM: 122, AS_CUM: 30, AF_CUM: 27, },
    { CDATE: '2025-08-15', AREA: 'HULL',    YARD: 'HHI',   STAGE: 'CUTTING',  PS_WK: 0,  PF_WK: 8,  AS_WK: 0,  AF_WK: 0, PS_CUM: 130, PF_CUM: 130, AS_CUM: 30, AF_CUM: 27, },
    { CDATE: '2025-08-22', AREA: 'HULL',    YARD: 'HHI',   STAGE: 'CUTTING',  PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 130, PF_CUM: 130, AS_CUM: 30, AF_CUM: 27, },
    { CDATE: '2025-08-29', AREA: 'HULL',    YARD: 'HHI',   STAGE: 'CUTTING',  PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 130, PF_CUM: 130, AS_CUM: 30, AF_CUM: 27, },
    { CDATE: '2025-09-05', AREA: 'HULL',    YARD: 'HHI',   STAGE: 'CUTTING',  PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 130, PF_CUM: 130, AS_CUM: 30, AF_CUM: 27, },
    { CDATE: '2025-09-12', AREA: 'HULL',    YARD: 'HHI',   STAGE: 'CUTTING',  PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 130, PF_CUM: 130, AS_CUM: 30, AF_CUM: 27, },
    { CDATE: '2025-09-19', AREA: 'HULL',    YARD: 'HHI',   STAGE: 'CUTTING',  PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 130, PF_CUM: 130, AS_CUM: 30, AF_CUM: 27, },
    { CDATE: '2025-09-26', AREA: 'HULL',    YARD: 'HHI',   STAGE: 'CUTTING',  PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 130, PF_CUM: 130, AS_CUM: 30, AF_CUM: 27, },
    { CDATE: '2025-10-03', AREA: 'HULL',    YARD: 'HHI',   STAGE: 'CUTTING',  PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 130, PF_CUM: 130, AS_CUM: 30, AF_CUM: 27, },
    { CDATE: '2025-10-10', AREA: 'HULL',    YARD: 'HHI',   STAGE: 'CUTTING',  PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 130, PF_CUM: 130, AS_CUM: 30, AF_CUM: 27, },
    { CDATE: '2025-10-17', AREA: 'HULL',    YARD: 'HHI',   STAGE: 'CUTTING',  PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 130, PF_CUM: 130, AS_CUM: 30, AF_CUM: 27, },
    { CDATE: '2025-10-24', AREA: 'HULL',    YARD: 'HHI',   STAGE: 'CUTTING',  PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 130, PF_CUM: 130, AS_CUM: 30, AF_CUM: 27, },
    { CDATE: '2025-10-31', AREA: 'HULL',    YARD: 'HHI',   STAGE: 'CUTTING',  PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 130, PF_CUM: 130, AS_CUM: 30, AF_CUM: 27, },
    { CDATE: '2025-11-07', AREA: 'HULL',    YARD: 'HHI',   STAGE: 'CUTTING',  PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 130, PF_CUM: 130, AS_CUM: 30, AF_CUM: 27, },
    { CDATE: '2025-11-14', AREA: 'HULL',    YARD: 'HHI',   STAGE: 'CUTTING',  PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 130, PF_CUM: 130, AS_CUM: 30, AF_CUM: 27, },
    { CDATE: '2025-11-21', AREA: 'HULL',    YARD: 'HHI',   STAGE: 'CUTTING',  PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 130, PF_CUM: 130, AS_CUM: 30, AF_CUM: 27, },
    { CDATE: '2025-11-28', AREA: 'HULL',    YARD: 'HHI',   STAGE: 'CUTTING',  PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 130, PF_CUM: 130, AS_CUM: 30, AF_CUM: 27, },
    { CDATE: '2025-12-05', AREA: 'HULL',    YARD: 'HHI',   STAGE: 'CUTTING',  PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 130, PF_CUM: 130, AS_CUM: 30, AF_CUM: 27, },
    { CDATE: '2025-12-12', AREA: 'HULL',    YARD: 'HHI',   STAGE: 'CUTTING',  PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 130, PF_CUM: 130, AS_CUM: 30, AF_CUM: 27, },
    { CDATE: '2025-12-19', AREA: 'HULL',    YARD: 'HHI',   STAGE: 'CUTTING',  PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 130, PF_CUM: 130, AS_CUM: 30, AF_CUM: 27, },
    { CDATE: '2025-12-26', AREA: 'HULL',    YARD: 'HHI',   STAGE: 'CUTTING',  PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 130, PF_CUM: 130, AS_CUM: 30, AF_CUM: 27, },
    { CDATE: '2026-01-02', AREA: 'HULL',    YARD: 'HHI',   STAGE: 'CUTTING',  PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 130, PF_CUM: 130, AS_CUM: 30, AF_CUM: 27, },
    { CDATE: '2026-01-09', AREA: 'HULL',    YARD: 'HHI',   STAGE: 'CUTTING',  PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 130, PF_CUM: 130, AS_CUM: 30, AF_CUM: 27, },
    { CDATE: '2026-01-16', AREA: 'HULL',    YARD: 'HHI',   STAGE: 'CUTTING',  PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 130, PF_CUM: 130, AS_CUM: 30, AF_CUM: 27, },
    { CDATE: '2026-01-23', AREA: 'HULL',    YARD: 'HHI',   STAGE: 'CUTTING',  PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 130, PF_CUM: 130, AS_CUM: 30, AF_CUM: 27, },
    { CDATE: '2026-01-30', AREA: 'HULL',    YARD: 'HHI',   STAGE: 'CUTTING',  PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 130, PF_CUM: 130, AS_CUM: 30, AF_CUM: 27, },
    { CDATE: '2026-02-06', AREA: 'HULL',    YARD: 'HHI',   STAGE: 'CUTTING',  PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 130, PF_CUM: 130, AS_CUM: 30, AF_CUM: 27, },
    { CDATE: '2026-02-13', AREA: 'HULL',    YARD: 'HHI',   STAGE: 'CUTTING',  PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 130, PF_CUM: 130, AS_CUM: 30, AF_CUM: 27, },
    { CDATE: '2026-02-20', AREA: 'HULL',    YARD: 'HHI',   STAGE: 'CUTTING',  PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 130, PF_CUM: 130, AS_CUM: 30, AF_CUM: 27, },
    { CDATE: '2026-02-27', AREA: 'HULL',    YARD: 'HHI',   STAGE: 'CUTTING',  PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 130, PF_CUM: 130, AS_CUM: 30, AF_CUM: 27, },
    { CDATE: '2024-10-04', AREA: 'HULL',    YARD: 'HHI',   STAGE: 'PAINT',    PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 0,   PF_CUM: 0,   AS_CUM: 0,  AF_CUM: 0, },
    { CDATE: '2024-10-11', AREA: 'HULL',    YARD: 'HHI',   STAGE: 'PAINT',    PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 0,   PF_CUM: 0,   AS_CUM: 0,  AF_CUM: 0, },
    { CDATE: '2024-10-18', AREA: 'HULL',    YARD: 'HHI',   STAGE: 'PAINT',    PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 0,   PF_CUM: 0,   AS_CUM: 0,  AF_CUM: 0, },
    { CDATE: '2024-10-25', AREA: 'HULL',    YARD: 'HHI',   STAGE: 'PAINT',    PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 0,   PF_CUM: 0,   AS_CUM: 0,  AF_CUM: 0, },
    { CDATE: '2024-11-01', AREA: 'HULL',    YARD: 'HHI',   STAGE: 'PAINT',    PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 0,   PF_CUM: 0,   AS_CUM: 0,  AF_CUM: 0, },
    { CDATE: '2024-11-08', AREA: 'HULL',    YARD: 'HHI',   STAGE: 'PAINT',    PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 0,   PF_CUM: 0,   AS_CUM: 0,  AF_CUM: 0, },
    { CDATE: '2024-11-15', AREA: 'HULL',    YARD: 'HHI',   STAGE: 'PAINT',    PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 0,   PF_CUM: 0,   AS_CUM: 0,  AF_CUM: 0, },
    { CDATE: '2024-11-22', AREA: 'HULL',    YARD: 'HHI',   STAGE: 'PAINT',    PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 0,   PF_CUM: 0,   AS_CUM: 0,  AF_CUM: 0, },
    { CDATE: '2024-11-29', AREA: 'HULL',    YARD: 'HHI',   STAGE: 'PAINT',    PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 0,   PF_CUM: 0,   AS_CUM: 0,  AF_CUM: 0, },
    { CDATE: '2024-12-06', AREA: 'HULL',    YARD: 'HHI',   STAGE: 'PAINT',    PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 0,   PF_CUM: 0,   AS_CUM: 0,  AF_CUM: 0, },
    { CDATE: '2024-12-13', AREA: 'HULL',    YARD: 'HHI',   STAGE: 'PAINT',    PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 0,   PF_CUM: 0,   AS_CUM: 0,  AF_CUM: 0, },
    { CDATE: '2024-12-20', AREA: 'HULL',    YARD: 'HHI',   STAGE: 'PAINT',    PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 0,   PF_CUM: 0,   AS_CUM: 0,  AF_CUM: 0, },
    { CDATE: '2024-12-27', AREA: 'HULL',    YARD: 'HHI',   STAGE: 'PAINT',    PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 0,   PF_CUM: 0,   AS_CUM: 0,  AF_CUM: 0, },
    { CDATE: '2025-01-03', AREA: 'HULL',    YARD: 'HHI',   STAGE: 'PAINT',    PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 0,   PF_CUM: 0,   AS_CUM: 0,  AF_CUM: 0, },
    { CDATE: '2025-01-10', AREA: 'HULL',    YARD: 'HHI',   STAGE: 'PAINT',    PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 0,   PF_CUM: 0,   AS_CUM: 0,  AF_CUM: 0, },
    { CDATE: '2025-01-17', AREA: 'HULL',    YARD: 'HHI',   STAGE: 'PAINT',    PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 0,   PF_CUM: 0,   AS_CUM: 0,  AF_CUM: 0, },
    { CDATE: '2025-01-24', AREA: 'HULL',    YARD: 'HHI',   STAGE: 'PAINT',    PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 0,   PF_CUM: 0,   AS_CUM: 0,  AF_CUM: 0, },
    { CDATE: '2025-01-31', AREA: 'HULL',    YARD: 'HHI',   STAGE: 'PAINT',    PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 0,   PF_CUM: 0,   AS_CUM: 0,  AF_CUM: 0, },
    { CDATE: '2025-02-07', AREA: 'HULL',    YARD: 'HHI',   STAGE: 'PAINT',    PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 0,   PF_CUM: 0,   AS_CUM: 0,  AF_CUM: 0, },
    { CDATE: '2025-02-14', AREA: 'HULL',    YARD: 'HHI',   STAGE: 'PAINT',    PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 0,   PF_CUM: 0,   AS_CUM: 0,  AF_CUM: 0, },
    { CDATE: '2025-02-21', AREA: 'HULL',    YARD: 'HHI',   STAGE: 'PAINT',    PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 0,   PF_CUM: 0,   AS_CUM: 0,  AF_CUM: 0, },
    { CDATE: '2025-02-28', AREA: 'HULL',    YARD: 'HHI',   STAGE: 'PAINT',    PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 0,   PF_CUM: 0,   AS_CUM: 0,  AF_CUM: 0, },
    { CDATE: '2025-03-07', AREA: 'HULL',    YARD: 'HHI',   STAGE: 'PAINT',    PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 0,   PF_CUM: 0,   AS_CUM: 0,  AF_CUM: 0, },
    { CDATE: '2025-03-14', AREA: 'HULL',    YARD: 'HHI',   STAGE: 'PAINT',    PS_WK: 2,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 2,   PF_CUM: 0,   AS_CUM: 0,  AF_CUM: 0, },
    { CDATE: '2025-03-21', AREA: 'HULL',    YARD: 'HHI',   STAGE: 'PAINT',    PS_WK: 3,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 5,   PF_CUM: 0,   AS_CUM: 0,  AF_CUM: 0, },
    { CDATE: '2025-03-28', AREA: 'HULL',    YARD: 'HHI',   STAGE: 'PAINT',    PS_WK: 2,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 7,   PF_CUM: 0,   AS_CUM: 0,  AF_CUM: 0, },
    { CDATE: '2025-04-04', AREA: 'HULL',    YARD: 'HHI',   STAGE: 'PAINT',    PS_WK: 1,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 8,   PF_CUM: 0,   AS_CUM: 0,  AF_CUM: 0, },
    { CDATE: '2025-04-11', AREA: 'HULL',    YARD: 'HHI',   STAGE: 'PAINT',    PS_WK: 1,  PF_WK: 2,  AS_WK: 0,  AF_WK: 0, PS_CUM: 9,   PF_CUM: 2,   AS_CUM: 0,  AF_CUM: 0, },
    { CDATE: '2025-04-18', AREA: 'HULL',    YARD: 'HHI',   STAGE: 'PAINT',    PS_WK: 0,  PF_WK: 3,  AS_WK: 0,  AF_WK: 0, PS_CUM: 9,   PF_CUM: 5,   AS_CUM: 0,  AF_CUM: 0, },
    { CDATE: '2025-04-25', AREA: 'HULL',    YARD: 'HHI',   STAGE: 'PAINT',    PS_WK: 1,  PF_WK: 3,  AS_WK: 0,  AF_WK: 0, PS_CUM: 10,  PF_CUM: 8,   AS_CUM: 0,  AF_CUM: 0, },
    { CDATE: '2025-05-02', AREA: 'HULL',    YARD: 'HHI',   STAGE: 'PAINT',    PS_WK: 4,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 14,  PF_CUM: 8,   AS_CUM: 0,  AF_CUM: 0, },
    { CDATE: '2025-05-09', AREA: 'HULL',    YARD: 'HHI',   STAGE: 'PAINT',    PS_WK: 1,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 15,  PF_CUM: 8,   AS_CUM: 0,  AF_CUM: 0, },
    { CDATE: '2025-05-16', AREA: 'HULL',    YARD: 'HHI',   STAGE: 'PAINT',    PS_WK: 3,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 18,  PF_CUM: 8,   AS_CUM: 0,  AF_CUM: 0, },
    { CDATE: '2025-05-23', AREA: 'HULL',    YARD: 'HHI',   STAGE: 'PAINT',    PS_WK: 0,  PF_WK: 2,  AS_WK: 0,  AF_WK: 0, PS_CUM: 18,  PF_CUM: 10,  AS_CUM: 0,  AF_CUM: 0, },
    { CDATE: '2025-05-30', AREA: 'HULL',    YARD: 'HHI',   STAGE: 'PAINT',    PS_WK: 1,  PF_WK: 5,  AS_WK: 0,  AF_WK: 0, PS_CUM: 19,  PF_CUM: 15,  AS_CUM: 0,  AF_CUM: 0, },
    { CDATE: '2025-06-06', AREA: 'HULL',    YARD: 'HHI',   STAGE: 'PAINT',    PS_WK: 0,  PF_WK: 3,  AS_WK: 0,  AF_WK: 0, PS_CUM: 19,  PF_CUM: 18,  AS_CUM: 0,  AF_CUM: 0, },
    { CDATE: '2025-06-13', AREA: 'HULL',    YARD: 'HHI',   STAGE: 'PAINT',    PS_WK: 1,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 20,  PF_CUM: 18,  AS_CUM: 0,  AF_CUM: 0, },
    { CDATE: '2025-06-20', AREA: 'HULL',    YARD: 'HHI',   STAGE: 'PAINT',    PS_WK: 1,  PF_WK: 1,  AS_WK: 0,  AF_WK: 0, PS_CUM: 21,  PF_CUM: 19,  AS_CUM: 0,  AF_CUM: 0, },
    { CDATE: '2025-06-27', AREA: 'HULL',    YARD: 'HHI',   STAGE: 'PAINT',    PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 21,  PF_CUM: 19,  AS_CUM: 0,  AF_CUM: 0, },
    { CDATE: '2025-07-04', AREA: 'HULL',    YARD: 'HHI',   STAGE: 'PAINT',    PS_WK: 4,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 25,  PF_CUM: 19,  AS_CUM: 0,  AF_CUM: 0, },
    { CDATE: '2025-07-11', AREA: 'HULL',    YARD: 'HHI',   STAGE: 'PAINT',    PS_WK: 0,  PF_WK: 1,  AS_WK: 0,  AF_WK: 0, PS_CUM: 25,  PF_CUM: 20,  AS_CUM: 0,  AF_CUM: 0, },
    { CDATE: '2025-07-18', AREA: 'HULL',    YARD: 'HHI',   STAGE: 'PAINT',    PS_WK: 2,  PF_WK: 1,  AS_WK: 0,  AF_WK: 0, PS_CUM: 27,  PF_CUM: 21,  AS_CUM: 0,  AF_CUM: 0, },
    { CDATE: '2025-07-25', AREA: 'HULL',    YARD: 'HHI',   STAGE: 'PAINT',    PS_WK: 4,  PF_WK: 4,  AS_WK: 0,  AF_WK: 0, PS_CUM: 31,  PF_CUM: 25,  AS_CUM: 0,  AF_CUM: 0, },
    { CDATE: '2025-08-01', AREA: 'HULL',    YARD: 'HHI',   STAGE: 'PAINT',    PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 31,  PF_CUM: 25,  AS_CUM: 0,  AF_CUM: 0, },
    { CDATE: '2025-08-08', AREA: 'HULL',    YARD: 'HHI',   STAGE: 'PAINT',    PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 31,  PF_CUM: 25,  AS_CUM: 0,  AF_CUM: 0, },
    { CDATE: '2025-08-15', AREA: 'HULL',    YARD: 'HHI',   STAGE: 'PAINT',    PS_WK: 2,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 33,  PF_CUM: 25,  AS_CUM: 0,  AF_CUM: 0, },
    { CDATE: '2025-08-22', AREA: 'HULL',    YARD: 'HHI',   STAGE: 'PAINT',    PS_WK: 1,  PF_WK: 2,  AS_WK: 0,  AF_WK: 0, PS_CUM: 34,  PF_CUM: 27,  AS_CUM: 0,  AF_CUM: 0, },
    { CDATE: '2025-08-29', AREA: 'HULL',    YARD: 'HHI',   STAGE: 'PAINT',    PS_WK: 2,  PF_WK: 2,  AS_WK: 0,  AF_WK: 0, PS_CUM: 36,  PF_CUM: 29,  AS_CUM: 0,  AF_CUM: 0, },
    { CDATE: '2025-09-05', AREA: 'HULL',    YARD: 'HHI',   STAGE: 'PAINT',    PS_WK: 7,  PF_WK: 4,  AS_WK: 0,  AF_WK: 0, PS_CUM: 43,  PF_CUM: 33,  AS_CUM: 0,  AF_CUM: 0, },
    { CDATE: '2025-09-12', AREA: 'HULL',    YARD: 'HHI',   STAGE: 'PAINT',    PS_WK: 8,  PF_WK: 1,  AS_WK: 0,  AF_WK: 0, PS_CUM: 51,  PF_CUM: 34,  AS_CUM: 0,  AF_CUM: 0, },
    { CDATE: '2025-09-19', AREA: 'HULL',    YARD: 'HHI',   STAGE: 'PAINT',    PS_WK: 5,  PF_WK: 1,  AS_WK: 0,  AF_WK: 0, PS_CUM: 56,  PF_CUM: 35,  AS_CUM: 0,  AF_CUM: 0, },
    { CDATE: '2025-09-26', AREA: 'HULL',    YARD: 'HHI',   STAGE: 'PAINT',    PS_WK: 3,  PF_WK: 4,  AS_WK: 0,  AF_WK: 0, PS_CUM: 59,  PF_CUM: 39,  AS_CUM: 0,  AF_CUM: 0, },
    { CDATE: '2025-10-03', AREA: 'HULL',    YARD: 'HHI',   STAGE: 'PAINT',    PS_WK: 4,  PF_WK: 2,  AS_WK: 0,  AF_WK: 0, PS_CUM: 63,  PF_CUM: 41,  AS_CUM: 0,  AF_CUM: 0, },
    { CDATE: '2025-10-10', AREA: 'HULL',    YARD: 'HHI',   STAGE: 'PAINT',    PS_WK: 0,  PF_WK: 2,  AS_WK: 0,  AF_WK: 0, PS_CUM: 63,  PF_CUM: 43,  AS_CUM: 0,  AF_CUM: 0, },
    { CDATE: '2025-10-17', AREA: 'HULL',    YARD: 'HHI',   STAGE: 'PAINT',    PS_WK: 2,  PF_WK: 12, AS_WK: 0,  AF_WK: 0, PS_CUM: 65,  PF_CUM: 55,  AS_CUM: 0,  AF_CUM: 0, },
    { CDATE: '2025-10-24', AREA: 'HULL',    YARD: 'HHI',   STAGE: 'PAINT',    PS_WK: 9,  PF_WK: 4,  AS_WK: 0,  AF_WK: 0, PS_CUM: 74,  PF_CUM: 59,  AS_CUM: 0,  AF_CUM: 0, },
    { CDATE: '2025-10-31', AREA: 'HULL',    YARD: 'HHI',   STAGE: 'PAINT',    PS_WK: 0,  PF_WK: 4,  AS_WK: 0,  AF_WK: 0, PS_CUM: 74,  PF_CUM: 63,  AS_CUM: 0,  AF_CUM: 0, },
    { CDATE: '2025-11-07', AREA: 'HULL',    YARD: 'HHI',   STAGE: 'PAINT',    PS_WK: 0,  PF_WK: 1,  AS_WK: 0,  AF_WK: 0, PS_CUM: 74,  PF_CUM: 64,  AS_CUM: 0,  AF_CUM: 0, },
    { CDATE: '2025-11-14', AREA: 'HULL',    YARD: 'HHI',   STAGE: 'PAINT',    PS_WK: 0,  PF_WK: 10, AS_WK: 0,  AF_WK: 0, PS_CUM: 74,  PF_CUM: 74,  AS_CUM: 0,  AF_CUM: 0, },
    { CDATE: '2025-11-21', AREA: 'HULL',    YARD: 'HHI',   STAGE: 'PAINT',    PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 74,  PF_CUM: 74,  AS_CUM: 0,  AF_CUM: 0, },
    { CDATE: '2025-11-28', AREA: 'HULL',    YARD: 'HHI',   STAGE: 'PAINT',    PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 74,  PF_CUM: 74,  AS_CUM: 0,  AF_CUM: 0, },
    { CDATE: '2025-12-05', AREA: 'HULL',    YARD: 'HHI',   STAGE: 'PAINT',    PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 74,  PF_CUM: 74,  AS_CUM: 0,  AF_CUM: 0, },
    { CDATE: '2025-12-12', AREA: 'HULL',    YARD: 'HHI',   STAGE: 'PAINT',    PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 74,  PF_CUM: 74,  AS_CUM: 0,  AF_CUM: 0, },
    { CDATE: '2025-12-19', AREA: 'HULL',    YARD: 'HHI',   STAGE: 'PAINT',    PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 74,  PF_CUM: 74,  AS_CUM: 0,  AF_CUM: 0, },
    { CDATE: '2025-12-26', AREA: 'HULL',    YARD: 'HHI',   STAGE: 'PAINT',    PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 74,  PF_CUM: 74,  AS_CUM: 0,  AF_CUM: 0, },
    { CDATE: '2026-01-02', AREA: 'HULL',    YARD: 'HHI',   STAGE: 'PAINT',    PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 74,  PF_CUM: 74,  AS_CUM: 0,  AF_CUM: 0, },
    { CDATE: '2026-01-09', AREA: 'HULL',    YARD: 'HHI',   STAGE: 'PAINT',    PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 74,  PF_CUM: 74,  AS_CUM: 0,  AF_CUM: 0, },
    { CDATE: '2026-01-16', AREA: 'HULL',    YARD: 'HHI',   STAGE: 'PAINT',    PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 74,  PF_CUM: 74,  AS_CUM: 0,  AF_CUM: 0, },
    { CDATE: '2026-01-23', AREA: 'HULL',    YARD: 'HHI',   STAGE: 'PAINT',    PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 74,  PF_CUM: 74,  AS_CUM: 0,  AF_CUM: 0, },
    { CDATE: '2026-01-30', AREA: 'HULL',    YARD: 'HHI',   STAGE: 'PAINT',    PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 74,  PF_CUM: 74,  AS_CUM: 0,  AF_CUM: 0, },
    { CDATE: '2026-02-06', AREA: 'HULL',    YARD: 'HHI',   STAGE: 'PAINT',    PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 74,  PF_CUM: 74,  AS_CUM: 0,  AF_CUM: 0, },
    { CDATE: '2026-02-13', AREA: 'HULL',    YARD: 'HHI',   STAGE: 'PAINT',    PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 74,  PF_CUM: 74,  AS_CUM: 0,  AF_CUM: 0, },
    { CDATE: '2026-02-20', AREA: 'HULL',    YARD: 'HHI',   STAGE: 'PAINT',    PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 74,  PF_CUM: 74,  AS_CUM: 0,  AF_CUM: 0, },
    { CDATE: '2026-02-27', AREA: 'HULL',    YARD: 'HHI',   STAGE: 'PAINT',    PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 74,  PF_CUM: 74,  AS_CUM: 0,  AF_CUM: 0, },
    { CDATE: '2024-10-04', AREA: 'LQ',      YARD: 'HHI',   STAGE: 'ASSEMBLY', PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 0,   PF_CUM: 0,   AS_CUM: 0,  AF_CUM: 0, },
    { CDATE: '2024-10-11', AREA: 'LQ',      YARD: 'HHI',   STAGE: 'ASSEMBLY', PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 0,   PF_CUM: 0,   AS_CUM: 0,  AF_CUM: 0, },
    { CDATE: '2024-10-18', AREA: 'LQ',      YARD: 'HHI',   STAGE: 'ASSEMBLY', PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 0,   PF_CUM: 0,   AS_CUM: 0,  AF_CUM: 0, },
    { CDATE: '2024-10-25', AREA: 'LQ',      YARD: 'HHI',   STAGE: 'ASSEMBLY', PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 0,   PF_CUM: 0,   AS_CUM: 0,  AF_CUM: 0, },
    { CDATE: '2024-11-01', AREA: 'LQ',      YARD: 'HHI',   STAGE: 'ASSEMBLY', PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 0,   PF_CUM: 0,   AS_CUM: 0,  AF_CUM: 0, },
    { CDATE: '2024-11-08', AREA: 'LQ',      YARD: 'HHI',   STAGE: 'ASSEMBLY', PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 0,   PF_CUM: 0,   AS_CUM: 0,  AF_CUM: 0, },
    { CDATE: '2024-11-15', AREA: 'LQ',      YARD: 'HHI',   STAGE: 'ASSEMBLY', PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 0,   PF_CUM: 0,   AS_CUM: 0,  AF_CUM: 0, },
    { CDATE: '2024-11-22', AREA: 'LQ',      YARD: 'HHI',   STAGE: 'ASSEMBLY', PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 0,   PF_CUM: 0,   AS_CUM: 0,  AF_CUM: 0, },
    { CDATE: '2024-11-29', AREA: 'LQ',      YARD: 'HHI',   STAGE: 'ASSEMBLY', PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 0,   PF_CUM: 0,   AS_CUM: 0,  AF_CUM: 0, },
    { CDATE: '2024-12-06', AREA: 'LQ',      YARD: 'HHI',   STAGE: 'ASSEMBLY', PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 0,   PF_CUM: 0,   AS_CUM: 0,  AF_CUM: 0, },
    { CDATE: '2024-12-13', AREA: 'LQ',      YARD: 'HHI',   STAGE: 'ASSEMBLY', PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 0,   PF_CUM: 0,   AS_CUM: 0,  AF_CUM: 0, },
    { CDATE: '2024-12-20', AREA: 'LQ',      YARD: 'HHI',   STAGE: 'ASSEMBLY', PS_WK: 3,  PF_WK: 0,  AS_WK: 3,  AF_WK: 0, PS_CUM: 3,   PF_CUM: 0,   AS_CUM: 3,  AF_CUM: 0, },
    { CDATE: '2024-12-27', AREA: 'LQ',      YARD: 'HHI',   STAGE: 'ASSEMBLY', PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 3,   PF_CUM: 0,   AS_CUM: 3,  AF_CUM: 0, },
    { CDATE: '2025-01-03', AREA: 'LQ',      YARD: 'HHI',   STAGE: 'ASSEMBLY', PS_WK: 3,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 6,   PF_CUM: 0,   AS_CUM: 3,  AF_CUM: 0, },
    { CDATE: '2025-01-10', AREA: 'LQ',      YARD: 'HHI',   STAGE: 'ASSEMBLY', PS_WK: 3,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 9,   PF_CUM: 0,   AS_CUM: 3,  AF_CUM: 0, },
    { CDATE: '2025-01-17', AREA: 'LQ',      YARD: 'HHI',   STAGE: 'ASSEMBLY', PS_WK: 0,  PF_WK: 0,  AS_WK: 2,  AF_WK: 0, PS_CUM: 9,   PF_CUM: 0,   AS_CUM: 5,  AF_CUM: 0, },
    { CDATE: '2025-01-24', AREA: 'LQ',      YARD: 'HHI',   STAGE: 'ASSEMBLY', PS_WK: 3,  PF_WK: 0,  AS_WK: 4,  AF_WK: 0, PS_CUM: 12,  PF_CUM: 0,   AS_CUM: 9,  AF_CUM: 0, },
    { CDATE: '2025-01-31', AREA: 'LQ',      YARD: 'HHI',   STAGE: 'ASSEMBLY', PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 12,  PF_CUM: 0,   AS_CUM: 9,  AF_CUM: 0, },
    { CDATE: '2025-02-07', AREA: 'LQ',      YARD: 'HHI',   STAGE: 'ASSEMBLY', PS_WK: 8,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 20,  PF_CUM: 0,   AS_CUM: 9,  AF_CUM: 0, },
    { CDATE: '2025-02-14', AREA: 'LQ',      YARD: 'HHI',   STAGE: 'ASSEMBLY', PS_WK: 2,  PF_WK: 3,  AS_WK: 0,  AF_WK: 0, PS_CUM: 22,  PF_CUM: 3,   AS_CUM: 9,  AF_CUM: 0, },
    { CDATE: '2025-02-21', AREA: 'LQ',      YARD: 'HHI',   STAGE: 'ASSEMBLY', PS_WK: 6,  PF_WK: 2,  AS_WK: 0,  AF_WK: 0, PS_CUM: 28,  PF_CUM: 5,   AS_CUM: 9,  AF_CUM: 0, },
    { CDATE: '2025-02-28', AREA: 'LQ',      YARD: 'HHI',   STAGE: 'ASSEMBLY', PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 28,  PF_CUM: 5,   AS_CUM: 9,  AF_CUM: 0, },
    { CDATE: '2025-03-07', AREA: 'LQ',      YARD: 'HHI',   STAGE: 'ASSEMBLY', PS_WK: 0,  PF_WK: 1,  AS_WK: 0,  AF_WK: 0, PS_CUM: 28,  PF_CUM: 6,   AS_CUM: 9,  AF_CUM: 0, },
    { CDATE: '2025-03-14', AREA: 'LQ',      YARD: 'HHI',   STAGE: 'ASSEMBLY', PS_WK: 0,  PF_WK: 5,  AS_WK: 0,  AF_WK: 0, PS_CUM: 28,  PF_CUM: 11,  AS_CUM: 9,  AF_CUM: 0, },
    { CDATE: '2025-03-21', AREA: 'LQ',      YARD: 'HHI',   STAGE: 'ASSEMBLY', PS_WK: 0,  PF_WK: 7,  AS_WK: 0,  AF_WK: 0, PS_CUM: 28,  PF_CUM: 18,  AS_CUM: 9,  AF_CUM: 0, },
    { CDATE: '2025-03-28', AREA: 'LQ',      YARD: 'HHI',   STAGE: 'ASSEMBLY', PS_WK: 0,  PF_WK: 4,  AS_WK: 0,  AF_WK: 0, PS_CUM: 28,  PF_CUM: 22,  AS_CUM: 9,  AF_CUM: 0, },
    { CDATE: '2025-04-04', AREA: 'LQ',      YARD: 'HHI',   STAGE: 'ASSEMBLY', PS_WK: 0,  PF_WK: 6,  AS_WK: 0,  AF_WK: 0, PS_CUM: 28,  PF_CUM: 28,  AS_CUM: 9,  AF_CUM: 0, },
    { CDATE: '2025-04-11', AREA: 'LQ',      YARD: 'HHI',   STAGE: 'ASSEMBLY', PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 28,  PF_CUM: 28,  AS_CUM: 9,  AF_CUM: 0, },
    { CDATE: '2025-04-18', AREA: 'LQ',      YARD: 'HHI',   STAGE: 'ASSEMBLY', PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 28,  PF_CUM: 28,  AS_CUM: 9,  AF_CUM: 0, },
    { CDATE: '2025-04-25', AREA: 'LQ',      YARD: 'HHI',   STAGE: 'ASSEMBLY', PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 28,  PF_CUM: 28,  AS_CUM: 9,  AF_CUM: 0, },
    { CDATE: '2025-05-02', AREA: 'LQ',      YARD: 'HHI',   STAGE: 'ASSEMBLY', PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 28,  PF_CUM: 28,  AS_CUM: 9,  AF_CUM: 0, },
    { CDATE: '2025-05-09', AREA: 'LQ',      YARD: 'HHI',   STAGE: 'ASSEMBLY', PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 28,  PF_CUM: 28,  AS_CUM: 9,  AF_CUM: 0, },
    { CDATE: '2025-05-16', AREA: 'LQ',      YARD: 'HHI',   STAGE: 'ASSEMBLY', PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 28,  PF_CUM: 28,  AS_CUM: 9,  AF_CUM: 0, },
    { CDATE: '2025-05-23', AREA: 'LQ',      YARD: 'HHI',   STAGE: 'ASSEMBLY', PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 28,  PF_CUM: 28,  AS_CUM: 9,  AF_CUM: 0, },
    { CDATE: '2025-05-30', AREA: 'LQ',      YARD: 'HHI',   STAGE: 'ASSEMBLY', PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 28,  PF_CUM: 28,  AS_CUM: 9,  AF_CUM: 0, },
    { CDATE: '2025-06-06', AREA: 'LQ',      YARD: 'HHI',   STAGE: 'ASSEMBLY', PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 28,  PF_CUM: 28,  AS_CUM: 9,  AF_CUM: 0, },
    { CDATE: '2025-06-13', AREA: 'LQ',      YARD: 'HHI',   STAGE: 'ASSEMBLY', PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 28,  PF_CUM: 28,  AS_CUM: 9,  AF_CUM: 0, },
    { CDATE: '2025-06-20', AREA: 'LQ',      YARD: 'HHI',   STAGE: 'ASSEMBLY', PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 28,  PF_CUM: 28,  AS_CUM: 9,  AF_CUM: 0, },
    { CDATE: '2025-06-27', AREA: 'LQ',      YARD: 'HHI',   STAGE: 'ASSEMBLY', PS_WK: 1,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 29,  PF_CUM: 28,  AS_CUM: 9,  AF_CUM: 0, },
    { CDATE: '2025-07-04', AREA: 'LQ',      YARD: 'HHI',   STAGE: 'ASSEMBLY', PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 29,  PF_CUM: 28,  AS_CUM: 9,  AF_CUM: 0, },
    { CDATE: '2025-07-11', AREA: 'LQ',      YARD: 'HHI',   STAGE: 'ASSEMBLY', PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 29,  PF_CUM: 28,  AS_CUM: 9,  AF_CUM: 0, },
    { CDATE: '2025-07-18', AREA: 'LQ',      YARD: 'HHI',   STAGE: 'ASSEMBLY', PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 29,  PF_CUM: 28,  AS_CUM: 9,  AF_CUM: 0, },
    { CDATE: '2025-07-25', AREA: 'LQ',      YARD: 'HHI',   STAGE: 'ASSEMBLY', PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 29,  PF_CUM: 28,  AS_CUM: 9,  AF_CUM: 0, },
    { CDATE: '2025-08-01', AREA: 'LQ',      YARD: 'HHI',   STAGE: 'ASSEMBLY', PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 29,  PF_CUM: 28,  AS_CUM: 9,  AF_CUM: 0, },
    { CDATE: '2025-08-08', AREA: 'LQ',      YARD: 'HHI',   STAGE: 'ASSEMBLY', PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 29,  PF_CUM: 28,  AS_CUM: 9,  AF_CUM: 0, },
    { CDATE: '2025-08-15', AREA: 'LQ',      YARD: 'HHI',   STAGE: 'ASSEMBLY', PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 29,  PF_CUM: 28,  AS_CUM: 9,  AF_CUM: 0, },
    { CDATE: '2025-08-22', AREA: 'LQ',      YARD: 'HHI',   STAGE: 'ASSEMBLY', PS_WK: 0,  PF_WK: 1,  AS_WK: 0,  AF_WK: 0, PS_CUM: 29,  PF_CUM: 29,  AS_CUM: 9,  AF_CUM: 0, },
    { CDATE: '2025-08-29', AREA: 'LQ',      YARD: 'HHI',   STAGE: 'ASSEMBLY', PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 29,  PF_CUM: 29,  AS_CUM: 9,  AF_CUM: 0, },
    { CDATE: '2025-09-05', AREA: 'LQ',      YARD: 'HHI',   STAGE: 'ASSEMBLY', PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 29,  PF_CUM: 29,  AS_CUM: 9,  AF_CUM: 0, },
    { CDATE: '2025-09-12', AREA: 'LQ',      YARD: 'HHI',   STAGE: 'ASSEMBLY', PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 29,  PF_CUM: 29,  AS_CUM: 9,  AF_CUM: 0, },
    { CDATE: '2025-09-19', AREA: 'LQ',      YARD: 'HHI',   STAGE: 'ASSEMBLY', PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 29,  PF_CUM: 29,  AS_CUM: 9,  AF_CUM: 0, },
    { CDATE: '2025-09-26', AREA: 'LQ',      YARD: 'HHI',   STAGE: 'ASSEMBLY', PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 29,  PF_CUM: 29,  AS_CUM: 9,  AF_CUM: 0, },
    { CDATE: '2025-10-03', AREA: 'LQ',      YARD: 'HHI',   STAGE: 'ASSEMBLY', PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 29,  PF_CUM: 29,  AS_CUM: 9,  AF_CUM: 0, },
    { CDATE: '2025-10-10', AREA: 'LQ',      YARD: 'HHI',   STAGE: 'ASSEMBLY', PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 29,  PF_CUM: 29,  AS_CUM: 9,  AF_CUM: 0, },
    { CDATE: '2025-10-17', AREA: 'LQ',      YARD: 'HHI',   STAGE: 'ASSEMBLY', PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 29,  PF_CUM: 29,  AS_CUM: 9,  AF_CUM: 0, },
    { CDATE: '2025-10-24', AREA: 'LQ',      YARD: 'HHI',   STAGE: 'ASSEMBLY', PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 29,  PF_CUM: 29,  AS_CUM: 9,  AF_CUM: 0, },
    { CDATE: '2025-10-31', AREA: 'LQ',      YARD: 'HHI',   STAGE: 'ASSEMBLY', PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 29,  PF_CUM: 29,  AS_CUM: 9,  AF_CUM: 0, },
    { CDATE: '2025-11-07', AREA: 'LQ',      YARD: 'HHI',   STAGE: 'ASSEMBLY', PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 29,  PF_CUM: 29,  AS_CUM: 9,  AF_CUM: 0, },
    { CDATE: '2025-11-14', AREA: 'LQ',      YARD: 'HHI',   STAGE: 'ASSEMBLY', PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 29,  PF_CUM: 29,  AS_CUM: 9,  AF_CUM: 0, },
    { CDATE: '2025-11-21', AREA: 'LQ',      YARD: 'HHI',   STAGE: 'ASSEMBLY', PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 29,  PF_CUM: 29,  AS_CUM: 9,  AF_CUM: 0, },
    { CDATE: '2025-11-28', AREA: 'LQ',      YARD: 'HHI',   STAGE: 'ASSEMBLY', PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 29,  PF_CUM: 29,  AS_CUM: 9,  AF_CUM: 0, },
    { CDATE: '2025-12-05', AREA: 'LQ',      YARD: 'HHI',   STAGE: 'ASSEMBLY', PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 29,  PF_CUM: 29,  AS_CUM: 9,  AF_CUM: 0, },
    { CDATE: '2025-12-12', AREA: 'LQ',      YARD: 'HHI',   STAGE: 'ASSEMBLY', PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 29,  PF_CUM: 29,  AS_CUM: 9,  AF_CUM: 0, },
    { CDATE: '2025-12-19', AREA: 'LQ',      YARD: 'HHI',   STAGE: 'ASSEMBLY', PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 29,  PF_CUM: 29,  AS_CUM: 9,  AF_CUM: 0, },
    { CDATE: '2025-12-26', AREA: 'LQ',      YARD: 'HHI',   STAGE: 'ASSEMBLY', PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 29,  PF_CUM: 29,  AS_CUM: 9,  AF_CUM: 0, },
    { CDATE: '2026-01-02', AREA: 'LQ',      YARD: 'HHI',   STAGE: 'ASSEMBLY', PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 29,  PF_CUM: 29,  AS_CUM: 9,  AF_CUM: 0, },
    { CDATE: '2026-01-09', AREA: 'LQ',      YARD: 'HHI',   STAGE: 'ASSEMBLY', PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 29,  PF_CUM: 29,  AS_CUM: 9,  AF_CUM: 0, },
    { CDATE: '2026-01-16', AREA: 'LQ',      YARD: 'HHI',   STAGE: 'ASSEMBLY', PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 29,  PF_CUM: 29,  AS_CUM: 9,  AF_CUM: 0, },
    { CDATE: '2026-01-23', AREA: 'LQ',      YARD: 'HHI',   STAGE: 'ASSEMBLY', PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 29,  PF_CUM: 29,  AS_CUM: 9,  AF_CUM: 0, },
    { CDATE: '2026-01-30', AREA: 'LQ',      YARD: 'HHI',   STAGE: 'ASSEMBLY', PS_WK: 2,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 31,  PF_CUM: 29,  AS_CUM: 9,  AF_CUM: 0, },
    { CDATE: '2026-02-06', AREA: 'LQ',      YARD: 'HHI',   STAGE: 'ASSEMBLY', PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 31,  PF_CUM: 29,  AS_CUM: 9,  AF_CUM: 0, },
    { CDATE: '2026-02-13', AREA: 'LQ',      YARD: 'HHI',   STAGE: 'ASSEMBLY', PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 31,  PF_CUM: 29,  AS_CUM: 9,  AF_CUM: 0, },
    { CDATE: '2026-02-20', AREA: 'LQ',      YARD: 'HHI',   STAGE: 'ASSEMBLY', PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 31,  PF_CUM: 29,  AS_CUM: 9,  AF_CUM: 0, },
    { CDATE: '2026-02-27', AREA: 'LQ',      YARD: 'HHI',   STAGE: 'ASSEMBLY', PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 31,  PF_CUM: 29,  AS_CUM: 9,  AF_CUM: 0, },
    { CDATE: '2024-10-04', AREA: 'LQ',      YARD: 'HHI',   STAGE: 'CUTTING',  PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 0,   PF_CUM: 0,   AS_CUM: 0,  AF_CUM: 0, },
    { CDATE: '2024-10-11', AREA: 'LQ',      YARD: 'HHI',   STAGE: 'CUTTING',  PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 0,   PF_CUM: 0,   AS_CUM: 0,  AF_CUM: 0, },
    { CDATE: '2024-10-18', AREA: 'LQ',      YARD: 'HHI',   STAGE: 'CUTTING',  PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 0,   PF_CUM: 0,   AS_CUM: 0,  AF_CUM: 0, },
    { CDATE: '2024-10-25', AREA: 'LQ',      YARD: 'HHI',   STAGE: 'CUTTING',  PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 0,   PF_CUM: 0,   AS_CUM: 0,  AF_CUM: 0, },
    { CDATE: '2024-11-01', AREA: 'LQ',      YARD: 'HHI',   STAGE: 'CUTTING',  PS_WK: 3,  PF_WK: 0,  AS_WK: 3,  AF_WK: 0, PS_CUM: 3,   PF_CUM: 0,   AS_CUM: 3,  AF_CUM: 0, },
    { CDATE: '2024-11-08', AREA: 'LQ',      YARD: 'HHI',   STAGE: 'CUTTING',  PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 3, PS_CUM: 3,   PF_CUM: 0,   AS_CUM: 3,  AF_CUM: 3, },
    { CDATE: '2024-11-15', AREA: 'LQ',      YARD: 'HHI',   STAGE: 'CUTTING',  PS_WK: 3,  PF_WK: 3,  AS_WK: 3,  AF_WK: 0, PS_CUM: 6,   PF_CUM: 3,   AS_CUM: 6,  AF_CUM: 3, },
    { CDATE: '2024-11-22', AREA: 'LQ',      YARD: 'HHI',   STAGE: 'CUTTING',  PS_WK: 3,  PF_WK: 3,  AS_WK: 3,  AF_WK: 2, PS_CUM: 9,   PF_CUM: 6,   AS_CUM: 9,  AF_CUM: 5, },
    { CDATE: '2024-11-29', AREA: 'LQ',      YARD: 'HHI',   STAGE: 'CUTTING',  PS_WK: 3,  PF_WK: 3,  AS_WK: 3,  AF_WK: 4, PS_CUM: 12,  PF_CUM: 9,   AS_CUM: 12, AF_CUM: 9, },
    { CDATE: '2024-12-06', AREA: 'LQ',      YARD: 'HHI',   STAGE: 'CUTTING',  PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 12,  PF_CUM: 9,   AS_CUM: 12, AF_CUM: 9, },
    { CDATE: '2024-12-13', AREA: 'LQ',      YARD: 'HHI',   STAGE: 'CUTTING',  PS_WK: 1,  PF_WK: 3,  AS_WK: 0,  AF_WK: 3, PS_CUM: 13,  PF_CUM: 12,  AS_CUM: 12, AF_CUM: 12, },
    { CDATE: '2024-12-20', AREA: 'LQ',      YARD: 'HHI',   STAGE: 'CUTTING',  PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 13,  PF_CUM: 12,  AS_CUM: 12, AF_CUM: 12, },
    { CDATE: '2024-12-27', AREA: 'LQ',      YARD: 'HHI',   STAGE: 'CUTTING',  PS_WK: 0,  PF_WK: 1,  AS_WK: 0,  AF_WK: 0, PS_CUM: 13,  PF_CUM: 13,  AS_CUM: 12, AF_CUM: 12, },
    { CDATE: '2025-01-03', AREA: 'LQ',      YARD: 'HHI',   STAGE: 'CUTTING',  PS_WK: 0,  PF_WK: 0,  AS_WK: 1,  AF_WK: 0, PS_CUM: 13,  PF_CUM: 13,  AS_CUM: 13, AF_CUM: 12, },
    { CDATE: '2025-01-10', AREA: 'LQ',      YARD: 'HHI',   STAGE: 'CUTTING',  PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 13,  PF_CUM: 13,  AS_CUM: 13, AF_CUM: 12, },
    { CDATE: '2025-01-17', AREA: 'LQ',      YARD: 'HHI',   STAGE: 'CUTTING',  PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 1, PS_CUM: 13,  PF_CUM: 13,  AS_CUM: 13, AF_CUM: 13, },
    { CDATE: '2025-01-24', AREA: 'LQ',      YARD: 'HHI',   STAGE: 'CUTTING',  PS_WK: 7,  PF_WK: 0,  AS_WK: 6,  AF_WK: 2, PS_CUM: 20,  PF_CUM: 13,  AS_CUM: 19, AF_CUM: 15, },
    { CDATE: '2025-01-31', AREA: 'LQ',      YARD: 'HHI',   STAGE: 'CUTTING',  PS_WK: 0,  PF_WK: 3,  AS_WK: 0,  AF_WK: 0, PS_CUM: 20,  PF_CUM: 16,  AS_CUM: 19, AF_CUM: 15, },
    { CDATE: '2025-02-07', AREA: 'LQ',      YARD: 'HHI',   STAGE: 'CUTTING',  PS_WK: 4,  PF_WK: 4,  AS_WK: 0,  AF_WK: 0, PS_CUM: 24,  PF_CUM: 20,  AS_CUM: 19, AF_CUM: 15, },
    { CDATE: '2025-02-14', AREA: 'LQ',      YARD: 'HHI',   STAGE: 'CUTTING',  PS_WK: 0,  PF_WK: 1,  AS_WK: 0,  AF_WK: 0, PS_CUM: 24,  PF_CUM: 21,  AS_CUM: 19, AF_CUM: 15, },
    { CDATE: '2025-02-21', AREA: 'LQ',      YARD: 'HHI',   STAGE: 'CUTTING',  PS_WK: 0,  PF_WK: 3,  AS_WK: 0,  AF_WK: 0, PS_CUM: 24,  PF_CUM: 24,  AS_CUM: 19, AF_CUM: 15, },
    { CDATE: '2025-02-28', AREA: 'LQ',      YARD: 'HHI',   STAGE: 'CUTTING',  PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 24,  PF_CUM: 24,  AS_CUM: 19, AF_CUM: 15, },
    { CDATE: '2025-03-07', AREA: 'LQ',      YARD: 'HHI',   STAGE: 'CUTTING',  PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 24,  PF_CUM: 24,  AS_CUM: 19, AF_CUM: 15, },
    { CDATE: '2025-03-14', AREA: 'LQ',      YARD: 'HHI',   STAGE: 'CUTTING',  PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 24,  PF_CUM: 24,  AS_CUM: 19, AF_CUM: 15, },
    { CDATE: '2025-03-21', AREA: 'LQ',      YARD: 'HHI',   STAGE: 'CUTTING',  PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 24,  PF_CUM: 24,  AS_CUM: 19, AF_CUM: 15, },
    { CDATE: '2025-03-28', AREA: 'LQ',      YARD: 'HHI',   STAGE: 'CUTTING',  PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 24,  PF_CUM: 24,  AS_CUM: 19, AF_CUM: 15, },
    { CDATE: '2025-04-04', AREA: 'LQ',      YARD: 'HHI',   STAGE: 'CUTTING',  PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 24,  PF_CUM: 24,  AS_CUM: 19, AF_CUM: 15, },
    { CDATE: '2025-04-11', AREA: 'LQ',      YARD: 'HHI',   STAGE: 'CUTTING',  PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 24,  PF_CUM: 24,  AS_CUM: 19, AF_CUM: 15, },
    { CDATE: '2025-04-18', AREA: 'LQ',      YARD: 'HHI',   STAGE: 'CUTTING',  PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 24,  PF_CUM: 24,  AS_CUM: 19, AF_CUM: 15, },
    { CDATE: '2025-04-25', AREA: 'LQ',      YARD: 'HHI',   STAGE: 'CUTTING',  PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 24,  PF_CUM: 24,  AS_CUM: 19, AF_CUM: 15, },
    { CDATE: '2025-05-02', AREA: 'LQ',      YARD: 'HHI',   STAGE: 'CUTTING',  PS_WK: 1,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 25,  PF_CUM: 24,  AS_CUM: 19, AF_CUM: 15, },
    { CDATE: '2025-05-09', AREA: 'LQ',      YARD: 'HHI',   STAGE: 'CUTTING',  PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 25,  PF_CUM: 24,  AS_CUM: 19, AF_CUM: 15, },
    { CDATE: '2025-05-16', AREA: 'LQ',      YARD: 'HHI',   STAGE: 'CUTTING',  PS_WK: 0,  PF_WK: 1,  AS_WK: 0,  AF_WK: 0, PS_CUM: 25,  PF_CUM: 25,  AS_CUM: 19, AF_CUM: 15, },
    { CDATE: '2025-05-23', AREA: 'LQ',      YARD: 'HHI',   STAGE: 'CUTTING',  PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 25,  PF_CUM: 25,  AS_CUM: 19, AF_CUM: 15, },
    { CDATE: '2025-05-30', AREA: 'LQ',      YARD: 'HHI',   STAGE: 'CUTTING',  PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 25,  PF_CUM: 25,  AS_CUM: 19, AF_CUM: 15, },
    { CDATE: '2025-06-06', AREA: 'LQ',      YARD: 'HHI',   STAGE: 'CUTTING',  PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 25,  PF_CUM: 25,  AS_CUM: 19, AF_CUM: 15, },
    { CDATE: '2025-06-13', AREA: 'LQ',      YARD: 'HHI',   STAGE: 'CUTTING',  PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 25,  PF_CUM: 25,  AS_CUM: 19, AF_CUM: 15, },
    { CDATE: '2025-06-20', AREA: 'LQ',      YARD: 'HHI',   STAGE: 'CUTTING',  PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 25,  PF_CUM: 25,  AS_CUM: 19, AF_CUM: 15, },
    { CDATE: '2025-06-27', AREA: 'LQ',      YARD: 'HHI',   STAGE: 'CUTTING',  PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 25,  PF_CUM: 25,  AS_CUM: 19, AF_CUM: 15, },
    { CDATE: '2025-07-04', AREA: 'LQ',      YARD: 'HHI',   STAGE: 'CUTTING',  PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 25,  PF_CUM: 25,  AS_CUM: 19, AF_CUM: 15, },
    { CDATE: '2025-07-11', AREA: 'LQ',      YARD: 'HHI',   STAGE: 'CUTTING',  PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 25,  PF_CUM: 25,  AS_CUM: 19, AF_CUM: 15, },
    { CDATE: '2025-07-18', AREA: 'LQ',      YARD: 'HHI',   STAGE: 'CUTTING',  PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 25,  PF_CUM: 25,  AS_CUM: 19, AF_CUM: 15, },
    { CDATE: '2025-07-25', AREA: 'LQ',      YARD: 'HHI',   STAGE: 'CUTTING',  PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 25,  PF_CUM: 25,  AS_CUM: 19, AF_CUM: 15, },
    { CDATE: '2025-08-01', AREA: 'LQ',      YARD: 'HHI',   STAGE: 'CUTTING',  PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 25,  PF_CUM: 25,  AS_CUM: 19, AF_CUM: 15, },
    { CDATE: '2025-08-08', AREA: 'LQ',      YARD: 'HHI',   STAGE: 'CUTTING',  PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 25,  PF_CUM: 25,  AS_CUM: 19, AF_CUM: 15, },
    { CDATE: '2025-08-15', AREA: 'LQ',      YARD: 'HHI',   STAGE: 'CUTTING',  PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 25,  PF_CUM: 25,  AS_CUM: 19, AF_CUM: 15, },
    { CDATE: '2025-08-22', AREA: 'LQ',      YARD: 'HHI',   STAGE: 'CUTTING',  PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 25,  PF_CUM: 25,  AS_CUM: 19, AF_CUM: 15, },
    { CDATE: '2025-08-29', AREA: 'LQ',      YARD: 'HHI',   STAGE: 'CUTTING',  PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 25,  PF_CUM: 25,  AS_CUM: 19, AF_CUM: 15, },
    { CDATE: '2025-09-05', AREA: 'LQ',      YARD: 'HHI',   STAGE: 'CUTTING',  PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 25,  PF_CUM: 25,  AS_CUM: 19, AF_CUM: 15, },
    { CDATE: '2025-09-12', AREA: 'LQ',      YARD: 'HHI',   STAGE: 'CUTTING',  PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 25,  PF_CUM: 25,  AS_CUM: 19, AF_CUM: 15, },
    { CDATE: '2025-09-19', AREA: 'LQ',      YARD: 'HHI',   STAGE: 'CUTTING',  PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 25,  PF_CUM: 25,  AS_CUM: 19, AF_CUM: 15, },
    { CDATE: '2025-09-26', AREA: 'LQ',      YARD: 'HHI',   STAGE: 'CUTTING',  PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 25,  PF_CUM: 25,  AS_CUM: 19, AF_CUM: 15, },
    { CDATE: '2025-10-03', AREA: 'LQ',      YARD: 'HHI',   STAGE: 'CUTTING',  PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 25,  PF_CUM: 25,  AS_CUM: 19, AF_CUM: 15, },
    { CDATE: '2025-10-10', AREA: 'LQ',      YARD: 'HHI',   STAGE: 'CUTTING',  PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 25,  PF_CUM: 25,  AS_CUM: 19, AF_CUM: 15, },
    { CDATE: '2025-10-17', AREA: 'LQ',      YARD: 'HHI',   STAGE: 'CUTTING',  PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 25,  PF_CUM: 25,  AS_CUM: 19, AF_CUM: 15, },
    { CDATE: '2025-10-24', AREA: 'LQ',      YARD: 'HHI',   STAGE: 'CUTTING',  PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 25,  PF_CUM: 25,  AS_CUM: 19, AF_CUM: 15, },
    { CDATE: '2025-10-31', AREA: 'LQ',      YARD: 'HHI',   STAGE: 'CUTTING',  PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 25,  PF_CUM: 25,  AS_CUM: 19, AF_CUM: 15, },
    { CDATE: '2025-11-07', AREA: 'LQ',      YARD: 'HHI',   STAGE: 'CUTTING',  PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 25,  PF_CUM: 25,  AS_CUM: 19, AF_CUM: 15, },
    { CDATE: '2025-11-14', AREA: 'LQ',      YARD: 'HHI',   STAGE: 'CUTTING',  PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 25,  PF_CUM: 25,  AS_CUM: 19, AF_CUM: 15, },
    { CDATE: '2025-11-21', AREA: 'LQ',      YARD: 'HHI',   STAGE: 'CUTTING',  PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 25,  PF_CUM: 25,  AS_CUM: 19, AF_CUM: 15, },
    { CDATE: '2025-11-28', AREA: 'LQ',      YARD: 'HHI',   STAGE: 'CUTTING',  PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 25,  PF_CUM: 25,  AS_CUM: 19, AF_CUM: 15, },
    { CDATE: '2025-12-05', AREA: 'LQ',      YARD: 'HHI',   STAGE: 'CUTTING',  PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 25,  PF_CUM: 25,  AS_CUM: 19, AF_CUM: 15, },
    { CDATE: '2025-12-12', AREA: 'LQ',      YARD: 'HHI',   STAGE: 'CUTTING',  PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 25,  PF_CUM: 25,  AS_CUM: 19, AF_CUM: 15, },
    { CDATE: '2025-12-19', AREA: 'LQ',      YARD: 'HHI',   STAGE: 'CUTTING',  PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 25,  PF_CUM: 25,  AS_CUM: 19, AF_CUM: 15, },
    { CDATE: '2025-12-26', AREA: 'LQ',      YARD: 'HHI',   STAGE: 'CUTTING',  PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 25,  PF_CUM: 25,  AS_CUM: 19, AF_CUM: 15, },
    { CDATE: '2026-01-02', AREA: 'LQ',      YARD: 'HHI',   STAGE: 'CUTTING',  PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 25,  PF_CUM: 25,  AS_CUM: 19, AF_CUM: 15, },
    { CDATE: '2026-01-09', AREA: 'LQ',      YARD: 'HHI',   STAGE: 'CUTTING',  PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 25,  PF_CUM: 25,  AS_CUM: 19, AF_CUM: 15, },
    { CDATE: '2026-01-16', AREA: 'LQ',      YARD: 'HHI',   STAGE: 'CUTTING',  PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 25,  PF_CUM: 25,  AS_CUM: 19, AF_CUM: 15, },
    { CDATE: '2026-01-23', AREA: 'LQ',      YARD: 'HHI',   STAGE: 'CUTTING',  PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 25,  PF_CUM: 25,  AS_CUM: 19, AF_CUM: 15, },
    { CDATE: '2026-01-30', AREA: 'LQ',      YARD: 'HHI',   STAGE: 'CUTTING',  PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 25,  PF_CUM: 25,  AS_CUM: 19, AF_CUM: 15, },
    { CDATE: '2026-02-06', AREA: 'LQ',      YARD: 'HHI',   STAGE: 'CUTTING',  PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 25,  PF_CUM: 25,  AS_CUM: 19, AF_CUM: 15, },
    { CDATE: '2026-02-13', AREA: 'LQ',      YARD: 'HHI',   STAGE: 'CUTTING',  PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 25,  PF_CUM: 25,  AS_CUM: 19, AF_CUM: 15, },
    { CDATE: '2026-02-20', AREA: 'LQ',      YARD: 'HHI',   STAGE: 'CUTTING',  PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 25,  PF_CUM: 25,  AS_CUM: 19, AF_CUM: 15, },
    { CDATE: '2026-02-27', AREA: 'LQ',      YARD: 'HHI',   STAGE: 'CUTTING',  PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 25,  PF_CUM: 25,  AS_CUM: 19, AF_CUM: 15, },
    { CDATE: '2024-10-04', AREA: 'LQ',      YARD: 'HHI',   STAGE: 'PAINT',    PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 0,   PF_CUM: 0,   AS_CUM: 0,  AF_CUM: 0, },
    { CDATE: '2024-10-11', AREA: 'LQ',      YARD: 'HHI',   STAGE: 'PAINT',    PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 0,   PF_CUM: 0,   AS_CUM: 0,  AF_CUM: 0, },
    { CDATE: '2024-10-18', AREA: 'LQ',      YARD: 'HHI',   STAGE: 'PAINT',    PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 0,   PF_CUM: 0,   AS_CUM: 0,  AF_CUM: 0, },
    { CDATE: '2024-10-25', AREA: 'LQ',      YARD: 'HHI',   STAGE: 'PAINT',    PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 0,   PF_CUM: 0,   AS_CUM: 0,  AF_CUM: 0, },
    { CDATE: '2024-11-01', AREA: 'LQ',      YARD: 'HHI',   STAGE: 'PAINT',    PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 0,   PF_CUM: 0,   AS_CUM: 0,  AF_CUM: 0, },
    { CDATE: '2024-11-08', AREA: 'LQ',      YARD: 'HHI',   STAGE: 'PAINT',    PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 0,   PF_CUM: 0,   AS_CUM: 0,  AF_CUM: 0, },
    { CDATE: '2024-11-15', AREA: 'LQ',      YARD: 'HHI',   STAGE: 'PAINT',    PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 0,   PF_CUM: 0,   AS_CUM: 0,  AF_CUM: 0, },
    { CDATE: '2024-11-22', AREA: 'LQ',      YARD: 'HHI',   STAGE: 'PAINT',    PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 0,   PF_CUM: 0,   AS_CUM: 0,  AF_CUM: 0, },
    { CDATE: '2024-11-29', AREA: 'LQ',      YARD: 'HHI',   STAGE: 'PAINT',    PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 0,   PF_CUM: 0,   AS_CUM: 0,  AF_CUM: 0, },
    { CDATE: '2024-12-06', AREA: 'LQ',      YARD: 'HHI',   STAGE: 'PAINT',    PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 0,   PF_CUM: 0,   AS_CUM: 0,  AF_CUM: 0, },
    { CDATE: '2024-12-13', AREA: 'LQ',      YARD: 'HHI',   STAGE: 'PAINT',    PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 0,   PF_CUM: 0,   AS_CUM: 0,  AF_CUM: 0, },
    { CDATE: '2024-12-20', AREA: 'LQ',      YARD: 'HHI',   STAGE: 'PAINT',    PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 0,   PF_CUM: 0,   AS_CUM: 0,  AF_CUM: 0, },
    { CDATE: '2024-12-27', AREA: 'LQ',      YARD: 'HHI',   STAGE: 'PAINT',    PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 0,   PF_CUM: 0,   AS_CUM: 0,  AF_CUM: 0, },
    { CDATE: '2025-01-03', AREA: 'LQ',      YARD: 'HHI',   STAGE: 'PAINT',    PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 0,   PF_CUM: 0,   AS_CUM: 0,  AF_CUM: 0, },
    { CDATE: '2025-01-10', AREA: 'LQ',      YARD: 'HHI',   STAGE: 'PAINT',    PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 0,   PF_CUM: 0,   AS_CUM: 0,  AF_CUM: 0, },
    { CDATE: '2025-01-17', AREA: 'LQ',      YARD: 'HHI',   STAGE: 'PAINT',    PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 0,   PF_CUM: 0,   AS_CUM: 0,  AF_CUM: 0, },
    { CDATE: '2025-01-24', AREA: 'LQ',      YARD: 'HHI',   STAGE: 'PAINT',    PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 0,   PF_CUM: 0,   AS_CUM: 0,  AF_CUM: 0, },
    { CDATE: '2025-01-31', AREA: 'LQ',      YARD: 'HHI',   STAGE: 'PAINT',    PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 0,   PF_CUM: 0,   AS_CUM: 0,  AF_CUM: 0, },
    { CDATE: '2025-02-07', AREA: 'LQ',      YARD: 'HHI',   STAGE: 'PAINT',    PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 0,   PF_CUM: 0,   AS_CUM: 0,  AF_CUM: 0, },
    { CDATE: '2025-02-14', AREA: 'LQ',      YARD: 'HHI',   STAGE: 'PAINT',    PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 0,   PF_CUM: 0,   AS_CUM: 0,  AF_CUM: 0, },
    { CDATE: '2025-02-21', AREA: 'LQ',      YARD: 'HHI',   STAGE: 'PAINT',    PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 0,   PF_CUM: 0,   AS_CUM: 0,  AF_CUM: 0, },
    { CDATE: '2025-02-28', AREA: 'LQ',      YARD: 'HHI',   STAGE: 'PAINT',    PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 0,   PF_CUM: 0,   AS_CUM: 0,  AF_CUM: 0, },
    { CDATE: '2025-03-07', AREA: 'LQ',      YARD: 'HHI',   STAGE: 'PAINT',    PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 0,   PF_CUM: 0,   AS_CUM: 0,  AF_CUM: 0, },
    { CDATE: '2025-03-14', AREA: 'LQ',      YARD: 'HHI',   STAGE: 'PAINT',    PS_WK: 1,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 1,   PF_CUM: 0,   AS_CUM: 0,  AF_CUM: 0, },
    { CDATE: '2025-03-21', AREA: 'LQ',      YARD: 'HHI',   STAGE: 'PAINT',    PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 1,   PF_CUM: 0,   AS_CUM: 0,  AF_CUM: 0, },
    { CDATE: '2025-03-28', AREA: 'LQ',      YARD: 'HHI',   STAGE: 'PAINT',    PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 1,   PF_CUM: 0,   AS_CUM: 0,  AF_CUM: 0, },
    { CDATE: '2025-04-04', AREA: 'LQ',      YARD: 'HHI',   STAGE: 'PAINT',    PS_WK: 1,  PF_WK: 1,  AS_WK: 0,  AF_WK: 0, PS_CUM: 2,   PF_CUM: 1,   AS_CUM: 0,  AF_CUM: 0, },
    { CDATE: '2025-04-11', AREA: 'LQ',      YARD: 'HHI',   STAGE: 'PAINT',    PS_WK: 1,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 3,   PF_CUM: 1,   AS_CUM: 0,  AF_CUM: 0, },
    { CDATE: '2025-04-18', AREA: 'LQ',      YARD: 'HHI',   STAGE: 'PAINT',    PS_WK: 4,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 7,   PF_CUM: 1,   AS_CUM: 0,  AF_CUM: 0, },
    { CDATE: '2025-04-25', AREA: 'LQ',      YARD: 'HHI',   STAGE: 'PAINT',    PS_WK: 6,  PF_WK: 1,  AS_WK: 0,  AF_WK: 0, PS_CUM: 13,  PF_CUM: 2,   AS_CUM: 0,  AF_CUM: 0, },
    { CDATE: '2025-05-02', AREA: 'LQ',      YARD: 'HHI',   STAGE: 'PAINT',    PS_WK: 2,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 15,  PF_CUM: 2,   AS_CUM: 0,  AF_CUM: 0, },
    { CDATE: '2025-05-09', AREA: 'LQ',      YARD: 'HHI',   STAGE: 'PAINT',    PS_WK: 7,  PF_WK: 1,  AS_WK: 0,  AF_WK: 0, PS_CUM: 22,  PF_CUM: 3,   AS_CUM: 0,  AF_CUM: 0, },
    { CDATE: '2025-05-16', AREA: 'LQ',      YARD: 'HHI',   STAGE: 'PAINT',    PS_WK: 0,  PF_WK: 4,  AS_WK: 0,  AF_WK: 0, PS_CUM: 22,  PF_CUM: 7,   AS_CUM: 0,  AF_CUM: 0, },
    { CDATE: '2025-05-23', AREA: 'LQ',      YARD: 'HHI',   STAGE: 'PAINT',    PS_WK: 0,  PF_WK: 6,  AS_WK: 0,  AF_WK: 0, PS_CUM: 22,  PF_CUM: 13,  AS_CUM: 0,  AF_CUM: 0, },
    { CDATE: '2025-05-30', AREA: 'LQ',      YARD: 'HHI',   STAGE: 'PAINT',    PS_WK: 0,  PF_WK: 9,  AS_WK: 0,  AF_WK: 0, PS_CUM: 22,  PF_CUM: 22,  AS_CUM: 0,  AF_CUM: 0, },
    { CDATE: '2025-06-06', AREA: 'LQ',      YARD: 'HHI',   STAGE: 'PAINT',    PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 22,  PF_CUM: 22,  AS_CUM: 0,  AF_CUM: 0, },
    { CDATE: '2025-06-13', AREA: 'LQ',      YARD: 'HHI',   STAGE: 'PAINT',    PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 22,  PF_CUM: 22,  AS_CUM: 0,  AF_CUM: 0, },
    { CDATE: '2025-06-20', AREA: 'LQ',      YARD: 'HHI',   STAGE: 'PAINT',    PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 22,  PF_CUM: 22,  AS_CUM: 0,  AF_CUM: 0, },
    { CDATE: '2025-06-27', AREA: 'LQ',      YARD: 'HHI',   STAGE: 'PAINT',    PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 22,  PF_CUM: 22,  AS_CUM: 0,  AF_CUM: 0, },
    { CDATE: '2025-07-04', AREA: 'LQ',      YARD: 'HHI',   STAGE: 'PAINT',    PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 22,  PF_CUM: 22,  AS_CUM: 0,  AF_CUM: 0, },
    { CDATE: '2025-07-11', AREA: 'LQ',      YARD: 'HHI',   STAGE: 'PAINT',    PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 22,  PF_CUM: 22,  AS_CUM: 0,  AF_CUM: 0, },
    { CDATE: '2025-07-18', AREA: 'LQ',      YARD: 'HHI',   STAGE: 'PAINT',    PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 22,  PF_CUM: 22,  AS_CUM: 0,  AF_CUM: 0, },
    { CDATE: '2025-07-25', AREA: 'LQ',      YARD: 'HHI',   STAGE: 'PAINT',    PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 22,  PF_CUM: 22,  AS_CUM: 0,  AF_CUM: 0, },
    { CDATE: '2025-08-01', AREA: 'LQ',      YARD: 'HHI',   STAGE: 'PAINT',    PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 22,  PF_CUM: 22,  AS_CUM: 0,  AF_CUM: 0, },
    { CDATE: '2025-08-08', AREA: 'LQ',      YARD: 'HHI',   STAGE: 'PAINT',    PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 22,  PF_CUM: 22,  AS_CUM: 0,  AF_CUM: 0, },
    { CDATE: '2025-08-15', AREA: 'LQ',      YARD: 'HHI',   STAGE: 'PAINT',    PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 22,  PF_CUM: 22,  AS_CUM: 0,  AF_CUM: 0, },
    { CDATE: '2025-08-22', AREA: 'LQ',      YARD: 'HHI',   STAGE: 'PAINT',    PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 22,  PF_CUM: 22,  AS_CUM: 0,  AF_CUM: 0, },
    { CDATE: '2025-08-29', AREA: 'LQ',      YARD: 'HHI',   STAGE: 'PAINT',    PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 22,  PF_CUM: 22,  AS_CUM: 0,  AF_CUM: 0, },
    { CDATE: '2025-09-05', AREA: 'LQ',      YARD: 'HHI',   STAGE: 'PAINT',    PS_WK: 1,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 23,  PF_CUM: 22,  AS_CUM: 0,  AF_CUM: 0, },
    { CDATE: '2025-09-12', AREA: 'LQ',      YARD: 'HHI',   STAGE: 'PAINT',    PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 23,  PF_CUM: 22,  AS_CUM: 0,  AF_CUM: 0, },
    { CDATE: '2025-09-19', AREA: 'LQ',      YARD: 'HHI',   STAGE: 'PAINT',    PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 23,  PF_CUM: 22,  AS_CUM: 0,  AF_CUM: 0, },
    { CDATE: '2025-09-26', AREA: 'LQ',      YARD: 'HHI',   STAGE: 'PAINT',    PS_WK: 0,  PF_WK: 1,  AS_WK: 0,  AF_WK: 0, PS_CUM: 23,  PF_CUM: 23,  AS_CUM: 0,  AF_CUM: 0, },
    { CDATE: '2025-10-03', AREA: 'LQ',      YARD: 'HHI',   STAGE: 'PAINT',    PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 23,  PF_CUM: 23,  AS_CUM: 0,  AF_CUM: 0, },
    { CDATE: '2025-10-10', AREA: 'LQ',      YARD: 'HHI',   STAGE: 'PAINT',    PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 23,  PF_CUM: 23,  AS_CUM: 0,  AF_CUM: 0, },
    { CDATE: '2025-10-17', AREA: 'LQ',      YARD: 'HHI',   STAGE: 'PAINT',    PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 23,  PF_CUM: 23,  AS_CUM: 0,  AF_CUM: 0, },
    { CDATE: '2025-10-24', AREA: 'LQ',      YARD: 'HHI',   STAGE: 'PAINT',    PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 23,  PF_CUM: 23,  AS_CUM: 0,  AF_CUM: 0, },
    { CDATE: '2025-10-31', AREA: 'LQ',      YARD: 'HHI',   STAGE: 'PAINT',    PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 23,  PF_CUM: 23,  AS_CUM: 0,  AF_CUM: 0, },
    { CDATE: '2025-11-07', AREA: 'LQ',      YARD: 'HHI',   STAGE: 'PAINT',    PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 23,  PF_CUM: 23,  AS_CUM: 0,  AF_CUM: 0, },
    { CDATE: '2025-11-14', AREA: 'LQ',      YARD: 'HHI',   STAGE: 'PAINT',    PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 23,  PF_CUM: 23,  AS_CUM: 0,  AF_CUM: 0, },
    { CDATE: '2025-11-21', AREA: 'LQ',      YARD: 'HHI',   STAGE: 'PAINT',    PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 23,  PF_CUM: 23,  AS_CUM: 0,  AF_CUM: 0, },
    { CDATE: '2025-11-28', AREA: 'LQ',      YARD: 'HHI',   STAGE: 'PAINT',    PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 23,  PF_CUM: 23,  AS_CUM: 0,  AF_CUM: 0, },
    { CDATE: '2025-12-05', AREA: 'LQ',      YARD: 'HHI',   STAGE: 'PAINT',    PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 23,  PF_CUM: 23,  AS_CUM: 0,  AF_CUM: 0, },
    { CDATE: '2025-12-12', AREA: 'LQ',      YARD: 'HHI',   STAGE: 'PAINT',    PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 23,  PF_CUM: 23,  AS_CUM: 0,  AF_CUM: 0, },
    { CDATE: '2025-12-19', AREA: 'LQ',      YARD: 'HHI',   STAGE: 'PAINT',    PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 23,  PF_CUM: 23,  AS_CUM: 0,  AF_CUM: 0, },
    { CDATE: '2025-12-26', AREA: 'LQ',      YARD: 'HHI',   STAGE: 'PAINT',    PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 23,  PF_CUM: 23,  AS_CUM: 0,  AF_CUM: 0, },
    { CDATE: '2026-01-02', AREA: 'LQ',      YARD: 'HHI',   STAGE: 'PAINT',    PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 23,  PF_CUM: 23,  AS_CUM: 0,  AF_CUM: 0, },
    { CDATE: '2026-01-09', AREA: 'LQ',      YARD: 'HHI',   STAGE: 'PAINT',    PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 23,  PF_CUM: 23,  AS_CUM: 0,  AF_CUM: 0, },
    { CDATE: '2026-01-16', AREA: 'LQ',      YARD: 'HHI',   STAGE: 'PAINT',    PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 23,  PF_CUM: 23,  AS_CUM: 0,  AF_CUM: 0, },
    { CDATE: '2026-01-23', AREA: 'LQ',      YARD: 'HHI',   STAGE: 'PAINT',    PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 23,  PF_CUM: 23,  AS_CUM: 0,  AF_CUM: 0, },
    { CDATE: '2026-01-30', AREA: 'LQ',      YARD: 'HHI',   STAGE: 'PAINT',    PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 23,  PF_CUM: 23,  AS_CUM: 0,  AF_CUM: 0, },
    { CDATE: '2026-02-06', AREA: 'LQ',      YARD: 'HHI',   STAGE: 'PAINT',    PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 23,  PF_CUM: 23,  AS_CUM: 0,  AF_CUM: 0, },
    { CDATE: '2026-02-13', AREA: 'LQ',      YARD: 'HHI',   STAGE: 'PAINT',    PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 23,  PF_CUM: 23,  AS_CUM: 0,  AF_CUM: 0, },
    { CDATE: '2026-02-20', AREA: 'LQ',      YARD: 'HHI',   STAGE: 'PAINT',    PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 23,  PF_CUM: 23,  AS_CUM: 0,  AF_CUM: 0, },
    { CDATE: '2026-02-27', AREA: 'LQ',      YARD: 'HHI',   STAGE: 'PAINT',    PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 23,  PF_CUM: 23,  AS_CUM: 0,  AF_CUM: 0, },
    { CDATE: '2024-10-04', AREA: 'TOPSIDE', YARD: 'HHI',   STAGE: 'ASSEMBLY', PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 0,   PF_CUM: 0,   AS_CUM: 0,  AF_CUM: 0, },
    { CDATE: '2024-10-11', AREA: 'TOPSIDE', YARD: 'HHI',   STAGE: 'ASSEMBLY', PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 0,   PF_CUM: 0,   AS_CUM: 0,  AF_CUM: 0, },
    { CDATE: '2024-10-18', AREA: 'TOPSIDE', YARD: 'HHI',   STAGE: 'ASSEMBLY', PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 0,   PF_CUM: 0,   AS_CUM: 0,  AF_CUM: 0, },
    { CDATE: '2024-10-25', AREA: 'TOPSIDE', YARD: 'HHI',   STAGE: 'ASSEMBLY', PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 0,   PF_CUM: 0,   AS_CUM: 0,  AF_CUM: 0, },
    { CDATE: '2024-11-01', AREA: 'TOPSIDE', YARD: 'HHI',   STAGE: 'ASSEMBLY', PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 0,   PF_CUM: 0,   AS_CUM: 0,  AF_CUM: 0, },
    { CDATE: '2024-11-08', AREA: 'TOPSIDE', YARD: 'HHI',   STAGE: 'ASSEMBLY', PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 0,   PF_CUM: 0,   AS_CUM: 0,  AF_CUM: 0, },
    { CDATE: '2024-11-15', AREA: 'TOPSIDE', YARD: 'HHI',   STAGE: 'ASSEMBLY', PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 0,   PF_CUM: 0,   AS_CUM: 0,  AF_CUM: 0, },
    { CDATE: '2024-11-22', AREA: 'TOPSIDE', YARD: 'HHI',   STAGE: 'ASSEMBLY', PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 0,   PF_CUM: 0,   AS_CUM: 0,  AF_CUM: 0, },
    { CDATE: '2024-11-29', AREA: 'TOPSIDE', YARD: 'HHI',   STAGE: 'ASSEMBLY', PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 0,   PF_CUM: 0,   AS_CUM: 0,  AF_CUM: 0, },
    { CDATE: '2024-12-06', AREA: 'TOPSIDE', YARD: 'HHI',   STAGE: 'ASSEMBLY', PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 0,   PF_CUM: 0,   AS_CUM: 0,  AF_CUM: 0, },
    { CDATE: '2024-12-13', AREA: 'TOPSIDE', YARD: 'HHI',   STAGE: 'ASSEMBLY', PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 0,   PF_CUM: 0,   AS_CUM: 0,  AF_CUM: 0, },
    { CDATE: '2024-12-20', AREA: 'TOPSIDE', YARD: 'HHI',   STAGE: 'ASSEMBLY', PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 0,   PF_CUM: 0,   AS_CUM: 0,  AF_CUM: 0, },
    { CDATE: '2024-12-27', AREA: 'TOPSIDE', YARD: 'HHI',   STAGE: 'ASSEMBLY', PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 0,   PF_CUM: 0,   AS_CUM: 0,  AF_CUM: 0, },
    { CDATE: '2025-01-03', AREA: 'TOPSIDE', YARD: 'HHI',   STAGE: 'ASSEMBLY', PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 0,   PF_CUM: 0,   AS_CUM: 0,  AF_CUM: 0, },
    { CDATE: '2025-01-10', AREA: 'TOPSIDE', YARD: 'HHI',   STAGE: 'ASSEMBLY', PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 0,   PF_CUM: 0,   AS_CUM: 0,  AF_CUM: 0, },
    { CDATE: '2025-01-17', AREA: 'TOPSIDE', YARD: 'HHI',   STAGE: 'ASSEMBLY', PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 0,   PF_CUM: 0,   AS_CUM: 0,  AF_CUM: 0, },
    { CDATE: '2025-01-24', AREA: 'TOPSIDE', YARD: 'HHI',   STAGE: 'ASSEMBLY', PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 0,   PF_CUM: 0,   AS_CUM: 0,  AF_CUM: 0, },
    { CDATE: '2025-01-31', AREA: 'TOPSIDE', YARD: 'HHI',   STAGE: 'ASSEMBLY', PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 0,   PF_CUM: 0,   AS_CUM: 0,  AF_CUM: 0, },
    { CDATE: '2025-02-07', AREA: 'TOPSIDE', YARD: 'HHI',   STAGE: 'ASSEMBLY', PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 0,   PF_CUM: 0,   AS_CUM: 0,  AF_CUM: 0, },
    { CDATE: '2025-02-14', AREA: 'TOPSIDE', YARD: 'HHI',   STAGE: 'ASSEMBLY', PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 0,   PF_CUM: 0,   AS_CUM: 0,  AF_CUM: 0, },
    { CDATE: '2025-02-21', AREA: 'TOPSIDE', YARD: 'HHI',   STAGE: 'ASSEMBLY', PS_WK: 3,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 3,   PF_CUM: 0,   AS_CUM: 0,  AF_CUM: 0, },
    { CDATE: '2025-02-28', AREA: 'TOPSIDE', YARD: 'HHI',   STAGE: 'ASSEMBLY', PS_WK: 5,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 8,   PF_CUM: 0,   AS_CUM: 0,  AF_CUM: 0, },
    { CDATE: '2025-03-07', AREA: 'TOPSIDE', YARD: 'HHI',   STAGE: 'ASSEMBLY', PS_WK: 8,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 16,  PF_CUM: 0,   AS_CUM: 0,  AF_CUM: 0, },
    { CDATE: '2025-03-14', AREA: 'TOPSIDE', YARD: 'HHI',   STAGE: 'ASSEMBLY', PS_WK: 6,  PF_WK: 2,  AS_WK: 0,  AF_WK: 0, PS_CUM: 22,  PF_CUM: 2,   AS_CUM: 0,  AF_CUM: 0, },
    { CDATE: '2025-03-21', AREA: 'TOPSIDE', YARD: 'HHI',   STAGE: 'ASSEMBLY', PS_WK: 4,  PF_WK: 2,  AS_WK: 0,  AF_WK: 0, PS_CUM: 26,  PF_CUM: 4,   AS_CUM: 0,  AF_CUM: 0, },
    { CDATE: '2025-03-28', AREA: 'TOPSIDE', YARD: 'HHI',   STAGE: 'ASSEMBLY', PS_WK: 12, PF_WK: 1,  AS_WK: 0,  AF_WK: 0, PS_CUM: 38,  PF_CUM: 5,   AS_CUM: 0,  AF_CUM: 0, },
    { CDATE: '2025-04-04', AREA: 'TOPSIDE', YARD: 'HHI',   STAGE: 'ASSEMBLY', PS_WK: 11, PF_WK: 1,  AS_WK: 0,  AF_WK: 0, PS_CUM: 49,  PF_CUM: 6,   AS_CUM: 0,  AF_CUM: 0, },
    { CDATE: '2025-04-11', AREA: 'TOPSIDE', YARD: 'HHI',   STAGE: 'ASSEMBLY', PS_WK: 13, PF_WK: 5,  AS_WK: 0,  AF_WK: 0, PS_CUM: 62,  PF_CUM: 11,  AS_CUM: 0,  AF_CUM: 0, },
    { CDATE: '2025-04-18', AREA: 'TOPSIDE', YARD: 'HHI',   STAGE: 'ASSEMBLY', PS_WK: 6,  PF_WK: 4,  AS_WK: 0,  AF_WK: 0, PS_CUM: 68,  PF_CUM: 15,  AS_CUM: 0,  AF_CUM: 0, },
    { CDATE: '2025-04-25', AREA: 'TOPSIDE', YARD: 'HHI',   STAGE: 'ASSEMBLY', PS_WK: 12, PF_WK: 5,  AS_WK: 0,  AF_WK: 0, PS_CUM: 80,  PF_CUM: 20,  AS_CUM: 0,  AF_CUM: 0, },
    { CDATE: '2025-05-02', AREA: 'TOPSIDE', YARD: 'HHI',   STAGE: 'ASSEMBLY', PS_WK: 5,  PF_WK: 4,  AS_WK: 0,  AF_WK: 0, PS_CUM: 85,  PF_CUM: 24,  AS_CUM: 0,  AF_CUM: 0, },
    { CDATE: '2025-05-09', AREA: 'TOPSIDE', YARD: 'HHI',   STAGE: 'ASSEMBLY', PS_WK: 7,  PF_WK: 1,  AS_WK: 0,  AF_WK: 0, PS_CUM: 92,  PF_CUM: 25,  AS_CUM: 0,  AF_CUM: 0, },
    { CDATE: '2025-05-16', AREA: 'TOPSIDE', YARD: 'HHI',   STAGE: 'ASSEMBLY', PS_WK: 11, PF_WK: 20, AS_WK: 0,  AF_WK: 0, PS_CUM: 103, PF_CUM: 45,  AS_CUM: 0,  AF_CUM: 0, },
    { CDATE: '2025-05-23', AREA: 'TOPSIDE', YARD: 'HHI',   STAGE: 'ASSEMBLY', PS_WK: 8,  PF_WK: 13, AS_WK: 0,  AF_WK: 0, PS_CUM: 111, PF_CUM: 58,  AS_CUM: 0,  AF_CUM: 0, },
    { CDATE: '2025-05-30', AREA: 'TOPSIDE', YARD: 'HHI',   STAGE: 'ASSEMBLY', PS_WK: 15, PF_WK: 7,  AS_WK: 0,  AF_WK: 0, PS_CUM: 126, PF_CUM: 65,  AS_CUM: 0,  AF_CUM: 0, },
    { CDATE: '2025-06-06', AREA: 'TOPSIDE', YARD: 'HHI',   STAGE: 'ASSEMBLY', PS_WK: 5,  PF_WK: 8,  AS_WK: 0,  AF_WK: 0, PS_CUM: 131, PF_CUM: 73,  AS_CUM: 0,  AF_CUM: 0, },
    { CDATE: '2025-06-13', AREA: 'TOPSIDE', YARD: 'HHI',   STAGE: 'ASSEMBLY', PS_WK: 2,  PF_WK: 13, AS_WK: 0,  AF_WK: 0, PS_CUM: 133, PF_CUM: 86,  AS_CUM: 0,  AF_CUM: 0, },
    { CDATE: '2025-06-20', AREA: 'TOPSIDE', YARD: 'HHI',   STAGE: 'ASSEMBLY', PS_WK: 4,  PF_WK: 2,  AS_WK: 0,  AF_WK: 0, PS_CUM: 137, PF_CUM: 88,  AS_CUM: 0,  AF_CUM: 0, },
    { CDATE: '2025-06-27', AREA: 'TOPSIDE', YARD: 'HHI',   STAGE: 'ASSEMBLY', PS_WK: 15, PF_WK: 8,  AS_WK: 0,  AF_WK: 0, PS_CUM: 152, PF_CUM: 96,  AS_CUM: 0,  AF_CUM: 0, },
    { CDATE: '2025-07-04', AREA: 'TOPSIDE', YARD: 'HHI',   STAGE: 'ASSEMBLY', PS_WK: 4,  PF_WK: 17, AS_WK: 0,  AF_WK: 0, PS_CUM: 156, PF_CUM: 113, AS_CUM: 0,  AF_CUM: 0, },
    { CDATE: '2025-07-11', AREA: 'TOPSIDE', YARD: 'HHI',   STAGE: 'ASSEMBLY', PS_WK: 13, PF_WK: 11, AS_WK: 0,  AF_WK: 0, PS_CUM: 169, PF_CUM: 124, AS_CUM: 0,  AF_CUM: 0, },
    { CDATE: '2025-07-18', AREA: 'TOPSIDE', YARD: 'HHI',   STAGE: 'ASSEMBLY', PS_WK: 6,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 175, PF_CUM: 124, AS_CUM: 0,  AF_CUM: 0, },
    { CDATE: '2025-07-25', AREA: 'TOPSIDE', YARD: 'HHI',   STAGE: 'ASSEMBLY', PS_WK: 2,  PF_WK: 4,  AS_WK: 0,  AF_WK: 0, PS_CUM: 177, PF_CUM: 128, AS_CUM: 0,  AF_CUM: 0, },
    { CDATE: '2025-08-01', AREA: 'TOPSIDE', YARD: 'HHI',   STAGE: 'ASSEMBLY', PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 177, PF_CUM: 128, AS_CUM: 0,  AF_CUM: 0, },
    { CDATE: '2025-08-08', AREA: 'TOPSIDE', YARD: 'HHI',   STAGE: 'ASSEMBLY', PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 177, PF_CUM: 128, AS_CUM: 0,  AF_CUM: 0, },
    { CDATE: '2025-08-15', AREA: 'TOPSIDE', YARD: 'HHI',   STAGE: 'ASSEMBLY', PS_WK: 13, PF_WK: 5,  AS_WK: 0,  AF_WK: 0, PS_CUM: 190, PF_CUM: 133, AS_CUM: 0,  AF_CUM: 0, },
    { CDATE: '2025-08-22', AREA: 'TOPSIDE', YARD: 'HHI',   STAGE: 'ASSEMBLY', PS_WK: 8,  PF_WK: 19, AS_WK: 0,  AF_WK: 0, PS_CUM: 198, PF_CUM: 152, AS_CUM: 0,  AF_CUM: 0, },
    { CDATE: '2025-08-29', AREA: 'TOPSIDE', YARD: 'HHI',   STAGE: 'ASSEMBLY', PS_WK: 15, PF_WK: 4,  AS_WK: 0,  AF_WK: 0, PS_CUM: 213, PF_CUM: 156, AS_CUM: 0,  AF_CUM: 0, },
    { CDATE: '2025-09-05', AREA: 'TOPSIDE', YARD: 'HHI',   STAGE: 'ASSEMBLY', PS_WK: 16, PF_WK: 8,  AS_WK: 0,  AF_WK: 0, PS_CUM: 229, PF_CUM: 164, AS_CUM: 0,  AF_CUM: 0, },
    { CDATE: '2025-09-12', AREA: 'TOPSIDE', YARD: 'HHI',   STAGE: 'ASSEMBLY', PS_WK: 22, PF_WK: 12, AS_WK: 0,  AF_WK: 0, PS_CUM: 251, PF_CUM: 176, AS_CUM: 0,  AF_CUM: 0, },
    { CDATE: '2025-09-19', AREA: 'TOPSIDE', YARD: 'HHI',   STAGE: 'ASSEMBLY', PS_WK: 0,  PF_WK: 1,  AS_WK: 0,  AF_WK: 0, PS_CUM: 251, PF_CUM: 177, AS_CUM: 0,  AF_CUM: 0, },
    { CDATE: '2025-09-26', AREA: 'TOPSIDE', YARD: 'HHI',   STAGE: 'ASSEMBLY', PS_WK: 3,  PF_WK: 6,  AS_WK: 0,  AF_WK: 0, PS_CUM: 254, PF_CUM: 183, AS_CUM: 0,  AF_CUM: 0, },
    { CDATE: '2025-10-03', AREA: 'TOPSIDE', YARD: 'HHI',   STAGE: 'ASSEMBLY', PS_WK: 0,  PF_WK: 15, AS_WK: 0,  AF_WK: 0, PS_CUM: 254, PF_CUM: 198, AS_CUM: 0,  AF_CUM: 0, },
    { CDATE: '2025-10-10', AREA: 'TOPSIDE', YARD: 'HHI',   STAGE: 'ASSEMBLY', PS_WK: 0,  PF_WK: 2,  AS_WK: 0,  AF_WK: 0, PS_CUM: 254, PF_CUM: 200, AS_CUM: 0,  AF_CUM: 0, },
    { CDATE: '2025-10-17', AREA: 'TOPSIDE', YARD: 'HHI',   STAGE: 'ASSEMBLY', PS_WK: 0,  PF_WK: 1,  AS_WK: 0,  AF_WK: 0, PS_CUM: 254, PF_CUM: 201, AS_CUM: 0,  AF_CUM: 0, },
    { CDATE: '2025-10-24', AREA: 'TOPSIDE', YARD: 'HHI',   STAGE: 'ASSEMBLY', PS_WK: 0,  PF_WK: 17, AS_WK: 0,  AF_WK: 0, PS_CUM: 254, PF_CUM: 218, AS_CUM: 0,  AF_CUM: 0, },
    { CDATE: '2025-10-31', AREA: 'TOPSIDE', YARD: 'HHI',   STAGE: 'ASSEMBLY', PS_WK: 0,  PF_WK: 27, AS_WK: 0,  AF_WK: 0, PS_CUM: 254, PF_CUM: 245, AS_CUM: 0,  AF_CUM: 0, },
    { CDATE: '2025-11-07', AREA: 'TOPSIDE', YARD: 'HHI',   STAGE: 'ASSEMBLY', PS_WK: 0,  PF_WK: 9,  AS_WK: 0,  AF_WK: 0, PS_CUM: 254, PF_CUM: 254, AS_CUM: 0,  AF_CUM: 0, },
    { CDATE: '2025-11-14', AREA: 'TOPSIDE', YARD: 'HHI',   STAGE: 'ASSEMBLY', PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 254, PF_CUM: 254, AS_CUM: 0,  AF_CUM: 0, },
    { CDATE: '2025-11-21', AREA: 'TOPSIDE', YARD: 'HHI',   STAGE: 'ASSEMBLY', PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 254, PF_CUM: 254, AS_CUM: 0,  AF_CUM: 0, },
    { CDATE: '2025-11-28', AREA: 'TOPSIDE', YARD: 'HHI',   STAGE: 'ASSEMBLY', PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 254, PF_CUM: 254, AS_CUM: 0,  AF_CUM: 0, },
    { CDATE: '2025-12-05', AREA: 'TOPSIDE', YARD: 'HHI',   STAGE: 'ASSEMBLY', PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 254, PF_CUM: 254, AS_CUM: 0,  AF_CUM: 0, },
    { CDATE: '2025-12-12', AREA: 'TOPSIDE', YARD: 'HHI',   STAGE: 'ASSEMBLY', PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 254, PF_CUM: 254, AS_CUM: 0,  AF_CUM: 0, },
    { CDATE: '2025-12-19', AREA: 'TOPSIDE', YARD: 'HHI',   STAGE: 'ASSEMBLY', PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 254, PF_CUM: 254, AS_CUM: 0,  AF_CUM: 0, },
    { CDATE: '2025-12-26', AREA: 'TOPSIDE', YARD: 'HHI',   STAGE: 'ASSEMBLY', PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 254, PF_CUM: 254, AS_CUM: 0,  AF_CUM: 0, },
    { CDATE: '2026-01-02', AREA: 'TOPSIDE', YARD: 'HHI',   STAGE: 'ASSEMBLY', PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 254, PF_CUM: 254, AS_CUM: 0,  AF_CUM: 0, },
    { CDATE: '2026-01-09', AREA: 'TOPSIDE', YARD: 'HHI',   STAGE: 'ASSEMBLY', PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 254, PF_CUM: 254, AS_CUM: 0,  AF_CUM: 0, },
    { CDATE: '2026-01-16', AREA: 'TOPSIDE', YARD: 'HHI',   STAGE: 'ASSEMBLY', PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 254, PF_CUM: 254, AS_CUM: 0,  AF_CUM: 0, },
    { CDATE: '2026-01-23', AREA: 'TOPSIDE', YARD: 'HHI',   STAGE: 'ASSEMBLY', PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 254, PF_CUM: 254, AS_CUM: 0,  AF_CUM: 0, },
    { CDATE: '2026-01-30', AREA: 'TOPSIDE', YARD: 'HHI',   STAGE: 'ASSEMBLY', PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 254, PF_CUM: 254, AS_CUM: 0,  AF_CUM: 0, },
    { CDATE: '2026-02-06', AREA: 'TOPSIDE', YARD: 'HHI',   STAGE: 'ASSEMBLY', PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 254, PF_CUM: 254, AS_CUM: 0,  AF_CUM: 0, },
    { CDATE: '2026-02-13', AREA: 'TOPSIDE', YARD: 'HHI',   STAGE: 'ASSEMBLY', PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 254, PF_CUM: 254, AS_CUM: 0,  AF_CUM: 0, },
    { CDATE: '2026-02-20', AREA: 'TOPSIDE', YARD: 'HHI',   STAGE: 'ASSEMBLY', PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 254, PF_CUM: 254, AS_CUM: 0,  AF_CUM: 0, },
    { CDATE: '2026-02-27', AREA: 'TOPSIDE', YARD: 'HHI',   STAGE: 'ASSEMBLY', PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 254, PF_CUM: 254, AS_CUM: 0,  AF_CUM: 0, },
    { CDATE: '2024-10-04', AREA: 'TOPSIDE', YARD: 'HHI',   STAGE: 'CUTTING',  PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 0,   PF_CUM: 0,   AS_CUM: 0,  AF_CUM: 0, },
    { CDATE: '2024-10-11', AREA: 'TOPSIDE', YARD: 'HHI',   STAGE: 'CUTTING',  PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 0,   PF_CUM: 0,   AS_CUM: 0,  AF_CUM: 0, },
    { CDATE: '2024-10-18', AREA: 'TOPSIDE', YARD: 'HHI',   STAGE: 'CUTTING',  PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 0,   PF_CUM: 0,   AS_CUM: 0,  AF_CUM: 0, },
    { CDATE: '2024-10-25', AREA: 'TOPSIDE', YARD: 'HHI',   STAGE: 'CUTTING',  PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 0,   PF_CUM: 0,   AS_CUM: 0,  AF_CUM: 0, },
    { CDATE: '2024-11-01', AREA: 'TOPSIDE', YARD: 'HHI',   STAGE: 'CUTTING',  PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 0,   PF_CUM: 0,   AS_CUM: 0,  AF_CUM: 0, },
    { CDATE: '2024-11-08', AREA: 'TOPSIDE', YARD: 'HHI',   STAGE: 'CUTTING',  PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 0,   PF_CUM: 0,   AS_CUM: 0,  AF_CUM: 0, },
    { CDATE: '2024-11-15', AREA: 'TOPSIDE', YARD: 'HHI',   STAGE: 'CUTTING',  PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 0,   PF_CUM: 0,   AS_CUM: 0,  AF_CUM: 0, },
    { CDATE: '2024-11-22', AREA: 'TOPSIDE', YARD: 'HHI',   STAGE: 'CUTTING',  PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 0,   PF_CUM: 0,   AS_CUM: 0,  AF_CUM: 0, },
    { CDATE: '2024-11-29', AREA: 'TOPSIDE', YARD: 'HHI',   STAGE: 'CUTTING',  PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 0,   PF_CUM: 0,   AS_CUM: 0,  AF_CUM: 0, },
    { CDATE: '2024-12-06', AREA: 'TOPSIDE', YARD: 'HHI',   STAGE: 'CUTTING',  PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 0,   PF_CUM: 0,   AS_CUM: 0,  AF_CUM: 0, },
    { CDATE: '2024-12-13', AREA: 'TOPSIDE', YARD: 'HHI',   STAGE: 'CUTTING',  PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 0,   PF_CUM: 0,   AS_CUM: 0,  AF_CUM: 0, },
    { CDATE: '2024-12-20', AREA: 'TOPSIDE', YARD: 'HHI',   STAGE: 'CUTTING',  PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 0,   PF_CUM: 0,   AS_CUM: 0,  AF_CUM: 0, },
    { CDATE: '2024-12-27', AREA: 'TOPSIDE', YARD: 'HHI',   STAGE: 'CUTTING',  PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 0,   PF_CUM: 0,   AS_CUM: 0,  AF_CUM: 0, },
    { CDATE: '2025-01-03', AREA: 'TOPSIDE', YARD: 'HHI',   STAGE: 'CUTTING',  PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 0,   PF_CUM: 0,   AS_CUM: 0,  AF_CUM: 0, },
    { CDATE: '2025-01-10', AREA: 'TOPSIDE', YARD: 'HHI',   STAGE: 'CUTTING',  PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 0,   PF_CUM: 0,   AS_CUM: 0,  AF_CUM: 0, },
    { CDATE: '2025-01-17', AREA: 'TOPSIDE', YARD: 'HHI',   STAGE: 'CUTTING',  PS_WK: 4,  PF_WK: 0,  AS_WK: 3,  AF_WK: 0, PS_CUM: 4,   PF_CUM: 0,   AS_CUM: 3,  AF_CUM: 0, },
    { CDATE: '2025-01-24', AREA: 'TOPSIDE', YARD: 'HHI',   STAGE: 'CUTTING',  PS_WK: 14, PF_WK: 2,  AS_WK: 11, AF_WK: 0, PS_CUM: 18,  PF_CUM: 2,   AS_CUM: 14, AF_CUM: 0, },
    { CDATE: '2025-01-31', AREA: 'TOPSIDE', YARD: 'HHI',   STAGE: 'CUTTING',  PS_WK: 4,  PF_WK: 2,  AS_WK: 0,  AF_WK: 0, PS_CUM: 22,  PF_CUM: 4,   AS_CUM: 14, AF_CUM: 0, },
    { CDATE: '2025-02-07', AREA: 'TOPSIDE', YARD: 'HHI',   STAGE: 'CUTTING',  PS_WK: 3,  PF_WK: 8,  AS_WK: 0,  AF_WK: 0, PS_CUM: 25,  PF_CUM: 12,  AS_CUM: 14, AF_CUM: 0, },
    { CDATE: '2025-02-14', AREA: 'TOPSIDE', YARD: 'HHI',   STAGE: 'CUTTING',  PS_WK: 5,  PF_WK: 9,  AS_WK: 0,  AF_WK: 0, PS_CUM: 30,  PF_CUM: 21,  AS_CUM: 14, AF_CUM: 0, },
    { CDATE: '2025-02-21', AREA: 'TOPSIDE', YARD: 'HHI',   STAGE: 'CUTTING',  PS_WK: 4,  PF_WK: 2,  AS_WK: 0,  AF_WK: 0, PS_CUM: 34,  PF_CUM: 23,  AS_CUM: 14, AF_CUM: 0, },
    { CDATE: '2025-02-28', AREA: 'TOPSIDE', YARD: 'HHI',   STAGE: 'CUTTING',  PS_WK: 7,  PF_WK: 7,  AS_WK: 0,  AF_WK: 0, PS_CUM: 41,  PF_CUM: 30,  AS_CUM: 14, AF_CUM: 0, },
    { CDATE: '2025-03-07', AREA: 'TOPSIDE', YARD: 'HHI',   STAGE: 'CUTTING',  PS_WK: 13, PF_WK: 2,  AS_WK: 0,  AF_WK: 0, PS_CUM: 54,  PF_CUM: 32,  AS_CUM: 14, AF_CUM: 0, },
    { CDATE: '2025-03-14', AREA: 'TOPSIDE', YARD: 'HHI',   STAGE: 'CUTTING',  PS_WK: 7,  PF_WK: 6,  AS_WK: 0,  AF_WK: 0, PS_CUM: 61,  PF_CUM: 38,  AS_CUM: 14, AF_CUM: 0, },
    { CDATE: '2025-03-21', AREA: 'TOPSIDE', YARD: 'HHI',   STAGE: 'CUTTING',  PS_WK: 22, PF_WK: 7,  AS_WK: 0,  AF_WK: 0, PS_CUM: 83,  PF_CUM: 45,  AS_CUM: 14, AF_CUM: 0, },
    { CDATE: '2025-03-28', AREA: 'TOPSIDE', YARD: 'HHI',   STAGE: 'CUTTING',  PS_WK: 11, PF_WK: 14, AS_WK: 0,  AF_WK: 0, PS_CUM: 94,  PF_CUM: 59,  AS_CUM: 14, AF_CUM: 0, },
    { CDATE: '2025-04-04', AREA: 'TOPSIDE', YARD: 'HHI',   STAGE: 'CUTTING',  PS_WK: 9,  PF_WK: 19, AS_WK: 0,  AF_WK: 0, PS_CUM: 103, PF_CUM: 78,  AS_CUM: 14, AF_CUM: 0, },
    { CDATE: '2025-04-11', AREA: 'TOPSIDE', YARD: 'HHI',   STAGE: 'CUTTING',  PS_WK: 5,  PF_WK: 14, AS_WK: 0,  AF_WK: 0, PS_CUM: 108, PF_CUM: 92,  AS_CUM: 14, AF_CUM: 0, },
    { CDATE: '2025-04-18', AREA: 'TOPSIDE', YARD: 'HHI',   STAGE: 'CUTTING',  PS_WK: 10, PF_WK: 4,  AS_WK: 0,  AF_WK: 0, PS_CUM: 118, PF_CUM: 96,  AS_CUM: 14, AF_CUM: 0, },
    { CDATE: '2025-04-25', AREA: 'TOPSIDE', YARD: 'HHI',   STAGE: 'CUTTING',  PS_WK: 14, PF_WK: 10, AS_WK: 0,  AF_WK: 0, PS_CUM: 132, PF_CUM: 106, AS_CUM: 14, AF_CUM: 0, },
    { CDATE: '2025-05-02', AREA: 'TOPSIDE', YARD: 'HHI',   STAGE: 'CUTTING',  PS_WK: 6,  PF_WK: 5,  AS_WK: 0,  AF_WK: 0, PS_CUM: 138, PF_CUM: 111, AS_CUM: 14, AF_CUM: 0, },
    { CDATE: '2025-05-09', AREA: 'TOPSIDE', YARD: 'HHI',   STAGE: 'CUTTING',  PS_WK: 7,  PF_WK: 7,  AS_WK: 0,  AF_WK: 0, PS_CUM: 145, PF_CUM: 118, AS_CUM: 14, AF_CUM: 0, },
    { CDATE: '2025-05-16', AREA: 'TOPSIDE', YARD: 'HHI',   STAGE: 'CUTTING',  PS_WK: 9,  PF_WK: 14, AS_WK: 0,  AF_WK: 0, PS_CUM: 154, PF_CUM: 132, AS_CUM: 14, AF_CUM: 0, },
    { CDATE: '2025-05-23', AREA: 'TOPSIDE', YARD: 'HHI',   STAGE: 'CUTTING',  PS_WK: 4,  PF_WK: 6,  AS_WK: 0,  AF_WK: 0, PS_CUM: 158, PF_CUM: 138, AS_CUM: 14, AF_CUM: 0, },
    { CDATE: '2025-05-30', AREA: 'TOPSIDE', YARD: 'HHI',   STAGE: 'CUTTING',  PS_WK: 6,  PF_WK: 8,  AS_WK: 0,  AF_WK: 0, PS_CUM: 164, PF_CUM: 146, AS_CUM: 14, AF_CUM: 0, },
    { CDATE: '2025-06-06', AREA: 'TOPSIDE', YARD: 'HHI',   STAGE: 'CUTTING',  PS_WK: 14, PF_WK: 10, AS_WK: 0,  AF_WK: 0, PS_CUM: 178, PF_CUM: 156, AS_CUM: 14, AF_CUM: 0, },
    { CDATE: '2025-06-13', AREA: 'TOPSIDE', YARD: 'HHI',   STAGE: 'CUTTING',  PS_WK: 8,  PF_WK: 3,  AS_WK: 0,  AF_WK: 0, PS_CUM: 186, PF_CUM: 159, AS_CUM: 14, AF_CUM: 0, },
    { CDATE: '2025-06-20', AREA: 'TOPSIDE', YARD: 'HHI',   STAGE: 'CUTTING',  PS_WK: 20, PF_WK: 12, AS_WK: 0,  AF_WK: 0, PS_CUM: 206, PF_CUM: 171, AS_CUM: 14, AF_CUM: 0, },
    { CDATE: '2025-06-27', AREA: 'TOPSIDE', YARD: 'HHI',   STAGE: 'CUTTING',  PS_WK: 12, PF_WK: 11, AS_WK: 0,  AF_WK: 0, PS_CUM: 218, PF_CUM: 182, AS_CUM: 14, AF_CUM: 0, },
    { CDATE: '2025-07-04', AREA: 'TOPSIDE', YARD: 'HHI',   STAGE: 'CUTTING',  PS_WK: 4,  PF_WK: 16, AS_WK: 0,  AF_WK: 0, PS_CUM: 222, PF_CUM: 198, AS_CUM: 14, AF_CUM: 0, },
    { CDATE: '2025-07-11', AREA: 'TOPSIDE', YARD: 'HHI',   STAGE: 'CUTTING',  PS_WK: 9,  PF_WK: 13, AS_WK: 0,  AF_WK: 0, PS_CUM: 231, PF_CUM: 211, AS_CUM: 14, AF_CUM: 0, },
    { CDATE: '2025-07-18', AREA: 'TOPSIDE', YARD: 'HHI',   STAGE: 'CUTTING',  PS_WK: 10, PF_WK: 8,  AS_WK: 0,  AF_WK: 0, PS_CUM: 241, PF_CUM: 219, AS_CUM: 14, AF_CUM: 0, },
    { CDATE: '2025-07-25', AREA: 'TOPSIDE', YARD: 'HHI',   STAGE: 'CUTTING',  PS_WK: 12, PF_WK: 6,  AS_WK: 0,  AF_WK: 0, PS_CUM: 253, PF_CUM: 225, AS_CUM: 14, AF_CUM: 0, },
    { CDATE: '2025-08-01', AREA: 'TOPSIDE', YARD: 'HHI',   STAGE: 'CUTTING',  PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 253, PF_CUM: 225, AS_CUM: 14, AF_CUM: 0, },
    { CDATE: '2025-08-08', AREA: 'TOPSIDE', YARD: 'HHI',   STAGE: 'CUTTING',  PS_WK: 2,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 255, PF_CUM: 225, AS_CUM: 14, AF_CUM: 0, },
    { CDATE: '2025-08-15', AREA: 'TOPSIDE', YARD: 'HHI',   STAGE: 'CUTTING',  PS_WK: 3,  PF_WK: 11, AS_WK: 0,  AF_WK: 0, PS_CUM: 258, PF_CUM: 236, AS_CUM: 14, AF_CUM: 0, },
    { CDATE: '2025-08-22', AREA: 'TOPSIDE', YARD: 'HHI',   STAGE: 'CUTTING',  PS_WK: 24, PF_WK: 6,  AS_WK: 0,  AF_WK: 0, PS_CUM: 282, PF_CUM: 242, AS_CUM: 14, AF_CUM: 0, },
    { CDATE: '2025-08-29', AREA: 'TOPSIDE', YARD: 'HHI',   STAGE: 'CUTTING',  PS_WK: 11, PF_WK: 14, AS_WK: 0,  AF_WK: 0, PS_CUM: 293, PF_CUM: 256, AS_CUM: 14, AF_CUM: 0, },
    { CDATE: '2025-09-05', AREA: 'TOPSIDE', YARD: 'HHI',   STAGE: 'CUTTING',  PS_WK: 0,  PF_WK: 15, AS_WK: 0,  AF_WK: 0, PS_CUM: 293, PF_CUM: 271, AS_CUM: 14, AF_CUM: 0, },
    { CDATE: '2025-09-12', AREA: 'TOPSIDE', YARD: 'HHI',   STAGE: 'CUTTING',  PS_WK: 0,  PF_WK: 22, AS_WK: 0,  AF_WK: 0, PS_CUM: 293, PF_CUM: 293, AS_CUM: 14, AF_CUM: 0, },
    { CDATE: '2025-09-19', AREA: 'TOPSIDE', YARD: 'HHI',   STAGE: 'CUTTING',  PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 293, PF_CUM: 293, AS_CUM: 14, AF_CUM: 0, },
    { CDATE: '2025-09-26', AREA: 'TOPSIDE', YARD: 'HHI',   STAGE: 'CUTTING',  PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 293, PF_CUM: 293, AS_CUM: 14, AF_CUM: 0, },
    { CDATE: '2025-10-03', AREA: 'TOPSIDE', YARD: 'HHI',   STAGE: 'CUTTING',  PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 293, PF_CUM: 293, AS_CUM: 14, AF_CUM: 0, },
    { CDATE: '2025-10-10', AREA: 'TOPSIDE', YARD: 'HHI',   STAGE: 'CUTTING',  PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 293, PF_CUM: 293, AS_CUM: 14, AF_CUM: 0, },
    { CDATE: '2025-10-17', AREA: 'TOPSIDE', YARD: 'HHI',   STAGE: 'CUTTING',  PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 293, PF_CUM: 293, AS_CUM: 14, AF_CUM: 0, },
    { CDATE: '2025-10-24', AREA: 'TOPSIDE', YARD: 'HHI',   STAGE: 'CUTTING',  PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 293, PF_CUM: 293, AS_CUM: 14, AF_CUM: 0, },
    { CDATE: '2025-10-31', AREA: 'TOPSIDE', YARD: 'HHI',   STAGE: 'CUTTING',  PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 293, PF_CUM: 293, AS_CUM: 14, AF_CUM: 0, },
    { CDATE: '2025-11-07', AREA: 'TOPSIDE', YARD: 'HHI',   STAGE: 'CUTTING',  PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 293, PF_CUM: 293, AS_CUM: 14, AF_CUM: 0, },
    { CDATE: '2025-11-14', AREA: 'TOPSIDE', YARD: 'HHI',   STAGE: 'CUTTING',  PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 293, PF_CUM: 293, AS_CUM: 14, AF_CUM: 0, },
    { CDATE: '2025-11-21', AREA: 'TOPSIDE', YARD: 'HHI',   STAGE: 'CUTTING',  PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 293, PF_CUM: 293, AS_CUM: 14, AF_CUM: 0, },
    { CDATE: '2025-11-28', AREA: 'TOPSIDE', YARD: 'HHI',   STAGE: 'CUTTING',  PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 293, PF_CUM: 293, AS_CUM: 14, AF_CUM: 0, },
    { CDATE: '2025-12-05', AREA: 'TOPSIDE', YARD: 'HHI',   STAGE: 'CUTTING',  PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 293, PF_CUM: 293, AS_CUM: 14, AF_CUM: 0, },
    { CDATE: '2025-12-12', AREA: 'TOPSIDE', YARD: 'HHI',   STAGE: 'CUTTING',  PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 293, PF_CUM: 293, AS_CUM: 14, AF_CUM: 0, },
    { CDATE: '2025-12-19', AREA: 'TOPSIDE', YARD: 'HHI',   STAGE: 'CUTTING',  PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 293, PF_CUM: 293, AS_CUM: 14, AF_CUM: 0, },
    { CDATE: '2025-12-26', AREA: 'TOPSIDE', YARD: 'HHI',   STAGE: 'CUTTING',  PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 293, PF_CUM: 293, AS_CUM: 14, AF_CUM: 0, },
    { CDATE: '2026-01-02', AREA: 'TOPSIDE', YARD: 'HHI',   STAGE: 'CUTTING',  PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 293, PF_CUM: 293, AS_CUM: 14, AF_CUM: 0, },
    { CDATE: '2026-01-09', AREA: 'TOPSIDE', YARD: 'HHI',   STAGE: 'CUTTING',  PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 293, PF_CUM: 293, AS_CUM: 14, AF_CUM: 0, },
    { CDATE: '2026-01-16', AREA: 'TOPSIDE', YARD: 'HHI',   STAGE: 'CUTTING',  PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 293, PF_CUM: 293, AS_CUM: 14, AF_CUM: 0, },
    { CDATE: '2026-01-23', AREA: 'TOPSIDE', YARD: 'HHI',   STAGE: 'CUTTING',  PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 293, PF_CUM: 293, AS_CUM: 14, AF_CUM: 0, },
    { CDATE: '2026-01-30', AREA: 'TOPSIDE', YARD: 'HHI',   STAGE: 'CUTTING',  PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 293, PF_CUM: 293, AS_CUM: 14, AF_CUM: 0, },
    { CDATE: '2026-02-06', AREA: 'TOPSIDE', YARD: 'HHI',   STAGE: 'CUTTING',  PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 293, PF_CUM: 293, AS_CUM: 14, AF_CUM: 0, },
    { CDATE: '2026-02-13', AREA: 'TOPSIDE', YARD: 'HHI',   STAGE: 'CUTTING',  PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 293, PF_CUM: 293, AS_CUM: 14, AF_CUM: 0, },
    { CDATE: '2026-02-20', AREA: 'TOPSIDE', YARD: 'HHI',   STAGE: 'CUTTING',  PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 293, PF_CUM: 293, AS_CUM: 14, AF_CUM: 0, },
    { CDATE: '2026-02-27', AREA: 'TOPSIDE', YARD: 'HHI',   STAGE: 'CUTTING',  PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 293, PF_CUM: 293, AS_CUM: 14, AF_CUM: 0, },
    { CDATE: '2024-10-04', AREA: 'TOPSIDE', YARD: 'HHI',   STAGE: 'PAINT',    PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 0,   PF_CUM: 0,   AS_CUM: 0,  AF_CUM: 0, },
    { CDATE: '2024-10-11', AREA: 'TOPSIDE', YARD: 'HHI',   STAGE: 'PAINT',    PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 0,   PF_CUM: 0,   AS_CUM: 0,  AF_CUM: 0, },
    { CDATE: '2024-10-18', AREA: 'TOPSIDE', YARD: 'HHI',   STAGE: 'PAINT',    PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 0,   PF_CUM: 0,   AS_CUM: 0,  AF_CUM: 0, },
    { CDATE: '2024-10-25', AREA: 'TOPSIDE', YARD: 'HHI',   STAGE: 'PAINT',    PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 0,   PF_CUM: 0,   AS_CUM: 0,  AF_CUM: 0, },
    { CDATE: '2024-11-01', AREA: 'TOPSIDE', YARD: 'HHI',   STAGE: 'PAINT',    PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 0,   PF_CUM: 0,   AS_CUM: 0,  AF_CUM: 0, },
    { CDATE: '2024-11-08', AREA: 'TOPSIDE', YARD: 'HHI',   STAGE: 'PAINT',    PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 0,   PF_CUM: 0,   AS_CUM: 0,  AF_CUM: 0, },
    { CDATE: '2024-11-15', AREA: 'TOPSIDE', YARD: 'HHI',   STAGE: 'PAINT',    PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 0,   PF_CUM: 0,   AS_CUM: 0,  AF_CUM: 0, },
    { CDATE: '2024-11-22', AREA: 'TOPSIDE', YARD: 'HHI',   STAGE: 'PAINT',    PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 0,   PF_CUM: 0,   AS_CUM: 0,  AF_CUM: 0, },
    { CDATE: '2024-11-29', AREA: 'TOPSIDE', YARD: 'HHI',   STAGE: 'PAINT',    PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 0,   PF_CUM: 0,   AS_CUM: 0,  AF_CUM: 0, },
    { CDATE: '2024-12-06', AREA: 'TOPSIDE', YARD: 'HHI',   STAGE: 'PAINT',    PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 0,   PF_CUM: 0,   AS_CUM: 0,  AF_CUM: 0, },
    { CDATE: '2024-12-13', AREA: 'TOPSIDE', YARD: 'HHI',   STAGE: 'PAINT',    PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 0,   PF_CUM: 0,   AS_CUM: 0,  AF_CUM: 0, },
    { CDATE: '2024-12-20', AREA: 'TOPSIDE', YARD: 'HHI',   STAGE: 'PAINT',    PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 0,   PF_CUM: 0,   AS_CUM: 0,  AF_CUM: 0, },
    { CDATE: '2024-12-27', AREA: 'TOPSIDE', YARD: 'HHI',   STAGE: 'PAINT',    PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 0,   PF_CUM: 0,   AS_CUM: 0,  AF_CUM: 0, },
    { CDATE: '2025-01-03', AREA: 'TOPSIDE', YARD: 'HHI',   STAGE: 'PAINT',    PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 0,   PF_CUM: 0,   AS_CUM: 0,  AF_CUM: 0, },
    { CDATE: '2025-01-10', AREA: 'TOPSIDE', YARD: 'HHI',   STAGE: 'PAINT',    PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 0,   PF_CUM: 0,   AS_CUM: 0,  AF_CUM: 0, },
    { CDATE: '2025-01-17', AREA: 'TOPSIDE', YARD: 'HHI',   STAGE: 'PAINT',    PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 0,   PF_CUM: 0,   AS_CUM: 0,  AF_CUM: 0, },
    { CDATE: '2025-01-24', AREA: 'TOPSIDE', YARD: 'HHI',   STAGE: 'PAINT',    PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 0,   PF_CUM: 0,   AS_CUM: 0,  AF_CUM: 0, },
    { CDATE: '2025-01-31', AREA: 'TOPSIDE', YARD: 'HHI',   STAGE: 'PAINT',    PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 0,   PF_CUM: 0,   AS_CUM: 0,  AF_CUM: 0, },
    { CDATE: '2025-02-07', AREA: 'TOPSIDE', YARD: 'HHI',   STAGE: 'PAINT',    PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 0,   PF_CUM: 0,   AS_CUM: 0,  AF_CUM: 0, },
    { CDATE: '2025-02-14', AREA: 'TOPSIDE', YARD: 'HHI',   STAGE: 'PAINT',    PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 0,   PF_CUM: 0,   AS_CUM: 0,  AF_CUM: 0, },
    { CDATE: '2025-02-21', AREA: 'TOPSIDE', YARD: 'HHI',   STAGE: 'PAINT',    PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 0,   PF_CUM: 0,   AS_CUM: 0,  AF_CUM: 0, },
    { CDATE: '2025-02-28', AREA: 'TOPSIDE', YARD: 'HHI',   STAGE: 'PAINT',    PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 0,   PF_CUM: 0,   AS_CUM: 0,  AF_CUM: 0, },
    { CDATE: '2025-03-07', AREA: 'TOPSIDE', YARD: 'HHI',   STAGE: 'PAINT',    PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 0,   PF_CUM: 0,   AS_CUM: 0,  AF_CUM: 0, },
    { CDATE: '2025-03-14', AREA: 'TOPSIDE', YARD: 'HHI',   STAGE: 'PAINT',    PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 0,   PF_CUM: 0,   AS_CUM: 0,  AF_CUM: 0, },
    { CDATE: '2025-03-21', AREA: 'TOPSIDE', YARD: 'HHI',   STAGE: 'PAINT',    PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 0,   PF_CUM: 0,   AS_CUM: 0,  AF_CUM: 0, },
    { CDATE: '2025-03-28', AREA: 'TOPSIDE', YARD: 'HHI',   STAGE: 'PAINT',    PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 0,   PF_CUM: 0,   AS_CUM: 0,  AF_CUM: 0, },
    { CDATE: '2025-04-04', AREA: 'TOPSIDE', YARD: 'HHI',   STAGE: 'PAINT',    PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 0,   PF_CUM: 0,   AS_CUM: 0,  AF_CUM: 0, },
    { CDATE: '2025-04-11', AREA: 'TOPSIDE', YARD: 'HHI',   STAGE: 'PAINT',    PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 0,   PF_CUM: 0,   AS_CUM: 0,  AF_CUM: 0, },
    { CDATE: '2025-04-18', AREA: 'TOPSIDE', YARD: 'HHI',   STAGE: 'PAINT',    PS_WK: 3,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 3,   PF_CUM: 0,   AS_CUM: 0,  AF_CUM: 0, },
    { CDATE: '2025-04-25', AREA: 'TOPSIDE', YARD: 'HHI',   STAGE: 'PAINT',    PS_WK: 2,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 5,   PF_CUM: 0,   AS_CUM: 0,  AF_CUM: 0, },
    { CDATE: '2025-05-02', AREA: 'TOPSIDE', YARD: 'HHI',   STAGE: 'PAINT',    PS_WK: 1,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 6,   PF_CUM: 0,   AS_CUM: 0,  AF_CUM: 0, },
    { CDATE: '2025-05-09', AREA: 'TOPSIDE', YARD: 'HHI',   STAGE: 'PAINT',    PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 6,   PF_CUM: 0,   AS_CUM: 0,  AF_CUM: 0, },
    { CDATE: '2025-05-16', AREA: 'TOPSIDE', YARD: 'HHI',   STAGE: 'PAINT',    PS_WK: 1,  PF_WK: 1,  AS_WK: 0,  AF_WK: 0, PS_CUM: 7,   PF_CUM: 1,   AS_CUM: 0,  AF_CUM: 0, },
    { CDATE: '2025-05-23', AREA: 'TOPSIDE', YARD: 'HHI',   STAGE: 'PAINT',    PS_WK: 4,  PF_WK: 2,  AS_WK: 0,  AF_WK: 0, PS_CUM: 11,  PF_CUM: 3,   AS_CUM: 0,  AF_CUM: 0, },
    { CDATE: '2025-05-30', AREA: 'TOPSIDE', YARD: 'HHI',   STAGE: 'PAINT',    PS_WK: 2,  PF_WK: 2,  AS_WK: 0,  AF_WK: 0, PS_CUM: 13,  PF_CUM: 5,   AS_CUM: 0,  AF_CUM: 0, },
    { CDATE: '2025-06-06', AREA: 'TOPSIDE', YARD: 'HHI',   STAGE: 'PAINT',    PS_WK: 9,  PF_WK: 1,  AS_WK: 0,  AF_WK: 0, PS_CUM: 22,  PF_CUM: 6,   AS_CUM: 0,  AF_CUM: 0, },
    { CDATE: '2025-06-13', AREA: 'TOPSIDE', YARD: 'HHI',   STAGE: 'PAINT',    PS_WK: 12, PF_WK: 3,  AS_WK: 0,  AF_WK: 0, PS_CUM: 34,  PF_CUM: 9,   AS_CUM: 0,  AF_CUM: 0, },
    { CDATE: '2025-06-20', AREA: 'TOPSIDE', YARD: 'HHI',   STAGE: 'PAINT',    PS_WK: 13, PF_WK: 3,  AS_WK: 0,  AF_WK: 0, PS_CUM: 47,  PF_CUM: 12,  AS_CUM: 0,  AF_CUM: 0, },
    { CDATE: '2025-06-27', AREA: 'TOPSIDE', YARD: 'HHI',   STAGE: 'PAINT',    PS_WK: 3,  PF_WK: 9,  AS_WK: 0,  AF_WK: 0, PS_CUM: 50,  PF_CUM: 21,  AS_CUM: 0,  AF_CUM: 0, },
    { CDATE: '2025-07-04', AREA: 'TOPSIDE', YARD: 'HHI',   STAGE: 'PAINT',    PS_WK: 7,  PF_WK: 1,  AS_WK: 0,  AF_WK: 0, PS_CUM: 57,  PF_CUM: 22,  AS_CUM: 0,  AF_CUM: 0, },
    { CDATE: '2025-07-11', AREA: 'TOPSIDE', YARD: 'HHI',   STAGE: 'PAINT',    PS_WK: 2,  PF_WK: 21, AS_WK: 0,  AF_WK: 0, PS_CUM: 59,  PF_CUM: 43,  AS_CUM: 0,  AF_CUM: 0, },
    { CDATE: '2025-07-18', AREA: 'TOPSIDE', YARD: 'HHI',   STAGE: 'PAINT',    PS_WK: 3,  PF_WK: 5,  AS_WK: 0,  AF_WK: 0, PS_CUM: 62,  PF_CUM: 48,  AS_CUM: 0,  AF_CUM: 0, },
    { CDATE: '2025-07-25', AREA: 'TOPSIDE', YARD: 'HHI',   STAGE: 'PAINT',    PS_WK: 15, PF_WK: 10, AS_WK: 0,  AF_WK: 0, PS_CUM: 77,  PF_CUM: 58,  AS_CUM: 0,  AF_CUM: 0, },
    { CDATE: '2025-08-01', AREA: 'TOPSIDE', YARD: 'HHI',   STAGE: 'PAINT',    PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 77,  PF_CUM: 58,  AS_CUM: 0,  AF_CUM: 0, },
    { CDATE: '2025-08-08', AREA: 'TOPSIDE', YARD: 'HHI',   STAGE: 'PAINT',    PS_WK: 9,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 86,  PF_CUM: 58,  AS_CUM: 0,  AF_CUM: 0, },
    { CDATE: '2025-08-15', AREA: 'TOPSIDE', YARD: 'HHI',   STAGE: 'PAINT',    PS_WK: 2,  PF_WK: 1,  AS_WK: 0,  AF_WK: 0, PS_CUM: 88,  PF_CUM: 59,  AS_CUM: 0,  AF_CUM: 0, },
    { CDATE: '2025-08-22', AREA: 'TOPSIDE', YARD: 'HHI',   STAGE: 'PAINT',    PS_WK: 6,  PF_WK: 3,  AS_WK: 0,  AF_WK: 0, PS_CUM: 94,  PF_CUM: 62,  AS_CUM: 0,  AF_CUM: 0, },
    { CDATE: '2025-08-29', AREA: 'TOPSIDE', YARD: 'HHI',   STAGE: 'PAINT',    PS_WK: 0,  PF_WK: 16, AS_WK: 0,  AF_WK: 0, PS_CUM: 94,  PF_CUM: 78,  AS_CUM: 0,  AF_CUM: 0, },
    { CDATE: '2025-09-05', AREA: 'TOPSIDE', YARD: 'HHI',   STAGE: 'PAINT',    PS_WK: 1,  PF_WK: 10, AS_WK: 0,  AF_WK: 0, PS_CUM: 95,  PF_CUM: 88,  AS_CUM: 0,  AF_CUM: 0, },
    { CDATE: '2025-09-12', AREA: 'TOPSIDE', YARD: 'HHI',   STAGE: 'PAINT',    PS_WK: 12, PF_WK: 1,  AS_WK: 0,  AF_WK: 0, PS_CUM: 107, PF_CUM: 89,  AS_CUM: 0,  AF_CUM: 0, },
    { CDATE: '2025-09-19', AREA: 'TOPSIDE', YARD: 'HHI',   STAGE: 'PAINT',    PS_WK: 2,  PF_WK: 5,  AS_WK: 0,  AF_WK: 0, PS_CUM: 109, PF_CUM: 94,  AS_CUM: 0,  AF_CUM: 0, },
    { CDATE: '2025-09-26', AREA: 'TOPSIDE', YARD: 'HHI',   STAGE: 'PAINT',    PS_WK: 6,  PF_WK: 1,  AS_WK: 0,  AF_WK: 0, PS_CUM: 115, PF_CUM: 95,  AS_CUM: 0,  AF_CUM: 0, },
    { CDATE: '2025-10-03', AREA: 'TOPSIDE', YARD: 'HHI',   STAGE: 'PAINT',    PS_WK: 14, PF_WK: 6,  AS_WK: 0,  AF_WK: 0, PS_CUM: 129, PF_CUM: 101, AS_CUM: 0,  AF_CUM: 0, },
    { CDATE: '2025-10-10', AREA: 'TOPSIDE', YARD: 'HHI',   STAGE: 'PAINT',    PS_WK: 0,  PF_WK: 5,  AS_WK: 0,  AF_WK: 0, PS_CUM: 129, PF_CUM: 106, AS_CUM: 0,  AF_CUM: 0, },
    { CDATE: '2025-10-17', AREA: 'TOPSIDE', YARD: 'HHI',   STAGE: 'PAINT',    PS_WK: 1,  PF_WK: 2,  AS_WK: 0,  AF_WK: 0, PS_CUM: 130, PF_CUM: 108, AS_CUM: 0,  AF_CUM: 0, },
    { CDATE: '2025-10-24', AREA: 'TOPSIDE', YARD: 'HHI',   STAGE: 'PAINT',    PS_WK: 3,  PF_WK: 7,  AS_WK: 0,  AF_WK: 0, PS_CUM: 133, PF_CUM: 115, AS_CUM: 0,  AF_CUM: 0, },
    { CDATE: '2025-10-31', AREA: 'TOPSIDE', YARD: 'HHI',   STAGE: 'PAINT',    PS_WK: 6,  PF_WK: 14, AS_WK: 0,  AF_WK: 0, PS_CUM: 139, PF_CUM: 129, AS_CUM: 0,  AF_CUM: 0, },
    { CDATE: '2025-11-07', AREA: 'TOPSIDE', YARD: 'HHI',   STAGE: 'PAINT',    PS_WK: 5,  PF_WK: 1,  AS_WK: 0,  AF_WK: 0, PS_CUM: 144, PF_CUM: 130, AS_CUM: 0,  AF_CUM: 0, },
    { CDATE: '2025-11-14', AREA: 'TOPSIDE', YARD: 'HHI',   STAGE: 'PAINT',    PS_WK: 15, PF_WK: 3,  AS_WK: 0,  AF_WK: 0, PS_CUM: 159, PF_CUM: 133, AS_CUM: 0,  AF_CUM: 0, },
    { CDATE: '2025-11-21', AREA: 'TOPSIDE', YARD: 'HHI',   STAGE: 'PAINT',    PS_WK: 28, PF_WK: 6,  AS_WK: 0,  AF_WK: 0, PS_CUM: 187, PF_CUM: 139, AS_CUM: 0,  AF_CUM: 0, },
    { CDATE: '2025-11-28', AREA: 'TOPSIDE', YARD: 'HHI',   STAGE: 'PAINT',    PS_WK: 10, PF_WK: 3,  AS_WK: 0,  AF_WK: 0, PS_CUM: 197, PF_CUM: 142, AS_CUM: 0,  AF_CUM: 0, },
    { CDATE: '2025-12-05', AREA: 'TOPSIDE', YARD: 'HHI',   STAGE: 'PAINT',    PS_WK: 0,  PF_WK: 15, AS_WK: 0,  AF_WK: 0, PS_CUM: 197, PF_CUM: 157, AS_CUM: 0,  AF_CUM: 0, },
    { CDATE: '2025-12-12', AREA: 'TOPSIDE', YARD: 'HHI',   STAGE: 'PAINT',    PS_WK: 0,  PF_WK: 30, AS_WK: 0,  AF_WK: 0, PS_CUM: 197, PF_CUM: 187, AS_CUM: 0,  AF_CUM: 0, },
    { CDATE: '2025-12-19', AREA: 'TOPSIDE', YARD: 'HHI',   STAGE: 'PAINT',    PS_WK: 0,  PF_WK: 10, AS_WK: 0,  AF_WK: 0, PS_CUM: 197, PF_CUM: 197, AS_CUM: 0,  AF_CUM: 0, },
    { CDATE: '2025-12-26', AREA: 'TOPSIDE', YARD: 'HHI',   STAGE: 'PAINT',    PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 197, PF_CUM: 197, AS_CUM: 0,  AF_CUM: 0, },
    { CDATE: '2026-01-02', AREA: 'TOPSIDE', YARD: 'HHI',   STAGE: 'PAINT',    PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 197, PF_CUM: 197, AS_CUM: 0,  AF_CUM: 0, },
    { CDATE: '2026-01-09', AREA: 'TOPSIDE', YARD: 'HHI',   STAGE: 'PAINT',    PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 197, PF_CUM: 197, AS_CUM: 0,  AF_CUM: 0, },
    { CDATE: '2026-01-16', AREA: 'TOPSIDE', YARD: 'HHI',   STAGE: 'PAINT',    PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 197, PF_CUM: 197, AS_CUM: 0,  AF_CUM: 0, },
    { CDATE: '2026-01-23', AREA: 'TOPSIDE', YARD: 'HHI',   STAGE: 'PAINT',    PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 197, PF_CUM: 197, AS_CUM: 0,  AF_CUM: 0, },
    { CDATE: '2026-01-30', AREA: 'TOPSIDE', YARD: 'HHI',   STAGE: 'PAINT',    PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 197, PF_CUM: 197, AS_CUM: 0,  AF_CUM: 0, },
    { CDATE: '2026-02-06', AREA: 'TOPSIDE', YARD: 'HHI',   STAGE: 'PAINT',    PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 197, PF_CUM: 197, AS_CUM: 0,  AF_CUM: 0, },
    { CDATE: '2026-02-13', AREA: 'TOPSIDE', YARD: 'HHI',   STAGE: 'PAINT',    PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 197, PF_CUM: 197, AS_CUM: 0,  AF_CUM: 0, },
    { CDATE: '2026-02-20', AREA: 'TOPSIDE', YARD: 'HHI',   STAGE: 'PAINT',    PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 197, PF_CUM: 197, AS_CUM: 0,  AF_CUM: 0, },
    { CDATE: '2026-02-27', AREA: 'TOPSIDE', YARD: 'HHI',   STAGE: 'PAINT',    PS_WK: 0,  PF_WK: 0,  AS_WK: 0,  AF_WK: 0, PS_CUM: 197, PF_CUM: 197, AS_CUM: 0,  AF_CUM: 0, },
  ],


  Queries: {
    
    SQL1: [
      {
        // Timeline
        TL_CUTOFF               : '2025-01-10',
      },
    ],

  },
}