import __C from '../../../../../../includes/primitives/_constant_'

export default {
    Canvas: {
      CanvasWidth: 1400,
      CanvasHeight: 420,
      CanvasBorderWeight: .5,
      CanvasBorderColor: '#E0E0DF',
      CanvasFillColor: '#fff',
      CanvasOpacity: 1,

      CanvasChartX: 0,
      CanvasChartY: 0,
      CanvasChartWidth: 400,
      CanvasChartHeight: 200,
      CanvasChartBorderWeight: 0,
      CanvasChartBorderColor: '#757575',
      CanvasChartFillColor: '#757575',
      CanvasChartOpacity: 0,
      CanvasChartSpace: 0,

      CanvasFontFamily: 'roboto',
    },
    
    Text: {
      Text: [],
      Filter: [],
      Sql: []
    },

    DataItems: [
      { CDATE: '2024-06-07', DISC: 'Structure', TOTAL: 24899, P_WK: 0, A_WK: 0, D_WK: 0, P_CUM: 0, A_CUM: 0, D_CUM: 0, },
      { CDATE: '2024-06-14', DISC: 'Structure', TOTAL: 24899, P_WK: 0, A_WK: 0, D_WK: 0, P_CUM: 0, A_CUM: 0, D_CUM: 0, },
      { CDATE: '2024-06-21', DISC: 'Structure', TOTAL: 24899, P_WK: 0, A_WK: 0, D_WK: 0, P_CUM: 0, A_CUM: 0, D_CUM: 0, },
      { CDATE: '2024-06-28', DISC: 'Structure', TOTAL: 24899, P_WK: 0, A_WK: 0, D_WK: 0, P_CUM: 0, A_CUM: 0, D_CUM: 0, },
      { CDATE: '2024-07-05', DISC: 'Structure', TOTAL: 24899, P_WK: 0, A_WK: 0, D_WK: 0, P_CUM: 0, A_CUM: 0, D_CUM: 0, },
      { CDATE: '2024-07-12', DISC: 'Structure', TOTAL: 24899, P_WK: 0, A_WK: 0, D_WK: 0, P_CUM: 0, A_CUM: 0, D_CUM: 0, },
      { CDATE: '2024-07-19', DISC: 'Structure', TOTAL: 24899, P_WK: 0, A_WK: 0, D_WK: 0, P_CUM: 0, A_CUM: 0, D_CUM: 0, },
      { CDATE: '2024-07-26', DISC: 'Structure', TOTAL: 24899, P_WK: 0, A_WK: 0, D_WK: 0, P_CUM: 0, A_CUM: 0, D_CUM: 0, },
      { CDATE: '2024-08-02', DISC: 'Structure', TOTAL: 24899, P_WK: 0, A_WK: 0, D_WK: 0, P_CUM: 0, A_CUM: 0, D_CUM: 0, },
      { CDATE: '2024-08-09', DISC: 'Structure', TOTAL: 24899, P_WK: 0, A_WK: 0, D_WK: 0, P_CUM: 0, A_CUM: 0, D_CUM: 0, },
      { CDATE: '2024-08-16', DISC: 'Structure', TOTAL: 24899, P_WK: 0, A_WK: 0, D_WK: 0, P_CUM: 0, A_CUM: 0, D_CUM: 0, },
      { CDATE: '2024-08-23', DISC: 'Structure', TOTAL: 24899, P_WK: 0, A_WK: 0, D_WK: 0, P_CUM: 0, A_CUM: 0, D_CUM: 0, },
      { CDATE: '2024-08-30', DISC: 'Structure', TOTAL: 24899, P_WK: 0, A_WK: 0, D_WK: 0, P_CUM: 0, A_CUM: 0, D_CUM: 0, },
      { CDATE: '2024-09-06', DISC: 'Structure', TOTAL: 24899, P_WK: 0, A_WK: 0, D_WK: 0, P_CUM: 0, A_CUM: 0, D_CUM: 0, },
      { CDATE: '2024-09-13', DISC: 'Structure', TOTAL: 24899, P_WK: 0, A_WK: 0, D_WK: 0, P_CUM: 0, A_CUM: 0, D_CUM: 0, },
      { CDATE: '2024-09-20', DISC: 'Structure', TOTAL: 24899, P_WK: 0, A_WK: 0, D_WK: 0, P_CUM: 0, A_CUM: 0, D_CUM: 0, },
      { CDATE: '2024-09-27', DISC: 'Structure', TOTAL: 24899, P_WK: 0, A_WK: 0, D_WK: 0, P_CUM: 0, A_CUM: 0, D_CUM: 0, },
      { CDATE: '2024-10-04', DISC: 'Structure', TOTAL: 24899, P_WK: 0, A_WK: 0, D_WK: 0, P_CUM: 0, A_CUM: 0, D_CUM: 0, },
      { CDATE: '2024-10-11', DISC: 'Structure', TOTAL: 24899, P_WK: 0, A_WK: 0, D_WK: 0, P_CUM: 0, A_CUM: 0, D_CUM: 0, },
      { CDATE: '2024-10-18', DISC: 'Structure', TOTAL: 24899, P_WK: 0, A_WK: 0, D_WK: 0, P_CUM: 0, A_CUM: 0, D_CUM: 0, },
      { CDATE: '2024-10-25', DISC: 'Structure', TOTAL: 24899, P_WK: 0, A_WK: 0, D_WK: 0, P_CUM: 0, A_CUM: 0, D_CUM: 0, },
      { CDATE: '2024-11-01', DISC: 'Structure', TOTAL: 24899, P_WK: 2, A_WK: 0, D_WK: -2, P_CUM: 2, A_CUM: 0, D_CUM: -2, },
      { CDATE: '2024-11-08', DISC: 'Structure', TOTAL: 24899, P_WK: 28, A_WK: 0, D_WK: -28, P_CUM: 30, A_CUM: 0, D_CUM: -30, },
      { CDATE: '2024-11-15', DISC: 'Structure', TOTAL: 24899, P_WK: 48, A_WK: 17, D_WK: -31, P_CUM: 78, A_CUM: 17, D_CUM: -61, },
      { CDATE: '2024-11-22', DISC: 'Structure', TOTAL: 24899, P_WK: 59, A_WK: 46, D_WK: -13, P_CUM: 137, A_CUM: 63, D_CUM: -74, },
      { CDATE: '2024-11-29', DISC: 'Structure', TOTAL: 24899, P_WK: 62, A_WK: 71, D_WK: 9, P_CUM: 199, A_CUM: 134, D_CUM: -65, },
      { CDATE: '2024-12-06', DISC: 'Structure', TOTAL: 24899, P_WK: 78, A_WK: 112, D_WK: 34, P_CUM: 277, A_CUM: 246, D_CUM: -31, },
      { CDATE: '2024-12-13', DISC: 'Structure', TOTAL: 24899, P_WK: 92, A_WK: 126, D_WK: 34, P_CUM: 369, A_CUM: 372, D_CUM: 3, },
      { CDATE: '2024-12-20', DISC: 'Structure', TOTAL: 24899, P_WK: 98, A_WK: 171, D_WK: 73, P_CUM: 467, A_CUM: 543, D_CUM: 76, },
      { CDATE: '2024-12-27', DISC: 'Structure', TOTAL: 24899, P_WK: 76, A_WK: 176, D_WK: 100, P_CUM: 543, A_CUM: 719, D_CUM: 176, },
      { CDATE: '2025-01-03', DISC: 'Structure', TOTAL: 24899, P_WK: 76, A_WK: 0, D_WK: 0, P_CUM: 619, A_CUM: 719, D_CUM: 0, },
      { CDATE: '2025-01-10', DISC: 'Structure', TOTAL: 24899, P_WK: 90, A_WK: 0, D_WK: 0, P_CUM: 709, A_CUM: 719, D_CUM: 0, },
      { CDATE: '2025-01-17', DISC: 'Structure', TOTAL: 24899, P_WK: 110, A_WK: 0, D_WK: 0, P_CUM: 819, A_CUM: 719, D_CUM: 0, },
      { CDATE: '2025-01-24', DISC: 'Structure', TOTAL: 24899, P_WK: 150, A_WK: 0, D_WK: 0, P_CUM: 969, A_CUM: 719, D_CUM: 0, },
      { CDATE: '2025-01-31', DISC: 'Structure', TOTAL: 24899, P_WK: 68, A_WK: 0, D_WK: 0, P_CUM: 1037, A_CUM: 719, D_CUM: 0, },
      { CDATE: '2025-02-07', DISC: 'Structure', TOTAL: 24899, P_WK: 187, A_WK: 0, D_WK: 0, P_CUM: 1224, A_CUM: 719, D_CUM: 0, },
      { CDATE: '2025-02-14', DISC: 'Structure', TOTAL: 24899, P_WK: 190, A_WK: 0, D_WK: 0, P_CUM: 1414, A_CUM: 719, D_CUM: 0, },
      { CDATE: '2025-02-21', DISC: 'Structure', TOTAL: 24899, P_WK: 204, A_WK: 0, D_WK: 0, P_CUM: 1618, A_CUM: 719, D_CUM: 0, },
      { CDATE: '2025-02-28', DISC: 'Structure', TOTAL: 24899, P_WK: 269, A_WK: 0, D_WK: 0, P_CUM: 1887, A_CUM: 719, D_CUM: 0, },
      { CDATE: '2025-03-07', DISC: 'Structure', TOTAL: 24899, P_WK: 338, A_WK: 0, D_WK: 0, P_CUM: 2225, A_CUM: 719, D_CUM: 0, },
      { CDATE: '2025-03-14', DISC: 'Structure', TOTAL: 24899, P_WK: 329, A_WK: 0, D_WK: 0, P_CUM: 2554, A_CUM: 719, D_CUM: 0, },
      { CDATE: '2025-03-21', DISC: 'Structure', TOTAL: 24899, P_WK: 332, A_WK: 0, D_WK: 0, P_CUM: 2886, A_CUM: 719, D_CUM: 0, },
      { CDATE: '2025-03-28', DISC: 'Structure', TOTAL: 24899, P_WK: 310, A_WK: 0, D_WK: 0, P_CUM: 3196, A_CUM: 719, D_CUM: 0, },
      { CDATE: '2025-04-04', DISC: 'Structure', TOTAL: 24899, P_WK: 252, A_WK: 0, D_WK: 0, P_CUM: 3448, A_CUM: 719, D_CUM: 0, },
      { CDATE: '2025-04-11', DISC: 'Structure', TOTAL: 24899, P_WK: 287, A_WK: 0, D_WK: 0, P_CUM: 3735, A_CUM: 719, D_CUM: 0, },
      { CDATE: '2025-04-18', DISC: 'Structure', TOTAL: 24899, P_WK: 342, A_WK: 0, D_WK: 0, P_CUM: 4077, A_CUM: 719, D_CUM: 0, },
      { CDATE: '2025-04-25', DISC: 'Structure', TOTAL: 24899, P_WK: 387, A_WK: 0, D_WK: 0, P_CUM: 4464, A_CUM: 719, D_CUM: 0, },
      { CDATE: '2025-05-02', DISC: 'Structure', TOTAL: 24899, P_WK: 290, A_WK: 0, D_WK: 0, P_CUM: 4754, A_CUM: 719, D_CUM: 0, },
      { CDATE: '2025-05-09', DISC: 'Structure', TOTAL: 24899, P_WK: 265, A_WK: 0, D_WK: 0, P_CUM: 5019, A_CUM: 719, D_CUM: 0, },
      { CDATE: '2025-05-16', DISC: 'Structure', TOTAL: 24899, P_WK: 369, A_WK: 0, D_WK: 0, P_CUM: 5388, A_CUM: 719, D_CUM: 0, },
      { CDATE: '2025-05-23', DISC: 'Structure', TOTAL: 24899, P_WK: 406, A_WK: 0, D_WK: 0, P_CUM: 5794, A_CUM: 719, D_CUM: 0, },
      { CDATE: '2025-05-30', DISC: 'Structure', TOTAL: 24899, P_WK: 421, A_WK: 0, D_WK: 0, P_CUM: 6215, A_CUM: 719, D_CUM: 0, },
      { CDATE: '2024-06-07', DISC: 'Mechanical', TOTAL: 3511,  P_WK: 0, A_WK: 0, D_WK: 0, P_CUM: 0, A_CUM: 0, D_CUM: 0, },
      { CDATE: '2024-06-14', DISC: 'Mechanical', TOTAL: 3511,  P_WK: 0, A_WK: 0, D_WK: 0, P_CUM: 0, A_CUM: 0, D_CUM: 0, },
      { CDATE: '2024-06-21', DISC: 'Mechanical', TOTAL: 3511,  P_WK: 0, A_WK: 0, D_WK: 0, P_CUM: 0, A_CUM: 0, D_CUM: 0, },
      { CDATE: '2024-06-28', DISC: 'Mechanical', TOTAL: 3511,  P_WK: 0, A_WK: 0, D_WK: 0, P_CUM: 0, A_CUM: 0, D_CUM: 0, },
      { CDATE: '2024-07-05', DISC: 'Mechanical', TOTAL: 3511,  P_WK: 0, A_WK: 0, D_WK: 0, P_CUM: 0, A_CUM: 0, D_CUM: 0, },
      { CDATE: '2024-07-12', DISC: 'Mechanical', TOTAL: 3511,  P_WK: 0, A_WK: 0, D_WK: 0, P_CUM: 0, A_CUM: 0, D_CUM: 0, },
      { CDATE: '2024-07-19', DISC: 'Mechanical', TOTAL: 3511,  P_WK: 0, A_WK: 0, D_WK: 0, P_CUM: 0, A_CUM: 0, D_CUM: 0, },
      { CDATE: '2024-07-26', DISC: 'Mechanical', TOTAL: 3511,  P_WK: 0, A_WK: 0, D_WK: 0, P_CUM: 0, A_CUM: 0, D_CUM: 0, },
      { CDATE: '2024-08-02', DISC: 'Mechanical', TOTAL: 3511,  P_WK: 0, A_WK: 0, D_WK: 0, P_CUM: 0, A_CUM: 0, D_CUM: 0, },
      { CDATE: '2024-08-09', DISC: 'Mechanical', TOTAL: 3511,  P_WK: 0, A_WK: 0, D_WK: 0, P_CUM: 0, A_CUM: 0, D_CUM: 0, },
      { CDATE: '2024-08-16', DISC: 'Mechanical', TOTAL: 3511,  P_WK: 0, A_WK: 0, D_WK: 0, P_CUM: 0, A_CUM: 0, D_CUM: 0, },
      { CDATE: '2024-08-23', DISC: 'Mechanical', TOTAL: 3511,  P_WK: 0, A_WK: 0, D_WK: 0, P_CUM: 0, A_CUM: 0, D_CUM: 0, },
      { CDATE: '2024-08-30', DISC: 'Mechanical', TOTAL: 3511,  P_WK: 0, A_WK: 0, D_WK: 0, P_CUM: 0, A_CUM: 0, D_CUM: 0, },
      { CDATE: '2024-09-06', DISC: 'Mechanical', TOTAL: 3511,  P_WK: 0, A_WK: 0, D_WK: 0, P_CUM: 0, A_CUM: 0, D_CUM: 0, },
      { CDATE: '2024-09-13', DISC: 'Mechanical', TOTAL: 3511,  P_WK: 0, A_WK: 0, D_WK: 0, P_CUM: 0, A_CUM: 0, D_CUM: 0, },
      { CDATE: '2024-09-20', DISC: 'Mechanical', TOTAL: 3511,  P_WK: 0, A_WK: 0, D_WK: 0, P_CUM: 0, A_CUM: 0, D_CUM: 0, },
      { CDATE: '2024-09-27', DISC: 'Mechanical', TOTAL: 3511,  P_WK: 0, A_WK: 0, D_WK: 0, P_CUM: 0, A_CUM: 0, D_CUM: 0, },
      { CDATE: '2024-10-04', DISC: 'Mechanical', TOTAL: 3511,  P_WK: 0, A_WK: 0, D_WK: 0, P_CUM: 0, A_CUM: 0, D_CUM: 0, },
      { CDATE: '2024-10-11', DISC: 'Mechanical', TOTAL: 3511,  P_WK: 0, A_WK: 0, D_WK: 0, P_CUM: 0, A_CUM: 0, D_CUM: 0, },
      { CDATE: '2024-10-18', DISC: 'Mechanical', TOTAL: 3511,  P_WK: 0, A_WK: 0, D_WK: 0, P_CUM: 0, A_CUM: 0, D_CUM: 0, },
      { CDATE: '2024-10-25', DISC: 'Mechanical', TOTAL: 3511,  P_WK: 0, A_WK: 0, D_WK: 0, P_CUM: 0, A_CUM: 0, D_CUM: 0, },
      { CDATE: '2024-11-01', DISC: 'Mechanical', TOTAL: 3511,  P_WK: 0, A_WK: 0, D_WK: 0, P_CUM: 0, A_CUM: 0, D_CUM: 0, },
      { CDATE: '2024-11-08', DISC: 'Mechanical', TOTAL: 3511,  P_WK: 0, A_WK: 0, D_WK: 0, P_CUM: 0, A_CUM: 0, D_CUM: 0, },
      { CDATE: '2024-11-15', DISC: 'Mechanical', TOTAL: 3511,  P_WK: 0, A_WK: 0, D_WK: 0, P_CUM: 0, A_CUM: 0, D_CUM: 0, },
      { CDATE: '2024-11-22', DISC: 'Mechanical', TOTAL: 3511,  P_WK: 0, A_WK: 0, D_WK: 0, P_CUM: 0, A_CUM: 0, D_CUM: 0, },
      { CDATE: '2024-11-29', DISC: 'Mechanical', TOTAL: 3511,  P_WK: 0, A_WK: 0, D_WK: 0, P_CUM: 0, A_CUM: 0, D_CUM: 0, },
      { CDATE: '2024-12-06', DISC: 'Mechanical', TOTAL: 3511,  P_WK: 0, A_WK: 0, D_WK: 0, P_CUM: 0, A_CUM: 0, D_CUM: 0, },
      { CDATE: '2024-12-13', DISC: 'Mechanical', TOTAL: 3511,  P_WK: 0, A_WK: 0, D_WK: 0, P_CUM: 0, A_CUM: 0, D_CUM: 0, },
      { CDATE: '2024-12-20', DISC: 'Mechanical', TOTAL: 3511,  P_WK: 0, A_WK: 0, D_WK: 0, P_CUM: 0, A_CUM: 0, D_CUM: 0, },
      { CDATE: '2024-12-27', DISC: 'Mechanical', TOTAL: 3511,  P_WK: 0, A_WK: 0, D_WK: 0, P_CUM: 0, A_CUM: 0, D_CUM: 0, },
      { CDATE: '2025-01-03', DISC: 'Mechanical', TOTAL: 3511,  P_WK: 0, A_WK: 0, D_WK: 0, P_CUM: 0, A_CUM: 0, D_CUM: 0, },
      { CDATE: '2025-01-10', DISC: 'Mechanical', TOTAL: 3511,  P_WK: 0, A_WK: 0, D_WK: 0, P_CUM: 0, A_CUM: 0, D_CUM: 0, },
      { CDATE: '2025-01-17', DISC: 'Mechanical', TOTAL: 3511,  P_WK: 0, A_WK: 0, D_WK: 0, P_CUM: 0, A_CUM: 0, D_CUM: 0, },
      { CDATE: '2025-01-24', DISC: 'Mechanical', TOTAL: 3511,  P_WK: 0, A_WK: 0, D_WK: 0, P_CUM: 0, A_CUM: 0, D_CUM: 0, },
      { CDATE: '2025-01-31', DISC: 'Mechanical', TOTAL: 3511,  P_WK: 0, A_WK: 0, D_WK: 0, P_CUM: 0, A_CUM: 0, D_CUM: 0, },
      { CDATE: '2025-02-07', DISC: 'Mechanical', TOTAL: 3511,  P_WK: 0, A_WK: 0, D_WK: 0, P_CUM: 0, A_CUM: 0, D_CUM: 0, },
      { CDATE: '2025-02-14', DISC: 'Mechanical', TOTAL: 3511,  P_WK: 2, A_WK: 0, D_WK: 0, P_CUM: 2, A_CUM: 0, D_CUM: 0, },
      { CDATE: '2025-02-21', DISC: 'Mechanical', TOTAL: 3511,  P_WK: 3, A_WK: 0, D_WK: 0, P_CUM: 5, A_CUM: 0, D_CUM: 0, },
      { CDATE: '2025-02-28', DISC: 'Mechanical', TOTAL: 3511,  P_WK: 5, A_WK: 0, D_WK: 0, P_CUM: 10, A_CUM: 0, D_CUM: 0, },
      { CDATE: '2025-03-07', DISC: 'Mechanical', TOTAL: 3511,  P_WK: 6, A_WK: 0, D_WK: 0, P_CUM: 16, A_CUM: 0, D_CUM: 0, },
      { CDATE: '2025-03-14', DISC: 'Mechanical', TOTAL: 3511,  P_WK: 4, A_WK: 0, D_WK: 0, P_CUM: 20, A_CUM: 0, D_CUM: 0, },
      { CDATE: '2025-03-21', DISC: 'Mechanical', TOTAL: 3511,  P_WK: 3, A_WK: 0, D_WK: 0, P_CUM: 23, A_CUM: 0, D_CUM: 0, },
      { CDATE: '2025-03-28', DISC: 'Mechanical', TOTAL: 3511,  P_WK: 4, A_WK: 0, D_WK: 0, P_CUM: 27, A_CUM: 0, D_CUM: 0, },
      { CDATE: '2025-04-04', DISC: 'Mechanical', TOTAL: 3511,  P_WK: 7, A_WK: 0, D_WK: 0, P_CUM: 34, A_CUM: 0, D_CUM: 0, },
      { CDATE: '2025-04-11', DISC: 'Mechanical', TOTAL: 3511,  P_WK: 15, A_WK: 0, D_WK: 0, P_CUM: 49, A_CUM: 0, D_CUM: 0, },
      { CDATE: '2025-04-18', DISC: 'Mechanical', TOTAL: 3511,  P_WK: 20, A_WK: 0, D_WK: 0, P_CUM: 69, A_CUM: 0, D_CUM: 0, },
      { CDATE: '2025-04-25', DISC: 'Mechanical', TOTAL: 3511,  P_WK: 24, A_WK: 0, D_WK: 0, P_CUM: 93, A_CUM: 0, D_CUM: 0, },
      { CDATE: '2025-05-02', DISC: 'Mechanical', TOTAL: 3511,  P_WK: 23, A_WK: 0, D_WK: 0, P_CUM: 116, A_CUM: 0, D_CUM: 0, },
      { CDATE: '2025-05-09', DISC: 'Mechanical', TOTAL: 3511,  P_WK: 25, A_WK: 0, D_WK: 0, P_CUM: 141, A_CUM: 0, D_CUM: 0, },
      { CDATE: '2025-05-16', DISC: 'Mechanical', TOTAL: 3511,  P_WK: 30, A_WK: 0, D_WK: 0, P_CUM: 171, A_CUM: 0, D_CUM: 0, },
      { CDATE: '2025-05-23', DISC: 'Mechanical', TOTAL: 3511,  P_WK: 22, A_WK: 0, D_WK: 0, P_CUM: 193, A_CUM: 0, D_CUM: 0, },
      { CDATE: '2025-05-30', DISC: 'Mechanical', TOTAL: 3511,  P_WK: 24, A_WK: 0, D_WK: 0, P_CUM: 217, A_CUM: 0, D_CUM: 0, },
      { CDATE: '2024-06-07', DISC: 'Piping',    TOTAL: 19726, P_WK: 0, A_WK: 0, D_WK: 0, P_CUM: 0, A_CUM: 0, D_CUM: 0, },
      { CDATE: '2024-06-14', DISC: 'Piping',    TOTAL: 19726, P_WK: 0, A_WK: 0, D_WK: 0, P_CUM: 0, A_CUM: 0, D_CUM: 0, },
      { CDATE: '2024-06-21', DISC: 'Piping',    TOTAL: 19726, P_WK: 0, A_WK: 0, D_WK: 0, P_CUM: 0, A_CUM: 0, D_CUM: 0, },
      { CDATE: '2024-06-28', DISC: 'Piping',    TOTAL: 19726, P_WK: 0, A_WK: 0, D_WK: 0, P_CUM: 0, A_CUM: 0, D_CUM: 0, },
      { CDATE: '2024-07-05', DISC: 'Piping',    TOTAL: 19726, P_WK: 0, A_WK: 0, D_WK: 0, P_CUM: 0, A_CUM: 0, D_CUM: 0, },
      { CDATE: '2024-07-12', DISC: 'Piping',    TOTAL: 19726, P_WK: 0, A_WK: 0, D_WK: 0, P_CUM: 0, A_CUM: 0, D_CUM: 0, },
      { CDATE: '2024-07-19', DISC: 'Piping',    TOTAL: 19726, P_WK: 0, A_WK: 0, D_WK: 0, P_CUM: 0, A_CUM: 0, D_CUM: 0, },
      { CDATE: '2024-07-26', DISC: 'Piping',    TOTAL: 19726, P_WK: 0, A_WK: 0, D_WK: 0, P_CUM: 0, A_CUM: 0, D_CUM: 0, },
      { CDATE: '2024-08-02', DISC: 'Piping',    TOTAL: 19726, P_WK: 0, A_WK: 0, D_WK: 0, P_CUM: 0, A_CUM: 0, D_CUM: 0, },
      { CDATE: '2024-08-09', DISC: 'Piping',    TOTAL: 19726, P_WK: 0, A_WK: 0, D_WK: 0, P_CUM: 0, A_CUM: 0, D_CUM: 0, },
      { CDATE: '2024-08-16', DISC: 'Piping',    TOTAL: 19726, P_WK: 0, A_WK: 0, D_WK: 0, P_CUM: 0, A_CUM: 0, D_CUM: 0, },
      { CDATE: '2024-08-23', DISC: 'Piping',    TOTAL: 19726, P_WK: 0, A_WK: 0, D_WK: 0, P_CUM: 0, A_CUM: 0, D_CUM: 0, },
      { CDATE: '2024-08-30', DISC: 'Piping',    TOTAL: 19726, P_WK: 0, A_WK: 0, D_WK: 0, P_CUM: 0, A_CUM: 0, D_CUM: 0, },
      { CDATE: '2024-09-06', DISC: 'Piping',    TOTAL: 19726, P_WK: 0, A_WK: 0, D_WK: 0, P_CUM: 0, A_CUM: 0, D_CUM: 0, },
      { CDATE: '2024-09-13', DISC: 'Piping',    TOTAL: 19726, P_WK: 0, A_WK: 0, D_WK: 0, P_CUM: 0, A_CUM: 0, D_CUM: 0, },
      { CDATE: '2024-09-20', DISC: 'Piping',    TOTAL: 19726, P_WK: 0, A_WK: 0, D_WK: 0, P_CUM: 0, A_CUM: 0, D_CUM: 0, },
      { CDATE: '2024-09-27', DISC: 'Piping',    TOTAL: 19726, P_WK: 0, A_WK: 0, D_WK: 0, P_CUM: 0, A_CUM: 0, D_CUM: 0, },
      { CDATE: '2024-10-04', DISC: 'Piping',    TOTAL: 19726, P_WK: 0, A_WK: 0, D_WK: 0, P_CUM: 0, A_CUM: 0, D_CUM: 0, },
      { CDATE: '2024-10-11', DISC: 'Piping',    TOTAL: 19726, P_WK: 0, A_WK: 0, D_WK: 0, P_CUM: 0, A_CUM: 0, D_CUM: 0, },
      { CDATE: '2024-10-18', DISC: 'Piping',    TOTAL: 19726, P_WK: 0, A_WK: 0, D_WK: 0, P_CUM: 0, A_CUM: 0, D_CUM: 0, },
      { CDATE: '2024-10-25', DISC: 'Piping',    TOTAL: 19726, P_WK: 0, A_WK: 0, D_WK: 0, P_CUM: 0, A_CUM: 0, D_CUM: 0, },
      { CDATE: '2024-11-01', DISC: 'Piping',    TOTAL: 19726, P_WK: 0, A_WK: 0, D_WK: 0, P_CUM: 0, A_CUM: 0, D_CUM: 0, },
      { CDATE: '2024-11-08', DISC: 'Piping',    TOTAL: 19726, P_WK: 0, A_WK: 0, D_WK: 0, P_CUM: 0, A_CUM: 0, D_CUM: 0, },
      { CDATE: '2024-11-15', DISC: 'Piping',    TOTAL: 19726, P_WK: 0, A_WK: 0, D_WK: 0, P_CUM: 0, A_CUM: 0, D_CUM: 0, },
      { CDATE: '2024-11-22', DISC: 'Piping',    TOTAL: 19726, P_WK: 0, A_WK: 0, D_WK: 0, P_CUM: 0, A_CUM: 0, D_CUM: 0, },
      { CDATE: '2024-11-29', DISC: 'Piping',    TOTAL: 19726, P_WK: 0, A_WK: 0, D_WK: 0, P_CUM: 0, A_CUM: 0, D_CUM: 0, },
      { CDATE: '2024-12-06', DISC: 'Piping',    TOTAL: 19726, P_WK: 0, A_WK: 0, D_WK: 0, P_CUM: 0, A_CUM: 0, D_CUM: 0, },
      { CDATE: '2024-12-13', DISC: 'Piping',    TOTAL: 19726, P_WK: 0, A_WK: 0, D_WK: 0, P_CUM: 0, A_CUM: 0, D_CUM: 0, },
      { CDATE: '2024-12-20', DISC: 'Piping',    TOTAL: 19726, P_WK: 0, A_WK: 0, D_WK: 0, P_CUM: 0, A_CUM: 0, D_CUM: 0, },
      { CDATE: '2024-12-27', DISC: 'Piping',    TOTAL: 19726, P_WK: 0, A_WK: 0, D_WK: 0, P_CUM: 0, A_CUM: 0, D_CUM: 0, },
      { CDATE: '2025-01-03', DISC: 'Piping',    TOTAL: 19726, P_WK: 0, A_WK: 0, D_WK: 0, P_CUM: 0, A_CUM: 0, D_CUM: 0, },
      { CDATE: '2025-01-10', DISC: 'Piping',    TOTAL: 19726, P_WK: 0, A_WK: 0, D_WK: 0, P_CUM: 0, A_CUM: 0, D_CUM: 0, },
      { CDATE: '2025-01-17', DISC: 'Piping',    TOTAL: 19726, P_WK: 0, A_WK: 0, D_WK: 0, P_CUM: 0, A_CUM: 0, D_CUM: 0, },
      { CDATE: '2025-01-24', DISC: 'Piping',    TOTAL: 19726, P_WK: 0, A_WK: 0, D_WK: 0, P_CUM: 0, A_CUM: 0, D_CUM: 0, },
      { CDATE: '2025-01-31', DISC: 'Piping',    TOTAL: 19726, P_WK: 0, A_WK: 0, D_WK: 0, P_CUM: 0, A_CUM: 0, D_CUM: 0, },
      { CDATE: '2025-02-07', DISC: 'Piping',    TOTAL: 19726, P_WK: 0, A_WK: 0, D_WK: 0, P_CUM: 0, A_CUM: 0, D_CUM: 0, },
      { CDATE: '2025-02-14', DISC: 'Piping',    TOTAL: 19726, P_WK: 9, A_WK: 0, D_WK: 0, P_CUM: 9, A_CUM: 0, D_CUM: 0, },
      { CDATE: '2025-02-21', DISC: 'Piping',    TOTAL: 19726, P_WK: 17, A_WK: 0, D_WK: 0, P_CUM: 26, A_CUM: 0, D_CUM: 0, },
      { CDATE: '2025-02-28', DISC: 'Piping',    TOTAL: 19726, P_WK: 28, A_WK: 0, D_WK: 0, P_CUM: 54, A_CUM: 0, D_CUM: 0, },
      { CDATE: '2025-03-07', DISC: 'Piping',    TOTAL: 19726, P_WK: 40, A_WK: 0, D_WK: 0, P_CUM: 94, A_CUM: 0, D_CUM: 0, },
      { CDATE: '2025-03-14', DISC: 'Piping',    TOTAL: 19726, P_WK: 44, A_WK: 0, D_WK: 0, P_CUM: 138, A_CUM: 0, D_CUM: 0, },
      { CDATE: '2025-03-21', DISC: 'Piping',    TOTAL: 19726, P_WK: 49, A_WK: 0, D_WK: 0, P_CUM: 187, A_CUM: 0, D_CUM: 0, },
      { CDATE: '2025-03-28', DISC: 'Piping',    TOTAL: 19726, P_WK: 54, A_WK: 0, D_WK: 0, P_CUM: 241, A_CUM: 0, D_CUM: 0, },
      { CDATE: '2025-04-04', DISC: 'Piping',    TOTAL: 19726, P_WK: 66, A_WK: 0, D_WK: 0, P_CUM: 307, A_CUM: 0, D_CUM: 0, },
      { CDATE: '2025-04-11', DISC: 'Piping',    TOTAL: 19726, P_WK: 76, A_WK: 0, D_WK: 0, P_CUM: 383, A_CUM: 0, D_CUM: 0, },
      { CDATE: '2025-04-18', DISC: 'Piping',    TOTAL: 19726, P_WK: 94, A_WK: 0, D_WK: 0, P_CUM: 477, A_CUM: 0, D_CUM: 0, },
      { CDATE: '2025-04-25', DISC: 'Piping',    TOTAL: 19726, P_WK: 123, A_WK: 0, D_WK: 0, P_CUM: 600, A_CUM: 0, D_CUM: 0, },
      { CDATE: '2025-05-02', DISC: 'Piping',    TOTAL: 19726, P_WK: 122, A_WK: 0, D_WK: 0, P_CUM: 722, A_CUM: 0, D_CUM: 0, },
      { CDATE: '2025-05-09', DISC: 'Piping',    TOTAL: 19726, P_WK: 136, A_WK: 0, D_WK: 0, P_CUM: 858, A_CUM: 0, D_CUM: 0, },
      { CDATE: '2025-05-16', DISC: 'Piping',    TOTAL: 19726, P_WK: 201, A_WK: 0, D_WK: 0, P_CUM: 1059, A_CUM: 0, D_CUM: 0, },
      { CDATE: '2025-05-23', DISC: 'Piping',    TOTAL: 19726, P_WK: 233, A_WK: 0, D_WK: 0, P_CUM: 1292, A_CUM: 0, D_CUM: 0, },
      { CDATE: '2025-05-30', DISC: 'Piping',    TOTAL: 19726, P_WK: 235, A_WK: 0, D_WK: 0, P_CUM: 1527, A_CUM: 0, D_CUM: 0, },
      { CDATE: '2024-06-07', DISC: 'Elect/Inst/Tel', TOTAL: 12480, P_WK: 0, A_WK: 0, D_WK: 0, P_CUM: 0, A_CUM: 0, D_CUM: 0, },
      { CDATE: '2024-06-14', DISC: 'Elect/Inst/Tel', TOTAL: 12480, P_WK: 0, A_WK: 0, D_WK: 0, P_CUM: 0, A_CUM: 0, D_CUM: 0, },
      { CDATE: '2024-06-21', DISC: 'Elect/Inst/Tel', TOTAL: 12480, P_WK: 0, A_WK: 0, D_WK: 0, P_CUM: 0, A_CUM: 0, D_CUM: 0, },
      { CDATE: '2024-06-28', DISC: 'Elect/Inst/Tel', TOTAL: 12480, P_WK: 0, A_WK: 0, D_WK: 0, P_CUM: 0, A_CUM: 0, D_CUM: 0, },
      { CDATE: '2024-07-05', DISC: 'Elect/Inst/Tel', TOTAL: 12480, P_WK: 0, A_WK: 0, D_WK: 0, P_CUM: 0, A_CUM: 0, D_CUM: 0, },
      { CDATE: '2024-07-12', DISC: 'Elect/Inst/Tel', TOTAL: 12480, P_WK: 0, A_WK: 0, D_WK: 0, P_CUM: 0, A_CUM: 0, D_CUM: 0, },
      { CDATE: '2024-07-19', DISC: 'Elect/Inst/Tel', TOTAL: 12480, P_WK: 0, A_WK: 0, D_WK: 0, P_CUM: 0, A_CUM: 0, D_CUM: 0, },
      { CDATE: '2024-07-26', DISC: 'Elect/Inst/Tel', TOTAL: 12480, P_WK: 0, A_WK: 0, D_WK: 0, P_CUM: 0, A_CUM: 0, D_CUM: 0, },
      { CDATE: '2024-08-02', DISC: 'Elect/Inst/Tel', TOTAL: 12480, P_WK: 0, A_WK: 0, D_WK: 0, P_CUM: 0, A_CUM: 0, D_CUM: 0, },
      { CDATE: '2024-08-09', DISC: 'Elect/Inst/Tel', TOTAL: 12480, P_WK: 0, A_WK: 0, D_WK: 0, P_CUM: 0, A_CUM: 0, D_CUM: 0, },
      { CDATE: '2024-08-16', DISC: 'Elect/Inst/Tel', TOTAL: 12480, P_WK: 0, A_WK: 0, D_WK: 0, P_CUM: 0, A_CUM: 0, D_CUM: 0, },
      { CDATE: '2024-08-23', DISC: 'Elect/Inst/Tel', TOTAL: 12480, P_WK: 0, A_WK: 0, D_WK: 0, P_CUM: 0, A_CUM: 0, D_CUM: 0, },
      { CDATE: '2024-08-30', DISC: 'Elect/Inst/Tel', TOTAL: 12480, P_WK: 0, A_WK: 0, D_WK: 0, P_CUM: 0, A_CUM: 0, D_CUM: 0, },
      { CDATE: '2024-09-06', DISC: 'Elect/Inst/Tel', TOTAL: 12480, P_WK: 0, A_WK: 0, D_WK: 0, P_CUM: 0, A_CUM: 0, D_CUM: 0, },
      { CDATE: '2024-09-13', DISC: 'Elect/Inst/Tel', TOTAL: 12480, P_WK: 0, A_WK: 0, D_WK: 0, P_CUM: 0, A_CUM: 0, D_CUM: 0, },
      { CDATE: '2024-09-20', DISC: 'Elect/Inst/Tel', TOTAL: 12480, P_WK: 0, A_WK: 0, D_WK: 0, P_CUM: 0, A_CUM: 0, D_CUM: 0, },
      { CDATE: '2024-09-27', DISC: 'Elect/Inst/Tel', TOTAL: 12480, P_WK: 0, A_WK: 0, D_WK: 0, P_CUM: 0, A_CUM: 0, D_CUM: 0, },
      { CDATE: '2024-10-04', DISC: 'Elect/Inst/Tel', TOTAL: 12480, P_WK: 0, A_WK: 0, D_WK: 0, P_CUM: 0, A_CUM: 0, D_CUM: 0, },
      { CDATE: '2024-10-11', DISC: 'Elect/Inst/Tel', TOTAL: 12480, P_WK: 0, A_WK: 0, D_WK: 0, P_CUM: 0, A_CUM: 0, D_CUM: 0, },
      { CDATE: '2024-10-18', DISC: 'Elect/Inst/Tel', TOTAL: 12480, P_WK: 0, A_WK: 0, D_WK: 0, P_CUM: 0, A_CUM: 0, D_CUM: 0, },
      { CDATE: '2024-10-25', DISC: 'Elect/Inst/Tel', TOTAL: 12480, P_WK: 0, A_WK: 0, D_WK: 0, P_CUM: 0, A_CUM: 0, D_CUM: 0, },
      { CDATE: '2024-11-01', DISC: 'Elect/Inst/Tel', TOTAL: 12480, P_WK: 0, A_WK: 0, D_WK: 0, P_CUM: 0, A_CUM: 0, D_CUM: 0, },
      { CDATE: '2024-11-08', DISC: 'Elect/Inst/Tel', TOTAL: 12480, P_WK: 0, A_WK: 0, D_WK: 0, P_CUM: 0, A_CUM: 0, D_CUM: 0, },
      { CDATE: '2024-11-15', DISC: 'Elect/Inst/Tel', TOTAL: 12480, P_WK: 0, A_WK: 0, D_WK: 0, P_CUM: 0, A_CUM: 0, D_CUM: 0, },
      { CDATE: '2024-11-22', DISC: 'Elect/Inst/Tel', TOTAL: 12480, P_WK: 0, A_WK: 0, D_WK: 0, P_CUM: 0, A_CUM: 0, D_CUM: 0, },
      { CDATE: '2024-11-29', DISC: 'Elect/Inst/Tel', TOTAL: 12480, P_WK: 0, A_WK: 0, D_WK: 0, P_CUM: 0, A_CUM: 0, D_CUM: 0, },
      { CDATE: '2024-12-06', DISC: 'Elect/Inst/Tel', TOTAL: 12480, P_WK: 0, A_WK: 0, D_WK: 0, P_CUM: 0, A_CUM: 0, D_CUM: 0, },
      { CDATE: '2024-12-13', DISC: 'Elect/Inst/Tel', TOTAL: 12480, P_WK: 0, A_WK: 0, D_WK: 0, P_CUM: 0, A_CUM: 0, D_CUM: 0, },
      { CDATE: '2024-12-20', DISC: 'Elect/Inst/Tel', TOTAL: 12480, P_WK: 0, A_WK: 0, D_WK: 0, P_CUM: 0, A_CUM: 0, D_CUM: 0, },
      { CDATE: '2024-12-27', DISC: 'Elect/Inst/Tel', TOTAL: 12480, P_WK: 0, A_WK: 0, D_WK: 0, P_CUM: 0, A_CUM: 0, D_CUM: 0, },
      { CDATE: '2025-01-03', DISC: 'Elect/Inst/Tel', TOTAL: 12480, P_WK: 0, A_WK: 0, D_WK: 0, P_CUM: 0, A_CUM: 0, D_CUM: 0, },
      { CDATE: '2025-01-10', DISC: 'Elect/Inst/Tel', TOTAL: 12480, P_WK: 0, A_WK: 0, D_WK: 0, P_CUM: 0, A_CUM: 0, D_CUM: 0, },
      { CDATE: '2025-01-17', DISC: 'Elect/Inst/Tel', TOTAL: 12480, P_WK: 0, A_WK: 0, D_WK: 0, P_CUM: 0, A_CUM: 0, D_CUM: 0, },
      { CDATE: '2025-01-24', DISC: 'Elect/Inst/Tel', TOTAL: 12480, P_WK: 0, A_WK: 0, D_WK: 0, P_CUM: 0, A_CUM: 0, D_CUM: 0, },
      { CDATE: '2025-01-31', DISC: 'Elect/Inst/Tel', TOTAL: 12480, P_WK: 0, A_WK: 0, D_WK: 0, P_CUM: 0, A_CUM: 0, D_CUM: 0, },
      { CDATE: '2025-02-07', DISC: 'Elect/Inst/Tel', TOTAL: 12480, P_WK: 0, A_WK: 0, D_WK: 0, P_CUM: 0, A_CUM: 0, D_CUM: 0, },
      { CDATE: '2025-02-14', DISC: 'Elect/Inst/Tel', TOTAL: 12480, P_WK: 5, A_WK: 0, D_WK: 0, P_CUM: 5, A_CUM: 0, D_CUM: 0, },
      { CDATE: '2025-02-21', DISC: 'Elect/Inst/Tel', TOTAL: 12480, P_WK: 9, A_WK: 0, D_WK: 0, P_CUM: 14, A_CUM: 0, D_CUM: 0, },
      { CDATE: '2025-02-28', DISC: 'Elect/Inst/Tel', TOTAL: 12480, P_WK: 14, A_WK: 0, D_WK: 0, P_CUM: 28, A_CUM: 0, D_CUM: 0, },
      { CDATE: '2025-03-07', DISC: 'Elect/Inst/Tel', TOTAL: 12480, P_WK: 17, A_WK: 0, D_WK: 0, P_CUM: 45, A_CUM: 0, D_CUM: 0, },
      { CDATE: '2025-03-14', DISC: 'Elect/Inst/Tel', TOTAL: 12480, P_WK: 13, A_WK: 0, D_WK: 0, P_CUM: 58, A_CUM: 0, D_CUM: 0, },
      { CDATE: '2025-03-21', DISC: 'Elect/Inst/Tel', TOTAL: 12480, P_WK: 9, A_WK: 0, D_WK: 0, P_CUM: 67, A_CUM: 0, D_CUM: 0, },
      { CDATE: '2025-03-28', DISC: 'Elect/Inst/Tel', TOTAL: 12480, P_WK: 12, A_WK: 0, D_WK: 0, P_CUM: 79, A_CUM: 0, D_CUM: 0, },
      { CDATE: '2025-04-04', DISC: 'Elect/Inst/Tel', TOTAL: 12480, P_WK: 21, A_WK: 0, D_WK: 0, P_CUM: 100, A_CUM: 0, D_CUM: 0, },
      { CDATE: '2025-04-11', DISC: 'Elect/Inst/Tel', TOTAL: 12480, P_WK: 30, A_WK: 0, D_WK: 0, P_CUM: 130, A_CUM: 0, D_CUM: 0, },
      { CDATE: '2025-04-18', DISC: 'Elect/Inst/Tel', TOTAL: 12480, P_WK: 43, A_WK: 0, D_WK: 0, P_CUM: 173, A_CUM: 0, D_CUM: 0, },
      { CDATE: '2025-04-25', DISC: 'Elect/Inst/Tel', TOTAL: 12480, P_WK: 55, A_WK: 0, D_WK: 0, P_CUM: 228, A_CUM: 0, D_CUM: 0, },
      { CDATE: '2025-05-02', DISC: 'Elect/Inst/Tel', TOTAL: 12480, P_WK: 51, A_WK: 0, D_WK: 0, P_CUM: 279, A_CUM: 0, D_CUM: 0, },
      { CDATE: '2025-05-09', DISC: 'Elect/Inst/Tel', TOTAL: 12480, P_WK: 56, A_WK: 0, D_WK: 0, P_CUM: 335, A_CUM: 0, D_CUM: 0, },
      { CDATE: '2025-05-16', DISC: 'Elect/Inst/Tel', TOTAL: 12480, P_WK: 88, A_WK: 0, D_WK: 0, P_CUM: 423, A_CUM: 0, D_CUM: 0, },
      { CDATE: '2025-05-23', DISC: 'Elect/Inst/Tel', TOTAL: 12480, P_WK: 112, A_WK: 0, D_WK: 0, P_CUM: 535, A_CUM: 0, D_CUM: 0, },
      { CDATE: '2025-05-30', DISC: 'Elect/Inst/Tel', TOTAL: 12480, P_WK: 119, A_WK: 0, D_WK: 0, P_CUM: 654, A_CUM: 0, D_CUM: 0, },
      { CDATE: '2024-06-07', DISC: 'HVAC & Arch.', TOTAL: 1948,  P_WK: 0, A_WK: 0, D_WK: 0, P_CUM: 0, A_CUM: 0, D_CUM: 0, },
      { CDATE: '2024-06-14', DISC: 'HVAC & Arch.', TOTAL: 1948,  P_WK: 0, A_WK: 0, D_WK: 0, P_CUM: 0, A_CUM: 0, D_CUM: 0, },
      { CDATE: '2024-06-21', DISC: 'HVAC & Arch.', TOTAL: 1948,  P_WK: 0, A_WK: 0, D_WK: 0, P_CUM: 0, A_CUM: 0, D_CUM: 0, },
      { CDATE: '2024-06-28', DISC: 'HVAC & Arch.', TOTAL: 1948,  P_WK: 0, A_WK: 0, D_WK: 0, P_CUM: 0, A_CUM: 0, D_CUM: 0, },
      { CDATE: '2024-07-05', DISC: 'HVAC & Arch.', TOTAL: 1948,  P_WK: 0, A_WK: 0, D_WK: 0, P_CUM: 0, A_CUM: 0, D_CUM: 0, },
      { CDATE: '2024-07-12', DISC: 'HVAC & Arch.', TOTAL: 1948,  P_WK: 0, A_WK: 0, D_WK: 0, P_CUM: 0, A_CUM: 0, D_CUM: 0, },
      { CDATE: '2024-07-19', DISC: 'HVAC & Arch.', TOTAL: 1948,  P_WK: 0, A_WK: 0, D_WK: 0, P_CUM: 0, A_CUM: 0, D_CUM: 0, },
      { CDATE: '2024-07-26', DISC: 'HVAC & Arch.', TOTAL: 1948,  P_WK: 0, A_WK: 0, D_WK: 0, P_CUM: 0, A_CUM: 0, D_CUM: 0, },
      { CDATE: '2024-08-02', DISC: 'HVAC & Arch.', TOTAL: 1948,  P_WK: 0, A_WK: 0, D_WK: 0, P_CUM: 0, A_CUM: 0, D_CUM: 0, },
      { CDATE: '2024-08-09', DISC: 'HVAC & Arch.', TOTAL: 1948,  P_WK: 0, A_WK: 0, D_WK: 0, P_CUM: 0, A_CUM: 0, D_CUM: 0, },
      { CDATE: '2024-08-16', DISC: 'HVAC & Arch.', TOTAL: 1948,  P_WK: 0, A_WK: 0, D_WK: 0, P_CUM: 0, A_CUM: 0, D_CUM: 0, },
      { CDATE: '2024-08-23', DISC: 'HVAC & Arch.', TOTAL: 1948,  P_WK: 0, A_WK: 0, D_WK: 0, P_CUM: 0, A_CUM: 0, D_CUM: 0, },
      { CDATE: '2024-08-30', DISC: 'HVAC & Arch.', TOTAL: 1948,  P_WK: 0, A_WK: 0, D_WK: 0, P_CUM: 0, A_CUM: 0, D_CUM: 0, },
      { CDATE: '2024-09-06', DISC: 'HVAC & Arch.', TOTAL: 1948,  P_WK: 0, A_WK: 0, D_WK: 0, P_CUM: 0, A_CUM: 0, D_CUM: 0, },
      { CDATE: '2024-09-13', DISC: 'HVAC & Arch.', TOTAL: 1948,  P_WK: 0, A_WK: 0, D_WK: 0, P_CUM: 0, A_CUM: 0, D_CUM: 0, },
      { CDATE: '2024-09-20', DISC: 'HVAC & Arch.', TOTAL: 1948,  P_WK: 0, A_WK: 0, D_WK: 0, P_CUM: 0, A_CUM: 0, D_CUM: 0, },
      { CDATE: '2024-09-27', DISC: 'HVAC & Arch.', TOTAL: 1948,  P_WK: 0, A_WK: 0, D_WK: 0, P_CUM: 0, A_CUM: 0, D_CUM: 0, },
      { CDATE: '2024-10-04', DISC: 'HVAC & Arch.', TOTAL: 1948,  P_WK: 0, A_WK: 0, D_WK: 0, P_CUM: 0, A_CUM: 0, D_CUM: 0, },
      { CDATE: '2024-10-11', DISC: 'HVAC & Arch.', TOTAL: 1948,  P_WK: 0, A_WK: 0, D_WK: 0, P_CUM: 0, A_CUM: 0, D_CUM: 0, },
      { CDATE: '2024-10-18', DISC: 'HVAC & Arch.', TOTAL: 1948,  P_WK: 0, A_WK: 0, D_WK: 0, P_CUM: 0, A_CUM: 0, D_CUM: 0, },
      { CDATE: '2024-10-25', DISC: 'HVAC & Arch.', TOTAL: 1948,  P_WK: 0, A_WK: 0, D_WK: 0, P_CUM: 0, A_CUM: 0, D_CUM: 0, },
      { CDATE: '2024-11-01', DISC: 'HVAC & Arch.', TOTAL: 1948,  P_WK: 0, A_WK: 0, D_WK: 0, P_CUM: 0, A_CUM: 0, D_CUM: 0, },
      { CDATE: '2024-11-08', DISC: 'HVAC & Arch.', TOTAL: 1948,  P_WK: 0, A_WK: 0, D_WK: 0, P_CUM: 0, A_CUM: 0, D_CUM: 0, },
      { CDATE: '2024-11-15', DISC: 'HVAC & Arch.', TOTAL: 1948,  P_WK: 0, A_WK: 0, D_WK: 0, P_CUM: 0, A_CUM: 0, D_CUM: 0, },
      { CDATE: '2024-11-22', DISC: 'HVAC & Arch.', TOTAL: 1948,  P_WK: 0, A_WK: 0, D_WK: 0, P_CUM: 0, A_CUM: 0, D_CUM: 0, },
      { CDATE: '2024-11-29', DISC: 'HVAC & Arch.', TOTAL: 1948,  P_WK: 0, A_WK: 0, D_WK: 0, P_CUM: 0, A_CUM: 0, D_CUM: 0, },
      { CDATE: '2024-12-06', DISC: 'HVAC & Arch.', TOTAL: 1948,  P_WK: 0, A_WK: 0, D_WK: 0, P_CUM: 0, A_CUM: 0, D_CUM: 0, },
      { CDATE: '2024-12-13', DISC: 'HVAC & Arch.', TOTAL: 1948,  P_WK: 0, A_WK: 0, D_WK: 0, P_CUM: 0, A_CUM: 0, D_CUM: 0, },
      { CDATE: '2024-12-20', DISC: 'HVAC & Arch.', TOTAL: 1948,  P_WK: 0, A_WK: 0, D_WK: 0, P_CUM: 0, A_CUM: 0, D_CUM: 0, },
      { CDATE: '2024-12-27', DISC: 'HVAC & Arch.', TOTAL: 1948,  P_WK: 0, A_WK: 0, D_WK: 0, P_CUM: 0, A_CUM: 0, D_CUM: 0, },
      { CDATE: '2025-01-03', DISC: 'HVAC & Arch.', TOTAL: 1948,  P_WK: 0, A_WK: 0, D_WK: 0, P_CUM: 0, A_CUM: 0, D_CUM: 0, },
      { CDATE: '2025-01-10', DISC: 'HVAC & Arch.', TOTAL: 1948,  P_WK: 0, A_WK: 0, D_WK: 0, P_CUM: 0, A_CUM: 0, D_CUM: 0, },
      { CDATE: '2025-01-17', DISC: 'HVAC & Arch.', TOTAL: 1948,  P_WK: 0, A_WK: 0, D_WK: 0, P_CUM: 0, A_CUM: 0, D_CUM: 0, },
      { CDATE: '2025-01-24', DISC: 'HVAC & Arch.', TOTAL: 1948,  P_WK: 0, A_WK: 0, D_WK: 0, P_CUM: 0, A_CUM: 0, D_CUM: 0, },
      { CDATE: '2025-01-31', DISC: 'HVAC & Arch.', TOTAL: 1948,  P_WK: 0, A_WK: 0, D_WK: 0, P_CUM: 0, A_CUM: 0, D_CUM: 0, },
      { CDATE: '2025-02-07', DISC: 'HVAC & Arch.', TOTAL: 1948,  P_WK: 0, A_WK: 0, D_WK: 0, P_CUM: 0, A_CUM: 0, D_CUM: 0, },
      { CDATE: '2025-02-14', DISC: 'HVAC & Arch.', TOTAL: 1948,  P_WK: 12, A_WK: 0, D_WK: 0, P_CUM: 12, A_CUM: 0, D_CUM: 0, },
      { CDATE: '2025-02-21', DISC: 'HVAC & Arch.', TOTAL: 1948,  P_WK: 18, A_WK: 0, D_WK: 0, P_CUM: 30, A_CUM: 0, D_CUM: 0, },
      { CDATE: '2025-02-28', DISC: 'HVAC & Arch.', TOTAL: 1948,  P_WK: 30, A_WK: 0, D_WK: 0, P_CUM: 60, A_CUM: 0, D_CUM: 0, },
      { CDATE: '2025-03-07', DISC: 'HVAC & Arch.', TOTAL: 1948,  P_WK: 35, A_WK: 0, D_WK: 0, P_CUM: 95, A_CUM: 0, D_CUM: 0, },
      { CDATE: '2025-03-14', DISC: 'HVAC & Arch.', TOTAL: 1948,  P_WK: 29, A_WK: 0, D_WK: 0, P_CUM: 124, A_CUM: 0, D_CUM: 0, },
      { CDATE: '2025-03-21', DISC: 'HVAC & Arch.', TOTAL: 1948,  P_WK: 42, A_WK: 0, D_WK: 0, P_CUM: 166, A_CUM: 0, D_CUM: 0, },
      { CDATE: '2025-03-28', DISC: 'HVAC & Arch.', TOTAL: 1948,  P_WK: 46, A_WK: 0, D_WK: 0, P_CUM: 212, A_CUM: 0, D_CUM: 0, },
      { CDATE: '2025-04-04', DISC: 'HVAC & Arch.', TOTAL: 1948,  P_WK: 56, A_WK: 0, D_WK: 0, P_CUM: 268, A_CUM: 0, D_CUM: 0, },
      { CDATE: '2025-04-11', DISC: 'HVAC & Arch.', TOTAL: 1948,  P_WK: 65, A_WK: 0, D_WK: 0, P_CUM: 333, A_CUM: 0, D_CUM: 0, },
      { CDATE: '2025-04-18', DISC: 'HVAC & Arch.', TOTAL: 1948,  P_WK: 63, A_WK: 0, D_WK: 0, P_CUM: 396, A_CUM: 0, D_CUM: 0, },
      { CDATE: '2025-04-25', DISC: 'HVAC & Arch.', TOTAL: 1948,  P_WK: 34, A_WK: 0, D_WK: 0, P_CUM: 430, A_CUM: 0, D_CUM: 0, },
      { CDATE: '2025-05-02', DISC: 'HVAC & Arch.', TOTAL: 1948,  P_WK: 21, A_WK: 0, D_WK: 0, P_CUM: 451, A_CUM: 0, D_CUM: 0, },
      { CDATE: '2025-05-09', DISC: 'HVAC & Arch.', TOTAL: 1948,  P_WK: 14, A_WK: 0, D_WK: 0, P_CUM: 465, A_CUM: 0, D_CUM: 0, },
      { CDATE: '2025-05-16', DISC: 'HVAC & Arch.', TOTAL: 1948,  P_WK: 17, A_WK: 0, D_WK: 0, P_CUM: 482, A_CUM: 0, D_CUM: 0, },
      { CDATE: '2025-05-23', DISC: 'HVAC & Arch.', TOTAL: 1948,  P_WK: 28, A_WK: 0, D_WK: 0, P_CUM: 510, A_CUM: 0, D_CUM: 0, },
      { CDATE: '2025-05-30', DISC: 'HVAC & Arch.', TOTAL: 1948,  P_WK: 32, A_WK: 0, D_WK: 0, P_CUM: 542, A_CUM: 0, D_CUM: 0, },
      { CDATE: '2024-06-07', DISC: 'Painting',  TOTAL: 5507,  P_WK: 0, A_WK: 0, D_WK: 0, P_CUM: 0, A_CUM: 0, D_CUM: 0, },
      { CDATE: '2024-06-14', DISC: 'Painting',  TOTAL: 5507,  P_WK: 0, A_WK: 0, D_WK: 0, P_CUM: 0, A_CUM: 0, D_CUM: 0, },
      { CDATE: '2024-06-21', DISC: 'Painting',  TOTAL: 5507,  P_WK: 0, A_WK: 0, D_WK: 0, P_CUM: 0, A_CUM: 0, D_CUM: 0, },
      { CDATE: '2024-06-28', DISC: 'Painting',  TOTAL: 5507,  P_WK: 0, A_WK: 0, D_WK: 0, P_CUM: 0, A_CUM: 0, D_CUM: 0, },
      { CDATE: '2024-07-05', DISC: 'Painting',  TOTAL: 5507,  P_WK: 0, A_WK: 0, D_WK: 0, P_CUM: 0, A_CUM: 0, D_CUM: 0, },
      { CDATE: '2024-07-12', DISC: 'Painting',  TOTAL: 5507,  P_WK: 0, A_WK: 0, D_WK: 0, P_CUM: 0, A_CUM: 0, D_CUM: 0, },
      { CDATE: '2024-07-19', DISC: 'Painting',  TOTAL: 5507,  P_WK: 0, A_WK: 0, D_WK: 0, P_CUM: 0, A_CUM: 0, D_CUM: 0, },
      { CDATE: '2024-07-26', DISC: 'Painting',  TOTAL: 5507,  P_WK: 0, A_WK: 0, D_WK: 0, P_CUM: 0, A_CUM: 0, D_CUM: 0, },
      { CDATE: '2024-08-02', DISC: 'Painting',  TOTAL: 5507,  P_WK: 0, A_WK: 0, D_WK: 0, P_CUM: 0, A_CUM: 0, D_CUM: 0, },
      { CDATE: '2024-08-09', DISC: 'Painting',  TOTAL: 5507,  P_WK: 0, A_WK: 0, D_WK: 0, P_CUM: 0, A_CUM: 0, D_CUM: 0, },
      { CDATE: '2024-08-16', DISC: 'Painting',  TOTAL: 5507,  P_WK: 0, A_WK: 0, D_WK: 0, P_CUM: 0, A_CUM: 0, D_CUM: 0, },
      { CDATE: '2024-08-23', DISC: 'Painting',  TOTAL: 5507,  P_WK: 0, A_WK: 0, D_WK: 0, P_CUM: 0, A_CUM: 0, D_CUM: 0, },
      { CDATE: '2024-08-30', DISC: 'Painting',  TOTAL: 5507,  P_WK: 0, A_WK: 0, D_WK: 0, P_CUM: 0, A_CUM: 0, D_CUM: 0, },
      { CDATE: '2024-09-06', DISC: 'Painting',  TOTAL: 5507,  P_WK: 0, A_WK: 0, D_WK: 0, P_CUM: 0, A_CUM: 0, D_CUM: 0, },
      { CDATE: '2024-09-13', DISC: 'Painting',  TOTAL: 5507,  P_WK: 0, A_WK: 0, D_WK: 0, P_CUM: 0, A_CUM: 0, D_CUM: 0, },
      { CDATE: '2024-09-20', DISC: 'Painting',  TOTAL: 5507,  P_WK: 0, A_WK: 0, D_WK: 0, P_CUM: 0, A_CUM: 0, D_CUM: 0, },
      { CDATE: '2024-09-27', DISC: 'Painting',  TOTAL: 5507,  P_WK: 0, A_WK: 0, D_WK: 0, P_CUM: 0, A_CUM: 0, D_CUM: 0, },
      { CDATE: '2024-10-04', DISC: 'Painting',  TOTAL: 5507,  P_WK: 0, A_WK: 0, D_WK: 0, P_CUM: 0, A_CUM: 0, D_CUM: 0, },
      { CDATE: '2024-10-11', DISC: 'Painting',  TOTAL: 5507,  P_WK: 0, A_WK: 0, D_WK: 0, P_CUM: 0, A_CUM: 0, D_CUM: 0, },
      { CDATE: '2024-10-18', DISC: 'Painting',  TOTAL: 5507,  P_WK: 0, A_WK: 0, D_WK: 0, P_CUM: 0, A_CUM: 0, D_CUM: 0, },
      { CDATE: '2024-10-25', DISC: 'Painting',  TOTAL: 5507,  P_WK: 0, A_WK: 0, D_WK: 0, P_CUM: 0, A_CUM: 0, D_CUM: 0, },
      { CDATE: '2024-11-01', DISC: 'Painting',  TOTAL: 5507,  P_WK: 0, A_WK: 0, D_WK: 0, P_CUM: 0, A_CUM: 0, D_CUM: 0, },
      { CDATE: '2024-11-08', DISC: 'Painting',  TOTAL: 5507,  P_WK: 0, A_WK: 0, D_WK: 0, P_CUM: 0, A_CUM: 0, D_CUM: 0, },
      { CDATE: '2024-11-15', DISC: 'Painting',  TOTAL: 5507,  P_WK: 0, A_WK: 0, D_WK: 0, P_CUM: 0, A_CUM: 0, D_CUM: 0, },
      { CDATE: '2024-11-22', DISC: 'Painting',  TOTAL: 5507,  P_WK: 0, A_WK: 0, D_WK: 0, P_CUM: 0, A_CUM: 0, D_CUM: 0, },
      { CDATE: '2024-11-29', DISC: 'Painting',  TOTAL: 5507,  P_WK: 0, A_WK: 0, D_WK: 0, P_CUM: 0, A_CUM: 0, D_CUM: 0, },
      { CDATE: '2024-12-06', DISC: 'Painting',  TOTAL: 5507,  P_WK: 0, A_WK: 0, D_WK: 0, P_CUM: 0, A_CUM: 0, D_CUM: 0, },
      { CDATE: '2024-12-13', DISC: 'Painting',  TOTAL: 5507,  P_WK: 0, A_WK: 0, D_WK: 0, P_CUM: 0, A_CUM: 0, D_CUM: 0, },
      { CDATE: '2024-12-20', DISC: 'Painting',  TOTAL: 5507,  P_WK: 0, A_WK: 0, D_WK: 0, P_CUM: 0, A_CUM: 0, D_CUM: 0, },
      { CDATE: '2024-12-27', DISC: 'Painting',  TOTAL: 5507,  P_WK: 0, A_WK: 0, D_WK: 0, P_CUM: 0, A_CUM: 0, D_CUM: 0, },
      { CDATE: '2025-01-03', DISC: 'Painting',  TOTAL: 5507,  P_WK: 0, A_WK: 0, D_WK: 0, P_CUM: 0, A_CUM: 0, D_CUM: 0, },
      { CDATE: '2025-01-10', DISC: 'Painting',  TOTAL: 5507,  P_WK: 0, A_WK: 0, D_WK: 0, P_CUM: 0, A_CUM: 0, D_CUM: 0, },
      { CDATE: '2025-01-17', DISC: 'Painting',  TOTAL: 5507,  P_WK: 0, A_WK: 0, D_WK: 0, P_CUM: 0, A_CUM: 0, D_CUM: 0, },
      { CDATE: '2025-01-24', DISC: 'Painting',  TOTAL: 5507,  P_WK: 0, A_WK: 0, D_WK: 0, P_CUM: 0, A_CUM: 0, D_CUM: 0, },
      { CDATE: '2025-01-31', DISC: 'Painting',  TOTAL: 5507,  P_WK: 0, A_WK: 0, D_WK: 0, P_CUM: 0, A_CUM: 0, D_CUM: 0, },
      { CDATE: '2025-02-07', DISC: 'Painting',  TOTAL: 5507,  P_WK: 0, A_WK: 0, D_WK: 0, P_CUM: 0, A_CUM: 0, D_CUM: 0, },
      { CDATE: '2025-02-14', DISC: 'Painting',  TOTAL: 5507,  P_WK: 0, A_WK: 0, D_WK: 0, P_CUM: 0, A_CUM: 0, D_CUM: 0, },
      { CDATE: '2025-02-21', DISC: 'Painting',  TOTAL: 5507,  P_WK: 0, A_WK: 0, D_WK: 0, P_CUM: 0, A_CUM: 0, D_CUM: 0, },
      { CDATE: '2025-02-28', DISC: 'Painting',  TOTAL: 5507,  P_WK: 0, A_WK: 0, D_WK: 0, P_CUM: 0, A_CUM: 0, D_CUM: 0, },
      { CDATE: '2025-03-07', DISC: 'Painting',  TOTAL: 5507,  P_WK: 0, A_WK: 0, D_WK: 0, P_CUM: 0, A_CUM: 0, D_CUM: 0, },
      { CDATE: '2025-03-14', DISC: 'Painting',  TOTAL: 5507,  P_WK: 14, A_WK: 0, D_WK: 0, P_CUM: 14, A_CUM: 0, D_CUM: 0, },
      { CDATE: '2025-03-21', DISC: 'Painting',  TOTAL: 5507,  P_WK: 32, A_WK: 0, D_WK: 0, P_CUM: 46, A_CUM: 0, D_CUM: 0, },
      { CDATE: '2025-03-28', DISC: 'Painting',  TOTAL: 5507,  P_WK: 53, A_WK: 0, D_WK: 0, P_CUM: 99, A_CUM: 0, D_CUM: 0, },
      { CDATE: '2025-04-04', DISC: 'Painting',  TOTAL: 5507,  P_WK: 69, A_WK: 0, D_WK: 0, P_CUM: 168, A_CUM: 0, D_CUM: 0, },
      { CDATE: '2025-04-11', DISC: 'Painting',  TOTAL: 5507,  P_WK: 58, A_WK: 0, D_WK: 0, P_CUM: 226, A_CUM: 0, D_CUM: 0, },
      { CDATE: '2025-04-18', DISC: 'Painting',  TOTAL: 5507,  P_WK: 64, A_WK: 0, D_WK: 0, P_CUM: 290, A_CUM: 0, D_CUM: 0, },
      { CDATE: '2025-04-25', DISC: 'Painting',  TOTAL: 5507,  P_WK: 60, A_WK: 0, D_WK: 0, P_CUM: 350, A_CUM: 0, D_CUM: 0, },
      { CDATE: '2025-05-02', DISC: 'Painting',  TOTAL: 5507,  P_WK: 44, A_WK: 0, D_WK: 0, P_CUM: 394, A_CUM: 0, D_CUM: 0, },
      { CDATE: '2025-05-09', DISC: 'Painting',  TOTAL: 5507,  P_WK: 63, A_WK: 0, D_WK: 0, P_CUM: 457, A_CUM: 0, D_CUM: 0, },
      { CDATE: '2025-05-16', DISC: 'Painting',  TOTAL: 5507,  P_WK: 92, A_WK: 0, D_WK: 0, P_CUM: 549, A_CUM: 0, D_CUM: 0, },
      { CDATE: '2025-05-23', DISC: 'Painting',  TOTAL: 5507,  P_WK: 90, A_WK: 0, D_WK: 0, P_CUM: 639, A_CUM: 0, D_CUM: 0, },
      { CDATE: '2025-05-30', DISC: 'Painting',  TOTAL: 5507,  P_WK: 85, A_WK: 0, D_WK: 0, P_CUM: 724, A_CUM: 0, D_CUM: 0, },
      { CDATE: '2024-06-07', DISC: 'Load-out',  TOTAL: 236,   P_WK: 0, A_WK: 0, D_WK: 0, P_CUM: 0, A_CUM: 0, D_CUM: 0, },
      { CDATE: '2024-06-14', DISC: 'Load-out',  TOTAL: 236,   P_WK: 0, A_WK: 0, D_WK: 0, P_CUM: 0, A_CUM: 0, D_CUM: 0, },
      { CDATE: '2024-06-21', DISC: 'Load-out',  TOTAL: 236,   P_WK: 0, A_WK: 0, D_WK: 0, P_CUM: 0, A_CUM: 0, D_CUM: 0, },
      { CDATE: '2024-06-28', DISC: 'Load-out',  TOTAL: 236,   P_WK: 0, A_WK: 0, D_WK: 0, P_CUM: 0, A_CUM: 0, D_CUM: 0, },
      { CDATE: '2024-07-05', DISC: 'Load-out',  TOTAL: 236,   P_WK: 0, A_WK: 0, D_WK: 0, P_CUM: 0, A_CUM: 0, D_CUM: 0, },
      { CDATE: '2024-07-12', DISC: 'Load-out',  TOTAL: 236,   P_WK: 0, A_WK: 0, D_WK: 0, P_CUM: 0, A_CUM: 0, D_CUM: 0, },
      { CDATE: '2024-07-19', DISC: 'Load-out',  TOTAL: 236,   P_WK: 0, A_WK: 0, D_WK: 0, P_CUM: 0, A_CUM: 0, D_CUM: 0, },
      { CDATE: '2024-07-26', DISC: 'Load-out',  TOTAL: 236,   P_WK: 0, A_WK: 0, D_WK: 0, P_CUM: 0, A_CUM: 0, D_CUM: 0, },
      { CDATE: '2024-08-02', DISC: 'Load-out',  TOTAL: 236,   P_WK: 0, A_WK: 0, D_WK: 0, P_CUM: 0, A_CUM: 0, D_CUM: 0, },
      { CDATE: '2024-08-09', DISC: 'Load-out',  TOTAL: 236,   P_WK: 0, A_WK: 0, D_WK: 0, P_CUM: 0, A_CUM: 0, D_CUM: 0, },
      { CDATE: '2024-08-16', DISC: 'Load-out',  TOTAL: 236,   P_WK: 0, A_WK: 0, D_WK: 0, P_CUM: 0, A_CUM: 0, D_CUM: 0, },
      { CDATE: '2024-08-23', DISC: 'Load-out',  TOTAL: 236,   P_WK: 0, A_WK: 0, D_WK: 0, P_CUM: 0, A_CUM: 0, D_CUM: 0, },
      { CDATE: '2024-08-30', DISC: 'Load-out',  TOTAL: 236,   P_WK: 0, A_WK: 0, D_WK: 0, P_CUM: 0, A_CUM: 0, D_CUM: 0, },
      { CDATE: '2024-09-06', DISC: 'Load-out',  TOTAL: 236,   P_WK: 0, A_WK: 0, D_WK: 0, P_CUM: 0, A_CUM: 0, D_CUM: 0, },
      { CDATE: '2024-09-13', DISC: 'Load-out',  TOTAL: 236,   P_WK: 0, A_WK: 0, D_WK: 0, P_CUM: 0, A_CUM: 0, D_CUM: 0, },
      { CDATE: '2024-09-20', DISC: 'Load-out',  TOTAL: 236,   P_WK: 0, A_WK: 0, D_WK: 0, P_CUM: 0, A_CUM: 0, D_CUM: 0, },
      { CDATE: '2024-09-27', DISC: 'Load-out',  TOTAL: 236,   P_WK: 0, A_WK: 0, D_WK: 0, P_CUM: 0, A_CUM: 0, D_CUM: 0, },
      { CDATE: '2024-10-04', DISC: 'Load-out',  TOTAL: 236,   P_WK: 0, A_WK: 0, D_WK: 0, P_CUM: 0, A_CUM: 0, D_CUM: 0, },
      { CDATE: '2024-10-11', DISC: 'Load-out',  TOTAL: 236,   P_WK: 0, A_WK: 0, D_WK: 0, P_CUM: 0, A_CUM: 0, D_CUM: 0, },
      { CDATE: '2024-10-18', DISC: 'Load-out',  TOTAL: 236,   P_WK: 0, A_WK: 0, D_WK: 0, P_CUM: 0, A_CUM: 0, D_CUM: 0, },
      { CDATE: '2024-10-25', DISC: 'Load-out',  TOTAL: 236,   P_WK: 0, A_WK: 0, D_WK: 0, P_CUM: 0, A_CUM: 0, D_CUM: 0, },
      { CDATE: '2024-11-01', DISC: 'Load-out',  TOTAL: 236,   P_WK: 0, A_WK: 0, D_WK: 0, P_CUM: 0, A_CUM: 0, D_CUM: 0, },
      { CDATE: '2024-11-08', DISC: 'Load-out',  TOTAL: 236,   P_WK: 0, A_WK: 0, D_WK: 0, P_CUM: 0, A_CUM: 0, D_CUM: 0, },
      { CDATE: '2024-11-15', DISC: 'Load-out',  TOTAL: 236,   P_WK: 0, A_WK: 0, D_WK: 0, P_CUM: 0, A_CUM: 0, D_CUM: 0, },
      { CDATE: '2024-11-22', DISC: 'Load-out',  TOTAL: 236,   P_WK: 0, A_WK: 0, D_WK: 0, P_CUM: 0, A_CUM: 0, D_CUM: 0, },
      { CDATE: '2024-11-29', DISC: 'Load-out',  TOTAL: 236,   P_WK: 0, A_WK: 0, D_WK: 0, P_CUM: 0, A_CUM: 0, D_CUM: 0, },
      { CDATE: '2024-12-06', DISC: 'Load-out',  TOTAL: 236,   P_WK: 0, A_WK: 0, D_WK: 0, P_CUM: 0, A_CUM: 0, D_CUM: 0, },
      { CDATE: '2024-12-13', DISC: 'Load-out',  TOTAL: 236,   P_WK: 0, A_WK: 0, D_WK: 0, P_CUM: 0, A_CUM: 0, D_CUM: 0, },
      { CDATE: '2024-12-20', DISC: 'Load-out',  TOTAL: 236,   P_WK: 0, A_WK: 0, D_WK: 0, P_CUM: 0, A_CUM: 0, D_CUM: 0, },
      { CDATE: '2024-12-27', DISC: 'Load-out',  TOTAL: 236,   P_WK: 0, A_WK: 0, D_WK: 0, P_CUM: 0, A_CUM: 0, D_CUM: 0, },
      { CDATE: '2025-01-03', DISC: 'Load-out',  TOTAL: 236,   P_WK: 0, A_WK: 0, D_WK: 0, P_CUM: 0, A_CUM: 0, D_CUM: 0, },
      { CDATE: '2025-01-10', DISC: 'Load-out',  TOTAL: 236,   P_WK: 0, A_WK: 0, D_WK: 0, P_CUM: 0, A_CUM: 0, D_CUM: 0, },
      { CDATE: '2025-01-17', DISC: 'Load-out',  TOTAL: 236,   P_WK: 0, A_WK: 0, D_WK: 0, P_CUM: 0, A_CUM: 0, D_CUM: 0, },
      { CDATE: '2025-01-24', DISC: 'Load-out',  TOTAL: 236,   P_WK: 0, A_WK: 0, D_WK: 0, P_CUM: 0, A_CUM: 0, D_CUM: 0, },
      { CDATE: '2025-01-31', DISC: 'Load-out',  TOTAL: 236,   P_WK: 0, A_WK: 0, D_WK: 0, P_CUM: 0, A_CUM: 0, D_CUM: 0, },
      { CDATE: '2025-02-07', DISC: 'Load-out',  TOTAL: 236,   P_WK: 0, A_WK: 0, D_WK: 0, P_CUM: 0, A_CUM: 0, D_CUM: 0, },
      { CDATE: '2025-02-14', DISC: 'Load-out',  TOTAL: 236,   P_WK: 0, A_WK: 0, D_WK: 0, P_CUM: 0, A_CUM: 0, D_CUM: 0, },
      { CDATE: '2025-02-21', DISC: 'Load-out',  TOTAL: 236,   P_WK: 0, A_WK: 0, D_WK: 0, P_CUM: 0, A_CUM: 0, D_CUM: 0, },
      { CDATE: '2025-02-28', DISC: 'Load-out',  TOTAL: 236,   P_WK: 0, A_WK: 0, D_WK: 0, P_CUM: 0, A_CUM: 0, D_CUM: 0, },
      { CDATE: '2025-03-07', DISC: 'Load-out',  TOTAL: 236,   P_WK: 0, A_WK: 0, D_WK: 0, P_CUM: 0, A_CUM: 0, D_CUM: 0, },
      { CDATE: '2025-03-14', DISC: 'Load-out',  TOTAL: 236,   P_WK: 0, A_WK: 0, D_WK: 0, P_CUM: 0, A_CUM: 0, D_CUM: 0, },
      { CDATE: '2025-03-21', DISC: 'Load-out',  TOTAL: 236,   P_WK: 0, A_WK: 0, D_WK: 0, P_CUM: 0, A_CUM: 0, D_CUM: 0, },
      { CDATE: '2025-03-28', DISC: 'Load-out',  TOTAL: 236,   P_WK: 0, A_WK: 0, D_WK: 0, P_CUM: 0, A_CUM: 0, D_CUM: 0, },
      { CDATE: '2025-04-04', DISC: 'Load-out',  TOTAL: 236,   P_WK: 0, A_WK: 0, D_WK: 0, P_CUM: 0, A_CUM: 0, D_CUM: 0, },
      { CDATE: '2025-04-11', DISC: 'Load-out',  TOTAL: 236,   P_WK: 0, A_WK: 0, D_WK: 0, P_CUM: 0, A_CUM: 0, D_CUM: 0, },
      { CDATE: '2025-04-18', DISC: 'Load-out',  TOTAL: 236,   P_WK: 0, A_WK: 0, D_WK: 0, P_CUM: 0, A_CUM: 0, D_CUM: 0, },
      { CDATE: '2025-04-25', DISC: 'Load-out',  TOTAL: 236,   P_WK: 0, A_WK: 0, D_WK: 0, P_CUM: 0, A_CUM: 0, D_CUM: 0, },
      { CDATE: '2025-05-02', DISC: 'Load-out',  TOTAL: 236,   P_WK: 0, A_WK: 0, D_WK: 0, P_CUM: 0, A_CUM: 0, D_CUM: 0, },
      { CDATE: '2025-05-09', DISC: 'Load-out',  TOTAL: 236,   P_WK: 0, A_WK: 0, D_WK: 0, P_CUM: 0, A_CUM: 0, D_CUM: 0, },
      { CDATE: '2025-05-16', DISC: 'Load-out',  TOTAL: 236,   P_WK: 0, A_WK: 0, D_WK: 0, P_CUM: 0, A_CUM: 0, D_CUM: 0, },
      { CDATE: '2025-05-23', DISC: 'Load-out',  TOTAL: 236,   P_WK: 0, A_WK: 0, D_WK: 0, P_CUM: 0, A_CUM: 0, D_CUM: 0, },
      { CDATE: '2025-05-30', DISC: 'Load-out',  TOTAL: 236,   P_WK: 0, A_WK: 0, D_WK: 0, P_CUM: 0, A_CUM: 0, D_CUM: 0, },
      { CDATE: '2024-06-07', DISC: 'COSCO',     TOTAL: 23768, P_WK: 0, A_WK: 0, D_WK: 0, P_CUM: 0, A_CUM: 0, D_CUM: 0, },
      { CDATE: '2024-06-14', DISC: 'COSCO',     TOTAL: 23768, P_WK: 0, A_WK: 0, D_WK: 0, P_CUM: 0, A_CUM: 0, D_CUM: 0, },
      { CDATE: '2024-06-21', DISC: 'COSCO',     TOTAL: 23768, P_WK: 0, A_WK: 0, D_WK: 0, P_CUM: 0, A_CUM: 0, D_CUM: 0, },
      { CDATE: '2024-06-28', DISC: 'COSCO',     TOTAL: 23768, P_WK: 0, A_WK: 0, D_WK: 0, P_CUM: 0, A_CUM: 0, D_CUM: 0, },
      { CDATE: '2024-07-05', DISC: 'COSCO',     TOTAL: 23768, P_WK: 0, A_WK: 0, D_WK: 0, P_CUM: 0, A_CUM: 0, D_CUM: 0, },
      { CDATE: '2024-07-12', DISC: 'COSCO',     TOTAL: 23768, P_WK: 0, A_WK: 0, D_WK: 0, P_CUM: 0, A_CUM: 0, D_CUM: 0, },
      { CDATE: '2024-07-19', DISC: 'COSCO',     TOTAL: 23768, P_WK: 0, A_WK: 0, D_WK: 0, P_CUM: 0, A_CUM: 0, D_CUM: 0, },
      { CDATE: '2024-07-26', DISC: 'COSCO',     TOTAL: 23768, P_WK: 0, A_WK: 0, D_WK: 0, P_CUM: 0, A_CUM: 0, D_CUM: 0, },
      { CDATE: '2024-08-02', DISC: 'COSCO',     TOTAL: 23768, P_WK: 0, A_WK: 0, D_WK: 0, P_CUM: 0, A_CUM: 0, D_CUM: 0, },
      { CDATE: '2024-08-09', DISC: 'COSCO',     TOTAL: 23768, P_WK: 0, A_WK: 0, D_WK: 0, P_CUM: 0, A_CUM: 0, D_CUM: 0, },
      { CDATE: '2024-08-16', DISC: 'COSCO',     TOTAL: 23768, P_WK: 0, A_WK: 0, D_WK: 0, P_CUM: 0, A_CUM: 0, D_CUM: 0, },
      { CDATE: '2024-08-23', DISC: 'COSCO',     TOTAL: 23768, P_WK: 0, A_WK: 0, D_WK: 0, P_CUM: 0, A_CUM: 0, D_CUM: 0, },
      { CDATE: '2024-08-30', DISC: 'COSCO',     TOTAL: 23768, P_WK: 0, A_WK: 0, D_WK: 0, P_CUM: 0, A_CUM: 0, D_CUM: 0, },
      { CDATE: '2024-09-06', DISC: 'COSCO',     TOTAL: 23768, P_WK: 0, A_WK: 0, D_WK: 0, P_CUM: 0, A_CUM: 0, D_CUM: 0, },
      { CDATE: '2024-09-13', DISC: 'COSCO',     TOTAL: 23768, P_WK: 0, A_WK: 0, D_WK: 0, P_CUM: 0, A_CUM: 0, D_CUM: 0, },
      { CDATE: '2024-09-20', DISC: 'COSCO',     TOTAL: 23768, P_WK: 0, A_WK: 0, D_WK: 0, P_CUM: 0, A_CUM: 0, D_CUM: 0, },
      { CDATE: '2024-09-27', DISC: 'COSCO',     TOTAL: 23768, P_WK: 0, A_WK: 0, D_WK: 0, P_CUM: 0, A_CUM: 0, D_CUM: 0, },
      { CDATE: '2024-10-04', DISC: 'COSCO',     TOTAL: 23768, P_WK: 0, A_WK: 0, D_WK: 0, P_CUM: 0, A_CUM: 0, D_CUM: 0, },
      { CDATE: '2024-10-11', DISC: 'COSCO',     TOTAL: 23768, P_WK: 0, A_WK: 0, D_WK: 0, P_CUM: 0, A_CUM: 0, D_CUM: 0, },
      { CDATE: '2024-10-18', DISC: 'COSCO',     TOTAL: 23768, P_WK: 0, A_WK: 0, D_WK: 0, P_CUM: 0, A_CUM: 0, D_CUM: 0, },
      { CDATE: '2024-10-25', DISC: 'COSCO',     TOTAL: 23768, P_WK: 0, A_WK: 0, D_WK: 0, P_CUM: 0, A_CUM: 0, D_CUM: 0, },
      { CDATE: '2024-11-01', DISC: 'COSCO',     TOTAL: 23768, P_WK: 0, A_WK: 0, D_WK: 0, P_CUM: 0, A_CUM: 0, D_CUM: 0, },
      { CDATE: '2024-11-08', DISC: 'COSCO',     TOTAL: 23768, P_WK: 3, A_WK: 0, D_WK: -3, P_CUM: 3, A_CUM: 0, D_CUM: -3, },
      { CDATE: '2024-11-15', DISC: 'COSCO',     TOTAL: 23768, P_WK: 12, A_WK: 9, D_WK: -3, P_CUM: 15, A_CUM: 9, D_CUM: -6, },
      { CDATE: '2024-11-22', DISC: 'COSCO',     TOTAL: 23768, P_WK: 33, A_WK: 65, D_WK: 32, P_CUM: 48, A_CUM: 74, D_CUM: 26, },
      { CDATE: '2024-11-29', DISC: 'COSCO',     TOTAL: 23768, P_WK: 58, A_WK: 134, D_WK: 76, P_CUM: 106, A_CUM: 208, D_CUM: 102, },
      { CDATE: '2024-12-06', DISC: 'COSCO',     TOTAL: 23768, P_WK: 111, A_WK: 269, D_WK: 158, P_CUM: 217, A_CUM: 477, D_CUM: 260, },
      { CDATE: '2024-12-13', DISC: 'COSCO',     TOTAL: 23768, P_WK: 190, A_WK: 394, D_WK: 204, P_CUM: 407, A_CUM: 871, D_CUM: 464, },
      { CDATE: '2024-12-20', DISC: 'COSCO',     TOTAL: 23768, P_WK: 298, A_WK: 395, D_WK: 97, P_CUM: 705, A_CUM: 1266, D_CUM: 561, },
      { CDATE: '2024-12-27', DISC: 'COSCO',     TOTAL: 23768, P_WK: 353, A_WK: 400, D_WK: 47, P_CUM: 1058, A_CUM: 1666, D_CUM: 608, },
      { CDATE: '2025-01-03', DISC: 'COSCO',     TOTAL: 23768, P_WK: 388, A_WK: 0, D_WK: 0, P_CUM: 1446, A_CUM: 1666, D_CUM: 0, },
      { CDATE: '2025-01-10', DISC: 'COSCO',     TOTAL: 23768, P_WK: 579, A_WK: 0, D_WK: 0, P_CUM: 2025, A_CUM: 1666, D_CUM: 0, },
      { CDATE: '2025-01-17', DISC: 'COSCO',     TOTAL: 23768, P_WK: 691, A_WK: 0, D_WK: 0, P_CUM: 2716, A_CUM: 1666, D_CUM: 0, },
      { CDATE: '2025-01-24', DISC: 'COSCO',     TOTAL: 23768, P_WK: 768, A_WK: 0, D_WK: 0, P_CUM: 3484, A_CUM: 1666, D_CUM: 0, },
      { CDATE: '2025-01-31', DISC: 'COSCO',     TOTAL: 23768, P_WK: 136, A_WK: 0, D_WK: 0, P_CUM: 3620, A_CUM: 1666, D_CUM: 0, },
      { CDATE: '2025-02-07', DISC: 'COSCO',     TOTAL: 23768, P_WK: 396, A_WK: 0, D_WK: 0, P_CUM: 4016, A_CUM: 1666, D_CUM: 0, },
      { CDATE: '2025-02-14', DISC: 'COSCO',     TOTAL: 23768, P_WK: 779, A_WK: 0, D_WK: 0, P_CUM: 4795, A_CUM: 1666, D_CUM: 0, },
      { CDATE: '2025-02-21', DISC: 'COSCO',     TOTAL: 23768, P_WK: 731, A_WK: 0, D_WK: 0, P_CUM: 5526, A_CUM: 1666, D_CUM: 0, },
      { CDATE: '2025-02-28', DISC: 'COSCO',     TOTAL: 23768, P_WK: 628, A_WK: 0, D_WK: 0, P_CUM: 6154, A_CUM: 1666, D_CUM: 0, },
      { CDATE: '2025-03-07', DISC: 'COSCO',     TOTAL: 23768, P_WK: 526, A_WK: 0, D_WK: 0, P_CUM: 6680, A_CUM: 1666, D_CUM: 0, },
      { CDATE: '2025-03-14', DISC: 'COSCO',     TOTAL: 23768, P_WK: 459, A_WK: 0, D_WK: 0, P_CUM: 7139, A_CUM: 1666, D_CUM: 0, },
      { CDATE: '2025-03-21', DISC: 'COSCO',     TOTAL: 23768, P_WK: 559, A_WK: 0, D_WK: 0, P_CUM: 7698, A_CUM: 1666, D_CUM: 0, },
      { CDATE: '2025-03-28', DISC: 'COSCO',     TOTAL: 23768, P_WK: 563, A_WK: 0, D_WK: 0, P_CUM: 8261, A_CUM: 1666, D_CUM: 0, },
      { CDATE: '2025-04-04', DISC: 'COSCO',     TOTAL: 23768, P_WK: 494, A_WK: 0, D_WK: 0, P_CUM: 8755, A_CUM: 1666, D_CUM: 0, },
      { CDATE: '2025-04-11', DISC: 'COSCO',     TOTAL: 23768, P_WK: 928, A_WK: 0, D_WK: 0, P_CUM: 9683, A_CUM: 1666, D_CUM: 0, },
      { CDATE: '2025-04-18', DISC: 'COSCO',     TOTAL: 23768, P_WK: 976, A_WK: 0, D_WK: 0, P_CUM: 10659, A_CUM: 1666, D_CUM: 0, },
      { CDATE: '2025-04-25', DISC: 'COSCO',     TOTAL: 23768, P_WK: 715, A_WK: 0, D_WK: 0, P_CUM: 11374, A_CUM: 1666, D_CUM: 0, },
      { CDATE: '2025-05-02', DISC: 'COSCO',     TOTAL: 23768, P_WK: 952, A_WK: 0, D_WK: 0, P_CUM: 12326, A_CUM: 1666, D_CUM: 0, },
      { CDATE: '2025-05-09', DISC: 'COSCO',     TOTAL: 23768, P_WK: 1003, A_WK: 0, D_WK: 0, P_CUM: 13329, A_CUM: 1666, D_CUM: 0, },
      { CDATE: '2025-05-16', DISC: 'COSCO',     TOTAL: 23768, P_WK: 839, A_WK: 0, D_WK: 0, P_CUM: 14168, A_CUM: 1666, D_CUM: 0, },
      { CDATE: '2025-05-23', DISC: 'COSCO',     TOTAL: 23768, P_WK: 759, A_WK: 0, D_WK: 0, P_CUM: 14927, A_CUM: 1666, D_CUM: 0, },
      { CDATE: '2025-05-30', DISC: 'COSCO',     TOTAL: 23768, P_WK: 720, A_WK: 0, D_WK: 0, P_CUM: 15647, A_CUM: 1666, D_CUM: 0, },
      { CDATE: '2024-06-07', DISC: 'Sung-II',   TOTAL: 0,     P_WK: 0, A_WK: 0, D_WK: 0, P_CUM: 0, A_CUM: 0, D_CUM: 0, },
      { CDATE: '2024-06-14', DISC: 'Sung-II',   TOTAL: 0,     P_WK: 0, A_WK: 0, D_WK: 0, P_CUM: 0, A_CUM: 0, D_CUM: 0, },
      { CDATE: '2024-06-21', DISC: 'Sung-II',   TOTAL: 0,     P_WK: 0, A_WK: 0, D_WK: 0, P_CUM: 0, A_CUM: 0, D_CUM: 0, },
      { CDATE: '2024-06-28', DISC: 'Sung-II',   TOTAL: 0,     P_WK: 0, A_WK: 0, D_WK: 0, P_CUM: 0, A_CUM: 0, D_CUM: 0, },
      { CDATE: '2024-07-05', DISC: 'Sung-II',   TOTAL: 0,     P_WK: 0, A_WK: 0, D_WK: 0, P_CUM: 0, A_CUM: 0, D_CUM: 0, },
      { CDATE: '2024-07-12', DISC: 'Sung-II',   TOTAL: 0,     P_WK: 0, A_WK: 0, D_WK: 0, P_CUM: 0, A_CUM: 0, D_CUM: 0, },
      { CDATE: '2024-07-19', DISC: 'Sung-II',   TOTAL: 0,     P_WK: 0, A_WK: 0, D_WK: 0, P_CUM: 0, A_CUM: 0, D_CUM: 0, },
      { CDATE: '2024-07-26', DISC: 'Sung-II',   TOTAL: 0,     P_WK: 0, A_WK: 0, D_WK: 0, P_CUM: 0, A_CUM: 0, D_CUM: 0, },
      { CDATE: '2024-08-02', DISC: 'Sung-II',   TOTAL: 0,     P_WK: 0, A_WK: 0, D_WK: 0, P_CUM: 0, A_CUM: 0, D_CUM: 0, },
      { CDATE: '2024-08-09', DISC: 'Sung-II',   TOTAL: 0,     P_WK: 0, A_WK: 0, D_WK: 0, P_CUM: 0, A_CUM: 0, D_CUM: 0, },
      { CDATE: '2024-08-16', DISC: 'Sung-II',   TOTAL: 0,     P_WK: 0, A_WK: 0, D_WK: 0, P_CUM: 0, A_CUM: 0, D_CUM: 0, },
      { CDATE: '2024-08-23', DISC: 'Sung-II',   TOTAL: 0,     P_WK: 0, A_WK: 0, D_WK: 0, P_CUM: 0, A_CUM: 0, D_CUM: 0, },
      { CDATE: '2024-08-30', DISC: 'Sung-II',   TOTAL: 0,     P_WK: 0, A_WK: 0, D_WK: 0, P_CUM: 0, A_CUM: 0, D_CUM: 0, },
      { CDATE: '2024-09-06', DISC: 'Sung-II',   TOTAL: 0,     P_WK: 0, A_WK: 0, D_WK: 0, P_CUM: 0, A_CUM: 0, D_CUM: 0, },
      { CDATE: '2024-09-13', DISC: 'Sung-II',   TOTAL: 0,     P_WK: 0, A_WK: 0, D_WK: 0, P_CUM: 0, A_CUM: 0, D_CUM: 0, },
      { CDATE: '2024-09-20', DISC: 'Sung-II',   TOTAL: 0,     P_WK: 0, A_WK: 0, D_WK: 0, P_CUM: 0, A_CUM: 0, D_CUM: 0, },
      { CDATE: '2024-09-27', DISC: 'Sung-II',   TOTAL: 0,     P_WK: 0, A_WK: 0, D_WK: 0, P_CUM: 0, A_CUM: 0, D_CUM: 0, },
      { CDATE: '2024-10-04', DISC: 'Sung-II',   TOTAL: 0,     P_WK: 0, A_WK: 0, D_WK: 0, P_CUM: 0, A_CUM: 0, D_CUM: 0, },
      { CDATE: '2024-10-11', DISC: 'Sung-II',   TOTAL: 0,     P_WK: 0, A_WK: 0, D_WK: 0, P_CUM: 0, A_CUM: 0, D_CUM: 0, },
      { CDATE: '2024-10-18', DISC: 'Sung-II',   TOTAL: 0,     P_WK: 0, A_WK: 0, D_WK: 0, P_CUM: 0, A_CUM: 0, D_CUM: 0, },
      { CDATE: '2024-10-25', DISC: 'Sung-II',   TOTAL: 0,     P_WK: 0, A_WK: 0, D_WK: 0, P_CUM: 0, A_CUM: 0, D_CUM: 0, },
      { CDATE: '2024-11-01', DISC: 'Sung-II',   TOTAL: 0,     P_WK: 0, A_WK: 10, D_WK: 10, P_CUM: 0, A_CUM: 10, D_CUM: 10, },
      { CDATE: '2024-11-08', DISC: 'Sung-II',   TOTAL: 0,     P_WK: 0, A_WK: 10, D_WK: 10, P_CUM: 0, A_CUM: 20, D_CUM: 20, },
      { CDATE: '2024-11-15', DISC: 'Sung-II',   TOTAL: 0,     P_WK: 0, A_WK: 12, D_WK: 12, P_CUM: 0, A_CUM: 32, D_CUM: 32, },
      { CDATE: '2024-11-22', DISC: 'Sung-II',   TOTAL: 0,     P_WK: 0, A_WK: 15, D_WK: 15, P_CUM: 0, A_CUM: 47, D_CUM: 47, },
      { CDATE: '2024-11-29', DISC: 'Sung-II',   TOTAL: 0,     P_WK: 0, A_WK: 20, D_WK: 20, P_CUM: 0, A_CUM: 67, D_CUM: 67, },
      { CDATE: '2024-12-06', DISC: 'Sung-II',   TOTAL: 0,     P_WK: 0, A_WK: 20, D_WK: 20, P_CUM: 0, A_CUM: 87, D_CUM: 87, },
      { CDATE: '2024-12-13', DISC: 'Sung-II',   TOTAL: 0,     P_WK: 0, A_WK: 20, D_WK: 20, P_CUM: 0, A_CUM: 107, D_CUM: 107, },
      { CDATE: '2024-12-20', DISC: 'Sung-II',   TOTAL: 0,     P_WK: 0, A_WK: 20, D_WK: 20, P_CUM: 0, A_CUM: 127, D_CUM: 127, },
      { CDATE: '2024-12-27', DISC: 'Sung-II',   TOTAL: 0,     P_WK: 0, A_WK: 15, D_WK: 15, P_CUM: 0, A_CUM: 142, D_CUM: 142, },
      { CDATE: '2025-01-03', DISC: 'Sung-II',   TOTAL: 0,     P_WK: 0, A_WK: 0, D_WK: 0, P_CUM: 0, A_CUM: 142, D_CUM: 0, },
      { CDATE: '2025-01-10', DISC: 'Sung-II',   TOTAL: 0,     P_WK: 0, A_WK: 0, D_WK: 0, P_CUM: 0, A_CUM: 142, D_CUM: 0, },
      { CDATE: '2025-01-17', DISC: 'Sung-II',   TOTAL: 0,     P_WK: 0, A_WK: 0, D_WK: 0, P_CUM: 0, A_CUM: 142, D_CUM: 0, },
      { CDATE: '2025-01-24', DISC: 'Sung-II',   TOTAL: 0,     P_WK: 0, A_WK: 0, D_WK: 0, P_CUM: 0, A_CUM: 142, D_CUM: 0, },
      { CDATE: '2025-01-31', DISC: 'Sung-II',   TOTAL: 0,     P_WK: 0, A_WK: 0, D_WK: 0, P_CUM: 0, A_CUM: 142, D_CUM: 0, },
      { CDATE: '2025-02-07', DISC: 'Sung-II',   TOTAL: 0,     P_WK: 0, A_WK: 0, D_WK: 0, P_CUM: 0, A_CUM: 142, D_CUM: 0, },
      { CDATE: '2025-02-14', DISC: 'Sung-II',   TOTAL: 0,     P_WK: 0, A_WK: 0, D_WK: 0, P_CUM: 0, A_CUM: 142, D_CUM: 0, },
      { CDATE: '2025-02-21', DISC: 'Sung-II',   TOTAL: 0,     P_WK: 0, A_WK: 0, D_WK: 0, P_CUM: 0, A_CUM: 142, D_CUM: 0, },
      { CDATE: '2025-02-28', DISC: 'Sung-II',   TOTAL: 0,     P_WK: 0, A_WK: 0, D_WK: 0, P_CUM: 0, A_CUM: 142, D_CUM: 0, },
      { CDATE: '2025-03-07', DISC: 'Sung-II',   TOTAL: 0,     P_WK: 0, A_WK: 0, D_WK: 0, P_CUM: 0, A_CUM: 142, D_CUM: 0, },
      { CDATE: '2025-03-14', DISC: 'Sung-II',   TOTAL: 0,     P_WK: 0, A_WK: 0, D_WK: 0, P_CUM: 0, A_CUM: 142, D_CUM: 0, },
      { CDATE: '2025-03-21', DISC: 'Sung-II',   TOTAL: 0,     P_WK: 0, A_WK: 0, D_WK: 0, P_CUM: 0, A_CUM: 142, D_CUM: 0, },
      { CDATE: '2025-03-28', DISC: 'Sung-II',   TOTAL: 0,     P_WK: 0, A_WK: 0, D_WK: 0, P_CUM: 0, A_CUM: 142, D_CUM: 0, },
      { CDATE: '2025-04-04', DISC: 'Sung-II',   TOTAL: 0,     P_WK: 0, A_WK: 0, D_WK: 0, P_CUM: 0, A_CUM: 142, D_CUM: 0, },
      { CDATE: '2025-04-11', DISC: 'Sung-II',   TOTAL: 0,     P_WK: 0, A_WK: 0, D_WK: 0, P_CUM: 0, A_CUM: 142, D_CUM: 0, },
      { CDATE: '2025-04-18', DISC: 'Sung-II',   TOTAL: 0,     P_WK: 0, A_WK: 0, D_WK: 0, P_CUM: 0, A_CUM: 142, D_CUM: 0, },
      { CDATE: '2025-04-25', DISC: 'Sung-II',   TOTAL: 0,     P_WK: 0, A_WK: 0, D_WK: 0, P_CUM: 0, A_CUM: 142, D_CUM: 0, },
      { CDATE: '2025-05-02', DISC: 'Sung-II',   TOTAL: 0,     P_WK: 0, A_WK: 0, D_WK: 0, P_CUM: 0, A_CUM: 142, D_CUM: 0, },
      { CDATE: '2025-05-09', DISC: 'Sung-II',   TOTAL: 0,     P_WK: 0, A_WK: 0, D_WK: 0, P_CUM: 0, A_CUM: 142, D_CUM: 0, },
      { CDATE: '2025-05-16', DISC: 'Sung-II',   TOTAL: 0,     P_WK: 0, A_WK: 0, D_WK: 0, P_CUM: 0, A_CUM: 142, D_CUM: 0, },
      { CDATE: '2025-05-23', DISC: 'Sung-II',   TOTAL: 0,     P_WK: 0, A_WK: 0, D_WK: 0, P_CUM: 0, A_CUM: 142, D_CUM: 0, },
      { CDATE: '2025-05-30', DISC: 'Sung-II',   TOTAL: 0,     P_WK: 0, A_WK: 0, D_WK: 0, P_CUM: 0, A_CUM: 142, D_CUM: 0, },
      { CDATE: '2024-06-07', DISC: 'ShinHwaTech.', TOTAL: 0,     P_WK: 0, A_WK: 0, D_WK: 0, P_CUM: 0, A_CUM: 0, D_CUM: 0, },
      { CDATE: '2024-06-14', DISC: 'ShinHwaTech.', TOTAL: 0,     P_WK: 0, A_WK: 0, D_WK: 0, P_CUM: 0, A_CUM: 0, D_CUM: 0, },
      { CDATE: '2024-06-21', DISC: 'ShinHwaTech.', TOTAL: 0,     P_WK: 0, A_WK: 0, D_WK: 0, P_CUM: 0, A_CUM: 0, D_CUM: 0, },
      { CDATE: '2024-06-28', DISC: 'ShinHwaTech.', TOTAL: 0,     P_WK: 0, A_WK: 0, D_WK: 0, P_CUM: 0, A_CUM: 0, D_CUM: 0, },
      { CDATE: '2024-07-05', DISC: 'ShinHwaTech.', TOTAL: 0,     P_WK: 0, A_WK: 0, D_WK: 0, P_CUM: 0, A_CUM: 0, D_CUM: 0, },
      { CDATE: '2024-07-12', DISC: 'ShinHwaTech.', TOTAL: 0,     P_WK: 0, A_WK: 0, D_WK: 0, P_CUM: 0, A_CUM: 0, D_CUM: 0, },
      { CDATE: '2024-07-19', DISC: 'ShinHwaTech.', TOTAL: 0,     P_WK: 0, A_WK: 0, D_WK: 0, P_CUM: 0, A_CUM: 0, D_CUM: 0, },
      { CDATE: '2024-07-26', DISC: 'ShinHwaTech.', TOTAL: 0,     P_WK: 0, A_WK: 0, D_WK: 0, P_CUM: 0, A_CUM: 0, D_CUM: 0, },
      { CDATE: '2024-08-02', DISC: 'ShinHwaTech.', TOTAL: 0,     P_WK: 0, A_WK: 0, D_WK: 0, P_CUM: 0, A_CUM: 0, D_CUM: 0, },
      { CDATE: '2024-08-09', DISC: 'ShinHwaTech.', TOTAL: 0,     P_WK: 0, A_WK: 0, D_WK: 0, P_CUM: 0, A_CUM: 0, D_CUM: 0, },
      { CDATE: '2024-08-16', DISC: 'ShinHwaTech.', TOTAL: 0,     P_WK: 0, A_WK: 0, D_WK: 0, P_CUM: 0, A_CUM: 0, D_CUM: 0, },
      { CDATE: '2024-08-23', DISC: 'ShinHwaTech.', TOTAL: 0,     P_WK: 0, A_WK: 0, D_WK: 0, P_CUM: 0, A_CUM: 0, D_CUM: 0, },
      { CDATE: '2024-08-30', DISC: 'ShinHwaTech.', TOTAL: 0,     P_WK: 0, A_WK: 0, D_WK: 0, P_CUM: 0, A_CUM: 0, D_CUM: 0, },
      { CDATE: '2024-09-06', DISC: 'ShinHwaTech.', TOTAL: 0,     P_WK: 0, A_WK: 0, D_WK: 0, P_CUM: 0, A_CUM: 0, D_CUM: 0, },
      { CDATE: '2024-09-13', DISC: 'ShinHwaTech.', TOTAL: 0,     P_WK: 0, A_WK: 0, D_WK: 0, P_CUM: 0, A_CUM: 0, D_CUM: 0, },
      { CDATE: '2024-09-20', DISC: 'ShinHwaTech.', TOTAL: 0,     P_WK: 0, A_WK: 0, D_WK: 0, P_CUM: 0, A_CUM: 0, D_CUM: 0, },
      { CDATE: '2024-09-27', DISC: 'ShinHwaTech.', TOTAL: 0,     P_WK: 0, A_WK: 0, D_WK: 0, P_CUM: 0, A_CUM: 0, D_CUM: 0, },
      { CDATE: '2024-10-04', DISC: 'ShinHwaTech.', TOTAL: 0,     P_WK: 0, A_WK: 0, D_WK: 0, P_CUM: 0, A_CUM: 0, D_CUM: 0, },
      { CDATE: '2024-10-11', DISC: 'ShinHwaTech.', TOTAL: 0,     P_WK: 0, A_WK: 0, D_WK: 0, P_CUM: 0, A_CUM: 0, D_CUM: 0, },
      { CDATE: '2024-10-18', DISC: 'ShinHwaTech.', TOTAL: 0,     P_WK: 0, A_WK: 0, D_WK: 0, P_CUM: 0, A_CUM: 0, D_CUM: 0, },
      { CDATE: '2024-10-25', DISC: 'ShinHwaTech.', TOTAL: 0,     P_WK: 0, A_WK: 0, D_WK: 0, P_CUM: 0, A_CUM: 0, D_CUM: 0, },
      { CDATE: '2024-11-01', DISC: 'ShinHwaTech.', TOTAL: 0,     P_WK: 0, A_WK: 20, D_WK: 20, P_CUM: 0, A_CUM: 20, D_CUM: 20, },
      { CDATE: '2024-11-08', DISC: 'ShinHwaTech.', TOTAL: 0,     P_WK: 0, A_WK: 30, D_WK: 30, P_CUM: 0, A_CUM: 50, D_CUM: 50, },
      { CDATE: '2024-11-15', DISC: 'ShinHwaTech.', TOTAL: 0,     P_WK: 0, A_WK: 30, D_WK: 30, P_CUM: 0, A_CUM: 80, D_CUM: 80, },
      { CDATE: '2024-11-22', DISC: 'ShinHwaTech.', TOTAL: 0,     P_WK: 0, A_WK: 30, D_WK: 30, P_CUM: 0, A_CUM: 110, D_CUM: 110, },
      { CDATE: '2024-11-29', DISC: 'ShinHwaTech.', TOTAL: 0,     P_WK: 0, A_WK: 30, D_WK: 30, P_CUM: 0, A_CUM: 140, D_CUM: 140, },
      { CDATE: '2024-12-06', DISC: 'ShinHwaTech.', TOTAL: 0,     P_WK: 0, A_WK: 35, D_WK: 35, P_CUM: 0, A_CUM: 175, D_CUM: 175, },
      { CDATE: '2024-12-13', DISC: 'ShinHwaTech.', TOTAL: 0,     P_WK: 0, A_WK: 27, D_WK: 27, P_CUM: 0, A_CUM: 202, D_CUM: 202, },
      { CDATE: '2024-12-20', DISC: 'ShinHwaTech.', TOTAL: 0,     P_WK: 0, A_WK: 26, D_WK: 26, P_CUM: 0, A_CUM: 228, D_CUM: 228, },
      { CDATE: '2024-12-27', DISC: 'ShinHwaTech.', TOTAL: 0,     P_WK: 0, A_WK: 21, D_WK: 21, P_CUM: 0, A_CUM: 249, D_CUM: 249, },
      { CDATE: '2025-01-03', DISC: 'ShinHwaTech.', TOTAL: 0,     P_WK: 0, A_WK: 0, D_WK: 0, P_CUM: 0, A_CUM: 249, D_CUM: 0, },
      { CDATE: '2025-01-10', DISC: 'ShinHwaTech.', TOTAL: 0,     P_WK: 0, A_WK: 0, D_WK: 0, P_CUM: 0, A_CUM: 249, D_CUM: 0, },
      { CDATE: '2025-01-17', DISC: 'ShinHwaTech.', TOTAL: 0,     P_WK: 0, A_WK: 0, D_WK: 0, P_CUM: 0, A_CUM: 249, D_CUM: 0, },
      { CDATE: '2025-01-24', DISC: 'ShinHwaTech.', TOTAL: 0,     P_WK: 0, A_WK: 0, D_WK: 0, P_CUM: 0, A_CUM: 249, D_CUM: 0, },
      { CDATE: '2025-01-31', DISC: 'ShinHwaTech.', TOTAL: 0,     P_WK: 0, A_WK: 0, D_WK: 0, P_CUM: 0, A_CUM: 249, D_CUM: 0, },
      { CDATE: '2025-02-07', DISC: 'ShinHwaTech.', TOTAL: 0,     P_WK: 0, A_WK: 0, D_WK: 0, P_CUM: 0, A_CUM: 249, D_CUM: 0, },
      { CDATE: '2025-02-14', DISC: 'ShinHwaTech.', TOTAL: 0,     P_WK: 0, A_WK: 0, D_WK: 0, P_CUM: 0, A_CUM: 249, D_CUM: 0, },
      { CDATE: '2025-02-21', DISC: 'ShinHwaTech.', TOTAL: 0,     P_WK: 0, A_WK: 0, D_WK: 0, P_CUM: 0, A_CUM: 249, D_CUM: 0, },
      { CDATE: '2025-02-28', DISC: 'ShinHwaTech.', TOTAL: 0,     P_WK: 0, A_WK: 0, D_WK: 0, P_CUM: 0, A_CUM: 249, D_CUM: 0, },
      { CDATE: '2025-03-07', DISC: 'ShinHwaTech.', TOTAL: 0,     P_WK: 0, A_WK: 0, D_WK: 0, P_CUM: 0, A_CUM: 249, D_CUM: 0, },
      { CDATE: '2025-03-14', DISC: 'ShinHwaTech.', TOTAL: 0,     P_WK: 0, A_WK: 0, D_WK: 0, P_CUM: 0, A_CUM: 249, D_CUM: 0, },
      { CDATE: '2025-03-21', DISC: 'ShinHwaTech.', TOTAL: 0,     P_WK: 0, A_WK: 0, D_WK: 0, P_CUM: 0, A_CUM: 249, D_CUM: 0, },
      { CDATE: '2025-03-28', DISC: 'ShinHwaTech.', TOTAL: 0,     P_WK: 0, A_WK: 0, D_WK: 0, P_CUM: 0, A_CUM: 249, D_CUM: 0, },
      { CDATE: '2025-04-04', DISC: 'ShinHwaTech.', TOTAL: 0,     P_WK: 0, A_WK: 0, D_WK: 0, P_CUM: 0, A_CUM: 249, D_CUM: 0, },
      { CDATE: '2025-04-11', DISC: 'ShinHwaTech.', TOTAL: 0,     P_WK: 0, A_WK: 0, D_WK: 0, P_CUM: 0, A_CUM: 249, D_CUM: 0, },
      { CDATE: '2025-04-18', DISC: 'ShinHwaTech.', TOTAL: 0,     P_WK: 0, A_WK: 0, D_WK: 0, P_CUM: 0, A_CUM: 249, D_CUM: 0, },
      { CDATE: '2025-04-25', DISC: 'ShinHwaTech.', TOTAL: 0,     P_WK: 0, A_WK: 0, D_WK: 0, P_CUM: 0, A_CUM: 249, D_CUM: 0, },
      { CDATE: '2025-05-02', DISC: 'ShinHwaTech.', TOTAL: 0,     P_WK: 0, A_WK: 0, D_WK: 0, P_CUM: 0, A_CUM: 249, D_CUM: 0, },
      { CDATE: '2025-05-09', DISC: 'ShinHwaTech.', TOTAL: 0,     P_WK: 0, A_WK: 0, D_WK: 0, P_CUM: 0, A_CUM: 249, D_CUM: 0, },
      { CDATE: '2025-05-16', DISC: 'ShinHwaTech.', TOTAL: 0,     P_WK: 0, A_WK: 0, D_WK: 0, P_CUM: 0, A_CUM: 249, D_CUM: 0, },
      { CDATE: '2025-05-23', DISC: 'ShinHwaTech.', TOTAL: 0,     P_WK: 0, A_WK: 0, D_WK: 0, P_CUM: 0, A_CUM: 249, D_CUM: 0, },
      { CDATE: '2025-05-30', DISC: 'ShinHwaTech.', TOTAL: 0,     P_WK: 0, A_WK: 0, D_WK: 0, P_CUM: 0, A_CUM: 249, D_CUM: 0, },
      { CDATE: '2024-06-07', DISC: 'Others',    TOTAL: 183,   P_WK: 0, A_WK: 0, D_WK: 0, P_CUM: 0, A_CUM: 0, D_CUM: 0, },
      { CDATE: '2024-06-14', DISC: 'Others',    TOTAL: 183,   P_WK: 0, A_WK: 0, D_WK: 0, P_CUM: 0, A_CUM: 0, D_CUM: 0, },
      { CDATE: '2024-06-21', DISC: 'Others',    TOTAL: 183,   P_WK: 0, A_WK: 0, D_WK: 0, P_CUM: 0, A_CUM: 0, D_CUM: 0, },
      { CDATE: '2024-06-28', DISC: 'Others',    TOTAL: 183,   P_WK: 0, A_WK: 0, D_WK: 0, P_CUM: 0, A_CUM: 0, D_CUM: 0, },
      { CDATE: '2024-07-05', DISC: 'Others',    TOTAL: 183,   P_WK: 0, A_WK: 0, D_WK: 0, P_CUM: 0, A_CUM: 0, D_CUM: 0, },
      { CDATE: '2024-07-12', DISC: 'Others',    TOTAL: 183,   P_WK: 0, A_WK: 0, D_WK: 0, P_CUM: 0, A_CUM: 0, D_CUM: 0, },
      { CDATE: '2024-07-19', DISC: 'Others',    TOTAL: 183,   P_WK: 0, A_WK: 0, D_WK: 0, P_CUM: 0, A_CUM: 0, D_CUM: 0, },
      { CDATE: '2024-07-26', DISC: 'Others',    TOTAL: 183,   P_WK: 0, A_WK: 0, D_WK: 0, P_CUM: 0, A_CUM: 0, D_CUM: 0, },
      { CDATE: '2024-08-02', DISC: 'Others',    TOTAL: 183,   P_WK: 0, A_WK: 0, D_WK: 0, P_CUM: 0, A_CUM: 0, D_CUM: 0, },
      { CDATE: '2024-08-09', DISC: 'Others',    TOTAL: 183,   P_WK: 0, A_WK: 0, D_WK: 0, P_CUM: 0, A_CUM: 0, D_CUM: 0, },
      { CDATE: '2024-08-16', DISC: 'Others',    TOTAL: 183,   P_WK: 0, A_WK: 0, D_WK: 0, P_CUM: 0, A_CUM: 0, D_CUM: 0, },
      { CDATE: '2024-08-23', DISC: 'Others',    TOTAL: 183,   P_WK: 0, A_WK: 0, D_WK: 0, P_CUM: 0, A_CUM: 0, D_CUM: 0, },
      { CDATE: '2024-08-30', DISC: 'Others',    TOTAL: 183,   P_WK: 0, A_WK: 0, D_WK: 0, P_CUM: 0, A_CUM: 0, D_CUM: 0, },
      { CDATE: '2024-09-06', DISC: 'Others',    TOTAL: 183,   P_WK: 0, A_WK: 0, D_WK: 0, P_CUM: 0, A_CUM: 0, D_CUM: 0, },
      { CDATE: '2024-09-13', DISC: 'Others',    TOTAL: 183,   P_WK: 0, A_WK: 0, D_WK: 0, P_CUM: 0, A_CUM: 0, D_CUM: 0, },
      { CDATE: '2024-09-20', DISC: 'Others',    TOTAL: 183,   P_WK: 0, A_WK: 0, D_WK: 0, P_CUM: 0, A_CUM: 0, D_CUM: 0, },
      { CDATE: '2024-09-27', DISC: 'Others',    TOTAL: 183,   P_WK: 0, A_WK: 0, D_WK: 0, P_CUM: 0, A_CUM: 0, D_CUM: 0, },
      { CDATE: '2024-10-04', DISC: 'Others',    TOTAL: 183,   P_WK: 0, A_WK: 0, D_WK: 0, P_CUM: 0, A_CUM: 0, D_CUM: 0, },
      { CDATE: '2024-10-11', DISC: 'Others',    TOTAL: 183,   P_WK: 0, A_WK: 0, D_WK: 0, P_CUM: 0, A_CUM: 0, D_CUM: 0, },
      { CDATE: '2024-10-18', DISC: 'Others',    TOTAL: 183,   P_WK: 0, A_WK: 0, D_WK: 0, P_CUM: 0, A_CUM: 0, D_CUM: 0, },
      { CDATE: '2024-10-25', DISC: 'Others',    TOTAL: 183,   P_WK: 0, A_WK: 0, D_WK: 0, P_CUM: 0, A_CUM: 0, D_CUM: 0, },
      { CDATE: '2024-11-01', DISC: 'Others',    TOTAL: 183,   P_WK: 0, A_WK: 0, D_WK: 0, P_CUM: 0, A_CUM: 0, D_CUM: 0, },
      { CDATE: '2024-11-08', DISC: 'Others',    TOTAL: 183,   P_WK: 0, A_WK: 0, D_WK: 0, P_CUM: 0, A_CUM: 0, D_CUM: 0, },
      { CDATE: '2024-11-15', DISC: 'Others',    TOTAL: 183,   P_WK: 0, A_WK: 0, D_WK: 0, P_CUM: 0, A_CUM: 0, D_CUM: 0, },
      { CDATE: '2024-11-22', DISC: 'Others',    TOTAL: 183,   P_WK: 0, A_WK: 0, D_WK: 0, P_CUM: 0, A_CUM: 0, D_CUM: 0, },
      { CDATE: '2024-11-29', DISC: 'Others',    TOTAL: 183,   P_WK: 0, A_WK: 0, D_WK: 0, P_CUM: 0, A_CUM: 0, D_CUM: 0, },
      { CDATE: '2024-12-06', DISC: 'Others',    TOTAL: 183,   P_WK: 0, A_WK: 0, D_WK: 0, P_CUM: 0, A_CUM: 0, D_CUM: 0, },
      { CDATE: '2024-12-13', DISC: 'Others',    TOTAL: 183,   P_WK: 0, A_WK: 0, D_WK: 0, P_CUM: 0, A_CUM: 0, D_CUM: 0, },
      { CDATE: '2024-12-20', DISC: 'Others',    TOTAL: 183,   P_WK: 0, A_WK: 0, D_WK: 0, P_CUM: 0, A_CUM: 0, D_CUM: 0, },
      { CDATE: '2024-12-27', DISC: 'Others',    TOTAL: 183,   P_WK: 0, A_WK: 0, D_WK: 0, P_CUM: 0, A_CUM: 0, D_CUM: 0, },
      { CDATE: '2025-01-03', DISC: 'Others',    TOTAL: 183,   P_WK: 0, A_WK: 0, D_WK: 0, P_CUM: 0, A_CUM: 0, D_CUM: 0, },
      { CDATE: '2025-01-10', DISC: 'Others',    TOTAL: 183,   P_WK: 0, A_WK: 0, D_WK: 0, P_CUM: 0, A_CUM: 0, D_CUM: 0, },
      { CDATE: '2025-01-17', DISC: 'Others',    TOTAL: 183,   P_WK: 0, A_WK: 0, D_WK: 0, P_CUM: 0, A_CUM: 0, D_CUM: 0, },
      { CDATE: '2025-01-24', DISC: 'Others',    TOTAL: 183,   P_WK: 0, A_WK: 0, D_WK: 0, P_CUM: 0, A_CUM: 0, D_CUM: 0, },
      { CDATE: '2025-01-31', DISC: 'Others',    TOTAL: 183,   P_WK: 0, A_WK: 0, D_WK: 0, P_CUM: 0, A_CUM: 0, D_CUM: 0, },
      { CDATE: '2025-02-07', DISC: 'Others',    TOTAL: 183,   P_WK: 0, A_WK: 0, D_WK: 0, P_CUM: 0, A_CUM: 0, D_CUM: 0, },
      { CDATE: '2025-02-14', DISC: 'Others',    TOTAL: 183,   P_WK: 0, A_WK: 0, D_WK: 0, P_CUM: 0, A_CUM: 0, D_CUM: 0, },
      { CDATE: '2025-02-21', DISC: 'Others',    TOTAL: 183,   P_WK: 0, A_WK: 0, D_WK: 0, P_CUM: 0, A_CUM: 0, D_CUM: 0, },
      { CDATE: '2025-02-28', DISC: 'Others',    TOTAL: 183,   P_WK: 0, A_WK: 0, D_WK: 0, P_CUM: 0, A_CUM: 0, D_CUM: 0, },
      { CDATE: '2025-03-07', DISC: 'Others',    TOTAL: 183,   P_WK: 0, A_WK: 0, D_WK: 0, P_CUM: 0, A_CUM: 0, D_CUM: 0, },
      { CDATE: '2025-03-14', DISC: 'Others',    TOTAL: 183,   P_WK: 0, A_WK: 0, D_WK: 0, P_CUM: 0, A_CUM: 0, D_CUM: 0, },
      { CDATE: '2025-03-21', DISC: 'Others',    TOTAL: 183,   P_WK: 0, A_WK: 0, D_WK: 0, P_CUM: 0, A_CUM: 0, D_CUM: 0, },
      { CDATE: '2025-03-28', DISC: 'Others',    TOTAL: 183,   P_WK: 0, A_WK: 0, D_WK: 0, P_CUM: 0, A_CUM: 0, D_CUM: 0, },
      { CDATE: '2025-04-04', DISC: 'Others',    TOTAL: 183,   P_WK: 0, A_WK: 0, D_WK: 0, P_CUM: 0, A_CUM: 0, D_CUM: 0, },
      { CDATE: '2025-04-11', DISC: 'Others',    TOTAL: 183,   P_WK: 0, A_WK: 0, D_WK: 0, P_CUM: 0, A_CUM: 0, D_CUM: 0, },
      { CDATE: '2025-04-18', DISC: 'Others',    TOTAL: 183,   P_WK: 0, A_WK: 0, D_WK: 0, P_CUM: 0, A_CUM: 0, D_CUM: 0, },
      { CDATE: '2025-04-25', DISC: 'Others',    TOTAL: 183,   P_WK: 0, A_WK: 0, D_WK: 0, P_CUM: 0, A_CUM: 0, D_CUM: 0, },
      { CDATE: '2025-05-02', DISC: 'Others',    TOTAL: 183,   P_WK: 0, A_WK: 0, D_WK: 0, P_CUM: 0, A_CUM: 0, D_CUM: 0, },
      { CDATE: '2025-05-09', DISC: 'Others',    TOTAL: 183,   P_WK: 0, A_WK: 0, D_WK: 0, P_CUM: 0, A_CUM: 0, D_CUM: 0, },
      { CDATE: '2025-05-16', DISC: 'Others',    TOTAL: 183,   P_WK: 0, A_WK: 0, D_WK: 0, P_CUM: 0, A_CUM: 0, D_CUM: 0, },
      { CDATE: '2025-05-23', DISC: 'Others',    TOTAL: 183,   P_WK: 0, A_WK: 0, D_WK: 0, P_CUM: 0, A_CUM: 0, D_CUM: 0, },
      { CDATE: '2025-05-30', DISC: 'Others',    TOTAL: 183,   P_WK: 0, A_WK: 0, D_WK: 0, P_CUM: 0, A_CUM: 0, D_CUM: 0, },
      { CDATE: '2024-06-07', DISC: 'OVERALL',   TOTAL: 92258, P_WK: 0, A_WK: 0, D_WK: 0, P_CUM: 0, A_CUM: 0, D_CUM: 0, },
      { CDATE: '2024-06-14', DISC: 'OVERALL',   TOTAL: 92258, P_WK: 0, A_WK: 0, D_WK: 0, P_CUM: 0, A_CUM: 0, D_CUM: 0, },
      { CDATE: '2024-06-21', DISC: 'OVERALL',   TOTAL: 92258, P_WK: 0, A_WK: 0, D_WK: 0, P_CUM: 0, A_CUM: 0, D_CUM: 0, },
      { CDATE: '2024-06-28', DISC: 'OVERALL',   TOTAL: 92258, P_WK: 0, A_WK: 0, D_WK: 0, P_CUM: 0, A_CUM: 0, D_CUM: 0, },
      { CDATE: '2024-07-05', DISC: 'OVERALL',   TOTAL: 92258, P_WK: 0, A_WK: 0, D_WK: 0, P_CUM: 0, A_CUM: 0, D_CUM: 0, },
      { CDATE: '2024-07-12', DISC: 'OVERALL',   TOTAL: 92258, P_WK: 0, A_WK: 0, D_WK: 0, P_CUM: 0, A_CUM: 0, D_CUM: 0, },
      { CDATE: '2024-07-19', DISC: 'OVERALL',   TOTAL: 92258, P_WK: 0, A_WK: 0, D_WK: 0, P_CUM: 0, A_CUM: 0, D_CUM: 0, },
      { CDATE: '2024-07-26', DISC: 'OVERALL',   TOTAL: 92258, P_WK: 0, A_WK: 0, D_WK: 0, P_CUM: 0, A_CUM: 0, D_CUM: 0, },
      { CDATE: '2024-08-02', DISC: 'OVERALL',   TOTAL: 92258, P_WK: 0, A_WK: 0, D_WK: 0, P_CUM: 0, A_CUM: 0, D_CUM: 0, },
      { CDATE: '2024-08-09', DISC: 'OVERALL',   TOTAL: 92258, P_WK: 0, A_WK: 0, D_WK: 0, P_CUM: 0, A_CUM: 0, D_CUM: 0, },
      { CDATE: '2024-08-16', DISC: 'OVERALL',   TOTAL: 92258, P_WK: 0, A_WK: 0, D_WK: 0, P_CUM: 0, A_CUM: 0, D_CUM: 0, },
      { CDATE: '2024-08-23', DISC: 'OVERALL',   TOTAL: 92258, P_WK: 0, A_WK: 0, D_WK: 0, P_CUM: 0, A_CUM: 0, D_CUM: 0, },
      { CDATE: '2024-08-30', DISC: 'OVERALL',   TOTAL: 92258, P_WK: 0, A_WK: 0, D_WK: 0, P_CUM: 0, A_CUM: 0, D_CUM: 0, },
      { CDATE: '2024-09-06', DISC: 'OVERALL',   TOTAL: 92258, P_WK: 0, A_WK: 0, D_WK: 0, P_CUM: 0, A_CUM: 0, D_CUM: 0, },
      { CDATE: '2024-09-13', DISC: 'OVERALL',   TOTAL: 92258, P_WK: 0, A_WK: 0, D_WK: 0, P_CUM: 0, A_CUM: 0, D_CUM: 0, },
      { CDATE: '2024-09-20', DISC: 'OVERALL',   TOTAL: 92258, P_WK: 0, A_WK: 0, D_WK: 0, P_CUM: 0, A_CUM: 0, D_CUM: 0, },
      { CDATE: '2024-09-27', DISC: 'OVERALL',   TOTAL: 92258, P_WK: 0, A_WK: 0, D_WK: 0, P_CUM: 0, A_CUM: 0, D_CUM: 0, },
      { CDATE: '2024-10-04', DISC: 'OVERALL',   TOTAL: 92258, P_WK: 0, A_WK: 0, D_WK: 0, P_CUM: 0, A_CUM: 0, D_CUM: 0, },
      { CDATE: '2024-10-11', DISC: 'OVERALL',   TOTAL: 92258, P_WK: 0, A_WK: 0, D_WK: 0, P_CUM: 0, A_CUM: 0, D_CUM: 0, },
      { CDATE: '2024-10-18', DISC: 'OVERALL',   TOTAL: 92258, P_WK: 0, A_WK: 0, D_WK: 0, P_CUM: 0, A_CUM: 0, D_CUM: 0, },
      { CDATE: '2024-10-25', DISC: 'OVERALL',   TOTAL: 92258, P_WK: 0, A_WK: 0, D_WK: 0, P_CUM: 0, A_CUM: 0, D_CUM: 0, },
      { CDATE: '2024-11-01', DISC: 'OVERALL',   TOTAL: 92258, P_WK: 2, A_WK: 30, D_WK: 28, P_CUM: 2, A_CUM: 30, D_CUM: 28, },
      { CDATE: '2024-11-08', DISC: 'OVERALL',   TOTAL: 92258, P_WK: 31, A_WK: 40, D_WK: 9, P_CUM: 33, A_CUM: 70, D_CUM: 37, },
      { CDATE: '2024-11-15', DISC: 'OVERALL',   TOTAL: 92258, P_WK: 60, A_WK: 68, D_WK: 8, P_CUM: 93, A_CUM: 138, D_CUM: 45, },
      { CDATE: '2024-11-22', DISC: 'OVERALL',   TOTAL: 92258, P_WK: 92, A_WK: 156, D_WK: 64, P_CUM: 185, A_CUM: 294, D_CUM: 109, },
      { CDATE: '2024-11-29', DISC: 'OVERALL',   TOTAL: 92258, P_WK: 120, A_WK: 255, D_WK: 135, P_CUM: 305, A_CUM: 549, D_CUM: 244, },
      { CDATE: '2024-12-06', DISC: 'OVERALL',   TOTAL: 92258, P_WK: 189, A_WK: 436, D_WK: 247, P_CUM: 494, A_CUM: 985, D_CUM: 491, },
      { CDATE: '2024-12-13', DISC: 'OVERALL',   TOTAL: 92258, P_WK: 282, A_WK: 567, D_WK: 285, P_CUM: 776, A_CUM: 1552, D_CUM: 776, },
      { CDATE: '2024-12-20', DISC: 'OVERALL',   TOTAL: 92258, P_WK: 396, A_WK: 612, D_WK: 216, P_CUM: 1172, A_CUM: 2164, D_CUM: 992, },
      { CDATE: '2024-12-27', DISC: 'OVERALL',   TOTAL: 92258, P_WK: 429, A_WK: 612, D_WK: 183, P_CUM: 1601, A_CUM: 2776, D_CUM: 1175, },
      { CDATE: '2025-01-03', DISC: 'OVERALL',   TOTAL: 92258, P_WK: 464, A_WK: 0, D_WK: 0, P_CUM: 2065, A_CUM: 2776, D_CUM: 0, },
      { CDATE: '2025-01-10', DISC: 'OVERALL',   TOTAL: 92258, P_WK: 669, A_WK: 0, D_WK: 0, P_CUM: 2734, A_CUM: 2776, D_CUM: 0, },
      { CDATE: '2025-01-17', DISC: 'OVERALL',   TOTAL: 92258, P_WK: 801, A_WK: 0, D_WK: 0, P_CUM: 3535, A_CUM: 2776, D_CUM: 0, },
      { CDATE: '2025-01-24', DISC: 'OVERALL',   TOTAL: 92258, P_WK: 918, A_WK: 0, D_WK: 0, P_CUM: 4453, A_CUM: 2776, D_CUM: 0, },
      { CDATE: '2025-01-31', DISC: 'OVERALL',   TOTAL: 92258, P_WK: 204, A_WK: 0, D_WK: 0, P_CUM: 4657, A_CUM: 2776, D_CUM: 0, },
      { CDATE: '2025-02-07', DISC: 'OVERALL',   TOTAL: 92258, P_WK: 583, A_WK: 0, D_WK: 0, P_CUM: 5240, A_CUM: 2776, D_CUM: 0, },
      { CDATE: '2025-02-14', DISC: 'OVERALL',   TOTAL: 92258, P_WK: 997, A_WK: 0, D_WK: 0, P_CUM: 6237, A_CUM: 2776, D_CUM: 0, },
      { CDATE: '2025-02-21', DISC: 'OVERALL',   TOTAL: 92258, P_WK: 982, A_WK: 0, D_WK: 0, P_CUM: 7219, A_CUM: 2776, D_CUM: 0, },
      { CDATE: '2025-02-28', DISC: 'OVERALL',   TOTAL: 92258, P_WK: 974, A_WK: 0, D_WK: 0, P_CUM: 8193, A_CUM: 2776, D_CUM: 0, },
      { CDATE: '2025-03-07', DISC: 'OVERALL',   TOTAL: 92258, P_WK: 962, A_WK: 0, D_WK: 0, P_CUM: 9155, A_CUM: 2776, D_CUM: 0, },
      { CDATE: '2025-03-14', DISC: 'OVERALL',   TOTAL: 92258, P_WK: 892, A_WK: 0, D_WK: 0, P_CUM: 10047, A_CUM: 2776, D_CUM: 0, },
      { CDATE: '2025-03-21', DISC: 'OVERALL',   TOTAL: 92258, P_WK: 1026, A_WK: 0, D_WK: 0, P_CUM: 11073, A_CUM: 2776, D_CUM: 0, },
      { CDATE: '2025-03-28', DISC: 'OVERALL',   TOTAL: 92258, P_WK: 1042, A_WK: 0, D_WK: 0, P_CUM: 12115, A_CUM: 2776, D_CUM: 0, },
      { CDATE: '2025-04-04', DISC: 'OVERALL',   TOTAL: 92258, P_WK: 965, A_WK: 0, D_WK: 0, P_CUM: 13080, A_CUM: 2776, D_CUM: 0, },
      { CDATE: '2025-04-11', DISC: 'OVERALL',   TOTAL: 92258, P_WK: 1459, A_WK: 0, D_WK: 0, P_CUM: 14539, A_CUM: 2776, D_CUM: 0, },
      { CDATE: '2025-04-18', DISC: 'OVERALL',   TOTAL: 92258, P_WK: 1602, A_WK: 0, D_WK: 0, P_CUM: 16141, A_CUM: 2776, D_CUM: 0, },
      { CDATE: '2025-04-25', DISC: 'OVERALL',   TOTAL: 92258, P_WK: 1398, A_WK: 0, D_WK: 0, P_CUM: 17539, A_CUM: 2776, D_CUM: 0, },
      { CDATE: '2025-05-02', DISC: 'OVERALL',   TOTAL: 92258, P_WK: 1503, A_WK: 0, D_WK: 0, P_CUM: 19042, A_CUM: 2776, D_CUM: 0, },
      { CDATE: '2025-05-09', DISC: 'OVERALL',   TOTAL: 92258, P_WK: 1562, A_WK: 0, D_WK: 0, P_CUM: 20604, A_CUM: 2776, D_CUM: 0, },
      { CDATE: '2025-05-16', DISC: 'OVERALL',   TOTAL: 92258, P_WK: 1636, A_WK: 0, D_WK: 0, P_CUM: 22240, A_CUM: 2776, D_CUM: 0, },
      { CDATE: '2025-05-23', DISC: 'OVERALL',   TOTAL: 92258, P_WK: 1650, A_WK: 0, D_WK: 0, P_CUM: 23890, A_CUM: 2776, D_CUM: 0, },
      { CDATE: '2025-05-30', DISC: 'OVERALL',   TOTAL: 92258, P_WK: 1636, A_WK: 0, D_WK: 0, P_CUM: 25526, A_CUM: 2776, D_CUM: 0, },
    ],


    Queries: {
      SQL1: [
        {
          // Timeline
          CUTOFF      : '2024-12-27',
          START_DATE  : '2024-06-07',
          END_DATE    : '2025-05-30',
        },
      ],

    },


}