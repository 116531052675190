export default {
    Canvas: {
      CanvasWidth: 810,
      CanvasHeight: 320,
      CanvasBorderWeight: .5,
      CanvasBorderColor: '#E0E0DF',
      CanvasFillColor: '#fff',
      CanvasOpacity: 1,

      CanvasChartX: 0,
      CanvasChartY: 0,
      CanvasChartWidth: 400,
      CanvasChartHeight: 200,
      CanvasChartBorderWeight: 0,
      CanvasChartBorderColor: '#757575',
      CanvasChartFillColor: '#757575',
      CanvasChartOpacity: 0,
      CanvasChartSpace: 0,

      CanvasFontFamily: 'roboto',
    },
    
    Text: {
    },

    
    DataItems: [
      {
        CUTOFF: '2025-01-24'
      },
    ],


    Queries: {
      
      SQL1: [
        { CDATE: '2024-11-15', TOT: 100,   PO: 43,    FAT: 100,   ATA: 100,   IST: 100, },
        { CDATE: '2024-11-22', TOT: 100,   PO: 43,    FAT: 100,   ATA: 100,   IST: 100, },
        { CDATE: '2024-11-29', TOT: 100,   PO: 43,    FAT: 100,   ATA: 100,   IST: 100, },
        { CDATE: '2024-12-06', TOT: 100,   PO: 43,    FAT: 100,   ATA: 100,   IST: 100, },
        { CDATE: '2024-12-13', TOT: 100,   PO: 43,    FAT: 100,   ATA: 100,   IST: 100, },
        { CDATE: '2024-12-20', TOT: 100,   PO: 43,    FAT: 100,   ATA: 100,   IST: 100, },
        { CDATE: '2024-12-27', TOT: 100,   PO: 43,    FAT: 100,   ATA: 100,   IST: 100, },
        { CDATE: '2025-01-03', TOT: 100,   PO: 43,    FAT: 100,   ATA: 100,   IST: 100, },
        { CDATE: '2025-01-10', TOT: 100,   PO: 43,    FAT: 100,   ATA: 100,   IST: 100, },
        { CDATE: '2025-01-17', TOT: 100,   PO: 43,    FAT: 100,   ATA: 100,   IST: 100, },
        { CDATE: '2025-01-24', TOT: 100,   PO: 43,    FAT: 100,   ATA: 100,   IST: 100, },
        { CDATE: '2025-01-31', TOT: 100,   PO: 43,    FAT: 100,   ATA: 100,   IST: 100, },
        { CDATE: '2025-02-07', TOT: 100,   PO: 43,    FAT: 100,   ATA: 100,   IST: 100, },
        { CDATE: '2025-02-14', TOT: 100,   PO: 43,    FAT: 100,   ATA: 100,   IST: 100, },
        { CDATE: '2025-02-21', TOT: 100,   PO: 43,    FAT: 100,   ATA: 100,   IST: 100, },
        { CDATE: '2025-02-28', TOT: 100,   PO: 43,    FAT: 100,   ATA: 100,   IST: 100, },
        { CDATE: '2025-03-07', TOT: 100,   PO: 43,    FAT: 100,   ATA: 100,   IST: 100, },
        { CDATE: '2025-03-14', TOT: 100,   PO: 43,    FAT: 100,   ATA: 100,   IST: 100, },
        { CDATE: '2025-03-21', TOT: 100,   PO: 43,    FAT: 100,   ATA: 100,   IST: 100, },
        { CDATE: '2025-03-28', TOT: 100,   PO: 43,    FAT: 100,   ATA: 100,   IST: 100, },
        { CDATE: '2025-04-04', TOT: 100,   PO: 43,    FAT: 100,   ATA: 100,   IST: 100, },
        { CDATE: '2025-04-11', TOT: 100,   PO: 43,    FAT: 100,   ATA: 100,   IST: 100, },
        { CDATE: '2025-04-18', TOT: 100,   PO: 43,    FAT: 100,   ATA: 100,   IST: 100, },
        { CDATE: '2025-04-25', TOT: 100,   PO: 43,    FAT: 100,   ATA: 100,   IST: 100, },
        ],
        
        
      SQL2: [
        { ZONE: 'NORTH',   LEVEL: 'Production Deck',     TOT: '50',  PO: '21', FAT: '0', ATA: '0', IST: '0', },
        { ZONE: 'NORTH',   LEVEL: 'Mezz. Deck',          TOT: '12',  PO: '3',  FAT: '0', ATA: '0', IST: '0', },
        { ZONE: 'NORTH',   LEVEL: 'Main Deck',           TOT: '20',  PO: '10', FAT: '0', ATA: '0', IST: '0', },
        { ZONE: 'NORTH',   LEVEL: 'Compressor Platform', TOT: '18',  PO: '6',  FAT: '0', ATA: '0', IST: '0', },
        { ZONE: 'NORTH',   LEVEL: 'TOTAL',               TOT: '100', PO: '40', FAT: '0', ATA: '0', IST: '0', },
        ]
    },
}