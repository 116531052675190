  import { START_LOCATION } from "vue-router";

export default {
  Canvas: {
    CanvasWidth: 2520,
    CanvasHeight: 1120,
    CanvasBorderWeight: 0,
    CanvasBorderColor: '#bcbcbc',
    CanvasFillColor: '#fff',
    CanvasOpacity: 1,

    CanvasChartX: 0,
    CanvasChartY: 0,
    CanvasChartWidth: 1300,
    CanvasChartHeight: 760,
    CanvasChartBorderWeight: 0,
    CanvasChartBorderColor: '#757575',
    CanvasChartFillColor: '#000',
    CanvasChartOpacity: 0,
    CanvasChartSpace: 0,

    CanvasFontFamily: 'roboto',
  },
  


  DataItems: [
    {
      CUTOFF:                 '2025-02-28', //weekend is FRI
      TITLE_SUB:              'SkylineChart for RFQ Tagged ',
    }
  ],

  Queries: { //move from data.js

    SQL1: [
      { RFQ: '1Q-5E', DESC: 'BULK OIL TREATER AND DEGASSER',                    ETA: '2025-07-07', SRD: '2025-07-08', ETA_SRD: 1, },
      { RFQ: '1Q-63', DESC: 'WASTE HEAT RECOVERY UNIT',                         ETA: '2025-09-24', SRD: '2025-12-01', ETA_SRD: 68, },
      { RFQ: '1Q-64', DESC: 'PLATE AND FRAME HEAT EXCHANGER',                   ETA: '2025-03-13', SRD: '2025-07-29', ETA_SRD: 138, },
      { RFQ: '1Q-92', DESC: 'WATER PRESSURE SET',                               ETA: '2025-09-30', SRD: '2025-08-06', ETA_SRD: -55, },
      { RFQ: 'HQ-31A', DESC: 'CENTRIFUGAL PUMP (FRESH WATER/ DIESEL)',          ETA: '2025-02-21', SRD: '2025-03-25', ETA_SRD: 32, },
      { RFQ: 'HQ-32', DESC: 'ROTARY PUMP',                                      ETA: '2025-02-28', SRD: '2025-03-25', ETA_SRD: 25, },
      { RFQ: 'HQ-54', DESC: 'OILY WATER SEPARATOR PACKAGE',                     ETA: '2025-06-19', SRD: '2025-08-07', ETA_SRD: 49, },
      { RFQ: 'HQ-83', DESC: 'HYPOCHLORITE GENERATOR PACKAGE',                   ETA: '2025-07-15', SRD: '2025-08-21', ETA_SRD: 37, },
      { RFQ: 'HQ-84', DESC: 'WATER MAKER PACKAGE',                              ETA: '2025-07-13', SRD: '2025-08-21', ETA_SRD: 39, },
      { RFQ: 'HQ-86', DESC: 'SEWAGE TREATMENT PACKAGE',                         ETA: '2025-07-01', SRD: '2025-08-08', ETA_SRD: 38, },
      { RFQ: 'HQ-88', DESC: 'DISPOSAL CAISSON WITH PUMP PACKAGE',               ETA: '2025-10-30', SRD: '2025-10-24', ETA_SRD: -6, },
      { RFQ: '1P-01', DESC: 'ON/OFF ACTUATED VALVE',                            ETA: '2025-10-30', SRD: '2025-08-19', ETA_SRD: -72, },
      { RFQ: '1P-02', DESC: 'PRESSURE RELIEF VALVE',                            ETA: '2025-10-20', SRD: '2025-09-04', ETA_SRD: -46, },
      { RFQ: '1P-03', DESC: 'CHOKE VALVE',                                      ETA: '2025-10-13', SRD: '2025-07-08', ETA_SRD: -97, },
      { RFQ: '1P-04', DESC: 'CONTROL VALVE',                                    ETA: '2025-08-21', SRD: '2025-08-19', ETA_SRD: -2, },
      { RFQ: '1P-04A', DESC: 'CONTROL VALVE (SEVERE SERVICE)',                  ETA: '2025-10-01', SRD: '2025-11-03', ETA_SRD: 33, },
      { RFQ: '1P-04B', DESC: 'CONTROL VALVE (LOW SHEAR DESIGN)',                ETA: '2025-12-01', SRD: '2025-09-09', ETA_SRD: -83, },
      { RFQ: '1P-05', DESC: 'PRESSURE REGULATOR',                               ETA: '2025-07-01', SRD: '2025-09-04', ETA_SRD: 65, },
      { RFQ: '1P-06', DESC: 'FLOWLINE & RISER AUTOMATED BALL VALVE',            ETA: '2025-08-18', SRD: undefined,    ETA_SRD: 0, },
      { RFQ: '1P-07', DESC: 'HPU SKID',                                         ETA: '2025-05-28', SRD: '2025-09-08', ETA_SRD: 103, },
      { RFQ: '1P-08', DESC: 'PRESSURE VACUUM RELIEF VALVE',                     ETA: '2025-09-16', SRD: '2025-06-24', ETA_SRD: -84, },
      { RFQ: '1P-09', DESC: 'BUCKLING PIN RELIEF VALVE',                        ETA: '2025-11-06', SRD: '2025-11-03', ETA_SRD: -3, },
      { RFQ: '1P-12', DESC: 'V-CONE METER',                                     ETA: '2026-01-15', SRD: '2025-08-19', ETA_SRD: -149, },
      { RFQ: '1P-13', DESC: 'ULTRASONIC METER',                                 ETA: '2025-09-01', SRD: '2025-10-16', ETA_SRD: 45, },
      { RFQ: '1P-15', DESC: 'ROTAMETER',                                        ETA: '2025-07-02', SRD: '2025-10-03', ETA_SRD: 93, },
      { RFQ: '1P-16', DESC: 'CORIOLIS FLOWMETER',                               ETA: '2025-06-21', SRD: '2025-10-15', ETA_SRD: 116, },
      { RFQ: '1P-17', DESC: 'MAGNETIC FLOWMETER',                               ETA: '2025-06-21', SRD: '2025-09-04', ETA_SRD: 75, },
      { RFQ: '1P-19', DESC: 'ORIFICE PLATE',                                    ETA: '2025-10-19', SRD: '2025-06-09', ETA_SRD: -132, },
      { RFQ: '1P-22', DESC: 'PIG LAUNCHER / RECEIVER',                          ETA: '2025-06-06', SRD: '2025-07-08', ETA_SRD: 32, },
      { RFQ: '1P-23', DESC: '2500LB & API 10K COMPACT VALVE',                   ETA: '2026-02-22', SRD: '2025-05-30', ETA_SRD: -268, },
      { RFQ: '1P-24', DESC: 'BALL VALVE 3" & ABOVE',                            ETA: '2025-07-01', SRD: '2025-05-30', ETA_SRD: -32, },
      { RFQ: '1P-25', DESC: 'BUTTERFLY VALVE',                                  ETA: '2025-06-01', SRD: '2025-05-30', ETA_SRD: -2, },
      { RFQ: '1P-26', DESC: 'BALL VALVE 2" & BELOW',                            ETA: '2025-06-01', SRD: '2025-05-30', ETA_SRD: -2, },
      { RFQ: '1P-27', DESC: 'CASTING GATE, GLOBE & CHECK VALVE',                ETA: '2025-07-30', SRD: '2025-08-19', ETA_SRD: 20, },
      { RFQ: '1P-28', DESC: 'FORGED GATE, GLOBE & CHECK VALVE',                 ETA: '2025-06-01', SRD: '2025-08-19', ETA_SRD: 79, },
      { RFQ: '1P-29', DESC: 'INTEGRAL DOUBLE BLOCK & BLEED VALVE (FOR IN-LINE)',ETA: '2026-01-12', SRD: '2025-09-04', ETA_SRD: -130, },
      { RFQ: '1P-29A', DESC: 'INTEGRAL DOUBLE BLOCK & BLEED VALVE (FOR INSTRUMENT)',ETA: '2025-06-10', SRD: '2025-08-19', ETA_SRD: 70, },
      { RFQ: '1P-30', DESC: 'NEEDLE VALVE',                                     ETA: '2025-05-01', SRD: '2025-06-09', ETA_SRD: 39, },
      { RFQ: '1P-31', DESC: 'NON-SLAM CHECK VALVE',                             ETA: '2025-08-15', SRD: '2025-08-19', ETA_SRD: 4, },
      { RFQ: '1P-37', DESC: 'FLAME ARRESTOR',                                   ETA: '2025-08-16', SRD: '2025-09-04', ETA_SRD: 19, },
      { RFQ: '1P-38', DESC: 'STRAINER',                                         ETA: '2025-08-26', SRD: '2025-05-30', ETA_SRD: -88, },
      { RFQ: '1P-38A', DESC: 'SPECIAL STRAINER',                                ETA: '2025-08-10', SRD: '2025-10-22', ETA_SRD: 73, },
      { RFQ: '1P-39', DESC: 'FLOWLINE & RISER SPECIALTY ITEM',                  ETA: '2025-06-23', SRD: '2025-03-28', ETA_SRD: -87, },
      { RFQ: '1P-40', DESC: 'PIPING SPECIALTY ITEM #1',                         ETA: '2025-12-15', SRD: '2025-10-20', ETA_SRD: -56, },
      { RFQ: '1P-41', DESC: 'PIPING SPECIALTY ITEM #2',                         ETA: '2025-09-17', SRD: '2025-10-20', ETA_SRD: 33, },
      { RFQ: '1P-44', DESC: 'SAMPLE CONNECTION & CHEMICAL INJECTION QUILL',     ETA: '2025-12-19', SRD: '2025-10-01', ETA_SRD: -79, },
      { RFQ: 'HP-01', DESC: 'HPU WITH ACTUATED VALVES',                         ETA: '2025-02-24', SRD: '2025-03-25', ETA_SRD: 29, },
      { RFQ: 'HP-02', DESC: 'PNEUMATIC ON/OFF VALVE',                           ETA: '2025-05-29', SRD: '2025-08-08', ETA_SRD: 71, },
      { RFQ: 'HP-03', DESC: 'PRESSURE RELIEF VALVE',                            ETA: '2025-01-25', SRD: '2025-04-01', ETA_SRD: 66, },
      { RFQ: 'HP-04', DESC: 'CONTROL VALVE & REGULATOR',                        ETA: '2025-06-15', SRD: '2025-06-27', ETA_SRD: 12, },
      { RFQ: 'HP-07', DESC: 'HULL FLOWMETER (MAGNETIC, ORIFICE)',               ETA: '2025-03-02', SRD: '2025-03-25', ETA_SRD: 23, },
      { RFQ: 'HP-08', DESC: 'BALL VALVE',                                       ETA: '2025-03-25', SRD: '2025-02-25', ETA_SRD: -28, },
      { RFQ: 'HP-09', DESC: 'BUTTERFLY VALVE',                                  ETA: '2025-03-10', SRD: '2025-02-25', ETA_SRD: -13, },
      { RFQ: 'HP-10', DESC: 'GLOBE & CHECK VALVE (CASTING TYPE)',               ETA: '2025-04-18', SRD: '2025-03-25', ETA_SRD: -24, },
      { RFQ: 'HP-10A', DESC: 'GLOBE & CHECK VALVE (FORGED TYPE)',               ETA: '2025-04-18', SRD: '2025-03-25', ETA_SRD: -24, },
      { RFQ: 'HP-11', DESC: 'DUAL PLATE CHECK VALVE',                           ETA: '2025-03-25', SRD: '2025-03-25', ETA_SRD: 0, },
      { RFQ: 'HP-12', DESC: 'INTEGRAL DOUBLE BLOCK & BLEED VALVE',              ETA: '2025-08-15', SRD: '2025-08-21', ETA_SRD: 6, },
      { RFQ: 'HP-13', DESC: 'VENT CHECK VALVE',                                 ETA: '2025-05-15', SRD: '2025-08-07', ETA_SRD: 84, },
      { RFQ: 'HP-14', DESC: 'AXIAL FLOW CHECK VALVE',                           ETA: '2025-07-15', SRD: '2025-08-08', ETA_SRD: 24, },
      { RFQ: 'HP-15', DESC: 'STRAINER',                                         ETA: '2025-02-14', SRD: '2025-02-25', ETA_SRD: 11, },
      { RFQ: 'HP-16', DESC: 'HULL SPECIALTY ITEMS #1',                          ETA: '2025-07-10', SRD: '2025-08-07', ETA_SRD: 28, },
      { RFQ: 'HP-17', DESC: 'HULL SPECIALTY ITEMS #2',                          ETA: '2025-06-20', SRD: '2025-06-02', ETA_SRD: -18, },
      { RFQ: '1E-01', DESC: 'MV SWGR/ MCC/ PMCS',                               ETA: '2025-10-02', SRD: '2025-10-28', ETA_SRD: 26, },
      { RFQ: '1E-02', DESC: 'LV SWGR/MCC',                                      ETA: '2025-08-09', SRD: '2025-08-30', ETA_SRD: 21, },
      { RFQ: '1E-03', DESC: 'AC & DC UPS SYSTEM',                               ETA: '2025-08-18', SRD: '2025-10-28', ETA_SRD: 71, },
      { RFQ: '1E-04', DESC: 'POWER TRANSFORMERS',                               ETA: '2025-09-21', SRD: '2025-10-28', ETA_SRD: 37, },
      { RFQ: '1E-05', DESC: 'NEUTRAL EARTHING RESISTORS (NER)',                 ETA: '2025-08-08', SRD: '2025-10-28', ETA_SRD: 81, },
      { RFQ: '1E-06', DESC: 'DRY TYPE POWER TRANSFORMERS',                      ETA: '2025-07-09', SRD: '2025-08-30', ETA_SRD: 52, },
      { RFQ: '1E-07A', DESC: 'POWER DISTRIBUTION BOARD - INDOOR',               ETA: '2025-08-30', SRD: '2025-08-30', ETA_SRD: 0, },
      { RFQ: '1E-07B', DESC: 'POWER DISTRIBUTION BOARD - OUTDOOR',              ETA: '2025-08-30', SRD: '2025-08-30', ETA_SRD: 0, },
      { RFQ: '1E-08', DESC: 'NAVIGATION AIDS SYSTEM &  HELIDECK LIGHTING',      ETA: '2025-10-03', SRD: '2025-10-25', ETA_SRD: 22, },
      { RFQ: '1E-09', DESC: 'ELEC HEAT TRACING',                                ETA: '2025-06-30', SRD: '2025-10-01', ETA_SRD: 93, },
      { RFQ: '1E-11', DESC: 'CABLE LADDER AND PUNCH TRAY',                      ETA: '2025-04-11', SRD: '2025-04-11', ETA_SRD: 0, },
      { RFQ: '1E-12', DESC: 'CABLES (ELEC, INST, TELECOM)',                     ETA: '2025-02-28', SRD: '2025-09-27', ETA_SRD: 211, },
      { RFQ: '1I-01', DESC: 'ICSS',                                             ETA: '2025-09-01', SRD: '2025-08-16', ETA_SRD: -16, },
      { RFQ: '1I-02', DESC: 'FIRE ALARM CONTROL PANEL',                         ETA: '2025-06-16', SRD: '2025-07-19', ETA_SRD: 33, },
      { RFQ: '1I-03', DESC: 'FIRE AND GAS DETECTORS',                           ETA: '2025-08-23', SRD: '2025-08-30', ETA_SRD: 7, },
      { RFQ: '1I-03B', DESC: 'OPTICAL INFRARED GAS DETECTION SYSTEM',           ETA: '2025-10-28', SRD: '2025-11-07', ETA_SRD: 10, },
      { RFQ: '1I-04A', DESC: 'SO4 ANALYZER',                                    ETA: '2025-09-18', SRD: '2025-10-01', ETA_SRD: 13, },
      { RFQ: '1I-05', DESC: 'PIG DETECTOR',                                     ETA: '2025-08-20', SRD: '2025-10-01', ETA_SRD: 42, },
      { RFQ: '1I-06', DESC: 'CORRSION PROBE',                                   ETA: '2025-08-29', SRD: '2025-11-11', ETA_SRD: 74, },
      { RFQ: '1I-07', DESC: 'ELECTRONIC TRANSMITTER',                           ETA: '2025-08-29', SRD: '2025-10-01', ETA_SRD: 33, },
      { RFQ: '1I-08', DESC: 'GWR, MAGNETOSTRICTIVE LEVEL TRANSMITTERS AND LEVEL GAUGE',ETA: '2025-07-15', SRD: '2025-10-01', ETA_SRD: 78, },
      { RFQ: '1I-12', DESC: 'CONDITION PERFORMANCE MONITORING SYSTEM',          ETA: '2025-10-29', SRD: '2025-10-29', ETA_SRD: 0, },
      { RFQ: '1T-01', DESC: 'TELECOMMUNICATION SYSTEMS INTEGRATOR',             ETA: '2025-08-30', SRD: '2025-10-06', ETA_SRD: 37, },
      { RFQ: '1T-02', DESC: 'PAGA SYSTEM',                                      ETA: '2025-08-06', SRD: '2025-08-01', ETA_SRD: -5, },
      { RFQ: '1T-03', DESC: 'TELECOM INFRASTRUCTURE',                           ETA: '2025-07-01', SRD: '2025-08-07', ETA_SRD: 37, },
      { RFQ: 'HE-01', DESC: 'HULL LV MCC',                                      ETA: '2025-08-07', SRD: '2025-08-07', ETA_SRD: 0, },
      { RFQ: 'HE-02', DESC: 'HULL DRY TYPE POWER TRANSFORMERS',                 ETA: '2025-07-10', SRD: '2025-08-07', ETA_SRD: 28, },
      { RFQ: 'HE-03', DESC: 'HULL POWER DISTRIBUTION BOARD INCLUDING UPS SUB-D.B', ETA: '2025-06-01', SRD: '2025-08-07', ETA_SRD: 67, },
      { RFQ: 'HI-01', DESC: 'MARINE MANAGEMENT CONTROL SYSTEM',                 ETA: '2025-09-08', SRD: '2025-08-16', ETA_SRD: -23, },
      { RFQ: 'HI-04', DESC: 'HULL ELECTRONIC TRANSMITTER',                      ETA: '2025-07-15', SRD: '2025-10-16', ETA_SRD: 93, },
      { RFQ: 'HI-05', DESC: 'HULL LEVEL TRANSMITTER',                           ETA: '2025-06-24', SRD: '2025-10-16', ETA_SRD: 114, },
      { RFQ: 'HT-01', DESC: 'HULL IMMS',                                        ETA: '2025-09-02', SRD: '2025-09-13', ETA_SRD: 11, },
      { RFQ: 'HS-01', DESC: 'SACRIFICIAL ANODES',                               ETA: '2024-11-14', SRD: '2024-11-14', ETA_SRD: 0, },
      { RFQ: 'HS-27', DESC: 'MOORING TENSIONING HARDWARE',                      ETA: '2025-10-21', SRD: '2025-12-15', ETA_SRD: 55, },
      { RFQ: '1A-04', DESC: 'LIFT',                                             ETA: '2025-06-01', SRD: '2025-06-18', ETA_SRD: 17, },
      { RFQ: '1H-01', DESC: 'HVAC PACKAGE FOR LQ',                              ETA: '2025-06-13', SRD: '2025-05-14', ETA_SRD: -30, },
      { RFQ: '1H-02', DESC: 'HVAC PACKAGE FOR BUILDINGS',                       ETA: '2025-09-30', SRD: '2025-08-15', ETA_SRD: -46, },
      { RFQ: '1O-01', DESC: 'LSA',                                              ETA: '2025-05-06', SRD: '2025-12-30', ETA_SRD: 238, },
      { RFQ: '1O-03', DESC: 'SURVIVAL CRAFT AND FAST RESCUE BOAT',              ETA: '2025-06-29', SRD: '2025-08-06', ETA_SRD: 38, },
      { RFQ: '1S-01', DESC: 'ALUMINIUM HELIDECK',                               ETA: '2026-02-01', SRD: '2026-05-01', ETA_SRD: 89, },
      { RFQ: 'HH-01', DESC: 'HVAC PACKAGE',                                     ETA: '2025-08-15', SRD: '2025-08-07', ETA_SRD: -8, },
      { RFQ: 'HH-03', DESC: 'WATER TIGHT DOOR',                                 ETA: '2025-03-25', SRD: '2025-03-25', ETA_SRD: 0, },
      { RFQ: '1F-03A', DESC: 'DELUGE VALVE',                                    ETA: '2025-07-01', SRD: '2025-09-19', ETA_SRD: 80, },
      { RFQ: '1F-07', DESC: 'LOOSE FIRE FIGHTING',                              ETA: '2025-12-01', SRD: '2025-05-15', ETA_SRD: -200, },
      { RFQ: '1F-09', DESC: 'CLEAN AGENT SYSTEM',                               ETA: '2025-08-04', SRD: '2025-10-28', ETA_SRD: 85, },
      { RFQ: '1O-07', DESC: 'HOSE REEL',                                        ETA: '2025-06-15', SRD: '2025-07-08', ETA_SRD: 23, },
      { RFQ: '1Q-02', DESC: 'VAPOR RECOVERY/FLASH GAS COMPRESSOR',              ETA: '2025-11-22', SRD: '2025-11-17', ETA_SRD: -5, },
      { RFQ: '1Q-06', DESC: 'INSTRUMENT AIR AND NITROGEN GENERATION SKID',      ETA: '2025-07-07', SRD: '2025-07-29', ETA_SRD: 22, },
      { RFQ: '1Q-15', DESC: 'EMERGENCY AND AUXILIARY GENERATOR SKID',           ETA: '2025-10-12', SRD: '2025-08-06', ETA_SRD: -67, },
      { RFQ: '1Q-21', DESC: 'FIRE WATER PUMP AND GENERATOR SKID',               ETA: '2025-10-05', SRD: '2025-08-15', ETA_SRD: -51, },
      { RFQ: '1Q-22', DESC: 'WATER INJECTION PUMP SKID',                        ETA: '2026-01-03', SRD: '2025-10-15', ETA_SRD: -80, },
      { RFQ: '1Q-24', DESC: 'SEAWATER LIFT PUMP',                               ETA: '2025-10-05', SRD: '2025-10-06', ETA_SRD: 1, },
      { RFQ: '1Q-27', DESC: 'CRUDE OIL PUMP',                                   ETA: '2025-07-09', SRD: '2025-07-08', ETA_SRD: -1, },
      { RFQ: '1Q-31', DESC: 'MISCELLANEOUS PUMPS (CENTRIFUGAL)',                ETA: '2025-07-18', SRD: '2025-07-29', ETA_SRD: 11, },
      { RFQ: '1Q-32', DESC: 'PROGRESSIVE CAVITY PUMPS',                         ETA: '2025-04-01', SRD: '2025-07-08', ETA_SRD: 98, },
      { RFQ: '1Q-49', DESC: 'FILTER',                                           ETA: '2025-03-19', SRD: '2025-07-29', ETA_SRD: 132, },
      { RFQ: '1Q-4A', DESC: 'STRAINER',                                         ETA: '2025-07-29', SRD: '2025-08-15', ETA_SRD: 17, },
      { RFQ: '1Q-51', DESC: 'TEG DEHYDRATION SYSTEM',                           ETA: '2025-08-22', SRD: '2025-09-08', ETA_SRD: 17, },
      { RFQ: '1Q-52', DESC: 'SEAWATER TREATMENT SYSTEM',                        ETA: '2025-07-16', SRD: '2025-07-29', ETA_SRD: 13, },
      { RFQ: '1Q-53', DESC: 'FUEL GAS SKID AND ELECTRIC HEATER',                ETA: '2025-12-07', SRD: '2025-09-15', ETA_SRD: -83, },
      { RFQ: '1Q-54', DESC: 'FLOTATION CELL SKID',                              ETA: '2025-06-07', SRD: '2025-07-29', ETA_SRD: 52, },
      { RFQ: '1Q-55', DESC: 'CHEMICAL INJECTION SKID',                          ETA: '2025-09-30', SRD: '2025-07-29', ETA_SRD: -63, },
      { RFQ: '1Q-56', DESC: 'DIESEL TRANSFER SKID',                             ETA: '2025-10-13', SRD: '2025-07-29', ETA_SRD: -76, },
      { RFQ: '1Q-57', DESC: 'CLOSED DRAIN SKID',                                ETA: '2025-11-08', SRD: '2025-10-06', ETA_SRD: -33, },
      { RFQ: '1Q-5C', DESC: 'SALES AND BUY-BACK GAS METER SKID',                ETA: '2025-06-25', SRD: '2025-08-30', ETA_SRD: 66, },
      { RFQ: '1Q-66', DESC: 'WELDED PLATE HEAT EXCHANGER',                      ETA: '2025-05-01', SRD: '2025-07-08', ETA_SRD: 68, },
      { RFQ: '1Q-68', DESC: 'S&T EXCHANGER',                                    ETA: '2025-05-01', SRD: '2025-07-29', ETA_SRD: 89, },
      { RFQ: '1Q-6A', DESC: 'PRINTED CIRCUIT HEAT EXCHANGER',                   ETA: '2025-09-07', SRD: '2025-10-03', ETA_SRD: 26, },
      { RFQ: '1Q-6Z', DESC: 'ELECTRIC WATER HEATER',                            ETA: '2025-04-16', SRD: '2025-06-12', ETA_SRD: 57, },
      { RFQ: '1Q-71', DESC: 'PEDESTAL CRANE',                                   ETA: '2025-10-21', SRD: '2025-11-07', ETA_SRD: 17, },
      { RFQ: '1Q-72', DESC: 'MATERIAL HANDLING EQUIPMENT',                      ETA: '2026-01-08', SRD: '2025-10-03', ETA_SRD: -97, },
      { RFQ: '1Q-82', DESC: 'HELICOPTER REFUELING SKID',                        ETA: '2025-10-18', SRD: '2025-09-29', ETA_SRD: -19, },
      { RFQ: '1Q-89', DESC: 'FLARE TIP AND CONTROL SKID',                       ETA: '2025-08-15', SRD: '2025-11-17', ETA_SRD: 94, },
      { RFQ: '1Q-99', DESC: 'ATMOSPHERIC TANK',                                 ETA: '2025-05-08', SRD: '2025-08-06', ETA_SRD: 90, },
      { RFQ: '1Q-9A', DESC: 'LOW PRESSURE VESSEL',                              ETA: '2025-04-20', SRD: '2025-07-08', ETA_SRD: 79, },
      { RFQ: '1Q-9B', DESC: 'MEDIUM PRESSURE VESSEL',                           ETA: '2025-04-20', SRD: '2025-07-08', ETA_SRD: 79, },
      { RFQ: '1Q-9C', DESC: 'HIGH PRESSURE VESSEL',                             ETA: '2025-05-08', SRD: '2025-08-06', ETA_SRD: 90, },
      { RFQ: '1Q-9Z', DESC: 'EXPANSION TANK',                                   ETA: '2025-04-04', SRD: '2025-06-12', ETA_SRD: 69, },
      { RFQ: 'HQ-24', DESC: 'SEAWATER LIFT PUMP (SEAWATER SERVICE)',            ETA: '2025-11-01', SRD: '2025-10-03', ETA_SRD: -29, },
      { RFQ: 'HQ-31', DESC: 'BALLAST/ MAIN BILGE PUMP',                         ETA: '2025-04-30', SRD: '2025-03-25', ETA_SRD: -36, },
      ],
    
  },

}