
export default {
  Canvas: {
    CanvasWidth: 810,
    CanvasHeight: 1500,
    CanvasBorderWeight: 0,
    CanvasBorderColor: '#bcbcbc',
    CanvasFillColor: '#fff',
    CanvasOpacity: 1,

    CanvasChartX: 0,
    CanvasChartY: 0,
    CanvasChartWidth: 1300,
    CanvasChartHeight: 760,
    CanvasChartBorderWeight: 0,
    CanvasChartBorderColor: '#757575',
    CanvasChartFillColor: '#000',
    CanvasChartOpacity: 0,
    CanvasChartSpace: 0,

    CanvasFontFamily: 'roboto',
  },
  


  DataItems: [
    {
      CUTOFF: '2025-02-14'
    },
  ],


  Queries: {
    
    SQL1: [
      { EXCEPT: '',  AREA: 'HULL',    ZONE: 'NODE',           MOD: 'Node, NW',       LEVEL: 'NW, Node',            NAME: 'Node  Shell',            G_EREC: 'WN112', G_2PE: 'WN112', G_1PE: 'WN111', EREC: 'WN112', PE2: '',      PE1: 'WN111', PBLK: 'WN111', BLK: 'WN110', L: '19.6',  B: '19.6',  H: '2.8',   YARD: 'HHI',   SC_WF: 835.67, FB_WF: 1671.34, AS_WF: 2507.01, PE1_WF: 0,     PT_WF: 2711.73, PE2_WF: 4178.34, ER_WF: 2073.7, SC_PS: '2024-11-20', SC_PF: '2024-12-09', SC_PP: 100,   SC_AS: '2024-11-26', SC_AF: '2024-12-06', SC_AP: 100, FB_PS: '2024-12-10', FB_PF: '2025-01-13', FB_PP: 100,   FB_AS: '2024-12-19', FB_AF: '2025-02-14', FB_AP: 100,  AS_PS: '2025-01-14', AS_PF: '2025-03-10', AS_PP: 43.8, AS_AS: '2025-02-12', AS_AF: undefined,    AS_AP: 5,    PE1_PS: undefined,    PE1_PF: undefined,    PE1_PP: 0, PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0, PT_PS: '2025-04-10', PT_PF: '2025-05-28', PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: '2025-05-29', PE2_PF: '2025-07-23', PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: '2025-07-24', ER_PF: '2025-09-02', ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'HULL',    ZONE: 'NODE',           MOD: 'Node, NW',       LEVEL: 'NW, Node',            NAME: 'Node Support',           G_EREC: 'WN112', G_2PE: 'WN112', G_1PE: 'WN111', EREC: '',      PE2: '',      PE1: '',      PBLK: '',      BLK: 'WNSA0', L: '1(n)',  B: '1(n)',  H: '1(n)',  YARD: 'HHI',   SC_WF: 8,     FB_WF: 8,     AS_WF: 0,     PE1_WF: 24,    PT_WF: 0,     PE2_WF: 0,     ER_WF: 0,     SC_PS: '2025-01-08', SC_PF: '2025-01-27', SC_PP: 100,   SC_AS: '2025-01-11', SC_AF: '2025-01-17', SC_AP: 100, FB_PS: '2025-01-31', FB_PF: '2025-03-17', FB_PP: 24.1,  FB_AS: undefined,    FB_AF: undefined,    FB_AP: 0,    AS_PS: undefined,    AS_PF: undefined,    AS_PP: 0,    AS_AS: undefined,    AS_AF: undefined,    AS_AP: 0,    PE1_PS: '2025-03-18', PE1_PF: '2025-04-07', PE1_PP: 0, PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0, PT_PS: undefined,    PT_PF: undefined,    PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: undefined,    PE2_PF: undefined,    PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: undefined,    ER_PF: undefined,    ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'HULL',    ZONE: 'NODE',           MOD: 'Node, NW',       LEVEL: 'NW, Node',            NAME: 'Node  Shell',            G_EREC: 'WN112', G_2PE: 'WN112', G_1PE: 'WN121', EREC: '',      PE2: '',      PE1: 'WN121', PBLK: 'WN121', BLK: 'WN120', L: '13.3',  B: '7.8',   H: '6.7',   YARD: 'HHI',   SC_WF: 400.85, FB_WF: 801.7, AS_WF: 1202.55, PE1_WF: 2004.25, PT_WF: 602.61, PE2_WF: 2004.25, ER_WF: 0,     SC_PS: '2024-12-20', SC_PF: '2025-01-13', SC_PP: 100,   SC_AS: '2024-12-26', SC_AF: '2025-01-09', SC_AP: 100, FB_PS: '2025-01-14', FB_PF: '2025-02-13', FB_PP: 100,   FB_AS: '2025-01-22', FB_AF: undefined,    FB_AP: 10,   AS_PS: '2025-02-14', AS_PF: '2025-04-02', AS_PP: 2.1,  AS_AS: undefined,    AS_AF: undefined,    AS_AP: 0,    PE1_PS: '2025-04-03', PE1_PF: '2025-05-07', PE1_PP: 0, PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0, PT_PS: '2025-05-08', PT_PF: '2025-05-30', PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: '2025-06-02', PE2_PF: '2025-07-23', PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: undefined,    ER_PF: undefined,    ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'HULL',    ZONE: 'NODE',           MOD: 'Node, NW',       LEVEL: 'NW, Node',            NAME: 'Node  Shell',            G_EREC: 'WN112', G_2PE: 'WN112', G_1PE: 'WN121', EREC: '',      PE2: '',      PE1: '',      PBLK: '',      BLK: 'WN130', L: '7.6',   B: '13',    H: '6.8',   YARD: 'HHI',   SC_WF: 369.99, FB_WF: 739.99, AS_WF: 1109.98, PE1_WF: 1849.96, PT_WF: 0,     PE2_WF: 0,     ER_WF: 0,     SC_PS: '2024-12-20', SC_PF: '2025-01-13', SC_PP: 100,   SC_AS: '2024-12-27', SC_AF: '2025-01-11', SC_AP: 100, FB_PS: '2025-01-14', FB_PF: '2025-02-13', FB_PP: 100,   FB_AS: '2025-01-22', FB_AF: undefined,    FB_AP: 10,   AS_PS: '2025-02-14', AS_PF: '2025-04-02', AS_PP: 2.1,  AS_AS: undefined,    AS_AF: undefined,    AS_AP: 0,    PE1_PS: '2025-04-03', PE1_PF: '2025-04-30', PE1_PP: 0, PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0, PT_PS: undefined,    PT_PF: undefined,    PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: undefined,    PE2_PF: undefined,    PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: undefined,    ER_PF: undefined,    ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      { EXCEPT: '',  AREA: 'HULL',    ZONE: 'NODE',           MOD: 'Node, NW',       LEVEL: 'NW, Node',            NAME: 'Node  Shell',            G_EREC: 'WN112', G_2PE: 'WN112', G_1PE: 'WN121', EREC: '',      PE2: '',      PE1: '',      PBLK: '',      BLK: 'WN140', L: '7.5',   B: '13.3',  H: '6.7',   YARD: 'HHI',   SC_WF: 414.15, FB_WF: 828.31, AS_WF: 1242.46, PE1_WF: 2070.77, PT_WF: 0,     PE2_WF: 0,     ER_WF: 0,     SC_PS: '2024-12-30', SC_PF: '2025-01-20', SC_PP: 100,   SC_AS: '2025-01-06', SC_AF: '2025-01-13', SC_AP: 100, FB_PS: '2025-01-21', FB_PF: '2025-02-20', FB_PP: 74,    FB_AS: '2025-02-08', FB_AF: undefined,    FB_AP: 10,   AS_PS: '2025-02-21', AS_PF: '2025-04-09', AS_PP: 0,    AS_AS: undefined,    AS_AF: undefined,    AS_AP: 0,    PE1_PS: '2025-04-10', PE1_PF: '2025-04-30', PE1_PP: 0, PE1_AS: undefined,    PE1_AF: undefined,    PE1_AP: 0, PT_PS: undefined,    PT_PF: undefined,    PT_PP: 0, PT_AS: undefined,    PT_AF: undefined,    PT_AP: 0, PE2_PS: undefined,    PE2_PF: undefined,    PE2_PP: 0, PE2_AS: undefined,    PE2_AF: undefined,    PE2_AP: 0, ER_PS: undefined,    ER_PF: undefined,    ER_PP: 0, ER_AS: undefined,    ER_AF: undefined,    ER_AP: 0, },
      ],

  },
}